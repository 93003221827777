<mat-drawer-container class="sidenav-container" autosize>
  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-contentnavbar></app-contentnavbar>
    <div class="mainrightcontainersecondary">
    <div class="breadcrumbholder">
      <app-breadcrumb route="content/therapyedit/custombranding"></app-breadcrumb>

    </div>

    <div class="col-md-12">
      <h3 class="commontitleprimary">
        <app-backbutton></app-backbutton>
        Update Branding
      </h3>
    </div>
    <div class="col-md-12 scroll-area">
      <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
        <div class="form-wrapper">
          <div class="row">
            <div class="col-md-12">
              <div class="row">

                <div class="col-md-9" style="padding:0">
                  <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="Phone Number" formControlName="support_phone_number">
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Email ID</mat-label>
                    <input matInput placeholder="Email ID" formControlName="support_email_id">
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Therapy branding?</mat-label>
                    <mat-select formControlName="use_therapy_branding">
                      <mat-option [value]="true">Yes</mat-option>
                      <mat-option [value]="false">No</mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </div>              
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="row" style="height: 50px;"> <h5>Logo media</h5></div>
              <div class="image-placeholder">
                
                <div class="profile-image" >
                  <div class="profile-dummy-img" *ngIf="!isloading  && !uploadedparam">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isloading"></mat-spinner>
                  <img *ngIf="!isloading  && uploadedparam" class="image-data img-responsive" [src]="s3url + log_media_image_path">
                </div>
                <form [formGroup]="demoForm" (change)="uploadImage('logo_media')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="logo_media" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        edit
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>

            </div>

            <div class="col-md-3">
              <div class="row" style="height: 50px;"> <h5>Banner Media</h5></div>
              <div class="image-placeholder">
                
                <div class="profile-image" >
                  <div class="profile-dummy-img" *ngIf="!isbannerloading && !uploadedBannermedia">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isbannerloading"></mat-spinner>
                  <img *ngIf="!isbannerloading && uploadedBannermedia" class="image-data img-responsive"
                    [src]="s3url + banner_media_image_path">
                </div>
                <form [formGroup]="demoForm" (change)="uploadImage('banner_media')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="banner_media" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        edit
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>

            </div>


            <div class="col-md-3">
              <div class="row" style="height: 50px;"> <h5>Colored Logo Image</h5></div>
              <div class="image-placeholder">
               
                <div class="profile-image">
                  <div class="profile-dummy-img" *ngIf="!iscoloredloading && !uploadedcoloredmedia">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="iscoloredloading"></mat-spinner>
                  <img *ngIf="!iscoloredloading && uploadedcoloredmedia" class="image-data img-responsive"
                    [src]="s3url + colored_logo_media_path">
                </div>
                <form [formGroup]="demoForm" (change)="uploadImage('colored_logo_media')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="colored_logo_media" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        edit
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>

            </div>



            <div class="col-md-3">
              <div class="row" style="height: 50px;"> <h5>Banner Media For Logging (Optional)</h5></div>
              <div class="image-placeholder">
                
                <div class="profile-image" >
                  <div class="profile-dummy-img" *ngIf="!isbannerloggingLight && !uploadedBannerloggingLight">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isbannerloggingLight"></mat-spinner>
                  <img *ngIf="!isbannerloggingLight && uploadedBannerloggingLight" class="image-data img-responsive"
                    [src]="s3url + banner_media_logging_image_path">
                </div>
                <form [formGroup]="demoForm" (change)="uploadImage('banner_media_logging_light')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="banner_media_logging_light" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        edit
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>

            </div>
          </div>
          <div class="row">
            <!-- <div class="col-md-9" style="padding:0"> -->
            <div class="col-md-3">
              <div class="row" style="height: 50px;"><h5>Dark Logo media (optional)</h5></div>
              <div class="image-placeholder">
                
                <div class="profile-image">
                  <div class="profile-dummy-img" *ngIf="!isdarklogoloading && !uploadeddarkparam">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isdarklogoloading"></mat-spinner>
                  <img *ngIf="!isdarklogoloading && uploadeddarkparam" class="image-data img-responsive"
                    [src]="s3url + dark_log_media_image_path">
                </div>

                <form [formGroup]="demoForm" (change)="uploadImage('dark_logo_media')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="dark_logo_media" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        cloud_upload
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row" style="height: 50px;"> <h5> Dark Banner Media (optional)</h5></div>
              <div class="image-placeholder">
                
                <div class="profile-image" >
                  <div class="profile-dummy-img" *ngIf="!isdarkbannerloading && !uploadedDarkBannermedia">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isdarkbannerloading"></mat-spinner>
                  <img *ngIf="!isdarkbannerloading && uploadedDarkBannermedia" class="image-data img-responsive"
                    [src]="s3url + dark_banner_media_image_path">
                </div>

                <form [formGroup]="demoForm" (change)="uploadImage('dark_banner_media')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="dark_banner_media" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        cloud_upload
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>
            </div>


            <div class="col-md-3">
              <div class="row" style="height: 50px;"> <h5>Dark Colored Logo (optional)</h5></div>
              <div class="image-placeholder">
                
                <div class="profile-image" >
                  <div class="profile-dummy-img" *ngIf="!isdarkcoloredloading && !uploadedDarkcoloredmedia">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isdarkcoloredloading"></mat-spinner>
                  <img *ngIf="!isdarkcoloredloading && uploadedDarkcoloredmedia" class="image-data img-responsive"
                    [src]="s3url + dark_colored_logo_media_path">
                </div>

                <form [formGroup]="demoForm" (change)="uploadImage('dark_colored_logo_media')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="dark_colored_logo_media" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        cloud_upload
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>
            </div>
            <!-- </div> -->

            <div class="col-md-3">
              <div class="row" style="height: 50px;"><h5> Dark Banner Media For Logging (Optional)</h5></div>
              <div class="image-placeholder">
               
                <div class="profile-image" >
                  <div class="profile-dummy-img" *ngIf="!isdarkcoloredloggingLight && !uploadedDarkLoggingLight">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .jpeg, .png, .jpg
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <mat-spinner class="upload-spinner" *ngIf="isdarkcoloredloggingLight"></mat-spinner>
                  <img *ngIf="!isdarkcoloredloggingLight && uploadedDarkLoggingLight" class="image-data img-responsive"
                    [src]="s3url + dark_banner_media_logging_light_image_path">
                </div>

                <form [formGroup]="demoForm" (change)="uploadImage('banner_media_logging_dark')" validate
                  [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="banner_media_logging_dark" simple accept=".jpeg,.png,.jpg"
                    class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        cloud_upload
                      </i>
                    </ng-template>
                  </file-upload>

                </form>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <!-- <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
            [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Carey Card</button> -->
              <button mat-flat-button color="primary" class="submit-btn">Update Branding</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  </div>

</mat-drawer-container>