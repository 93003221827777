import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.scss']
})

export class CreateRequestComponent implements OnInit {
  levelid: any;
  public requestForm = this.fb.group({
    title: ["", Validators.required],
    level_data_fk: [],
    created_by_data_fk: [],
    description: ["", Validators.required]
  });

  constructor(private browserTitle: Title, private router: Router, private route: ActivatedRoute, public fb: FormBuilder, private contentserviceService: ContentserviceService, private systemService: SystemService,private commonService:CommonserviceService) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Level Create Request');
  }

  ngOnInit(): void {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.levelid = params.id;
    })
  }

  // To Create Request
  // ----------------------------

  publishRequest() {
    var postdata = this.requestForm.value;
    let title = this.commonService.isHTML( this.requestForm.value.title);

    if(title  === true){
      this.requestForm.controls.title.patchValue('')
      return;
    }
    postdata.level_data_fk = this.levelid;
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    this.contentserviceService.createRequest(postdata)
    .subscribe(
      (res : any) => this.publishCreateSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private publishCreateSuccess() {
    this.router.navigate(['content/level']);
    this.systemService.showSnackBar("Publish Request has been created", "OK");
  }

  // ----------------------------

}
