<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-therapysetupleftnav></app-therapysetupleftnav>
    <div class="mainrightcontainersecondary">
      <div class="col-md-12">
        <h3 class="commontitleprimary" *ngIf="!newentry">
          <button mat-flat-button color="primary" class="create-btn"[routerLink]="['/content/onboarding/create', 'new']">
            Create New Onboarding Flow </button>
        </h3>
        <h3 class="commontitleprimary onboardingheader" *ngIf="newentry">Onboarding Flow</h3>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <form>
              <div class="form-group has-feedback has-search">
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="example-container">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
            <!-- Name Column -->
            <ng-container matColumnDef="client_id">
              <th mat-header-cell *matHeaderCellDef style="width: 100px;"> Client ID </th>
              <td mat-cell *matCellDef="let element" style="width: 100px;"> {{element.client_id==null?'-':element.client_id}} </td>
            </ng-container>
            <ng-container matColumnDef="client_name">
              <th mat-header-cell *matHeaderCellDef style="width: 150px;"> Client Name </th>
              <td mat-cell *matCellDef="let element" style="width: 150px;"> {{element.client_id==null?'No client':getClientName(element.client_id)}} </td>
            </ng-container>
            <ng-container matColumnDef="widget">
              <th mat-header-cell *matHeaderCellDef> Widget </th>
              <td mat-cell *matCellDef="let element"> {{getWidgetname(element.widgets)}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button disableRipple
                     tooltip ="Edit" 
                    class="no-hover-effect"
                    [routerLink]="['/content/onboarding/create', element.id]">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="">
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                        class="no-hover-effect" [routerLink]="['/content/onboarding/create', element.id]">Edit</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>

      </div>
    </div>
  </div>

</mat-drawer-container>