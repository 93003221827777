import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, delay, finalize } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { isString } from 'util';
import { SettingsService } from '../services/settings/settings.service';

declare var $: any;

@Component({
  selector: 'app-hcsettings',
  templateUrl: './hcsettings.component.html',
  styleUrls: ['./hcsettings.component.scss']
})
export class HcsettingsComponent implements OnInit {
  public userSettings: any;
  public settingForm: FormGroup;
  public configdata;
  public settingUpdateSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);/*true ti show submit button */

  constructor(private settingService: SettingsService,
    private browserTitle: Title,
    private systemService: SystemService, private formBuilder: FormBuilder) { }
    
  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Settings');
  }
  ngOnInit() {
    this.setSEOTags();
    /**Build form */
    this.buildSettingForm();
    /**Get user settings */
    this.getUserSettings();
  }

  getUserSettings() {
    this.settingService.getUserSettings().subscribe(data => {
      this.settingForm.patchValue(data);
    })
  }

  buildSettingForm() {
    this.settingForm = this.formBuilder.group({
      "hc_id_data_fk": [localStorage.getItem('scarletuserid')],
      "open_user_section": [0],
      "logout_on_no_activity": [0],
      "message_alerts": [0],
      "push_notifications": [0],
      "theme": ["light", Validators.required]
    });
  }

  changeFormValues(orginialData: any) {
    let convertedData = {};
    for (let key in orginialData) {
      let val = orginialData[key];
      if (val && !isString(val)) {
        convertedData[key] = "1";
      } else if (!val && !isString(val)) {
        convertedData[key] = "0";
      } else {
        convertedData[key] = val;
      }
    }
    return convertedData;
  }


  updateUserSettings(): void {
    this.settingUpdateSubject$.next(false);
    var settingdata;
    var postdata = this.settingForm.value;
    postdata.open_user_section = postdata.open_user_section ? 1 : 0;
    postdata.logout_on_no_activity = postdata.logout_on_no_activity ? 1 : 0;
    postdata.message_alerts = postdata.message_alerts ? 1 : 0;
    postdata.push_notifications = postdata.push_notifications ? 1 : 0;
    postdata.hc_id_data_fk = parseInt(localStorage.getItem('scarletuserid'));
    this.settingService.updateSettings(postdata).pipe(
      delay(2000),
      catchError((error: any) => {
        this.settingUpdateSubject$.next(true);
        this.systemService.showSnackBar("Sorry, unable to update settings.", "Ok");
        return throwError(error);
      }),
      finalize(() => { this.settingUpdateSubject$.next(true); })
    ).subscribe(data => {
      if (data) {
        this.settingUpdateSubject$.next(true);
        //this.updateLocalConfig();
        this.systemService.showSnackBar("Settings has been saved", "OK");

        this.configdata = localStorage.getItem('scarletuserconfig');
        this.configdata = JSON.parse(this.configdata);
        $('body').removeClass(this.configdata.theme);


        settingdata = JSON.stringify(this.settingForm.value);
        localStorage.setItem('scarletuserconfig', settingdata);
        $('body').addClass(this.settingForm.value.theme);
      } else {
        this.systemService.showSnackBar("Somthing went wrong", "OK");
      }
    });
  }

  // updateLocalConfig(): void {
  //   this.userSettings = this.changeFormValues(this.settingForm.value);
  //   /**Update setting now */
  //   this.settingService.updateUserSettings(this.userSettings);
  // }

}
