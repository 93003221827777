import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-reassign',
  templateUrl: './reassign.component.html',
  styleUrls: ['./reassign.component.scss']
})
export class ReassignComponent implements OnInit {
  public dataVal: any;
  public todaydate: any = new Date();
  public dataForm = this.fb.group({
    expiry_date: ['', Validators.required],
    has_expiry: [false],
    
    
  });
  constructor(public fb: FormBuilder,public commonService: CommonserviceService, @Inject(MAT_DIALOG_DATA) private data: any,private systemService: SystemService,public dialogRef: MatDialogRef<ReassignComponent>) { }

  ngOnInit(): void {
    this.dataVal = this.data
  }

  closeModal(): void {
    this.dialogRef.close();
  }
  // Submitting New Activation Code Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.expiry_date = postdata.expiry_date;
    postdata.has_expiry = postdata.has_expiry.toString();
    postdata.id = this.dataVal.toString();
    postdata.created_by_fk = localStorage.getItem('scarletuserid').toString();
    this.commonService.reassignTask(postdata)
    .subscribe(
      (res: any) => this.onReassignSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  private onReassignSuccess(){
    this.systemService.showSuccessSnackBar(("Task is Re-assigned successfully"), "OK");
    this.closeModal();
  }
}
