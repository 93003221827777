import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ReplaySubject } from 'rxjs/index';
import { ChangeEmailComponent } from 'src/app/admin/shared/change-email/change-email.component';
import { SUPPORTED_FILE_TYPES } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../../environments/environment';
import { whiteSpaceValidation } from '../../../../utility/validation';
import { ProfileimageComponent } from '../../../profileimage/profileimage.component';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';
import { CropImageComponent } from './crop-image/crop-image.component';

@Component({
  selector: 'app-createhc',
  templateUrl: './createhc.component.html',
  styleUrls: ['./createhc.component.scss']
})

export class CreatehcComponent implements OnInit {
  upload_type: any = upload_type;
  public chooseMedia: any;
  public allmedia: any;
  public newitem: boolean = false;
  public showButtonLoader: boolean = false;
  public userid: any;
  s3url: any = environment.s3url;
  public userdata: any;
  public dataForm = this.fb.group({
    name: ['', [Validators.required, whiteSpaceValidation]],
    phone: ['', Validators.required],
    profile_image: ['3', Validators.required],
    std_code: ['', Validators.required],
    email: ['', [Validators.required, this.validateEmail]],
    user_type: ['healthcoach'],
    user_role: ['healthcoach-role'],
    user_acl: [['healthcoach']],
    display_bio: ['', [Validators.required, this.noWhitespaceValidator]],
    gender: ['', Validators.required],
    display_name: ['', [Validators.pattern(/^((?!\s{2,}).)*$/)]],
    password: ['Wel123456789', Validators.required],
    city: [''],
    address: [''],
    speciality: [''],
    degree: [''],
    nickname: [''],
    doctor_type: [''],
    tc_accepted: [''],
    client_ids: [[]],
    whatsapp_number: ['']
  });
  private allfiles: any;
  public uploadedfiletype: any;
  private responsethumbUrl: any;
  public resdata: any;
  public image_path: any;
  public image_id: any;
  public imagepath: any;
  public uploadedparam: boolean;
  adddata: any;
  public image_data: any = {};
  public multiple: boolean = false;
  public isloading: boolean = false;
  public emptyList: any;
  public img_file_name: any;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  public isinvalid: boolean = false;
  private responseUrl: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public userData: any;
  public allclients: any;
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmatclient: FormControl = new FormControl();
  isNewItem:any;
  constructor(private browserTitle: Title, public fb: FormBuilder, public dialog: MatDialog,
    private router: Router, private route: ActivatedRoute, private commonService: CommonserviceService,
    private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<CreatehcComponent>
  ) { }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || ' ').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  validateEmail(control: FormControl) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(control.value)) {
      if (control.value.indexOf("@wellthy.care", control.value.length - "@wellthy.care".length) !== -1) {
        return null
      }
      else {
        return { 'validateEmail': true };
      }
    }
    else {
      return { 'validateEmail': true };
    }
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Health Coach');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getallclient();
    this.userData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.userData.user_acl == 'superadmin' ? true : false);

    this.isNewItem = this.data.element;
    

    if (this.isNewItem == 'newsection') {
      this.newitem = true;
    }
    else {
      this.newitem = false;
      this.userid = this.isNewItem;
      this.getUserData(this.isNewItem);
    }
    // this.route.params.subscribe(params => {
    //   if (params.id == 'new') {
    //     this.newitem = true;
    //   }
    //   else {
    //     this.newitem = false;
    //     this.userid = params.id;
    //     this.getUserData(params.id);
    //   }
    // })
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  fileChangeEvent(event: any): void {
    this.uploadedparam = false;
    this.imageChangedEvent = event;
    this.adddata = this.dialog.open(CropImageComponent, {
      width: '720px',
      height: '450px',
      data: {
        dataKey: this.imageChangedEvent,
      }
    });
    this.adddata.afterClosed().subscribe((result) => {
      if (result && result.data) {
        this.allfiles = [result.data];
        this.uploadImage();
      }
      else {
        this.getMedia(this.userdata.profile_image);
        this.systemService.showSnackBar("Not uploaded", "Ok");
      }
    });
  }


  // To Upload Image 
  // ----------------------------

  uploadImage() {
    if (this.newitem == false) {
      this.uploadedparam = false;
    }
    if (this.demoForm.status == 'INVALID') {
      this.isinvalid = true;
      this.systemService.showSnackBar("Your filetype is not supported. The Supported formats are .jpeg,.png,.jpg ", "Ok");
    }
    else if (this.imageChangedEvent.target.files[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      // this.allfiles = this.demoForm.value.files;
      this.uploadedfiletype = (this.imageChangedEvent.target.files[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.dataForm.value.profile_image == this.image_id) {
            this.uploadedparam = true;
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloading = true;
              scope.uploadedparam = false;
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        }
                      )
                  }
                );
            }
          }
        });
      }
    }
  }

  // ----------------------------


  // To Upload Thumbnail 
  // ----------------------------

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;

    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          scope.commonService.getS3URL('user_profile', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, thumbpath, imageinfo) {
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, upload_type[0], 'document')
        .subscribe(
          (data) => {
            scope.resdata = data;
            scope.image_path = scope.resdata.data.path;
            scope.uploadedparam = true;
            scope.isloading = false;
            if (scope.resdata.status == 201) {
            }
            else {
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          })
    }
  }

  // ----------------------------


  // To Convert DataURI to Blob
  // ----------------------------

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  // ----------------------------



  // To Get User Data
  // ----------------------------

  getUserData(ide) {
    this.commonService.getOneDataById(ide, "users")
      .subscribe(
        (res: any) => this.onUserDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onUserDataSuccess(data) {
    data = data.data;
    this.userdata = data;
    this.userdata.std_code = this.userdata.std_code.toString();
    this.dataForm.patchValue(this.userdata);
    this.getMedia(this.userdata.profile_image);
  }

  // ----------------------------


  // To Get Media 
  // ----------------------------

  getMedia(id) {
    this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
      .subscribe(
        (res: any) => this.onMediaDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onMediaDataSuccess(data) {
    data = (data as any).data;
    this.image_path = data.getOneDocumentMedia.path;
    this.image_id = data.getOneDocumentMedia.id;
    this.uploadedparam = true;
  }

  // ----------------------------

  getallclient() {
    this.commonService.getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.onClientSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onClientSuccess(data) {
    data = (data as any).data;
    this.allclients = data.getAllClients;
    this.filteredClient.next(this.allclients.slice());
  }

  // ----------------------------
  modelChangedClient() {
    this.filterClients();
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // Submitting Health Coach Data
  // ----------------------------

  dataObject() {
    // this.dataForm.markAllAsTouched();
    if (this.resdata) {
      this.dataForm.value.profile_image = this.resdata.data.id;
    }
    var postdata = this.dataForm.value;
    let name = this.commonService.isHTML(this.dataForm.value.name);
    let phone = this.commonService.isHTML(this.dataForm.value.phone);
    let std_code = this.commonService.isHTML(this.dataForm.value.std_code);
    let email = this.commonService.isHTML(this.dataForm.value.email);
    let display_name = this.commonService.isHTML(this.dataForm.value.display_name);
    let display_bio = this.commonService.isHTML(this.dataForm.value.display_bio);
    let whatsapp_number = this.commonService.isHTML(this.dataForm.value.whatsapp_number);
    let password = this.commonService.isHTML(this.dataForm.value.password);


    if(name  === true){
      this.dataForm.controls.name.patchValue('')
      return;
    }
    if(phone  === true){
      this.dataForm.controls.phone.patchValue('')
      return;
    }
    if(std_code  === true){
      this.dataForm.controls.std_code.patchValue('')
      return;
    }
    if(email  === true){
      this.dataForm.controls.email.patchValue('')
      return;
    }
    if(display_bio  === true){
      this.dataForm.controls.display_bio.patchValue('')
      return;
    }
    if(display_name  === true){
      this.dataForm.controls.display_name.patchValue('')
      return;
    }
    if(whatsapp_number  === true){
      this.dataForm.controls.whatsapp_number.patchValue('')
      return;
    }
    if(password  === true){
      this.dataForm.controls.password.patchValue('')
      return;
    }

    postdata.name = postdata.name.toString();
    postdata.phone = postdata.phone.toString();
    postdata.password = postdata.password.toString();
    postdata.std_code = postdata.std_code.toString();
    postdata.email = postdata.email.toString();
    postdata.display_bio = postdata.display_bio.toString();
    postdata.gender = postdata.gender.toString();
    postdata.display_name = postdata.display_name.toString();
    postdata.user_type = postdata.user_type.toString();
    postdata.user_role = postdata.user_role.toString();
    postdata.user_acl = JSON.stringify(postdata.user_acl);
    postdata.tc_accepted = postdata.tc_accepted.toString();
    postdata.profile_image = this.dataForm.value.profile_image.toString();
    postdata.client_ids = JSON.stringify(postdata.client_ids);
    postdata.whatsapp_number = postdata.whatsapp_number?.toString();
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    
    if (!this.newitem) {
      delete postdata.password;
      delete postdata.email;
    }
    if (this.hasWhiteSpace(this.dataForm.value.password) == false) {
      if (this.newitem == true) {
        this.commonService.postToBlade(postdata, 'users')
          .subscribe(
            (res: any) => this.onHCCreateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
      else {
        this.commonService.updateToBlade(this.userid, postdata, 'users')
          .subscribe(
            (res: any) => this.onHCUpdateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
    }
    else {
      if (this.hasWhiteSpace(this.dataForm.value.password) == true) {
        this.systemService.showSnackBar("Enter valid password, Your password should not be empty or contain spaces", "OK");
      }
    }
  }

  private onHCCreateSuccess() {
    this.systemService.showSuccessSnackBar(("New healthcoach has been created"), "OK");
    this.dialogRef.close();

    // this.router.navigate(['admin/hchome']);
  }

  private onHCUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Healthcoach data has been updated"), "OK");
    this.dialogRef.close();

    // this.router.navigate(['admin/hchome']);
  }

  // ----------------------------

  changeEmail(element): void {
    const profile = this.dialog.open(ChangeEmailComponent, {
      width: '650px',
      data: {
        id: this.userid,
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
      this.getUserData(this.userid);
    });
  }


  closeModal(): void {
    this.dialogRef.close();
  }

}

export enum upload_type {
  patient_profile
}
