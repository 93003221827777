import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docpatientgeneratedcode',
  templateUrl: './docpatientgeneratedcode.component.html',
  styleUrls: ['./docpatientgeneratedcode.component.scss']
})
export class DocpatientgeneratedcodeComponent implements OnInit {
  public imgurl : any;
  constructor() { }

  ngOnInit() {
    this.imgurl = "https://chart.googleapis.com/chart?cht=qr&chl=campaign_id:"+'0125'+"&chs=232x232&choe=UTF-8&chld=H%7C2";
  }

}
