<div *ngIf="userView.user_type!='doctor'" class="profile-wrapper">
  <div class="profile-info clearfix">
    <div class="media">
      <div class="media-left media-middle" *ngIf="image_path != null">
        <div class="image-holder">
          <img class="media-object" [src]="s3url + image_path" alt="Wellthy">
        </div>
      </div>
      <div class="media-body media-middle">
        <h4 class="media-heading text-capitalize">{{userView.name}}</h4>
        <p>{{userView.email}}</p>
        <!-- <div class="profile-content">{{userView.email}}</div> -->
      </div>
    </div>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        <img src="assets/img/dls/icons/cross.svg" alt="">
      </i>
    </div>
  </div>

  <div class="hc-details">
    <div class="text-over">{{userView.user_type}} details</div>
    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5>Phone Number</h5>
          <p>+{{userView.std_code}}-{{userView.phone}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="location">
          <h5>Gender</h5>
          <p>{{gender(userView.gender)}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="email">
          <h5>Role</h5>
          <p>{{userView.user_type}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="about-me mt-30">
          <h5>About me</h5>
          <p>{{userView.display_bio}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- doctor details -->
<div *ngIf="userView.user_type=='doctor'" class="profile-wrapper">
  <div class="profile-info clearfix">
    <div class="media">
      <div class="media-left media-middle" *ngIf="image_path != null">
        <div class="image-holder">
          <img class="media-object" [src]="s3url + image_path" alt="Wellthy">
        </div>
      </div>
      <div class="media-body media-middle">
        <h4 class="media-heading text-capitalize">{{userView.name}}</h4>
        <p *ngIf="userView.degree">{{userView.degree}}</p>
        <p *ngIf="!userView.degree">-</p>
        <!-- <div class="profile-content">{{userView.email}}</div> -->
      </div>
    </div>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        <img src="assets/img/dls/icons/cross.svg" alt="">
      </i>
    </div>
  </div>

  <div class="hc-details1">
    <div class="text-over1">{{userView.user_type}} details</div>
    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5>Phone Number</h5>
          <p>+{{userView.std_code}}-{{userView.phone}}</p>
        </div>
      </div>


      <div class="col-md-4">
        <div class="location">
          <h5>Gender</h5>
          <p>{{gender(userView.gender)}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="email">
          <h5>Email</h5>
          <p>{{userView.email}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5>Type</h5>
          <p>{{userView.doctor_type}}</p>
        </div>
      </div>


      <div class="col-md-4">
        <div class="location">
          <h5>Campaign</h5>
          <p *ngIf="userView.Campaign">{{userView.Campaign}}</p>
          <p *ngIf="!userView.Campaign">-</p>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="about-me mt-30">
          <h5>About me</h5>
          <p>{{userView.display_bio}}</p>
        </div>
      </div>
    </div>
  </div>
</div>