<div class="row" *ngIf="!uploading">

    <div class="heading-wrapper">
        <div class="col-md-10">
            <p class="title">Media Manager</p>
            <p class="sub-title">Upload your images, audios and videos, over here. A maximum of 5 files, can be uploaded at a time.</p>
        </div>

        <div class="col-md-2">
            <div class="close-icon-wrapper">
                <div class="close-icon" mat-dialog-close></div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <form [formGroup]="demoForm" (ngSubmit)="uploadObject()" validate>
            <file-upload class="fileupload" formControlName="files" accept=".jpeg,.png,.jpg,.mp3,.wav,.m4a,.mp4,.mov,.webm,.avi,">

                <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                    <span *ngIf="isFileDragDropAvailable">
                        <div class="add-file" [ngClass]="previewData.length > 0 ? 'pos-top-12': ''">
                            
                            <div class="upload-icon"></div>
                            <span class="add-file-text">Add file</span>
                        </div>
                    </span>
                    <span *ngIf="!isFileDragDropAvailable">click</span>
                </ng-template>

                <ng-template let-i="index" let-file="file" let-control="control" #item>
                    <div class="media-wrapper">
                        <ng-container *ngIf="previewData[i].type == 'image'">
                            <img [src]="previewData[i].url" width="120" height="120" alt="{{file.name}}">
                            <div class="remove-media" (click)="control.removeFile(file)"></div>
                        </ng-container>
                        
                        <ng-container *ngIf="previewData[i].type == 'audio'">
                            <div class="media-cont">
                                <mat-icon>library_music</mat-icon>
                                <div>{{file.name}}</div>
                            </div>
                            <div class="remove-media" (click)="control.removeFile(file)"></div>
                        </ng-container>

                        <ng-container *ngIf="previewData[i].type == 'video'">
                            <div class="media-cont">
                                <mat-icon>video_library</mat-icon>
                                <div>{{file.name}}</div>
                            </div>
                            <div class="remove-media" (click)="control.removeFile(file)"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </file-upload>

            <div class="options-wrapper">
                <p class="error-msg" *ngIf="isinvalid">You are trying to upload a media more than the recommended size (see below) | You can upload maximum 5 at a time </p>
                <p class="uploadguidance">
                    <strong>Image</strong> (.jpeg,.png,.jpg) ~5 MB &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                    <strong>Audio</strong> (.mp3,.wav,.m4a) ~10 MB &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                    <strong>Video</strong> (.mp4,.mov,.webm,.avi) ~20 MB.
                </p>
                <button mat-flat-button color="primary" class="upload-btn" [disabled]="this.demoForm.value.files == null || this.demoForm.value.files.length == 0 || this.demoForm.value.files.length >5 ">Upload Files</button>
            </div>
        </form>
    </div>
</div>  

<div class="uploading-media" *ngIf="uploading">
    Uploading media
</div>