import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService extends HttpService{
  public sessionID: string;
  public apiUrl: string = environment.serverUrl;
  private profileUrl = this.apiUrl + 'broadcast/forHC';
  private broadcastdelurl = this.apiUrl + 'broadcast';
  private sendsmsurl = this.apiUrl + 'sms/send/broadcast';

  constructor(private http: HttpClient, private systemService : SystemService) {
    super(http)
  }

  public getAllBrodcast(): Observable<any> {
    return this.get(this.profileUrl+'/'+localStorage.getItem('scarletuserid'))
  }

  public deleteBrodcast(brodcastID: string): Observable<any> {
    return this.delete(this.broadcastdelurl + '/' + brodcastID)
  }

  public createBrodcast(brodcastData: string): Observable<any> {
    return this.post(this.broadcastdelurl, brodcastData)
  }

  public sendSMS(phone,message,hc_id_data_fk): Observable<any> {
    phone = this.removeHTMLtags(phone);
    let data = {
      "number_list" : phone,
      "message" : message,
      "hc_id_data_fk":hc_id_data_fk,
    }
    return this.post(this.sendsmsurl, data)
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
