import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { PersistenceModule } from 'angular-persistence';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
/* Time Picker Imports */
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OrderModule } from 'ngx-order-pipe';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxTextDiffModule } from 'ngx-text-diff';
import { FilterlangPipe } from '../pipes/filterlanguage/filterlang.pipe';
import { DaysagoPipe } from './../pipes/daysago/daysago.pipe';
import { HtmltrimPipe } from './../pipes/htmltrim/htmltrim.pipe';
import { KeysPipe } from './../pipes/keys/keys.pipe';
import { TruncatePipe } from './../pipes/truncate/truncate.pipe';
import { TypefilterPipe } from './../pipes/typefilter/typefilter.pipe';
import { BreadcrumbComponent } from './../utility/breadcrumb/breadcrumb.component';
// import {
//   LoadingComponent,
//   ErrorComponent,
//   BackButtonComponent,
//   ContactUsModalComponent,
//   DateTimePickerComponent,
//   FilePickerComponent
// } from './components';
import { BackbuttonComponent } from './components';
import { TableComponent } from './components/table/table.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';

//import { DocleftnavComponent } from '../doctor/shared/docleftnav/docleftnav.component';
//import { DocuserlistnavbarComponent } from '../doctor/components/docuserlistnavbar/docuserlistnavbar.component';

const PRIVATE_MODULES = [
];
const MODULES = [
  CommonModule,
  FormsModule,
  NgxDaterangepickerMd.forRoot(),
  CdkTableModule,
  NgxTextDiffModule,
  ReactiveFormsModule,
  RouterModule,
  MatDialogModule,
  MatTableModule,
  MatAutocompleteModule,
  MatCardModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatIconModule,
  MatTabsModule,
  MatMenuModule,
  MatGridListModule,
  MatSidenavModule,
  MatSliderModule,
  MatButtonModule,
  MatBadgeModule,
  MatButtonToggleModule,
  MatSnackBarModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatStepperModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatListModule,
  MatSortModule,
  MatBottomSheetModule,
  NgxQRCodeModule,
  InfiniteScrollModule,
  MatRippleModule,
  NgxMaterialTimepickerModule,
  MatDividerModule,
  MatTooltipModule,
  MatExpansionModule,
  PersistenceModule,
  ScrollingModule,
  Ng2SearchPipeModule,
  NgxMatSelectSearchModule,
  MatTreeModule,
  DragDropModule,
  FileUploadModule,
  OrderModule,
  LMarkdownEditorModule,
  TooltipModule,
  MatSelectInfiniteScrollModule,
  MatSelectModule
];
const PRIVATE_COMPONENTS = [
];
const COMPONENTS = [
  BreadcrumbComponent,
  //DocleftnavComponent,
  //DocuserlistnavbarComponent,
  BackbuttonComponent
];
const NATIVE_PIPES = [
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  { provide: MAT_DIALOG_DATA, useValue: {} }
];
const PIPES = [
  KeysPipe,
  DaysagoPipe,
  FilterlangPipe,
  TypefilterPipe,
  HtmltrimPipe,
  TruncatePipe,
];
const DIRECTIVES = [];
const ENTRY_COMPONENTS = [];
@NgModule({
  exports: [...MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  imports: [...PRIVATE_MODULES, ...MODULES],
  entryComponents: ENTRY_COMPONENTS,
  declarations: [...PRIVATE_COMPONENTS, ...COMPONENTS, ...DIRECTIVES, ...PIPES, TableComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [...NATIVE_PIPES, ...PIPES]
    };
  }
}