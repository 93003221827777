import { CommonserviceService } from './../../../admin/services/commonservice/commonservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { ReplaySubject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

@Component({
  selector: 'app-medicineadd',
  templateUrl: './medicineadd.component.html',
  styleUrls: ['./medicineadd.component.scss']
})

export class MedicineaddComponent implements OnInit {
  public dataForm = this.fb.group({
    title: ['', [Validators.required]],
    client_ids: [[]],
    comorbilities: [[], Validators.required],
    type: ['', [Validators.required]],
    icon_media: ['', Validators.required],
    hindi : [''],
    bangla : [''],
    kannada : [''],
    spanish: ['']
  });
  public hindi: any;
  public bangla: any;
  public kannada: any;
  chooseMedia: any;
  public newitem: boolean = false;
  public allclients: any;
  allquestionnair: any;
  allconditions: any;
  mappingData: any;
  private myhash: any = {};
  public allmedia: any;
  questionannaring_mapping_id: any;
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredQuestionnair: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public showButtonLoader: any;
  public searchmatclient: FormControl = new FormControl();
  public searchmatquestionnair: FormControl = new FormControl();
  formdata: any;
  imageData: any;

  @ViewChild('singleSelectClient', { static: false }) singleSelectClient: MatSelect;

  constructor(private browserTitle: Title, private router: Router, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute,private commonService:CommonserviceService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Medicne');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      if (params.id == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        this.questionannaring_mapping_id = params.id;
        this.getMappingData(params.id);
      }
    })
    this.getClients();
    this.getConditions();
  }

  modelChangedClient() {
    this.filterClients();
  }



  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['icon_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }


  // Get Media
  // -----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe(
        (res: any) => this.onGetMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }
  // -----------------------------


  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  getMappingData(id) {
    this.contentService.getmedDataById(id, "medicinesearch")
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
          return throwError(error);
        }),
        finalize(() => { this.showButtonLoader = false; })
      ).subscribe(
        (data) => {
          data = (data as any).data;
          this.formdata = data;
          this.formdata.icon_media = this.formdata.iconMediaId;
          this.dataForm.patchValue(this.formdata);
          var language_translation = this.formdata.language_translation
          this.dataForm.controls['hindi'].setValue(language_translation[0].title);
          this.dataForm.controls['kannada'].setValue(language_translation[1].title);
          this.dataForm.controls['bangla'].setValue(language_translation[2].title);
          this.dataForm.controls['spanish'].setValue(language_translation[3].title)
          this.getMedia(this.formdata.icon_media);

        });
  }


  // Get all Conditions
  // ----------------------------

  getConditions() {
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetConditionsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetConditionsSuccess(data) {
    this.allconditions = data.getAllConditions;
    this.allconditions.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }


  // Get all Clients
  // ----------------------------


  getClients() {
    this.contentService
      .getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.getClientsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getClientsSuccess(data) {
    this.allclients = data.getAllClients;
    this.filteredClient.next(this.allclients.slice());
  }
  // -----------------------------


  // ----------------------------


  // Submitting Tag Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let language_translation = this.commonService.isHTML( this.dataForm.value.language_translation);
    if(title  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    } 
    if(language_translation  === true){
      this.dataForm.controls.language_translation.patchValue('')
      return;
    } 
   
    postdata.client_ids = JSON.stringify(postdata.client_ids);
    postdata.comorbilities = JSON.stringify(postdata.comorbilities);
    postdata.title = postdata.title.toString();
    postdata.type = postdata.type.toString();
    postdata.icon_media = postdata.icon_media.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_translation = JSON.stringify([{"id":"2","title":postdata.hindi},{"id":"3","title":postdata.kannada},{"id":"4","title":postdata.bangla},{"id":"5","title":postdata.spanish}])
    delete postdata.hindi;
    delete postdata.kannada;
    delete postdata.bangla;
    delete postdata.spanish;
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'medicinelist')
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      this.contentService
        .updateMappingToBlade(postdata, this.questionannaring_mapping_id, 'medicinelist')
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Medicine creation has been done"), "OK");
    this.router.navigate(['/content/medicine']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Medicine has been updated"), "OK");
    this.router.navigate(['content/medicine'])
  }

  // ----------------------------

}