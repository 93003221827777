import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-viewtask',
  templateUrl: './viewtask.component.html',
  styleUrls: ['./viewtask.component.scss']
})
export class ViewtaskComponent implements OnInit {
  displayedColumns = ['created_at', 'action_type', 'created_by_name'];
  dataSource:any;
  public showloader: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  // dataSource: any;
  public dataVal: any;
  public isactive = 'all';
  activecls = 'active';
  public taskdata:any;
  // displayedColumns = ['created_at', 'action_type', 'created_by_name'];

  conditions: any;
  id: any;
  description: any;
  isTemplate!: boolean;
  templateData: any = [];
  taskDetails: any;
  private myCathash: any = {};
  public template: FormArray;
  public newitem: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  thirdFormGroup: FormGroup;
  constructor(public dialogRef: MatDialogRef<ViewtaskComponent>,
    private _formBuilder: FormBuilder,
    private fb: FormBuilder,
    private contentService: ContentserviceService,
    private systemService: SystemService,public commonService: CommonserviceService,@Inject(MAT_DIALOG_DATA) private data: any) { }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    this.dataVal = this.data
    
    this.getAllTaskData();
    this.search();
    this.thirdFormGroup = this._formBuilder.group({
      template: this.fb.array([
        // this.templateFormSelection(this.language),
      ]),
    });
    this.getLanguages();
  }


  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name,short_code}}')
      .subscribe((res: any) => {
        this.getAllLanguageSuccess((res as any).data)
        

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    // this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
    this.primarylanguage.forEach(element => {
      this.myCathash[element.id] = element.name;
    });
    if (this.newitem == true) {
      this.addTab(this.alllanguages[0])
    } else {
      this.alllanguages = this.alllanguages;
    }

  }

  templateFormSelection(lang): FormGroup {
    return this.fb.group({
      "id": [lang.id],
      "description": [''],
      "name": [lang.name]
    })
  }

  selected = new FormControl(0);

  addTab(lang) {
    // this.tabs.push(lang);
    // 
    this.template = this.thirdFormGroup.get('template') as FormArray;
    this.template.push(this.templateFormSelection(lang));

    if (lang) {
      this.selected.setValue(this.alllanguages.length - 1);
    }
    this.removeSelectedLang(lang.id)
  }


  removeSelectedLang(id) {
    this.alllanguages = this.alllanguages.filter((item) => item.id !== id)
  }


  closeModal(): void {
    this.dialogRef.close();
  }

  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ','+ ' ' + year ;
    }
  }

  // To get one task details

  getAllTaskData() {
    this.commonService.getOneTaskData(this.dataVal)
      .subscribe(
        (res: any) => this.getOneTaskSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

 
  private getOneTaskSuccess(data) {
    data = data.data;
    this.taskDetails = data;
    
    this.templateData = this.taskDetails.template;
    
    this.templateData.sort(function (a, b) {
      
    }).sort(function (a, b) {
      
    });
    
    if (this.templateData.length == 0) {
      this.isTemplate = true
    }
    for (var i = 0; i < this.templateData.length; i++) {
      if (this.templateData[i].id == undefined) {
        this.isTemplate = true
      }
      if (this.templateData[i].id == '1') {
        let objIndex = this.templateData.findIndex((obj => obj.id == 1));
        this.templateData[objIndex].id = "English";
      }
      if (this.templateData[i].id == '2') {
        let objIndex1 = this.templateData.findIndex((obj => obj.id == 2));
        this.templateData[objIndex1].id = "हिन्दी";
      }
      if (this.templateData[i].id == '3') {
        let objIndex2 = this.templateData.findIndex((obj => obj.id == 3));
        this.templateData[objIndex2].id = 'ಕನ್ನಡ';
      }
      if (this.templateData[i].id == '4') {
        let objIndex3 = this.templateData.findIndex((obj => obj.id == 4));
        this.templateData[objIndex3].id = 'বাংলা';
      }
      if (this.templateData[i].id == '5') {
        let objIndex4 = this.templateData.findIndex((obj => obj.id == 5));
        this.templateData[objIndex4].id = 'Español';
      }
    }
    let data1 = {
      id: this.id,
      description: this.description
    }
    this.templateData.push(data1);

    
    this.conditions = data.filter_conditions_summary

  }
   //  Task List
  // ----------------------------
  

  search() {
   var bodyData = {
     "id":this.dataVal.toString(),
     "page":this.page.toString(),
     "limit":"10",
     "hc_id":""
   }
    this.showloader = true;
    this.commonService.TaskPost(bodyData, 'taskHistory')
      .subscribe(
        (res: any) => this.onTaskListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTaskListSuccess(data) {
    data = data.data;
    this.tabulardata = data;
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }


}



