import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { PersistenceService, StorageType } from 'angular-persistence';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { setDarkTheme } from 'src/app/utility/utils';
import { environment } from '../../../environments/environment';
import { ContentserviceService } from '../services/contentservice/contentservice.service';

@Component({
  selector: 'app-cprofile',
  templateUrl: './cprofile.component.html',
  styleUrls: ['./cprofile.component.scss']
})
export class CprofileComponent implements OnInit {
  public showButtonLoader: boolean = false;
  error: boolean = false;
  public userData: any;
  public message: any;
  s3url: any = environment.s3url;
  public ide: any;
  public dataForm = this.fb.group({
    new_password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  },
    { validator: this.checkPasswords }
  );

  isDark = sessionStorage.getItem('dark');
  public isThemeDark = false;

  constructor(private browserTitle: Title, private persistenceService: PersistenceService, private authService: AuthenticateService,
    private systemService: SystemService, public fb: FormBuilder, private contentService: ContentserviceService,
  ) { }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Profile Page');
  }

  ngOnInit() {
    this.setSEOTags();
    this.userData = JSON.parse(localStorage.getItem('scarletuserdata'));
    if(this.isDark == 'true'){
      this.isThemeDark = true;
    }else{
      this.isThemeDark = false;
    }
  }


  // Logout From The app 
  // ----------------------------

  logoutAccount(): void {
    this.persistenceService.removeAll(StorageType.SESSION);
    this.authService.logout()
    .subscribe(
      (res: any) => this.logoutSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private logoutSuccess() {
    this.systemService.showSuccessSnackBar(("Logout Successfully"), "OK");
    sessionStorage.clear();
    window.location.reload();
  }

  // ----------------------------

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }


  // To Change Password 
  // ----------------------------

  changePassword() {
    var postdata = this.dataForm.value;
    delete postdata['confirmPassword'];
    this.error = false;
    if (this.hasWhiteSpace(this.dataForm.value.new_password) == false) {
      this.contentService
        .updatePassword(this.userData.id, postdata)
        .subscribe(
          (res: any) => this.changePasswordSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.error = true;
      this.message = "Enter valid password, Your password should not be empty or contain spaces";
    }
  }

  private changePasswordSuccess() {
    this.systemService.showSuccessSnackBar(("Password has been updated"), "OK");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  // ----------------------------

  toggleDarkTheme(checked) {
    setDarkTheme(checked);
    
  }  

}
