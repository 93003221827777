import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ChoosemediamodalComponent } from 'src/app/content/mediamanager/choosemediamodal/choosemediamodal.component';
import { FormPage } from 'src/app/shared/classes';
import { SUPPORTED_FILE_TYPES } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { environment } from '../../../../environments/environment';
import { ProfileimageComponent } from '../../profileimage/profileimage.component';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { ChangeEmailComponent } from '../../shared/change-email/change-email.component';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})

export class EdituserComponent extends FormPage implements OnInit {
  upload_type: any = upload_type;
  public chooseMedia: any;
  public allmedia: any;
  private responseUrl: any;
  public newitem: boolean = false;
  public userid: any;
  public userdata: any;
  public isinvalid: boolean = false;
  private allfiles: any;
  public uploadedfiletype: any;
  private responsethumbUrl: any;
  public resdata: any;
  public image_path: any;
  public image_id: any;
  s3url: any = environment.s3url;
  public uploadedparam: boolean = false;
  public isloading: boolean = false;
  public emptyList: any;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  public userData: any;
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  img_file_name: any;
  public isNewItem: any;
  constructor(protected el: ElementRef<HTMLElement>, private browserTitle: Title,
    public fb: FormBuilder, public dialog: MatDialog, private router: Router,
    private commonService: CommonserviceService,
    private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EdituserComponent>) {
    super(el)
  }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update User');
  }

  initForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, whiteSpaceValidation]],
      phone: ['', Validators.required],
      profile_image: [''],
      std_code: ['', Validators.required],
      email: ['', [Validators.required, super.validateEmail]],
      user_type: ['', Validators.required],
      user_role: [''],
      user_acl: this.fb.array([]),
      display_bio: ['', [Validators.required, whiteSpaceValidation]],
      gender: ['', Validators.required],
      display_name: ['', [Validators.required, whiteSpaceValidation]],
      password: ['Wel123456789', Validators.required],
      user_acl_privileges: [null]
    });
  }

  ngOnInit() {
    this.setSEOTags();
    super.ngOnInit();
    this.userData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.userData.user_acl == 'superadmin' ? true : false);

    this.isNewItem = this.data.element;


    if (this.isNewItem == 'newsection') {
      this.newitem = true;
    }
    else {
      this.newitem = false;
      this.userid = this.isNewItem;
      this.getUserData(this.isNewItem);
    }

    // this.route.params.subscribe(params => {
    //   if (params.id == 'new') {
    //     this.newitem = true;
    //   }
    //   else {
    //     this.newitem = false;
    //     this.userid = params.id;
    //     this.getUserData(params.id);
    //   }
    // })

  }

  // ngAfterViewInit(): void {
  //   this.form = this.fb.group({
  //     name: ['', [Validators.required, whiteSpaceValidation]],
  //     phone: ['', Validators.required],
  //     profile_image: [''],
  //     std_code: ['', Validators.required],
  //     email: ['', [Validators.required, super.validateEmail]],
  //     user_type: ['', Validators.required],
  //     user_role: [''],
  //     user_acl: this.fb.array([]),
  //     display_bio: ['', [Validators.required, whiteSpaceValidation]],
  //     gender: ['', Validators.required],
  //     display_name: ['', [Validators.required, whiteSpaceValidation]],
  //     password: ['Wel123456789', Validators.required],
  //     user_acl_privileges: [null]
  //   });
  // }
  // To Upload Image
  // ----------------------------
  handleChange(files: FileList) {

    if (this.newitem == false) {
      this.uploadedparam = false;
    }

    else if (files[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = files;
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.form.value.profile_image == this.image_id) {
            this.uploadedparam = true;
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloading = true;
              scope.uploadedparam = false;
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        })
                  });
            }
          }
        });
      }
    }
    // if (files && files.length) {
    //   this.file = files[0].name;
    // }
  }
  uploadImage() {

    if (this.newitem == false) {
      this.uploadedparam = false;
    }
    if (this.demoForm.status == 'INVALID') {
      this.isinvalid = true;
      this.systemService.showSnackBar("Your filetype is not supported. The Supported formats are .jpeg, .png, .jpg ", "Ok");
    }
    else if (this.demoForm.value.files[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = this.demoForm.value.files;
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.form.value.profile_image == this.image_id) {
            this.uploadedparam = true;
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloading = true;
              scope.uploadedparam = false;
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        })
                  });
            }
          }
        });
      }
    }
  }
  uploadImage1() {

    if (this.demoForm.status == 'INVALID') {
      this.isinvalid = true;
      this.systemService.showSnackBar("Your filetype is not supported. The Supported formats are .jpeg,.png,.jpg ", "Ok");
    }
    else if (this.demoForm.value.logo_media[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = this.demoForm.value.logo_media;
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {

          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        }
                      )
                  }
                );
            }
          }
        });
      }
    }
  }

  // ----------------------------


  // To Upload Thumbnail
  // ----------------------------

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          }
          else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }
          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          scope.commonService.getS3URL('user_profile', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, imageinfo);
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    }
                  )
              })
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }
    function saveIttoDB(path, size, type, imageinfo) {
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, upload_type[0], 'document')
        .subscribe(
          (data) => {

            // data = (data as any).data;
            scope.resdata = data;

            scope.image_path = scope.resdata.data.path;
            scope.uploadedparam = true;
            scope.isloading = false;
            if (scope.resdata.status == 201) {
            }
            else {
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          }
        )
    }
  }

  // ----------------------------


  // Convert dataURI to Blob
  // ----------------------------

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  // ----------------------------


  // Get User List
  // ----------------------------

  getUserData(ide) {
    this.commonService.getOneDataById(ide, "users")
      .subscribe(
        (res: any) => this.getUser(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private getUser(data) {
    data = (data as any).data;
    this.userdata = data;
    this.userdata.std_code = this.userdata.std_code.toString();
    this.form.patchValue(this.userdata);
    this.getMedia(this.userdata.profile_image);
  }

  // ----------------------------


  // Get Media 
  // ----------------------------

  getMedia(id) {
    this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
      .subscribe(
        (res: any) => this.getMediaData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private getMediaData(data) {
    data = (data as any).data;
    this.image_path = data.getOneDocumentMedia.path;
    this.image_id = data.getOneDocumentMedia.id;
    this.uploadedparam = true;
  }

  // ----------------------------


  // Open Media Modal Box
  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.form.controls['profile_image'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }

  // ----------------------------


  //  Submitting User Data 
  // ----------------------------

  dataObject() {
    var postdata = super.submit()
    if (this.resdata) {
      this.form.value.profile_image = this.resdata.data.id;
      postdata.profile_image = this.resdata.data.id;
    }
    let name = this.removeHTMLtags(postdata.name);
    let display_bio = this.removeHTMLtags(postdata.display_bio);
    let display_name = this.removeHTMLtags(postdata.display_name);
    let phone = this.removeHTMLtags(postdata.phone);
    var user_access_control = [];
    
    postdata.name = name.toString();
    postdata.phone = phone.toString();
    postdata.std_code = postdata.std_code.toString();
    postdata.email = postdata.email.toString();
    postdata.display_bio = display_bio.toString();
    postdata.gender = postdata.gender.toString();
    postdata.display_name = display_name.toString();
    postdata.user_type = postdata.user_type.toString();
    postdata.user_role = (postdata.user_type + "-role").toString();
    user_access_control.push(postdata.user_type);
    postdata.user_acl = JSON.stringify(user_access_control);
    postdata.profile_image = postdata.profile_image.toString();
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');

    if (postdata.user_type == 'scarlet') {
      postdata.user_acl_privileges = '{"show_only_self_created_doctors": true,"only_show_doctor_screen":true}'
    }
    if (!this.newitem) {
      delete postdata.password;
      delete postdata.email;
    }

    if (super.hasWhiteSpace(this.form.value.password) == true) {
      this.systemService.showSnackBar("Enter valid password, Your password should not be empty or contain spaces", "OK");
      return;
    }

    if (this.newitem == true) {
      this.commonService.postToBlade(postdata, 'users')
        .subscribe(
          (res: any) => this.onSuccessCreate(),
          (err: HttpErrorResponse) => 
          {
            this.systemService.handleErrorResponse(err)
          super.onSubmissionError(err)
          }
        );
    }
    else {
      this.commonService.updateToBlade(this.userid, postdata, 'users')
        .subscribe(
          (res: any) => this.onSuccessUpdate(),
          (err: HttpErrorResponse) => {
            this.systemService.handleErrorResponse(err)
          super.onSubmissionError(err)
          }
        );
    }
  }
  private onSuccessCreate() {
    this.systemService.showSuccessSnackBar(("User has been created"), "OK");  
     this.dialogRef.close();  
      //  this.router.navigate(['admin/users']);


  }
  private onSuccessUpdate() {
    this.systemService.showSuccessSnackBar(("User has been updated"), "OK");
    this.dialogRef.close();
    // this.router.navigate(['admin/users']);

  }

  // ----------------------------

  changeEmail(element): void {
    const profile = this.dialog.open(ChangeEmailComponent, {
      width: '650px',
      data: {
        id: this.userid,
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
      this.getUserData(this.userid);
    });
  }


  closeModal(): void {
    this.dialogRef.close();
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

}

export enum upload_type {
  user_profile
}