<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="healthcoach/hctasklist/taskID/Complete"></app-breadcrumb>
        <div class="main-wrapper">
            <div class="row">
                <div class="col-md-8 request-details">
                    <div class="hc-name mt-40 mb-40">
                        <span></span>
                    </div>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="title-bar"> <img src="assets/img/dls/icons/Tick_solid.svg"
                                            class="img-responsive" alt="Wellthy"> ID<span>{{taskdata.task_display_id}}</span>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="task-details">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="items">
                                            <h4>Total patients</h4>
                                            <h5 *ngIf="patientList && totalPatient.length >1">{{totalPatient.length}}</h5>
                                            <h5 *ngIf="totalPatient.length === 0">0</h5>
                                            <h5 *ngIf="patientList && totalPatient.length === 1">{{totalPatient.length}}</h5>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="items">
                                            <h4>Completed</h4>
                                            <h5 *ngIf="progresspatendId.length > 1" class="like"><img src="assets/img/dls/icons/Icon_complete.svg"
                                                    class="img-responsive"  style="margin-left: 5px;" alt="Wellthy">
                                                {{progresspatendId.length}}
                                            </h5>
                                            <h5 *ngIf="progresspatendId.length === 1" class="like"><img src="assets/img/dls/icons/Icon_complete.svg"
                                                class="img-responsive"  style="margin-left: 5px;" alt="Wellthy">
                                            {{progresspatendId.length}}
                                        </h5>
                                        <h5 *ngIf="progresspatendId.length === 0" class="like"><img src="assets/img/dls/icons/Icon_complete.svg"
                                            class="img-responsive"  style="margin-left: 5px;" alt="Wellthy">
                                       {{0}}
                                    </h5>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="items">
                                            <h4>Failed</h4>
                                            <h5 *ngIf="failedPatiendId.length > 1" class="dislike"><img src="assets/img/dls/icons/Icon_Incomplete.svg"
                                                    class="img-responsive" alt="Wellthy">
                                                    {{failedPatiendId.length}}
                                            </h5>
                                            <h5 *ngIf="failedPatiendId.length === 1" class="dislike"><img src="assets/img/dls/icons/Icon_Incomplete.svg"
                                                class="img-responsive" alt="Wellthy">
                                                {{failedPatiendId.length}}
                                        </h5>
                                        <h5 *ngIf="failedPatiendId.length === 0" class="dislike"><img src="assets/img/dls/icons/Icon_Incomplete.svg"
                                            class="img-responsive" alt="Wellthy">
                                            {{0}}
                                    </h5>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="items">
                                            <h4>Pending</h4>
                                            <h5 class="pending" *ngIf="totalPatient?.length-progressPatients?.length > 1"><img src="assets/img/dls/icons/Icon_Pending.svg"
                                                    class="img-responsive" alt="Wellthy">
                                                {{totalPatient?.length-progressPatients?.length}}
                                            </h5>
                                            <h5 class="pending" *ngIf="totalPatient?.length-progressPatients?.length  === 1"><img src="assets/img/dls/icons/Icon_Pending.svg"
                                                class="img-responsive" alt="Wellthy">
                                            {{totalPatient?.length-progressPatients?.length}}
                                        </h5>
                                        <h5 class="pending"  *ngIf="totalPatient?.length-progressPatients?.length  === 0"><img src="assets/img/dls/icons/Icon_Pending.svg"
                                            class="img-responsive" alt="Wellthy">
                                        {{0}}
                                    </h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- <p>I'm visible because I am open</p> -->
                        </mat-expansion-panel>
                    </mat-accordion>


                    <div class="mt-30">
                        <div (click)="changeShowStatus()" class="view-allp-btn">
                            <p class="pull-left">
                                View all patients
                            </p>
                            <div class="pull-right">
                                <img src="assets/img/dls/icons/arrow-down.svg" class="img-fluid d-block" alt="">
                            </div>

                        </div>
                    </div>


                    <div class="button-holder mt-40"><!--[disabled]="( taskDetails.expiry_date > getToday)"-->
                        <button mat-button class="btn btn-medium btn-filled mr-10" [disabled]=" taskdata.expiry_date < getToday && apiCall ||(totalPatient.length==0)" (click)="sendForApprova()">
                            Send for Approval
                        </button>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="showHide">
                    <div class="view-allpatients">
                        <div class="title-holder">
                            <h4>All patients</h4>
                            <!-- <form class="search-form"> -->
                            <div class="form-group has-feedback has-search">
                                <input class="form-control" placeholder="Search patient">
                                <button mat-fab class="searchbtn" style="display: none;">
                                    <mat-icon matSuffix>search</mat-icon>
                                </button>
                            </div>
                            <!-- </form> -->
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="container-table" *ngIf="totalPatient?.length">
                                    <ul class="list-group">
                                        <li class="list-group-item" *ngFor="let patients of totalPatient">
                                            <mat-accordion>
                                                <mat-expansion-panel [expanded]="taskdata.status != 'published'">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            <div class="d-flex align-items-center content">
                                                                <img *ngIf="checkprogress(patients.id)" style="margin-left: 8px;"
                                                                    src="assets/img/dls/icons/Icon_complete.svg"
                                                                    class="img-responsive" alt="Wellthy">
                                                                <img *ngIf="!checkprogress(patients.id)"
                                                                    src="assets/img/dls/icons/Icon_Pending.svg"
                                                                    class="img-responsive" alt="Wellthy">
                                                                {{patients.name}}
                                                            </div>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div class="row">
                                                        <div class="configure-conditions col-md-12">
                                                            <h5>Task type</h5>
                                                            <div class="task-type-bg"
                                                                *ngFor="let task of taskdata.task_summary">
                                                                <div class="d-flex align-items-center content">
                                                                    <img *ngIf="checkprogress(patients.id)"
                                                                        src="assets/img/dls/icons/Icon_complete.svg"
                                                                        class="img-responsive" alt="Wellthy">
                                                                    <img *ngIf="!checkprogress(patients.id)"
                                                                        src="assets/img/dls/icons/Icon_Pending.svg"
                                                                        class="img-responsive" alt="Wellthy">
                                                                    {{task}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h6>Priority</h6>

                                                            <div class="preview-sub text-capitalize">
                                                                {{priorityInList(taskdata?.priority)}}</div>

                                                        </div>
                                                        <div class="col-md-4">
                                                            <h6>Scope</h6>
                                                            <div class="preview-sub text-capitalize">{{taskdata?.scope}}
                                                            </div>

                                                        </div>
                                                        <div class="col-md-4">
                                                            <h6>Expiry</h6>
                                                            <div class="preview-sub">
                                                                {{convertDate(taskdata?.expiry_date)}}
                                                            </div>

                                                        </div>
                                                    </div>



                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h6 class="temp-title">Condition</h6>
                                                            <div class="template-data">
                                                                {{taskdata?.filter_conditions_summary}}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </li>
                                        <!-- <li class="list-group-item">
                                            <mat-accordion>
                                                <mat-expansion-panel [expanded]="false">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            <div class="title-bar"> <img
                                                                    src="assets/img/dls/icons/Tick_solid.svg"
                                                                    class="img-responsive" alt="Wellthy">Alexa Leech
                                                            </div>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>

                                                    <div class="row">
                                                        <div class="configure-conditions col-md-12">
                                                            <h5>Task type</h5>
                                                            <div class="task-type-bg">
                                                                <div class="d-flex align-items-center content">
                                                                    <img src="assets/img/dls/icons/Tick_solid.svg"
                                                                        class="img-responsive" alt="Wellthy"> Make
                                                                    phone call to patients
                                                                </div>
                                                            </div>
                                                            <div class="task-type-bg">
                                                                <div class="d-flex align-items-center content">
                                                                    <img src="assets/img/dls/icons/Tick_solid.svg"
                                                                        class="img-responsive" alt="Wellthy"> Make
                                                                    phone call to patients
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h6>Priority</h6>

                                                            <div class="preview-sub text-capitalize">
                                                                High</div>

                                                        </div>
                                                        <div class="col-md-4">
                                                            <h6>Scope</h6>
                                                            <div class="preview-sub text-capitalize">Global
                                                            </div>

                                                        </div>
                                                        <div class="col-md-4">
                                                            <h6>Expiry</h6>
                                                            <div class="preview-sub">21 Aug,2021
                                                            </div>

                                                        </div>
                                                    </div>



                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h6 class="temp-title">Condition</h6>
                                                            <div class="template-data">
                                                                Patient who is diabetic and has logged less than 15
                                                                mins of activity last week.
                                                            </div>
                                                        </div>
                                                    </div>

                                                </mat-expansion-panel>
                                            </mat-accordion>

                                        </li>
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center content">
                                                <img src="assets/img/dls/icons/Tick_solid.svg"
                                                    class="img-responsive" alt="Wellthy"> Cvita Doleschall
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center content">
                                                <img src="assets/img/dls/icons/Tick_solid.svg"
                                                    class="img-responsive" alt="Wellthy"> Stacy Dcosta
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center content">
                                                <img src="assets/img/dls/icons/Tick_solid.svg"
                                                    class="img-responsive" alt="Wellthy"> Maisy Williams
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center content">
                                                <img src="assets/img/dls/icons/Tick_solid.svg"
                                                    class="img-responsive" alt="Wellthy"> John Secada
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center content">
                                                <img src="assets/img/dls/icons/Tick_solid.svg"
                                                    class="img-responsive" alt="Wellthy"> Brandon Rauth
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center content">
                                                <img src="assets/img/dls/icons/Tick_solid.svg"
                                                    class="img-responsive" alt="Wellthy"> Alec Benjamin
                                            </div>
                                        </li> -->
                                    </ul>
                                </div>
                                <div style="text-align: center;" *ngIf="!patientList?.length">
                                    No patients matched with the task creation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    </div>
</mat-drawer-container>