<div class="row">
    <div *ngIf="!reportGenerated && !history" class="col-md-12">
        <div class="row">
    
            <div class="col-md-8">
                <p class="title-cont">Create Diet Plan</p>
            </div>
    
            <div class="col-md-4">
                <div class="copy-close-wrapper">
                    <div class="close-icon" (click)="close()"></div>
                </div>
            </div>
    
        </div>

        <div class="row">

            <form class="mobile-number-form">

                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Age</mat-label>
                        <input matInput [(ngModel)]="dob" [disabled]="false" name="dob">
                    </mat-form-field>

                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Unit</mat-label>
                        <mat-select [(ngModel)]="age_unit" name="age unit" [disabled]="true" >
                          <mat-option value="year">Year</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Gender</mat-label>
                        <mat-select [(ngModel)]="gender" name="gender" [disabled]="true" >
                          <mat-option value="m">Male</mat-option>
                          <mat-option value="f">Female</mat-option>
                          <mat-option value="o">Other</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Height</mat-label>
                        <input matInput [(ngModel)]="height" [disabled]="false" name="height">
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Unit</mat-label>
                        <mat-select [(ngModel)]="height_unit" name="height unit" [disabled]="true" >
                          <mat-option value="cm">cm</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Weight</mat-label>
                        <input matInput [(ngModel)]="weight" [disabled]="false" name="weight">
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Unit</mat-label>
                        <mat-select [(ngModel)]="weight_unit" name="weight unit" [disabled]="true" >
                          <mat-option value="kg">kg</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Activity Level</mat-label>
                        <mat-select [(ngModel)]="selected_activity_lvl" name="activitylevel" [disabled]="false" >
                          <mat-option *ngFor="let activity of activity_lvl" [value]="activity" >{{activity}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Geographical location</mat-label>
                        <mat-select [(ngModel)]="selected_location" name="location" [disabled]="false" >
                            <mat-option *ngFor="let loc of locations" [value]="loc" >{{loc}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Patient's disease</mat-label>
                        <mat-select [(ngModel)]="selected_disease" name="disese" [disabled]="false" multiple>
                          <mat-option *ngFor="let d of disease" [value]="d">{{d}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Primary Goal</mat-label>
                        <mat-select [(ngModel)]="selected_primary_goal" name="p_goal" [disabled]="false" >
                          <mat-option *ngFor="let p of primary_goal" [value]="p">{{p}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Secondary Goal</mat-label>
                        <mat-select [(ngModel)]="selected_secondry_goal" name="s_goal" [disabled]="false" >
                            <mat-option *ngFor="let s of secondry_goal" [value]="s">{{s}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Duration to achieve goals</mat-label>
                        <mat-select [(ngModel)]="selected_goal_time" name="goal_time" [disabled]="false" >
                            <mat-option *ngFor="let gt of goal_time" [value]="gt">{{gt}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Diet Type</mat-label>
                      <mat-select [(ngModel)]="selected_diet_type" name="diet_type" [disabled]="false" >
                        <mat-option *ngFor="let dt of diet_type" [value]="dt">{{dt}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Diet Preference</mat-label>
                      <mat-select [(ngModel)]="selected_diet_preference" name="diet_preference" [disabled]="false" >
                        <mat-option *ngFor="let dt of diet_preference" [value]="dt">{{dt}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-slide-toggle (change)="onChangeAdvancePlan($event)" [(ngModel)]="isAdvancePlan" name="isAdvancePlan">Advance Planning</mat-slide-toggle>
                </div>
              
                <div *ngIf="isAdvancePlan">
                <!-- <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Do you have any food allergies?</mat-label>
                        <input matInput [(ngModel)]="food_allergies" [disabled]="false" name="food_allergies">
                      </mat-form-field>
                </div> -->

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Do you have any dietary restriction?</mat-label>
                      <input matInput [(ngModel)]="dietary_restrications" [disabled]="false" name="dietary_restrications">
                    </mat-form-field>
                </div>

                  <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Which food items do you like?</mat-label>
                      <input matInput [(ngModel)]="food_likes" [disabled]="false" name="food_likes">
                      </mat-form-field>
                  </div>

                  <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Which food items do you dislike?</mat-label>
                      <input matInput [(ngModel)]="food_dislikes" [disabled]="false" name="food_dislikes">
                      </mat-form-field>
                  </div>
                  
                  <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>List of allergies</mat-label>
                        <input matInput [(ngModel)]="allergies" [disabled]="false" name="allergies">
                      </mat-form-field>
                  </div>

                  <div class="col-md-12">
                      <mat-form-field appearance="outline" class="full-width-formfield">
                          <mat-label>Are you on any medications?</mat-label>
                          <input matInput [(ngModel)]="medications" [disabled]="false" name="medications">
                        </mat-form-field>
                  </div>

                  <div class="col-md-12">
                      <mat-form-field appearance="outline" class="full-width-formfield">
                          <mat-label>Do you have any family history of health issues?</mat-label>
                          <mat-select [(ngModel)]="health_issue_history_of_family" name="disese" [disabled]="false" multiple>
                            <mat-option *ngFor="let d of disease" [value]="d">{{d}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>How many meals do you consume daily?</mat-label>
                        <mat-select [(ngModel)]="no_of_meals_consumed_daily" name="no_of_meals_consumed_daily" [disabled]="false" multiple>
                          <mat-option *ngFor="let lg of meal_times" [value]="lg">{{lg}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>How many snacks do you consume daily?</mat-label>
                      <mat-select [(ngModel)]="no_of_snacks_consumed_daily" name="no_of_snacks_consumed_daily" [disabled]="false" multiple>
                        <mat-option *ngFor="let lg of snack_times" [value]="lg">{{lg}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <!-- <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>What is your meal times?</mat-label>
                      <input matInput [(ngModel)]="typical_meal_times" [disabled]="false" name="typical_meal_times">
                    </mat-form-field>
                </div> -->

                <!-- <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Describe your eating environment</mat-label>
                      <input matInput [(ngModel)]="eating_enviroment" [disabled]="false" name="eating_enviroment">
                    </mat-form-field>`
                </div> -->

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Which cuisines do you prefer?</mat-label>
                      <mat-select [(ngModel)]="selected_cuisines" name="selected_cuisines;" [disabled]="false" multiple>
                        <mat-option *ngFor="let s of cuisines" [value]="s">{{s}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>What percentage of meals are homecooked?</mat-label>
                      <mat-select [(ngModel)]="selected_percentage_of_meals" name="selected_percentage_of_meals;" [disabled]="false" >
                        <mat-option *ngFor="let s of percentage_of_meals" [value]="s">{{s}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>What food do you commonly eat or is made in your home?</mat-label>
                      <input matInput [(ngModel)]="commonly_eat_food_item" [disabled]="false" name="commonly_eat_food_item">
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Describe if you have any emotional stress related eating patterns</mat-label>
                      <input matInput [(ngModel)]="any_emotional_stress_realted_eating_patterns" [disabled]="false" name="any_emotional_stress_realted_eating_patterns">
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Are you familiar with macronutrients?</mat-label>
                      <mat-select [(ngModel)]="familiar_with_macronutrients" name="familiar_with_macronutrients" [disabled]="false" >
                        <mat-option *ngFor="let s of yes_no" [value]="s">{{s}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Are you familiar with micronutrients?</mat-label>
                      <mat-select [(ngModel)]="familiar_with_micronutrients" name="familiar_with_micronutrients" [disabled]="false" >
                        <mat-option *ngFor="let s of yes_no" [value]="s">{{s}}</mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Do you have cooking skills and willng to preapare meal at home?</mat-label>
                      <mat-select [(ngModel)]="cooking_skills_and_willingness_to_prepare_meal_at_home" name="cooking_skills_and_willingness_to_prepare_meal_at_home" [disabled]="false" >
                        <mat-option *ngFor="let s of yes_no" [value]="s">{{s}}</mat-option>
                       </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Do you have availability of support from friends, family or professionals?</mat-label>
                      <mat-select [(ngModel)]="availibility_of_support_from_friends_family_professionals" name="availibility_of_support_from_friends_family_professionals" [disabled]="false" >
                        <mat-option *ngFor="let s of yes_no" [value]="s">{{s}}</mat-option>
                       </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Do you have access to healthy food options?</mat-label>
                      <mat-select [(ngModel)]="access_to_healthy_food_options" name="access_to_healthy_food_options" [disabled]="false" >
                        <mat-option *ngFor="let s of yes_no" [value]="s">{{s}}</mat-option>
                       </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Do you have budget for food supplements?</mat-label>
                      <mat-select [(ngModel)]="budget_for_food_supplements" name="budget_for_food_supplements" [disabled]="false" >
                        <mat-option *ngFor="let s of yes_no" [value]="s">{{s}}</mat-option>
                       </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="full-width-formfield">
                      <mat-label>Language for Diet Plan</mat-label>
                      <mat-select [(ngModel)]="language_for_diet_plan" name="diet_language" [disabled]="false" >
                        <mat-option *ngFor="let lg of language" [value]="lg">{{lg}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
              </div>
            </form>
        </div>

        <div mat-dialog-actions class="mat-btn-holder">
            <button mat-stroked-button mat-dialog-close="cancel" class="btn btn-outline cancel-btn">Cancel</button>
            <button mat-flat-button [disabled]="loading" class="btn btn-filled" (click)="createDietPlan()">
                <span *ngIf="loading" class="spinner">Creating...</span>
                <span *ngIf="!loading">Create</span>
            </button>
        </div>
    </div>
    <div *ngIf="reportGenerated" class="col-md-12">
        <div id="section1" style="padding: 20px;">
          <div class="row" style="margin-bottom: 20px;">
            <div class="copy-close-wrapper">
              <div class="close-icon" (click)="close()"></div>
          </div>
            <div class="col-md-6">
                <div class="report-title pull-left">
                    <h4 style="padding-bottom: 10px;">Diet Plan, {{selectedPlanResponse?.created_at | date:'MMM d, y'}}</h4>
                    <h5>Patient:
                      {{name}}, {{selectedPlanResponse?.gender}}, {{selectedPlanResponse?.age}} {{selectedPlanResponse?.age_unit}}</h5>
                  <p >Height: {{selectedPlanResponse?.height}} {{selectedPlanResponse?.height_unit}}, Weight: {{selectedPlanResponse?.weight}} {{selectedPlanResponse?.weight_unit}}</p>
                  <p *ngIf="selectedPlanResponse?.diet_type">Diet Type: {{selectedPlanResponse?.diet_type}}, Diet Preference: {{selectedPlanResponse?.diet_preference}}</p>
                  <p *ngIf="selectedPlanResponse?.primary_goal">Goal: {{selectedPlanResponse?.primary_goal}}</p>
                </div>
            </div>
            <div class="col-md-6" style="margin-top: 20px;">
                <div class="logo-section pull-right">
                    <img src="assets/img/wellthynewlogo.png"
                        style="float:right; height:50px; width: 200px" class="logo" alt="Logo"
                        alt="" />
                </div>
            </div>

        </div>
            <div class="row">
                <table mat-table [dataSource]="mealPlanData.mealPlan" multiTemplateDataRows class="widthfluid bordergrey list-table" style="border: 1px solid #000000 !important;">
                  <caption *ngIf="false">Diet Plan</caption>

                  <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
                    <th mat-header-cell *matHeaderCellDef [ngClass]="colIndex == 0 ? 'diet-plan-th-0' : 'diet-plan-th'">{{disCol}}</th>
                    <td mat-cell *matCellDef="let element" style="padding-right:10px;height: 150px;padding-top: 10px;padding-bottom: 10px;">
                        <textarea matInput autosize cdkAutosizeMinRows="3" [ngStyle]="{'min-width': colIndex == 0 ? '95px' : '125px' }"  style="height: 140px; overflow: hidden; resize: none;"
                       [ngModel]="element[disCol]" (ngModelChange)="onChange($event, disCol, colIndex, element)"></textarea>
                    </td>
                  </ng-container>
 
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns let even = even" [ngClass]="{evenRowWhite: even}"></tr>

                </table>
            </div>
        </div>
        <div mat-dialog-actions class="mat-btn-holder">
            <button mat-stroked-button (click)="reportGenerated=false;history=true" class="btn btn-outline cancel-btn">Back</button>
            <button mat-stroked-button *ngIf="allowEdit" class="btn btn-outline cancel-btn" (click)="editDietPlan()">Save</button>
            <button mat-flat-button class="btn btn-filled" (click)="downloadDietPlan()">Download</button>
        </div>
    </div>
    <div *ngIf="!reportGenerated && history" class="col-md-12">
          <div class="row" style="margin-bottom: 20px;">
            
              <div class="col-md-8">
                  <p class="title-cont">Diet Plan History</p>
              </div>
            
              <div class="col-md-4">
                  <div class="copy-close-wrapper">
                      <div class="close-icon" (click)="close()"></div>
                  </div>
              </div>
            
          </div>
          <div class="row">
              <table mat-table [dataSource]="historyData" multiTemplateDataRows class="widthfluid bordergrey list-table" style="border: 1px solid #000000 !important;">
                <caption *ngIf="false">Diet Plan History</caption>

                <ng-container matColumnDef="request">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="diet-plan-th-0">Creation Time</th>
                  <td mat-cell *matCellDef="let element" style="padding-right:10px">{{element.created_at | date: 'dd/MM/yyyy' }}  {{element.created_at | date: 'h:mm a'}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 100px;">Action </th>
                  <td mat-cell class="text-capitalize" *matCellDef="let element">
                    <div class="action-button-row">
                      <button mat-button (click)="showdata(element, 'raw')">
                        <img src="assets/img/dls/admin-table/view_raw_diet_plan.svg" tooltip="View Raw Plan" alt="Wellthy" style="height: 25px; width: 25px;">
                      </button>
                      <button mat-button (click)="showdata(element, 'edited')">
                        <img src="assets/img/dls/admin-table/view_edited_diet_plan.svg" tooltip="Edit Plan" alt="Wellthy" style="height: 25px; width: 25px;">
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>

              </table>
          </div>
      </div>
</div>
