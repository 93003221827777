<div id="content">
    <div class="showdownload">
        <div class="title">
            <div class="clearfix">
                <h2 class="pull-left title-modal">QR Code</h2>
                <div class="pull-right">
                    <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal"
                        alt="">
                </div>
            </div>
        </div>

        <div class="qrbox">
            <img src="assets/img/wellthy_logo_2.png" class="wellthylogo" />
            <img [src]="imgurl" class="img-responsive" style="width: 100%;"/>
        </div>
        
    </div>
</div>