import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-careychat',
  templateUrl: './careychat.component.html',
  styleUrls: ['./careychat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CareyChatComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }
  ngOnInit() {
    this.data = JSON.parse(this.data);
    
  }
}
