
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';
import { delay } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-aemail',
  templateUrl: './aemail.component.html',
  styleUrls: ['./aemail.component.scss']
})
export class AEMailComponent implements OnInit {
  public userDetail: any;
  public dataVal: any;
  public selectedUserID: any;
  public typeFilters: any;
  adversedata:any;
  emaildata:any;
  showButtonLoader: boolean;
  public subscribeObj: any;
  public ferrerAEMailID:any = environment.ferrerAEMailID;
  public dataForm = this.fb.group({
    // to: [['pharmacovigilance@ferrer.com','pharmaco.vigilance@wellthy.care']],
    to: [[]],
    subject: [''],
    patient_data_fk:[''],
     gender: [''],
     age :[''],
     dob :[''],
     weight : [''],
     weight_unit :[''],
     height : [''],
     height_unit :[''],
     qualification :[''],
     country:['']  ,
     situtation_to_patient:[],
     action_taken:[] ,
     causality_relatedness:[],
     adverse_reaction_abated:[],
     adverse_reaction_disappeared:[],
     adverse_reaction_recurred:[],
     medicinal_product:[],
     adverse_reaction:[''], 
     adverse_start_date:[''],
     adverse_end_date :[''],
     adverse_outcome :[''] ,
     patient_medical_history:[[]], 
  });

  public medicinal_product: FormArray;

  constructor(public dialog: MatDialog, private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any, private systemService: SystemService) { }

  ngOnInit() {
    this.dataVal = this.data.dataKey.data;
    
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.dataForm.patchValue(this.dataVal);
    var hcEmail = JSON.parse(localStorage.getItem('scarletuserdata'));
    if(this.ferrerAEMailID){
      var senEmails = [hcEmail.email,'pharmacovigilance@ferrer.com','pharmaco.vigilance@wellthy.care']
      
    }else{
      var senEmails = [hcEmail.email]
      
    }
    
    // var senEmails = ['amruthavarshini.d.s@wellthy.care']
    this.dataForm.controls.to.patchValue(senEmails)
    this.initComponentDraw();
  }

  initComponentDraw() {   
    this.UserdataService.getUserProfileDetails(this.selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        return throwError(error);
      }),
      finalize(() => { 
       })
    ).subscribe(data => {
      data = (data as any).data;
      
      this.userDetail = data;
    });
  }


  sendMail() {    
     var postdata = this.dataForm.value
     postdata.to = JSON.stringify(this.dataForm.value.to),
     postdata.subject = "MRD - "+moment(this.dataVal.mrd_date).format("DD-MMM-YYYY")+", "+"Activation Code - "+this.userDetail.activation_code,
     postdata.patient_data_fk = this.selectedUserID.toString(),
     postdata.gender  = this.userDetail.gender;
     var newAge = (this.userDetail.age/12).toFixed(0);
     postdata.age = newAge;
     const momentDate = new Date(this.userDetail.dob);
     postdata.dob  = moment(momentDate).format("DD-MMM-YYYY"),
     postdata.weight =  this.userDetail.weight,
     postdata.weight_unit = this.userDetail.weight_unit,
     postdata.height = this.userDetail.height,
     postdata.height_unit = this.userDetail.height_unit,
     postdata.qualification =  this.dataVal.qualification,
     postdata.country = this.dataVal.country,
     postdata.situtation_to_patient  =  JSON.stringify(this.dataVal.situtation_to_patient),
     postdata.action_taken = JSON.stringify([this.dataVal.action_taken]),
     postdata.causality_relatedness = JSON.stringify([this.dataVal.causality_relatedness]),
     postdata.adverse_reaction_abated = JSON.stringify([this.dataVal.adverse_reaction_abated]),
     postdata.adverse_reaction_disappeared = JSON.stringify([this.dataVal.adverse_reaction_disappeared]),
     postdata.adverse_reaction_recurred = JSON.stringify([this.dataVal.adverse_reaction_recurred]),
     postdata.medicinal_product = JSON.stringify(this.dataVal.medicinal_product),
     postdata.adverse_reaction = this.dataVal.adverse_reaction;
    //  postdata.adverse_start_date = this.dataVal.adverse_start_date,
    //  postdata.adverse_end_date = this.dataVal.adverse_end_date,
     if(this.dataVal.adverse_start_date == null){
      postdata.adverse_start_date = ""
    }else{
      postdata.adverse_start_date = moment(this.dataVal.adverse_start_date).format("DD-MMM-YYYY");
    }
    if(this.dataVal.adverse_end_date == null){
      postdata.adverse_end_date = ""
    }else{
      postdata.adverse_end_date = moment(this.dataVal.adverse_end_date).format("DD-MMM-YYYY");
    }
     postdata.adverse_outcome = this.dataVal.adverse_outcome,
     postdata.patient_medical_history = JSON.stringify(this.dataVal.patient_medical_history)  
     
    this.UserdataService.sendAEMail(postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    )
    .subscribe(
      (data) => {
        this.dialog.closeAll();
        this.systemService.showSuccessSnackBar("Mail has been sent successfully.", "OK");
        this.showButtonLoader = false;
      })
  }

}


