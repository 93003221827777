<div class="profile-wrapper">
  <div class="profile-info clearfix">
    <div class="media">
      <div class="media-body media-middle">
        <h4 class="media-heading text-capitalize">Instructions for editor</h4>
      </div>
    </div>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        close
      </i>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows
        class="widthfluid table-striped bordergrey list-table">
        <ng-container matColumnDef="style">
          <th mat-header-cell *matHeaderCellDef> Style </th>
          <td mat-cell *matCellDef="let element"> {{element.style}} </td>
        </ng-container>

        <ng-container matColumnDef="syntax">
          <th mat-header-cell *matHeaderCellDef> Syntax </th>
          <td mat-cell *matCellDef="let element"> {{element.syntax}} </td>
        </ng-container>

        <ng-container matColumnDef="example">
          <th mat-header-cell *matHeaderCellDef> Example </th>
          <td mat-cell *matCellDef="let element"> {{element.example}} </td>
        </ng-container>

        <ng-container matColumnDef="output">
          <th mat-header-cell *matHeaderCellDef> Output </th>
          <td mat-cell *matCellDef="let element" [ngClass]="stylefunc(element.style)" [innerHTML]="element.output">  </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>
  </div>

</div>