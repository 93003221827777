<form [formGroup]="dataForm" validate style="width: calc(100% - 10px);" *ngIf="allsymptoms.length != 0">
    <div class="row">
        <div class="col-md-12">
            <h4 class="generictitle">Add Symptoms</h4>
            <hr />
            <div class="row">
                <mat-form-field class="col-md-12" appearance="outline">
                    <mat-label>Date Time *</mat-label>
                    <input matInput [max]="maxdate" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
                        placeholder="Date Time *" formControlName="log_date">
                    <owl-date-time #dt1></owl-date-time>
                </mat-form-field>
                <div class="clearfix"><br /></div>
                <div formArrayName="symptom_ids" class="symptomsection">
                    <div *ngFor="let option of dataForm.get('symptom_ids')?.controls; let j=index;" [formGroupName]="j"
                        class="col-md-12">
                        <div class="row">
                            <div class="col-md-9 titlesec">
                                {{allsymptoms[j]?.title}}
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Severity</mat-label>
                                    <mat-select placeholder="Select Severity" formControlName="value" >
                                        <mat-option id="none" value="none"> None </mat-option>
                                        <mat-option id="mild" value="mild"> Mild </mat-option>
                                        <mat-option id="moderate" value="moderate"> Moderate </mat-option>
                                        <mat-option id="severe" value="severe"> Severe </mat-option>
                                        <mat-option id="extreme" value="extreme"> Extreme </mat-option>
                                    </mat-select>
                                </mat-form-field>

                              
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
        <button mat-flat-button color="primary" class="submit-btn" (click)="dataObject()">Add Symptoms</button>
    </div>
</form>
<div *ngIf="allsymptoms.length == 0">
    <h5 style="margin:0 auto;margin-top: 45%;text-align: center;line-height: 24px;">We cannot find symptoms mapped to
        the patient's condition. <br /> Contact Administrator</h5>
</div>