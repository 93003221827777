import { CommonserviceService } from './../../../../admin/services/commonservice/commonservice.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { Userdetailsinterface } from '../../../models/userdetailsinterface';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { EditNotesComponent } from './edit-notes/edit-notes.component';
import { NotesLogComponent } from './notes-log/notes-log.component';

@Component({
  selector: 'app-knowuser',
  templateUrl: './knowuser.component.html',
  styleUrls: ['./knowuser.component.scss']
})

export class KnowuserComponent implements OnInit {
  public knowUserSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public knowUserCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public tabID: number = 1;
  public knowUserForm: FormGroup;
  public selectedChatUserID: string;
  public subscribeObj: any;
  public noteType: string = "healthcoach";
  public noteText: string = "self";
  public pagecount: number = 1;
  public showloadmore: boolean = false;
  healthcoachID: any;
  knowUserData: any;
  public rawdata: any;
  public isArchiveActive: boolean = false;
  public isPartnerActive: boolean = false;


  @ViewChild("scrollToBottom", { static: false }) private scrollToBottomContainer: ElementRef;
  @ViewChild("form", { static: false }) tsForm;
  enablePartnerNotes: any;

  constructor(private appContext: ContextService, private userDetailService: UserdetailsService,
    private formBuilder: FormBuilder, private systemService: SystemService, public translate: TranslateService,
    private dialog: MatDialog,private commonService:CommonserviceService) { }

  ngOnInit() {
    this.healthcoachID = JSON.parse(localStorage.getItem('scarletuserdata'));

    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.knowUserData = [];
        this.pagecount = 1;
        this.showloadmore = false;
        this.selectedChatUserID = this.appContext.selectedChatUser.getValue();
        this.initComponentDraw(this.selectedChatUserID,'all');
        this.getProfileFlags(this.selectedChatUserID);
      }
      let notificationStoredValue = localStorage.getItem("selectedNotification");
      if (notificationStoredValue == 'patient_note_event') {
        this.selectedChatUserID = this.appContext.selectedChatUser.getValue();
        this.initComponentDraw(this.selectedChatUserID,'all');
        this.getProfileFlags(this.selectedChatUserID);
      }
    });
    /**Build form */
    this.buildKnowUserForm();
    this.getProfileFlags(this.selectedChatUserID);
  }
  getProfileFlags(selectedUserID) {
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      data = data == null ? [] : data;
      this.enablePartnerNotes = data.profile_flags.includes("PPN");
    });
  }

  buildKnowUserForm(): void {

    this.knowUserForm = this.formBuilder.group({
      note: ["", Validators.required],
      type: ["healthcoach"]
    });
  }

  setNoteType(noteType: string): void {
    this.noteType = noteType;
    if (noteType == "doctor") {
      this.noteText = "doctor";
    } else if (noteType == "healthcoach") {
      this.noteText = "self";
    }else if (noteType == "partner") {
      this.noteText = "partner";
    }
  }

  saveKnowUserNote(): void {
    let data = this.knowUserForm.value;
    /**Call API */
    data.type = this.noteType;
    let note = this.commonService.isHTML( this.knowUserForm.value.note);
   
    if(note  === true){
      this.knowUserForm.controls.note.patchValue('')
      return;
    }
    if (!data.type || data.type == "") {
      this.systemService.showSnackBar("Please select note type.", "Ok");
      return;
    }

    if (!(/\S/.test(data.note))) {
      this.tsForm.resetForm();
      return;
    }
    this.knowUserCreationSubject$.next(false);

    this.userDetailService.saveUserNotes(this.selectedChatUserID, this.healthcoachID.id, data.note, this.noteText).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      this.loadingSubject$.next(false);
      this.tsForm.resetForm();
      this.initComponentDraw(this.selectedChatUserID,'all');

    });

  }

  getNextSet() {
    this.pagecount++;
    this.initComponentDraw(this.selectedChatUserID,'all')
  }

  initComponentDraw(id,type?:any) {

    this.userDetailService.getPatienInfo("", this.pagecount, type, id, this.isArchiveActive).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      this.showloadmore = (data.length >= 20) ? true : false;

      if (this.pagecount == 1) {
        this.knowUserData = data
      }
      else {
        (data as any).forEach(el => {
          this.knowUserData.push(el)
        });
      }

      this.sortData();

      let scope = this;
      setTimeout(function () {
        scope.scrollToBottom();
      }, 200);

    });
  }

  sortData() {
    return this.knowUserData.sort((a, b) => {
      return <any>new Date(a.created_at) - <any>new Date(b.created_at);
    });
  }

  sortByKey(arrp, keyp) {
    return arrp.sort(function (a, b) {
      var x = a[keyp];
      var y = b[keyp];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  convertIsoDate(d) {
    var date = new Date(d);
    return date.toLocaleString();
  }

  scrollToBottom(): void {
    try {
      this.scrollToBottomContainer.nativeElement.scrollIntoView(false);//scrollTop = this.scrollToBottomContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  ngOnDestroy() {
    this.subscribeObj.unsubscribe();
    // this.knowUserSubject$.unsubscribe();
    // this.loadingError$.unsubscribe();
    // this.loadingSubject$.unsubscribe();
  }

  toggleArchive() {
    this.isPartnerActive = false;
    this.isArchiveActive = !this.isArchiveActive;
    this.pagecount = 1;
    this.initComponentDraw(this.selectedChatUserID,'all');
  }

  togglePartner() {
    this.isArchiveActive = false;
    this.isPartnerActive = !this.isPartnerActive;
    this.pagecount = 1;
    if(this.isPartnerActive === false){
      this.initComponentDraw(this.selectedChatUserID,'all');
    }else{
      this.initComponentDraw(this.selectedChatUserID,'partner');
    }
    
  }

  showEdit(created_date) {
    let createdDate = moment.utc(created_date);
    createdDate.add(90, 'm');
    let now = moment.utc();

    if (now.isBefore(createdDate)) {
      return true;
    } else {
      return false;
    }
  }

  markNoteArchive(noteId) {

    this.userDetailService.markNoteAsArchive(noteId, this.healthcoachID.id)
      .subscribe((response: any) => {
        if (response.status == 200) {

          let knowUserData = this.knowUserData;
          this.knowUserData = [];

          for (let i = 0; i < knowUserData.length; i++) {
            if (knowUserData[i].id === noteId) {
              knowUserData.splice(i, 1);
              this.knowUserData = knowUserData;
              break;
            }
          }
        }
      }, (error) => {
        this.systemService.handleErrorResponse(error);
      })
  }

  editNote(note) {

    let editData = { 
      note: note.content,
      hcId: this.healthcoachID.id,
      id: note.id,
      userId: this.selectedChatUserID,
    };

    const dialogRef = this.dialog.open(EditNotesComponent,{
      width: "760px",
      height: "350px",
      data: editData
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data){
        this.pagecount = 1;
        this.initComponentDraw(this.selectedChatUserID,'all');
      }
    })
  }

  openNotesLog(notesChanges){
    this.dialog.open(NotesLogComponent, {
      width: "760px",
      height: "500px",
      data: notesChanges
    })
  }
}
