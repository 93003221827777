export const environment = {
  production: true,
  googleClientID: "716270162301-5r4segpeairb1tk9ehl4kr7o45aeo40i.apps.googleusercontent.com",
  googleOAuthClientID: "750965104167-29cpdm0a1kvme375httr9p77nrgbshqd.apps.googleusercontent.com",
  googleClientSecret: "E8yK8UHJGLVKSVWUm13cEmNi",
  userActionAPIKey: "1824cdf5-b8c8-4b9c-a3f1-334532970b87",
  doctorTypes: ["Freemium", "Regular"],
  defaultDoctorType: "Regular",
  debug: false,
  org: false,
  captcha : true,

  // prod
  serverUrl : 'https://showcaseblade.wellthy.app/',
  gqlUrl: "https://showcaseblade.wellthy.app/",
  zohoOrganisationID: '652974278',
  zohoAuthToken: 'd4b999c9182d7ae481341218dc394cfc',
  cueSocketUrl : 'https://showcasechat.wellthy.app/',
  chatlistener : 'v3/prod',
  s3url : 'https://s3.ap-south-1.amazonaws.com/gene-media-manager-new-prod/',
  richMedia:'https://wellthy-cue2.s3.ap-south-1.amazonaws.com/',
  deeplinkUrl:'https://wellthy.care/deeplink/',
  captchaKey: '6LfTsI4gAAAAAItaY4pzKWVtk-U5ssdr8SLbFEo-',
  invisibleCaptcha: '6Lcn2o4gAAAAAF2oqa71sJe1jxmgpzYJLvCqV6VJ',
  microserviceUrl:'https://micro.wellthy.app/',
  clevertapAccountID:'8R9-RKK-475Z',
  ClevertapPassword:'SOS-IWB-ATKL',
  ferrerAEMailID: true,
  isDebugg: false,
  publishVersion:"v3.9.5",
  HCfaqURL:'https://faq.wellthy.app/#/chat?usertype=',
  encryptionKey:"W3llThy@!API_encryption-key&737d3ae2*27&_salt"
};
