import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterApiService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-adminleftnav',
  templateUrl: './adminleftnav.component.html',
  styleUrls: ['./adminleftnav.component.scss']
})

export class AdminleftnavComponent implements OnInit, OnDestroy {
  public actRoute: any;
  public localStoredData: any;
  public actRouteEdit: any;
  public showButtonLoader: any = false;
  s3url: any = environment.s3url;
  public hasPhoneNumberRequest: boolean = false;

  observer =  null;
  adminClient: any;

  constructor(
    private router: Router,
    private routerApiService: RouterApiService
  ) { 
    this.observer = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd){
        this.routerApiService.anyPhoneNumberRequests()
          .subscribe((data) => {
            if (data.data.length > 0){
              this.hasPhoneNumberRequest = true;
            }
          })
      }
    })
  }

  ngOnInit() {
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.adminClient = JSON.parse(localStorage.getItem('adminClient'));
  }

  activeRouteAdmin() {
    this.actRoute = window.location.hash.slice(1, 20);
    this.actRouteEdit = window.location.hash;
    var regadmnExp1 = RegExp('admin/users/edit');
    var regadmnExp2 = RegExp('admin/doctors/edit');
    var regadmnExp3 = RegExp('admin/patients/edit');
    var regadmnExp4 = RegExp('admin/hardsetotp');
    var regadmnExp5 = RegExp('admin/app-master');
    var regadmnExp5 = RegExp('admin/publishrequests');
    var regadmnExp6 = RegExp('admin/activationsearch');
    var regadmnExp7 = RegExp('admin/taskadmin');
    var regadmnExp8 = RegExp('admin/client');
    var regadmnExp9 = RegExp('admin/client/edit');

    var result = (regadmnExp1.test(this.actRouteEdit) || regadmnExp2.test(this.actRouteEdit)
      || regadmnExp3.test(this.actRouteEdit) || regadmnExp4.test(this.actRouteEdit)
      || regadmnExp5.test(this.actRouteEdit) || regadmnExp6.test(this.actRouteEdit)
      || regadmnExp7.test(this.actRouteEdit || regadmnExp8.test(this.actRoute) || regadmnExp9.test(this.actRoute))
    );
    var routeBool = (this.actRoute === '/admin/patients' || this.actRoute === '/admin/doctors'
      || this.actRoute === '/admin/users' || this.actRoute === '/admin/hardsetotp'
      || this.actRoute === '/admin/app-master' || this.actRoute === '/admin/publishrequests'
      || this.actRoute === 'admin/activationsearch' || this.actRoute === 'admin/taskadmin'  || this.actRoute == '/admin/client'
      || this.actRoute == '/admin/client/edit/');

    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }

  }

  activeRouteConditions() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    var regadmnExp1 = RegExp('admin/hchome/edit');
    var regadmnExp2 = RegExp('admin/hcuserassign/edit');
    var regadmnExp3 = RegExp('admin/codegenerate/edit');
    var regadmnExp4 = RegExp('admin/campaign/edit');
    var regadmnExp5 = RegExp('admin/hccreate/edit');
    var regadmnExp6 = RegExp('admin/hctimelog');
    var regadmnExp7 = RegExp('admin/hcstatus');
    var regadmnExp8 = RegExp('admin/viewallactivation');
    var regadmnExp9 = RegExp('admin/createcodegen/edit/new');
    var regadmnExp10 = RegExp('admin/createcampaign/edit/new');
    var regadmnExp11 = RegExp('admin/createcampaign/edit');

    var result = (regadmnExp1.test(this.actRouteEdit) || regadmnExp2.test(this.actRouteEdit) || regadmnExp3.test(this.actRouteEdit)
      || regadmnExp4.test(this.actRouteEdit) || regadmnExp5.test(this.actRouteEdit) || regadmnExp6.test(this.actRouteEdit) || regadmnExp7.test(this.actRouteEdit)
      || regadmnExp8.test(this.actRouteEdit) || regadmnExp9.test(this.actRouteEdit) || regadmnExp10.test(this.actRouteEdit)
      || regadmnExp11.test(this.actRouteEdit));


    var routeBool = (this.actRoute === '/admin/hchome' || this.actRoute === '/admin/hcuserassign' || this.actRoute === '/admin/codegenerate' || this.actRoute === '/admin/campaign'
      || this.actRoute === 'admin/hccreate' || this.actRoute === 'admin/hctimelog' || this.actRoute === 'admin/hcstatus'
      || this.actRoute === 'admin/viewallactivation' || this.actRoute === 'admin/createcodegen' || this.actRoute === 'admin/createcampaign/edit/new'
      || this.actRoute === 'admin/createcampaign/edit' || this.actRoute === 'admin/activationsearch'|| this.actRoute == 'admin/client');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  activeRoutePayment() {
    this.actRoute = window.location.hash.slice(1, 20);
    this.actRouteEdit = window.location.hash;
    var regadmnExp1 = RegExp('admin/payment/edit');
    var regadmnExp2 = RegExp('admin/payment');
    var regadmnExp3 = RegExp('admin/paymentmapping/edit');
    var regadmnExp4 = RegExp('admin/paymentmapping');

    var result = (regadmnExp1.test(this.actRouteEdit) || regadmnExp2.test(this.actRouteEdit) || regadmnExp3.test(this.actRouteEdit) || regadmnExp4.test(this.actRouteEdit));
    var routeBool = (this.actRoute === '/admin/payment' || this.actRoute === '/admin/paymentmapping'
    );
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  activeRouteTask() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    var regadmnExp1 = RegExp('admin/task/edit');
    var regadmnExp2 = RegExp('admin/tasklibrary');


    var result = (regadmnExp1.test(this.actRouteEdit) || regadmnExp2.test(this.actRouteEdit));
    var routeBool = (this.actRoute === '/admin/tasklibrary' || this.actRoute === '/admin/tasklibrary'
    );
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }


  activeRouteTaskRequest() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    var regExp = RegExp('admin/taskrequest');
    var result = (regExp.test(this.actRouteEdit));
    var routeBool = (this.actRoute === 'admin/taskrequest' || this.actRoute === 'content/staticuuxid');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  ngOnDestroy(){
    this.observer.unsubscribe();
  }
}
