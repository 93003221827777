import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../../shared/shared.module';
import { MediainformationComponent } from './../../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../../shared/deletemodal/deletemodal.component';

import { QuestionnairComponent } from './questionnair.component';
import { QuestionnairRoutingModule } from './questionnair.routing.module';
import { ContentleftnavModule } from '../../shared/contentleftnav/contentleftnav.module';
import { ContentnavbarModule } from '../../shared/contentnavbar/contentnavbar.module';
import { FaqleftnavModule } from '../../shared/faqleftnav/faqleftnav.module';

@NgModule({
  declarations: [

    QuestionnairComponent,
  ],
  imports: [
    CommonModule,
    QuestionnairRoutingModule,
    ContentleftnavModule,
    ContentnavbarModule,
    FaqleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class QuestionnairModule { }
