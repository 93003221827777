<!-- /*==========================================
Title: Removed is_active columns
Author: Soundharya AM
Date:   May 07, 2020
Last Change :  Removed is_active columns
===========================================*/  -->
<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-faqleftnav></app-faqleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="content/questionnairmappinghome"></app-breadcrumb>
      <div class="col-md-8">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="create-btn" [routerLink]="['/content/questionnairmapping/edit',newsection]">
            Create New Questionnair Mapping</button>
        </h3>
      </div>

      <div class="col-md-12">
        <div class="row">

          <div class="col-md-12">
            <form>
              <div class="form-group has-feedback has-search">
                <i class="material-icons form-control-feedback">
                </i>
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>                
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''" style="width: 48px;text-align: left;">
            <a (click)="tableSort('all')" class="all">
              All
            </a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">
              Active
            </a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">
              Inactive
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">

          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table mapping-table">


            <ng-container matColumnDef="client_name" class="col-width">
              <th mat-header-cell *matHeaderCellDef style="width: 150px;"> Client name </th>
              <!-- <span [class]="element.is_active ? 'circle green' : 'circle red'"></span> -->
              <td mat-cell *matCellDef="let element"><span
                  [class]="element.is_active ? 'circle green' : 'circle red'"></span> {{element.client_name}} </td>
            </ng-container>

            <!-- Type -->

            <ng-container matColumnDef="condition_name" class="col-width">
              <th mat-header-cell *matHeaderCellDef style="width: 130px;"> Condition Name </th>
              <td mat-cell class="camelcased" *matCellDef="let element"> {{element.condition_name}} </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                        [routerLink]="['/content/questionnairmapping/edit', element.id]" class="no-hover-effect">Edit

                      </button>
                      <button mat-button disableRipple class="no-hover-effect active-green"
                        [ngClass]="{'my-class': element.is_active == true}"
                        (click)="isActive(element.is_active,element.id)">{{element.is_active? "Deactivate" :
                        "Activate"}}</button>
                      <button class="last-btn no-hover-effect" mat-button disableRipple
                        *ngIf="element.is_active == false" (click)="openDialog(element.id)">Delete</button>

                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>