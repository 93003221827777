import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ContextService as HealthCoachContextService  } from 'src/app/healthcoach/services/context.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { LabreportsComponent } from './labreports.component';


@NgModule({
  declarations: [
    LabreportsComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    LabreportsComponent
  ]
 
})
export class LabReportModule { }
