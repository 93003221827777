<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <div class="col-md-12">
      <h3 class="commontitle">Feedback Overview</h3>
    </div>
    <div class="col-md-12 feedback-tabs">
      <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Chapter">
          <div class="col-md-12 marginTop30">
            <app-feedbackviewertable [tableData]="dataList" matSort tableIndex=0 ></app-feedbackviewertable>
          </div>
        </mat-tab>
        <mat-tab label="Lesson">
          <div class="col-md-12 marginTop30">
            <app-feedbackviewertable [tableData]="dataList" tableIndex=1 ></app-feedbackviewertable>
          </div>
        </mat-tab>
        <mat-tab label="Quiz">
          <div class="col-md-12 marginTop30">
            <app-feedbackviewertable [tableData]="dataList" tableIndex=2 ></app-feedbackviewertable>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

</mat-drawer-container>
