<div [hidden]="showdeeplink == true">
    <div class="clearfix"><br/></div>
<form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate class="activatebottomsheet">
    <div class="col-md-6 col-xs-12">
        <mat-form-field appearance="outline" class="full-width-formfield" style="width: 90px;float: left;">
            <mat-label>Code</mat-label>
            <input matInput formControlName="country_code" minlength="1"
            maxlength="3" pattern="^\d+(?:\.\d{0,1})?$" oninput="validity.valid||(value='');" placeholder="Code">
            <mat-icon matPrefix>add</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-formfield" style="width: calc(100% - 90px);padding-left: 10px;">
            <mat-label>Phone Number</mat-label>
            <input matInput [pattern]="'^[0-9]+$'" minlength="10" maxlength="10"  formControlName="phone" placeholder="Phone Number">
        </mat-form-field>
    </div>
    <div class="col-md-6 col-xs-12">
        <mat-form-field appearance="outline" class="full-width-formfield">
            <mat-label>Select Campaign</mat-label>
            <mat-select formControlName="condition" placeholder="Select Campaign">
                <mat-option *ngFor="let camp of allCampaigns" value="{{camp.id}}" >
                    {{camp.title}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6 col-xs-12">
        <mat-form-field appearance="outline" class="full-width-formfield">
            <mat-label>Select Language</mat-label>
            <mat-select formControlName="language_data_fk" placeholder="Select Language">
                <mat-option *ngFor="let lang of alllanguages" value="{{lang.id}}">
                    {{lang.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-12 col-xs-12">
        <button mat-flat-button color="primary" class="centerbtn mt-15"
            [disabled]="!dataForm.valid">Activate
            Patient</button>
            <div class="clearfix"><br/></div>
    </div>

    <ng-template #buttonSubmitLoader>
        <app-loader showLoader="{{true}}"></app-loader>
    </ng-template>

</form>
<div class="clearfix"><br/></div>
</div>

<div class="sidenav-content" [hidden]="showdeeplink != true">
    <div id="content">
        <div class="container-fluid showdownload">
            <div class="qrboxp">
                <a href="#" style="font-size: 19px;word-break: break-all;">
                    {{alldata?.deeplink?.shortLink}}
                </a>
            </div>
            <div class="copyurlp">
                <button mat-flat-button (click)="copyURL()" class="commonbackbtn pull-right qrdownloadbtn"><i
                    class="material-icons">cloud_download</i> Copy Link</button>
            </div>
        </div>
    </div>
</div>