import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { MealComponent } from './meal.component';
import { EditmealComponent } from './editmeal/editmeal.component';
const routes: Routes = [
  { path: '', component: MealComponent, canActivate: [ContentGuardGuard] },
  { path: 'meal/edit/:id', component: EditmealComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MealRoutingModule { }
