import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HcleftnavModule } from '../shared/hcleftnav/hcleftnav.module';
import { SharedModule } from './../../shared/shared.module';
import { ContextService as HealthCoachContextService } from './../services/context.service';
import { HcsettingsComponent } from './hcsettings.component';
import { HcsettingsRoutingModule } from './hcsettings.routing.module';

@NgModule({
  declarations: [
    HcsettingsComponent
  ],
  imports: [
    CommonModule,
    HcsettingsRoutingModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    HcleftnavModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ]
})
export class HcsettingsModule { }
