import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() showLoader?: boolean;

  public loaderSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  ngOnInit() {
    /**Check For Loader */
    if (this.showLoader) {
      this.loaderSubject$.next(true);
    } else {
      this.loaderSubject$.next(false);
    }
  }

  ngOnDestroy() {
    //this.loaderSubject$.unsubscribe();
  }

}
