<div class="login-wrapper"  >
  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-6">
        <div class="bg-white">
          <div class="login-box">
            <!-- <div class="beta-box">
              <div style="margin-left:75px;">Go back to old app</div>
              <div style="margin-top: -23px;margin-bottom: 65px;">
                 <input type="checkbox" id="toggle_checkbox" (change)="switchBeta()">
                 <label for="toggle_checkbox"></label>
              </div>
              </div> -->
  
            
            <h4>Wellthy Therapeutics</h4>
            <p>{{displayVersion}}</p>
            <form [formGroup]="loginForm" validate autocomplete="off" style="height: 170px;">
              <div class="form-group" style="top: 0%;">
                <mat-form-field appearance="outline" class="col-md-12">
                  <mat-label>User name</mat-label>
                  <input matInput placeholder="Enter Username/Email" id="userName" type="email" formControlName="email"
                    autocomplete="off">
                </mat-form-field>
              </div>
              <div class="form-group" style="top: 0%;">
                <mat-form-field appearance="outline" class="col-md-12">
                  <mat-label for="username">Password</mat-label>
                  <input matInput (keydown.space)="$event.preventDefault();" id="pwd" placeholder="Enter Password"
                    type="password" [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="off" />
                  <mat-icon matSuffix (click)="hide = !hide">
                    <img alt="logo" src="assets/img/dls/icons/actiontable/Icon_View.svg" class="password-img"
                      [ngClass]="{grayimage: hide }">
                  </mat-icon>
                </mat-form-field>
                <button class="button1">
                </button>
              </div>
            </form>
            
            <div (click)="forgotPassword()"  class="pointer">
              <a style="text-align: right ; margin-top: 10px;float: right;margin-right: 29px;" target="_blank" class="tncandpolicy1" rel="noopener"> Forgot Password</a>
            </div>

            <ng-container *ngIf="captcha.enableCaptcha">
              <button 
                type="button" 
                id="recaptcha" 
                mat-flat-button 
                color="primary" 
                class="btn-filled btn-normal g-recaptcha"
                [disabled]="!loginForm.valid || settimer"
                data-sitekey="6Lcn2o4gAAAAAF2oqa71sJe1jxmgpzYJLvCqV6VJ"
                data-callback="captchaResolve"
                data-size="invisible"
                data-error-callback="captchaError"
              >

                {{settimer ? 'Unlocks in '+settimer : 'Login'}}

              </button>
            </ng-container>

            <ng-container *ngIf="!captcha.enableCaptcha">

              <button type="button" id="login" mat-flat-button color="primary"   (click)="login();" class="btn-filled btn-normal"
              [disabled]="!loginForm.valid || settimer">
              {{settimer ? 'Unlocks in '+settimer : 'Login'}}
              </button>

            </ng-container>

            <div class="agreement">
              By clicking Login, you agree to the Wellthy <a href="https://documents.wellthy.app/termsandcondition"
                target="_blank" class="tncandpolicy" rel="noopener">Terms and Condition</a> and <a
                href="https://documents.wellthy.app/privacypolicy" target="_blank" class="tncandpolicy" rel="noopener">Privacy
                Policy</a>
            </div>
            
            <div class="loginuiline">
              <div class="dashliner"></div>
              <div class="orcss">Or</div>
            </div>

            <!-- <button mat-flat-button color="warn" class="fullwidth loginbtn" (click)="signInWithGoogle()" data-onsuccess="onSignIn">
              <img alt="logo" class="imgsvg" src="assets/img/dls/icons/glogo.svg"> &nbsp;Login with wellthy.care
            </button> -->

            <div class="google-login-wrapper">
              <div id="googleBtn"></div>
            </div>
            
            <div class="help">
              <a href="#">Trouble logging in?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- 
<div class="container-fluid">
  <div class="row">
    <div class="col-md-7 leftbrandbg">
      <img src="assets/img/loginart.png" width="95%" />
    </div>
    <div class="col-md-4">
      <div class="loginbox1" *ngIf="!showloginform">
        <img class="logocss" src="assets/img/wellthynewlogo.png" />
        <div class="clearfix"><br /><br /><br /></div>
        <form [formGroup]="loginForm" (ngSubmit)="loginObject()" validate autocomplete="off">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput placeholder="Enter Email" type="text" formControlName="email" autocomplete="off" />
            <mat-icon matSuffix>person</mat-icon>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput (keydown.space)="$event.preventDefault();" placeholder="Enter Password" type="password"
              formControlName="password" autocomplete="off" />
            <mat-icon matSuffix>lock</mat-icon>
          </mat-form-field>

          <a class="forgetpassword hidden">Forget password?</a>
          <br />
          <button mat-flat-button color="primary" [disabled]="!captchadone || !loginForm.valid || settimer"
            class="fullwidth blackcolor loginbtn loginbtn-a">{{settimer ? 'Unlocks in '+settimer : 'Login via
            email'}}</button>
          <br />
        </form>
        <div class="loginuiline">
          <div class="dashliner"></div>
          <div class="orcss">Or</div>
        </div>
        <button mat-flat-button color="warn" class="fullwidth loginbtn" (click)="signInWithGoogle()"
          data-onsuccess="onSignIn"><img class="imgsvg"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIKICAgICB2aWV3Qm94PSIwIDAgMjQgMjQiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDsiPiAgICA8cGF0aCBkPSJNMTIsMkM2LjQ3NywyLDIsNi40NzcsMiwxMmMwLDUuNTIzLDQuNDc3LDEwLDEwLDEwczEwLTQuNDc3LDEwLTEwQzIyLDYuNDc3LDE3LjUyMywyLDEyLDJ6IE0xMCwxNmMtMi4yMDksMC00LTEuNzkxLTQtNCBjMC0yLjIwOSwxLjc5MS00LDQtNGMxLjA0MiwwLDEuOTgyLDAuNDA4LDIuNjk0LDEuMDYxbC0xLjA5MywxLjAzNEMxMS4xNjcsOS43MjksMTAuNjEzLDkuNSwxMCw5LjVjLTEuMzgxLDAtMi41LDEuMTE5LTIuNSwyLjUgYzAsMS4zODEsMS4xMTksMi41LDIuNSwyLjVjMS4yMDksMCwyLjIxOC0wLjg1OSwyLjQ1LTJIMTBWMTFoMy44NTlDMTMuOTQyLDExLjMyMSwxNCwxMS42NTMsMTQsMTJDMTQsMTQuMjA5LDEyLjIwOSwxNiwxMCwxNnogTTE4LDEydjEuNWgtMVYxMmgtMS41di0xSDE3VjkuNWgxVjExaDEuNXYxSDE4eiI+PC9wYXRoPjwvc3ZnPg==">
          &nbsp;Login with Google</button>
        <div class="col-md-12 agreement">
          By clicking Login, you agree to the Wellthy <a href="https://documents.wellthy.app/termsandcondition"
            target="_blank" class="tncandpolicy">Terms and Condition</a> and <a
            href="https://documents.wellthy.app/privacypolicy" target="_blank" class="tncandpolicy">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="col-md-1">
    </div>
  </div>
</div> -->


<div id="google_translate_element" class="google-translate"></div>
