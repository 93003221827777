import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobileChangeRequestRoutingModule } from './mobile-change-request-routing.module';
import { SharedModule } from './../../shared/shared.module';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';

import { MobileChangeNumberRequestComponent } from './mobile-change-number-request.component';
import { NumberChangeActionComponent } from './number-change-action/number-change-action.component';

@NgModule({
  declarations: [
    MobileChangeNumberRequestComponent,
    NumberChangeActionComponent
  ],
  imports: [
    CommonModule,
    MobileChangeRequestRoutingModule,
    SharedModule.forRoot(),
    AdminleftnavModule,
    AdminsectionleftnavModule,
  ]
})

export class MobileChangeRequestModule { }
