import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { AuthenticateService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../services/context/context.service';

@Component({
  selector: 'app-docleftnav',
  templateUrl: './docleftnav.component.html',
  styleUrls: ['./docleftnav.component.scss']
})

export class DocleftnavComponent implements OnInit {
  public haveAlerts: boolean = false;
  public dummyImage: string = 'assets/img/imgplaceholder.svg';
  public imageurl: string;
  public responseImageUrl: any;
  s3url: any = environment.s3url;
  ws: WebSocket;
  hasUnreadAlert: boolean;
  notificationPanel: any;
  allalerts = [];
  totalAlerts: number;
  unReadAlerts: number;
  readAlerts: number;
  rawdata: any;
  todaydate: any;
  public actRoute: any;
  doctorData: any;
  checker: boolean;
  activeRoute: string;
  constructor(private ContextService: ContextService, public dialog: MatDialog, private AuthenticateService: AuthenticateService) { }

  ngOnInit() {
    // window.document.title = "Doctor Dashboard";
    //this.getProfileImage();
    this.ContextService.docSystemHaveAlerts.subscribe(data => {      
      this.haveAlerts = data;
    });
    this.doctorData = JSON.parse(localStorage.getItem('scarletuserdata'));
  

  }

  /**
   * @name getProfileImage
   * @description Getting the profile image from AWS's S3. If the image is not there, display the dummy image.
   * @param No_param
   * @returns NULL
   */

  alertClicked() {
    this.sendEventToCleverTap("Alert Tab Clicked");
  }
  profileSecClicked() {
    
    this.sendEventToCleverTap("Profile Section Clicked");
  }
  patientsecClicked() {
    let data={
      "selectedpatent":'',
      "selectedpatentflag":false
    }
    localStorage.removeItem("selectedNotification");
    localStorage.removeItem("notifyPatientName");
    localStorage.removeItem("patient_id");
    localStorage.removeItem("selectedCondition");
    localStorage.removeItem('createdDateTime');
    localStorage.setItem("selectedpatentdata",JSON.stringify(data));
    this.sendEventToCleverTap("Patient Section Clicked");
  }

  followupClicked(){
    this.sendEventToCleverTap("Follow Up Button Clicked");
  }
  patientClicked(){
    this.sendEventToCleverTap("Patient Clicked");
  }
  
  reportDownloadedClicked(){
    this.sendEventToCleverTap("Report downloaded Clicked");
  }
  
  bloodSugarReportClicked(){
    this.sendEventToCleverTap("Blood Sugar report Visualization Clicked");
  }
  weightReportClicked(){
    this.sendEventToCleverTap("Weight report visualization Clicked");
  }
bPReportClicked(){
    this.sendEventToCleverTap("BP report visualization Clicked");
  }
  activityReportClicked(){
    this.sendEventToCleverTap("Activity report visualization Clicked");
  }
  prescriptionsClicked(){
    this.sendEventToCleverTap("Prescriptions Clicked");
  }
  medicationsClicked(){
    this.sendEventToCleverTap("Medications Clicked");
  }
  timeSpentDashboardClicked(){
    this.sendEventToCleverTap("Time spent in dashboard ");
  }

  sendEventToCleverTap(evetname) {
    let datap =
    {
      "d": [
        {
          "identity": localStorage.getItem('scarletuserid'),
          "ts": (Math.floor(new Date().valueOf() / 1000)),
          "type": "event",
          "evtName": evetname,
          "evtData": {
            "Accessed": "Yes"
          }
        }
      ]
    }
    this.AuthenticateService
      .sendEventData(datap)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
      })
  }

  activeRouteConditions() {
    this.activeRoute = window.location.hash;
    var regdocExp1 = RegExp('doctor/user');
    var result = (regdocExp1.test(this.activeRoute));
    if (result) {
      return 'active';
    } else {
      return '';
    }
  }

}
