import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../services/context/context.service';
import { DoctorDataService } from '../services/doctor/doctor.service';
import * as moment from 'moment';

@Component({
  selector: 'app-docalerts',
  templateUrl: './docalerts.component.html',
  styleUrls: ['./docalerts.component.scss']
})
export class DocalertsComponent implements OnInit {
   public isactive = 'all';
  public showloader: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  searchcriteria: any;
  public page: number = 1;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public alltags: any;
  public filteredtabulardata: any;
  public tagFilters: any;
  public filteredtags: any = [];
  public status: any = 'active';
  public typeFilters: any;
  public filtercategory: any = null;
  public filterval: any = null;
  public notiftype: any = 'all';
  public delayedLoaderSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isLoading = false;
  isUpdating = false;
  dataloaded = false;
  public timeZone:any;
  public notificationtypes = [
    ['patient_assigned', 'Patient Assigned'],
    ['patient_reassigned', 'Patient Reassigned'],
    ['patient_note_event', 'Patient Note'],
    ['adverse_reaction_event', 'Adverse Effect'],
    ['medication_added_event', 'Medication Added'],
    ['medication_updated_event', 'Medication Updated'],
    ['pump_added_event', 'Pump Added'],
    ['pump_updated_event', 'Pump Updated'],
  ]
  constructor(
    private SystemService: SystemService,
    private appContext: ContextService,
    private router: Router,
    private browserTitle: Title,
    private doctorService: DoctorDataService,
    private translate: TranslateService
  ) {

  }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Doctor | Notifications');
  }
  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
    this.appContext.docSystemHaveAlerts.next(false);
   
  }

  covertTime(date){
    // var newDate = new Date(date);
    // var convertedDate = newDate.toLocaleString('en-US', { timeZone: this.timeZone })
    // 
    // return convertedDate
    var newDate = new Date(date);
    newDate.toLocaleString();
    // 
    return newDate; 
    // var convertedDate = newDate.toLocaleString('en-US', { timeZone: this.timeZone })
    // return convertedDate
  }

  ngOnDestroy() {

  }

  tableSort(e, typer) {
    this.filterval = e;
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
    this.notiftype = typer;
  }

  /**
   * @name readNotification
   * @description Mark as read for one notification
   * @param ide
   * @returns NULL
   */


  typeFilter(e) {
    var pru = JSON.stringify(e).replace('["', '').replace('"]', '').replace(/\"/g, '');
    this.filtercategory = (e != '' ? pru : null);
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
  }
  getNextPage() {

    this.page++;
    this.search();
  }

  starfunc(ide, index, starval) {
    var status = starval ? 'unstar' : 'star';
    this.doctorService.starunstar(ide, status)
      .subscribe(data => {
        this.tabulardata[index].patient_starred = !starval;
      })
  }
  markallread(ide) {
    this.doctorService.markallread(ide)
      .subscribe(data => {
      })
  }

  openChatOption(userID) {
    this.appContext.docSystemHaveAlerts.next(false);
    this.appContext.showSelectedNotificationUser.next(userID);
    /**Navigate to page now */
    this.router.navigate(['/doctor/user/' + userID + '/' + 'do_follow']);
  }


  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  setstatus(type, value) {
    if (type == 'reminder_added_event' || type == 'reminder_updated_event') {
      if (value != null) {
        return JSON.parse(value).category ? JSON.parse(value).category : '' + " Reminder"
      }
      else {
        return "New Reminder"
      }
    }
    else if (type == 'medicine_added_event') {
      return "Medicine Added"
    }
    else if (type == 'medicine_updated_event') {
      return "Medicine Updated"
    }
    else if (type == 'low_bp_event') {
      return "Low Blood Pressure"
    }
    else if (type == 'high_bp_event') {
      return "High Blood Pressure"
    }
    else if (type == 'low_blood_sugar_event') {
      return "Low Blood Sugar"
    }
    else if (type == 'high_blood_sugar_event') {
      return "High Blood Sugar"
    }
    else if (type == 'blood_sugar_event') {
      return "Blood Sugar Logged"
    }
    else if (type == 'onboarding_event') {
      return "On Boarded"
    }
    else if (type == 'prescription_upload') {
      return "Prescription Added"
    }
    else if (type == 'prescription_update') {
      return "Prescription Updated"
    }
    else if (type == 'caregiver_added') {
      return "Caregiver Added"
    }
    else if (type == 'caregiver_updated') {
      return "Caregiver Updated"
    }
    else if (type == 'lab_report_event') {
      return "Lab Report Added"
    }
    else if (type == 'doctor_assignment_event') {
      return "Doctor Assigned"
    }
    else if (type == 'doctor_reassignment_event') {
      return "Doctor Reassigned"
    }
    else if (type == 'patient_transfer_event') {
      return "Transferred to you"
    }
    else if (type == 'patient_note_event') {
      return "Patient Note"
    }
    else if (type == 'trigger_event') {
      return "Trigger Values Added"
    }
    else if (type == 'questionnaire_event') {
      return "Questionnaire Logged"
    }
    else if (type == 'symptom_event') {
      return "Symptom Added"
    }
    else if (type == 'daily_goals') {
      return "Daily Goals Added"
    }
    else if (type == 'peak_flow') {
      return "Peak Flow Added"
    }
    else if (type == 'activity_event') {
      return "Activity Event"
    }
    else if (type == 'weight_event') {
      return "Weight Added"
    }
    else if (type == 'water_alert_event') {
      return "Water Added"
    }
    if (type == 'patient_assigned') {
      return "Patient Assigned"
    }
    else if (type == 'patient_reassigned') {
      return "Patient Reassigned"
    }
    else if (type == 'adverse_reaction_event') {
      return "Adverse Event"
    }
    else if (type == 'medication_added_event') {
      return "Medication Added"
    }
    else if (type == 'medication_updated_event') {
      return "Medication Updated"
    }
    else if (type == 'pump_added_event') {
      return "Pump Added"
    }
    else if (type == 'pump_updated_event') {
      return "Pump Updated"
    }
    else {
      return type
    }

  }


  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.doctorService.getAllNotifications(this.searchcriteria, this.page.toString(), "20", this.status, this.filtercategory, this.filterval)
        .subscribe(
          (res: any) => this.onSuccessAllNotification((res as any).data),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        );
    }
  }

  onSuccessAllNotification(data) {
    this.delayedLoaderSubject$ = new BehaviorSubject<any>(null);
    this.delayedLoaderSubject$.next(["Alerts"]);
    this.isLoading = false;
    this.isUpdating = false;
    this.dataloaded = true;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
    }
  }
  notificationVerify(selectedNotification, patientName, selectedCondition, createdDateTime, crreatedID,patient_id) {

    if (selectedNotification == 'medication_added_event' || selectedNotification == 'medication_updated_event' ||
      selectedNotification == 'medication_survey_added' || selectedNotification == 'prescription_upload' ||
      selectedNotification == 'prescription_update' || selectedNotification == 'reminder_added_event' ||
      selectedNotification == 'reminder_updated_event' || selectedNotification == 'medicine_added_event' ||
      selectedNotification == 'medicine_updated_event' || selectedNotification == 'low_bp_event' ||
      selectedNotification == 'high_bp_event' || selectedNotification == 'low_blood_sugar_event' ||
      selectedNotification == 'meal_event' || selectedNotification == 'infusion_site_event' ||
      selectedNotification == 'high_blood_sugar_event' || selectedNotification == 'blood_sugar_event' ||
      selectedNotification == 'caregiver_added' || selectedNotification == 'caregiver_updated' ||
      selectedNotification == 'lab_report_event' || selectedNotification == 'doctor_assignment_event' ||
      selectedNotification == 'doctor_reassignment_event' || selectedNotification == 'patient_note_event' ||
      selectedNotification == 'trigger_event' || selectedNotification == 'questionnaire_event' ||
      selectedNotification == 'symptom_event' || selectedNotification == 'peak_flow' ||
      selectedNotification == 'activity_event' || selectedNotification == 'weight_event' ||
      selectedNotification == 'water_alert_event' || selectedNotification == 'adverse_reaction_event' ||
      selectedNotification == 'pump_added_event' || selectedNotification == 'pump_updated_event' || 'diet_plan_created_event') {
      this.router.navigateByUrl('/doctor/home');
      localStorage.setItem("selectedNotification", selectedNotification);
      localStorage.setItem("notifyPatientName", patientName);
      localStorage.setItem("patient_id", patient_id);
      localStorage.setItem("selectedCondition", selectedCondition);
      localStorage.setItem('createdDateTime', createdDateTime);
      let logDate = JSON.parse(crreatedID);
      localStorage.setItem('createdId', logDate.id);
    }
  }
}
