<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-media-setupleft-nav></app-media-setupleft-nav>
    <div class="mainrightcontainersecondary">

      <app-breadcrumb route="content/media"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <button mat-stroked-button color="primary" class="create-btn" (click)="openUploadMedia()">
            Upload Media
          </button>
        </h3>
      </div>

      <!-- Filter and Search Bar -->
      <div class="col-md-12">
        <div class="row">

          <div class="col-md-2">
            <button mat-button [matMenuTriggerFor]="filterbroadcast" class="sortcommonwhite media-filter-button">
              <span>
                Filter 
              </span>

              <div class="dropdown-arrow"></div>
            </button>

            <mat-menu #filterbroadcast="matMenu" class="custom-menu">
              <form [formGroup]="dataForm" validate class="filter-form">
                <div class="outerlayout">
                  <div (click)="$event.stopPropagation();">
                    <div class="title first">
                      Select Media Type
                    </div>
                    <mat-radio-group aria-label="Select an option" formControlName="mediatype">
                      <mat-radio-button value="image">Image</mat-radio-button>
                      <mat-radio-button value="audio">Audio</mat-radio-button>
                      <mat-radio-button value="video">Video</mat-radio-button>
                      <mat-radio-button value="all">All</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div (click)="$event.stopPropagation();">
                    <div class="title">
                      Select Status Type
                    </div>
                    <mat-radio-group aria-label="Select an option" formControlName="statustype">
                      <mat-radio-button value="active">Active</mat-radio-button>
                      <mat-radio-button value="inactive">Inactive</mat-radio-button>
                      <mat-radio-button value="all">All</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="title">
                    Select Date
                  </div>
                  <div class="row">

                    <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-6">
                      <mat-label>Choose start date</mat-label>
                      <input matInput formControlName="startdate" [matDatepicker]="picker1"
                        (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [max]="morrow"
                        placeholder="Choose a date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-6">
                      <mat-label>Choose end date</mat-label>
                      <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="enddateval" [max]="morrow" placeholder="Choose a date">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="example-button-row">
                        <button mat-flat-button color="primary" class="labreportbtn" (click)="applymainfilter()" [disabled]="!dataForm.valid">Apply</button>
                        <button mat-flat-button class="secondary" (click)="cancelfilter()">Cancel</button>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="example-button-row">
                        <button mat-flat-button class="secondary" (click)="clearfilter()">Clear Filter</button>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </mat-menu>
          </div>

          <div class="col-md-10">
            <div class="form-holder">
              <form>
                <div class="form-group has-feedback has-search">
                  <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                    placeholder="Search">
                  <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                    <mat-icon matSuffix>search</mat-icon>
                  </button>
                </div>
              </form>
            </div>
          </div>
          
        </div>
      </div>

      <!-- Table Wrapper -->
      <div class="col-md-12">

        <div class="example-container" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">

          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>

          <table mat-table matSort [dataSource]="dataSource"  class="bordergrey list-table-media list-table">

            <!-- Media Column -->
            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef> Media </th>
              <td mat-cell *matCellDef="let element">

                <div class="img-fit">
                  <img style="padding: 16px 0 16px 12px;" [src]="s3url + element.thumbnail_path" />
                </div>

                <span class="circle" [ngClass]="element.is_active ? 'green': 'gray'"></span>
              </td>
            </ng-container>


            <!-- File name Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef > File name </th>
              <td mat-cell *matCellDef="let element">
                 {{element.title | titlecase}}
                <br />
                <span>{{element.description}}</span>
              </td>
            </ng-container>

            <!-- File Type -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef style="min-width: 20%;"> File Type </th>
              <td mat-cell *matCellDef="let element"> {{element.type | titlecase}} </td>
            </ng-container>

            <!-- Last Updated -->
            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width:20%;">
                Last updated
              </th>
              <td mat-cell *matCellDef="let element"> {{element.updated_at | daysago }} </td>
            </ng-container>

            <!-- Action -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">

                <div class="action-button-row">
                  <button mat-button (click)="openMedia(element)" tooltip="View Media">
                    <div class="button-bg-image view-btn"></div>
                  </button>

                  <ng-container *ngIf="element.is_active">
                    <button mat-button tooltip="De-activate" (click)="changeActivation(element.is_active,element.id)">
                      <div class="button-bg-image deactivate-btn"></div>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="!element.is_active">
                    <button mat-button tooltip="Activate" (click)="changeActivation(element.is_active,element.id)">
                      <div class="button-bg-image activate-btn"></div>
                    </button>
                  </ng-container>

                  <button mat-button tooltip="Delete" [disabled]="element.is_active" (click)="deleteMedia(element.id)">
                    <div class="button-bg-image delete-btn" [disabled]="element.is_active"></div>
                  </button>

                </div>
              </td>
            </ng-container>

            <!-- TODO: 1060 -->
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail">
                  <div class="menu-holder">
                    <div class="example-button-row table-btn example-button-mediamanager">

                      <button mat-button disableRipple class="no-hover-effect"
                        style="padding-left: 0;text-align: left;width: auto;" (click)="openMedia(element)">View</button>
                      <button mat-button disableRipple class="no-hover-effect active-green"
                        [ngClass]="{'my-class': element.is_active == true}"
                        (click)="isActive(element.is_active,element.id)">{{element.is_active? "Deactivate" : "Activate"}}</button>

                      <button class="last-btn no-hover-effect" mat-button disableRipple *ngIf="element.is_active == false"
                        (click)="openDialog(element.id)">Delete</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="pos-relative" ></tr>

          </table>
        </div>

      </div>
    </div>
  </div>

</mat-drawer-container>