import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { SystemService } from '../shared/services/system.service';
import { CommonserviceService } from '../admin/services/commonservice/commonservice.service';
import { MatStepper } from '@angular/material/stepper';

export interface Food {
  value: string;
  display: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {

  public hideNewPwd = true;
  public hideConfirmPwd = true;
  private environmentdata: any = environment;
  displayVersion: any;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  dataForm: FormGroup;
  error: boolean = false;
  token: any;
  message: any;
  otpDataAfterVerification: any
  otpData: any;
  otpVerified: any;
  OtpSuccessDetect: any;
  doctorCodeDetect!: boolean;
  inputValidate: any;
  doctorCode_UserNameValidate: boolean = false;
  doctorCode_ErrorMessage: any;
  UserEmailFlag: boolean = true;
  newPwdFlag: boolean = false;
  otpFlag: boolean = false;
  matchPassword: any;
  matchPassword1: any;
  comparePasswords: any;
  NewPassword: any;
  ConfirmPassword: any;
  refresh: string;

  enableCaptcha: boolean = false;
  enableDonebtn: boolean = false;

  constructor(
    private _formBuilder: FormBuilder, 
    public router: Router, 
    private systemService: SystemService, 
    public commonService: CommonserviceService,
    public zone: NgZone
  ) {
    
    this.refresh = window.localStorage.getItem('refresh');

    if (this.refresh===null){
        window.location.reload();
        window.localStorage.setItem('refresh', "1");
    }

    // used for recaptcha
    let that = this;

    window["captchaResolve"] = function($event){
      that.zone.run(() => {
        that.captchaResolved($event);
      });
    }

    window["captchaError"] = function(){
      that.zone.run(() => {
        that.captchaError();
      });
    }
   }

  ngOnInit() {
    this.enableCaptcha = this.environmentdata.captcha;

    this.displayVersion = this.environmentdata.publishVersion;
    this.firstFormGroup = this._formBuilder.group({
      doctor_code: ['', Validators.required],
    });
  
    this.secondFormGroup = this._formBuilder.group({
      otp: ['', Validators.required]
    });
    this.dataForm = this._formBuilder.group({
      new_password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      { validator: this.checkPasswords }
    );
  }

  captchaResolved(captchaResponse: string): void {
    if (captchaResponse == null) {
      this.systemService.showSnackBar("Error in reCaptcha Verification", "OK");
    } else {
      this.commonService.captchaResponse = captchaResponse;
      this.createPassword();
    }
  }

  captchaError(){
    this.systemService.showSnackBar("Error in reCaptcha Verification", "OK"); 
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirmPassword').value;
    if(pass === undefined || confirmPass === undefined || pass === null || confirmPass === null ||
      pass === '' || confirmPass === ''){
      this.enableDonebtn = false;
    }
    if (pass == confirmPass) {
      return pass === confirmPass ? null : { notSame: true }
    }else{
      this.enableDonebtn = false;
    }
  }

  PasswordTextChange(event: any) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    this.matchPassword = re.test(event.target.value);
    if (this.matchPassword == true) {
      this.NewPassword = event.target.value;
      if(this.NewPassword !== '' || this.NewPassword !== undefined  && this.ConfirmPassword !== '' || this.ConfirmPassword !== undefined){
        if ((this.NewPassword != this.ConfirmPassword)) {
          this.comparePasswords = "PasswordNotMatched";
          this.enableDonebtn = false;
        }else{
          this.comparePasswords="";
          this.enableDonebtn = true;
        }
      }
    }else{
      this.comparePasswords="";
      this.enableDonebtn = false;
    }
  }

  confirmPasswordTextChange(event: any) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    this.matchPassword1 = re.test(event.target.value);
    if (this.matchPassword1 == true) {
      this.ConfirmPassword = event.target.value;
      if(this.NewPassword !== '' || this.NewPassword !== undefined  && this.ConfirmPassword !== '' || this.ConfirmPassword !== undefined){
        if ((this.NewPassword != this.ConfirmPassword)) {
          this.comparePasswords = "PasswordNotMatched";
          this.enableDonebtn = false;
        }else{
          this.comparePasswords="";
          this.enableDonebtn = true;
        }
      }
    }else{
      this.comparePasswords="";
      this.enableDonebtn = false;
    }
  }

  back() {
    this.router.navigate(['/login']);
  }

  public ngAfterViewInit() {
    
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  sendOTP(stepper) {
    //if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.firstFormGroup.value))
    if (this.firstFormGroup.controls['doctor_code'].value.length > 6) {
      this.inputValidate = "Email";
    } else {
      this.inputValidate = "Doctor Code";
    }
    
    this.commonService.sendOTP(this.firstFormGroup.value)
      .subscribe(async (res: any) => {
        
        let PhoneNumber = this.replacePhoneNumbers(res.data.phone);;
        
        this.systemService.showSuccessSnackBar(("OTP has been sent to the phone number" + PhoneNumber), "OK");
        this.otpData = res.data;
        this.OtpSuccessDetect = res.status;
        this.doctorCode_UserNameValidate = false;

        this.goForward(stepper);
      },
        (err: HttpErrorResponse) => {
          // this.systemService.handleErrorResponse(err)
          
          this.doctorCode_UserNameValidate = true;
          this.doctorCode_ErrorMessage = err.error.message;
          this.otpFlag = false;
          // this.goBack()
        }
      )
  }

  replacePhoneNumbers(number) {
    let cuantos = number.length;
    let tele = [];
    for (let i = 0; i < cuantos; i++) {
      if (i < 8) {
        tele[i] = number[i].replace(/^\d+$/, "*");
      } else {
        tele[i] = number[i];
      }
    }

    let full_phone = "";
    for (let i = 0; i < cuantos; i++) {
      full_phone += tele[i];
    }
    return full_phone;
    //return number;
  }

  keyFunc() {
    this.doctorCode_UserNameValidate = false;
    this.otpFlag = true;
  }

  verifyOTP() {
    
    let data1: any = {}
    data1.otp = this.secondFormGroup.controls['otp'].value,
      data1.phone = this.otpData.phone;
    data1.country_code = this.otpData.country_code.toString();

    
    this.commonService.verifyOTP(data1)
      .subscribe((res: any) => {
        
        // if (res.status == 200) {
        this.otpVerified = res.status;
        this.otpDataAfterVerification = res.data;
        this.token = res.data.id_token;
        this.systemService.showSuccessSnackBar(("OTP Verified"), "OK");
        // } else {
        //   this.systemService.showSuccessSnackBar(res.message, "OK");
        // }
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  createPassword() {
    
    let newPasswordData: any = {}
    newPasswordData.token = this.token;
    newPasswordData.new_password = this.dataForm.controls.new_password.value;
    newPasswordData.phone = this.otpData.phone;
    newPasswordData.source_user_id = this.otpData.id.toString();
    newPasswordData.country_code = this.otpData.country_code.toString();
    newPasswordData.user_id_fk = this.otpData.id.toString();
    newPasswordData.id = this.otpData.id.toString();;
    newPasswordData.password_changed_via = this.inputValidate;

    let loginUser = localStorage.getItem('app')
    if (loginUser) {
      newPasswordData.source_of_password_change = loginUser;
    }
    else {
      newPasswordData.source_of_password_change = "self";
    }
    
    if (this.hasWhiteSpace(this.dataForm.value.new_password) == false) {
      this.commonService.createPassword1(newPasswordData)
        .subscribe(
          (res: any) => this.onStatusSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {  
      this.error = true;
      if (this.hasWhiteSpace(this.dataForm.value.new_password) == true) {
        this.message = "Enter valid password, Your password should not be empty or contain spaces";
      }
      this.systemService.showSnackBar((this.message), "OK");
    }
  }

  private async onStatusSuccess() {
    this.systemService.showSuccessSnackBar(("The password has been updated"), "OK");
    await this.sleep(3000)
    this.router.navigate(['/login']);
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  ngOnDestroy(){
    localStorage.removeItem('refresh');
  }

  // TextChange(event: any) {

  //   if (event.target.value.includes(".com") || event.target.value.length > 6) {
  //     this.doctorCodeDetect = true
  //     this.systemService.showSnackBar("Please Enter Valid Doctor code", "Ok");
  //   }
  //   else if(event.target.value.length < 6)
  //   {
  //     this.doctorCodeDetect = false
  //    // this.systemService.showSnackBar("Please Enter Valid Doctor code", "Ok");
  //   }else if(event.target.value.length == 6)
  //   {
  //     this.doctorCodeDetect = false     
  //   }
  // }
}
