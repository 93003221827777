import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-update-val',
  templateUrl: './update-val.component.html',
  styleUrls: ['./update-val.component.scss']
})

export class UpdateValComponent implements OnInit {
  public dataForm = this.fb.group({
    version_data: [''],
    email: [''],
    phone_number: ['']
  });
  appMasterData: any;
  showloader: any;
  app_version_data: boolean = false;
  phonenumber_data: boolean = false;
  email_data: boolean = false;
  
  constructor(
    private browserTitle: Title,
    private commonService: CommonserviceService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateValComponent>,
    private systemService: SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update App Master');
  }

  ngOnInit(): void {
    this.setSEOTags();
    this.appMasterData = this.data.element;
    if (this.appMasterData.key == 'IOS_MIN_APP_VERSION' || this.appMasterData.key == 'IOS_CURRENT_APP_VERSION' || this.appMasterData.key == 'ANDROID_MIN_APP_VERSION' || this.appMasterData.key == 'ANDROID_CURRENT_APP_VERSION') {
      this.appMasterData.version_data = this.appMasterData.value;
      this.app_version_data = true;
    }
    else if (this.appMasterData.key == 'WELLTHY_DEFAULT_PHONE_NUMBER') {
      this.appMasterData.phone_number = this.appMasterData.value;
      this.phonenumber_data = true;
    }
    else if (this.appMasterData.key == 'WELLTHY_DEFAULT_EMAIL_ID') {
      this.appMasterData.email = this.appMasterData.value;
      this.email_data = true;
    }

    this.dataForm.patchValue(this.appMasterData);
  }

  // Submitting AppMaster Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    var data;
    if (this.appMasterData.key == 'IOS_MIN_APP_VERSION' || this.appMasterData.key == 'IOS_CURRENT_APP_VERSION' || this.appMasterData.key == 'ANDROID_MIN_APP_VERSION' || this.appMasterData.key == 'ANDROID_CURRENT_APP_VERSION') {
      data = postdata.version_data;
    }
    else if (this.appMasterData.key == 'WELLTHY_DEFAULT_PHONE_NUMBER') {
      data = postdata.phone_number;
    }
    else if (this.appMasterData.key == 'WELLTHY_DEFAULT_EMAIL_ID') {
      data = postdata.email;
    }
    this.commonService.updateAppMasterData(this.appMasterData.id, data)
    .subscribe(
      (res: any) => this.onUpdateSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onUpdateSuccess() {
    this.dialogRef.close();
  }

  // ----------------------------

}
