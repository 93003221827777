import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../content/services/contentservice/contentservice.service';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-changeuserrole',
  templateUrl: './changeuserrole.component.html',
  styleUrls: ['./changeuserrole.component.scss']
})
export class ChangeuserroleComponent implements OnInit {
  public allhc: any;
  public selectedOption: any;
  userhc: any;
  public showloader: boolean = false;
  public localStoredHCData: any;


  constructor(private commonService: CommonserviceService,private browserTitle: Title, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService,
    public dialogRef: MatDialogRef<ChangeuserroleComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Health Coach');
  }

  ngOnInit() {
    this.userhc = this.data.element;   
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
  }


  changeRole() {  
    let tempdata = {};
    tempdata['user_type'] = this.selectedOption;
    tempdata['user_role'] = this.selectedOption + "-role";
    tempdata['user_acl'] = JSON.stringify([this.selectedOption]);
    tempdata['tc_accepted'] = this.userhc.tc_accepted.toString();
    tempdata['profile_image'] = this.userhc.profile_image.toString();
    tempdata['std_code'] = this.userhc.std_code.toString();
    tempdata['phone'] = this.userhc.phone;
    tempdata['display_name'] = this.userhc.display_name;
    tempdata['display_bio'] = this.userhc.display_bio;
    tempdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    this.commonService.updateToBlade(this.userhc.id, tempdata, 'users')
      .subscribe(
        (res: any) => {         
          this.closeModal();
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  // private onChangeRoleSuccess(role, id) {
  //   for (var i = 0; i < this.tabulardata.length; i++) {
  //     if (this.tabulardata[i].id == id) {
  //       this.tabulardata[i].user_type = role;
  //     }
  //   }
  //   this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  //   this.systemService.showSuccessSnackBar(("User Role has been updated"), "OK");
  // }

  

  // ----------------------------

  closeModal(): void {
    this.dialogRef.close();
  }


}

export enum TrueFalsedata {
  'true',
  'false'
}
