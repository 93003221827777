<div class="col-md-12 clearfix">
  <h2 class="pull-left title-modal"> {{newitem ? 'New' : 'Update'}} Doctor
  </h2>
  <div class="pull-right">
    <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
  </div>
</div>

<mat-dialog-content>

  <div class="col-md-12">
    <div class="form-wrapper">
      <form [formGroup]="dataForm" validate>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <!-- <div class="image-placeholder">
                  <mat-spinner class="upload-spinner" *ngIf="isloading"></mat-spinner>
                  <div class="profile-image" *ngIf="!isloading">
                    <div class="profile-dummy-img" *ngIf="!uploadedparam">
                      <img _ngcontent-nwm-c37="" class="" height="50px" src="assets/img/Group 1437.png">
                      <img _ngcontent-nwm-c37="" class="" src="assets/img/Buttoun_outline_icon.png" (click)="uploadImage()">
                      
                    </div>
                    <img *ngIf="uploadedparam" class="image-data img-responsive" style="margin: 0px;border-radius: 8px;" [src]="s3url + image_path">
                  </div>
                </div> -->

                <div class="image-placeholder">
                  <mat-spinner class="upload-spinner" *ngIf="isloading"></mat-spinner>
                  <div class="profile-image" *ngIf="!isloading">
                    <div class="profile-dummy-img" *ngIf="!uploadedparam">
                      <img _ngcontent-nwm-c37="" class="" height="50px" src="assets/img/Group 1437.png">
                      <input #fileInput type="file" style="display: none;"
                      (change)="handleChange($event.target.files)">

                      <img _ngcontent-nwm-c37="" (click)="fileInput.click()" src="assets/img/Buttoun_outline_icon.png">
                    </div>
                    <img *ngIf="uploadedparam" class="image-data img-responsive" style="margin: 0px;border-radius: 8px;" [src]="s3url + image_path">
                  </div>
                  <form *ngIf="uploadedparam" [formGroup]="demoForm" (change)="uploadImage()" validate
                    [class]="isinvalid ? 'invalidcls' : ''">
                    <file-upload  formControlName="files" simple accept=".jpeg,.png,.jpg"
                      class="file-uplaod-html">
                      <ng-template let-file #placeholder>

                        <ng-container *ngIf="file; else emptyList">
                          {{ img_file_name }}
                        </ng-container>
                        <ng-container *ngIf="!file">
                          Choose a file...
                        </ng-container>

                      </ng-template>

                      <ng-template let-control="control" #button>
                        <i class="material-icons">
                          edit
                        </i>
                      </ng-template>
                    </file-upload>

                  </form>
                </div>
<!-- 
                <form [formGroup]="demoForm" (change)="uploadImage()" validate [class]="isinvalid ? 'invalidcls' : ''">
                  <file-upload formControlName="files" simple accept=".jpeg,.png,.jpg" class="file-uplaod-html">
                    <ng-template let-file #placeholder>

                      <ng-container *ngIf="file; else emptyList">
                        {{ img_file_name }}
                      </ng-container>
                      <ng-container *ngIf="!file">
                        Choose a file...
                      </ng-container>

                    </ng-template>

                    <ng-template let-control="control" #button>
                      <i class="material-icons">
                        cloud_upload
                      </i>
                    </ng-template>
                  </file-upload>

                </form> -->
              </div>
              <div class="col-md-9" style="padding:0">
                <mat-form-field appearance="outline" class="col-md-6">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" formControlName="name" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6">
                  <mat-label>Display Name</mat-label>
                  <input matInput placeholder="Display Name" formControlName="display_name" required>
                </mat-form-field>

                <div class="col-md-12">
                  <div class="gender-wrapper">
                    <span class="gender">Gender</span>
                    <mat-radio-group aria-label="Select an option" formControlName="gender" required>
                      <mat-radio-button value="m">Male</mat-radio-button>
                      <mat-radio-button value="f">Female</mat-radio-button>
                      <mat-radio-button value="o">Other</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <mat-form-field class="col-md-3" appearance="outline">
                  <mat-label>Country code</mat-label>
                  <!-- <mat-icon matPrefix>add</mat-icon> -->
                  <input type=“number” matInput placeholder="Country code" formControlName="std_code" minlength="1"
                    maxlength="3" pattern="^\d+(?:\.\d{0,1})?$" oninput="validity.valid||(value='');">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-4">
                  <mat-label>Phone Number</mat-label>
                  <input [pattern]="'^[0-9]+$'" minlength="8" maxlength="12" matInput placeholder="Phone Number"
                    formControlName="phone" required autocomplete="off">
                  <mat-error>Please enter the valid phone number</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-5" >
                  <mat-label>Email ID</mat-label>
                  <input type="email" matInput autocomplete="off" placeholder="Email ID" formControlName="email">
                </mat-form-field>
                
              </div>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col-md-12" *ngIf="newitem">
                <mat-label>Password</mat-label>
                <input matInput minlength="4" placeholder="Enter your password" type="password"
                  autocomplete="new-password" formControlName="password" required>
                <mat-error>Minimum length of password should be 4</mat-error>
              </mat-form-field>
              </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col-md-4">
                <mat-label>Select type</mat-label>
                <mat-select placeholder="Select Doctor Type" class="select-role" formControlName="doctor_type" required>
                  <mat-option value="Freemium">Freemium</mat-option>
                  <mat-option value="Regular">Regular</mat-option>
                </mat-select>
              </mat-form-field>
           
            <mat-form-field appearance="outline" class="col-md-4" *ngIf="!checkEligibility()">
              <mat-label>Select campaign</mat-label>
              <mat-select formControlName="campaign_data" multiple required #singleSelectTag>
                <mat-option>
                  <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                    [placeholderLabel]="'Search Item'" (ngModelChange)="modelChangedTags()"
                    [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmattag" ngDefaultControl>
                    <div class="mat-select-search-custom-header-content">
                      <i class="material-icons">
                        search
                      </i>
                    </div>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option class="camelcased select-tags-active" *ngFor="let campaign of filteredTherapy | async"
                  [value]="campaign.id">
                  {{campaign.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="client_role_id !== 9" appearance="outline" class="col-md-4">
              <mat-label>Select Hospital</mat-label>
              <mat-select formControlName="hospital_data"   #singleSelectTag>
                <mat-option  class="camelcased select-tags-active" *ngFor="let hospital of HospitalMappingList"
                  [value]="hospital.id">
                  {{hospital.name}}-{{hospital.city}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="client_role_id === 9"  appearance="outline" class="col-md-4">
              <mat-label>Select Hospital</mat-label>
              <mat-select formControlName="hospital_data"   #singleSelectTag required>
                <mat-option  class="camelcased select-tags-active" *ngFor="let hospital of HospitalMappingList"
                  [value]="hospital.id">
                  {{hospital.name}}-{{hospital.city}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

            <div class="row">
              <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Degree</mat-label>
                <input matInput placeholder="Degree" formControlName="degree">
              </mat-form-field>
             
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Speciality</mat-label>
                <input matInput placeholder="Speciality" formControlName="speciality">
              </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field appearance="outline" class="col-md-12">
                  <mat-label>Address</mat-label>
                  <input matInput placeholder="Address" formControlName="address">
                </mat-form-field>
              </div>

            <div class="row">
              <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>City</mat-label>
                <input matInput placeholder="City" formControlName="city">
              </mat-form-field>             
            </div>

            

            <div class="row">
              <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>About Me</mat-label>
                <textarea matInput placeholder="About Me" rows="6" formControlName="display_bio" required></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</mat-dialog-content>



<mat-dialog-actions align="start" class="col-md-12">
  <button mat-flat-button color="primary" class="btn btn-filled" (click)="dataObject()"
    [disabled]="!dataForm.valid">{{newitem ? 'Create' : 'Update'}}
    Doctor</button>
</mat-dialog-actions>