import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminusersComponent } from './adminusers.component';
import { EdituserComponent } from './edituser/edituser.component';
import { AdminGuardGuard } from './../../shared/guards';

const routes: Routes = [
  { path: '', component: AdminusersComponent, canActivate: [AdminGuardGuard] },
  { path: 'edit/:id', component: EdituserComponent, canActivate: [AdminGuardGuard] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminusersRoutingModule {}