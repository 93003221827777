

export function setDarkTheme(isThemeDark) {
    if (isThemeDark == true) {
        
        document.documentElement.setAttribute('data-theme', 'dark');
        sessionStorage.setItem('dark', 'true');
    }
    else {
        
        document.documentElement.setAttribute('data-theme', 'light');
        sessionStorage.setItem('dark', 'false');
    }
}
