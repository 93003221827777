import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isObject } from 'rxjs/internal/util/isObject';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { CommonserviceService } from '../services/commonservice/commonservice.service';

@Component({
  selector: 'app-paymentplan',
  templateUrl: './paymentplan.component.html',
  styleUrls: ['./paymentplan.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class PaymentplanComponent implements OnInit {
  
  constructor( private contentService: ContentserviceService,private systemService: SystemService, private commonService: CommonserviceService,private dialog: MatDialog,private snackBar: MatSnackBar) { }

  public newsection: any = 'new';
  public primarylanguage: any;
  public alllanguages: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['title', 'price', 'subscription', 'last_updated','action'];
   public isactive = 'all';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public localStoredData: any;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  ngOnInit(): void {
    this.searchcriteria = "";
    this.page = 1;
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.getLanguages();
    this.search();
  }


  // To Get Language 
  // ----------------------------

  getLanguages() {
    this.commonService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
    .subscribe(
      (res: any) => this.onlangStatusSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  
  private onlangStatusSuccess(data) {
    data = data.data;
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  // ----------------------------


  // To Get Language Child ID
  // ----------------------------

  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  // ----------------------------


  // To Delete Payment
  // ----------------------------

  delete(id) {
    this.commonService.hardDeleteWithUserfk('productplan', id)
    .subscribe(
      (res: any) => this.onDeleteSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // To open Delete Modal
  // ----------------------------

  openDialog(id) {
    localStorage.removeItem('modal_header');
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Payment Plan has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

   // ----------------------------

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  typeFilter(fln) {
    this.filteredtypes = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.doctor_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }

  
  // Active Deactive Payment 
  // ----------------------------

  isActive(status, id) {
    let value;
    if(status){
      value = "Deactivate"
    }else{
      value = "Activate"
    }
    let header = "Are you sure you want to "+""+ value +" "+ "Payment?";
    localStorage.setItem('modal_header',header);
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        localStorage.removeItem("modal_header");
        this.contentService.setStatus(status, 'productplan', id)
        .subscribe(
          (res: any) => this.onStatusSuccess(status, id,value),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Payment has been '+ value+'d', 'Ok', {
          duration: 2000,
        });
      }
    });
    
  }

  private onStatusSuccess(status, id,value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
    else {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // Payment List 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getSearchData(this.searchcriteria, this.page.toString(), "20", this.status, "productplan")
      .subscribe(
        (res: any) => this.onSearchDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'updatedat': return new Date(item.updated_at);
          default: return item[property];
        }
      };
    }
    if (this.filteredtypes.length !== 0) {
      this.typeFilter(this.filteredtypes);
    }
    else {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }

  // ----------------------------

}
