<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb *ngIf="!newitem" route="content/pumphome/pumpedit"></app-breadcrumb>
        <app-breadcrumb *ngIf="newitem" route="content/pumphome/pumpCreate"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Pump
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Pump Unit</mat-label>
                                    <input matInput placeholder="Enter the sub title" formControlName="pump_unit">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                            <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label> Route Type</mat-label>
                                <mat-select formControlName="route_type" (selectionChange)="onSelectChange($event)"  multiple #singleSelect>
                                    <mat-option value="subcutaneous">Subcutaneous</mat-option>
                                    <mat-option value="intravenous">Intravenous</mat-option>
                                    <mat-option value="implantable">Implantable</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                            <mat-form-field appearance="outline"  class="full-width-formfield ">
                                <mat-label>Add Pump Status</mat-label>
                                <mat-select formControlName="add_pump_status"  multiple  (selectionChange)="onAddPump($event)">
                                    <mat-option value="1">
                                        Current
                                    </mat-option>
                                    <mat-option  value="2">
                                        Reserved
                                    </mat-option>
                                    <mat-option value="3">
                                        Disabled
                                    </mat-option>
                                </mat-select>
                        </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                            <mat-form-field appearance="outline"  class="full-width-formfield ">
                                <mat-label>Select Valid Pump Status</mat-label>
                                <mat-select formControlName="valid_pump_status" multiple (selectionChange)="onSelectValidPump($event)">
                                    <mat-option value="1">
                                        Current
                                    </mat-option>
                                    <mat-option  value="2">
                                        Reserved
                                    </mat-option>
                                    <mat-option value="3">
                                        Disabled
                                    </mat-option>
                                </mat-select>
                        </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" *ngIf="showSCDropdown">
                            <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label>Label of Remodulin (Subcutaneous)</mat-label>
                                <mat-select formControlName="SC_label">
                                    <mat-option [value]="1">
                                      ON
                                    </mat-option>
                                    <mat-option [value]="0">
                                     OFF
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" *ngIf="showInDropdown">
                            <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label>Label of Remodulin (Intravenous)</mat-label>
                                <mat-select formControlName="IV_label">
                                    <mat-option [value]="1">
                                      ON
                                    </mat-option>
                                    <mat-option [value]="0">
                                     OFF
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" *ngIf="showIVDropdown">
                            <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label>Label of Remodulin (Implantable)</mat-label>
                                <mat-select formControlName="IM_label">
                                    <mat-option [value]="1">
                                      ON
                                    </mat-option>
                                    <mat-option [value]="0">
                                     OFF
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                            <!-- <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label>Remodulin Labels</mat-label>
                                <mat-select formControlName="remodulin_labels" multiple #singleSelect>
                                    <mat-option [value]="cat.id"></mat-option>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button mat-flat-button color="primary" class="centerbtn btn-left"
                            [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Pump</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>