/*==========================================
Title: removed unused consoles
Author: Mandeep singh
Date:   16 April 2020
Last Change :  removed unused consoles
===========================================*/
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-viewuserassign',
  templateUrl: './viewuserassign.component.html',
  styleUrls: ['./viewuserassign.component.scss']
})

export class ViewuserassignComponent implements OnInit {

  public patient_status: any;
  public allconditions: any;
  public emptycondition: boolean = false;
  public therapy_name: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewuserassignComponent>, private commonService: CommonserviceService, private systemService: SystemService,
    private browserTitle: Title
  ) { }

  public showButtonLoader: any;
  public userView: any;
  public therapy_data: boolean = false;
  public conditions: any;
  public myhash = {};

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | View User Assign');
  }

  ngOnInit() {
    this.setSEOTags();
    this.userView = this.data.element;
    this.patient_status = this.userView.status.replace(/_/g, " ").toLowerCase();
    if (this.userView.therapyId == null) {
      this.therapy_data = true;
    }
    else {
      this.getTherapy(this.userView.therapyId);
    }
    if (this.userView.conditions == null) {
      this.emptycondition = true;
    }
    else {
      this.getConditions(this.userView.conditions);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  conversion(c) {
    var data = c.replace(/[\[\]"]+/g, '')
    return data;
  }

  getConditionName(allconditions) {
    allconditions = allconditions.map(i => this.myhash[i]);
    return allconditions;
  }


  // To Get Therapy List 
  // ----------------------------

  getTherapy(id) {
    this.commonService.getGraphQlData('{therapy_data(appMode:false,therapyId:' + id + '){id,name}}')
    .subscribe(
      (res: any) => this.onTherapyDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onTherapyDataSuccess(data) {
    data = data.data;
    this.therapy_name = data.therapy_data.name;
  }

  // ----------------------------


  // To Get Conditions Data 
  // ----------------------------

  getConditions(id) {
    this.commonService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}',false)
    .subscribe(
      (res: any) => this.onConditionsStatusSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )

  }

  private onConditionsStatusSuccess(data) {
    data = data.data;
    this.conditions = data.getAllConditions;
    this.conditions.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  // ----------------------------

}
