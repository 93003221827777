<form [formGroup]="mediainfoForm" (ngSubmit)="onsubmit()">
    <div formArrayName="mediadatas" class="mealbox">
        <div *ngFor="let media of mediainfoForm.get('mediadatas')['controls']; let j=index" [formGroupName]="j">
            <div class="clearfix"><br /></div>
            <div class="showbox col-md-4">
                <div class="image-preview-upload" *ngIf="media.value.type == 'image'">
                    <img [src]="media.value.url">
                </div>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" formControlName="title" type="text" value="" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description (optional)" formControlName="detailed_text" type="text"
                        value="" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <button mat-button type="submit" mat-flat-button color="primary" [disabled]="!mediainfoForm.valid"
        class="uploadmodalbtn">Upload Image</button>
</form>