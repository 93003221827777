

   
  
    

            <div class="col-md-12 p-1">
                <div style="float: left">
                <h3 class="commontitleprimary hc-status-head">
                    Payment history
                </h3>
                </div>
                <div class="close-icon" style="float:right;margin-top: 30px;">
                    <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()" class="img-responsive close-modal" alt="">
                  </div>
            </div>
            <!-- <div class="col-md-12">
                <div class="row">
                    <div class="col-md-5">
                        <form>
                            <div class="form-group has-feedback has-search">
                                <i class="material-icons form-control-feedback">
                                    search
                                </i>
                                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}"
                                    class="form-control" placeholder="Search Patient here">
                                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                                    <mat-icon matSuffix>search</mat-icon>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <form [formGroup]="dataForm" validate class="filter-form">
                                <mat-form-field (click)="$event.stopPropagation();" appearance="outline"
                                    class="col-md-4 pq">
                                    <mat-label>Choose start date</mat-label>
                                    <input matInput formControlName="startdate" [matDatepicker]="picker1"
                                        (dateInput)="addEvent('input', $event)"
                                        (dateChange)="addEvent('change', $event)" [max]="morrow"
                                        placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field appearance="outline" (click)="$event.stopPropagation();"
                                    class="col-md-4 pr">
                                    <mat-label>Choose end date</mat-label>
                                    <input matInput formControlName="enddate" [matDatepicker]="picker2"
                                        [min]="enddateval" placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div class="col-md-4">
                                    <button mat-flat-button color="primary" class="primary apply-btn btn-block"
                                        (click)="applymainfilter()" [disabled]="!dataForm.valid">Apply</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div> -->



            <div class="col-md-12">


                <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                    (scrolled)="getNextSet()" [scrollWindow]="false">
                    <div class="spinner-container" *ngIf="showloader">
                        <app-loader showLoader="{{true}}"></app-loader>
                    </div>


                    <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
                        <ng-container matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef> Created at </th>
                            <td mat-cell class="text-capitalize" *matCellDef="let element">
                                {{element.created_at | daysago}} </td>
                        </ng-container>
                        <ng-container matColumnDef="purchase_type">
                            <th mat-header-cell *matHeaderCellDef> Purchase type </th>
                            <td mat-cell class="text-capitalize" *matCellDef="let element">
                                {{parseString(element.purchase_type)}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="transaction_amount">
                            <th mat-header-cell *matHeaderCellDef> Transaction Amount </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.transaction_amount}}{{element.transaction_currency}} </td>
                        </ng-container>
                        <ng-container matColumnDef="payment_status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell class="text-capitalize" *matCellDef="let element">
                                {{(element.payment_status)}} </td>
                        </ng-container>
                        

                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>

   
