<div class="userdetailcontent">
  <div class="allcards" *ngIf="userDetailsSubject$ | async as userData; else loadingOrError;">
    <div *ngIf="userData.id">
      <mat-card class="pad-x">
        <mat-card-header class="dashed-border">
          <mat-card-title class="notranslate">{{userData.name}}</mat-card-title>
          <mat-card-subtitle>
            +{{userData.country_code}}-{{userData.phone}}

            <ng-container *ngIf="!isPhoneNumberChangeStatus">
              <span class="update-number-link" (click)="openUpdateNumberModal()" >(Update Number)</span>
            </ng-container>

            <ng-container *ngIf="isPhoneNumberChangeStatus">
              (Waiting for Approval)
            </ng-container>

            <ng-container *ngIf="hasPhoneNumberChangeLogs">
              <button mat-button matTooltip="Phone Number Change history" (click)="openPhoneNumberChangeLog()">
                <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="" >                 
              </button>
            </ng-container>
            
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Gender</mat-card-title>
                <mat-card-subtitle>{{userData?.gender=="m"?"Male": (userData?.gender=="o"?"Other" : "Female")}}
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          <div class="col-md-5">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Age</mat-card-title>
                <mat-card-subtitle>
                  {{year}}
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          <div class="col-md-3">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Weight</mat-card-title>
                <mat-card-subtitle>{{userData.weight}} {{userData.weight_unit}}</mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          <div class="col-md-4">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Height</mat-card-title>
                <mat-card-subtitle>{{userData.height}} {{userData.height_unit}}</mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          <div class="col-md-4" *ngIf="userData.meal_preference">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Diet</mat-card-title>
                <mat-card-subtitle class="text-capitalize">{{userData.meal_preference}}</mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          <div class="col-md-5">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Preferred Language</mat-card-title>
                <mat-card-subtitle class="text-capitalize">{{PreferedLanguage}}
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          <div class="col-md-3">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>BMI</mat-card-title>
                <mat-card-subtitle class="text-capitalize" *ngIf="userData.show_bmi==true">
                  {{bmiValue}}<br />({{bmiData}})
                </mat-card-subtitle>
                <mat-card-subtitle class="text-capitalize" *ngIf="userData.show_bmi!=true">NA
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>

          
          <ng-container *ngIf="userData.show_targets">
            <div class="col-md-4">
              <mat-card class="userdetail-card">
                <mat-card-header>
                  <mat-card-title>Target Weight Range</mat-card-title>
                  <mat-card-subtitle *ngIf="userData.weight_unit == 'kg'">{{idealWeightRange.kg.start}} kg to {{idealWeightRange.kg.end}} kg</mat-card-subtitle>
                  <mat-card-subtitle *ngIf="userData.weight_unit != 'kg'">{{idealWeightRange.lb.start}} lb to {{idealWeightRange.lb.end}} lb</mat-card-subtitle>
                </mat-card-header>
              </mat-card>
            </div>
          </ng-container>
          <div class="col-md-4" *ngIf="targetPatientData.target">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Daily calorie goal</mat-card-title>
                <mat-card-subtitle class="text-capitalize" *ngIf="userData.show_targets==true">
                  {{convertDecimal(targetPatientData.target)}} calories </mat-card-subtitle>
                <mat-card-subtitle class="text-capitalize" *ngIf="userData.show_targets==false"> Not Applicable
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>
          <div class="col-md-7" *ngIf="targetPatientData.target">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Meal wise breakdown <span class="update-number-link" *ngIf="!hideDietPlanBuilder" (click)="openCreateDietPlanModal()" >(Create Diet Plan)</span>
                  <ng-container *ngIf="!hideDietPlanHistory">	
                    <button mat-button matTooltip="Diet Plan history" (click)="openCreateDietPlanModal('history')">	
                      <img src="assets/img/dls/admin-table/Icon_Diet_Plan_History.svg" alt="" style="height:20px; width:20px;">                 	
                    </button>	
                  </ng-container>
                </mat-card-title>
                <mat-card-subtitle class="text-capitalize" *ngIf="userData.show_targets==false">
                  Not Applicable </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
            <table *ngIf="userData.show_targets==true"
              style="background-color: beige;width: 107%;height: 49px;border-radius: 2px;padding: -35px;margin-left: -1%; margin-top: 0%;">
              <tr>
                <th>Breakfast</th>
                <th>Lunch</th>
              </tr>
              <tr>
                <td>{{convertDecimal(targetPatientData.daily.breakfast)}} cal
                  <!-- <span *ngIf="targetPatientData.daily.breakfast">{{convertDecimal(targetPatientData.daily.breakfast)}}
                    cal</span> -->
                </td>
                <td>{{convertDecimal(targetPatientData.daily.lunch)}} cal
                  <!-- <span *ngIf="targetPatientData.daily.lunch">
                    cal</span> -->
                </td>
              </tr>
            </table>
            <br>
            <table *ngIf="userData.show_targets==true"
              style="background-color: beige;width: 107%;height: 49px;border-radius: 2px;padding: -35px;margin-left: -1%;margin-top: -7%;">
              <tr>
                <th>Dinner</th>
                <th>Snack</th>
              </tr>
              <tr>
                <td>
                  <span *ngIf="targetPatientData.daily.dinner">{{convertDecimal(targetPatientData.daily.dinner)}}
                    cal</span>
                </td>
                <td>
                  <span *ngIf="targetPatientData.daily.snacks">{{convertDecimal(targetPatientData.daily.snacks)}}
                    cal</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-9" *ngIf="targetPatientData.target">
            <mat-card class="userdetail-card">
              <mat-card-header style="margin-bottom: -15px;margin-top: 15px;">              
                <mat-card-title>Meal wise breakdown</mat-card-title>
              </mat-card-header>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9" *ngIf="targetPatientData.target">
            <mat-card class="userdetail-card">
              <mat-card-header style="margin-bottom: 0px;margin-top: 0px;">              
                <mat-card-title></mat-card-title>
              </mat-card-header>
            </mat-card>
          </div>
        </div>

        <table style="background-color: beige;width: 50%;height: 50px;    border-radius: 2px">
          <tr>
            <th>Breakfast</th>
            <th>Lunch</th>
          </tr>
          <tr>
            <td><span *ngIf="targetPatientData.daily.breakfast">{{convertDecimal(targetPatientData.daily.breakfast)}}
                cal</span><span *ngIf="!targetPatientData.daily.breakfast">NA</span></td>         
            <td><span *ngIf="targetPatientData.daily.lunch">{{convertDecimal(targetPatientData.daily.lunch)}}
                cal</span><span *ngIf="!targetPatientData.daily.lunch">NA</span></td>         
          </tr>
        </table>
        <br>
        <table style="width:50%;background-color:beige;margin-top:-18px;height: 50px;    border-radius: 2px;">
          <tr>
            <th>Dinner</th>
            <th>Snack</th>
          </tr>
          <tr>
            <td><span *ngIf="targetPatientData.daily.dinner">{{convertDecimal(targetPatientData.daily.dinner)}}
                cal</span><span *ngIf="!targetPatientData.daily.dinner">NA</span></td>          
            <td><span *ngIf="targetPatientData.daily.snacks">{{convertDecimal(targetPatientData.daily.snacks)}}
                cal</span><span *ngIf="!targetPatientData.daily.snacks">NA</span></td>
         


          </tr>
        </table> -->
        
        <div class="row">
          <div class="col-md-10">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Device</mat-card-title>
                <!-- {{userData?.device_type}} -->
                <mat-card-subtitle class="text-capitalize" *ngIf="device_type">
                  {{device_type}} Version : {{device_version}} <span style="color: red;font-weight: 500;"
                    *ngIf="is_device_rooted">(Rooted)</span> &nbsp;
                </mat-card-subtitle>
                <br />
                <mat-card-subtitle class="text-capitalize" *ngIf="device_type">
                  <a class="pointer" (click)="openDeviceModel()">Device history</a>
                </mat-card-subtitle>

                <!-- <div class=""><a (click)="">Old devices</a></div> -->
                <mat-card-subtitle *ngIf="!device_type">Not Available</mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>
          <div class="col-md-10">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>App Version</mat-card-title>
                <!-- {{userData?.device_type}} -->
                <mat-card-subtitle class="text-capitalize" *ngIf="app_version">
                  {{app_version}}
                  &nbsp;&nbsp;
                  <p class="text-capitalize" style="color: green;font-weight: 500;" *ngIf="result==0">(Latest) </p>
                  <p class="text-capitalize" style="color: red;font-weight: 500;" *ngIf="result==-1">(Need Update)</p>
                  <p *ngIf="is_debug_app" style="color: red;font-weight: 500;">&nbsp;(Debug App)</p>
                </mat-card-subtitle>
                <!-- <div class=""><a (click)="">Old devices</a></div> -->
                <mat-card-subtitle *ngIf="!app_version">Not Available
                  <p *ngIf="is_debug_app">&nbsp;(Debug App)</p>
                </mat-card-subtitle>
              </mat-card-header>

            </mat-card>
          </div>
          <div class="col-md-12">
            <mat-card>
              <mat-card-header class="matcardheadertag">
                <mat-card-title>
                  Assigned Tags
                  <button mat-icon-button class="plusrectangletransparent pull-right notranslate"
                    (click)="openTagDialog()">
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-card-title>
                <mat-card-subtitle *ngIf="userTags">
                  <div *ngFor="let tag of userTags" class="tagshowcss">
                    {{tag.title}} <a (click)="deletetag(tag.tag_id)" class="deletebtn"><i
                        class="material-icons">clear</i></a>
                  </div>
                </mat-card-subtitle>
                <mat-card-subtitle *ngIf="!userTags">
                  No Tags Assigned Yet
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <mat-card class="userdetail-card">
              <mat-card-header>
                <mat-card-title>Permissions</mat-card-title>
                <mat-card-subtitle *ngIf="!showPermission">
                  waiting for patient app interaction
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>
          <div *ngIf="showPermission">
            <div class="col-md-2">
              <mat-card class="userdetail-card">
                <mat-card-header>
                  <!-- <mat-card-title>1/5</mat-card-title> -->
                </mat-card-header>
              </mat-card>
            </div>
            <div class="col-md-12">
              <div class="" [ngClass]="whatsapp == true ? 'cowincard' : 'permissions'">
                <div class="title">Whatsapp access</div>
                <button *ngIf="!whatsapp && !isWhatsAppno" class="btn btn-ask" color="primary"
                  (click)="askPermission('Whatsapp',$event)" [disabled]="!isWhatsAppno" data-toggle="tooltip"
                  data-placement="top" title="Whatsapp is not configured">Ask</button>
                <button *ngIf="!whatsapp && isWhatsAppno" class="btn btn-ask" color="primary"
                  (click)="askPermission('Whatsapp',$event)">Ask</button>
                <div *ngIf="whatsapp" class="like" style="display:flex">
                  <span style="padding: 0px 6px;cursor: pointer;"><a (click)="askPermission('Whatsapp',$event)">Ask
                      Again</a></span>
                  <img src="assets/img/dls/icons/Tick_solid.svg" class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>

            <!-- App Permissions in use -->
            <div class="col-md-12" *ngIf="device_type == 'iOS'">
              <div class="" [ngClass]="applefit == true ? 'cowincard' : 'permissions'">
                <div class="title">Apple Health</div>
                <button [disabled]="showapplefit" *ngIf="!applefit" class="btn btn-ask" color="primary"
                  (click)="getpermissionRequest('applefit')">Ask</button>
                <div *ngIf="applefit" class="like "><img src="assets/img/dls/icons/Tick_solid.svg"
                    class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="device_type == 'Android'">
              <div class="" [ngClass]="googlefit == true ? 'cowincard' : 'permissions'">
                <div class="title">Google fit</div>
                <button [disabled]="showgooglefit" *ngIf="!googlefit" class="btn btn-ask" color="primary"
                  (click)="getpermissionRequest('googlefit')">Ask</button>
                <div *ngIf="googlefit" class="like "><img src="assets/img/dls/icons/Tick_solid.svg"
                    class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="" [ngClass]="locationAccess == true ? 'cowincard' : 'permissions'">
                <div class="title">Location access</div>
                <button [disabled]="showlocationAccess" *ngIf="!locationAccess" class="btn btn-ask" color="primary"
                  (click)="getpermissionRequest('locationAccess')">Ask</button>
                <div *ngIf="locationAccess" class="like"><img src="assets/img/dls/icons/Tick_solid.svg"
                    class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="" [ngClass]="camera == true ? 'cowincard' : 'permissions'">
                <div class="title">Camera</div>
                <button [disabled]="showCamera" *ngIf="!camera" class="btn btn-ask" color="primary"
                  (click)="getpermissionRequest('camera')">Ask</button>
                <div *ngIf="camera" class="like"><img src="assets/img/dls/icons/Tick_solid.svg" class="img-responsive"
                    alt="Wellthy">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="" [ngClass]="gallery == true ? 'cowincard' : 'permissions'">
                <div class="title">Gallery access</div>
                <button [disabled]="showgallery" *ngIf="!gallery" class="btn btn-ask" color="primary"
                  (click)="getpermissionRequest('gallery')">Ask</button>
                <div *ngIf="gallery" class="like"><img src="assets/img/dls/icons/Tick_solid.svg" class="img-responsive"
                    alt="Wellthy">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="" [ngClass]="medSurvey == true ? 'cowincard' : 'permissions'">
                <div class="title">Medication Survey</div>
                <button [disabled]="showSurvey" *ngIf="!medSurvey" class="btn btn-ask" color="primary"
                  (click)="getpermissionRequest('medSurvey')">Ask</button>
                <div *ngIf="medSurvey" class="like"><img src="assets/img/dls/icons/Tick_solid.svg"
                    class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="permissions">
                <div class="title">Ask rating</div>
                <button class="btn btn-ask" color="primary"
                  (click)="askPermission('ask_for_rating',$event)">Ask</button>
                <!-- <div  *ngIf="isRating" class="like"><img src="assets/img/dls/icons/Tick_solid.svg"
                class="img-responsive" alt="Wellthy">
              </div> -->
              </div>
            </div>
            <!-- Debug app   -->
            <div class="col-md-12">
              <div class="" [ngClass]="appDebug == true ? 'cowincard' : 'permissions'">
                <div class="title">Debug App</div>
                <label class="switch1" [ngStyle]="{'opacity': appDebug ? '0.5' : '1' }">
                  <input type="checkbox" [(ngModel)]="DebugAppPermission" [disabled]="appDebug" [checked]="appDebug"
                    (change)="askPermission('debug_mode_activated_at',$event)">
                  <span class="slider round" [ngStyle]="appDebug ? {cursor: 'not-allowed'} : {cursor: 'pointer'}" ></span>
                </label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="" [ngClass]="coachMark == true ? 'cowincard' : 'permissions'">
                <div class="title">Coach mark</div>
                <label class="switch1" *ngIf="enableCoachMark===true">
                  <input type="checkbox" [(ngModel)]="coachmarkPermission" [checked]="coachmarkProgress"
                    (change)="getpermissionRequest('coachMark',coachmarkPermission)">
                  <span class="slider round"></span>
                </label>

                <label class="switch1" *ngIf="enableCoachMark===false" [disabled]="true"
                  tooltip="Coach Mark feature is disabled">
                  <input [disabled]="enableCoachMark==false" [(ngModel)]="coachmarkPermission" type="checkbox"
                    [checked]="coachmarkProgress" (change)="getpermissionRequest('coachMark',coachmarkPermission)"
                    class="btn btn-ask" tooltip="Coach Mark feature is disabled" checked [disabled]="true">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div class="col-md-12" *ngIf="device_type == 'Android'">
              <div class="" [ngClass]="Resync_GoogleFit == true ? 'cowincard' : 'permissions'">
                <div class="title">Resync Google Fit</div>
                <button *ngIf="!Resync_GoogleFit" class="btn btn-ask" color="primary"
                  (click)="askPermission('Resync_GoogleFit',$event)">Ask</button>
                <!--[disabled]="showgallery" -->
                <div *ngIf="Resync_GoogleFit" tooltip="Resync asked at {{convertDate(resync_google_health_ask_at)}}" class="like"><img src="assets/img/dls/icons/Tick_solid.svg"
                    class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="device_type == 'iOS'">
              <div class="" [ngClass]="Resync_HealthKit == true ? 'cowincard' : 'permissions'">
                <div class="title">Resync Health Kit</div>
                <button *ngIf="!Resync_Healthkit" class="btn btn-ask" color="primary"
                  (click)="askPermission('Resync_HealthKit',$event)">Ask</button>
                <!--[disabled]="showgallery" -->
                <div *ngIf="Resync_Healthkit" tooltip="Resync asked at {{convertDate(resync_google_health_ask_at)}}" class="like"><img src="assets/img/dls/icons/Tick_solid.svg"
                    class="img-responsive" alt="Wellthy">
                </div>
              </div>
            </div>


            <!-- <div class="col-md-12">
            <div class="cowincard">
              <span class="title">App rating</span> 
              <label class="switch">
                <input type="checkbox"  [(ngModel)]="isRating" (click)="askPermission('ask_for_rating')" style="visibility: hidden;">
                <span class="slide round"></span>
              </label>
          </div>        
          </div> -->
            <div class="col-md-12">
              <mat-card class="userdetail-card">
                <mat-card-header>
                  <mat-card-title>Last Rating on :</mat-card-title>
                  <!-- {{userData?.device_type}} -->
                  <mat-card-subtitle class="text-capitalize" *ngIf="showRating">
                    <div>
                      {{convertDate(rating_given_at)}}
                    </div>
                    <div class="rate">
                      <input type="radio" id="star5" name="rate" value="5" [(ngModel)]="ratingValue" disabled />
                      <label for="star5" title="text">5 stars</label>
                      <input type="radio" id="star4" name="rate" value="4" [(ngModel)]="ratingValue" disabled />
                      <label for="star4" title="text">4 stars</label>
                      <input type="radio" id="star3" name="rate" value="3" [(ngModel)]="ratingValue" disabled />
                      <label for="star3" title="text">3 stars</label>
                      <input type="radio" id="star2" name="rate" value="2" [(ngModel)]="ratingValue" disabled />
                      <label for="star2" title="text">2 stars</label>
                      <input type="radio" id="star1" name="rate" value="1" [(ngModel)]="ratingValue" disabled />
                      <label for="star1" title="text">1 star</label>
                    </div>
                  </mat-card-subtitle>
                  <mat-card-subtitle *ngIf="!showRating">Not Available</mat-card-subtitle>
                </mat-card-header>
              </mat-card>
            </div>
          </div>
          <div *ngIf="userData?.patient_client_data?.id != '9'">
            <div class="col-md-12">
              <div class="cowincard">
                <span class="title">CoWIN notifications Opt in</span>
                <label class="switch2">
                  <input type="checkbox" [(ngModel)]="isOPTOUT" (change)="patientOPTout(isOPTOUT)"
                    style="visibility: hidden;">
                  <span class="slide round"></span>
                </label>
              </div>
            </div>
            <div class="col-md-12" *ngIf="!isOPTOUT">
              <mat-card class="userdetail-card">
                <mat-card-header>
                  <mat-card-title>Last Opt-out on :</mat-card-title>
                  <!-- {{userData?.device_type}} -->
                  <mat-card-subtitle class="text-capitalize" *ngIf="coWINData?.updated_at">
                    {{coWINData?.updated_at | date:'dd-MM-yyyy'}}
                  </mat-card-subtitle>
                  <mat-card-subtitle *ngIf="!coWINData?.updated_at">Not Available</mat-card-subtitle>
                </mat-card-header>
              </mat-card>
            </div>
            <div class="col-md-12">
              <button *ngIf="!dataSource && isOPTOUT" mat-flat-button color="primary" class="submit-btn"
                (click)="getCowinSlots()">Slot Availability</button>
              <div class="container-table" infiniteScroll>
                <table *ngIf="dataSource && isOPTOUT" mat-table [dataSource]="dataSource"
                  class="table-fixed-container list-table">


                  <ng-container matColumnDef="min_age_limit">
                    <th mat-header-cell *matHeaderCellDef> Age </th>
                    <td mat-cell *matCellDef="let element"> {{element.min_age_limit}} + </td>
                  </ng-container>


                  <ng-container matColumnDef="available_capacity_dose1">
                    <th mat-header-cell *matHeaderCellDef> Dose 1 </th>
                    <td mat-cell *matCellDef="let element">{{element.available_capacity_dose1}} slots </td>
                  </ng-container>


                  <ng-container matColumnDef="available_capacity_dose2">
                    <th mat-header-cell *matHeaderCellDef> Dose 2 </th>
                    <td mat-cell *matCellDef="let element">{{element.available_capacity_dose2}} slots</td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date</th>
                    <td mat-cell *matCellDef="let element">{{element.date}}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="selection.toggle(row);handleRowSelection(row);"
                    [class]="highlightedRow == row ? 'lightdark userrowtb1' : 'userrowtb1'"></tr>
                </table>
                <div *ngIf="dataSource.length == 0" class="text-center">
                  <p class="no-data">-- No slots Available --</p>
                </div>
              </div>
            </div>
            <table *ngIf="dataSource && !isOPTOUT" mat-table [dataSource]="dataSource"
              class="table-fixed-container list-table">


              <ng-container matColumnDef="min_age_limit">
                <th mat-header-cell *matHeaderCellDef> Age </th>
                <td mat-cell *matCellDef="let element"> {{element.min_age_limit}} + </td>
              </ng-container>


              <ng-container matColumnDef="available_capacity_dose1">
                <th mat-header-cell *matHeaderCellDef> Dose 1 </th>
                <td mat-cell *matCellDef="let element">{{element.available_capacity_dose1}} slots </td>
              </ng-container>


              <ng-container matColumnDef="available_capacity_dose2">
                <th mat-header-cell *matHeaderCellDef> Dose 2 </th>
                <td mat-cell *matCellDef="let element">{{element.available_capacity_dose2}} slots</td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Date</th>
                <td mat-cell *matCellDef="let element">{{element.date}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                (click)="selection.toggle(row);handleRowSelection(row);"
                [class]="highlightedRow == row ? 'lightdark userrowtb1' : 'userrowtb1'"></tr>
            </table>
          </div>
        </div>
      </div>
     
      <!-- <div class="clearfix"><br /></div>
      <mat-card class="pad-x">
        <mat-card-header>
          <mat-card-title>Device Details</mat-card-title>
          <mat-card-subtitle *ngIf="userData?.device_type">{{userData?.device_type}} Version : {{userData?.device_version}}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="!userData?.device_type">Not Available</mat-card-subtitle>
        </mat-card-header>
      </mat-card> -->

      <!-- <mat-card *ngIf="!(userData.caregiver.name && userData.caregiver.phone)" class="caregivercardcss">
          <mat-card-title class="card-title">No Caregiver details available</mat-card-title>
          <mat-card-subtitle class="card-text">Provide details to add caregiver on behalf of user</mat-card-subtitle>
          <mat-card-content>
              <form [formGroup]="caregiverForm" validate>
                  <mat-form-field style="width: 100%;" appearance="outline">
                      <input matInput placeholder="Caregiver Name" minlength="1" maxlength="729" formControlName="name">
                  </mat-form-field>
                  <mat-form-field style="width: 20%;" appearance="outline" >
                  <span matPrefix style="display: table-caption;">+&nbsp;</span>
                      <input matInput placeholder="Code" minlength="1" maxlength="4" formControlName="code"  pattern="^\d+(?:\.\d{0,1})?$">
                    </mat-form-field> 
                  <mat-form-field style="width: 80%;" appearance="outline" >
                      <input matInput placeholder="Phone Number" minlength="1" maxlength="12" formControlName="phone"  pattern="^\d+(?:\.\d{0,1})?$">
                  </mat-form-field>
                      <mat-form-field appearance="outline"  style="width: 100%;">
                          <mat-select placeholder="Select Relationship" formControlName="relationship">
                              <mat-option value="wife">
                                  Wife
                              </mat-option>
                              <mat-option value="husband">
                                  Husband
                              </mat-option>
                              <mat-option value="son">
                                  Son
                              </mat-option>
                              <mat-option value="daughter">
                                  Daughter
                              </mat-option>
                              <mat-option value="other">
                                  Other
                              </mat-option>
                          </mat-select>
                      </mat-form-field>

                  <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn" [disabled]="!caregiverForm.valid" (click)="careObject()">Add Caregiver</button>
              </form>
          </mat-card-content>
      </mat-card>
    
    <mat-card *ngIf="userData.caregiver.name">
            <mat-card-title class="card-title">Caregiver</mat-card-title>
            <mat-card-subtitle class="card-text">{{userData.caregiver.name ? userData.caregiver.name : 'No Data'}}</mat-card-subtitle>
    </mat-card>
    <mat-card *ngIf="userData.caregiver.phone">
            <mat-card-title class="card-title">Caregiver Phone Number</mat-card-title>
            <mat-card-subtitle class="card-text">{{userData.caregiver.phone ? userData.caregiver.phone : 'No Data'}}</mat-card-subtitle>
    </mat-card> -->

      <div class="clearfix"><br /><br /></div>
    </div>
    <app-nodata [noRecordFound]="!userData.id"></app-nodata>
  </div>
  <ng-template #loadingOrError>
    <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async"></app-loadorerror>
  </ng-template>
</div>

<ng-template #fit_kit_data_asked>
    <div  style="text-align: center;">
      Do you want to resync entire data or resync data for a particular day ?
     </div>
     <div class="col-md-12" style="text-align: center;overflow: hidden;padding: 8px;">
       <button style="margin: 5px;"  mat-flat-button (click)="resyncData('full_data')" color="primary">Resync Entire Data</button>
       <button  mat-flat-button (click)="resyncData('particular_data')" color="primary">Resync Particular Day</button>
     </div>
     <div style="margin-top: 25px;" *ngIf="enableReyncCalender">
      <form [formGroup]="dataForm" validate class="filter-form">
        <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-6"
        style="padding-right: 0px;">
  
        <mat-label>Choose start date</mat-label>
        <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="morrow"
          placeholder="Choose a date">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-6">
        <mat-label>Choose end date</mat-label>
        <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="dataForm.get('startdate')?.value"
          [max]="morrow" placeholder="Choose a date">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker2>
        </mat-datepicker>
      </mat-form-field>
      <div class="col-md-12" style="text-align: center;">
        <button (click)="customResync();" mat-flat-button color="primary" class="primary margin-btn" 
          style="width: 25%;">Apply</button>
      </div>
       </form>
     </div>
</ng-template>
<ng-template #confirm_fit_kit_data_asked>
  <div  style="text-align: center;">
    In case of conflicting activity data received for the same timestamp, the higher value will be taken into consideration
   </div>
   <div class="col-md-12" style="text-align: center;overflow: hidden;padding: 8px;">
     <button style="margin: 5px;"  mat-flat-button (click)="applyCustomResync()" color="primary">Confirm</button>
   </div>
</ng-template>
