import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { AuthenticateService } from 'src/app/shared/services';
import { DoctorDataService } from '../../services/doctor/doctor.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  today: number = Date.now();
  jstoday = '';
  checkboxValue: boolean;
  rawprescriptiondata: any
  rawdata: any;
  doctorData: any;
  newpassword: any;
  error: boolean = false;
  message: any;
  constructor(private router: Router, private authService: AuthenticateService, private doctorService: DoctorDataService) {
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
  }

  ngOnInit() {
    let userData: string = localStorage.getItem('scarletuserdata');
    let parsedUserData: any = JSON.parse(userData);
    if (parsedUserData.doctor && parsedUserData.doctor.is_agreement_accepted) {
      this.router.navigateByUrl('/doctor/home');
    }
    this.doctorData = JSON.parse(userData);

  }

  /**
  * @name serviceAgreement
  * @description Setting the localStorage to true when the doctor clicks on 'I Agree' button
  * @param No_param
  * @returns NULL
  */
  skip() {
    this.router.navigateByUrl('/doctor/serviceagreement');
  }
  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  changePassword() {
    if (this.newpassword) {
      this.error = false;
      if (this.hasWhiteSpace(this.newpassword) == false) {

        this.doctorService.updateDocPassword(this.newpassword)
          .subscribe(
            (data) => {
              this.rawprescriptiondata = data;
              let datap = {
                "d": [
                  {
                    "identity": localStorage.getItem('scarletuserid'),
                    "ts": (Math.floor(new Date().valueOf() / 1000)),
                    "type": "event",
                    "evtName": "Password Changed",
                    "evtData": {
                      "Accessed": "Yes"
                    }
                  }
                ]
              }
              this.authService
                .sendEventData(datap)
                .pipe(
                  catchError((error: any) => {
                    return throwError(error);
                  }),
                  finalize(() => {
                  })
                )
                .subscribe(data => {
                })
              this.router.navigateByUrl('/doctor/serviceagreement');
              //window.location.reload();
            })
      }
      else {
        this.error = true;
        this.message = "Enter valid password, Your password should not be empty or contain spaces";
      }
    }
    else {
      this.error = true;
    }
  }
}
