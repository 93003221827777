

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-campaignqr',
  templateUrl: './campaignqr.component.html',
  styleUrls: ['./campaignqr.component.scss']
})

export class CampaignqrComponent implements OnInit {
  public image: any;
  cc: any;
  canvas: any;
  img: any = null;
  qrcode: any = null;
  gctx: any;
  imgurl: any;
  downloadOn: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private browserTitle: Title,public dialogRef: MatDialogRef<CampaignqrComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Campaign QR');
  }

  ngOnInit() {
    this.setSEOTags();
    this.imgurl = "https://chart.googleapis.com/chart?cht=qr&chl=campaign_id:" + this.data.campaign_id + "&chs=297x297&choe=UTF-8&chld=H%7C2";
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
