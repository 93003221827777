import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from './../../../shared/shared.module';
import { ContextService as HealthCoachContextService } from './../../services/context.service';
import { HcleftnavComponent } from './hcleftnav.component';

@NgModule({
  declarations: [
    HcleftnavComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
      HcleftnavComponent
  ],
 
})
export class HcleftnavModule { }
