<!-- /*==========================================
Title: change hc status and Transfer hc 
Author: Mandeep singh
Date:   17 April 2020
Last Change :  change hc status to leave or resume and transfer all patient to new hc 
-->
<mat-drawer-container class="sidenav-container" autosize>
  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-breadcrumb route="admin/hchome"></app-breadcrumb>

    <div class="col-md-12 no-padding ml-20">
      <h3 class="commontitleprimary hc-status-head"><button mat-icon-button class="commonbackbtn"
          [routerLink]="['/admin/hchome']">
          <i class="material-icons">keyboard_arrow_left</i>
        </button>Change Status
      </h3>
    </div>
    <div class="col-md-12 hc-status-container ml-20">
      <div class="wrapper">
        <form [formGroup]="dataForm" validate>
          <div class="col-md-12 hc-report-time-box">
            <div class="text-capitalize title">{{hcdata?.name}}</div>
            <div class="sub-title" [ngClass]="activeclass(hcdata?.is_on_leave,hcdata?.is_active)">
              {{hcdata?.is_on_leave?'On leave':(hcdata?.is_active?'Active':'Inactive')}}</div>

          </div>

          <div class="col-md-6">
            <mat-form-field appearance="outline" class="full-width-formfield  hc-status-selection">
              <mat-label>Set Status</mat-label>
              <mat-select [(ngModel)]="selectedOption" formControlName="request_type" required>
                <mat-option value="resume">Active</mat-option>
                <mat-option value="leave">On Leave</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <ng-container>
            <div class="col-md-12 fbcheckbox assign-hc-checkbox">
              <mat-checkbox value="checked" (click)="changeValue(checked)" *ngIf="selectedOption == 'leave'">
                Assign Users to other Healthcoach
              </mat-checkbox>
            </div>

            <div class="col-md-12 ">
              <div class="col-md-6 zeropadleft" [ngClass]="!checked ? 'hidefield' : ''">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Select Healthcoach</mat-label>
                  <mat-select formControlName="new_coach_ids" multiple #singleSelectHC #singleSelect
                    [required]="checked">
                    <mat-option>
                      <ngx-mat-select-search class="tagmat" [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
                        [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmathc" ngModel
                        (ngModelChange)="modelChangedHC()" ngDefaultControl>
                        <div class="mat-select-search-custom-header-content">
                          <i class="material-icons">
                            search
                          </i></div>
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let hc of filteredHC | async" [value]="hc.id">
                      {{hc.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


            </div>

            <div class="col-md-12 ">
              <h3 class="commontitleprimary">
                <button (click)="dataObject()" color="primary" mat-flat-button class="update-btn"
                  [disabled]="!dataForm.valid">
                  Update
                </button>
              </h3>
            </div>

          </ng-container>
        </form>
      </div>
    </div>
  </div>
</mat-drawer-container>