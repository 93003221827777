import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../../shared/guards';
import { HcuserassignComponent } from './hcuserassign.component';
import { ChangehealthcoachComponent } from './changehealthcoach/changehealthcoach.component';
import { PatientprofileComponent } from './patientprofile/patientprofile.component';
import { TransferlistdataComponent } from './transferlistdata/transferlistdata.component';
import { ViewuserassignComponent } from './viewuserassign/viewuserassign.component';

const routes: Routes = [
    { path: '', component: HcuserassignComponent, canActivate: [AdminGuardGuard] },
    { path: 'hcpatientprofile', component: PatientprofileComponent, canActivate: [AdminGuardGuard] },
    { path: 'hcpatientchangehealthcoach', component: ChangehealthcoachComponent, canActivate: [AdminGuardGuard] },
    { path: 'hcpatientviewuserassign', component: ViewuserassignComponent, canActivate: [AdminGuardGuard] },
    { path: 'hcuserassign/transferlist/:id', component: TransferlistdataComponent, canActivate: [AdminGuardGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HcuserassignRoutingModule {}