import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  /**Persist Subjects */
  public moduleTitle: Subject<string> = new Subject<string>();
  public userName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public gestationalAge: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public userCurrentStatus: Subject<string> = new Subject<string>();
  public userLastSeen: Subject<string> = new Subject<string>();
  public sessionIDToken: Subject<string> = new Subject<string>();  
  public selectedChatUser1: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public selectedChatUser: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public selectedChatUserRowData: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
  public isSelectedChatUserIsBot: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSelectedUserHasCKD: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public selectedTab: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedTabActivity: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public changeWatcher: Subject<any> = new Subject<any>();
  public changeWatcherp: Subject<any> = new Subject<any>();
  public masterSocketIO: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showSelectedNotificationUser: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
  /** Destroyable Subjects*/
  public supportTicketObjectHandler: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public tagTicketObjectHandler: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public startMultiUserSelection: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  public startMultiUserSelectionMode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public multiUserSelectionUsers: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public multiUserSelectionUsers1: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**Notification count subjects */
  public userListChatStatusCollection: BehaviorSubject<any> = new BehaviorSubject<any>({ initiate: [], waiting: [], respond: [] });
  public userListNotificationCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userListNotificationCollection: ReplaySubject<any> = new ReplaySubject<any>(null);
  public userListSequenceCollection: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  /**Alerts count subjects */
  public docSystemHaveAlerts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**Last message */
  public lastMessageCollection: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  /**Shimmer service */
  public showGlobalShimmer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  /**Login Obs */
    public userHasLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // multiUserSelectionUsers1: any;

  public updateTimerLog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() {
    this.sessionIDToken.next(localStorage.getItem('hcdttoken'));
  }

  public emitNewUserListNotifications(userID: string, notificationCount: number) {
    this.userListNotificationCollection.next({ id: '5b127249e74dd01dba2aaea7', count: 10 });
  }

  public addUserNewMessage(userID: string, message: string, activity: string, isSender: boolean): void {
    const dataArray = this.lastMessageCollection.getValue();
    const myMoment: any = '';
    if (message == '') {
      message = 'Sent Attachment'
    }
    dataArray[userID] = { message: message, userID: userID, activityDate: activity, activity: myMoment, isSender: isSender, isReceiver: !isSender };
    // this.lastMessageCollection.next(this.updateOldTimeStamps(dataArray));
    /**Update new message sequence also, not call in case of undefined */
    if (message)
      this.updateUserListSequenceArray(userID, activity);
  }

  public updateUserListSequenceArray(userID, activity) {
    if (userID.includes('Bot'))
      return;
    const oldData = this.userListSequenceCollection.getValue();
    oldData.push({ userID: userID, activity: activity });
    oldData.sort((a, b) => (a.activity > b.activity) ? 1 : ((b.activity > a.activity) ? -1 : 0));
    this.userListSequenceCollection.next(oldData);
  }

  public updateUserListChatStatusCollection(userID, activity) {

    const data = this.userListChatStatusCollection.getValue();
    let respondArray;
    if (data.top)
      respondArray = data.top.slice();
    else
      respondArray = [];
    /**Check wheathe already exist or not */
    const x = respondArray.find(chat => { return chat == userID });
    if (!x) {
      respondArray.push(userID);
    }
    respondArray.reverse();
    data.top = respondArray;
    this.userListChatStatusCollection.next(data);
  }

  public updateUserListChatStatusCollection1(userID, activity) {
    const data = this.userListChatStatusCollection.getValue();
    const respondArray = data.respond.slice();
    const x = respondArray.find(chat => { return chat == userID });
    if (!x) {
      respondArray.push(userID);
    }
    respondArray.reverse();
    data.top = respondArray;
    this.userListChatStatusCollection.next(data);
  }

  public updateOldTimeStamps(dataArray): any {
    return dataArray;
  }

  public replaceWords(input: string): any {
    let output;
    input = input.toLowerCase();
    if (input == 'a few seconds ago') {
      output = input.replace('a few', 'few');
    }
    else if (input == 'an hour ago') {
      output = input.replace('an hour', '1 hour');
    }
    else {
      output = input;
    }
    return output.toLowerCase();
  }

  public deleteUserNewMessage(userID: string): void {
    const dataArray = this.lastMessageCollection.getValue();
    if (dataArray[userID]) {
      delete dataArray[userID];
    }
    this.lastMessageCollection.next(dataArray);
  }

   // whatsapp link
   public messageSource:  Subject<any> = new Subject<any>()
   currentMessage = this.messageSource.asObservable();
 
   changeMessage(message) {
     this.messageSource.next(message)
   }

   //send broadcast
   public broadcastSource: Subject<string> = new Subject<string>();
   broadcastMessage = this.broadcastSource.asObservable();

   sendMessages(message) {
    this.broadcastSource.next(message)
  }

}
