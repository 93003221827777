<!-- /*==========================================
Title:  Add/ Edit FAQ Category
Author: Nikhil Raj A
Date:   22 April 2020
Last Change : Mobile UI Updates
===========================================*/ -->
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/magazinecategoryhome/magazinecategoryhomeedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Magazine Category
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Title</mat-label>
                                <input matInput placeholder="Enter the title" formControlName="title" maxlength="30" required>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Detailed Text</mat-label>
                                <input matInput placeholder="Enter the title" formControlName="detailed_text" maxlength="15" required>
                            </mat-form-field> -->
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Detailed Text</mat-label>
                                <textarea matInput placeholder="Enter the description"
                                    formControlName="detailed_text"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Magazine Category</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside">
                            <div class="title-holder">
                                <div class="card" *ngIf="!(dataForm?.value.title == '')">
                                    <div class="content-text">
                                        <div class="iphonetitle">
                                            {{dataForm?.value?.title}}
                                        </div>
                                    </div>
                                    <i class="material-icons">chevron_right</i>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div> -->

        </div>
    </div>
</mat-drawer-container>