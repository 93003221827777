import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PolicyApiService } from 'src/app/shared/services/policy-api.service';
import { PolicyMobileNumberVerificationModalComponent } from './policy-mobile-number-verification-modal/policy-mobile-number-verification-modal.component';


@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})

export class PolicyComponent implements OnInit {

  policyNumber: string = "";
  noPolicyFound: boolean = false;
  policyData: any = [];

  constructor(
    private dialog: MatDialog,
    public policyService: PolicyApiService

  ) { }

  ngOnInit(): void {
    
  }

  callPoliciesSearch(){

    this.noPolicyFound = false;
    this.policyData = [];

    this.policyService.getPolicies(this.policyNumber)
      .subscribe((response: any) => {

        this.policyData = response.data;

      }, (error: any) => {
        if (error.status === 400){
          this.noPolicyFound = true;
        }
      });
  }

  verifyMobileNumber(customerDetails: any){

    const dialogueRef = this.dialog.open(PolicyMobileNumberVerificationModalComponent, {
      width: "760px",
      height: "350px",
      data: customerDetails
    });

    dialogueRef.afterClosed().subscribe((data) => {
      if (data) {
        this.callPoliciesSearch();
      } 
    });

  }

  clearSearch(){
    return this.policyNumber = "";
  }

}
