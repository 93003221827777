<!-- <mat-drawer-container class="sidenav-container" autosize> -->
    <!-- <app-adminleftnav></app-adminleftnav> -->

    <!-- <div class="sidenav-content"> -->
        <!-- <div class="breadcrumbholder">
            <app-breadcrumb route="admin/paymentmapping/paymentmappingedit"></app-breadcrumb>
        </div> -->

        <!-- <div class="col-md-12"> -->
            <!-- <h3 class="commontitleprimary"> -->
                <!-- <app-backbutton></app-backbutton> -->
                <!-- {{newitem ? 'New' : 'Update'}}
                Plan Mapping
            </h3> -->
        <!-- </div> -->
      
        <div class="col-md-12">
            <div class="clearfix">
              <p class="pull-left title-modal"> {{newitem ? 'New' : 'Update'}} Payment Mapping
              </p>
              <div class="pull-right">
                <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
              </div>
            </div>
          </div>
          <mat-dialog-content>
        <div class="col-md-12">
            <form [formGroup]="dataForm" validate class="leftformsection">
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Select Plan</mat-label>
                    <mat-select formControlName="plan_ids"  multiple required #singleSelectplan (selectionChange)="changed()">
                        <mat-option>
                            <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                [placeholderLabel]="'Search Item'" [noEntriesFoundLabel]="'No matching records found'"
                                [formControl]="searchmatplan" ngModel (ngModelChange)="modelChangedPlan()"
                                ngDefaultControl>
                                <div class="mat-select-search-custom-header-content">
                                    <i class="material-icons">
                                        search
                                    </i></div>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let plan of filteredPlan | async" [value]="plan.id">
                            {{plan.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Select Client</mat-label>
                    <mat-select formControlName="client_data_fk" #singleSelectClient>
                        <mat-option>
                            <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                [placeholderLabel]="'Search Item'" [noEntriesFoundLabel]="'No matching records found'"
                                [formControl]="searchmatclient" ngModel (ngModelChange)="modelChangedClient()"
                                ngDefaultControl>
                                <div class="mat-select-search-custom-header-content">
                                    <i class="material-icons">
                                        search
                                    </i></div>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option value="">No Client</mat-option>
                        <mat-option *ngFor="let client of filteredClient | async" [value]="client.id">
                            {{client.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Select Therapy</mat-label>
                    <mat-select formControlName="therapy_data_fk" required #singleSelectTherapy >
                        <mat-option>
                            <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                [placeholderLabel]="'Search Item'" [noEntriesFoundLabel]="'No matching records found'"
                                [formControl]="searchmattherapy" ngModel (ngModelChange)="modelChangedTherapy()"
                                ngDefaultControl>
                                <div class="mat-select-search-custom-header-content">
                                    <i class="material-icons">
                                        search
                                    </i></div>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let therapy of filteredTherapy | async" [value]="therapy.id" >
                            {{therapy.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-flat-button color="primary" class="submit-btn"
                (click)="dataObject()" [disabled]="!dataForm.valid ">{{newitem ? 'Create' : 'Update'}}
                    Mapping</button>

            </form>
        </div>
</mat-dialog-content>
<!-- </mat-drawer-container> -->