import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ContextService } from '../../services/context.service';



@Component({
  selector: 'app-hctaskmanual',
  templateUrl: './hctaskmanual.component.html',
  styleUrls: ['./hctaskmanual.component.scss']
})
export class HctaskmanualComponent implements OnInit {
  public userSelectionStarted: string = "no";
  public showUserDetailsSection: string = "yes";
  
  constructor(private appContext: ContextService, private cdRef: ChangeDetectorRef, private browserTitle: Title) {

  }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Chat');
  }
  ngOnInit() {
    this.setSEOTags();
    /**Subscribe user selection */
    this.appContext.startMultiUserSelection.subscribe(data => {
      var mode: string = this.appContext.startMultiUserSelectionMode.getValue();
      if (data) {
        this.userSelectionStarted = mode;
      } else {
        this.userSelectionStarted = "no";
      }
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });

    /**Subscribe for isSelectedChatUserIsBot event */
    this.appContext.isSelectedChatUserIsBot.subscribe(data => {
      if (data) {
        this.showUserDetailsSection = "no";
      } else {
        this.showUserDetailsSection = "yes";
      }
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });

  }

  ngAfterViewInit() {
    this.appContext.moduleTitle.next("Chats");
    //this.appContext.userName.next("Amit Kumar");
    //this.appContext.userCurrentStatus.next("Offline.");
    //this.appContext.userLastSeen.next("Last seen 02:30 PM");
    this.cdRef.detectChanges();
  }

  ngOnChanges() {
  }

  ngOnDestroy(): void {
    this.appContext.selectedChatUser.next(null);
    /**Assign row data also */
    this.appContext.selectedChatUserRowData.next(null);
    /**Change watcher */
    this.appContext.changeWatcher.next(true);
    this.cdRef.detach();
  }


}

