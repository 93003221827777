import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientactivationService {
  public apiUrl: string = environment.serverUrl;
  private getallcampaingurl = this.apiUrl + 'campaign/user/';
  private preactivateUrl = this.apiUrl + 'patient/register/bydoctor';
  private deeplinkUrl = this.apiUrl + 'patient/register/qrdeeplink';
  constructor() { }

  // getAllCampaign(): Observable<string> {
  //   var SessionID = localStorage.getItem('scarlettoken');
  //   var doctorID = localStorage.getItem('scarletuserid');
  //   if (SessionID || SessionID !== '') {
  //     const endPoint = doctorID;
  //     let headers = new Headers();
  //     headers.append('x-access-token', SessionID);
  //     // headers.append('user_id', doctorID);
  //     let options = new RequestOptions({ headers: headers });
  //     return this._http
  //       .get(this.getallcampaingurl + endPoint, options)
  //       .map(response => <string>response.json());
  //   }
  // }
  // preActiveUser(data): Observable<string> {
  //   var SessionID = localStorage.getItem('scarlettoken');
  //   var body = {
  //     "campaign_id_fk" : data.condition,
  //     "user_code" : JSON.parse(localStorage.getItem('scarletuserdata')).code 
  //   }
  //   if (SessionID || SessionID !== '') {
  //     let headers = new Headers();
  //     headers.append('x-access-token', SessionID);
  //     headers.append('phone', data.phone);
  //     headers.append('country-code', data.country_code);
  //     let options = new RequestOptions({ headers: headers });
  //     return this._http
  //       .post(this.preactivateUrl,body, options)
  //       .map(response => <string>response.json());
  //   }
  // }
  

}
