<div>
    <div class="title-holder d-flex align-items-center">
        <h3 class="title">
            Triggers
        </h3>
    </div>
    <div class="triggercontent" *ngIf="!(allloggedtrigger.length ==0 && verifiedtrigger.length == 0)">
        <div class="trigger-wrapper mt-30" *ngIf="verifiedtrigger.length !=0">
            <div class="title">
                Verified triggers
            </div>
            <div class="nav nav-pills faq-nav" id="faq-tabs" role="tablist" aria-orientation="vertical">
                <div class="nav-link active d-flex py-3 justify-content-between align-items-center"
                    *ngFor="let vtrigger of verifiedtrigger">
                    <div class="title-section">
                        <div class="imgbox">
                            <img [src]="s3url + vtrigger?.image_media?.path" class="img-fluid">
                        </div>
                        <span>{{vtrigger.title}}</span>
                    </div>
                    <a [routerLink]="['/triggersheet/', vtrigger?.title, vtrigger?.content]"><img
                            src="assets/img/info.svg" class="img-fluid" alt=""></a>
                </div>
            </div>
        </div>
        <div class="spacing-ash"></div>
        <div class="trigger-wrapper" *ngIf="allloggedtrigger.length !=0">
            <div class="title">
                All triggers
            </div>
            <div class="nav nav-pills faq-nav" id="faq-tabs" role="tablist" aria-orientation="vertical">
                <div class="nav-link active d-flex py-3 justify-content-between align-items-center"
                    *ngFor="let vtrigger of allloggedtrigger">
                    <div class="title-section">
                        <div class="imgbox">
                            <img [src]="s3url + vtrigger?.image_media?.path" class="img-fluid">
                        </div>
                        <span>{{vtrigger.title}}</span>
                    </div>
                    <a [routerLink]="['/triggersheet/', vtrigger?.title, vtrigger?.content]"><img
                            src="assets/img/info.svg" class="img-fluid" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</div>