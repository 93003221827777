import { Injectable } from '@angular/core';
import { IPersistenceContainer } from 'angular-persistence';
import 'rxjs/add/operator/map';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PatientDataService {
  public apiUrl: string = environment.serverUrl;
  private prodUrl = this.apiUrl + 'doctor/user/diary';
  private presUrl = this.apiUrl + 'utilities/prescriptions';
  private targetUrl = this.apiUrl + 'utilities/targets';
  private container: IPersistenceContainer;
  constructor() { }
 


}
