
<form [formGroup]="addmedicineform" validate>
    <div class="row">
        <div class="col-md-12">
            <h4>Add Medicine</h4>
            <hr />
            <div class="row">
                <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Medicine Type</mat-label>
                    <mat-select #fontSize value="16px" [(ngModel)]="typeFilters" formControlName="medicine_type"
                        required>
                        <mat-option value="tablet">Tablet</mat-option>
                        <mat-option value="syrup">Syrup</mat-option>
                        <mat-option value="capsule">Capsule</mat-option>
                        <mat-option value="insulin_pen">Insulin</mat-option>
                        <mat-option value="ointment">Ointment</mat-option>
                        <mat-option value="injectable">Injectable</mat-option>
                        <mat-option value="nebulizer">Nebulizer</mat-option>
                        <mat-option value="mdi">MDI</mat-option>
                        <mat-option value="dpi">DPI</mat-option>
                        <mat-option value="bai">BAI</mat-option>
                        <mat-option value="nasal_spray">Nasal Spray</mat-option>
                        <mat-option value="dpi_revolizer">DPI Revolizer</mat-option>
                        <mat-option value="dpi_multihaler">DPI Multihaler</mat-option>
                        <mat-option value="dpi_rotahaler">DPI Rotahaler</mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="typeFilters == 'capsule' || typeFilters == 'tablet'">
                    <mat-label>Medicine Shape</mat-label>
                    <mat-select #fontSize value="16px" formControlName="medicine_shape">
                        <mat-option value="round">Round</mat-option>
                        <mat-option value="oblong">Oblong</mat-option>
                        <mat-option value="oval">Oval</mat-option>
                        <mat-option value="square">Square</mat-option>
                        <mat-option value="rectangle">Rectangle</mat-option>
                        <mat-option value="diamond">Diamond</mat-option>
                        <mat-option value="round_dash">Round Dash</mat-option>
                        <mat-option value="oblong_dash">Oblong Dash</mat-option>
                        <mat-option value="oval_dash">Oval Dash</mat-option>
                        <mat-option value="square_dash">Square Dash</mat-option>
                        <mat-option value="rectangle_dash">Rectangle Dash</mat-option>
                        <mat-option value="diamond_dash">Diamond Dash</mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="name" formControlName="name" required>
                </mat-form-field>

                <div class="clearfix"></div>
                <div class="row col-md-12 no-margin">
                    <div class="label-color" *ngIf="typeFilters != 'capsule'">Select Color</div>
                    <div class="label-color" *ngIf="typeFilters == 'capsule'">Capsule Upper half color</div>

                    <div class="colorholder col-md-12">
                        <div class="radio-holder ml-0">
                            <input type="radio" name="first_color" formControlName="first_color" value="#eaeaea"
                                id="color1" />
                            <label for="color1">
                                <div class="rectangle" [style.background]="'#eaeaea'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#ffc699"
                                id="color2" />
                            <label for="color2">
                                <div class="rectangle" [style.background]="'#ffc699'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#f0df64"
                                id="color3" />
                            <label for="color3">
                                <div class="rectangle" [style.background]="'#f0df64'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#afed93"
                                id="color4" />
                            <label for="color4">
                                <div class="rectangle" [style.background]="'#afed93'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#7de1eb"
                                id="color5" />
                            <label for="color5">
                                <div class="rectangle" [style.background]="'#7de1eb'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#788de4"
                                id="color6" />
                            <label for="color6">
                                <div class="rectangle" [style.background]="'#788de4'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#9a80eb"
                                id="color7" />
                            <label for="color7">
                                <div class="rectangle" [style.background]="'#9a80eb'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#d76e6b"
                                id="color16" />
                            <label for="color16">
                                <div class="rectangle" [style.background]="'#d76e6b'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="first_color" formControlName="first_color" value="#8694a4"
                                id="color17" />
                            <label for="color17">
                                <div class="rectangle" [style.background]="'#8694a4'"></div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="row col-md-12">
                    <div class="label-color" *ngIf="typeFilters == 'capsule'">Capsule Bottom half color</div>
                    <div class="colorholder col-md-12" *ngIf="typeFilters == 'capsule'">
                        <div class="radio-holder ml-0">
                            <input type="radio" name="second_color" formControlName="second_color" value="#eaeaea"
                                id="color8" />
                            <label for="color8">
                                <div class="rectangle" [style.background]="'#eaeaea'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#ffc699"
                                id="color9" />
                            <label for="color9">
                                <div class="rectangle" [style.background]="'#ffc699'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#f0df64"
                                id="color10" />
                            <label for="color10">
                                <div class="rectangle" [style.background]="'#f0df64'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#afed93"
                                id="color11" />
                            <label for="color11">
                                <div class="rectangle" [style.background]="'#afed93'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#7de1eb"
                                id="color12" />
                            <label for="color12">
                                <div class="rectangle" [style.background]="'#7de1eb'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#788de4"
                                id="color13" />
                            <label for="color13">
                                <div class="rectangle" [style.background]="'#788de4'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#9a80eb"
                                id="color14" />
                            <label for="color14">
                                <div class="rectangle" [style.background]="'#9a80eb'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#d76e6b"
                                id="color15" />
                            <label for="color15">
                                <div class="rectangle" [style.background]="'#d76e6b'"></div>
                            </label>
                        </div>
                        <div class="radio-holder">
                            <input type="radio" name="second_color" formControlName="second_color" value="#8694a4"
                                id="color15" />
                            <label for="color15">
                                <div class="rectangle" [style.background]="'#8694a4'"></div>
                            </label>
                        </div>
                    </div>
                </div>
                <mat-form-field appearance="outline" class="col-md-6"
                    *ngIf="!(typeFilters == 'tablet' || typeFilters == 'capsule' || typeFilters == 'insulin_pen' || typeFilters == 'ointment' || typeFilters == 'mdi'|| typeFilters == 'dpi'|| typeFilters == 'dpi_multihaler'|| typeFilters == 'dpi_rotahaler'|| typeFilters == 'bai'|| typeFilters == 'nasal_spray')">
                    <mat-label>Volume<span
                            *ngIf="typeFilters == 'syrup' || typeFilters == 'injectable'||  typeFilters == 'nebulizer'">(ML)</span>
                    </mat-label>
                    <input type="number" matInput placeholder="volume" formControlName="volume" min="0" max="100"
                        oninput="validity.valid||(value='');">
                </mat-form-field>


                <mat-form-field appearance="outline" class="col-md-6"
                    *ngIf="typeFilters == 'insulin_pen' || typeFilters == 'injectable' || typeFilters == 'mdi'|| typeFilters == 'dpi'|| typeFilters == 'dpi_multihaler'|| typeFilters == 'dpi_rotahaler'|| typeFilters == 'bai'||typeFilters == 'nasal_spray'">
                    <mat-label *ngIf="typeFilters == 'insulin_pen' || typeFilters == 'injectable' ">Number of Shots
                    </mat-label>

                    <mat-label *ngIf="typeFilters == 'mdi'|| typeFilters == 'bai'">Number of Puffs</mat-label>
                    <mat-label *ngIf="typeFilters == 'dpi'|| typeFilters == 'dpi_rotahaler'">Number of Capsules
                    </mat-label>
                    <mat-label *ngIf="typeFilters == 'dpi_multihaler'">Number of DOSES</mat-label>
                    <mat-label *ngIf="typeFilters == 'nasal_spray'">Number of spray</mat-label>


                    <input type="number" matInput placeholder="num_shots" formControlName="num_shots" min="0"
                        oninput="validity.valid||(value='');">
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-6"
                    *ngIf="!(typeFilters == 'syrup' || typeFilters == 'ointment' || typeFilters == 'injectable'|| typeFilters == 'mdi'|| typeFilters == 'dpi'|| typeFilters == 'dpi_multihaler'|| typeFilters == 'dpi_rotahaler'|| typeFilters == 'bai'||typeFilters == 'nebulizer')">
                    <mat-label>Num Units</mat-label>
                    <input type="number" matInput placeholder="Num Units" formControlName="num_units" min="0"
                        oninput="validity.valid||(value='');">
                </mat-form-field>



                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="typeFilters == 'capsule' || typeFilters == 'tablet'">
                    <mat-label>Pre Meal</mat-label>
                    <mat-select #fontSize value="16px" formControlName="pre_meal">
                        <mat-option value="true">Yes</mat-option>
                        <mat-option value="false">No</mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Duration(Days)</mat-label>
                    <input type="number" matInput placeholder="Eg Everyday(1), Alternate(2) or custom gap"
                        formControlName="duration" min="0" oninput="validity.valid||(value='');">

                </mat-form-field>

                <div class="row col-md-12" *ngIf="typeFilters == 'capsule' || typeFilters == 'tablet'">
                    <p class="col-md-12">Dosage</p>
                    <mat-form-field appearance="outline" class="col-md-4">
                        <mat-label>Morining Dose</mat-label>
                        <input type="number" step="0.5" matInput placeholder="Morining Dose"
                            formControlName="morning_dose">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-4">
                        <mat-label>Afternone Dose</mat-label>
                        <input type="number" step="0.5" matInput placeholder="Afternone Dose"
                            formControlName="afternone_dose">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-4">
                        <mat-label>Evening Dose</mat-label>
                        <input type="number" step="0.5" matInput placeholder="Evening Dose"
                            formControlName="evening_dose">
                    </mat-form-field>
                </div>

                <!-- <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Choose a Start date</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Choose a Start date"
                        formControlName="start_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> -->

                <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Choose a Start date</mat-label>
                    <input matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time"
                        formControlName="start_date">
                    <owl-date-time #dt1></owl-date-time>
                </mat-form-field>

            </div>
        </div>
        <div class="col-md-12">
            <button class="col-md-12" mat-flat-button color="primary" [disabled]="!addmedicineform.valid"
                class="submit-btn" (click)="dataObject()">Add Medicine</button>
        </div>
    </div>

</form>