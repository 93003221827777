<span class="slidetogglecss" (click)="togglenav()"><i [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">{{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}</i></span>
<div class="userlistnavcontent">
 <div class="row">
  <div class="col-md-12 submenubar">
   <h3 class="userlisttitle">Media Upload </h3> 
   <!-- <a [routerLink]="['/content/conditions']" routerLinkActive="active">Conditions</a> -->
   <a [routerLink]="['/content/media']" routerLinkActive="active">Magazine</a>
   <!-- <a [routerLink]="['/content/activationcode']" routerLinkActive="active">Activation code</a> -->
   <a [routerLink]="['/content/notification']" routerLinkActive="active">Notification</a>
   <!-- <a [routerLink]="['/content/campaign']" routerLinkActive="active">Campaign</a> -->
  </div>

 </div>
</div>