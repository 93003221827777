
  <div class="col-md-12 p-1">
    <div style="float: left">
    <h3 class="commontitleprimary hc-status-head">
       View description
    </h3>
    </div>
    <div class="close-icon" style="float:right">
        <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()" class="img-responsive close-modal" alt="">
      </div>
</div>
<div class="container-table1">
<table class="table table-bordered">
    <thead>
        <tr>
            <th>Request Title</th>
            <th>Description</th>
        </tr>
    </thead>
    <tbody style="background:#F1F1F1">
        <tr>
            <td>{{viewDetails?.title}}</td>
            <td>{{viewDetails.description}}</td>
        </tr>
    </tbody>
</table>
</div>