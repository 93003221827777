<div class="row">
    <div class="col-md-12">  
    
        <div class="title-wrapper">
            <h2 class="commontitleprimary text-capitalize">
                <span style="color:var(--primary-orange);">Mobile Number Change Log</span>
            </h2>
        
            <div class="close-icon">
                <img src="assets/img/dls/icons/cross.svg" (click)="close()">
            </div>
        </div>
        
    
        <div class="row">
            <div class="col-md-12">
                <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="getNextSet()">
                    <table mat-table [dataSource]="mySource" multiTemplateDataRows class="widthfluid bordergrey list-table">
                
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>Old</th>
                        <td mat-cell *matCellDef="let element"> 
                            {{ element.current_country_code }} - {{element.current_phone}} 
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="newNumber">
                        <th mat-header-cell *matHeaderCellDef>New</th>
                        <td mat-cell *matCellDef="let element"> 
                            {{ element.new_country_code }} - {{element.new_phone}} 
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="reasonHC">
                        <th mat-header-cell *matHeaderCellDef>HC Note</th>
                        <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="healthCoach">
                        <th mat-header-cell *matHeaderCellDef>HC</th>
                        <td mat-cell *matCellDef="let element"> {{ element.requester_user_name }} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element"> 
                            <ng-container *ngIf="element.request_status === null">
                                Action Pending
                            </ng-container>
                            <ng-container *ngIf="element.request_status === 1">
                                Approved
                            </ng-container>
                            <ng-container *ngIf="element.request_status === 2">
                                Rejected
                            </ng-container>
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="adminName">
                        <th mat-header-cell *matHeaderCellDef>Admin</th>
                        <td mat-cell *matCellDef="let element"> {{ element.admin_user_name }} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="reasonAdmin">
                        <th mat-header-cell *matHeaderCellDef>Admin Note</th>
                        <td mat-cell *matCellDef="let element"> {{element.admin_status_reason}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="dateAndTime">
                        <th mat-header-cell *matHeaderCellDef>Date & Time</th>
                        <td mat-cell *matCellDef="let element"> {{convertDate(element.created_at)}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                
                    </table>
                </div>  
            </div>

        </div>
    
    </div>
</div>