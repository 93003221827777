import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-onboardingcontent',
  templateUrl: './onboardingcontent.component.html',
  styleUrls: ['./onboardingcontent.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class OnboardingcontentComponent implements OnInit {
  displayedColumns: string[] = ["widget_data_fk", "title", "updatedat","action"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  private myhash: any = {};
  allwidgets: any;
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  public tags: any;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Onboarding Content');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.getWidgets();
    this.getLanguages();
    this.search();
  }

  // ----------------------------

  // To get All Widgets
  // ----------------------------

  getWidgets() {
    this.contentService
      .getGraphQlData('{getAllOnboardingWidgets(appMode:false,status:true){title,id}}', false)
      .subscribe(
        (res: any) => this.getWidgetsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getWidgetsSuccess(data) {
    this.allwidgets = data.getAllOnboardingWidgets;
    this.allwidgets.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  getWidgetName(id) {
    var title = this.myhash[id];
    return title;
  }
  
  getUsername(id) {
    return this.contentService.allusers[id];
  }

  // ----------------------------

  // To get All getLanguages
  // ----------------------------

  getLanguages() {
    this.contentService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getLanguagesSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLanguagesSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == "1")[0].id;
  }

  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  applyFilterType(val) {
    var filtereddata = null;
    if (val != 'all') {
      filtereddata = this.tabulardata.filter(t => t.type == val);
    }
    else {
      filtereddata = this.tabulardata;
    }
    this.dataSource = new MatTableDataSource<Element>(filtereddata);
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }


  // List Onboarding Content 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getSearchData(this.searchcriteria, this.page.toString(), "20", "all", "onboardingflowmapper", true)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'updatedat': return new Date(item.updated_at);
          default: return item[property];
        }
      } 

    }
  }

  // ----------------------------

}
export interface PeriodicElement {
  widget_data_fk: string;
  title: string;
  updatedat: string;
}
