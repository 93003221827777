<!-- /*==========================================
Title:  Add/ Edit FAQ Category
Author: Nikhil Raj A
Date:   22 April 2020
Last Change : Mobile UI Updates
===========================================*/ -->
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/faqcategoryhome/faqcategoryedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Category
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Title</mat-label>
                                <input matInput placeholder="Enter the title" formControlName="title" maxlength="30" required>
                            </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Tags</mat-label>
                                    <mat-select formControlName="tags" multiple #singleSelectTag>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                                ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content notranslate">
                                                    <i class="material-icons">
                                                        search
                                                    </i>
                                                </div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option class="camelcased select-tags-active"
                                            *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                            {{tag.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                <mat-label>Select Image</mat-label>
                                <input matInput (click)="openmediabox()" formControlName="image_media" required
                                    readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Category</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside">
                            <div class="title-holder">
                                <div class="card" *ngIf="!(dataForm?.value.title == '')">
                                    <div class="options-image">
                                        <img *ngIf="allmedia" [src]="s3url + allmedia" class="img-responsive" />
                                    </div>
                                    <div class="content-text">
                                        <div class="iphonetitle">
                                            {{dataForm?.value?.title}}
                                        </div>
                                    </div>
                                    <i class="material-icons">chevron_right</i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</mat-drawer-container>