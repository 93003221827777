import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-editrouteofadmin',
  templateUrl: './editrouteofadmin.component.html',
  styleUrls: ['./editrouteofadmin.component.scss']
})
export class EditrouteofadminComponent implements OnInit {
  public dataVal: any;
  pumpDetails: any;
  public selectedUserID: any;
  public editRoaForm = this.fb.group({
    patient_id_fk: [''],
    route_of_administration: ['', [Validators.required]],
    reason_for_addition: [''],
    log_date: ['', Validators.required]
  });
  constructor(public fb: FormBuilder, private userdetailsService: UserdetailsService, private appContext: ContextService, public dialog: MatDialog, private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any, public systemService: SystemService,public commonService:CommonserviceService) { }

  ngOnInit(): void {
    
    this.dataVal = this.data;
    this.getRouteList();
    this.selectedUserID = this.appContext.selectedChatUser.getValue();

  }

  getRouteList() {
    this.userdetailsService.getOneRoute(this.dataVal)
      .subscribe(
        (res: any) => this.getTherapyData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private getTherapyData(res) {
    
    this.pumpDetails = res.data;
    this.editRoaForm.patchValue(this.pumpDetails);
  }


  editRouteofAdmin() {
    var bodydata = this.editRoaForm.value;
    let reason_for_addition = this.commonService.isHTML(this.editRoaForm.value.reason_for_addition);
    if(reason_for_addition  === true){
      this.editRoaForm.value.reason_for_addition.patchValue('')
      return;
    }
    bodydata.route_of_administration = bodydata.route_of_administration;
    bodydata.reason_for_addition = bodydata.reason_for_addition;
    bodydata.patient_id_fk = this.selectedUserID.toString();
    bodydata['updated_by_fk'] = localStorage.getItem('scarletuserid');
    var newlogdate = new Date(bodydata.log_date);
    var mm = (newlogdate.getMonth() + 1).toString().padStart(2, "0");;
    var dd = newlogdate.getDate().toString().padStart(2, "0");
    var yy = newlogdate.getFullYear();
    bodydata.log_date = yy + '-' + mm + '-' + dd;
    this.userdetailsService.updateROAData(this.dataVal, bodydata)
      .subscribe(
        (res: any) => this.addSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private addSuccess() {
    this.dialog.closeAll();
    this.systemService.showSnackBar("Route Of Administration updated.", "OK");
  }
}

