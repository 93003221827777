import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as jsPDF from "jspdf";
import * as html2canvas from '../../../../../../assets/js/html2canvas.js';

@Component({
  selector: 'app-dietplanpdfreport',
  templateUrl: './dietplanpdfreport.component.html',
  styleUrls: ['./dietplanpdfreport.component.scss']
})
export class DietPlanpdfreportComponent implements OnInit {
  
  public mealPlanData;
  public mealPlanDataName;
  public displayedColumns;
  public timeleftval: any;
  public mealPlanPatientData;

  constructor(private browserTitle: Title, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.mealPlanData = JSON.parse(localStorage.getItem('mealPlanData'));
    this.displayedColumns = Object.keys(this.mealPlanData.mealPlan[0]);
    this.displayedColumns = this.displayedColumns.filter(m => m != 'id');
    this.mealPlanDataName = localStorage.getItem('mealPlanDataName');
    this.mealPlanPatientData = JSON.parse(localStorage.getItem('mealPlanPatientData'));
    this.setSEOTags();
    this.route.params.subscribe((params) => {
      this.countdown()
      setTimeout(() => {
        this.pdfDownload(this.mealPlanDataName)
      }, 5000);
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Diet Plan | Report');
  }

  countdown() {
    let timeleft = 5;
    let scope = this;
    let downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        scope.timeleftval = "Finished";
      } else {
        scope.timeleftval = "Diet Plan will be downloaded in " + timeleft + " Sec";
      }
      timeleft -= 1;
    }, 1000);
  }


  pdfDownload(name) {
    let data = document.getElementById('section1');
    let options = {
      allowTaint: false,
      useCORS: true,
      dpi: 96
    }

    html2canvas(data, options).then(canvas => {
      let img = canvas.toDataURL("image/png");
      let imgWidth = 210;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let doc = new jsPDF('P', 'mm', 'a4', true);
      doc.addImage(img, 'JPG', 0, 0, imgWidth, imgHeight, 'FAST');
      localStorage.removeItem('mealPlanData');
      localStorage.removeItem('mealPlanDataName');
      localStorage.removeItem('mealPlanPatientData');
      doc.save(name + '-diet-plan.pdf');
      window.close()
    });
  }
}