<div class="userdetailcontent matcardbottomzero">
  <div>
    <h3 class="generictitle col-md-6">Lab Reports</h3>
    <button mat-icon-button class="plusrectangletransparent pull-right  col-md-6"
      style="margin-right: 15px;margin-top:15px;" (click)="openLabDialog()">
      <mat-icon>add</mat-icon>
    </button>
    <div class="chartheader">

      <div class="clearfix"><br /></div>
      <form [formGroup]="dataForm" validate class="filter-form">
        <mat-form-field appearance="outline" class="option-selection col-md-12">
          <mat-label>Select an option</mat-label>
          <mat-select formControlName="selectedOption" (selectionChange)="selectVal($event.value)">
            <mat-option class="camelcased" *ngFor="let tag of eligibleLogData" [value]="tag.title">
              {{changeTextInList(tag.title)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="option-selection col-md-12 bsugar"
          *ngIf="mainoption == 'Complete Blood Count'">
          <mat-label>Sub Type</mat-label>
          <mat-select class="camelcased" [(ngModel)]="defaultcbcoption" formControlName="cbclabtype">
            <mat-option class="camelcased" value="cbc/meanPlateletVolume">Mean Platelet Volume</mat-option>
            <mat-option class="camelcased" value="cbc/wbcDifferential">WBC differential count</mat-option>
            <mat-option class="camelcased" value="cbc/rbc">Red blood cell count (RBC or
              Erythrocyte count )</mat-option>
            <mat-option class="camelcased" value="cbc/hematocrit">Hematocrit (Hct)</mat-option>
            <mat-option class="camelcased" value="cbc/mcv">Mean corpuscular volume (MCV)</mat-option>
            <mat-option class="camelcased" value="cbc/plateletCount">Platelet count</mat-option>
            <mat-option class="camelcased" value="cbc/mch">Mean corpuscular hemoglobin (MCH)
            </mat-option>
            <mat-option class="camelcased" value="cbc/mchc">Mean corpuscular hemoglobin concentration (MCHC)
            </mat-option>
            <mat-option class="camelcased" value="cbc/rdw">Red cell distribution width (RDW)
            </mat-option>
            <mat-option class="camelcased" value="cbc/esr">Erythrocyte sedimentation rate (ESR)</mat-option>
            <mat-option class="camelcased" value="cbc/wbc">White blood cell count</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-6"
          style="padding-right: 0px;">

          <mat-label>Choose start date</mat-label>
          <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="morrow"
            placeholder="Choose a date">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-6">
          <mat-label>Choose end date</mat-label>
          <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="dataForm.get('startdate')?.value"
            [max]="morrow" placeholder="Choose a date">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker2>
          </mat-datepicker>
        </mat-form-field>
        <div class="col-md-12">
          <button mat-flat-button color="primary" class="primary margin-btn" (click)="applymainfilter()"
            style="width: 100%;">Apply</button>
        </div>
      </form>
    </div>

    <div class="clearfix"><br /></div>
    <div *ngIf="tabulardata?.length == 0" class="text-center nodata-note">
      <p>No logs found</p>
    </div>

    <div class="example-container diarycss" *ngIf="tabulardata?.length != 0">
      <table mat-table [dataSource]="dataSource" matSort class="list-table">
        <ng-container matColumnDef="measure">
          <mat-header-cell *matHeaderCellDef> Measure</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}">
            {{checkNumber(showGenericData.measure)}} {{showGenericData.unit}} </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="unit">
          <mat-header-cell *matHeaderCellDef> Unit</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"> {{showGenericData.unit}} </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="log_date">
          <mat-header-cell *matHeaderCellDef> Log Date </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{convertIsoDate(showGenericData.log_date)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="aptt">
          <mat-header-cell *matHeaderCellDef> APTT</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.aptt}} {{showGenericData.aptt_unit}}
          </mat-cell>
        </ng-container>
   
        <ng-container matColumnDef="tag">
          <mat-header-cell *matHeaderCellDef> Tags</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}">  {{!showGenericData.tag?'-':showGenericData.tag}}
            <!-- {{showGenericData.tag}}         -->
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="fibrinogen">
          <mat-header-cell *matHeaderCellDef> fibrinogen</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.fibrinogen}}
            {{showGenericData.fibrinogen_unit}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="TC">
          <mat-header-cell matTooltip="Total cholesterol" *matHeaderCellDef>TC</mat-header-cell>
          <mat-cell  *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.total_cholesterol}} {{showGenericData.total_cholesterol_unit}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hdl">
          <mat-header-cell *matHeaderCellDef> hdl</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.hdl}} {{showGenericData.hdl_unit}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="t3">
          <mat-header-cell *matHeaderCellDef> t3</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.t3}} {{showGenericData.t3_unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total_t4">
          <mat-header-cell *matHeaderCellDef> total_t4</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.total_t4}} {{showGenericData.total_t4_unit}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="free_t4">
          <mat-header-cell *matHeaderCellDef> free_t4</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.free_t4}} {{showGenericData.free_t4_unit}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tsh">
          <mat-header-cell *matHeaderCellDef> tsh</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.tsh}} {{showGenericData.tsh_unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ldl">
          <mat-header-cell *matHeaderCellDef> ldl</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.ldl}} {{showGenericData.ldl_unit}} </mat-cell>
        </ng-container>
        



        <ng-container matColumnDef="bicarbonate">
          <mat-header-cell *matHeaderCellDef> bicarbonate</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.bicarbonate}}
            {{showGenericData.bicarbonate_unit}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="chloride">
          <mat-header-cell *matHeaderCellDef> chloride</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.chloride}} {{showGenericData.chloride_unit}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="potassium">
          <mat-header-cell *matHeaderCellDef style="padding-right: 5px;"> potassium</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData" style="padding-right: 5px;"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.potassium}}
            {{showGenericData.potassium_unit}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sodium">
          <mat-header-cell *matHeaderCellDef> sodium</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.sodium}} {{showGenericData.sodium_unit}}
          </mat-cell>
        </ng-container>



        <ng-container matColumnDef="triglycerides">
          <mat-header-cell matTooltip="Triglycerides"  *matHeaderCellDef>TG</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}">
            {{showGenericData.triglycerides}} {{showGenericData.triglycerides_unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="neutrophile">
          <mat-header-cell *matHeaderCellDef> NEUTRO. </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.neutrophile}}
            {{showGenericData.neutrophile_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="lymphocyte">
          <mat-header-cell *matHeaderCellDef> LYMPHO</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.lymphocyte}}
            {{showGenericData.lymphocyte_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="monocyte">
          <mat-header-cell *matHeaderCellDef> mono</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.monocyte}} {{showGenericData.monocyte_unit}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="eosinophil">
          <mat-header-cell *matHeaderCellDef> eosino</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.eosinophil}}
            {{showGenericData.eosinophil_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="basophil">
          <mat-header-cell *matHeaderCellDef> baso</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.basophil}}{{showGenericData.basophil_unit}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="immature_granulocyte">
          <mat-header-cell *matHeaderCellDef> immature granulo</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.immature_granulocyte}}
            {{showGenericData.immature_granulocyte_unit}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fev1">
          <mat-header-cell *matHeaderCellDef> fev1</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.fev1}} {{showGenericData.fev1_unit}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="fvc">
          <mat-header-cell *matHeaderCellDef> fvc</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.fvc}} {{showGenericData.fvc_unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fev1_fvc_ratio">
          <mat-header-cell *matHeaderCellDef> fev1 fvc ratio</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.fev1_fvc_ratio}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="blood_eosinophils">
          <mat-header-cell *matHeaderCellDef> Blood eosinophils</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.blood_eosinophils}}
            {{showGenericData.blood_eosinophils_unit}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sputum_eosinophils">
          <mat-header-cell *matHeaderCellDef> Sputum eosinophils</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.sputum_eosinophils}}
            {{showGenericData.sputum_eosinophils_unit}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="absolute_eosinophils">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Absolute Eosinophils</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.absolute_eosinophils}}
            {{showGenericData.absolute_eosinophils_unit}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="serum_bilirubin">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Serum Bilirubin</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.serum_bilirubin}}
            {{showGenericData.serum_bilirubin_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="direct_bilirubin">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Direct Bilirubin</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.direct_bilirubin}}
            {{showGenericData.direct_bilirubin_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="indirect_bilirubin">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Indirect Bilirubin</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.indirect_bilirubin}}
            {{showGenericData.indirect_bilirubin_unit}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="iron">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Iron</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.iron}}
            {{showGenericData.iron_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="ferritin">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Ferritin</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.ferritin}}
            {{showGenericData.ferritin_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="soluble_transferrin_receptor">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;">Soluble Transferrin Receptor</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.soluble_transferrin_receptor}}
            {{showGenericData.soluble_transferrin_receptor_unit}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="ast">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;">AST</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.ast}}
            {{showGenericData.ast_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="alt">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> ALT</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.alt}}
            {{showGenericData.alt_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="alp">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;">ALP</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.alp}}
            {{showGenericData.alp_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="ggt">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;">GGT</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.ggt}}
            {{showGenericData.ggt_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="troponin_i">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Troponin i</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.troponin_i}}
            {{showGenericData.troponin_i_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="troponin_t">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;"> Troponin T</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.troponin_t}}
            {{showGenericData.troponin_t_unit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="troponin_t_high_sensitivity">
          <mat-header-cell *matHeaderCellDef style="margin-right: 10px;">Troponin T High sensivity</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"  [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (showGenericData.log_date | date:'short')}"> {{showGenericData.troponin_t_high_sensitivity}}
            {{showGenericData.troponin_t_high_sensitivity_unit}}</mat-cell>
        </ng-container>



        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let showGenericData; columns: displayedColumns;"></mat-row>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #paginator showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <ng-template #loadingOrError>
    <app-loadorerror [loadError]="loadingError$ | async" #paginator [loadSubject]="loadingSubject$ | async">
    </app-loadorerror>
  </ng-template>
</div>





<!-- Type Column -->
<!-- <ng-container matColumnDef="items">
                <mat-header-cell *matHeaderCellDef style="flex:2"> Items </mat-header-cell>
                <mat-cell *matCellDef="let showGenericData" style="flex:2">
                  <div *ngFor="let fooditem of showGenericData.food; let i=index" class="mealcardspacing"><span>{{fooditem.food_name}}
                    {{fooditem.count}} {{fooditem.unit}}</span> <br /></div>
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                <mat-cell *matCellDef="let showGenericData"> {{renderType(showGenericData.type)}} </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="track">
                <mat-header-cell *matHeaderCellDef> Track </mat-header-cell>
                <mat-cell *matCellDef="let showGenericData"> {{showGenericData.track_type}} </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Calories </mat-header-cell>
                <mat-cell *matCellDef="let showGenericData" style="text-transform: capitalize">
                  {{showGenericData.nutritional_analysis.calories}} </mat-cell>
              </ng-container> -->
<!-- 
              <ng-container matColumnDef="breakup">
                <mat-header-cell *matHeaderCellDef> Details</mat-header-cell>
                <mat-cell *matCellDef="let showGenericData" style="text-transform: capitalize;"><button mat-raised-button
                    (click)="showbreakup(showGenericData)" color="accent">View</button></mat-cell>
              </ng-container> -->



<!-- 
      <div class="col-md-4">
          
      </div>
      <div *ngIf="userData.hba1c || userData.cholesterol || userData.creatinine || userData.hemoglobin || userData.serum_electrolytes || userData.uric_acid || userData.cbc">
          <div class="clearfix"><br /></div> 
        <div class="clearfix"><br /><br/></div>
       
        <mat-tab-group class="labreporttabpanel">
          <mat-tab label="HbA1C" *ngIf="showlabrepo('hba1c')">
            <div *ngIf="!labReportData.hba1c" class="nodata-card-lab">
              <br/>No HbA1C Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.hba1c">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.hba1c | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} %</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          
          <mat-tab label="Cholesterol" *ngIf="showlabrepo('cholesterol')">
            <div *ngIf="!labReportData.cholesterol" class="nodata-card-lab">
              <br/>No Cholesterol Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.cholesterol">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">HDL</th>
                    <th class="th-sm">LDL</th>
                    <th class="th-sm">Triglyceride</th>
                    <th class="th-sm">TC/HDL Ratio</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.cholesterol | orderBy:on_date">
                  <tr>
                    <td>{{lr.hdl}} mg/dL</td>
                    <td>{{lr.ldl}} mg/dL</td>
                    <td>{{lr.triglyceride}} mg/dL</td>
                    <td>{{tclratio(lr.hdl,lr.ldl, lr.triglyceride)}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          <mat-tab label="Serum Electrolyte"  *ngIf="showlabrepo('serum_electrolytes')">
            <div *ngIf="!labReportData.serum_electrolytes" class="nodata-card-lab">
              <br/>No Serum Electrolyte Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.serum_electrolytes">
              <table class="table table-borderless">
                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Sodium</th>
                    <th class="th-sm">Potassium</th>
                    <th class="th-sm">Chloride</th>
                    <th class="th-sm">Bicarbonate</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.serum_electrolytes | orderBy:on_date">
                  <tr>
                    <td>{{lr.sodium}} <br/>mEq/L </td>
                    <td>{{lr.potassium}} <br/> mEq/L</td>
                    <td>{{lr.chloride}} <br/> mEq/L</td>
                    <td>{{lr.bicarbonate}} <br/> mEq/L</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>

          <mat-tab label="Uric Acid" *ngIf="showlabrepo('uric_acid')">
            <div *ngIf="!labReportData.uric_acid" class="nodata-card-lab">
              <br/>No Uric Acid Lab Report Found.
              <br/>
            </div>
            <mat-card class="matCard" *ngIf="labReportData.uric_acid">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Type</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.uric_acid | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.type}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>

          </mat-tab>
          <mat-tab label="Creatinine" *ngIf="showlabrepo('creatinine')">
            <div *ngIf="!labReportData.creatinine" class="nodata-card-lab">
              <br/>No Creatinine Lab Report Found.
              <br/>
            </div>
            <mat-card class="matCard" *ngIf="labReportData.creatinine">
              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Type</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.creatinine | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.type}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          <mat-tab label="Hemoglobin" *ngIf="showlabrepo('hemoglobin')">
            <div *ngIf="!labReportData.hemoglobin" class="nodata-card-lab">
              <br/>No Hemoglobin Lab Report Found.
              <br/>
            </div>
            <mat-card class="matCard" *ngIf="labReportData.hemoglobin">
              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th>Value</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.hemoglobin | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          <mat-tab label="eGFR" *ngIf="showlabrepo('eGFR')">
            <div *ngIf="!labReportData.eGFR" class="nodata-card-lab">
              <br/>No eGFR Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.eGFR">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.eGFR | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          <mat-tab label="Blood Urea Nitrogen"  *ngIf="showlabrepo('blood_urea')">
            <div *ngIf="!labReportData.blood_urea" class="nodata-card-lab">
              <br/>No Blood Urea Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.blood_urea">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.blood_urea | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          <mat-tab label="Urine Albumin"  *ngIf="showlabrepo('urine_albumin')">
            <div *ngIf="!labReportData.urine_albumin" class="nodata-card-lab">
              <br/>No Blood Urea Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.urine_albumin">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.urine_albumin | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>
          
          <mat-tab label="BNP"  *ngIf="showlabrepo('bnp')">
            <div *ngIf="!labReportData.bnp" class="nodata-card-lab">
              <br/>No Blood Urea Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.bnp">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.bnp | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>

          <mat-tab label="NT-proBNP"  *ngIf="showlabrepo('nt_pro_bnp')">
            <div *ngIf="!labReportData.nt_pro_bnp" class="nodata-card-lab">
              <br/>No NT proBNP Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.nt_pro_bnp">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">Value</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.nt_pro_bnp | orderBy:on_date">
                  <tr>
                    <td>{{lr.value}} {{lr.unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>

          <mat-tab label="Thyroid Function"  *ngIf="showlabrepo('thyroid_function')">
            <div *ngIf="!labReportData.thyroid_function" class="nodata-card-lab">
              <br/>No Thyroid Function Lab Report Found.
              <br/>
            </div>

            <mat-card class="matCard" *ngIf="labReportData.thyroid_function">

              <table class="table table-borderless">

                <thead class="tablehead">
                  <tr>
                    <th class="firstrow">TSH</th>
                    <th class="th-sm">Total T4</th>
                    <th class="th-sm">Free T4</th>
                    <th class="th-sm">T3</th>
                    <th class="th-sm">Date</th>
                  </tr>
                </thead>
                <tbody *ngFor="let lr of labReportData.thyroid_function | orderBy:on_date">
                  <tr>
                    <td>{{lr.tsh}} {{lr.tsh_unit}}</td>
                    <td>{{lr.total_t4}} {{lr.total_t4_unit}}</td>
                    <td>{{lr.free_t4}} {{lr.free_t4_unit}}</td>
                    <td>{{lr.t3}} {{lr.t3_unit}}</td>
                    <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </mat-tab>

          <mat-tab label="CBC"  *ngIf="showlabrepo('cbc')">
              <div *ngIf="!labReportData.cbc" class="nodata-card-lab">
                <br/>No CBC Lab Report Found.
                <br/>
              </div>
  
              <mat-card class="matCard" *ngIf="labReportData.cbc">
  
                <table class="table table-borderless">
  
                  <thead class="tablehead">
                    <tr>
                      <th class="firstrow">Type</th>
                      <th class="th-sm">Value</th>
                      <th class="th-sm">Date</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let lr of labReportData.cbc | orderBy:on_date">
                    <tr>
                        <td style="text-transform: capitalize">{{parseString(lr.type)}} </td>
                      <td *ngIf="lr.type == 'wbcd_count'">
                          Neutrophile : {{lr.neutrophile}} %<br/>
                          Lymphocyte : {{lr.lymphocyte}} %<br/>
                          Monocyte : {{lr.monocyte}} %<br/>
                          Eosinophil : {{lr.eosinophil}} %<br/>
                          Basophil : {{lr.basophil}} %<br/>
                          Immature Granulocyte : {{lr.immature_granulocyte}} %
                      </td>
                      <td *ngIf="lr.type != 'wbcd_count'">{{lr.value}} {{cbcunits[lr.type]}}</td>
                      <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                    </tr>
                  </tbody>
                </table>
              </mat-card>
          </mat-tab>

          <mat-tab label="coagulation_factor"  *ngIf="showlabrepo('coagulation_factor')">
              <div *ngIf="!labReportData.coagulation_factor" class="nodata-card-lab">
                <br/>No Coagulation factor Lab Report Found.
                <br/>
              </div>
  
              <mat-card class="matCard" *ngIf="labReportData.coagulation_factor">
  
                <table class="table table-borderless">
  
                  <thead class="tablehead">
                    <tr>
                      <th class="firstrow">Value</th>
                      <th class="th-sm">Date</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let lr of labReportData.coagulation_factor | orderBy:on_date">
                    <tr>
                      <td>{{lr.value}} {{lr.unit}}</td>
                      <td>{{lr.on_date*1000 | date:'dd MMM yyyy'}}</td>
                    </tr>
                  </tbody>
                </table>
              </mat-card>
          </mat-tab>

        </mat-tab-group> -->