import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from 'src/app/shared/guards';
import { GoogleAppleRawdataComponent } from './googleapplerawdata.component';


const routes: Routes = [
  { path: '', component: GoogleAppleRawdataComponent, canActivate: [AdminGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GoogleapplerawdataRoutingModule { }
