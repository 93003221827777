<div class="col-md-12" *ngIf="tabulardata.length > 0">
  <div class="row">
    <div class="col-md-12 text-right">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        <img style="margin-bottom: 15px;" src="assets/img/dls/icons/cross.svg" alt="">
      </i>
    </div>
  </div>
    <div class="container-table"  infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
      (scrolled)="getNextSet()" [scrollWindow]="false">
      <div class="spinner-container" *ngIf="showloader">
        <app-loader showLoader="{{true}}"></app-loader>
      </div>
      
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
        <ng-container matColumnDef="Devicename">
          <th mat-header-cell *matHeaderCellDef> Device Name</th>
          <td mat-cell class="camelcased" *matCellDef="let element">
            {{element.device_version}}
          
          </td>
        </ng-container>
        <ng-container matColumnDef="Deviceid">
            <th mat-header-cell *matHeaderCellDef> Device Id</th>
            <td mat-cell class="camelcased" *matCellDef="let element">
              {{element.device_id}}
            </td>
          </ng-container>
        <ng-container matColumnDef="Loginat">
          <th mat-header-cell *matHeaderCellDef> Login At</th>
          <td mat-cell *matCellDef="let element">{{element.created_at|date:'dd-MMM-yyy'}}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell class="text-capitalize" *matCellDef="let element"> {{(element.status)}} </td>
        </ng-container> -->
        <!-- <ng-container matColumnDef="login history">
          <th mat-header-cell *matHeaderCellDef> Login history </th>
          <td mat-cell class="text-capitalize" *matCellDef="let element">
            <button mat-button (click)="loginHistory(element.id)" tooltip="login history">
              <img src="assets/img/dls/admin-table/Icon_Start_staging.svg" alt="Wellthy">
            </button>
          </td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action </th>
          <td mat-cell class="text-capitalize" *matCellDef="let element">
            <div class="action-button-row">

              <button mat-button (click)="viewProfile(element)" tooltip="View profile">
                <img src="assets/img/dls/admin-table/Icon_View profile.svg" alt="Wellthy">
              </button>
              <button mat-button (click)="viewPayment(element)" tooltip="Payment history">
                <img src="assets/img/dls/admin-table/Icon_Payment history.svg" alt="Wellthy">
              </button>
              <button mat-button (click)="forceLogout(element.id)" tooltip="Force logout">
                <img src="assets/img/dls/admin-table/Icon_Force_Logout.svg" alt="Wellthy">
              </button>
              <button mat-button (click)="openDialog(element.id)" tooltip="Delete user">
                <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="Wellthy">
              </button>
              <button mat-button (click)="EditPatient(element)" tooltip="Change program"  *ngIf="element.status=='ACTIVATION_CODE_VERIFIED' ||  element.status=='ONBOARDED'  ||  element.status=='SECONDARY_CONDITION_ENTERED'" >
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy">
              </button>
              <button mat-button  class="disabledEditOperation" tooltip="This patient is not a part of any campaign right now"  *ngIf="element.status!='ACTIVATION_CODE_VERIFIED' &&  element.status!='ONBOARDED'  &&  element.status!='SECONDARY_CONDITION_ENTERED'" >
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy" style="opacity:0.5" disabled>
              </button>
             
              <button mat-button (click)="PatientInfo(element)" tooltip="Program history">
                <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy">
              </button>

            </div>
          </td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="menu-holder">
                <div class="example-button-row">

                  <button mat-button (click)="viewProfile(element)" disableRipple
                    class="no-hover-effect view-btn">View Profile
                  </button>
                  <button mat-button disableRipple [routerLink]="['/admin/patients/paymentinfo',element.id]"
                    class="no-hover-effect" style="width: 145px;">Payment History
                  </button>
                  <button mat-button (click)="forceLogout(element.id)" disableRipple class="no-hover-effect"
                    style="width: 130px;">Force Logout
                  </button>
                  <button mat-button (click)="openDialog(element.id)" disableRipple
                    class="no-hover-effect last-btn">Delete
                  </button>
                </div>
              </div>
            </div>
          </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;">
        </tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
      </table>
    </div>
  </div>
  <div class="col-md-12" *ngIf="tabulardata.length === 0">
  <div class="row" >
    <div class="col-md-12 text-right">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        <img style="margin-bottom: 15px;" src="assets/img/dls/icons/cross.svg" alt="">
      </i>
    </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center" >
        No Records Found
      </div>
    </div>
  </div>