import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthcoachGuardGuard } from './../../shared/guards';
import { HcalertsComponent } from './hcalerts.component';

const routes: Routes = [
  { path: '', component: HcalertsComponent, canActivate: [HealthcoachGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HcalertsRoutingModule { }
