import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardGuard } from './../../../shared/guards';
import { CreatehcComponent } from './createhc/createhc.component';
import { CropImageComponent } from './createhc/crop-image/crop-image.component';
import { HcassignhomeComponent } from './hcassignhome.component';
import { HcstatusComponent } from './hcstatus/hcstatus.component';
import { HctimelogComponent } from './hctimelog/hctimelog.component';

const routes: Routes = [
    { path: '', component: HcassignhomeComponent, canActivate: [AdminGuardGuard] },
    { path: 'hctimelog/:id', component: HctimelogComponent, canActivate: [AdminGuardGuard] },
    { path: 'hccreate/edit/:id', component: CreatehcComponent, canActivate: [AdminGuardGuard] },
    { path: 'hcstatus/:id', component: HcstatusComponent, canActivate: [AdminGuardGuard] },
    { path: 'crop-image', component: CropImageComponent, canActivate: [AdminGuardGuard] },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HcassignhomeRoutingModule {}