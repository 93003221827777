import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { setDarkTheme } from 'src/app/utility/utils';
import { environment } from '../../../environments/environment';
import { DoctorDataService } from '../services/doctor/doctor.service';

@Component({
  selector: 'app-docsettings',
  templateUrl: './docsettings.component.html',
  styleUrls: ['./docsettings.component.scss']
})

export class DocsettingsComponent implements OnInit {
  public showButtonLoader: boolean = false;
  error: boolean = false;
  public message: any;
  public doctorData: any;
  public campaignData:any;
  s3url: any = environment.s3url;
  public dataForm = this.fb.group({
    new_password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  },
    { validator: this.checkPasswords }
  );
  public ide: any;

  isDark = sessionStorage.getItem('dark');
  public isThemeDark = false;
  public getClient:any;
  public isFerrer:boolean=false;
  constructor(
    private browserTitle: Title, private authService: AuthenticateService,
    private systemService: SystemService, public fb: FormBuilder, private doctorService: DoctorDataService) { }


  checkPasswords(group: FormGroup) {
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Doctor | Profile');
  }

  ngOnInit() {
    this.setSEOTags();
    this.doctorData = JSON.parse(localStorage.getItem('scarletuserdata'));
    if(this.isDark == 'true'){
      this.isThemeDark = true;
    }else{
      this.isThemeDark = false;
    }
    this.showTncFerrer();
  }

  logoutAccount(): void {
    this.loggedOutClicked();
    this.authService.logout()

      .subscribe(data => {
        this.systemService.showSuccessSnackBar(("Logout Successfully"), "OK");
        sessionStorage.clear();
        window.location.reload();
      });
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  passwordChangeClicked(){
    this.sendEventToCleverTap("Password change Clicked");
  }
  loggedOutClicked(){
    this.sendEventToCleverTap("Logged Out");
  }
  sendEventToCleverTap(evetname) {
    let datap =
    {
      "d": [
        {
          "identity": localStorage.getItem('scarletuserid'),
          "ts": (Math.floor(new Date().valueOf() / 1000)),
          "type": "event",
          "evtName": evetname,
          "evtData": {
            "Accessed": "Yes"
          }
        }
      ]
    }
    this.authService
      .sendEventData(datap)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
      })
  }
   changePassword() {
    var postdata = this.dataForm.value;
    postdata['user_id_data_fk']= localStorage.getItem('scarletuserid');
    postdata['source_of_password_change']= localStorage.getItem('app')
    postdata['password_changed_via']= 'Dashboard';
    delete postdata['confirmPassword'];
    this.error = false;
    if (this.hasWhiteSpace(this.dataForm.value.new_password) == false) {
      this.doctorService
        .updatePassword(this.doctorData.id, postdata)
        .pipe(
          catchError((error: any) => {
            this.showButtonLoader = false;
            this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
            this.showButtonLoader = false;
          })
        )
        .subscribe(data => {
          this.passwordChangeClicked();
          this.systemService.showSuccessSnackBar(("Password has been updated"), "OK");
          setTimeout(() => {
            window.location.reload();
          }, 2000);


        });
    }
    else {
      this.error = true;
      this.message = "Enter valid password, Your password should not be empty or contain spaces";
    }
  }

  toggleDarkTheme(checked) {
    setDarkTheme(checked);
  } 

//show tnc for ferrer
  showTncFerrer(){
    this.campaignData = JSON.parse(localStorage.getItem('scarletuserdata')).campaign_data;
    this.getClient = this.campaignData.filter(campaign_data => campaign_data.client_data == '9');
    if(this.getClient!= 0){
      this.isFerrer = true;
    }else{
      this.isFerrer = false;
    }
      
  }

}
