import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ChoosemediamodalComponent } from 'src/app/content/mediamanager/choosemediamodal/choosemediamodal.component';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { whiteSpaceValidation } from '../../../utility/validation';
import { EditorinstructionsComponent } from '../../shared/editorinstructions/editorinstructions.component';

declare var $: any;

@Component({
  selector: 'app-edittrigger',
  templateUrl: './edittrigger.component.html',
  styleUrls: ['./edittrigger.component.scss']
})

export class EdittriggerComponent implements OnInit {
  chooseMedia: any;
  public newitem: boolean = false;
  public disabled: boolean = true;
  s3url: any = environment.s3url;
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }
  public allmedia: any;
  allconditions: any;
  private myhash: any = {};
  public showButtonLoader: any;
  public selfparentide: any;
  public language: any;
  public selfparentid: any;
  public childid: any;
  public langid: any;
  userdata: any;
  doUpload: any;
  public imageData: any;
  public renderhtml: any;
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    content: [''],
    image_media: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    comorbilities: [[], Validators.required],
    raw_content: [''],
    additionaltext: ['']
  });

  constructor(private browserTitle: Title, private router: Router, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, protected sanitizer: DomSanitizer,private commonService:CommonserviceService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getTriggerData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getTriggerData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getTriggerData(params.chid);
        }
      }
    });
    this.getConditions();
    this.setSEOTags();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Trigger');
  }

  uirender() {
    var scope = this;
    setTimeout(() => {

      scope.renderhtml = $('.preview-panel').html();
      scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
    }, 100);
    setTimeout(() => {

      scope.renderhtml = $('.preview-panel').html();
      scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
    }, 300);
  }

  openinstructions(): void {
    const instructions = this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });

    instructions.afterClosed().subscribe(result => {
    });
  }

  additionalTextChange(e) {
    const rawcontent = <FormControl>this.dataForm.controls.raw_content;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    additionaltextcontent.setValue(rawcontent.value);
  }

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }


  // To Get Trigger Data
  // ----------------------------

  getTriggerData(ide) {
    this.contentService.getOneDataById(ide, "trigger")
    .subscribe(
      (res : any) => this.onTriggerDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onTriggerDataSuccess(data) {
    data = (data as any).data;
    this.userdata = data;
    this.userdata.image_media = this.userdata.image_media;
    this.dataForm.patchValue(this.userdata);
    this.getMedia(this.userdata.image_media);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
    this.uirender();
  }

  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }


  // To Get Media Data
  // ----------------------------

  getMedia(id) {
    
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
    .subscribe(
      (res : any) => this.onMediaSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onMediaSuccess(data) {
    data = (data as any).data;
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // ----------------------------


  // To Get Conditions Data
  // ----------------------------

  getConditions() {
    this.contentService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}',false)
    .subscribe(
      (res : any) => this.onConditionDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onConditionDataSuccess(data) {
    data = (data as any).data;
    this.allconditions = data.getAllConditions;
    this.allconditions.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  // ----------------------------


  // Submitting Trigger Data
  // ----------------------------
  dataObject() {
    var postdata = this.dataForm.value;
    let content = this.commonService.isHTML( this.dataForm.value.content);
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let raw_content = this.commonService.isHTML( this.dataForm.value.raw_content);
    let additionaltext = this.commonService.isHTML( this.dataForm.value.additionaltext);
    if(content  === true){
      this.dataForm.controls.content.patchValue('')
      return;
    }
    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    if(raw_content  === true){
      this.dataForm.controls.raw_content.patchValue('')
      return;
    }
    if(additionaltext  === true){
      this.dataForm.controls.additionaltext.patchValue('')
      return;
    }
    postdata.title = postdata.title.toString();
    postdata.content = postdata.content.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    postdata.image_media = postdata.image_media.toString();
    postdata.comorbilities = JSON.stringify(postdata.comorbilities);
    postdata.content = $('.preview-panel').html();
    postdata.raw_content = postdata.raw_content.toString();
    delete postdata.additionaltext;
    if (this.newitem == true) {
      this.contentService.postToBlade(postdata, 'trigger')
      .subscribe(
        (res : any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
    else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'trigger')
      .subscribe(
        (res : any) => this.onUpdateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Trigger has been added"), "OK");
    this.router.navigate(['/content/triggers']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Trigger has been updated"), "OK");
    this.router.navigate(['content/triggers']);
  }

  // ----------------------------

}
