<form [formGroup]="dataForm" validate class="filter-form">
    <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-4 pl0">
      <mat-label>Start date</mat-label>
      <input matInput formControlName="startdate" [matDatepicker]="picker1"
        (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [max]="morrow"
        placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-4 pl0">
      <mat-label>End date</mat-label>
      <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="currentdate"
        [max]="newDate" placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <div class="col-md-4 pr-0 pl0">
      <button mat-flat-button color="primary" class="primary apply-btn btn-block"
        (click)="timelogs()" style="height: 50px;margin-top: 4px;"
        [disabled]="!dataForm.valid">Apply</button>
    </div>
</form>
<div class="col-md-12" class="border-grey-class">
    <button (click)="downloadReport()" *ngIf="showdownloadoption" class="downloadreport">Download Report</button>
</div>