<span class="slidetogglecss" (click)="togglenav()"><i
        [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">{{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}</i></span>
<div class="userlistnavcontent">
    <div class="row">
        <div class="col-md-12 submenubar">
            <h3 class="userlisttitle">UUXID</h3>
            <a [routerLink]="['/content/staticuuxid']" routerLinkActive="active">Static Links</a>
            <a [routerLink]="['/content/dynamicuuxid']" routerLinkActive="active">Dynamic Links</a>
        </div>
    </div>
</div>