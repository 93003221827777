import { CommonserviceService } from './../../../admin/services/commonservice/commonservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { EditorinstructionsComponent } from '../../shared/editorinstructions/editorinstructions.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
declare var $: any;

@Component({
  selector: 'app-editpump',
  templateUrl: './editpump.component.html',
  styleUrls: ['./editpump.component.scss']
})

export class EditpumpComponent implements OnInit {
  s3url: any = environment.s3url;
  categories = ['General','Healthy Eating','Being Active','Symptom Management','Self Monitoring','Medication','Mind & Body','First Trimester','  Second Trimester','Third Trimester','Other']
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }

  public dataForm = this.fb.group({
    title: ['', Validators.required],
    pump_unit: ['', Validators.required],
    route_type: [[], Validators.required],
    add_pump_status:[[]],
    valid_pump_status:[[]],
    SC_label: [''],
    IV_label: [''],
    IM_label:[''],
  
    // language_data_fk: [Validators.required],
    // self_parent_fk: [Validators.required],
  });

  @ViewChild('singleSelect', { static: false }) singleSelectHC: MatSelect;

  public searchmatcat: FormControl = new FormControl();
  public filteredCat: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  title: any = 'New Pump';
  htmlContent: any;
  public mode: any;
  chooseMedia: any;
  pumpdata: any;
  doUpload: any;
  disabled: boolean = true;
  secondarycondition: any;
  public newitem: boolean = true;
  public showButtonLoader: any;
  public alltags: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public allusers: any;
  public renderhtml: any;
  public allRouteType: any;
  private _onDestroy = new Subject<void>();
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCatData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  myHtml: string;
  public allcategories: any;
  allCategory: any;
  showSCDropdown:boolean = false;
  showInDropdown:boolean = false;
  showIVDropdown:boolean = false;
  pumpStatus: any;
  addPumpStatus: any;
  validPumpStatus: any;
  
  constructor(
    private browserTitle: Title,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private contentService: ContentserviceService,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    protected sanitizer: DomSanitizer,
    private commonService:CommonserviceService
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Pump');
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        // this.selfparentide.setValue(null);
        // this.language.setValue(this.langid);
      } else {
        if (params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallMagazineData(this.childid);
        }  else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallMagazineData(params.chid);
        }
      }
    });
    // this.getRouteType();
  }

  onSelectChange(e) {
    
    // this.showDropdown = e.value;
    if(e.value[0] == 'subcutaneous'|| e.value[1] == 'subcutaneous'|| e.value[2] == 'subcutaneous'){
      this.showSCDropdown = true;
    }else{
      this.showSCDropdown = false;
      // this.dataForm.value.SC_label = '';
      this.dataForm.controls.SC_label.setValue('');
    }
    if(e.value[0] == 'intravenous'|| e.value[1] == 'intravenous'|| e.value[2] == 'intravenous'){
      this.showInDropdown = true;
    }
    else{
      this.showInDropdown = false;
      // this.dataForm.value.IV_label = '';
      this.dataForm.controls.IV_label.setValue('');
    }
    if(e.value[0] == 'implantable'|| e.value[1] == 'implantable'|| e.value[2] == 'implantable'){
      this.showIVDropdown = true;
    }else{
      this.showIVDropdown = false;
      // this.dataForm.value.IM_label = '';
      this.dataForm.controls.IM_label.setValue('');
    }
 
// e.value.forEach(function (value) {
//   if(value =='subcutaneous'){
//     this.showSCDropdown = true;
//   }else if(value =='intravenous'){
//     this.showInDropdown = true;
//   }else if(value =='implantable'){
//     this.showIVDropdown = true;
//   }
// });
    // if (e.value == 'symptom') {
    //   this.getSymptoms(1);
    // }
    // if (e.value == 'trigger') {
    //   this.getTriggers(1);
    // }
    // if (e.value == 'questionnaire') {
    //   this.getQuestionnaires(1);
    // }
   
  }

  ngAfterViewInit() {
  }

  uirender() {
    var scope = this;
    scope.renderhtml = $('.preview-panel').html();
    scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
  }

  additionalTextChange(e) {
    const rawcontent = <FormControl>this.dataForm.controls.raw_content;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    additionaltextcontent.setValue(rawcontent.value);
  }

  openinstructions(): void {
    const instructions = this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });

    instructions.afterClosed().subscribe(result => {
    });
  }


  // To get Route Type
  // ----------------------------

  getRouteType() {
    this.contentService.getGraphQlData('{getParentPumpRouteType(status:true){id,title}}',false)
    .subscribe(
      (res : any) => this.onConditionsSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onConditionsSuccess(data) {
    data = (data as any).data;
    this.allRouteType = data.getParentPumpRouteType;
  } 

  // ----------------------------

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }

  modelChangedTags() {
    this.filterTags();
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }



  modelChangedCat() {
    this.filterCat();
  }
  
  private filterCat() {
    if (!this.allcategories) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcat.value;
    if (!search) {
      this.filteredCatData.next(this.allcategories.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredCatData.next(
      this.allcategories.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  getCatList() {
    this.contentService
      .getGraphQlData('{getParentMagazineTypes(status:true){id,title}}', false )
      .subscribe((res: any) => this.getCatListSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCatListSuccess(data) {
    this.allCategory = data.getParentMagazineTypes;
    
    this.filteredCatData.next(this.allCategory.slice());
    
    this.initializeCategory();
  }

  initializeCategory() {
    this.route.params.subscribe(params => {
      // this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      // this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        // this.selfparentide.setValue(null);
        // this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallMagazineData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallMagazineData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallMagazineData(params.chid);
        }
      }
    });
  }
  
  // To get Pump Data
  // ----------------------------

  getallMagazineData(ide) {
    this.contentService.getOneDataById(ide, 'pump')
    .subscribe(
      (res : any) => this.onMagazineSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  
  private onMagazineSuccess(data) {
    data = (data as any).data;
    this.pumpdata = data;
    
    this.dataForm.patchValue(this.pumpdata);
    if(this.pumpdata.route_type[0] == 'subcutaneous'|| this.pumpdata.route_type[1] == 'subcutaneous'|| this.pumpdata.route_type[2] == 'subcutaneous'){
      this.showSCDropdown = true;
    }else{
      this.showSCDropdown = false;
    }
    if(this.pumpdata.route_type[0] == 'intravenous'|| this.pumpdata.route_type[1] == 'intravenous'|| this.pumpdata.route_type[2] == 'intravenous'){
      this.showInDropdown = true;
    }
    else{
      this.showInDropdown = false;
    }
    if(this.pumpdata.route_type[0] == 'implantable'|| this.pumpdata.route_type[1] == 'implantable'|| this.pumpdata.route_type[2] == 'implantable'){
      this.showIVDropdown = true;
    }
    if(this.pumpdata.route_type[0] == 'subcutaneous'){
      this.dataForm.controls.SC_label.patchValue(parseInt(this.pumpdata.remodulin_labels[0]));
    }
    if(this.pumpdata.route_type[0] == 'intravenous'){
      this.dataForm.controls.IV_label.patchValue(parseInt(this.pumpdata.remodulin_labels[0]));
    }
    if(this.pumpdata.route_type[1] == 'intravenous'){
      this.dataForm.controls.IV_label.patchValue(parseInt(this.pumpdata.remodulin_labels[1]));
    }
    if(this.pumpdata.route_type[0] == 'implantable'){
      this.dataForm.controls.IM_label.patchValue(parseInt(this.pumpdata.remodulin_labels[0]));
    }
    if(this.pumpdata.route_type[1] == 'implantable'){
      this.dataForm.controls.IM_label.patchValue(parseInt(this.pumpdata.remodulin_labels[1]));
    }
    if(this.pumpdata.route_type[2] == 'implantable'){
      this.dataForm.controls.IM_label.patchValue(parseInt(this.pumpdata.remodulin_labels[2]));
    }
    
    // this.selfparentide.setValue(this.selfparentid)
    var scope = this;
    setTimeout(() => {
      scope.uirender();
    }, 1000);
  }

  // ----------------------------


  // To get Users Data
  // ----------------------------

  getUsers() {
    this.contentService.getGraphQlData('{getAllContentUsers(appMode:false, status:true){id,name,display_name}}')
    .subscribe(
      (res : any) => this.onUsersSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onUsersSuccess(data) {
    data = (data as any).data;
    this.allusers = data.getAllContentUsers;
  }

  // ----------------------------


  // Submitting Pump Data
  // ----------------------------

  dataObject() {
    let postdata = this.dataForm.value;
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let pump_unit = this.commonService.isHTML( this.dataForm.value.pump_unit);

    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }

    if(pump_unit  === true){
      this.dataForm.controls.pump_unit.patchValue('')
      return;
    }
    postdata.title = this.removeHTMLtags(postdata.title).toString();
    postdata.route_type = JSON.stringify(postdata.route_type);
    var labelData = []
    labelData.push(postdata.SC_label,postdata.IV_label,postdata.IM_label);
    var cleanArray = labelData.join('').split('').map(Number);
    const newArray = cleanArray.filter( value => !Number.isNaN(value) );
    // for add_pump_status
    var arr = []
    arr.push(postdata.add_pump_status)
    var add_pump_status = arr.join('').split('').map(Number);
    const newArray_add_pump_status = add_pump_status.filter( value => !Number.isNaN(value) );
    postdata.add_pump_status =  JSON.stringify(newArray_add_pump_status);
     // for valid_pump_status
     var arr1 = []
     arr1.push(postdata.valid_pump_status)
     var valid_pump_status = arr1.join('').split('').map(Number);
     const newArray_valid_pump_status = valid_pump_status.filter( value => !Number.isNaN(value) );
     postdata.valid_pump_status = JSON.stringify(newArray_valid_pump_status);
    // var c = b.map(Number);
    postdata.remodulin_labels = JSON.stringify(newArray);
    delete postdata.SC_label;
    delete postdata.IV_label;
    delete postdata.IM_label;
    postdata.pump_unit = postdata.pump_unit.toString();
    if (this.newitem == true) {
      postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
      this.contentService.postToBlade(postdata, 'pump')
      .subscribe(
        (res : any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    } else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'pump')
      .subscribe(
        (res : any) => this.onUpadteSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
  }

  private onCreateSuccess () {
    this.systemService.showSuccessSnackBar(("pump has been added"), "OK");
    this.router.navigate(['content/pump']);
  }

  private onUpadteSuccess () {
    this.systemService.showSuccessSnackBar(("pump has been updated"), "OK");
    this.router.navigate(['content/pump']);
  }

  // ----------------------------


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService.getGraphQlData('{getParentTags(status:true){id,title}}',false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------


  // To Get Media
  // ----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
    .subscribe((res: any) => this.ongetMediaSuccess((res as any).data),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  )
  }
 
  private ongetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      var formelement = <FormArray>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });

  }
  ​ removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
  onAddPump(value) {
    this.addPumpStatus = value.value;
  }
  onSelectValidPump(value) {
    this.validPumpStatus = value.value;
  }

}
