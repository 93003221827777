/*==========================================
Title: change hc status and Transfer hc 
Author: Mandeep singh
Date:   17 April 2020
Last Change :  change hc status to leave or resume and transfer all patient to new hc
===========================================*/
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject} from 'rxjs';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../../content/services/contentservice/contentservice.service';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

export interface PeriodicElement {
  pateint_name: string;
  health_coach: string;

}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-hcstatus',
  templateUrl: './hcstatus.component.html',
  styleUrls: ['./hcstatus.component.scss'],
  encapsulation: ViewEncapsulation.None

})


export class HcstatusComponent implements OnInit {
  public userid: any;
  public showButtonLoader: boolean = false;
  public hcdata: any;
  selectedOption: any;
  public allhc: any = [];
  checked: boolean = false;
  public filteredHC: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmathc: FormControl = new FormControl();
  localStoredHCData: string;
  hclist: any;
  
  constructor(private browserTitle: Title, public fb: FormBuilder, private contentService: ContentserviceService, private router: Router, private route: ActivatedRoute, private commonService: CommonserviceService, private systemService: SystemService) { }
  
  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Health Coach Status');
  }
 
  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.userid = params.id;
      this.getUserData(params.id);
      this.getHC();
    })
  }
  
  public dataForm = this.fb.group({
    request_type: ['', Validators.required],
    new_coach_ids: ['']
  });

  changeValue(value) {
    this.checked = !value;
  }

  modelChangedHC() {
    this.filterHC();
  }

  private filterHC() {
    if (!this.allhc) {
      return;
    }
    // get the search keyword
    let search = this.searchmathc.value;
    if (!search) {
      this.filteredHC.next(this.allhc.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredHC.next(
      this.allhc.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  displayedColumns: string[] = ['select', 'pateint_name', 'health_coach'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);

  
  // To Get User Data
  // ----------------------------

  getUserData(ide) {
    this.commonService.getOneDataById(ide, "users")
    .subscribe(
      (res: any) => this.onUserDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onUserDataSuccess(data) {
    data = (data as any).data;
    this.hcdata = data;
    if (this.hcdata.is_on_leave == true) {
      // this.selectedOption = 'leave';
      this.dataForm.controls.request_type.patchValue('leave');
    }
    else {
      // this.selectedOption = 'resume';
      this.dataForm.controls.request_type.patchValue('resume');
    }
  }

  // ----------------------------


  // To Get Health Coach Data
  // ----------------------------

  getHC() {
    this.contentService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name,display_name,is_on_leave,is_active}}')
    .subscribe(
      (res: any) => this.onHCStatusSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onHCStatusSuccess(data) {
    data =  (data as any).data;
    this.hclist = data.getAllHealthcoaches;
    for (var j = 0; j < this.hclist.length; j++) {
      if (this.hclist[j].id != this.userid && this.hclist[j].is_active && !(this.hclist[j].is_on_leave)) {
        this.allhc.push(this.hclist[j]);
      }
    }
    this.filteredHC.next(this.allhc.slice());
  }

  // ----------------------------

  activeclass(onleave, active) {
    return onleave ? 'onleave-cls' : (active ? 'active-cls' : 'inactive-cls');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }


  // Submitting Status Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    let hcdata = { old_coach_id: parseInt(this.userid), new_coach_ids: postdata.new_coach_ids, request_type: postdata.request_type.toString() };
    if (postdata.request_type == 'leave') {
      if (this.checked) {
        this.commonService.changeHC(hcdata)
        .subscribe(
          (res: any) => this.onHCSuccess(hcdata),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
      }
      else {
        this.commonService.changeHcleaveRequest(hcdata.old_coach_id, hcdata.request_type, 'hcleaverequest')
        .subscribe(
          (res: any) => this.onLeaveSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
      }
    }
    else {
      let hcdata = { old_coach_id: parseInt(this.userid), request_type: postdata.request_type.toString() };
      this.commonService.changeHcleaveRequest(hcdata.old_coach_id, hcdata.request_type, 'hcleaverequest')
      .subscribe(
        (res: any) => this.onChangeHCLeaveSuccess(hcdata),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }
 
  private onHCSuccess(hcdata) {
    this.commonService.changeHcleaveRequest(hcdata.old_coach_id, hcdata.request_type, 'hcleaverequest')
    .subscribe(
      (res: any) => this.onChangeSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onChangeSuccess() {
    this.router.navigate(['admin/hchome']);
  }

  private onLeaveSuccess() {
    this.systemService.showSuccessSnackBar(("Status has been updated"), "OK");
    this.router.navigate(['admin/hchome']);
  }

  private onChangeHCLeaveSuccess(hcdata) {
    this.commonService.changeHC(hcdata)
    .subscribe(
      (res: any) => this.onChangeHCSuccess(),
      (err: HttpErrorResponse) => this.onErrorResponse(err),
      // (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  onErrorResponse(err) {
    
      this.systemService.showSuccessSnackBar(("Status has been updated"), "OK");;
      this.router.navigate(['admin/hchome']);
  }

  private onChangeHCSuccess() {
    this.router.navigate(['admin/hchome']);
  }

  // ----------------------------

}
