import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../shared/shared.module';
import { MediainformationComponent } from './../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

import { ModuleComponent } from './module.component';
import { EditmoduleComponent } from './editmodule/editmodule.component';
import { ModuleRoutingModule } from './module.routing.module';
import { ContentleftnavModule } from '../shared/contentleftnav/contentleftnav.module';
import { ContentnavbarModule } from '../shared/contentnavbar/contentnavbar.module';
import { TherapysetupleftnavModule } from '../shared/therapysetupleftnav/therapysetupleftnav.module';

@NgModule({
  declarations: [

    ModuleComponent,
    EditmoduleComponent
  ],
  imports: [
    CommonModule,
    ModuleRoutingModule,
    ContentleftnavModule,
    ContentnavbarModule,
    TherapysetupleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ModuleModule { }
