<div class="userdetailcontent">
  <div *ngIf="(programDetailsSubject$ | async) as userData; else loadingOrError">
    <div *ngIf="userData.id">
      <div class="col-md-12 dashed-border">
        <div class="row">
          <div class="col-md-8 zeropadleft">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{userData.therapy_data.title}}</mat-card-title>
                <mat-card-subtitle>{{userData.therapy_data.code}} </mat-card-subtitle>
                <!-- <span *ngIf="userData.activation_type == 'doctor_freemium'" class="freetag">FREE</span><span *ngIf="userData.days_remaining < 0" class="freetag">EXPIRED</span> -->
              </mat-card-header>
            </mat-card>
          </div>
          <div class="col-md-4" *ngIf="userData.days_remaining > 0">
            <div class="progressarea">
              <span class="daysleft"><strong>50</strong><br />Days</span>
              <mat-progress-spinner class="spinner-margin" [mode]="spinnermode" value="30" diameter="75">
              </mat-progress-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="matcardbottomzero">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Activation Code</mat-card-title>
            <mat-card-subtitle>{{userData?.activation_code}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Client Name</mat-card-title>
            <mat-card-subtitle>{{userData?.patient_client_data?.title}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>


        <mat-card>
          <mat-card-header>
            <mat-card-title>Campaign Name</mat-card-title>
            <mat-card-subtitle>{{userData?.patient_campaign_data == null ? 'Not Available' :
              userData?.patient_campaign_data?.title}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Primary Condition</mat-card-title>
            <mat-card-subtitle>
              <span>
                <span class="conditionbox">{{PrimaryCondition}}</span></span>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>



        <mat-card *ngIf="userData?.conditions != null">
          <mat-card-header>
            <mat-card-title>Comorbidities</mat-card-title>
            <mat-card-subtitle>
              <span *ngFor="let ab of userData.therapy_condition_data"> <span
                  *ngIf="getstyle(userData?.conditions,ab?.id)" class="conditionbox">{{ab.title}}</span></span>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <mat-card>
          <mat-card-header>
            <mat-card-title>Therapy start date</mat-card-title>
            <mat-card-subtitle>{{userData.therapy_start_date | date:"dd MMM yyyy"}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <mat-card *ngIf="profile_flags">
          <mat-card-header>
            <mat-card-title>EDD
              <button (click)='editEdd("edd");$event.stopPropagation()' *ngIf="!editableEdd"
                style="border: none;background: transparent;">
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="">
              </button>
            </mat-card-title>
            <mat-card-subtitle *ngIf="!editableEdd && eddValue !== 'NA'">{{eddValue | date:"dd MMM yyyy"}} </mat-card-subtitle>
            <mat-card-subtitle *ngIf="!editableEdd && eddValue == 'NA'">{{eddValue}} </mat-card-subtitle>
            <mat-card-subtitle class="edd-form" *ngIf="editableEdd">
              <form [formGroup]="dataForm" (ngSubmit)="submitEdd(dataForm.value)" validate class="filter-form clearfix">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field (click)="$event.stopPropagation();" appearance="outline" style="padding-left: 0;"
                      class="col-md-12 pr-0 pq">
                      <mat-label>Choose Edd date</mat-label>
                      <input matInput formControlName="startdate" [min]="EddminDate" [max]="EddmaxDate" [matDatepicker]="picker1" 
                        placeholder="Choose EDD date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <div class="col-md-6" style="text-align: end;">
                      <button mat-flat-button type="submit" [disabled]="!dataForm.valid" color="primary"
                        class="primary margin-btn">Done</button>
                    </div>
                    <div class="col-md-6" style="text-align: initial;">
                      <button mat-flat-button color="primary" (click)='cancelEdit("edd");$event.stopPropagation()'
                        class="primary margin-btn">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <mat-card *ngIf="profile_flags">
          <mat-card-header>
            <mat-card-title>LMP
              <button (click)='editEdd("lmp");$event.stopPropagation()' style="border: none;background: transparent;">
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="">
              </button>
            </mat-card-title>
            <mat-card-subtitle *ngIf="!editableLmp && lmpValue !== 'NA'">{{lmpValue | date:"dd MMM yyyy"}}  </mat-card-subtitle>
              <mat-card-subtitle *ngIf="!editableLmp  && lmpValue === 'NA'">{{lmpValue}}  </mat-card-subtitle>
            <mat-card-subtitle class="edd-form" *ngIf="editableLmp">
              <form [formGroup]="lmpdataForm" (ngSubmit)="submitLmp(lmpdataForm.value)" validate class="filter-form clearfix">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field (click)="$event.stopPropagation();" appearance="outline" style="padding-left: 0;"
                      class="col-md-12 pr-0 pq">
                      <mat-label>Choose LMP date</mat-label>
                      <input matInput formControlName="startdate" [matDatepicker]="picker1"  [max]="LmpmaxDate"
                        placeholder="Choose LMP date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <div class="col-md-6" style="text-align: end;">
                      <button mat-flat-button type="submit" [disabled]="!lmpdataForm.valid" color="primary"
                        class="primary margin-btn">Done</button>
                    </div>
                    <div class="col-md-6" style="text-align: initial;">
                      <button mat-flat-button color="primary" (click)='cancelEdit("lmp");$event.stopPropagation()'
                        class="primary margin-btn">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <mat-card *ngIf="profile_flags">
          <mat-card-header>
            <mat-card-title>Gestational Age</mat-card-title>
            <mat-card-subtitle>{{gaValue}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <mat-card *ngIf="profile_flags">
          <mat-card-header>
            <mat-card-title>Delivery outcome
              <button (click)='editEdd("delivery_outcome");$event.stopPropagation()'
                style="border: none;background: transparent;">
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="">
              </button>
            </mat-card-title>
            <mat-card-subtitle *ngIf="!editableDeliveryOutCome">{{deliveryOutputValue}}</mat-card-subtitle>
            <mat-card-subtitle class="edd-form" *ngIf="editableDeliveryOutCome">
              <form [formGroup]="deliveryForm" (ngSubmit)="submitDeliveryOutcome()" validate
                class="filter-form clearfix">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field (click)="$event.stopPropagation();" appearance="outline" style="padding-left: 0;"
                      class="col-md-12 pr-0 pq">
                      <mat-label>Choose Delivery out come</mat-label>
                      <mat-select formControlName="deliveryOutCome" class="camelcased" required>
                        <mat-option class="camelcased" value="Successful Delivery 'C' Section">Successful Delivery 'C' Section</mat-option>
                        <mat-option class="camelcased" value="Successful Delivery Normal">Successful Delivery Normal</mat-option>
                        <mat-option class="camelcased" value="Unsuccessful Delivery">Unsuccessful Delivery</mat-option>
                        <mat-option class="camelcased" value="NA">NA</mat-option>
                      </mat-select>
                      </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <div class="col-md-6" style="text-align: end;">
                      <button mat-flat-button type="submit" [disabled]="!deliveryForm.valid" color="primary"
                        class="primary margin-btn">Done</button>
                    </div>
                    <div class="col-md-6" style="text-align: initial;">
                      <button mat-flat-button color="primary"
                        (click)='cancelEdit("delivery_outcome");$event.stopPropagation()'
                        class="primary margin-btn">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <mat-card *ngIf="profile_flags">
          <mat-card-header>
            <mat-card-title>Delivery date
              <button (click)='editEdd("delivery_date");$event.stopPropagation()'
                style="border: none;background: transparent;">
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="">
              </button>
            </mat-card-title>
            <mat-card-subtitle *ngIf="!editableDeliveryDate && deliveryDateValue !== 'NA'">{{deliveryDateValue | date:"dd MMM yyyy"}} </mat-card-subtitle>
            <mat-card-subtitle *ngIf="!editableDeliveryDate && deliveryDateValue === 'NA'">{{deliveryDateValue}} </mat-card-subtitle>
            <mat-card-subtitle class="edd-form" *ngIf="editableDeliveryDate">
              <form [formGroup]="deliverydateForm" (ngSubmit)="submitDeliveryDate(deliverydateForm.value)" validate
                class="filter-form clearfix">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field (click)="$event.stopPropagation();" appearance="outline" style="padding-left: 0;"
                      class="col-md-12 pr-0 pq">
                      <mat-label>Choose Delivery date</mat-label>
                      <input matInput formControlName="startdate" [matDatepicker]="picker1" 
                        placeholder="Choose Delivery date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <div class="col-md-6" style="text-align: end;">
                      <button mat-flat-button type="submit" [disabled]="!deliverydateForm.valid" color="primary"
                        class="primary margin-btn">Done</button>
                    </div>
                    <div class="col-md-6" style="text-align: initial;">
                      <button mat-flat-button color="primary"
                        (click)='cancelEdit("delivery_date");$event.stopPropagation()'
                        class="primary margin-btn">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <!-- <mat-card>
          <mat-card-header>
            <mat-card-title>Program end date</mat-card-title>
            <mat-card-subtitle>{{userData.program_end_date*1000 | date:"dd MMM yyyy"}}</mat-card-subtitle>
          </mat-card-header>
        </mat-card> -->

        <!-- <mat-card class="card card-wrapper" *ngIf="userData?.has_diabetes && userData?.diabetic_since > 0">
          <div class="card-block card-outer">
              <mat-card-title class="card-title">Diabetic Since</mat-card-title>
              <mat-card-subtitle class="card-text">{{userData.diabetic_since ? userData.diabetic_since : 'No Data'}}</mat-card-subtitle>
          </div>
      </mat-card>

        <mat-card class="card card-wrapper" *ngIf="userData?.has_ckd && userData?.ckd_since > 0">
            <div class="card-block card-outer">
                <mat-card-title class="card-title">CKD Since</mat-card-title>
                <mat-card-subtitle class="card-text">{{userData.ckd_since ? userData.ckd_since : 'No Data'}}</mat-card-subtitle>
            </div>
        </mat-card>
        <mat-card class="card card-wrapper" *ngIf="userData.has_hyper_tension && userData.hypertensive_since > 0">
                <div class="card-block card-outer">
                    <mat-card-title class="card-title">Hypertension Since</mat-card-title>
                    <mat-card-subtitle class="card-text">{{userData.hypertensive_since ? userData.hypertensive_since : 'No Data'}}</mat-card-subtitle>
                </div>
        </mat-card>
        <mat-card class="card card-wrapper" *ngIf="userData.has_cholesterol && userData.cholesterol_since > 0">
                <div class="card-block card-outer">
                    <mat-card-title class="card-title">High Cholestrol Since</mat-card-title>
                    <mat-card-subtitle class="card-text">{{userData.cholesterol_since ? userData.cholesterol_since : 'No Data'}}</mat-card-subtitle>
                </div>
        </mat-card>
        <mat-card class="card card-wrapper">
          <div class="card-block card-outer">
              <mat-card-title class="card-title">Activation code</mat-card-title>
              <mat-card-subtitle class="card-text">{{ userData.activation_code }}</mat-card-subtitle>
          </div>
      </mat-card> -->

        <div class="clearfix"><br /><br /></div>
      </div>



    </div>
    <app-nodata [noRecordFound]="!userData.id"></app-nodata>
  </div>
  <ng-template #loadingOrError>
    <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async"></app-loadorerror>
  </ng-template>
</div>