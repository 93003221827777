<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content" [hidden]="isloading">
    <div class="col-md-12">
      <h3 class="commontitle">Media Manager</h3>
    </div>
    <div class="col-md-12">
      <p>Upload your images, audios and videos, over here. A maximum of 5 files, can be uploaded at a time.</p>
    </div>
    <div class="col-md-12">
      <div class="clearfix"><br /></div>
      <div class="upload-wrapper">
        <form [formGroup]="demoForm" (ngSubmit)="uploadObject()" validate [class]="isinvalid ? 'invalidcls' : ''">
          <file-upload class="fileupload" formControlName="files" accept=".jpeg,.png,.jpg,.mp3,.wav,.m4a,.mp4,.mov,.webm,.avi,">
            <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
              <span *ngIf="isFileDragDropAvailable">
                <div _ngcontent-c1="" class="upload-input ng-trigger ng-trigger-zoomAnimation">
                  <div _ngcontent-c1="" class="icon ng-tns-c1-2 ng-star-inserted"><svg _ngcontent-c1=""
                      class="ng-tns-c1-2" viewBox="0 0 96 96">
                      <g _ngcontent-c1="" class="ng-tns-c1-2">
                        <path _ngcontent-c1="" class="ng-tns-c1-2"
                          d="M62.8,68.1c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6s-1.1,0.2-1.5,0.6
                           c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.5-0.6S62.8,68.7,62.8,68.1z M71.3,68.1
                           c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6C67.2,67,67,67.5,67,68.1c0,0.6,0.2,1.1,0.6,1.5
                           s0.9,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6C71.1,69.2,71.3,68.7,71.3,68.1z M75.5,60.7v10.6c0,0.9-0.3,1.6-0.9,2.2
                           c-0.6,0.6-1.4,0.9-2.2,0.9H23.7c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2V60.7c0-0.9,0.3-1.6,0.9-2.2
                           c0.6-0.6,1.4-0.9,2.2-0.9h14.1c0.5,1.2,1.2,2.2,2.3,3c1.1,0.8,2.3,1.2,3.7,1.2h8.5c1.3,0,2.6-0.4,3.7-1.2c1.1-0.8,1.9-1.8,2.3-3
                           h14.1c0.9,0,1.6,0.3,2.2,0.9C75.2,59.1,75.5,59.8,75.5,60.7z M64.8,39.3c-0.4,0.9-1,1.3-2,1.3h-8.5v14.8c0,0.6-0.2,1.1-0.6,1.5
                           c-0.4,0.4-0.9,0.6-1.5,0.6h-8.5c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5V40.6h-8.5c-0.9,0-1.6-0.4-2-1.3
                           c-0.4-0.9-0.2-1.6,0.5-2.3l14.8-14.8c0.4-0.4,0.9-0.6,1.5-0.6s1.1,0.2,1.5,0.6L64.3,37C65,37.7,65.1,38.4,64.8,39.3z"></path>
                      </g>
                    </svg></div>
                  <div _ngcontent-c1="" class="upload-text ng-tns-c1-2 ng-star-inserted"><b _ngcontent-c1=""
                      class="ng-tns-c1-2 ng-star-inserted">Drag and drop</b> files<br _ngcontent-c1=""
                      class="ng-tns-c1-2 ng-star-inserted"> or click here </div>
                </div>
              </span>
              <span *ngIf="!isFileDragDropAvailable">click</span>
            </ng-template>

            <ng-template let-i="index" let-file="file" let-control="control" #item>
              <div class="file-info">
                <div class="file-items">
                  <svg _ngcontent-c5="" viewBox="0 0 96 96" class="fileuploadicon">
                    <g _ngcontent-c5="">
                      <path _ngcontent-c5="" d="M71.4,30.7c0.6,0.6,1.2,1.5,1.6,2.5c0.4,1.1,0.7,2.1,0.7,2.9v38.6c0,0.9-0.3,1.7-0.9,2.3
                           S71.4,78,70.5,78h-45c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.4-0.9-2.3V21.2c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9h30
                           c0.9,0,1.9,0.2,2.9,0.7c1.1,0.4,1.9,1,2.5,1.6L71.4,30.7z M56.6,22.6v12.6h12.6c-0.2-0.6-0.5-1.1-0.7-1.4L57.9,23.3
                           C57.7,23,57.2,22.8,56.6,22.6z M69.4,73.7V39.4H55.5c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.4-0.9-2.3V22.3H26.6v51.4H69.4z
                           M65.1,58.7v10.7H30.9V63l6.4-6.4l4.3,4.3L54.4,48L65.1,58.7z M37.3,52.3c-1.8,0-3.3-0.6-4.6-1.9c-1.3-1.2-1.9-2.8-1.9-4.6
                           c0-1.8,0.6-3.3,1.9-4.6c1.3-1.2,2.8-1.9,4.6-1.9s3.3,0.6,4.6,1.9c1.3,1.3,1.9,2.8,1.9,4.6c0,1.8-0.6,3.3-1.9,4.6
                           C40.6,51.7,39.1,52.3,37.3,52.3z"></path>
                    </g>
                  </svg>
                  <div _ngcontent-c4="" class="file-info"><span _ngcontent-c4=""
                      class="file-name">{{ file.name }}</span> ( {{ file.size }} )</div>
                  <div _ngcontent-c4="" class="file-buttons" (click)="control.removeFile(file)"><span _ngcontent-c4=""
                      class="remove-btn"><svg _ngcontent-c4="" viewBox="0 0 96 96">
                        <g _ngcontent-c4="">
                          <path _ngcontent-c4=""
                            d="M40.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S39.6,38,39.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4
                               s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4S40.5,67.1,40.5,66.8z M50.5,66.8V39.3
                               c0-0.4-0.1-0.7-0.4-0.9S49.6,38,49.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4
                               h2.5c0.4,0,0.7-0.1,0.9-0.4S50.5,67.1,50.5,66.8z M60.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S59.6,38,59.3,38h-2.5
                               c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4
                               S60.5,67.1,60.5,66.8z M39.3,28h17.5l-1.9-4.6c-0.2-0.2-0.4-0.4-0.7-0.4H41.8c-0.3,0.1-0.5,0.2-0.7,0.4L39.3,28z M75.5,29.3v2.5
                               c0,0.4-0.1,0.7-0.4,0.9S74.6,33,74.3,33h-3.8v37c0,2.2-0.6,4-1.8,5.6S66,78,64.3,78H31.8c-1.7,0-3.2-0.8-4.4-2.3s-1.8-3.4-1.8-5.5
                               V33h-3.8c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.5-0.4-0.9v-2.5c0-0.4,0.1-0.7,0.4-0.9s0.5-0.4,0.9-0.4h12.1l2.7-6.5c0.4-1,1.1-1.8,2.1-2.5
                               s2-1,3.1-1h12.5c1,0,2.1,0.3,3.1,1s1.7,1.5,2.1,2.5l2.7,6.5h12.1c0.4,0,0.7,0.1,0.9,0.4S75.5,28.9,75.5,29.3z">
                          </path>
                        </g>
                      </svg> </span> <span class="removetxt">Remove</span></div>
                </div>
              </div>
              <div class="clearfix"><br /></div>
            </ng-template>
          </file-upload>
          <div class="erorrcls" *ngIf="isinvalid">You are trying to upload a media more than the recommended size (see below) | You can upload maximum 5 at a time </div>
          <div class="clearfix"><br /></div>
          <div>
            <p class="uploadguidance"><b>Image</b> (.jpeg,.png,.jpg) ~5 MB &nbsp;&nbsp;&nbsp; <b>Audio</b> (.mp3,.wav,.m4a) ~10 MB &nbsp;&nbsp;&nbsp; <b>Video</b> (.mp4,.mov,.webm,.avi) ~20 MB.</p>
            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;" [disabled]="this.demoForm.value.files == null || this.demoForm.value.files.length == 0">Upload Files</button>
            <!-- <button mat-stroked-button color="primary" class="centerbtn" style="margin-top:15px;margin-left: 10px;" (click)="resetfiles()">Reset</button> -->
          </div>
        </form>
      </div>

    </div>
  </div>
  <div class="sidenav-content" [hidden]="!isloading">
    <mat-progress-bar mode="determinate" [value]="loadingval"></mat-progress-bar>
  </div>

</mat-drawer-container>