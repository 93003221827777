import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { APILogsRoutingModule } from './api-logs-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';

import { APILogsComponent } from './api-logs.component';

@NgModule({
  declarations: [
    APILogsComponent,
  ],
  imports: [
    CommonModule,
    APILogsRoutingModule,
    SharedModule.forRoot(),
    AdminleftnavModule,
    AdminsectionleftnavModule,
  ]
})

export class APILogsModule { }
