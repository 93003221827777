import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { MagazinecategoryComponent } from './magazinecategory.component';
import { EditmagazinecategoryComponent } from './editmagazinecategory/editmagazinecategory.component';
const routes: Routes = [
  { path: '', component: MagazinecategoryComponent, canActivate: [ContentGuardGuard] },
  { path: 'magazinecategory/edit/:id/:langid/:chid', component: EditmagazinecategoryComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MagazinecategoryRoutingModule { }
