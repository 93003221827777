<span class="slidetogglecss" (click)="togglenav()"><i
        [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">{{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}</i></span>
<div class="userlistnavcontent">
    <div class="row">
        <div class="col-md-12 submenubar">
            <h3 class="userlisttitle">Therapy Dashboard</h3>
            <a [routerLink]="['/content/therapy']" routerLinkActive="active">Therapy Design</a>
            <a [routerLink]="['/content/level']" routerLinkActive="active">Level</a>
            <a [routerLink]="['/content/module']" routerLinkActive="active">Module</a>
            <a [routerLink]="['/content/chapter']" routerLinkActive="active">Chapter</a>
            <a [routerLink]="['/content/lesson']" routerLinkActive="active">Lesson</a>
            <a [routerLink]="['/content/quiz']" routerLinkActive="active">Quiz</a>
            <a [routerLink]="['/content/question']" routerLinkActive="active">Question</a>
            <a [routerLink]="['/content/careycard']" routerLinkActive="active">Carey Card</a>
            <!-- <a [routerLink]="['/content/magazinecollection']" routerLinkActive="active">Magazine Collection</a> -->
            <a [routerLink]="['/content/magazinecategory']" routerLinkActive="active">Magazine Category</a>
            <a [routerLink]="['/content/magazine']" routerLinkActive="active">Magazine</a>
            <a [routerLink]="['/content/feedback']" routerLinkActive="active">Feedback</a>
            <a [routerLink]="['/content/tag']" routerLinkActive="active">Tag</a>
            <!-- <a [routerLink]="['/content/language']" routerLinkActive="active">Language</a> -->
            <a [routerLink]="['/content/intent']" routerLinkActive="active">Intent</a>
        </div>
    </div>
</div>