<app-breadcrumb *ngIf="isNormalChat || isCall" route="healthcoach/hctasklist/hctaskmanual"></app-breadcrumb>
<app-breadcrumb *ngIf="isChat" route="healthcoach/hctasklist/taskID/hcManualExecute"></app-breadcrumb>
<div class="userlistnav">
  <div *ngIf="isLoadingdata" class="spinner-layout">
    <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
  <!-- <app-breadcrumb route="hctasklist/hctaskmanual"></app-breadcrumb> -->
  <h4 style="margin-left: 10px;font-size: 14px;" *ngIf="isChat || isCall">
    <b>All patients
      <span id="patientcount"
        *ngIf="totalPatientCountForTask > 0 &&  totalPatientCountForTask !== 1">({{totalPatientCountForTask}})</span>
      <span *ngIf="totalPatientCountForTask == 0">(0)</span>
      <span *ngIf="totalPatientCountForTask == 1">(1)</span>
    </b>
  </h4>
  <div class="row">
    <!-- <div class="col-md-10 padright20">
      <div class="filter-search">
        <form>
          <div class="form-group has-feedback has-search mb-10">
            <input class="form-control" (keyup.enter)="applyFilter($event.target.value)" [value]="filterValue"
              placeholder="Search Users" autocomplete="off">
            <button mat-fab class="searchbtn" style="display: none;" (click)="applyFilter('')">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-2 padleft9">
      <div [ngSwitch]="switchcaseval">
        <div *ngSwitchCase="'default'">
          <button mat-icon-button [matMenuTriggerFor]="addmenu" class="plusrectangle">
            <mat-icon>add</mat-icon>
          </button>
          <mat-menu #addmenu="matMenu">
            <button mat-menu-item (click)="startBrodcast()">
              <mat-icon>wifi_tethering</mat-icon>
              <span>Broadcast</span>
            </button>
            <button mat-menu-item (click)="startUserTransfer()">
              <mat-icon>shuffle</mat-icon>
              <span>Transfer users</span>
            </button>
          </mat-menu>
        </div>
        <div *ngSwitchCase="'selection'">
          <button mat-icon-button class="plusrectangle" (click)="stopBrodcastorTransfer()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix" *ngIf="!hideAllFilters"></div> -->
    <div class="filtersection d-flex justify-content-spacebetween pl-15 pr-15" *ngIf="!isChat && !isCall">
      <div *ngIf="!isNormalChat">

        <button class="btn btn-shadow" [matMenuTriggerFor]="filtermenu" *ngIf="!hideAllFilters">
          <img src="assets/img/dls/icons/hcicons/HC_User_FIlter.svg" alt="">
          <!-- <mat-icon class="bottomarrowfloat notranslate">expand_more</mat-icon> -->
        </button>
        <mat-menu #filtermenu="matMenu" hasBackdrop="true" class="filtermenucss">
          <button mat-menu-item [disabled]="disableFilterExpiredCase"
            (click)="changeFilter('Active');$event.stopPropagation();" role="menuitemcheckbox"
            class="inner-no-pointer-events">
            <!-- <mat-icon>wifi_tethering</mat-icon> -->
            <span>
              <mat-checkbox [disabled]="disableFilterExpiredCase" [checked]="filterModel?.Active">Active
                <!-- <div
                  class="circlebox">{{getCounts("Active")}}</div> -->
              </mat-checkbox>
            </span>
          </button>
          <!-- commented lagging based on new CR -->
          <!-- <button mat-menu-item [disabled]="disableFilterExpiredCase"
            (click)="changeFilter('Lagging');$event.stopPropagation();" class="inner-no-pointer-events">
            <span>
              <mat-checkbox [disabled]="disableFilterExpiredCase" [checked]="filterModel?.Lagging">Lagging
              </mat-checkbox>
            </span>
          </button> -->
          <!-- <button mat-menu-item [disabled]="disableFilterExpiredCase" (click)="changeFilter('Freemium');$event.stopPropagation();" class="inner-no-pointer-events">
        <span><mat-checkbox [disabled]="disableFilterExpiredCase" [checked]="filterModel?.Freemium">Freemium <div class="circlebox">{{getCounts("Freemium")}}</div></mat-checkbox></span>
       </button> -->
          <button mat-menu-item (click)="changeFilter('Expired');$event.stopPropagation();"
            class="inner-no-pointer-events">
            <!-- <mat-icon>shuffle</mat-icon> -->
            <span>
              <mat-checkbox [checked]="filterModel?.Expired">Expired
              </mat-checkbox>
            </span>
          </button>
          <button mat-menu-item (click)="changeFilter('program_ended');$event.stopPropagation();"
            class="inner-no-pointer-events">
            <!-- <mat-icon>shuffle</mat-icon> -->
            <span>
              <mat-checkbox [checked]="filterModel?.program_ended">
                Program Ended
              </mat-checkbox>
            </span>
          </button>
          <button mat-menu-item [disabled]="disableFilterExpiredCase" [matMenuTriggerFor]="dayofprogram"
            class="inner-no-pointer-events">
            <!-- <mat-icon>shuffle</mat-icon> -->
            <span>Day Of Program</span>
          </button>
          <button mat-menu-item [disabled]="disableFilterExpiredCase" [matMenuTriggerFor]="gestationalage"
          class="inner-no-pointer-events">
          <span>Gestational Age</span>
        </button>
          <!-- <mat-checkbox [disabled]="disableFilterExpiredCase" [checked]="filterModel?.DayOfProgram">Day Of Program
            </mat-checkbox> -->
          <!-- <button mat-menu-item [disabled]="disableFilterExpiredCase" [matMenuTriggerFor]="knowledgefilter"
            class="inner-no-pointer-events">
            <span>Filter by Knowledge Level</span>
          </button> -->
          <div *ngIf="!hideClients">
            <button mat-menu-item [disabled]="disableFilterExpiredCase" [matMenuTriggerFor]="clientnames"
              class="inner-no-pointer-events"
              *ngIf="!localStoredData?.organization_data?.scarlet_acl_restriction?.includes('activation')">
              <!-- <mat-icon>shuffle</mat-icon> -->
              <span>Filter by Clients</span>
            </button>
          </div>

          <button *ngIf="tagData?.length > 0" mat-menu-item [disabled]="disableFilterExpiredCase"
            [matMenuTriggerFor]="tagnames" class="inner-no-pointer-events">
            <span>Filter by Tag</span>
          </button>
          <!-- <button mat-menu-item (click)="changeFilter('All')">
        <span>All <div class="circlebox">{{getCounts("All")}}</div></span>
       </button> -->
        </mat-menu>
        <mat-menu #clientnames="matMenu" class="filtermenucss">
          <button *ngFor="let client of dataSource.clientList" mat-menu-item
            (click)="changeFilter(client.title, true,checkbox.checked);$event.stopPropagation();"
            class="inner-no-pointer-events">
            <!-- <mat-icon>shuffle</mat-icon> -->
            <span>
              <mat-checkbox #checkbox [checked]="filterModel && filterModel[client.title]">{{client.title}}
              </mat-checkbox>
            </span>
          </button>
        </mat-menu>
        <mat-menu #dayofprogram="matMenu" class="filtermenucss">
          <button *ngFor="let dayof of programDays" mat-menu-item
            (click)="changeFilter(dayof.value,false,checkbox.checked,true,'');$event.stopPropagation();"
            class="inner-no-pointer-events">
            <span>
              <mat-checkbox #checkbox [checked]="filterModel && filterModel[dayof.value]">{{dayof.value}}
              </mat-checkbox>
            </span>
          </button>
        </mat-menu>
        <mat-menu #gestationalage="matMenu" class="filtermenucss">
          <button *ngFor="let ga of gestationalAge" mat-menu-item
            (click)="changeFilter(ga.value, false, checkbox.checked,false,true);$event.stopPropagation();"
            class="inner-no-pointer-events">
            <span>
              <mat-checkbox #checkbox [checked]="filterModel && filterModel[ga.value]">{{ga.value}}
              </mat-checkbox>
            </span>
          </button>
        </mat-menu>
        <mat-menu #knowledgefilter="matMenu" class="filtermenucss">
          <button mat-menu-item (click)="changeFilter('amateur');" class="inner-no-pointer-events">
            Amateur
          </button>
          <button mat-menu-item (click)="changeFilter('average');" class="inner-no-pointer-events">
            Average
          </button>
          <button mat-menu-item (click)="changeFilter('proficient');" class="inner-no-pointer-events">
            Proficient
          </button>
        </mat-menu>
        <mat-menu #tagnames="matMenu" class="filtermenucss">
          <button *ngFor="let tag of tagData" mat-menu-item (click)="changeFilterbytag(tag.id,tag.title);"
            class="inner-no-pointer-events">
            <span>{{tag.title}}</span>
          </button>
          <!-- <button mat-menu-item (click)="changeFilterbytag(null,null);$event.stopPropagation();"
            class="inner-no-pointer-events">
            <span>Remove Tag Filter</span>
          </button> -->
        </mat-menu>

      </div>
      &nbsp;&nbsp;
      <div class="filter-search" *ngIf="!isNormalChat && !isCall">
        <!-- <form> -->
        <div class="form-group has-feedback has-search mb-10">
          <input class="form-control" (keyup.enter)="applyFilter($event.target.value)" [value]="filterValue"
            placeholder="Search Users" autocomplete="off">
          <button mat-fab class="searchbtn" style="display: none;" (click)="applyFilter('')">
            <mat-icon matSuffix>search</mat-icon>
          </button>
        </div>
        <!-- </form> -->
      </div>
      <div class="filter-search1" *ngIf="isNormalChat && !isCall" style="margin-left: 0%;">
        <!-- <form> -->
        <h4 style="margin-left: 0%;font-size: 14px;" *ngIf="isNormalChat">
          <b>All patients
            <span id="patientcount"
              *ngIf="totalPatientCountForTask > 0 && totalPatientCountForTask !== 1">({{totalPatientCountForTask}})</span>
            <span *ngIf="totalPatientCountForTask == 0">(0)</span>
            <span *ngIf="totalPatientCountForTask == 1">(1)</span>
          </b>
        </h4>
        <div class="form-group has-feedback has-search1 mb-10">
          <input class="form-control1" (keyup.enter)="applyFilter($event.target.value)" [value]="filterValue"
            placeholder="Search" autocomplete="off">
          <button mat-fab class="searchbtn" style="display: none;" (click)="applyFilter('')">
            <mat-icon matSuffix>search</mat-icon>
          </button>
        </div>
        <!-- </form> -->

      </div>



      <div class="col-md-2 padleft9" *ngIf="!isNormalChat && !isCall">
        <div [ngSwitch]="switchcaseval">
          <div *ngSwitchCase="'default'">
            <button mat-icon-button [matMenuTriggerFor]="addmenu" class="plusrectangle">
              <mat-icon>add</mat-icon>
            </button>
            <mat-menu #addmenu="matMenu">
              <button mat-menu-item (click)="startBrodcast()">
                <mat-icon>wifi_tethering</mat-icon>
                <span>Broadcast</span>
              </button>
              <button mat-menu-item (click)="startUserTransfer()">
                <mat-icon>shuffle</mat-icon>
                <span>Transfer users</span>
              </button>
            </mat-menu>
          </div>
          <div *ngSwitchCase="'selection'">
            <button mat-icon-button class="plusrectangle" (click)="stopBrodcastorTransfer()">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8" style="float: right;" *ngIf="!isChat && !isNormalChat && !isCall">
      <button mat-button [matMenuTriggerFor]="sortmenu" class="filterrectangle filterrectanglesecondary"
        *ngIf="!hideAllFilters" [disabled]="disableChatStatusFilter">
        {{chatHtmlStatus}} &nbsp;&nbsp;
        <mat-icon class="notranslate">filter_list</mat-icon>
      </button>
      <mat-menu #sortmenu="matMenu">
        <button mat-menu-item (click)="changeFilter('initiate');">
          <span>First Response Due&nbsp;<div class="circlebox" *ngIf="chatMessageStatus?.initiate?.length">
              {{chatMessageStatus.initiate.length}}</div></span>
        </button>
        <button mat-menu-item (click)="changeFilter('respond');">
          <span>Response Due&nbsp;<div class="circlebox" *ngIf="chatMessageStatus?.respond?.length">
              {{chatMessageStatus.respond.length}}</div></span>
        </button>
        <button mat-menu-item (click)="changeFilter('waiting');">
          <span>No Response Due&nbsp;<div class="circlebox" *ngIf="chatMessageStatus?.waiting?.length">
              {{chatMessageStatus.waiting.length}}</div></span>
        </button>
      </mat-menu>
    </div>
    <!-- <div class="clearfix" *ngIf="!isChat "></div> -->
    <div style="padding: 0px;" class="col-md-12" *ngIf="!isChat && !isCall && filterChips.length > 0">
      <mat-chip-list>
        <mat-chip class="notranslate" *ngFor="let chip of filterChips" selectable="true" removable="true"
          (removed)="removeFilter(chip)">
          {{changeName(chip)}}<mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <!-- <mat-chip *ngIf="chatStatusFilterValue != '' && chatStatusFilterValue != 'All' && chatStatusFilterValue != 'all'" color="primary" selectable="true" removable="true" (removed)="removeChatStatusFilter(chatStatusFilterValue)">{{chatStatusFilterValue}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip> -->
      </mat-chip-list>
    </div>
    <!-- <div class="clearfix" *ngIf="!isChat "></div> -->
    <div class="col-md-12 p-0">
      <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
        <app-loader style="position: absolute;margin-top: 60%;margin-left: 110px;" showLoader="{{true}}"></app-loader>
      </div>
      <!-- <div *ngIf="dataSource?.userListFilterData?.length">
     No Users found
    </div> -->
      <div [ngSwitch]="switchcaseval">
        <!-- <div *ngSwitchCase="'selection'">
      im here
     <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 userlisttable userlisttablesmall">
      <ng-container matColumnDef="select">
       <th mat-header-cell *matHeaderCellDef style="display:none;">
       </th>
       <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation();$event.preventDefault();handleMultiRowSelection(row, $event)"
         [checked]="selection.isSelected(row)">
        </mat-checkbox>
       </td>
      </ng-container>
      <ng-container matColumnDef="name">
       <th mat-header-cell *matHeaderCellDef style="display:none;">  </th>
       <td mat-cell *matCellDef="let element"> 
          <span class="epname">{{element.name}}</span> 
       </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="userrowtb"></tr>
     </table>
    </div> -->
        <!-- infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextPage()"
    [scrollWindow]="false" -->
        <div [ngClass]="filterChips.length > 0 ? 'example-container-with-filter' : 'example-container' "
          class="example-container" id="chatList">
          <div>
            <mat-checkbox (change)="$event ? masterToggle() : null" class="col-md-12" style="margin-top: 10px;"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [hidden]="switchcaseval == 'default'">All
            </mat-checkbox>
          </div>

          <table infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="5" (scrolled)="getNextPage()"
            [scrollWindow]="false" mat-table [dataSource]="dataSource" class="mat-elevation-z8 userlisttable">

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef style="display:none;">
                <!-- <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
             [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox> -->
              </th>
              <td mat-cell *matCellDef="let row" [hidden]="switchcaseval == 'default'">
                <mat-checkbox
                  (click)="$event.stopPropagation();$event.preventDefault();handleMultiRowSelection(row, $event)"
                  [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="name" *ngIf="!isChat && !isNormalChat && !isCall">
              <td mat-cell *matCellDef="let element" (click)="clickpatient(element)">
                <span class="{{element?.notificationCount ? 'newnotificationcount' : ''}}">
                  <span class="epname notranslate">{{element?.name}}
                    <span [ngClass]="{'onlinegreen': element?.chatMetadata[0]?.status === 'online' }"></span>
                    <!-- <span class="offline" *ngIf="element?.chatMetadata[0]?.status === 'offline'" style="position: relative;margin-bottom: -2px;"></span> -->
                    <span class="expired-badge" *ngIf="element?.chatMetadata[0]?.is_expired === true">Expired</span>
                    <span class="program-ended-badge"
                      *ngIf="element?.chatMetadata[0]?.is_program_completed === true">Program Ended</span>
                    <span class="day-badge"
                      *ngIf="element?.chatMetadata[0]?.days > 0 && element?.chatMetadata[0]?.is_expired === false && element?.chatMetadata[0]?.is_program_completed === false">Day
                      - {{element?.chatMetadata[0]?.days}}</span>
                  </span>
                  <!-- <span class="epname notranslate">{{element?.name}}</span><span class="testusercss" title="Test User" *ngIf="element.activation_track != ''">{{element.activation_track == '' ? '' : element.activation_track}}</span>&nbsp; -->
                  <span class="testusercss notranslate" title="Test User"
                    *ngIf="element?.chatMetadata[0]?.is_test_user">{{element?.chatMetadata[0]?.is_test_user ? 'TEST' :
                    ''}}</span>
                  <!-- &nbsp;<span class="testusercss" title="Expired User" *ngIf="element?.Expired">{{element?.Expired ? 'EXP' : ''}}</span> -->
                  <!-- <span class="expiretag" matTooltip="Freemium User" *ngIf="element?.activation_type == 'doctor_freemium'">FREM</span>
                  <span matTooltip="Expired User" class="expiretag" *ngIf="element?.Expired">EXP</span> -->
                  <!-- <span class="last_message_time notranslate" *ngIf="element?.chatMetadata[0]?.last_message_at"
                    [hidden]="switchcaseval == 'selection'">{{element?.chatMetadata[0]?.last_message_at | date:'h:mm
                    a'}}</span> -->
                  <span class="last_message_time notranslate"
                    *ngIf="element?.chatMetadata[0]?.last_message_at">{{element?.chatMetadata[0]?.last_message_at |
                    date:'h:mma'}}
                  </span>
                </span>

                <br />

                <span style="float: left;" class="last_message"
                  *ngIf="element?.chatMetadata[0]?.last_message_details?.body" [hidden]="switchcaseval == 'selection'">
                  <i
                    class="material-icons notranslate {{element?.chatMetadata[0]?.last_sender?.includes('H') ? '' : 'reverseicon' }}">reply</i>
                  {{element.chatMetadata[0].last_message_details.body}}</span>

                <span style="float: right; margin-top: 10px;margin-right: 20px;" class="my-custom-badge-accent"
                  *ngIf="(clickeduser_id==0 || element.id!=clickeduser_id) && element?.chatMetadata[0]?.unread_count>0"
                  [matBadge]="element?.chatMetadata[0]?.unread_count" matBadgeOverlap="false" matBadgeSize="small"
                  matBadgeColor="accent"></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="name" *ngIf="isChat && !isNormalChat">
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [ngClass]="{'my_class':checkprogress(element.d)}"
                  (change)="handleMultiRowSelection1(element,$event)"
                  class="{{element?.notificationCount ? 'newnotificationcount' : ''}}"
                  [disabled]="checkprogress(element.id)" [checked]="checkprogress(element.id)">
                  <span class="epname notranslate">{{element?.name}}
                    <!-- <span class="online" *ngIf="element?.chatMetadata[0]?.status === 'online'"
                      style="position: relative;margin-bottom: -2px;"></span> -->
                    <!-- <span class="offline" *ngIf="element?.chatMetadata[0]?.status === 'offline'" style="position: relative;margin-bottom: -2px;"></span> -->
                    <!-- <span class="expired-badge" *ngIf="element?.chatMetadata[0]?.is_expired === true"
                      style="position: relative;margin-bottom: -2px;">Expired</span>
                    <span class="day-badge"
                      *ngIf="element?.chatMetadata[0]?.days > 0 && element?.chatMetadata[0]?.is_expired === false"
                      style="position: relative;margin-bottom: -2px;">Day-{{element?.chatMetadata[0]?.days}}</span> -->
                  </span>
                  <span class="testusercss notranslate" title="Test User"
                    *ngIf="element?.chatMetadata[0]?.is_test_user">{{element?.chatMetadata[0]?.is_test_user ? 'TEST' :
                    ''}}</span>
                  <span class="last_message_time1 notranslate" *ngIf="element?.chatMetadata[0]?.last_message_at"
                    [hidden]="switchcaseval == 'selection'">{{element?.chatMetadata[0]?.last_message_at | date:'h:mm
                    a'}}</span>
                  <br />
                  <span class="last_message" *ngIf="element?.chatMetadata[0]?.last_message_details?.body"
                    [hidden]="switchcaseval == 'selection'"> <i
                      class="material-icons notranslate {{element?.chatMetadata[0]?.last_sender?.includes('H') ? '' : 'reverseicon' }}">reply</i>
                    {{element?.chatMetadata[0]?.last_message_details?.body}}</span>
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container id="normalCallSection" matColumnDef="name" *ngIf="(isNormalChat || isCall )">
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [ngClass]="{'my_class':checkprogress(element.id)}"
                  (change)="handleMultiRowSelection1(element,$event)"
                  class="{{element?.notificationCount ? 'newnotificationcount' : ''}}"
                  [disabled]="checkprogress(element.id)" [checked]="checkprogress(element.id)">
                  <span class="epname notranslate">{{element?.name}}
                    <!-- <span class="online" *ngIf="element?.chatMetadata[0]?.status === 'online'"
                      style="position: relative;margin-bottom: -2px;"></span> -->
                    <!-- <span class="offline" *ngIf="element?.chatMetadata[0]?.status === 'offline'" style="position: relative;margin-bottom: -2px;"></span> -->
                    <!-- <span class="expired-badge" *ngIf="element?.chatMetadata[0]?.is_expired === true"
                      style="position: relative;margin-bottom: -2px;">Expired</span> -->
                    <!-- <span class="day-badge"
                      *ngIf="element?.chatMetadata[0]?.days > 0 && element?.chatMetadata[0].is_expired === false"
                      style="position: relative;margin-bottom: -2px;">Day-{{element?.chatMetadata[0]?.days}}</span> -->
                  </span>
                </mat-checkbox>
                <!-- <mat-selection-list>
                  <mat-list-option checkboxPosition="before" (click)="handleMultiRowSelection1(element,$event)"  [value]="element.name" multiple [disabled]="checkprogress(element.user_id)" [ngClass]="{'my_class':checkprogress(element.user_id)}"
                  [selected]="checkprogress(element.user_id)">{{element.name}}</mat-list-option>
                </mat-selection-list> -->
              </td>

              <!-- <div class="pseudobtn" (click)="getNextPage()" *ngIf="dataSource?.userListFilterData?.length >= 1"
                  [hidden]="dataSource.loadmore != true || dataSource?.userListFilterData?.length < 20">Load more</div> -->

            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [class]="(highlightedRow == row && switchcaseval == 'default') ? (row?.Onboarding ? 'lightdark userrowtb disable' : 'lightdark userrowtb') : (row?.Onboarding ? 'userrowtb disable' : 'userrowtb')">
            </tr>
          </table>
          <div class="pseudobtn" (click)="getNextPage()" *ngIf="dataSource?.userListFilterData?.length >= 1"
            [hidden]="dataSource.loadmore != true || dataSource?.userListFilterData?.length < 20">Load more</div>
        </div>
      </div>
    </div>
  </div>
</div>