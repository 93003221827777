import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ReplaySubject} from 'rxjs';
import { ChoosemediamodalComponent } from 'src/app/content/mediamanager/choosemediamodal/choosemediamodal.component';
import { SUPPORTED_FILE_TYPES } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { environment } from '../../../../environments/environment';
import { ProfileimageComponent } from '../../profileimage/profileimage.component';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-editpaymentplan',
  templateUrl: './editpaymentplan.component.html',
  styleUrls: ['./editpaymentplan.component.scss']
})

export class EditpaymentplanComponent implements OnInit {
  upload_type: any = upload_type;
  public newitem: boolean = false;
  public userid: any;
  chooseMedia: any;
  public allmedia: any;
  public langid: any;
  public selfparentid: any;
  public selfparentide: any;
  public childid: any;
  public language: any;
  optiontype: any;
  plandata: any;
  showPaymentduration: boolean = true;
  public showButtonLoader: boolean = false;
  public isloading: boolean = false;
  public uploadedparam: boolean = false;
  private allfiles: any;
  public uploadedfiletype: any;
  public image_id: any;
  private responseUrl: any;
  private responsethumbUrl: any;
  public resdata: any;
  public image_path: any;
  s3url: any = environment.s3url;
  noofoptions:any;
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  public isinvalid: boolean = false;
  public dataForm = this.fb.group({
    title: ['',[Validators.required,whiteSpaceValidation]],
    detailed_text: ['', Validators.required],
    image_media: [''],
    plan_type: ['', Validators.required],
    payment_type: ['', Validators.required],
    features: this.fb.array([]),
    is_most_popular: [false, Validators.required],
    price: ['', Validators.required],
    payment_duration: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    payment_frequency:[''],
    featurecount: ['']
  });

  constructor(public dialog: MatDialog, public fb: FormBuilder, private router: Router, private route: ActivatedRoute, private systemService: SystemService, private commonService: CommonserviceService) { }

  initTextOptions() {
    return this.fb.group({
      feature: ['', Validators.required]
    });
  }
  
  initTextOptionswithdata(data) {
    return this.fb.group({
      feature: [data, Validators.required]
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
        this.showPaymentduration = false;
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getPaymentData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getPaymentData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getPaymentData(params.chid);
        }
      }
    });
  }


  // To Get Payment Data
  // ----------------------------

  getPaymentData(ide) {
    this.commonService.getOneDataById(ide, "payment")
    .subscribe(
      (res: any) => this.onPaymentSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onPaymentSuccess(data) {
    const feature = <FormArray>(this.dataForm.controls['features']);
    data = (data as any).data;
    this.plandata = data;
    this.subscriptionType(this.plandata.payment_type);
    this.plandata.featurecount = this.plandata.features.length.toString();
    this.plandata.payment_duration = this.plandata.payment_duration.toString();
    if (this.plandata.payment_type == 'subscription') {
      this.plandata.payment_frequency = this.plandata.payment_frequency.toString();

    }
    this.dataForm.patchValue(this.plandata);
    if (this.plandata.features.length != 0) {
      for (var i = 0; i < this.plandata.features.length; i++) {
        feature.push(this.initTextOptionswithdata(this.plandata.features[i]));
      }
    }
    this.dataForm.value.image_media = this.plandata.imageMediaId;
    this.getMedia(this.plandata.imageMediaId);
    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
  }

  // ----------------------------


  // To Get Payment Image
  // ----------------------------

  getMedia(id) {
    this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
    .subscribe(
      (res: any) => this.onSuccessMediaData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  
  private onSuccessMediaData(data) {
    data = (data as any).data;
    this.image_path = data.getOneDocumentMedia.path;
    this.image_id = data.getOneDocumentMedia.id;
    this.uploadedparam = true;
  }

  // ----------------------------


  // To Upload Image
  // ----------------------------

  uploadImage() {
    if (this.newitem == false) {
      this.uploadedparam = false;
    }
    if (this.demoForm.status == 'INVALID') {
      this.isinvalid = true;
      this.systemService.showSnackBar("Your filetype is not supported. The Supported formats are .jpeg, .png, .jpg ", "Ok");
    }
    else if (this.demoForm.value.files[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = this.demoForm.value.files;
      
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.dataForm.value.image_media == this.image_id) {
            this.uploadedparam = true;
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloading = true;
              scope.uploadedparam = false;
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
              .subscribe(
                (data) => {
                  scope.responseUrl = data;
                  scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                      });
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    }
                  )
                }
              );
            }
          }
        });
      }
    }
  }

  // ----------------------------


  // To Upload Thumbnail
  // ----------------------------

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          scope.commonService.getS3URL('user_profile', thtype, ele.size, ele.name)
          .subscribe(
            (data) => {
              scope.responsethumbUrl = data;
              scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
              .subscribe(
                (data) => {
                  saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                }, (error) => {
                  scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                }
              )
            }
          )
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, thumbpath, imageinfo) {

      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, upload_type[0], 'document')
      .subscribe(
      (data) => {
        scope.resdata = data;
        scope.image_path = scope.resdata.data.path;
        scope.uploadedparam = true;
        scope.isloading = false;
        if (scope.resdata.status == 201) {
        }
        else {
          scope.systemService.showSnackBar("Unknown error occured", "Ok");
        }
        cb(true);
      }
      )
    }
  }

  // ----------------------------


  // To Convert DataURI to Blob
  // ----------------------------

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  // ----------------------------


  // To Open media Modal
  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }

  // ----------------------------


  // To generate options
  // ----------------------------

  generateOptions(e) {
    this.noofoptions = e;
    for (let j = 0; j < e; j++) {
      this.filteredTags[j] = new ReplaySubject<any[]>(1);
    }
     const feature = <FormArray>(this.dataForm.controls['features']);
    if (feature.length !== 0) {
      if (feature.length < e) {
        for (let l = feature.length; l < e; l++) {
          feature.push(this.initTextOptions());
        }
      }
      if (feature.length > e) {
        for (let l = feature.length; l > e; l--) {
          feature.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        feature.push(this.initTextOptions());
      }
    }
  }

  // ----------------------------

  subscriptionType(e) {
    
    this.optiontype = e;
    if (this.optiontype == 'subscription') {
      this.showPaymentduration = true;
      this.dataForm.addControl('payment_frequency', new FormControl('', Validators.required));
    }
    else if (this.optiontype == 'onetime') {
      this.showPaymentduration = false;
      this.dataForm.removeControl('payment_frequency');
    }
    else {
      this.showPaymentduration = false;
      this.dataForm.removeControl('payment_frequency');
    }
  }


  // Submitting payment Data
  // ----------------------------

  dataObject() {
    var optionFeature = [];
    var postdata = this.dataForm.value;
    
    if (this.resdata) {
      this.dataForm.value.image_media = this.resdata.data.id;
      postdata.image_media = this.dataForm.value.image_media.toString();
    }
    else {
      postdata.image_media = this.dataForm.value.image_media.id.toString();
    }
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let detailed_text = this.commonService.isHTML( this.dataForm.value.detailed_text);

    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    if(detailed_text  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    }
    postdata.title = postdata.title.toString();
    postdata.detailed_text = postdata.detailed_text.toString();
    postdata.plan_type = postdata.plan_type.toString();
    postdata.payment_duration = postdata.payment_duration.toString();
    if (postdata.payment_type == 'subscription') {
      postdata.payment_frequency = postdata.payment_frequency.toString();
    }
    else if (postdata.payment_type == 'onetime') {
      postdata.payment_frequency = '0';
    }
    else {
      postdata.payment_frequency = postdata.payment_frequency.toString();
    }
    postdata.is_most_popular = postdata.is_most_popular.toString();
    postdata.price = postdata.price.toString();
    if (this.dataForm.value.features.length != 0) {
      postdata.features.forEach(el => {
        optionFeature.push(el.feature);
      });
    }
    postdata.features = JSON.stringify(optionFeature);
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    postdata.features = postdata.features;
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    delete postdata.featurecount;
    if (this.newitem == true) {
      this.commonService.postToBlade(postdata, 'payment')
      .subscribe(
        (res: any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
    else {
      this.commonService.updateChildToBlade(this.childid, this.selfparentid, postdata, 'payment')
      .subscribe(
        (res: any) => this.onUpdateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Payment has been created"), "OK");
    this.router.navigate(['admin/payment']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Payment has been updated"), "OK");
    this.router.navigate(['admin/payment']);
  }

  // ----------------------------

}

export enum upload_type {
  user_profile
}