import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-profileimage',
  templateUrl: './profileimage.component.html',
  styleUrls: ['./profileimage.component.scss']
})

export class ProfileimageComponent implements OnInit {
 public imageData: any;
 public showprev: any;

  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;

  public mediainfoForm = this.fb.group({
    mediadatas: this.fb.array([
    ])
  });

  // Validator For Whitespaces
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  mediadataarray(url, type) {
    return this.fb.group({
      url: [url],
      type: [type],
      title: ["", [Validators.required, this.noWhitespaceValidator]],
      detailed_text: [""]
    })
  }

  constructor(private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, private dialogRef: MatDialogRef<ProfileimageComponent>, private systemService: SystemService, private sanitizer: DomSanitizer) { }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Patient Profile');
  }

  ngOnInit() {
    this.setSEOTags();
    const control = <FormArray>this.mediainfoForm.controls['mediadatas'];
    var p;
    for (var k = 0; k < this.data.image.length; k++) {
      this.previewFile(this.data.image[k], data => {
        p = data;
        if (p != null) {
          control.push(this.mediadataarray(p.url, p.type))
        }
        else {
          control.push(this.mediadataarray(null, null))
        }
      });
    }
  }


  // Submitting Image Data
  // ----------------------------

  onsubmit() {
    const control = <FormArray>this.mediainfoForm.controls['mediadatas'];
    this.dialogRef.close(control);
  }

  // ----------------------------


  // To Preview Uploaded File
  // ----------------------------

  previewFile(event, cb): void {
    let dt;
    var input = event;
    if (input.size > 25000000) {
      dt = null;
      cb(dt)
    }
    else {
      if (input && input.type.match('image')) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target;
          this.imageData = this.imageData.result;
          dt = {
            "type": "image",
            "url": this.imageData
          }
          cb(dt)
        }
        reader.readAsDataURL(input);
      }
      this.showprev = true;
    }
  }

  // ----------------------------

}
