<div class="userdetailcontent matcardbottomzero diarysectioncss">
  <div>
    <h3 class="generictitle col-md-6">Diary</h3>
    <button mat-icon-button class="plusrectangletransparent pull-right col-md-6"
      style="margin-right: 15px;margin-top:15px;" (click)="openLabDialog()">
      <mat-icon>add</mat-icon>
    </button>

    <div class="chartheader">

      <div class="clearfix"><br /></div>
      <form [formGroup]="dataForm" validate class="filter-form clearfix">

        <mat-form-field appearance="outline" class="option-selection" [ngClass]="((mainoption == 'Activity') || (mainoption === 'Weight') || (mainoption === 'Water') || (mainoption === 'Peak Flow') || (mainoption === 'Meal') || (mainoption === 'Blood Pressure') || (mainoption === 'Blood Sugar')) ? 'col-md-12 bsugar' : 'col-md-6 pr-0'">
          <mat-label>Select Option</mat-label>
          <mat-select class="camelcased" formControlName="selectedOption" [(ngModel)]="selectedDiaryOption"
            (selectionChange)="selectVal($event.value)">
            <mat-option class="camelcased" *ngFor="let tag of eligibleLogData" [value]="tag.title">{{tag.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="option-selection col-md-12 bsugar"
          *ngIf="mainoption == 'Blood Sugar'">
          <mat-label>Sub Type</mat-label>
          <mat-select class="camelcased" (selectionChange)="bloodSugarSubMenu($event.value)">
            <mat-option class="camelcased" value="fasting blood sugar">Fasting</mat-option>
            <mat-option class="camelcased" value="postmeal breakfast">Post Breakfast</mat-option>
            <mat-option class="camelcased" value="postmeal lunch">Post Lunch</mat-option>
            <mat-option class="camelcased" value="postmeal dinner">Post Dinner</mat-option>
            <mat-option class="camelcased" value="post snacks">Post Snacks</mat-option>
            <mat-option class="camelcased" value="premeal breakfast">Pre Breakfast</mat-option>
            <mat-option class="camelcased" value="premeal lunch">Pre Lunch</mat-option>
            <mat-option class="camelcased" value="premeal dinner">Pre Dinner</mat-option>
            <mat-option class="camelcased" value="pre snacks">Pre Snacks</mat-option>
            <mat-option class="camelcased" value="random">Random</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="option-selection col-md-6 bsugar" *ngIf="mainoption == 'meal'">
          <mat-label>Sub Type</mat-label>
          <mat-select class="camelcased" (selectionChange)="mealSubMenus($event.value)">
            <mat-option class="camelcased" value="breakfast">Breakfast</mat-option>
            <mat-option class="camelcased" value="lunch">Lunch</mat-option>
            <mat-option class="camelcased" value="snacks">Snacks</mat-option>
            <mat-option class="camelcased" value="dinner">Dinner</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="option-selection col-md-6 pp"
          *ngIf="selectedDiaryOption == 'Body Parameters'">
          <mat-label>Measurement Type</mat-label>
          <mat-select class="camelcased" (selectionChange)="selectMeasurementVal($event.value)">
            <mat-option class="camelcased" *ngFor="let measurement of MeasurementOptions" [value]="measurement.title">
              {{measurement.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="(mainoption != 'Activity' && mainoption != 'Water' && mainoption != 'Weight' && mainoption != 'Peak Flow' && mainoption != 'Meal' && mainoption != 'Blood Pressure' && mainoption != 'Blood Sugar') ">
          <mat-form-field appearance="outline" class="option-selection col-md-6 pp"
            *ngIf="mainoption != 'Infusion Site'">
            <mat-label>Graph Type</mat-label>
            <mat-select class="camelcased" (selectionChange)="changeRange($event.value)">
              <mat-option class="camelcased" value="daily">Daily</mat-option>
              <mat-option class="camelcased" value="weekly">Weekly</mat-option>
              <mat-option class="camelcased" value="monthly">Monthly</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-6 pr-0 pq"
          [ngClass]="mainoption != 'Peak Flow' ? '' : 'pr-15'">
            <mat-label>Choose start date</mat-label>
            <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="todaydate"
              placeholder="Choose a date" (dateChange)="changeEndDate()">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-6 pr"
            [ngClass]="mainoption != 'Peak Flow' ? '' : 'pr-0'">
            <mat-label>Choose end date</mat-label>
            <input matInput formControlName="enddate" [matDatepicker]="picker2" [max]="morrow" placeholder="Choose a date"
              [min]="dataForm.get('startdate')?.value">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker2></mat-datepicker>
          </mat-form-field>
        </ng-container>

        <div class="col-md-12">
          <button mat-flat-button color="primary" class="primary margin-btn" (click)="applymainfilter()"
            style="width: 100%;">Apply</button>
        </div>
      </form>

      <div *ngIf="mainoption=='Meal'" class="col-md-12" class="meal-data" style="padding: 15px;">
        Daily calorie goal:
        <span *ngIf="targetPatientData.target && userData.show_targets==true"
          class="meal-data-sub-text">{{convertDecimal(targetPatientData.target)}} kcal</span>
        <span *ngIf="mainoption=='Meal' && userData.show_targets==false"
          class="meal-data-sub-text">NA</span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' &&  userData.show_targets==true" class="meal-data"> Breakfast:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.breakfast)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' && userData.show_targets==true" class="meal-data">Lunch:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.lunch)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' && userData.show_targets==true" class="meal-data"> Dinner:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.dinner)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' && userData.show_targets==true" class="meal-data"> Snacks:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.snacks)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;
      </div>

      <ng-container *ngIf="mainoption === 'Activity'">
        <div class="col-md-12" style="padding-top: 10px;">
          <span class="meal-data">
            Daily average: 
            <span class="meal-data-sub-text">{{ activityAverage }}</span>
          </span>
        </div>
      </ng-container>
      
      <ng-container *ngIf="mainoption === 'Water'">
        <div class="col-md-12" style="padding-top: 10px;">
          <span class="meal-data">
            Daily average: 
            <span class="meal-data-sub-text">{{ waterAverage }}</span>
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="mainoption === 'Meal'">
        <div class="col-md-12" style="padding-top: 10px;">
          <span class="meal-data">
            Daily average: 
            <span class="meal-data-sub-text">{{ mealAverage }}</span>
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="mainoption === 'Weight'" matColumnDef="weight">
        <div class="col-md-12" style="padding-top: 10px;">
          <span class="meal-data">
            Latest Weight: 
            <span class="meal-data-sub-text">{{latestWeight}}</span>
          </span>
        </div>
      </ng-container>

    </div>
    <div class="logging-chart">
   
      <ng-container *ngIf="defaultleftoption === 'activity' || defaultleftoption === 'weight' || defaultleftoption === 'water' || defaultleftoption === 'peak_flow' || defaultleftoption === 'meal' || defaultleftoption === 'blood_pressure' || defaultleftoption === 'blood_sugar'">
        <div  *ngIf="showLoader" style="position: absolute;top: 40%;transform: translateY(-40%);width: 100%;">  
          <mat-progress-spinner
          class="spinner-margin"
          color="accent"
          mode="indeterminate"
          diameter="50">
        </mat-progress-spinner>
      </div>
        <div id="dashboard_div" *ngIf="!showNoGraphdatafound;else nographdatafound">
          <div id="chart_div"></div>
          <div id="slider_div"></div>
        </div>
      </ng-container>

      <div [hidden]="!(defaultleftoption == 'blood_pressure')" *ngIf="!showNodatafound" class="labels text-center">
        <div>
          <div class="systolic-bar"></div> Systolic-Diastolic (mmHg) &nbsp;<div class="pulse-bar"></div> Pulse
          Rate&nbsp;(BPM)
        </div>
      </div>

    </div>

    <div class="example-container diarycss"  *ngIf="!showNodatafound;else nodatafound"  style="padding:15px;">
      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li>
            <a class="all" (click)="applymainfilter()">All</a>
          </li>
          <li>
            <a class="active-filter" (click)="deletedData(false)">Active</a>
          </li>
          <li>
            <a class="inactive-filter" (click)="deletedData(true)">Deleted</a>
          </li>
        </ul>
      </div>
      <div class="lazy-loading" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
      (scrolled)="getNextSet()" [scrollWindow]="false" >
      <table style="width: -webkit-fill-available;" mat-table matSort multiTemplateDataRows *ngIf="!showNodatafound" [dataSource]="dataSource">

        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef>Qty</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.quantity}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="weight">
          <mat-header-cell *matHeaderCellDef>Weight &nbsp;<span>(Kg)</span></mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> 
            {{showGenericData?.unit == 'lbs' ? (showGenericData.quantity * 0.453592  | number: '1.0-0') : (showGenericData.quantity| number: '1.0-0')}} 
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="measure">
          <mat-header-cell *matHeaderCellDef>Measure </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.measure}}
            {{showGenericData?.unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tag">
          <mat-header-cell *matHeaderCellDef> Tags</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{!showGenericData.tag?'-':showGenericData.tag}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="diastolic">
          <mat-header-cell *matHeaderCellDef> Systolic-Diastolic &nbsp;<span>(mmHg)</span> </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{showGenericData.systolic}}-{{showGenericData.diastolic}}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="systolic">
          <mat-header-cell *matHeaderCellDef>Systolic </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"> {{showGenericData.systolic}} {{showGenericData.systolic_unit}}
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="heart_rate">
          <mat-header-cell *matHeaderCellDef>Pulse Rate &nbsp;<span>(bpm)</span></mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            <span *ngIf="showGenericData.heart_rate !== null">
              {{showGenericData.heart_rate}}
            </span> 
            <span *ngIf="showGenericData.heart_rate === null">
              NA
            </span> 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activity_type">
          <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{showGenericData.activity_type}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activity_value">
          <mat-header-cell *matHeaderCellDef> 
            Activity 
          </mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> 
            
            <ng-container *ngIf="activityUnits === 'mins'">
              {{showGenericData.quantity | number:'1.0-0'}} mins
            </ng-container>

            <ng-container *ngIf="activityUnits === 'steps'">

              <ng-container *ngIf="(showGenericData.steps_count !== null && showGenericData.steps_count !== 0)">
                {{showGenericData.steps_count | number:'1.0-0'}} steps
              </ng-container>

              <ng-container *ngIf="showGenericData.steps_count === null || showGenericData.steps_count === 0">
                {{showGenericData.quantity | number:'1.0-0'}} mins
              </ng-container>
              
            </ng-container>
            
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="blood_sugar_log_type">
          <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.meal_type}}
            <br />
            <span  *ngIf="showGenericData.meal_type !== 'fasting'">
            {{ showGenericData.blood_sugar_log_type}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="blood_sugar_meal_type">
          <mat-header-cell *matHeaderCellDef>Meal Type</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"> {{showGenericData.meal_type}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="calories_value">
          <mat-header-cell *matHeaderCellDef class="notranslate">kcal</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            <div *ngFor="let d of showGenericData.meal_data" style="margin-top:2px">
              <span *ngFor="let meal of d.food_data;let i=index;" >{{calculateKcal(meal.default_kcal,meal.quantity) | number : '1.1-1'}}<br><br></span>
            </div>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="blood_sugar_val">
          <mat-header-cell *matHeaderCellDef> value &nbsp;</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.value}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Quantity">
          <mat-header-cell *matHeaderCellDef> Quantity</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.value}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hip">
          <mat-header-cell *matHeaderCellDef> Hip</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.hip}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hip_unit">
          <mat-header-cell *matHeaderCellDef> Hip Unit</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.hip_unit}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="waist">
          <mat-header-cell *matHeaderCellDef> Waist</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.waist}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="waist_unit">
          <mat-header-cell *matHeaderCellDef> Waist Unit</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.waist_unit}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ratio">
          <mat-header-cell *matHeaderCellDef> Aspect Ratio</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.ratio}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> value</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.value}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="unit">
          <mat-header-cell *matHeaderCellDef>Unit</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{convertToFirstletterCaps(showGenericData.unit)}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="log_date" *ngIf="defaultleftoption !== 'meal'" >
          <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{convertIsoDate(showGenericData.log_date)}} <br> <br>  </mat-cell>
        </ng-container>
        <ng-container matColumnDef="log_date" *ngIf="defaultleftoption === 'meal'" >
          <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
          <mat-cell *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{convertIsoDate(showGenericData?.meal_data[0]?.log_date)}} <br> <br> <img *ngIf="defaultleftoption === 'meal'" style="height: 20px;" alt="macro-nutritient" src="assets/img/nutrition.png" (click)="showMacroData(showGenericData.meal_data);"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="meal_type">
          <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> <span>{{convertToFirstletterCaps(showGenericData.meal_type)}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="food_item">
          <mat-header-cell *matHeaderCellDef> Item</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            <div *ngFor="let d of showGenericData.meal_data">
              <span *ngFor="let meal of d.food_data;let j = index"><span [ngStyle]="(d.is_deleted || meal.is_deleted ) ? {'color': 'red','opacity': '0.5','cursor':'not-allowed','pointer-events': 'none'}:{'background-color': '','pointer-events': 'all'}" (click)="deleteMealLog(meal.id,d.id,showGenericData,j)">{{meal.food_item}}({{meal.quantity}})<br></span><br></span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="log_type">
          <mat-header-cell *matHeaderCellDef> Log Type</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{ConvertData(showGenericData.log_type)}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <mat-header-cell *matHeaderCellDef> Long</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.longitude}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="latitude">
          <mat-header-cell *matHeaderCellDef> Lat</mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }"> {{showGenericData.latitude}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="infusion_site">
          <mat-header-cell *matHeaderCellDef> Infusion Site </mat-header-cell>
          <mat-cell class="camelcased" *matCellDef="let showGenericData"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{ConvertData(showGenericData.infusion_site)}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="site_change_reason">
          <mat-header-cell *matHeaderCellDef>Reason for change</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData" class="text-capitalize"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            {{showGenericData.site_change_reason}}
            <div *ngIf="!showGenericData.site_change_reason">
              _
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="showEdit">
          <mat-header-cell *matHeaderCellDef>Change Infusion Site</mat-header-cell>
          <mat-cell *matCellDef="let showGenericData; let i = index" class="camelcased"
            [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
            <button mat-icon-button class="plusrectangletransparent ae-add"
              *ngIf="showGenericData.id === latest && !showGenericData.is_deleted "
              (click)="editInfusionDialog(showGenericData)"
              style="height: 28px;width: 28px;background: #333333;margin-right: 25px;">
              <mat-icon style="height: 38px;font-size: 16px;">edit</mat-icon>

            </button>
            <!-- {{dataSource.filteredData.indexOf(showGenericData)}} -->
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let showGenericData" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail"
              [@detailExpand]="showGenericData == expandedElement ? 'expanded' : 'collapsed'"
              *ngIf="!showGenericData.is_deleted && !showGenericData.meal_type">
              <div class="menu-holder">
                <div class="example-button-row">
                  <button class="last-btn no-hover-effect" mat-button disableRipple
                    (click)="deleteLog(showGenericData)">Delete</button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let showGenericData; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === showGenericData"
          (click)="expandedElement = expandedElement === showGenericData ? null : showGenericData"
          [ngStyle]="showGenericData.is_deleted?{'background-color':'#FFECEF'}:{'background-color': ''}">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    </div>
   
  </div>
  <ng-template #nodatafound>	
    <div style="text-align: center; padding: 50px;background: #8080801f;margin: 10px;">
      No Table Data Available	
    </div>	
  </ng-template>
  <ng-template #nographdatafound>	
    <div style="text-align: center; padding: 50px;background: #8080801f;margin: 10px;">
      No Graph Data Available	
    </div>	
  </ng-template>
</div>
