import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-generatecodebyrequest',
  templateUrl: './generatecodebyrequest.component.html',
  styleUrls: ['./generatecodebyrequest.component.scss']
})
export class GeneratecodebyrequestComponent implements OnInit {
  selectedUserID: any;
  public localStoredHCData: any;
  public showButtonLoader: boolean;
  public userData: any;
  constructor(private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, private commonService: CommonserviceService, public fb: FormBuilder,
    private systemService: SystemService, public dialogRef: MatDialogRef<GeneratecodebyrequestComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Generate Code By Request');
  }

  ngOnInit() {
    this.setSEOTags();
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = this.data.element;
  }

  public generateCodeForm = this.fb.group({
    code_count: [Validators.required]

  });


  // Submitting Data To Generate Codes 
  // ----------------------------

  dataObject() {
    var postdata = this.generateCodeForm.value;
    postdata.code_count = postdata.code_count.toString();
    postdata.created_by_data_fk = this.localStoredHCData.id.toString();
    postdata.generation_request_data_fk = this.userData.id;
    this.commonService.postToBlade(postdata, 'activationbyreq', false)
      .subscribe(
        (res: any) => this.onRequestSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onRequestSuccess() {
    this.dialog.closeAll();
    this.systemService.showSuccessSnackBar(" Successfully Generated codes", "OK");
  }

  // ----------------------------

  closeModal(): void {
    this.dialogRef.close();
  }

}
