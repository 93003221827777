import { OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';

export abstract class FormPage implements OnInit, AfterViewInit {
  formElements: HTMLDivElement[];
  form: FormGroup;
  submitted: boolean;
  loading: boolean;

  constructor(protected el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.formElements = Array.from(
      this.el.nativeElement.querySelectorAll('.form-group')
    );
  }

  abstract initForm(): void;

  onSubmissionSuccess(res: any): void {
    this.loading = false;
  }

  scrollToFirstErroredInput(errors: any): void {
    const firstErrorKey = Object.keys(errors)[0];
    const formgrp = this.formElements.find(
      el =>
        el.querySelector('.form-control') &&
        el.querySelector('.form-control').id === firstErrorKey
    );
    if (formgrp) {
        formgrp.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  highlightErroredInputs(errors: any): void {
    Object.entries(errors).forEach(([key, value]) =>
      this.form.get(key).setErrors({ [value as any]: true })
    );
  }

  onSubmissionError(err: HttpErrorResponse): void {
    this.form.enable();
    this.loading = false;
  }

  submit(): any {
    // if (this.form.invalid) {
    //   this.submitted = true;
    //   this.form.markAllAsTouched();
    //   const errors = Object.entries(this.form.controls)
    //     .filter(([, control]) => control.errors)
    //     .reduce((acc, [key]) => ({ ...acc, [key]: true }), {});
    //   return this.scrollToFirstErroredInput(errors);
    // }
    const body = { ...this.form.value };
    this.form.markAsPristine();
    this.form.disable();
    this.loading = true;
    return body;
  }

  validateEmail(control: FormControl) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(control.value)) {
      if (control.value.indexOf("@wellthy.care", control.value.length - "@wellthy.care".length) !== -1) {
        return null
      } 
      else {
        return { 'validateEmail': true };
      }
    }
    else {
      return { 'validateEmail': true };
    }
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  
}
