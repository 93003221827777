<div class="row">
    <div class="col-md-12">
        <div class="row">
    
            <div class="col-md-8">
                <p class="title-cont">Add Mobile Number</p>
            </div>
    
            <div class="col-md-4">
                <div class="copy-close-wrapper">
                    <div class="close-icon" (click)="close()"></div>
                </div>
            </div>
    
        </div>

        <div class="row">

            <form [formGroup]="addMobileNumberForm" validate class="mobile-number-form">

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" placeholder="Enter Mobile Number" >
                    </mat-form-field>
                </div>

                <div class="col-md-10">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput type="number" formControlName="mobileNumber" placeholder="Enter Mobile Number">
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <button class="btn btn-primary otp-btn" [disabled]="!isValidMobileNumber()" (click)="sendOTP()">Send OTP</button>
                </div>

                <div class="col-md-10">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>OTP</mat-label>
                        <input matInput type="number" formControlName="otp" placeholder="Enter OTP">
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <button class="btn btn-primary otp-btn" [disabled]="disableOtpBtn" (click)="verifyOTP()">Verify OTP</button>
                </div>

            </form>
        </div>
    </div>     
</div>
