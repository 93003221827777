import { Component, Input, OnInit } from '@angular/core';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  @Input() route: any;

  public taskID: any;
  public createroute = [];

  private adminroutes = {
    'admin': '/application',
    'hchome': '/admin/hchome',
    'healthcoach': '/admin/healthcoach/hctasks',
    'hcedit': '/admin/hchome/edit',
    'hcassignhome': '/admin/hcuserassign',
    'codegeneratehome': '/admin/codegenerate',
    'codegenerate': '/admin/createcodegen/edit',
    'viewcode': '/admin/viewallactivation',
    'clienthome': '/admin/client',
    'clientedit': '/admin/client/edit',
    'campaignhome': '/admin/campaign',
    'campaignedit': '/admin/campaign/campaignedit',
    'userhome': '/admin/users',
    'useredit': '/admin/users/edit',
    'doctorhome': '/admin/doctors',
    'doctoredit': '/admin/doctors/edit',
    'hardsetotphome': '/admin/hardsetotp',
    'app-master': '/admin/app-master',
    'hardsetotpedit': '/admin/hardsetotp/edit',
    'publishrequests': '/admin/publishrequests',
    'activationsearch': '/admin/activationsearch',
    'patients': '/admin/patients',
    'payment': '/admin/payment',
    'paymentedit': '/admin/paymentedit/editpayment',
    'paymentmapping': '/admin/paymentmapping',
    'paymentmappingedit': '/admin/paymentlist/edit',
    'tasklibrary': '/admin/tasklibrary',
    'taskedit': '/admin/taskedit',
    'assigntask': '/admin/task/assignedtask',
    'taskadmin': '/admin/taskadmin',
    'requestlist': '/admin/taskrequest',
    'requestapprove': '/admin/requestdetails',
    'taskId': '/admin/requestdetails/requestapprove',
    'mobilechange': '/admin/mobilechange',
    'apilogs': '/admin/apilogs',
  }

  private therapyroutes = {
    'content': '/application',
    'careyhome': '/content/careycard',
    'careyedit': '/content/careycard/edit',
    'chapterhome': '/content/chapter',
    'chapteredit': '/content/chapter/edit',
    'lessonhome': '/content/lesson',
    'lessonedit': '/content/lesson/edit',
    'modulehome': '/content/module',
    'moduleedit': '/content/module/edit',
    'therapyhome': '/content/therapy',
    'therapyedit': '/content/therapy/edit',
    'therapyleveledit': '/content/therapy/editlevels',
    'custombranding': '/content/therapy/custombranding',
    'quizhome': '/content/quiz',
    'quizedit': '/content/quiz/edit',
    'questionhome': '/content/question',
    'questionedit': '/content/question/edit',
    'magazinecollectionhome': '/content/magazinecollection',
    'magazinecollectionedit': '/content/magazinecollection/edit',
    'magazinehome': '/content/magazine',
    'magazineedit': '/content/magazine/edit',
    'feedbackhome': '/content/feedback',
    'feedbackedit': '/content/feedback/edit',
    'taghome': '/content/tag',
    'tagedit': '/content/tag/edit',
    'intenthome': '/content/intent',
    'intentedit': '/content/intent/edit',
    // 'onboardinghome' : '/content/onboarding',
    // 'onboardingedit' : '/content/onboardingedit',
    'questionnairmappinghome': '/content/questionnairmapping',
    'questionnairmappingedit': '/content/questionnairmapping/edit',
    'triggershome': '/content/triggers',
    'triggersedit': '/content/triggers/edit',    
    'triggersCreate': '/content/triggers/Create',
    'medicinehome': '/content/medicine',
    'medicineedit': '/content/medicine/edit',
    'levelhome': '/content/level',
    'leveledit': '/content/level/edit',

    'faqcategoryhome': '/content/faq/category',
    'faqcategoryedit': '/content/faq/category/edit',
    'faqqahome': '/content/faq/question_answers',
    'faqqaedit': '/content/faq/question_answers/edit',
    'logginghome': '/content/logging',
    'loggingedit': '/content/logging/edit',//'/content/logging/mapping/create',
    'loggingcreate':  '/content/logging/create',
    'questionnairhome': '/content/questionnair',
    'questionnairedit': '/content/questionnair/edit',
    'questionnaircreate': '/content/questionnair/create',
    'symptomhome': '/content/symptom',
    'symptomedit': '/content/symptom/edit',
    'symptomCreate': '/content/symptom/create',
    'mealhome': '/content/meal',
    'mealedit': '/content/meal/edit',    
    'mealCreate': '/content/meal/Create',
    'uuxid': '/content/uuxid',
    'staticLinks': '/content/staticLinks',
    'dynamicLinks':'/content/dynamicLinks',
    'conditionlist': '/content/conditionlist',
    'conditionedit': '/content/conditionlist/edit',
    'magazinecategoryhome': '/content/magazinecategory',
    'magazinecategoryhomeedit': '/content/magazinecategory/edit',
    'medicationhome': '/content/medication',    
    'medicationedit': '/content/medication/edit',
    'medicationCreate': '/content/medication/create',
    'healthgoalhome': '/content/healthgoalList',
    'healthgoaledit': '/content/healthgoalList/edit',
    'healthgoalCreate': '/content/healthgoalList/create',
    'pumphome': '/content/pump',
    'pumpedit': '/content/pump/edit',
    'pumpCreate': '/content/pump/Create',

    'media': '/content/media',
    'notification': '/content/notification',

    'triggerhome': '/content/triggerhome',
    'triggerhomeEit': '/content/triggerhome/edit',    
    'triggerhomeCreate': '/content/triggerhome/Create',
  }

  private hcroutes = {
    'healthcoach': '/application',
    'hctasklist': '/healthcoach/hctasks',
    'task': '/healthcoach/hctasks/hctaskexecute',
    'hctaskmanual': '/healthcoach/hctasks/hctaskmanual',
     'taskID': this.getID(),
    'policies': '/healthcoach/policies',
  }

  // Used for display title 
  private namingConvention = {

    // Admin Dashboard
    "admin": localStorage.getItem("app").toUpperCase(),
    "hchome": "Healthcoach",
    "hcedit": "Healthcoach Update",
    "hcassignhome": "HC Assign",
    "codegeneratehome": "Activation code",
    "codegenerate": "Generate Code",
    "viewcode": "View Code",
    "clienthome": "Client",
    "clientedit": this.getclientname(),
    "campaignhome": "Campaign",
    "campaignedit": "Campaign Update",
    "userhome": "Scarlet user",
    "useredit": "User Update",
    "doctorhome": "Doctor",
    "doctoredit": "Doctor Update",
    'hardsetotphome': "Hardset OTP",
    'app-master':'App actions',
    "hardsetotpedit": "Hardset OTP Update",
    "publishrequests": "Publish Request",
    "patients": "Patients",
    "mobilechange": "Change Mobile Number Requests",
    "apilogs": "Api Logs",

    // therapy dashboard
    "content": "Content",
    "careyhome": "Carey Card",
    "careyedit": "Carey Card Update",
    "chapterhome": "Chapter",
    "chapteredit": "Chapter Update",
    "lessonhome": "Lesson",
    "lessonedit": "Lesson Update",
    "modulehome": "Module",
    "moduleedit": "Module Update",
    "therapyhome": "Therapy",
    "therapyedit": "Therapy Update",
    "therapyleveledit": "Therapy Level Update",
    "custombranding": "Therapy Branding Update",
    "quizhome": "Quiz",
    "quizedit": "Quiz Update",
    "questionhome": "Question",
    "questionedit": "Question Update",
    "magazinehome": "Magazine",
    "magazineedit": "Magazine Update",
    "feedbackhome": "Feedback",
    "feedbackedit": "Feedback Update",
    "taghome": "Tag",
    "tagedit": "Tag Update",
    "intenthome": "Intent",
    "intentedit": "Intent Update",
    "questionnairmappinghome": "Questionnaire Mapping",
    "questionnairmappingedit": "Questionnaire Mapping Update",
    "triggershome": "Trigger",
    "triggersedit": " Edit trigger",
    "triggersCreate": " Create new trigger",
    "medicinehome": "Medicine",
    "medicineedit": "Edit Medicine",
    "medicineCreate": "Create new Medicine",
    "levelhome": "Level",
    "leveledit": "Level Update",
    "faqcategoryhome": "FAQ Category",
    "faqcategoryedit": "FAQ Category Update",
    "faqqahome": "FAQ",
    "faqqaedit": "FAQ Update",
    //"logginghome": "Log mapping",
   
    //"loggingedit": "Create new logging flow",
    "triggeredit": "trigger mapping",
    "questionnairhome": "Questionnaire",
    "questionnairedit": "Questionnaire Update",
    "questionnaircreate": "Create new question",

    "logginghome": "Log mapping",
    "loggingedit": "Edit logging flow",
    "loggingcreate": "Create new logging flow",

    "triggerhome": "Trigger Mapping",
    'triggerhomeEit': 'Edit trigger flow',    
    'triggerhomeCreate': 'Create new trigger flow',

    "symptomhome": "Symptom mapping",
    "symptomedit": "Edit Symptom Flow",
    "symptomCreate": "Create new symptom flow",
    "mealhome": "Meal",
    "mealedit": "Edit food item",    
    "mealCreate": "Create new food item",
    "uuxid": "UUXID",
    "staticLinks": "Static Links",
    "dynamicLinks":"Dynamic  Links",
    
    "conditionlist": "Conditions",
    "conditionedit": "Edit conditions",
    'activationsearch': 'Activation Search',
    'payment': 'Payment List',
    'paymentedit': 'Payment Update',
    'paymentmapping': 'Payment Mapping',
    'paymentmappingedit': 'Payment Mapping Update',
    "magazinecategoryhome": "Magazine Category",
    "magazinecategoryhomeedit": "Magazine Category Update",
    "medicationhome": "Medication",
    "medicationedit": "Edit medication",
    "medicationCreate": "Create new medication",
    "healthgoaledit": "Edit health goal",
    "healthgoalCreate": "Create new health goal",
    
    "healthgoalhome": "Health goals",
    "pumphome": "Pump",
    "pumpedit": "Edit Pump",
    "pumpCreate": "create new pump",
    
    'tasklibrary': 'Library',
    'taskeditID': this.getTaskID2(),
    'taskId': this.getTaskID1(),
    'assigntask': 'Health Coach',
    'taskadmin': 'Admin',

    // HC Routes
    'healthcoach': 'Health Coach',
    'hctasklist': 'Task',
    'hctaskmanual': this.taskCheck(),//'hctaskmanual'
    'requestlist': 'Requests',
    'requestApprove': 'Feedback',
    'task': this.getTaskID(),
    'taskID':this.taskCheck1(),//'Execute'
    'hcManualExecute': 'Execute',
    'Complete':'Complete',
    'policies': 'Policy Details',

    // Doctor Dashboard
    "doctor": "Doctor",
    "user": "Patients",
    "doctorHome": "Home",

    // Media 
    'media': 'Media',
    'notification': 'Notification'
  }

  private doctorRoutes = {
    "doctor": "/doctor",
    "user": "/user",
    "doctorHome": "/home"
  }

  constructor(public commonService: CommonserviceService) { }

  ngOnInit(): void {
    let routesplit = this.route.split("/");
    
    switch (routesplit[0]) {
      case 'admin':
        this.showroutes(this.adminroutes, routesplit)
        break;
      case 'content':
        this.showroutes(this.therapyroutes, routesplit)
        break;
      case 'healthcoach':
        this.showroutes(this.hcroutes, routesplit)
        break;
      case 'hctasklist':
        this.showroutes(this.hcroutes, routesplit)
        break;
      case 'doctor':
        this.showroutes(this.doctorRoutes, routesplit);
        break;
      default:
        break
    }
    //   var taskId = this.commonService.currentMessage.subscribe(data => {
    //     this.taskID =  data;
    //     this.getTaskID();
    // });
  }

  showroutes(routes, inputroutes) {
    
    inputroutes.forEach(el => {
      this.createroute.push({
        // "title": el == 'admin' ? '<span class="material-icons">home</span>' : this.namingConvention[el],
        "route": routes[el],
        "title": this.namingConvention[el]
      })
    });

  }

  // localStorage.setItem('newTaskID', e.task_library_id)
  getID() {
    let displayBtn = localStorage.getItem('hcSelectedType')
    if ((displayBtn=='broadcast'|| displayBtn == 'chat' || displayBtn == 'custom_broadcast' || displayBtn == 'whatsapp_chat')){
      var taskID = localStorage.getItem('newTaskID');
      return '/healthcoach/hctasks/hctaskexecute/'+taskID;
    }else{
      return '/healthcoach/hctasks/hctaskmanual';
    }
    
  }
  getTaskID() {
    var taskID = localStorage.getItem('newTaskID');
    return "WTM000" + taskID;
  }

  getTaskID2() {
    var taskID = localStorage.getItem('newTaskID');
    return "WTM000" + taskID;
  }
  getTaskID1() {
    var taskID = localStorage.getItem('task_display_id');
    if (taskID) {
      return taskID.replace(/['"]+/g, '');
    }
  }
  taskCheck() {
    let displayBtn = localStorage.getItem('hcSelectedType')
    if ((displayBtn == 'chat' || displayBtn == 'custom_broadcast' || displayBtn == 'whatsapp_chat')) {
      return 'Create Template';
    }
    else {
      return "Execute";
    }
  }
  getclientname(){
    return localStorage.getItem('clientname');
  }
  
  taskCheck1() {
    let displayBtn = localStorage.getItem('hcSelectedType')
    if ((displayBtn == 'chat' || displayBtn == 'custom_broadcast' || displayBtn == 'whatsapp_chat')) {
      return "Create Template ";
    }
    else {
      return "Execute";
    }
  }
}