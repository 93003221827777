import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../shared/shared.module';
//import { CareycardComponent } from './careycard/careycard.component';
//import { EditcareycardComponent } from './careycard/editcareycard/editcareycard.component';
//import { ChapterComponent } from './chapter/chapter.component';
//import { EditchapterComponent } from './chapter/editchapter/editchapter.component';
//import { ConditionComponent } from './condition/condition/condition.component';
//import { EditConditionComponent } from './condition/condition/edit-condition/edit-condition.component';
import { ContentRoutingModule } from './content-routing.module';
//import { CprofileComponent } from './cprofile/cprofile.component';
//import { DashboardComponent } from './dashboard/dashboard.component';
//import { CategoryComponent } from './faq/category/category.component';
//import { EditcategoryComponent } from './faq/category/editcategory/editcategory.component';
//import { EditquestionanswerComponent } from './faq/questionanswers/editquestionanswer/editquestionanswer.component';
//import { QuestionanswersComponent } from './faq/questionanswers/questionanswers.component';
//import { EditfeedbackComponent } from './feedback/editfeedback/editfeedback.component';
//import { FeedbackComponent } from './feedback/feedback.component';
import { ViewlottieComponent } from './feedback/viewlottie/viewlottie.component';
//import { FeedbackviewerComponent } from './feedbackviewer/feedbackviewer.component';
//import { FeedbackviewertableComponent } from './feedbackviewer/feedbackviewertable/feedbackviewertable.component';
//import { EditintentComponent } from './intent/editintent/editintent.component';
//import { IntentComponent } from './intent/intent.component';
import { EditlanguageComponent } from './language/editlanguage/editlanguage.component';
import { LanguageComponent } from './language/language.component';
//import { EditlessonComponent } from './lesson/editlesson/editlesson.component';
//import { LessonComponent } from './lesson/lesson.component';
//import { CreateRequestComponent } from './level/create-request/create-request.component';
//import { EditlevelComponent } from './level/editlevel/editlevel.component';
//import { LevelComponent } from './level/level.component';
//import { LeveldifferenceComponent } from './level/leveldifference/leveldifference.component';
//import { CreateloggingmapComponent } from './logging/createloggingmap/createloggingmap.component';
//import { ViewloggingComponent } from './logging/viewlogging/viewlogging.component';
//import { ViewloggingmapComponent } from './logging/viewloggingmap/viewloggingmap.component';
//import { EditmagazineComponent } from './magazine/editmagazine/editmagazine.component';
//import { MagazineComponent } from './magazine/magazine.component';
//import { EditmagazinecollectionComponent } from './magazinecollection/editmagazinecollection/editmagazinecollection.component';
//import { MagazinecollectionComponent } from './magazinecollection/magazinecollection.component';
//import { EditmealComponent } from './meal/editmeal/editmeal.component';
//import { MealComponent } from './meal/meal.component';
import { ChoosemediamodalComponent } from './mediamanager/choosemediamodal/choosemediamodal.component';
//import { MediamanagerComponent } from './mediamanager/mediamanager.component';
import { MediainformationComponent } from './mediamanager/uploadmedia/mediainformation/mediainformation.component';
//import { UploadmediaComponent } from './mediamanager/uploadmedia/uploadmedia.component';
//import { ViewmediaComponent } from './mediamanager/viewmedia/viewmedia.component';
//import { MedicineComponent } from './medicine/medicine.component';
//import { MedicineaddComponent } from './medicine/medicineadd/medicineadd.component';
//import { EditmoduleComponent } from './module/editmodule/editmodule.component';
//import { ModuleComponent } from './module/module.component';
//import { EditquestionComponent } from './question/editquestion/editquestion.component';
//import { QuestionComponent } from './question/question.component';
//import { CreatequestionnairComponent } from './questionnair/createquestionnair/createquestionnair.component';
//import { CreatequestionnairmappingComponent } from './questionnair/createquestionnairmapping/createquestionnairmapping.component';
//import { QuestionnairComponent } from './questionnair/questionnair/questionnair.component';
//import { QuestionnairmappingComponent } from './questionnair/questionnairmapping/questionnairmapping.component';
//import { EditquizComponent } from './quiz/editquiz/editquiz.component';
//import { QuizComponent } from './quiz/quiz.component';
//import { ContentleftnavComponent } from './shared/contentleftnav/contentleftnav.component';
//import { ContentnavbarComponent } from './shared/contentnavbar/contentnavbar.component';
import { DeletemodalComponent } from './shared/deletemodal/deletemodal.component';
import { EditorinstructionsComponent } from './shared/editorinstructions/editorinstructions.component';
//import { MagazinecategoryComponent } from './magazinecategory/magazinecategory.component';
//import { EditmagazinecategoryComponent } from './magazinecategory/editmagazinecategory/editmagazinecategory.component';

//import { FaqleftnavComponent } from './shared/faqleftnav/faqleftnav.component';
//import { NutritionleftnavComponent } from './shared/nutritionleftnav/nutritionleftnav.component';
//import { TherapysetupleftnavComponent } from './shared/therapysetupleftnav/therapysetupleftnav.component';
//import { UuxidleftnavComponent } from './shared/uuxidleftnav/uuxidleftnav.component';
//import { StaticUuxidComponent } from './static-uuxid/static-uuxid.component';
//import { CreatesymptommapComponent } from './symptoms/createsymptommap/createsymptommap.component';
//import { ViewsymptommapComponent } from './symptoms/viewsymptommap.component';
//import { EdittagComponent } from './tag/edittag/edittag.component';
//import { TagComponent } from './tag/tag.component';
//import { EditlevelsComponent } from './therapydesign/editlevels/editlevels.component';
//import { EdittherapydesignComponent } from './therapydesign/edittherapydesign/edittherapydesign.component';
//import { TherapydesignComponent } from './therapydesign/therapydesign.component';
//import { ViewactivationcodeComponent } from './therapysetup/activationcode/viewactivationcode/viewactivationcode.component';
//import { ConditionsComponent } from './therapysetup/conditions/conditions.component';
//import { EditconditionsComponent } from './therapysetup/conditions/editconditions/editconditions.component';
//import { EditonboardingcontentComponent } from './therapysetup/onboardingcontent/editonboardingcontent/editonboardingcontent.component';
//import { OnboardingcontentComponent } from './therapysetup/onboardingcontent/onboardingcontent.component';
//import { CreateonboardingflowComponent } from './therapysetup/onboardingflow/createonboardingflow/createonboardingflow.component';
//import { OnboardingflowComponent } from './therapysetup/onboardingflow/onboardingflow.component';
//import { EdittriggerComponent } from './triggers/edittrigger/edittrigger.component';
//import { TriggersmappingComponent } from './triggers/triggersmapping/triggersmapping.component';
//import { CreatetriggermappingComponent } from './triggers/triggersmapping/createtriggermapping/createtriggermapping.component';
//import { TriggersComponent } from './triggers/triggers.component';
//import { UuxidComponent } from './uuxid/uuxid.component';
import { CareycardRoutingModule } from './careycard/careycard.routing.module';
import { ChapterRoutingModule } from './chapter/chapter.routing.module';
import { ConditionRoutingModule } from './condition/condition.routing.module';
import { LessonRoutingModule } from './lesson/lesson.routing.module';
import { LevelRoutingModule } from './level/level.routing.module';
import { DashboardRoutingModule } from './dashboard/dashboard.routing.module';
import { CprofileRoutingModule } from './cprofile/cprofile.routing.module';
import { CategoryrRoutingModule } from './faq/category/category.routing.module';
import { QuestionanswersRoutingModule } from './faq/questionanswers/questionanswers.routing.module';
import { FeedbackRoutingModule } from './feedback/feedback.routing.module';
import { FeedbackviewerRoutingModule } from './feedbackviewer/feedbackviewer.routing.module';
import { IntentRoutingModule } from './intent/intent.routing.module';
import { MagazineRoutingModule } from './magazine/magazine.routing.module';
import { MagazinecollectionRoutingModule } from './magazinecollection/magazinecollection.routing.module';
import { MealRoutingModule } from './meal/meal.routing.module';
import { MediamanagerRoutingModule } from './mediamanager/mediamanager.routing.module';
import { QuestionRoutingModule } from './question/question.routing.module';
import { MedicineRoutingModule } from './medicine/medicine.routing.module';
import { ModuleRoutingModule } from './module/module.routing.module';
import { CreatequestionnairmappingRoutingModule } from './questionnair/createquestionnairmapping/createquestionnairmapping.routing.module';
import { CreatequestionnairRoutingModule } from './questionnair/createquestionnair/createquestionnair.routing.module';
import { QuestionnairRoutingModule } from './questionnair/questionnair/questionnair.routing.module';
import { QuestionnairmappingRoutingModule } from './questionnair/questionnairmapping/questionnairmapping.routing.module';
import { SymptomsRoutingModule } from './symptoms/symptoms.routing.module';
import { QuizRoutingModule } from './quiz/quiz.routing.module';
import { TagRoutingModule } from './tag/tag.routing.module';
import { TherapydesignRoutingModule } from './therapydesign/therapydesign.routing.module';
import { TriggersRoutingModule } from './triggers/triggers.routing.module';
import { UuxidRoutingModule } from './uuxid/uuxid.routing.module';
import { OnboardingflowRoutingModule } from './therapysetup/onboardingflow/onboardingflow.routing.module';
import { OnboardingcontentRoutingModule } from './therapysetup/onboardingcontent/onboardingcontent.routing.module';
import { ViewactivationcodeRoutingModule } from './/therapysetup/activationcode/viewactivationcode/viewactivationcode.routing.module';
import { StaticUuxidRoutingModule } from './static-uuxid/static-uuxid.routing.module';
import { CreateloggingmapRoutingModule } from './logging/createloggingmap/createloggingmap.routing.module';
import { ViewloggingRoutingModule } from './logging/viewlogging/viewlogging.routing.module';
import { ViewloggingmapRoutingModule } from './logging/viewloggingmap/viewloggingmap.routing.module';
import { MagazinecategoryRoutingModule } from './magazinecategory/magazinecategory.routing.module';
import { MedicationRoutingModule } from './medication/medication.routing.module';
import { PumpRoutingModule } from './pump/pump.routing.module';
import { HealthgoalComponent } from './healthgoal/healthgoal.component';
import { HealthgoalRoutingModule } from './healthgoal/healthgoal.routing.module';
import { AleartmodelComponent } from './shared/aleartmodel/aleartmodel.component';
import { PatientEditModelComponent } from './shared/patient-edit-model/patient-edit-model.component';
import { MediaSetupleftNavComponent } from './shared/media-setupleft-nav/media-setupleft-nav.component';
import { MediaSetupleftNavModule } from './shared/media-setupleft-nav/media-setupleft-nav.module';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';

//import { ConditionsRoutingModule } from './therapysetup/conditions/conditions.routing.module';

//import { LanguageRoutingModule } from './language/language.routing.module';


@NgModule({
  declarations: [
    //MediainformationComponent,
   // DashboardComponent,
    //ContentleftnavComponent,
    //ContentnavbarComponent,
    //TherapydesignComponent,
    //ChapterComponent,
    //LessonComponent,
    ///QuizComponent,
    //CareycardComponent,
   // MagazineComponent,
    //EditcareycardComponent,
    //EditchapterComponent,
    //EditlessonComponent,
    //EditmagazineComponent,
    //EditquizComponent,
    //EdittherapydesignComponent,
    ViewlottieComponent,
    //ModuleComponent,
    //EditmoduleComponent,
    //EditlevelsComponent,
    //LevelComponent,
    //EditlevelComponent,
    //EditfeedbackComponent,
    //FeedbackComponent,
    //MediamanagerComponent,
    //UploadmediaComponent,
    //NutritionleftnavComponent,
    //QuestionComponent,
    //EditquestionComponent,
    //TagComponent,
    //EdittagComponent,
    //ViewmediaComponent,
    ChoosemediamodalComponent,
    LanguageComponent,
    EditlanguageComponent,
    //IntentComponent,
    //EditintentComponent,
    //TherapysetupleftnavComponent,
    //OnboardingflowComponent,
    //CreateonboardingflowComponent,
    //ConditionsComponent,
    //EditconditionsComponent,
    //ViewactivationcodeComponent,
    //CprofileComponent,
    DeletemodalComponent,
    //FeedbackviewerComponent,
    //FeedbackviewertableComponent,
  //  OnboardingcontentComponent,
   // EditonboardingcontentComponent,
    //MagazinecollectionComponent,
    //EditmagazinecollectionComponent,
    EditorinstructionsComponent,
    AleartmodelComponent,
    PatientEditModelComponent,
    ConfirmationModalComponent,
    //MagazinecategoryComponent,
    //EditmagazinecategoryComponent

    //FaqleftnavComponent,
    //CategoryComponent,
    //QuestionanswersComponent,
    //EditcategoryComponent,
    //EditquestionanswerComponent,
   // CreatequestionnairComponent,
    //QuestionnairComponent,
    //QuestionnairmappingComponent,
    //CreatequestionnairmappingComponent,
    //ViewloggingComponent,
    //CreateloggingmapComponent,
    //ViewloggingmapComponent,
   // CreatesymptommapComponent,
    //ViewsymptommapComponent,
    //TriggersComponent,
    //EdittriggerComponent,
    //MedicineComponent,
    //MedicineaddComponent,
    //MealComponent,
    //EditmealComponent,
    //LeveldifferenceComponent,
    //CreateRequestComponent,
    //UuxidComponent,
    //UuxidleftnavComponent,
    //StaticUuxidComponent,
   // ConditionComponent,
   // EditConditionComponent,
    //TriggersmappingComponent,
    //CreatetriggermappingComponent

  ],
  imports: [
    CommonModule,
    ContentRoutingModule,
    CareycardRoutingModule,
    ChapterRoutingModule,
    ConditionRoutingModule,
    LessonRoutingModule,
    LevelRoutingModule,
    DashboardRoutingModule,
    CprofileRoutingModule,
    CategoryrRoutingModule,
    QuestionanswersRoutingModule,
    FeedbackRoutingModule,
    FeedbackviewerRoutingModule,
    IntentRoutingModule,
   // LanguageRoutingModule,
    MagazineRoutingModule,
    MagazinecollectionRoutingModule,
    MealRoutingModule,
    MediamanagerRoutingModule,
    QuestionRoutingModule,
    MedicineRoutingModule,
    ModuleRoutingModule,
    CreatequestionnairmappingRoutingModule,
    CreatequestionnairRoutingModule,
    QuestionnairRoutingModule,
    QuestionnairmappingRoutingModule,
    SymptomsRoutingModule,
    QuizRoutingModule,
    TagRoutingModule,
    TherapydesignRoutingModule,
    TriggersRoutingModule,
    UuxidRoutingModule,
    OnboardingflowRoutingModule,
    OnboardingcontentRoutingModule,
    ViewactivationcodeRoutingModule,
    StaticUuxidRoutingModule,
    CreateloggingmapRoutingModule,
    ViewloggingRoutingModule,
    ViewloggingmapRoutingModule,
    MagazinecategoryRoutingModule,
    MedicationRoutingModule,
    PumpRoutingModule,
    HealthgoalRoutingModule,
    MediaSetupleftNavModule,
   // ConditionsRoutingModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ContentModule { }
