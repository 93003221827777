<div class="col-md-12">

  <div *ngIf="historyType === 'password'">
    <h2 style="text-align: center;" class="commontitleprimary1 text-capitalize" *ngIf="UserType=='doctor'">Doctor
      password Change History </h2>
    <h2 style="text-align: center;" class="commontitleprimary1 text-capitalize" *ngIf="UserType=='Scarlet User'">Scarlet
      User password Change History </h2>
    <h2 style="text-align: center;" class="commontitleprimary1 text-capitalize" *ngIf="UserType=='HealthCoach'">Health
      Coach password Change History </h2>
  </div>
  
  <!-- ------- -->

  <div *ngIf="historyType === 'email'">
    <h2 style="text-align: center;" class="commontitleprimary1 text-capitalize" >Doctor Password Reset Email History </h2>
  </div>
  <br />
  <h2 class="commontitleprimary text-capitalize">
    <span style="color:var(--primary-orange);">{{userName}}</span>
  </h2>
  <div class="close-icon">
    <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()">
  </div>

  <div class="container-table" *ngIf="tabulardata.length != 0 && historyType === 'password'" infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50" [scrollWindow]="false">
    <div class="spinner-container" *ngIf="showloader">
      <app-loader showLoader="{{true}}"></app-loader>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="widthfluid bordergrey list-table">

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id </th>
        <td mat-cell *matCellDef="let element">{{element.source_user_id}} </td>
      </ng-container>

      <ng-container matColumnDef="changedBy">
        <th mat-header-cell *matHeaderCellDef> Changed By </th>
        <td mat-cell *matCellDef="let element"> {{element.source_user_name}} </td>
      </ng-container>

      <ng-container matColumnDef="changedVia">
        <th mat-header-cell *matHeaderCellDef> Changed Using </th>
        <td mat-cell *matCellDef="let element"> {{element.password_changed_via}} </td>
      </ng-container>
  

      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef> Change Password Date </th>
        <td mat-cell *matCellDef="let element"> {{convertDate(element.updated_at)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;">
      </tr>

    </table>
  </div>

  <!-- ------ -->

  <div class="container-table" *ngIf="tabulardata.length != 0 && historyType === 'email'" infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50" [scrollWindow]="false">
    <div class="spinner-container" *ngIf="showloader">
      <app-loader showLoader="{{true}}"></app-loader>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="widthfluid bordergrey list-table">

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id </th>
        <td mat-cell *matCellDef="let element">{{element.user_id_data_fk}} </td>
      </ng-container>

      <ng-container matColumnDef="uuid">
        <th mat-header-cell *matHeaderCellDef style="text-align: -webkit-center;"> UUID </th>
        <td mat-cell *matCellDef="let element"> {{element.uuid}} </td>
      </ng-container>

      <ng-container matColumnDef="is_used">
        <th mat-header-cell *matHeaderCellDef> Is used </th>
        <td mat-cell *matCellDef="let element"> {{element.is_used}} </td>
      </ng-container>
  
      <ng-container matColumnDef="CreatedDate">
        <th mat-header-cell *matHeaderCellDef> Created at </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at |  date:'short'}} </td>
      </ng-container>

      <ng-container matColumnDef="validtill">
        <th mat-header-cell *matHeaderCellDef> Valid till </th>
        <td mat-cell *matCellDef="let element"> {{element.valid_till | date:'short'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="emaildisplayedColumns;sticky:true"></tr>
      <tr mat-row *matRowDef="let element; columns: emaildisplayedColumns;">
      </tr>

    </table>
  </div>

  <!-- ------ -->
  
  <div class="" *ngIf="tabulardata.length == 0">
    <p class="center" *ngIf="historyType === 'password'">No password change history found for this user.</p>
    <p class="center" *ngIf="historyType === 'email'">No Password Reset Email History for this user.</p>
  </div>
</div>