
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { ChangehealthcoachComponent } from './changehealthcoach/changehealthcoach.component';
import { TransferlistdataComponent } from './transferlistdata/transferlistdata.component';
import { ViewuserassignComponent } from './viewuserassign/viewuserassign.component';

@Component({
  selector: 'app-hcuserassign',
  templateUrl: './hcuserassign.component.html',
  styleUrls: ['./hcuserassign.component.scss'],

})

export class HcuserassignComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'phone','activationCode', 'conditions', 'healthcoach', 'action'];
   public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public status = 'active';
  constructor(
    public dialog: MatDialog,
    public commonService: CommonserviceService,
    private systemService: SystemService,
    private browserTitle: Title
  ) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Health Coach User Assign');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = '';
    this.page = 1;
    this.tabulardata = [];
    this.search();
  }

  typeFilter(fln) {
    this.filteredtypes = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    } else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(
        this.filteredtabulardata
      );
    }
    function checkTags(t) {
      if (
        t.doctor_type.split(',').filter(arr1Item => fln.includes(arr1Item))
          .length > 0
      ) {
        return true;
      }
    }
  }

  //  Patients List
  // ----------------------------

  search() {
    
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllPatients(this.searchcriteria, this.page.toString(), '20', this.status, 'hcassignment')
        .subscribe(
          (res: any) => this.onPatientListSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onPatientListSuccess(data) {
    
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;

      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------

  viewHistory(element): void {
    const history = this.dialog.open(TransferlistdataComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    history.afterClosed().subscribe(result => {
    });
  }


  viewProfile(element): void {
    const profile = this.dialog.open(ViewuserassignComponent, {
      width: '900px',
      data: {
        element: element
      }

      // data: {
      //   id: element.id,
      //   code: element.code,
      //   name: element.name
      // }
    });

    profile.afterClosed().subscribe(result => {
    });

  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = '';
    this.search();
  }

  livesearch() {
    
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  getNextSet() {
    
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  changeHealthCoach(element): void {
    const profile = this.dialog.open(ChangehealthcoachComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
      this.lastpage = 0;
      this.page = 1;
      this.tabulardata = [];
      this.search();
    });
  }
}

export interface PeriodicElement {
  name: string;
  conditions: string;
  healthcoach: string;
  phone: number;
}
