import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { MediainformationComponent } from './mediainformation/mediainformation.component';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';

@Component({
  selector: 'app-uploadmedia',
  templateUrl: './uploadmedia.component.html',
  styleUrls: ['./uploadmedia.component.scss']
})

export class UploadmediaComponent implements OnInit {

  private allfiles: any;
  private responseUrl: any;
  private responsethumbUrl: any;
  thumbresponseUrl: any;
  private postuploads3response: any;
  private mediainfoopen: any;
  private resdata: any;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public isinvalid: boolean = false;
  public isloading: boolean = false;
  public loadingval: any = 5;
  public uploadedfiletype: any;
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'mp3', 'wav', 'm4a', 'mp4', 'mov', 'webm', 'avi', 'mpeg'];
  public imageFileSize: any;
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  mediaUploadsize: any;

  constructor(
    private browserTitle: Title, 
    public router: Router, 
    private contentService: ContentserviceService, 
    private systemService: SystemService, 
    public dialog: MatDialog,
    private commonService:CommonserviceService
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Media');
  }

  ngOnInit() {
    this.setSEOTags();
    this.mediaUploadsize = JSON.parse(JSON.parse(localStorage.getItem('mediaUploadSize')));

  }

  uploadObject() {
    this.demoForm.value.files.forEach(el => {
      if (((el.size / (1024 * 1024)) > this.mediaUploadsize.video) && (el.type.includes('video'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.image) && (el.type.includes('image'))) {
        this.isinvalid = true;
      }
      else {
        this.isinvalid = false;
      }
    });

    if (this.isinvalid != true) {
      if (this.demoForm.status == 'INVALID') {
        this.isinvalid = true;
      }
      else {
        this.isinvalid = false;
        this.allfiles = this.demoForm.value.files;
        this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];

        if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
          this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
        }
        else {
          this.mediainfoopen = this.dialog.open(MediainformationComponent, {
            width: '700px',
            maxHeight: '600px',
            data: {
              image: this.allfiles
            }
          }
          );

          this.mediainfoopen.afterClosed().subscribe((imageinfo) => {
            this.isloading = true;
            var scope = this;
            var last = false;
            var imagedetails;
            var loadingvalcalculation = (100 / (this.allfiles.length * 3));
            processfiles(0);
            function processfiles(i) {
              if (imageinfo && last == false) {
                imagedetails = "";
                imagedetails = imageinfo.value[i];
                var path = 'healthcoach/media';

                var newfile = null;
                var newname = (+ new Date()) + localStorage.getItem('scarletuserid') + '.' + scope.allfiles[i].name.split('.').pop()
                newfile = new File([scope.allfiles[i]], newname);
                let isItRichmedia = localStorage.getItem('richMediaPage');
                if(isItRichmedia === '0'){
                  scope.contentService.getS3URL(path, scope.allfiles[i].type, scope.allfiles[i].size, newfile.name)
                  .subscribe(
                    (data) => {
                      scope.responseUrl = data;
                      scope.imageFileSize = scope.allfiles[i].size;
                      scope.loadingval = scope.loadingval + loadingvalcalculation;
                      scope.contentService.uploadToS3(newfile, scope.responseUrl.data.signed_request)
                        .subscribe(
                          (data) => {
                            scope.loadingval = scope.loadingval + loadingvalcalculation;
                            
                            if ((i + 1) == scope.allfiles.length) {
                              last = true;
                            }
                            scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, last, data => {
                              if (data) {
                                scope.loadingval = scope.loadingval + loadingvalcalculation;
                                if (i + 1 == scope.allfiles.length) {
                                  scope.isloading = false;
                                  scope.systemService.showSuccessSnackBar(imagedetails.title + " is uploaded and saved", "Ok");
                                  scope.router.navigate(['content/media']);
                                }
                                else {
                                  processfiles(i + 1)
                                }
                              }
                              else {
                                processfiles(i + 1)
                              }
                            });
                          }, (error) => {
                            scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                          })
                    })
                }else if(isItRichmedia === '1'){
                  scope.contentService.getRichMediaS3URL(path, scope.allfiles[i].type, scope.allfiles[i].size, newfile.name)
                  .subscribe(
                    (data) => {
                      scope.responseUrl = data;
                      scope.imageFileSize = scope.allfiles[i].size;
                      scope.loadingval = scope.loadingval + loadingvalcalculation;
                      scope.commonService.RichMediauploadToS3(newfile, scope.responseUrl.data.signed_request)
                        .subscribe(
                          (data) => {
                            scope.loadingval = scope.loadingval + loadingvalcalculation;
                            
                            if ((i + 1) == scope.allfiles.length) {
                              last = true;
                            }
                            scope.uploadRichmediathumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, last, data => {
                              if (data) {
                                scope.loadingval = scope.loadingval + loadingvalcalculation;
                                if (i + 1 == scope.allfiles.length) {
                                  scope.isloading = false;
                                  scope.systemService.showSuccessSnackBar(imagedetails.title + " is uploaded and saved", "Ok");
                                  scope.router.navigate(['content/notification']);
                                }
                                else {
                                  processfiles(i + 1)
                                }
                              }
                              else {
                                processfiles(i + 1)
                              }
                            });
                          }, (error) => {
                            scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                          })
                    })
                }else{
                  scope.contentService.getS3URL(path, scope.allfiles[i].type, scope.allfiles[i].size, newfile.name)
                  .subscribe(
                    (data) => {
                      scope.responseUrl = data;
                      scope.imageFileSize = scope.allfiles[i].size;
                      scope.loadingval = scope.loadingval + loadingvalcalculation;
                      scope.commonService.uploadToS3(newfile, scope.responseUrl.data.signed_request)
                        .subscribe(
                          (data) => {
                            scope.loadingval = scope.loadingval + loadingvalcalculation;
                            
                            if ((i + 1) == scope.allfiles.length) {
                              last = true;
                            }
                            scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, last, data => {
                              if (data) {
                                scope.loadingval = scope.loadingval + loadingvalcalculation;
                                if (i + 1 == scope.allfiles.length) {
                                  scope.isloading = false;
                                  scope.systemService.showSuccessSnackBar(imagedetails.title + " is uploaded and saved", "Ok");
                                  scope.router.navigate(['content/media']);
                                }
                                else {
                                  processfiles(i + 1)
                                }
                              }
                              else {
                                processfiles(i + 1)
                              }
                            });
                          }, (error) => {
                            scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                          })
                    })
                }
             
              }
            }
            if (!imageinfo) {
              this.isloading = false;
            }
          });
        }
      }
    }
    else {
    }
  }


  uploadthumbnail(path, ele, imageinfo, last, cb) {
    var scope = this;
    
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          var thpath = 'healthcoach/thumbnail';
          scope.contentService.getS3URL(thpath, thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.contentService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo, last);
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }

      }
      fileReader.readAsDataURL(ele);
    }
    else if (ele.type.match('video')) {
      fileReader.onload = function () {
        var blob = new Blob([fileReader.result], { type: ele.type });
        var url = URL.createObjectURL(blob);
        var video = document.createElement('video');
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };

        video.addEventListener('loadeddata', function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });

        var snapImage = function () {
          var canvas = document.createElement('canvas');
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = video.videoWidth;
          var tempH = video.videoHeight;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          canvas.getContext('2d').drawImage(video, 0, 0, tempW, tempH);
          var success = canvas.toDataURL().length > 100;
          if (success) {
            var thtype = "image/jpeg";
            var dataURL = canvas.toDataURL("image/jpeg");
            var myFile: Blob = scope.dataURItoBlob(dataURL);
            var thpath = 'healthcoach/thumbnail';
            var mediatype = ele.type.split("/")[1]
            var res = ele.name.replace('.' + mediatype, ".jpg");
            scope.contentService.getS3URL(thpath, thtype, myFile.size, res)
              .subscribe(
                (data) => {
                  scope.responsethumbUrl = data;
                  scope.contentService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                    .subscribe(
                      (data) => {
                        saveIttoDB(path, myFile.size, ele.type, scope.responsethumbUrl.data.path, imageinfo, last);
                      }, (error) => {
                        scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                      })
                })
          }
          return success;
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // // Load video in Safari / IE11
        video.muted = true;
        // //video.playsInline = true;
        // video.play();
        // cb(true);
        var playPromise = video.play();
        // In browsers that don’t yet support this functionality,
        // playPromise won’t be defined.
        if (playPromise !== undefined) {
          playPromise.then(function () {
            // Automatic playback started!
          }).catch(function (error) {
            // cb(true);
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          });
        }
      };
      fileReader.readAsArrayBuffer(ele);
    }
    else if (ele.type.match('audio')) {
      var newfile = null;
      var newname = (+ new Date()) + localStorage.getItem('scarletparentuserid') + '.' + imageinfo.audio_thumb[0].name.split('.').pop()
      newfile = new File([imageinfo.audio_thumb[0]], newname);

      scope.contentService.getS3URL('user_profile', imageinfo.audio_thumb[0].type, imageinfo.audio_thumb[0].size, newfile.name)
        .subscribe(
          (data) => {
            scope.thumbresponseUrl = data;
            scope.contentService.uploadToS3(newfile, scope.thumbresponseUrl.data.signed_request)
              .subscribe(
                (data) => {
                  var size = this.imageFileSize;
                  var fileReader = new FileReader();
                  fileReader.onloadend = function () {
                    let tempImg = new Image();
                    tempImg.src = fileReader.result as string;
                    tempImg.onload = function () {
                      var MAX_WIDTH = 400;
                      var MAX_HEIGHT = 300;
                      var tempW = tempImg.width;
                      var tempH = tempImg.height;
                      if (tempW > tempH) {
                        if (tempW > MAX_WIDTH) {
                          tempH *= MAX_WIDTH / tempW;
                          tempW = MAX_WIDTH;
                        }
                      } else {
                        if (tempH > MAX_HEIGHT) {
                          tempW *= MAX_HEIGHT / tempH;
                          tempH = MAX_HEIGHT;
                        }
                      }

                      var canvas = document.createElement('canvas');
                      canvas.width = tempW;
                      canvas.height = tempH;
                      var ctx = canvas.getContext("2d");
                      ctx.drawImage(tempImg, 0, 0, tempW, tempH);
                      var thtype = "image/jpeg";
                      var dataURL = canvas.toDataURL("image/jpeg");
                      var myFile: Blob = scope.dataURItoBlob(dataURL);
                      var thpath = 'healthcoach/thumbnail';
                      scope.contentService.getS3URL(thpath, thtype, imageinfo.audio_thumb[0].size, imageinfo.audio_thumb[0].name)
                        .subscribe(
                          (data) => {
                            scope.responsethumbUrl = data;
                            scope.contentService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                              .subscribe(
                                (data) => {
                                  scope.contentService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, imageinfo.type, scope.responsethumbUrl.data.path, '5')
                                    .subscribe(
                                      (data) => {
                                        scope.resdata = data;
                                        if (scope.resdata.status == 201) {
                                        }
                                        else {
                                          scope.isloading = false;
                                          scope.systemService.showSnackBar("Unknown error occured", "Ok");
                                        }
                                        scope.systemService.showSuccessSnackBar(imageinfo.title + " is uploaded and saved", "Ok");
                                        cb(false);
                                      })
                                }, (error) => {
                                  scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                                })
                          })
                    }

                  }
                  fileReader.readAsDataURL(imageinfo.audio_thumb[0]);

                  // function saveaudiotoDB(path, size, type, thumbpath, imageinfo, last) {
                  //   scope.contentService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, imageinfo.type, scope.responsethumbUrl.data.path, '5')
                  //     .subscribe(
                  //       (data) => {
                  //         scope.resdata = data;
                  //         if (scope.resdata.status == 201) {
                  //         }
                  //         else {
                  //           scope.isloading = false;
                  //           scope.systemService.showSnackBar("Unknown error occured", "Ok");
                  //         }
                  //         scope.systemService.showSuccessSnackBar(imageinfo.title + " is uploaded and saved", "Ok");
                  //         cb(false);
                  //       })
                  // }

                }, (error) => {
                  scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                }
              )
          });
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }


    function saveIttoDB(path, size, type, thumbpath, imageinfo, last) {
      scope.contentService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, thumbpath, '5')
        .subscribe(
          (res: any) => {
            scope.resdata = res;
            
            if (scope.resdata.status == 201) {
            }
            else {
              scope.isloading = false;
              scope.router.navigate(['content/media']);
              // scope.systemService.showSnackBar(error.error.message, "OK");
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }

  }

  uploadRichmediathumbnail(path, ele, imageinfo, last, cb) {
    var scope = this;
    
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          var thpath = 'healthcoach/thumbnail';
          scope.contentService.getRichMediaS3URL(thpath, thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.RichMediauploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo, last);
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }

      }
      fileReader.readAsDataURL(ele);
    } else if (ele.type.match('video')) {
      fileReader.onload = function () {
        var blob = new Blob([fileReader.result], { type: ele.type });
        var url = URL.createObjectURL(blob);
        var video = document.createElement('video');
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };

        video.addEventListener('loadeddata', function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });

        var snapImage = function () {
          var canvas = document.createElement('canvas');
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = video.videoWidth;
          var tempH = video.videoHeight;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          canvas.getContext('2d').drawImage(video, 0, 0, tempW, tempH);
          var success = canvas.toDataURL().length > 100;
          if (success) {
            var thtype = "image/jpeg";
            var dataURL = canvas.toDataURL("image/jpeg");
            var myFile: Blob = scope.dataURItoBlob(dataURL);
            var thpath = 'healthcoach/thumbnail';
            var mediatype = ele.type.split("/")[1]
            var res = ele.name.replace('.' + mediatype, ".jpg");
            scope.contentService.getS3URL(thpath, thtype, myFile.size, res)
              .subscribe(
                (data) => {
                  scope.responsethumbUrl = data;
                  scope.contentService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                    .subscribe(
                      (data) => {
                        saveIttoDB(path, myFile.size, ele.type, scope.responsethumbUrl.data.path, imageinfo, last);
                      }, (error) => {
                        scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                      })
                })
          }
          return success;
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // // Load video in Safari / IE11
        video.muted = true;
        // //video.playsInline = true;
        // video.play();
        // cb(true);
        var playPromise = video.play();
        // In browsers that don’t yet support this functionality,
        // playPromise won’t be defined.
        if (playPromise !== undefined) {
          playPromise.then(function () {
            // Automatic playback started!
          }).catch(function (error) {
            // cb(true);
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          });
        }
      };
      fileReader.readAsArrayBuffer(ele);
    }
    else if (ele.type.match('audio')) {
      var newfile = null;
      var newname = (+ new Date()) + localStorage.getItem('scarletparentuserid') + '.' + imageinfo.audio_thumb[0].name.split('.').pop()
      newfile = new File([imageinfo.audio_thumb[0]], newname);

      scope.contentService.getS3URL('user_profile', imageinfo.audio_thumb[0].type, imageinfo.audio_thumb[0].size, newfile.name)
        .subscribe(
          (data) => {
            scope.thumbresponseUrl = data;
            scope.contentService.uploadToS3(newfile, scope.thumbresponseUrl.data.signed_request)
              .subscribe(
                (data) => {
                  var size = this.imageFileSize;
                  var fileReader = new FileReader();
                  fileReader.onloadend = function () {
                    let tempImg = new Image();
                    tempImg.src = fileReader.result as string;
                    tempImg.onload = function () {
                      var MAX_WIDTH = 400;
                      var MAX_HEIGHT = 300;
                      var tempW = tempImg.width;
                      var tempH = tempImg.height;
                      if (tempW > tempH) {
                        if (tempW > MAX_WIDTH) {
                          tempH *= MAX_WIDTH / tempW;
                          tempW = MAX_WIDTH;
                        }
                      } else {
                        if (tempH > MAX_HEIGHT) {
                          tempW *= MAX_HEIGHT / tempH;
                          tempH = MAX_HEIGHT;
                        }
                      }

                      var canvas = document.createElement('canvas');
                      canvas.width = tempW;
                      canvas.height = tempH;
                      var ctx = canvas.getContext("2d");
                      ctx.drawImage(tempImg, 0, 0, tempW, tempH);
                      var thtype = "image/jpeg";
                      var dataURL = canvas.toDataURL("image/jpeg");
                      var myFile: Blob = scope.dataURItoBlob(dataURL);
                      var thpath = 'healthcoach/thumbnail';
                      scope.contentService.getS3URL(thpath, thtype, imageinfo.audio_thumb[0].size, imageinfo.audio_thumb[0].name)
                        .subscribe(
                          (data) => {
                            scope.responsethumbUrl = data;
                            scope.contentService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                              .subscribe(
                                (data) => {
                                  scope.contentService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, imageinfo.type, scope.responsethumbUrl.data.path, '5')
                                    .subscribe(
                                      (data) => {
                                        scope.resdata = data;
                                        if (scope.resdata.status == 201) {
                                        }
                                        else {
                                          scope.isloading = false;
                                          scope.systemService.showSnackBar("Unknown error occured", "Ok");
                                        }
                                        scope.systemService.showSuccessSnackBar(imageinfo.title + " is uploaded and saved", "Ok");
                                        cb(false);
                                      })
                                }, (error) => {
                                  scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                                })
                          })
                    }

                  }
                  fileReader.readAsDataURL(imageinfo.audio_thumb[0]);

                  // function saveaudiotoDB(path, size, type, thumbpath, imageinfo, last) {
                  //   scope.contentService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, imageinfo.type, scope.responsethumbUrl.data.path, '5')
                  //     .subscribe(
                  //       (data) => {
                  //         scope.resdata = data;
                  //         if (scope.resdata.status == 201) {
                  //         }
                  //         else {
                  //           scope.isloading = false;
                  //           scope.systemService.showSnackBar("Unknown error occured", "Ok");
                  //         }
                  //         scope.systemService.showSuccessSnackBar(imageinfo.title + " is uploaded and saved", "Ok");
                  //         cb(false);
                  //       })
                  // }

                }, (error) => {
                  scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                }
              )
          });
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }


    function saveIttoDB(path, size, type, thumbpath, imageinfo, last) {
      scope.commonService.CreateRichMedia(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, thumbpath, '5')
        .subscribe(
          (res: any) => {
            scope.resdata = res;
            
            if (scope.resdata.status == 201) {
            }
            else {
              scope.isloading = false;
              scope.router.navigate(['content/notification']);
              // scope.systemService.showSnackBar(error.error.message, "OK");
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }

  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  resetfiles() {
    this.demoForm.reset();
  }

}
