import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { empty, Observable, of, Subject, throwError } from 'rxjs';
// import { switchMap } from 'rxjs-compat/operator/switchMap';
import { catchError, tap ,switchMap} from 'rxjs/operators';
import { AuthenticateService } from '../services';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
import * as CryptoJS from 'crypto-js';  
import { environment } from 'src/environments/environment';

  @Injectable()
  export class AuthInterceptor implements HttpInterceptor {
    constructor( private authService: AuthenticateService, private tokenExtractor:HttpXsrfTokenExtractor,private router: Router) {}

    // refreshingAccessToken:boolean;

  refreshingAccessToken: boolean;
  public encryptionKey = environment.encryptionKey;

  accessTokenRefreshed: Subject<any> = new Subject();

    private addAccessTokenToHeaders(req: HttpRequest<any>): HttpRequest<any> {
      const accessToken = this.authService.accessToken;
      const checkSum = this.encryptURL(req.url);
      if (req.headers.has(InterceptorSkipHeader)) {
        const headers = req.headers.delete(InterceptorSkipHeader);
        return req.clone({ headers });
      }     
      //("accessToken",accessToken)
      if (accessToken) {       
        const authReq = req.clone({
          setHeaders: {
            'x-access-token': accessToken,
            'x-api-checksum': checkSum,
          }
        });
        return authReq;
      }else{
        const authReq = req.clone({
          setHeaders: {
            'x-api-checksum': checkSum,
          }
        });
        return authReq;
      }
    }
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<any> {
      // req = req.clone({ withCredentials: true });
      // 
      // // return XSRF-TOKEN in each request's header (anti-CSRF security)
      // const headerName = 'X-XSRF-TOKEN';
      // let csrftoken = this.tokenExtractor.getToken() as string;
      // 
      // if (csrftoken !== null && !req.headers.has(headerName)) {
      //     req = req.clone(
      //       { setHeaders: {
      //         'X-XSRF-TOKEN':csrftoken,
      //       }, headers: req.headers.set(headerName, csrftoken) 
      //     });
      // }
      req = this.addAccessTokenToHeaders(req)
      return next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          if(err.error.message === "Unauthorized: Refresh token is not valid.")
          {
            // sessionStorage.clear();
            this.authService.logout();
            this.router.navigate(['login'])
          }
          if (err.status === 401 && !req.url.includes('login')) { 
            this.refreshingAccessToken = false;

            // refresh acess token
            return this.refreshAccessToken().pipe(
              switchMap(() => {
                req = this.addAccessTokenToHeaders(req);
                return next.handle(req);
              }),
              catchError((err:any) => {
               // 
                // sessionStorage.clear();
                this.authService.logout();
                return empty();
        
              })
            )
            // return of(null);
          }
          return throwError(err);
        })
      );
    }

  refreshAccessToken() {
    if (this.refreshingAccessToken) {
      return new Observable(observer => {
        this.accessTokenRefreshed.subscribe(() => {
          // this code will run when the access token has been refreshed
          observer.next();
          observer.complete();
        })
      })
    } else {
      this.refreshingAccessToken = true;
      // we want to call a method in the auth service to send a request to refresh the access token
      return this.authService.refreshToken().pipe(
        tap(() => {
          
          
          this.refreshingAccessToken = false;
          this.accessTokenRefreshed.next();
        })
      )
    }
    
  }
  encryptURL(httpURL: string){
    const cipherText: string = CryptoJS.AES.encrypt(httpURL, this.encryptionKey).toString();
    return cipherText;
};
  }