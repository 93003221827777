import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ReplaySubject } from 'rxjs/index';
import { ProfileimageComponent } from 'src/app/admin/profileimage/profileimage.component';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SUPPORTED_FILE_TYPES } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ChoosemediamodalComponent } from '../../mediamanager/choosemediamodal/choosemediamodal.component';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-custombranding',
  templateUrl: './custombranding.component.html',
  styleUrls: ['./custombranding.component.scss']
})
export class CustombrandingComponent implements OnInit {

  upload_type: any = upload_type;
  public chooseMedia: any;
  public allmedia: any;
  public newitem: boolean = false;
  public showButtonLoader: boolean = false;
  public userid: any;
  public userdata: any;
  public coachaccess: any;
  public allhc: any;
  public formelement: any;
  s3url: any = environment.s3url;

  public dataForm = this.fb.group({
    support_email_id: [''],
    support_phone_number: [''],
    banner_media: [''],
    logo_media: [''],
    colored_logo_media: [''],
    use_therapy_branding: [''],
    dark_logo_media: [''],
    dark_colored_logo_media: [''],
    dark_banner_media: [''],    
    banner_media_logging_light:[''],
    banner_media_logging_dark:['']
  });

  public select_media_type: any;
  private allfiles: any;
  public uploadedfiletype: any;
  private responsethumbUrl: any;
  public resdata: any;
  public log_media_image_path: any;
  public colored_logo_media_path: any;
  public banner_media_image_path: any;  
  public banner_media_logging_image_path:any;
  public image_id: any;
  public imagepath: any;
  public hcdata: any;
  trailaccess: any;
  public uploadedparam: boolean = false;
  public uploadedBannermedia: boolean = false;  
  public uploadedBannerloggingLight: boolean = false;
  public uploadedcoloredmedia: boolean = false;
  public uploaded_logo_media_param: boolean = false;
  public image_data: any = {};
  public multiple: boolean = false;
  public isloading: boolean = false;
  public isbannerloading: boolean = false;  
  public isbannerloggingLight: boolean = false;
  public iscoloredloading: boolean = false;
  // public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg'];
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  public demoForm = new FormGroup({
    logo_media: this.filesControl,
    banner_media: this.filesControl,
    colored_logo_media: this.filesControl,
    dark_logo_media: this.filesControl,
    dark_colored_logo_media: this.filesControl,
    dark_banner_media: this.filesControl,    
    banner_media_logging_light:this.filesControl,
    banner_media_logging_dark:this.filesControl
  });

  public isinvalid: boolean = false;
  private responseUrl: any;
  public dark_log_media_image_path: any;
  public dark_colored_logo_media_path: any;
  public dark_banner_media_image_path: any;
  
  public dark_banner_media_logging_light_image_path:any;
  public uploadeddarkparam: boolean = false;
  public uploadedDarkBannermedia: boolean = false;
  public uploadedDarkcoloredmedia: boolean = false;
  public isdarklogoloading: boolean = false;
  public isdarkbannerloading: boolean = false;
  public isdarkcoloredloading: boolean = false;
 
  public isdarkcoloredloggingLight: boolean = false;
  public uploadedDarkLoggingLight: boolean = false;
  public filteredHC: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmathc: FormControl = new FormControl();
  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;

  constructor(private browserTitle: Title, public fb: FormBuilder, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private commonService: CommonserviceService, private systemService: SystemService, private contentService: ContentserviceService) { }

  validateEmail(control: FormControl) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(control.value)) {
      if (control.value.indexOf("@wellthy.care", control.value.length - "@wellthy.care".length) !== -1) {
        return null
      }
      else {
        return { 'validateEmail': true };
      }
    }
    else {
      return { 'validateEmail': true };
    }
  }

  public filterHC() {
    if (!this.allhc) {
      return;
    }
    // get the search keyword
    let search = this.searchmathc.value;
    if (!search) {
      this.filteredHC.next(this.allhc.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredHC.next(
      this.allhc.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Client');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      // if (params.id == 'new') {
      //   this.newitem = true;
      // }
      // else {
      this.newitem = false;
      this.userid = params.id;
      this.getBrandingData(params.id);
      // }
    })
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }


  // To Upload Image 
  // ----------------------------

  uploadImage(upload_type_media) {
    
    this.select_media_type = upload_type_media;
    if (this.newitem == false) {
      this.uploaded_logo_media_param = false;
      if (this.select_media_type == 'logo_media') {
        this.uploadedparam = false;
      }
      else if (this.select_media_type == 'banner_media') {
        this.uploadedBannermedia = false;
      }
      else if (this.select_media_type == 'colored_logo_media') {
        this.uploadedcoloredmedia = false;
      }
      else if (this.select_media_type == 'banner_media_logging_light') {
        this.uploadedBannerloggingLight = false;
      }
       else if (this.select_media_type == 'dark_logo_media') {
        this.uploadeddarkparam = false;
      }
      else if (this.select_media_type == 'dark_banner_media') {
        this.uploadedDarkBannermedia = false;
      }
      else if (this.select_media_type == 'dark_colored_logo_media') {
        this.uploadedDarkcoloredmedia = false;
      } 
      else if (this.select_media_type == 'banner_media_logging_dark') {
        this.uploadedDarkLoggingLight = false;
      }
      else {
      }
    }
    if (this.demoForm.status == 'INVALID') {
      this.isinvalid = true;
      this.systemService.showSnackBar("Your filetype is not supported. The Supported formats are .jpeg,.png,.jpg ", "Ok");
    }
    else if (this.demoForm.value.logo_media[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = this.demoForm.value.logo_media;
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.dataForm.value.profile_image == this.image_id) {
            this.uploaded_logo_media_param = true;
            if (this.select_media_type == 'logo_media') {
              this.uploadedparam = true;
            }
            else if (this.select_media_type == 'banner_media') {
              this.uploadedBannermedia = true;
            }
            else if (this.select_media_type == 'colored_logo_media') {
              this.uploadedcoloredmedia = true;
            } 
             else if (this.select_media_type == 'banner_media_logging_light') {
              this.uploadedBannerloggingLight = true;
            }
            else if (this.select_media_type == 'dark_logo_media') {
              this.uploadeddarkparam = false;
            }
            else if (this.select_media_type == 'dark_banner_media') {
              this.uploadedDarkBannermedia = false;
            }
            else if (this.select_media_type == 'dark_colored_logo_media') {
              this.uploadedDarkcoloredmedia = false;
            }
            else if (this.select_media_type == 'banner_media_logging_dark') {
              this.uploadedDarkLoggingLight = false;
            }
            else {
            }
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.uploaded_logo_media_param = false;
              if (scope.select_media_type == 'logo_media') {
                scope.uploadedparam = true;
                scope.isloading = true;
              }
              else if (scope.select_media_type == 'banner_media') {
                scope.uploadedBannermedia = true;
                scope.isbannerloading = true;
              }
              else if (scope.select_media_type == 'colored_logo_media') {
                scope.uploadedcoloredmedia = true;
                scope.iscoloredloading = true;
              } 
              else if (scope.select_media_type == 'banner_media_logging_light') {
                scope.uploadedBannerloggingLight = true;
                scope.isbannerloggingLight = true;
              }
              else if (scope.select_media_type == 'dark_logo_media') {
                scope.uploadeddarkparam = true;
                scope.isdarklogoloading = true;
              }
              else if (scope.select_media_type == 'dark_banner_media') {
                scope.uploadedDarkBannermedia = true;
                scope.isdarkbannerloading = true;
              }
              else if (scope.select_media_type == 'dark_colored_logo_media') {
                scope.uploadedDarkcoloredmedia = true;
                scope.isdarkcoloredloading = true;
              }   
              else if (scope.select_media_type == 'banner_media_logging_dark') {
                scope.uploadedDarkLoggingLight = true;
                scope.isdarkcoloredloggingLight = true;
              }
              else {
              }
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        }
                      )
                  }
                );
            }
          }
        });
      }
    }
  }

  // ----------------------------


  // To Upload Thumbnail 
  // ----------------------------

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          scope.commonService.getS3URL('user_profile', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    }
                  )
              }
            )
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, thumbpath, imageinfo) {

      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, upload_type[0], 'document')
        .subscribe(
          (data) => {
            scope.resdata = data;

            if (scope.select_media_type == 'logo_media') {
              scope.log_media_image_path = scope.resdata.data.path;
              scope.dataForm.value.logo_media = scope.resdata.data.id;
            }
            else if (scope.select_media_type == 'banner_media') {
              scope.banner_media_image_path = scope.resdata.data.path;
              scope.dataForm.value.banner_media = scope.resdata.data.id;
            }
            else if (scope.select_media_type == 'colored_logo_media') {
              scope.colored_logo_media_path = scope.resdata.data.path;
              scope.dataForm.value.colored_logo_media = scope.resdata.data.id;
            } 
            else if (scope.select_media_type == 'banner_media_logging_light') {
              scope.banner_media_logging_image_path = scope.resdata.data.path;
              scope.dataForm.value.banner_media_logging_light = scope.resdata.data.id;
            }
            else if (scope.select_media_type == 'dark_logo_media') {
              scope.dark_log_media_image_path = scope.resdata.data.path;
              scope.dataForm.value.dark_logo_media = scope.resdata.data.id;
            }
            else if (scope.select_media_type == 'dark_banner_media') {
              scope.dark_banner_media_image_path = scope.resdata.data.path;
              scope.dataForm.value.dark_banner_media = scope.resdata.data.id;
            }
            else if (scope.select_media_type == 'dark_colored_logo_media') {
              scope.dark_colored_logo_media_path = scope.resdata.data.path;
              scope.dataForm.value.dark_colored_logo_media = scope.resdata.data.id;
            }
              else if (scope.select_media_type == 'banner_media_logging_dark') {
              scope.dark_banner_media_logging_light_image_path = scope.resdata.data.path;
              scope.dataForm.value.banner_media_logging_dark = scope.resdata.data.id;
            }
            else {

            }
            scope.uploaded_logo_media_param = true;
            if (scope.select_media_type == 'logo_media') {
              scope.uploadedparam = true;
              scope.isloading = false;

            }
            else if (scope.select_media_type == 'banner_media') {
              scope.uploadedBannermedia = true;
              scope.isbannerloading = false;
            }
            else if (scope.select_media_type == 'colored_logo_media') {
              scope.uploadedcoloredmedia = true;
              scope.iscoloredloading = false;
            }
            else if (scope.select_media_type == 'dark_logo_media') {
              scope.uploadeddarkparam = true;
              scope.isdarklogoloading = false;
            }
            else if (scope.select_media_type == 'banner_media_logging_light') {           
              scope.uploadedBannerloggingLight = true;
              scope.isbannerloggingLight = false;
            }
            else if (scope.select_media_type == 'dark_banner_media') {
              scope.uploadedDarkBannermedia = true;
              scope.isdarkbannerloading = false;
            }
            else if (scope.select_media_type == 'dark_colored_logo_media') {
              scope.uploadedDarkcoloredmedia = true;
              scope.isdarkcoloredloading = false;
            }  
            else if (scope.select_media_type == 'banner_media_logging_dark') {
              scope.uploadedDarkLoggingLight = true;
              scope.isdarkcoloredloggingLight = false;
            }
            else {

            }

            if (scope.resdata.status == 201) {
            }
            else {
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          }
        )
    }
  }

  // ----------------------------


  // To Convert DataURI to Blob
  // ----------------------------

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  // ----------------------------

  trailAccess(e) {
    this.trailaccess = e;
    if (this.trailaccess) {
      this.dataForm.addControl('trial_days', new FormControl([], Validators.required));
    }
    else {
      this.dataForm.removeControl('trial_days');
    }
  }

  HCAccess(e) {
    this.hcdata = e;
    if (this.hcdata) {
      this.dataForm.addControl('coach_ids', new FormControl([], Validators.required));
    }
    else {
      this.dataForm.removeControl('coach_ids');
    }
  }


  // ----------------------------

  openmediabox(type) {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      if (type == 'banner') {
        this.formelement = <FormControl>this.dataForm.controls['banner_media'];
      }
      else if (type == 'logo') {
        this.formelement = <FormControl>this.dataForm.controls['logo_media'];
      }
      else if (type == 'coloredlogo') {
        this.formelement = <FormControl>this.dataForm.controls['colored_logo_media'];
      }
      this.formelement.setValue(data.id);
      this.allmedia = data.path;

    });
  }


  // To Get Branding Data
  // ----------------------------

  getBrandingData(id) {
    this.contentService.getOneDataById(id, 'therapy')
      .subscribe(
        (res: any) => this.onBrandingDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onBrandingDataSuccess(data) {
    data = (data as any).data;
    this.userdata = data;
    
    this.dataForm.patchValue(this.userdata);
    this.dataForm.controls.support_email_id.patchValue(this.userdata.support_email)
    if (this.userdata.logo_media) {
      this.log_media_image_path = this.userdata.logo_media.path;
      this.image_id = this.userdata.logo_media.id;
      this.uploadedparam = true;
    }
    if (this.userdata.banner_media) {
      this.banner_media_image_path = this.userdata.banner_media.path;
      this.image_id = this.userdata.banner_media.id;
      this.uploadedBannermedia = true;
    }
    if (this.userdata.colored_logo_media) {
      this.colored_logo_media_path = this.userdata.colored_logo_media.path;
      this.image_id = this.userdata.colored_logo_media.id;
      this.uploaded_logo_media_param = true;
      this.uploadedcoloredmedia = true;
    }
    if(this.userdata.banner_media_logging_light){
      this.banner_media_logging_image_path =  this.userdata.banner_media_logging_light.path;
      this.image_id = this.userdata.banner_media_logging_light.id;
    //  this.uploaded_logo_media_param = true;
      this.uploadedBannerloggingLight = true;
    }
    if (this.userdata.dark_logo_media) {
      this.dark_log_media_image_path = this.userdata.dark_logo_media.path;
      this.image_id = this.userdata.dark_logo_media.id;
      this.uploadeddarkparam = true;
    }
    if (this.userdata.dark_banner_media) {
      this.dark_banner_media_image_path = this.userdata.dark_banner_media.path;
      this.image_id = this.userdata.dark_banner_media.id;
      this.uploadedDarkBannermedia = true;
    }
    if (this.userdata.dark_colored_logo_media) {
      this.dark_colored_logo_media_path = this.userdata.dark_colored_logo_media.path;
      this.image_id = this.userdata.dark_colored_logo_media.id;
      this.uploaded_logo_media_param = true;
      this.uploadedDarkcoloredmedia = true;
    }
    if(this.userdata.banner_media_logging_dark){
      this.dark_banner_media_logging_light_image_path =  this.userdata.banner_media_logging_dark.path;
      this.image_id = this.userdata.banner_media_logging_dark.id;
       this.uploadedDarkLoggingLight = true;
      // this.uploadedDarkcoloredmedia = true;
    }
    // if (this.userdata.logoMediaId) {
    //   this.dataForm.value.logo_media = this.userdata.logoMediaId;
    //   this.getLogoMedia(this.userdata.logoMediaId);
    // }
    // if (this.userdata.bannerMediaId) {
    //   this.dataForm.value.banner_media = this.userdata.bannerMediaId;
    //   this.getBannerMedia(this.userdata.bannerMediaId);
    // }
    // if (this.userdata.coloredLogoMediaId) {
    //   this.dataForm.value.colored_logo_media = this.userdata.coloredLogoMediaId;
    //   this.getColoredMedia(this.userdata.coloredLogoMediaId);
    // }
    if (this.userdata.has_coach_access) {
      this.coachaccess = true;
    }
  }

  // ----------------------------


  // To Get Logo Media
  // ----------------------------

  // getLogoMedia(id) {
  //   this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
  //   .subscribe(
  //     (res: any) => this.onlogoDataSuccess(res),
  //     (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //   )
  // }

  // private onlogoDataSuccess(data) {
  //   data = (data as any).data;
  //   this.log_media_image_path = data.getOneDocumentMedia.path;
  //   this.image_id = data.getOneDocumentMedia.id;
  //   this.uploadedparam = true;
  // }

  // ----------------------------


  // To Get Banner Media
  // ----------------------------

  // getBannerMedia(id) {
  //   this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
  //   .subscribe(
  //     (res: any) => this.onBannerDataSuccess(res),
  //     (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //   )
  // }

  // private onBannerDataSuccess(data) {
  //   data = (data as any).data;
  //   this.banner_media_image_path = data.getOneDocumentMedia.path;
  //   this.image_id = data.getOneDocumentMedia.id;
  //   this.uploadedBannermedia = true;
  // }

  // ----------------------------


  // To Get Colored Media
  // ----------------------------

  // getColoredMedia(id) {
  //   this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
  //   .subscribe(
  //     (res: any) => this.onColoredMediaSuccess(res),
  //     (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //   )
  // }

  // private onColoredMediaSuccess(data) {
  //   data = (data as any).data;
  //   this.colored_logo_media_path = data.getOneDocumentMedia.path;
  //   this.image_id = data.getOneDocumentMedia.id;
  //   this.uploaded_logo_media_param = true;
  //   this.uploadedcoloredmedia = true;
  // }

  // ----------------------------


  // Submitting Branding Data
  // ----------------------------

  dataObject() {
    
    var postdata = this.dataForm.value;
    
    
    postdata.support_email_id = postdata.support_email_id ? postdata.support_email_id.toString() : '';
    postdata.support_phone_number = postdata.support_phone_number ? postdata.support_phone_number.toString() : '';
    postdata.logo_media = typeof postdata.logo_media == 'object' ? postdata.logo_media.id.toString() : postdata.logo_media.toString();
    postdata.colored_logo_media = typeof postdata.colored_logo_media == 'object' ? postdata.colored_logo_media.id.toString() : postdata.colored_logo_media.toString();
    postdata.banner_media = typeof postdata.banner_media == 'object' ? postdata.banner_media.id.toString() : postdata.banner_media.toString();
    postdata.banner_media_logging_light = typeof postdata.banner_media_logging_light == 'object' ? postdata.banner_media_logging_light.id.toString() : postdata.banner_media_logging_light.toString();
  
    postdata.use_therapy_branding = postdata.use_therapy_branding.toString();
    
    if (postdata.dark_logo_media) {
      postdata.dark_logo_media = typeof postdata.dark_logo_media == 'object' ? postdata.dark_logo_media.id.toString() : postdata.dark_logo_media.toString();
    } else {
      delete postdata.dark_logo_media
    }

    if (postdata.dark_colored_logo_media) {
      postdata.dark_colored_logo_media = typeof postdata.dark_colored_logo_media == 'object' ? postdata.dark_colored_logo_media.id.toString() : postdata.dark_colored_logo_media.toString();
    } else {
      delete postdata.dark_colored_logo_media
    }

    if (postdata.dark_banner_media) {
      postdata.dark_banner_media = typeof postdata.dark_banner_media == 'object' ? postdata.dark_banner_media.id.toString() : postdata.dark_banner_media.toString();
    } else {
      delete postdata.dark_banner_media
    }
    if(postdata.banner_media_logging_dark){
      postdata.banner_media_logging_dark = typeof postdata.banner_media_logging_dark == 'object' ? postdata.banner_media_logging_dark.id.toString() : postdata.banner_media_logging_dark.toString();
     }else{
       delete postdata.banner_media_logging_dark
     }
  
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    this.commonService.customBranding(this.userid, postdata)
      .subscribe(
        (res: any) => this.onClientStatusSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onClientStatusSuccess() {
    this.systemService.showSuccessSnackBar(("Branding data has been updated"), "OK");
    this.router.navigate(['content/therapy']);
  }

  // ----------------------------

}

export enum upload_type {
  patient_profile
}
