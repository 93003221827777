import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-taskviewhistory',
  templateUrl: './taskviewhistory.component.html',
  styleUrls: ['./taskviewhistory.component.scss']
})
export class TaskviewhistoryComponent implements OnInit {
  public showloader: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public dataVal: any;
  public isactive = 'all';
  activecls = 'active';
  displayedColumns = ['created_at', 'action_type', 'created_by_name'];

  constructor(public dialogRef: MatDialogRef<TaskviewhistoryComponent>,private systemService: SystemService,public commonService: CommonserviceService,@Inject(MAT_DIALOG_DATA) private data: any) { }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    this.dataVal = this.data
    this.search();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ','+ ' ' + year ;
    }
  }

   //  Task List
  // ----------------------------

  search() {
   var bodyData = {
     "id":this.dataVal.task_id.toString(),
     "page":this.page.toString(),
     "limit":"10",
     "hc_id":this.dataVal.hc_id.toString()
   }
    this.showloader = true;
    this.commonService.TaskPost(bodyData, 'taskHistory')
      .subscribe(
        (res: any) => this.onTaskListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTaskListSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;

      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

}
