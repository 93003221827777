<!-- /*==========================================
Title: Medicne Creation 
Author: Nikhil Raj A
Date:   25 may 2020
Last Change :  UI Fixes
===========================================*/  -->
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb  *ngIf="!newitem" route="content/medicinehome/medicineedit"></app-breadcrumb>
        <app-breadcrumb  *ngIf="newitem" route="content/medicinehome/medicineCreate"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                Create Medicine
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" class="leftformsection" (ngSubmit)="dataObject()" validate>
                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Title</mat-label>
                            <input matInput placeholder="Enter the title" formControlName="title" required>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Client</mat-label>
                            <mat-select formControlName="client_ids" multiple #singleSelectClient>
                                <mat-option>
                                    <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                        [placeholderLabel]="'Search Item'"
                                        [noEntriesFoundLabel]="'No matching records found'"
                                        [formControl]="searchmatclient" ngModel (ngModelChange)="modelChangedClient()"
                                        ngDefaultControl>
                                        <div class="mat-select-search-custom-header-content notranslate">
                                            <i class="material-icons">
                                                search
                                            </i>
                                        </div>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let client of filteredClient | async" [value]="client.id">
                                    {{client.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Primary Condition</mat-label>
                            <mat-select formControlName="comorbilities" multiple required>
                                <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                    [value]="condition.id">
                                    {{condition.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                            <mat-label>Select Image</mat-label>
                            <input matInput (click)="openmediabox()" formControlName="icon_media" required readonly>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field class="full-width-formfield" appearance="outline">
                            <mat-label>Medicine type</mat-label>
                            <mat-select #fontSize value="16px" formControlName="type">
                                <mat-option value="tablet">Tablet</mat-option>
                                <mat-option value="syrup">Syrup</mat-option>
                                <mat-option value="capsule">Capsule</mat-option>
                                <mat-option value="insulin_pen">Insulin Pen</mat-option>
                                <mat-option value="ointment">Ointment</mat-option>
                                <mat-option value="injectable">Injectable</mat-option>
                                <mat-option value="nebulizer">Nebulizer</mat-option>
                                <mat-option value="mdi">MDI</mat-option>
                                <mat-option value="dpi">DPI</mat-option>
                                <mat-option value="bai">BAI</mat-option>
                                <mat-option value="nasal_spray">Nasal Spray</mat-option>
                                <mat-option value="dpi_revolizer">DPI Revolizer</mat-option>
                                <mat-option value="dpi_multihaler">DPI Multihaler</mat-option>
                                <mat-option value="dpi_rotahaler">DPI Rotahaler</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Enter Text in हिन्दी</mat-label>
                            <input matInput placeholder="Enter Text" formControlName="hindi">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Enter Text in ಕನ್ನಡ</mat-label>
                            <input matInput placeholder="Enter Text" formControlName="kannada">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Enter Text in বাংলা</mat-label>
                            <input matInput placeholder="Enter Text" formControlName="bangla">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Enter Text in Español</mat-label>
                            <input matInput placeholder="Enter Text" formControlName="spanish">
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                            [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Medicine</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-drawer-container>