<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-hcassignleftnav></app-hcassignleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/campaignhome"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="create-btn" (click)="createCampaign('newsection')">
            Create Campaign
          </button>
        </h3>
      </div>

      <div class="col-md-2">
        <mat-select [(ngModel)]="tagFilters" placeholder="Filter" multiple class="sortcommonwhite"
          (selectionChange)="tagfilter($event.value)">
          <mat-option value="campaign_paid">Campaign Paid</mat-option>
          <mat-option value="campaign_free">Campaign Free</mat-option>
          <mat-option value="demo_campaign">Demo Campaign </mat-option>
          <!-- <mat-option *ngFor="let tag of alltags" [value]="tag.id">{{tag.title}}</mat-option>   -->
        </mat-select>
      </div>


      <div class="col-md-10">
        <form>
          <div class="form-group has-feedback has-search">
            <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
              placeholder="Search">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>

      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''">
            <a (click)="tableSort('all')" class="all">
              All
            </a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">
              Active
            </a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">
              Inactive
            </a>
          </li>
        </ul>
      </div>

      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">

          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table">

            <!-- Position Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
                {{element.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let element" style="text-transform: capitalize;">
                {{removeunderscore(element.type)}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="client_data">
              <th mat-header-cell *matHeaderCellDef> Client </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                {{element.client_data==null?'No client':getClientName(element.client_data)}}
              </td>
            </ng-container>

            <!-- updatedat Column -->
            <ng-container matColumnDef="coach_ids">
              <th mat-header-cell *matHeaderCellDef> Healthcoach </th>
              <td mat-cell class="camelcased" *matCellDef="let element">{{getHCName(element.coach_ids)}}
              </td>
            </ng-container>


            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef > Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">
                  <canvas [hidden]="true" #canvas></canvas>
                  <!-- [routerLink]="['/admin/createcampaign/edit', element.id]" -->
                  <button mat-button (click)="createCampaign(element.id)" tooltip="Edit campaign">
                    <img src="assets/img/dls/icons/actiontable/Icon_Edit.svg" style="height: 28px;" alt="">
                  </button>
                  <button mat-button (click)="CampaignInfo(element.id)" tooltip="Campaign history"  *ngIf="element.show_history === true">
                    <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy">
                  </button>
                  <button mat-button disableRipple  *ngIf="element.show_history === false"   matTooltip="This campaign has no change history" style="opacity:0.5;cursor: not-allowed;">
                    <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy" disabled>
                  </button>           
                 
                  <button mat-button download="xyz.png" (click)="downloadQR(element)" tooltip="Download QR">
                    <img src="assets/img/dls/admin-table/Icon_DownloadQR.svg" alt="">
                  </button>
                  <button mat-button (click)="showQR(element.id)" tooltip="View QR">
                    <img src="assets/img/dls/icons/actiontable/Icon_ViewQRcode.svg" alt="">
                  </button>

                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openCampaignDeeplink(element.id)">
                      <img src="assets/img/dls/icons/actiontable/Icon_Deeplink.svg" style="height: 28px;" alt="">
                      Generate deeplink
                    </button>
                    <button mat-menu-item (click)="viewCampaignDetails(element)">
                      <img src="assets/img/dls/icons/actiontable/Icon_View_hover.svg" style="height: 28px;" alt="">
                      <span class="drop-menu-items">View details</span>
                    </button>
                    <button mat-menu-item class="active-green align-items-center" *ngIf="!element.is_active"
                      [ngClass]="{'my-class': element.is_active == true}"
                      (click)="isActive(element.is_active,element.id)">
                      <img src="assets/img/dls/icons/actiontable/Icon_Activate.svg" alt="">
                      <span class="drop-menu-items"> {{element.is_active? "Deactivate" : "Activate"}}</span>

                    </button>
                    <button class="last-btn align-items-center" mat-menu-item disableRipple
                      *ngIf="element.is_active == false" (click)="openDialog(element.id)">
                      <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="">
                      <span class="drop-menu-items">Delete</span>
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>
