import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPersistenceContainer, PersistenceService } from 'angular-persistence';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpService, SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../services/context.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService extends HttpService {
  public sessionID: string;
  public apiUrl: string = environment.serverUrl;
  private listTicketsUrl = this.apiUrl + 'zohosupport/ticket/getall';
  private hctimelogurl = this.apiUrl + 'healthcoach/timelogs';
  private container: IPersistenceContainer;
  public s3tokenurl: any = environment.serverUrl;
  public microserviceUrl:any = environment.microserviceUrl;
  public allextension: any = {
    timelog: 'timelog',
    hctimelog: 'timelog/user/getall',
    hctime: 'timelog/user/getcount',
    organization_data: this.systemService.getorg()
  };
  constructor(private http: HttpClient, private appContext: ContextService, private persistenceService: PersistenceService, private systemService : SystemService) {
    super(http)
  }

  public getTickets(): Observable<any> {
    return this.get(this.listTicketsUrl)
  }



  public createTicket(subject: string, description: string, priority: string, contactID: string, assigneeID: string, scarletID: string): Observable<any> {
    description = this.removeHTMLtags(description);
    let bodydata = {
      subject: subject,
      customFields: {
        "Scarlet ID": scarletID.toString()
      },
      description: description,
      priority: priority,
      contactId: contactID,
      assigneeId: assigneeID
    };
    bodydata.customFields = JSON.stringify(bodydata.customFields) as any;
    return this.post(this.s3tokenurl + 'zoho/ticket/create', bodydata)

  }

  public updateHCTime(id, service): Observable<any> {
   
    let data=localStorage.getItem('app');
    let param;

    if(data=="healthcoach"){
      param="active";
    }

    if(data=="admin"){
      param="admin";
    }   

    let bodydata = {
      hc_id_data_fk: id,
      "start_time": 0,
      "end_time": 0,
      "log_type": param,
    };
    if(environment.org)
    {
        bodydata['organization_data'] = this.systemService.getorg()
    }
    return this.post(this.microserviceUrl + this.allextension[service], bodydata)
  }
  

  private handleError(operation: string = 'operation') {
    return (error: any) => {
      error.operation = operation;
      this.systemService.handleErrorResponse(error);
      return throwError(error);
    };
  }


  public gethctimelog(stime, etime, id, service, count): Observable<any> {
    
    let bodydata = {
      hc_id_data_fk: id.toString(),
      start_time: stime,
      end_time: etime,
      page : count.toString(),
      limit : "20"
    };
    if(environment.org)
    {
        bodydata['organization_data'] = this.systemService.getorg()
    }
    return this.post(this.microserviceUrl + this.allextension[service], bodydata)
  }

  public gethctime(stime, etime, id, service): Observable<any> {
    
    let bodydata = {
      hc_id_data_fk: id.toString(),
      start_time: stime,
      end_time: etime
    };
    if(environment.org)
    {
        bodydata['organization_data'] = this.systemService.getorg()
    }
    return this.post(this.microserviceUrl + this.allextension[service], bodydata)
  }


  public getAgents(): Observable<any> {
    return this.get(this.s3tokenurl + 'zoho/ticket/agent/getall')
  }

  public getTicketsFilterWise(status: string, lastID: string, limit: string): Observable<any> {
    return this.get(this.s3tokenurl + 'zoho/ticket/paginate?last_index=&limit=' + limit + '&status=' + status)

  }

  public getAllTickets(status: string, lastID: string, limit: string): Observable<any> {
    return this.get(this.s3tokenurl + 'zoho/ticket/paginate?last_index=&limit=' + limit + '&status=' + status)

  }

  public getContact(fullName: string, email: string, phone: string): Observable<any> {
    return this.get(this.s3tokenurl + 'zoho/ticket/contact/search?fullname=' + fullName + '&phone=' + phone + '&email=' + email)
  }

  public createContact(fullName: string, email: string, phone: string, scarletID: string): Observable<any> {
    let name = fullName.split(" ");
    phone = this.removeHTMLtags(phone);
    email = this.removeHTMLtags(email);
    let bodydata = {
      lastName: (name[1] ? name[1] : "N/A"),
      mobile: phone,
      firstName: (name[0] ? name[0] : "N/A"),
      phone: phone,
      email: email,
      customFields: {
        "Scarlet ID": scarletID,
      }
    };
    bodydata.customFields = JSON.stringify(bodydata.customFields) as any;
    return this.post(this.s3tokenurl + 'zoho/ticket/contact/create', bodydata)
  }

  private __timelofData1 = new BehaviorSubject<any>(null);
  current____timelogData1= this.__timelofData1.asObservable();
  
  change___timelogData1(timelogData1:any){
    this.__timelofData1.next(timelogData1)
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
