
<mat-drawer-container class="sidenav-container" autosize>
    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
      <app-faqleftnav></app-faqleftnav>
      <div class="mainrightcontainersecondary">
        <!-- <app-breadcrumb route="content/triggerhome/triggeredit"></app-breadcrumb> -->
        <app-breadcrumb route="content/triggerhome"></app-breadcrumb>
        <div class="col-md-12">
          <h3 class="commontitleprimary">
            <button mat-icon-button class="create-btn" [routerLink]="['/content/triggers/mapping/create','new','0']">
              Create New Trigger Mapping </button>
          </h3>
        </div>
  
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <form>
                <div class="form-group has-feedback has-search">
                  <i class="material-icons form-control-feedback">
                  </i>
                  <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                    placeholder="Search Condition">
                  <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                    <mat-icon matSuffix>search</mat-icon>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <ul class="list-inline table-filter-btn">
            <li [class]="activecls == 'all' ? 'active' : ''">
              <a (click)="tableSort('all')" class="all">
                All
              </a>
            </li>
            <li [class]="activecls == 'active' ? 'active' : ''">
              <a (click)="tableSort('active')" class="active-filter">
                Active
              </a>
            </li>
            <li [class]="activecls == 'inactive' ? 'active' : ''">
              <a (click)="tableSort('inactive')" class="inactive-filter">
                Inactive
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-12">
          <div class="example-container">
            <div class="spinner-container" *ngIf="showloader">
              <app-loader showLoader="{{true}}"></app-loader>
            </div>
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
              <!-- Name Column -->
              <ng-container matColumnDef="client_id">
                <th mat-header-cell *matHeaderCellDef> Condition ID </th>
                <td mat-cell *matCellDef="let element">
                  <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
                  {{element.condition_data_fk==null?'-':element.condition_data_fk}} </td>
              </ng-container>
              <ng-container matColumnDef="client_name">
                <th mat-header-cell *matHeaderCellDef> Condition </th>
                <td mat-cell *matCellDef="let element"> {{element.condition_title}} </td>
              </ng-container>
              <ng-container matColumnDef="widget">
                <th mat-header-cell *matHeaderCellDef> Trigger </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngFor="let log of element.trigger_data;let i=index">
                    {{log.title}}
                  </div>
                </td>
              </ng-container>
  
              <!-- <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="menu-holder">
                      <div class="example-button-row">
                        <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                          class="no-hover-effect"
                          [routerLink]="['/content/triggers/mapping/create/', element.condition_data_fk, element.id]">Edit</button>
                        <button mat-button disableRipple class="no-hover-effect active-green last-btn"
                          [ngClass]="{'my-class': element.is_active == true}"
                          (click)="isActive(element.is_active,element.id)">{{element.is_active? "Deactivate" : "Activate"}}</button>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element"></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td class="text-capitalize" mat-cell *matCellDef="let element">
                  <div class="action-button-row">
                    <button mat-button disableRipple  class="no-hover-effect"    [routerLink]="['/content/triggers/mapping/create/', element.condition_data_fk, element.id]">
                      <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit Trigger Mapping" alt="">
                    </button>
                    <button mat-button disableRipple class="no-hover-effect active-green"
                      [ngClass]="{'my-class': element.is_active == true}"
                      (click)="isActive(element.is_active,element.id)">
                      <span *ngIf="element.is_active == true">
                        <img src="assets/img/dls/admin-table/Icon_Deactivate.svg" tooltip="De-activate" alt="">
                      </span>
                      <span *ngIf="element.is_active == false">
                        <img src="assets/img/dls/admin-table/Icon_Activate.svg" tooltip="Activate" alt="">
                      </span></button>
                    <button class="last-btn1 no-hover-effect1" mat-button [disabled]="element.is_active === true"
                      [ngClass]="{disabled:element.is_active == true }" ><!--(click)="openDialog(element.id)"-->
                      <img src="assets/img/dls/admin-table/Icon_Delete.svg" style="height: 28px;" alt="" tooltip="Delete"
                        disabled></button>
                    <!-- <mat-menu #menu3="matMenu">
                      <button mat-menu-item *ngFor="let language of alllanguages"
                      [routerLink]="['/content/questionnair/create', element.id,  language.id, getChildID(element, language.id)]"><span
                          *ngIf="getChildID(element, language.id) || language.id == 1">
                          <mat-icon>done</mat-icon>
                        </span>{{language.name}}</button>
                    </mat-menu> -->
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">
  
            </table>
          </div>
  
        </div>
      </div>
    </div>
  
  </mat-drawer-container>