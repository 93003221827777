import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typefilter'
})
export class TypefilterPipe implements PipeTransform {

  transform(value: any, activecls: any, args?: any): any {
    if(activecls=='activelist'){
      return value.filter(i=>i.is_active==true);
    }
    else if(activecls=='inactive'){
      return value.filter(i=>i.is_active==false);
    }
    else{
      return value = value;
    }
  }

}
