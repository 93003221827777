import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-patientpaymentinfo',
  templateUrl: './patientpaymentinfo.component.html',
  styleUrls: ['./patientpaymentinfo.component.scss']
})

export class PatientpaymentinfoComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['created_at','purchase_type', 'transaction_amount', 'payment_status'];
  public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  actRoute: string;
  actRouteEdit: string;
  public viewMedia: any;
  public morrow: any = new Date();
  public filterapplied: boolean = false;
  public startd: any;
  public endd: any;
  enddateval: any;
  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });
  userid: any;
paymentData:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<PatientpaymentinfoComponent>,private browserTitle: Title, private route: ActivatedRoute, private fb: FormBuilder, private commonService: CommonserviceService, private systemService: SystemService) { }
  
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Patient Details');
  }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   this.userid = params.id;
    // })
    this.paymentData = this.data.element;
   
    this.userid = this.paymentData.id;
    
    this.setSEOTags()
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }


  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  typeFilter(fln) {
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.patient_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }


  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  parseString(t) {
    return t.replace("_", " ");
  }


  //  Patient List
  // ----------------------------

  search() {
    this.showloader = true; 
    this.commonService.getAllPatientPayment(this.userid.toString(), "all", '', '', this.page.toString(), "1")
      .subscribe(
        (res : any) => this.onSearchDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onSearchDataSuccess(data){
    
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.enddateval = event.target.value;
  }

  
  //  Main Filters
  // ----------------------------

  applymainfilter() {
    var postdata = this.dataForm.value;
    this.startd = postdata.startdate;
    this.endd = postdata.enddate;
    this.startd.setHours(0, 0, 0, 0);
    this.endd.setHours(23, 59, 59, 99);
    this.page = 1;
    this.lastSearch = this.searchcriteria;
    this.lastpage = null;
    this.tabulardata = [];
    this.search();
    this.filterapplied = true;
  }
  
// ----------------------------
  
}


