<mat-drawer-container class="sidenav-container" autosize>

    <app-docleftnav></app-docleftnav>

    <div class="sidenav-content">
        <div class="row topalertbar leftformsection">
            <div class="col-md-12 toptitlesec">
                <h3 class="alerttitle">Activate Patient</h3>
                <div class="show-notification-count">Add Phone number and select activation mode to activate wellthy
                    app</div>
            </div>
            <div class="clearfix">
            </div>
            <div class="margin-spaces"></div>
            <div class="col-md-12 formitemcss">
                <div class="row">
                    <form [formGroup]="dataForm" validate>
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Phone Number</mat-label>
                                <input matInput formControlName="phone" placeholder="Phone Number">
                                <mat-icon matPrefix>add</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-select formControlName="condition" placeholder="Select Condition">
                                    <mat-option value="diabetes">
                                        Diabetes
                                    </mat-option>
                                    <mat-option value="asthma">
                                        Asthma
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-select formControlName="type" placeholder="Select Type">
                                    <mat-option value="preactive">
                                        Pre Active Patient
                                    </mat-option>
                                    <mat-option value="deeplink">
                                        Generate Deeplink
                                    </mat-option>
                                    <mat-option value="qrcode">
                                        Generate QR Code
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="clearfix"></div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                [disabled]="!dataForm.valid" [routerLink]="['/doctor/generate', 0]">
                                Activate Patient</button>
                        </div>

                        <ng-template #buttonSubmitLoader>
                            <app-loader showLoader="{{true}}"></app-loader>
                        </ng-template>

                    </form>
                </div>
            </div>
            <ng-template #pageLoadingLoader>
                <app-loader showLoader="true"></app-loader>
            </ng-template>
        </div>
    </div>
</mat-drawer-container>