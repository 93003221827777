<div class="col-md-8 topsenduserbroadcastbar" style="left: 31%;position: fixed;">
    <div class="col-md-6">
        <h3 class="senduserbroadcasttitle">Send broadcast</h3>
        <div class="col-md-12 zeropadleft broadcastsendsection">
            <div class="clearfix"><br /></div>
            <form [formGroup]="brodcastForm" class="broadcast-form" (ngSubmit)="processBrodcast()" novalidate
                #form="ngForm">
                <mat-form-field appearance="outline" [hideRequiredMarker]="false" class="full-width-formfield">
                    <mat-label>Type</mat-label>
                    <mat-select placeholder="Type" formControlName="type" (selectionChange)="changeOptions($event.value)">
                        <mat-option value="mobile">
                            Mobile App
                        </mat-option>
                        <mat-option value="sms">
                            SMS
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <div *ngIf="brodcastSubject$ | async as brodcastData; else dropdownLoader">
                    <hr class="left-hr" />
                    <div formArrayName="brodcast_list" *ngFor="let pl of formData.controls; let i = index;">
                        <div [formGroupName]="i" class="gray-bg">
                            <a class="pull-right removecircle" href="javascript:;" (click)="removeBrodcast(i)"><i
                                    *ngIf="i > 0" tooltip="Remove this row"
                                    class="material-icons">remove_circle</i></a>
                            <mat-form-field appearance="outline" [hideRequiredMarker]="false"
                                class="full-width-formfield">
                                <mat-label>Select Broadcast</mat-label>

                                <mat-select formControlName="name" placeholder="Select Broadcast"
                                    (selectionChange)="drawMessagePreview($event)" #singleSelect>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="broadcastFilterCtrl">
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i></div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let brodcast of filteredBroadcast | async"
                                        [value]="brodcast.id">
                                        {{brodcast.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-icon-button class="pluscenterbtn" type="button" (click)="addMoreBrodcast()" *ngIf="brodcastForm?.get('type')?.value == 'mobile'">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <ng-template #dropdownLoader>
                    <app-loader showLoader="{{true}}"></app-loader>
                </ng-template>
                <div class="clearfix"><br /></div>

                <button mat-flat-button color="primary" [disabled]="!brodcastForm.valid" class="centerbtn">Send
                    Broadcast</button>
            </form>
        </div>
    </div>
    <div class="col-md-5 col-md-offset-1">
        <h3 class="senduserbroadcasttitle" *ngIf="broadcastPreviewData.length">Preview</h3>
        <div class="broadcastarea">
            <div class="clearfix"></div>
            <div *ngFor="let broadcast of broadcastPreviewData;">
                <div class="broadcastbox">
                    <p class="chattext">{{broadcast.name}}</p>
                    <p class="chattext">{{broadcast.message}}</p>
                    <img *ngIf="broadcast?.media_url && broadcast?.broadcastImageUrl && broadcast?.mime_type.includes('image')"
                        [src]="broadcast?.broadcastImageUrl" />
                    <audio
                        *ngIf="broadcast?.media_url && broadcast?.broadcastImageUrl && broadcast?.mime_type.includes('audio')"
                        class="audioprev" [src]="broadcast?.broadcastImageUrl" controls
                        controlsList="nodownload noremoteplayback"></audio>
                    <p class="chatdate">{{broadcast.created_at*1000 | date:'dd MMM yyyy'}}</p>
                    <app-loader *ngIf="broadcast?.media_url && !broadcast?.broadcastImageUrl" showLoader="{{true}}">
                    </app-loader>
                </div>
            </div>
        </div>
    </div>
</div>