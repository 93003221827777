<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                Request Details
            </h3>
        </div>
        <div class="col-md-8">
            <div class="clearfix"><br /></div>
            <form [formGroup]="requestForm" validate>
                <mat-form-field class="col-md-12" appearance="outline">
                    <mat-label>Request Title</mat-label>

                    <input matInput autocomplete="off" placeholder="Request Title" formControlName="title"
                        type="text" />
                </mat-form-field>
                <mat-form-field class="col-md-12" appearance="outline">
                    <mat-label>Describe all your changes</mat-label>

                    <textarea matInput autocomplete="off" placeholder="Describe all your changes"
                        formControlName="description"></textarea>
                </mat-form-field>
                <div class="col-md-12">
                    <button [disabled]="!requestForm.valid" mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                        (click)="publishRequest()">
                        Submit</button>
                </div>
            </form>
        </div>
    </div>

</mat-drawer-container>