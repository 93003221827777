import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import * as moment from 'moment';
import { element } from 'protractor';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../../environments/environment';
import { Userdetailsinterface } from '../../../models/userdetailsinterface';
import { ContextService } from '../../../services/context.service';
import { SocketService } from '../../../services/socket/socket.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { UserlistService } from '../../../services/userlist/userlist.service';
import { AddhopitalisationComponent } from '../../addhopitalisation/addhopitalisation.component';
import { AddmedicineComponent } from '../../addmedicine/addmedicine.component';
import { AddprescriptionComponent } from '../../addprescription/addprescription.component';
// import { AddprescriptionComponent } from '../../addprescription/addprescription.component';
import { AddreminderComponent } from '../../addreminder/addreminder.component';
import { AddsymptomsComponent } from '../../addsymptoms/addsymptoms.component';
import { AdversedrugaddmodalComponent } from '../../adversedrugaddmodal/adversedrugaddmodal.component';
import { AEMailComponent } from '../../adversedrugaddmodal/aemail/aemail.component';
import { DeleteAEComponent } from '../../adversedrugaddmodal/delete-ae/delete-ae.component';
import { EditAdverseEffectComponent } from '../../adversedrugaddmodal/edit-adverse-effect/edit-adverse-effect.component';
import { AlltriggersComponent } from '../../alltriggers/alltriggers.component';
import { InfusionsiteaddComponent } from '../../infusionsiteadd/infusionsiteadd.component';
import { MedicalhistoryaddComponent } from '../../medicalhistoryadd/medicalhistoryadd.component';
import { EditmedicationComponent } from '../../medicationadd/editmedication/editmedication.component';
import { MedicationaddComponent } from '../../medicationadd/medicationadd.component';
import { EdithcpumpComponent } from '../../pump/edithcpump/edithcpump.component';
import { PumpComponent } from '../../pump/pump.component';
import { PumpaddComponent } from '../../pump/pumpadd/pumpadd.component';
import { EditrouteofadminComponent } from '../../routeofadminadd/editrouteofadmin/editrouteofadmin.component';
import { RouteofadminaddComponent } from '../../routeofadminadd/routeofadminadd.component';
import { ShowmodalComponent } from '../../showmodal/showmodal.component';
import { MatSort } from '@angular/material/sort';
import { json } from 'd3';
import { CaregiverhistoryComponent } from '../caregiverhistory/caregiverhistory.component';

export interface Fruit {
  name: string;
}
@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class PrescriptionsComponent implements OnInit {
  relationship: any = relationship;
  public prescriptionSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  private prescriptionFilterData: any;
  public prescriptionUrlRaw: any;
  public presData: Array<Object> = [];
  public presDataArray: Array<Object> = [];
  public medicineData: Array<Object> = [];
  public reminderData: Array<Object> = [];
  public pumpData: Array<Object> = [];
  public InfusionSiteData;
  public MedicationData;
  public routeofAdminData;
  public selectedWeek: string = "1";
  s3url: any = environment.s3url;
  public documentPlaceHolder: string = '/assets/img/pdficon.jpg';
  private tabID: number = 7;
  public subscribeObj: any;
  public selected: any = 'prescription';
  public caregiverData: any = '';
  public caregiverId: any;
  public doctorData: any = '';
  public addmeddata: any;
  public selectedUserID: string;
  public calldata: any;
  public updateDocData: boolean = false;
  public caregiverButtonDisplay: boolean = false;
  public tempDocData: any;
  public allsymptoms: any;
  public filteredSymptoms: any;
  public alltriggers: any;
  public alltransactions: any;
  public allQuestionnaire: any;
  public showmore: boolean = false;
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
  public uploadedfiletype: any;
  public isinvalid: boolean = false;
  public isloading: boolean = false;
  isloadingpres: boolean = false;
  public loadingval: any = 5;
  private resdata: any;
  private responsethumbUrl: any;
  thumbresponseUrl: any;
  public image_path: any;
  upload_prescription_type: any;
  upload_doc_type: any;
  prescription_type: any;
  upload_type: any = upload_type;
  filedata: any;
  bodydata: any;
  pageno: any;
  docdata: any;
  private allfiles: any;
  addremdata: any;
  responseUrl: any;
  imageFileSize: any;
  public morrow: any = new Date();
  pumpTableData: any;
  medicationData: any;
  pumphistoryData: any;
  adrfRemodulinData: any;
  adrfNonRemodulinData: any;
  public allreminder: Array<Object> = [];
  public allInfusions: Array<Object> = [];
  public allMedications: Array<Object> = [];
  public allMedicalData: Array<Object> = [];
  public MedicalData: any;
  public allROA: Array<Object> = [];
  private myCathash: any = {};
  caregiverHistoryData: any = [];
  public alladverseemail: any
  taskComplete!: boolean;
  public todayDate: any = new Date();
  // Pump Variables
  public tagCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public tagData: any;
  public filterList: Array<string> = ["Open", "Closed", "Escalated", "On Hold"];
  public selectedFilter: string = "Open";
  public enteredSearchText: string;
  public tagForm: FormGroup;
  public directMode: boolean = false;
  public dataSource: any;
  public infusiondataSource: any;
  public lastSearch: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  caregiver!: boolean;
  public searchcriteria: any;
  public status: any = 'all';
  public allpumpdata: any;
  public hospitalizationData: any;
  tabulardata: any;
  public userClientDetails: any;
  public userDataQ: any;
  public is_remodulin: boolean;
  public showMedicalHistory: boolean;
  public surveyData: any;
  public surveyLength: any;
  public saveUsername: boolean;
  enableReminder: boolean;
  public emaildata: any;
  caregiverColumns = ['__old', '__new', 'caregiver_updated_date'];
  // caregiverColumns= ['caregiver_field','__old','__new','caregiver_updated_by','caregiver_updated_date'];
  displayedColumns = ['log_date', 'infusion_site', 'site_change_reason'];
  pumpColumns = ['log_date', 'brand_name', 'status', 'actions'];
  roaColumns = ['log_date', 'route_of_administration', 'reason_for_addition'];
  pumphistoryColumns = ['log_date', 'pump_id_fk', 'pump_route_type', 'reason_for_change'];
  adrfColumn = ['log_date', 'log_type', 'ae_form_submitted']
  public showloader: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public careEditData: boolean = false;
  public cancelButtonEnable: boolean = false;
  public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public showButtonLoader: boolean;
  daysData: any = [];
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public demoForm = new FormGroup({
    files: this.filesControl,
  });
  public dataForm = this.fb.group({
    patient_id_fk: [''],
    name: ['', [Validators.required]],
    prescription_ids: [''],
    test_date: ['', Validators.required]
  });
  public callogForm = this.fb.group({
    startdate: [''],
    enddate: ['']
  });
  public transactionslogForm = this.fb.group({
    startdate: [''],
    enddate: ['']
  });
  public caregiverForm = this.fb.group({
    name: ["", Validators.required],
    phone: ['', [Validators.required, Validators.pattern('^[1-9]{1}[0-9]{9}$')]],
    relation: ["", Validators.required],
    country_code: ["", Validators.required],
    email: ['', [Validators.required, Validators.pattern('^.+@.+([.].+)+$')]],
    gender: ["", Validators.required]
  });
  public doctorForm = this.fb.group({
    doctorCode: ["", Validators.required]
  });
  public infusionlogForm = this.fb.group({
    start_date: [''],
    end_date: ['']
  });
  getToday: any;
  getTodayDate: any;
  medicationDataValue: any = [];
  medicationId: any;
  medicationItem: any;
  notificationDate_Time: any;
  refreshReceived: any;
  public refreshPage: Subscription;
  public newitem: boolean = false;
  tabledata: any;
  caregiverDataVal: any;
  public showcaregiverHidtory: boolean = false;
  sort: MatSort = new MatSort;
  tagdialogopen: any;
  showHistory: boolean = false;

  max_reserve_pump_count: any;
  min_current_pump_count: any;
  min_reserve_pump_count: any;
  max_current_pump_count: any;
  user_selected_new_current_pump: any;

  public selectNewCurrentPump = this.fb.group({
    new_current_pump: [null, Validators.required],
  });

  @ViewChild('selectCurrentPump') newCurrentPumpDialog: TemplateRef<MatDialog>;
  refDeletePumpID: any;
  user_selected_new_current_pump_id: any;
  user_selected_new_current_pump_status: any;
  selectNewCurrentPumpDropDown: any;
  mediaUploadsize: any;
  campaign_id: string;
  selectedDoc:any;
  doclist: any;
  appMasterData: any;
  mediaUploadSize: string;
  enableDoctorVist: boolean;



  constructor(@Inject(MAT_DIALOG_DATA) public medicineID: any, private UserdataService: UserdetailsService, private route: ActivatedRoute, public router: Router, private SystemService: SystemService,
    public fb: FormBuilder, public dialog: MatDialog, private appContext: ContextService,
    private userDetailService: UserdetailsService, private socketService: SocketService,
    private UserlistService: UserlistService, private systemService: SystemService,
    private commonService: CommonserviceService, private snackBar: MatSnackBar, private contentService: ContentserviceService,) {
    this.refreshPage = this.commonService.getRefresh().subscribe
      (message => { //message contains the data sent from service
        this.refreshReceived = message;

        this.initComponentDraw(this.appContext.selectedChatUser.getValue());
      });
    localStorage.removeItem("eyeIconDisplay");
    // window.onunload = function () {
    //   localStorage.removeItem("notifyPatientName");
    //   localStorage.removeItem("selectedNotification");
    // }
  }

  ngOnInit() {
    this.loadInitialData();
    this.searchcriteria = '';
    this.page = 1;
    this.campaign_id = localStorage.getItem('campaign_id').toString();
  }
  loadInitialData() {
    localStorage.removeItem("eyeIconDisplay");
    let selectedPatientName = localStorage.getItem('selectedPatientName');

    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {

      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        // let val = null;
        // this.onSelectChange(val);
        this.selected = "prescription";
        this.notificationDate_Time = false;
        this.getAppMasterData();
        this.resetData();
        this.initComponentDraw(this.appContext.selectedChatUser.getValue());
        this.initComponentDrawDetails(this.appContext.selectedChatUser.getValue());
        this.getRemainder();
        this.getUserPumps();
        this.getUserMedication();
        this.getSymptoms(1);
        this.caregiverButtonDisplay = false;
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        // this.getMedicalHistory();
      }

      this.route.params.subscribe(async params => {
        if (params.id == 'new') {
          this.newitem = true;
        }
        else {
          this.newitem = false;
        }
      })

      let patientName = localStorage.getItem("notifyPatientName");


      let selectedNotificationData = localStorage.getItem("selectedNotification");
      let notificationSelectedPatientName = localStorage.getItem('notifyPatientName');
      // let notificationStoredValue = localStorage.getItem("selectedNotification");
      // if (selectedPatientName != notificationSelectedPatientName) {
      //   this.resetData();
      //   this.notificationDate_Time = false;
      //   localStorage.removeItem('selectedPatientName');
      //   localStorage.removeItem('notifyPatientName');
      //   localStorage.removeItem("selectedNotification");
      // } else {

      if (selectedNotificationData == "medication_survey_added") {
        this.selected = "medicationSurvey";
        let val = 'medicationSurvey'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getMedicationSurvey()
      }
      else if (selectedNotificationData == "medication_added_event" || selectedNotificationData == "medication_updated_event") {
        this.selected = "medication";
        let val = 'medication'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getUserMedication()
      }
      else if (selectedNotificationData == "prescription_upload" || selectedNotificationData == "prescription_update") {
        this.selected = "prescription";
        let val = 'prescription'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.initComponentDraw(this.selectedUserID)
      }
      else if (selectedNotificationData == "reminder_added_event" || selectedNotificationData == "reminder_updated_event" ||
        selectedNotificationData == "medicine_added_event" || selectedNotificationData == "medicine_updated_event") {
        this.selected = "reminder";
        let val = 'reminder'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getRemainder()
      }
      else if (selectedNotificationData == 'caregiver_added' || selectedNotificationData == 'caregiver_updated') {
        this.selected = "caregiver";
        let val = 'caregiver'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.initComponentDraw(this.selectedUserID);
      }
      else if (selectedNotificationData == 'doctor_assignment_event' || selectedNotificationData == 'doctor_reassignment_event') {
        this.selected = "doctor";
        let val = 'doctor'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.initComponentDraw(this.selectedUserID);
      }
      else if (selectedNotificationData == 'trigger_event') {
        this.selected = "trigger";
        let val = 'trigger'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getTriggers(1);
      }
      else if (selectedNotificationData == 'questionnaire_event') {
        this.selected = "questionnaire";
        let val = 'questionnaire'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getQuestionnaires(1);
      }
      else if (selectedNotificationData == 'symptom_event') {
        this.selected = "symptom";
        let val = 'symptom'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getSymptoms(1);
      }
      else if (selectedNotificationData == 'adverse_reaction_event') {
        this.selected = "adr";
        let val = 'adr'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        var data: any = {
          "remodulin": true,
          "nonremodulin": false
        }
        this.getAdrfRemodulin(data, this.page);
      }
      else if (selectedNotificationData == 'pump_added_event' || selectedNotificationData == 'pump_updated_event') {
        this.selected = "pump";
        let val = 'pump'
        this.onSelectChange(val);
        this.notificationDate_Time = localStorage.getItem("createdDateTime");
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getUserPumps()
      }
      //}

    });

  }

  caregiverHistory() {
    this.tagdialogopen = this.dialog.open(CaregiverhistoryComponent, {
      width: "650px",
      height: "500px",
      data: {
        userid: this.selectedUserID,
      }
    });
    // this.tagdialogopen.afterClosed().subscribe(() => {
    //   scope.initComponentDraw(this.appContext.selectedChatUser.getValue());
    // });

  }


  checkNotification(created_at) {
    if (this.notificationDate_Time == created_at) {
      return true;
    }
    else {
      return false;
    }
  }

  remove(item, id): void {

    this.medicationItem = item;
    this.medicationId = id;
    let data = this.appContext.selectedChatUser.getValue();

    this.getallTagData(id, item)

  }
  getallTagData(ide, item) {
    this.UserdataService.getOneMedicationById(ide)
      .subscribe(
        (res: any) => this.getTagData(res, item),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getTagData(data, item) {
    const t = <FormArray>(this.dataForm.controls['time']);
    data = (data as any).data;

    this.medicationDataValue = data;

    // 
    // let findVal=this.medicationDataValue.find(element=>element.reminder_joined_data.time==item);
    //  
    // let obj = this.medicationDataValue.find(o => o.reminder_joined_data.time.t === item.t);
    this.removeByAttr(this.medicationDataValue.reminder_joined_data.time, 't', item.t);

    var newArray = [];


    newArray = this.medicationDataValue.reminder_joined_data.time.map(el => {
      return {
        "e": el.e,
        "t": el.t,
        "i": this.convertIsoDate1(el.i) + ""
      }
    });

    this.medicationDataValue.reminder_joined_data.time = JSON.stringify(newArray);

    this.dataObject(this.medicationDataValue, this.medicationId);
  }

  convertIsoDatetime1(d) {

    var date = new Date(d);

    const number = moment(date).format("HH:mm");

    return number;
  }

  convertIsoDate1(d) {
    var newdate = new Date(d);
    var date = newdate.toISOString();
    var isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return isoDate;
  }
  removeByAttr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {

        arr.splice(i, 1);

      }
    }
    return arr;
  }

  async dataObject(post, id) {


    var ids = post.reminder_joined_data.days.split(',').map(parseFloat);

    let createdBy = localStorage.getItem('scarletuserid');
    let postdata = {
      created_by_fk: createdBy + "",
      days: ids,
      dose: post.dose,
      dose_unit: post.dose_unit,
      drug_concentration: post.drug_concentration,
      drug_concentration_unit: post.drug_concentration_unit,
      flow_rate: post.flow_rate,
      flow_rate_unit: post.flow_rate_unit,
      is_custom_interval: "false",
      language_data_fk: post.medication_joined_data.language_data_fk + "",
      medication_id_fk: post.medication_id_fk + "",
      medication_route_type: post.medication_route_type,
      patient_id_fk: post.patient_id_fk + "",
      time: post.reminder_joined_data.time ? post.reminder_joined_data.time : null
    }
    postdata.days = JSON.stringify(postdata.days);


    this.UserdataService.updateMedication(id, postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {


        this.getUserMedication();
        this.systemService.showSnackBar("Medication updated sucessfully.", "OK");
        this.showButtonLoader = false;
      })
    this.getUserMedication();
    await this.sleep(1000)
  }

  public onSaveUsernameChanged(value: boolean, id) {

    this.saveUsername = value;

    this.medicationId = id;
    let data = this.appContext.selectedChatUser.getValue();

    this.getallTagData1(id, value)
  }

  getallTagData1(ide, item) {

    this.UserdataService.getOneMedicationById(ide)
      .subscribe(
        (res: any) => this.getTagData1(res, item),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getTagData1(data, item) {

    const t = <FormArray>(this.dataForm.controls['time']);
    data = (data as any).data;

    this.medicationDataValue = data;

    var newArray = [];

    newArray = this.medicationDataValue.reminder_joined_data.time.map(el => {
      return {
        "e": this.saveUsername,
        "t": el.t,
        "i": this.convertIsoDate1(el.i) + ""
      }
    });
    this.medicationDataValue.reminder_joined_data.time = JSON.stringify(newArray);

    this.dataObject(this.medicationDataValue, this.medicationId);
  }


  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  initComponentDrawDetails(selectedUserID: string) {
    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**Call API */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      data = (data == null) ? [] : data;
      //data = [];
      this.userDetailsSubject$.next(data);
      this.userDataQ = data;
      this.userClientDetails = data?.patient_client_data?.title;
      this.is_remodulin = data?.is_remodulin;
      this.max_reserve_pump_count = data?.patient_client_data?.max_reserve_pump_count;
      this.min_reserve_pump_count = data?.patient_client_data?.min_reserve_pump_count;

      this.max_current_pump_count = data?.patient_client_data?.max_current_pump_count;
      this.min_current_pump_count = data?.patient_client_data?.min_current_pump_count;
      /**Change subject also */
      //this.appContext.isSelectedUserHasCKD.next((data.has_ckd ? data.has_ckd : false));
      /**false other subjects */
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
      let reminderValidate;
      let doctorVist;
      reminderValidate = data.profile_flags.includes("SRM");
      doctorVist = data.profile_flags.includes("SDV");
      if (reminderValidate === true) {
        this.enableReminder = true;
      } else if (reminderValidate === false) {
        this.enableReminder = false;
      }
      if (doctorVist === true) {
        this.enableDoctorVist = true;
      } else if (doctorVist === false) {
        this.enableDoctorVist = false;
      }
    });
  }


  imageData(path) {
    return 'this.s3url + path';
  }

  resetData() {
    this.doctorForm.reset();
    this.callogForm.reset();
    this.transactionslogForm.reset();
    this.caregiverForm.reset();
    this.calldata = [];
    this.allsymptoms = []
    this.medicineData = [];
    this.reminderData = [];
    this.pumpData = [];
    this.caregiverData = [];
    this.doctorData = [];
    this.alltransactions = [];
    this.infusiondataSource = [];
  }

  uploadObject() {
    this.bodydata = this.dataForm.value;
    this.bodydata.patient_id_fk = this.selectedUserID.toString();
    const momentDate = new Date(this.dataForm.value.test_date); // Replace event.value with your date value
    this.bodydata.test_date = moment(momentDate).format("YYYY-MM-DD");
    this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
    this.mediaUploadsize = JSON.parse(localStorage.getItem('mediaUploadSize'));

    this.demoForm.value.files.forEach(el => {
      console.log("el.size " + el.size );
      if (((el.size / (1024 * 1024)) > this.mediaUploadsize.video) && (el.type.includes('video'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.image) && (el.type.includes('image'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.document) && (el.type.includes('pdf'))) {
        this.isinvalid = true;
      }
      else {
        this.isinvalid = false;
        this.isloadingpres = false;
      }
    });
    if (this.isinvalid != true) {
      if (this.demoForm.status == 'INVALID') {
        this.isinvalid = true;
        this.isloadingpres = false;
      }
      else {
        this.isinvalid = false;
        this.allfiles = this.demoForm.value.files;
        this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];

        if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
          this.isloadingpres = false;
          this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
        }
        else {
          var imageinfo = {
            detailed_text: this.dataForm.value.name,
            title: this.dataForm.value.name,
            type: this.uploadedfiletype,
          }

          this.isloadingpres = true;
          var scope = this;
          var last = false;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloadingpres = true;
              imagedetails = "";
              imagedetails = imageinfo;
              if (scope.allfiles[i].type.includes('pdf')) {
                scope.prescription_type = 'prescription_pdf';
              }
              if (scope.allfiles[i].type.includes('image')) {
                scope.prescription_type = 'prescription_image';
              }
              scope.commonService.getS3URL(scope.prescription_type, scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.isloadingpres = false;
                          scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                        })
                  }, (error) => {
                    scope.isloadingpres = false;
                    scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                  });
            }
          }
        }
      }
    }

  }

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('prescription_image', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else if (ele.type.match('pdf')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.setAttribute('crossorigin', 'anonymous');
        tempImg.src = '/assets/img/pdficon.jpg';
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);

          scope.commonService.getS3URL('prescription_image', thtype, myFile.size, 'image.png')
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);

    }
    else {
      this.isloadingpres = true;
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, thumbpath, imageinfo) {
      if (scope.allfiles[0].type.includes('pdf')) {
        scope.upload_prescription_type = upload_type[0];
        scope.upload_doc_type = 'document';
      }
      if (scope.allfiles[0].type.includes('image')) {
        scope.upload_prescription_type = upload_type[1];
        scope.upload_doc_type = 'gallary';
      }
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, scope.upload_prescription_type, scope.upload_doc_type)
        .subscribe(
          (data) => {
            scope.resdata = data;
            scope.image_path = scope.resdata.data.path;
            scope.dataForm.reset();
            scope.demoForm.reset();
            scope.isloadingpres = false;
            scope.bodydata.prescription_ids = [scope.resdata.data.id];
            if (scope.resdata.status == 201) {
              scope.userDetailService.uploadPres(scope.bodydata).pipe(
                delay(100),
                catchError((error: any) => {
                  scope.loadingError$.next(true);
                  scope.isloadingpres = true;
                  return throwError(error);
                }),
                finalize(() => { scope.loadingSubject$.next(false); })
              ).subscribe(data => {
                scope.systemService.showSuccessSnackBar("Prescription has been uploaded", "Ok");
                scope.initComponentDraw(scope.appContext.selectedChatUser.getValue());
              })
            }
            else {
              scope.isloadingpres = true;
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          })
    }
  }

  dataURItoBlobb(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  convertIsoDatetime(d) {
    var date = new Date(d);
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleTimeString('en-US');
  }

  openSymptomDialog() {
    var scope = this;
    this.addremdata = this.dialog.open(AddsymptomsComponent, {
      width: '700px',
      height: '700px',
      data: {
        dataKey: scope.selectedUserID,
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getSymptoms(1)
    });
  }
  openReminderDialog(isReminder:any) {
    var scope = this;
    this.addremdata = this.dialog.open(AddreminderComponent, {
      width: '700px',
      height: 'auto',
      data: {
        dataKey: scope.selectedUserID,
        isReminder:isReminder
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
      this.allreminder = [];
      this.getRemainder();
    });
  }


  openTriggers() {
    var scope = this;
    this.addremdata = this.dialog.open(AlltriggersComponent, {
      width: '420px',
      height: '600px',
      data: {
        dataKey: scope.selectedUserID,
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  openLabDialog() {
    var scope = this;
    this.addmeddata = this.dialog.open(AddmedicineComponent, {
      width: '700px',
      height: '600px',
      data: {
        dataKey: scope.selectedUserID,
      }
    });

    this.addmeddata.afterClosed().subscribe(() => {
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  openPrescriptionDialog() {
    var scope = this;
    this.addremdata = this.dialog.open(AddprescriptionComponent, {
      width: '500px',
      height: 'auto',
      data: {
        dataKey: scope.selectedUserID,
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  initComponentDraw(selectedUserID: string) {

    this.updateDocData = false;
    this.loadingSubject$.next(true);
    /**Reinitialise prescription subject */
    this.prescriptionSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**API Call */
    this.userDetailService.getUserRemSettingDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {

      data = (data as any).data;
      data = (data == null) ? [] : data;
      let presRawData = data.prescription_data.filter(x => x.is_deleted == false);
      presRawData.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());


      this.medicineData = data.medicine_data;
      this.caregiverData = data.caregiver_data;
      this.caregiverHistoryData = this.caregiverData?.caregiver_history;
      this.caregiverId = this.caregiverData?.id;
      this.caregiverButtonDisplay = false;
      if (this.caregiverData != undefined) {
        this.caregiver = true;

      } else if (this.caregiverData == undefined) {
        this.caregiver = false;
      }
      this.doctorData = '';
      this.doctorData = data.doctor_data;
      this.tempDocData = this.doctorData;
      this.prescriptionFilterData = data;
      if (!data.prescriptions) {
        this.prescriptionSubject$.next(data);
        this.loadingSubject$.next(false);
        this.loadingError$.next(false);
      }

      /**Check for empty prescription data again for no data loader */
      this.presData = [];
      // this.presDataArray = [];
      if (presRawData) {
        for (var i = 0; i < presRawData.length; i++) {
          presRawData[i].prescription_ids.forEach(element => {
            this.presData.push({
              'id': presRawData[i].id,
              'date': presRawData[i].updated_at,
              'path': element.path,
              'type': element.media_type,
              'mime': element.mime,
              'name': presRawData[i].name
            })
          })
          if (i == (presRawData.length - 1)) {
            this.presData.forEach(elem => {
              if (elem['path'] && elem['mime']) {
                this.geturl(elem['path'], elem['mime'], datap => {
                  elem['url'] = datap;
                });
              }
            });
          }
        }
      }
    });
    this.presDataArray = [];

  }



  onSelectChange(e) {
    this.getDoctorList();
    this.pageno = 1;
    this.showmore = false;
    if (e.value == 'symptom') {
      this.getSymptoms(1);
    }
    if (e.value == 'trigger') {
      this.getTriggers(1);
    }
    if (e.value == 'questionnaire') {
      this.getQuestionnaires(1);
    }
    if (e.value == 'reminder') {
      this.getRemainder();
    }
    if (e.value == 'pump') {
      this.getUserPumps();
    }
    if (e.value == 'medication') {
      this.getUserMedication();
    }
    if (e.value == 'medicalhistory') {
      this.getHospitalization();
    }
    if (e.value == 'roa') {
      this.getRouteOfAdmin();
    }
    if (e.value == 'pumphistory') {
      this.gethistoricalPumps();
      this.getPumpData();
    }
    if (e.value == 'adr') {
      var data = {
        "remodulin": true,
        "nonremodulin": false
      }
      this.getSymptoms(1);
      this.getAdrfRemodulin(data, this.pageno);
    }
    if (e.value == 'nonadr') {
      var data = {
        "remodulin": false,
        "nonremodulin": true
      }
      this.getAdrfRemodulin(data, this.pageno);
    }
    if (e.value == 'hospitalisation') {
      this.getHospitalization();
    }

    if (e.value == 'medicationSurvey') {
      this.getMedicationSurvey();
    }
    if (e.value == 'caregiver') {
      this.geCaregiverdata();
    }
    if (e.value == 'doctorVisit') {
      this.getRemainder();
    }
    // this.showcaregiverHidtory = false
  }
  geCaregiverdata() {
    this.userDetailService.getUserRemSettingDetails(this.selectedUserID)
      .subscribe(
        (res: any) => this.caregiverHistorySuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  caregiverHistorySuccess(res: any): void {
    let response = (res as any).data;
    this.caregiverDataVal = response.caregiver_data.caregiver_history;
    if (this.caregiverDataVal !== null || this.caregiverDataVal === undefined) {
      this.showHistory = true;
    }
  }

  getRemainder() {

    this.userDetailService.getUserRemSettingDetails(this.selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {

      data = (data as any).data;
      this.reminderData = data.reminder_data
      
      for (var j = 0; j < this.reminderData.length; j++) {
        this.allreminder.push(this.reminderData[j]);
      }
    });

  }



  // Pump
  getUserPumps() {
    let data = {
      "patient_id_fk": this.appContext.selectedChatUser.getValue().toString()
      // "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString()

    }
    this.userDetailService.getPumps(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);

        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      this.pumpTableData = data;
      this.pumpTableData = this.pumpTableData.filter(a => a.is_deleted == false);
      this.pumpTableData = this.pumpTableData.sort((r1, r2) => (r1.pump_status > r2.pump_status) ? 1 : (r1.pump_status < r2.pump_status) ? -1 : 0);
      this.selectNewCurrentPumpDropDown = this.pumpTableData.filter(pump => pump.pump_status !== 1)
      this.dataSource = new MatTableDataSource(this.pumpTableData);
    });

  }

  deletePump(id: any, pump_status: any) {
    this.refDeletePumpID = id
    this.pumpStatusChangeType(id, pump_status)


  }


  // Get Pump Details

  viewPump(id) {

    this.dialog.open(PumpComponent, {
      width: '530px',
      data: id
    });
  }

  // Medication

  getUserMedication() {


    let data = {
      "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
      "language_data_fk": '1'

    }
    this.userDetailService.getMedicationdata(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);

        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      var date = new Date();
      this.getTodayDate = date.toISOString();


      data = (data as any).data;
      this.medicationData = data;
      this.medicationData = this.medicationData.filter(a => a.is_deleted == false)

    });

  }

  deleteUserMedication(id) {

    // 
    if (confirm("Are you sure you want to delete the Medication?")) {
      this.userDetailService.deleteMedication(id)
        .subscribe(data => {
          this.systemService.showSnackBar("Medication has been Deleted", "OK");
          this.getUserMedication();
        })
    }

  }

  goNextPage(e) {
    this.pageno = this.pageno + 1;
    if (e == 'symptom') {
      this.getSymptoms(this.pageno);
    }
    if (e == 'trigger') {
      this.getTriggers(this.pageno);
    }
    if (e == 'questionnaire') {
      this.getQuestionnaires(this.pageno);
    }
    if (e == 'adr') {
      var data = {
        "remodulin": true,
        "nonremodulin": false
      }
      this.getAdrfRemodulin(data, this.pageno);
    }
  }

  convertdate(d) {
    for (var i = 0; i < d.length; d++) {
      if (d == 1) {
        return 'Monday'
      }
      if (d == 2) {
        return 'Tuesday'
      }
      if (d == 3) {
        return 'Wednesday'
      }
      if (d == 4) {
        return 'Thursday'
      }
      if (d == 5) {
        return 'Friday'
      }
      if (d == 6) {
        return 'Saturday'
      }
      if (d == 7) {
        return 'Sunday'
      }
    }

  }

  getSymptoms(pageno) {
    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString()
    }
    this.userDetailService.getMisc(data, 'symptoms').pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      if (data.length < 10) {
        this.showmore = false;
      }
      else {
        this.showmore = true;
      }
      if (this.pageno == 1 || this.pageno == undefined) {
        this.allsymptoms = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.allsymptoms.push(data[k]);
        }
      }

      // const modified = this.allsymptoms.map(function (ComparisonResult) {
      //   ComparisonResult.symptom_ids = ComparisonResult.symptom_ids.filter(x => x.value != 'none');
      //   return ComparisonResult;
      // });





    })

  }

  getTransactions(pageno) {

    var postdata = this.transactionslogForm.value;
    var scope = this;
    var startd = null;
    var endd = null;
    startd = postdata.startdate;
    endd = postdata.enddate;
    startd.setHours(0, 0, 0, 0);
    endd.setHours(23, 59, 59, 99);
    startd = moment(startd).toISOString();
    endd = moment(endd).toISOString();

    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString(),
      "transaction_type": "all",
      "start_time": startd,
      "end_time": endd
    }
    this.userDetailService.getMisc(data, 'transactions').pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;

      if (data.length < 10) {
        this.showmore = false;
      }
      else {
        this.showmore = true;
      }
      if (this.pageno == 1 || this.pageno == undefined) {
        this.alltransactions = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.alltransactions.push(data[k]);
        }
      }

    })
  }
  getTriggers(pageno) {
    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString()
    }
    this.userDetailService.getMisc(data, 'triggers').pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      if (data.length < 10) {
        this.showmore = false;
      }
      else {
        this.showmore = true;
      }
      if (this.pageno == 1 || this.pageno == undefined) {
        this.alltriggers = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.alltriggers.push(data[k]);
        }
      }
    })

  }
  getQuestionnaires(pageno) {
    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString()
    }
    this.userDetailService.getMisc(data, 'questionnaire').pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      if (data.length < 10) {
        this.showmore = false;
      }
      else {
        this.showmore = true;
      }
      if (this.pageno == 1 || this.pageno == undefined) {
        this.allQuestionnaire = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.allQuestionnaire.push(data[k]);
        }
      }
    })

  }
  isArray(obj: any) {
    return Array.isArray(obj)
  }
  applylogfilter() {
    var postdata = this.callogForm.value;
    var scope = this;
    var startd = null;
    var endd = null;
    startd = postdata.startdate;
    endd = postdata.enddate;
    startd.setHours(0, 0, 0, 0);
    endd.setHours(23, 59, 59, 99);
    startd = moment(startd).format("YYYY-MM-DD HH:mm:ss+05:30");
    endd = moment(endd).format("YYYY-MM-DD HH:mm:ss+05:30");
    let localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.getUserPhonenumber(scope.appContext.selectedChatUser.getValue(), data => {
      this.userDetailService.getcalllogs(endd, startd, localStoredHCData.phone, '+' + data.country_code + data.phone)
        .subscribe(data => {
          this.calldata = data.objects;
        })
    });
  }
  getUserPhonenumber(id, cb) {
    this.UserlistService
      .getGraphQlData('{getOnePatient(appMode:false,patientId:' + id + '){country_code,phone}}')
      .pipe(
        catchError((error: any) => {
          this.SystemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        cb(data.getOnePatient)
      })
  }
  convertDate(d) {
    var date = new Date(d);
    return date.toLocaleString();
  }
  geturl(p, t, cb) {
    this.socketService.getfileurl(p, t, data => {
      cb(data);
    })
  }
  careObject() {
    var postdata = this.caregiverForm.value;
    postdata.name = postdata.name.toString();
    postdata.phone = postdata.phone.toString();
    postdata.relation = postdata.relation.toString();
    postdata.country_code = postdata.country_code.toString();
    postdata.email = postdata.email.toString();
    postdata.gender = postdata.gender.toString();
    // postdata['created_by_fk'] = localStorage.getItem('scarletuserid');
    postdata['updated_by_fk'] = localStorage.getItem('scarletuserid');
    // let userIdFk= JSON.parse(localStorage.getItem('scarletuserdata')) ;

    // return;

    if (this.careEditData == false) {
      this.userDetailService.addDetails(postdata, this.appContext.selectedChatUser.getValue().toString(), 'caregiver')
        .pipe(
          catchError((error: any) => {
            this.SystemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
          })
        ).subscribe(data => {
          this.SystemService.showSnackBar("Caregiver details has been updated", "OK");
          this.sendMqttRequest('Caregiver');
          this.initComponentDraw(this.appContext.selectedChatUser.getValue());
          this.sendMqttRequest('Caregiver');
          // this.caregiverForm.resetForm();
          this.caregiverForm.reset();
        });
    } else {
      this.userDetailService.EditDetails(this.caregiverId, postdata, this.appContext.selectedChatUser.getValue().toString(), 'caregiver')
        .pipe(
          catchError((error: any) => {
            this.SystemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
          })
        ).subscribe(data => {
          this.SystemService.showSnackBar("Caregiver details has been updated", "OK");
          this.sendMqttRequest('Caregiver');
          this.initComponentDraw(this.appContext.selectedChatUser.getValue());
          // this.caregiverForm.resetForm();
          this.caregiverForm.reset();
        });
    }

  }

  sendMqttRequest(type) {
    var obj = {
      link: "https://wellthy.page.link/B421",
      type: type
    }
    this.appContext.changeMessage(obj);
  }

  // ----------------------------


  // Submitting Tag Data
  // ----------------------------

  docObject() {
    var docdata = this.doctorForm.value;
    let postdata = {}

    postdata['user_code'] = docdata.doctorCode;
    postdata['patient_data_fk'] = this.appContext.selectedChatUser.getValue().toString();
    postdata['assigned_by_data_fk'] = localStorage.getItem('scarletuserid');

    this.userDetailService.patietDocMapping(postdata)
      .subscribe(
        (res: any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onCreateSuccess() {
    this.SystemService.showSuccessSnackBar("Doctor has been updated", "OK");
    this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    this.doctorForm.reset();
  }

  private onUpdateSuccess() {
    this.SystemService.showSuccessSnackBar("Doctor has been updated", "OK");
    this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    this.doctorForm.reset();
  }

  // ----------------------------


  // docObject() {
  //   var docdata = this.doctorForm.value;
  //   docdata.doctorCode = docdata.doctorCode;
  //   if (!this.updateDocData) {
  //     this.userDetailService.addDocDetails(docdata, this.appContext.selectedChatUser.getValue().toString(), 'doc_code')
  //       .pipe(
  //         catchError((error: any) => {
  //           this.SystemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
  //           return throwError(error);
  //         }),
  //         finalize(() => {
  //         })
  //       ).subscribe(data => {
  //         this.SystemService.showSuccessSnackBar("Doctor has been updated", "OK");

  //         this.initComponentDraw(this.appContext.selectedChatUser.getValue());
  //         this.doctorForm.reset();
  //       });
  //   }
  //   else {
  //     this.userDetailService.updateDocDetails(docdata, this.appContext.selectedChatUser.getValue().toString(), 'doc_code', this.tempDocData.id.toString())
  //       .pipe(
  //         catchError((error: any) => {
  //           this.SystemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
  //           return throwError(error);
  //         }),


  //         finalize(() => {
  //         })
  //       ).subscribe(data => {
  //         this.SystemService.showSuccessSnackBar("Doctor has been updated", "OK");
  //         this.initComponentDraw(this.appContext.selectedChatUser.getValue());
  //         this.doctorForm.reset();
  //       });
  //   }

  // }

  updateDoc() {
    this.updateDocData = true;
    this.doctorData = '';
    this.tempDocData.doctorCode = this.tempDocData.code;
    this.doctorForm.patchValue(this.tempDocData);
  }

  gender(data) {
    if (data == 'm') {
      return 'Male';
    }
    else if (data == 'f') {
      return 'Female';
    }
    else {
      return 'Others';
    }
  }

  convertIsoData(d) {
    var date = new Date(d);
    let options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);

  }
  medData(d) {
    var x = d.toString().replace(/"/g, "");

    var date = new Date(x);
    let options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
  conversion(c) {
    if (c) {
      var x = c.toString().replace(/"/g, "");
      var color = x.replace(/[\[\]]+/g, '')
    }
    else {
      return 'No color';
    }

    return color;
  }
  duration(dur) {
    if (dur == 1) {
      return 'Daily';
    }
    else if (dur == 2) {
      return 'Alternate';
    }
    else {
      return dur + " days"
    }
  }

  preMeal(meal) {
    if (meal == true) {
      return 'Yes'
    }
    else
      return 'No'
  }
  dosage(dose) {
    var med_dose = dose.toString().replace(/[\[\]]+/g, '')
    return med_dose;
  }

  openDialogImg(url, img) {
    this.dialog.open(ShowmodalComponent, {
      width: 'auto',
      height: '80%',
      data: {
        dataurl: url,
        type: img
      }
    });
  }

  ngOnDestroy(): void {
    this.subscribeObj.unsubscribe();
    this.refreshPage.unsubscribe();
  }

  // Add Medication

  addMedication() {
    this.addremdata = this.dialog.open(MedicationaddComponent, {
      width: '420px',
      // height: '600px',
      data: {
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getUserMedication();
    });
  }

  editUserMedication(id) {
    this.addremdata = this.dialog.open(EditmedicationComponent, {
      width: '420px',
      // height: '600px',
      data: id
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getUserMedication();
    });
  }
  // Add Medical History
  addMedicalHistory() {
    this.addremdata = this.dialog.open(MedicalhistoryaddComponent, {
      width: '420px',
      // height: '600px',
      data: {
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getHospitalization();
    });
  }

  // Add Route Of Administration
  addROA() {
    var scope = this;
    this.addremdata = this.dialog.open(RouteofadminaddComponent, {
      width: '420px',
      // height: '600px',
      data: {
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getRouteOfAdmin();
    });
  }

  editROA(id) {

    this.addremdata = this.dialog.open(EditrouteofadminComponent, {
      width: '420px',
      data: id
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getRouteOfAdmin();
    });
  }

  // Add Hospitalisation

  addHospitalisation() {
    this.addremdata = this.dialog.open(AddhopitalisationComponent, {
      width: '420px',
      // height: '600px',
      data: {
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getHospitalization();
    });
  }


  // Add Infusion Site Changes
  addISC() {
    this.dialog.open(InfusionsiteaddComponent, {
      width: '420px',
      // height: '400px',
      data: {
      }
    });
  }



  addPump() {
    var scope = this;
    this.addremdata = this.dialog.open(PumpaddComponent, {
      width: '500px',
      height: '600px',
      data: {
        dataKey: scope.selectedUserID,
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getUserPumps();
    });
  }

  editPump(id) {
    this.addremdata = this.dialog.open(EdithcpumpComponent, {
      width: '500px',
      height: '600px',
      data: id
    });
    this.addremdata.afterClosed().subscribe(() => {
      this.getUserPumps();
    });
  }

  // Adverse Drug Reaction
  addADRF() {
    var scope = this;
    this.addremdata = this.dialog.open(AdversedrugaddmodalComponent, {
      width: '800px',
      // height: '600px',
      data: {
        dataKey: scope.selectedUserID,
      }
    });
    this.addremdata.afterClosed().subscribe(() => {
      var data = {
        "remodulin": true,
        "nonremodulin": false
      }
      this.getAdrfRemodulin(data, this.page);
    });
  }

  editADRF(id) {
    localStorage.removeItem("eyeIconDisplay");
    this.addremdata = this.dialog.open(EditAdverseEffectComponent, {
      width: '800px',
      data: id
    });
    this.addremdata.afterClosed().subscribe(() => {
      var data = {
        "remodulin": true,
        "nonremodulin": false
      }
      this.getAdrfRemodulin(data, this.page);
    });
  }

  editADRF1(id) {
    localStorage.setItem("eyeIconDisplay", "eyeIconDisplay");
    this.addremdata = this.dialog.open(EditAdverseEffectComponent, {
      width: '800px',
      data: id
    });
    this.addremdata.afterClosed().subscribe(() => {
      var data = {
        "remodulin": true,
        "nonremodulin": false
      }
      this.getAdrfRemodulin(data, this.page);
    });
  }



  //get InfusionSite
  getInfusionSite() {
    var postdata = this.infusionlogForm.value
    var startd = null;
    var endd = null;
    startd = postdata.start_date;
    endd = postdata.end_date;
    startd.setHours(18, 29, 59, 99);
    endd.setHours(18, 29, 59, 99);
    startd = moment(startd).subtract(30, 'minutes').subtract(18, 'hours').toISOString();
    endd = moment(endd).add(30, 'minutes').add(5, 'hours').toISOString();
    postdata.start_date = startd;
    postdata.end_date = endd;
    postdata.patient_id_data_fk = this.selectedUserID.toString();

    this.userDetailService.getInfusionSitedata(postdata)
      .pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false); })
      ).subscribe(data => {

        data = (data as any).data;
        this.InfusionSiteData = data;
        this.InfusionSiteData = new MatTableDataSource<Element>(this.InfusionSiteData);

        for (var j = 0; j < this.InfusionSiteData.length; j++) {
          this.allInfusions.push(this.InfusionSiteData[j]);

        }
      });
  }

  openDialog(id) {
    this.addremdata = this.dialog.open(DeleteAEComponent, {
      width: '500px',
      height: 'auto',
      data: id
    });
    this.addremdata.afterClosed().subscribe(() => {
      var data = {
        "remodulin": true,
        "nonremodulin": false
      }
      this.getAdrfRemodulin(data, this.page);
    });
  }

  // delete(id) {
  //   this.userDetailService.hardDelete(id)
  //     .subscribe(
  //       (res: any) => this.onDeleteSuccess(id),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  // }

  // private onDeleteSuccess(id) {
  //   for (let i = 0; i < this.InfusionSiteData.length; i++) {
  //     if (this.InfusionSiteData[i].id === id) {
  //       this.InfusionSiteData.splice(i, 1);
  //     }
  //   }
  //   // this.InfusionSiteData = new MatTableDataSource<Element>(this.InfusionSiteData);
  // }


  getMedication() {
    var postdata = this.infusionlogForm.value

    postdata.patient_id_fk = this.selectedUserID.toString();
    postdata.language_data_fk = '1'
    this.userDetailService.getInfusionSitedata(postdata)
      .pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false); })
      ).subscribe(data => {

        data = (data as any).data;
        this.MedicationData = data;

        for (var j = 0; j < this.MedicationData.length; j++) {
          this.allMedications.push(this.MedicationData[j]);

        }
      });
  }

  // getMedicalHistory() {
  //   this.userDetailService.getUserMedicalHistory(this.selectedUserID).pipe(
  //     delay(100),
  //     catchError((error: any) => {
  //       this.loadingError$.next(true);
  //       return throwError(error);
  //     }),
  //     finalize(() => { this.loadingSubject$.next(false); })
  //   ).subscribe((data: any) => {
  //     data = (data as any).data;
  //     this.MedicalData = data.data;

  //   });
  // }


  //get ROA
  getRouteOfAdmin() {
    var patient_id_data_fk = this.selectedUserID.toString();
    this.userDetailService.getROAData(patient_id_data_fk)
      .pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false); })
      ).subscribe(data => {

        data = (data as any).data;
        this.routeofAdminData = data;
        this.routeofAdminData = this.routeofAdminData.filter(a => a.is_deleted == false)

        for (var j = 0; j < this.routeofAdminData.length; j++) {
          this.allROA.push(this.routeofAdminData[j]);

        }
      });
  }

  openROADialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteROA(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Route of Administration has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  deleteROA(id) {
    this.userDetailService.roaDelete(id)
      .subscribe(
        (res: any) => this.onDeleteROASuccess(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDeleteROASuccess(id) {
    for (let i = 0; i < this.routeofAdminData.length; i++) {
      if (this.routeofAdminData[i].id === id) {
        this.routeofAdminData.splice(i, 1);
      }
    }
    this.getRouteOfAdmin();
    // this.routeofAdminData = this.routeofAdminData);
  }

  ConvertData(d) {
    return d.replace(/_/g, ' ');
  }
  capitalizeFl(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  // Pump
  gethistoricalPumps() {

    let data = {
      "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
      "limit": "10",
      "page": "1"
      // "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString()
    }
    this.userDetailService.gethistoricalPumps(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);

        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      this.pumphistoryData = data;

    });
  }


  getPumpData() {
    this.contentService.getGraphQlData('{getAllPump(status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.onPumpDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onPumpDataSuccess(data) {
    data = (data as any).data;
    this.allpumpdata = data.getAllPump;
    this.allpumpdata.forEach(element => {
      this.myCathash[element.id] = element.title;
    });
  }

  getPumpName(category) {
    category = this.myCathash[category];
    return category;

  }



  // Adverse Effect
  getAdrfRemodulin(adrf, page) {
    let data = {
      "patient_data_fk": this.appContext.selectedChatUser.getValue().toString(),
      "is_remodulin": adrf.remodulin.toString(),
      "is_nonremodulin": adrf.nonremodulin.toString(),
      "limit": "10",
      "page": page.toString()
    }
    this.userDetailService.getAdversedata(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);

        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe((data:any) => {
      data = (data as any).data;
      for (let i in data) {
        if(data[i].log_type === 'symptom logged')
        for (let j = 0 ; j <= this.allsymptoms.length ; j++) {
        data[i].symptom_list = this.allsymptoms[j].symptom_ids;
        delete this.allsymptoms[j];
        this.allsymptoms = this.allsymptoms.filter(Boolean);
        break;
        }
    }
    this.adrfRemodulinData = data;
      // var todayDate = new Date();
      // todayDate.setDate(todayDate.getDate())
      // this.getToday = todayDate.toISOString()
      // 
      // 
      // if (this.adrfRemodulinData.created_at != this.getToday) {
      //   this.taskComplete = true
      // }
      // let date=new Date();
      this.getToday = moment().startOf('day').toISOString();

    });

  }

  // 

  getHospitalization() {
    this.userDetailService.getUserMedicalHistory(this.selectedUserID)
      .subscribe(
        (res: any) => this.gethospitaizationData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private gethospitaizationData(data) {
    data = (data as any).data;

    if (data.id) {
      this.MedicalData = data;
      this.hospitalizationData = data.hospitalisations;
      this.showMedicalHistory = true;
    } else {
      this.showMedicalHistory = false;
    }
  }

  // getNextSet() {
  //   if (this.endoflist == false) {
  //     this.page = this.page + 1;
  //     // this.search();
  //   }
  // }

  deletePrescription(id) {

    var data = {
      "deleted_by_fk": localStorage.getItem('scarletuserid')
    }
    // 
    if (confirm("Are you sure you want to delete the Prescription?")) {
      this.userDetailService.deletePresc(id, data)
        .subscribe(data => {
          this.systemService.showSnackBar("Prescription has been Deleted", "OK");
          this.initComponentDraw(this.appContext.selectedChatUser.getValue());
        })
    }
  }

  // get medication survey
  getMedicationSurvey() {

    let data = {
      "patient_data_fk": this.selectedUserID.toString(),
      "page": "1",
      "limit": "10"

    }
    this.userDetailService.getMedicationSurveyData(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);

        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      this.surveyData = data;

      // for (var i = 0; i < this.surveyData.length; i++) {
      // this.surveyLength = this.surveyData[i].medication_data.filter(a => a.taken == true);
      // }

      // 
    });

    // let selectedNotificationData = localStorage.getItem("selectedNotification");
    // if (selectedNotificationData == "medication_survey_added")
    // {
    //   localStorage.removeItem("notifyPatientName");
    //   localStorage.removeItem("selectedNotification");
    // }

  }

  convertIsoDate(d) {
    var date = new Date(d);
    let options: any = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString("en-US", options);
  }


  sendMedSurvey(obj, id, val, itemId) {
    var medId = obj.find(b => b.id === id);
    if (medId) {
      medId.taken = val;
      medId.t = "10:56"
    }
    let data = {
      "patient_data_fk": this.selectedUserID.toString(),
      "medication_data": JSON.stringify(medId),
      "is_skipped": true.toString(),
      "date": "2020-12-10",
      "created_by_data_fk": localStorage.getItem('scarletuserid').toString()
    }

    this.userDetailService.getMedicationDatabyID(itemId, data)
      .subscribe(
        (res: any) => this.onSurveySuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private onSurveySuccess() {
    this.getMedicationSurvey();
  }



  getallAdverseData(id) {
    this.userDetailService.getOneAdversedata(id)
      .subscribe(
        (res: any) => this.onAdverseSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onAdverseSuccess(data) {
    this.openEmailDialog(data);
  }

  openEmailDialog(data) {
    this.emaildata = this.dialog.open(AEMailComponent, {
      width: '500px',
      height: 'auto',
      data: {
        dataKey: data,
      }
    });
    this.emaildata.afterClosed().subscribe(() => {
    });
  }

  covertTime(date) {
    // var newDate = new Date(date);
    // var convertedDate = newDate.toLocaleString('en-US', { timeZone: this.timeZone })
    // return convertedDate
    var newDate = new Date(date);
    newDate.toLocaleString();
    // 
    return newDate;
  }

  EditCareGiver(data) {

    this.caregiverData = undefined;
    this.caregiverButtonDisplay = true;
    // this.caregiver = false;
    this.careEditData = true;
    this.showcaregiverHidtory = false;
    this.caregiverForm.patchValue(data);
    // this.caregiverForm.controls.relationship.patchValue(data.relation);
    //this.dataForm.controls.relationship.patchValue((data.relation).toString());

  }
  closeForm() {
    if (this.caregiverButtonDisplay === false) {
      this.caregiverButtonDisplay = false;
    }
    else if (this.caregiverButtonDisplay === true) {
      this.caregiverButtonDisplay = true;
    }
    this.initComponentDraw(this.selectedUserID);
  }
  pumpStatusChangeType(user_selected_pump_id: any, user_selected_pump_status: any) {
    //  ******  Current  Status ********
    if (user_selected_pump_status === 1 || user_selected_pump_status === "1") {
      let header: any = 'Kindly select New Current Pump to delete the existing'
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.pumpTableData))
      localStorage.setItem('modal_header', header);
      const dialogRef = this.dialog.open(DeletemodalComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.selectNewCurrentPump = new FormGroup({
            new_current_pump: new FormControl("", ([
              Validators.required
            ])),
          });
          this.dialog.open(this.newCurrentPumpDialog);

        } else {
          this.dialog.closeAll();
        }
      });

    }
    // ******  Reserve  Status ********
    else if (user_selected_pump_status === 2 || user_selected_pump_status === "2") {
      let header: any = 'Are you sure you want to delete this pump?'
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.pumpTableData))
      localStorage.setItem('modal_header', header);
      const dialogRef = this.dialog.open(DeletemodalComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          for (let i = 0; i < userfullpumpdata.length; i++) {
            if (userfullpumpdata[i].id === user_selected_pump_id) {
              this.userDetailService.deletePump(userfullpumpdata[i].id).pipe().toPromise()
                .then((data => {
                  this.systemService.showSuccessSnackBar("Pump has been Deleted", "Ok");
                  this.getUserPumps();
                  this.dialog.closeAll();
                }));
            }
          }
        } else {
          this.dialog.closeAll();
        }
      });
    }
    // ****** Disable Sttus  ******
    else if (user_selected_pump_status === 3 || user_selected_pump_status === "3") {
      let header: any = 'Are you sure you want to delete this pump?'
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.pumpTableData))
      localStorage.setItem('modal_header', header);
      const dialogRef = this.dialog.open(DeletemodalComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          for (let i = 0; i < userfullpumpdata.length; i++) {
            if (userfullpumpdata[i].id === user_selected_pump_id) {
              this.userDetailService.deletePump(userfullpumpdata[i].id).pipe().toPromise()
                .then((data => {
                  this.systemService.showSuccessSnackBar("Pump has been Deleted", "Ok");
                  this.getUserPumps();
                  this.dialog.closeAll();
                }));
            }
          }
        } else {
          this.dialog.closeAll();
        }
      });
    } else {
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.pumpTableData))
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].id === user_selected_pump_id) {
          this.userDetailService.deletePump(userfullpumpdata[i].id).pipe().toPromise()
            .then((data => {
              this.systemService.showSuccessSnackBar("Pump has been Deleted", "Ok");
              this.getUserPumps();
              this.dialog.closeAll();
            }));
        }
      }
    }
  }
  buildObject(data: any, pump_type: any, user_selected_pump_status: any) {
    let tempData: any;
    tempData = { ...data };
    if (pump_type === 'current') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.add_pump_status;
      delete tempData.valid_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();
      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
        let t = parseInt(tempData?.time[0].t)
        var time = this.convertIsoDatetime(t);
        var d = new Date(t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      } else {
        tempData.time = tempData.time?.toString();
      }
      tempData.pump_status = '1';
    } else if (pump_type === 'reserve') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.add_pump_status;
      delete tempData.valid_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();
      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
        let t = parseInt(tempData?.time[0].t)
        var time = this.convertIsoDatetime(t);
        var d = new Date(t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      } else {
        tempData.time = tempData.time?.toString();
      }

      tempData.pump_status = '2';
    } else if (pump_type === 'disabled') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.temperature;
      delete tempData.add_pump_status;
      delete tempData.valid_pump_status;


      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();
      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
        let t = parseInt(tempData?.time[0].t)
        var time = this.convertIsoDatetime(t);
        var d = new Date(t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      } else {
        tempData.time = tempData.time?.toString();
      }
      tempData.pump_status = '3';
    }
    return tempData;
  }
  getROAType(allROA) {
    this.user_selected_new_current_pump_id = allROA.id;
    this.user_selected_new_current_pump_status = allROA.pump_status;
  }
  onSelectingNewPump() {
    if (this.selectNewCurrentPump.value.new_current_pump !== '' || this.selectNewCurrentPump.value.new_current_pump !== null || this.selectNewCurrentPump.value.new_current_pump !== undefined) {
      // check for current pump by id and delete it 
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.pumpTableData))
      let formObject;
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].id === this.refDeletePumpID) {
          this.userDetailService.deletePump(userfullpumpdata[i].id).pipe().toPromise()
            .then((data => {
              // Update user selected new current Pump ID
              for (let i = 0; i < userfullpumpdata.length; i++) {
                if (userfullpumpdata[i].id === this.user_selected_new_current_pump_id) {
                  formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                  this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                    .then((data => {
                      this.systemService.showSuccessSnackBar("Pump has been Deleted", "Ok");
                      this.getUserPumps();
                      this.dialog.closeAll();
                    }));
                }
              }
            }));
        }
      }
    }

  }
  onSelectDoctorChange(event){
    console.log(event);
  }
  getDoctorList(){
    this.commonService.getAllData(this.searchcriteria, this.page.toString(), "20", "active", "users", "doctor")
    .subscribe(
      (res: any) => this.onSearchDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  onSearchDataSuccess(data) {
    data = data.data;
    this.campaign_id = localStorage.getItem('campaign_id').toString();
    let arrDoclist = [];
    for (let i= 0; i<data.length; i++) {
      if (data[i].campaign_data === this.campaign_id) {
         arrDoclist.push(data[i])
      }
  }
  this.doclist = [...new Set(arrDoclist)]
}
getAppMasterData() {
  this.commonService.getAppMasterData()
    .subscribe(
      (res: any) => this.getAppMasterSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
}

private getAppMasterSuccess(data: any) {
  this.appMasterData = data;
  for (var i = 0; i <= this.appMasterData.length; i++) {
    if (this.appMasterData[i] && this.appMasterData[i].key == 'ALLOWED_ATTACHMENT_MAXIMUM_SIZE') {
      this.mediaUploadSize = JSON.stringify(this.appMasterData[i].value);
      localStorage.setItem('mediaUploadSize',this.mediaUploadSize);
  }
  }
}
}

export enum relationship {

}

export enum upload_type {
  prescription_pdf,
  prescription_image
}

