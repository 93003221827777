/* ==========================================
Title:  HC profile
Author: Akash
Date:   16 Apr 2020
Last Change :  Added knowlarity call logs
=========================================== */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { PersistenceService, StorageType } from 'angular-persistence';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { setDarkTheme } from 'src/app/utility/utils';
import { environment } from '../../../environments/environment';
import { Userlistinterface } from '../models/userlistinterface';
import { SupportService } from '../services/support/support.service';
import { UserdetailsService } from '../services/userdetails/userdetails.service';
import { UserlistService } from '../services/userlist/userlist.service';




@Component({
  selector: 'app-hcprofile',
  templateUrl: './hcprofile.component.html',
  styleUrls: ['./hcprofile.component.scss']
})

export class HcprofileComponent implements OnInit {
  profileselection: any = "summary";
  isAccountActive: boolean = true;
  public hcFilterData: Userlistinterface[] = [];
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public loadingError$ = new Subject<boolean>();
  public localStoredHCData: any;
  s3url: any = environment.s3url;
  public dummyImage: string = "assets/img/imgplaceholder.svg";
  public imageurl: string;
  public responseImageUrl: any;
  public timelogdata: any;
  public todaytimelogdata: any;
  public timedata: any;
  public todaytimedata: any;
  currentdate: any;
  newDate: any;
  public calldata: any;
  hrCalculate: any;
  public morrow: any = new Date();
  public todaycount: number = 1;
  public genericcount: number = 1;
  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });
  public callogForm = this.fb.group({
    startdate: [''],
    enddate: ['']
  });
  loggedData: any;
  adminLogTime: any;
  loggedTodayTime: any;
  isDark = sessionStorage.getItem('dark');
  public isThemeDark = false;
  breaktime: any;
  constructor(
    public browserTitle: Title,
    public fb: FormBuilder, private persistenceService: PersistenceService, private authService: AuthenticateService,
    private userlistService: UserlistService, private systemService: SystemService,
    private SupportService: SupportService, private UserdetailService: UserdetailsService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Profile');
  }


  ngOnInit() {
    this.checktimelog();
    
    if (this.isDark == 'true') {
      this.isThemeDark = true;
    } else {
      this.isThemeDark = false;
    }
    this.setSEOTags();

    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    /**Load HC data now */
    // this.loadHCData();
    // this.morrow.setDate(this.morrow.getDate());
    this.getTodaysdata();
  }

  checktimelog() {
    this.SupportService.current____timelogData1.subscribe(
      (response: any) => {
        if (response == null) {
          return;
        } else {
          
          this.loggedData = response;
          let hour: any = (this.loggedData * 6) / 60;
          let min: any = (this.loggedData * 6) % 60
          let resultHR: any = this.getroundown(hour);
          let data1: any = resultHR.toString();
          let data2: any = min.toString();
          if (data1.length == 1) {
            resultHR = "0" + resultHR;
          }
          if (data2.length == 1) {
            min = "0" + min;
          }
          this.loggedData = resultHR + " Hrs " + min + " Min";
          
        }
      },
      (error: any) => {

      },
      () => { }
    )
  }
  logoutAccount(): void {
    /**Clear all session storage first */
    this.persistenceService.removeAll(StorageType.SESSION);
    /**Now Logout */
    this.authService.logout()
      .pipe(
        catchError((error: any) => {
          window.location.reload();
          this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        finalize(() => {

        })
      )
      .subscribe(data => {
        this.systemService.showSuccessSnackBar(("Logout Successfully"), "OK");
        sessionStorage.clear();
        sessionStorage.removeItem('onSelectNotifyUserid');
        sessionStorage.removeItem('onSelectNotifyUsername');
        window.location.reload();
      });
  }

  loadHCData(): void {
    this.loadingSubject.next(false);
    this.userlistService.getHealthCoachUserList().pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingSubject.next(true);
        this.systemService.showSnackBar("Sorry, unable to load health coaches.", "Ok");
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject.next(true); })
    ).subscribe(data => {
      data = (data as any).data;
      data = data.filter(hc => { return hc.id == this.localStoredHCData.parentID });
      data = data[0];
      this.isAccountActive = data.is_active;
      this.localStoredHCData = data;
      this.loadingSubject.next(true);
      this.hcFilterData = data;
    });
  }

  applylogfilter() {
    var postdata = this.callogForm.value;
    var startd = null;
    var endd = null;
    startd = postdata.startdate;
    endd = postdata.enddate;
    startd.setHours(0, 0, 0, 0);
    endd.setHours(23, 59, 59, 99);
    startd = moment(startd).format("YYYY-MM-DD HH:mm:ss");
    endd = moment(endd).format("YYYY-MM-DD HH:mm:ss");
    this.UserdetailService.getcalllogs(endd, startd, this.localStoredHCData.phone, null)
      .subscribe((data: any) => {
        //data = (data as any).data;
        this.calldata = data.objects;
      })
  }
  getroundown(a) {
    return Math.floor(a);
  }
  updateAccountStatus(): void {
    var answer;
    if (this.isAccountActive == true) {
      answer = confirm("Are you sure you want to deactivate?");
    } else {
      answer = true;
    }

    if (answer) {
      this.loadingSubject.next(false);
      this.userlistService.updateAccountStatus(!this.isAccountActive).pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingSubject.next(true);
          this.systemService.showSnackBar("Sorry, unable to update account status.", "Ok");
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject.next(true); })
      ).subscribe(data => {
        this.loadingSubject.next(false);
        if (data.code == 200) {
          this.systemService.showSnackBar(data.message, "OK");
          this.isAccountActive = !this.isAccountActive;
        } else {
          this.systemService.showSnackBar(data.message, "OK");
        }
      });
    }
  }

  getNextSet() {
    this.genericcount++;
    this.applymainfilter()
  }
  gettodayNextSet() {
    this.todaycount++;
    this.getTodaysdata()
  }
  applymainfilter() {
    var postdata = this.dataForm.value;
    var startd = null;
    var endd = null;
    startd = postdata.startdate;
    endd = postdata.enddate;

    this.timeLogService(startd, endd, this.genericcount, (data: any) => {
      //
      // data = (data as any).data;
      if (!data.HttpErrorResponse && data != null) {
        if (this.genericcount == 1) {
          this.timelogdata = data;
          this.timeCountService(startd, endd, data => {
            this.timedata = data;
          })
        }
        else {
          data.forEach(el => {
            this.timelogdata.push(el)
          });
        }
      }
    })

  }
  getTodaysdata() {
    
    var start = moment().startOf('day'); // set to 12:00 am today
    var end = moment().endOf('day'); // set to 23:59 pm today
    
    var d = new Date();
    var p = new Date();
    this.timeLogService(d, p, this.todaycount, (data: any) => {
      // data = (data as any).data;
      if (!data.HttpErrorResponse && data != null) {
        if (this.todaycount == 1) {
          this.todaytimelogdata = data;
          
          this.timeCountService(d, p, data => {
            this.todaytimedata = data;
            
            
            let ActiveTimelog = this.todaytimedata.total_minutes;

            const first = this.todaytimelogdata[0];
            var FirstTimeLog: any = new Date(first.start_time);
            var FirstTimeLogHr = FirstTimeLog.getHours();
            var FirstTimeLogmin = FirstTimeLog.getMinutes();
            var FirstTimelog = (FirstTimeLogHr * 60) + FirstTimeLogmin;


            let currentTime: any = new Date();
            var currentTimeHr: any = currentTime.getHours();
            var currentTimeMin: any = currentTime.getMinutes();
            var currentTimetotal = (currentTimeHr * 60) + currentTimeMin;

            var currentMinuteValue = currentTimetotal - FirstTimelog;

            if(currentMinuteValue > ActiveTimelog)
            {
              let BreakTimeInMinute = currentMinuteValue - ActiveTimelog

              let BreakTime_Hour: any = Math.floor(BreakTimeInMinute / 60);
              let breakTime_minute: any = Math.floor(BreakTimeInMinute % 60);
              let data1: any = BreakTime_Hour.toString();
              let data2: any = breakTime_minute.toString();
              if (data1.length == 1) {
                BreakTime_Hour = "0" + BreakTime_Hour;
              }
              if (data2.length == 1) {
                breakTime_minute = "0" + breakTime_minute;
              }
              this.breaktime = BreakTime_Hour + " Hrs " + breakTime_minute + " Min";  
            }
            else{
              this.breaktime = "00 Hrs 00 Min"; 
            }

           

            // const first = this.todaytimelogdata[0];
            // var FirstTimeLog: any = new Date(first.start_time);
            // var FirstTimeLogHr = FirstTimeLog.getHours();
            // var FirstTimeLogmin = FirstTimeLog.getMinutes();         
            // var totalStartTime = (FirstTimeLogHr * 60) + FirstTimeLogmin;

            // let currentTime: any = new Date();
            // var currentTimeHr: any = currentTime.getHours();
            // var currentTimeMin: any = currentTime.getMinutes();        
            // var currentTimetotal = (currentTimeHr * 60) + currentTimeMin;
            // var TotalMinFromFirstTimeLog = currentTimetotal - totalStartTime;  

            // let TotalTimeLogHR = (this.todaytimedata.total_logs * 6) / 60;
            // let TotalTimeLogMin = Math.floor((this.todaytimedata.total_logs * 6) % 60);         
            // let logData = (Math.floor(TotalTimeLogHR) * 60) + TotalTimeLogMin;            
            // let TotalBreakTime = TotalMinFromFirstTimeLog - logData;          

            // var break_hours: any = Math.floor(TotalBreakTime / 60);
            // var break_minute: any = Math.floor(break_hours % 60);
            // let data1: any = break_hours.toString();
            // let data2: any = break_minute.toString();
            // if (data1.length == 1) {
            //   break_hours = "0" + break_hours;
            // }
            // if (data2.length == 1) {
            //   break_minute = "0" + break_minute;
            // }
            // this.breaktime = break_hours + " Hrs " + break_minute + " Min";


            this.adminTimecalculate(this.todaytimedata);
          })
        }
        else {
          data.forEach(el => {
            this.todaytimelogdata.push(el)
          });
        }
      }
      if (localStorage.getItem('todaytimelogdata')) {
        this.todaytimelogdata.total_logs = JSON.parse(localStorage.getItem('todaytimelogdata')).total_logs
      }

    })

  }
  timeLogService(start, end, count, cb) {
    
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 99);
    var dep = moment(start).toISOString();
    var pep = moment(end).toISOString();
    this.SupportService.gethctimelog(dep, pep, this.localStoredHCData.id, 'hctimelog', count).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingSubject.next(true);
        this.systemService.showSnackBar("Sorry, unable to load health coaches.", "Ok");
        return cb(error);
      }),
      finalize(() => { this.loadingSubject.next(true); })
    ).subscribe(data => {
      data = (data as any).data;
      
      this.hourCalculate(data);
      return cb(data)
    });
  }

  timeCountService(start, end, cb) {
    
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 99);

    var dep = moment(start).format('YYYY-MM-DD');
    var pep = moment(end).format('YYYY-MM-DD');

    this.SupportService.gethctime(dep, pep, this.localStoredHCData.id, 'hctime').pipe(
      delay(100),
      finalize(() => { this.loadingSubject.next(true); })
    ).subscribe(data => {
      data = (data as any).data;
      
      return cb(data)
    });
  }

  convertDate(d) {
    var date = new Date(d);
    return date.toLocaleString();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // 
    this.dataForm.get('enddate').reset();

    this.currentdate = event.target.value;
    this.newDate = new Date(event.target.value);
    this.newDate.setDate(this.newDate.getDate() + 29)

  }


  ngOnDestroy(): void {
    this.loadingError$.unsubscribe();
    this.loadingSubject.unsubscribe();
  }

  toggleDarkTheme(checked) {
    setDarkTheme(checked);
    //  
  }


  getBreakTime(total_minutes) {
    const first = this.todaytimelogdata[0];
    var date1 = new Date(first.start_time)
    const last = this.todaytimelogdata[this.todaytimelogdata.length - 1];
    var date2 = new Date(last.end_time)
    var duration = date2.getTime() - date1.getTime()
    var minutes = Math.floor(duration / 1000 / 60);
    var newTime = minutes - total_minutes;
    // 
    var totalTime = Math.floor(newTime / 60) + ' Hrs ' + newTime % 60 + ' Min'
    // 
    // var hours = Math.floor(duration / 1000 / 60 / 60);
    // duration -= hours * 1000 * 60 * 60;
    // var minutes = Math.floor(duration / 1000 / 60);
    // 
    return totalTime

  }

  checkSession(element) {
    let result = element.end_time;
    let result1 = element.start_time;
    let diff = Math.round((new Date(result).getTime() - new Date(result1).getTime()) / (1000 * 60));
    return diff;
  }

  hourCalculate(element) {
    let data: any = 0;
    let hr: any = 0;
    let min: any = 0;
    for (let i = 0; i < element.length; i++) {
      let result = element[i].end_time;
      let result1 = element[i].start_time;
      let diff = Math.round((new Date(result).getTime() - new Date(result1).getTime()) / (1000 * 60));
      data = data + diff;
      
    }
    hr = data / 60;
    min = data % 60;
    hr = Math.floor(hr);
    
    
  }

  // getBreaktime(data) {
  //   
  //   const first = this.todaytimelogdata[0];
  //   var date1: any = new Date(first.start_time);
  //   var hh = date1.getHours();
  //   var mm = date1.getMinutes();
  //   var ss = date1.getSeconds();
  //   var total = (hh * 60) + mm;
  //   let currentTime: any = new Date();
  //   var currentTimeres: any = currentTime.getHours();
  //   var currentTimeres1: any = currentTime.getMinutes();
  //   var currentTimeres2: any = currentTime.getSeconds();
  //   var currentTimetotal = (currentTimeres * 60) + currentTimeres1;
  //   var cal1 = currentTimetotal - total;
  //   
  //   let hr1 = (data * 6) / 60;
  //   let min1 = (data * 6) % 60;
  //   hr1 = Math.floor(hr1);
  //   min1 = Math.floor(min1);
  //   let logData = (hr1 * 60) + min1;
  //   
  //   let response = cal1 - logData;
  //   
  //   var num = response;
  //   var hours = (num / 60);
  //   var rhours = Math.floor(hours);
  //   var minutes = (hours - rhours) * 60;
  //   var rminutes = Math.round(minutes);
  //   return  rhours + " Hrs " + rminutes + " Min";
  // }

  adminTimecalculate(data) {
    var num = data.admin_total_minutes;
    var hours = (num / 60);
    let rhours: any = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    let rminutes: any = Math.round(minutes);
    let data1 = rhours.toString();
    let data2 = rminutes.toString();
    if (data1.length == 1) {
      rhours = "0" + rhours;
    }
    if (data2.length == 1) {
      rminutes = "0" + rminutes;
    }
    this.adminLogTime = rhours + " Hrs " + rminutes + " Min";
  }

}