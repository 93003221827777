import { TrueFalsedata } from './../../adminsection/changeuserrole/changeuserrole.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, ReplaySubject } from 'rxjs-compat';
import { AleartmodelComponent } from 'src/app/content/shared/aleartmodel/aleartmodel.component';
import { PatientEditModelComponent } from 'src/app/content/shared/patient-edit-model/patient-edit-model.component';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-edit-patient-information',
  templateUrl: './edit-patient-information.component.html',
  styleUrls: ['./edit-patient-information.component.scss']
})


export class EditPatientInformationComponent implements OnInit {
  searchcriteria: any;
  //selectedLanguage:any;
  //langData: any;
  value: any;
  langData: any;
  SecoundaryConditionData: any;
  // current_campaign_id: ['', Validators.required],
  public dataForm = this.fb.group({
    new_campaign_id: ['', Validators.required],
    language_data_fk: ['', Validators.required],
    conditions: ['', Validators.required],
  });
  public endoflist: boolean = false;
  public page: number = 1;
  public limit: number = 20;
  lastSearch: any;
  public lastpage: number = 0;
  tabulardata: any;
  public showloader: boolean = false;
  public status: any = 'active';
  userid: any;
  public userName: string;
  patientData: any = [];
  PatientFullInfo: any = []
  editpageopen: boolean = true;
  keepSameLang: boolean = false;
  allconditions: any = [];
  doNotChangeCondition: any = [];
  doNotChangeLanguage: any;
  KeepSameLanguageValue: any;
  secoundaryCOnditionValues: any;
  CampaignData: any = [];
  CampaignArray: any = [];
  LanguageTherapyId: any = [];
  LanguageTherapyData: any = [];
  SecoundaryTherapyData: any = []
  filteredTherapyData: any = []
  filteredTherapyData1: any = []
  newCampaignData: any = [];
  public LanguageTherapy: [];
  searchCampaign: any;
  secoundaryConditionId: any = [];
  currentLanguageList: any = [];
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredLang: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmattag1: FormControl = new FormControl();
  public searchlangtag: FormControl = new FormControl();
  public searchcamptag: FormControl = new FormControl();
  selected = 'keepSameLanguage';
  languageName: any;
  keepLang: any;
  languageId: any;
  keepSameCampaign: boolean = true;
  disableSubmitBtn: boolean = true;
  staticSecondaryCondtion: any;
  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditPatientInformationComponent>,
    public commonService: CommonserviceService, private systemService: SystemService, private _formBuilder: FormBuilder,
    public fb: FormBuilder, private contentService: ContentserviceService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userid = this.data.element.id;
    this.userName = this.data.element.name
    this.getPatientInfo(this.userid);
    this.getLanguageList();
    this.getCampaignData();
    this.getConditions('');
    this.doNotChangeCondition = false;
    
  }

  getConditions(cb) {
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getConditionsSuccess(cb, (res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsSuccess(cb, data) {
    this.allconditions = data.getAllConditions;
    cb(true);
  }


  onKey(value) {
    this.searchCampaign = value;
    this.searchcriteria = value;
    this.page = 1;
    this.getCampaignData();
  }

  search(value: string) {
    let filter = this.newCampaignData.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    return [...filter];
  }

  getPatientInfo(id) {
    this.commonService.getPtaient(id)
      .subscribe((res: any) => {
        this.getPatient(res)
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getPatient(data) {
    data = (data as any).data;
    this.patientData.push(data.patient_campaign_data);
    this.PatientFullInfo = data;
    this.LanguageTherapyData.push(this.PatientFullInfo.patient_language_data)
    this.LanguageTherapy = this.LanguageTherapyData;
    if (this.PatientFullInfo.language_data_fk) {
      let result: any = [];
      result.push(this.PatientFullInfo.language_data_fk)
      const result1 = this.langData.filter(({ id }) => result.includes(id));
      this.LanguageTherapyId = result1;
      this.languageName = this.LanguageTherapyId[0].name;
      this.languageId = this.LanguageTherapyId[0].id;
      this.LanguageTherapy = [];
      let lang = this.PatientFullInfo.therapy_language_data;
      var index = lang.map(x => {
        return x.id;
      }).indexOf(this.languageId);
      lang.splice(index, 1);
      this.currentLanguageList = lang;
    }
    if (this.PatientFullInfo.conditions) {
      let conditionData: any = [];
      let condn: any = []
      let condition: any = [];
      this.filteredTherapyData1 = false;
      conditionData.push(...this.PatientFullInfo.conditions.split(','));
      for (let i = 0; i < conditionData.length; i++) {
        condn.push(conditionData[i] + '');
      }
      const result1 = this.allconditions.filter(({ id }) => condn.includes(id));
      this.filteredTherapyData1 = result1;
      this.currentCampaignSecoundaryConditionCheck(this.PatientFullInfo.therapy_id)
      //Api call to get language list for current campaign
    }
  }

  KeepSameCampaign(userid) {
    this.getPatientInfo1(userid)
  }

  getPatientInfo1(id) {
    this.commonService.getPtaient(id)
      .subscribe((res: any) => {
        this.getPatient1(res)
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getPatient1(data) {
    data = (data as any).data;
    this.PatientFullInfo = data;
    this.keepSameCampaign = false;
    this.LanguageTherapyData.push(this.PatientFullInfo.patient_language_data)
    this.LanguageTherapy = this.LanguageTherapyData;
    if (this.PatientFullInfo.language_data_fk) {
      let result: any = [];
      result.push(this.PatientFullInfo.language_data_fk)
      const result1 = this.langData.filter(({ id }) => result.includes(id));
      this.LanguageTherapyId = result1;
      this.languageName = this.LanguageTherapyId[0].name;
      this.languageId = this.LanguageTherapyId[0].id;
      this.LanguageTherapy = [];
      let lang = this.PatientFullInfo.therapy_language_data;
      var index = lang.map(x => {
        return x.id;
      }).indexOf(this.languageId);
      lang.splice(index, 1);
      this.currentLanguageList = lang;
      this.dataForm.get("language_data_fk").setValue(this.languageId);
    }
    if (this.PatientFullInfo.conditions) {
      let conditionData: any = [];
      let condn: any = []
      let condition: any = [];
      this.filteredTherapyData1 = false;
      conditionData.push(...this.PatientFullInfo.conditions.split(','));
      for (let i = 0; i < conditionData.length; i++) {
        condn.push(conditionData[i] + '');
      }
      const result1 = this.allconditions.filter(({ id }) => condn.includes(id));
      this.filteredTherapyData1 = result1;
      this.currentCampaignSecoundaryConditionCheck(this.PatientFullInfo.therapy_id)
      //Api call to get language list for current campaign
    }
  }

  currentCampaignSecoundaryConditionCheck(condition) {
      this.commonService.getTherapyCondition(condition)
        .subscribe((res: any) => {
          this.currentCampaignetCondSuccess(res);
        },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
  }

 

  private currentCampaignetCondSuccess(data) {
    this.secoundaryConditionId = [];
    data = (data as any).data;
    this.SecoundaryConditionData = data.comorbilities;
    if (this.SecoundaryConditionData.length > 0) {
      let result: any = [];
      this.allconditions.forEach(val => this.SecoundaryConditionData.includes(val.id) && result.push(val));
      this.filteredTherapy = result;
      Object.values(this.filteredTherapy).forEach(val => { this.checkSecoundaryCondition(val.id) });
    }
  }


  checkSecoundaryCondition(id) {
    for (let i = 0; i < this.filteredTherapyData1.length; i++) {
      if (this.filteredTherapyData1[i].id === id) {
        this.secoundaryConditionId.push(this.filteredTherapyData1[i].id);
      }
    }
    this.staticSecondaryCondtion = this.secoundaryConditionId;

  }

  newLangSelection(lang) {
    if (lang == "keepSameLanguage") {
      this.dataForm.get("language_data_fk").setValue(this.PatientFullInfo.has_update_language_id);
      this.dataForm.controls['language_data_fk'].setErrors({ 'incorrect': true });
      this.disableSubmitBtn = true;
    } else {
      this.dataForm.get("language_data_fk").setValue(lang);
      if (this.keepSameCampaign === false) {
        this.disableSubmitBtn = false;
      } else {
        this.disableSubmitBtn = true;
      }
    }

  }

  getCampaignData() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllGeneralData(this.searchcriteria, this.page.toString(), this.limit.toString(), this.status, "campaigndata")
        .subscribe((res: any) => {
          this.onSearchDataSuccess(res)
          this.page = this.limit;
        },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  private onSearchDataSuccess(data) {

    this.tabulardata = (data as any).data;
    if (this.searchCampaign) {
      this.CampaignData = [];
      this.CampaignArray = [];
      this.CampaignData.push(this.tabulardata)
      for (let i = 0; i < this.CampaignData[0].length; i++) {
        if (this.CampaignData[0].length > 0) {
          this.CampaignArray.push(this.CampaignData[0][i])
        }
      }
      this.newCampaignData = [...this.CampaignArray];
      this.CampaignData = [];
      this.searchCampaign = false;
    } else {
      this.CampaignData.push(this.tabulardata)
      for (let i = 0; i < this.CampaignData[0].length; i++) {
        if (this.CampaignData[0].length > 0) {
          this.CampaignArray.push(this.CampaignData[0][i])
        }
      }
      this.newCampaignData = [...this.CampaignArray];
      this.CampaignData = [];
    }
  }
  getNextSet() {
    this.page = this.page + 1;
    this.getCampaignData();

  }

  getLanguageList() {
    if (!this.searchcriteria) {
      this.searchcriteria = ""
    }
    this.commonService.getTherapyList(this.searchcriteria, "10", "language")
      .subscribe(
        (res: any) => this.getLangSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLangSuccess(data) {
    data = (data as any).data;
    this.langData = data;
  }

  closeModal(): void {
    this.dialogRef.close();
  }


  modelcampTags() {
    this.filtercampTags();
  }
  private filtercampTags() {
    if (!this.tabulardata) {
      return;
    }
    // get the search keyword
    let search = this.searchcamptag.value;
    if (!search) {
      this.CampaignArray.push(this.tabulardata.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }

    // filter the modules
    this.CampaignArray.push(
      this.tabulardata.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }
  modelChangedTags() {
    this.filterTags();
  }

  private filterTags() {
    if (!this.SecoundaryConditionData) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag1.value;
    if (!search) {
      this.filteredTherapy.next(this.SecoundaryConditionData.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.SecoundaryConditionData.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }
  livelangsearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getLanguageList()
    }
    else if (search.length <= 0) {
      this.searchcriteria = '';
      this.getLanguageList()
    }
  }
  modellangTags() {
    this.filterlangTags();
  }
  private filterlangTags() {
    if (!this.langData) {
      return;
    }
    // get the search keyword
    let search = this.searchlangtag.value;
    if (!search) {
      this.filteredLang.next(this.langData.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredLang.next(
      this.langData.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }
  //secoundary condition check by passing therapyId
  SecoundaryConditionCheck(condition) {
    this.keepSameLang = true;
    this.doNotChangeCondition = false;
    // this.disableSubmitBtn = false;
    this.getConditionList(condition)
  }

  getConditionList(condition) {
    this.commonService.getTherapyCondition(condition)
      .subscribe((res: any) => {
        this.getCondSuccess(res);
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCondSuccess(data) {
    let languageArray: any = [];
    data = (data as any).data;
    this.keepSameCampaign = false;
    this.SecoundaryConditionData = data.comorbilities;
    let language_Data = data.languages;
    languageArray = language_Data.map(function (x) {
      return parseInt(x, 10);
    });
    if (this.SecoundaryConditionData.length > 0) {
      let result: any = [];
      this.filteredTherapyData1 = false;
      this.filteredTherapy = undefined;
      this.secoundaryConditionId = false;
      this.allconditions.forEach(val => this.SecoundaryConditionData.includes(val.id) && result.push(val));
      this.filteredTherapy = result;
      this.filteredTherapyData1 = result;
      this.SecoundaryTherapyData = [...result];
    }

    if (language_Data.length > 0) {
      this.currentLanguageList = [];
      let result: any = [];
      this.langData.forEach(val => languageArray.includes(val.id) && result.push(val));
      this.LanguageTherapy = result;
      this.LanguageTherapyId = []
      //if user select keep same campaign then keep same language shoup be displyed else remove KSL
    }
    
  }

  SecoundaryCondition(condition) {
    this.doNotChangeCondition = "similarDataPassed";
    this.getConditionList1(condition)
  }
  getConditionList1(condition) {
    this.commonService.getTherapyCondition(condition)
      .subscribe((res: any) => {
        this.getCondSuccess1(res)
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private getCondSuccess1(data) {

    data = (data as any).data;
    this.SecoundaryConditionData = data.comorbilities;
    let result: any = [];
    let condition: any = [];
    this.allconditions.forEach(val => this.SecoundaryConditionData.includes(val.id) && result.push(val));
    this.filteredTherapy = result;
    condition = result;
    let languageArray: any = [];
    let language_Data = data.languages;
    languageArray = language_Data.map(function (x) {
      return parseInt(x, 10);
    });
    if (language_Data.length > 0) {
      let result: any = [];
      this.langData.forEach(val => languageArray.includes(val.id) && result.push(val));
      // if (this.languageId != false) {
      //   var index = result.map(x => {
      //     return x.id;
      //   }).indexOf(this.languageId);
      //   result.splice(index, 1);
      // }
      // if (result.length == 0) {
      //   this.LanguageTherapyData.push(this.PatientFullInfo.patient_language_data)
      //   this.LanguageTherapy = this.LanguageTherapyData;
      //   if (this.PatientFullInfo.language_data_fk) {
      //     let result: any = [];
      //     result.push(this.PatientFullInfo.language_data_fk)
      //     const result1 = this.langData.filter(({ id }) => result.includes(id));
      //     this.LanguageTherapyId = result1;
      //     this.languageName = this.LanguageTherapyId[0].name;
      //     this.languageId = this.LanguageTherapyId[0].id;
      //     this.LanguageTherapy = [];
      //     this.dataForm.controls['language_data_fk'].setValue(this.LanguageTherapyId[0].id);
      //     this.languageId = false;

      //   }
      // } else {

      // }
      this.LanguageTherapy = result;
      this.LanguageTherapyId = []
    }

  }

  keepSameLanguage(language) {
    this.doNotChangeLanguage = "keepSameLanguage";
    this.KeepSameLanguageValue = language;
  }

  removeSelectedSecoundaryCondition(event: any) {
    this.dataForm.controls.conditions.patchValue([]);
    this.secoundaryCOnditionValues = "SecoundaryConditionReset";
    if (event.source._selected) {
      this.disableSubmitBtn = false
    } else {
      this.disableSubmitBtn = true
    }
  }
  onSecondaryCondChange(value: any) {
    if (value.length > 0) {
      if (this.arrayEquals(value, this.staticSecondaryCondtion)) {
        if (this.keepSameCampaign === false) {
          this.disableSubmitBtn = true;
        } else {
          this.disableSubmitBtn = true;
        }
      }else{
        if (this.keepSameCampaign === false) {
          this.disableSubmitBtn = false;
        } else {
          this.disableSubmitBtn = true;
        }
      }
    } else {
      this.disableSubmitBtn = true
    }
  }
  arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  dataObject() {
    var postdata = this.dataForm.value;
    postdata['updated_by_fk'] = localStorage.getItem('scarletuserid')
    postdata['patient_id_fk'] = this.userid;
    if (postdata.conditions.length > 0) {
      const str = postdata.conditions.toString();
      const backToArr = str.split(",");
      postdata.conditions = JSON.stringify(backToArr);
    } else {
      let data = [];
      postdata.conditions = JSON.stringify(data);
    }
    if (this.secoundaryCOnditionValues == "SecoundaryConditionReset") {
      postdata.conditions = [];
      postdata.conditions = JSON.stringify(postdata.conditions);
    }
    postdata.current_campaign_id = this.patientData[0].id;
    postdata.current_campaign_id = postdata.current_campaign_id.toString();
    if (postdata.language_data_fk === '') {
      postdata.language_data_fk = this.currentLanguageList[0];
    }
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.new_campaign_id = postdata.new_campaign_id.toString();
    postdata.patient_id_fk = postdata.patient_id_fk.toString();
    this.dialogRef.close();
    const dialogRef = this.dialog.open(PatientEditModelComponent, {
      data: {
        element: this.dataForm.value
      }
    });
    return dialogRef.afterClosed();


  }

}