<div class="col-md-12 p-1">
    <div style="float: left">
    <h3 class="commontitleprimary hc-status-head">
        Start staging
    </h3>
    </div>
    <div class="close-icon" style="float:right">
        <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()" class="img-responsive close-modal" alt="">
      </div>
</div>
<div style="margin-top: 45px;">
<form [formGroup]="requestForm" validate>
   
    <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Enter Phone Numner</mat-label>
        <input [pattern]="'^[0-9]+$'" matInput placeholder="Enter Phone Number" formControlName="phone" maxlength="10">
        <mat-error class="p-0">Please enter the valid phone number</mat-error>
    </mat-form-field>

    <div class="col-md-12">
        <button mat-flat-button color="primary" [disabled]="!requestForm.valid" class="centerbtn mt-15" (click)="staging()">
            Submit</button>
    </div>
</form>
</div>