import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { PatientInformationComponent } from '../patient-information/patient-information.component';

@Component({
  selector: 'app-loginhistory',
  templateUrl: './loginhistory.component.html',
  styleUrls: ['./loginhistory.component.scss']
})
export class LoginhistoryComponent implements OnInit {
  userid: any;
  loginData: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['Devicename',"Deviceid" ,'Loginat'];
  public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  actRoute: string;
  actRouteEdit: string;
  public viewMedia: any;
  public morrow: any = new Date();
  public filterapplied: boolean = false;
  public startd: any;
  public endd: any;
  enddateval: any;
  userData: any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<LoginhistoryComponent>, private commonService: CommonserviceService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.userid = this.data.element;
    // this.search();
    this.getLoginHistory(this.userid);
  }
  getLoginHistory(id) {    
    let bodyData = {page:this.page.toString(),limit:'20',patient_data_fk:this.userid.toString()}
    this.commonService.getPtaientloginHistory(bodyData)
      .subscribe(
        (res: any) => {
          this.loginData = res.data;
          this.onSearchDataSuccess(res);
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  search() {
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllPatientsFilter('', this.page.toString(), "10", "all", "patient", this.startd, this.endd)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
  }

  private onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
        
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;

      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }   
  }
  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
