<div class="view-task">
    <div class="clearfix">
        <h2 class="pull-left title-modal">To do tasks</h2>

        <div class="pull-right">
            <div class="close-modal">
                <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive" alt="">
            </div>
        </div>
    </div>


    <form>
        <div class="form-group has-feedback has-search">
            <input class="form-control" placeholder="Search" [(ngModel)]="searchcriteria"
                [ngModelOptions]="{standalone: true}">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                <mat-icon matSuffix>search</mat-icon>
            </button>
        </div>
    </form>
    <div class="row">
        <div class="col-md-12">
            <div class="container-table">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let shoe of dataSource">
                        <div class="d-flex align-items-center justify-content-spacebetween">
                            <div class="content">
                                {{shoe.task_display_id}} <span>{{shoe.task_summary}}</span>
                            </div>
                            <div>
                                <button mat-button class="btn btn-small btn-filled"
                                    (click)="getDatabyId(shoe.id)">Choose</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>