<div class="row">
<div class="col-md-12 clearfix">
    <h2 class="pull-left title-modal">
        Generic QR code
    </h2>
    <div class="pull-right">
        <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
    </div>
</div>
</div>
<div>
    <div class="row" style="height:300px;width: 100%;">
        <div class="col-md-5" style="border: 1px solid whitesmoke;height: 100%;">
            <h5 class="activatetitlecss">All Offerings</h5>
            <div *ngFor="let camp of allCampaigns">
            <button mat-raised-button style="margin:10px;width: 190px;" (click)="dataObject(camp.id)">{{camp.title}}</button>
            </div>
            </div>
            <div class="col-md-7" style="border: 1px solid whitesmoke;border-left: none;height: 100%;">
                <div class="sidenav-content" >
                    <div id="content">
                  
                        <div class="showdownload">
                    
                            <div class="qrbox">
                                <img src="assets/img/wellthy_logo_2.png" class="wellthylogo" />
                                <img [src]="imgurl" style="margin:0 auto;display:block;" />
                            </div>
                            <div class="clearfix"><br /><br /></div>
                        </div>
                    </div>
            </div>
        </div>
    <!-- <mat-grid-list cols="4" rowHeight="100px">
        <mat-grid-tile>
            <h5 class="activatetitlecss">All Offerings</h5>
            <div class="col-md-12 col-xs-12">
                <div class="col-xs-12 col-md-4 navigatorcontainercss qrcodegeneral" *ngFor="let camp of allCampaigns"
                    (click)="dataObject(camp.id)">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <h5>{{camp.title}}</h5>
                        </div>
                    </div>
                </div>
                <div class="clearfix"><br /></div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            
          </mat-grid-tile>
      </mat-grid-list> -->
    </div>
<!-- <div >
    <div class="clearfix"><br /></div>
    <h5 class="activatetitlecss">All Offerings</h5>
    <div class="col-md-12 col-xs-12">
        <div class="col-xs-12 col-md-4 navigatorcontainercss qrcodegeneral" *ngFor="let camp of allCampaigns"
            (click)="dataObject(camp.id)">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <h5>{{camp.title}}</h5>
                </div>
            </div>
        </div>
        <div class="clearfix"><br /></div>
    </div>
    <div class="clearfix"><br /></div>
</div>
<div class="sidenav-content" >
    <div id="content">
  
        <div class="showdownload">
    
            <div class="qrbox">
                <img src="assets/img/wellthy_logo_2.png" class="wellthylogo" />
                <img [src]="imgurl" style="margin:0 auto;display:block;" />
            </div>
            <div class="clearfix"><br /><br /></div>
        </div>
    </div>
</div> -->