<div class="row">
    <div class="col-md-12">  
    
        <div class="title-wrapper">
            <h2 class="commontitleprimary text-capitalize">
                <span style="color:var(--primary-orange);">Mobile Number Change Action</span>
            </h2>
        
            <div class="close-icon">
                <img src="assets/img/dls/icons/cross.svg" (click)="close()">
            </div>
        </div>
    </div>

    <form>
        <mat-form-field appearance="outline" class="col-md-12">
            <textarea name="edit" matInput [(ngModel)]="reason" class="edit-text-area" (ngModelChange)="reasonChange($event)"></textarea>
        </mat-form-field>
        

        <div class="col-md-12">
            <button class="btn btn-medium btn-filled" (click)="callAction()" [disabled]="reasonDisabled"> 
                <ng-container *ngIf="data.action === 1">Approve</ng-container>    
                <ng-container *ngIf="data.action === 2">Reject</ng-container>    
            </button>
        </div>
    </form>

</div>

