import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TaskadminComponent } from './taskadmin.component';
import { AdminGuardGuard } from 'src/app/shared/guards';
import { HcassigntaskComponent } from './hcassigntask/hcassigntask.component';
import { TaskviewhistoryComponent } from './hcassigntask/taskviewhistory/taskviewhistory.component';


const routes: Routes = [
  { path: '', component: TaskadminComponent, canActivate: [AdminGuardGuard] },
  // { path: 'task/edit', component: EdittaskComponent, canActivate: [AdminGuardGuard] },
  // { path: 'task/prefill', component :PrefillComponent,canActivate: [AdminGuardGuard]},
  { path: 'task/assigntask/:id', component: HcassigntaskComponent, canActivate: [AdminGuardGuard] },
  { path: 'task/viewtask', component: TaskviewhistoryComponent, canActivate: [AdminGuardGuard] }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskadminRoutingModule { }
