<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-hcassignleftnav></app-hcassignleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/codegeneratehome"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <!-- (click)="createCode('newsection')" -->
          <!-- [routerLink]="['/admin/createcodegen/edit',newsection]" -->
          <button mat-icon-button class="create-btn" (click)="createCode('newsection')">
            New code generation request
          </button>
        </h3>
      </div>

      <div class="col-md-12">
        <form>
          <div class="form-group has-feedback has-search">
            <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
              placeholder="Search request name">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>

      <div class="col-md-12">
        <div class="container-table table-wrapper" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">

          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table">


            <!-- Position Column -->
            <ng-container matColumnDef="request_name">
              <th mat-header-cell *matHeaderCellDef style="width: 200px;"> Request name </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                {{element.request_name}} </td>
            </ng-container>

            <ng-container matColumnDef="therapy_title">
              <th mat-header-cell *matHeaderCellDef style="width: 200px;"> Therapy title </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.therapy_title}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="code_count">
              <th mat-header-cell *matHeaderCellDef> Code generated </th>
              <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.code_count}}</td>
            </ng-container>

            <!-- updatedat Column -->
            <ng-container matColumnDef="healthcoach_name">
              <th mat-header-cell *matHeaderCellDef> Healthcoach </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> <span class="data"
                  *ngFor="let el of element.healthcoach_name">{{el.name}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="client_name">
              <th mat-header-cell *matHeaderCellDef> Client</th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> <span
                  *ngIf="element.client_name">{{element.client_name}}</span>
                <span *ngIf="!element.client_name">-</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button [matMenuTriggerFor]="menu3" tooltip="Codes">
                    <img src="assets/img/dls/admin-table/Icon_Codes.svg" alt="">
                  </button>

                  <button mat-button (click)="showdata(element)" tooltip="Edit healthcoach">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy">
                  </button>

                  <mat-menu #menu3="matMenu">
                    <button mat-menu-item (click)="generateCode(element)">
                      Generate codes</button>
                    <!-- <button mat-menu-item [matMenuTriggerFor]="menu4">
                      Download codes
                    </button> -->
                    <!-- <button >
                    <mat-accordion>
                      <mat-expansion-panel >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                           
                      Download codes
                   
                          </mat-panel-title>
                         
                        </mat-expansion-panel-header>
                        <p>This is the primary content of the panel.</p>
                      </mat-expansion-panel>
                      </mat-accordion>
                    </button> -->
                    <div (click)="$event.stopPropagation()">
                      <mat-accordion  multi>
                       
                        <mat-expansion-panel class="mat-elevation-z0">
                          <mat-expansion-panel-header>
                            <mat-panel-description>
                              Download codes
                            </mat-panel-description>
                            
                           
                          </mat-expansion-panel-header>
                      
                          <button mat-menu-item (click)="exportAsXLSX(element,'active')">
                            Active codes</button>
                          <button mat-menu-item (click)="exportAsXLSX(element,'inactive')">
                            Inactive codes</button>
                          <button mat-menu-item (click)="exportAsXLSX(element,'all')">
                            All codes</button>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <button mat-menu-item (click)="viewCode(element)">
                      View codes</button>
                  </mat-menu>

                  <!-- [routerLink]="['/admin/viewallactivation',element.id]" -->

                  <!-- <mat-menu #menu4="matMenu">
                    <button mat-menu-item (click)="exportAsXLSX(element,'active')">
                      Active codes</button>
                    <button mat-menu-item (click)="exportAsXLSX(element,'inactive')">
                      Inactive codes</button>
                    <button mat-menu-item (click)="exportAsXLSX(element,'all')">
                      All codes</button>
                  </mat-menu> -->

                </div>
              </td>
            </ng-container>




            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 90px;"
                        [matMenuTriggerFor]="menu3" class="no-hover-effect">Codes
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <button mat-button disableRipple class="no-hover-effect edit-hc-btn" style="width:180px;"
                        (click)=showdata(element)>Edit Healthcoach</button>

                      <mat-menu #menu3="matMenu">
                        <button mat-menu-item (click)="generateCode(element)">
                          Generate Codes</button>
                        <button mat-menu-item [matMenuTriggerFor]="menu4">
                          Download Codes</button>
                        <button mat-menu-item [routerLink]="['/admin/viewallactivation',element.id]">
                          View Codes</button>
                      </mat-menu>

                      <mat-menu #menu4="matMenu">
                        <button mat-menu-item (click)="exportAsXLSX(element,'active')">
                          Active Codes</button>
                        <button mat-menu-item (click)="exportAsXLSX(element,'inactive')">
                          Inactive Codes</button>
                        <button mat-menu-item (click)="exportAsXLSX(element,'all')">
                          All Codes</button>
                      </mat-menu>

                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>

      </div>
    </div>
  </div>

  <div id="myTableElementId">
    Hello bean
  </div>
</mat-drawer-container>