import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';

@Component({
  selector: 'app-addmedicine',
  templateUrl: './addmedicine.component.html',
  styleUrls: ['./addmedicine.component.scss']
})
export class AddmedicineComponent implements OnInit {
  public selectedUserID: any;
  public typeFilters: any;
  showButtonLoader: boolean;
  public addmedicineform = this.fb.group({
    name: ["", [Validators.required, whiteSpaceValidation]],
    volume: [],
    dosage: this.fb.array([]),
    num_shots: [],
    num_units: [],
    pre_meal: ['false'],
    duration: [],
    medicine_type: [],
    medicine_shape: [],
    start_date: [],
    morning_dose: [],
    afternone_dose: [],
    evening_dose: [],
    first_color: [],
    second_color: []
  });



  constructor(public dialog: MatDialog, private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder, private systemService: SystemService) { }

  ngOnInit() {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
  }

  dataObject() {
    var postdata = this.addmedicineform.value;
    postdata.dosage = [postdata.morning_dose, postdata.afternone_dose, postdata.evening_dose];
    if (postdata.medicine_type == 'tablet') {
      postdata.volume = '0';
      postdata.num_shots = '0';
      postdata.color = [postdata.first_color];

    }
    else if (postdata.medicine_type == 'capsule') {
      postdata.color = [postdata.first_color, postdata.second_color];
      postdata.volume = '0';
      postdata.num_shots = '0';

    }
    else if (postdata.medicine_type == 'syrup') {
      postdata.num_units = '0';
      postdata.num_shots = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'insulin_pen') {
      postdata.volume = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'ointment') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.num_shots = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'injectable') {
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'mdi') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'dpi') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'bai') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'nasal_spray') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'dpi_revolizer') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
    }
    else if (postdata.medicine_type == 'dpi_multihaler') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'dpi_rotahaler') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'nebulizer') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else {

    }
    postdata.num_shots = postdata.num_shots.toString();
    postdata.volume = postdata.volume.toString();
    postdata.name = postdata.name.toString();
    postdata.color = JSON.stringify(postdata.color);
    postdata.dosage = JSON.stringify(postdata.dosage);
    postdata.num_units = postdata.num_units.toString();
    postdata.pre_meal = postdata.pre_meal.toString();
    postdata.duration = postdata.duration.toString();
    postdata.medicine_type = postdata.medicine_type.toString();
    postdata.medicine_shape = postdata.medicine_shape.toString();

    var datum = this.addmedicineform.value.start_date.toUTCString();

    var logdate = null;
    logdate = moment(datum).toISOString();
    postdata.start_date_time = [];
    postdata.start_date_time.push(logdate);
    postdata.start_date_time = postdata.start_date_time.toString();
    postdata.patient_id_fk = this.selectedUserID.toString();
    postdata['created_by_fk'] = localStorage.getItem('scarletuserid')
    
    delete postdata.start_date;
    delete postdata.morning_dose;
    delete postdata.afternone_dose;
    delete postdata.evening_dose;
    delete postdata.first_color;
    delete postdata.second_color;

    this.UserdataService.addMedicine(postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.systemService.showSuccessSnackBar("Medicine added sucessfully.", "OK");
        this.showButtonLoader = false;
      })
  }

}
