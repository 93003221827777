import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, delay, finalize } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { BotlearningService } from '../../services/botlearning/botlearning.service';

declare var $: any;

@Component({
  selector: 'app-chatbotmodal',
  templateUrl: './chatbotmodal.component.html',
  styleUrls: ['./chatbotmodal.component.scss']
})
export class ChatbotmodalComponent implements OnInit {
  public botCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);/*true ti show submit button */
  showselectioncontainer: boolean = false;
  selectionMessage: boolean = false;
  chatmes: any;
  lastselection: any;
  currentNode: any;
  isIntentSelected: boolean = true;
  showButtonLoader: boolean;
  intentdata: any;
  newentity: any;
  selectionText: any;
  isCustomSelected: boolean = false;
  public chatmodalForm = this.fb.group({
    conversationID: [Validators.required],
    messageID: [Validators.required],
    intent: [Validators.required],
    entityClassified: this.fb.array([
    ])
  });
  initOptions() {
    return this.fb.group({
      entity: ["", Validators.required],
      text: ["", Validators.required],
    })
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private BotlearningService: BotlearningService, private systemService: SystemService, public fb: FormBuilder, public dialogRef: MatDialogRef<ChatbotmodalComponent>,private commonService:CommonserviceService) {
    this.selectionText = this.data.chatmessage;
  }

  ngOnInit() {
    this.chatmes = this.data.chatmessage;
    this.chatmodalForm.controls.messageID.setValue(this.data.id);
    this.chatmodalForm.controls.conversationID.setValue(this.data.conid);
    this.getIntentsfn();
  }
  clearAllData() {
    this.chatmodalForm.reset();
    if (this.isCustomSelected == true) {
      $("#textareamessage").val(this.chatmes);
      this.isCustomSelected = false;
    }
    else {
      this.isCustomSelected = true;
    }
  }
  selectedintent(selectedintent) {
    for (var p = 0; p < this.intentdata.length; p++) {
      if (this.intentdata[p].intent == selectedintent) {
        this.newentity = this.intentdata[p].entities;
      }
    }
    this.isIntentSelected = false;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  chatModalObject() {
    this.BotlearningService.postBotLearningData(this.data.id, this.chatmodalForm.value).pipe(
      delay(100),
      catchError((error: any) => {
        this.botCreationSubject$.next(true);
        this.systemService.showSnackBar("Sorry, unable to save learning data.", "Ok");
        return throwError(error);
      }),
      finalize(() => { this.botCreationSubject$.next(true); })
    ).subscribe(
      (data: any) => {
        this.botCreationSubject$.next(true);
        if (data == null) {
          this.systemService.showSnackBar("Learning saved", "OK");
          this.closeDialog();
        }
      })
  }
  addMorePairs() {
    const control = <FormArray>this.chatmodalForm.controls['entityClassified'];
    control.push(this.initOptions());

  }
  doneselectionfn() {
    this.showselectioncontainer = false;
    const faControl =
      (<FormArray>this.chatmodalForm.controls['entityClassified']).at(this.currentNode);
    faControl['controls'].text.setValue(this.lastselection);
  }
  getIntentsfn() {
    this.BotlearningService.getIntents().pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
        return throwError(error);
      }),
      finalize(() => { this.showButtonLoader = false; })
    ).subscribe(
      (data) => {
        this.intentdata = data;
      })
  }
  renderselectionmodule(eve) {
    this.selectionText = $("#textareamessage").val();
    this.showselectioncontainer = true;
    document.addEventListener("selectionchange", event => {
      let selection;
      if (document.getSelection) {
        selection = document.getSelection().toString()
      }
      this.lastselection = selection;
      this.currentNode = eve;
    })
  }

}
