
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

@Component({
  selector: 'app-editchapter',
  templateUrl: './editchapter.component.html',
  styleUrls: ['./editchapter.component.scss']
})

export class EditchapterComponent implements OnInit {

  public actRoute: any;
  chooseMedia: any;
  optiontype: any;
  s3url: any = environment.s3url;
  public showButtonLoader: boolean = false;
  alltags: any;
  allfeedback: any;
  alllessons: any;
  allquizes: any;
  public selfparentid: any;
  public newitem: boolean = false;
  public childid: any;
  public selectedoptn: any = [];
  cptrdata: any;
  public langid: any;
  optioncount: any;
  chapterdata: any;
  complexityenum: any = complexityenum;
  checked: boolean = false;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public control1: any;
  public chapoptntype: any = [];
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: ['', [Validators.required, whiteSpaceValidation]],
    complexity_level: ['', Validators.required],
    subpart_count: ['', Validators.required],
    image_media: ['', Validators.required],
    time_to_finish: ['', [Validators.required, Validators.max(3600), Validators.min(1)]],
    allow_feedback: [''],
    feedback: [''],
    tags: [[], Validators.required],
    subpart_data: this.fb.array([]),
    magazine_data: [''],
    magazine_count: ['0'],
    carey_card_count: ['0'],
    carey_card_data: [''],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required]
  });

  public searchmat: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  filteredLessons: Array<ReplaySubject<any[]>> = [];
  public searchmatquiz: FormControl = new FormControl();
  filteredQuizzes: Array<ReplaySubject<any[]>> = [];
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmatfeedback: FormControl = new FormControl();
  public searchmatmagazine: FormControl = new FormControl();
  public searchmatcareycard: FormControl = new FormControl();
  public filteredFeedback: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredMagazines: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCareycards: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public allcareyCards: any;
  public allmagazineCards: any;

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;
  @ViewChild('singleSelectFeedback', { static: false }) singleSelectFeedback: MatSelect;
  @ViewChild('singleSelectQuiz', { static: false }) singleSelectQuiz: MatSelect;
  @ViewChild('singleSelectMagazine', { static: false }) singleSelectMagazine: MatSelect;
  @ViewChild('singleSelectCareyCard', { static: false }) singleSelectCareyCard: MatSelect;


  initTypeOptions() {
    return this.fb.group({
      type: [],
      id: []
    });
  }

  constructor(private browserTitle: Title, private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Chapter');
  }

  ngOnInit() {
    this.getCareyCards();
    this.setSEOTags();
  }

  initializeChapter() {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getAllChapterData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getAllChapterData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getAllChapterData(params.chid);
        }
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChanged(event, index) {
    this.filterBanksMulti(index);
  }

  modelChangedQuiz(event, index) {
    this.filterQuizzes(index);
  }
  modelChangedTags() {
    this.filterTags();
  }
  modelChangedFeedback() {
    this.filterFeedback();
  }

  modelChangedMagazines() {
    this.filterMagazines();
  }

  modelChangedCareycards() {
    this.filterCareycards();
  }

  private filterBanksMulti(index) {
    if (!this.alllessons) {
      return;
    }
    // get the search keyword
    let search = this.searchmat.value;
    if (!search) {
      this.filteredLessons[index].next(this.alllessons.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredLessons[index].next(
      this.alllessons.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterQuizzes(index) {
    if (!this.allquizes) {
      return;
    }
    // get the search keyword
    let search = this.searchmatquiz.value;
    if (!search) {
      this.filteredQuizzes[index].next(this.allquizes.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredQuizzes[index].next(
      this.allquizes.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterCareycards() {
    if (!this.allcareyCards) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcareycard.value;
    if (!search) {
      this.filteredCareycards.next(this.allcareyCards.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the carey cards
    this.filteredCareycards.next(
      this.allcareyCards.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  private filterMagazines() {
    if (!this.allmagazineCards) {
      return;
    }
    // get the search keyword
    let search = this.searchmatmagazine.value;
    if (!search) {
      this.filteredMagazines.next(this.allmagazineCards.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredMagazines.next(
      this.allmagazineCards.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterFeedback() {
    if (!this.allfeedback) {
      return;
    }
    // get the search keyword
    let search = this.searchmatfeedback.value;
    if (!search) {
      this.filteredFeedback.next(this.allfeedback.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredFeedback.next(
      this.allfeedback.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  optionType(j, e) {
    this.chapoptntype[j] = e;
  }

  selectOptions() {
    this.selectedoptn[0] = [];
    this.selectedoptn[1] = [];
    if (this.control1 && this.control1.value) {
      for (let l = 0; l < this.alllessons.length; l++) {
        for (let k = 0; k < this.control1.value.length; k++) {
          if (this.control1.value[k].type == 'lesson' && this.alllessons[l].id == this.control1.value[k].id) {
            this.selectedoptn[0][k] = this.alllessons[l];
          }
        }
      }
      for (let l = 0; l < this.allquizes.length; l++) {
        for (let k = 0; k < this.control1.value.length; k++) {
          if (this.control1.value[k].type == 'quiz' && this.allquizes[l].id == this.control1.value[k].id) {
            this.selectedoptn[1][k] = this.allquizes[l];
          }
        }
      }
    }
  }

  selectedOptions(e, j, option) {
    var type;
    if (option == 0) {
      type = this.alllessons;
    }
    if (option == 1) {
      type = this.allquizes;
    }
    if (!this.selectedoptn[option]) {
      this.selectedoptn[option] = [];
    }
    for (let k = 0; k < type.length; k++) {
      if (e == type[k].id) {
        if (this.selectedoptn[1] && option == 0) {
          delete this.selectedoptn[1][j];
        }
        if (this.selectedoptn[0] && option == 1) {
          delete this.selectedoptn[0][j];
        }
        this.selectedoptn[option][j] = type[k];
      }
    }
  }

  disableOptions(option, e) {
    if (this.selectedoptn[option]) {
      for (let k = 0; k < this.selectedoptn[option].length; k++) {
        if (this.selectedoptn[option][k] && this.selectedoptn[option][k].id && e == this.selectedoptn[option][k].id) {
          return true;
        }
      }
    }
  }

  changeOptions(e) {
    this.optioncount = e;
    this.selectedoptn = [];
    this.control1 = <FormArray>this.dataForm.controls['subpart_data'];
    if (this.control1.length !== 0) {
      if (this.control1.length < e) {
        for (let l = this.control1.value.length; l < e; l++) {
          this.control1.push(this.initTypeOptions());
          this.filteredLessons[l] = new ReplaySubject<any[]>(1);
          this.filteredQuizzes[l] = new ReplaySubject<any[]>(1);
          this.filteredTags[l] = new ReplaySubject<any[]>(1);
          this.filteredFeedback[l] = new ReplaySubject<any[]>(1);
        }
        this.selectOptions();
      }
      if (this.control1.length > e) {
        for (let j = this.control1.value.length; j > e; j--) {
          this.control1.removeAt(j - 1);
          this.filteredLessons[j] = new ReplaySubject<any[]>(1);
          this.filteredQuizzes[j] = new ReplaySubject<any[]>(1);
          this.filteredTags[j] = new ReplaySubject<any[]>(1);
          this.filteredFeedback[j] = new ReplaySubject<any[]>(1);
        }
        this.selectOptions();
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        this.control1.push(this.initTypeOptions());
        this.filteredLessons[k] = new ReplaySubject<any[]>(1);
        this.filteredQuizzes[k] = new ReplaySubject<any[]>(1);
        this.filteredTags[k] = new ReplaySubject<any[]>(1);
        this.filteredFeedback[k] = new ReplaySubject<any[]>(1);
      }
    }
  }

  changeValue(value) {
    this.checked = !value;
  }


  // To Get Chapter 
  // ----------------------------

  getAllChapterData(ide) {
    
    this.contentService.getOneDataById(ide, 'chapter')
      .subscribe(
        (res: any) => this.getAllChapterDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllChapterDataSuccess(data) {
    data = (data as any).data;
    this.cptrdata = data;
    this.changeOptions(this.cptrdata.subpart_count);
    for (let k = 0; k < this.cptrdata.subpart_count; k++) {
      let txtformelement = (<FormArray>(this.dataForm.controls['subpart_data'])).at(k);
      txtformelement['controls'].type.setValue(this.cptrdata.subpart_data[k].type);
      this.optiontype = this.cptrdata.subpart_data[k].type;
      txtformelement['controls'].id.setValue(this.cptrdata.subpart_data[k].id);
      this.optionType(k, this.cptrdata.subpart_data[k].type);
      this.selectOptions();
    }
    this.cptrdata.subpart_count = this.cptrdata.subpart_count.toString();
    if (this.cptrdata.allow_feedback == true) {
      this.checked = true;
      this.cptrdata.feedback = this.cptrdata.feedback.toString();
    }
    this.cptrdata.carey_card_count = this.cptrdata.carey_card_count.toString();
    if (this.cptrdata.carey_card_data !== null) {
      this.cptrdata.carey_card_data = this.cptrdata.carey_card_data.toString();
    }
    this.cptrdata.magazine_count = this.cptrdata.magazine_count.toString();
    if (this.cptrdata.magazine_data !== null) {
      this.cptrdata.magazine_data = this.cptrdata.magazine_data;
    }
    this.dataForm.patchValue(this.cptrdata);
    this.getMedia(this.cptrdata.image_media);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
  }

  // ----------------------------


  // To Get Carey Cards 
  // ----------------------------

  getCareyCards() {
    this.contentService
      .getGraphQlData('{getParentCareyCards(status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getCareyCardsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCareyCardsSuccess(data) {
    data = (data as any).data;
    this.allcareyCards = data.getParentCareyCards;
    this.filteredCareycards.next(this.allcareyCards.slice());
    this.getMagazineCards();
  }

  // ----------------------------


  // To Get Magazine 
  // ----------------------------

  getMagazineCards() {
    this.contentService
      .getGraphQlData('{getParentMagazines(status:true){id,title}}', false)
      .subscribe((res: any) => this.getMagazineCardsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getMagazineCardsSuccess(data) {
    data = (data as any).data;
    this.allmagazineCards = data.getParentMagazines;
    this.filteredMagazines.next(this.allmagazineCards.slice());
    this.getTags();
  }

  // ----------------------------


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private ongetTagsSuccess(data) {
    data = (data as any).data;
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
    this.getFeedbacks();
  }

  // ----------------------------


  // Get Feedbacks
  // ----------------------------

  getFeedbacks() {
    this.contentService
      .getGraphQlData('{getParentFeedbacks(status:true){id,title}}', false)
      .subscribe((res: any) => this.getFeedbackSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getFeedbackSuccess(data) {
    data = (data as any).data;
    this.allfeedback = data.getParentFeedbacks;
    this.filteredFeedback.next(this.allfeedback.slice());
    this.getLessons();
  }

  // ----------------------------


  // Get Lessions
  // ----------------------------

  getLessons() {
    this.contentService
      .getGraphQlData('{getParentLessons(status:true){id,title}}', false)
      .subscribe((res: any) => this.getLessonsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLessonsSuccess(data) {
    data = (data as any).data;
    this.alllessons = data.getParentLessons;
    for (let j = 0; j < this.alllessons; j++) {
      this.filteredLessons[j].next(this.alllessons.slice());
    }
    this.getQuiz();
  }

  // ----------------------------


  // Get Quiz
  // ----------------------------

  getQuiz() {
    this.contentService
      .getGraphQlData('{getParentQuizes(status:true){id,title}}', false)
      .subscribe((res: any) => this.getQuizSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getQuizSuccess(data) {
    data = (data as any).data;
    this.allquizes = data.getParentQuizes;
    this.initializeChapter();
  }


  // ----------------------------

  // Get Media
  // -----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe(
        (res: any) => this.onGetMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // -----------------------------


  // Submitting Chapter Data
  // -----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    if (!this.checked) {
      postdata.feedback = '';
    }
    postdata.title = this.removeHTMLtags(postdata.title).toString();
    postdata.detailed_text = this.removeHTMLtags(postdata.detailed_text).toString();
    postdata.complexity_level = postdata.complexity_level.toString();
    postdata.subpart_count = postdata.subpart_count.toString();
    postdata.image_media = postdata.image_media.toString();
    postdata.time_to_finish = postdata.time_to_finish.toString();
    postdata.allow_feedback = this.checked.toString();
    postdata.feedback = postdata.feedback == null ? null : postdata.feedback.toString();
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.subpart_data = JSON.stringify(postdata.subpart_data);
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    postdata.carey_card_count = postdata.carey_card_count.toString();
    postdata.carey_card_data = postdata.carey_card_data == null ? null : postdata.carey_card_data.toString();
    postdata.magazine_count = postdata.magazine_count.toString();
    postdata.magazine_data = postdata.magazine_data == null ? null : postdata.magazine_data.toString();
    if (postdata.carey_card_data == '') { postdata.carey_card_count = '0'; }
    if (postdata.magazine_data == '') { postdata.magazine_count = '0' }
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'chapter')
        .subscribe(
          (res: any) => this.onCreateSuccess((res as any).data),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'chapter')
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onCreateSuccess(data) {
    this.chapterdata = data;
    this.systemService.showSuccessSnackBar(("Chapter has been added"), "OK");
    this.router.navigate(['content/chapter']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Chapter has been updated"), "OK");
    this.router.navigate(['content/chapter']);
  }

  // -----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }
  ​ removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}

export enum complexityenum {
  High,
  Medium,
  Low
}