import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})

export class ConfirmationModalComponent implements OnInit {

  question: string;

  // Variables can be used in future for changing 
  // labels by passing the data in constructor
  confirmButtonText = "Confirm";
  cancelButtonText = "Cancel";

  cancelString = "cancel";
  confirmString = "confirm";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any, 
    private dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) { 
    this.question = this.data.question;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('376px', '150px');
  }

}
