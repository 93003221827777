import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HealthcoachGuardGuard } from './../../shared/guards';
import { PolicyComponent } from './policy.component';


const routes: Routes = [
	{ path: '', component: PolicyComponent, canActivate: [HealthcoachGuardGuard] },
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class PolicyRoutingModule { }
