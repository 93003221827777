import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { SHORT_MONTHS } from 'src/app/shared/data';
import * as _ from 'lodash';


@Component({
  selector: 'app-completehctask',
  templateUrl: './completehctask.component.html',
  styleUrls: ['./completehctask.component.scss']
})
export class CompletehctaskComponent implements OnInit {
  startDate: any;
  endDate: any;
  progresspatendId = [];
  failedPatiendId: any = [];
  pendingPatientId = [];
  perf: any;
  overView: any;
  showHide: boolean = false;
  public childid: any;
  public page: number = 1;
  isLoading: boolean = false;
  patientList: any;
  public executionform = this.fb.group({
    number_list: ['']
  });
  hcId: any;
  public hctaskdata: any;
  public taskdata: any;
  iscomplete: boolean = false;
  getToday: any;
  progressApidata: any;
  apiCall: boolean = false;
  progressPatients: any = [];
  totalPatient: any = [];
  constructor(public commonService: CommonserviceService, private systemService: SystemService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    this.showHide = true;
    localStorage.removeItem('taskcompleteflag');
    localStorage.removeItem('progressPatients');
  }

  async ngOnInit() {
    localStorage.removeItem('progressPatients');
    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate())
    this.getToday = todayDate.toISOString();

    this.route.params.subscribe(params => {
      this.childid = params.id;
      this.hcId = params.hc_id;

    });
    // await this.getProgress();
    await this.applyFilters();
    this.hctaskdata = JSON.parse(localStorage.getItem('hctaskdata'));
    await this.getAllTaskData();

  }

  async ngAfterViewInit() {
    await this.getProgress();
  }



  getAllTaskData() {
    this.commonService.getOneTaskData(this.hctaskdata.task_library_id.toString())
      .subscribe(
        (res: any) => this.getOneTaskSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getOneTaskSuccess(data) {
    data = data.data;
    this.taskdata = data
  }

  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }

  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ',' + ' ' + year;
    }
  }



  changeShowStatus() {
    this.showHide = !this.showHide;
  }
  // disbale button for multi api hit 
  sendForApprova() {
    this.apiCall = true;
    var postdata = {
      task_library_id: this.childid,
      hc_id: localStorage.getItem('scarletuserid'),
    }

    this.commonService.TaskPost(postdata, 'sendForApproval')
      .subscribe(
        (res: any) => this.onSendSuccess(),
        (err: HttpErrorResponse) => {
          this.apiCall = false;
          this.systemService.handleErrorResponse(err)
        }
      )
  }
  private onSendSuccess() {
    this.apiCall = true;
    this.systemService.showSuccessSnackBar(("Request has been Sent for Approval"), "OK");
    this.router.navigate(['healthcoach/hctasks'])
  }

  getAllPatients() {

    var postdata = {
      task_id_fk: this.childid,
      user_id_fk: localStorage.getItem('scarletuserid'),
      page: this.page.toString(),
      limit: "20"
    }

    // if (this.searchcriteria) {
    //   this.searchcriteria = this.searchcriteria
    // } else {
    //   this.searchcriteria = ''
    // }
    // this.lastSearch = this.searchcriteria;
    // this.lastpage = this.page;
    this.isLoading = true;
    // this.searchcriteria = ''

    this.commonService.TaskPost(postdata, 'getPatientList')
      .subscribe(
        (res: any) => this.onTaskListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTaskListSuccess(data) {

    data = data.data;
    this.patientList = data;
    this.isLoading = false;



  }


  getProgress() {

    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      hc_id: localStorage.getItem('scarletuserid'),
    }

    this.commonService.getProgress(postData, 'getTaskProgress')
      .subscribe(
        (res: any) => {
          this.totalPatient = res.data[0].patient_list;
          if (res.data[0].progress.length > 0) {
            this.progressPatients = res.data[0].progress[0].patient_completed;
          }
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].progress.length > 0) {
              if (!this.progresspatendId.includes(res.data[i].progress[0].patient_completed[0]) && res.data[i].progress[0].patient_completed[0] != null && (_.filter(res.data[i].patient_list, person => person.id === res.data[i].progress[0].patient_completed[0]).length > 0)) {
                this.failedPatiendId = res.data[i].progress[0].patient_failed;
                this.pendingPatientId = res.data[i].progress[0].patient_ongoing;
                this.progresspatendId = res.data[i].progress[0].patient_completed;
              }
              // this.progresspatendId.push(res.data[i].progress[0].patient_completed[0]);
            }
          }


          this.getAllPatients();
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  checkprogress(id) {
    
    let data = this.progressPatients.includes(id);
    // 
    return data;

  }

  pendingList(patientList, progresspatendId) {
    return (patientList.length - progresspatendId.length)
  }



  checkProgress(res) {
    // for (let i = 0;i<patientList.length;i++)
    //   {
    //     let  patientId1 ;
    //     patientId1=this.patientList[i].id;
    //     
    //   }

    var patientId = this.patientList[0].id;

    var data = res.data.find(element => element.progress[0].patient_completed[0] == patientId);
    if (data) {
      let data1 = data.progress[0].patient_completed[0];
      if (patientId == data1) {
        this.iscomplete === true
      }
    }
    else {
      this.iscomplete === false
    }
    // this.patientLength = localStorage.getItem("patientLength");
    // let patientLength = parseInt(this.patientLength);
    // this.progressPatientLength = parseInt(this.progressPatientLength);
    // if (this.progressPatientLength == patientLength) {
    //   this.checkTaskComplete()
    // }
  }



  resetForm() {
    this.executionform.reset()
  }


  applyFilters() {
    if (this.startDate == undefined && this.endDate == undefined) {
      this.startDate = ''
      this.endDate = ''
    }
    var filterdata = {
      "performance": this.perf,
      "create_start_date": this.startDate,
      "create_end_date": this.endDate
    }

    var bodyData = {
      mode: "admin",
      hc_id: "",
      user_id_fk: "",
      q: "",
      filters: JSON.stringify(filterdata)
    }
    this.commonService.TaskPost(bodyData, 'filterOverview')
      .subscribe(
        (res: any) => this.onFilterSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onFilterSuccess(data) {
    data = data.data;
    this.overView = data

    this.page = 1;


  }


  async ngOnDestroy() {
    await this.getProgress();
  }
}
