import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorDataService } from '../../services/doctor/doctor.service';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent implements OnInit {

  today: number = Date.now();
  jstoday = '';

  checkboxValue: boolean;
  rawprescriptiondata: any
  rawdata: any;
  doctorData: any;
  constructor( private router: Router, private doctorService: DoctorDataService) {

    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');

  }

  ngOnInit() {
    let userData: string = localStorage.getItem('scarletuserdata');
    this.doctorData = JSON.parse(userData);
  }

  /**
  * @name serviceAgreement
  * @description Setting the localStorage to true when the doctor clicks on 'I Agree' button
  * @param No_param
  * @returns NULL
  */
  serviceAgreement() {
    this.doctorService.slaAgreement(this.doctorData.id.toString())
      .subscribe(
        (data) => {
          this.router.navigateByUrl('/doctor/home');
        })
  }

}
