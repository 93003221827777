import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { HcuserassignRoutingModule } from './hcuserassign.routing.module';
import { HcuserassignComponent } from './hcuserassign.component';
import { ChangehealthcoachComponent } from './changehealthcoach/changehealthcoach.component';
import { PatientprofileComponent } from './patientprofile/patientprofile.component';
import { TransferlistdataComponent } from './transferlistdata/transferlistdata.component';
import { ViewuserassignComponent } from './viewuserassign/viewuserassign.component';
import { HcassignleftnavModule } from '../../shared/hcassignleftnav/hcassignleftnav.module';
import { AdminleftnavModule } from '../../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../../shared/adminsectionleftnav/adminsectionleftnav.module';

@NgModule({
  declarations: [
    
    HcuserassignComponent,
    ChangehealthcoachComponent,
    PatientprofileComponent,
    TransferlistdataComponent,
    ViewuserassignComponent
    
  ],
  imports: [
    CommonModule,
    HcuserassignRoutingModule,
    ImageCropperModule,
    HcassignleftnavModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class HcuserassignModule {}