<div class="wrapper">
  <div class="row h-100">
    <div class="col-md-12">
      <h4>Crop and upload image</h4>
    </div>
    <div class="col-md-6">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="4 / 3"
        format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="col-md-6">
      <img class="image-data img-responsive" [src]=" croppedImage">

    </div>
    
  </div>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-md-12">
      <button mat-flat-button color="primary" class="submit-btn mt-15" (click)="dataObject()">Upload Profile Image
      </button>
    </div>
  </div>
  
</div>
<!-- <img [src]="croppedImage"/> -->