import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DietPlanAPIService {

  public apiUrl: string = environment.serverUrl;

  constructor(
    public httpService: HttpService
  ) { }

  createDietPlan(data: any){

    let url = this.apiUrl + 'patient/diet/plan';
    return this.httpService.post(url, data);
  }

  editDietPlan(id:any, data: any) {
    let url = this.apiUrl + `patient/diet/plan/edit/${id}`;
    return this.httpService.patch(url, data);
  }

  getAllDietPlan(data: any){
    let url = this.apiUrl + 'patient/diet/plan/list';

    let body = new HttpParams()
        .set("patient_data_fk", data.patient_data_fk)

    return this.httpService.post(url, body);
  }
}