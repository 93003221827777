import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { HcassignleftnavModule } from '../shared/hcassignleftnav/hcassignleftnav.module';
import { SharedModule } from './../../shared/shared.module';
import { ApproveDialogComponent } from './approve-dialog/approve-dialog.component';
import { CheckmultilingualComponent } from './checkmultilingual/checkmultilingual.component';
import { PublishRequestComponent } from './publish-request.component';
import { PublishRequestRoutingModule } from './publish-request.routing.module';
import { PublishdetailsComponent } from './publishdetails/publishdetails.component';
import { StartstagingComponent } from './startstaging/startstaging.component';
import { ViewcommentComponent } from './viewcomment/viewcomment.component';



@NgModule({
  declarations: [

    PublishRequestComponent,
    ApproveDialogComponent,
    StartstagingComponent,
    ViewcommentComponent,
    PublishdetailsComponent,
    CheckmultilingualComponent

  ],
  imports: [
    CommonModule,
    PublishRequestRoutingModule,
    ImageCropperModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    HcassignleftnavModule,
    SharedModule.forRoot()
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PublishRequestModule { }