<div class="" [formGroup]="dataForm">
    <div class="row">
        <h2 class="col-md-12 commontitleprimary">Select Doctor</h2>
        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Select Doctor</mat-label>
            <mat-select formControlName="user_code" single #singleSelectHC>
                <mat-option>
                    <ngx-mat-select-search class="tagmat" [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
                        [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmathc" ngModel
                        (ngModelChange)="filterdoc()" ngDefaultControl>
                        <div class="mat-select-search-custom-header-content">
                            <i class="material-icons">
                                search
                            </i></div>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredDoc | async" [value]="doctor.code">
                    {{doctor.name}}- {{doctor.code}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <div class="row">
        <div class="col-md-12">
            <button mat-flat-button color="primary" class="submit-btn" (click)="dataObject()"
                [disabled]="!dataForm.valid">
                Map Doctor</button>
        </div>
    </div>
</div>