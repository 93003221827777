<mat-drawer-container class="sidenav-container" autosize>
  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/clienthome"></app-breadcrumb>
      <div class="col-md-12">
        <h3 class="commontitleprimary">
          Clients
        </h3>
      </div>
      <div class="col-md-12">
        <form>
          <div class="form-group has-feedback has-search">
            <input [(ngModel)]="searchcriteria" (keyup)="applyFilter($event)" [ngModelOptions]="{standalone: true}"
              class="form-control" placeholder="Search">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>



      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td class="camelcased" mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="no_of_patients">
              <th mat-header-cell *matHeaderCellDef> Phone </th>
              <td class="camelcased" mat-cell *matCellDef="let element"> {{element.support_phone_number}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td class="camelcased" mat-cell *matCellDef="let element"> {{element.support_email_id}} </td>
            </ng-container>


            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button (click)="clickeditclient(element)"  tooltip="Edit">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy">
                  </button>
                  <!-- *ngIf="element.logoMediaId && element.bannerMediaId && element.coloredLogoMediaId" -->
                  <button tooltip="Remove branding" mat-button (click)="removeBranding(element.id)" [ngClass]="{disabled: !element.logoMediaId && !element.bannerMediaId && !element.coloredLogoMediaId && !element.darkLogoMediaId}">
                    <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="Wellthy">
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>

</mat-drawer-container>