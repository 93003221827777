import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
const defaultDataList = [
  [
    {
      id: 1,
      name: "The first chapter of First module.",
      rating: 3.2,
      numRating: 643,
      lastRatedAt: "12",
      lastUpdatedAt: "14",
      bounceRate: "52/68",
    },
    {
      id: 2,
      name: "The second chapter of First module.",
      rating: 2.4,
      numRating: 112,
      lastRatedAt: "18",
      lastUpdatedAt: "21",
      bounceRate: "25/27",
    }
  ],
  [
    {
      id: 1,
      name: "The first lesson of First module.",
      rating: 3.2,
      numRating: 643,
      lastRatedAt: "12",
      lastUpdatedAt: "14",
      bounceRate: "52/68",
    },
    {
      id: 2,
      name: "The second lesson of First module.",
      rating: 2.4,
      numRating: 112,
      lastRatedAt: "18",
      lastUpdatedAt: "21",
      bounceRate: "25/27",
    }
  ],
  [
    {
      id: 1,
      name: "The first quiz of First module.",
      rating: 3.2,
      numRating: 643,
      lastRatedAt: "12",
      lastUpdatedAt: "14",
      bounceRate: "52/68",
    },
    {
      id: 2,
      name: "The second quiz of First module.",
      rating: 2.4,
      numRating: 112,
      lastRatedAt: "18",
      lastUpdatedAt: "21",
      bounceRate: "25/27",
    }
  ]
];

@Component({
  selector: 'app-feedbackviewer',
  templateUrl: './feedbackviewer.component.html',
  styleUrls: ['./feedbackviewer.component.scss']
})

export class FeedbackviewerComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataList: Array<Array<Object>> = [];

  constructor() { }

  ngOnInit() {
    this.dataList = defaultDataList;
  }

  tabChanged($event) {
  }

}
