import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthenticateService, SystemService } from '../shared/services';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})

export class NavigatorComponent implements OnInit {

  public userACL: any;
  public orgData: any;
  public environmentData: any = environment;
  s3url: any = environment.s3url;
  public localStoredHCData: any;
  public orgid: any;
  public getClient: any;
  public doctorData: any;
  private menuOptions: any = [
    { "id": 1, "label": "Admin", "image_url": "assets/img/dls/navigator/admin.svg", "description": "check payments, manage doctors,products & account", "router": "/admin/hchome", "acl_key": "admin", "organization": "all" },
    { "id": 2, "label": "Healthcoach", "image_url": "assets/img/whistle.svg", "description": "Chat with users, view and analyse there progress, logs and reports.", "router": "/healthcoach/home", "acl_key": "healthcoach", "organization": "all" },
    { "id": 3, "label": "Doctor dashboard", "image_url": "assets/img/docactivation_icons/Icons_Doctor.svg", "description": "Visualise your patient’s journey", "router": this.doctorinitialrouting(), "acl_key": "doctor", "organization": "all" },
    { "id": 4, "label": "Patient activation", "image_url": "assets/img/docactivation_icons/Icons_Activate Patient.svg", "description": "Onboard patients to your therapy", "router": this.doctorinitialactivaterouting(), "acl_key": "doctor", "organization": "restrict" },
    { "id": 5, "label": "Therapy", "image_url": "assets/img/dls/navigator/therapy.svg", "description": "Manage and analyse app and other content across the products", "router": "/content/home", "acl_key": "admin", "organization": "restrict" },
    { "id": 6, "label": "Therapy", "image_url": "assets/img/dls/navigator/therapy.svg", "description": "Manage and analyse app and other content across the products", "router": "/content/home", "acl_key": "content", "organization": "restrict" },
    { "id": 7, "label": "Admin", "image_url": "assets/img/dls/navigator/admin.svg", "description": "Manage and Create doctors", "router": "/admin/doctors", "acl_key": "scarlet", "organization": "all" },
    { "id": 8, "label": "Admin", "image_url": "assets/img/dls/navigator/admin.svg", "description": "check payments, manage doctors,products & account", "router": "/admin/hchome", "acl_key": "superadmin", "organization": "all" },
    { "id": 9, "label": "Therapy", "image_url": "assets/img/dls/navigator/therapy.svg", "description": "Manage and analyse app and other content across the products", "router": "/content/home", "acl_key": "superadmin", "organization": "restrict" },
    { "id": 10, "label": "Task Management", "image_url": "assets/img/dls/navigator/taskmanagement.svg", "description": "create and manage tasks, track health coach performance.", "router": "/admin/tasklibrary", "acl_key": "admin", "organization": "all" },
    { "id": 11, "label": "Task Management", "image_url": "assets/img/dls/navigator/taskmanagement.svg", "description": "create and manage tasks, track health coach performance.", "router": "/admin/tasklibrary", "acl_key": "superadmin", "organization": "all" },
  ];
  public userOptions: any = [];

  constructor(
    private authService: AuthenticateService, 
    private browserTitle: Title, 
    private systemService: SystemService,
  ) { }


  private setSEOTags(): void {
    this.browserTitle.setTitle('Wellthy | All Products');
  }

  ngOnInit() {
    this.setSEOTags();
    /**Check for access also */
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.orgData = JSON.parse(localStorage.getItem("scarletOrganisation"));
    this.orgid = JSON.parse(localStorage.getItem("orgid"));
    this.userACL = this.getLocalStorage("scarletuserdata","user_acl");

    /**Check for permissions */
    if (this.environmentData.org == true) {
      this.menuOptions.forEach((data) => {
        if (this.userACL.includes(data.acl_key)) {
          if (this.orgid == 1) {
            this.userOptions.push(data);
          } else {
            if (data.organization == 'all') { 
              this.userOptions.push(data); 
            }
          }
        }
      });
    } else {
      this.menuOptions.forEach((data) => {
        if (this.userACL.includes(data.acl_key)) {
          this.userOptions.push(data);
        }
      });
    }
  }

  private getLocalStorage(attribute: string, key: string){

    if (localStorage.getItem(attribute) && JSON.parse(localStorage.getItem(attribute))[key]){
      return JSON.parse(localStorage.getItem(attribute))[key];
    }

  }

  doctorinitialrouting() {
    // new check for ferrer TNC
    let isDoctor = this.getLocalStorage("scarletuserdata", "user_type");

    if (isDoctor == 'doctor') {
      this.doctorData = JSON.parse(localStorage.getItem('scarletuserdata')).campaign_data;
      this.getClient = this.doctorData.filter(campaign_data => campaign_data.client_data == '9');
    }
    if (JSON.parse(localStorage.getItem('doctorsla'))) {
      return "/doctor/home";
    }
    else if(this.getClient!=0){
      return "/doctor/settings/serviceagreement-es";
    }
    else {
      return "/doctor/serviceagreement";
    }
  }

  switchOrg(d) {
    this.authService.switchOrganization(d)
  }

  doctorinitialactivaterouting() {
    return "/doctor/activate";
    // if(JSON.parse(localStorage.getItem('doctorsla'))){
    //   return "/doctor/activate";
    // }
    // else{
    //   return "/doctor/serviceagreement";
    // }
  }

  logoutAccount(): void {
    
    localStorage.removeItem('timerGetData');
    let isDoctor = this.getLocalStorage("scarletuserdata", "user_type");;

    if (isDoctor == 'doctor') {
      this.sendEventToCleverTap();
    }
    /**Now Logout */
    this.authService.logout()
      .pipe(
        catchError((error: any) => {
          window.location.reload();
          this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        finalize(() => {

        })
      )
      .subscribe(data => {
        this.systemService.showSuccessSnackBar(("Logout Successfully"), "OK");
        sessionStorage.clear();
        window.location.reload();
      });
     // localStorage.removeItem("selectedNotification");
      localStorage.removeItem("notifyPatientName");
  }

  sendEventToCleverTap() {
    let datap =
    {
      "d": [
        {
          "identity": localStorage.getItem('scarletuserid'),
          "ts": (Math.floor(new Date().valueOf() / 1000)),
          "type": "event",
          "evtName": "Logged Out",
          "evtData": {
            "Accessed": "Yes"
          }
        }
      ]
    }
    this.authService
      .sendEventData(datap)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
      })
  }
}