<div class="login-wrapper" *ngIf="refresh === '1'" >
    <div class="container">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="bg-white">
                    <div class="login-box">
                        <!-- <div class="beta-box">
                            <div style="margin-left:75px;">Go back to old app</div>
                            <div style="margin-top: -23px;margin-bottom: 65px;">
                            <input type="checkbox" id="toggle_checkbox" (change)="switchBeta()">
                            <label for="toggle_checkbox"></label>
                            </div>
                            </div> -->
                        <h4>Forgot Password</h4>
                        <p>{{displayVersion}}</p>

                        <mat-vertical-stepper fxLayout="column" [linear]="true" #stepper>
                            <mat-step [stepControl]="firstFormGroup">
                                <form [formGroup]="firstFormGroup" autocomplete="off">
                                    <ng-template matStepLabel>Fill out Username/Email</ng-template>

                                    <div class="form-group" style="top: 0%;">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Username/Email</mat-label>
                                            <input matInput placeholder="Enter Username/Email" type="email"
                                                (keyup)="keyFunc()" formControlName="doctor_code" autocomplete="off"
                                                required>
                                        </mat-form-field>
                                    </div>

                                    <div>
                                        <p *ngIf="doctorCode_UserNameValidate" style="color: red;margin: -17px 0 10px;font-size: 11px;">
                                            {{doctorCode_ErrorMessage}}
                                        </p>
                                        <button mat-flat-button color="primary" class="centerbtn" style="margin:15px 0;"
                                            (click)="sendOTP(stepper)"
                                            [disabled]="doctorCodeDetect ||  !firstFormGroup.valid  || doctorCode_UserNameValidate">Next</button>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step [stepControl]="secondFormGroup">
                                <ng-template matStepLabel>Enter OTP</ng-template>
                                <form [formGroup]="secondFormGroup" *ngIf="OtpSuccessDetect==201" autocomplete="off">

                                    <div class="form-group" style="top: 0%;">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter OTP</mat-label>
                                            <input matInput placeholder="Enter OTP" formControlName="otp" minlength="6"
                                                maxlength="6" autocomplete="off" required>
                                        </mat-form-field>
                                    </div>

                                    <div>
                                        <button mat-flat-button color="primary" class="centerbtn" style="margin:15px 0;"
                                            (click)="verifyOTP()" matStepperNext
                                            [disabled]="!secondFormGroup.valid">Next</button>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>Create New Password</ng-template>
                                <div>
                                    <form [formGroup]="dataForm" *ngIf="otpVerified==200" autocomplete="off">
                                        
                                        <mat-form-field appearance="outline">
                                            <mat-label>New Password</mat-label>
                                            <input matInput minlength="4" type="password" autocomplete="new-password"
                                                formControlName="new_password" [type]="hideNewPwd ? 'password' : 'text'"  (keyup)="PasswordTextChange($event)"
                                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="New Password"
                                                required>
                                                <mat-icon matSuffix (click)="hideNewPwd = !hideNewPwd">
                                                    <img src="assets/img/dls/icons/actiontable/Icon_View.svg" class="password-img"
                                                      [ngClass]="{grayimage: hideNewPwd }">
                                                  </mat-icon>
                                        </mat-form-field>
                                        <p *ngIf=" matchPassword==false"
                                            style="color: red;margin: -17px 0 10px;font-size: 11px;">Please provide a
                                            valid password</p>
                                        <br>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Confirm Password</mat-label>
                                            <input matInput minlength="4" type="password" autocomplete="new-password"
                                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                (keyup)="confirmPasswordTextChange($event)"
                                                formControlName="confirmPassword" placeholder="Confirm Password"
                                                [type]="hideConfirmPwd ? 'password' : 'text'" 
                                                required>
                                                <mat-icon matSuffix (click)="hideConfirmPwd = !hideConfirmPwd">
                                                    <img src="assets/img/dls/icons/actiontable/Icon_View.svg" class="password-img"
                                                      [ngClass]="{grayimage: hideConfirmPwd }">
                                                  </mat-icon>
                                        </mat-form-field>
                                        <p *ngIf="matchPassword1==false" style="color: red;margin: -17px 0 10px;font-size: 11px;">
                                            Please provide a valid password
                                        </p>
                                        <br>
                                        <p *ngIf="comparePasswords=='PasswordNotMatched' && matchPassword1==true"
                                            style="color: red;margin: -17px 0 10px;font-size: 11px;">Passwords are not
                                            matching</p>
                                        <span>
                                            <strong>Note:</strong> Minimum length of new password should
                                            be 8, atleast one uppercase, one lowercase and one number.
                                        </span>
                                    </form>
                                </div>




                            </mat-step>
                        </mat-vertical-stepper>
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-2">
                                <button mat-flat-button color="primary" class="centerbtn" style="margin:15px 0;"
                                    (click)="back()">Back</button>
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-2">
                              
                                <ng-container *ngIf="enableCaptcha">
                                    <button 
                                        mat-flat-button 
                                        color="primary" 
                                        id="recaptcha"
                                        class="centerbtn g-recaptcha" 
                                        style="margin:15px 0;"
                                        [disabled]="!enableDonebtn"
                                        [ngClass]="{'disabled': !enableDonebtn,'enabled': enableDonebtn}"
                                        data-sitekey="6Lcn2o4gAAAAAF2oqa71sJe1jxmgpzYJLvCqV6VJ"
                                        data-callback="captchaResolve"
                                        data-error-callback="captchaError"
                                        data-size="invisible" 
                                    >
                                        Done
                                    </button>   
                                </ng-container>
                                <ng-container *ngIf="!enableCaptcha">
                                    <button mat-flat-button  [ngClass]="{'disabled': !enableDonebtn,'enabled': enableDonebtn}"
                                    color="primary" type="submit" class="centerbtn" style="margin:15px 0;"
                                    (click)="createPassword()" [disabled]="!enableDonebtn">Done</button>   
                                </ng-container>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>