import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';
import { ActivatebottomsheetComponent } from './activationhome/activatebottomsheet/activatebottomsheet.component';
import { ActivationhomeRoutingModule } from './activationhome/activationhome.routing.module';
//import { ActivationhomeComponent } from './activationhome/activationhome.component';
import { DeeplinkbottomsheetComponent } from './activationhome/deeplinkbottomsheet/deeplinkbottomsheet.component';
import { GeneralqrbottomsheetComponent } from './activationhome/generalqrbottomsheet/generalqrbottomsheet.component';
import { GenericDeeplinkComponent } from './activationhome/genericdeeplink/genericdeeplink.component';
import { QrbottomsheetComponent } from './activationhome/qrbottomsheet/qrbottomsheet.component';
import { AllqrRoutingModule } from './allqr/allqr.routing.module';
//import { AllqrComponent } from './allqr/allqr.component';
//import { DocLabReportsComponent } from './components/doclabreports/doclabreports.component';
import { DocLabReportsRoutingModule } from './components/doclabreports/doclabreports.routing.module';
//import { DoctorGraphRoutingModule } from './components/doctorgraph/doctorgraph.routing.module';
//import { DoctorGraphComponent } from './components/doctorgraph/doctorgraph.component';
//import { DoctorpdfreportComponent } from './components/doctorpdfreport/doctorpdfreport.component';
import { DoctorpdfreportRoutingModule } from './components/doctorpdfreport/doctorpdfreport.routing.module';
import { DocuserlistnavbarModule } from './components/docuserlistnavbar/docuserlistnavbar.module';
import { GeneraldataRoutingModule } from './components/generaldata/generaldata.routing.module';
//import { DocuserlistnavbarComponent as DocUserListNavbarComponent } from './components/docuserlistnavbar/docuserlistnavbar.component';
//import { GeneraldataComponent as GeneralDataComponent } from './components/generaldata/generaldata.component';
import { GraphComponent } from './components/graph/graph.component';
import { HealthdataComponent as HealthDataComponent } from './components/healthdata/healthdata.component';
import { MealbreakupComponent as DocMealBreakupComponent } from './components/mealbreakup/mealbreakup.component';
//import { OtherdetailsComponent } from './components/otherdetails/otherdetails.component';
import { DocactivationRoutingModule } from './docactivation/docactivation.routing.module';
import { DocpatientgeneratedcodeRoutingModule } from './docpatientgeneratedcode/docpatientgeneratedcode.routing.module';
//import { DocactivationComponent } from './docactivation/docactivation.component';
//import { DocalertsComponent } from './docalerts/docalerts.component';
//import { DocpatientgeneratedcodeComponent } from './docpatientgeneratedcode/docpatientgeneratedcode.component';
//import { DocsettingsComponent as DocSettingsComponent } from './docsettings/docsettings.component';
import { DocsettingsRoutingModule } from './docsettings/docsettings.routing.module';
import { DoctorRoutingModule } from './doctor-routing.module';
//import { MainpanelComponent as DocMainPanelComponent, MainpanelComponent } from './mainpanel/mainpanel.component';
import { MainpanelRoutingModule } from './mainpanel/mainpanel.routing.module';
//import { QuickAccessComponent } from './modals/quick-access/quick-access.component';
import { QuickAccessRoutingModule } from './modals/quick-access/quick-access.routing.module';
//import { ResetpasswordComponent } from './modals/resetpassword/resetpassword.component';
import { ResetpasswordRoutingModule } from './modals/resetpassword/resetpassword.routing.module';
import { AlertsService } from './services/alerts/alerts.service';
import { DoctorDataService } from './services/doctor/doctor.service';
import { PatientDataService } from './services/patient/patient.service';
// import { UserDataService } from './services/userdata/user-data.service';
import { UserListService } from './services/userlist/user-list.service';
import { DocleftnavModule } from './shared/docleftnav/docleftnav.module';
import { SpanishTncRoutingModule } from './modals/spanish-tnc/spanish-tnc.routing.module';
//import { DocleftnavComponent, DocleftnavComponent as DocLeftNavComponent } from './shared/docleftnav/docleftnav.component';

@NgModule({
  declarations: [
   // MainpanelComponent,
    //DocalertsComponent,
    //DocleftnavComponent,
    //DocMainPanelComponent,
    //QuickAccessComponent,
    //DocLeftNavComponent,
    //DocUserListNavbarComponent,
    //DocSettingsComponent,
    HealthDataComponent,
    GraphComponent,
    //DoctorGraphComponent,
    //OtherdetailsComponent,
    //DocLabReportsComponent,
    //GeneralDataComponent,
    DocMealBreakupComponent,
    //ResetpasswordComponent,
    //DocactivationComponent,
   //DocpatientgeneratedcodeComponent,
   //AllqrComponent,
    //ActivationhomeComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
    GenericDeeplinkComponent,
    //DoctorpdfreportComponent
  ],
  imports: [
    CommonModule,
    DoctorRoutingModule,
    ActivationhomeRoutingModule,
    AllqrRoutingModule,
    DocLabReportsRoutingModule,
    //DoctorGraphRoutingModule,
    DocactivationRoutingModule,
    DocpatientgeneratedcodeRoutingModule,
    DocsettingsRoutingModule,
    MainpanelRoutingModule,
    QuickAccessRoutingModule,
    ResetpasswordRoutingModule,
    DoctorpdfreportRoutingModule,
    GeneraldataRoutingModule,
    DocleftnavModule,
    DocuserlistnavbarModule,
    SpanishTncRoutingModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService
  ],
  entryComponents: [
    DocMealBreakupComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
  ]
})

export class DoctorModule { }
