import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskadminRoutingModule } from './taskadmin-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TaskadminRoutingModule
  ]
})
export class TaskadminModule { }
