import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../../shared/shared.module';
//import { DeeplinkbottomsheetComponent } from './deeplinkbottomsheet/deeplinkbottomsheet.component';
//import { GeneralqrbottomsheetComponent } from './generalqrbottomsheet/generalqrbottomsheet.component';
//import { GenericDeeplinkComponent } from './genericdeeplink/genericdeeplink.component';
//import { QrbottomsheetComponent } from './qrbottomsheet/qrbottomsheet.component';
//import { MealbreakupComponent as DocMealBreakupComponent } from './../components/mealbreakup/mealbreakup.component';
import { AlertsService } from './../../services/alerts/alerts.service';
import { DoctorDataService } from './../../services/doctor/doctor.service';
import { PatientDataService } from './../../services/patient/patient.service';
import { UserListService } from './../../services/userlist/user-list.service';
import { AbbottreportComponent } from './abbottreport/abbottreport.component';
import { DoctorpdfreportComponent } from './doctorpdfreport.component';
import { DoctorpdfreportRoutingModule } from './doctorpdfreport.routing.module';
import { FerrerreportComponent } from './ferrerreport/ferrerreport.component';

@NgModule({
  declarations: [
    //DocMealBreakupComponent,
    DoctorpdfreportComponent,
    FerrerreportComponent,
    AbbottreportComponent
    //ActivatebottomsheetComponent,
    //DeeplinkbottomsheetComponent,
    //QrbottomsheetComponent,
    // GeneralqrbottomsheetComponent,
    // GenericDeeplinkComponent,
  ],
  imports: [
    CommonModule,
    DoctorpdfreportRoutingModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService
  ],
  /*entryComponents: [
    DocMealBreakupComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
  ]*/
})
export class DoctorpdfreportModule { }