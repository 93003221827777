<mat-drawer-container class="sidenav-container" autosize>

  <app-hcleftnav></app-hcleftnav>

  <div class="sidenav-content">
    <div class="col-md-12 topbroadcastbar">
      <div class="flex-ui">
        <div class="col-md-12" style="text-align: center;">
          <h3 class="broadcasttitle">Create Broadcast</h3>
        </div>
        <!-- <div class="col-md-4">
          <button mat-icon-button class="plusbroadcasttransparent" (click)="opencreatebroadcast()">
            <mat-icon>add</mat-icon>
          </button>
        </div> -->
      </div>
      <div class="flex-ui mt-40">
        <a mat-button class="col-md-4 btn btn-medium btn-filled"
            (click)="opencreatebroadcast()">
            Create Broadcast
        </a>
    </div>

      <div class="clearfix"><br /></div>

      <div *ngIf="filterList.length">
        <div class="col-md-2">
          <button mat-button [matMenuTriggerFor]="filterbroadcast" class="col-md-4 scar-dropdown">{{filterStatusValue}}</button>
          <!-- <button mat-button [matMenuTriggerFor]="filterbroadcast" class="sortbroadcastwhite">
            {{filterStatusValue}}
            <mat-icon>filter_list</mat-icon>
          </button> -->
          <mat-menu #filterbroadcast="matMenu">
            <button mat-menu-item *ngFor="let filter of filterList;" (click)="applyFilterType(filter)">
              <span>{{filter}}</span>
            </button>
            <button mat-menu-item (click)="applyFilterType('All')">
              <span>All</span>
            </button>
          </mat-menu>
        </div>
          <div class="col-md-10">
          <form [formGroup]="searchForm" class="broadcast-form">
            <div class="form-group has-feedback has-search">
              <input class="form-control" formControlName="searchText" (keyup)="applyFilter($event.target.value)"
                [value]="filterValue" placeholder="Search for broadcast here" autocomplete="off">
              <button mat-fab class="searchbtn" style="display: none;" (click)="applyFilter('')">
                <mat-icon matSuffix>search</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </div>


      <!-- <div class="col-md-12" *ngIf="filterList.length">
        <button mat-button [matMenuTriggerFor]="filterbroadcast" class="sortbroadcastwhite">
          {{filterStatusValue}}
          <mat-icon>filter_list</mat-icon>
        </button>
        <mat-menu #filterbroadcast="matMenu">
          <button mat-menu-item *ngFor="let filter of filterList;" (click)="applyFilterType(filter)">
            <span>{{filter}}</span>
          </button>
          <button mat-menu-item (click)="applyFilterType('All')">
            <span>All</span>
          </button>
        </mat-menu>

        <form [formGroup]="searchForm">
          <mat-form-field appearance="outline" class="broadcastsearch">
            <mat-label>Search for broadcast here</mat-label>
            <input matInput formControlName="searchText" (keyup)="applyFilter($event.target.value)"
              [value]="filterValue" placeholder="Search for broadast here" autocomplete="off">
            <button class="crosssearchbtn" *ngIf="filterValue" mat-icon-button aria-label="Clear"
              (click)="applyFilter('');">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div> -->

      <div class="clearfix"><br /></div>
      <div class="col-md-12">
        <div class="broadcasttable">
          <div class="spinner-container" *ngIf="dataSource.loading$ | async; else brodcastTable">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <ng-template #brodcastTable>
            <div class="nodata-card" *ngIf="dataSource.brodcastFilterData.length === 0">
              <div style="position: absolute;top: 50%;transform: translateY(-50%);width: 100%;">  
                <span>Currently there is no broadcast created</span>
              </div>
            </div>
            <div [hidden]="!(dataSource.brodcastFilterData && dataSource.brodcastFilterData.length)"
              class="table-container">
              <table mat-table [dataSource]="dataSource" class="table-fixed-container list-table">

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Category </th>
                  <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="message">
                  <th mat-header-cell *matHeaderCellDef> Message </th>
                  <td mat-cell *matCellDef="let element"> {{element.message.substring(0,10)}}... </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef> Created At </th>
                  <td mat-cell *matCellDef="let element"> {{element.created_at | date:'HH:mm'}} On
                    {{element.created_at | date:'dd MMM yyyy'}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <a *ngIf="!(brodcastDeletionID == element.id)" tooltip="Delete Brodcast" href="javascript:;"
                      (click)="deleteBrodcast(element.id, $event)">
                      <img src="assets/img/healthcoach/vectors/bin.svg" class="img-fluid" />
                    </a>
                    <!-- Loader for inline table delete button begin-->
                    <div *ngIf="brodcastDeletionID == element.id">
                      <mat-spinner class="spinner" strokeWidth="3" [diameter]="25"></mat-spinner>
                    </div>
                    <!-- <app-loader *ngIf="brodcastDeletionID == element.id" showLoader="{{true}}"></app-loader> -->
                    <!-- Loader for inline table delete button end-->
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  (click)="selection.toggle(row);handleRowSelection(row);"
                  [class]="highlightedRow == row ? 'lightdark userrowtb1' : 'userrowtb1'"></tr>
              </table>
              <!-- <mat-paginator [length]="dataSource?.dataLength" (page)="paginateBrodcast($event)" [pageSize]="10"
                      [pageSizeOptions]="[10, 15, 20]"></mat-paginator> -->
            </div>
          </ng-template>
        </div>
      </div>
    
    </div>
  </div>
</mat-drawer-container>