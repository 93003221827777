import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../shared/shared.module';
import { MediainformationComponent } from './../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

import { EditlevelComponent } from './editlevel/editlevel.component';
import { LevelRoutingModule } from './level.routing.module';
import { LevelComponent } from './level.component';
import { CreateRequestComponent } from './create-request/create-request.component';
import { LeveldifferenceComponent } from './leveldifference/leveldifference.component';
import { ContentleftnavModule } from '../shared/contentleftnav/contentleftnav.module';
import { ContentnavbarModule } from '../shared/contentnavbar/contentnavbar.module';
import { TherapysetupleftnavModule } from '../shared/therapysetupleftnav/therapysetupleftnav.module';
import { ViewdetailsComponent } from './viewdetails/viewdetails.component';
import { ViewcommentComponent } from './viewcomment/viewcomment.component';

@NgModule({
  declarations: [
    LevelComponent,
    EditlevelComponent,
    CreateRequestComponent,
    LeveldifferenceComponent,
    ViewdetailsComponent,
    ViewcommentComponent
  ],
  imports: [
    CommonModule,
    LevelRoutingModule,
    ContentleftnavModule,
    ContentnavbarModule,
    TherapysetupleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LevelModule { }
