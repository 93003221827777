<img src="https://cdn.dribbble.com/users/159981/screenshots/3269471/checkmark2.gif" *ngIf="showsuccess"
        style="width: 100%;" />
<div class="pull-right">
        <div class="close-icon">
                <i class="material-icons mat-dialog-close" (click)="onNoClick()">
                        close
                </i>
        </div>
</div>
<p class="generictitle" *ngIf="!showsuccess">
        Lab Reports
</p>
<br />
<form [formGroup]="labreportForm" (ngSubmit)="labreportObject()" *ngIf="!showsuccess" validate>
        <mat-form-field class="customselectboxui">
                <mat-label>Select an option</mat-label>
                <mat-select formControlName="labtype" [(ngModel)]="defaultleftoption"
                        (selectionChange)="changeLabOptions()">
                        <mat-option *ngFor="let tag of eligibleLogData" [value]="tag.title">
                                {{changeTextInList(tag.title)}}
                        </mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field class="customselectboxui option-selection bsugar"
                *ngIf="defaultleftoption == 'Complete Blood Count'">
                <mat-label>Sub Type</mat-label>
                <mat-select class="camelcased notranslate" formControlName="cbclabtype" [(ngModel)]="defaultcbcoption">
                        <mat-option class="camelcased" value="cbc/meanPlateletVolume">Mean Platelet Volume</mat-option>
                        <mat-option class="camelcased" value="cbc/wbcDifferential">WBC differential count</mat-option>
                        <mat-option class="camelcased" value="cbc/rbc">Red blood cell count (RBC or
                                Erythrocyte count )</mat-option>
                        <mat-option class="camelcased" value="cbc/hematocrit">Hematocrit (Hct)</mat-option>
                        <mat-option class="camelcased" value="cbc/mcv">Mean corpuscular volume (MCV)</mat-option>
                        <mat-option class="camelcased" value="cbc/plateletCount">Platelet count</mat-option>
                        <mat-option class="camelcased" value="cbc/mch">Mean corpuscular hemoglobin (MCH)
                        </mat-option>
                        <mat-option class="camelcased" value="cbc/mchc">Mean corpuscular hemoglobin concentration (MCHC)
                        </mat-option>
                        <mat-option class="camelcased" value="cbc/rdw">Red cell distribution width (RDW)
                        </mat-option>
                        <mat-option class="camelcased" value="cbc/esr">Erythrocyte sedimentation rate (ESR)</mat-option>
                        <mat-option class="camelcased" value="cbc/wbc">White blood cell count</mat-option>
                </mat-select>
        </mat-form-field>

        <mat-form-field class="customselectboxui">
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date"
                        formControlName="on_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker></mat-datepicker>
        </mat-form-field>

        <!-- HbA1C -->

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'HbA1C'">
                <input matInput placeholder="Hba1c Value [ 2 - 20%]" minlength="0" maxlength="25" formControlName="val"
                        type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" required>
                <span matSuffix>%</span>
        </mat-form-field>

        <!-- Cholestrol -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cholesterol'">
                <input matInput placeholder="Total Cholesterol Value [1 - 999 mg/dL]" minlength="0.1" maxlength="999"
                        formControlName="tc" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" required/>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cholesterol'">
                <input matInput placeholder="Total HDL Value [15- 150 mg/dL]" minlength="0.1" maxlength="159"
                        formControlName="hdl" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" required/>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cholesterol'">
                <input matInput placeholder="Total LDL Value [20 - 550 mg/dl]" minlength="0.1" maxlength="550"
                        formControlName="ldl" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" required/>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cholesterol'">
                <input matInput placeholder="Total Triglyceride Value [40 - 1000 mg/dl]" minlength="0.1"
                        maxlength="1000" formControlName="triglyceride" type="number" step="0.01"
                        pattern="^\d+(?:\.\d{0,1})?$" required/>
        </mat-form-field>

        <!-- Urine Creatinine -->

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Urine Creatinine'">
                <input matInput placeholder="Urine Creatinine Value [400-3000 mg/24h]" minlength="0.1" maxlength="10"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- Serum Creatinine -->

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Serum Creatinine'">
                <input matInput placeholder="Serum Value in mg/dL[0.1 - 40 mg/dL]" minlength="0" maxlength="40"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- Urine Uric Acid -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Urine Uric Acid'">
                <input matInput placeholder="Uric Acid Value[0-1500 mg/24h]" minlength="0" maxlength="1500"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'IgE'">
                <input matInput placeholder="Enter IgE Value in IU/ml (20-2000)" minlength="20" maxlength="2000"
                        formControlName="measure_val" type="number">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'FeNO Test/Exhaled Nitric Oxide'">
                <input matInput placeholder="Enter FeNO Value in ppb(5-300)" minlength="5" maxlength="300"
                        formControlName="measure_val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Pulse Oximetry'">
                <input matInput placeholder="Enter Pulse Oximetry Value in [0-100%]" minlength="0" maxlength="100"
                        formControlName="measure_val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Eosinophil Count'">
                <input matInput placeholder="Enter Blood Eosinophils Value in %(0-99)" minlength="0" maxlength="99"
                        formControlName="blood_eosinophils" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Eosinophil Count'">
                <input matInput placeholder="Enter Sputum Eosinophils Value in %(0-99)" minlength="0" maxlength="99"
                        formControlName="sputum_eosinophils" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Eosinophil Count'">
                <input matInput placeholder="Enter Absolute Eosinophils Value in μL−1(0-20000)" minlength="0"
                        maxlength="20000" formControlName="absolute_eosinophils" type="number">
        </mat-form-field>


        <!-- Serum Uric Acid -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Serum Uric Acid'">
                <input matInput placeholder="Serum Uric Acid Value[0.4 -20 mg/dL]" minlength="0.1" maxlength="30"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- Blood Urea -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Blood Urea'">
                <input matInput placeholder="Blood Urea Value [3-120 mg/dL]" minlength="0.1" maxlength="125"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" required>
        </mat-form-field>

        <!-- Urine Albumin -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Urine Albumin'">
                <input matInput placeholder="Urine albumin [0 - 800 mg/24 hr]" minlength="0" maxlength="1500"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- eGFR -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'eGFR'">
                <input matInput placeholder="eGFR Value [1 - 500 mL/min/1.73 m^2] " minlength="1" maxlength="130" formControlName="val"
                        type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- Thyroid function -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Thyroid Function'">
                <input matInput placeholder="T3 [1 to 600 ng/dL]" minlength="1" maxlength="999" formControlName="t3"
                        type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Thyroid Function'">
                <input matInput placeholder="Total T4 [0.1 to 30 mcg/dL]" minlength="0" maxlength="999"
                        formControlName="total_t4" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Thyroid Function'">
                <input matInput placeholder="Free T4 [0.1 to 15 ng/dl]" minlength="0" maxlength="99"
                        formControlName="free_t4" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Thyroid Function'">
                <input matInput placeholder="TSH [0.1 to 25 mU/L]" minlength="0" maxlength="99" formControlName="tsh"
                        type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- Serum Electrolytes -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Electrolytes'">
                <input matInput placeholder="Total sodium [40 - 205 mEq/L]" minlength="50" maxlength="300"
                        formControlName="sodium" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" />
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Electrolytes'">
                <input matInput placeholder="Total potassium [1.5 - 15 mEq/L]" minlength="0.5" maxlength="20"
                        formControlName="potassium" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" />
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Electrolytes'">
                <input matInput placeholder="Total chloride [50-200 mEq/L]" minlength="8" maxlength="400"
                        formControlName="chloride" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" />
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Electrolytes'">
                <input matInput placeholder="Total bicarbonate [15-40 mEq/L]" minlength="3" maxlength="100"
                        formControlName="bicarbonate" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$" />
        </mat-form-field>

        <!-- Hemoglobin -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Haemoglobin'">
                <input matInput placeholder="Haemoglobin [1 - 25 in g/dL]" minlength="1" maxlength="25"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- BNP -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'BNP'">
                <input matInput placeholder="BNP Value [5-5000 pgmol/L] " minlength="10" maxlength="3000"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <!-- NT Pro Bnp -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'NT-proBNP'">
                <input matInput placeholder="NT-proBNP Value [5-35000 pg/mL]" minlength="30" maxlength="10000"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Coagulation Factor'">
                <input matInput placeholder="APTT Value [18-150 seconds]" minlength="30" maxlength="10000"
                        formControlName="aptt" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Coagulation Factor'">
                <input matInput placeholder="Fibrinogen Value [100-900 mg/dL]" minlength="30" maxlength="10000"
                        formControlName="fibrinogen" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>


        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbc'">
                <input matInput placeholder="WBC Value in(0.2x 10^3/μl to 99 x 10^3/μl)" minlength="0" maxlength="99"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/rbc'">
                <input matInput placeholder="RBC Value in (0.2 x 10^6 /μl to 8 x 10^6 /μl)" minlength="30"
                        maxlength="10000" formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/hematocrit'">
                <input matInput placeholder="Hematocrit Value in (1 - 100 %)" minlength="0" maxlength="100"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/mch'">
                <input matInput placeholder="MCH Value in (1 pg to 999 pg)" minlength="1" maxlength="999"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/mcv'">
                <input matInput placeholder="Mcv Value (1 fL to 200 fL)" minlength="1" maxlength="200"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/mchc'">
                <input matInput placeholder="MCHC Value in (1 g/dl to 100 g/dl)" minlength="1" maxlength="200"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/rdw'">
                <input matInput placeholder="RDW in (1 fL to 100 fL)" minlength="1" maxlength="200"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/plateletCount'">
                <input matInput placeholder="PlateletCount in (10x 10^3 /μl to 1000 x 10^3 /μl)" minlength="30"
                        maxlength="10000" formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/esr'">
                <input matInput placeholder="ESR Value in (0.1 mm/hr to 999 mm/hr)" minlength="1" maxlength="999"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/meanPlateletVolume'">
                <input matInput placeholder="MPV in fL [0.2 fL to 9999 fL]" minlength="0.1" maxlength="9999"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbcDifferential'">
                <input matInput placeholder="Neutrophile in [0.1 to 100 %]" minlength="0.1" maxlength="100"
                        formControlName="neutrophile" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbcDifferential'">
                <input matInput placeholder="Lymphocyte in [0.1 to 100 %]" minlength="0.1" maxlength="100"
                        formControlName="lymphocyte" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbcDifferential'">
                <input matInput placeholder="Monocyte in [0.1 to 100 %]" minlength="0.1" maxlength="100"
                        formControlName="monocyte" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbcDifferential'">
                <input matInput placeholder="Eosinophil in [0.1 to 100 %]" minlength="0.1" maxlength="100"
                        formControlName="eosinophil" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbcDifferential'">
                <input matInput placeholder="Basophil in [0.1 to 100 %]" minlength="0.1" maxlength="100"
                        formControlName="basophil" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultcbcoption == 'cbc/wbcDifferential'">
                <input matInput placeholder="Immature granulocyte in [0.1 to 100 %]" minlength="0.1" maxlength="100"
                        formControlName="immature_granulocyte" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Spirometry'" appearance="outline">
                <mat-select formControlName="fev1_unit" placeholder="Choose FEV1 unit"
                        (selectionChange)="fev1Unit($event.value)" required>
                        <mat-option value="%">%</mat-option>
                </mat-select>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Spirometry'">
                <input matInput placeholder="FEV1 Value " [minlength]="minFev1Unit" [maxlength]="maxFev1Unit"
                        formControlName="fev1" type="number">
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Spirometry'" appearance="outline">
                <mat-select formControlName="fvc_unit" placeholder="Choose FVC unit"
                        (selectionChange)="fvcUnit($event.value)" required>
                        <mat-option value="%">%</mat-option>
                </mat-select>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Spirometry'">
                <input matInput placeholder="FVC Value" [minlength]="minFvcUnit" [maxlength]="maxFvcUnit"
                        formControlName="fvc" type="number">
        </mat-form-field>


        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Spirometry'">
                <input matInput placeholder="FEV1/FVC Ratio (0-99)" minlength="0" maxlength="99"
                        formControlName="fev1_fvc_ratio" type="number">
        </mat-form-field>


        <!-- 6 Min Walk -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == '6MWT'">
                <input matInput placeholder="Distance in Meters (Range 0-1000)" min="0" max="1000" formControlName="MWT"
                        type="number">
                <mat-error>
                        Cannot be greater than 1000
                </mat-error>
        </mat-form-field>

        <!-- 6 Min Walk -->
        <!-- Billirubin -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Bilirubin'">
                <input matInput placeholder="Serum Billirubin (mg/dl) (Range 0-20)" min="0" max="20"
                        formControlName="serum_bilirubin" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Bilirubin'">
                <input matInput placeholder="Direct Billirubin (mg/dl)  (Range 0-20)" min="0" max="20"
                        formControlName="direct_bilirubin" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Bilirubin'">
                <input matInput placeholder="Indirect Billirubin (mg/dl) (Range 0-20)" min="0" max="20"
                        formControlName="indirect_bilirubin" type="number">
        </mat-form-field>

        <!-- Billirubin -->

        <!-- Cardiac tropin -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cardiac Troponin'">
                <input matInput placeholder="Troponin I (ng/ml)  (Range 0-20)" min="0" max="20"
                        formControlName="troponin_i" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cardiac Troponin'">
                <input matInput placeholder="Troponin T (high sensitivity test) (ng/L) (Range 0-50)" min="0" max="50"
                        formControlName="troponin_t_high_sensitivity" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Cardiac Troponin'">
                <input matInput placeholder="Troponin T (ng/ml) (Range 0-10)" min="0" max="10"
                        formControlName="troponin_t" type="number">
        </mat-form-field>

        <!-- Billirubin -->
        <!-- INR -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'International Normalised Ratio'">
                <input matInput placeholder="International Normalised ratio (Range 0-10)" min="0" max="10"
                        formControlName="inr" type="number">
        </mat-form-field>
        <!-- INR -->

        <!-- Iron Status -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Iron Status'">
                <input matInput placeholder="Iron (mcg/dL) (Range 0-400)" min="0" max="400"
                        formControlName="iron" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Iron Status'">
                <input matInput placeholder="Ferritin (ng/ml) (Range 0-400)" min="0" max="400"
                        formControlName="ferritin" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Iron Status'">
                <input matInput placeholder="Soluble Tranferrin receptor(mg/l) (Range 0-10)" min="0" max="10"
                        formControlName="soluble_transferrin_receptor" type="number">
        </mat-form-field>

        <!-- Iron Status -->
        <!-- Liver Enzym -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Liver Enzymes'">
                <input matInput placeholder="Aspartate Aminotransferase (AST) (Range 10-550)" minlength="10"
                        maxlength="550" formControlName="ast" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Liver Enzymes'">
                <input matInput placeholder="Alanine Aminotransferase (ALT) (Range 1-1200)" minlength="0" maxlength="99"
                        formControlName="alt" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Liver Enzymes'">
                <input matInput placeholder="Alkaline Phosphatase (ALP) (Range 20-900)" minlength="20" maxlength="900"
                        formControlName="alp" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Liver Enzymes'">
                <input matInput placeholder="Gamma Glutamyl Transferase (GGT) (Range 1-1200)" minlength="0"
                        maxlength="99" formControlName="ggt" type="number">
        </mat-form-field>

        <!-- Iron Status -->

        <!-- Abbott -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Alpha-fetoprotein AFP'">
                <input matInput placeholder="Alpha-fetoprotein AFP [0 - 1000 ng/mL]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui"
                *ngIf="defaultleftoption == 'Free Beta HCG - Human chorionic gonadotropin'">
                <input matInput placeholder="Free Beta HCG [0 - 90000 mIU/ml]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Estriol Free'">
                <input matInput placeholder="Estriol Free [0 - 50 ng/mL]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Inhibin A'">
                <input matInput placeholder="Inhibin A [0 - 1000 pg/ml]" minlength="0" maxlength="99"
                        formControlName="mes" type="number" required>
        </mat-form-field>
        <mat-form-field class="customselectboxui"
                *ngIf="defaultleftoption == 'Pregnancy-associated plasma protein-A - PAPPa'">
                <input matInput placeholder="PAPPa [0 - 50 mIU/ml]" minlength="0" maxlength="99" formControlName="mes"
                        type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Urine Glucose'">
                <input matInput placeholder="Urine Glucose [0 - 9000 mg/dL]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Urine - Protein'">
                <input matInput placeholder="Urine Protein [0 - 800 mg/24hr] [0 - 9000 mg/dL]" minlength="0"
                        maxlength="99" formControlName="mes" type="number">
                <span></span>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Urine - Protein'">
                <mat-label>Select Units</mat-label>
                <mat-select class="camelcased" formControlName="unitsforprotien" required>
                        <mat-option class="camelcased" value="mg/24hr">mg/24hr</mat-option>
                        <mat-option class="camelcased" value="mg/dL">mg/dL</mat-option>
                </mat-select>
        </mat-form-field>

        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'AMH Anti-Müllerian Hormone'">
                <input matInput placeholder="AMH Anti-Müllerian Hormone [0 - 100 ng/mL]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Antral follicular count'">
                <input matInput placeholder="Antral follicular count [0 - 80]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Estradiol'">
                <input matInput placeholder="Estradiol [0 - 9000 pg/ml]" minlength="0" maxlength="99"
                        formControlName="mes" type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'FSH Follicle-stimulating hormone'">
                <input matInput placeholder="FSH [0 - 500 mIU/ml]" minlength="0" maxlength="99" formControlName="mes"
                        type="number">
        </mat-form-field>
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Inhibin B'">
                <input matInput placeholder="Inhibin B [0 - 1000 pg/ml]" minlength="0" maxlength="99"
                        formControlName="mes" type="number" required>
        </mat-form-field>

        <!-- Abbott -->
        <!-- Blood urea nitrogen -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Blood urea nitrogen'">
                <input matInput placeholder="Blood Urea Nitrogen Value [0-100 mg/dL]" minlength="0.1" maxlength="125"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <!-- CRP-->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'CRP'">
                <input matInput placeholder="CRP [0-1000 mg/dL]" minlength="0.1" maxlength="125" formControlName="val"
                        type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <!-- Albumin-->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Albumin'">
                <input matInput placeholder="Albumin Value [0-100 mg/dL]" minlength="0.1" maxlength="125"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <!-- RF -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Rheumatoid Factor'">
                <input matInput placeholder="RF Value [0-100 IU/mL]" minlength="0.1" maxlength="125"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <!-- Anti-CCP -->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Anti Cyclic Citrullinated Peptide antibodies'">
                <input matInput placeholder="Anti - CCP Value [0-1000 EU/mL]" minlength="0.1" maxlength="125"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <!-- ANA-->
        <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Antinuclear antibody'">
                <input matInput placeholder="ANA Value [0-1000 unit]" minlength="0.1" maxlength="125"
                        formControlName="val" type="number" step="0.01" pattern="^\d+(?:\.\d{0,1})?$">
        </mat-form-field>
        <div class="col-md-12" style="margin-left: -4%;margin-top: 1%;"
                *ngIf="selectedTag=='HbA1C' || selectedTag=='Cholesterol'">
                <mat-radio-group aria-label="Select an option" required formControlName="tag">
                        <mat-radio-button [value]="tag" class="generictitle" *ngFor="let tag of MCHITagData">{{tag |
                                uppercase}}&nbsp;&nbsp;&nbsp;</mat-radio-button>
                </mat-radio-group>
        </div>

        <br />
        <br />
        <span *ngIf="showerror" class="text-danger invalidentrycss">Invalid values entered</span>
        <br />



        <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn" *ngIf="!showButtonLoader"
                [disabled]="!labreportForm.valid">Add Report</button>
        <mat-spinner style="margin-left: 125px;" class="upload-spinner" strokeWidth="5" [diameter]="50"
                *ngIf="showButtonLoader"></mat-spinner>

        <!-- <app-loader showLoader="{{true}}" *ngIf="showButtonLoader"></app-loader> -->
</form>