import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterlang',
  pure: false
})
export class FilterlangPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // 
    // Remove the duplicate elements
    var art = value.map(x=>{
      return x.value.id;
        // return x.map(y=>{ return y.value.id;});
    }).reduce((acc,ele,i)=>{
        acc = acc.concat(ele);
        return acc;
    });
    return new Set(art);
}

}
