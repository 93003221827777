import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../shared/guards';
import { PaymentplanComponent } from './paymentplan.component';
import { EditpaymentplanComponent } from './editpaymentplan/editpaymentplan.component';

const routes: Routes = [
  { path: '', component: PaymentplanComponent, canActivate: [AdminGuardGuard] },
  { path: 'payment/edit/:id/:langid/:chid', component: EditpaymentplanComponent, canActivate: [AdminGuardGuard] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentplanRoutingModule {}