import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { MobileNumberChangeAPIService } from 'src/app/shared/services/mobile-number-change-api.service';
import { NumberChangeActionComponent } from '../../mobile-change-request/number-change-action/number-change-action.component';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-patient-events',
  templateUrl: './patient-events.component.html',
  styleUrls: ['./patient-events.component.scss']
})
export class PatientEventsComponent implements OnInit {
  public searchTerm = '';
  public dataSource: any = [];
  public eventCountArr: any = [];
  public displayedColumns: string[] = ['Event Name', 'Patientid', 'Timestamp', 'action'];
  public filterList:any = []
  public pageNumber: number = 1;

  public admin;
  toppings = this._formBuilder.group({
    pepperoni: false,
    extracheese: false,
    mushroom: false,
  });
  eventData: any = [];
  public eventArr: any = [];
  endofEventList: boolean;
  endofEventCount: boolean;
  eventPageNo: number = 1;
  eventCountNo: number = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PatientEventsComponent>, private apiService: MobileNumberChangeAPIService,
    private systemService: SystemService,
    private dialog: MatDialog,
    private eventService:CommonserviceService,
    private _formBuilder: FormBuilder
  ) {

    let adminData = JSON.parse(localStorage.getItem('scarletuserdata'))

    this.admin = {
      id: adminData.id, 
      name: adminData.name
    }
  }

  ngOnInit(): void {
    this.getPatientEventCount();
    this.getPatientAllEvent();
    localStorage.removeItem('filterScrollPos');

  }
  getPatientAllEvent(filterName?,id?) {
    if(filterName === '' || filterName === null || filterName === undefined){
      this.filterList = [];
    }
   
    this.eventService.getPatientAllEvent(this.data.id,this.eventPageNo,this.filterList).subscribe((data) => {
      if (data.status === 200){
        if(data.data.length > 0 ){
          this.endofEventList = false;
          for (var k = 0; k < data.data.length; k++) {
            this.eventArr.push(data.data[k]);
          }
          this.eventData = this.eventArr;
        }else{
          this.endofEventList = true;
          this.eventData =  this.eventArr;
        }
       
      }
      setTimeout(function () {
          const element =  (<HTMLElement>document.getElementById('filter'+id));
          element.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }
  getPatientEventCount() {
    this.eventService.getPatientEventCount(this.data.id).subscribe((data) => {
      if (data.status === 200){
        if(data.data.length > 0 ){
          this.endofEventCount = false;
          for (var k = 0; k < data.data.length; k++) {
            data.data[k]["checked"] = false;
            this.eventCountArr.push(data.data[k]);
          }
          this.dataSource = this.eventCountArr;
         
        }else{
          this.endofEventCount = true;
          this.dataSource = this.eventCountArr;
        }
      }
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }

  textSearch(){
    this.pageNumber = 1;
    this.dataSource = [];
    this.search();
  }

  search(){
    this.apiService.getAllPhoneChangeRequest(this.searchTerm, this.pageNumber).subscribe((data) => {

      if (data.status === 200){
        this.dataSource = [...this.dataSource, ...data.data];
      }
      
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }

  getNextSet(){
    this.pageNumber += 1;
    this.search();
  }

  onEventFilterChange(event:any,filterName:any,i?){
    localStorage.setItem('event',event);
    localStorage.setItem('filtername',event);
    this.filterList = [...new Set(this.filterList)];
    if(event.checked === true){
      this.filterList.push(filterName)
      this.updateCheckbox(true,filterName);
    }else {
      this.updateCheckbox(false,filterName);
     
    }
   this.eventPageNo = 1;
   this.eventArr = [];
   this.eventData = [];
   this.getPatientAllEvent(this.filterList,i)
  }
  updateCheckbox(value,filterName){
      for(let j = 0 ; j <  this.dataSource.length;j++){
        if(filterName === this.dataSource[j].event){
          if(value === true){
            this.dataSource[j].checked = true;
          }else{
            this.dataSource[j].checked = false;
            this.filterList = this.filterList.filter(e => e !== filterName)
          }
      }
   }
  
  }
  close(){
    this.dialogRef.close();
  }
  eventRefresh(){
  this.eventPageNo = 1;
  this.eventArr = [];
  this.eventData = [];
  this.eventCountArr = [];
  this.dataSource = [];
  this.filterList = [];
  this.getPatientEventCount();
  this.getPatientAllEvent(this.filterList);
  }
  getNextSetFilter(){
    if(this.endofEventCount === false){
      this.getPatientEventCount()
    }

  }
  getNextSetEvent(){
    if(this.endofEventList === false){
      this.eventPageNo = this.eventPageNo + 1
      this.getPatientAllEvent(this.filterList)
    }
  }
  scrollPos(event){
    var position = document.getElementById('filter').scrollTop.toString();
    localStorage.setItem('filterScrollPos',position);
  }
}
