import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IPersistenceContainer, PersistenceService, StorageType } from 'angular-persistence';
import { SystemService } from 'src/app/shared/services';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { ContextService } from '../../../services/context.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class ActivityComponent implements OnInit {
  searchcriteria: any;
  showloader: any;
  public page: number = 1;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: Section[];
  private tabID: number = 0;
  public emptyplaceholder: boolean = false;
  public status: any = 'all';
  public filtercategory: any = null;
  public filterval: any = null;
  public notiftype: any = 'all';
  public selectedUserID: any;
  public subscribeObj: any;
  private container: IPersistenceContainer;
  public notificationtypes = [
    ['reminder_added_event', 'Reminder', 7],
    ['reminder_updated_event', 'Reminder', 7],
    ['medicine_added_event', 'Medicine', 7],
    ['medicine_updated_event', 'Medicine', 7],
    ['high_bp_event', 'Blood Pressure', 5],
    ['low_bp_event', 'Blood Pressure', 5],
    ['low_blood_sugar_event', 'Blood Sugar', 5],
    ['high_blood_sugar_event', 'Blood Sugar', 5],
    ['meal_event', 'Meal', 5],
    ['infusion_site_event', 'Infusion Site', 5],
    ['onboarding_event', 'Onboarded', 3],
    ['prescription_upload', 'Prescription', 7],
    ['prescription_update', 'Prescription', 7],
    ['caregiver_updated', 'Care Giver', 7],
    ['caregiver_added', 'Care Giver', 7],
    ['lab_report_event', 'Lab Report', 6],
    ['doctor_assignment_event', 'Doctor Assigned', 7],
    ['doctor_reassignment_event', 'Doctor Assigned', 7],
    ['patient_transfer_event', 'Patient Transferred', 3],
    ['symptom_event', 'Symptom Event', 7],
    ['trigger_event', 'Trigger', 7],
    ['questionnaire_event', 'Questionnaire', 7],
    ['reminder_added_event', 'Reminder', 7],
    ['patient_note_event', 'Patient Note', 2],
    ['weight_event', 'Weight Added', 5],
    ['activity_event', 'Activity', 5],
    ['adverse_reaction_event', 'Adverse Event', 7],
    ['medication_added_event', 'Medication', 7],
    ['medication_updated_event', 'Medication', 7],
    ['pump_added_event', 'Pump', 7],
    ['pump_updated_event', 'Pump', 7],
    ['medication_survey_added', 'Medication Survey', 7]



  ]

  constructor(private AlertsService: AlertsService, private SystemService: SystemService, private appContext: ContextService, private persistenceService: PersistenceService) {
    this.container = this.persistenceService.createContainer(
      'com.scarlet.hc',
      { type: StorageType.LOCAL, oneUse: false }
    );
  }

  ngOnInit() {
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.searchcriteria = "";
        this.page = 1;
        this.tabulardata = [];
        this.lastpage = 0;
        this.endoflist = false;
        this.onit = true;
        this.search();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscribeObj.unsubscribe();
  }
  changeTab(tb, crreatedID, createdDateTime) {


    for (var k = 0; k < this.notificationtypes.length; k++) {
      localStorage.setItem("selectedNotification", tb);
      localStorage.setItem('createdDateTime', createdDateTime);

      // let logDate = crreatedID;
      let typeoflogDate = this.typeOflogDate(crreatedID);

      if (typeoflogDate == true) {
        let logData = JSON.parse(crreatedID);;
        localStorage.setItem('createdId', logData.id);
      }
      else {
        localStorage.setItem("selectedCondition", crreatedID);
      }



      if ((this.notificationtypes[k][0] as string).includes(tb)) {
        if (this.notificationtypes[k][2] != null) {
          this.appContext.selectedTabActivity.next(this.notificationtypes[k][2] as number);
          this.appContext.changeWatcherp.next(true);
        }
      }
    }
  }

  typeOflogDate(crreatedID) {
    try {
      JSON.parse(crreatedID);
    } catch (e) {
      return false;
    }
    return true;
  }

  // convertData(d) {
  //   return d.replace(/_/g, ' ');
  // }

  setstatus(type, value) {
    if (type == 'reminder_added_event' || type == 'reminder_updated_event') {
      if (value != null) {
        return JSON.parse(value).category ? JSON.parse(value).category : '' + " Reminder"
      }
      else {
        return "New Reminder"
      }
    }
    else if (type == 'medicine_added_event') {
      return "Medicine Added"
    }
    else if (type == 'medicine_updated_event') {
      return "Medicine Updated"
    }
    else if (type == 'low_bp_event') {
      return "Low Blood Pressure"
    }
    else if (type == 'high_bp_event') {
      return "High Blood Pressure"
    }
    else if (type == 'low_blood_sugar_event') {
      return "Low Blood Sugar"
    }
    else if (type == 'high_blood_sugar_event') {
      return "High Blood Sugar"
    }
    else if (type == 'onboarding_event') {
      return "On Boarded"
    }
    else if (type == 'prescription_upload') {
      return "Prescription Added"
    }
    else if (type == 'prescription_update') {
      return "Prescription Updated"
    }
    else if (type == 'caregiver_added') {
      return "Caregiver Added"
    }
    else if (type == 'caregiver_updated') {
      return "Caregiver Updated"
    }
    else if (type == 'lab_report_event') {
      return "Lab Report Added"
    }
    else if (type == 'doctor_assignment_event') {
      return "Doctor Assigned"
    }
    else if (type == 'doctor_reassignment_event') {
      return "Doctor Reassigned"
    }
    else if (type == 'patient_transfer_event') {
      return "Transferred to you"
    } else if (type == 'trigger_event') {
      return "Trigger Values Added"
    }
    else if (type == 'questionnaire_event') {
      return "Questionnaire Logged"
    }
    else if (type == 'symptom_event') {
      return "Symptom Added"
    }
    else if (type == 'daily_goals') {
      return "Daily Goals Added"
    }
    else if (type == 'peak_flow') {
      return "Peak Flow Added"
    }
    else if (type == 'patient_note_event') {
      return "Patient Note"
    }
    else if (type == 'activity_event') {
      return "Activity Event"
    }
    else if (type == 'blood_sugar_event') {
      return "Blood Sugar Added"
    }
    else if (type == 'weight_event') {
      return "Weight Added"
    }
    else if (type == 'adverse_reaction_event') {
      return "Adverse Reaction|Remodulin"
    }
    else if (type == 'medication_added_event') {
      return "Medication Added"
    }
    else if (type == 'medication_updated_event') {
      return "Medication Updated"
    }
    else if (type == 'pump_added_event') {
      return "Pump Added"
    }
    else if (type == 'pump_updated_event') {
      return "Pump Updated"
    } 
    else if (type == 'medication_survey_added') {
      return "Medication Survey Added"
    }
    else if (type == 'hip_waist_event') {
      return "Hip Waist Event"
    }
    else if (type == 'body_temperature_event') {
      return "Body Temperature"
    }    else {
      return type
    }
  }
  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  tableSort(e, typer) {
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
    this.notiftype = typer;
  }
  typeFilter(e) {
    var pru = JSON.stringify(e).replace('["', '').replace('"]', '').replace(/\"/g, '');
    this.filtercategory = (e != '' ? pru : null);
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
  }
  getNextPage() {

    this.page++;
    this.search();
  }


  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------

  // List Activity 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.AlertsService.getAllActivity(this.searchcriteria, this.page.toString(), "20", this.status, this.filtercategory, this.selectedUserID)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = (data as any).data.notifications;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
    }

  }

  // ----------------------------

}

export interface Section {
  name: string;
  updated: Date;
}
