import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-checkmultilingual',
  templateUrl: './checkmultilingual.component.html',
  styleUrls: ['./checkmultilingual.component.scss']
})
export class CheckmultilingualComponent implements OnInit {
  public responsedata: any;
  constructor(private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private commonService: CommonserviceService, private systemService: SystemService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Check Multilingual');
  }

  ngOnInit(): void {
    this.setSEOTags()
    this.checkmultilingual()
  }

  checkmultilingual() {
    this.commonService.checkmultilingual(this.data.id)
    .subscribe(
      (res : any) => this.onDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  onDataSuccess(data){
    this.responsedata = (data as any).data;
  }

}
