import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../../shared/guards';
import { CreatequestionnairmappingComponent } from './createquestionnairmapping.component';

const routes: Routes = [
  { path: '', component: CreatequestionnairmappingComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreatequestionnairmappingRoutingModule { }
