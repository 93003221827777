<mat-dialog-content class="mat-typography pump-add">
    <h3>Select Drug</h3>

    <form [formGroup]="dataForm" validate>
        <div class="row">
            <div class="col-md-12">

                <mat-form-field appearance="outline">
                    <mat-label>Select Drug</mat-label>
                    <mat-select class="notranslate" formControlName="medication_id_fk" required #singleSelectTag>
                        <mat-option class="camelcased notranslate" *ngFor="let medication of allMedications"
                            [value]="medication.id" (click)="getROAList(medication.id)">
                            {{medication.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Route Of Administration</mat-label>
                    <mat-select placeholder="Route Of Administration" formControlName="medication_route_type" required
                        #singleSelectTag>
                        <mat-option class="camelcased" *ngFor="let ROA of ROAList" [value]="ROA"
                            (click)="getFlowUnits(ROA)">
                            {{ROA}}
                        </mat-option>
                        <!-- <mat-option value="oral">Oral</mat-option>
                        <mat-option value="inhaled">Inhalation</mat-option>
                        <mat-option value="intravenous">Intravenous</mat-option>
                        <mat-option value="subcutaneous">Subcutaneous</mat-option>
                        <mat-option value="implantable">Implantable</mat-option> -->
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col-md-12" *ngIf="showMeal">
                <mat-form-field appearance="outline">
                    <mat-label>Select Meal Category</mat-label>
                    <mat-select placeholder="Meal Category" formControlName="meal_type" #singleSelectTag>
                        <mat-option class="camelcased" value="premeal">
                            Pre Meal
                        </mat-option>
                        <mat-option class="camelcased" value="postmeal">
                            Post Meal
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col-md-12" *ngIf="showBreath">
                <mat-form-field appearance="outline">
                    <mat-label>Number of Breaths</mat-label>
                    <input type="number" matInput placeholder="No of Breaths [0 - 15]" min="0" max="15"
                        formControlName="number_of_breaths">
                </mat-form-field>

            </div>

            <div class="row" style="margin: 0;">
                <div class="col-md-7" *ngIf="showFlow">
                    <mat-form-field appearance="outline">
                        <mat-label>Flow Rate</mat-label>
                        <input type="number" matInput placeholder="Flow Rate [0.048 - 24]" min="0.048" max="24"
                            formControlName="flow_rate">

                    </mat-form-field>
                </div>
                <div class="col-md-5" *ngIf="showFlow">
                    <mat-form-field appearance="outline">
                        <mat-label>Flow rate unit</mat-label>
                        <mat-select formControlName="flow_rate_unit" placeholder="Choose unit">
                            <mat-option *ngIf="showmLh" value="mL/h">mL/h</mat-option>
                            <mat-option *ngIf="showUH" value="U/H">U/H</mat-option>
                            <mat-option *ngIf="showpLh" value="µL/h">µL/h </mat-option>
                            <mat-option *ngIf="showmL24h" value="mL/24h">mL/24h</mat-option>
                            <mat-option *ngIf="showmLday" value="mL/day">mL/day</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row" style="margin: 0;">
                <div class="col-md-7" *ngIf="showDose">
                    <mat-form-field appearance="outline">
                        <mat-label>Dose</mat-label>
                        <input type="text" min="0.1" max="400" matInput placeholder="Dose Value" formControlName="dose">
                    </mat-form-field>
                    <div class="Dose-Range" *ngIf="showOtherDose">
                        Dose Range: [0.1 - 400]
                    </div>
                    <div class="Dose-Range" *ngIf="showOralDose">
                        Dose Range: [0.5 - 2]
                    </div>
                    <div class="Dose-Range" *ngIf="showInhaleDose">
                        Dose Range: [0.1 - 70]
                    </div>
                </div>
                <!-- <div class="col-md-7">
                    Range
                </div> -->
                <div class="col-md-5" *ngIf="showDose">
                    <mat-form-field appearance="outline">
                        <mat-label>Dose unit</mat-label>
                        <mat-select class="notranslate" formControlName="dose_unit" placeholder="Choose unit">
                            <mat-option class="notranslate" *ngIf="!showmcg&&!showtablet" value="ng/kg/min">ng/kg/min
                            </mat-option>
                            <!-- <mat-option *ngIf="!showmcg&&!showtablet" value="kg">kg</mat-option>
                            <mat-option *ngIf="!showmcg&&!showtablet" value="min">min</mat-option> -->
                            <mat-option *ngIf="showmcg" value="mcg">mcg</mat-option>
                            <mat-option *ngIf="showtablet" value="tablet">tablet</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="row" style="margin: 0;">
                <div class="col-md-7" *ngIf="showDose">
                    <mat-form-field appearance="outline">
                        <mat-label>Drug Concentration</mat-label>
                        <mat-select placeholder="Drug Concentration" formControlName="drug_concentration" required
                            #singleSelectTag>
                            <mat-option class="camelcased" *ngFor="let drug of drugconcentration" [value]="drug">
                                {{drug}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-5" *ngIf="showDose">
                    <mat-form-field appearance="outline">
                        <mat-label>Drug Concentration unit</mat-label>
                        <mat-select formControlName="drug_concentration_unit" placeholder="Choose unit">
                            <mat-option *ngIf="showmgml" value="mg/mL">mg/mL</mat-option>
                            <mat-option *ngIf="showngml" value="ng/mL">ng/mL</mat-option>
                            <mat-option *ngIf="showmcgml" value="mcg/ml">mcg/ml</mat-option>
                            <mat-option *ngIf="showmg" value="mg">mg</mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Custom Days</mat-label>
                <mat-select placeholder="Route Of Administration" formControlName="custom_days" required
                    #singleSelectTag>
                    <mat-option value="15">15</mat-option>
                    <mat-option value="20">20</mat-option>
                    <mat-option value="25">25</mat-option>
                    <mat-option value="30">30</mat-option>
                    <mat-option value="35">35</mat-option>
                    <mat-option value="40">40</mat-option>
                    <mat-option value="45">45</mat-option>
                    <mat-option value="50">50</mat-option>
                    <mat-option value="55">55</mat-option>
                    <mat-option value="60">60</mat-option>

                </mat-select>
                <input type="text" matInput placeholder="Label for Remodulin" formControlName="custom_days">
            </mat-form-field> -->
            <!-- <div class="col-md-12">
                <mat-checkbox value="checked" formControlName="is_custom_interval">
                    Is Custom Interval
                </mat-checkbox>
                <br>
            </div> -->
            <!-- <div class="col-md-12">
                <mat-form-field appearance="outline" *ngIf="dataForm?.value?.is_custom_interval">
                    <mat-label>Custom Days</mat-label>
                    <input type="text" matInput placeholder="Label for Remodulin" formControlName="custom_days">
                </mat-form-field>
            </div> -->
            <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-6">

                <mat-label>Choose start date</mat-label>
                <input matInput formControlName="start_date" [matDatepicker]="picker1" [max]="morrow"
                    placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-6">
                <mat-label>Choose end date</mat-label>
                <input matInput formControlName="end_date" [min]="dataForm.get('start_date')?.value" [max]="maxDate" [matDatepicker]="picker2"
                     placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker2>
                </mat-datepicker>
            </mat-form-field>
            <!-- 
            <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-6">
                <mat-label>Choose start date</mat-label>
                <input matInput formControlName="start_date" [matDatepicker]="picker1"
                    placeholder="Choose a date" required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-6">
                <mat-label>Choose end date</mat-label>
                <input matInput formControlName="end_date" [matDatepicker]="picker2"
                    [min]="dataForm.get('start_date')?.value"  placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" class="notranslate" #picker2>
                </mat-datepicker>
            </mat-form-field> -->

            <mat-form-field appearance="outline" class="col-md-6" *ngIf="showCustomDays">
                <mat-label>Days Interval</mat-label>
                <mat-select formControlName="custom_days">
                    <mat-option value="1">
                        1
                    </mat-option>
                    <mat-option value="2">
                        2
                    </mat-option>
                    <mat-option value="3">
                        3
                    </mat-option>
                    <mat-option value="4">
                        4
                    </mat-option>
                    <mat-option value="5">
                        5
                    </mat-option>
                    <mat-option value="6">
                        6
                    </mat-option>
                    <mat-option value="7">
                        7
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6" *ngIf="showDuration && !showCustomDays">
                <mat-label>Select Duration</mat-label>
                <mat-select placeholder="Select Duration" formControlName="days" multiple #singleSelectTag>
                    <mat-option [value]="1">Monday</mat-option>
                    <mat-option [value]="2">Tuesday</mat-option>
                    <mat-option [value]="3">Wednesday</mat-option>
                    <mat-option [value]="4">Thursday</mat-option>
                    <mat-option [value]="5">Friday</mat-option>
                    <mat-option [value]="6">Saturday</mat-option>
                    <mat-option [value]="7">Sunday</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="col-md-12" *ngIf="showDuration">
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Reminder Count</mat-label>
                    <mat-select formControlName="featurecount" (selectionChange)="generateOptions($event.value)">
                        <mat-option value="1">1</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="3">3</mat-option>
                        <mat-option value="4">4 </mat-option>
                        <mat-option value="5">5 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div formArrayName="time" class="col-md-12" *ngIf="showDuration">
                <div *ngFor="let feature of dataForm.get('time')['controls']; let j=index;" [formGroupName]="j">
                    <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                        <mat-label>Enter the time</mat-label>
                        <input matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time *"
                            formControlName="t">
                        <owl-date-time #dt1 [pickerType]="'timer'"></owl-date-time>
                    </mat-form-field>
                </div>
            </div>
        </div>

    </form>


</mat-dialog-content>
<mat-dialog-actions align="start">
    <!-- [disabled]="!dataForm.valid" -->
    <button mat-flat-button color="primary" class="submit-btn" (click)="dataObject()">Add Medication</button>
</mat-dialog-actions>
