<div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <h2 class="pull-left title-modal">Change role</h2>
        <div class="pull-right">
          <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
        </div>
      </div>
      <mat-form-field appearance="outline" class="col-md-12 p-0">
        <mat-label>Select role</mat-label>
        <mat-select [(ngModel)]="selectedOption" >
            <mat-option  value="scarlet" *ngIf="userhc.user_type != 'scarlet'">Scarlet</mat-option>
            <mat-option  value="admin" *ngIf="userhc.user_type != 'admin'">Admin</mat-option>
            <mat-option  value="content" *ngIf="userhc.user_type != 'content'">Content</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" class="submit-btn" (click)="changeRole()" [disabled]="!selectedOption">Done</button>
    </div>
  </div>