import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { Brodcastlistinterface } from '../../models/brodcastlistinterface';
import { ContextService } from '../../services/context.service';
import { SocketService } from '../../services/socket/socket.service';
import { UserlistService } from '../../services/userlist/userlist.service';
import { catchError, finalize } from 'rxjs/internal/operators';
import { resourceUsage } from 'process';
import * as _ from 'lodash';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { _filter } from 'src/app/utility/globalsearch/globalsearch.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadValidators } from '@iplab/ngx-file-upload';

@Component({
  selector: 'app-executehctask',
  templateUrl: './executehctask.component.html',
  styleUrls: ['./executehctask.component.scss']
})
export class ExecutehctaskComponent implements OnInit {
  filtervehicleData: any = [];
  public searchmattag: FormControl = new FormControl();
  matTabIndex = '0';
  searchcriteria: any;
  public page: number = 1;
  isLoading: boolean = false;
  patientList = [];
  private partid: any;
  private convid: any;
  public childid: any;
  taskDetails: any;
  conditions: any;
  message: any;
  progressPatients: any = [];
  progresspatendId = [];
  patentlanguageId = [1];
  templateData: any = [];
  languageData: any = [];
  id: any;
  private uid: any;
  description: any;
  showsummaryTab: boolean = true;
  @ViewChild('stepper') stepper: MatStepper;
  public loadingError$ = new Subject<boolean>();
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public executionform = this.fb.group({
    number_list: ['', Validators.required]
  });
  templateData1: any = [
    { value: 1, name: 'English' },
    { value: 4, name: 'বাংলা' },
    { value: 2, name: 'हिन्दी', },
    { value: 3, name: 'ಕನ್ನಡ' },
    { value: 5, name: 'Español' },
  ];
  //tempformData:any=[];
  public alllanguages: any;
  public primarylanguage: any;
  thirdFormGroup: FormGroup;
  private myCathash: any = {};
  public template: FormArray;
  public newitem: boolean = false;
  public selfparentid: any;
  newTaskID: any;
  public broadcastPreviewData: Array<Brodcastlistinterface> = [];
  showTab: boolean = true;
  private selectedUsers: any = [];
  taskID: any
  displayBtn: string;
  isTemplate!: boolean;
  is_Next!: boolean;
  taskComplete!: boolean;
  patientsList: any = [];
  tabEventData: any;
  selectedData1: any = [];
  displayTextArea!: boolean;
  getToday: any;
  prevprogress:any = [];
  //  RICH MEDIA VARIABLES
  mediaType = [{
    id: 1, name: 'image', isSelected: true, isDisabled: false
  },
    // {
    //   id: 2, name: 'audio', isSelected: false, isDisabled: true
    // },
    // {
    //   id: 3, name: 'video', isDisabled: true,
    // },
    // {
    //   id: 4, name: 'carousel', isDisabled: true,
    // }
  ];
  enableRichMedia: boolean;
  choose_local_media: boolean = false;
  choose_library_media: boolean = true;
  chooseMedia: any;
  public dataForm = this.fb.group({
    image_media: ['', Validators.required],
  });
  allmedia: any;
  filename: any;
  base64File: any;
  public lastpage: number = 0;
  data: any;
  lastSearch: any;
  public onit: boolean = true;
  tabulardata: any[];
  emptyplaceholder: boolean;
  dataSource: any;
  public endoflist: boolean = false;
  richMediaType: any;
  mediaselection: boolean;
  s3url: any = environment.s3url;
  richMedaiS3url: any = environment.richMedia;
  path: any;
  selectedFile: any;
  imageData: any;
  video: any;
  audio: any;
  videomimeType: any;
  videosource: any;
  audiomimeType: any;
  audiosource: any;
  filesize: any;
  fileType: any;
  mediatype: any;
  mimeType: any;
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  textbox = '';
  radioEnable: any;
  uploadMediaType: any;
  upload_doc_type: any;
  createdby: any;
  responseUrl: any;
  resdata: any;
  final_media_path: any;
  mediaSize: any;
  title: any;
  detailedText: any;
  thumbnail_path: any;
  previewRichMediaType: any;
  grpValue: any;
  edit_mode: any = 0;
  uploadedFile: any;
  disableUploadbtn: boolean = true;
  previewRichMediaLink: string;
  @ViewChild("richmediatabGrp", { static: false }) richmediatabGrp: MatTabGroup;
  public uploadedFiles: Array<any> = [];
  private filesControl = new FormControl(
    null,
    FileUploadValidators.filesLimit(2)
  );
  public localMediaUpload = new FormGroup({
    files: this.filesControl
  });
  matRichmedia: string;
  public loader: boolean = false;
  openEditTemplate: boolean = false;
  totalPatientCountForTask: any;
  // selectedIndex: number;
  constructor(public commonService: CommonserviceService, private snackBar: MatSnackBar, private socketService: SocketService,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private appContext: ContextService,
    private _formBuilder: FormBuilder,
    private contentService: ContentserviceService,
    private userListService: UserlistService, private sanitizer: DomSanitizer) {
    this.thirdFormGroup = this._formBuilder.group({
      template: this.fb.array([

      ]),
    });
  }

  async ngOnInit(): Promise<void> {

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate())
    this.getToday = todayDate.toISOString()
    this.getProgress();
    this.appContext.multiUserSelectionUsers.subscribe(data => {
      if (data != null) {
        this.selectedUsers = data;
      }
    });
    this.newTaskID = localStorage.getItem('newTaskID')
    this.displayBtn = localStorage.getItem('hcSelectedType')
    if (this.displayBtn == 'chat' || this.displayBtn == 'custom_broadcast') {
      this.is_Next = true
    }
    if (this.displayBtn == 'whatsapp_chat') {
      this.taskComplete = true
    }
    if (this.displayBtn == 'broadcast') {
      this.displayTextArea = true
    }

    this.route.params.subscribe(params => {


      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        // this.createTask();
        this.newTaskID = localStorage.getItem('newTaskID')
      }
    });


    this.route.params.subscribe(params => {
      // this.selectedIndex = 0;
      this.getAllTaskData(params.id);
      this.childid = params.id;
    });

    this.edit_mode = 1;
    this.matRichmedia = 'image';
    this.enableRichMedia = true;
    this.matTabIndex = '1';
    this.richMediaType = 'image';
    this.searchcriteria = "";
    this.loadLibraryAssets();
    // this.appContext.multiUserSelectionUsers.subscribe(data => {
    //   if (data != null) {
    //     this.selectedUsers = data;
    //     
    //   }
    // });
    // this.getPatientList();
    await this.sleep(1100)
  }
  public form2 = new FormGroup({
    drop: new FormControl(this.mediaType[0].id,
      Validators.required,
    ),
  });
  // livesearch() {
  //   let search = this.searchmattag.value;
  //   if (search.length > 2) {
  //     this.searchcriteria = search;
  //     this.getTherapyList()
  //   }
  //   else if (search.length <= 0) {
  //     this.searchcriteria = '';
  //     this.getTherapyList()
  //   }
  // }
  // getPatientList() {
  //   let page = 1;
  //   let filters = [];
  //   let query = 'Amrutha Test User 1';
  //   this.socketService.getUserList(page, filters, query).pipe(
  //     catchError((error: any) => {
  //       this.loadingError$.next(true);
  //       return throwError(error);
  //     }),
  //     finalize(() => this.loadingSubject.next(false))
  //   ).subscribe(data => {
  //     this.patientsList = data;
  //     
  //   })

  // }
  getPatientList(name, id) {
    let d;
    let page = 1;
    let filters = [];
    let query = name;
    this.socketService.getUserList(page, filters, query).pipe(
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(data => {
      // this.patientsList = data;
      if (data.data.metadata && data.data.metadata.length > 0) {
        let filtered_array = _.filter(
          data.data.metadata, function (o) {
            return (o.name == name && o.user_id == id);
          }
        );
        let filtered_array1 = _.filter(
          this.patientsList, function (o) {
            return o.user_id == filtered_array[0].user_id;
          }
        );
        if (filtered_array1.length == 0) {
          this.patientsList.push(filtered_array[0])
        }

      }

    })

  }
  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  // To get one task details

  getAllTaskData(id) {
    this.commonService.getOneTaskData(id)
      .subscribe(
        (res: any) => this.getOneTaskSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }


  private async getOneTaskSuccess(data) {
    this.conditions = data.filter_conditions_summary
    data = data.data;
    this.thirdFormGroup.patchValue(data);
    this.taskDetails = data;

    const name2 = <FormArray>(this.thirdFormGroup.controls['template']);
    //this.tempformData=data.template;
    if (data.template.length != 0) {
      for (var i = 0; i < data.template.length; i++) {
        let check = _.findIndex(name2.value, function (o) { return o.id == data.template[i].id });
        if (check == -1) {
          name2.push(this.initTextOptions(data.template[i]));
          // this.removeSelectedLang(data.template[i].id);
        }
      }
    }
    //  await this.sleep(1000)
    localStorage.setItem("taskDetails", JSON.stringify(this.taskDetails));
    this.languageData = this.taskDetails.template;
    this.templateData = this.taskDetails.template;
    await this.getAllPatients();


    await this.getLanguages();
    // this.templateData.sort(function (a, b) {
    // }).sort(function (a, b) {
    // });
    // if (this.templateData.length == 0) {
    //   this.isTemplate = true
    // }
    // for (var i = 0; i < this.templateData.length; i++) {
    //   if (this.templateData[i].id == undefined) {
    //     this.isTemplate = true
    //   }
    //   if (this.templateData[i].id == '1') {
    //     let objIndex = this.templateData.findIndex((obj => obj.id == 1));
    //     this.templateData[objIndex].id = "English";
    //   }
    //   if (this.templateData[i].id == '2') {
    //     let objIndex1 = this.templateData.findIndex((obj => obj.id == 2));
    //     this.templateData[objIndex1].id = "हिन्दी";
    //   }
    //   if (this.templateData[i].id == '3') {
    //     let objIndex2 = this.templateData.findIndex((obj => obj.id == 3));
    //     this.templateData[objIndex2].id = 'ಕನ್ನಡ';
    //   }
    //   if (this.templateData[i].id == '4') {
    //     let objIndex3 = this.templateData.findIndex((obj => obj.id == 4));
    //     this.templateData[objIndex3].id = 'বাংলা';
    //   }
    //   if (this.templateData[i].id == '5') {
    //     let objIndex4 = this.templateData.findIndex((obj => obj.id == 5));
    //     this.templateData[objIndex4].id = 'Español';
    //   }
    // }
    // let data1 = {
    //   id: this.id,
    //   description: this.description
    // }
    // this.templateData.push(data1);

    // 


  }

  initTextOptions(data) {
    this.textbox = data.description;
    this.previewRichMediaLink = data.link;
    this.previewRichMediaType = data.type;
    this.path = '';
    return this.fb.group({
      id: [data.id],
      description: [data.description],
      name: [this.getLanguageName(data.id)]
    })
  }
  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }

  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ',' + ' ' + year;
    }
  }
  onSearchChange(data) {
    this.patientList = this.filtervehicleData;
    const searchTerm = data;
    if (!searchTerm) {
      return;
    }
    this.patientList = this.patientList.filter(templeList => {
      if (
        templeList.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        // || 
        // templeList.model.toLowerCase().indexOf(searchTerm.toLowerCase()) >-1 
      ) {
        return true;
      }
      return false;

    });

  }
  getAllPatients() {
    var postdata = {

      task_id_fk: this.childid,
      user_id_fk: localStorage.getItem('scarletuserid'),
      page: this.page.toString(),
      limit: "10",
    }

    // if (this.searchcriteria) {
    //   this.searchcriteria = this.searchcriteria
    // } else {
    //   this.searchcriteria = ''
    // }
    // this.lastSearch = this.searchcriteria;
    // this.lastpage = this.page;
    this.isLoading = true;
    // this.searchcriteria = ''

    this.commonService.TaskPost(postdata, 'getPatientList')
      .subscribe(
        (res: any) => this.onTaskListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTaskListSuccess(data) {
    data = data.data;
    this.patientList = this.patientList.concat(data);
    // this.patientList.push(data);
    this.filtervehicleData = this.patientList;
    this.isLoading = false;

    // for(let i=0;i<this.patientList.length;i++){
    //   this.getPatientList(this.patientList[i].name);

    // }
    // 



    //  if(this.taskDetails.scope=="global"){
    //     this.getData.setValue(this.patientList);
    //   }
    this.getProgress();

  }
  checknextbutton() {
    if (this.taskDetails.scope == "global") {
      return (!this.executionform.valid)
    } else {
      return this.selectedData1.length == 0;
    }
  }

  changeindex(event: number) {
    this.message = this.templateData[event].description;
  }

  someMethod(patient) {
    let selectedData: any = this.executionform.get('number_list').value;
    let fetchRecord = [];
    this.patentlanguageId = [1];
    this.selectedData1 = [];
    for (let i = 0; i < selectedData.length; i++) {

      if (!this.progresspatendId.includes(selectedData[i].id)) {

        let filtered_array = _.filter(
          this.patientsList, function (o) {
            return o.id == patient.id;
          }
        );
        if (filtered_array.length == 0) {
          this.getPatientList(patient.name, patient.id);
        }
        this.selectedData1.push(selectedData[i]);
      }
    }
    for (let i = 0; i < this.selectedData1.length; i++) {
      this.patientsList.filter(e => { return e.user_id == this.selectedData1[i].id }).map((obj) => {

        if (obj.language_data_fk) {
          this.patentlanguageId.push(obj.language_data_fk);
        }
      });
    }

  }
  checklang(id) {
    let d = this.patentlanguageId.includes(parseInt(id))
    //  
    return d;
  }

  dataObject() {

    this.uid = localStorage.getItem('scarletuserid');
    var attachment;
    var addType;
    var partner;
    var allconv;
    var datenew;
    let scope = this;
    let counter = 0;
    let conversationId = [];
    let fetchRecord = []
    let patientProgressId: any = [];
    let selectedData: any = this.executionform.get('number_list').value;
    this.selectedData1 = [];
    this.getProgress();
    for (let i = 0; i < selectedData.length; i++) {
      patientProgressId.push(selectedData[i].id) // to get all the patients who have completed the task
      if (!this.progressPatients.includes(selectedData[i].id)) {
        this.selectedData1.push(selectedData[i].id);
      }
    }
    for (let i = 0; i < this.selectedData1.length; i++) {
      fetchRecord.push(this.patientsList.filter(e => { return e.user_id == this.selectedData1[i] }));
    }
    for (let i = 0; i < this.patientsList.length; i++) {
      patientProgressId.push(this.patientsList[i].user_id);
    }
    fetchRecord.forEach((element, i) => {
      scope.partid = element[0];
      scope.convid = [];
      partner = null;
      let newmessagedata;
      datenew = new Date();
      datenew = datenew * 1;
      if (scope.partid.chatMetadata[0].conv_id) {
        let msg = this.message;
        let langid = 1;
        if (element[0].language_data_fk) {
          langid = element[0].language_data_fk
        } else {
          langid = 1;
        }
        let index = this.templateData.map(function (e) { return e.id }).indexOf((langid).toString());
        if (index != -1) {
          msg = this.templateData[index].description;
        } else {
          msg = this.templateData[0].description;
        }
        let repoMedia;
        let attachment;
        repoMedia = this.taskDetails.template;
        if (this.path !== '') {
          attachment = repoMedia.map((repoMedia) => ({
            ...repoMedia,
            link: this.path,
            type: this.mimeType
          }));
        } else if (this.base64File !== '' && this.base64File !== undefined && this.base64File !== "undefined") {
          attachment = repoMedia.map((repoMedia) => ({
            ...repoMedia,
            link: this.final_media_path,
            type: this.mimeType
          }));
        } else {
          attachment = this.taskDetails.template[0];
        }

        if (attachment) {
          newmessagedata = {
            conversationId: scope.partid.chatMetadata[0].conv_id,
            clientId: this.socketService.clientid,
            //title: this.uid + scope.partid.user_id,
            type: 'conversation',
            sender: 'H' + this.uid,
            //receiver: scope.partid.user_id,
            participants: ['H' + this.uid, 'U' + scope.partid.user_id],
            body: msg,
            trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
            mimeType: 'text/plain',
            attachment: attachment
          };
        } else {
          newmessagedata = {
            conversationId: scope.partid.chatMetadata[0].conv_id,
            clientId: this.socketService.clientid,
            //title: this.uid + scope.partid.user_id,
            type: 'conversation',
            sender: 'H' + this.uid,
            //receiver: scope.partid.user_id,
            participants: ['H' + this.uid, 'U' + scope.partid.user_id],
            body: msg,
            trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
            mimeType: 'text/plain',
          };
        }
        this.socketService.emitNewMessage(newmessagedata)
          .subscribe(data => {
            if (this.patientsList.length - 1 === i) {
              localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
              let type: any = this.taskDetails.task[0].type ?? "";
              let sub_type: any = this.taskDetails.task[0].sub_type ?? "";
              let operation: any = this.taskDetails.task[0].operation ?? "";
              let operation_type: any = this.taskDetails.task[0].operation_type ?? "";
              let value: any = this.taskDetails.task[0].value ?? "";
              let unit: any = this.taskDetails.task[0].unit ?? "";
              var progress = [{
                "type": type,
                "sub_type": sub_type,
                "operation": operation,
                "operation_type": operation_type,
                "value": value,
                "unit": unit,
                "patient_completed":  [...new Set(patientProgressId)],
                "patient_ongoing": [],
                "patient_failed": []
              }]
              var postData = {
                task_library_id: localStorage.getItem('newTaskID'),
                created_by_fk: localStorage.getItem('scarletuserid').toString(),
                hc_id: localStorage.getItem('scarletuserid'),
                progress: JSON.stringify(progress)
              }
              this.commonService.TaskProgress(postData, 'createTaskProgress')
                .subscribe((res: any) => {
                  this.systemService.showSnackBar("Broadcast sent to patients successfully.", "Ok");
                  var task_library_id = localStorage.getItem('newTaskID');
                  this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                },
                  (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
                )
            }
          });
      }
      else {
        counter = counter + 1;
        this.checksuccess(counter, this.selectedUsers.length)
      }
    });
  }
  progressAPI(type: any, sub_type: any, operation: any, operation_type: any, value: any, unit: any) {
    this.prevprogress.push({
      "type": type,
      "sub_type": sub_type,
      "operation": operation,
      "operation_type": operation_type,
      "value": value,
      "unit": unit,
      "patient_completed": [],// pass ur array value 
      "patient_ongoing": [],
      "patient_failed": []
    })

    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      created_by_fk: localStorage.getItem('scarletuserid').toString(),
      hc_id: localStorage.getItem('scarletuserid'),
      progress: JSON.stringify(this.prevprogress)

    }

    this.commonService.TaskProgress(postData, 'createTaskProgress')
      .subscribe((res: any) => {
        this.systemService.showSnackBar("Broadcast sent to patients successfully.", "Ok");

        var task_library_id = localStorage.getItem('newTaskID');
        this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  goToCompletepage() {
    var task_library_id = localStorage.getItem('newTaskID');
    this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
  }
  sendBrodcastTM(sendData) {

    let counter = 0;
    this.socketService.emitNewMessage(sendData)
      .subscribe(data => {
        counter = counter + 1;
        this.checksuccess(counter, this.selectedUsers);
      });
  }

  getNextSet() {
    this.page = this.page + 1;
    this.getAllPatients()
  }


  checksuccess(counter, userlength) {
    if (counter == userlength) {
      localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
      this.systemService.showSnackBar("Broadcast sent to patients successfully.", "Ok");
      /*Clear user list */
      this.appContext.multiUserSelectionUsers.next(null);
      this.createProgress();
      var task_library_id = localStorage.getItem('newTaskID');
      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
      /**Reset form */
      // this.tsForm.resetForm();
      //  scope.selection.clear();
      //scope.showmessage();
    }
  }
  reset() {
    this.patentlanguageId = [1];
    this.patientList = [];  
    this.getAllPatients();
  }

  // checksuccess(counter, userlength) {
  //   if (counter == userlength) {
  //     localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
  //     this.systemService.showSnackBar("Brodcast Successfully Sent.", "Ok");
  //     /*Clear user list */
  //     this.appContext.multiUserSelectionUsers.next(null);
  //     /**Reset form */
  //     this.tsForm.resetForm();
  //     //  scope.selection.clear();
  //     //scope.showmessage();
  //   }
  // }

  // Task selection
  // templateFormSelection(lang): FormGroup {
  //   return this.fb.group({
  //     "id": [lang.id],
  //     "description": [''],
  //     "name": [lang.name]
  //   })
  // }

  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name,short_code}}')
      .subscribe((res: any) => {
        this.getAllLanguageSuccess((res as any).data)

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    // this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
    this.primarylanguage.forEach(element => {
      this.myCathash[element.id] = element.name;
    });
    if (this.newitem == true) {
      this.addTab(this.alllanguages[0])
    } else {
      // this.selectedIndex=0;
      this.addTab(this.alllanguages[0]);
      this.alllanguages = this.alllanguages;

    }

  }
  changeIdTolang(id) {
    for (let i = 0; i < this.alllanguages.length; i++) {
      if (id == this.alllanguages[i].id) {
        return this.alllanguages[i].name;
      }
    }

  }
  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  addTab(lang) {
    // this.tabs.push(lang);
    this.template = this.thirdFormGroup.get('template') as FormArray;
    // this.template.push(this.templateFormSelection(lang));

    if (lang) {
      this.selected.setValue(this.alllanguages.length - 1);
    }
    this.removeSelectedLang(lang.id)
  }


  removeSelectedLang(id) {
    this.alllanguages = this.alllanguages.filter((item) => item.id !== id)
  }

  getLanguageName(category) {
    category = this.myCathash[category];
    return category;
  }

  get tempformData(): FormArray {
    return <FormArray>this.thirdFormGroup.get('template');

  }

  // closeTab(index: number, data: any) {
  //   // this.tabs.splice(index, 1);
  //   this.template.removeAt(index);
  //   this.alllanguages.push(data.value);

  // }


  getStepper() {
    if (this.stepper.selectedIndex == 0) {
      this.showTab = true;
      this.showsummaryTab = true;
    } else if (this.stepper.selectedIndex == 2) {
      this.showsummaryTab = false;
    }
    else {
      this.showTab = false;
      this.showsummaryTab = true;
    }
  }

  changeindex1(tabChangeEvent: MatTabChangeEvent): void {
    this.tabEventData = tabChangeEvent;
  }


  NextBtn() {
    var post3data = this.thirdFormGroup.value;
    post3data.template.filter(function (item) {
      delete item.name;
    });
    //If image is selected from Repository overide link and type in this.taskDetails.template
    let repoMedia;
    let newMedia;
    let msg = this.textbox;
    repoMedia = this.taskDetails.template;
    if (this.path !== '') {
      newMedia = repoMedia.map((repoMedia) => ({
        ...repoMedia,
        description: msg,
        link: this.path,
        type: this.mimeType
      }));
      this.commonService.change___languageData(newMedia);
      this.commonService.change___languageTemplate1(newMedia);
    } else if (this.base64File) {
      newMedia = repoMedia.map((repoMedia) => ({
        ...repoMedia,
        description: msg,
        link: this.final_media_path,
        type: this.mimeType
      }));
      this.commonService.change___languageData(newMedia);
      this.commonService.change___languageTemplate1(newMedia);
    } else {
      newMedia = repoMedia.map((repoMedia) => ({
        ...repoMedia,
        description: msg,
      }));
      this.commonService.change___languageData(newMedia);
      this.commonService.change___languageTemplate1(newMedia);
    }
    let patentname = [];
    let selected = this.executionform.get('number_list').value;
    // for (let i = 0; i < selected.length; i++) {
    //   patentname.push(selected[i].name);
    // }
    let data = {
      "selectedpatent": selected,
      "selectedpatentflag": true
    }
    localStorage.setItem("selectedpatentdata", JSON.stringify(data));
    this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
  }

  taskCompleteButton() {

    this.uid = localStorage.getItem('scarletuserid');
    let selectedData: any = this.executionform.get('number_list').value;
    this.selectedData1 = [];

    for (let i = 0; i < selectedData.length; i++) {
      if (!this.progressPatients.includes(selectedData[i].id)) {
        this.selectedData1.push(selectedData[i].id);
      }
    }
    let taskProgress: any = []
    if (this.progressPatients.length > 0) {
      taskProgress = this.selectedData1.concat(this.progressPatients);
    }
    else {
      taskProgress = this.selectedData1;
    }
    let type: any = this.taskDetails.task[0].type ?? "";
    let sub_type: any = this.taskDetails.task[0].sub_type ?? "";
    let operation: any = this.taskDetails.task[0].operation ?? "";
    let operation_type: any = this.taskDetails.task[0].operation_type ?? "";
    let value: any = this.taskDetails.task[0].value ?? "";
    let unit: any = this.taskDetails.task[0].unit ?? "";
    var progress = [{
      "type": type,
      "sub_type": sub_type,
      "operation": operation,
      "operation_type": operation_type,
      "value": value,
      "unit": unit,
      "patient_completed": taskProgress,
      "patient_ongoing": [],
      "patient_failed": []
    }]
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      created_by_fk: localStorage.getItem('scarletuserid').toString(),
      hc_id: localStorage.getItem('scarletuserid'),
      progress: JSON.stringify(progress)
    }
    this.commonService.TaskProgress(postData, 'createTaskProgress')
      .subscribe((res: any) => {
        var task_library_id = localStorage.getItem('newTaskID');
        this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )

  }

  getProgress() {
    this.prevprogress = [];
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      hc_id: localStorage.getItem('scarletuserid'),
    }
    this.commonService.getProgress(postData, 'getTaskProgress')
      .subscribe(
        (res: any) => {
          this.totalPatientCountForTask = res.data[0].patient_list.length
          if (res.data[0].progress.length > 0) {
            this.progressPatients = res.data[0].progress[0].patient_completed;
            localStorage.setItem("progressPatients", JSON.stringify(this.progressPatients));
          }
          for (let i = 0; i < res.data[0].progress.length; i++) {
            this.prevprogress.push(res.data[0].progress[i]);
            let check = _.findIndex(this.patientList, function (o) { return o.id == res.data[0].progress[i].patient_completed[0] });
            if (check != -1 && !this.progresspatendId.includes(res.data[0].progress[i].patient_completed[0])) {
              this.progresspatendId.push(res.data[0].progress[i].patient_completed[0]);
            }
          }
        
          // this.getAllPatients();         
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  checkprogress(id) {
    let data = this.progressPatients.includes(id);
    return data;

  }
  checkchecked(id) {
    let data = this.progressPatients.includes(id);
    return data;
    // if(this.taskDetails.scope=="global"){
    //   return true
    // }else{
    //   let data=this.progresspatendId.includes(patient.id);
    //   return data;
    // } 
  }

  get getData(): FormArray {
    return this.executionform.get('number_list') as FormArray;
  }

  createProgress() {
    var patientData = [];
    let selectedData: any = this.executionform.get('number_list').value;
    let patientId = selectedData[0];
    let fetchRecord = []
    for (let i = 0; i < selectedData.length; i++) {
      fetchRecord.push(this.patientsList.filter(e => { return e.user_id == selectedData[i].id }));
    }
    for (let i = 0; i < fetchRecord.length; i++) {
      patientData.push(fetchRecord[i][0].user_id);
    }
    // patientData.push(patientId);
    let type: any = this.taskDetails.task[0].type ?? "";
    let sub_type: any = this.taskDetails.task[0].sub_type ?? "";
    let operation: any = this.taskDetails.task[0].operation ?? "";
    let operation_type: any = this.taskDetails.task[0].operation_type ?? "";
    let value: any = this.taskDetails.task[0].value ?? "";
    let unit: any = this.taskDetails.task[0].unit ?? "";
    var progress = [{
      "type": type,
      "sub_type": sub_type,
      "operation": operation,
      "operation_type": operation_type,
      "value": value,
      "unit": unit,
      "patient_completed": patientData,
      "patient_ongoing": [],
      "patient_failed": []
    }]
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      created_by_fk: localStorage.getItem('scarletuserid').toString(),
      hc_id: localStorage.getItem('scarletuserid'),
      progress: JSON.stringify(progress)
    }
    this.commonService.TaskProgress(postData, 'createTaskProgress')
      .subscribe((res: any) => {
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  // RICH MEDIA CODE
  richMediaCheckbox(checked) {
    if (checked === true) {
      this.enableRichMedia = true;
      this.choose_library_media = true;
      this.choose_local_media = false;
      this.path = '';
      this.base64File = '';
      // To get all the Assets from Library 
      this.searchcriteria = "";
      this.page = 1;
    } else {
      this.enableRichMedia = false;
      this.path = '';
      this.base64File = '';
      this.choose_library_media = false;
      this.choose_local_media = true;
      this.richMediaType = '';

    }
  }
  chooseRadiobutton(event) {
    this.radioEnable = event.value;
    if (this.radioEnable === '0') {
      this.choose_library_media = true;
      this.choose_local_media = false;
      this.path = '';
      this.base64File = '';
      // To get all the Assets from Library 
      this.searchcriteria = "";
      this.page = 1;
      this.loadLibraryAssets();
    } else {
      this.path = '';
      this.base64File = '';
      this.choose_library_media = false;
      this.choose_local_media = true;
    }
  }
  onFileSelect(event): void {
    // var input = event.target;
    var input = this.localMediaUpload.value;
    var scope = this;
    if (input.files[0].size > 25000000) {
      this.imageData = null;
      this.video = null;
      this.audio = null;
      this.snackBar.open("File size exceed 25mb", "OK", {
        duration: 2000,
      });
    }
    else {
      if (input.files && input.files[0] && (input.files[0].type == 'image/jpeg' || input.files[0].type == 'image/png')) {
        try {
          this.choose_library_media = false;
          // const file = event.target.files[0];
          const file = input.files[0];
          const fReader = new FileReader()
          fReader.readAsDataURL(file)
          fReader.onloadend = (_event: any) => {
            let tempImg = new Image();
            scope.filename = file.name;
            scope.filesize = input.files[0].size
            scope.fileType = input.files[0].type;
            scope.previewRichMediaLink = '';
            scope.base64File = _event.target.result;
            tempImg.src = fReader.result as string;
            tempImg.onload = function () {
              var MAX_WIDTH = 400;
              var MAX_HEIGHT = 300;
              var tempW = tempImg.width;
              var tempH = tempImg.height;
              if (tempW > tempH) {
                if (tempW > MAX_WIDTH) {
                  tempH *= MAX_WIDTH / tempW;
                  tempW = MAX_WIDTH;
                }
              } else {
                if (tempH > MAX_HEIGHT) {
                  tempW *= MAX_HEIGHT / tempH;
                  tempH = MAX_HEIGHT;
                }
              }
              var canvas = document.createElement('canvas');
              canvas.width = tempW;
              canvas.height = tempH;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(tempImg, 0, 0, tempW, tempH);
              var thtype = "image/jpeg";
              var dataURL = canvas.toDataURL("image/jpeg");
              var myFile: Blob = scope.dataURItoBlob(dataURL);
              scope.selectedFile = myFile;
              scope.disableUploadbtn = true;
              scope.processfiles(scope.fileType, scope.filesize, scope.filename, scope.selectedFile)
            }

          }
        } catch (error) {
          this.filename = null;
          this.base64File = null;

        }
      } else if (input.files && input.files[0] && (input.files[0].type == 'video/mp4' || input.files[0].type == 'video/webm' || input.files[0].type == 'video/quicktime')) {

        var path = URL.createObjectURL(input.files[0]);
        this.video = {
          sources: [{
            src: path,
            type: input.files[0].type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.videomimeType = input.files[0].type;
        this.videosource = path;
        this.videosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.videosource);
      } else if (input.files && input.files[0] && (input.files[0].type == 'audio/mp3' || input.files[0].type == 'audio/aac' || input.files[0].type == 'audio/wav' || input.files[0].type == 'audio/flac' || input.files[0].type == 'audio/mpeg')) {
        var audiopath = URL.createObjectURL(input.files[0]);
        this.audio = {
          sources: [{
            src: path,
            type: input.files[0].type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.audiomimeType = input.files[0].type;
        this.audiosource = audiopath;

        this.audiosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.audiosource);
        // this.showprev = true;
        // } else if (input.files && input.files[0] && input.files[0].type == 'application/pdf') {
        //     this.isPDF = true;
        //     this.showprev = true;
        // } 
      } else {
        // this.showprev = false;
        this.snackBar.open("Unsuported file format", "OK", {
          duration: 2000,
        });
      }
    }

  }
  loadLibraryAssets() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.contentService.getAllRichMedia(this.searchcriteria, this.page.toString(), "20", "all", this.richMediaType, "", "")
        .subscribe(
          (res: any) => this.onDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }
  onDataSuccess(data) {
    var mediatype;
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = this.tabulardata;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      if (mediatype == true) {
        data = data.filter(dt => dt.type == this.data.type)
      }
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = this.tabulardata;
    }
  }
  mediaselect(grp) {
    this.mediaselection = true;
    this.previewRichMediaLink = '';
    this.previewRichMediaType = '';
    this.base64File = '';
    this.grpValue = grp.value;
    this.richmediatabGrp.selectedIndex = 1;

    if (grp && grp.value) {
      this.path = this.tabulardata.filter(d => d.id == grp.value)[0].path;
      this.mediatype = this.tabulardata.filter(d => d.id == grp.value)[0].type;
      this.mimeType = this.tabulardata.filter(d => d.id == grp.value)[0].mime;
      this.mediaSize = this.tabulardata.filter(d => d.id == grp.value)[0].size;
      this.title = this.tabulardata.filter(d => d.id == grp.value)[0].title;
      this.detailedText = this.tabulardata.filter(d => d.id == grp.value)[0].detailed_text;
      this.thumbnail_path = this.tabulardata.filter(d => d.id == grp.value)[0].thumbnail_path;
      this.createdby = this.tabulardata.filter(d => d.id == grp.value)[0].created_by_data_fk;

    }
    else {
      this.systemService.showSnackBar('Please select media', 'OK');
    }
  }
  richMedia_livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.loadLibraryAssets();
    }
  }
  getNextSetOFmedia() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.loadLibraryAssets();
    }
  }
  changeRichMediaType(data) {
    this.richMediaType = data;
    this.searchcriteria = "";
    this.lastSearch = "";
    this.lastpage = 0;
    this.page = 1;
    this.onit = true;
    this.loadLibraryAssets();
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const { textbox } = this;
    const text = `${textbox}${event.emoji.native}`;
    this.textbox = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
  attachRichMedia() {
    if (this.radioEnable === 0 || this.radioEnable === undefined) { // media is selected from Library 
      if (this.mediatype.includes('pdf')) {
        this.uploadMediaType = 'richmedia_pdf';
        this.upload_doc_type = 'document';
      }
      if (this.mediatype.includes('image')) {
        this.uploadMediaType = 'richmedia';
        this.upload_doc_type = 'gallary';
      }
      // media is uploaded by Repository
      this.createRichMedia(this.path, this.mediaSize, this.title, this.detailedText, this.mediatype, this.thumbnail_path, this.createdby);
    } else { // media is uploaded by local system
      this.processfiles(this.fileType, this.filesize, this.filename, this.selectedFile);
    }
  }
  processfiles(filetype, fileSize, fileName, file) {
    let path = 'images'
    this.createdby = localStorage.getItem('scarletuserid');
    if (filetype.includes('pdf')) {
      this.uploadMediaType = 'richmedia_pdf';
      this.upload_doc_type = 'document';
    }
    if (filetype.includes('image')) {
      this.uploadMediaType = 'richmedia';
      this.upload_doc_type = 'gallary';
    }
    this.contentService.getRichMediaS3URL(path, filetype, fileSize, fileName)
      .subscribe(
        (data) => {
          this.responseUrl = data;
          this.commonService.uploadToS3(file, this.responseUrl.data.signed_request).subscribe(
            (data) => {
              this.thumbnail_path = this.responseUrl.data.path;
              this.createRichMedia(this.responseUrl.data.path, fileSize.toString(), fileName, fileName, filetype, this.thumbnail_path, this.createdby)
            }, (error) => {
              // scope.isloadingpres = false;
              this.systemService.showSnackBar("Unable to upload file.", "Ok");
            })
        }, (error) => {
          // scope.isloadingpres = false;
          this.systemService.showSnackBar("Unable to upload file.", "Ok");
        });
  }
  createRichMedia(path, fileSize, fileName, fileDesc, filetype, thumbnail_path, createdby) {
    this.commonService.CreateRichMedia(path, fileSize.toString(), fileName, fileDesc, filetype, thumbnail_path, createdby)
      .subscribe(
        (data) => {
          this.resdata = data;
          this.final_media_path = this.resdata.data.path;
          this.mimeType = this.resdata.data.mime;
          this.disableUploadbtn = false;
          this.richmediatabGrp.selectedIndex = 1;
          this.systemService.showSnackBar("Media Uploaded Successfully", "Ok");
        })
  }
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }
  public clear(): void {
    this.uploadedFiles = [];
  }
  ChooseMediaSelectionType(type) {
    let selectionType = type;
    if (selectionType === 'repo') {
      this.choose_library_media = true;
      this.choose_local_media = false;
      this.path = '';
      this.base64File = '';
      // To get all the Assets from Library 
      this.searchcriteria = "";
      this.page = 1;
      this.loadLibraryAssets();
    } else {
      this.path = '';
      this.base64File = '';
      this.choose_library_media = false;
      this.choose_local_media = true;
    }
  }
  editTemplate() {
    this.openEditTemplate = true;
  }
}