import { T } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as Chartist from "chartist";
import 'chartist-plugin-tooltips/dist/chartist-plugin-tooltip.min.js';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { SHORT_MONTHS } from '../../../../shared/data';
import { Userdetailsinterface } from '../../../models/userdetailsinterface';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { DiaryLogDialogComponent } from '../../diarylog/diarylog/diarylogdialog.component';
import { MealbreakupComponent } from '../../mealbreakup/mealbreakup/mealbreakup.component';
import { cloneDeep } from "lodash"; 
import { SocketService } from 'src/app/healthcoach/services/socket/socket.service';

declare var google: any;

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
  animations: [detailExpandTable]
})

export class DiaryComponent implements OnInit {
  public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public userHasNix: boolean = false;
  public selectedActivity: string = "Activity";
  public selectedRange: string = "daily";
  public subscribeObj: any;
  selectedDiaryOption: any;
  MeasurementOptions: any = [];
  private tabID: number = 5;
  public morrow: any = new Date();
  public todaydate: any = new Date();
  public emailChartData: any;
  public hoursChartData: any;
  public hoursChartOptions: any;
  public hoursChartResponsive: any[];
  public activityChartData: any;
  public activityChartOptions: any;
  public activityChartResponsive: any[];
  public allusersrawdata: any;
  public rawdata: any;
  public tabulardata: any = [];
  public symptomnames = {
    "tirednessConfusion": "Tiredness / Confusion",
    "difficultyInBreathing": "Difficulty in Breathing",
    "poundingInChest": "Pounding in Chest, Neck or Ears",
    "sleepProblems": "Sleep problems and daytime exhaustion",
    "painTightnessPressure": "Pain, tightness and pressure in the neck, jaws, shoulder and back",
    "heartPalpitation": "Heart palpitation",
    "swellingInLegs": "Swelling in legs, ankle, feet, stomach and veins of the neck",
    "feelingOfFaintness": "Feeling of faintness",
    "bloating": "Bloating",
    "constipation": "Constipation",
    "diarrhoea": "Diarrhoea",
    "feelingOfNervousness": "Feeling of nervousness or anxiety",
    "nightmares": "Nightmares",
    "dryCough": "Dry Cough",
    "chestPain": "Chest Pain",
    "frequentUrination": "Frequent Urination",
    "dizziness": "Dizziness",
    "headache": "Headache"
  }
  user: any;
  sub: any;
  userid: any;
  AverageActivity: any;
  HighestActivity: any;
  weightpercentage: any;
  newar = [];
  defaultleftoption = "";
  displayedColumns = [];
  dataSource: any;
  onlinestatus: any;
  allchats = [];
  timestamp: any;
  chattext: any;
  recipientStatus: any;
  chatrectype: any;
  responseUrl: any;
  file: any;
  imageData: any;
  isPDF = false;
  video: any;
  videomimeType: any;
  videosource: any;
  showprev = false;
  selectedFile: any;
  postuploads3response: any;
  uploaderror = false;
  uid: any;
  showTyper = false;
  layer: any;
  useractivename: any;
  useractivenameshort: any;
  showChatLoader = false;
  rawprogressdata: any;
  alluserset: any;
  useractiveid: any;
  respondUserIds: any;
  filterdrespondinuser = [];
  userDetails = 'uprofile';
  uprogressweek = 1;
  usercurrentweek: any;
  nomealsdata = false;
  nobloodsugardata = false;
  nobloodpressuredata = false;
  bloodPressurePercentage: any;
  systolicLastRecorded: any;
  diastolicLastRecorded: any;
  nowaterdata = false;
  bpMessage: any;
  pressureData: any;
  waterData: any;
  HideFirstTime = true;
  ToggleHamburger: any;
  isSaved: boolean;
  isSavedself: boolean;
  presRawData: any;
  prescriptionurl = [];
  coachdata: any;
  coachname: any;
  countsec: any;
  term: any;
  labreport: any;
  isGallery = false;
  progid = false;
  AllAverage: any;
  addedattachment: any;
  prescriptionurlraw: any;
  tabulardatasource: any = [];
  usermigrate = false;
  recStatusval: any;
  chatroomid: any;
  lastID = null;
  isvisible: boolean = false;
  requestedbloodgraph = 'premeal';
  showtarget: boolean = false;
  activitystatus: any;
  mealnutritionaldata: any;
  documentPlaceHolder = '/assets/img/pdficon.jpg';
  socket: any;
  convoid: any;
  initialdeliverystatus: any;
  initialseenstatus: any;
  convid: any;
  allconvesations: any;
  readtimestamp: any;
  checkconversationfetched: boolean = false;
  getthenewmessage: any;
  connectionchecker: any = true;
  connectivitysub: any;
  mainoption: any = 'all';
  mealsubmenu: any = 'breakfast';
  diffDays: any;
  diarylogopen: any;
  public selectedUserID: string;
  eligibleLogData: any;
  public subtype = null;
  public mealsubmenuclicked: boolean = false;
  public bloodsugarlogtype = '';
  filterargs = { type: 'dairy' };
  selectedOptionData: any;
  masterConditionid: any;
  graph_data: any;
  selectedPatientID: any;
  selectedTitle: any
  public dataForm = this.fb.group({
    startdate: [''],
    enddate: [''],
    selectedOption: ['', Validators.required]
  });
  MeasurementType: any;
  latest: any;
  targetPatientData: any = [];
  createdIdnotificationId: any;
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  userData: any = {};
  activityUnits: string;
  activityAverage = "";
  waterAverage = "";
  mealAverage = "";
  latestWeight: any;
  showNodatafound: boolean = false;	
  showNoGraphdatafound: boolean = true;
  endoflist: boolean = true;
  page: any = 0;
  disbaleDeleteOption: boolean = true;
  public showLoader: boolean = false;


  constructor(private systemService: SystemService, public fb: FormBuilder, private appContext: ContextService, private userDetailService: UserdetailsService, public dialog: MatDialog,private socketService:SocketService) {
  }

  ngOnInit() {
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {

        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.createdIdnotificationId = false;
        this.dataForm.reset();
        this.displayedColumns = [];
        this.dataSource = '';
        this.defaultleftoption = "";
        this.mainoption = "";
        this.getUserEligibleLogs(this.selectedUserID);
        this.getPatientTargets(this.selectedUserID);
        this.initComponentDraw1(this.selectedUserID);

        this.activityAverage = "";
      }

      let notificationStoredValue = localStorage.getItem("selectedNotification");

      if (notificationStoredValue == 'low_bp_event' || notificationStoredValue == 'high_bp_event') {
        let val = 'Blood Pressure';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'low_blood_sugar_event' || notificationStoredValue == 'high_blood_sugar_event' || notificationStoredValue == 'blood_sugar_event') {
        let val = 'Blood Sugar';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'peak_flow') {
        let val = 'Peak Flow';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        var startd = "";
        var endd = "";
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'activity_event') {
        let val = 'Activity';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'weight_event') {
        let val = 'Weight';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'water_alert_event') {
        let val = 'Water';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'meal_event') {
        let val = 'Meal';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'infusion_site_event') {
        let val = 'Infusion Site';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.applymainfilter();
      }

    });
    this.dataForm.reset();

  }


  dateClass = (d: any) => {
    const date = d.getDate();
    const day = d.getDay();

    // Highlight the 1st and 20th day of each month.
    return (day == 1 || date == 16) ? 'notranslate' : undefined;
  }


  ngOnDestroy(): void {
    this.subscribeObj.unsubscribe();
  }

  getprogdetails(selectedUserID: string) {
    this.loadingSubject$.next(true);
    /**Reinitiliase program detail subject */
    /**API Call */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      this.masterConditionid = data
      // // this.getUserEligibleLogs( this.masterConditionid);
      // this.getUserEligibleLogs(this.masterConditionid.therapy_id);
    });
  }


   initComponentDraw1(selectedUserID: string) {
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;

      this.userData = data;

      let profileFlags = this.userData.profile_flags.split(',');

      if (profileFlags.indexOf("SST") > -1){
        this.activityUnits = "steps";
      }else {
        this.activityUnits = "mins";
      }
      this.applymainfilter();
    })
  }

  openLabDialog() {
    var scope = this;
    this.diarylogopen = this.dialog.open(DiaryLogDialogComponent, {
      width: '700px',
      height: 'auto',
      data: {
        dataKey: scope.selectedUserID,
        hasnix: this.userHasNix,
        selectedOption: this.selectedActivity
      }
    });
    this.diarylogopen.afterClosed().subscribe(() => {
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  editInfusionDialog(infData) {
    let showSiteReason;
    if(this.userData.profile_flags.includes("CIR")){
      showSiteReason = true
    }else{
      showSiteReason = false
    }
    var scope = this;
    this.diarylogopen = this.dialog.open(DiaryLogDialogComponent, {
      width: '700px',
      height: 'auto',
      data: {
        dataKey: scope.selectedUserID,
        hasnix: this.userHasNix,
        selectedOption: infData.infusion_site,
        showSiteReason: showSiteReason,
        infusionChange: true,
        lastLogData: infData.log_date,
        reminder_days:infData.reminder_days
      }
    });
    this.diarylogopen.afterClosed().subscribe(() => {
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
      this.applymainfilter();
    });
  }

  convertIsoDate(d) {
    let date = new Date(d);
    let str;
    let options: any = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    str = (date).toString();
    if(str !== 'Invalid Date'){
      return date.toLocaleDateString("en-US", options);
    }else{
      return d;
    }
  }

  showMeallogdate(data){
    for(let i = 0 ; i<data.meal_data.length ; i++){
      this.convertIsoDate(data.meal_data[i].log_date) ;
    }
  }

  changeRange(e) {
    this.selectedRange = e;
    this.dataForm.get('startdate').reset();
    this.dataForm.get('enddate').reset();
  }

  changeEndDate() {
    if (this.mainoption != 'Peak Flow') {
      var newDate = new Date(this.dataForm.get('startdate').value);

      if (this.selectedRange == 'daily') {
        newDate.setDate(newDate.getDate() + 7);
        this.morrow = newDate;
      }
      else if (this.selectedRange == 'weekly') {
        newDate.setDate(newDate.getDate() + 28);
        this.morrow = newDate;
      }
      else {
        this.morrow = new Date();
      }
    }
    else {
      this.morrow = new Date();
    }
  }

  getTableData(UserID, selectedOption, startd, endd, page?) {
    this.userDetailService.getPatientDiary(UserID, selectedOption, startd, endd, page).pipe(
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false) })
    ).subscribe((data: any) => {
      data = (data as any).data;
      if (data.length > 0) {
        this.endoflist = false;
        this.showNodatafound = false;
        for (let i = 0; i < data.length; i++) {
          this.tabulardatasource.push(data[i]);
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].unit === 'kg') {
            this.latestWeight = Math.round(data[0].quantity) + " " + data[i].unit
          } else if (data[i].unit === 'lbs') {
            this.latestWeight = Math.round(data[0].quantity * 0.453592) + " " + 'kg'
          }
          if (data[i]?.meal_data) {
            this.disbaleDeleteOption = false;
            for (let j = 0; j < data[i].meal_data.length; j++) {
              if (data[i].meal_data[j]?.is_deleted === false) {
                this.showNoGraphdatafound = false;
                break;
              }
            }
          } else {
            this.disbaleDeleteOption = true;
            if (data[i]?.is_deleted === false) {
              this.showNoGraphdatafound = false;
            }
          }
        }
        this.dataSource = new MatTableDataSource<Element>(this.tabulardatasource);
        this.enableEdit(this.tabulardatasource);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      } else if (this.page === 1 || this.page === '1') {
        this.showNodatafound = true;
        this.showNoGraphdatafound = true;
        this.endoflist = true;
      }
    });
  }

  enableEdit(array) {
    const filteredPerson = array.filter(person => person.is_deleted === false);
    this.latest = filteredPerson.reduce(function (r, a) {
      return (r.log_date > a.log_date) && (r.is_deleted && a.is_deleted == false) ? a : r;
    });
    this.latest = this.latest.id;
  }

  getPatientTargets(selectedPatientID) {

    this.userDetailService.getPatientTargetData(selectedPatientID)
      .subscribe((res: any) => {

        if (res.data[0].type == 'meal') {
          this.targetPatientData = res.data[0];
        } else if (res.data[1].type == 'meal') {
          this.targetPatientData = res.data[1];
        }

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  convertDecimal(num) {
    return Math.floor(num).toFixed(2);
  }

  deletedData(isdeleted) {
    setTimeout(() => {
      this.deletedLog(isdeleted)
    }, 2000);

  }
  deletedLog(isdeleted) {
    const arr = cloneDeep(this.tabulardatasource);
    let finalArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.meal_data) {
        for (let j = 0; j < arr[i].meal_data.length; j++) {
          if (isdeleted) {
            this.tabulardatasource =  arr[i].meal_data.filter(function (mealdata) {
                if(mealdata.is_deleted === isdeleted){
                  let obj = arr[i];
                  arr[i].meal_data = arr[i].meal_data
                  
                  return 
                }
                
            })
          } else if (arr[i].meal_data[j].is_deleted === isdeleted) {
            if (arr[i].meal_data.length > 1) {
              finalArr.push(arr[i]);
            } else {
              finalArr.push({
                "log_date": arr[i].log_date,
                "meal_type": arr[i].meal_type,
                "meal_data": [arr[i].meal_data[j]]
              });
            }
          }
        }
        console.log('finalArr' + JSON.stringify(finalArr));
        this.dataSource = new MatTableDataSource<Element>(this.tabulardatasource);
      } else {
        this.tabulardatasource = this.tabulardatasource;
        this.tabulardatasource = this.tabulardatasource.filter(a => a.is_deleted == isdeleted);
        this.dataSource = new MatTableDataSource<Element>(this.tabulardatasource);
      }
    }
 
  }

  selectVal(e) {
    this.latestWeight = '';
    this.mainoption = e;
    this.defaultleftoption= '';
    this.dataSource = [];
  }

  mealSubMenus(e) {
    this.mealsubmenuclicked = true;
    if (e == 'breakfast') {
      this.bloodsugarlogtype = 'breakfast';
      this.subtype = 'breakfast';
    }
    else if (e == 'lunch') {
      this.bloodsugarlogtype = 'lunch';
      this.subtype = 'lunch';
    }
    else if (e == 'snacks') {
      this.bloodsugarlogtype = 'snacks';
      this.subtype = 'snacks';
    }
    else if (e == 'dinner') {
      this.bloodsugarlogtype = 'dinner';
      this.subtype = 'dinner';
    }

  }

  bloodSugarSubMenu(e) {
    if (e == 'fasting blood sugar') {
      this.bloodsugarlogtype = '';
      this.subtype = 'fasting';
    }
    else if (e == 'postmeal breakfast') {
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'breakfast';
    }
    else if (e == 'postmeal lunch') {
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'lunch';
    }
    else if (e == 'postmeal dinner') {
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'dinner';
    }
    else if(e == 'post snacks'){
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'snacks';
    }
    else if (e == 'premeal breakfast') {
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'breakfast';
    }
    else if (e == 'premeal lunch') {
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'lunch';
    }
    else if (e == 'premeal dinner') {
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'dinner';
    }
    else if (e == 'pre snacks'){
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'snacks';
    }
    else if (e == 'random') {
      this.bloodsugarlogtype = '';
      this.subtype = 'random';
    }
  }

  ConvertData(d) {
    return d.replace(/_/g, ' ');
  }

  selectMeasurementVal(event) {
    this.MeasurementType = event;
  }

  async applymainfilter() {
    // let infuusionData = localStorage.getItem("log_data");   
    var postdata = this.dataForm.value;
    var startd = null;
    var endd = null;
    this.page = "1";
    this.defaultleftoption = this.mainoption;
    this.tabulardatasource = [];
    if (postdata.startdate && postdata.enddate) {
      startd = postdata.startdate;
      endd = postdata.enddate;
      startd.setHours(0, 0, 0, 0);
      endd.setHours(23, 59, 59, 99);
    } else {
      startd = ""
      endd = ""
    }

    if(this.mainoption === "Activity" || this.mainoption === "Weight" || this.mainoption === "Water" || this.mainoption === "Peak Flow" || this.mainoption === "Meal" || this.mainoption === "Blood Pressure" || this.mainoption === "Blood Sugar"){
      // If policy start date is available then use for MCHI user else use therapy start date to view diary logs
      this.showLoader = true;
      if(this.userData?.policy_data?.customer?.[0]?.policy_start_date) {
        startd = new Date(this.userData?.policy_data?.customer?.[0]?.policy_start_date);
      } else {
        startd = new Date(this.userData.therapy_start_date);
      }
      startd.setHours(0, 0, 0, 0);
      endd = new Date();
      endd.setHours(23, 59, 59, 99);
    }

    if (endd >= startd) {
      
      startd = moment(startd).add(30, 'minutes').add(5, 'hours').toISOString();
      endd = moment(endd).add(30, 'minutes').add(5, 'hours').toISOString();

      this.loadingSubject$.next(true);

      if (postdata.selectedOption == 'Activity') {
        this.displayedColumns = ['log_date', 'activity_type', 'activity_value'];
      }
      else if (postdata.selectedOption == 'Blood Sugar') {
        this.displayedColumns = ['log_date', 'blood_sugar_log_type', 'blood_sugar_val', 'unit'];
      }
      else if (postdata.selectedOption == 'Blood Pressure') {
        this.displayedColumns = ['log_date', 'diastolic', 'heart_rate', 'tag'];
      }
      else if (postdata.selectedOption == 'Water') {
        this.displayedColumns = ['log_date', 'quantity', 'unit'];
      }
      else if (postdata.selectedOption == 'Meal') {
        this.displayedColumns = ['log_date', 'meal_type', 'food_item', 'calories_value'];
      }
      else if (postdata.selectedOption == 'Weight') {
        this.displayedColumns = ['log_date', 'weight', 'tag'];
      }
      else if (postdata.selectedOption == 'Peak Flow') {
        this.displayedColumns = ['log_date', 'measure', 'log_type'];
      }
      else if (postdata.selectedOption == 'Infusion Site') {
        this.displayedColumns = ['log_date', 'infusion_site', 'site_change_reason', 'showEdit'];
      }
      else if (postdata.selectedOption == 'Body Parameters: Temperature') {
        this.displayedColumns = ['log_date', 'unit', 'Quantity'];
      }
      else if (postdata.selectedOption == 'Body Parameters: Hip waist ratio') {
        this.displayedColumns = ['log_date', 'hip', 'hip_unit', 'waist', 'waist_unit', 'ratio'];
      }
      /**Reinitilise user detail component */
      this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
      if (postdata.selectedOption == 'Blood Sugar') {
        this.selectedOptionData = 'bloodSugar';
        this.graph_data = 'blood_sugar';
      }
      else if (postdata.selectedOption == 'Activity') {
        this.subtype = null;
        this.selectedOptionData = 'activity';
        this.graph_data = 'activity';
      }
      else if (postdata.selectedOption == 'Weight') {
        this.subtype = null;
        this.selectedOptionData = 'weight';
        this.graph_data = 'weight';
      }
      else if (postdata.selectedOption == 'Meal') {
        this.subtype = null;
        this.selectedOptionData = 'meal';
        this.graph_data = 'meal';
      }
      else if (postdata.selectedOption == 'Water') {
        this.subtype = null;
        this.selectedOptionData = 'water';
        this.graph_data = 'water';
      }
      else if (postdata.selectedOption == 'Blood Pressure') {
        this.subtype = null;
        this.selectedOptionData = 'bloodPressure';
        this.graph_data = 'heart_rate';
      }
      else if (postdata.selectedOption == 'Peak Flow') {
        this.selectedOptionData = 'peakFlow'
        this.graph_data = 'peak_flow';
      }
      else if (postdata.selectedOption == 'Infusion Site') {
        this.selectedOptionData = 'infusionsite'
        this.graph_data = 'peak_flow';
      }
      else if (postdata.selectedOption == 'Body Parameters: Temperature') {
        this.subtype = null;
        this.selectedOptionData = 'bodytemperature';
        this.graph_data = 'bodytemperature';
      }
      else if (postdata.selectedOption == 'Body Parameters: Hip waist ratio') {
        this.subtype = null;
        this.selectedOptionData = 'hipwaist';
        this.graph_data = 'hipwaist';
      }
      this.getTableData(this.selectedUserID, this.selectedOptionData, startd, endd,this.page);

      if (this.mainoption == 'Peak Flow') {
        this.userDetailService.getPeakFlowGraphData(startd, endd, this.selectedUserID)
          .pipe(
            delay(100),
            catchError((error: any) => {
              this.loadingError$.next(true);
              return throwError(error);
            }),
            finalize(() => { this.loadingSubject$.next(false) })
          ).subscribe((data:any)  => {
            data = (data as any).data;
           
            this.tabulardata = data;
            this.loadingSubject$.next(false);
            this.loadingError$.next(false);

            this.defaultleftoption = 'peak_flow';

            let dates = [];
            let values = [];
            let values1 = [];

            for (let p = 0; p < this.tabulardata.graph_data.length; p++) {
              if (this.tabulardata.graph_data[p].value != "0,0") {
                values.push(this.tabulardata.graph_data[p].value[0]);
                values1.push(this.tabulardata.graph_data[p].value[1]);
                
                let dateArray = this.tabulardata.graph_data[p].key.split("-");
                dates.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
              }
            }

            if ( values.length === 0){
              let dateArray = this.tabulardata.graph_data[0].key.split("-");
              dates.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));

              let maxGraphDataLength = this.tabulardata.graph_data.length - 1;
              let dateArr = this.tabulardata.graph_data[maxGraphDataLength].key.split("-");
              dates.push(new Date(dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0]));

              values.push(0);
              values.push(0);

              values1.push(0);
              values1.push(0);
            }

            setTimeout(() => {
              this.showMultipleBarGraph(dates, values, values1);
            }, 300);

          });

      }

      if (this.mainoption != 'Peak Flow') {
        this.userDetailService.getPatientFilteredDiaryData(this.graph_data, startd, endd, this.selectedRange, this.selectedUserID, this.subtype, this.bloodsugarlogtype, this.activityUnits)
          .pipe(
            delay(100),
            catchError((error: any) => {
              this.loadingError$.next(true);
              this.defaultleftoption = "";
              return throwError(error);
            }),
            finalize(() => { this.loadingSubject$.next(false) })
          ).subscribe((data:any) => {
            this.tabulardata = data;
            this.loadingSubject$.next(false);
            this.loadingError$.next(false);

            if (postdata.selectedOption == 'Activity') {
              
              this.defaultleftoption = 'activity';
              let bottomlabel = [];
              let serieslevel = [];

              for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {
                  serieslevel.push(Number(this.tabulardata.data[this.selectedRange][p].value));

                  let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");

                  bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
              }

              setTimeout(() => {
                this.showNewBarGraph(bottomlabel, serieslevel);
              }, 300);

            }

            else if (postdata.selectedOption == 'Blood Sugar') {

              this.defaultleftoption = 'blood_sugar';

              let bottomlabel = [];
              let serieslevel = [];

              for (let r = 0; r < this.tabulardata.data[this.selectedRange].length; r++) {
                if (this.tabulardata.data[this.selectedRange][r].value != "0") {
                  serieslevel.push(Number(this.tabulardata.data[this.selectedRange][r].value));

                  let dateArray = this.tabulardata.data[this.selectedRange][r].key.split("-");
                  bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
                }
              }

              if (serieslevel.length === 0){
                serieslevel.push(0);
                serieslevel.push(0);

                let lengthMax = this.tabulardata.data[this.selectedRange].length - 1;

                let dateArray = this.tabulardata.data[this.selectedRange][0].key.split("-");
                let dateArray1 = this.tabulardata.data[this.selectedRange][lengthMax].key.split("-");

                bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
                bottomlabel.push(new Date(dateArray1[2] + "-" + dateArray1[1] + "-" + dateArray1[0]));
              }

              setTimeout(() => {
                this.showNewDottedGraph(bottomlabel, serieslevel);
              }, 300);
            }

            else if (postdata.selectedOption == 'Blood Pressure') {
              this.defaultleftoption = 'blood_pressure'
              var pressureBottomLabel = [];
              var systolicValues = [];
              var diastolicValues = [];
              var heartRateValues = [];
              for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {

                if (this.tabulardata.data[this.selectedRange][p].value.blood_pressure != "0/0"){
                  let values = this.tabulardata.data[this.selectedRange][p].value.blood_pressure.split("/");
                  systolicValues.push(Number(values[0]));
                  diastolicValues.push(Number(values[1]));

                  let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");
                  pressureBottomLabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
                  if(this.tabulardata.data[this.selectedRange][p].value.heart_rate === null){
                    heartRateValues.push(0);
                  }else{
                    heartRateValues.push(this.tabulardata.data[this.selectedRange][p].value.heart_rate);
                  }
                }
              }

              if (systolicValues.length === 0){

                systolicValues.push(0);
                systolicValues.push(0);

                diastolicValues.push(0);
                diastolicValues.push(0);

                heartRateValues.push(0);
                heartRateValues.push(0);

                let lengthMax = this.tabulardata.data[this.selectedRange].length - 1;

                let dateArray = this.tabulardata.data[this.selectedRange][0].key.split("-");
                let dateArray1 = this.tabulardata.data[this.selectedRange][lengthMax].key.split("-");

                pressureBottomLabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
                pressureBottomLabel.push(new Date(dateArray1[2] + "-" + dateArray1[1] + "-" + dateArray1[0]));

              }

              setTimeout(() => {
                this.showBloodPressureGraph(pressureBottomLabel, systolicValues, diastolicValues, heartRateValues);
              }, 300);
            }

            else if (postdata.selectedOption == 'Water') {
              this.defaultleftoption = 'water';
              var bottomlabel = [];
              var serieslevel = [];

              for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {
                serieslevel.push(Number(this.tabulardata.data[this.selectedRange][p].value));

                let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");
                bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
              }

              this.showNewBarGraph(bottomlabel, serieslevel);
            }

            else if (postdata.selectedOption == 'Meal') {
              this.defaultleftoption = "meal";

              let mealBottomLabel = [];
              let mealSeriesValue = [];

              for (let t = 0; t < this.tabulardata.data[this.selectedRange].length; t++) {
                let dateArray = this.tabulardata.data[this.selectedRange][t].key.split("-");
                mealBottomLabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));

                mealSeriesValue.push(Number(this.tabulardata.data[this.selectedRange][t].value));
              }

              setTimeout(() => {
                this.showNewBarGraph(mealBottomLabel, mealSeriesValue);
              }, 300);
            }

            else if (postdata.selectedOption == 'Weight') {
              this.defaultleftoption = "weight";
              let bottomlabel = [];
              let serieslevel = [];
            
              for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {
                  
                if (Number(this.tabulardata.data[this.selectedRange][p].value) !== 0){
                  serieslevel.push(Number(this.tabulardata.data[this.selectedRange][p].value));

                  let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");

                  bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
                }  

              }

              if (bottomlabel.length === 0){

                serieslevel.push(Number(this.tabulardata.data[this.selectedRange][0].value));

                let dateArray = this.tabulardata.data[this.selectedRange][0].key.split("-");

                bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));

                let lengthMax = this.tabulardata.data[this.selectedRange].length - 1;

                serieslevel.push(Number(this.tabulardata.data[this.selectedRange][lengthMax].value));

                let dateArray1 = this.tabulardata.data[this.selectedRange][lengthMax].key.split("-");

                bottomlabel.push(new Date(dateArray1[2] + "-" + dateArray1[1] + "-" + dateArray1[0]));

              }
              
              setTimeout(() => {
                this.showNewDottedGraph(bottomlabel, serieslevel);
              }, 300);
            }

          });
      }
    }
    else {
      this.dataSource = '';
      this.systemService.showSnackBar("End date should be Greater than Start Date", "OK");
    }
  }

  initComponentDraw(selectedUserID: string) {
    //this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    let datap = [];
    datap.push('id', selectedUserID);
    this.userDetailsSubject$.next(datap);
    this.user = datap;
  }

  getUserEligibleLogs(id: string) {
    this.userDetailService.getEligibleLogs(id).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      this.eligibleLogData = data;

      this.filteradmincontent(this.eligibleLogData);
      this.loadingError$.next(false);
    });
  }

  filteradmincontent(a) {
    this.eligibleLogData = [];
    for (let j = 0; j < a.length; j++) {
      if (a[j].type == 'diary' || a[j].type == 'body_parameters') {
        this.eligibleLogData.push(a[j]);
      }
    }
  }

  showBloodPressureGraph(pressureBottomLabel, systolicValues, diastolicValues, heartRateValues) {

    google.charts.load('current', { 'packages': ['corechart', 'controls'] });
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;
    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));
      let maxValue = 0;
      let pointSize = 3;

      let combined = new Array();
      
      if ( systolicValues[0] > diastolicValues[0]){
        maxValue = systolicValues[0];
      } else {
        maxValue = diastolicValues[0];
      }

      for(let i = 0;i<systolicValues.length;i++){

        if (systolicValues[i] > maxValue){
          maxValue = systolicValues[i];
        }

        if(diastolicValues[i] > maxValue){
          maxValue = diastolicValues[i];
        }
      }

      if (maxValue === 0){
        pointSize = 0;
      }

      maxValue += 50;

      let tickDifference = 50;
      let ticks = [0];

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      combined[0] = ['range', 'systolic', 'diastolic', 'systolic', 'diastolic', 'pulserate'];
      for (let i = 0; i < systolicValues.length; i++) {
        combined[i + 1] = [pressureBottomLabel[i], systolicValues[i], systolicValues[i], diastolicValues[i], diastolicValues[i], heartRateValues[i]];
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(4, { type: 'string', role: 'tooltip', p: { html: true } });

      for (let i = 0; i < data.getNumberOfRows(); i++) {
        let tooltip = `<div class="ggl-tooltip">
        <span style="font-size: 10px;">${data.getValue(i, 1)}-${data.getValue(i, 3)}mmHg</span>
        <br />
        <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
        </div>`;
        data.setValue(i, 4, tooltip);
      }

      data.insertColumn(7, { type: 'string', role: 'tooltip', p: { html: true } });
      for (let i = 0; i < data.getNumberOfRows(); i++) {
          if(data.getValue(i, 6) !== 0){
            let tooltip = `<div class="ggl-tooltip">
            <span style="font-size: 10px;"> Pulse:${data.getValue(i, 6)}bpm</span>
            <br />
            <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
            </div>`;
          data.setValue(i, 7, tooltip);
          }else{
            let tooltip = `<div class="ggl-tooltip">
            <span style="font-size: 10px;"> Pulse:NA</span>
            <br />
            <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
            </div>`;
          data.setValue(i, 7, tooltip);
          }
        
      }

      // Create a Column chart passing some options
      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'CandlestickChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": "mmHg",
            "format": "decimal",
            "gridlines": { "color": '#dfdfdf' },
            "minorGridlines": { "color": '#dfdfdf' },
            "ticks": ticks,
            "maxValue": maxValue
          },
          "bar": { "groupWidth": '4' },
          "candlestick": {
            "fallingColor": { "strokeWidth": 0, "fill": '#CB0083' }, // red
            "risingColor": { "strokeWidth": 0, "fill": '#CB0083' }   // green
          },
          "seriesType": 'candlesticks',
          "series": { 1: { "type": 'scatter', "color": '#691DD6', "pointSize": pointSize } }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });
      
      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
    }
  }

  showNewBarGraph(dates, values){

    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;

    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));

      let combined = new Array();
      let maxValue = values[0];
      let sum = 0;
      let count = 0;

      combined[0] = ['range', 'value'];

      for(let i = 0; i<values.length;i++){
        combined[i+1] = [dates[i], values[i]];

        if (values[i] > maxValue){
          maxValue = values[i];
        }

        if (scope.mainoption === 'Activity' || scope.mainoption === 'Water' || scope.mainoption === 'Meal'){
          if (values[i] != 0){
            sum += values[i];
            ++count;
          }
        }
       
      }

      if (sum > 0){
        if (scope.mainoption === 'Activity'){
          scope.activityAverage = ` ${Math.round(sum / count)} ${scope.activityUnits}`;
        }

        if (scope.mainoption === 'Water'){
          scope.waterAverage = ` ${Math.round(sum / count)} ml`;
        }

        if (scope.mainoption === 'Meal'){
          scope.mealAverage = ` ${Math.round(sum / count)} kcal`;
        }
      } else {
        scope.activityAverage = "NA";
        scope.waterAverage = "NA";
        scope.mealAverage = "NA";
      }

      let ticks = [0];
      let tickDifference = 0;
      let vAxisTitle = "";

      if (scope.mainoption === "Activity"){
        if (scope.activityUnits === "mins"){
          tickDifference = 30;
          maxValue = maxValue + 50;
          vAxisTitle = "mins";
        } else if (scope.activityUnits === "steps"){
          tickDifference = 1500;
          maxValue = maxValue + 300;
          vAxisTitle = "steps";
        }
      } else if (scope.mainoption === 'Water'){
        tickDifference = 500;
        maxValue = maxValue + 500;
        vAxisTitle = "ml";
      } else if (scope.mainoption === 'Peak Flow'){
        tickDifference = 100;
        maxValue = maxValue + 100;
        vAxisTitle = "l/min";
      } else if (scope.mainoption === 'Meal'){
        tickDifference = 300;
        maxValue = maxValue + 300;
        vAxisTitle = "kcal";
      }

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(2, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> Value:${data.getValue(i, 1)}${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 2, tooltip);
      }

      // Create a Column chart passing some options
      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'ColumnChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": vAxisTitle,
            "format": "decimal",
            "minorGridlines": {
              "count": 0
            },
            "gridlines": {
              "count": 0
            },
            "ticks": ticks,
            "maxValue": maxValue
          }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });
      
      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
      scope.showLoader = false;
      
    }
  }

  showMultipleBarGraph(dates, values, values1){

    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;
    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));

      let combined = new Array();
      let maxValue = values[0];

      combined[0] = ['range', 'Before Medication', 'After Medication'];

      for(let i = 0; i< values.length;i++){
        combined[i+1] = [dates[i], values[i], values1[i]];

        if (values[i] > maxValue){
          maxValue = values[i];
        }

        if(values1[i] > maxValue){
          maxValue = values1[i];
        }
       
      }

      let ticks = [0];
      let tickDifference = 100;
      let vAxisTitle = "l/min";

      maxValue = maxValue + 100;

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(2, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> Before Medication: ${data.getValue(i, 1)} ${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 2, tooltip);
      }

      data.insertColumn(4, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> After Medication: ${data.getValue(i, 3)} ${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 4, tooltip);
      }

      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'ColumnChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": vAxisTitle,
            "format": "decimal",
            "minorGridlines": {
              "count": 0
            },
            "gridlines": {
              "count": 0
            },
            "ticks": ticks,
            "maxValue": maxValue
          }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });

      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
      scope.showLoader = false;
    }

  }

  showNewDottedGraph(dates, values){

    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;
    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));

      let combined = new Array();
      let maxValue = values[0];

      combined[0] = ['range', 'value'];

      for(let i = 0; i<values.length;i++){
        combined[i+1] = [dates[i], values[i]];

        if (values[i] > maxValue){
          maxValue = values[i];
        }
       
      }

      let pointSize = 2;

      if (maxValue === 0){
        pointSize = 0;
      }

      let ticks = [0];
      let tickDifference;
      let vAxisTitle;

      if (scope.mainoption === "Weight"){
        tickDifference = 20;
        vAxisTitle = "Kg";
        maxValue = maxValue + 30;
      } else if (scope.mainoption === "Blood Sugar"){
        tickDifference = 50;
        vAxisTitle = "mg/dL"
        maxValue = maxValue + 50;
      }

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(2, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> Value:${data.getValue(i, 1)}${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 2, tooltip);
      }

      // Create a Column chart passing some options
      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'ScatterChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "pointSize": pointSize,
          "colors": ["#ff7131"],
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": vAxisTitle,
            "format": "decimal",
            "minorGridlines": {
              "count": 0
            },
            "gridlines": {
              "count": 0
            },
            "ticks": ticks,
            "maxValue": maxValue
          }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "height": 60,
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });

      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
      scope.showLoader = false;
    }

  }

  getMonthString(num){
    return SHORT_MONTHS[num];
  }

  sortByKey(arrp, keyp) {
    return arrp.sort(function (a, b) {
      var x = a[keyp];
      var y = b[keyp];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  removeDuplicates(array, key) {
    var k;
    var newp;
    var oldarray;
    var newdate;
    var arraydate;
    this.newar = [];
    for (var i = 0; i < array.length; i++) {
      k = 0;
      for (var j = 0; j < this.newar.length; j++) {
        newdate = new Date(this.newar[j]['on_date'] * 1000);
        arraydate = new Date(array[i]['on_date'] * 1000);
        newdate = newdate.getDay() + '/' + newdate.getMonth() + 1 + '/' + newdate.getFullYear();
        arraydate = arraydate.getDay() + '/' + arraydate.getMonth() + 1 + '/' + arraydate.getFullYear();

        if (newdate == arraydate) {
          k = 1;
          if (key == 'duration') {
            oldarray = array[i][key];
            newp = this.newar[j][key] + oldarray;
            this.newar[j][key] = newp;
          }
          if (key == 'quantity') {
            oldarray = array[i][key];
            newp = this.newar[j][key] + oldarray;
            this.newar[j][key] = newp;
          }
        }
      }
      if (k == 0) {
        this.newar.push(array[i]);
      }
    }
    return this.newar;
  }

  /// All Graph Functions End //

  getTheTime(dateStamp) {
    if (dateStamp == undefined) {
      return "Not Available";
    } else {
      var datep = new Date(dateStamp * 1000);
      var minval = ('0' + datep.getMinutes()).slice(-2)
      return (datep.getHours() + ":" + minval + "  " + datep.getDate() + " " + SHORT_MONTHS[datep.getMonth()] + " " + datep.getFullYear());
    }
  }
  gettype(type) {
    type = this.symptomnames[type] ? this.symptomnames[type] : 'NF';
    return type
  }
  showbreakup(data) {
    this.dialog.open(MealbreakupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        dataKey: data
      }
    });
  }
  close() {
    this.imageData = null;
    this.video = null;
    this.isPDF = null;
    this.myInputVariable.nativeElement.value = null;
  }

  deleteLog(genericData) {
    if (confirm("Are you sure you want to delete the log?")) {
      this.userDetailService.deleteReport(this.selectedOptionData, genericData.id)
        .subscribe(data => {
          this.systemService.showSnackBar("Log has been Deleted", "OK");
          this.sendMqttmsg();
          this.applymainfilter();
        })
    }
  }

  deleteMealLog(meal_id: any, group_id: any, full_data: any,index) {
    let bodydata: any;
    let innerdata: any;
    let foodData = [];
    let finalValue:any = 0;
    let date:any
    const arr = cloneDeep(full_data);
    if (confirm("Are you sure you want to delete the log?")) {
    for (let i = 0; i < arr.meal_data.length; i++) {
      if( arr.meal_data[i].id === group_id){ 
        for (let j = 0; j < arr.meal_data[i].food_data.length; j++) {
          let food_data_length = arr.meal_data[i].food_data.length;
        
        
          if (arr.meal_data[i].food_data.length > 1) {
            if (index === j) {
              arr.meal_data[i].food_data[j]['is_deleted'] = true ;
           }else{
             if( arr.meal_data[i].food_data[j]['is_deleted'] === true){
               arr.meal_data[i].food_data[j]['is_deleted'] = true ;
             }else{
               arr.meal_data[i].food_data[j]['is_deleted'] = false ;
             }
           }
            if(arr.meal_data[i].food_data[j]){
              delete arr.meal_data[i].food_data[j].nutrition_data
              finalValue += arr.meal_data[i].food_data[j].value
              foodData.push(arr.meal_data[i].food_data[j]);
              // let str = arr.log_date;
              // let darr = str.split("-");  
              // let ISOFormat = new Date(parseInt(darr[2]),parseInt(darr[1]),parseInt(darr[0]));
              // date = ISOFormat.toISOString()
              date = arr.meal_data[i].log_date;
              innerdata = {
                "type": arr.meal_type,
                "on_date": date,
                "food_data": JSON.stringify(foodData),
                "patient_id_data_fk": arr.meal_data[i].patient_id_data_fk,
              }
              let update_global_is_deleted:any=false;
              update_global_is_deleted = arr.meal_data[i].food_data.every(element => {
                if (element.is_deleted === true) {
                  return true;
                }
              });
              if(update_global_is_deleted === true){
                bodydata = {
                  value: finalValue,
                  food_data: innerdata.food_data,
                  log_date:  moment(innerdata.on_date).toISOString(),
                  meal_type: innerdata.type.toString(),
                  logged_by: "hc",
                  patient_id_data_fk: innerdata.patient_id_data_fk,
                  is_suggestion: "false",
                  image_ids:"[]",
                  is_deleted:update_global_is_deleted,
                  created_by_fk:localStorage.getItem('scarletuserid'),
                 
                };
                if((j + 1) == (food_data_length)){
                  this.userDetailService.UpdateMealLog(bodydata,group_id)
                  .subscribe(data => {
                    this.systemService.showSnackBar("Log has been Deleted", "OK");
                    this.sendMqttmsg();
                    this.applymainfilter();
                  })
                }
                this.userDetailService.deleteReport(this.selectedOptionData, group_id)
                .subscribe(data => {
                  this.systemService.showSnackBar("Log has been Deleted", "OK");
                  this.applymainfilter();
                  this.sendMqttmsg();
                })
                break;
              }else if(update_global_is_deleted === false){
                bodydata = {
                  value: finalValue,
                  food_data: innerdata.food_data,
                  log_date:  moment(innerdata.on_date).toISOString(),
                  meal_type: innerdata.type.toString(),
                  logged_by: "hc",
                  patient_id_data_fk: innerdata.patient_id_data_fk,
                  is_suggestion: "false",
                  image_ids:"[]",
                  created_by_fk:localStorage.getItem('scarletuserid'),
                 
                };
                if((j + 1) == (food_data_length)){
                  this.userDetailService.UpdateMealLog(bodydata,group_id)
                  .subscribe(data => {
                    this.systemService.showSnackBar("Log has been Deleted", "OK");
                    this.sendMqttmsg();
                    this.applymainfilter();
                  })
                  break;
              }
            }
              }
         
          } else {
              this.userDetailService.deleteReport(this.selectedOptionData, group_id)
                .subscribe(data => {
                  this.systemService.showSnackBar("Log has been Deleted", "OK");
                  this.applymainfilter();
                  this.sendMqttmsg();
                })
          }
        }
      }
     
    }
  }
  }

  convertToFirstletterCaps(value){
    const string = value.charAt(0).toUpperCase() + value.slice(1);
    return string;
  }

  getNextSet() {
    if(this.selectedOptionData === "activity" || this.selectedOptionData === "meal"){
      var startd = null;
      var endd = null;
      // If policy start date is available then use for MCHI user else use therapy start date to view diary logs
      if(this.userData?.policy_data?.customer?.[0]?.policy_start_date) {
        startd = new Date(this.userData?.policy_data?.customer?.[0]?.policy_start_date);
      } else {
        startd = new Date(this.userData.therapy_start_date);
      }
        startd.setHours(0, 0, 0, 0);
        endd = new Date();
        endd.setHours(23, 59, 59, 99);
      if (this.endoflist == false) {
        this.page = (+this.page + 1);
        this.getTableData(this.selectedUserID, this.selectedOptionData, startd, endd,this.page.toString());
      }
    }
  }
  showMacroData(data: any) {
    var scope = this;
    let carb = 0;
    let protein = 0;
    let fat = 0;
    let fiber = 0;
    let calories_kcal = 0;
    for (let i = 0; i < data.length; i++) {
      for(let j = 0; j < data[i].food_data.length; j++){
        let foodLength = data[i].food_data.length;
        if(foodLength > 1){
          if(data[i].food_data[j].is_deleted !== true){
            calories_kcal += (data[i].food_data[j].default_kcal  *  data[i].food_data[j].quantity);
            carb += data[i].food_data[j].nutrition_data.carbohydrates;
            protein +=  data[i].food_data[j].nutrition_data.proteins;
            fat +=  data[i].food_data[j].nutrition_data.fats;
            fiber +=  data[i].food_data[j].nutrition_data.fibers;
          }
        }else{
          if(data[i].is_deleted !== true){
            calories_kcal += (data[i].food_data[j].default_kcal  *  data[i].food_data[j].quantity);
            carb += data[i].food_data[j].nutrition_data.carbohydrates;
            protein +=  data[i].food_data[j].nutrition_data.proteins;
            fat +=  data[i].food_data[j].nutrition_data.fats;
            fiber +=  data[i].food_data[j].nutrition_data.fibers;
          }
        }
        
      }
      }
      this.diarylogopen = this.dialog.open(DiaryLogDialogComponent, {
        width: '500px',
        height: 'auto',
        data: {
          enable: true,
          meal_type: data[0].meal_type,
          totalcalories: calories_kcal,
          carb: carb,
          protein: protein,
          fat: fat,
          fiber: fiber,
        }
      });
      this.diarylogopen.afterClosed().subscribe(() => {
        this.initComponentDraw(this.appContext.selectedChatUser.getValue());
      });
  }
  calculateKcal(kcal:any,quantity:any) {
    return (kcal * quantity);
  }
  sendMqttmsg(){
    let convID = (this.appContext.selectedChatUserRowData.getValue() as any).chatMetadata[0].conv_id;
    let datenew;
    let healthCoachId = localStorage.getItem('scarletuserid');
    var hccode = 'H' + (healthCoachId).toString();
    var ucode = 'U' + (this.selectedUserID).toString();
    datenew = new Date();
    datenew = datenew * 1;
    let newmessagedata = {
      conversationId: convID,
      sender: hccode,
      clientId: this.socketService.clientid,
      participants: [hccode,  ucode],
      body: '',
      attachment: {
          link: '',
          type: "deeplink",
          value: 'Diary',
          additionalData: 'Diary Refresh',
      },
      trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
      mimeType: 'text/plain',
      type: 'deeplink',
      replyTo: '',
  };
  this.socketService.emitNewMessage(newmessagedata).subscribe(data => {
  });        
  }
}

export interface Element {
  duration: string;
  date: string;
  showGenericData: string;
  value: string;
  track: string;
  items: string;
  status: string;
  breakup: string;
  diastolic: string;
  systolic: string;
  heartrate: string;
  totalglass: string;
}
