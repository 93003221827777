import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../shared/shared.module';
import { MediainformationComponent } from './../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';
import { MagazinecollectionComponent } from './magazinecollection.component';
import { EditmagazinecollectionComponent } from './editmagazinecollection/editmagazinecollection.component';
import { MagazinecollectionRoutingModule } from './magazinecollection.routing.module';
import { ContentleftnavModule } from '../shared/contentleftnav/contentleftnav.module';
import { ContentnavbarModule } from '../shared/contentnavbar/contentnavbar.module';
import { TherapysetupleftnavModule } from '../shared/therapysetupleftnav/therapysetupleftnav.module';
import { FaqleftnavModule } from '../shared/faqleftnav/faqleftnav.module';


@NgModule({
  declarations: [

    MagazinecollectionComponent,
    EditmagazinecollectionComponent
  ],
  imports: [
    CommonModule,
    MagazinecollectionRoutingModule,
    ContentleftnavModule,
    ContentnavbarModule,
    TherapysetupleftnavModule,
    FaqleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class MagazinecollectionModule { }
