<div class="sidenav-content">
    <div id="content">
        <!-- <div class="col-md-12 actiobtncss">
                <div class="col-md-6">
                    <button mat-icon-button class="commonbackbtn qrbckbtn"
                    (click)="openLink($event)"><i
                        class="material-icons">keyboard_arrow_left</i></button>
                </div>
                <div class="col-md-6" style="padding-right: 0;">
                    <button mat-icon-button class="commonbackbtn pull-right qrsharebtn" style="margin-left: 10px;"><i
                        class="material-icons">share</i></button>
                </div>
        </div> -->
        <div class="container-fluid showdownload">
            <div class="qrboxp">
                <a href="#" style="font-size: 19px;word-break: break-all;">
                    {{alldata?.shortLink}}
                </a>
            </div>
            <div class="copyurlp">
                <button mat-flat-button (click)="copyURL()" class="commonbackbtn pull-right qrdownloadbtn"><i
                    class="material-icons">cloud_download</i> Copy Link</button>
            </div>
        </div>
    </div>
</div>