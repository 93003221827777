/*==========================================
Title: Profile image
Author: Soundharya AM
Date:   22 April 2020
Last Change :  Dynamic profile image changes on side navbar
==========================================*/

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-contentleftnav',
  templateUrl: './contentleftnav.component.html',
  styleUrls: ['./contentleftnav.component.scss']
})
export class ContentleftnavComponent implements OnInit {
  public haveAlerts: boolean = false;
  public dummyImage: string = "assets/img/imgplaceholder.svg";
  public imageurl: string;
  public responseImageUrl: any;
  public actRoute: any;
  public actRouteEdit: any;
  public showButtonLoader: any = false;
  public abc: any;
  s3url: any = environment.s3url;
  public userdata: any;
  public alluhash: any = {};
  fullImagePath: any;
  fullImagePath1: any;
  constructor() { }

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem('scarletuserdata'));
  }

  activeRouteTherapy() {
    this.actRouteEdit = window.location.hash;
    this.actRoute = window.location.hash.slice(1, 30);
    var regExp1 = RegExp('/content/level/edit/');
    var regExp2 = RegExp('/content/quiz/edit/');
    var regExp3 = RegExp('/content/module/edit/');
    var regExp4 = RegExp('/content/chapter/edit/');
    var regExp5 = RegExp('/content/lesson/edit/');
    var regExp6 = RegExp('/content/question/edit/');
    var regExp7 = RegExp('/content/magazine/edit/');
    var regExp8 = RegExp('/content/feedback/edit/');
    var regExp9 = RegExp('/content/language/edit/');
    var regExp10 = RegExp('/content/intent/edit/');
    var regExp11 = RegExp('/content/tag/edit/');
    var regExp12 = RegExp('/content/careycard/edit');
    var regExp13 = RegExp('/content/magazinecollection/edit');

    var result = (regExp1.test(this.actRouteEdit) || regExp2.test(this.actRouteEdit) || regExp3.test(this.actRouteEdit)
      || regExp4.test(this.actRouteEdit) || regExp5.test(this.actRouteEdit) || regExp6.test(this.actRouteEdit)
      || regExp7.test(this.actRouteEdit) || regExp8.test(this.actRouteEdit) || regExp9.test(this.actRouteEdit)
      || regExp10.test(this.actRouteEdit) || regExp11.test(this.actRouteEdit) || regExp12.test(this.actRouteEdit)
      || regExp13.test(this.actRouteEdit));

    var routeBool = (this.actRoute === '/content/level' || this.actRoute === '/content/quiz'
      || this.actRoute === '/content/module' || this.actRoute === '/content/chapter'
      || this.actRoute === '/content/lesson' || this.actRoute === '/content/question'
      || this.actRoute === '/content/careycard' || this.actRoute === '/content/magazine'
      || this.actRoute === '/content/feedback' || this.actRoute === '/content/tag'
      || this.actRoute === '/content/language' || this.actRoute === '/content/intent'
      || this.actRoute === '/content/magazinecollection');

    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  activeRouteRecipe() {
    this.actRoute = window.location.hash.slice(1, 20);
    this.actRouteEdit = window.location.hash;
    var regExp = RegExp('content/recipe/edit');
    var result = regExp.test(this.actRouteEdit);
    var routeBool = (this.actRoute === '/content/recipe' || this.actRoute === '/content/ingredient');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  activeRouteConditions() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    var regExp = RegExp('content/onboarding/create');
    var regExp2 = RegExp('content/activationcode/create');
    var regExp3 = RegExp('content/onboardingcontent')
    var regExp4 = RegExp('content/activationcode/view');
    var result = (regExp.test(this.actRouteEdit) || regExp2.test(this.actRouteEdit) || regExp3.test(this.actRouteEdit) || regExp4.test(this.actRouteEdit));
    var routeBool = (this.actRoute === '/content/onboarding' || this.actRoute === '/content/activationcode' || this.actRouteEdit == '/content/onboardingcontent');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  activeRouteFaq() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    
    var regExp = RegExp('content/faq/catogery');
    var regExp2 = RegExp('content/faq/question_answers');
    var regExp3 = RegExp('content/faq/category/edit/new');
    var regExp4 = RegExp('content/logging/mapping');
    var regExp5 = RegExp('content/symptom/mapping');
    var regExp6 = RegExp('content/questionnairmapping');
    var regExp7 = RegExp('content/questionnair');
    var regExp8 = RegExp('content/triggers');
    var regExp9 = RegExp('content/medicine');
    var regExp10 = RegExp('content/meal');
    var regExp11 = RegExp('content/conditionlist');
    var regExp12 = RegExp('content/conditionlist/update');
    var regExp13 = RegExp('content/trigger/mapping');
    var regExp14 = RegExp('/content/medication');
    var regExp15 = RegExp('/content/pump');
    var regExp16 = RegExp('/content/healthgoal');

    var result = (regExp.test(this.actRouteEdit) || regExp2.test(this.actRouteEdit) || regExp3.test(this.actRouteEdit)
      || regExp4.test(this.actRouteEdit) || regExp5.test(this.actRouteEdit) || regExp6.test(this.actRouteEdit)
      || regExp7.test(this.actRouteEdit) || regExp8.test(this.actRouteEdit) || regExp9.test(this.actRouteEdit) || regExp10.test(this.actRouteEdit) || regExp11.test(this.actRouteEdit) || regExp12.test(this.actRouteEdit) || regExp13.test(this.actRouteEdit) || regExp14.test(this.actRouteEdit) || regExp15.test(this.actRouteEdit) || regExp16.test(this.actRouteEdit));
    var routeBool = (this.actRoute === 'content/faq/categery' || this.actRoute === 'content/faq/question_answers'
      || this.actRoute === 'content/faq/category/edit/new' || this.actRoute === 'content/questionnairmapping'
      || this.actRoute === 'content/questionnair' || this.actRoute === 'content/logging/mapping'
      || this.actRoute === 'content/symptom/mapping' || this.actRoute === 'content/triggers'
      || this.actRoute === 'content/medicine' || this.actRoute === 'content/medicine/edit'
      || this.actRoute === 'content/meal' || this.actRoute === 'content/meal/edit'
      || this.actRoute === 'content/conditionlist' || this.actRoute === 'content/conditionlist/update' || this.actRoute === 'content/trigger/mapping' || this.actRoute === 'content/medication' || this.actRoute === 'content/pump' || this.actRoute === 'content/healthgoal');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

  activeRouteUuxid() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    var regExp = RegExp('content/dynamicuuxid');
    var regExp2 = RegExp('content/staticuuxid');

    var result = (regExp.test(this.actRouteEdit));
    var routeBool = (this.actRoute === 'content/dynamicuuxid' || this.actRoute === 'content/staticuuxid');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }
  mediaRouteConditons() {
    this.actRoute = window.location.hash.slice(1, 25);
    this.actRouteEdit = window.location.hash;
    var regExp = RegExp('/content/media');
    var result = (regExp.test(this.actRouteEdit));
    var routeBool = (this.actRoute === '/content/media');
    if (routeBool) {
      return 'active';
    }
    if (result) {
      return 'active';
    }
  }

}
