<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/taghome/tagedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Tag</h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                    <div *ngIf="dataForm.hasError('whitespace')">Please enter valid data</div>

                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput placeholder="Enter the description"
                                        formControlName="detailed_text"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <!-- <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Color</mat-label>
                                    <input matInput placeholder="Enter the color code" formControlName="color" required>
                                </mat-form-field> -->
                                <div class="label-color">Select Tag Color</div>

                                <div class="colorholder">
                                    <div class="radio-holder" style="margin-left:0">
                                        <input type="radio" name="color" formControlName="color" value="#479020"
                                            id="color1" />
                                        <label for="color1">
                                            <div class="rectangle" [style.background]="'#479020'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#691dd6"
                                            id="color2" />
                                        <label for="color2">
                                            <div class="rectangle" [style.background]="'#691dd6'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#cb0054"
                                            id="color3" />
                                        <label for="color3">
                                            <div class="rectangle" [style.background]="'#cb0054'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#0053d5"
                                            id="color4" />
                                        <label for="color4">
                                            <div class="rectangle" [style.background]="'#0053d5'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#008e9a"
                                            id="color5" />
                                        <label for="color5">
                                            <div class="rectangle" [style.background]="'#008e9a'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#cb0083"
                                            id="color6" />
                                        <label for="color6">
                                            <div class="rectangle" [style.background]="'#cb0083'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#ff7400"
                                            id="color7" />
                                        <label for="color7">
                                            <div class="rectangle" [style.background]="'#ff7400'"></div>
                                        </label>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="col-md-12 zeropadleft zeromarginleft">
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Tag</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>