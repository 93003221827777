    <div class="tag-holder">
        <form [formGroup]="tagForm" #form="ngForm">
            <mat-form-field appearance="outline" class="full-width-formfield inputboxcss tag-addbox">
                <mat-label>Select Tag</mat-label>
                <mat-select placeholder="Select Tag" formControlName="tag_id">
                    <mat-option *ngFor="let tag of filtertags" [value]="tag.id" [disabled]="checkDuplicate(tag.id)">
                        {{tag.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>    
            <button mat-flat-button (click)="saveTag()" color="primary" class="centerbtn" [disabled]="!tagForm.valid" [class]="isloading ? 'centerbtn loaderbtn' : 'centerbtn'">Assign Tag</button>
        </form>
    </div>

