import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export const listAnimation = trigger('listAnimation', [
    transition('* => *', [
        query(':enter', style({ opacity: 0.05 }), { optional: true }),
        query(':enter', stagger('50ms', [
            animate('0.5s ease-in', keyframes([
                style({ opacity: 0.2, transform: 'translateY(-75%)', offset: 0 }),
                style({ opacity: 0.5, transform: 'translateY(35px)', offset: 0.3 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
            ]))]), { optional: true })
    ])
])
