import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-therapydesign',
  templateUrl: './therapydesign.component.html',
  styleUrls: ['./therapydesign.component.scss']
})

export class TherapydesignComponent implements OnInit {

  displayedColumns: string[] = ['title','type','updatedat','action'];
  searchcriteria: any;
  public isactive: any;
  public page: number = 1;
  public showloader: boolean = false;
  lastSearch: any;
  public lastpage: number = 0;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public newsection: any = 'new';
  private myhash: any = {};
  private myhash2: any = {};
  allconditions: any;
  temporiginalvalue: any;
  temptabulardata: any;
  originalvalue: any;
  public filteredtabulardata: any;
  activecls = 'active';
  public conditionFilters: any;
  public filteredcondition: any = [];
  public status: any = 'active';
  public userData: any;
  alllevels: any;
  result: any = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) {
    localStorage.removeItem("ActiveTherapy");
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Therapy Design');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
    this.getConditions();
    this.getLevels();

    this.userData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.userData.email == 'central@wellthy.care' ? true : false);
  }


  getUsername(id) {
    return this.contentService.allusers[id];
  }

  checkdisable(el, therapy) {
    // if((el=='amateur' && therapy.amateur_levels!== null && therapy.amateur_levels.length == therapy.level_count) || (el=='average' && therapy.average_levels!==null && therapy.average_levels.length == therapy.level_count) || (el=='proficient' && therapy.proficient_levels!==null && therapy.proficient_levels.length == therapy.level_count))
    if (therapy.is_active == true) {
      return true;
    }
  }


  // activate inactivate Therapy Design
  // ----------------------------


  isActive(status, id) {
    let value;
    if (status) {
      value = "Deactivate"
    } else {
      value = "Activate"
    }
    this.contentService.getOneDataById1(status, 'therapy', id)
      .subscribe(
        (res: any) => this.onChangeStatusSuccess(status, id, res,value),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onChangeStatusSuccess(status, id, data,value) {
    // var islevelunfilled = false;
    // data = (data as any).data;
    
    // for (let k = 0; k < data.therapy_levels.length; k++) {
    //   if ([data.therapy_levels[k]] == null) {
    //     this.systemService.showSnackBar("Please fill the therapy levels", "Ok");
    //     islevelunfilled = true;
    //   }
    // }
    // if (islevelunfilled == false) {
    //   this.activate(status, id);
    // }
     this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = this.tabulardata;
  }


  // ----------------------------

  tableSort(getFilterValue) {
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredcondition = [];
    this.conditionFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.searchcriteria = "";
    this.tabulardata = [];    
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.endoflist = false;
    this.search();
  }


  // activate inactivate Therapy Design
  // ----------------------------

  activate(status, id) {
    this.contentService.setStatus(status, 'therapy', id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.systemService.showSuccessSnackBar('The selected therapy has been activated', 'Ok');    
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // To delete Therapy Design
  // ----------------------------

  delete(id) {
    this.contentService.hardDelete('therapy', id)
      .subscribe(
        (res: any) => this.onDeleteSuccess(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected therapy has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }



  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  conditionfilter(fln) {
    this.filteredcondition = fln;
    this.temptabulardata = this.originalvalue;
    this.tabulardata = this.originalvalue;
    if (fln.length == 0) {
      this.tabulardata = this.tabulardata;
    }
    else {
      this.tabulardata = [];
      this.filteredtabulardata = this.temptabulardata.filter(checkConditions);
      this.tabulardata = this.filteredtabulardata;
    }
    function checkConditions(t) {
      if (fln.includes(t.master_condition.toString())) {
        return true;
      }
    }
  }

  // ----------------------------


  // To get All Conditions
  // ----------------------------

  getConditions() {
    this.showloader = true;
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getConditionsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsSuccess(data) {
    this.allconditions = data.getAllConditions;
    
    this.allconditions.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  getConditionName(condition) {
    condition = this.myhash[condition];
    return condition;
  }

  // getSecoundaryCondition(condition) {
  //   

  //   let SecoundaryConditionData:any={}
  //   this.allconditions.forEach(val => condition.includes(val.id) &&   this.result.push(val));
  //   // for (let i = 0; condition.length >= 1; i++) {
  //   //   
  //   //   return result[i].title
  //   // }
  //   
  // }

  // ----------------------------

  // To get All Levels
  // ----------------------------

  getLevels() {
    this.contentService
      .getGraphQlData('{getParentLevels(status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getLevelsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLevelsSuccess(data) {
    
    this.alllevels = data.getParentLevels;
    
    this.alllevels.forEach(element => {
      this.myhash2[element.id] = element.title;
    });
  }

  getLevelName(level) {
    level = this.myhash2[level];
    return level;
  }
  // ----------------------------

  // To get All Levels
  // ----------------------------



  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  // List Therapy
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getAllData(this.searchcriteria, this.page.toString(), "20", this.status, "therapy", true)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    
    data = (data as any).data;
    
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;        
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
      this.originalvalue = this.tabulardata;
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.originalvalue = this.tabulardata;
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'updatedat': return new Date(item.updated_at);
          default: return item[property];
        }
      };
    }
    if (this.filteredcondition.length !== 0) {
      this.conditionfilter(this.filteredcondition);
    }
    else {
      this.tabulardata = this.originalvalue;      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }

  viewTherapy() {
    localStorage.setItem("ActiveTherapy", "Active");
  }

}

export interface PeriodicElement {
  name: string;
  code: string;
  module: number;
  condition: string;
}