<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                New Publish Request
            </h3>
        </div>
        <div class="col-md-12" style="padding: 10px 30px;" *ngIf="showdiff">
            <div class="leftformsection">
                <div class="clearfix"><br /></div>
                <td-ngx-text-diff [left]="left" [hideMatchingLines]="true" [right]="right" (compareResults)="onCompareResults($event)">
                </td-ngx-text-diff>
                <div class="spinner-container" *ngIf="!showdiff">
                    <app-loader showLoader="{{true}}"></app-loader>
                </div>
            </div>

        </div>
        <div class="col-md-12" style="padding: 0px 30px;">
            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;margin-left: 40px;"
                [routerLink]="['/content/level/createrequest', levelid]">
                Create Publish Request</button>
        </div>
    </div>

</mat-drawer-container>