import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../environments/environment';
import { ContentserviceService } from '../services/contentservice/contentservice.service';

@Component({
  selector: 'app-static-uuxid',
  templateUrl: './static-uuxid.component.html',
  styleUrls: ['./static-uuxid.component.scss']
})
export class StaticUuxidComponent implements OnInit {
  deeplinkdata: any;
  deeplinkUrl: any = environment.deeplinkUrl;
  constructor(private contentService: ContentserviceService, private systemService: SystemService) { }

  ngOnInit(): void {
  }

  // To Copy Deeplink
  // -----------------------------

  copyDeeplink(title) {
    this.contentService.createDynamicLink(title)
    .subscribe(
      (res: any) => this.copyDeeplinkSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private  copyDeeplinkSuccess(data) {
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    this.deeplinkdata = data;
    
    inp.value = this.deeplinkdata.shortLink;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }

  // -----------------------------

}
