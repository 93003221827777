
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-patientuserdetails',
  templateUrl: './patientuserdetails.component.html',
  styleUrls: ['./patientuserdetails.component.scss']
})

export class PatientuserdetailsComponent implements OnInit {
  public patient_status: any;
  public allconditions: any;
  public emptycondition: boolean = false;
  public therapy_name: any;
  public app_version: any;
  public device_id:any;
  public device_type:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,

    public dialogRef: MatDialogRef<PatientuserdetailsComponent>, private userDetailService: UserdetailsService, private commonService: CommonserviceService, private systemService: SystemService,
    private browserTitle: Title
  ) { }
  public showButtonLoader: any;
  public userView: any;
  public therapy_data: boolean = false;
  public conditions: any;
  public myhash = {};
  patientData: any = [];
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Patient Profile');
  }

  ngOnInit() {
    this.setSEOTags();
    this.userView = this.data.element;   
    this.patient_status = this.userView.status.replace(/_/g, " ").toLowerCase();
    if (this.userView.therapyId == null) {
      this.therapy_data = true;
    }
    else {
      this.getTherapy(this.userView.therapyId);
      this.getpermissionData(this.data.element.id)
    }
    if (this.userView.conditions == null) {
      this.emptycondition = true;
    }
    else {
      this.getConditions(this.userView.conditions);
    }
  }

  getpermissionData(id) {


    this.userDetailService.getpatientPermissiondata(id)
      .subscribe(
        (res: any) => this.successermisonData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private successermisonData(data) {

    data = (data as any).data;  
    this.app_version = data[0].app_version;
    this.device_id= data[0].device_id;
    this.device_type= data[0].device_type;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  gender(data) {
    if (data == 'm') {
      return 'Male';
    }
    else if (data == 'f') {
      return 'Female';
    }
    else {
      return 'Others';
    }
  }

  getConditionName(allconditions) {
    allconditions = allconditions.map(i => this.myhash[i]);
    return allconditions;
  }


  //  Get Patient Therapy Data
  // ----------------------------

  getTherapy(id) {
    this.commonService.getGraphQlData('{getOneTherapy(appMode:false,therapyId:' + id + '){id,alias_name}}')
      .subscribe(
        (res: any) => this.getTherapyData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyData(data) {
    data = data.data;

    this.therapy_name = data.getOneTherapy.alias_name;
  }

  // ----------------------------


  //  Get Patient Condition Data
  // ----------------------------

  getConditions(id) {
    this.commonService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getConditionsData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsData(data) {
    data = data.data;
    this.conditions = data.getAllConditions;
    // 
    this.conditions.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  // ----------------------------

}
