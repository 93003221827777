import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';

@Component({
  selector: 'app-activatebottomsheet',
  templateUrl: './activatebottomsheet.component.html',
  styleUrls: ['./activatebottomsheet.component.scss']
})
export class ActivatebottomsheetComponent implements OnInit {
  public allCampaigns: any;
  public alllanguages: any;
  public dataForm = this.fb.group({
    phone: ["", Validators.required],
    country_code: ["", Validators.required],
    condition: ["", Validators.required],
    language_data_fk: ["",Validators.required]
  });
  constructor(private contentService: ContentserviceService,private systemService: SystemService, private _bottomSheetRef: MatBottomSheetRef<ActivatebottomsheetComponent>, public fb: FormBuilder,private doctorService: DoctorDataService) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  ngOnInit() {
    this.getCampaignData();
    this.getAllLanguages();
  }
  getCampaignData() {
    this.doctorService.getAllCampaign()
      .subscribe(data => {
        this.allCampaigns = (data as any).data;
      })
  }

  dataObject(){
    var postdata = this.dataForm.value;
    this.doctorService.preActiveUser(postdata)
      .subscribe(
        (res : any) => this.onSuccessUpdate(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
       );
  }

  onSuccessUpdate(data){
    if ((data as any).status == 200) {
      this.systemService.showSnackBar("Patient has been activated Successfully", "OK");
      this._bottomSheetRef.dismiss();
    }
    else {
      this.systemService.showSnackBar((data as any).message, "OK");
      this._bottomSheetRef.dismiss();
    }
  }

  getAllLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
  }

}
