import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivationsearchComponent } from './activationsearch.component';
import { MapDoctorComponent } from './map-doctor/map-doctor.component';
import { AdminGuardGuard } from './../../shared/guards';

const routes: Routes = [
  { path: '', component: ActivationsearchComponent, canActivate: [AdminGuardGuard] },
  { path: 'map-doctor', component: MapDoctorComponent, canActivate: [AdminGuardGuard] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivationsearchRoutingModule {}