import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-spanish-tnc',
  templateUrl: './spanish-tnc.component.html',
  styleUrls: ['./spanish-tnc.component.scss']
})
export class SpanishTncComponent implements OnInit {
  today: number = Date.now();
  jstoday = '';

  checkboxValue: boolean;
  rawprescriptiondata: any
  rawdata: any;
  doctorData: any;
  showButton:boolean = true;
  constructor(private router: Router, private doctorService: DoctorDataService) { 
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
  }

  ngOnInit(): void {
    let userData: string = localStorage.getItem('scarletuserdata');
    this.doctorData = JSON.parse(userData);
    if (JSON.parse(localStorage.getItem('doctorsla'))) {
      this.showButton = false;
    }else{
      this.showButton = true;
    }
  }

  serviceAgreement() {
    this.doctorService.slaAgreement(this.doctorData.id.toString())
    .subscribe(
      (data) => {
        localStorage.setItem('doctorsla', 'true');
        this.router.navigateByUrl('/doctor/home');
      })
  }
}
