<div class="row">
  <div class="col-md-12">

    <div class="row">

      <div class="col-md-8">
        <div class="title-desc-type">
          <p class="title-cont"> {{mediaView.title}} </p>
          <p class="desc"> {{mediaView.detailed_text}} </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="copy-close-wrapper">
          <div (click)="copyURL()" class="copy-btn">

            <div class="icon"></div>
            <div class="text">Copy URL</div>
            
          </div>

          <div class="close-icon-wrapper">
            <div class="close-icon" mat-dialog-close></div>
          </div>
        </div>
        
      </div>

    </div>
    
  </div>

  <div class="col-md-12">

    <div class="content-wrapper">

      <div class="original-content">
        <div class="content-holder">
          <p>Original {{mediaView.type}}</p>

          <div class="image-preview-upload" *ngIf="mediaView.type.includes('image')">
            <img [src]="s3url + mediaView.path" class="img-responsive center-block" alt="Original Thumbnail">
          </div>

          <div class="image-preview-upload" *ngIf="mediaView.type.includes('video') && !(mediaView.mime == 'video/x-flv' || mediaView.mime == 'video/mp4')">
            <video style="width:100%" [src]="s3url + mediaView.path" controls></video>
          </div>

          <div *ngIf="mediaView.type.includes('video') && (mediaView.mime == 'video/x-flv' || mediaView.mime == 'video/mp4')">
            <video id="video_player" class="video-js vjs-big-play-centered" style="width:100%;height: 250px;" controls preload="auto" data-setup='{"techOrder": ["flash"]}'>
              <source [src]="s3url + mediaView.path" type='{{mediaView.mime}}'>
              <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="http://videojs.com/html5-video-support/" target="_blank" rel="noopener">supports HTML5 video</a>
              </p>
            </video>
          </div>
  
          <div class="image-preview-upload" *ngIf="mediaView.type.includes('audio') && (mediaView.mime !== 'video/mp4')">
            <audio controls>
              <source [src]="s3url + mediaView.path" [type]="mediaView.mime">
            </audio>
          </div>
        </div>
      </div>

      <div class="thumbnail-content">
        <div class="content-holder">
          <p>Thumbnail</p>

          <ng-container *ngIf="!newImageSource.selected"> 
            <div class="image-preview-upload">
              <img [src]="s3url + mediaView?.thumbnail_path" class="img-responsive center-block" alt="Thumbnail">
            </div>
          </ng-container>
          
          <ng-container *ngIf="newImageSource.selected">
            <div class="image-preview-upload">
              <img [src]="newImageSource.url" class="img-responsive center-block" alt="Thumbnail">
            </div>
          </ng-container>

        </div>

        <div class='file-input'>
          <input type='file'  (change)="imageUpload($event)" accept='image/*'>
          <span class='label' data-js-label>No file selected</span>
          <span class='button'>Choose</span>
        </div>

        <button mat-flat-button color="primary" class="centerbtn tickbtn" *ngIf="btnactive" (click)="uploadObject()">
          <span class="material-icons">done</span>
        </button>
      </div>

    </div>

  </div>
</div>