import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ReplaySubject, Subject } from 'rxjs';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-create-hospital',
  templateUrl: './create-hospital.component.html',
  styleUrls: ['./create-hospital.component.scss']
})
export class CreateHospitalComponent implements OnInit {

  public newitem: boolean = false;
  public showButtonLoader: boolean = false;
  public userid: any;
  public userdata: any;
  allcampaigns: any;
  s3url: any = environment.s3url;
  public dataForm = this.fb.group({
    hospital_name: ['', [Validators.required, this.noWhitespaceValidator]],
    type: ['', Validators.required],
    contact_info: ['', Validators.required],
    address: ['', [Validators.required]],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    postalcode: ['', [Validators.required]],
    country: ['', [Validators.required]],
  });
  public localStoredData: any;
  private allfiles: any;
  public uploadedfiletype: any;
  private responsethumbUrl: any;
  public resdata: any;
  public image_path: any;
  public image_id: any;
  public uploadedparam: boolean = false;
  public isloading: boolean = false;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  public isinvalid: boolean = false;
  public searchmattag: FormControl = new FormControl();
  public isNewItem: any;
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;

  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private _onDestroy = new Subject<void>();
  location_data_fk: void;
  hospital_id: any;

  constructor(private browserTitle: Title, public contentService: ContentserviceService, public fb: FormBuilder,
    public dialog: MatDialog, private router: Router, private route: ActivatedRoute,
    private commonService: CommonserviceService, private systemService: SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateHospitalComponent>) { }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Hospital');
  }

  ngOnInit() {
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.setSEOTags();
    this.isNewItem = this.data.element;

    if (this.isNewItem == 'newsection') {
      this.newitem = true;
    }
    else {
      this.newitem = false;
      this.isNewItem;
      this.patchFormdata(this.isNewItem);
    }
  }
  patchFormdata(fromValue: any) {
    this.location_data_fk = fromValue.location_data_fk;
    this.hospital_id = fromValue.id;
    this.dataForm.get("hospital_name").patchValue(fromValue.name);
    this.dataForm.get("type").patchValue(fromValue.type);
    this.dataForm.get("city").patchValue(fromValue.city);
    this.dataForm.get("address").patchValue(fromValue.address);
    this.dataForm.get("state").patchValue(fromValue.state);
    this.dataForm.get("postalcode").patchValue(fromValue.postal_code);
    this.dataForm.get("country").patchValue(fromValue.country);
    this.dataForm.get("contact_info").patchValue(fromValue.contact_info);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChangedTags() {
    this.filterTags();
  }

  validateEmail(control: FormControl) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(control.value)) {
      if (control.value.indexOf("@wellthy.care", control.value.length - "@wellthy.care".length) !== -1) {
        return null
      }
      else {
        return { 'validateEmail': true };
      }
    }
    else {
      return { 'validateEmail': true };
    }
  }


  private filterTags() {
    if (!this.allcampaigns) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTherapy.next(this.allcampaigns.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.allcampaigns.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // ----------------------------

  getHospitalData(ide) {
    this.commonService.getHospitalDataById(ide)
      .subscribe(
        (res: any) => this.getUser(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private getUser(data) {
    data = (data as any).data;
    this.userdata = data;
    this.userdata.std_code = this.userdata.std_code.toString();
  }

  // ----------------------------

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  //  Submitting User Data 
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.hospital_name = postdata.hospital_name.toString();
    postdata.type = postdata.type.toString();
    postdata.contact_info = postdata.contact_info.toString();
    postdata.city = postdata.city.toString();
    postdata.state = postdata.state.toString();
    postdata.address = postdata.address.toString();
    postdata.postalcode = postdata.postalcode.toString();
    postdata.country = postdata.country.toString();
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');

    let locationPostdata = {
      "street_address": postdata.address,
      "city": postdata.city,
      "state": postdata.state,
      "postal_code": postdata.postalcode,
      "country": postdata.country
    }
    if (this.newitem == true) {
      this.commonService.createHospitalLocation(locationPostdata).pipe()
        .subscribe(
          (res: any) => {
            let hospitalPostdata = {
              "location_data_fk": (res.data.id).toString(),
              "name": postdata.hospital_name,
              "type": postdata.type,
              "contact_info": postdata.contact_info,
            }
            this.commonService.createHospital(hospitalPostdata).subscribe((data: any) => {
              this.systemService.showSuccessSnackBar(("Hospital has been created"), "OK");
              this.closeModal();
              this.router.navigate(['admin/hospital'])
                .then(() => {
                  window.location.reload();
                });
            });
          },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      this.commonService.updateHospitalLocation(locationPostdata, this.location_data_fk)
        .subscribe(
          (res: any) => {
            let hospitalPostdata = {
              "name": postdata.hospital_name,
              "type": postdata.type,
              "contact_info": postdata.contact_info,
            }
            this.commonService.updateHospital(hospitalPostdata, this.hospital_id).subscribe((data: any) => {
              this.systemService.showSuccessSnackBar(("Hospital has been Updated"), "OK");
              this.closeModal();
              this.router.navigate(['admin/hospital'])
                .then(() => {
                  window.location.reload();
                });
            });
          },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
