import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { FeedbackComponent } from './feedback.component';
import { EditfeedbackComponent } from './editfeedback/editfeedback.component';
const routes: Routes = [
  { path: '', component: FeedbackComponent, canActivate: [ContentGuardGuard] },
  { path: 'feedback/edit/:id/:langid/:chid', component: EditfeedbackComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeedbackRoutingModule { }
