import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-onboardingflow',
  templateUrl: './onboardingflow.component.html',
  styleUrls: ['./onboardingflow.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class OnboardingflowComponent implements OnInit {
   public isactive = 'all';
  public showloader: any;
  public alltags: any;
  viewContainer: any;
  displayedColumns: string[] = ["client_id", "client_name", "widget","action"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
  public newsection: any = 'new';
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public primarylanguage: any;
  public alllanguages: any;
  public tags: any;
  public allwidgets: any;
  private myhash: any = {};
  private myhashtwo: any = {};
  public showButtonLoader: boolean = false;
  public allclients: any;
  public newentry: boolean = false;
  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Onboarding Flow');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.getAllWidgets();
    this.getClients();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------

  // To get All clients
  // ----------------------------

  getClients() {
    this.contentService.getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.getClientsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getClientsSuccess(data) {
    this.allclients = data.getAllClients;
    this.allclients.forEach(element => {
      this.myhashtwo[element.id] = element.title;
    });
    this.search();
  }

  newentrycheck() {
    this.newentry = (((this.allclients.length) + 1) == this.tabulardata.length);
  }

  getClientName(clients) {
    clients = this.myhashtwo[clients];
    return clients;
  }

  // ----------------------------

  // To get All Widgets
  // ----------------------------

  getAllWidgets() {
    this.showloader = true;
    this.contentService.getGeneralRequest("obflow")
      .subscribe(
        (res: any) => this.getAllWidgetsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllWidgetsSuccess(data) {
    this.allwidgets = data;
    this.allwidgets.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }


  getWidgetname(allname) {
    var widgetarray = "";
    var count = 1;
    allname.forEach(el => {
      if (allname.length == count) {
        widgetarray += this.myhash[parseInt(el)];
      }
      else {
        widgetarray += this.myhash[parseInt(el)] + " -> ";
      }
      count++;
    });
    return widgetarray
  }
  // ----------------------------

  // List Onboarding flow 
  // ----------------------------
  
  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getAllData(this.searchcriteria, this.page.toString(), "10", "active", "obflowcreate", true)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'updatedat': return new Date(item.updated_at);
          default: return item[property];
        }
      }

    }
  }

  // ----------------------------

}

export interface PeriodicElement {
  client_id: string;
  client_name: string;
  widget: string;
}

