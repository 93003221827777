<div class="overlay">
    <div class="Downloader">{{timeleftval}}</div>
    <div class="bgaround"></div>
</div>
<div style="width: 940px; overflow: auto;height: 100vmin;">

    <!------------------------------------------------------------>
    <div class="">
        <div id="section1" class="wrapper sec1">
            <div class="row">
                <div class="secondpagedefaultheight">
                    <div class="center-row">
                        <div class="col-md-6">
                            <div class="report-title pull-left">
                                <h4 style="padding-bottom: 10px;">{{profileData?.primary_condition}} Report,
                                    {{(daterange?.startdate | date:'MMM') == (daterange?.enddate | date:'MMM') ? '' :
                                    (daterange?.startdate | date:'MMM') + '-'}}{{daterange?.enddate | date:'MMM y'}}
                                </h4>
                                <h5>Comorbidity:
                                    {{profileData?.conditions ? profileData?.conditions.join() : 'None Selected'}}</h5>
                                <p class="text-capitalize">{{profileData?.name}}, {{profileData?.gender}},

                                    {{userAge}}
                                </p>
                                <p>Program Start Date: {{profileData?.therapy_start_date | date:'MMM d, y'}}</p>
                                <p>Doctor Name: {{profileData?.doctor_name}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="logo-section pull-right">
                                <img src="assets/img/doctor/report-wellthy-d.png"
                                    [class]="clientData?.logo_media ? 'img-responsive mr-15' : 'img-responsive mr0'"
                                    alt="" />
                                <img *ngIf="clientData?.logo_media"
                                    [class]="clientData?.logo_media ? 'image-data img-responsive mr0' : 'image-data img-responsive'"
                                    [src]="s3url + clientData?.logo_media" crossorigin="*" />
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12 p-0">
                        <hr style="margin-top: 10px;">
                    </div>
                    <div class="row lifestyle">
                        <div class="col-md-12">
                            <div class="title-holder">
                                <h3 class="title mt0 mbo pb10">lifestyle <span>Based on {{datediff}} days</span></h3>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h4 style="width: 40%;">Meal target</h4>
                                    <h4 style="width: 60%;">Meal consumed</h4>
                                </div>

                                <div class="table-holder">
                                    <table mat-table *ngIf="mealTableData" [dataSource]="mealTableData"
                                        class="col-md-12">

                                        <!-- Position Column -->
                                        <ng-container matColumnDef="items">
                                            <th mat-header-cell *matHeaderCellDef style="width: 40%;"> Items
                                            </th>
                                            <td mat-cell *matCellDef="let element"> <span class="boldFontWeight">
                                                    {{element?.item}}
                                                    {{'(' + element?.target + '%)'}} </span> </td>
                                        </ng-container>

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="breakfast">
                                            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Breakfast
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="tbitemval">
                                                    <!-- {{(element?.breakfast?.consumed)?.toFixed(2) + '%'}} -->
                                                    <!-- [class.boldFontWeight]="(element?.target > element?.breakfast?.consumed || 
                                                    element?.target < element?.breakfast?.consumed)" -->
                                                    {{(startWithZero(element?.breakfast?.consumed?.toFixed(2))) + '%'}}

                                                </span>
                                                <span class="labels">
                                                    <span class="label-inline"
                                                        *ngIf="element?.target > element?.breakfast?.consumed">
                                                        <img src="assets/img/doctor/uparrow.png" class="img-responsive"
                                                            alt="">
                                                    </span>

                                                    <span class="label-inline"
                                                        *ngIf="element?.target < element?.breakfast?.consumed">
                                                        <img src="assets/img/doctor/uparrow.png"
                                                            class="img-responsive img-decrease" alt="">
                                                    </span>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <!-- Weight Column -->
                                        <ng-container matColumnDef="lunch">
                                            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Lunch
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="tbitemval">
                                                    <!-- [class.boldFontWeight]="(element?.target > element?.lunch?.consumed || 
                                                    element?.target < element?.lunch?.consumed)" -->
                                                    <!-- {{(element?.lunch?.consumed)?.toFixed(2) + '%'}} -->
                                                    {{(startWithZero(element?.lunch?.consumed?.toFixed(2))) + '%'}}
                                                </span>
                                                <span class="labels">
                                                    <span class="label-inline"
                                                        *ngIf="element?.target > element?.lunch?.consumed">
                                                        <img src="assets/img/doctor/uparrow.png" class="img-responsive"
                                                            alt="">
                                                    </span>

                                                    <span class="label-inline"
                                                        *ngIf="element?.target < element?.lunch?.consumed">
                                                        <img src="assets/img/doctor/uparrow.png"
                                                            class="img-responsive img-decrease" alt="">
                                                    </span>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <!-- Symbol Column -->
                                        <ng-container matColumnDef="dinner">
                                            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Dinner
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="tbitemval">
                                                    <!-- [class.boldFontWeight]="(element?.target > element?.dinner?.consumed || 
                                                    element?.target < element?.dinner?.consumed)" -->
                                                    <!-- {{(element?.dinner?.consumed?.toFixed(2)) + '%'}} -->
                                                    {{(startWithZero(element?.dinner?.consumed?.toFixed(2))) + '%'}}
                                                </span>
                                                <span class="labels">
                                                    <span class="label-inline"
                                                        *ngIf="element?.target > element?.dinner?.consumed">
                                                        <img src="assets/img/doctor/uparrow.png" class="img-responsive"
                                                            alt="">
                                                    </span>

                                                    <span class="label-inline"
                                                        *ngIf="element?.target < element?.dinner?.consumed">
                                                        <img src="assets/img/doctor/uparrow.png"
                                                            class="img-responsive img-decrease" alt="">
                                                    </span>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>

                                    <p *ngIf="!mealTableData" class="nodata-placeholder">No Data
                                        Available
                                    </p>
                                    <div class="labels labels-border" *ngIf="mealTableData">

                                        <div class="label-inline">
                                            <img src="assets/img/doctor/uparrow.png" class="img-responsive" alt="">
                                            Increase
                                        </div>

                                        <div class="label-inline">
                                            <img src="assets/img/doctor/uparrow.png" class="img-responsive img-decrease"
                                                alt="">
                                            Decrease
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="meal-target fluid-intake">
                                <div class="title-holder">
                                    <h4>Fluid Intake</h4>
                                </div>
                                <div *ngIf="hideFluidGraph">
                                    <div id="fluidIntakeChart" style="width: 100%; height: 180px;"></div>
                                </div>
                                <p class="nodata-placeholder" *ngIf="!hideFluidGraph">No Data
                                    Available
                                </p>
                                <!-- <div *ngIf="hideFluidGraph" class="text-center labels">X: Date Y: ml.</div> -->
                            </div>
                        </div>

                    </div>
                    <div class="row lifestyle pt0">
                        <div class="col-md-6">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h4>Activity**</h4>
                                </div>
                                <div class="table-holder">
                                    <table mat-table *ngIf="ActivityData" [dataSource]="ActivityData" class="col-md-12">

                                        <!-- Position Column -->
                                        <ng-container matColumnDef="target">
                                            <th mat-header-cell *matHeaderCellDef> Target </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{'> ' + element?.target + ' mins/' + element?.key}}</td>
                                        </ng-container>

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="achieved">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{datediff > 60 ? datediff + ' days' : 'Achieved'}} </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="boldFontWeight">
                                                    {{element?.accomplished}}</span>
                                                <span class="normalFontWeight">
                                                    {{(element?.key == 'day' ? ('/' + element?.total ) : ('/' +
                                                    element?.total ) ) + element?.key + 's'}}
                                                    <!-- {{(element?.key == 'day' ?'/ 30 ' : '/ 04 ') + element?.key + 's'}} -->
                                                </span>

                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsActivity"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsActivity;">
                                        </tr>
                                    </table>
                                    <p *ngIf="!ActivityData" class="nodata-placeholder">No Data
                                        Available
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="meal-target fluid-intake">
                                <div class="title-holder">
                                    <h4>Weight & BMI</h4>
                                </div>
                                <div class="weigh-bmi" *ngIf="weightBmiData">
                                    <span class="boldFontWeight">
                                        {{weightBmiData?.current_weight}} kg
                                        ({{weightBmiData?.current_weight_created_at | date:'d MMM, y'}})
                                    </span>
                                    <span class="normalFontWeight">{{getweightvariation(weightBmiData?.current_weight,
                                        ( weightBmiData?.first_weight == null ? weightBmiData.weight_at_onboarding :
                                        weightBmiData.first_weight ))}}
                                        <span *ngIf="weightBmiData?.patient_onboarded_at">
                                            ({{weightBmiData?.patient_onboarded_at | date:'d MMM, y'}})
                                        </span>
                                    </span>

                                    <div class="bmichart">
                                        <div class="marker" [style]="getBMIPercentage(weightBmiData?.bmi)">
                                            <div class="tooltip1">{{weightBmiData?.bmi | number:'1.0-0'}} Kg/m2
                                            </div>
                                            <span class="tooltipsec"></span>
                                        </div>

                                        <div class="rangeset">
                                            <span *ngIf="weightBmiData?.bmi <= 18.5" style="margin-left:6%">Low</span>
                                            <span *ngIf="weightBmiData?.bmi > 18.5 && weightBmiData?.bmi <= 24.9"
                                                style="margin-left:32%">Normal</span>
                                            <span *ngIf="weightBmiData?.bmi > 24.9 && weightBmiData?.bmi > 29.5"
                                                style="margin-left:56%">High</span>
                                            <span *ngIf="weightBmiData?.bmi > 29.5"
                                                style="margin-left:16.6%">Obesity</span>
                                        </div>

                                        <ul class="liner">
                                            <li>0</li>
                                            <li>18.5</li>
                                            <li>24.9</li>
                                            <li>29.9</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="nodata-placeholder" *ngIf="!weightBmiData">No Data
                                    Available
                                </p>

                                <div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row lifestyle pt0">
                        <!-- Body temperature -->
                        <div class="col-md-6">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h4>Body Temperature</h4>
                                </div>
                                <div class="body-parameter">
                                    <span class="boldFontWeight">
                                        Current Temperature : <span
                                            class="normalFontWeight">{{bodyMeasurement?.current_temperature}}
                                            {{bodyMeasurement?.current_temperature_unit}}
                                            ({{bodyMeasurement?.current_temperature_created_at | date:'d MMM,
                                            y'}})</span><br>
                                        <!-- Onboarding Date :   <span  class="normalFontWeight">({{bodyMeasurement?.patient_onboarded_at | date:'d MMM, y'}})</span> -->

                                    </span>
                                    <div class="bmichart">
                                        <div class="marker"
                                            [style]="getbodyTempPercentage(bodyMeasurement?.current_temperature )">
                                            <div class="tooltip1">{{bodyMeasurement?.current_temperature |
                                                number:'1.0-1'}} {{bodyMeasurement?.current_temperature_unit}}
                                            </div>
                                            <span class="tooltipsec"></span>
                                        </div>

                                        <div class="rangeset">
                                            <span *ngIf="bodyMeasurement?.current_temperature <= 35.8"
                                                style="margin-left:6%">Low</span>
                                            <span
                                                *ngIf="bodyMeasurement?.current_temperature > 35.9 && bodyMeasurement?.current_temperature <= 37.0"
                                                style="margin-left:32%">Normal</span>
                                            <span
                                                *ngIf="bodyMeasurement?.current_temperature > 38.6 && bodyMeasurement?.current_temperature <= 39.5"
                                                style="margin-left:56%">High fever</span>

                                            <span *ngIf="bodyMeasurement?.current_temperature > 39.6"
                                                style="margin-left:80.6%">very high fever</span>
                                        </div>

                                        <ul class="liner" *ngIf="bodyMeasurement?.current_temperature_unit == '°C'">
                                            <li>35.8 °C</li>
                                            <li>37.0 °C</li>
                                            <li>39.5 °C</li>
                                            <li>45.5 °C</li>
                                            <li></li>
                                        </ul>
                                        <ul class="liner" *ngIf="bodyMeasurement?.current_temperature_unit == 'F'">
                                            <li>97.7 °F</li>
                                            <li>99.5 °F</li>
                                            <li>100.9 °F</li>
                                            <li>104.0 °F </li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="nodata-placeholder" *ngIf="!bodyMeasurement">No Data
                                    Available
                                </p>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h4>Hip Waist Ratio</h4>
                                </div>
                                <div class="body-parameter">
                                    <span class="boldFontWeight">
                                        Current Hip-Waist Ratio : <span
                                            class="normalFontWeight">{{bodyMeasurement?.current_hip_waist_ratio |
                                            number:'1.0-1'}} ({{bodyMeasurement?.current_hip_waist_ratio_created_at |
                                            date:'d MMM, y'}})</span><br>
                                    </span>
                                    <div class="bmichart">
                                        <div class="marker"
                                            [style]="getHipWaistPercentage(bodyMeasurement?.current_hip_waist_ratio,reportData.data.meta_data.gender )">
                                            <div class="tooltip1">{{bodyMeasurement?.current_hip_waist_ratio |
                                                number:'1.0-1'}} cm
                                            </div>
                                            <span class="tooltipsec"></span>
                                        </div>
                                        <!-- {{reportData.data.meta_data.gender === 'f'}}-{{reportData.data.meta_data.gender === 'm'}} -->
                                        <!-- Range set for women -->
                                        <div class="rangeset-hipwaist" *ngIf="reportData.data.meta_data.gender === 'f' && reportData.data.meta_data.gender !== 'm'">
                                            <span *ngIf="bodyMeasurement?.first_hip_waist_ratio <= 0.80"
                                                style="margin-left:6%">Low</span>
                                            <span
                                                *ngIf="bodyMeasurement?.first_hip_waist_ratio > 0.81 && bodyMeasurement?.current_temperature <= 0.85"
                                                style="margin-left:32%">moderate</span>
                                            <span *ngIf="bodyMeasurement?.first_hip_waist_ratio > 0.86"
                                                style="margin-left:56%">High </span>

                                        </div>
                                        <!-- Range set for Men -->
                                        <div class="rangeset-hipwaist" *ngIf="reportData.data.meta_data.gender === 'm' && reportData.data.meta_data.gender !== 'f'">
                                            <span *ngIf="bodyMeasurement?.first_hip_waist_ratio <= 0.95"
                                                style="margin-left:6%">Low</span>
                                            <span
                                                *ngIf="bodyMeasurement?.first_hip_waist_ratio > 0.96 && bodyMeasurement?.current_temperature <= 1.0"
                                                style="margin-left:32%">moderate</span>
                                            <span *ngIf="bodyMeasurement?.first_hip_waist_ratio > 1.0"
                                                style="margin-left:56%">High </span>

                                        </div>
                                        <ul class="liner" *ngIf="reportData.data.meta_data.gender === 'f'">
                                            <li>0.80</li>
                                            <li>0.85</li>
                                            <li>0.86</li>
                                            <li>1.0</li>
                                            <li></li>
                                        </ul>
                                        <ul class="liner" *ngIf="reportData.data.meta_data.gender === 'm'">
                                            <li>0.95</li>
                                            <li>1.0</li>
                                            <li>1.1</li>
                                            <li>2.0</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="nodata-placeholder" *ngIf="!bodyMeasurement">No Data
                                    Available
                                </p>
                                <div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row lifestyle clinical">
                        <div class="col-md-12">
                            <div class="title-holder">
                                <h3 class="title mt0 pb10">CLINICAL PARAMETERS<span>Based on {{datediff}} days</span>
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="hidePostMealGraph">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h4>Post Meal Blood Sugar</h4>
                                    <div class="labels text-center pull-right" style="margin-top:14px"
                                        *ngIf="hidePostMealGraph">
                                        <!-- <span class="pull-left mr-5">X: Date, Y: Blood Sugar (mg/dL) </span> -->
                                        <!-- <span class="pull-left mr-5">Items:</span> -->
                                        <!-- <div class="d-flex mr-5 pull-left">
                                            <span class="pull-left"><span class="circles"></span> Breakfast
                                                &nbsp;&nbsp;&nbsp;</span>
                                            <span class="pull-left"><span class="diamond"></span> Lunch
                                                &nbsp;&nbsp;&nbsp;</span>
                                            <span class="pull-left"><span class="square"></span> Dinner</span>
                                        </div> -->

                                    </div>
                                </div>
                                <div *ngIf="hidePostMealGraph">
                                    <div id="postMealSugar" style="width: 100%; height: 220px;"></div>
                                </div>
                                <p class="nodata-placeholder" *ngIf="!hidePostMealGraph">No Data
                                    Available
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="hideFastingGraph">
                            <div class="meal-target fluid-intake">
                                <div class="title-holder">
                                    <h4>Fasting Blood Sugar</h4>
                                </div>
                                <div class="chart-holder" style="width:100%; height: 220px;">
                                    <div *ngIf="hideFastingGraph">
                                        <div id="fastingBloodSugar" style="width: 100%; height: 220px;"></div>
                                    </div>
                                    <p class="nodata-placeholder" *ngIf="!hideFastingGraph">No Data Available


                                </div>
                                <!-- <div class="labels text-center" *ngIf="hideFastingGraph">
                                    <div class="d-flex mr-5">
                                        <span class="circles"></span> Fasting
                                    </div>
                                </div> -->
                                <!-- <div class="labels text-center" *ngIf="hideFastingGraph">
                                    X: Date, Y: Blood Sugar (mg/dL)
                                </div> -->
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-md-12 tncsection">
                    <div class="col-md-8">
                        <span>* For more info refer to Detailed Report - page 1,</span>
                        <span>** For more info refer to Detailed Report - Page 2</span>
                    </div>
                    <div class="col-md-4 alignright">
                        <span>Page 1/3</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="section2" class="wrapper">
            <div class="row">
                <div class="secondpagedefaultheight">
                    <div class="lifestyle center-row pt0" *ngIf="hideBPGraph">
                        <div class="col-md-12" *ngIf="hideBPGraph">
                            <div class="meal-target fluid-intake">
                                <div class="title-holder">
                                    <h4 class="text-capitalize">Blood Pressure</h4>
                                    <div class="labels text-center pull-right" style="margin-top:14px"
                                        *ngIf="hideBPGraph">
                                        <!-- <span>X: Date, Y: Value,</span>
                                        <span>Items:</span> -->
                                        <span class="pull-left">
                                            <div class="triangle shape"></div>Heart Rate (BPM)&nbsp;&nbsp;&nbsp;
                                        </span>
                                        <span class="pull-left">
                                            <div class="height"></div>Systolic-Diastolic (mmHg)
                                        </span>
                                    </div>
                                </div>
                                <div class="chart-holder" style="width:100%; height: 210px;">
                                    <div *ngIf="hideBPGraph">
                                        <div id="bloodPressure" style="width:100%; height: 210px;"></div>
                                    </div>
                                    <p class="nodata-placeholder" *ngIf="!hideBPGraph">No Data Available
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                    <div class="lifestyle center-row" *ngIf="reportData?.show_hypo_events">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">HYPOGLYCEMIC EVENTS <span>Based on {{datediff}} days</span>
                                    </h3>
                                </div>
                                <div class="table-holder">
                                    <table mat-table [dataSource]="dataSource1" class="col-md-12">

                                        <ng-container matColumnDef="dateTime">
                                            <th mat-header-cell *matHeaderCellDef style="width: 34%;"> Date
                                                & Time </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{(element?.log_date) | date:'MMM, y'}}
                                                {{convertIsoDatetime(element?.log_date)}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="value">
                                            <th mat-header-cell *matHeaderCellDef style="width: 33%;"> Value (mg/dL)
                                            </th>
                                            <td mat-cell *matCellDef="let element"> {{element?.value}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="recheckedValue">
                                            <th mat-header-cell *matHeaderCellDef style="width: 33%;">
                                                Rechecked Value (mg/dL)</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element?.rechecked_value}}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                    </table>
                                    <div class="labels labels-border">
                                        Rechecked Value is taken after 15 to 30 mins of first event
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lifestyle center-row mt0 pt0">
                        <div class="col-md-12">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h3 class="title pb10"> SYMPTOMS OCCURED <span>Based on {{datediff}} days</span>
                                    </h3>
                                </div>
                                <div class="table-holder">
                                    <table *ngIf="symptomsOccuredData" mat-table [dataSource]="symptomsOccuredData"
                                        class="col-md-12">
                                        <ng-container matColumnDef="mostFrequentEvents">
                                            <th mat-header-cell *matHeaderCellDef> Most frequent events
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="normalFontWeight">
                                                    {{element?.frequent_symptom_title}}
                                                </span>
                                                <!-- {{element.mostFrequentEvents.substr(0, element.mostFrequentEvents.indexOf('('))}}</span> -->
                                                <span class="boldFontWeight">
                                                    &nbsp; &nbsp; &nbsp; {{' x ' + element?.frequent_occurance + ''}}
                                                    <!-- {{element.mostFrequentEvents.substr(element.mostFrequentEvents.indexOf('('))}} -->
                                                </span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="mostSevereEvents">
                                            <th mat-header-cell *matHeaderCellDef> Most severe events </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span *ngIf="element?.severe_symptom_title">
                                                    {{element?.severe_symptom_title}}
                                                </span>
                                                <span *ngIf="!element?.severe_symptom_title">
                                                    -
                                                </span>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                                    </table>


                                    <p class="nodata-placeholder" *ngIf="!symptomsOccuredData">No Data
                                        Available
                                    </p>
                                    <div class="labels labels-border" *ngIf="symptomsOccuredData">
                                        Listed in the order of frequency & severity highest to lowest
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lifestyle center-row pt0">
                        <div class="col-md-12">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h3 class="title mt0 pb10">HC NOTE</h3>
                                </div>
                                <p class="hc-notep" *ngFor="let note of hcNote; let i=index;">
                                    <span *ngIf="hcNote?.length == i+1">{{note.content}}</span>
                                </p>
                                <p class="nodata-placeholder" *ngIf="hcNote == ''">
                                    No Data Available
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="lifestyle center-row pt0" *ngIf="reportData?.show_symptom_tracking">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">SYMPTOM TRACKING QUESTIONS <span>Based on {{datediff}}
                                            days</span></h3>
                                </div>
                                <div class="chart-holder" style="width:100%; min-height: 150px;">
                                    <div *ngIf="hideSymptomGraph">
                                        <div id="symptomTracking" style="width: 100%; height: 240px;"></div>
                                    </div>
                                    <p class="nodata-placeholder" *ngIf="!hideSymptomGraph">No Data
                                        Available
                                    </p>
                                </div>
                                <div class="labels text-center" *ngIf="hideSymptomGraph">
                                    <span>X: Date, Y: Symptom Score </span>
                                    <span>(0-06: Under Control, 7-12: Worsening,13-18: Worst,19-24: Call
                                        Doctor)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="col-md-12 tncsection2">
                    <div class="col-md-8">
                        <span>* For more info refer to Detailed Report - page 1,</span>
                        <span>** For more info refer to Detailed Report - Page 2</span>
                    </div>
                    <div class="col-md-4 alignright">
                        <span>Page 2/3</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="section3" class="wrapper">
            <div class="row">
                <div class="secondpagedefaultheight">
                    <div class="lifestyle center-row">
                      
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">LAB REPORTS </h3>
                                </div>
                                <div class="table-holder">
                                    <table mat-table [dataSource]="labReportTableData" class="col-md-12">
                                        <ng-container matColumnDef="tests">
                                            <th mat-header-cell *matHeaderCellDef style="width: 35%;"> Tests
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">{{element?.lab_report_title
                                                    + '(' + element?.unit + ')'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="value1">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Value
                                                1 </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span *ngIf="element?.value2?.measure"
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">
                                                    {{element?.value2?.measure}}
                                                    <span *ngIf="element?.log_date">
                                                        {{'(' + (element?.value2?.log_date | date: 'dd/MM/yy') + ')'}}
                                                    </span>
                                                </span>
                                                <span *ngIf="!element?.value2?.measure">
                                                    -
                                                </span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="value2">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Value
                                                2 </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span *ngIf="element?.value1?.measure"
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">
                                                    {{element?.value1?.measure}}
                                                    <span *ngIf="element?.log_date">
                                                        {{'(' + (element?.value1?.log_date | date: 'dd/MM/yy') + ')'}}
                                                    </span>
                                                </span>
                                                <span *ngIf="!element?.value1?.measure">
                                                    -
                                                </span>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="v1v2">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> V1-V2
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <p
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData display-flex nomargin' : 'nomargin'">
                                                    <span class="v1v2values tbitemval1">{{(element?.value1?.measure -
                                                        element?.value2?.measure) < 0 ? (element?.value1?.measure -
                                                            element?.value2?.measure) *-1 :(element?.value1?.measure -
                                                            element?.value2?.measure)}}</span>
                                                            <span style="position: relative;top: 4px; "
                                                                *ngIf="(element?.value1?.measure && element?.value2?.measure && ((element?.value1?.measure - element?.value2?.measure) > 0)) ">
                                                                <img style="width: 11px;"
                                                                    src="assets/img/doctor/uparrow.png"
                                                                    class="img-responsive" alt=""> </span>
                                                            <span style="position: relative;top: 4px;"
                                                                *ngIf="(element?.value1?.measure && element?.value2?.measure && ((element?.value1?.measure - element?.value2?.measure) < 0)) ">
                                                                <img style="width: 11px;transform: rotate(180deg);"
                                                                    src="assets/img/doctor/uparrow.png"
                                                                    class="img-responsive" alt=""> </span>
                                                </p>
                                                <!-- <span class="labels"
                                                        *ngIf="element?.value1?.measure - element?.value2?.measure < 0">
                                                        <span class="label-inline">
                                                            <img src="assets/img/doctor/uparrow.png"
                                                                class="img-responsive" alt="">
                                                        </span>
                                                        <span class="label-inline"
                                                            *ngIf="element?.value1?.measure - element?.value2?.measure > 0">
                                                            <img src="assets/img/doctor/uparrow.png"
                                                                class="img-responsive img-decrease" alt="">
                                                        </span>
                                                    </span> -->
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="normalRange">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;">
                                                Normal Range </th>
                                            <td mat-cell *matCellDef="let element"> <span
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">
                                                    {{element?.range}}</span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                                    </table>
                                    <div class="labels labels-border pull-left">
                                        <div class="label-inline">
                                            <img src="assets/img/doctor/uparrow.png" class="img-responsive" alt="">
                                            Increase
                                        </div>
                                        <div class="label-inline">
                                            <img src="assets/img/doctor/uparrow.png" class="img-responsive img-decrease"
                                                alt="">
                                            Decrease
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 tncsection2">
                    <div class="col-md-8">
                        <span>* For more info refer to Detailed Report - page 1,</span>
                        <span>** For more info refer to Detailed Report - Page 2</span>
                    </div>
                    <div class="col-md-4 alignright">
                        <span>Page 3/3</span>
                    </div>
                </div>
            </div>
        </div>
    </div>



</div>