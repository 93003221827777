<mat-form-field appearance="outline" class="option-selection">
  <mat-label>Select an option</mat-label>
  <mat-select [(value)]="selected" (selectionChange)="onSelectChange($event)" class="notranslate">
    <!-- <mat-option value="medicine">Medicine</mat-option> -->
    <mat-option value="prescription">Prescription</mat-option>
    <mat-option value="reminder" *ngIf="enableReminder == true">Reminder</mat-option>
    <!--*ngIf="userClientDetails != 'Ferrer'"-->
    <mat-option value="symptom">Symptoms</mat-option>
    <mat-option value="trigger" *ngIf="userDataQ?.show_triggers">Triggers</mat-option>
    <mat-option value="questionnaire" *ngIf="userDataQ?.show_questionnaire">Questionnaire</mat-option>
    <mat-option value="caregiver">Caregiver</mat-option>
    <mat-option value="doctor">Doctor</mat-option>
    <mat-option value="calllog" *ngIf="userClientDetails != 'Ferrer'">Call Logs</mat-option>
    <mat-option value="transactions" *ngIf="userClientDetails != 'Ferrer'">Past Transactions</mat-option>
    <mat-option value="pump" *ngIf="userClientDetails == 'Ferrer'">Pump</mat-option>
    <mat-option value="pumphistory" *ngIf="userClientDetails == 'Ferrer'">Pump History</mat-option>
    <mat-option value="medication" *ngIf="userClientDetails == 'Ferrer'">Medication</mat-option>
    <mat-option value="medicalhistory" *ngIf="userClientDetails == 'Ferrer'">Medical History</mat-option>
    <mat-option value="hospitalisation" *ngIf="userClientDetails == 'Ferrer'">Hospitalisation</mat-option>
    <!-- <mat-option value="roa">Route of Administration</mat-option> -->
    <mat-option value="adr" *ngIf="is_remodulin">Adverse Reaction | Remodulin</mat-option>
    <!-- <mat-option value="nonadr">Adverse Reaction | Non Remodulin</mat-option> -->
    <mat-option value="medicationSurvey">Medication Survey</mat-option>
    <mat-option value="doctorVisit" *ngIf="enableDoctorVist == true">Doctor Visit</mat-option>
  </mat-select>
</mat-form-field>


<div class="prescriptiondetail matcardbottomzero">
  <div *ngIf="selected == 'prescription'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Prescriptions</h2>
      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="openPrescriptionDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div *ngIf="presData.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>

    <!-- <h2 class="heading-text mt-15" *ngIf="presData.length != 0">Prescriptions</h2> -->
    <div class="prescription" [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (pres.date | date:'short')}" *ngFor="let pres of presData">


      <!--[ngClass]="{ 'blink-bg': (notificationDate_Time | date:'short') == (item.created_at | date:'short')}"-->
      <div *ngIf="pres.name" class="prescriptionname">
        {{pres.name}}</div>
     {{pres.date | date:'short'}}
      <div *ngIf="pres.type" class="prescriptiondate">
        {{convertIsoData(pres.date)}}</div>
      <img *ngIf="pres.type == 'image'" [src]="s3url + pres.path" (click)="openDialogImg(s3url + pres.path,'image')" />
      <a *ngIf="pres.type == 'pdf'" [href]="s3url + pres.path" target="_blank"> <img [src]="documentPlaceHolder"
          class="imageboxcss" /> </a>
      <!-- <a *ngIf="pres.type == 'pdf'" [href]="s3url + pres.path" target="_blank"> <i class="material-icons"> description
        </i> </a> -->
      <!-- <a (click)="deletePrescription(pres.id)"> <i class="material-icons"> delete
        </i> Delete
      </a>  -->
      <button class="last-btn no-hover-effect" mat-button disableRipple (click)="deletePrescription(pres.id)">
        Delete</button>

    </div>
  </div>

  <div *ngIf="selected == 'medicine'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Medicine Details</h2>
      <div class="pull-right">
        <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="openLabDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>


    <div *ngIf="medicineData.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>

    <div class="col-md-12" *ngFor="let med of medicineData">
      <div class="row dashed-border-bottom" *ngIf="med">
        <p class="med-data"> {{medData(med.start_date_time[0])}} {{convertIsoDatetime(med.start_date_time[0])}}</p>
        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Name</mat-card-title>
              <mat-card-subtitle class="camelcased">{{med.name}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Medicine Type</mat-card-title>
              <mat-card-subtitle class="camelcased">{{med.medicine_type}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left" *ngIf="med.medicine_type == 'tablet' || typeFilters == 'capsule'">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Medicine Shape</mat-card-title>
              <mat-card-subtitle class="camelcased">{{med.medicine_shape}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left"
          *ngIf="!(med.medicine_type == 'tablet' || med.medicine_type == 'capsule' || med.medicine_type == 'insulin_pen' || med.medicine_type == 'ointment')">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Volume(ml)</mat-card-title>
              <mat-card-subtitle>{{med.volume}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left" *ngIf="med.medicine_type == 'capsule' || med.medicine_type == 'tablet'">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Dosage(M/A/E)</mat-card-title>
              <mat-card-subtitle>{{dosage(med.dosage)}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left" *ngIf="med.medicine_type == 'capsule' || med.medicine_type == 'tablet'">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Pre Meal</mat-card-title>
              <mat-card-subtitle>{{preMeal(med.pre_meal)}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left" *ngIf="med.duration != 0">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Duration</mat-card-title>
              <mat-card-subtitle>{{duration(med.duration)}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>


        <div class="col-md-4 no-padding-left"
          *ngIf="med.medicine_type == 'Insulin_pen' || med.medicine_type == 'injectable' || med.medicine_type == 'bai' || med.medicine_type == 'nasal_spray' || med.medicine_type == 'nasal_spray' || med.medicine_type == 'dpi_rotahaler' || med.medicine_type == 'nebulizer'">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title *ngIf="med.medicine_type == 'insulin_pen' || med.medicine_type == 'injectable' ">Number of
                Shots</mat-card-title>
              <mat-card-title *ngIf="med.medicine_type == 'mdi'|| med.medicine_type == 'bai'">Number of Puffs
              </mat-card-title>
              <mat-card-title *ngIf="med.medicine_type == 'dpi'|| med.medicine_type == 'dpi_rotahaler'">Number of
                Capsules</mat-card-title>
              <mat-card-title *ngIf="med.medicine_type == 'dpi_multihaler'">Number of DOSES</mat-card-title>
              <mat-card-title *ngIf="med.medicine_type == 'nasal_spray'">Number of spray</mat-card-title>

              <mat-card-subtitle>{{med.num_shots}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left"
          *ngIf="!(med.medicine_type == 'syrup' || med.medicine_type == 'ointment' || med.medicine_type == 'injectable'|| med.medicine_type == 'bai' || med.medicine_type == 'nasal_spray' || med.medicine_type == 'nasal_spray' || med.medicine_type == 'dpi_rotahaler' || med.medicine_type == 'nebulizer')">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Number of Units</mat-card-title>

              <mat-card-subtitle>{{med.num_units}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card medic-card">
            <mat-card-header>
              <mat-card-title>Color</mat-card-title>
              <mat-card-subtitle>
                <div class="med-color" [style.background-color]="conversion(med.color)">

                </div>
                <!-- {{conversion(med.color)}} -->
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>


      </div>

    </div>

  </div>

  <!-- <div *ngIf="selected == 'reminder'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Reminder</h2>

      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="openReminderDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div *ngIf="allreminder?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div class="col-md-12" *ngFor="let reminder of allreminder">
      <div class="row">
        <p class="med-data"> {{convertIsoData(reminder.created_at)}}
          {{convertIsoDatetime(reminder.created_at)}}</p>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Name</mat-card-title>
              <mat-card-subtitle>{{reminder.name}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Category</mat-card-title>
              <mat-card-subtitle class="camelcased">{{reminder.type}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Schedule for no. of days</mat-card-title>
              <mat-card-subtitle><span *ngFor="let day of reminder.days" class="days">{{convertdate(day)}}</span>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left" *ngIf="reminder.meal_type">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Meal Type</mat-card-title>
              <mat-card-subtitle class="camelcased">{{reminder.meal_type}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
      </div>
    </div>

  </div> -->

  <div *ngIf="selected == 'reminder'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Reminder</h2>

      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="openReminderDialog(true)">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div *ngIf="allreminder?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div class="col-md-12" *ngFor="let reminder of reminderData">
      <div class="row" *ngIf="reminder.type !== 'doctorvisit'">
        <p class="med-data"
          [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (reminder.created_at | date:'short')}">
          {{convertIsoData(reminder.created_at)}}
          {{convertIsoDatetime(reminder.created_at)}}</p>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Name</mat-card-title>
              <mat-card-subtitle>{{reminder.name}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Category</mat-card-title>
              <mat-card-subtitle class="camelcased">{{reminder.type}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Schedule for no. of days</mat-card-title>
              <mat-card-subtitle><span *ngFor="let day of reminder.days" class="days">{{convertdate(day)}}</span>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left" *ngIf="reminder.meal_type">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Meal Type</mat-card-title>
              <mat-card-subtitle class="camelcased">{{reminder.meal_type}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="selected == 'symptom'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Symptoms</h2>

      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="openSymptomDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <br />
    <div *ngIf="allsymptoms?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div class="col-md-12" *ngFor="let symptom of allsymptoms">
      <div class="row">
        <div class="col-md-12 no-padding-left border-grey" *ngIf="symptom?.symptom_ids?.length > 0"
          [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (symptom.created_at | date:'short')}">
          <div class="col-md-12">
            <div class="row">
              <p>{{symptom.log_date | date:'short'}}</p>
            </div>
          </div>
          <!-- <div class="col-md-6 zeropadleft">
            <p class="med-data-loc"> Longitude : {{symptom.longitude | number : '1.2-2'}}</p>
          </div>
          <div class="col-md-6 zeropadright">
            <p class="med-data-loc"> Lattitude : {{symptom.latitude | number : '1.2-2'}}</p>
          </div> -->
          <div class="clearfix"><br /></div>
          <mat-card class="userdetail-card triggerlogs">
            <mat-card-header>
              <mat-card-title>Symptom Logged</mat-card-title>
              <mat-card-subtitle *ngFor="let log of symptom?.symptom_ids">
                <p style="text-transform: capitalize;"><b>{{log.value}} </b>| {{log.title}}</p>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
      </div>
    </div>
    <button mat-flat-button (click)="goNextPage('symptom')" *ngIf="showmore && (allsymptoms.length > 0)">More</button>
  </div>

  <div *ngIf="selected == 'trigger'">
    <div class="clearfix">
      <button mat-flat-button class="plusrectangletransparent pull-right col-md-6"
        style="margin-bottom: 10px;padding-right: 90px;" (click)="openTriggers()">
        All Triggers
      </button>
    </div>

    <div *ngIf="alltriggers?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div class="col-md-12" *ngFor="let trigger of alltriggers">
      <div class="row">
        <div class="col-md-12 no-padding-left border-grey" *ngIf="trigger?.trigger_logs?.length > 0"
          [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (trigger.log_date | date:'short')}">
          <div class="col-md-12">
            <div class="row">
              <p>{{trigger.log_date | date:'short'}}</p>
            </div>
          </div>
          <div class="col-md-6 zeropadleft">
            <p class="med-data-loc"> Longitude : {{trigger.longitude | number : '1.2-2'}}</p>
          </div>
          <div class="col-md-6 zeropadright">
            <p class="med-data-loc"> Lattitude : {{trigger.latitude | number : '1.2-2'}}</p>
          </div>
          <div class="clearfix"><br /></div>
          <mat-card class="userdetail-card triggerlogs">
            <mat-card-header>
              <mat-card-title>Triggers Logged</mat-card-title>
              <mat-card-subtitle *ngFor="let log of trigger?.trigger_logs"><img
                  src="{{s3url + log?.image_media?.thumbnail_path}}" />
                <p>{{log.title}}</p>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
      </div>
    </div>
    <button mat-flat-button (click)="goNextPage('trigger')" *ngIf="showmore">More</button>
  </div>

  <div *ngIf="selected == 'questionnaire'">
    <!-- <h2 class="heading-text">Reminder</h2> -->
    <div *ngIf="allQuestionnaire?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div class="col-md-12" *ngFor="let questionnaire of allQuestionnaire">
      <div class="row">
        <div class="col-md-12 no-padding-left border-grey" *ngIf="questionnaire?.response?.length > 0"
          [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (questionnaire.created_at | date:'short')}">
          <div class="col-md-12">
            <div class="row">
              <p>{{questionnaire.created_at | date:'short'}}</p>
            </div>
          </div>
          <div class="col-md-6 zeropadleft">
            <p class="med-data-loc"> {{questionnaire.type}}</p>
          </div>
          <div class="col-md-6 zeropadright">
            <p class="med-data-loc"> Score : {{questionnaire.overall_score | number : '1.2-2'}}</p>
          </div>
          <div class="col-md-12 zeropadleft zeropadright topbottomspacing">
            {{questionnaire?.feedback?.length == 0 ? 'No Feedback Found' : questionnaire.feedback[0]}}
          </div>
          <div class="clearfix"><br /></div>
          <mat-card class="userdetail-card triggerlogs">
            <mat-card-header>
              <mat-card-title>Responses</mat-card-title>
              <mat-card-subtitle *ngFor="let log of questionnaire?.response">
                <p>{{log.question}}</p>
                <div class="clearfix"><br /></div>
                <div *ngIf="!(isArray(log.selection))">
                  <p class="optionquescss">{{log?.selection?.text}}</p>
                </div>
                <div *ngIf="isArray(log.selection)">
                  <p class="optionquescss" *ngFor="let ans of log.selection">{{ans?.text}}</p>
                </div>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
      </div>
    </div>
    <button mat-flat-button (click)="goNextPage('questionnaire')" *ngIf="showmore">More</button>
  </div>

  <div *ngIf="selected == 'caregiver'">
    <mat-card class="caregivercardcss" *ngIf="caregiverData === undefined  && !showcaregiverHidtory">
      <mat-card-title class="card-title" *ngIf="caregiver === false">No Caregiver details available</mat-card-title>
      <mat-card-subtitle class="card-text" *ngIf="caregiver === false ">Provide details to add caregiver on behalf of
        user</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="caregiverForm" (ngSubmit)="careObject()">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Caregiver Name</mat-label>
            <input matInput placeholder="Caregiver Name" minlength="1" maxlength="729" formControlName="name" required>
          </mat-form-field>
          <mat-form-field style="width: 26%;margin-right:4%;" appearance="outline">
            <!-- <mat-select placeholder="STD" formControlName="country_code">
              <mat-option value="91">+91</mat-option>
            </mat-select> -->
            <mat-label>STD</mat-label>
            <input type="number" matInput placeholder="STD" minlength="1" maxlength="5" formControlName="country_code"
              min="0" oninput="validity.valid||(value='');" required>
          </mat-form-field>
          <mat-form-field style="width: 70%;" appearance="outline">
            <mat-label>Phone Number </mat-label>
            <input type="number" matInput placeholder="Phone Number" formControlName="phone" [pattern]="'^[0-9]+$'"
              minlength="10" maxlength="10" required>
          </mat-form-field>
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Email</mat-label>

            <input matInput placeholder="Email" formControlName="email" type="email" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Select Gender</mat-label>

            <mat-select placeholder="Select Gender" formControlName="gender" required>
              <mat-option value="m">Male</mat-option>
              <mat-option value="f">Female</mat-option>
              <mat-option value="o">Other</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Select Relationship</mat-label>
            <input matInput placeholder="Relationship Name" minlength="1" maxlength="729" formControlName="relation" required>

             <mat-select placeholder="Select Relationship" formControlName="relation" required>
              <mat-option *ngFor="let relation of typeOfRelation" [value]="relation">
                {{relation}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Select Relationship</mat-label>
            <input matInput placeholder="Relationship Name" minlength="1" maxlength="729" formControlName="relation" required>
          </mat-form-field>



          <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn"
            *ngIf="caregiverButtonDisplay===false" [disabled]="!caregiverForm.valid && caregiverButtonDisplay===false">Add Caregiver</button>
          <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn"
            *ngIf="caregiverButtonDisplay===true" [disabled]="!caregiverForm.valid && caregiverButtonDisplay===true">Update Caregiver</button>
          <button  style="margin-left: 5px;" type="button" mat-flat-button color="primary"   *ngIf="caregiverButtonDisplay===true"
            class="labreportbtn" (click)="closeForm();"  >Cancel</button>
        </form>
      </mat-card-content>
    </mat-card>
    <div *ngIf="caregiverData != undefined &&  caregiver"
      style="background: #f8f8f8;padding-left: 10px;padding-right: 0px;" class="col-md-12">
      <div class="col-md-6" style="margin-top: 10px;padding-left: 0px;">
        <span>
          Caregiver Details
        </span>
      </div>
      <div class="col-md-6" style="display: flex; justify-content: flex-end;padding-right: 0px;">
        <button tooltip="Edit Caregiver" style="color: black;margin-right: 5px;border: 1px solid #ff7a32;"
          (click)="EditCareGiver(caregiverData)">
          <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy">
        </button>
  
        <button   style="color: black;border: 1px solid #ff7a32;"  tooltip="Caregiver history" (click)="caregiverHistory()"
        *ngIf="caregiverHistoryData.length!=0 && showHistory">
          <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy">
        </button>

        <button style="color: black;border: 1px solid #ff7a32;"
          matTooltip="This patient has no caregiver change history" style="opacity:0.5;cursor: not-allowed;"
          *ngIf="caregiverHistoryData.length==0 && showHistory">
          <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy" disabled>
        </button>

      </div>

    </div>



    <div *ngIf="caregiverData != undefined &&  caregiver && !showcaregiverHidtory">
      <div class="col-md-6 no-padding-left">
        <mat-card *ngIf="caregiverData?.name">
          <mat-card-title class="card-title">Caregiver</mat-card-title>
          <mat-card-subtitle class="card-text camelcased">{{caregiverData.name ? caregiverData.name : 'No Data'}}
          </mat-card-subtitle>
        </mat-card>
      </div>

      <div class="col-md-6 no-padding-left">
        <mat-card *ngIf="caregiverData?.name">
          <mat-card-title class="card-title">Caregiver Phone Number</mat-card-title>
          <mat-card-subtitle class="card-text camelcased">+{{caregiverData.country_code ? caregiverData.country_code :
            'No Data'}}-{{caregiverData.phone ? caregiverData.phone : 'No Data'}}
          </mat-card-subtitle>
        </mat-card>
      </div>
      <div class="col-md-6 no-padding-left">
        <mat-card *ngIf="caregiverData?.relation">
          <mat-card-title class="card-title">relation</mat-card-title>
          <mat-card-subtitle class="card-text camelcased">
            {{caregiverData.relation ? caregiverData.relation : 'No Data'}}</mat-card-subtitle>
        </mat-card>
      </div>
      <div class="col-md-6 no-padding-left">
        <mat-card *ngIf="caregiverData?.gender">
          <mat-card-title class="card-title">gender</mat-card-title>
          <mat-card-subtitle class="card-text camelcased">
            {{caregiverData.gender ? gender(caregiverData.gender) : 'No Data'}}</mat-card-subtitle>
        </mat-card>
      </div>
      <div class="col-md-12 no-padding-left">
        <mat-card *ngIf="caregiverData?.email">
          <mat-card-title class="card-title">E-Mail</mat-card-title>
          <mat-card-subtitle class="card-text camelcased text-lowercase">
            {{caregiverData.email ? caregiverData.email : 'No Data'}}
          </mat-card-subtitle>
        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="selected == 'doctor'">

    <mat-card class="caregivercardcss" *ngIf="!doctorData?.code">
      <mat-card-title *ngIf="!updateDocData" class="card-title">No Doctor details available</mat-card-title>
      <mat-card-title *ngIf="updateDocData" class="card-title">Update Doctor details</mat-card-title>
      <mat-card-subtitle class="card-text">Provide Doctor Code to add Doctor on behalf of user</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="doctorForm" validate>
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Select Doctor</mat-label>
            <mat-select [(value)]="selectedDoc" formControlName="doctorCode" (selectionChange)="onSelectDoctorChange($event)" class="notranslate">
              <mat-option *ngFor="let doc of doclist" value="{{doc.code}}">{{doc.name}} (<b>{{doc.code}}</b>)</mat-option>
              </mat-select>
          </mat-form-field>

          <!-- <input matInput placeholder="Relationship" minlength="1" maxlength="729" formControlName="relationship"> -->
          <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn"
            [disabled]="!doctorForm.valid" (click)="docObject(updateDocData)">
            <span class="btn-pos" *ngIf="!updateDocData">Add </span>
            <span class="btn-pos" *ngIf="updateDocData">Update </span>
            Doctor</button>
        </form>
      </mat-card-content>
    </mat-card>
    <div class="" *ngIf="doctorData !=''">
      <mat-card class="card card-wrapper" *ngIf="doctorData?.name">
        <div class="card-block card-outer">
          <mat-card-title class="card-title">Doctor Name</mat-card-title>
          <mat-card-subtitle class="card-text camelcased">{{doctorData.name | titlecase}}</mat-card-subtitle>
        </div>
      </mat-card>
      <mat-card class="card card-wrapper" *ngIf="doctorData?.code">
        <div class="card-block card-outer">
          <mat-card-title class="card-title">Doctor Code</mat-card-title>
          <mat-card-subtitle class="card-text" style="text-transform: uppercase">{{doctorData.code }}
          </mat-card-subtitle>
        </div>
      </mat-card>
      <mat-card class="card card-wrapper" *ngIf="doctorData?.doctor_type">
        <div class="card-block card-outer">
          <mat-card-title class="card-title">Doctor Type</mat-card-title>
          <mat-card-subtitle class="card-text camelcased">{{ doctorData.doctor_type }}</mat-card-subtitle>
        </div>
      </mat-card>
      <mat-card class="card card-wrapper" *ngIf="doctorData?.hospital_data">
        <div class="card-block card-outer">
          <mat-card-title class="card-title">Hospital Name</mat-card-title>
          <mat-card-subtitle class="card-text camelcased" *ngFor="let hosp of doctorData?.hospital_data">{{ hosp.name }} - {{ hosp.city }}</mat-card-subtitle>
        </div>
      </mat-card>
      <button mat-button type="submit" *ngIf="doctorData?.name" mat-flat-button color="primary" class="labreportbtn"
        (click)="updateDoc()">Update Doctor</button>

    </div>
    <div class="clearfix"><br /><br /></div>


  </div>
  <div *ngIf="selected == 'calllog'">

    <div class="chartcontainer">
      <div class="chartheader" style="padding-bottom: 18px;width:100%;">
        <form [formGroup]="callogForm" validate class="filter-form">
          <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-5 p-0">
            <mat-label>Start date</mat-label>
            <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="morrow"
              placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-5"
            style="padding-right:0;">
            <mat-label>End date</mat-label>
            <input matInput formControlName="enddate" [min]="callogForm.get('startdate')?.value"
              [matDatepicker]="picker2" [max]="morrow" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <button mat-icon-button class="plusrectangletransparent pull-right col-md-6"
            style="margin-top: 4px;width: 48px;height: 48px;" (click)="applylogfilter()">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>
      <div class="wrap" *ngIf="calldata && calldata?.length != 0">
        <table class="head">
          <tr>
            <th>Called at</th>
            <th>Recording</th>
          </tr>
        </table>
        <div class="inner_table">
          <table>
            <tr *ngFor="let ab of calldata; let i = index;">
              <td><span style="display:inline-block;float:left;width:20px;font-weight: bold;">{{i+1}}
                </span>{{convertDate(ab.start_time)}}</td>
              <td><audio [src]="ab.call_recording" style="width:250px;" controls
                  controlsList="nodownload noremoteplayback" disablepictureinpicture></audio></td>
          </table>
        </div>
      </div>
      <div *ngIf="!(calldata && calldata?.length != 0)" class="nodatacalllog">
        No Data
      </div>
    </div>
  </div>


  <div *ngIf="selected == 'transactions'">
    <div class="chartcontainer">
      <div class="chartheader" style="padding-bottom: 18px;width:100%;">
        <form [formGroup]="transactionslogForm" validate class="filter-form">
          <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-5" style="padding:0;">
            <mat-label>Choose start date</mat-label>
            <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="morrow"
              placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-5"
            style="padding-right:0;">
            <mat-label>Choose end date</mat-label>
            <input matInput formControlName="enddate" [min]="transactionslogForm.get('startdate')?.value"
              [matDatepicker]="picker2" [max]="morrow" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <button mat-icon-button class="plusrectangletransparent pull-right col-md-6"
            style="margin-top: 4px;width: 48px;height: 48px;" (click)="getTransactions(1)">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>
      <div class="wrap" *ngIf="alltransactions && alltransactions?.length != 0">
        <table class="head">
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </table>
        <div class="inner_table">
          <table>
            <tr *ngFor="let ab of alltransactions; let i = index;">
              <td>{{ab.created_at | date}}</td>
              <td>{{ab.purchase_type}}</td>
              <td>{{ab.transaction_amount}} &nbsp; {{ab.transaction_currency}}</td>
          </table>
        </div>
      </div>
      <div *ngIf="!(alltransactions && alltransactions?.length != 0)" class="nodatacalllog">
        No Data
      </div>
    </div>
  </div>

  <div *ngIf="selected == 'pump'">

    <div class="clearfix">
      <h2 class="heading-text pull-left">All Pumps</h2>
      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addPump()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div style="margin-top: 15px;" class="container-table tags-table">
      <!-- *ngIf="pumpTableData.length" -->
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
        <ng-container matColumnDef="brand_name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" class="camelcased"
            [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
            {{element.title}}{{element.other_pump_name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="log_date">
          <th mat-header-cell *matHeaderCellDef> Added On </th>
          <td mat-cell *matCellDef="let element" class="camelcased"
            [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
            {{element.created_at | date: 'dd/MM/yyyy'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element" class="camelcased"
            [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
            <span *ngIf="element.pump_status === 1" class="activePump">● Current</span>
            <span *ngIf="element.pump_status === 2" class="reservePump">● Reserve</span>
            <span *ngIf="element.pump_status === 3" class="disabledPump">● Disabled</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element" class="camelcased"
            [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon style="color: #FB7A20;">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <!--   <button mat-button (click)="changeHealthCoach(element)" tooltip="Change healthcoach">
                    <img src="assets/img/dls/admin-table/Icon_Change Healthcoach.svg" alt="">
                  </button> -->
              <button (click)='editPump(element.id);$event.stopPropagation()' mat-menu-item>
                <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="">
                <span>&nbsp;Edit</span>
              </button>
              <button (click)="viewPump(element.id);$event.stopPropagation()" mat-menu-item>
                <img style="height:25px;" src="assets/img/dls/icons/actiontable/Icon_View_hover.svg" alt="">
                <span>&nbsp;View</span>
              </button>
              <button  (click)="deletePump(element.id,element.pump_status);$event.stopPropagation()" mat-menu-item>
                <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="">
                <span>&nbsp;Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="pumpColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: pumpColumns;" class="example-element-row">
        <!-- <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="pumpColumns.length"
            [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="menu-holder">
                <div class="example-button-row">
                  <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                    (click)='editPump(element.id)' class="no-hover-effect">Edit
                  </button>
                  <button class="no-hover-effect" mat-button disableRipple (click)="viewPump(element.id)">View</button>
                  <button class="last-btn no-hover-effect" mat-button disableRipple
                    (click)="deletePump(element.id)">Delete</button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="pumpColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: pumpColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
      </table>
    </div>
    <div *ngIf="pumpTableData?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
  </div>
  <div style="height: 100px;" *ngIf="selected == 'pump'"></div>
  <div *ngIf="selected ==  'medication'" class="medication">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Add Medication Details</h2>
      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addMedication()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="row" style="margin-top: 15px;" class="container-table tags-table">
      <div class="medication-details" *ngFor="let item of medicationData">
        <mat-accordion [dataSource]="MedicationData"
          [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (item.created_at | date:'short')}">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
              <div>
                <div class="row" style="margin-bottom: 11px;">
                  <div class="col-md-5">
                    <mat-panel-title style=" font-family: Avenir;
                    font-size: 14px;">{{item.title}} </mat-panel-title>
                  </div>
                  <div class="col-md-4" style="padding-left: 0%;">
                    <mat-chip-list [disabled]="item.reminder_is_active==false || item.reminder_is_active==null">
                      <mat-chip class="notranslate" selectable="true" removable="true"
                        *ngFor="let itemData of item.time" (removed)="remove(itemData,item.id)">
                        {{itemData.t}}&nbsp;hrs
                        <!-- <mat-icon class="close-btn"  matChipRemove>cancel</mat-icon> -->
                        <button mat-icon-button class="close-btn">
                          <mat-icon style="color:  var(--primary-orange); ;" matChipRemove>close
                          </mat-icon>
                        </button>
                      </mat-chip>

                    </mat-chip-list>
                  </div>
                  <div class="col-md-3" style="padding-left: 0%;">
                    <label class="switch1" *ngIf="item.medication_end_date < getTodayDate">
                      <input #saveUserNameCheckBox id="saveUserNameCheckBox" type="checkbox"
                        [checked]="item.reminder_is_active" [disabled]="true"
                        (change)="onSaveUsernameChanged(saveUserNameCheckBox.checked,item.id)" checked>
                      <span class="slider round"></span>
                    </label>
                    <label class="switch"
                      *ngIf="item.medication_end_date > getTodayDate || item.medication_end_date==null">
                      <input #saveUserNameCheckBox id="saveUserNameCheckBox" type="checkbox"
                        [checked]="item.reminder_is_active"
                        (change)="onSaveUsernameChanged(saveUserNameCheckBox.checked,item.id)" checked>
                      <span class="slider round"></span>
                    </label>
                  </div>


                  <!-- <span style="font-size: 14px;margin: 3px 40px;">{{covertTime(item.updated_at)|date:'MMM d, h:mm a'}}
                    </span> -->
                  <!--   [disabled]="(item.medication_end_date | date:'yMMdd')  < (getTodayDate  | date:'yMMdd')"-->





                </div>



                <p>Route of Administration</p>
                <h5 class="text-capitalize">{{item.medication_route_type}}</h5>
                <div class="row">
                  <div class="col-md-4" *ngIf="item.flow_rate">
                    <div class="itmes">
                      <p>
                        Flow Rate
                      </p>
                      <h5>{{item.flow_rate}}{{item.flow_rate_unit}}</h5>
                    </div>
                  </div>
                  <div class="col-md-3" *ngIf="item.dose && (item.medication_route_type!='implantable')">
                    <div class="itmes">
                      <p>
                        Dose
                      </p>
                      <h5>{{item.dose}} {{item.dose_unit}}</h5>
                    </div>
                  </div>
                  <div class="col-md-5" *ngIf="item.drug_concentration && (item.medication_route_type!='implantable')">
                    <div class="itmes">
                      <p>
                        Drug Concentration
                      </p>
                      <h5>{{item.drug_concentration}} {{item.drug_concentration_unit}} </h5>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="item.meal_type">
                    <div class="itmes">
                      <p>
                        Meal Category
                      </p>
                      <h5>{{item.meal_type}}</h5>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="item.number_of_breaths">
                    <div class="itmes">
                      <p>
                        No of Breaths
                      </p>
                      <h5>{{item.number_of_breaths}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="menu-holder">
              <div class="example-button-row">
                <button mat-button disableRipple style="padding-left: 0;text-align: left;" class="no-hover-effect"
                  (click)="editUserMedication(item.id)">Edit
                </button>
                <button class="last-btn no-hover-effect" mat-button disableRipple
                  (click)="deleteUserMedication(item.id)">Delete</button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>



    <!-- <h4>Additional Information</h4>
    <form [formGroup]="" validate>
      <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Date of discontinuation old drug</mat-label>
        <input matInput placeholder="Date of discontinuation old drug" formControlName="">
      </mat-form-field>
      <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Start date of new drug</mat-label>
        <input matInput placeholder="Date of discontinuation old drug" formControlName="">
      </mat-form-field>

    </form> -->
  </div>
  <div style="height: 100px;" *ngIf="selected == 'medication'"></div>
  <div *ngIf="selected ==  'medicalhistory'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Medical History</h2>
      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addMedicalHistory()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div *ngIf="!showMedicalHistory" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div *ngIf="showMedicalHistory">
      <div class="medicationhisto-card">
        <div class="row">
          <div class="col-md-12">
            <div class="text-over">Current Conditions</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>PAH Details</h4>
          </div>
          <div class="col-md-6">
            <div class="items">
              <p>
                PAH Subtype
              </p>
              <h5>{{MedicalData.sub_type}}</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="items">
              <p>
                Current functional class
              </p>
              <h5>{{MedicalData.functional_class}}</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="items">
              <p>
                Date of Diagnosis
              </p>
              <h5>{{MedicalData.date_of_diagnosis | date: 'dd/MM/yyyy'}}</h5>
            </div>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Comorbidities</h4>
          </div>
          <div *ngFor="let data of MedicalData.comorbidities; let i = index;">

            <div class="col-md-6">
              <div class="items">
                <p>
                  PAH Subtype
                </p>
                <h5>{{data.name}}</h5>
              </div>
            </div>
            <div class="col-md-6">
              <div class="items">
                <p>
                  Date of Diagnosis
                </p>

                <h5> {{data.date_of_diagnosis | date: 'dd/MM/yyyy'}}</h5>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="medicationhisto-card" style="margin-bottom: 30px;">
        <div class="row">
          <div class="col-md-12">
            <div class="text-over">Previous Conditions</div>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let data of MedicalData.previous_conditions; let i = index;">
            <div class="col-md-6">
              <div class="items">
                <p>
                  Condition Name
                </p>
                <h5>{{data.name}}</h5>
              </div>
            </div>

            <div class="col-md-6">
              <div class="items">
                <p>
                  Date of Diagnosis
                </p>
                <h5>{{data.date_of_diagnosis | date: 'dd/MM/yyyy'}}</h5>
              </div>
            </div>
            <div class="col-md-6">
              <div class="items">
                <p>
                  End Date
                </p>
                <h5>{{data.end_date | date: 'dd/MM/yyyy'}}</h5>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="medicationhisto" style="margin-top: 0;">
        <div class="row">
          <div class="col-md-12">
            <div class="items">
              <p class="allergies">
                Allergies
              </p>
              <h5 class="alergy-data">{{MedicalData.allergies}}</h5>
            </div>
          </div>
          <div class="col-md-12">
            <div class="items">
              <p class="allergies">
                Symptoms
              </p>
              <h5 class="alergy-data">{{MedicalData.general_symptoms}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selected == 'roa'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Route of Administration</h2>
      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addROA()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div *ngIf="routeofAdminData.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>

    <div class="row" style="margin-top: 20px;">
      <div class="medication medication-details roa-expansion" *ngFor="let item of routeofAdminData">
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
              <mat-panel-title>
                <!-- {{item.title}} -->
              </mat-panel-title>
              <div class="row">
                <div class="col-md-6">
                  <p>Log Date</p>
                  <h5 class="text-capitalize">{{item.log_date | date}}</h5>
                </div>
                <div class="col-md-6">
                  <div class="itmes">
                    <p>
                      Route of administration
                    </p>
                    <h5 class="text-capitalize">{{item.route_of_administration}}</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="itmes">
                    <p>
                      Reason for addition
                    </p>
                    <h5>{{item.reason_for_addition}}</h5>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="menu-holder">
              <div class="example-button-row">
                <button mat-button disableRipple style="padding-left: 0;text-align: left" class="no-hover-effect"
                  (click)="editROA(item.id)">Edit
                </button>
                <button class="last-btn no-hover-effect" mat-button disableRipple
                  (click)="openROADialog(item.id)">Delete</button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>



    <!-- <div *ngIf="routeofAdminData.length != 0" style="margin-top: 20px;">
      <table mat-table [dataSource]="routeofAdminData" matSort multiTemplateDataRows
        class="widthfluid bordergrey list-table">

        <ng-container matColumnDef="log_date">
          <th mat-header-cell *matHeaderCellDef> Log Date </th>
          <td mat-cell *matCellDef="let element" class="camelcased">
            <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
            {{element.log_date | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="route_of_administration">
          <th mat-header-cell *matHeaderCellDef>Route Of Administration</th>
          <td mat-cell *matCellDef="let element" class="camelcased">
            {{capitalizeFl(element.route_of_administration)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="reason_for_addition">
          <th mat-header-cell *matHeaderCellDef>Reason for Addition</th>
          <td mat-cell *matCellDef="let element" class="camelcased">
            {{element.reason_for_addition}}
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="roaColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="menu-holder">
                <div class="example-button-row">
                  <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                    class="no-hover-effect" (click)="editROA(element.id)">Edit
                  </button>
                  <button class="last-btn no-hover-effect" mat-button disableRipple
                    (click)="openROADialog(element.id)">Delete</button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="roaColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: roaColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div> -->
  </div>
  <!-- <div *ngIf="selected == 'infusion'">
    <div class="chartcontainer">
      <div class="clearfix">
        <h2 class="heading-text pull-left">Infusion Site Changes</h2>
        <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addISC()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="chartheader clearfix" style="padding-bottom: 18px;width:100%;margin-top:15px">
        <form [formGroup]="infusionlogForm" validate class="filter-form">
          <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-5" style="padding:0;">
            <mat-label>Choose start date</mat-label>
            <input matInput formControlName="start_date" [matDatepicker]="picker1" [max]="morrow"
              placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-5"
            style="padding-right:0;">
            <mat-label>Choose end date</mat-label>
            <input matInput formControlName="end_date" [matDatepicker]="picker2" [max]="morrow"
              placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <button mat-icon-button class="plusrectangletransparent pull-right col-md-6"
            style="margin-top: 4px;width: 48px;height: 48px;" (click)="getInfusionSite()">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>

      <div class="no-data text-center" *ngIf="!InfusionSiteData">
        No Data Available
      </div>

      <table mat-table [dataSource]="InfusionSiteData" matSort multiTemplateDataRows
        class="widthfluid bordergrey list-table" style="margin-top: 15px;" *ngIf="InfusionSiteData">

        <ng-container matColumnDef="log_date">
          <th mat-header-cell *matHeaderCellDef> Log Date </th>
          <td mat-cell *matCellDef="let element" class="text-capitalize">
            {{element.log_date | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="infusion_site">
          <th mat-header-cell *matHeaderCellDef>Infusion Site </th>
          <td mat-cell *matCellDef="let element" class="text-capitalize">
            {{ConvertData(element.infusion_site)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="site_change_reason">
          <th mat-header-cell *matHeaderCellDef>Reason for change</th>
          <td mat-cell *matCellDef="let element" class="text-capitalize">
            {{element.site_change_reason}}
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="menu-holder">
                <div class="example-button-row">
                  <button class="last-btn no-hover-effect" style="padding-left: 0;text-align: left;" mat-button
                    disableRipple (click)="openDialog(element.id)">Delete</button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div> -->
  <div *ngIf="selected == 'adr'">
    <div class="chartcontainer">
      <div class="clearfix">
        <h2 class="heading-text pull-left">Adverse Reaction | Remodulin</h2>
        <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addADRF()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div *ngIf="adrfRemodulinData?.length == 0" class="text-center">
        <p class="no-data">-- No data Avaliable --</p>
      </div>
      <div *ngIf="adrfRemodulinData?.length != 0" class="container-table ae-table" (scrolled)="goNextPage('adr')"
        [scrollWindow]="false">
        <!--infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"-->
        <table mat-table [dataSource]="adrfRemodulinData" matSort multiTemplateDataRows
          class="widthfluid bordergrey list-table" style="margin-top: 15px;">

          <ng-container matColumnDef="log_date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element" class="camelcased"
              [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
              {{element.created_at | date}}
            </td>
          </ng-container>
          <ng-container matColumnDef="log_type">
            <th mat-header-cell *matHeaderCellDef>Logging Type </th>
            <td mat-cell *matCellDef="let element" class="camelcased"
              [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
              <h6 class="text-capitalize">{{element.log_type}}</h6>
              <span  *ngIf="element.log_type === 'symptom logged'"> 
                <span class="symptom_list"  *ngFor="let symp of element.symptom_list"> {{symp.title}}-{{(symp.value)}}</span>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="ae_form_submitted">
            <th mat-header-cell *matHeaderCellDef>AE Form</th>
            <td mat-cell *matCellDef="let element" class="camelcased"
              [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
              <!-- <button mat-icon-button class="plusrectangletransparent ae-add" (click)="editADRF(element.id)"
              style="height: 30px;width: 30px;">
              <mat-icon style="height: 35px;">add</mat-icon>
              </button> -->

              <button mat-icon-button class="plusrectangletransparent2 ae-add"
                *ngIf="element.ae_form_submitted == false" (click)="editADRF(element.id)"
                style="height: 30px;width: 30px;">
                <mat-icon style="height: 35px;">add</mat-icon>

              </button>

              <button mat-icon-button class="plusrectangletransparent1 ae-add"
                *ngIf="element.ae_form_submitted == true  && ((element.updated_at  | date:'yMMdd')  == (getToday  | date:'yMMdd'))"
                (click)="editADRF(element.id)" style="height: 30px;width: 30px;background: #333333;color: white;">
                <mat-icon style="height: 35px;font-size: 16px;">edit</mat-icon>
              </button>

              <button mat-icon-button (click)="editADRF1(element.id)" style="height: 30px;width: 30px;"
                class="plusrectangletransparent1 ae-add"
                *ngIf="element.ae_form_submitted == true && element.updated_at <  getToday">
                <!--|| (element.ae_form_submitted == false && element.updated_at <  getToday)-->
                <div class="button-bg-image view-btn"></div>
              </button>
              <!--tooltip="View Task" class="hover-btn-image" -->
              <!-- <button mat-icon-button class="plusrectangletransparent ae-add" 
                (click)="getallAdverseData(element.id)" style="height: 30px;width: 30px;background: #333333;">
                <mat-icon style="height: 35px;font-size: 16px;">resend</mat-icon>
              </button> -->
              <!-- <a *ngIf="element.ae_form_submitted == true" (click)="getallAdverseData(element.id)">Resend mail</a> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="adrfColumn.length"
              [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (element.created_at | date:'short')}">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="menu-holder">
                  <div class="example-button-row">
                    <button class="last-btn no-hover-effect" mat-button disableRipple
                      (click)="openDialog(element.id)">Delete</button>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
          <!--[ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') != (element.created_at | date:'short')}"-->
          <tr mat-header-row *matHeaderRowDef="adrfColumn;sticky:true"></tr>
          <tr mat-row *matRowDef="let element; columns: adrfColumn;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>
      </div>
      <!-- <div *ngIf="!(adrfRemodulinData && adrfRemodulinData?.length != 0)" class="nodatacalllog">
        No Data
      </div> -->
      <!-- <button mat-flat-button *ngIf="adrfRemodulinData.length" (click)="goNextPage('adr')">More</button> -->
    </div>
  </div>
  <div *ngIf="selected == 'nonadr'">
    <div class="chartcontainer">
      <div class="clearfix">
        <h2 class="heading-text pull-left">Adverse Reaction | Non Remodulin</h2>
      </div>

      <table mat-table [dataSource]="adrfRemodulinData" matSort multiTemplateDataRows
        class="widthfluid bordergrey list-table" style="margin-top: 15px;">
        <ng-container matColumnDef="log_date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element" class="camelcased">
            {{element.created_at | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="log_type">
          <th mat-header-cell *matHeaderCellDef>Logging Type </th>
          <td mat-cell *matCellDef="let element" class="camelcased">
            {{element.log_type}}
          </td>
        </ng-container>
        <ng-container matColumnDef="ae_form_submitted">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="camelcased">
            <!-- <button mat-icon-button class="plusrectangletransparent ae-add" (click)="addADRF()">
            <mat-icon>add</mat-icon>
          </button> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="adrfColumn.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="menu-holder">
                <!-- <div class="example-button-row">
                <button class="last-btn no-hover-effect" mat-button disableRipple
                  (click)="openDialog(element.id)">Delete</button>
              </div> -->
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="adrfColumn;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: adrfColumn;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
      <div *ngIf="!(adrfNonRemodulinData && adrfNonRemodulinData?.length != 0)" class="nodatacalllog">
        No Data
      </div>

    </div>
  </div>


  <div *ngIf="selected == 'pumphistory'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Historical Pump</h2>
    </div>
    <div class="row">
      <div style="margin-top: 15px;" class="medication medication-details roa-expansion">
        <div *ngFor="let item of pumphistoryData">
          <mat-accordion>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                  <!-- {{item.title}} -->
                </mat-panel-title>
                <div class="row">
                  <div class="col-md-6">
                    <p>Log Date</p>
                    <h5 class="text-capitalize">{{item.created_at | date}}</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="itmes">
                      <p>
                        Pump Name
                      </p>
                      <h5 class="text-capitalize">{{getPumpName(item.pump_id_fk)}}{{item.other_pump_name}}</h5>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="itmes">
                      <p>
                        Reason Type
                      </p>
                      <h5>{{item.pump_route_type}}</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="items">
                      <p>Reason for change</p>
                      <h5>{{item.reason_for_change}}</h5>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div class="menu-holder">
                <div class="example-button-row">
                  <!-- <button mat-button disableRipple style="padding-left: 0;text-align: left" class="no-hover-effect"
                    (click)="editPump(item.id)">Edit
                  </button> -->
                  <!-- <button class="last-btn no-hover-effect" mat-button disableRipple
                    (click)="deletePump(item.id)">Delete</button> -->
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>



        <!-- <table mat-table [dataSource]="pumphistoryData" multiTemplateDataRows class="widthfluid bordergrey list-table">
          <ng-container matColumnDef="log_date">
            <th mat-header-cell *matHeaderCellDef> Log Date </th>
            <td mat-cell *matCellDef="let element" class="camelcased">
              {{element.created_at | daysago}}
            </td>
          </ng-container>
          <ng-container matColumnDef="pump_id_fk">
            <th mat-header-cell *matHeaderCellDef>Pump Name</th>
            <td mat-cell *matCellDef="let element" class="camelcased">
              {{element.title}}
            </td>
          </ng-container>
          <ng-container matColumnDef="pump_route_type">
            <th mat-header-cell *matHeaderCellDef> Route Type </th>
            <td mat-cell *matCellDef="let element" class="camelcased">
              {{element.pump_route_type}}
            </td>
          </ng-container>
          <ng-container matColumnDef="reason_for_change">
            <th mat-header-cell *matHeaderCellDef> Reason for change </th>
            <td mat-cell *matCellDef="let element" class="camelcased">
              {{element.reason_for_change}}
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="pumphistoryColumns.length">
              <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="menu-holder">
                  <div class="example-button-row">
                    <button mat-button disableRipple style="padding-left: 0;text-align: left;"
                      (click)='editPump(element.id)' class="no-hover-effect">Edit
                    </button>
                    <button class="no-hover-effect" mat-button disableRipple (click)="viewPump(element.id)">View</button>
                    <button class="last-btn no-hover-effect" mat-button disableRipple
                      (click)="deletePump(element.id)">Delete</button>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="pumphistoryColumns;sticky:true"></tr>
          <tr mat-row *matRowDef="let element; columns: pumphistoryColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table> -->
      </div>
    </div>

  </div>

  <!-- Hospitalisation -->
  <div *ngIf="selected == 'hospitalisation'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Hospitalisation</h2>
      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addHospitalisation()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div class="row" style="margin-top: 15px;">
      <div class="medication medication-details" *ngFor="let item of hospitalizationData">
        <mat-accordion [dataSource]="hospitalizationData">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
              <div class="form-card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-over" style="width: 120px;">{{item.duration_from | date: 'dd-MMM-yyyy'}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <p>Reason for hospitalisation</p>
                    <h5 class="text-capitalize">{{item.reason}}</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="itmes">
                      <p>
                        Type
                      </p>
                      <h5 class="text-capitalize">{{item.type}}</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <p>Procedure</p>
                    <h5 class="text-capitalize">{{item.procedures}}</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="itmes">
                      <p>
                        Next Doctor Visit
                      </p>
                      <h5 class="text-capitalize">{{item.next_doctor_visit | date: 'dd-MMM-yyyy'}}</h5>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="itmes">
                      <p>
                        Details Of Stay
                      </p>
                      <h5>{{item.stay_details}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="menu-holder">
              <div class="example-button-row">
                <!-- <button mat-button disableRipple style="padding-left: 0;text-align: left;"
                  class="no-hover-effect" (click)="editUserMedication(item.id)">Edit
                </button>
                <button class="last-btn no-hover-effect" mat-button disableRipple
                  (click)="deleteUserMedication(item.id)">Delete</button> -->
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>


  <div *ngIf="selected == 'medicationSurvey'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Medication Survey</h2>
      <!-- <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="addHospitalisation()">
        <mat-icon>add</mat-icon>
      </button> -->
    </div>
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-12">
        <!-- <div class="container-table">  -->
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let item of surveyData">
            <mat-accordion
              [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (item.created_at | date:'short')}">
              <mat-expansion-panel [expanded]="false">
                <!--[ngClass]="{ 'quadrat': notificationDate_Time==item.created_at}"-->
                <!--(step=='step1')?'my-class1':'my-class2'-->
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="title-bar"> <img src="assets/img/dls/icons/Tick_solid.svg" width="20px"
                        class="img-responsive" alt="Wellthy">{{convertIsoDate(item.updated_at) | date:'EEE, MMM d, y'}}
                    </div>
                    <!-- <div class="title-bar"> <img
                                      src="assets/img/dls/icons/Icon_Requests.svg" width="30"
                                      class="img-responsive" alt="Wellthy">Tuesday, October 4, 2021 1:28:00 PM
                              </div> -->
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row divider" *ngFor="let med of item.medication_data">
                  <div class="configure-conditions col-md-5">
                    <!--[ngClass]="{ 'invalid': notificationDate_Time==med.created_at}"-->
                    <div class="time-text">{{med.t}}</div>
                    <div class="task-type-bg">
                      <div class="d-flex align-items-center content">
                        <h5>{{med.title}}</h5>
                        <!-- <a (click)="sendMedSurvey(item.medication_data,med.id,true,item.id)" [ngClass]="(med.taken) ? 'ThumbIconOpt' : 'ThumbIcon'">
                                           <img src="assets/img/dls/icons/Thumbup.svg"
                                             class="img-responsive" alt="Wellthy"> </a>
                                             <a (click)="sendMedSurvey(item.medication_data,med.id,false,item.id)" [ngClass]="(med.taken) ? 'ThumbIconOpt' : 'ThumbIcon'">
                                             <img src="assets/img/dls/icons/Thumbdown.svg"
                                             class="img-responsive" alt="Wellthy"> </a>  -->
                      </div>
                    </div>
                    <div class="medsurvey-sub-text">{{med.medicine_data.days}}</div>
                  </div>
                  <div class="configure-conditions col-md-4">
                    <div class="task-type-bg">
                      <div class="d-flex align-items-center content">

                        <a [ngClass]="{'ThumbIcon': med.taken === false}">
                          <img src="assets/img/dls/icons/Thumbup.svg" class="img-responsive" alt="Wellthy" disabled>
                        </a>
                        <a [ngClass]="{'ThumbIcon': med.taken === true}">
                          <img src="assets/img/dls/icons/Thumbdown.svg" class="img-responsive" alt="Wellthy" disabled>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </mat-expansion-panel>
            </mat-accordion>
          </li>

          <!-- <li class="list-group-item">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="title-bar"> <img
                                            src="assets/img/dls/icons/Icon_Requests.svg" width="30"
                                            class="img-responsive" alt="Wellthy">Tuesday, October 4, 2021 1:28:00 PM
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="configure-conditions col-md-12">
                                    <div class="time-text"></div>
                                    <div class="task-type-bg">
                                        <div class="d-flex align-items-center content">
                                           phone call to patients
                                        </div>
                                    </div>
                                    <div class="medsurvey-sub-text">phone call to patients</div>
                                   
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                  
                  </li>

                  <li class="list-group-item">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="title-bar"> <img
                                            src="assets/img/dls/icons/Icon_Requests Deselected.svg" width="30"
                                            class="img-responsive" alt="Wellthy">Tuesday, October 4, 2021 1:28:00 PM
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="configure-conditions col-md-12">
                                    <div class="time-text"></div>
                                    <div class="task-type-bg">
                                        <div class="d-flex align-items-center content">

                                           phone call to patients
                                        </div>
                                    </div>
                                    <div class="medsurvey-sub-text">phone call to patients</div>
                                   
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                  </li> -->
        </ul>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <!-- Doctor Vist -->

  <div *ngIf="selected == 'doctorVisit'">
    <div class="clearfix">
      <h2 class="heading-text pull-left">Doctor Visit</h2>

      <button mat-icon-button class="plusrectangletransparent pull-right col-md-6" (click)="openReminderDialog(false)">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div *ngIf="allreminder?.length == 0" class="text-center">
      <p class="no-data">-- No data Avaliable --</p>
    </div>
    <div class="col-md-12" *ngFor="let reminder of reminderData">
      <div class="row" *ngIf="reminder.type === 'doctorvisit'">
        <p class="med-data"
          [ngClass]="{ 'blink-bg1': (notificationDate_Time | date:'short') == (reminder.created_at | date:'short')}">
          {{convertIsoData(reminder.created_at)}}
          {{convertIsoDatetime(reminder.created_at)}}</p>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Visit Purpose</mat-card-title>
              <mat-card-subtitle>{{reminder.name}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Category</mat-card-title>
              <mat-card-subtitle class="camelcased">Doctor Visit</mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>

        <div class="col-md-4 no-padding-left">
          <mat-card class="userdetail-card">
            <mat-card-header>
              <mat-card-title>Visit date </mat-card-title>
              <mat-card-subtitle><span >{{reminder.start_date | date:'fullDate'}}</span>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
       

      </div>
    </div>
  </div>

  <ng-template #loadingOrError>
    <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async"></app-loadorerror>
  </ng-template>
</div>
<ng-template #selectCurrentPump>
  <form [formGroup]="selectNewCurrentPump" (ngSubmit)="onSelectingNewPump();" validate>
    <h4>Select New Current Pump</h4>
    <mat-form-field appearance="outline"  required>
        <mat-label>Select Pump</mat-label>
        <mat-select formControlName="new_current_pump" required>
            <mat-option class="camelcased" *ngFor="let pump of selectNewCurrentPumpDropDown" [value]="pump.id"
                (click)="getROAType(pump)" required>
             {{pump.title}}{{pump.other_pump_name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
      <div>
        <button type = "submit" mat-flat-button color="primary" [disabled] = "!selectNewCurrentPump.valid"  class="centerbtn">Update Pump</button>
    </div>
    </form>
</ng-template>
