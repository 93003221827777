<div class="col-md-12">  
  
    <h2 class="commontitleprimary text-capitalize">
      <span style="color:var(--primary-orange);">{{userName}}</span>
    </h2>
    <div class="close-icon">
      <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()">
    </div>
  
    <div class="container-table" *ngIf="tabulardata.length != 0" infiniteScroll [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50" [scrollWindow]="false">
      <div class="spinner-container" *ngIf="showloader">
        <app-loader showLoader="{{true}}"></app-loader>
      </div>
      <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
  
        <ng-container matColumnDef="previousCampaign">
          <th mat-header-cell *matHeaderCellDef>Previous Data </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.type === 'Phone Change'">
              {{ element.current_country_code }} - 
            </ng-container>
            {{element.old_data}} 
          </td>
        </ng-container>
  
  
        <ng-container matColumnDef="newCampaign">
          <th mat-header-cell *matHeaderCellDef> New Data </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.type === 'Phone Change'">
              {{ element.new_country_code }} - 
            </ng-container>

            {{element.new_data}} 
          </td>
        </ng-container>
  
  
        <ng-container matColumnDef="newLanguage">
          <th mat-header-cell *matHeaderCellDef> Language </th>
          <td mat-cell *matCellDef="let element"> {{element.language}} </td>
        </ng-container>
  
        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef> Approved By </th>
          <td mat-cell *matCellDef="let element"> {{element.updated_by}} </td>
        </ng-container>
  
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef> Updated At </th>
          <td mat-cell *matCellDef="let element"> {{convertDate(element.created_at)}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;">
        </tr>
  
      </table>
    </div>   

    <div class="" *ngIf="tabulardata.length == 0">
      <p class="center">No transfer history found for this user</p>
    </div>
  </div>