<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/patients"></app-breadcrumb>

      <div class="col-md-6">
        <h3 class="commontitleprimary">
          Patients
        </h3>
      </div>
      <div class="col-md-6">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="create-btn pull-right" (click)="patientonsite()">
            Upload New Patients
          </button>
        </h3>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-5">
            <form [formGroup]="createStoreForm">
              <div class="form-group has-feedback has-search">
                <input type="text" name="user" class="form-control" [formControl]="createStoreForm.controls['user']"
                  placeholder="Search Patient here" style="height:50px;">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-7">
            <div class="row">
              <form [formGroup]="dataForm" validate class="filter-form">
                <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-4 pq">
                  <mat-label>Choose start date</mat-label>
                  <input matInput formControlName="startdate" [matDatepicker]="picker1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [max]="morrow" placeholder="Choose a date">
                  <mat-datepicker-toggle class="notranslate" matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker panelClass="notranslate" #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="notranslate" appearance="outline" (click)="$event.stopPropagation();"
                  class="col-md-4 pr">
                  <mat-label>Choose end date</mat-label>
                  <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="enddateval"
                    placeholder="Choose a date">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker panelClass="notranslate" #picker2></mat-datepicker>
                </mat-form-field>
                <div class="col-md-4">
                  <button mat-flat-button color="primary" class="primary apply-btn btn-block"
                    (click)="applymainfilter()" [disabled]="!dataForm.valid">Apply</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" style="overflow-y: scroll;">
        <div class="Container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Patient Name </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                {{!element.name?'-':element.name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number </th>
              <td mat-cell *matCellDef="let element"> +{{element.country_code}}-{{element.phone}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> {{statusConvert(element.status)}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="login history">
              <th mat-header-cell *matHeaderCellDef> Login history </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <button mat-button (click)="loginHistory(element.id)" tooltip="login history">
                  <img src="assets/img/dls/admin-table/Icon_Start_staging.svg" alt="Wellthy">
                </button>
              </td>
            </ng-container> -->

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <div class="action-button-row">

                  <button mat-button (click)="viewProfile(element)" tooltip="View profile">
                    <img src="assets/img/dls/admin-table/Icon_View profile.svg" alt="Wellthy">
                  </button>
                  <button mat-button (click)="viewPayment(element)" tooltip="Payment history">
                    <img src="assets/img/dls/admin-table/Icon_Payment history.svg" alt="Wellthy">
                  </button>
                  <button mat-button (click)="forceLogout(element.id)" tooltip="Force logout">
                    <img src="assets/img/dls/admin-table/Icon_Force_Logout.svg" alt="Wellthy">
                  </button>
                  <button mat-button (click)="openDialog(element.id)" tooltip="Delete user">
                    <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="Wellthy">
                  </button>
                  <button mat-button (click)="EditPatient(element)" tooltip="Change program"
                    *ngIf="element.status=='ACTIVATION_CODE_VERIFIED' ||  element.status=='ONBOARDED'  ||  element.status=='SECONDARY_CONDITION_ENTERED' && (element.campaignDataId!=null && campaign_data_id!=null) ">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy">
                  </button>
                  <!-- <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button> -->
                
                  <button mat-button class="disabledEditOperation"
                    tooltip="This patient is not a part of any campaign right now"
                    *ngIf="element.status!='ACTIVATION_CODE_VERIFIED' &&  element.status!='ONBOARDED'  &&  element.status!='SECONDARY_CONDITION_ENTERED'">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy" style="opacity:0.5" disabled>
                  </button>
                 
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button  mat-button *ngIf="element.show_history === false"  matTooltip="This patient has no program change history" style="opacity:0.5;cursor: not-allowed;">
                      <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="History Icon" disabled>
                      <span> Program history</span>
                    </button>
                    <button *ngIf="element.show_history === true"   mat-button (click)="PatientInfo(element)" tooltip="Program history">
                      <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="History Icon">
                      <span> Program history </span>
                    </button>
                    <button  *ngIf="element.show_patient_data_changes === true"  mat-button (click)="patientPhoneLog(element)" tooltip="Phone Change history">
                      <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="History Icon">
                      <span> Phone Change history</span>
                    </button>
                    <button   mat-button *ngIf="element.show_patient_data_changes === false"  matTooltip="This patient has no Phone change history" style="opacity:0.5;cursor: not-allowed;">
                      <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="History Icon" disabled>
                      <span> Phone Change history</span>
                    </button>
                    <button  *ngIf="element.show_patient_event === true" mat-button (click)="openPatientEvents(element)" tooltip="Patient Events">
                      <img style="height:30px" src="assets/img/dls/navigator/therapy.svg" alt="Wellthy">
                      <span> Patient Events</span>
                    </button>
  
                    <button   *ngIf="element.show_patient_event === false" style="opacity:0.5;cursor: not-allowed;" mat-button tooltip="This patient has no Events">
                      <img style="height:30px" src="assets/img/dls/navigator/therapy.svg" alt="Wellthy">
                      <span> Patient Events</span>
                    </button>
  
                    <button *ngIf="superadmin" (click)="triggerHealthkit(element.id)" mat-button  matTooltip="Trigger Google / Apple health kit Rawdata">
                      <img  src="assets\img\therapy\therapy\editTherapy.svg"  alt="" disabled>
                      <span> Trigger health kit Rawdata</span>
                    </button>
                   
                    <button  *ngIf="superadmin"  (click)="getRawdata(element.id)"   mat-button  matTooltip="Google / Apple health kit Rawdata">
                    <img src="assets/img/dls/admin-table/Icon_View_description.svg" alt="" disabled>
                    <span> Google / Apple health kit Rawdata</span>
                  </button>
                  </mat-menu>
                 
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>
