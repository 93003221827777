import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
  styleUrls: ['./viewprofile.component.scss']
})
export class ViewprofileComponent implements OnInit {
  image_path: any;
  type:any;
  constructor(
    public dialogRef: MatDialogRef<ViewprofileComponent>,
    private commonService: CommonserviceService,
    private systemService: SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  userView: any;
  public showButtonLoader: boolean = false;

  ngOnInit() {
    this.userView = this.data.element;

    
 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // ----------------------------


}
