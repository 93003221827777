import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { PaymentmappingRoutingModule } from './paymentmapping.routing.module';
import { PaymentmappingComponent } from './paymentmapping.component';
import { EditpaymentmappingComponent } from './editpaymentmapping/editpaymentmapping.component';
import { PaymentleftnavModule } from '../shared/paymentleftnav/paymentleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';

@NgModule({
  declarations: [
    
    PaymentmappingComponent,
    EditpaymentmappingComponent
    
  ],
  imports: [
    CommonModule,
    PaymentmappingRoutingModule,
    ImageCropperModule,
    PaymentleftnavModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PaymentmappingModule {}