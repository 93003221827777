<form *ngIf="isReminder" [formGroup]="addremainderform" validate>
    <div class="row">
        <div class="col-md-12">
            <h4>Add Reminder</h4>
            <hr />
            <div class="row">
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" formControlName="name" required>
                </mat-form-field>

                <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Type of Reminder</mat-label>
                    <mat-select #fontSize value="16px" [(ngModel)]="remType" formControlName="type" required>
                        <mat-option value="meal">Meal</mat-option>
                        <mat-option value="activity">Activity</mat-option>
                        <mat-option value="water">Water</mat-option>
                        <mat-option value="bloodsugar">Blood Sugar</mat-option>
                        <mat-option value="bloodpressure">Blood Pressure</mat-option>
                        <mat-option value="weight">Weight</mat-option>
                        <mat-option value="labreport">Lab Reports</mat-option>
                        <mat-option value="medicine">Medicine</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-md-6" appearance="outline" *ngIf="remType == 'medicine'">
                    <mat-label>Medicine Type</mat-label>
                    <mat-select #fontSize value="16px" [(ngModel)]="typeFilters" formControlName="sub_type" required>
                        <mat-option value="tablet">Tablet</mat-option>
                        <mat-option value="syrup">Syrup</mat-option>
                        <mat-option value="capsule">Capsule</mat-option>
                        <mat-option value="insulin_pen">Insulin</mat-option>
                        <mat-option value="ointment">Ointment</mat-option>
                        <mat-option value="injectable">Injectable</mat-option>
                        <mat-option value="nebulizer">Nebulizer</mat-option>
                        <mat-option value="mdi">MDI</mat-option>
                        <mat-option value="dpi">DPI</mat-option>
                        <mat-option value="bai">BAI</mat-option>
                        <mat-option value="nasal_spray">Nasal Spray</mat-option>
                        <mat-option value="dpi_revolizer">DPI Revolizer</mat-option>
                        <mat-option value="dpi_multihaler">DPI Multihaler</mat-option>
                        <mat-option value="dpi_rotahaler">DPI Rotahaler</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="typeFilters == 'capsule' || typeFilters == 'tablet'">
                    <mat-label>Medicine Shape</mat-label>
                    <mat-select #fontSize value="16px" formControlName="medicine_shape">
                        <mat-option value="round">Round</mat-option>
                        <mat-option value="oblong">Oblong</mat-option>
                        <mat-option value="oval">Oval</mat-option>
                        <mat-option value="square">Square</mat-option>
                        <mat-option value="rectangle">Rectangle</mat-option>
                        <mat-option value="diamond">Diamond</mat-option>
                        <mat-option value="round_dash">Round Dash</mat-option>
                        <mat-option value="oblong_dash">Oblong Dash</mat-option>
                        <mat-option value="oval_dash">Oval Dash</mat-option>
                        <mat-option value="square_dash">Square Dash</mat-option>
                        <mat-option value="rectangle_dash">Rectangle Dash</mat-option>
                        <mat-option value="diamond_dash">Diamond Dash</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="clearfix"></div>



                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="typeFilters == 'capsule' || typeFilters == 'tablet'">
                    <mat-label>Meal Type</mat-label>
                    <mat-select #fontSize value="16px" formControlName="meal_type">
                        <mat-option value="premeal">Premeal</mat-option>
                        <mat-option value="postmeal">Postmeal</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="(!(typeFilters == 'capsule' || typeFilters == 'tablet')) && remType == 'medicine'">
                    <mat-label>Dosage Name</mat-label>
                    <mat-select #fontSize value="16px" [(ngModel)]="dosageData" formControlName="dosage_name">
                        <mat-option value="volume">volume</mat-option>
                        <mat-option value="shot">shot</mat-option>
                        <mat-option value="dosage">dosage</mat-option>

                    </mat-select>
                </mat-form-field>

                <div class="row col-md-12"
                    *ngIf="(typeFilters == 'capsule' || typeFilters == 'tablet') && dosageData == 'dosage'">
                    <p class="col-md-12">Dosage</p>
                    <mat-form-field appearance="outline" class="col-md-4">
                        <mat-label>Morning Dose</mat-label>
                        <input type="number" step="0.5" matInput placeholder="Morining Dose"
                            formControlName="morning_dose">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-4">
                        <mat-label>Afternone Dose</mat-label>
                        <input type="number" step="0.5" matInput placeholder="Afternone Dose"
                            formControlName="afternone_dose">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-4">
                        <mat-label>Evening Dose</mat-label>
                        <input type="number" step="0.5" matInput placeholder="Evening Dose"
                            formControlName="evening_dose">
                    </mat-form-field>
                </div>



                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="(!(typeFilters == 'capsule' || typeFilters == 'tablet')) && remType == 'medicine' && dosageData != 'dosage'">
                    <mat-label>Dosage value</mat-label>
                    <input matInput placeholder="Dosage value" formControlName="dosage_value">
                </mat-form-field>

                <mat-form-field class="col-md-6" appearance="outline"
                    *ngIf="(!(typeFilters == 'capsule' || typeFilters == 'tablet')) && remType == 'medicine' && dosageData != 'dosage'">
                    <mat-label>Dosage Unit</mat-label>
                    <input matInput placeholder="Dosage Unit" formControlName="dosage_unit">
                </mat-form-field>

                <!-- <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Duration(Days)</mat-label>
                    <input type="number" matInput placeholder="Eg Everyday(1), Alternate(2) or custom gap"
                        formControlName="duration" min="0" oninput="validity.valid||(value='');">

                </mat-form-field> -->



                <!-- <mat-form-field class="col-md-6" appearance="outline">
                    <mat-label>Choose a Start date</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Choose a Start date"
                        formControlName="start_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> -->

                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Select Duration</mat-label>
                    <mat-select placeholder="Select Duration" formControlName="days" required multiple #singleSelectTag>
                        <mat-option [value]="1">Monday</mat-option>
                        <mat-option [value]="2">Tuesday</mat-option>
                        <mat-option [value]="3">Wednesday</mat-option>
                        <mat-option [value]="4">Thursday</mat-option>
                        <mat-option [value]="5">Friday</mat-option>
                        <mat-option [value]="6">Saturday</mat-option>
                        <mat-option [value]="7">Sunday</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Select Feature Count</mat-label>
                        <mat-select formControlName="featurecount" (selectionChange)="generateOptions($event.value)">
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4 </mat-option>
                            <mat-option value="5">5 </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div formArrayName="time" class="col-md-12">
                    <div *ngFor="let feature of addremainderform.get('time')['controls']; let j=index;"
                        [formGroupName]="j">
                        <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                            <mat-label>Enter the time</mat-label>
                            <input matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time *"
                                formControlName="t">
                            <owl-date-time #dt1 [pickerType]="'timer'"></owl-date-time>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                            <mat-label>Enter the time</mat-label>
                            <mat-select formControlName="e">
                                <mat-option value="true">0</mat-option>
                                <mat-option value="false">1</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                            <mat-label>Enter the time</mat-label>
                            <input matInput formControlName="i" placeholder="Enter the time">
                        </mat-form-field> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <button class="col-md-12" mat-flat-button color="primary" [disabled]="!addremainderform.valid"
                class="submit-btn" (click)="dataObject()">Add </button>
        </div>
    </div>

</form>

<form  *ngIf="!isReminder"  [formGroup]="addDoctorVisitform"  validate style="overflow: hidden;">
    <div class="row">
        <div class="col-md-12">
        <h4 style="text-align: center;">Add Doctor Visit</h4>
        <hr />
        <div class="col-md-12">
            <mat-form-field appearance="outline" style="width: inherit;">
                <mat-label>Visit Purpose</mat-label>
                <input type="text" matInput placeholder="Purpose of visit" formControlName="name" required>
                <mat-error *ngIf="myError('name', 'minlength')">Use atleast 3 characters</mat-error>
            </mat-form-field>
        </div>
   </div>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field appearance="outline"  style="width: inherit;padding-left: 25px;padding-right: 25px;">
                <mat-label>Visit Date</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="Choose a Vist date"  formControlName="start_date"
                    [min]="morrow" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field appearance="outline"  style="width: inherit;padding-left: 25px;padding-right: 25px;">
                <mat-label>Visit time</mat-label>
                <input matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"  placeholder="Date Time "
                    formControlName="time" required>
                <owl-date-time #dt1 [pickerType]="'timer'"></owl-date-time>
            </mat-form-field>
        </div>
    </div>
    </div>
    <div class="col-md-12">
        <button class="col-md-12" mat-flat-button color="primary" [disabled]="!addDoctorVisitform.valid"
            class="submit-btn" (click)="addDoctorVisit()">Add </button>
    </div>
</form>