<div class="userdetailcontent">
  <div class="col-md-12 dashed-border">
    <div class="row">
      <div class="col-md-12">
        <button mat-button [matMenuTriggerFor]="progressmenu" class="programprogressmenu">
          Level {{selectedWeek}} <mat-icon class="bottomarrowfloat">expand_more</mat-icon>
        </button>
        <mat-menu #progressmenu="matMenu">
          <button mat-menu-item *ngFor="let week of currentWeekArray[0];let i = index"
            (click)="initComponentDraw(week, i)">
            <span>Level {{i+1}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="row">
      <div class="userprogresssection">
        <div *ngIf="(userProgressSubject$ | async) as userData; else loadingOrError">
          <div *ngIf="userData.module_data">
            <div class="clearfix"></div>
            <div class="matcardbottomzero col-md-12">
              <div class="">
                <mat-card style="padding-left: 0;">
                  <mat-card-header>
                    <mat-card-title>{{userData.title}}</mat-card-title>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
            <mat-accordion class="col-md-12">
              <mat-expansion-panel *ngFor="let module of userData.module_data; let i = index;">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{i+1}}. {{module.title}}
                  </mat-panel-title>
                  <mat-panel-description style="text-transform: capitalize;">
                    {{module.progress_status}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngFor="let chapter of module.chapter_data; let p = index;" class="chaptercard">
                  <span class="moveupthebox">Chapter {{p+1}}</span>
                  <p style="font-weight: 600;">{{chapter.title}}</p>
                  <div class="subpartcards" *ngIf="chapter.subpart_data">
                    <b style="display: block;margin-bottom: 10px;">Chapters</b>
                    <div *ngFor="let subpartchap of chapter.subpart_data"
                      [class]="subpartchap.data?.progress_is_completed == true ? 'lessonquizcard': 'greycard'">
                      {{subpartchap.data?.title}}
                    </div>
                  </div>
                  <div *ngIf="chapter.carey_card_data"><b>Carey Card</b><br /></div>
                  <div *ngIf="chapter.carey_card_data"
                    [class]="chapter.carey_card_data?.progress_is_completed == true ? 'careycard': 'greycard'">
                    {{chapter.carey_card_data.title}}
                  </div>
                  <div *ngIf="chapter.magazine_data"><b>Magazine</b><br /></div>
                  <div class="magazinecard" *ngIf="chapter.magazine_data"
                    [class]="chapter.magazine_data.progress_is_completed == true ? 'magazinecard': 'greycard'">
                    {{chapter.magazine_data.title}}
                  </div>
                  <div *ngIf="chapter.feedback"><b>Feedback</b><br /> </div>
                  <div class="feedback" *ngIf="chapter.feedback"
                    [class]="chapter.feedback.progress_is_completed == true ? 'feedback': 'greycard'">
                    {{chapter.feedback.title}}
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <div style="clear: both;"><br /><br /></div>
          </div>
          <!-- <app-nodata [noRecordFound]="!userData.skills && !userData.challenges && !userData.lessons && !userData.quizzes"></app-nodata> -->
        </div>
        <ng-template #loadingOrError>
          <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async">
          </app-loadorerror>
        </ng-template>
      </div>
    </div>
  </div>