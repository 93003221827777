<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/feedbackhome/feedbackedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Feedback
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Feedback Question</mat-label>
                                    <input matInput placeholder="Enter the feedback question"
                                        formControlName="feedback_main_question" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Option Type</mat-label>
                                    <mat-select formControlName="type" [(ngModel)]="typeselected" (selectionChange)="changeOptions($event.value)"
                                        required>
                                        <mat-option value="text">Text</mat-option>
                                        <!-- <mat-option value="image">Image </mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft" *ngIf="typeselected">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Option Count</mat-label>
                                    <mat-select formControlName="feedback_main_question_options_count"
                                        (selectionChange)="generateOptions($event.value)" required>
                                        <mat-option [value]="2">2</mat-option>
                                        <mat-option [value]="3">3</mat-option>
                                        <mat-option [value]="4" *ngIf="typeselected == 'text'">4 </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft" >
                                <div formArrayName="feedback_main_question_text_options" [class]="addingClass" *ngIf="typeselected == 'text'">
                                    <div *ngFor="let option of dataForm.get('feedback_main_question_text_options')['controls']; let j=index;"
                                        [formGroupName]="j" class="boxthekeyvalue">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>Text feedback {{j+1}}</p>
                                            </div>
                                            <div class="col-md-12">
                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Text feedback for {{j+1}} star</mat-label>
                                                    <input class="textName" matInput formControlName="fbtxtoptn" (keydown)= commaValidation() required>
                                                    <mat-error>
                                                        Feedback is required and Comma is not Allowed.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 zeropadleft" *ngIf="typeselected == 'image'">
                                <div formArrayName="main_media_options" >
                                    <h4 style="margin:0 0 20px">Choose Images</h4>
                                    <div *ngFor="let option of dataForm.get('main_media_options')['controls']; let j=index"
                                        [formGroupName]="j" class="parentwrapper">
                                        <div class="uploader-parent">
                                            <div class="upload-btn-wrapper" *ngIf="option && typeselected == 'image'"
                                                [ngClass]="{'upload-btn-wrapper-active': option?.controls?.fbimgoptn?.value != null}">
                                                <div class="holder">
                                                    <div class="success-wraper"
                                                        *ngIf="option && option?.controls?.fbimgoptn?.value != null">
                                                        <i class="material-icons">
                                                            done
                                                        </i>
                                                        <p class="customlabel">{{allmediatitle[j]}}</p>
                                                    </div>

                                                    <div class="initial"
                                                        *ngIf="option && option?.controls?.fbimgoptn?.value == null">
                                                        <img src="assets/img/therapy/lesson/image.svg"
                                                            class="img-responsive">
                                                        <p class="customlabel">Choose image
                                                            {{j+1}}
                                                        </p>
                                                    </div>

                                                </div>
                                                <input matInput (click)="openmediabox(j)" formControlName="fbimgoptn"
                                                    readonly required />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                    <!-- <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Feedback Response</mat-label>
                                        <mat-select formControlName="response_files_array" required multiple>
                                            <mat-option *ngFor="let res of responseobj" [value]="res.value" class="camelcased select-tags-active">
                                                {{res.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Tags</mat-label>
                                    <mat-select formControlName="tags" required multiple #singleSelectTag>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmattag" ngModel
                                                (ngModelChange)="modelChangedTags()" ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content notranslate">
                                                    <i class="material-icons">
                                                        search
                                                    </i></div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.id"
                                            class="camelcased select-tags-active">
                                            {{tag.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>       

                          
                            <!-- <a (click)="openDialog()" style="cursor:pointer">Show Lottie</a> -->

                            <!-- <div class="col-md-12 zeropadleft nvfbcheckbox">
                                <mat-checkbox value="checked" (click)="changeValue(checked)"
                                    formControlName="ask_negative_feedback">Ask Question for negative feedback?
                                </mat-checkbox>
                            </div>

                            <div *ngIf="checked" class="zeropadleft">
                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Title</mat-label>
                                        <input matInput placeholder="Question for negative feedback"
                                            formControlName="negative_feedback_question">
                                    </mat-form-field>
                                </div>

                                <div class="col-md-12 zeropadleft">
                                    <div formArrayName="negative_feedback_options" class="mealbox">
                                        <div *ngFor="let option of dataForm.controls.negative_feedback_options.controls; let j=index"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <mat-form-field appearance="outline" class="full-width-formfield">
                                                <mat-label>Negative Feedback Options</mat-label>
                                                <input matInput placeholder="Negative feedback options"
                                                    formControlName="nvfboptn">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-12 feedbackbtn">
                        <button mat-flat-button type="submit" color="primary" class="centerbtn mt-15"
                            [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Feedback</button>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="modal-bg" *ngIf="!(dataForm?.value.feedback_main_question == '')">
                            <div class="iphone-subtitle">{{dataForm?.value?.feedback_main_question}}</div>
                            <div class="option-holder">
                                <div *ngIf="typeselected == 'text'">
                                    <div *ngIf="!(dataForm?.value?.fbtxtoptn == '')">
                                        <div *ngFor="let option of dataForm.controls.feedback_main_question_text_options.controls; let j=index"
                                            class="holder">
                                            <div *ngIf="option?.value?.fbtxtoptn" class="iphone-options">
                                                {{option?.value?.fbtxtoptn}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="typeselected == 'image'">
                                    <div *ngFor="let option of allmedia; let k = index" class="image-holder">
                                        <img [src]="s3url + option"
                                            alt="Wellthy Care" class="img-responsive img-fit">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>