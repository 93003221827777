<form [formGroup]="mediainfoForm" (ngSubmit)="onsubmit()">
    <div formArrayName="mediadatas" class="mealbox">
        <div *ngFor="let media of mediainfoForm.get('mediadatas')['controls']; let j=index" [formGroupName]="j">
            <div class="clearfix"><br/></div>  
          <div class="showbox col-md-4">
                <div class="image-preview-upload" *ngIf="media.value.type == 'image'">
                    <img [src]="media.value.url">
                </div>
                <div class="image-preview-upload" *ngIf="media.value.type == 'video'">
                    <video style="width:100%" [src]="media.value.url" controls></video>
                </div>
                <div class="image-preview-upload" *ngIf="media.value.type.includes('audio')">
                    <audio controls style="height:43px;width:100%;">
                        <source [src]="media.value.url" [type]="media.value.type">
                      </audio>
                </div>
            </div>
          <div class="col-md-8 bottom-margin-sm">
              <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" formControlName="title" type="text" value=""/>
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Description</mat-label>
                  <input matInput placeholder="Description (optional)" formControlName="detailed_text"  type="text" value=""/>
              </mat-form-field>
              <p *ngIf="media.value.type.includes('audio')">Choose Thumbnail</p>
              <file-upload formControlName="audio_thumb" class="file-wrapper" simple *ngIf="media.value.type.includes('audio')"></file-upload>
          </div>
        </div>
    </div>
    <button mat-button type="submit" mat-flat-button color="primary" [disabled]="!mediainfoForm.valid" class="uploadmodalbtn">Upload and Save Media</button>
</form>
