import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  sysDataLoadingFlag = true;
  sysNoDataFlag = true;
  private debug: boolean = environment.debug;
  constructor(public snackBar: MatSnackBar, private sanitizer: DomSanitizer) { }

  getorg() {
    return localStorage.getItem('orgid');
  }
  
  showSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['scarlet-snack-bar']
    });
  }

  showSuccessSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['therapy-success-snack-bar']
    });
  }

  showOnboardingSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['onboarding-snackbar']
    })
  }

  returnDataLoadingImage(messgae?: string) {
    return this.sanitizer.bypassSecurityTrustHtml('<div class="emptyplaceholder" *ngIf="sysDataLoadingFlag"><img src="assets/img/Loading.gif"/><br/> <p style="text-align: center;color: #555;"><strong>Loading Awesomeness...<strong></p></div>');
  }

  returnNoDataImage(messgae?: string) {
    return '<div class="emptyplaceholder" *ngIf="sysNoDataFlag"><img src="assets/img/Crane.gif"/><br/> <p style="text-align: center;color: #555;">OOPS. No Data to Show.</p></div>';
  }

  handleErrorResponse(error: any) {
    
    switch (error.status) {
      case 500:
        this.showSnackBar('Internal server error.', 'Ok');
        break;
      case 400:
      case 403:
      case 422:
        this.showSnackBar(error.error.message ? error.error.message : error.message, 'Ok');
        break;
      case 404:
        this.showSnackBar('Sorry, the page you are looking for could not be found.', 'Ok');
        break;
      case 405:
        this.showSnackBar('Sorry, Invalid method request.', 'Ok');
        break;
      case 401:
        this.showSnackBar('Sorry, Invalid Token.', 'Ok')
        break;
      default:
        this.showSnackBar('Something went wrong. Try after some time.', 'Ok');
    }
  }
}
