<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>
    <ng-template #buttonSubmitLoader>
        <app-loader showLoader="{{true}}"></app-loader>
    </ng-template>
    <div class="sidenav-content">
        <div class="col-md-10 col-md-offset-1">

            <div class="media knowusercontent">
                <div class="media-left media-middle">
                    <div class="card-avatar">
                        <img [src]="s3url + localStoredHCData.profile_image.path" />
                    </div>
                </div>
                <div class="media-body">
                    <div class="clearfix">
                        <h4 class="media-heading pull-left">{{localStoredHCData.name}}</h4>
                        <div class="jeg_nav_item jeg_dark_mode pull-right">
                            <label class="dark_mode_switch">
                                <input type="checkbox" class="jeg_dark_mode_toggle" type="checkbox" id="checkbox"
                                    [(ngModel)]=isThemeDark (change)="toggleDarkTheme(isThemeDark)">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <p>{{localStoredHCData.phone}} &nbsp; • &nbsp; {{localStoredHCData.email}}</p>
                </div>
            </div>

            <!-- <mat-card class="hcprofile-card knowusercontent">
                <mat-card-header>
                    <div mat-card-avatar><img [src]="s3url + localStoredHCData.profile_image.path" />
                    </div>
                    <mat-card-title>{{localStoredHCData.name}}</mat-card-title>
                    <mat-card-subtitle>{{localStoredHCData.phone}} &nbsp; • &nbsp; {{localStoredHCData.email}}
                    </mat-card-subtitle>
                </mat-card-header>
            </mat-card> -->
            <hr style="margin-top: 0px !important;" />
            <div>
                <h4 class="h4title">Going on leave</h4>
                <p class="goingonleavedesc">If your'e going on leave, Click on the button below so we should not assign
                    any users to you during
                    your vacations. When you come back kindly click again to resume the work.</p>
                <!-- <br /> -->
                <!-- <button mat-flat-button color="primary" class="centerbtn pull-left" (click)="updateAccountStatus()"
                    *ngIf="loadingSubject | async; else buttonSubmitLoader">{{isAccountActive ? 'Deactivate' : 'Activate'}}
                    Account</button> -->
            </div>

            <div class="clearfix"><br /><br /><br /></div>
            <hr style="margin-top: 0px !important;" />
            <mat-button-toggle-group [(ngModel)]="profileselection">
                <mat-button-toggle value="summary" checked>Summary</mat-button-toggle>
                <mat-button-toggle value="logs">Call Logs</mat-button-toggle>
                <!-- <mat-button-toggle value="applyleave">Apply leave</mat-button-toggle> -->
            </mat-button-toggle-group>
            <div class="clearfix"><br /></div>
            <div class="col-md-12" *ngIf="profileselection == 'logs'">
                <div class="row">
                    <div class="chartcontainer">
                        <div class="chartheader" style="padding-bottom: 18px;width:100%;">
                            <h5>Select Date range to get started</h5>
                            <form [formGroup]="callogForm" validate class="filter-form">
                                <mat-form-field (click)="$event.stopPropagation();" appearance="outline"
                                    class="col-md-4" style="padding:0;">
                                    <mat-label>Choose start date</mat-label>
                                    <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="morrow"
                                        placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline" (click)="$event.stopPropagation();"
                                    class="col-md-4" style="padding-right:0;">
                                    <mat-label>Choose end date</mat-label>
                                    <input matInput formControlName="enddate" [matDatepicker]="picker2"
                                        placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <button mat-flat-button color="primary" class="col-md-2 col-md-offset-2"
                                    (click)="applylogfilter()"
                                    style="float: right;margin-top: 5px;height: 48px;">Apply</button>
                            </form>
                        </div>
                        <div class="wrap" *ngIf="calldata && calldata?.length != 0">
                            <table class="head">
                                <tr>
                                    <th>Called at</th>
                                    <th>Phone number</th>
                                    <th>Recording</th>
                                </tr>
                            </table>
                            <div class="inner_table">
                                <table>
                                    <tr *ngFor="let ab of calldata; let i = index;">
                                        <td>
                                            <span
                                                style="display: inline-block;float:left;width:20px;font-weight: bold;">{{i+1}}
                                            </span>{{convertDate(ab.start_time)}}
                                        </td>
                                        <td>{{ab.customer_number}}</td>
                                        <td><audio [src]="ab.call_recording" style="width:250px;" controls
                                                controlsList="nodownload noremoteplayback"
                                                disablepictureinpicture></audio></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="!(calldata && calldata?.length != 0)" class="nodatacalllog">
                            No Data
                        </div>
                    </div>
                </div>
                <div class="clearfix"><br /><br /></div>
            </div>
            <div class="col-md-12" *ngIf="profileselection == 'summary'">
                <div class="row" style="display: flex;">
                    <div class="col-md-6 zeropadleft p-0 mr-15 chartcontainer">
                        <div class="">
                            <div class="chartheader" style="width: 100%;">
                                <div *ngIf="todaytimedata && todaytimedata.total_logs"
                                    style="text-align: center;padding: 10px;">
                                    <div>

                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col-sm-6" style="margin-left: -1%;margin-bottom: -5%;    inline-size: auto;">
                                                <strong>{{loggedData}}</strong>
                                                Logged today
                                            </div>
                                            <div class="col-sm-3"></div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col-sm-6" style="margin-left: -1%;margin-bottom: -5%;    inline-size: auto;"> <strong>{{breaktime}}</strong> Idle Time
                                            </div>
                                            <div class="col-sm-3"></div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col-sm-6" style="margin-left: -1%;inline-size: auto;"> <strong>{{adminLogTime}}</strong>
                                                Admin Logged
                                                today</div>
                                            <div class="col-sm-3"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="chartsection">
                                <table *ngIf="todaytimelogdata" infiniteScroll [infiniteScrollDistance]="1"
                                    [infiniteScrollThrottle]="50" (scrolled)="gettodayNextSet()" [scrollWindow]="false"
                                    id="hcloggingcss" class="hcloggingtodaycss table hc-log-table">
                                    <tbody>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th> Session Start Time</th>
                                            <th> Session Duration</th>
                                        </tr>
                                        <tr *ngFor="let ab of todaytimelogdata; let i = index;">
                                            <td *ngIf="ab.log_type=='active'">{{i + 1}}</td>
                                            <!-- <td>{{convertDate(ab.start_time)}}</td>-->
                                            <td *ngIf="ab.log_type=='active'">{{ab.start_time | date: 'MM/dd/yyyy h:mm
                                                a' : '+0530'}}</td>
                                            <td *ngIf="ab.log_type=='active'">{{checkSession(ab)}} Minute</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-0 chartcontainer ml-15">
                        <div class="">
                            <div class="chartheader" style="padding-bottom: 18px;">
                                <strong style="padding-top: 10px;display: inline-block;">All Time logs</strong>
                                <button mat-flat-button color="primary" class="primary"
                                    (click)="genericcount=1;applymainfilter()" style="float: right"
                                    [disabled]="!dataForm.valid">Apply</button>
                                <div class="clearfix"><br /></div>
                                <h5>Select Date range to get started</h5>
                                <form [formGroup]="dataForm" validate class="filter-form">
                                    <mat-form-field (click)="$event.stopPropagation();" appearance="outline"
                                        class="col-md-6" style="padding:0;" required>
                                        <mat-label>Choose start date</mat-label>
                                        <input matInput formControlName="startdate" [matDatepicker]="picker1"
                                            [matDatepicker]="picker1" (dateInput)="addEvent('input', $event)"
                                            (dateChange)="addEvent('change', $event)" [max]="morrow"
                                            placeholder="Choose a date">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field required appearance="outline" (click)="$event.stopPropagation();"
                                        class="col-md-6" style="padding-right:0;">
                                        <mat-label>Choose end date</mat-label>
                                        <input matInput formControlName="enddate" [matDatepicker]="picker2"
                                            [min]="currentdate" [max]="newDate" [max]="morrow"
                                            placeholder="Choose a date">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </form>
                            </div>
                            <div class="eq-height">
                                <div *ngIf="timedata && timedata.total_logs" style="text-align: center;padding: 10px;">
                                    <strong>{{getroundown((timedata.total_logs * 6) / 60)}} Hrs
                                        {{(timedata.total_logs * 6) % 60 | number : '1.0-0'}} Min </strong> Logged for the
                                    selected range
                                </div>
                                <table *ngIf="timedata" infiniteScroll [infiniteScrollDistance]="1"
                                    [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false"
                                    id="hcloggingcss" class="hcfiltertable table"
                                    style="height: 228px !important;margin-bottom:0px;">
                                    <tbody>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Time</th>
                                            <th> Session Duration</th>
                                        </tr>
                                        <tr *ngFor="let ab of timelogdata; let i = index;">
                                            <td>{{i + 1}}</td>
                                            <!--<td>{{convertDate(ab.start_time)}}</td>-->
                                            <td>{{ab.start_time | date: 'MM/dd/yyyy h:mm a' : '+0530'}}</td>
                                            <!-- <td>5 Min</td> -->
                                            <td>{{checkSession(ab)}} Minute</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"><br /><br /></div>
            </div>
            <div class="clearfix"><br /><br /><br /></div>
            <div>
                <h4 class="h4title">Or just</h4>
                <br><br>
                <button mat-raised-button class="centerbtn pull-left" (click)="logoutAccount()">
                    <i class="material-icons">
                        exit_to_app
                    </i> Logout from all devices</button>
                <div class="clearfix"><br /><br /></div>
                <div class="col-md-6 p-0">
                    <a href="https://documents.wellthy.app/termsandcondition" target="_blank" class="tncandpolicy" rel="noopener">Terms
                        and Condition</a>
                    <a href="https://documents.wellthy.app/privacypolicy" target="_blank" class="tncandpolicy" rel="noopener">Privacy
                        Policy</a>
                    <a href="mailto:helpdesk@wellthy.care?subject=Opt out from Healthcoach&body=Hi, I want to opt out from healthcoach dashboard."
                        target="_blank" class="tncandpolicy">Opt Out</a>
                </div>
                <div class="clearfix"><br /></div>
            </div>
            <div class="clearfix"><br /><br /></div>
            <!-- <div class="col-md-8" *ngIf="profileselection == 'manualtime'">
                    <div class="row">
                        <form>
                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <input matInput [matDatepicker]="picker" placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                              
                            </div>
                            <div class="clearfix"><br/></div>
                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield"  [hideRequiredMarker]="true">
                                    <mat-label>From</mat-label>
                                    <input type="text" matInput placeholder="Morning Start Time" [ngxTimepicker]="mspicker2"
                                            >
                                    <ngx-material-timepicker #mspicker2></ngx-material-timepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield"  [hideRequiredMarker]="true">
                                    <mat-label>To</mat-label>
                                    <input type="text" matInput placeholder="Morning Start Time" [ngxTimepicker]="mspicker1"
                                            >
                                    <ngx-material-timepicker #mspicker1></ngx-material-timepicker>
                                </mat-form-field>
                            </div>
                            <div class="clearfix"><br/></div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Memo</mat-label>
                                    <textarea matInput placeholder="Write the description"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="clearfix"><br/></div>
                            <p>All manual times must be aproved by the manager</p>
                            <div class="clearfix"><br/></div>
                            <button mat-stroked-button color="primary" class="centerbtn">Cancel</button>
                            <button mat-flat-button color="primary" class="centerbtn mleft20">Save</button>

                        </form>
                    </div>
                  </div>
                  <div class="col-md-8" *ngIf="profileselection == 'applyleave'">
                      <div class="row">
                          <form>
                              <div class="col-md-6 zeropadleft">
                                  <mat-form-field appearance="outline" class="full-width-formfield"  [hideRequiredMarker]="true">
                                      <mat-label>From</mat-label>
                                      <input type="text" matInput placeholder="Morning Start Time" [ngxTimepicker]="mspicker2"
                                              >
                                      <ngx-material-timepicker #mspicker2></ngx-material-timepicker>
                                  </mat-form-field>
                              </div>
                              <div class="col-md-6 zeropadleft">
                                  <mat-form-field appearance="outline" class="full-width-formfield"  [hideRequiredMarker]="true">
                                      <mat-label>To</mat-label>
                                      <input type="text" matInput placeholder="Morning Start Time" [ngxTimepicker]="mspicker1"
                                              >
                                      <ngx-material-timepicker #mspicker1></ngx-material-timepicker>
                                  </mat-form-field>
                              </div>
                              <div class="clearfix"><br/></div>
                              <div class="col-md-12 zeropadleft">
                                  <mat-form-field appearance="outline" class="full-width-formfield">
                                      <mat-label>Memo</mat-label>
                                      <textarea matInput placeholder="Write the description"></textarea>
                                  </mat-form-field>
                              </div>
                              <div class="clearfix"><br/></div>
                              <p>All leaves must be aproved by the manager</p>
                              <div class="clearfix"><br/></div>
                              <button mat-stroked-button color="primary" class="centerbtn">Cancel</button>
                              <button mat-flat-button color="primary" class="centerbtn mleft20">Save</button>
  
                          </form>
                      </div>
                    </div>  -->
        </div>
    </div>
</mat-drawer-container>