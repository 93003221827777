import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { isObject } from 'rxjs/internal/util/isObject';
import { DeletemodalComponent } from '../../shared/deletemodal/deletemodal.component';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-questionnairmapping',
  templateUrl: './questionnairmapping.component.html',
  styleUrls: ['./questionnairmapping.component.scss'],
  animations: [detailExpandTable],
})

export class QuestionnairmappingComponent implements OnInit {
  displayedColumns: string[] = ["client_name", "condition_name"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  activecls = 'active';
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  public tags: any;
  public filteredtabulardata: any;
  public typeFilters: any;
  public filteredtypes: any = [];
  public status: any = 'active';

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Questionnaire Mapping');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected questionnair mapping has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }


  // To Delete Data
  // ----------------------------

  delete(id) {
    this.contentService.hardDelete('questionnairemapping', id)
    .subscribe((res: any) => this.onDeleteSuccess(id),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  typeFilter(ab) {
    this.filteredtypes = ab;
    if (ab.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTypes);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTypes(t) {
      if (t.type.split(',').filter(typeItem => ab.includes(typeItem)).length > 0) {
        return true;
      }
    }
  }


  // Active Deactive Chapter
  // ----------------------------

  isActive(status, id) {
    this.contentService.setStatus(status, 'questionnairemapping', id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }


  // List Questionnaire Mapping 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.removeHTMLtags(this.searchcriteria);
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getMappingData(this.searchcriteria, this.page.toString(), "20", this.status, "questionnairemapping")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
  // ----------------------------


}
