import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from '../shared/deletemodal/deletemodal.component';


@Component({
  selector: 'app-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class MealComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['food_name', 'food_group', 'cuisine', 'updatedat',"action"];
   public isactive = 'all';
  public newsection: any = 'new';
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tagFilters: any;
  tabulardata: any = [];
  filterdata: any = [];
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public typeFilters: any;
  public filteredtabulardata: any;
  public fliterval: any;
  activecls = 'active';
  public filteredtypes: any = [];
  public showButtonLoader: boolean = false;
  public userdata: any;
  public user_data: any;
  public status = "active";
  public update_role_data: any;
  public localStoredData: any;
  public filteredtags: any = [];

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService,private dialog: MatDialog,private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Meal');
  }
  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  tagfilter(fln) {
    this.filteredtags = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.tags.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }

  // ----------------------------

  // Active Deactive Chapter
  // ----------------------------

  // isActive(status, id) {
  //   this.contentService.setStatus(status, 'mealData', id)
  //     .subscribe(
  //       (res: any) => this.onStatusSuccess(status, id),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  // }

  // private onStatusSuccess(status, id) {
  //   this.tabulardata.forEach((item) => {
  //     if (item.id === id) {
  //       item.is_active = !status;
  //     }
  //   });
  //   this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  // }

  isActive(status, id) {
    let value;
    if(status){
      value = "Deactivate"
    }else{
      value = "Activate"
    }
    let header = "Are you sure you want to "+""+ value +" "+ "Meal?";
    localStorage.setItem('modal_header',header);
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        localStorage.removeItem("modal_header");
        this.contentService.setStatus(status, 'mealData', id)
        .subscribe(
          (res: any) => this.onChangeStatusSuccess(status, id,value),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Meal has been '+ value+'d', 'Ok', {
          duration: 2000,
        });
      }
    });
    
  }

  private onChangeStatusSuccess(status, id,value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // ----------------------------

  // List Meal 
  // ----------------------------
  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getAllData(this.searchcriteria, this.page.toString(), "20", this.status, "mealData")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
    else {
      this.page = 1;
      this.lastpage = 0;
      this.search();
    }

  }

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Meal has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  delete(id) {
    this.contentService.hardDelete('meal', id)
    .subscribe(
      (res: any) => this.onDeleteSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }
}

