<div class="plan-details d-flex" style="height: 500px;overflow: visible;" *ngIf="plandata && plandata.length > 0">
  <h3 class="text-center title">Select payment plan</h3>
  
  <form [formGroup]="dataForm">
  <div class="plans mat-elevation-z2" *ngFor="let pl of plandata; let ip=index;">
    <label for="{{'radItem' + ip}}" class="md-radio has--add-text clearfix">
      <div class="pull-left">
        <input type="radio" id="{{'radItem' + ip}}" value="{{pl?.id}}" class="md-radio__input" formControlName="planid">
        <span class="md-radio__custom">
          <span class="md-radio__custom-inner"></span>
        </span>
        <div class="md-radio__label-text">
          {{pl?.title}}
          <small class="md-radio__add-text">{{pl?.payment_type == 'onetime' ? 'One Time' : 'Subscription - ' + pl?.payment_duration + 'Days'}}</small>
        </div>
      </div>
      <div class="price pull-right">₹ {{pl?.price}}</div>
    </label>
  </div>

  <div class="send-btn text-center">
    <button mat-flat-button color="primary" class="submit-btn" (click)="createPaymentlink()" [disabled]="!dataForm.valid">
      Send Link</button> </div>
  </form>
</div>

<div class="plan-details d-flex" *ngIf="(!plandata || plandata.length == 0) && !loading">
  <h3 class="text-center title">{{errormessage}}</h3>
</div>
<div class="plan-details d-flex" *ngIf="loading">
  <h5 class="text-center title">Fetching Plans</h5>
</div>