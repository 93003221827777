<!--==========================================
Title: HC Time log graph
Author: Mandeep singh
Date:   28 April 2020
Last Change : UI CHNAGES
===========================================-->

<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">

    <div class="hc-time-log-container">
      <div class="col-md-12 no-padding">
        <h3 class="commontitleprimary hc-timelog-head col-md-6 camelcased"><button mat-icon-button class="commonbackbtn"
            [routerLink]="['/admin/hchome']">
            <i class="material-icons">keyboard_arrow_left</i>
          </button>
          <span class="title-span">{{userdata?.display_name}}'s Time log</span>
        </h3>
        <div class="col-md-6 text-end">
          <button mat-flat-button class="download-report-btn" *ngIf="false">
            Download Report
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
      <div class="wrapper col-md-12">
        <div class="title-in-border col-md-12">
          <h1><span>Today's stats</span></h1>
          <div class="stats-data">

            <div class="data">
              <p class="key">Logged in at</p>
              <p class="value" *ngIf="showdata && firstloggeddate">{{convertTime(firstloggeddate?.start_time)}}</p>
              <p class="value" *ngIf="!firstloggeddate ">-</p>
            </div>
            <div class="data data-timelogged">
              <p class="key">Time Logged Today(Admin)</p>
              <p class="value" *ngIf="showdata">{{adminLogTime}}</p>
              <!--{{getroundown((hc_data.data.admin_total_minutes * 6) / 60)}} Hrs
                {{(hc_data.data.admin_total_minutes * 6) % 60 | number : '1.0-0'}} Min -->
              <p class="value" *ngIf="!showdata">-</p>
            </div>
            <div class="data">
              <p class="key">Productivity</p>
              <p class="value" *ngIf="showdata">{{hc_data.data.productivity | number : '1.2-2'}}%</p>
              <p class="value" *ngIf="!showdata">-</p>
            </div>
            <div class="data data-breaks">
              <p class="key">Breaks</p>
              <p class="value" *ngIf="showdata">{{hc_data.data.total_breaks}}</p>
              <p class="value" *ngIf="!showdata">-</p>
            </div>

            <br />

            <div class="data">
              <!-- <p class="key">Logged in at</p>
              <p class="value" *ngIf="showdata && firstloggeddate">{{convertTime(firstloggeddate?.start_time)}}</p>
              <p class="value" *ngIf="!firstloggeddate ">-</p> -->
            </div>
            <div class="data data-timelogged">
              <p class="key">Time Logged Today(HealthCoach)</p>
              <p class="value" *ngIf="showdata">{{HCLoggedValue}}</p>
              <!--{{getroundown((hc_data.data.total_logs * 6) / 60)}} Hrs
                {{(hc_data.data.total_logs * 6) % 60 | number : '1.0-0'}} Min-->
              <p class="value" *ngIf="!showdata">-</p>
            </div>
            <div class="data">
              <!-- <p class="key">Productivity</p>
              <p class="value" *ngIf="showdata">{{hc_data.data.productivity | number : '1.2-2'}}%</p>
              <p class="value" *ngIf="!showdata">-</p> -->
            </div>
            <div class="data data-breaks">
              <!-- <p class="key">Breaks</p>
              <p class="value" *ngIf="showdata">{{hc_data.data.total_breaks}}</p>
              <p class="value" *ngIf="!showdata">-</p> -->
            </div>

          </div>
        </div>
        <div class="clearfix"></div>
        <div class="weekly-graph displ">
          <div class="row d-flex">
            <div class="col-md-5">
              <button mat-flat-button type="button" class="nonactivebtn"
                (click)="showActiveButton('today');timelogs(localStoredHCData,'today');"
                [class.activebtn]="activeButton === 'today'">Today</button>
              <button mat-flat-button type="button" class="nonactivebtn"
                (click)="showActiveButton('weekly');timelogs(localStoredHCData,'weekly');"
                [class.activebtn]="activeButton === 'weekly'">This Week</button>
              <button mat-flat-button type="button" class="nonactivebtn"
                (click)="showActiveButton('monthly');timelogs(localStoredHCData,'monthly');"
                [class.activebtn]="activeButton === 'monthly'">This Month</button>
            </div>
            <div class="col-md-7">
              <form [formGroup]="dataForm" validate class="filter-form">
                <mat-form-field (click)="$event.stopPropagation();" appearance="outline" class="col-md-4">
                  <mat-label>Start date</mat-label>
                  <input matInput formControlName="startdate" [matDatepicker]="picker1"
                    (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [max]="morrow"
                    placeholder="Choose a date">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-4">
                  <mat-label>End date</mat-label>
                  <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="currentdate"
                    [max]="newDate" placeholder="Choose a date">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <div class="col-md-4 pr-0">
                  <button mat-flat-button color="primary" class="primary apply-btn btn-block"
                    (click)="showActiveButton('random');timelogs(localStoredHCData,'random')"
                    [disabled]="!dataForm.valid">Apply</button>
                </div>
              </form>
            </div>
          </div>


          <div class="bar-chart-data">
            <div class="logged-week-time">
              <span>HC Time log</span>
            </div>
            <div id="chart_wrapper">
              <div id="productivityChart" style="height: 500px;"></div>
            </div>
          </div>

          <!-- <div id="chart_wrapper"> 
            <div id="productivityChart" style="height: 500px;"></div>
             </div> -->

          <!-- <div id="dashboard_div">
              <div id="control_div" style="width: 100%; height: 100%"></div>
              <div id="daily_container_count_lineChart" style="width: 100%; height: 100%"></div>
            </div> -->
          <!-- <div id="dashboard">
              <div id="control-date"></div>
              <div id="chart-line"></div>
            </div> -->
          <!-- <div id="dashboard">
              <div id="chart" style='width: 915px; height: 300px;'></div>
              <div id="control" style='width: 915px; height: 50px;'></div>
          </div> -->

        </div>
        <div class="col-md-12 no-padding log-table" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">

          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 log-time-table">

            <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="sr_no">
              <th mat-header-cell *matHeaderCellDef> Sr.No. </th>
              <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef> Time </th>
              <td mat-cell *matCellDef="let element"> {{convertDate(element.start_time)}}</td>
            </ng-container>

            <!-- Weight Column -->
            <!-- <ng-container matColumnDef="break_time">
        <th mat-header-cell *matHeaderCellDef> Break Time </th>
        <td mat-cell *matCellDef="let element"> {{element.break_time}} </td>
      </ng-container> -->

            <!-- Symbol Column -->


            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
          </table>

        </div>


      </div>
    </div>
  </div>
</mat-drawer-container>