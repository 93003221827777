import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { MobileNumberChangeAPIService } from 'src/app/shared/services/mobile-number-change-api.service';

@Component({
  selector: 'app-number-change-action',
  templateUrl: './number-change-action.component.html',
  styleUrls: ['./number-change-action.component.scss']
})

export class NumberChangeActionComponent implements OnInit {

  public reason = "";
  public reasonDisabled = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NumberChangeActionComponent>,
    private apiService: MobileNumberChangeAPIService,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(false);
  }

  callAction(){
    this.apiService.phoneChangeRequestAction(this.data.requestId, this.data.action, this.data.adminId, this.data.adminName, this.reason)
    .subscribe((data) => {
      
      if (data.status === 200){

        if (this.data.action === 1){
          this.systemService.showSuccessSnackBar("Approved Successfully", "Ok");
        } else {
          this.systemService.showSuccessSnackBar("Rejected Successfully", "Ok");
        }

        this.dialogRef.close(true);

      }

    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }

  reasonChange(event){

    let reason = event;
    let trimmed = reason.replaceAll(" ", "");

    if (trimmed.length !== 0 && event.length !== 0){
      this.reasonDisabled = false;
    }
  }
}
