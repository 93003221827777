import { CreateHospitalComponent } from './create-hospital/create-hospital.component';
import { HospitalListComponent } from './hospital-list/hospital-list.component';
import { ClientDoctorAdminComponent } from './client-doctor-admin/client-doctor-admin.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { DoctorRoutingModule } from './doctor.routing.module';
import { DoctoruserComponent } from './doctor.component';
import { EditdoctorComponent } from './editdoctor/editdoctor.component';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { ChangePasswordHistoryComponent } from './change-password-history/change-password-history.component';
import { DocleftnavModule } from 'src/app/doctor/shared/docleftnav/docleftnav.module';

@NgModule({
  declarations: [
    
    DoctoruserComponent,
    EditdoctorComponent,
    ChangePasswordHistoryComponent,
    ClientDoctorAdminComponent,
    HospitalListComponent,
    CreateHospitalComponent
    
  ],
  imports: [
    CommonModule,
    DoctorRoutingModule,
    ImageCropperModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    DocleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class DoctorModule {}