import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
​
@Component({
  selector: 'app-editlevel',
  templateUrl: './editlevel.component.html',
  styleUrls: ['./editlevel.component.scss'],
  encapsulation: ViewEncapsulation.None
​
})
​
export class EditlevelComponent implements OnInit, OnDestroy {
  complexityenum: any = complexityenum;
  public showButtonLoader: boolean = false;
  alltags: any;
  public selfparentid: any;
  public newitem: boolean = false;
  chooseMedia: any;
  public childid: any;
  public langid: any;
  allmodules: any;
  leveldata: any;
  levelpatchdata: any;
  modulecount: any;
  public selfparentide: any;
  public language: any;
  s3url: any = environment.s3url;
  public allmedia: any;
  public imageData: any;
  public selectedoptn: any = [];
  public control1: any = [];
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: [''],
    complexity_level: ['', Validators.required],
    image_media: ['', Validators.required],
    module_count: ['', Validators.required],
    module_data: this.fb.array([]),
    tags: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
  });
​
  public searchmat: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  filteredModules: Array<ReplaySubject<any[]>> = [];
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
​
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
​
  initModuleOptions() {
    return this.fb.group({
      moduledata: []
    })
  }
​
​
  constructor(private browserTitle: Title, private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService) { }
  
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Level');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getModules(data => {
      this.route.params.subscribe(params => {
        this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
        this.language = <FormArray>this.dataForm.controls.language_data_fk;
        this.langid = params.langid;
        if (params.id == 'new') {
          this.selfparentid = null;
          this.newitem = true;
          this.selfparentide.setValue(null);
          this.language.setValue(this.langid);
        } else {
          if (params.langid == 1 && params.chid == 0) {
            this.childid = params.id;
            this.selfparentid = null;
            this.newitem = false;
            this.getAllLevelData(this.childid);
          } else if (params.langid != 1 && params.chid == 0) {
            this.newitem = true;
            this.childid = params.id;
            this.selfparentid = params.id;
            this.getAllLevelData(this.childid);
          } else {
            this.childid = params.chid;
            this.newitem = false;
            this.selfparentid = params.id;
            this.getAllLevelData(params.chid);
          }
        }
        this.getTags();
      });
    });
  }
​
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
​
  modelChanged(event, index) {
    this.filterBanksMulti(index);
  }
​
  modelChangedTags() {
    this.filterTags();
  }
​
  private filterBanksMulti(index) {
    if (!this.allmodules) {
      return;
    }
    // get the search keyword
    let search = this.searchmat.value;
    if (!search) {
​
      this.filteredModules[index].next(this.allmodules.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredModules[index].next(
      this.allmodules.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }
​
  createModuleOptions(e) {
    this.modulecount = e;
    this.control1 = <FormArray>this.dataForm.controls['module_data'];
    if (this.control1.length !== 0) {
      if (this.control1.length < e) {
        for (let j = 0; j < e; j++) {
          this.filteredModules[j] = new ReplaySubject<any[]>(1);
          this.filteredTags[j] = new ReplaySubject<any[]>(1);
        }
        for (let l = this.control1.length; l < e; l++) {
          this.control1.push(this.initModuleOptions());
        }
        this.selectOptions();
      }
      if (this.control1.length > e) {
        for (let j = 0; j > e; j--) {
          this.filteredModules[j] = new ReplaySubject<any[]>(1);
          this.filteredTags[j] = new ReplaySubject<any[]>(1);
        }
        for (let l = this.control1.length; l > e; l--) {
          this.control1.removeAt(l - 1);
        }
        this.selectOptions();
      }
    }
    else {
      for (let j = 0; j < e; j++) {
        this.filteredModules[j] = new ReplaySubject<any[]>(1);
        this.filteredTags[j] = new ReplaySubject<any[]>(1);
      }
      for (var k = 0; k < e; k++) {
        this.control1.push(this.initModuleOptions());
      }
    }
  }
​
  selectOptions() {
    this.selectedoptn = [];
    if (this.control1 && this.control1.value) {
      for (let l = 0; l < this.allmodules.length; l++) {
        for (let k = 0; k < this.control1.value.length; k++) {
          if (this.allmodules[l].id == this.control1.value[k].moduledata) {
            this.selectedoptn[k] = this.allmodules[l];
          }
        }
      }
    }
  }
​
  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }
​
  selectedOptions(e, j) {
    for (let k = 0; k < this.allmodules.length; k++) {
      if (e == this.allmodules[k].id) {
        this.selectedoptn[j] = this.allmodules[k];
      }
    }
  }
​
  disableOptions(e) {
    for (let k = 0; k < this.selectedoptn.length; k++) {
      if (this.selectedoptn[k] && this.selectedoptn[k].id && e == this.selectedoptn[k].id) {
        return true;
      }
    }
  }
​

  // Get all level data
  // ----------------------------
​
  getAllLevelData(ide) {
    this.contentService
      .getOneDataById(ide, 'level')
      .subscribe((res: any) => this.ongetAllLevelDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
​
  ongetAllLevelDataSuccess(data) {
    this.levelpatchdata = data;
    this.modulecount = this.levelpatchdata.module_count;
    this.levelpatchdata.module_count = this.levelpatchdata.module_count.toString();
    this.createModuleOptions(this.modulecount);
    for (let k = 0; k < this.modulecount; k++) {
      let moduleformelement = (<FormArray>(this.dataForm.controls['module_data'])).at(k);
      moduleformelement['controls'].moduledata.setValue(this.levelpatchdata.module_data[k]);
      this.selectOptions();
    }
    this.dataForm.patchValue(this.levelpatchdata);
    this.getMedia(this.levelpatchdata.image_media);
    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
  }
​
​
  // Get all Tags
  // ----------------------------
​
  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
​
  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }
​
 // ----------------------------
​

  // To Get Modules
  // ----------------------------

  getModules(cb) {
    this.contentService
      .getGraphQlData('{getParentModules(status:true){id,title}}', false)
      .subscribe((res: any) => this.getModuleSuccess(cb,(res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getModuleSuccess(cb, data) {
    this.allmodules = data.getParentModules;
    cb(true);
    for (let j = 0; j < this.modulecount; j++) {
      this.filteredModules[j].next(this.allmodules.slice());
    }
  }
​
  // ----------------------------
​
  // Get all Media
  // ----------------------------
​
  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe((res: any) => this.ongetMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
​
  ongetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }
​
  // ----------------------------
​
​
  // Submitting Level Data
  // ----------------------------
​
  dataObject() {
    var postdata = this.dataForm.value;
    postdata.title = this.removeHTMLtags(postdata.title).toString();
    postdata.detailed_text = postdata.detailed_text.toString();
    postdata.complexity_level = postdata.complexity_level.toString();
    postdata.image_media = (postdata.image_media === '' || postdata.image_media === null || postdata.image_media === undefined) ? '' : postdata.image_media.toString();
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.module_count = postdata.module_count.toString();
    var modulearray = [];
    postdata.module_data.forEach(el => {
      modulearray.push(el.moduledata);
    });
    postdata.module_data = JSON.stringify(modulearray);
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'level')
        .subscribe(
          (res: any) => this.onCreateSuccess((res as any).data),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'level').subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }
​
  private onCreateSuccess(data) {
    this.leveldata = data;
    this.systemService.showSuccessSnackBar(("Level has been added"), "OK");
    this.router.navigate(['content/level']);
  }
​
  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Level has been updated"), "OK");
    this.router.navigate(['content/level']);
  }
​
  // ----------------------------
​
  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
​
    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }
​ removeHTMLtags(value:string){
  return value.replace( /(<([^>]+)>)/ig, '');
}
}
​
export enum complexityenum {
  High,
  Medium,
  Low
}
