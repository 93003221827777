import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { whiteSpaceValidation } from '../../../utility/validation'
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-createsymptommapping',
  templateUrl: './createsymptommap.component.html',
  styleUrls: ['./createsymptommap.component.scss']
})

export class CreatesymptommapComponent implements OnInit {
  public newitem: boolean = true;
  public showButtonLoader: any;
  public showAllcondition: boolean = false;
  public clientid: any;
  private myhash: any = {};
  private myidhash: any = {};
  public allsymptoms: any;
  allconditions: any;
  public conditionid: any;
  public mappingid: any;
  public donepatcharr: any;
  todo = [];
  done = [];
  //getListedSymptomConditions:any=[];
  symptomData: any;
  public dataForm = this.fb.group({
    condition_id_data_fk: ["", [Validators.required, whiteSpaceValidation]],
    symptom_ids: this.fb.array([]),
    created_by_data_fk: ['5']
  });

  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Symptom Map');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getListedSymptomConditions();
    this.getConditions();
    this.route.params.subscribe(params => {
      this.clientid = params.id;
      this.conditionid = params.condid;
      this.mappingid = params.mappingid;
      if (params.condid == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        const conditionidval = <FormControl>this.dataForm.controls['condition_id_data_fk'];
        conditionidval.setValue(this.conditionid);
        this.getSymptomFlow(this.conditionid);
      }
    });

  }


  getListedSymptomConditions() {
    this.contentService.current__symptomData.subscribe(
      (response: any) => {
        this.symptomData = response;
      },
      (error: any) => {

      },
      () => { }
    )
  }


  checkSymptom(id) {
    for (let i = 0; i < this.symptomData.length; i++) {
      if (id == this.symptomData[i].condition_data_fk) {       
        return true;
      }    
    }  
  }





  // To Get Conditions
  // ----------------------------

  getConditions() {
    this.contentService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.onConditionsDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }


  private onConditionsDataSuccess(data) {
    data = (data as any).data;
    this.allconditions = data.getAllConditions;

    // let conditions: any = []
    // data = (data as any).data;
    // conditions = data.getAllConditions;
    // let a: any = [];
    // let b: any = [];
    // a = conditions;
    // b = this.symptomData;
    // const isSameUser = (a, b) => a.title === b.condition_title;
    // const onlyInLeft = (left, right, compareFunction) =>
    //   left.filter(leftValue =>
    //     !right.some(rightValue =>
    //       compareFunction(leftValue, rightValue)));
    // const onlyInA = onlyInLeft(a, b, isSameUser);
    // const onlyInB = onlyInLeft(b, a, isSameUser);
    // const result = [...onlyInA, ...onlyInB];
    // this.allconditions = result;
  }

  // ----------------------------


  // To Get SymptomFlow
  // ----------------------------

  getSymptomFlow(conditionid) {
    this.contentService.getSymptomPriorityForCondition(conditionid)
      .subscribe(
        (res: any) => this.onGetSymptomsFlowData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGetSymptomsFlowData(data) {
    data = (data as any).data;
    this.donepatcharr = data;
    this.dataForm.patchValue(this.donepatcharr);
    for (var k = 0; k < this.donepatcharr.length; k++) {
      this.done.push({
        id: this.donepatcharr[k].id,
        title: this.donepatcharr[k].title
      })
    }
    this.getSymptoms(this.conditionid);
  }

  // ----------------------------


  // To Get Symptoms
  // ----------------------------

  getSymptoms(condid) {
    this.todo = [];
    this.contentService.getAllSymptomsForCondition(condid)
      .subscribe(
        (res: any) => this.onGetSymptomsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGetSymptomsSuccess(data) {
    data = (data as any).data;
    this.allsymptoms = data;
    if (this.newitem == false) {
      for (let k = 0; k < this.donepatcharr.length; k++) {
        this.allsymptoms = this.allsymptoms.filter(a => a.id != this.donepatcharr[k].id);
      }
      this.allsymptoms.forEach(element => {
        this.myhash[element.id] = element.title;
        this.myidhash[element.title] = element.id;
      });
      this.todo = this.allsymptoms;
    }
    else {
      this.todo = this.allsymptoms;
      this.allsymptoms.forEach(element => {
        this.myhash[element.id] = element.title;
        this.myidhash[element.title] = element.id;
      });
    }
  }

  // ----------------------------

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  // Submitting Symptoms
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.symptom_ids = [];
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.condition_id_data_fk = postdata.condition_id_data_fk.toString();
    this.done.forEach(el => {
      postdata.symptom_ids.push(el.id);
    });
    postdata.symptom_ids = JSON.stringify(postdata.symptom_ids);

    if (this.done.length > 0) {
      if (this.newitem == true) {
        this.contentService.createSymptomPriority(postdata)
          .subscribe(
            (res: any) => this.onCreateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
      else {
        delete postdata.created_by_data_fk;
        this.contentService.updateSymptomPriority(postdata, this.mappingid)
          .subscribe(
            (res: any) => this.onUpdateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
    }
    else {
      this.systemService.showSnackBar(("Please drag the symptoms to the right"), "OK");
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Symptom flow has been prepared"), "OK");
    this.router.navigate(['content/symptom/mapping/']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Symptom flow has been updated"), "OK");
    this.router.navigate(['content/symptom/mapping/']);
  }

  // ----------------------------

}

