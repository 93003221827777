<div class="col-md-12">

    <div>
      <h2 style="text-align: center;" class="commontitleprimary1 text-capitalize" >
        Hardset OTP Change History </h2>
      
  
    </div>
    <br />
    <h2 class="commontitleprimary text-capitalize">
      <span style="color:var(--primary-orange);">{{userName}}</span>
    </h2>
    <div class="close-icon">
      <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()">
    </div>
  
    <div class="container-table" *ngIf="tabulardata.length != 0" infiniteScroll [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50" [scrollWindow]="false">
      <div class="spinner-container" *ngIf="showloader">
        <app-loader showLoader="{{true}}"></app-loader>
      </div>
      <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
  
        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef>User Id </th>
          <td mat-cell *matCellDef="let element">{{element.id}} </td>
        </ng-container>
  
        
  
        <ng-container matColumnDef="OTP">
          <th mat-header-cell *matHeaderCellDef> OTP</th>
          <td mat-cell *matCellDef="let element"> {{element.otp}} </td>
        </ng-container>
        <ng-container matColumnDef="is_active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let element"> {{element.is_active}} </td>
          </ng-container>
        <ng-container matColumnDef="beneficiary_name">
            <th mat-header-cell *matHeaderCellDef> Beneficiary name </th>
            <td mat-cell *matCellDef="let element"> {{element.beneficiary_name}} </td>
          </ng-container>
          <ng-container matColumnDef="requester_info">
            <th mat-header-cell *matHeaderCellDef> Requester info </th>
            <td mat-cell *matCellDef="let element"> {{element.requester_info}} </td>
          </ng-container>
          

        <ng-container matColumnDef="reason_for_hardset_otp">
            <th mat-header-cell *matHeaderCellDef> Reason for Hard-Set</th>
            <td mat-cell *matCellDef="let element"> {{element.reason_for_hardset_otp}} </td>
          </ng-container>
        
  
        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef> Change Password Date </th>
          <td mat-cell *matCellDef="let element"> {{convertDate(element.updated_at)}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;">
        </tr>
  
      </table>
    </div>  
    <div class="" *ngIf="tabulardata.length == 0">
      <p class="center">No password change history found for this user.</p>
    </div>
  </div>