import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/index';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { EditorinstructionsComponent } from '../../shared/editorinstructions/editorinstructions.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
declare var $: any;

@Component({
  selector: 'app-editmagazine',
  templateUrl: './editmagazine.component.html',
  styleUrls: ['./editmagazine.component.scss']
})

export class EditmagazineComponent implements OnInit {
  s3url: any = environment.s3url;
  categories = ['General','Healthy Eating','Being Active','Symptom Management','Self Monitoring','Medication','Mind & Body','First Trimester','  Second Trimester','Third Trimester','Other']
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }

  public dataForm = this.fb.group({
    title: ['', Validators.required],
    subtitle: [''],
    content: [''],
    raw_content: ['', Validators.required],
    // created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    image_media: ['', Validators.required],
    tags: ['', Validators.required],
    time_to_finish: ['', Validators.required],
    og_title: [''],
    og_description: [''],
    og_keywords: [''],
    og_author: [''],
    author_data: ['', Validators.required],
    type_data_fk: [''],
    image_url: [''],
    additionaltext: [''],
    comorbilities: [[], Validators.required],
  });

  public searchmatcat: FormControl = new FormControl();
  public filteredCat: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  title: any = 'New Magazine Article';
  htmlContent: any;
  public mode: any;
  chooseMedia: any;
  magazinedata: any;
  doUpload: any;
  disabled: boolean = true;
  secondarycondition: any;
  public newitem: boolean = true;
  public showButtonLoader: any;
  public alltags: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public allusers: any;
  public renderhtml: any;
  public allconditions: any;
  private _onDestroy = new Subject<void>();
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCatData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  myHtml: string;
  public allcategories: any;
  allCategory: any;

  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;
  
  constructor(
    private browserTitle: Title,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private contentService: ContentserviceService,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    protected sanitizer: DomSanitizer,
    private commonService:CommonserviceService
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Magazine');
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallMagazineData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallMagazineData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallMagazineData(params.chid);
        }
      }
      this.getCatList();
      this.getTags();
      this.getUsers();
      this.getConditions();
    });
  }

  ngAfterViewInit() {
  }

  uirender() {
    var scope = this;
    scope.renderhtml = $('.preview-panel').html();
    scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
  }

  additionalTextChange(e) {
    const rawcontent = <FormControl>this.dataForm.controls.raw_content;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    let checkHTML = this.commonService.isHTML(rawcontent.value);
    if(checkHTML !== true){
      additionaltextcontent.setValue(rawcontent.value);
    }else{
      rawcontent.setValue('');
      additionaltextcontent.setValue('');
    }
  }

  openinstructions(): void {
    const instructions = this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });

    instructions.afterClosed().subscribe(result => {
    });
  }


  // To get Conditions
  // ----------------------------

  getConditions() {
    this.contentService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}',false)
    .subscribe(
      (res : any) => this.onConditionsSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onConditionsSuccess(data) {
    data = (data as any).data;
    this.allconditions = data.getAllConditions;
  } 

  // ----------------------------

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }

  modelChangedTags() {
    this.filterTags();
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }



  modelChangedCat() {
    this.filterCat();
  }
  
  private filterCat() {
    if (!this.allcategories) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcat.value;
    if (!search) {
      this.filteredCatData.next(this.allcategories.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredCatData.next(
      this.allcategories.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  getCatList() {
    this.contentService
      .getGraphQlData('{getParentMagazineTypes(status:true){id,title}}', false )
      .subscribe((res: any) => this.getCatListSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCatListSuccess(data) {
    this.allCategory = data.getParentMagazineTypes;
    
    this.filteredCatData.next(this.allCategory.slice());
    
    this.initializeCategory();
  }

  initializeCategory() {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallMagazineData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallMagazineData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallMagazineData(params.chid);
        }
      }
    });
  }
  // To get All Magazines
  // ----------------------------

  getallMagazineData(ide) {
    this.contentService.getOneDataById(ide, 'magazine')
    .subscribe(
      (res : any) => this.onMagazineSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  
  private onMagazineSuccess(data) {
    data = (data as any).data;
    this.magazinedata = data;
    
    this.dataForm.controls.image_url.setValue(
      this.magazinedata.media_joined_data[0].path
    );
    this.getMedia(this.magazinedata.image_media);
    this.magazinedata.type_data_fk = this.magazinedata.type_data_fk.toString();
    this.dataForm.patchValue(this.magazinedata);
    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
    var scope = this;
    setTimeout(() => {
      scope.uirender();
    }, 1000);
  }

  // ----------------------------


  // To get Users Data
  // ----------------------------

  getUsers() {
    this.contentService.getGraphQlData('{getAllContentUsers(appMode:false, status:true){id,name,display_name}}')
    .subscribe(
      (res : any) => this.onUsersSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onUsersSuccess(data) {
    data = (data as any).data;
    this.allusers = data.getAllContentUsers;
  }

  // ----------------------------


  // Submitting Magazine Data
  // ----------------------------
  dataObject() {
    let postdata = this.dataForm.value;

    let title = this.commonService.isHTML( this.dataForm.value.title);
    let content = this.commonService.isHTML( this.dataForm.value.content);
    let sub_title = this.commonService.isHTML( this.dataForm.value.subtitle);
    let feedback = this.commonService.isHTML( this.dataForm.value.feedback);
    let additionaltext = this.commonService.isHTML( this.dataForm.value.additionaltext);
    let og_title = this.commonService.isHTML( this.dataForm.value.og_title);
    let og_description = this.commonService.isHTML( this.dataForm.value.og_description);
    let og_author = this.commonService.isHTML( this.dataForm.value.og_author);
    let og_keywords = this.commonService.isHTML( this.dataForm.value.og_keywords);

    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    if(content  === true){
      this.dataForm.controls.content.patchValue('')
      return;
    }
    if(sub_title  === true){
      this.dataForm.controls.sub_title.patchValue('')
      return;
    }
    if(feedback  === true){
      this.dataForm.controls.feedback.patchValue('')
      return;
    }
    if(additionaltext  === true){
      this.dataForm.controls.additionaltext.patchValue('')
      return;
    }
    if(og_title  === true){
      this.dataForm.controls.og_title.patchValue('')
      return;
    }
    if(og_description  === true){
      this.dataForm.controls.og_description.patchValue('')
      return;
    }
    if(og_author  === true){
      this.dataForm.controls.og_author.patchValue('')
      return;
    }
    if(og_keywords  === true){
      this.dataForm.controls.og_keywords.patchValue('')
      return;
    }
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.comorbilities = JSON.stringify(postdata.comorbilities);
    postdata.author_data = postdata.author_data.toString();
    postdata.image_media = postdata.image_media.toString();
    postdata.time_to_finish = postdata.time_to_finish.toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    // postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    postdata.type_data_fk = postdata.type_data_fk.toString();
    postdata.raw_content = postdata.raw_content.toString();
    postdata.content = $('.preview-panel').html();
    delete postdata.image_url;
    delete postdata.additionaltext;
    if (this.newitem == true) {
      postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
      this.contentService.postToBlade(postdata, 'magazine')
      .subscribe(
        (res : any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    } else {
      postdata.updated_by_data_fk = localStorage.getItem('scarletuserid').toString();
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'magazine')
      .subscribe(
        (res : any) => this.onUpadteSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
  }

  private onCreateSuccess () {
    this.systemService.showSuccessSnackBar(("Magazine has been added"), "OK");
    this.router.navigate(['content/magazine']);
  }

  private onUpadteSuccess () {
    this.systemService.showSuccessSnackBar(("Magazine has been updated"), "OK");
    this.router.navigate(['content/magazine']);
  }

  // ----------------------------


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService.getGraphQlData('{getParentTags(status:true){id,title}}',false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------


  // To Get Media
  // ----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
    .subscribe((res: any) => this.ongetMediaSuccess((res as any).data),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  )
  }
 
  private ongetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      var formelement = <FormArray>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });

  }
  ​ removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
