import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { catchError, map, retry, tap } from 'rxjs/internal/operators';
import { ContextService } from '../../healthcoach/services/context.service';
import { SocketService } from '../../healthcoach/services/socket/socket.service';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthenticateService extends HttpService {

  apiUrl: string = environment.serverUrl;
  private prodUrl = this.apiUrl + 'auth'
  private authSocialUrl = this.apiUrl + 'account/auth/social';
  private sessionDeleteUrl = this.apiUrl + 'users/session/';
  private refreshtoken = this.apiUrl + 'auth/token/verify';
  private refreshtoken1 = this.apiUrl + 'auth/token/refresh';
  private v3Social = this.apiUrl + 'auth/social';
  private clevertapUrl: any = environment.microserviceUrl;
  private clevertapAccountID: any = environment.clevertapAccountID;
  private ClevertapPassword: any = environment.ClevertapPassword;
  private enableCaptcha = environment.captcha;

  constructor( 
    private router: Router, 
    public appContext: ContextService, 
    private http: HttpClient, 
    private socketService: SocketService,
    private dialog: MatDialog,
  ) { 
    super(http);
  }

  user_data:any;
  captchaResponse: string | null;

  auth(email: string, password: string): Observable<[string]> {
   email = this.removeHTMLtags(email);
   password = this.removeHTMLtags(password);
    const loginData = {
      username: email,
      password: password
    }
    let reqHeaders = new HttpHeaders();
    reqHeaders = reqHeaders.set('Content-Type', 'application/json');

    if (this.enableCaptcha){
      reqHeaders = reqHeaders.set('captcha-response', this.captchaResponse);
    } 
    return this.post(this.prodUrl, loginData, { headers: reqHeaders})
  }

  //   generateCSRF():Observable<any>{
  //   return this.get(this.apiUrl,{ headers: new HttpHeaders().set('Content-Type', 'application/json'),withCredentials: true })
  // }

  get app(): string {
    return localStorage.getItem('app');
  }

  get accessToken(): string {
    return localStorage.getItem('scarlettoken');
  }

  setApp(app: string): void {
    if (app) {
      localStorage.setItem('app', app);
    } else {
      localStorage.removeItem('app');
    }  
  }

  switchOrganization(data)
  {
    this.configureApp(data);
    this.storeData(data);
    window.location.reload()
  }

  configureApp(data) {
    
    if(data){
      if (data.user_type === 'admin') {
        this.setApp('admin');
      }
      if (data.user_type === 'superadmin') {
        this.setApp('superadmin');
      }
      if (data.user_type === 'content') {
        this.setApp('content');
      }
      if (data.user_type === 'healthcoach') {
        this.setApp('healthcoach');
      }
      if (data.user_type === 'doctor') {
        this.setApp('doctor');
      }
      if (data.user_type === 'scarlet') {
        this.setApp('scarlet');
      }
    }
    else{
      this.logout()
    }
  }

  initializeData(data){

    let scope = this;
    localStorage.setItem('scarlettoken', data.token);
    this.storeData(data)
    this.appContext.showGlobalShimmer.next(true);
    this.appContext.userHasLoggedIn.next(true);

    let userACL = data.user_acl;
    let hopital_data = data.hospital_data
    if(hopital_data){
      localStorage.setItem('hopital_data',JSON.stringify(hopital_data));
    }
    
    if (userACL.includes("healthcoach")) {
      this.socketService.registerUser()
        .subscribe(data => {
          this.socketService.registerToken = data.token;
          this.socketService.getSocketConnection();
        })
      this.appContext.updateTimerLog.next(true)
    }
    if (userACL.includes("doctor") && !userACL.includes("admin") && data.doctor_type) {
      this.sendprofile().subscribe();

      let data = {
        "d": [
          {
            "identity": localStorage.getItem('scarletuserid'),
            "ts": (Math.floor(new Date().valueOf() / 1000)),
            "type": "event",
            "profileData":{
              "Name":JSON.parse(localStorage.getItem('scarletuserdata')).name,
              "Email":JSON.parse(localStorage.getItem('scarletuserdata')).email,
            },
            "evtName": "Doctor Logged In",
            "evtData": {
            }
          }
        ]
      }
      this.sendEventData(data)
        .subscribe(data => {
          setTimeout(function () {
            scope.router.navigate(['application']);
          }, 100);
        })
    }
    else {
      setTimeout(function () {
        scope.router.navigate(['application']);
      }, 100);
    }
  }

  v3authSocial(token: any): Observable<[string]> {
    const loginData = {
      token: token
    }
    const headers = new HttpHeaders();
    return this.http.post<any>(this.v3Social, loginData, { headers })
      .pipe(
        retry(1),
        map(
          (data) => {
            const dataToCache = data;
            /**Save Data */
            // this.container.set("hc.brodcast.all." + userID, dataToCache);
            return dataToCache;
          }),
        catchError(this.handleError('auth'))
      ) as Observable<any>;

  }


  deleteSession(session): Observable<[string]> {
    return this.delete(this.sessionDeleteUrl)
  }

  checkToken(): Observable<[string]> {
    const body = {
      token: localStorage.getItem('scarlettoken')
    }
    return this.post(this.refreshtoken, body);
  }

  refreshToken(){
    const body = {
      token: this.accessToken,
      refreshtoken: localStorage.getItem('refreshToken')
    }
    return this.post(this.refreshtoken1, body)
      .pipe(
        tap((res: HttpResponse<any>)=>{
          this.setAccessToken(res);
      })
    )
  }

  setAccessToken(data) {

    localStorage.setItem('scarlettoken',data.data.token)
    localStorage.setItem('refreshToken',data.data.refreshtoken)

  }

  /*sendprofile(ide, name, code): Observable<string> {
    const data =
    {
      d: [
        {
          identity: ide,
          ts: (Math.floor(new Date().valueOf() / 1000)),
          type: 'profile',
          profileData: {
            Name: name,
            Code: code
          }
        }
      ]
    }
    const SessionID = localStorage.getItem('scarlettoken');
    if ((SessionID) || (SessionID != '')) {
      const options = new HttpHeaders().set('sessionID', '5dc28254e4bb215ce9f5ba4e');
      return this.http
        .post<any>('https://api.wellthy.me/utilities/clevertap/profile', data, {
          headers: options
        })
        .pipe(
          retry(1),
          map(data => {
            const dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError('sendprofile'))
        ) as Observable<any>;
    }
  }*/
  sendprofile(): Observable<string> {

    let data ={
      d: [
        {
          identity: localStorage.getItem('scarletuserid'),
          ts: (Math.floor(new Date().valueOf() / 1000)),
          type: 'profile',
          profileData: {
            Name: JSON.parse(localStorage.getItem('scarletuserdata')).name,
            Email: JSON.parse(localStorage.getItem('scarletuserdata')).email,
          }
        }
      ]
    }

    const SessionID = localStorage.getItem('scarlettoken');

    if ((SessionID) || (SessionID != '')) {
      const options = new HttpHeaders();//.set('sessionID', '5dc28254e4bb215ce9f5ba4e');
      options.set("X-CleverTap-Account-Id",this.clevertapAccountID);
      options.set("X-CleverTap-Passcode",this.ClevertapPassword);
      options.set("Content-Type","application/x-www-form-urlencoded");
      const clevertapprofile = new HttpParams().set('d', JSON.stringify(data.d))
     return this.post(this.clevertapUrl+'clevertap/data', clevertapprofile,{headers: options})
    }
  }
  
  sendEventData(data): Observable<string> {
    const SessionID = localStorage.getItem('scarlettoken');
    if ((SessionID) || (SessionID != '')) {
      const options = new HttpHeaders();//.set('sessionID', '5dc28254e4bb215ce9f5ba4e');
      options.set("X-CleverTap-Account-Id",this.clevertapAccountID);
      options.set("X-CleverTap-Passcode",this.ClevertapPassword);
      options.set("Content-Type","application/x-www-form-urlencoded");
     //  data = {"d": [{"identity": 1986, "ts": 1612508430, "type": "event", "evtName": "Patient Section Clicked1"}]};
     const clevertapbody = new HttpParams()
     .set('d', JSON.stringify(data.d))
      return this.post(this.clevertapUrl+'clevertap/data', clevertapbody,{headers: options})
        
      //https://api.wellthy.me/utilities/clevertap/events
    }
  }

  logoutAccount(): void {
    this.logout()
    .subscribe(data => {
      
      this.clearData();
      this.dialog.closeAll();
      window.location.reload();
    });
  }

  logout(): Observable<string> {
    var uid = localStorage.getItem('scarletuserid');
    this.clearData();
    this.dialog.closeAll();
    return this.delete(this.apiUrl+'auth/logout/u/' + uid)
  }

  getorg()
  {
    return localStorage.getItem('orgid');
  }
  storeData(data)
  {
    localStorage.setItem('scarletIdValidate', data.id);
    localStorage.setItem('scarletuserid', data.id);
    localStorage.setItem('adminClient', data.client_role_id);
    // Organization Change
    if(environment.org){
      localStorage.setItem('orgid',data.organizationDataId);
    }

    if (data.user_type == "doctor") {
      localStorage.setItem('doctorsla', data.tc_accepted);
    }

    localStorage.setItem('scarletuserdata', JSON.stringify(data));
    localStorage.setItem('scarletuserdata1', JSON.stringify(data));
    localStorage.setItem('refreshToken', data.refreshtoken);
  }

  clearData(){
    localStorage.removeItem('scarlettoken');
    localStorage.removeItem('scarletuserid');
    localStorage.removeItem('scarletuserdata');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('app');
    localStorage.removeItem('orgid');
  }

  private handleError(operation: string = 'operation') {
    return (error: any) => {
      error.operation = operation;
      return throwError(error);
    };
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
