<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/activationsearch"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary notranslate">
          Search by {{'Admin.ActivationTitle' | translate}} code  
        </h3>
      </div>

      <div class="">
       
        <div class="col-md-4">
        
         
          <form>
            <div class="form-group has-feedback has-search">
              <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" minlength="6" maxlength="6"
                [pattern]="searchPattern" #uname="ngModel" class="form-control" placeholder="Enter Activation code here">
              <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                <mat-icon matSuffix>search</mat-icon>
              </button>
              <mat-error *ngIf="uname.errors?.minlength">
                Activation Code must be at least 6 characters long.
              </mat-error>
              <!-- <mat-error *ngIf="uname.errors?.pattern">
                Special charcters are not allowed
              </mat-error> -->
            </div>

          </form>
        </div>
        <div class="col-md-8">
          <button mat-flat-button color="primary" class="submit-btn pull-right " (click)="mapDoctor()"
            *ngIf="activationdata?.is_active && activationdata?.patient_data.id">Map Doctor</button>
          <!-- <button mat-flat-button color="primary" class="submit-btn pull-right btn-margin-left" (click)="resetCode()"
            *ngIf="activationdata?.is_active">Reset Code</button> -->
        </div>
      </div>

      <div class="col-md-12" *ngIf="showplaceholder">
        <div class="no-data">
          No details found for activation code you entered
        </div>
      </div>
      <div class="col-md-12" *ngIf="activationdata === undefined">
        <div class="no-data" style="font-size: 16px;color: rgb(197, 192, 192);">
         Enter an Acitivation code to get details
        </div>
      </div>


      <div class="searchContainer">
         
        <div class="col-md-12" *ngIf="activationdata">
          <div class="title-in-border">
            <h1><span>Activation Stats</span></h1>
            <div class="stats-data">
              <div class="data data-firstcol">
                <p class="key">Code Status</p>
                <p class="value">{{activationdata?.is_active ? "Active" : "InActive"}}</p>
              </div>
              <div class="data data-timelogged">
                <p class="key">Activated via</p>
                <p class="value"> {{activationdata?.activated_by ? activationdata?.activated_by : '-'}}</p>
              </div>
              <div class="data">
                <p class="key">Activated by</p>
                <p class="value">
                  {{activationdata?.activatedByDataFkId ? (activationdata?.patient_data?.name + " (" +
                  activationdata?.activatedByDataFkId + ")") : '-'}}
                </p>
              </div>
              <div class="data data-breaks">
                <p class="key">Phone</p>
                <a class="value" [routerLink]="['/admin/patients']" routerLinkActive="active" (click)="PatientSearch()">
                  +{{activationdata?.patient_data?.country_code ? activationdata?.patient_data?.country_code :
                  '-'}}-{{activationdata?.patient_data?.phone ? activationdata?.patient_data?.phone : '-'}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="activationdata">
          <div class="title-in-border">
            <h1><span>Program stats</span></h1>
            <div class="stats-data">
              <div class="data data-firstcol">
                <p class="key">Healthcoach</p>
                <a class="value" [routerLink]="['/admin/hchome']" routerLinkActive="active"
                  (click)="HealthcoachSearch()">
                  {{activationdata?.patient_data?.healthcoach_data?.name ?
                  activationdata?.patient_data?.healthcoach_data?.name : '-'}}
                </a>
              </div>
              <div class="data data-timelogged">
                <p class="key">Client</p>
                <p class="value">
                  {{activationdata?.patient_data?.patient_client_data?.title ?
                  activationdata?.patient_data?.patient_client_data?.title : '-'}}
                </p>
              </div>
              <div class="data">
                <p class="key">Therapy Detail</p>
                <p class="value">
                  {{activationdata?.patient_data?.therapy_data?.title ?
                  activationdata?.patient_data?.therapy_data?.title : '-'}}
                </p>
              </div>
              <div class="data data-breaks">
                <p class="key">Device</p>
                <p class="value">{{activationdata?.device_type ? activationdata?.device_type : '-'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>