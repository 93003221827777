<div class="main-wrapper mt-30">
    <form  validate>
    <div class="task-wrapper">
        <div class="text-over">
            <h4 class="">Task details</h4>
        </div>
        <div *ngIf="!taskDetails">
         No tasks assigned
        </div>

        <div *ngIf="taskDetails">
        <div class="row">
            <div class="col-md-12">
                <div class="title-area">
                    <h6>Task type</h6>
                    <div class="row">
                        <div class="col-md-12 rectangle-type">
                            <h5 class="like">
                                <img *ngIf="!taskcompleteflag" src="assets/img/dls/icons/pendingTask.svg" class="img-responsive" alt="Wellthy">
                                <img *ngIf="taskcompleteflag" src="assets/img/dls/icons/Tick_solid.svg" class="img-responsive" alt="Wellthy">
                                {{taskDetails?.task_summary}}
                           </h5>
                        </div>
                        <!-- <div class="col-md-12 rectangle-type">
                            <h5 class="like"><img src="assets/img/dls/icons/hcicons/circle.svg"
                                class="img-responsive" alt="Wellthy">
                                Get them to log 30 min of activity
                           </h5>
                      </div> -->
                    </div>
                    
                </div>
                <div class="task-details">
                    <div class="row">
                        <div class="col-md-4 title-area">
                            <div class="title-section">
                                <h6>Priority</h6>
                                <!-- <h4 class="preview-sub text-capitalize">                                   
                                    {{taskDetails?.priority}}
                                </h4> -->
                                <h4 class="preview-sub text-capitalize" *ngIf="taskDetails?.priority == '0'">  Low </h4>
                                <h4 class="preview-sub text-capitalize" *ngIf="taskDetails?.priority == '1'"> Medium</h4>
                                <h4 class="preview-sub text-capitalize" *ngIf="taskDetails?.priority == '2'"> High</h4>
                                 <!-- <span *ngIf="taskDetails?.priority == '2'" class="priority high"></span>
                                    <span *ngIf="taskDetails?.priority == '0'" class="priority low"></span>
                                    <span *ngIf="taskDetails?.priority == '1'" class="priority medium"></span> -->
                            </div>
                        </div>
                        <div class="col-md-4 title-area">
                            <div class="title-section">
                                <h6>Scope</h6>
                                <h4 class="preview-sub text-capitalize">
                                    {{taskDetails?.scope}}
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-4 title-area">
                            <div class="title-section">
                                <h6>Expiry</h6>
                                <h4 class="preview-sub">
                                    {{convertDate(taskDetails?.expiry_date)}}
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="title-area">
                                <h6>Conditions</h6>
                                <h4 class="preview-sub">
                                    {{conditions}}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- commented it since the requirement is not freezed -->
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="title-area">
                                <h6>Related notes</h6>                                
                                    <button mat-icon-button class="plusrectangletransparent mb-8">
                                    <mat-icon>add</mat-icon>
                                    
                                    </button>
                                
                            </div>
                        </div>
                    </div> -->

                </div>

            </div>

        </div>
        <div class="row" ><!--*ngIf="enableTaskBtn"-->
            <div class="col-md-12 zeromarginleft" style="margin-top:20px;">
              <button mat-flat-button color="primary" (click)="submitTask()" class="centerbtn" [disabled]="!taskcompleteflag">Task completed</button>
            </div>
          </div>
        </div>
    </div>
    </form>
</div>