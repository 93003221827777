<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/intenthome/intentedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Intent</h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput placeholder="Enter the description"
                                        formControlName="detailed_text"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Deeplink URL</mat-label>
                                    <input matInput placeholder="Enter the Deeplink URL" formControlName="url" required>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 zeropadleft zeromarginleft">
                                <button mat-flat-button color="primary" class="centerbtn mt-15"
                                    [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Intent</button>
                            </div>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>