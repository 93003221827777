import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { QuestionComponent } from './question.component';
import { EditquestionComponent } from './editquestion/editquestion.component';
const routes: Routes = [
  { path: '', component: QuestionComponent, canActivate: [ContentGuardGuard] },
  { path: 'question/edit/:id/:langid/:chid', component: EditquestionComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionRoutingModule { }
