import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../../../shared/shared.module';
import { AdminleftnavModule } from '../adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../adminsectionleftnav/adminsectionleftnav.module';
import { HcassignleftnavModule } from '../hcassignleftnav/hcassignleftnav.module';
import { PaymentleftnavModule } from '../paymentleftnav/paymentleftnav.module';
import { UserprofileviewComponent } from './userprofileview.component';
import { UserprofileviewRoutingModule } from './userprofileview.routing.module';


@NgModule({
  declarations: [
    UserprofileviewComponent
  ],
  imports: [
    CommonModule,
    UserprofileviewRoutingModule,
    ImageCropperModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    HcassignleftnavModule,
    PaymentleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class UserprofileviewModule {}