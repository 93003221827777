import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ReplaySubject, Subject } from 'rxjs';
import { SUPPORTED_FILE_TYPES } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../../content/services/contentservice/contentservice.service';
import { ProfileimageComponent } from '../../profileimage/profileimage.component';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';


@Component({
  selector: 'app-editdoctor',
  templateUrl: './editdoctor.component.html',
  styleUrls: ['./editdoctor.component.scss']
})

export class EditdoctorComponent implements OnInit {
  upload_type: any = upload_type;
  public newitem: boolean = false;
  public showButtonLoader: boolean = false;
  public userid: any;
  public userdata: any;
  allcampaigns: any;
  s3url: any = environment.s3url;
  public dataForm = this.fb.group({
    name: ['', [Validators.required, this.noWhitespaceValidator]],
    phone: ['', Validators.required],
    profile_image: [''],
    std_code: ['', [Validators.required]],
    email: ['', [Validators.required]],
    user_type: ['doctor'],
    user_role: [''],
    user_acl: this.fb.array([]),
    display_bio: ['', [Validators.required, this.noWhitespaceValidator]],
    gender: ['', Validators.required],
    display_name: ['', [Validators.required, this.noWhitespaceValidator]],
    password: ['', Validators.required],
    city: [''],
    address: [''],
    speciality: [''],
    degree: [''],
    hospital_data: [''],
    doctor_type: ['Regular', Validators.required],
    tc_accepted: ['false'],
    campaign_data: [],
  });
  public localStoredData: any;
  private allfiles: any;
  public uploadedfiletype: any;
  private responsethumbUrl: any;
  public resdata: any;
  public image_path: any;
  public image_id: any;
  public uploadedparam: boolean = false;
  public isloading: boolean = false;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  public isinvalid: boolean = false;
  private responseUrl: any;
  public searchmattag: FormControl = new FormControl();
  public isNewItem: any;
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;

  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public HospitalMappingList:any[] = [];

  private _onDestroy = new Subject<void>();
  hospital_id: any[] = [];
  client_role_id: any;

  constructor(private browserTitle: Title, public contentService: ContentserviceService, public fb: FormBuilder,
    public dialog: MatDialog, private router: Router, private route: ActivatedRoute,
    private commonService: CommonserviceService, private systemService: SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditdoctorComponent>) { }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Doctor');
  }

  ngOnInit() {
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.setSEOTags();
    this.getCampaigns();
    this.client_role_id = JSON.parse(localStorage.getItem('scarletuserdata')).client_role_id;
    this.isNewItem = this.data.element;
    if(this.client_role_id !== 9){
       this.dataForm = this.fb.group({
        name: ['', [Validators.required, this.noWhitespaceValidator]],
        phone: ['', Validators.required],
        profile_image: [''],
        std_code: ['', [Validators.required]],
        email: ['', [Validators.required]],
        user_type: ['doctor'],
        user_role: [''],
        user_acl: this.fb.array([]),
        display_bio: ['', [Validators.required, this.noWhitespaceValidator]],
        gender: ['', Validators.required],
        display_name: ['', [Validators.required, this.noWhitespaceValidator]],
        password: ['', Validators.required],
        city: [''],
        address: [''],
        speciality: [''],
        degree: [''],
        hospital_data: [''],
        doctor_type: ['Regular', Validators.required],
        tc_accepted: ['false'],
        campaign_data: [],
      });
    }else{
      this.dataForm = this.fb.group({
        name: ['', [Validators.required, this.noWhitespaceValidator]],
        phone: ['', Validators.required],
        profile_image: [''],
        std_code: ['', [Validators.required]],
        email: ['', [Validators.required]],
        user_type: ['doctor'],
        user_role: [''],
        user_acl: this.fb.array([]),
        display_bio: ['', [Validators.required, this.noWhitespaceValidator]],
        gender: ['', Validators.required],
        display_name: ['', [Validators.required, this.noWhitespaceValidator]],
        password: ['', Validators.required],
        city: [''],
        address: [''],
        speciality: [''],
        degree: [''],
        hospital_data: ['',Validators.required],
        doctor_type: ['Regular', Validators.required],
        tc_accepted: ['false'],
        campaign_data: [],
      });
    }
  

    if (this.isNewItem == 'newsection') {
      this.newitem = true;
    }
    else {
      this.newitem = false;
      this.userid = this.isNewItem;
      this.getUserData(this.isNewItem);
    }

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChangedTags() {
    this.filterTags();
  }

  checkEligibility() {
    return this.localStoredData?.organization_data?.scarlet_acl_restriction?.includes('campaign')
  }


  // To Validate Email
  // ----------------------------

  validateEmail(control: FormControl) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(control.value)) {
      if (control.value.indexOf("@wellthy.care", control.value.length - "@wellthy.care".length) !== -1) {
        return null
      }
      else {
        return { 'validateEmail': true };
      }
    }
    else {
      return { 'validateEmail': true };
    }
  }

  // ----------------------------


  // To Filter Campaign
  // ----------------------------

  private filterTags() {
    if (!this.allcampaigns) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTherapy.next(this.allcampaigns.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.allcampaigns.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // ----------------------------


  // To Upload Image
  // ----------------------------
  handleChange(files: FileList) {
    if (this.newitem == false) {
      this.uploadedparam = false;
    }

    else if (files[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = files;
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.dataForm.value.profile_image == this.image_id) {
            this.uploadedparam = true;
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloading = true;
              scope.uploadedparam = false;
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        })
                  });
            }
          }
        });
      }
    }
    // if (files && files.length) {
    //   this.file = files[0].name;
    // }
  }
  uploadImage() {
    if (this.newitem == false) {
      this.uploadedparam = false;
    }
    if (this.demoForm.status == 'INVALID') {
      this.isinvalid = true;
      this.systemService.showSnackBar("Your filetype is not supported. The Supported formats are .jpeg,.png,.jpg ", "Ok");
    }
    else if (this.demoForm.value.files[0].size > 5000000) {
      this.isinvalid = true;
      this.systemService.showSnackBar("Max allowed size is 5MB", "Ok");
    }
    else {
      this.isinvalid = false;
      this.allfiles = this.demoForm.value.files;
      this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];
      if (!SUPPORTED_FILE_TYPES.includes(this.uploadedfiletype)) {
        this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
      }
      else {
        const user_profile = this.dialog.open(ProfileimageComponent, {
          width: '500px',
          height: '350px',
          data: {
            image: this.allfiles
          }
        }
        );
        user_profile.afterClosed().subscribe((imageinfo) => {
          if (this.dataForm.value.profile_image == this.image_id) {
            this.uploadedparam = true;
          }
          var scope = this;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloading = true;
              scope.uploadedparam = false;
              imagedetails = "";
              imagedetails = imageinfo.value[i];
              scope.commonService.getS3URL('user_profile', scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        }
                      )
                  }
                );
            }
          }
        });
      }
    }
  }

  // ----------------------------


  // To Upload Thumbnail
  // ----------------------------

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }
          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlob(dataURL);
          scope.commonService.getS3URL('user_profile', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, imageinfo);
                    }, (error) => {
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              }
            )
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else {
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, imageinfo) {
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, upload_type[0], 'document')
        .subscribe(
          (data) => {
            scope.resdata = data;
            scope.image_path = scope.resdata.data.path;
            scope.uploadedparam = true;
            scope.isloading = false;
            if (scope.resdata.status == 201) {
            }
            else {
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          }
        )
    }
  }

  // ----------------------------


  // To Convert dataUPI to Blob
  // ----------------------------

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  // ----------------------------


  // Get User List
  // ----------------------------

  getUserData(ide) {
    this.commonService.getOneDataById(ide, "users")
      .subscribe(
        (res: any) => this.getUser(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private getUser(data) {
    data = (data as any).data;
    this.userdata = data;
    this.userdata.std_code = this.userdata.std_code.toString();
    if(this.userdata?.hospital_data){
      for (var i = 0; i < this.userdata.hospital_data.length; i++){
        this.hospital_id.push(parseInt(this.userdata.hospital_data[i]));
      }
    }
    this.dataForm.patchValue(this.userdata);
    if (this.userdata.profile_image != null && this.userdata.profile_image != "") {
      this.getMedia(this.userdata.profile_image);
    }
  }

  // ----------------------------


  // To Get Media
  // ----------------------------

  getMedia(id) {
    this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
      .subscribe(
        (res: any) => this.getMediaData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private getMediaData(data) {
    data = data.data;
    this.image_path = data.getOneDocumentMedia.path;
    this.image_id = data.getOneDocumentMedia.id;
    this.uploadedparam = true;
  }

  // ----------------------------


  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }


  // To Get Campaigns
  // ----------------------------

  getCampaigns() {
    this.contentService.getGraphQlData('{getAllCampaigns(appMode:false, status:true){id,title,therapy_data_fk,client_data}}')
      .subscribe(
        (res: any) => this.getCampaignData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private getCampaignData(data) {
    data = (data as any).data;
    this.allcampaigns = data.getAllCampaigns;
    this.client_role_id = JSON.parse(localStorage.getItem('scarletuserdata')).client_role_id;
    if (this.localStoredData.user_type == 'scarlet') {
      this.allcampaigns = this.allcampaigns.filter(a => {
        if(this.client_role_id === 9){
          if(a.client_data === 9){
            return true
          }
        }
        else  
        if (['CHF therapy', 'IHD therapy'].includes(a.title)) {
          return true
        }
       
      })
    }
    this.filteredTherapy.next(this.allcampaigns.slice());
    this.getHospitalList();
  }

  // ----------------------------
  //  Submitting User Data 
  // ----------------------------

  dataObject() {
   
    if (this.resdata) {
      this.dataForm.value.profile_image = this.resdata.data.id;
    }
    let display_bio = this.commonService.isHTML( this.dataForm.value.display_bio);
    if(display_bio  === true){
      this.dataForm.controls.display_bio.patchValue('')
      return;
    } 
   
    var postdata = this.dataForm.value;
    postdata.phone = postdata.phone.toString();
    postdata.name = this.removeHTMLtags(postdata.name);
    postdata.display_name =  this.removeHTMLtags(postdata.display_name);
    postdata.email =  this.removeHTMLtags(postdata.email);
    postdata.degree =  this.removeHTMLtags(postdata.degree);
    postdata.speciality =  this.removeHTMLtags(postdata.speciality);
    postdata.address =  this.removeHTMLtags(postdata.address);
    postdata.city =  this.removeHTMLtags(postdata.city);
    postdata.std_code = postdata.std_code.toString();
    postdata.tc_accepted = postdata.tc_accepted.toString();
    if (this.checkEligibility()) {
      delete postdata.campaign_data;
    }
    else {
        if(this.testJSON(postdata.campaign_data)){
          postdata.campaign_data = postdata.campaign_data;
        }else{
          postdata.campaign_data = JSON.stringify(postdata.campaign_data);
        }
    }
    if(postdata.hospital_data && postdata.hospital_data !== '' && postdata.hospital_data !== "null" && postdata.hospital_data !== undefined){
      if(this.testJSON(postdata.hospital_data)){
        postdata.hospital_data = postdata.hospital_data;
      }else{
        postdata.hospital_data = JSON.stringify([postdata.hospital_data]);
      }
    }else{
      delete postdata.hospital_data;
    }
    postdata.user_role = postdata.user_type + "-role";
    var user_access_control = [];
      user_access_control.push(postdata.user_type);
      postdata.user_acl = JSON.stringify(user_access_control);
   
    postdata.user_type = postdata.user_type.toString();
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    if (this.dataForm.value.profile_image == "" || this.dataForm.value.profile_image == null) {
      delete postdata.profile_image;
    }
    else {
      postdata.profile_image = this.dataForm.value.profile_image.toString();
    }
    if (!this.newitem) {
      delete postdata.password;
      delete postdata.email;
    }
    if (this.hasWhiteSpace(this.dataForm.value.password) == false) {
      if (this.newitem == true) {
        this.commonService.postToBlade(postdata, 'users')
          .subscribe(
            (res: any) => this.onSuccessCreate(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          );
      }
      else {
        this.commonService.updateToBlade(this.userid, postdata, 'users')
          .subscribe(
            (res: any) => this.onSuccessUpdate(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          );
      }
    }
    else {
      if (this.hasWhiteSpace(this.dataForm.value.password) == true) {
        postdata.campaign_data = JSON.parse(postdata.campaign_data);
        postdata.hospital_data = JSON.parse(postdata.hospital_data);
        this.systemService.showSnackBar("Enter valid password, Your password should not be empty or contain spaces", "OK");
      }
    }
 
  }

  private onSuccessCreate() {
    this.closeModal();
    this.systemService.showSuccessSnackBar(("User has been created"), "OK");
    this.router.navigate(['admin/doctors']);
  }
  private onSuccessUpdate() {
    this.closeModal();
    this.systemService.showSuccessSnackBar(("User has been updated"), "OK");
    this.router.navigate(['admin/doctors']);
  }

  // ----------------------------

  closeModal(): void {
    this.dialogRef.close();
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

  getHospitalList(hospital_id?) {
    this.commonService.getAllHospital()
      .subscribe(
        (res: any) => {
          this.HospitalMappingList = res.data;
          for (var i = 0; i < this.HospitalMappingList.length; i++){
            this.HospitalMappingList[i].id = (this.HospitalMappingList[i].id).toString();
          }
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  testJSON(text){
    if (typeof text!=="string"){
        return false;
    }
    try{
        var json = JSON.parse(text);
        return (typeof json === 'object');
    }
    catch (error){
        return false;
    }
}
}

export enum upload_type {
  patient_profile
}