import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-docactivation',
  templateUrl: './docactivation.component.html',
  styleUrls: ['./docactivation.component.scss']
})
export class DocactivationComponent implements OnInit {
  public dataForm = this.fb.group({
    phone: [""],
    condition: [""],
    type: [""],
  });
  constructor(public fb: FormBuilder) { }

  ngOnInit() {
  }

}
