<div class="col-md-12 clearfix">
    <h2 class="pull-left title-modal">
        Pre-activate patient
    </h2>
    <div class="pull-right">
        <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
    </div>
</div>

<form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate class="activatebottomsheet">
    <div class="col-md-12 col-xs-12 ">
        <mat-form-field appearance="outline" class="full-width-formfield" style="width: 150px;float: left;">
            <mat-label>Country code</mat-label>
            <input matInput formControlName="country_code" placeholder="Code" pattern="^\d+(?:\.\d{0,1})?$"
                oninput="validity.valid||(value='');" maxlength="3">
            <!-- <mat-icon matPrefix>add</mat-icon> -->
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-formfield"
            style="width: calc(80% - 30px);padding-left: 10px;">
            <mat-label>Phone Number</mat-label>
            <input matInput [pattern]="'^[0-9]+$'" formControlName="phone" maxlength="10" placeholder="Phone Number">
            <mat-error>Please enter the valid phone number</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 col-xs-12 ">
        <mat-form-field appearance="outline" class="full-width-formfield">
            <mat-label>Select Campaign</mat-label>
            <mat-select class="text-capitalize" formControlName="condition" placeholder="Select Campaign">
                <mat-option *ngFor="let camp of allCampaigns" value="{{camp.id}}">
                    {{camp.title}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-12 col-xs-12">
        <mat-form-field appearance="outline" class="full-width-formfield">
            <mat-label>Select Language</mat-label>
            <mat-select formControlName="language_data_fk" placeholder="Select Language">
                <mat-option *ngFor="let lang of alllanguages" value="{{lang.id}}">
                    {{lang.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-12 col-xs-12">
        <button mat-flat-button color="primary" class="centerbtn mt-15" [disabled]="!dataForm.valid">Activate
            Patient</button>
        <div class="clearfix"><br /></div>
    </div>

    <ng-template #buttonSubmitLoader>
        <app-loader showLoader="{{true}}"></app-loader>
    </ng-template>

</form>
<div class="clearfix"><br /></div>