<div class="view-task">
    <div class="clearfix">
        <h2 class="pull-left title-modal">Assign healthcoach</h2>
        <div class="pull-right">
            <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive" alt="">
        </div>
    </div>

    <form class="search-form" [formGroup]="dataForm" validate> 
    <mat-dialog-content>
            <div class="form-group has-feedback has-search mt-20">
                <input class="form-control" placeholder="Search healthcoach" [ngModelOptions]="{standalone: true}"  [(ngModel)]="searchcriteria">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                    <mat-icon matSuffix>search</mat-icon>
                </button>
            </div>
        <div class="row">
            <div class="col-md-12">
                <div class="container-table">
                    <mat-selection-list formControlName="hc_ids">
                        <mat-list-option checkboxPosition="before" *ngFor="let hc of dataSource" [value]="hc" multiple> 
                            <div class="clearfix">
                                <div  class="pull-left main-text">
                                    {{hc.name}}
                                </div>
                                <div class="pull-right sub-text">
                                    {{hc.pending_task}} task pending
                                </div>
                            </div>

                        </mat-list-option>
                    </mat-selection-list>

                    <div class="spinner-holder" *ngIf="isLoading">
                        <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate"
                            diameter="40">
                        </mat-progress-spinner>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="start" class="mb-30">
        <button mat-button  class="btn btn-filled btn-medium" (click)="dataObject()" [disabled]="!dataForm.valid">Assign</button>
        <button mat-button  class="btn btn-outline btn-medium" [disabled]="!dataForm.valid" (click)="dataForm.reset()">Clear</button>
    </mat-dialog-actions>
</form>
</div>