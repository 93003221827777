import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { HardsetotpRoutingModule } from './hardsetotp.routing.module';
import { HardsetotpComponent } from './hardsetotp.component';
import { EdithardsetotpComponent } from './edithardsetotp/edithardsetotp.component';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';

@NgModule({
  declarations: [
    
    HardsetotpComponent,
    EdithardsetotpComponent,
    ViewprofileComponent
],
  imports: [
    CommonModule,
    HardsetotpRoutingModule,
    ImageCropperModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class HardsetotpModule {}