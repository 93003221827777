<div class="profile-wrapper">
  
  <div class="profile-info clearfix">
    <h4>{{tabName}} History</h4>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        close
      </i>
    </div>
  </div>
  <mat-dialog-content>
    <!-- add tabs in this div -->
    <mat-tab-group (selectedTabChange)="tabClick($event)" #richmediatabGrp class="rich-media" mat-stretch-tabs>
      <mat-tab label="Login History">
        <div *ngIf="loginData.length == 0" class="text-center">
          <p class="no-data">-- Not Available --</p>
        </div>
        <table style="margin-top:10px;" *ngIf="loginData.length > 0"  class="table table-bordered">
          <thead>
            <tr style="background: bisque;">
              <th>Device Name</th>
              <th>Login Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of loginData">
              <td>{{element.device_type}} {{element.device_version}}</td>
              <td> <span> {{element.login_at | date: 'dd/MM/yyyy'}}</span><br>
                <span style="font-size: 8px;"> {{element.login_at | date: 'h:mm a'}}</span>
               </td>
              <ng-template #not_debug >
                <td>-</td>
              </ng-template>
            </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab  label="Device History">
        <div *ngIf="deviceHistory?.length == 0" class="text-center">
          <p class="no-data"> -- Not Available -- </p>
        </div>
        <table  style="margin-top:10px;"   class="table table-bordered">
          <thead>
            <tr style="background: bisque;">
              <th>Device ID</th>
              <th>Login Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of deviceHistory">
              <td><span>{{element.device_version}}</span>
                <!-- <span  *ngIf="element.is_debug_app === true" style="color: red; font-weight: 100;font-family: monospace;">(Debug App)</span> -->
                <span *ngIf="element.is_device_rooted === true" style="color: red; font-weight: 100;font-family: monospace;">(Rooted)</span><br>
                <span style="font-size: 12px;font-family: ui-serif;">
                  {{element.device_id}}
                </span>
              </td>
              <td>
                <span> {{element.updated_at | date: 'dd/MM/yyyy'}}</span><br>
                <span style="font-size: 8px;"> {{element.updated_at | date: 'h:mm a'}}</span>
                </td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>

  
  </mat-dialog-content>
</div>

<style>
  .profile-wrapper {
    position: relative;
  }

  .rich-media ::ng-deep .mat-tab-list .mat-tab-labels .mat-tab-label-active {
    color: white;
    background-color: #fb7a20 !important;
    border: white;
  }

  .rich-media ::ng-deep .mat-tab-list .mat-tab-labels {
    color: #fb7b20;
    background-color: white !important;
    opacity: 1;
    border: #fb7a20;
  }

  .profile-info {
    margin-bottom: 20px;
    font-family: 'avenir';
  }

  .profile-info .close-icon {
    position: absolute;
    right: -15px;
    top: -20px;
    color: #979797;
    cursor: pointer;
  }

  .rich-media ::ng-deep .mat-tab-list .mat-tab-labels .mat-tab-label-active {
    color: white;
    background-color: #fb7a20;
    border: white;
  }

  .rich-media ::ng-deep .mat-tab-list .mat-tab-labels {
    color: #fb7b20;
    background-color: white;
    opacity: 1;
    border: #fb7a20;
  }
  ::ng-deep.mat-tab-header {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    border: 1px solid #d6d0d0 !important;

}

  .profile-info .close-icon .material-icons {
    font-size: 38px;
    font-weight: bold;
  }
</style>