/*==========================================
Title:  code cleanup
Author: Soundharya AM
Date:   20 Apr 2020
Last Change :  code cleanup
===========================================*/
import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { AuthenticateService, } from 'src/app/shared/services';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { setDarkTheme } from 'src/app/utility/utils';
@Component({
  selector: 'app-adminprofile',
  templateUrl: './adminprofile.component.html',
  styleUrls: ['./adminprofile.component.scss']
})
export class AdminprofileComponent implements OnInit {
  profileselection: any = "summary";
  isAccountActive: boolean = true;
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public loadingError$ = new Subject<boolean>();
  public localStoredHCData: any;
  public dummyImage: string = "assets/img/imgplaceholder.svg";
  public imageurl: string;
  public responseImageUrl: any;
  public showButtonLoader: boolean = false;
  error: boolean = false;
  s3url: any = environment.s3url;
  public message: any;
  public adminData: any;
  public dataForm = this.fb.group({
    new_password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  },
    { validator: this.checkPasswords }
  );
  public ide: any;
  isDark = sessionStorage.getItem('dark');
  public isThemeDark = false;
  constructor(private authService: AuthenticateService,
    private systemService: SystemService, public fb: FormBuilder,
    private commonService: CommonserviceService) { }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnInit() {
    this.adminData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.getProfileImage();
    if (this.isDark == 'true') {
      this.isThemeDark = true;
    }else{
      this.isThemeDark = false;
    }
  }
  // Get Profile Image
  // ----------------------------
  getProfileImage(): any {
    this.imageurl = "https://amp.businessinsider.com/images/5899ffcf6e09a897008b5c04-750-750.jpg";
  }
  // ----------------------------
  // Logout From the App
  // ----------------------------
  logoutAccount() {
    this.authService.logout()
      .subscribe(
        (res: any) => this.onLogoutSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onLogoutSuccess() {
    this.systemService.showSuccessSnackBar(("Logout Successfully"), "OK");
    window.location.reload();
  }
  // ----------------------------
  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  // To Change Password
  // ----------------------------
  changePassword() {
    var postdata = this.dataForm.value;
    postdata['user_id_data_fk']= localStorage.getItem('scarletuserid');
    postdata['source_of_password_change']= localStorage.getItem('app')
    postdata['password_changed_via']= 'Dashboard';
    delete postdata['confirmPassword'];
    this.error = false;
    if (this.hasWhiteSpace(this.dataForm.value.new_password) == false) {
      this.commonService
        .updatePassword(this.adminData.id, postdata)
        .subscribe(
          (res: any) => this.onStatusSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.error = true;
      this.message = "Enter valid password, Your password should not be empty or contain spaces";
    }
  }
  private onStatusSuccess() {
    this.systemService.showSuccessSnackBar(("Password has been updated"), "OK");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
  // ----------------------------
  toggleDarkTheme(checked) {
    setDarkTheme(checked);
    
  }
}