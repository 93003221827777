import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { QuizComponent } from './quiz.component';
import { EditquizComponent } from './editquiz/editquiz.component';
const routes: Routes = [
  { path: '', component: QuizComponent, canActivate: [ContentGuardGuard] },
  { path: 'quiz/edit/:id/:langid/:chid', component: EditquizComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuizRoutingModule { }
