import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { PaymentplanRoutingModule } from './paymentplan.routing.module';
import { PaymentplanComponent } from './paymentplan.component';
import { EditpaymentplanComponent } from './editpaymentplan/editpaymentplan.component';
import { PaymentleftnavModule } from '../shared/paymentleftnav/paymentleftnav.module';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';

@NgModule({
  declarations: [
    
    PaymentplanComponent,
    EditpaymentplanComponent
    
  ],
  imports: [
    CommonModule,
    PaymentplanRoutingModule,
    ImageCropperModule,
    PaymentleftnavModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PaymentplanModule {}