import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class RouterApiService {

  public apiUrl: string = environment.serverUrl;

  constructor(
    private httpService: HttpService
  ) { }

  anyPhoneNumberRequests(){

    let url = this.apiUrl + 'patient/data/change/list';

    let body = new HttpParams()
      .set("status", "0")
      .set("limit", "1")
      .set("page", "1")
      .set("q", "");

    return this.httpService.post(url, body);
  }
}
