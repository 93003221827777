<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="admin/library"></app-breadcrumb>
        <div class="main-wrapper mt-30">

            <div class="task-wrapper">
                <div class="text-over">
                    <h3 class="">Task details</h3>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="title-section">
                            <h5>Task type</h5>
                            <h4>Send broadcast to patient</h4>
                        </div>
                        <div class="task-details">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="title-section">
                                        <h5>Priority</h5>
                                        <h4 class="preview-sub text-capitalize">
                                            High
                                        </h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="title-section">
                                        <h5>Scope</h5>
                                        <h4 class="preview-sub text-capitalize">Global</h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="title-section">
                                        <h5>Expiry</h5>
                                        <h4 class="preview-sub">21 Aug,2021</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="configure-conditions">
                                        <h5>Configure conditions</h5>
                                        <mat-vertical-stepper fxLayout="column" [linear]="true" #stepper>
                                            <mat-step editable="false">
                                                <ng-template matStepLabel>India_Diabetes_Roche</ng-template>
                                            </mat-step>
                                            <div>
                                                <mat-step editable="false">
                                                    <ng-template matStepLabel>Patient who has completed 15 days
                                                    </ng-template>
                                                </mat-step>
                                                <mat-step editable="false">
                                                    <ng-template matStepLabel>Diabetes
                                                    </ng-template>
                                                </mat-step>
                                            </div>
                                        </mat-vertical-stepper>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="temp-title">Template</h6>
                                    <div class="template-data">
                                        Patient who is diabetic and has logged less than 15
                                        mins of activity last week.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="all-patients">
                            <div class="title-holder">
                                <h4>All patients</h4>
                                <form class="search-form">
                                    <div class="form-group has-feedback has-search">
                                        <input class="form-control" placeholder="Search healthcoach">
                                        <button mat-fab class="searchbtn" style="display: none;">
                                            <mat-icon matSuffix>search</mat-icon>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="container-table">
                                        <mat-selection-list>
                                            <mat-list-option checkboxPosition="before">
                                                <h5>
                                                    Alexa Leech
                                                </h5>
                                            </mat-list-option>

                                            <mat-list-option checkboxPosition="before">
                                                <h5>
                                                    Alexa Leech
                                                </h5>
                                            </mat-list-option>
                                            <mat-list-option checkboxPosition="before">
                                                <h5>
                                                    Alexa Leech
                                                </h5>
                                            </mat-list-option>
                                        </mat-selection-list>
                                    </div>
                                    <div class="reset-btn mt-30 mr-30 pull-right ">
                                        <button mat-button class="btn btn-medium btn-outline">
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="button-holder mt-40">
                <a mat-button class="btn btn-medium btn-filled mr-10" [routerLink]="['hctaskcomplete']">
                    Send Broadcast
                </a>
            </div>
        </div>
    </div>
</mat-drawer-container>