<div class="row" *ngIf="mediaSaveas !== '1'">
  <div class="col-md-12">
    <h4>Add Prescription</h4>
    <hr />
    <mat-spinner class="upload-spinner" strokeWidth="3" *ngIf="isloadingpres"></mat-spinner>
    <form [formGroup]="demoForm" class="row" validate>
      <div class="col-md-12 hcprescription-upload-holder">
        <file-upload class="file-input" formControlName="files" simple accept=".jpeg,.png,.jpg,.pdf"></file-upload>
      </div>
      <form [formGroup]="dataForm" validate>
        <mat-form-field appearance="outline" class="col-md-12">
          <mat-label>Name of Prescription</mat-label>
          <input matInput placeholder="Name" formControlName="name">
          <mat-error *ngIf="myError('name', 'minlength')">Use atleast 3 characters</mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>Choose a date of Prescription</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="Choose a Start date" formControlName="test_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker panelClass="notranslate" #picker></mat-datepicker>
        </mat-form-field>

        <ng-template let-i="index" let-file="file" let-control="control" #item>
          <div class="file-info">
            <div class="file-items">
              <svg _ngcontent-c5="" viewBox="0 0 96 96" class="fileuploadicon">
                <g _ngcontent-c5="">
                  <path _ngcontent-c5="" d="M71.4,30.7c0.6,0.6,1.2,1.5,1.6,2.5c0.4,1.1,0.7,2.1,0.7,2.9v38.6c0,0.9-0.3,1.7-0.9,2.3
                   S71.4,78,70.5,78h-45c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.4-0.9-2.3V21.2c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9h30
                   c0.9,0,1.9,0.2,2.9,0.7c1.1,0.4,1.9,1,2.5,1.6L71.4,30.7z M56.6,22.6v12.6h12.6c-0.2-0.6-0.5-1.1-0.7-1.4L57.9,23.3
                   C57.7,23,57.2,22.8,56.6,22.6z M69.4,73.7V39.4H55.5c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.4-0.9-2.3V22.3H26.6v51.4H69.4z
                   M65.1,58.7v10.7H30.9V63l6.4-6.4l4.3,4.3L54.4,48L65.1,58.7z M37.3,52.3c-1.8,0-3.3-0.6-4.6-1.9c-1.3-1.2-1.9-2.8-1.9-4.6
                   c0-1.8,0.6-3.3,1.9-4.6c1.3-1.2,2.8-1.9,4.6-1.9s3.3,0.6,4.6,1.9c1.3,1.3,1.9,2.8,1.9,4.6c0,1.8-0.6,3.3-1.9,4.6
                   C40.6,51.7,39.1,52.3,37.3,52.3z"></path>
                </g>
              </svg>
              <div class="file-info"><span class="file-name">{{ file.name }}</span>
                (
                {{ file.size }} )</div>
              <div class="file-buttons" (click)="control.removeFile(file)"><span class="remove-btn"><svg
                    viewBox="0 0 96 96">
                    <g>
                      <path
                        d="M40.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S39.6,38,39.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4
                       s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4S40.5,67.1,40.5,66.8z M50.5,66.8V39.3
                       c0-0.4-0.1-0.7-0.4-0.9S49.6,38,49.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4
                       h2.5c0.4,0,0.7-0.1,0.9-0.4S50.5,67.1,50.5,66.8z M60.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S59.6,38,59.3,38h-2.5
                       c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4
                       S60.5,67.1,60.5,66.8z M39.3,28h17.5l-1.9-4.6c-0.2-0.2-0.4-0.4-0.7-0.4H41.8c-0.3,0.1-0.5,0.2-0.7,0.4L39.3,28z M75.5,29.3v2.5
                       c0,0.4-0.1,0.7-0.4,0.9S74.6,33,74.3,33h-3.8v37c0,2.2-0.6,4-1.8,5.6S66,78,64.3,78H31.8c-1.7,0-3.2-0.8-4.4-2.3s-1.8-3.4-1.8-5.5
                       V33h-3.8c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.5-0.4-0.9v-2.5c0-0.4,0.1-0.7,0.4-0.9s0.5-0.4,0.9-0.4h12.1l2.7-6.5c0.4-1,1.1-1.8,2.1-2.5
                       s2-1,3.1-1h12.5c1,0,2.1,0.3,3.1,1s1.7,1.5,2.1,2.5l2.7,6.5h12.1c0.4,0,0.7,0.1,0.9,0.4S75.5,28.9,75.5,29.3z">
                      </path>
                    </g>
                  </svg> </span> <span class="removetxt">Remove</span></div>
            </div>
          </div>
        </ng-template>
        <div class="col-md-12">
          <button mat-flat-button color="primary" class="centerbtn" (click)="uploadObject()"
            [disabled]="!(dataForm.valid && (demoForm.get('files')?.value?.length > 0))">Upload Files</button>
        </div>
      </form>
    </form>
  </div>
</div>

<div class="row" *ngIf="mediaSaveas === '1'">
  <div class="col-md-12">
    <h4>Add Prescription</h4>
    <hr />
    <mat-spinner class="upload-spinner" strokeWidth="3" *ngIf="isloadingpres"></mat-spinner>
    <form [formGroup]="saveAsMedia" validate>
      <div class="row justify-content-md-center">
        <div class="col-12" align="center">
          <img class="imageboxcss" *ngIf="(fileType == 'jpeg'||fileType =='png'|| fileType == 'jpg')"
            [src]="saveAsDocURL" />
          <a *ngIf="fileType == 'pdf'"> <img class="imageboxcss" [src]="documentPlaceHolder" />
          </a>
        </div>
      </div>
      
      <mat-form-field appearance="outline" class="col-md-12">
        <mat-label>Name of Prescription</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
      <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Choose a date of Prescription</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a Start date" formControlName="test_date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker panelClass="notranslate" #picker></mat-datepicker>
      </mat-form-field>
      <div class="col-md-12">
        <button mat-flat-button color="primary" class="centerbtn" (click)="saveAs()"
          [disabled]="!(saveAsMedia.valid)">Save As</button>
      </div>
    </form>
  </div>
</div>