import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends HttpService {
  public apiUrl: string = environment.serverUrl;
  constructor(private http: HttpClient) {
    super(http)
  }

  public getUserSettings(): Observable<any> {
    return this.get(this.apiUrl + 'dashboard/settings' + '/' + localStorage.getItem('scarletuserid'))
  }

  public updateSettings(settingData: any): Observable<any> {
    return this.patch(this.apiUrl + 'dashboard/settings' +'/'+localStorage.getItem('scarletuserid'), settingData)
  }
}
