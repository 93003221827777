import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { ContextService } from '../../services/context.service';
import { SocketService } from '../../services/socket/socket.service';
import { AddprescriptionComponent } from '../addprescription/addprescription.component';
import { CareyChatComponent } from '../careychat/careychat.component';
import { ChatbotmodalComponent } from '../chatbotmodal/chatbotmodal.component';
import { ShowmodalComponent } from '../showmodal/showmodal.component';
import { UploadprescriptionaldialogComponent } from '../uploadprescriptionaldialog/uploadprescriptionaldialog.component';
import { UserlistnavbarComponent } from '../userlistnavbar/userlistnavbar.component';

declare var $: any;

@Component({
    providers: [UserlistnavbarComponent],
    selector: 'app-renderchats',
    templateUrl: './renderchats.component.html',
    styleUrls: ['./renderchats.component.scss']
})

export class RenderchatsComponent implements OnInit, AfterViewInit, AfterContentChecked {
    timestamp: any;
    deletedId: any;
    chattext: any;
    chatrectype: any;
    recipientStatus: any;
    imgurl: any;
    videourl: any;
    audiourl: any;
    docurl: any;
    video: any;
    audio: any;
    isDocument: any;
    videosource: any;
    audiosource: any;
    clientel: any;
    responseurlp: any;
    rawimgurl: any;
    date: any;
    time: any = null;
    showdate: any;
    chatlength: number = 350;
    isattachment: boolean = false;
    showdatestatus: boolean = false;
    lasttime: boolean = false;
    imgtype: any;
    imgpath: any;
    deeplink: boolean = false;
    color: string = '#479020';
    whatsapp: boolean = false;
    payment_link: boolean = false;
    careychat: boolean = false;
    sendChat: boolean = true;
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public replyId = new BehaviorSubject<any>(false);
    public currentReplyId = this.replyId.asObservable();
    public loadingError$ = new Subject<boolean>();
    documentPlaceHolder = '/assets/img/pdficon.jpg';
    displayBtn: any;
    callData: any = [];
    isCall: boolean = false;
    @Input() chat: any;
    @Input() usId: any;
    @Input() usName: any;
    @Input() last: any;
    @Input() showGallery: any;
    @Input() recStatus: any;
    @Input() totallength: any;
    @Input() lastsetide: any;
    @Input() chatlastId: any;
    @Input() conversationid: any;
    saveImageUrl: any;
    saveAs_docType: any;
    docType: string;
    appMasterData: any;
    saveAsDocType: any;
    @ViewChild('menuTrigger') menuTrigger;
    selectedPatientId: string;
    previewText;

    constructor(
        private comp: UserlistnavbarComponent,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private socketService: SocketService,
        private _bottomSheet: MatBottomSheet,
        private commonService: CommonserviceService,
        private appContext: ContextService
    ) { }

    ngOnInit() {
        this.color = "#FC8D33";
        //(this.chat);
        this.displayBtn = localStorage.getItem('hcSelectedType')
        this.selectedPatientId = localStorage.getItem('selectedPatientId');
        if (this.displayBtn == 'call' || this.displayBtn == 'whatsapp_call') {
            this.isCall = true;
        }
        else {
            this.isCall = false;
        }
        this.renderMessage();
    }

    ngAfterViewInit(): void {
        
    }

    ngAfterContentChecked(): void {
        if (this.chat.replyTo !== null && this.chat.replyTo.rich_text !== null){
            this.previewText = this.createReplyTo();
        }
    }

    getBackgroundColor(v) {
        return 'red';
    }

    prescriptionDialog(path, type): void {
        this.dialog.open(UploadprescriptionaldialogComponent, {
            width: '350px',
            data: {
                path: path,
                type: type,
                id: this.usId
            }
        });
    }
    getChatClass() {
        if ((this.chatrectype != 'other') && (this.showGallery == false)) {
            return 'senderbox'
        } else if ((this.chatrectype == 'other') && (this.showGallery == false)) {
            return 'recieverbox'
        } else {
            return 'gallery'
        }
    }

    urlify(text) {
        return text.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");

    }

    htmlToElement(html) {
    let template = document.createElement('template');
    template.innerHTML = html;
    return template.content.firstChild;
    }

    createElements(){
        let ele = document.createElement("div");
        let richTextArray = [...this.chat.rich_text.data];
        for(let i = 0;i< richTextArray.length;i++){
            let element;
            if (richTextArray[i].type === "text"){
                let textView = document.createElement("div");
                let doc = new DOMParser().parseFromString(richTextArray[i].text, "text/html");
                let newContent =  doc.documentElement.textContent
                let hc_name = JSON.parse(localStorage.getItem('scarletuserdata1'));
                newContent = newContent.replace('&lt;user&gt;',localStorage.getItem('selectedPatientName'));
                newContent = newContent.replace('&lt;HC name&gt;',hc_name.name);
                textView.innerHTML = newContent;
                ele.append(textView);
            }
            if (richTextArray[i].type === "image"){
                let anchorTag = document.createElement("a");
                anchorTag.target = "_blank";
                anchorTag.href = richTextArray[i].src;
                anchorTag.style.display= 'block';
                anchorTag.style.width= '-webkit-fill-available';
                anchorTag.style.textAlign=  richTextArray[i]?.backgroundPosition;

                element = document.createElement("img");
                element.src = richTextArray[i].src;
                element.style.marginBottom = "5px";
                let screenWidth = screen.width;
                let percent = screenWidth * 0.4 * 0.6;

                if (richTextArray[i].hasOwnProperty('width')){

                    if (richTextArray[i].width <= percent && richTextArray[i].width > 0){
                        element.width = richTextArray[i].width;
                    } else {
                        element.width = percent;
                    }
                }

                if(richTextArray[i].hasOwnProperty('width_percent')){

                    if (richTextArray[i].width_percent <= 100 && richTextArray[i].width_percent > 0){
                        element.width = percent * (richTextArray[i].width_percent/100);
                    } else {
                        element.width = percent;
                    }
    
                }
    
                if (!richTextArray[i].hasOwnProperty('width_percent') && !richTextArray[i].hasOwnProperty('width') ){
    
                    element.width = percent;
                }

                element.width = Math.ceil(element.width);

                // height 
                if (richTextArray[i].hasOwnProperty('height')){

                    if (richTextArray[i].height <= percent && richTextArray[i].height > 0){
                        element.height = richTextArray[i].height;
                    } else {
                        element.height = percent;
                    }
                }

                if(richTextArray[i].hasOwnProperty('height_percent')){

                    if (richTextArray[i].height_percent <= 100 && richTextArray[i].height_percent > 0){
                        element.height = percent * (richTextArray[i].height_percent/100);
                    } else {
                        element.height = percent;
                    }
    
                }
    
                if (!richTextArray[i].hasOwnProperty('height_percent') && !richTextArray[i].hasOwnProperty('height') ){
    
                    element.height = percent;
                }

                element.height = Math.ceil(element.height);
                anchorTag.appendChild(element);
                ele.append(anchorTag);
            }
        }

        return ele.outerHTML;
    }

    createHTML(obj){
        let ele = document.createElement("div");
        if (obj.type === "text"){
            let textView = document.createElement("div");
            let doc = new DOMParser().parseFromString(obj.text, "text/html");
                let newContent =  doc.documentElement.textContent
                let hc_name = JSON.parse(localStorage.getItem('scarletuserdata1'));
                newContent = newContent.replace('&lt;user&gt;',localStorage.getItem('selectedPatientName'));
                newContent = newContent.replace('&lt;HC name&gt;',hc_name.name);
                textView.innerHTML = newContent;
                ele.append(textView);
        }
        if (obj.type === "image"){
            let element = document.createElement("img");
            element.src = obj.src;
            element.style.marginBottom = "5px";
            let screenWidth = screen.width;
            let percent = screenWidth * 0.4 * 0.6;

            if (obj.hasOwnProperty('width')){

                if (obj.width <= percent && obj.width > 0){
                    element.width = obj.width;
                } else {
                    element.width = percent;
                }
            }

            if(obj.hasOwnProperty('width_percent')){

                if (obj.width_percent <= 100 && obj.width_percent > 0){
                    element.width = percent * (obj.width_percent/100);
                } else {
                    element.width = percent;
                }

            }

            if (!obj.hasOwnProperty('width_percent') && !obj.hasOwnProperty('width') ){

                element.width = percent;
            }
            element.width = Math.ceil(element.width);
        
            //height 
            if (obj.hasOwnProperty('height')){
                if (obj.height <= percent && obj.height > 0){
                    element.height = obj.height;
                } else {
                    element.height = percent;
                }
            }
            if(obj.hasOwnProperty('height_percent')){

                if (obj.height_percent <= 100 && obj.height_percent > 0){
                    element.height = percent * (obj.height_percent/100);
                } else {
                    element.height = percent;
                }
            }
            if (!obj.hasOwnProperty('height_percent') && !obj.hasOwnProperty('height') ){
                element.height = percent;
            }
            element.height = Math.ceil(element.height);
            ele.append(element);
        }
        return ele.outerHTML;
    }

    createReplyTo(){
        let richTextArray = [...this.chat.replyTo?.rich_text?.data];
        for(let i = 0;i< richTextArray.length;i++){
            if (richTextArray[i].preview){
                return this.createHTML(richTextArray[i]);
            }
        }
        return this.createHTML(richTextArray[0]);
    }

    async renderMessage() {

        var scope = this;
        var parts = this.chat;
    
        this.deletedId = await localStorage.getItem("deletedId");
        this.showdatestatus = this.chat.showdate;
        if (this.date == 'today') {
            this.showdatestatus = false;
        }
        this.timestamp = this.chat.updatedAt;
        var textPart;

        if (this.chat.rich_text === null){
            textPart = this.urlify(parts.body)
        } else {
            textPart = this.createElements();
        }

        textPart = scope.sanitizer.bypassSecurityTrustHtml(textPart);
        var d = new Date(parts.timestamp);
        var todaydate = new Date();
        var todayval = todaydate.getDate() + " " + SHORT_MONTHS[todaydate.getMonth()] + " " + todaydate.getFullYear();

        var dateadd;
        if ((d.getHours() > 12) && (d.getHours() < 24)) {
            dateadd = ("0" + (d.getHours() - 12)).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + " PM";
        }
        if ((d.getHours() < 12) && (d.getHours() > 0)) {
            dateadd = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + " AM";
        }
        if (d.getHours() == 12) {
            dateadd = "12" + ":" + ("0" + d.getMinutes()).slice(-2) + "PM";
        }
        if (d.getHours() == 0) {
            dateadd = "12" + ":" + ("0" + d.getMinutes()).slice(-2) + "AM";
        }

        var chatdate = d.getDate() + " " + SHORT_MONTHS[d.getMonth()] + " " + d.getFullYear();
        if (chatdate == todayval) {
            chatdate = "Today"
        }
        if (localStorage.getItem('crtime')) {
            if (localStorage.getItem('crtime') == dateadd) {
                this.lasttime = false;
            } else {
                localStorage.setItem('crtime', dateadd);
                this.lasttime = true;
            }
        } else {
            localStorage.setItem('crtime', dateadd);
            this.lasttime = true;
        }
        this.time = dateadd;
        this.date = chatdate;
        if (localStorage.getItem('crdate')) {
            if (localStorage.getItem('crdate') == this.date) {
                this.showdate = false;
            } else {
                localStorage.setItem('crdate', this.date);
                this.showdate = true;
            }
        } else {
            localStorage.setItem('crdate', this.date);
            this.showdate = true;
        }
        var scope = this;
        this.chattext = textPart;

        if (this.chat.rich_text === null) {
            if (parts.attachment && parts.attachment.type != "deeplink" && parts.attachment.type != "payment_link" && parts.attachment.type != "carey_chat") {
                this.rawimgurl = parts.attachment.link;
                var rawimgurl = this.rawimgurl;
    
                this.socketService.getfileurl(rawimgurl, parts.attachment.type, data => {
                    if (data != false) {
                        this.responseurlp = data;
                        if ((parts.attachment.type).split("/")[0] === 'image') {
                            scope.imgurl = data;
                            scope.imgpath = parts.attachment.link;
                            scope.imgtype = parts.attachment.type;
                            scope.video = null;
                            scope.audio = null;
                            scope.isDocument = null;
                            scope.isattachment = true;
                        }
                        if ((parts.attachment.type).split("/")[0] == 'video') {
                            scope.video = {
                                sources: [{
                                    src: data
                                }],
                                options: {
                                    autoplay: false,
                                    volume: 0.6
                                }
                            }
                            scope.videosource = scope.sanitizer.bypassSecurityTrustResourceUrl(data);
    
                            scope.audiosource = null;
                            scope.isDocument = null;
                            scope.imgurl = null;
                            scope.isattachment = true;
                        }
                        if ((parts.attachment.type).split("/")[0] == 'audio') {
                            scope.audio = {
                                sources: [{
                                    src: data
                                }],
                                options: {
                                    autoplay: false,
                                    volume: 0.6
                                }
                            }
                            scope.audiosource = scope.sanitizer.bypassSecurityTrustResourceUrl(data);
                            scope.videosource = null;
                            scope.isDocument = null;
                            scope.imgurl = null;
                            scope.isattachment = true;
                        }
    
                        if (((parts.attachment.type).split("/")[0] == 'application') || (parts.attachment.type == 'file')) {
                            scope.docurl = data
                            scope.isDocument = true;
                            scope.video = null;
                            scope.videosource = null;
                            scope.audio = null;
                            scope.audiosource = null;
                            scope.imgurl = null;
                            scope.isattachment = true;
                        }
                    }
                })
            }
            else {
                scope.isattachment = true;
                if (parts.attachment && parts.attachment.type == 'deeplink' && parts.attachment.value != "whatsapp" && parts.attachment.link != 'https://wellthy.page.link/B421') {
    
                    if (parts.attachment.value == 'Weight') {
                        this.color = '#691DD6'
                    }
                    if (parts.attachment.value == 'Meal') {
                        this.color = '#479020'
                    }
                    if (parts.attachment.value == 'Profile Progress') {
                        this.color = '#FC8D33'
                    }
                    if (parts.attachment.value == 'Log') {
                        this.color = '#FC8D33'
                    }
                    if (parts.attachment.value == 'Lab report') {
                        this.color = '#008E9A'
                    }
                    if (parts.attachment.value == 'Activity') {
                        this.color = '#FF7400'
                    }
                    if (parts.attachment.value == 'Blood pressure') {
                        this.color = '#CB0083'
                    }
                    if (parts.attachment.value == 'Symptom') {
                        this.color = '#009BD5'
                    }
                    if (parts.attachment.value == 'Blood sugar') {
                        this.color = '#CB0054'
                    }
                    if (parts.attachment.value == 'Water') {
                        this.color = '#0053D5'
                    }
                    if (parts.attachment.value == 'Peak Flow') {
                        this.color = '#9E56A6'
                    }
                    if (parts.attachment?.additionalData) {
                        if ((parts.attachment.additionalData.replace(/^"(.*)"$/, '$1')) === 'magazine') {
                            this.color = '#FC8D33'
                        }
                        if ((parts.attachment.additionalData.replace(/^"(.*)"$/, '$1')) === 'lesson') {
                            this.color = '#93BC7B'
                        }
                    }
    
                    // if(parts.attachment.value=='Trucos: Ir de compras con PAH'){
                    //     this.color='#FC8D33'
                    // }
                    this.deeplink = true;
    
                }
                if (parts.attachment && parts.attachment.type == 'payment_link') {
                    this.payment_link = true;
                }
                if (parts.attachment && parts.attachment.type == "carey_chat") {
                    this.careychat = true;
                }
                if (parts.attachment && parts.attachment.type == 'deeplink' && parts.attachment.value == 'whatsapp') {
                    this.whatsapp = true;
                }
                if (parts.attachment && parts.attachment.link == 'https://wellthy.page.link/B421') {
                    this.sendChat = false;
                }
            }            
        }

        if (parts.replyTo != null && parts.replyTo.attachment != null && parts.replyTo.attachment.link != null) {
            this.socketService.getfileurl(parts.replyTo.attachment.link, parts.replyTo.attachment.type, data => {
                if (data != false) {
                    if ((this.chat.replyTo.attachment.type).split("/")[0] === 'image') {
                        this.chat.replyTo.attachment['url'] = data;
                        this.chat.replyTo.attachment['mtype'] = 'image';
                    }
                    if ((this.chat.replyTo.attachment.type).split("/")[0] == 'video') {
                        this.chat.replyTo.attachment['url'] = scope.sanitizer.bypassSecurityTrustResourceUrl(data);
                        this.chat.replyTo.attachment['mtype'] = 'video';
                    }
                    if ((this.chat.replyTo.attachment.type).split("/")[0] == 'audio') {
                        this.chat.replyTo.attachment['url'] = scope.sanitizer.bypassSecurityTrustResourceUrl(data);
                        this.chat.replyTo.attachment['mtype'] = 'audio';
                    }
                    if (((this.chat.replyTo.attachment.type).split("/")[0] == 'application') || (this.chat.replyTo.attachment.type == 'file')) {
                        this.chat.replyTo.attachment['url'] = data;
                        this.chat.replyTo.attachment['mtype'] = 'pdf';
                    }
                }
            });
        }
        if ('U' + this.usId == this.chat.sender) {
            this.chatrectype = 'other'
        }
        if (this.usId != this.chat.sender) {
            this.chat.isRead = true
        }
        if (scope.totallength <= 20) {
            $('.chatarea').animate({
                scrollTop: 99999
            });
        }

    }

    openDialog(url, img) {
        this.dialog.open(ShowmodalComponent, {
            width: 'auto',
            height: '80%',
            data: {
                dataurl: url,
                type: img
            }
        });

    }
    botdialog(ide, message) {
        this.dialog.open(ChatbotmodalComponent, {
            width: '70%',
            height: '60%',
            data: {
                id: ide,
                conid: this.conversationid,
                chatmessage: message
            }
        });
    }
    startReply(replyid, text) {
        if (text && text.changingThisBreaksApplicationSecurity == "") {
            text = "Replying to Attachment";
        }

        if (this.chat.rich_text !== null){
            text = "Replying to a multi-media message.";
        }

        let dte =
        {
            "id": replyid,
            "body": text
        }
        this.socketService.replyonid(dte);
    }
    deleteMessage(chat) {
        if (confirm("Are you sure you want to delete the message?")) {
            this.socketService.deleteMessage(this.conversationid, chat.id).subscribe(data => {
                // this.comp.calluser();
                // this.comp.dataSource.getUserList(1, [], '');
                // this.comp.ngOnInit();
                // this.router.navigateByUrl('/healthcoach/home')
                // this.router.navigate(['/healthcoach/home']);
                //let currentUrl = this.router.url;
                let hcId = 'H' + localStorage.getItem('scarletuserid');
                let readrec = { type: 'deleteCursor', conversationId: this.conversationid, userId: hcId, value: data.id, participants: ['H' + hcId, data.sender] };
                this.socketService.setCursor(readrec)
                    .subscribe(data => {
                    }, (error) => {
                    })
                if (this.chatlastId == chat.id) {
                    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    //     this.router.navigate([currentUrl]);
                    // });
                    localStorage.setItem("deletedId", chat.id);
                }

                if (data.status) {
                    $('#' + chat.id).remove();
                }
            })
        }
    }

    navigateTobotChat(data) {
        this._bottomSheet.open(CareyChatComponent, {
            data: data,
            panelClass: 'custom-bottom-carey-css'
        });
    }
    csstype(e) {
        var p = JSON.parse(e).log_type;
        return p
    }
    logtype(e) {
        var p = JSON.parse(e).log_type;
        if (p == 'activity') {
            return 'Activity'
        }
        else if (p == 'blood_pressure') {
            return 'Blood Pressure'
        }
        else if (p == 'blood_sugar') {
            return 'Blood Sugar'
        }
        else {
            return 'Logged'
        }
    }
    logvalue(e) {
        return JSON.parse(e).log_value.split('.')[0] + ''
    }
    getImg(e) {
        return 'assets/img/healthcoach/careychat/' + JSON.parse(e).log_type + '.svg'
    }
    getjson(e) {
        if (this.isJson(e)) {
            return JSON.parse(e)
        }
        else {
            return {}
        }
    }
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    startSaveAs(chat, doctype) {
        var rawImageUrl = (chat.attachment.link);
        var imageType = (chat.attachment.type);

        const toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
        this.socketService.getfileurl(rawImageUrl, imageType, data => {
            if (data != false) {
                this.saveImageUrl = data;
                localStorage.setItem("saveAsDocUrl", this.saveImageUrl);
                toDataURL(this.saveImageUrl)
                    .then(dataUrl => {
                        var strImage = dataUrl.toString();
                        strImage = strImage.replace('data:application/octet-stream', 'data:' + imageType);
                        localStorage.setItem('filesaveAs', JSON.stringify(strImage));
                    })
                this.redirectToSaveAs(doctype);
            }
        })

    }

    getSaveAsDocumentType() {
        this.commonService.getAppMasterData()
            .subscribe(
                (res: any) => this.getAppMasterSuccess((res as any).data)
            )
    }

    private getAppMasterSuccess(data) {
        this.appMasterData = data;
        for (var i = 0; i <= this.appMasterData.length; i++) {
            if (this.appMasterData[i] && this.appMasterData[i].key == 'UPLOAD_DOCUMENT_TO_OPTIONS') {
                this.saveAsDocType = JSON.parse(this.appMasterData[i].value);
            }
        }
    }

    openPrescriptionDialog() {
        var scope = this;
        scope.menuTrigger.closeMenu();
        this.dialog.open(AddprescriptionComponent, {
            width: '500px',
            height: 'auto',
            data: {
                // dataKey: scope.selectedUserID,
            }
        });
    }
    onSelectChange(e) {
        this.saveAs_docType = e.value;
    }
    redirectToSaveAs(doctype) {
        if (doctype === 'Prescription') {
            localStorage.setItem('mediaSaveas', '1');
            this.openPrescriptionDialog()
        } else {

        }
    }
    getadditionalData(additionalData) {
        if (additionalData !== null && additionalData !== undefined) {
            return additionalData.replace(/^"(.*)"$/, '$1');
        }
    }

    scrollToOrginalMsg(elementId: string) {
        const element = this.__getElementById(elementId);
        this.scrollToElement(element);
        element.scrollIntoView({ behavior: "smooth" });
    }

    __getElementById(id: string): HTMLElement {
        const element = document.getElementById(id);
        return element;
    }
    scrollToElement(element: HTMLElement) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}


