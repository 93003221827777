import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserIdleService } from 'angular-user-idle';
import 'hammerjs';
import * as moment from 'moment';
import { ConnectionService } from 'ng-connection-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { CommonserviceService } from './admin/services/commonservice/commonservice.service';
import { ContextService } from './healthcoach/services/context.service';
import { SocketService } from './healthcoach/services/socket/socket.service';
import { SupportService } from './healthcoach/services/support/support.service';
import { AuthenticateService, SystemService } from './shared/services';
import { GlobalsearchComponent } from './utility/globalsearch/globalsearch.component';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '.././environments/environment';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OfflineMessagesService } from './shared/services/offline-messages.service';
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'scarlet';
  pageurl: any;
  status = 'ONLINE';
  isConnected = true;
  public watching: boolean = false;
  private idlePingSubscription;
  private watcher: any;
  public dtime: number = 0;
  public ishc: boolean = false;
  public todaytimelogdata: any;
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public showshimmer: boolean = false;
  public globalShowShimmer: boolean = false;
  public configdata: any;
  private counter: number = 0;
  public pingcounter: number = 0;
  isDebugg: any = environment.isDebugg;
  public todaycount: number = 1;
  public localStoredHCData: any;
  public todaytimedata: any;
  public todaytimelogdata1: any;
  public hrIncrease: number = 0;
  HCTimeLog: any;
  HCMinuteLog: any;
  totalLogs: any;
  scarletId: any;
  UpdateDisable: any;
  localStoredHCData1: any;
  timerData: any;
  timeCounter: number = 0;
  NewDTime: number = 0;
  NewDHr: number = 0;
  openChat: boolean = false;
  FAQSafeurl: SafeResourceUrl;
  FAQurl: string = "https://angular.io/api/router/RouterLink";

  constructor(
    public router: Router, private dialog: MatDialog, private userIdle: UserIdleService, private connectionService: ConnectionService, private socketService: SocketService,
    private authService: AuthenticateService, public appContext: ContextService, private systemService: SystemService, private SupportService: SupportService, private commonserviceService: CommonserviceService, public translate: TranslateService, private sanitizer: DomSanitizer,
    private myElement: ElementRef,
    private offlineMessageService: OfflineMessagesService) {
    var scope = this;
    //translate.setDefaultLang('en');
    //translate.addLangs(['en','es']);
    // translate.setDefaultLang('es');
    const browserLang: string = translate.getBrowserLang();

    this.switchLanguage(browserLang);
    // translate.use(browserLang.match('/en|es') ? browserLang:'en');

    this.appContext.updateTimerLog.subscribe(data => {
      this.getTodaysdata()
    })

    // this.getTodaysdata1();
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        $("input").prop("disabled", false);
        $(':button').prop('disabled', false);
        this.systemService.showSnackBar("Your internet is back, We recommend you to refresh the page for proper functioning", "Ok");

        this.offlineMessageService.startSendingMessages();
      }
      else {
        this.status = "OFFLINE";
        $("input").prop("disabled", true);
        $(':button').prop('disabled', true);
        this.systemService.showSnackBar("No Internet Connection or your internet is not stable", "Ok");
      }
    })

    window.onunload = function () {
      localStorage.removeItem("timerGetData")
    }

  }
  switchLanguage(language: string) {
    this.translate.use(language);
  }
  // NotMobile = true;

  isDark = sessionStorage.getItem('dark');

  async ngOnInit() {
    let g = document.createElement("script");
    g.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    g.setAttribute('type', 'text/javascript');
    g.setAttribute('defer', 'defer');
    g.setAttribute('async', '');

    this.myElement.nativeElement.appendChild(g);

    this.localStoredHCData1 = JSON.parse(localStorage.getItem('scarletuserdata1'));
    this.scarletId = localStorage.getItem('scarletuserid1');

    //  this.getTodaysdata()
    if (sessionStorage.getItem('onhibernate') == null && (localStorage.getItem('scarletuserid1') != JSON.parse(localStorage.getItem('scarletuserdata1')).id)) {
      sessionStorage.setItem('onhibernate', 'true')
      localStorage.setItem('scarletuserid1', JSON.parse(localStorage.getItem('scarletuserdata1')).id)
      // window.location.reload()
    }
    if (sessionStorage.getItem('switchId') && sessionStorage.getItem('onhibernate') == 'true') {
      // 
      this.initSocketAuthToken()
    }

    // if (localStorage.getItem('scarletuserconfig')) {
    //   this.configdata = localStorage.getItem('scarletuserconfig');
    //   this.configdata = JSON.parse(this.configdata);
    //   $('body').addClass(this.configdata.theme);
    // }
    // else {
    //   var uconfig = {
    //     "theme": "light"
    //   }
    //   this.configdata = localStorage.setItem('scarletuserconfig', JSON.stringify(uconfig));
    //   $('body').addClass('light');
    // }

    // Commenting due to #2297 as checkToken returns 400 incase we try to access dashboard after 5mins
    // this.authService.checkToken()
    //   .subscribe(data => {
    //     if ((data as any).status == 200) {
    //       this.commonserviceService.getOneDataById(localStorage.getItem('scarletuserid'), 'users').subscribe();
    //     } else {
    //       this.logoutAccount();
    //     }
    //   })

    if (this.isDark == 'true') {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }


  getTodaysdata1() {
    localStorage.setItem('timerGetData', 'timerGetData');

    var d = new Date();
    var p = new Date();

    let data1 = d.toISOString();
    let data2 = p.toISOString();

    this.timeLogService1(d, p, this.todaycount, (data: any) => {
      if (!data.HttpErrorResponse && data != null) {

        if (this.todaycount == 1) {
          this.SupportService.gethctime(data1, data2, JSON.parse(localStorage.getItem('scarletuserdata1')).id, 'hctime').pipe(
            delay(100),
            finalize(() => { this.loadingSubject.next(true); })
          ).subscribe(data => {
            data = (data as any).data;

            this.todaytimedata = data;
      
            this.totalLogs = this.todaytimedata.total_logs;
      
            this.SupportService.change___timelogData1(this.todaytimedata.total_logs);
            this.HCTimeLog = (this.todaytimedata.total_logs * 6) / 60;
            this.HCMinuteLog = (this.todaytimedata.total_logs * 6) % 60;
          });
        }
        else {
          data.forEach(el => {
            this.todaytimelogdata.push(el)
          });
        }
      }
      let todaytimelogdata = localStorage.getItem('todaytimelogdata')!
      if (todaytimelogdata !== "undefined") {
        this.todaytimelogdata.total_logs = JSON.parse(localStorage.getItem('todaytimelogdata')).total_logs
      }
    })
  }

  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  async timeLogService1(start, end, count, cb) {

    await this.sleep(200)
    let data1 = start.toISOString();
    let data2 = end.toISOString();


    let id = localStorage.getItem('scarletIdValidate');

    this.SupportService.gethctimelog(data1, data2, id, 'hctimelog', count).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingSubject.next(true);
        this.systemService.showSnackBar("Sorry, unable to load health coaches.", "Ok");
        return cb(error);
      }),
      finalize(() => { this.loadingSubject.next(true); })
    ).subscribe(data => {
      data = (data as any).data;
      return cb(data)
    });
  }

  logoutAccount() {
    this.authService.logout()
      .pipe(
        catchError((error: any) => {

          this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        finalize(() => {

        })
      )
      .subscribe(data => {
        this.systemService.showSuccessSnackBar(("Logout Successfully"), "OK");
        window.location.reload();
      });
  }

  ngAfterViewInit(): void {
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    // this.getTodaysdata1();
    /**Call only if we have session */
    let xScope = this;

    let userACL = null;

    if (JSON.parse(localStorage.getItem("scarletuserdata")) && JSON.parse(localStorage.getItem("scarletuserdata")).user_acl) {
      userACL = JSON.parse(localStorage.getItem("scarletuserdata")).user_acl;
    }

    // 
    setTimeout(function () {
      xScope.showshimmer = true;
    }, 200);
    this.globalShowShimmer = this.appContext.showGlobalShimmer.getValue();
    this.appContext.showGlobalShimmer.subscribe(data => {
      this.globalShowShimmer = data;
    });
    this.appContext.userHasLoggedIn.subscribe(data => {
      //init the socke tif user have healthcoach access only

      if (localStorage.getItem('scarletuserid')
        && localStorage.getItem('scarletuserid') != null
        && userACL !== null
        && userACL.includes("healthcoach")
      ) {
        this.ishc = true;
        this.initSocketAuthToken();

        this.userIdle.onTimerStart().subscribe(count => {
          this.dtime = count;
        });

        this.userIdle.onTimeout().subscribe(() => {

          // this.userIdle.stopWatching();
          // window.clearInterval(this.watcher);
          // this.idlePingSubscription.unsubscribe();
          //this.dtime = 0;
          // this.counter = 0;
          // this.watching = true;
          // this.restart();
        });
      }

      else {
        this.ishc = false;
        this.appContext.showGlobalShimmer.next(false);
      }

      if (localStorage.getItem('scarletuserid') && localStorage.getItem('scarletuserid') != null && localStorage.getItem('scarletuserid') && localStorage.getItem('scarletuserid') != null && localStorage.getItem('scarlettoken') && userACL !== null && userACL.includes("Doctor")) {
        this.initSocketAuthToken(true);
        this.appContext.showGlobalShimmer.next(false);
      } else {
        this.appContext.showGlobalShimmer.next(false);
      }
    });

    // let translationCallback= (event)=>{
    //   let id:any = document.getElementsByClassName("goog-te-combo");
    //   let flag = 0;
    //     if (id.length > 0 && flag==0) {
    //         //alert (id[0]);
    //         //
    //         this.switchLanguage(id[0].value);
    //         flag=1;
    //     }
    // }
    // let select = document.querySelector('.goog-te-combo');
    // document.getElementById("translationDetector").addEventListener("DOMSubtreeModified", translationCallback, false);
  }

  openSearchDialog() {
    this.dialog.open(GlobalsearchComponent, {
      width: '350px',
      height: 'auto',
      maxHeight: '500px',
      panelClass: 'gsearchmodal'
    });
  }

  stop() {
    this.userIdle.stopTimer();
    this.dtime = 0;
    this.pingcounter = 0;
  }

  stopWatching() {

    this.userIdle.stopWatching();
    this.counter = 0;
    this.idlePingSubscription.unsubscribe();
    window.clearInterval(this.watcher);
    this.watching = false;
    this.dtime = 0;
  }

  pauseWatching() {
    let time = this.dtime;

    let tcounter = this.counter;
    this.userIdle.stopWatching();
    //this.counter = this.counter;
    this.idlePingSubscription.unsubscribe();
    window.clearInterval(this.watcher);
    this.watching = false;

    //this.userIdle.stopWatching();
    this.counter = tcounter;
    this.dtime = time;

  }

  getTime(d) {
    if (d < 60) {
      return d
    }
    else {
      var p = d / 60;
      var q = d % 60;
      return (p + ":" + q);
    }
  }
  getroundown(a) {
    return Math.floor(a);
  }

  // getroundown1(e) {
  //   
  //   if (this.HCMinuteLog > 59) {
  //     e = e + 1
  //     return Math.floor(e);
  //   }
  //   if (this.HCMinuteLog <= 69) {
  //     return Math.floor(e);
  //   }  
  // }

  // getroundown2(e) {
  //   
  //   let data = e
  //   if (e == 60) {
  //     this.HCMinuteLog = 60;
  //   }
  //   if (data <=59 ) {
  //     return Math.floor(data);
  //   }
  //   else (data >= 60)
  //   {
  //     this.NewDTime = data % 60;
  //     
  //     return Math.floor(this.NewDTime);
  //   } 
  // }

  convertHRMIN(e) {
    let hr = (e * 6) / 60;
    let min = ((e * 6) + this.dtime) % 60;

    hr = Math.floor(hr);
    min = Math.floor(min);
    // 

    // 
    if (min <= 59) {

      if (min == 0) {
        this.NewDHr = hr + 1;
      }
      else {
        this.NewDHr = hr
      }
      // 
      // 
      return this.NewDHr + " Hrs " + min + " Min";

    }
    else (min >= 60)
    {
      this.NewDTime = min % 60;
      let data = Math.floor(this.NewDTime)
      let data1 = Math.floor(this.NewDTime)
      if (data == 0) {
        this.NewDHr = hr + 1;
      }
      // 
      return this.NewDHr + " Hrs " + data1 + " Min";
    }
  }


  startWatching() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => {
      this.pauseWatching();
    });
    var scope = this;
    if (localStorage.getItem('pendingtime') && parseInt(localStorage.getItem('pendingtime')) > 0) {
      this.pingcounter = parseInt(localStorage.getItem('pendingtime'));
    }
    this.idlePingSubscription = this.userIdle.ping$.subscribe(() => {

      scope.dtime = scope.dtime + 1;

      scope.pingcounter = scope.pingcounter + 1;

      var kr = scope.pingcounter % 5;

      localStorage.setItem('pendingtime', JSON.stringify(kr));
      if (scope.pingcounter > 4) {
        this.SupportService.updateHCTime(localStorage.getItem('scarletuserid'), 'timelog').pipe(
          delay(100),
          catchError((error: any) => {

            this.loadingError$.next(true);
            this.UpdateDisable = "disable";
            this.getTodaysdata();
            return throwError(error);
          }),
          finalize(() => {

            this.getTodaysdata();
            this.UpdateDisable = "disable";
            this.loadingSubject$.next(false);
          })
        ).subscribe(data => {

          this.getTodaysdata();
          this.UpdateDisable = "disable";
          this.loadingError$.next(false);
        });
      }
      /* if(this.counter == 0)
      {
        this.counter = this.counter + 1;
        scope.userIdle.stopWatching();
        this.idlePingSubscription.unsubscribe();
        //scope.userIdle.setConfigValues({idle: 60, timeout: 0, ping: 60});
        localStorage.setItem('pendingtime','0');
        scope.dtime = 0;
        window.clearInterval(this.watcher);
        scope.startWatching();
      }
      else
      {
        localStorage.setItem('pendingtime','0');
        scope.dtime = 0;
      } */
    });
    this.watching = true;
  }

  getTodaysdata() {

    let d = new Date();
    let p = new Date();

    d.setHours(0, 0, 0, 0);
    p.setHours(23, 59, 59, 59);

    // var dep = moment(d).toISOString();
    // var pep = moment(p).toISOString();

    var dep = moment(d).format('YYYY-MM-DD');
    var pep = moment(p).format('YYYY-MM-DD');

    if (localStorage.getItem('scarletuserid')) {
      let data = localStorage.getItem('timerGetData');

      if (!data) {
        this.getTodaysdata1();
      }

      this.SupportService.gethctime(dep, pep, localStorage.getItem('scarletuserid'), 'hctime').pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingSubject.next(true);
          this.systemService.showSnackBar("Sorry, unable to load health coaches.", "Ok");
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject.next(true); })
      ).subscribe(data => {

        this.todaytimelogdata = data;

        localStorage.setItem('todaytimelogdata', this.todaytimelogdata.total_logs);
        //this.todaytimelogdata.total_logs = this.todaytimelogdata.total_logs + 1;
      });
    }
  }

  restart() {
    this.pingcounter = 0;
    this.userIdle.resetTimer();
  }

  initSocketAuthToken(b = false) {
    var scope = this;
    this.socketService.registerUser()
      .subscribe(data => {
        this.socketService.registerToken = data.token;
        this.socketService.getSocketConnection(b);
      })
  }
  ngOnDestroy() {
    localStorage.removeItem("timerGetData")
  }
  openFAQ(value) {
    if (value === true) {
      this.openChat = false;
      localStorage.setItem('openChatBot', 'false');
    } else {
      this.openChat = true;
      localStorage.setItem('openChatBot', 'true');
    }
    this.getSanitizedURL();
  }

  close() {
    this.openChat = false;
    localStorage.setItem('openChatBot', 'false');
  }
  getSanitizedURL() {
    let userType = localStorage.getItem('app');
    userType = userType.charAt(0).toUpperCase() + userType.slice(1)
    this.FAQSafeurl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.HCfaqURL + userType);
    return this.FAQurl;

  }
   checkRouterURL(url:any){
    if(url.includes("/doctor") || url.includes("/healthcoach") ){
      return true;
    }
  }
}
