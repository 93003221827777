<div class="pump-view">
    <div class="row">
        <div class="col-md-12">
            <div class="clearfix">
                <div class="pull-left">
                    <h4>Pump</h4>
                </div>
                <div class="pull-right">
                    <div class="close-icon">
                        <i class="material-icons mat-dialog-close" (click)="onNoClick()">
                            close
                        </i>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="itmes">
                <h5>
                    Date
                </h5>
                <p>{{pumpDetails.created_at | date: 'dd-MMM-yyyy'}}</p>
            </div>
        </div>
        <div class="col-md-7">
            <div class="itmes">
                <h5>
                    Brand Name
                </h5>
                <p>{{getPumpName(pumpDetails.pump_id_fk)}}{{pumpDetails.other_pump_name}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="itmes">
                <h5>
                    Type
                </h5>
                <p class="text-capitalize">{{pumpDetails.pump_route_type}}</p>
            </div>
        </div>
        <div class="col-md-7">
            <div class="itmes">
                <h5>
                    Seriel Number
                </h5>
                <p>{{pumpDetails.serial_number}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="itmes">
                <h5>
                    Expiry Date
                </h5>
                <p>{{pumpDetails.expiry_date | date: 'dd-MMM-yyyy'}}</p>
            </div>
        </div>
        <div class="col-md-7">
            <div class="itmes">
                <h5>
                    Repeat in Days
                </h5>
                <p>{{pumpDetails.reminder_joined_data.custom_days}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="itmes">
                <h5>
                   Time
                </h5>
                <p>{{pumpDetails.reminder_joined_data.time[0].t}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="itmes">
                <h5>
                    Pump Photos
                </h5>
            </div>
        </div>
        <div class="col-md-12">
            <ul class="list-inline">
                <li *ngFor="let image of image_path">
                    <img class="image-data img-responsive" [src]="s3url + image.path" height="200px" width="200px">
                </li>

            </ul>
        </div>
    </div>
</div>