<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/therapyedit/therapyleveledit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                Set Therapy Level
            </h3>
            <div class="clearfix"><br /> <br /> </div>
        </div>
        <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
            <div class="col-md-12 leftformsection">
                <div class="col-md-5 leftpadding">
                    <div class="leftborder"></div>
                    <div formArrayName="therapy_levels">
                        <div *ngFor="let lvl of dataForm.get('therapy_levels')['controls']; let j=index;"
                            [formGroupName]="j" class="boxthekeyvalue">
                            <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                <div class="leftdot"></div>
                                <mat-label>Select Level</mat-label>
                                <mat-select formControlName="leveloption"
                                    (selectionChange)="selectedOptions($event.value,j)" #singleSelectQuiz>
                                    <mat-option>
                                        <ngx-mat-select-search [clearSearchInput]="false"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmatquiz" ngModel
                                            (ngModelChange)="modelChangedQuiz($event,j)" ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i>
                                            </div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option class="camelcased" *ngFor="let level of filteredLevel[j] | async"
                                        [value]="level.id" [disabled]="disableOptions(level.id)">
                                        {{level.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 leftpadding">
                        <button mat-flat-button color="primary" class="centerbtn" style="margin-top:50px;"
                            [disabled]="!dataForm.valid">Save Therapy Levels</button>
                    </div>
                </div>

            </div>
        </form>
    </div>

</mat-drawer-container>