import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Observer,Subject } from 'rxjs';
import { EdittaskComponent } from 'src/app/admin/tasklibrary/edittask/edittask.component';
import { AleartmodelComponent } from 'src/app/content/shared/aleartmodel/aleartmodel.component';
import { AuthenticateService } from 'src/app/shared/services';
import { DirtyComponent } from '../models/dirty-component';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AdminGuardGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthenticateService,private dialog: MatDialog) {}
  
  canLoad(route: Route): boolean {
    const isAdmin = this.authService.app === 'admin' || this.authService.app === 'superadmin' || this.authService.app === 'scarlet';
    if (isAdmin) {
      return true;
    }
    this.router.navigateByUrl(`/application`);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAdmin = this.authService.app === 'admin'  || this.authService.app === 'superadmin' || this.authService.app === 'scarlet';
    if (isAdmin) {
      return true;
    }
    this.router.navigateByUrl(`/application`);
    return false;
    
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }


  // canDeactivate(component: EdittaskComponent) {
  //     return component.canDeactivate();
  //   }

  // canDeactivate(
  //   component: EdittaskComponent,
  //   currentRoute: ActivatedRouteSnapshot,
  //   currentState: RouterStateSnapshot,
  //   nextState?: RouterStateSnapshot
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
    
  //   let subject = new Subject<boolean>();
  //   component.canDeactivate();
  //   subject = component.subject;
  //   
  //   return subject.asObservable();
  // }

  // canDeactivate(component: EdittaskComponent, 
  //   route: ActivatedRouteSnapshot, 
  //   state: RouterStateSnapshot) {
  //     return component.canDeactivate ? component.canDeactivate() : true;
  //   }

  canDeactivate(component: EdittaskComponent): Observable<boolean> {
    if (component.editpageopen) {
      return component.confirmDialog();      
    }
    //please import 'of' form 'rxjs/operators'
    return of(true);
  }
  

}
