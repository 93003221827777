import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from '../../../shared/shared.module';
import { UuxidleftnavComponent } from './uuxidleftnav.component';


@NgModule({
  declarations: [

    UuxidleftnavComponent
  ],
  imports: [
    CommonModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  exports:[
    UuxidleftnavComponent
  ],
  providers: [
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class UuxidleftnavModule { }
