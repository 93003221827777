import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { DietPlanAPIService } from 'src/app/shared/services/diet-plan-api.service';
import { ContextService } from '../../../../services/context.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

@Component({
  selector: 'app-create-diet-plan-modal',
  templateUrl: './create-diet-plan-modal.component.html',
  styleUrls: ['./create-diet-plan-modal.component.scss']
})
export class CreateDietPlanModalComponent implements OnInit {

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public id;
  public allowEdit = false;
  public historyData;
  public displayedColumns1 = ["request", "action"];
  public isAdvancePlan = false;
  public currentNumber;
  public currentCountryCode;
  public dob;
  public age_unit;
  public height;
  public height_unit;
  public weight;
  public weight_unit;
  public gender;
  public name;
  public locations = ["India", "Spain"];
  public activity_lvl = ["Sedentary", "Lightly active", "Moderately active", "Very active", "Extremely active"];
  public disease = ["Chronic Pelvic Pain", "Psoriasis", "IHD", "Tuberculosis", "Secondary Cancers", "Anxiety", "IBS", "Obesity", "Ulcerative colitis", "CKD", "Sleep apnea", "Psoriatic Arthritis", "Cardiac Rehabilitation", "Celiac disease", "Diabetes", "Thyroid disorder", "Pulmonary arterial hypertension", "Type 2 Diabetes", "Eclampsia/Pre-eclampsia", "Heartburn", "Type 2 Diabetes on Insulin", "CHF", "Anemia", "Threatened miscarriage", "Dyslipidemia Injectable", "Rheumatoid Arthritis", "Polycystic ovary syndrome(PCOS)", "Dyslipidemia", "Crohn's disease", "Asthma", "Gestational diabetes", "Hypertension", "Depression", "Fibromyalgia", "Obstructive Sleep Apnea (OSA)", "Demo Condition", "Chronic obstructive pulmonary disease", "Lung Cancer", "In vitro Fertilization", "Recurrent pregnancy loss",];
  public primary_goal = ["Weight Loss", "Muscle Gain", "Maintenance", "Improve Health"];
  public secondry_goal = ["Increase energy", "Improve digesion", "Better sleep"];
  public goal_time = ["1 Month", "2 Month", "3 Month"];
  public diet_type = ["Vegetarian", "Ovo-Lacto Semi Vegetarian", "Vegan", "Non-Vegetarian", "Pescatarians", "Halal", "Kosher"];
  public diet_preference = ["Paleo", "Keto", "Gluten-Free", "Intermittent fasting", "Low sodium", "Dairy-Free"];
  public language = ["English", "Hindi", "Spain", "Bangla", "Kannada", "Arabic"];
  public meal_times = ["Breakfast", "Lunch", "Dinner"];
  public snack_times = ["Mid morning Snack", "Evening Snack", "Nighttime Snack"];
  public yes_no = ["Yes", "No"];
  public percentage_of_meals = ["All homecooked", "Mix of restaurant and homecooked", "All meals from restaurants"];
  public cuisines = ["North Indian", "South Indian", "Mexican", "Italian", "Spanish", "Chinese", "Japanese", "Thai", "Vietnamese", "French", "East Indian", "Gujarati", "Marwadi", "Maharashtrian", "Lebanese", "Arabic", "Turkish", "Iranian", "Middle Eastern", "Korean", "Greek", "Thai", "Vietnamese", "Mediterranean" ];
  public commonly_eat_food_item;
  public selected_cuisines = [];
  public selected_percentage_of_meals;
  public language_for_diet_plan = 'English';
  public selected_activity_lvl;
  public selected_location;
  public selected_disease = [];
  public selected_primary_goal;
  public selected_secondry_goal;
  public selected_goal_time;
  public selected_diet_type;
  public selected_diet_preference;
  public location;
  public food_allergies;
  public dietary_restrications;
  public likes_dislikes;
  public food_likes;
  public food_dislikes;
  public allergies;
  public health_issue_history_of_family = [];
  public medications;
  public no_of_meals_consumed_daily = ["Breakfast", "Lunch", "Dinner"];
  public no_of_snacks_consumed_daily = ["Mid morning Snack"];
  public typical_meal_times;
  public eating_enviroment;
  public any_emotional_stress_realted_eating_patterns;
  public familiar_with_macronutrients;
  public familiar_with_micronutrients;
  public cooking_skills_and_willingness_to_prepare_meal_at_home;
  public availibility_of_support_from_friends_family_professionals;
  public access_to_healthy_food_options;
  public budget_for_food_supplements;
  public history = false;
  public reportGenerated = false;
  displayedColumns: string[];
  public loading = false;
  public mealPlanData:any;
  public subscribeObj: any;
  public createdAt;
  public selectedPlanResponse;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateDietPlanModalComponent>,
    private apiService: DietPlanAPIService,
    private systemService: SystemService,
    private appContext: ContextService,
  ) {
    this.history = this.data.history ? true : false;
    if(this.history) {
        this.apiService.getAllDietPlan({ patient_data_fk: this.data.patientId.toString() }).subscribe((data) => {
          if (data.status === 200){
            this.historyData = data.data;
          }
        },
        (error) => {
          this.systemService.handleErrorResponse(error);
        });
    }
    this.dob = this.data.userDietPlanInput.dob;
    let startTime = new Date(this.dob).getTime();
    let currentTime = new Date().getTime()
    this.dob = Math.floor((currentTime - startTime)/31557600000);
    this.height = this.data.userDietPlanInput.height;
    this.height_unit = this.data.userDietPlanInput.height_unit;
    this.weight = this.data.userDietPlanInput.weight;
    this.weight_unit = this.data.userDietPlanInput.weight_unit;
    this.gender = this.data.userDietPlanInput.gender;
    this.name = this.data.userDietPlanInput.name;
    this.age_unit = 'year'
    this.selected_activity_lvl = this.data.userDietPlanInput.activity_lvl;
    this.selected_disease = this.data.userDietPlanInput.therapy_condition_data;
    let index = this.data.userDietPlanInput.therapy_condition_data.indexOf('Wellness');
    if (index !== -1) {
      this.selected_disease.splice(index, 1);
    }
  }

  ngOnInit(): void {
    localStorage.removeItem('mealPlanData');
    localStorage.removeItem('mealPlanDataName');
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      let selectedNotificationData = localStorage.getItem("selectedNotification");
        if (selectedNotificationData == "diet_plan_created_event") {
          this.history = true;
        }
    });
  }

  downloadDietPlan() {
    let reportData = {
      name: `${this.name}`,
      age: `${this.selectedPlanResponse.age}`,
      height: `${this.selectedPlanResponse.height} ${this.selectedPlanResponse.height_unit}`,
      weight: `${this.selectedPlanResponse.weight} ${this.selectedPlanResponse.weight_unit}`,
      gender: `${this.selectedPlanResponse.gender}`,
      dietType: `${this.selectedPlanResponse.diet_type ? this.selectedPlanResponse.diet_type : ''}`,
      dietPerference: `${this.selectedPlanResponse.diet_preference ? this.selectedPlanResponse.diet_preference : ''}`,
      goal: `${this.selectedPlanResponse.primary_goal ? this.selectedPlanResponse.primary_goal: ''}`,
      createdAt: `${this.selectedPlanResponse.created_at}`
    }
    localStorage.setItem('mealPlanData', JSON.stringify(this.mealPlanData));
    localStorage.setItem('mealPlanDataName', this.name);
    localStorage.setItem('mealPlanPatientData', JSON.stringify(reportData));

    window.open('#/healthcoach/dietplanpdfreport/', '_blank');
  }

  onChange(newValue, disCol, index, element) {
    this.mealPlanData.mealPlan[element.id][disCol] = newValue;
  }

  editDietPlan() {
    let editedResponse = [...this.mealPlanData.mealPlan];

    for (let m of editedResponse) {
      delete m.id;
    }
    this.apiService.editDietPlan(this.id, { edited_response: JSON.stringify({mealPlan: editedResponse}) })
    .subscribe((data) => {
      if (data.status === 200) {
        this.systemService.showSuccessSnackBar('Successfully edited diet plan', "Ok");
      }
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }

  createDietPlan() {
    this.loading = true;
    let data:any = {
      patient_data_fk: this.data.patientId.toString(),
      age: this.dob.toString(),
      age_unit: this.age_unit,
      gender: this.gender,
      height: this.height.toString(),
      height_unit: this.height_unit,
      weight: this.weight.toString(),
      weight_unit: this.weight_unit,
      activity_level: this.selected_activity_lvl,
      location: this.selected_location,
      diseases: this.selected_disease.toString(),
      primary_goal: this.selected_primary_goal,
      secondary_goal: this.selected_secondry_goal,
      goal_achieve_time: this.selected_goal_time,
      diet_type: this.selected_diet_type,
      diet_preference: this.selected_diet_preference,
      food_allergies: this.food_allergies,
      dietary_restrications: this.dietary_restrications,
      likes_dislikes: this.likes_dislikes,
      allergies: this.allergies,
      health_issue_history_of_family: this.health_issue_history_of_family.toString(),
      food_likes: this.food_likes,
      food_dislikes: this.food_dislikes,
      cuisines: this.selected_cuisines.toString(),
      commonly_eat_food_item: this.commonly_eat_food_item,
      medications: this.medications,
      no_of_meals_consumed_daily: this.no_of_meals_consumed_daily.toString(),
      no_of_snacks_consumed_daily: this.no_of_snacks_consumed_daily.toString(),
      typical_meal_times: this.typical_meal_times,
      eating_enviroment: this.eating_enviroment,
      any_emotional_stress_realted_eating_patterns: this.any_emotional_stress_realted_eating_patterns,
      familiar_with_macronutrients: this.familiar_with_macronutrients,
      familiar_with_micronutrients: this.familiar_with_micronutrients,
      cooking_skills_and_willingness_to_prepare_meal_at_home: this.cooking_skills_and_willingness_to_prepare_meal_at_home,
      availibility_of_support_from_friends_family_professionals: this.availibility_of_support_from_friends_family_professionals,
      access_to_healthy_food_options: this.access_to_healthy_food_options,
      budget_for_food_supplements: this.budget_for_food_supplements,
      percentage_of_meals: this.selected_percentage_of_meals,
      language_for_diet_plan: this.language_for_diet_plan,
    }

    this.apiService.createDietPlan(data)
    .subscribe((data) => {
      this.loading = false;
      if (data.status === 200) {
        this.selectedPlanResponse = data.data;
        this.mealPlanData = data.data.response;
        this.systemService.showSuccessSnackBar('Your diet plan request is successfull and will get notification once generated in few minutes.', "Ok");
        this.dialogRef.close(false);
      }
    },
    (error) => {
      this.loading = false;
      this.systemService.handleErrorResponse(error);
    });
  }

  onChangeAdvancePlan(value: MatSlideToggleChange) {
    const { checked } = value;
    this.isAdvancePlan = checked;
  }

  showdata(element, type) {
    this.selectedPlanResponse = element;
    this.createdAt = element.created_at
    this.id = element.id;
    if(type == 'raw') {
      this.mealPlanData = element.response;
      this.allowEdit = false;
    } else {
      this.mealPlanData = element?.edited_response ? element?.edited_response : element.response;
      this.allowEdit = true;
    }
    this.displayedColumns = Object.keys(this.mealPlanData.mealPlan[0]);
    this.displayedColumns = this.displayedColumns.filter(m => m != 'id');
    let count = 0;
    for (let m of this.mealPlanData.mealPlan) {
      m.id = count++;
    }
    this.reportGenerated = true;
  }

  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscribeObj.unsubscribe();
  }

}
