import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-patient-information',
  templateUrl: './patient-information.component.html',
  styleUrls: ['./patient-information.component.scss']
})
export class PatientInformationComponent implements OnInit {

  userid: any;
  public searchcriteria: any;
  public showloader: any;
  lastSearch: any;
  status: any = status;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any = [];
  filterdata: any = [];
  dataSource: any;
  tabledata: any;
  displayedColumns: string[] = ['previousCampaign', 'newCampaign', 'newLanguage', 'updatedBy', 'updatedAt'];
  public userName: string;
  UserType: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PatientInformationComponent>, private commonService: CommonserviceService, private systemService: SystemService) { }


  ngOnInit(): void {

    this.userid = this.data.element.id;
    this.userName = this.data.element.name
    
    this.searchcriteria = "";
    this.page = 1;
    this.UserType = localStorage.getItem('userType');
    
    this.getPasswordHistory(this.userid);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  convertDate(date) {
    var d = new Date(date);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()
    
    return currentdate + ' ' + month + ' ' + year + ',' + d.toLocaleTimeString();;

  }

  getPasswordHistory(id) {    
    this.commonService.getPatientInfo(id)
      .subscribe(
        (res: any) => this.onPatientInfoSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )

  }

  private onPatientInfoSuccess(data) {
    
    this.tabledata = data;
    if (this.tabledata.data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
      }
    }
    else {
      if (this.onit) {
        this.tabulardata = this.tabledata.data;
      }
      else {
        for (var k = 0; k < this.tabledata.data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }
}
