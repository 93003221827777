import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';

@Component({
  selector: 'app-routeofadminadd',
  templateUrl: './routeofadminadd.component.html',
  styleUrls: ['./routeofadminadd.component.scss']
})
export class RouteofadminaddComponent implements OnInit {
    public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selectedUserID: any;
    bodydata: any;
    public isinvalid: boolean = false;
    private allfiles: any;
    public uploadedfiletype: any;
    public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
    prescription_type: any;
    responseUrl: any;
    private responsethumbUrl: any;
    upload_prescription_type: any;
    // upload_type: any = upload_type;
    upload_doc_type: any;
    private resdata: any;
    public image_path: any;
    public loadingError$: Subject<boolean> = new Subject<boolean>();
    public roaForm = this.fb.group({
      patient_id_fk: [''],
      route_of_administration: ['', [Validators.required]],
      reason_for_addition: [''],
      log_date: ['', Validators.required]
    });
    isloadingpres: boolean = false;
    private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
    public demoForm = new FormGroup({
      files: this.filesControl,
    });
  
    constructor(public router: Router, public fb: FormBuilder, public dialog: MatDialog, private appContext: ContextService, private userDetailService: UserdetailsService, private systemService: SystemService, private commonService: CommonserviceService) { }
  
    ngOnInit(): void {
      this.selectedUserID = this.appContext.selectedChatUser.getValue();
    }
  

    // Add route of administration

    addRouteofAdmin() {
      var bodydata = this.roaForm.value;
      let reason_for_addition = this.commonService.isHTML(this.roaForm.value.reason_for_addition);
      if(reason_for_addition  === true){
        this.roaForm.value.reason_for_addition.patchValue('')
        return;
      }
      bodydata.route_of_administration = bodydata.route_of_administration;
      bodydata.reason_for_addition = bodydata.reason_for_addition;
      bodydata.patient_id_fk = this.selectedUserID.toString();
      bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
      var newlogdate = new Date(bodydata.log_date);
      var mm = (newlogdate.getMonth() + 1).toString().padStart(2, "0");;
      var dd = newlogdate.getDate().toString().padStart(2, "0");
      var yy = newlogdate.getFullYear();
      bodydata.log_date = yy + '-' + mm + '-' + dd;
      this.userDetailService.addROAData(bodydata)
      .subscribe(
        (res : any) => this.addSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
    private addSuccess(){
      this.dialog.closeAll();
      this.systemService.showSnackBar("Route Of Administration added.", "OK");
    }

  
  }
