<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/conditionlist/conditionedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                Update Condition
            </h3>
        </div>
        <div class="col-md-12">
            <form [formGroup]="dataForm">
                <div class="row">
                    <div class="col-md-12 leftformsection ">
                        <div formArrayName="language_translation" class="col-md-12 no-padding">
                            <div *ngFor="let control of dataForm.get('language_translation')['controls']; let j=index;"
                                [formGroupName]="j">
                                <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                    <mat-label>Enter Text in {{getLang(control.value.id)}}</mat-label>
                                    <input matInput formControlName="text" placeholder="Enter Text">
                                </mat-form-field>
                            </div>
                        </div>

                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Detailed text</mat-label>
                            <textarea matInput placeholder="Enter the Detailed Text" rows="6"
                                formControlName="detailed_text" required></textarea>
                        </mat-form-field>
                        <!-- <button mat-button [matMenuTriggerFor]="menu3" disableRipple
                            class="change-role no-hover-effect">Add Text
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>

                        <mat-menu #menu3="matMenu">
                            <button mat-menu-item *ngFor="let language of alllanguages"
                                (click)="generateOptions(language.id)">{{language.name}}</button>

                           
                        </mat-menu> -->
                        <div class="row">
                            <div class="col-md-12">
                                <button mat-flat-button color="primary" class="centerbtn mt-15"
                                    [disabled]="!dataForm.valid" (click)="dataOject()"> Update Condition</button>
                            </div>
                        </div>


                    </div>
                </div>

            </form>
        </div>
    </div>
</mat-drawer-container>