import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../../shared/guards';
import { CreateonboardingflowComponent } from './createonboardingflow/createonboardingflow.component';
import { OnboardingflowComponent } from './onboardingflow.component';

const routes: Routes = [
    { path: '', component: OnboardingflowComponent, canActivate: [ContentGuardGuard] },
    { path: 'onboarding/create/:id', component: CreateonboardingflowComponent, canActivate: [ContentGuardGuard] },
    
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingflowRoutingModule { }
