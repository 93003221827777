import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from '../../../shared/guards';
import { QuestionanswersComponent } from './questionanswers.component';
import { EditquestionanswerComponent } from './editquestionanswer/editquestionanswer.component';
const routes: Routes = [
  { path: '', component: QuestionanswersComponent, canActivate: [ContentGuardGuard] },
  { path: 'faq/question_answers/edit/:id/:langid/:chid', component: EditquestionanswerComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionanswersRoutingModule { }
