<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <app-adminsectionleftnav></app-adminsectionleftnav>
        <div class="mainrightcontainersecondary">
            <app-breadcrumb route="admin/taskadmin/assigntask"></app-breadcrumb>
            <div class="main-wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <div class="hc-details">
                            <div class="media">
                                <div class="media-left">
                                    <img class="media-object" [src]="s3url + image_path" height="50px" width="50px"
                                        alt="Wellthy">
                                </div>
                                <div class="media-body">
                                    <h4 class="media-heading">{{userdata?.name}}</h4>
                                    <p [ngClass]="userdata.is_active == true ? 'status-active':'status-inactive'">
                                        {{hcStatus(userdata?.is_active)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="filter-search mt-40 mb-40">
                        <div class="col-md-2">
                            <!-- <button mat-button [matMenuTriggerFor]="animals" class="scar-dropdown">Filter</button> -->
                            <button *ngIf="therapyDataFilter.length==0 && clientDataFilter.length==0 && ownerDataFilter.length==0 && priorityDataFilter.length==0 && expiryDataFilter.length==0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown" >Filter</button>
                            <button *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown1">Filter</button>
                            <mat-menu #animals="matMenu" class="multi-menu-list">
                                <button mat-menu-item [matMenuTriggerFor]="therapy">Select therapy</button>
                                <button mat-menu-item [matMenuTriggerFor]="client">Select client</button>
                                <button mat-menu-item [matMenuTriggerFor]="owner">Select owner</button>
                                <button mat-menu-item [matMenuTriggerFor]="priority">Select priority</button>
                                <button mat-menu-item [matMenuTriggerFor]="expiry">Select expiry</button>
                            </mat-menu>


                            <mat-menu #therapy="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="chosenTherapy">
                                    <mat-radio-button *ngFor="let therapy of therapyData" value="therapy.id"
                                        (change)="selectedTherapy(therapy.id,therapy.title)">
                                        {{therapy.title}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>

                            <mat-menu #client="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="chosenClient">
                                    <mat-radio-button *ngFor="let client of clientData" value="client.id"
                                        (change)="selectedClient(client.id,client.title)">
                                        {{client.title}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                            <mat-menu #owner="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="chosenOwner">
                                    <mat-radio-button *ngFor="let owner of ownerList" value="id"
                                        (change)="selectedOwner(owner.id,owner.name)">
                                        {{owner.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>

                            <mat-menu #priority="matMenu" class="custom-menu-filter">

                                <div class="outerlayout">
                                    <div (click)="$event.stopPropagation();">
                                        <h5 class="scar-dropdown-label">Choose from here</h5>
                                        <mat-checkbox [(ngModel)]="highPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox>High</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="mediumPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox1>Medium</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="lowPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox2>Low</mat-checkbox>
                                        <button mat-button class="btn btn-filled"
                                            (click)="selectedPriority()">Done</button>
                                        <button mat-button class="btn btn-outline"
                                            (click)="clear(myCheckBox,myCheckBox1,myCheckBox2)">Clear</button>
                                    </div>
                                </div>

                            </mat-menu>
                            <mat-menu #expiry="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="Chosendate">
                                    <mat-radio-button value="Today" (change)="tagfilter($event.value)">
                                        Today
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="Chosendate">
                                    <mat-radio-button value="Thisweek" (change)="tagfilter($event.value)">
                                        This week
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="Chosendate">
                                    <mat-radio-button value="customDate" (click)="$event.stopPropagation();"
                                        style="overflow: scroll;" (click)="openCalendar($event)">
                                        Custom date
                                    </mat-radio-button>
                                    <input type="text" matInput ngxDaterangepickerMd style="display: none;"
                                        [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" startKey="startDate"
                                        endKey="endDate" [(ngModel)]="selected" name="daterange"
                                        (change)='selectDateRange()' placeholder="choose date" />
                                </mat-radio-group>
                            </mat-menu>


                        </div>
                        <div class="col-md-10">
                            <form>
                                <div class="form-group has-feedback has-search">
                                    <input class="form-control" [(ngModel)]="searchcriteria"
                                        [ngModelOptions]="{standalone: true}" placeholder="Search">
                                    <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                                        <mat-icon matSuffix>search</mat-icon>
                                    </button>
                                    <button class="clear-search" mat-button *ngIf="searchcriteria" matSuffix
                                        mat-icon-button aria-label="Clear" (click)="cancelSearch()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <mat-chip-list *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0">
                        <br />
                        <mat-chip class="notranslate" selectable="true" removable="true" *ngFor="let data of therapyDataFilter" (removed)="remove(data)">
                            {{data.therapyName}}                          
                                <mat-icon matChipRemove>cancel</mat-icon>
                         </mat-chip>
                        <mat-chip   class="notranslate" selectable="true" removable="true" *ngFor="let data of clientDataFilter" (removed)="remove1(data)">
                            {{data.clientName}}                           
                                <mat-icon matChipRemove>cancel</mat-icon>                          
                        </mat-chip>
                        <mat-chip  class="notranslate" selectable="true" removable="true"  *ngFor="let data of ownerDataFilter" (removed)="remove2(data)">
                            {{data.ownerName}}                          
                                <mat-icon matChipRemove>cancel</mat-icon>                            
                        </mat-chip>
                        <mat-chip  class="notranslate" selectable="true" removable="true" *ngFor="let data of priorityDataFilter" (removed)="remove3(data)">
                            {{data.title}}                           
                                <mat-icon matChipRemove>cancel</mat-icon>                          
                        </mat-chip>
                        <mat-chip  class="notranslate" selectable="true" removable="true" *ngFor="let data of expiryDataFilter" (removed)="remove4(data)">
                            {{data.title}}                          
                                <mat-icon matChipRemove>cancel</mat-icon>                           
                        </mat-chip>
                        <mat-chip>
                            <span style="border-color: #EE6505 ;color: #EE6505 ;background-color:white"
                                *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0"
                                (click)="clearChips()">
                                clear all
                            </span>
                        </mat-chip>
                        <br /> <br />
                    </mat-chip-list>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="task-details">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-over">
                                        <h3 class="">Task details</h3>
                                    </div>
                               

                                <div class="col-md-3">
                                    <div class="items" style="text-align: center;">
                                        <h4>Task assigned</h4>
                                        <h5>{{overView?.total ?
                                            overView.total
                                            : '0'}}</h5>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="items" style="text-align: center;">
                                        <h4>Completed</h4>
                                        <h5 class="like"><img src="assets/img/dls/icons/Icon_complete.svg"
                                                class="img-responsive" alt="Wellthy">{{overView?.completed ?
                                            overView.completed
                                            : '0'}}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="items">
                                        <h4>Failed</h4>
                                        <h5 class="dislike">
                                            <img src="assets/img/dls/icons/Icon_Incomplete.svg" class="img-responsive"
                                                alt="Wellthy">{{overView?.failed ?
                                            overView.failed
                                            : '0'}}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="items">
                                        <h4>Pending</h4>
                                        <h5 class="pending"><img src="assets/img/dls/icons/Icon_Pending.svg"
                                                class="img-responsive" alt="Wellthy"> {{overView?.pending ?
                                            overView.pending
                                            : '0'}}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="items">
                                        <h4>Success rate</h4>
                                        <h5 class="success-rate">
                                            <!-- {{overView?.completed/overView?.total*100 |
                                            number:'1.0-0'}}%-->
                                            {{ convertSuccessRate()?convertSuccessRate():'0'}}
                                            %
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="container-table">
                            <table mat-table [dataSource]="dataSource" matSort class="widthfluid bordergrey list-table">

                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef  style="width: 160px;"> Task ID </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2', 'red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday }">
                                        <!-- <span [ngClass]="{'circle green': element.status == '4', 'circle red':(element.status == '2' &&  element.expiry_date < getToday),'circle orange':(element.status == '2' && element.expiry_date > getToday)}"></span> -->
                                        <span [ngClass]="checkcolor(element)"></span>
                                     {{element.task_display_id}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="filter_conditions_summary">
                                    <th mat-header-cell *matHeaderCellDef  style="width: 200px;"> To do task </th>
                                    <td mat-cell
                                        [ngClass]="{'bold-text': element.priority == '2','red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday}"
                                        *matCellDef="let element">
                                        {{element.task_summary}}</td>
                                </ng-container>
                                <ng-container matColumnDef="scope">
                                    <th mat-header-cell *matHeaderCellDef > Scope </th>
                                    <td mat-cell class="text-capitalize"
                                        [ngClass]="{'bold-text': element.priority == '2','red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday}"
                                        *matCellDef="let element"> {{element.scope}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="expiry_date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Expired on </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2','red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday}">
                                        {{convertDate(element.expiry_date)}}</td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                    <td mat-cell class="text-capitalize" *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2','red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday}">
                                        <span *ngIf="element.status == '2' && element.expiry_date > getToday"><img
                                                src="assets/img/dls/icons/Icon_Pending.svg" class="img-responsive"
                                                alt="Wellthy"></span>
                                        <!-- <span *ngIf="failed">
                                        <img src="assets/img/dls/icons/Icon_Incomplete.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </span> -->
                                        <span *ngIf="element.status == '2' &&  element.expiry_date < getToday">
                                            <img src="assets/img/dls/icons/Icon_Incomplete.svg" class="img-responsive"
                                                alt="Wellthy">
                                        </span>
                                        <span *ngIf="element.status == '4'">
                                            <img src="assets/img/dls/icons/Icon_complete.svg" class="img-responsive"
                                                alt="Wellthy">
                                        </span>
                                        {{statusInList(element)}}
                                    </td>
                                </ng-container>



                                <ng-container matColumnDef="priority">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
                                    <td mat-cell class="text-capitalize" *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2','priority-high':element.priority == '2','priority-low ':element.priority == '0','priority-medium':element.priority == '1'}">
                                        <span *ngIf="element.priority == '2'" class="priority high"></span>
                                        <span *ngIf="element.priority == '0'" class="priority low"></span>
                                        <span *ngIf="element.priority == '1'" class="priority medium"></span>
                                        {{priorityInList(element.priority)}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef style="width: 220px;"> Actions</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="action-button-row">
                                            <button mat-button (click)='reAssignTask(element.id)' tooltip="Re-assign"
                                                class="hover-btn-image">
                                                <div class="button-bg-image reassign-btn"></div>
                                            </button>
                                            <button mat-button (click)='viewHistory(element.task_library_id)'
                                                tooltip="History" class="hover-btn-image">
                                                <div class="button-bg-image history-btn"></div>
                                            </button>
                                            <button mat-button (click)='deleteTask(element.id)' tooltip="Delete"
                                                class="hover-btn-image">
                                                <div class="button-bg-image delete-btn"></div>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <div style="text-align: center;font-size: 20px;margin-top: 80px;"
                                *ngIf="dataList.length==0">No
                                Data Found</div>
                            <!-- <div class="spinner-holder" *ngIf="isLoading">
                                <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate"
                                    diameter="50">
                                </mat-progress-spinner>
                            </div> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>