import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SHORT_MONTHS } from 'src/app/shared/data';

@Component({
  selector: 'app-mealbreakup',
  templateUrl: './mealbreakup.component.html',
  styleUrls: ['./mealbreakup.component.scss']
})
export class MealbreakupComponent implements OnInit {

  mealdata: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public fb: FormBuilder) { }

  ngOnInit() {
    this.mealdata = this.data.dataKey;
  }
  getTheTime(dateStamp) {
    if (dateStamp == undefined) {
      return "Not Available";
    } else {
      var datep = new Date(dateStamp * 1000);
      var minval = ('0' + datep.getMinutes()).slice(-2)
      return (datep.getHours() + ":" + minval + " On " + datep.getDate() + " " + SHORT_MONTHS[datep.getMonth()] + " " + datep.getFullYear());
    }
  }
}
