import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ContentserviceService } from '../../../services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { whiteSpaceValidation } from '../../../../utility/validation'
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-createtriggermapping',
  templateUrl: './createtriggermapping.component.html',
  styleUrls: ['./createtriggermapping.component.scss']
})

export class CreatetriggermappingComponent implements OnInit {
  public newitem: boolean = true;
  public showButtonLoader: any;
  public showAllcondition: boolean = false;
  public clientid: any;
  private myhash: any = {};
  private myidhash: any = {};
  public alllogs: any;
  public conditionid: any;
  public mappingid: any;
  public donepatcharr: any;
  todo = [];
  done = [];
  allconditions: any;
  public dataForm = this.fb.group({
    condition_id_data_fk: ["", [Validators.required, whiteSpaceValidation]],
    trigger_ids: this.fb.array([]),
    created_by_data_fk: ['5']
  });

  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Logging Map');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getConditions();
    this.route.params.subscribe(params => {
      this.clientid = params.id;
      this.conditionid = params.condid;
      this.mappingid = params.mappingid;
      if (params.condid == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        const conditionidval = <FormControl>this.dataForm.controls['condition_id_data_fk'];
        conditionidval.setValue(this.conditionid);
        this.getTriggerFlow(this.conditionid);
      }
    });
  }


  // To get Conditions
  // ----------------------------

  getConditions() {
    this.contentService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}')
    .subscribe(
      (res : any) => this.onConditionsSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onConditionsSuccess(data) {
    data = (data as any).data;
    this.allconditions = data.getAllConditions;
  }

  // ----------------------------


  // To get Conditions
  // ----------------------------

  getTriggerFlow(conditionid) {
    this.contentService.getTriggerPriorityForCondition(conditionid)
    .subscribe(
      (res : any) => this.onSuccessTriggerflow(res,conditionid),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onSuccessTriggerflow(data,conditionid) {
    data = (data as any).data;
    this.donepatcharr = data;
    this.dataForm.patchValue(this.donepatcharr);
    for (var k = 0; k < this.donepatcharr.length; k++) {
      this.done.push({
        id: this.donepatcharr[k].id,
        title: this.donepatcharr[k].title
      })
    }
    this.getLogs(conditionid);
  }

  // ----------------------------


  // To get Logs
  // ----------------------------

  getLogs(condid) {
    this.contentService.getAllTriggerForCondition(condid)
    .subscribe(
      (res : any) => this.onSuccessLogs(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onSuccessLogs(data) {
    data = (data as any).data;
    this.alllogs = data;
    if (this.newitem == false) {
      for (let k = 0; k < this.donepatcharr.length; k++) {
        this.alllogs = this.alllogs.filter(a => a.id != this.donepatcharr[k].id);
      }
      this.alllogs.forEach(element => {
        this.myhash[element.id] = element.title;
        this.myidhash[element.title] = element.id;
      });
      this.todo = this.alllogs;
    }
    else {
      this.todo = this.alllogs;
      this.alllogs.forEach(element => {
        this.myhash[element.id] = element.title;
        this.myidhash[element.title] = element.id;
      });
    }
  }

  // ----------------------------

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  // Submitting TriggerMapping Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.trigger_ids = [];
    postdata.condition_id_data_fk = postdata.condition_id_data_fk.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    this.done.forEach(el => {
      postdata.trigger_ids.push(el.id);
    });
    postdata.trigger_ids = JSON.stringify(postdata.trigger_ids);
    if (this.done.length > 0) {
      if (this.newitem == true) {
        this.contentService.createTriggerriority(postdata)
        .subscribe(
          (res : any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
      }
      else {
        delete postdata.created_by_data_fk;
        this.contentService.updatetriggerPriority(postdata, this.mappingid)
        .subscribe(
          (res : any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
      }
    }
    else {
      this.systemService.showSnackBar(("Please drag the logs to the right"), "OK");
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Trigger flow has been prepared"), "OK");
    this.router.navigate(['content/trigger/mapping/']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Trigger flow has been updated"), "OK");
    this.router.navigate(['content/trigger/mapping/']);
  }

  // ----------------------------

}

