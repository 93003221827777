import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../../../shared/shared.module';
import { MediainformationComponent } from './../../../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../../../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../../../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../../../shared/deletemodal/deletemodal.component';

import { ViewactivationcodeComponent } from './viewactivationcode.component';
import { ViewactivationcodeRoutingModule } from './viewactivationcode.routing.module';
import { ContentnavbarModule } from 'src/app/content/shared/contentnavbar/contentnavbar.module';
import { ContentleftnavModule } from 'src/app/content/shared/contentleftnav/contentleftnav.module';
import { TherapysetupleftnavModule } from 'src/app/content/shared/therapysetupleftnav/therapysetupleftnav.module';

@NgModule({
  declarations: [

    ViewactivationcodeComponent,
  ],
  imports: [
    CommonModule,
    ViewactivationcodeRoutingModule,
    ContentnavbarModule,
    ContentleftnavModule,
    TherapysetupleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ViewactivationcodeModule { }
