import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-map-doctor',
  templateUrl: './map-doctor.component.html',
  styleUrls: ['./map-doctor.component.scss']
})

export class MapDoctorComponent implements OnInit {
  docData: any;

  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;

  public searchmathc: FormControl = new FormControl();
  public filteredDoc: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public showButtonLoader: boolean = false;
  userdata: any;
  public dataForm = this.fb.group({
    user_code: [''],
    patient_data_fk: ['']
  });

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<MapDoctorComponent>,
    private systemService: SystemService,
    private commonService: CommonserviceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.userdata = this.data.element;
    this.docData = this.userdata.doct_data;

  }

  public filterdoc() {
    if (!this.docData) {
      return;
    }
    // get the search keyword
    let search = this.searchmathc.value;
    if (!search) {
      this.filteredDoc.next(this.docData.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredDoc.next(
      this.docData.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


  //  Submitting Doctor Data
  // ----------------------------

  public dataObject() {
    var postdata = this.dataForm.value;
    postdata.user_code = this.removeHTMLtags(postdata.user_code).toString();
    postdata.patient_data_fk = this.userdata.patient_data.patient_data.id.toString();
    postdata.assigned_by_data_fk = '5';
    this.commonService.patietDocMapping(postdata)
      .subscribe(
        (res: any) => this.onSuccessStatus(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onSuccessStatus() {
    this.dialogRef.close();
    this.systemService.showSuccessSnackBar(("User has been mapped successfully"), "OK");
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

  // ----------------------------

}
