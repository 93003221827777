import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { ChapterComponent } from './chapter.component';
import { EditchapterComponent } from './editchapter/editchapter.component';
const routes: Routes = [
  { path: '', component: ChapterComponent, canActivate: [ContentGuardGuard] },
  { path: 'chapter/edit/:id/:langid/:chid', component: EditchapterComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChapterRoutingModule { }
