<div class="col-md-12 clearfix">
    <h2 class="pull-left title-modal">Update Patient
    </h2>
    <div class="pull-right">
        <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
    </div>
</div>
<mat-dialog-content>
    <div class="col-md-12">
        <div class="form-wrapper">
            <form [formGroup]="dataForm" validate>
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-12 zeropadleft">
                            <mat-form-field appearance="outline" class="full-width-formfield"
                                *ngFor="let patient of patientData; let i = index;">
                                <mat-label>Current Campaign</mat-label>
                                <input matInput placeholder="Current Campaign" [value]="patient.title"
                                    [name]="'patient.title' + i" [(ngModel)]="current_campaign_id" readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>New Campaign</mat-label>
                                <mat-select placeholder="New Campaign" class="select-role"
                                    formControlName="new_campaign_id" required (infiniteScroll)="getNextSet()"
                                    (openedChange)="myInput.focus()" 
                                    msInfiniteScroll>
                                    <mat-option>
                                        <input class="myInput" #myInput matInput focused="'true'" type="text"
                                            (keyup)="onKey($event.target.value)" placeholder="search">
                                    </mat-option>
                                    <mat-option *ngFor="let patient of patientData" [value]="patient.id"
                                        (click)="KeepSameCampaign(userid)">Keep Same
                                        Campaign</mat-option>
                                    <hr class="hrTag">
                                    <mat-option *ngFor="let campaign of CampaignArray" [value]="campaign.id"
                                        (onSelectionChange)="SecoundaryConditionCheck(campaign.therapy_data_fk)">
                                        {{campaign.title}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft"
                            *ngIf="LanguageTherapyId?.length > 0 && LanguageTherapy?.length==0">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Update Language</mat-label>
                                <mat-select readonly id="keepSameLanguage"  [(value)]="selected" placeholder="Mode" (selectionChange)="newLangSelection($event.value)" required>
                                    <mat-option value="keepSameLanguage"
                                       ><!-- (onSelectionChange)="keepSameLanguage(PatientFullInfo.has_update_language_id)"-->
                                        Keep Same Language({{languageName}})</mat-option>
                                    <mat-option *ngFor="let lang of currentLanguageList" (selectionChange)="newLangSelection($event.value)"  [value]="lang.id">{{lang.name}}
                                    </mat-option><!-- (onSelectionChange)="newLangSelection(lang.id)"-->
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft"
                            *ngIf="LanguageTherapyId.length==0 && LanguageTherapy.length >0">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Update Language</mat-label>
                                <mat-select placeholder="Select Language" (selectionChange)="newLangSelection($event.value)" class="select-role"
                                    formControlName="language_data_fk" required>
                                    <mat-option *ngFor="let lang of LanguageTherapy" [value]="lang.id">{{lang.name}}
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Patient Secoundary condition</mat-label>
                                <mat-select formControlName="conditions" (selectionChange)="onSecondaryCondChange($event.value)" [(ngModel)]="secoundaryConditionId" multiple
                                    #singleSelectTag required>

                                    <mat-option *ngFor="let condition of filteredTherapy" [value]="condition.id">
                                        {{condition.title}}
                                    </mat-option>
                                    <mat-option value ="none" (onSelectionChange)="removeSelectedSecoundaryCondition($event)" >
                                        None
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <p style="color: red;margin: -17px 0 10px;" *ngIf="filteredTherapyData1.length==0">(Patient
                                currently has
                                no secondary
                                conditions)</p>
                            <!-- && doNotChangeCondition!='newValueSelected' -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>   
<mat-dialog-actions align="start" class="col-md-12">
    <button mat-flat-button color="primary" id="form_submit_btn" class="btn btn-filled" (click)="dataObject()"
        [disabled]="disableSubmitBtn">Update
        Patient Program</button>
    <!-- <p style="color: red;margin: 10px 8px 8px;"
        *ngIf="doNotChangeCondition=='similarDataPassed' && doNotChangeLanguage == 'keepSameLanguage'">(All
        configurations are kept the same)</p>&& doNotChangeCondition!='newValueSelected' -->
</mat-dialog-actions>