<form [formGroup]="chatmodalForm" (ngSubmit)="chatModalObject()" validate>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-select placeholder="Select Intent" formControlName="intent"
                                        (selectionChange)="selectedintent($event.value)">
                                        <mat-option *ngFor="let intents of intentdata" value="{{intents.intent}}">
                                            {{intents.intent}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div [hidden]="isIntentSelected">
                                <div class="col-md-12">
                                    <div formArrayName="entityClassified">
                                        <div *ngFor="let options of chatmodalForm.get('entityClassified')['controls']; let j=index"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-select placeholder="Select Entity" formControlName="entity">
                                                        <mat-option *ngFor="let entity of newentity" value="{{entity}}">
                                                            {{entity}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Map Text</mat-label>
                                                    <input matInput formControlName="text" readonly
                                                        (click)="renderselectionmodule(j)">
                                                </mat-form-field>
                                            </div>
                                            <div class="clearfix"><br /></div>
                                        </div>
                                    </div>
                                    <div class="clearfix"><br /></div>
                                </div>
                                <div class="col-md-12">
                                    <button mat-icon-button class="pluscenterbtn" type="button"
                                        (click)="addMorePairs()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-12">
                            <mat-checkbox (change)="clearAllData()">Do you want to write custom message?</mat-checkbox>
                        </div>
                        <div class="clearfix"><br /><br /></div>
                        <div class="col-md-12" [hidden]="showselectioncontainer">
                            <mat-form-field appearance="outline">
                                <mat-label>Chat Message</mat-label>
                                <textarea id="textareamessage" matInput placeholder="Write the description"
                                    [ngModel]="chatmes" [ngModelOptions]="{standalone: true}"
                                    [readonly]="!isCustomSelected"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"><br /><br /></div>
    <div class="col-md-6" [hidden]="isIntentSelected">
        <button mat-flat-button color="primary" class="centerbtn">Save learning</button>
    </div>
</form>

<div class="selectionpanelopen" *ngIf="showselectioncontainer">
    <div class="selectioninnercontainer">
        <p>
            {{selectionText}}
        </p>
        <button (click)="doneselectionfn()">Done Selection</button>
    </div>
</div>