<div style="position: absolute;top: 40%;transform: translateY(-40%);width: 100%;" *ngIf="showshimmer && globalShowShimmer">
      <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
</div>
<!-- <div class="shimmersec" *ngIf="showshimmer">
      <div class="shine" style="float:left;width:64px;height:100vmin;"></div>
      <div class="shine" style="float:left;width:240px;height:100vmin;"></div>
      <div class="shine" style="float: left;width: 200px;height: 25px;position: absolute;margin-top: 20px;margin-left: 15px;z-index:2;"></div>
      <div class="shine" style="float: left;width: 400px;height: 25px;position: absolute;margin-top: 55px;margin-left: 15px;z-index:2;"></div>
      <div class="shine" style="float: left;width: 100%;height: 115px;position: absolute;margin-top: 0px;margin-left: 0px;z-index:1;background: #fafafa;"></div>
      <div class="shine" style="float: left;width: calc(100% - 335px);height: 50px;bottom: 15px;margin-left: 15px;position: absolute;"></div>
</div> -->
<!--<button (click)="switchLanguage('en')" class="notranslate">en</button>
<button (click)="switchLanguage('es')" class="notranslate">es</button>
<div [hidden]="NotMobile" class="showmessageformobiledevice">
      We do not support small and medium sized screens.
</div><span >{{'Home.Title' | translate}}</span>-->
<!-- cdkDragLockAxis="y" -->

<span [routerLink]="['healthcoach']" routerLinkActive="hideishc"></span>

<div style="display: none;">
      <div class="addbg" *ngIf="!watching"></div>
      
      <div class="example-box-dragger" cdkDrag>

            <button mat-icon-button color="primary" (click)="startWatching()" *ngIf="!watching" class="playpausebtn">
                  <mat-icon>play_circle_filled</mat-icon>
            </button>

            <button mat-icon-button color="primary" (click)="pauseWatching()" *ngIf="watching" class="playpausebtn">
                  <mat-icon>pause_circle_filled</mat-icon>
            </button>

            <div class="showcurrenttiming" *ngIf="todaytimelogdata && todaytimelogdata.total_logs && dtime <= 0">
                  <b> {{getroundown((todaytimelogdata.total_logs * 6) / 60)}} Hrs
                        {{(todaytimelogdata.total_logs * 6) % 60 | number : '1.0-0'}} Min</b>
            </div>

            <div class="showcurrenttiming" *ngIf="(todaytimedata && todaytimedata.total_logs ) && dtime <= 0 && totalLogs>=0">
                  <!-- 0 Hrs  0 Min -->
                  {{getroundown((todaytimedata.total_logs * 6) / 60)}} Hrs
                  {{(todaytimedata.total_logs * 6) % 60 | number : '1.0-0'}} Min
            </div>

            <div class="showcurrenttiming" *ngIf="dtime <= 0 && (!todaytimedata || todaytimedata.total_logs==0)">
                  0 Hrs 0 Min
            </div>

            <div class="showcurrenttiming" *ngIf="dtime > 0 && !todaytimedata">
                  {{dtime > 60 ? getroundown(dtime/60 | number) : 00}} Hrs {{dtime % 60}} Min
            </div>

            <div class="showcurrenttiming" *ngIf="dtime > 0 && todaytimedata">
                  {{convertHRMIN(this.todaytimedata.total_logs)}}
            </div>

            <!-- <div class="showcurrenttiming"
                  *ngIf="(!(todaytimelogdata && todaytimelogdata.total_logs)) && dtime <= 0 && !todaytimedata ">
                  0 Hrs 0 Min
            </div> -->

            <!--<div class="showcurrenttiming" *ngIf="dtime > 0">{{dtime > 60 ? getroundown(dtime/60 | number) : 00}} Min
                  {{dtime % 60}} Sec</div>-->
            
      </div>
</div>

<div *ngIf="this.openChat === true;" class="faq-container" id="iframe">
      <div class="card-header">
            <h4 class="card-title"><strong><img style=" height: 40px; width: 40px;" class="card-img-top"
                  src="assets/img/wellthylogo.png" alt="logo"> Scarlet Knowledge Base</strong></h4>
            <a id="close" (click)="close()" class="close"></a>
          </div>
          <iframe *ngIf="openChat" width="100%" height="130%" [src]="FAQSafeurl" frameborder="0" scrolling="auto">
    </iframe>
</div>

<div *ngIf="!(router.url === '/login' || router.url === '/application'  || router.url === '/forgotPassword' || checkRouterURL(router.url))" class="faq-box" cdkDrag>
      <img src="assets/img/adminicon/faq-icon.jpeg" (click)="openFAQ(openChat)" style="border: 2px solid #EE6505;border-radius: 25px;padding: 1px;" width="50px">
</div>

<div class="main-panel">
      <router-outlet></router-outlet>
</div>

<!-- Commented !NotMobile because there was visisbility issue in 991px viewport -->
<!-- <div class="main-panel" [hidden]="!NotMobile">
      <router-outlet></router-outlet>
</div> -->
