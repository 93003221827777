<div class="row">
  <div class="col-md-12">
    <h4>Send Mail</h4>
    <hr/>
      <form [formGroup]="dataForm" validate>
        <mat-form-field appearance="outline" class="col-md-12">
          <mat-label>To</mat-label>
          <textarea matInput placeholder="" rows="3" formControlName="to" readonly></textarea>
        </mat-form-field> 
         
              <div class="col-md-12" style="margin-top:14px;">
                <b><u>Subject :</u></b>
              </div>
              <div class="col-md-12" style="margin-bottom:50px;">
                <b>MRD :- </b><u>{{this.dataVal.mrd_date | date: 'dd-MMM-yyyy'}}</u>, <b>Activation Code :- </b><u>{{this.userDetail.activation_code}}</u>
              </div>
            
          <!-- </mat-form-field>  -->            
        <!-- <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>Subject</mat-label>
         <textarea matInput placeholder="Enter Subject" rows="6" formControlName="subject"></textarea>
        </mat-form-field>  -->
        <hr>
        <div class="col-md-12">
          <button mat-flat-button color="primary" class="centerbtn" (click)="sendMail()">Send</button>
        </div>
      </form>
  </div>
</div>