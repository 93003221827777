import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ContextService as HealthCoachContextService  } from 'src/app/healthcoach/services/context.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { DoctornotesComponent } from './doctornotes.component';
import {AutosizeModule} from 'ngx-autosize';

@NgModule({
  declarations: [
    DoctornotesComponent,
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    AutosizeModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    DoctornotesComponent
  ]
  
})
export class knowUserModule { }
