<div class="status-wrapper">
    <div class="row d-flex-row">
      <div class="col-md-12 left">
        <div class="media">
          <div class="media-body media-middle">
            <h4 class="media-heading">{{userdata.name}}</h4>
            <div class="close-icon">
              <i class="material-icons mat-dialog-close m-top" (click)="onNoClick()">
                close
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="set-status">
    <form [formGroup]="dataForm" validate>
      <div class="row">
        <div class="col-md-12">
          <div class="current-healthcoach">
            <h4>Current Email</h4>
            <h5 class="text" *ngIf="userdata">{{userdata.email}}</h5>
            <h5 *ngIf="!userdata">-</h5>
          </div>
        </div>
        <mat-form-field appearance="outline" class="full-width-formfield col-md-12">
          <mat-label>Edit Email</mat-label>
            <input matInput placeholder="Email ID" formControlName="new_email" required>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="btn-holder">
            <button mat-flat-button color="primary" class="btn-common" (click)="dataObject()">Update</button>
            <button mat-raised-button class="btn-common" (click)="closeDialog()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>