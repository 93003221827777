import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../../shared/guards';
import { CreatequestionnairComponent } from './createquestionnair.component';
const routes: Routes = [
  { path: '', component: CreatequestionnairComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreatequestionnairRoutingModule { }
