<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-therapysetupleftnav></app-therapysetupleftnav>
    <div class="mainrightcontainersecondary">
      <div class="col-md-8">
        <h3 class="commontitleprimary">Onboarding Content</h3>
      </div>
      <div class="col-md-4">
        <!-- <button mat-icon-button class="pluscommontransparent mt15"
          [routerLink]="['/content/question/edit', newsection , primarylanguage, 0]">
          <mat-icon>add</mat-icon>
        </button> -->
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <form>
              <div class="form-group has-feedback has-search">
               
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="widthfluid bordergrey list-table">

            <ng-container matColumnDef="widget_data_fk">
              <th mat-header-cell *matHeaderCellDef> Widget Name </th>
              <td mat-cell *matCellDef="let element" class="camelcased">
                {{getWidgetName(element.widget_data_fk)}} </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <!-- updatedat Column -->
            <ng-container matColumnDef="updatedat">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Last updated </th>
              <td mat-cell class="camelcased" *matCellDef="let element"> {{element.updated_at | daysago}}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">
                  <button  trigger="click mouseenter" mat-button disableRipple
                     [matMenuTriggerFor]="menu3"
                    class="no-hover-effect">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit Content" alt="">
                  </button>
                  <mat-menu #menu3="matMenu">
                    <button mat-menu-item *ngFor="let language of alllanguages"  
                    [routerLink]="['/content/onboardingedit', element.id,  language.id, getChildID(element, language.id),element.widget_data_fk]"><span
                        *ngIf="getChildID(element, language.id) || language.id == 1">
                        <mat-icon>done</mat-icon>
                      </span>{{language.name}}</button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                        [matMenuTriggerFor]="menu3" class="no-hover-effect">Edit
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <mat-menu #menu3="matMenu">
                        <button mat-menu-item *ngFor="let language of alllanguages"
                          [routerLink]="['/content/onboardingedit', element.id,  language.id, getChildID(element, language.id),element.widget_data_fk]"><span
                            *ngIf="getChildID(element, language.id) || language.id == 1">
                            <mat-icon>done</mat-icon>
                          </span>{{language.name}}</button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->

            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>

      </div>
    </div>
  </div>

</mat-drawer-container>