import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../shared/guards';
import { PatientComponent } from './patient.component';
import { PatientuserdetailsComponent } from './patientuserdetails/patientuserdetails.component';
import { PatientpaymentinfoComponent } from './patientpaymentinfo/patientpaymentinfo.component';
import { EditPatientInformationComponent } from './edit-patient-information/edit-patient-information.component';
import { PatientInformationComponent } from './patient-information/patient-information.component';
import { LoginhistoryComponent } from './loginhistory/loginhistory.component';

const routes: Routes = [
  { path: '', component: PatientComponent, canActivate: [AdminGuardGuard] },
  { path: 'patients/paymentinfo/:id', component: PatientpaymentinfoComponent, canActivate: [AdminGuardGuard] },
  { path: 'patients/patientuserdetails', component: PatientuserdetailsComponent, canActivate: [AdminGuardGuard] },
  { path: 'patient/edit/:id', component: EditPatientInformationComponent, canActivate: [AdminGuardGuard] },
  { path: 'patient/patientHistory/:id', component: PatientInformationComponent, canActivate: [AdminGuardGuard] },
  { path: 'patient/loginhistory/:id', component: LoginhistoryComponent, canActivate: [AdminGuardGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule {}