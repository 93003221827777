import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { Router } from '@angular/router';


@Component({
  selector: 'app-patient-edit-model',
  templateUrl: './patient-edit-model.component.html',
  styleUrls: ['./patient-edit-model.component.scss']
})
export class PatientEditModelComponent implements OnInit {

  
  message: string = "Are you sure you want to update patient program?This operation is irreversible and Will result patient progress data and therapy start date reset!!";
  confirmButtonText = "Confirm";
  cancelButtonText = "Cancel";
  postData:any={};

  constructor(private systemService: SystemService, @Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<PatientEditModelComponent>,public commonService: CommonserviceService, public router:Router ) { }

  ngOnInit() {
     this.postData=this.data.element;
    this.dialogRef.updateSize('386px', '150px');
  }

  
  onConfirmClick(): void {
   this.commonService.EditPatient(this.postData)
        .subscribe((res: any) => {
          // this.router.routeReuseStrategy.shouldReuseRoute = function () { return true; };

          let currentUrl = this.router.url + '?';
      
          this.router.navigateByUrl(currentUrl)
            .then(() => {
              this.router.navigated = false;
              this.router.navigate([this.router.url]);
            });
          this.systemService.showSuccessSnackBar(("Patient program updated"), "OK");
          this.dialogRef.close(true);
        },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    
   
  }
  
  onCancelClick(): void {
    this.dialogRef.close(false);
    
  }
}
// private dialog: MatDialog, public dialogRef: MatDialogRef<EditPatientInformationComponent>,
// this.userid = this.data.element.id;
//     this.userName = this.data.element.name
