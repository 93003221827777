/*==========================================
Title: Added different image 
Author: Soundharya AM
Date:   23 April 2020
Last Change :  Added different image for image placeholder
===========================================*/

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface PeriodicElement {
  style: string;
  syntax: string;
  example: string;
  output: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { style: 'Bold', syntax: '** **', example: '**This is bold text**', output: 'This is bold text' },
  { style: 'Italic', syntax: '* *', example: '*This is italic text*', output: 'This is italic text' },
  { style: 'Heading', syntax: '# ', example: '# Heading 1', output: 'Heading' },
  { style: 'Blockquotes', syntax: '> ', example: '> Quoted text', output: '' },
  { style: 'Link', syntax: '[ ](http://)', example: '[Healthy foods](https://www.healthline.com/nutrition/29-cheap-healthy-foods)', output: 'Healthy foods' },
  { style: 'Image', syntax: '![ ](http://)', example: '![Healthy foods](https://images.unsplash.com/photo-1498837167922-ddd27525d352?ixlib=rb-1.2.1&w=1000&q=80)', output: '' },
  { style: 'Bullets', syntax: '- Running', example: '- Running', output: ' Running' },
  { style: 'Numbered Bullets', syntax: '1. Healthy foods', example: '1. Healthy foods', output: '1. Healthy foods' },
  { style: 'Sub Script', syntax: '<sub></sub>', example: '<sub>Subscript</sub>', output: 'Hello <sub>Subscript</sub>' },
  { style: 'Super Script', syntax: '<sup></sup>', example: '<sup>SuperScript</sup>', output: 'Hello <sup>SuperScript</sup>' },
];

@Component({
  selector: 'app-editorinstructions',
  templateUrl: './editorinstructions.component.html',
  styleUrls: ['./editorinstructions.component.scss']
})

export class EditorinstructionsComponent implements OnInit {
  displayedColumns: string[] = ['style', 'syntax', 'example', 'output'];
  dataSource = ELEMENT_DATA;
  constructor(public dialogRef: MatDialogRef<EditorinstructionsComponent>) { }
  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  stylefunc(e) {
    if (e == "Bold") {
      return "bold-text";
    }
    if (e == "Italic") {
      return "italic-text";
    }
    if (e == "Heading") {
      return "heading-text";
    }
    if (e == "Link") {
      return "link-text";
    }
    if (e == "Blockquotes") {
      return "quotes-text";
    }
    if (e == "Bullets") {
      return "bullet-text";
    }
    if (e == "Image") {
      return "image-text";
    }
  }
}



