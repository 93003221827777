
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-editintent',
  templateUrl: './editintent.component.html',
  styleUrls: ['./editintent.component.scss']
})

export class EditintentComponent implements OnInit {
  title: any = "New Intent";
  public newitem: boolean = true;
  public showButtonLoader: any;
  public selfparentid: any;
  public childid: any;
  public langid: any;
  public intentdata: any;
  public ide: any;
  public selfparentide: any;
  public language: any;
  public dataForm = this.fb.group({
    url: ["", [Validators.required, whiteSpaceValidation]],
    title: ["", [Validators.required, whiteSpaceValidation]],
    detailed_text: [""],
    created_by_data_fk: [""],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required]
  });

  constructor(private browserTitle:Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute,private commonService:CommonserviceService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Intent');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe((params) => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.selfparentide.setValue(null);
        this.newitem = true;
        this.language.setValue(this.langid);
      }

      else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.getallIntentData(this.childid);
          this.newitem = false;
        }
        else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallIntentData(this.childid);
        }
        else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallIntentData(params.chid);
        }
      }
    });
  }


  // To Get All Intent data
  // ----------------------------

  getallIntentData(ide) {
    this.contentService.getOneDataById(ide, "intent")
    .subscribe((res: any) => this.getAllIntentSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
     )
  }

  private getAllIntentSuccess(data) {
    this.intentdata = data;
    this.dataForm.patchValue(this.intentdata);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
  }

  // ----------------------------


  // Submitting Intent data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    let detailed_text = this.commonService.isHTML( this.dataForm.value.detailed_text);
    let title = this.commonService.isHTML( this.dataForm.value.title);
    if(detailed_text  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    }
    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    postdata.created_by_data_fk = (localStorage.getItem('scarletuserid')).toString();
    postdata.language_data_fk = (postdata.language_data_fk as string).toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    if (this.newitem == true) {
      this.contentService.postToBlade(postdata, 'intent')
      .subscribe((res: any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
    else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'intent')
      .subscribe((res: any) => this.onUpdateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Intent has been added"), "OK");
    this.router.navigate(['content/intent'])
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Intent has been updated"), "OK");
    this.router.navigate(['content/intent'])
  }

  // ----------------------------


}
