import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedTranslateModule } from 'src/app/shared/shared-translate.module';
import { AddhopitalisationComponent } from '../components/addhopitalisation/addhopitalisation.component';
import { AddprescriptionComponent } from '../components/addprescription/addprescription.component';
import { AdversedrugaddmodalComponent } from '../components/adversedrugaddmodal/adversedrugaddmodal.component';
import { chatSectionModule } from '../components/chatsection/chatsection.module';
import { InfusionsiteaddComponent } from '../components/infusionsiteadd/infusionsiteadd.component';
import { MedicalhistoryaddComponent } from '../components/medicalhistoryadd/medicalhistoryadd.component';
import { EditmedicationComponent } from '../components/medicationadd/editmedication/editmedication.component';
import { MedicationaddComponent } from '../components/medicationadd/medicationadd.component';
import { PumpComponent } from '../components/pump/pump.component';
import { PumpaddComponent } from '../components/pump/pumpadd/pumpadd.component';
import { RenderChatsModule } from '../components/renderchats/renderchats.module';
import { RouteofadminaddComponent } from '../components/routeofadminadd/routeofadminadd.component';
import { ActivityModule } from '../components/tabpartials/activity/activity.module';
import { dairyModule } from '../components/tabpartials/diary/dairy.module';
import { knowUserModule } from '../components/tabpartials/knowuser/knowuser.module';
import { LabReportModule } from '../components/tabpartials/labreports/labreports.module';
import { prescriptionsModule } from '../components/tabpartials/prescriptions/prescriptions.module';
import { ProgramDetailModule } from '../components/tabpartials/programdetail/programdetail.module';
import { ProgramProgressModule } from '../components/tabpartials/programprogress/programprogress.module';
import { TargetsModule } from '../components/tabpartials/targets/targets.module';
import { TasksModule } from '../components/tabpartials/tasks/tasks.module';
import { UserDetailModule } from '../components/tabpartials/userdetail/userdetail.module';
import { userdetailsectionModule } from '../components/userdetailsection/userdetailsection.module';
import { UserlistnavbarModule } from '../components/userlistnavbar/userlistnavbar.module';
import { LoaderModule } from '../components/utilities/loader/loader/loader.module';
import { LoadoErrorModule } from '../components/utilities/loaderror/loadorerror/loadoerror.module';
import { NoDataModule } from '../components/utilities/nodata/nodata/nodata.module';
import { HcleftnavModule } from '../shared/hcleftnav/hcleftnav.module';
import { SharedModule } from './../../shared/shared.module';
//import { BroadcastComponent as HealthCoachBroadcastComponent } from './broadcast/broadcast.component';
//import { CreatebroadcastComponent } from './../broadcast/createbroadcast/createbroadcast.component';
import { ViewbroadcastComponent } from './../broadcast/viewbroadcast/viewbroadcast.component';
import { AddmedicineComponent } from './../components/addmedicine/addmedicine.component';
import { AddreminderComponent } from './../components/addreminder/addreminder.component';
import { AddsymptomsComponent } from './../components/addsymptoms/addsymptoms.component';
import { AlltriggersComponent } from './../components/alltriggers/alltriggers.component';
import { CareyChatComponent } from './../components/careychat/careychat.component';
import { ChatbotmodalComponent } from './../components/chatbotmodal/chatbotmodal.component';
import { DeeplinkComponent } from './../components/deeplink/deeplink.component';
import { DiaryLogDialogComponent } from './../components/diarylog/diarylog/diarylogdialog.component';
import { LabreportdialogComponent } from './../components/labreportdialog/labreportdialog/labreportdialog.component';
import { MealbreakupComponent } from './../components/mealbreakup/mealbreakup/mealbreakup.component';
import { PaymentlinkComponent } from './../components/paymentlink/paymentlink.component';
import { ShowmodalComponent } from './../components/showmodal/showmodal.component';
import { LogtagdialogComponent } from './../components/tabpartials/userdetail/logtagdialog/logtagdialog.component';
import { UploadprescriptionaldialogComponent } from './../components/uploadprescriptionaldialog/uploadprescriptionaldialog.component';
//import { HomeRoutingModule } from './home/home.routing.module';
import { ContextService as HealthCoachContextService } from './../services/context.service';
//import { HcalertsComponent as HealthCoachHcalertsComponent } from './hcalerts/hcalerts.component';
//import { HcprofileComponent } from './hcprofile/hcprofile.component';
//import { HcsettingsComponent as HealthCoachHcsettingsComponent } from './hcsettings/hcsettings.component';
//import { HcsupportComponent as HealthCoachHcsupportComponent } from './hcsupport/hcsupport.component';
//import { HctagsComponent } from './hctags/hctags.component';
//import { HealthcoachRoutingModule } from './healthcoach-routing.module';
import { HomeComponent as HealthCoachHomeComponent } from './home.component';
/*import { HcalertsRoutingModule } from './hcalerts/hcalerts.routing.module';
import { HcprofileRoutingModule } from './hcprofile/hcprofile.routing.module';
import { HcsupportRoutingModule } from './hcsupport/hcsupport.routing.module';
import { HcsettingsRoutingModule } from './hcsettings/hcsettings.routing.module';
import { HctagsRoutingModule } from './hctags/hctags.routing.module';
import { BroadcastRoutingModule } from './broadcast/broadcast.routing.module';
import { UploadprescriptionaldialogRoutingModule } from './components/uploadprescriptionaldialog/uploadprescriptionaldialog.routing.module';
*/ //
//import { HcleftnavComponent as HealthCoachHcleftnavComponent } from './../shared/hcleftnav/hcleftnav.component';
import { HomeRoutingModule } from './home.routing.module';
import { DeviceDialogComponent } from '../components/tabpartials/userdetail/olddevice/olddevice.component';
import { sendUserBroadcastModule } from '../components/senduserbrodcast/senduserbrodcast/senduserbroadcast.module';
import { userTransferModule } from '../components/usertransfer/usertransfer/usertransfer.module';



//import { HcleftnavComponent } from '../shared/hcleftnav/hcleftnav.component';


@NgModule({
  declarations: [
    HealthCoachHomeComponent,
    // HealthCoachUserdetailsectionComponent,
    // UserdetailComponent,
    // ProgramdetailComponent,
    // KnowuserComponent,
    // ProgramprogressComponent,
    // DiaryComponent,
    // PrescriptionsComponent,
    // TargetsComponent,
    // LabreportsComponent,
    // LoadorerrorComponent,
    // NodataComponent,
    // LoaderComponent,
    // RenderchatsComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    AddreminderComponent,
    AlltriggersComponent,
    AddsymptomsComponent,
    AddmedicineComponent,
    AddmedicineComponent,
    // ActivityComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent,
    AddprescriptionComponent,
    PumpComponent,
    PumpaddComponent,
    MedicationaddComponent,
    EditmedicationComponent,
    MedicalhistoryaddComponent,
    RouteofadminaddComponent,
    InfusionsiteaddComponent,
    AdversedrugaddmodalComponent,
    AddmedicineComponent,
    AddhopitalisationComponent,
    DeviceDialogComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HomeRoutingModule,
    HcleftnavModule,
    UserlistnavbarModule,
    SharedTranslateModule,
    SharedModule.forRoot(),
    chatSectionModule,
    userdetailsectionModule,
    UserDetailModule,
    ActivityModule,
    knowUserModule,
    ProgramDetailModule,
    ProgramProgressModule,
    dairyModule,
    LabReportModule,
    prescriptionsModule,
    TargetsModule,
    LoadoErrorModule,
    NoDataModule,
    LoaderModule,
    RenderChatsModule,
    TasksModule,
    sendUserBroadcastModule,
    userTransferModule
  ],
  providers: [
    HealthCoachContextService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

  entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent,
    AddprescriptionComponent,
    PumpaddComponent,
    MedicationaddComponent,
    EditmedicationComponent,
    MedicalhistoryaddComponent,
    RouteofadminaddComponent,
    InfusionsiteaddComponent,
    AdversedrugaddmodalComponent,
    AddhopitalisationComponent
  ],
  // exports:[HomeComponent],
})

export class HomeModule { }
