import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { MedicineComponent } from './medicine.component';
import { MedicineaddComponent } from './medicineadd/medicineadd.component';
const routes: Routes = [
  { path: '', component: MedicineComponent, canActivate: [ContentGuardGuard] },
  { path: 'medicine/edit/:id', component: MedicineaddComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedicineRoutingModule { }
