<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-breadcrumb route="content/questionhome/questionedit"></app-breadcrumb>
    <div class="col-md-12">
      <h3 class="commontitleprimary">
        <button mat-icon-button class="commonbackbtn"
          [routerLink]="['/content/question/']"><i
            class="material-icons">keyboard_arrow_left</i></button>{{newitem ? 'New' : 'Update'}} Question</h3>
    </div>
    <div class="col-md-12">
      <div class="col-md-8">
        <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

          <div class="col-md-12 leftformsection">
            <div class="row">
              <div class="col-md-12 zeropadleft">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Title</mat-label>
                  <input matInput placeholder="Enter the title" formControlName="title" required>
                </mat-form-field>
              </div>
              <div class="col-md-12 zeropadleft">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Correct Explanation</mat-label>
                  <input matInput placeholder="Enter the correct explanation" formControlName="correct_explanation"
                    required>
                </mat-form-field>
              </div>
              <div class="col-md-12 zeropadleft">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Wrong Explanation</mat-label>
                  <input matInput placeholder="Enter the wrong explanation" formControlName="wrong_explanation"
                    required>
                </mat-form-field>
              </div>
              <div class="col-md-12 zeropadleft">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Select Tags</mat-label>
                  <mat-select placeholder="Select Tags" formControlName="tags" required multiple #singleSelectTag>
                    <mat-option>
                      <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                        [placeholderLabel]="'Search Item'" [noEntriesFoundLabel]="'No matching records found'"
                        [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()" ngDefaultControl>
                        <div class="mat-select-search-custom-header-content">
                          <i class="material-icons">
                            search
                          </i></div>
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option class="camelcased select-tags-active" *ngFor="let tag of filteredTags | async"
                      [value]="tag.id">
                      {{tag.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-12 zeropadleft">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Select Type</mat-label>
                  <mat-select formControlName="type" [(ngModel)]="typeselected" required>
                    <mat-option value="text">Text</mat-option>
                    <mat-option value="image">Image </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 zeropadleft" *ngIf="typeselected">
                <mat-form-field appearance="outline" class="full-width-formfield">
                  <mat-label>Select Option Count</mat-label>
                  <mat-select formControlName="option_count" (selectionChange)="generateOptions($event.value)" required>
                    <mat-option [value]="2">2</mat-option>
                    <mat-option [value]="4">4 </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div formArrayName="options" class="mealbox">
                    <div *ngFor="let option of dataForm.get('options')['controls']; let j=index" [formGroupName]="j"
                      class="boxthekeyvalue optionsection">
                      <p class="customlabel">Option {{j+1}}</p>
                      <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Text</mat-label>
                        <input matInput placeholder="Enter the text" formControlName="text">
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="full-width-formfield featureimage"
                        (click)="openmediabox(typeselected,j)"
                        [ngClass]="!(typeselected == 'image') ? 'hidefield' : ''">
                        <mat-label>Select Media</mat-label>
                        <input matInput formControlName="media_id" [required]="typeselected == 'image'" readonly>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Is this a correct option?</mat-label>
                        <mat-select formControlName="is_correct" required>
                          <mat-option [value]="true">Yes</mat-option>
                          <mat-option [value]="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="clearfix"><br /></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <button mat-flat-button type="submit" color="primary" class="centerbtn btn-left"
              [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Question</button>
          </div>
        </form>
      </div>

      <div class="col-md-4 iphone-holder-height">
        <div class="iphonex">
          <div class="adios" id="iphonebox">
            <div class="content-inside">
              <div class="iphonesubtitle">{{dataForm?.value?.title}}</div>
              <div *ngIf="typeselected == 'text'">
                <div *ngIf="!(dataForm?.value.text == '')">
                  <div *ngFor="let option of dataForm.controls.options?.controls; let j=index">
                    <div *ngIf="option?.value?.text" class="options">
                      {{option?.value?.text}}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="typeselected == 'image'">
                <div class="flex-wrapper">
                  <div *ngFor="let option of allmedia; let j=index" class="options-image">
                    <img *ngIf="option" [src]="s3url + option" />
                    <div class="image-text" *ngIf="textCountArray">
                      {{dataForm?.controls?.options?.controls[j].value.text}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>