import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { EditorinstructionsComponent } from '../../shared/editorinstructions/editorinstructions.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
declare var $: any;

@Component({
  selector: 'app-edit-medication',
  templateUrl: './edit-medication.component.html',
  styleUrls: ['./edit-medication.component.scss']
})

export class EditMedicationComponent implements OnInit {
  s3url: any = environment.s3url;
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }

  public dataForm = this.fb.group({
    title: ['', Validators.required],
    medication_unit: ['', Validators.required],
    route_of_administration: [[], Validators.required],
    drug_concentration: this.fb.array([
      this.buildBrodcastSelection(),
    ]),
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    medication_type: ['',Validators.required]
  });

  public searchmatcat: FormControl = new FormControl();
  public filteredCat: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public drug_concentration: FormArray;
  htmlContent: any;
  public mode: any;
  chooseMedia: any;
  medicationdata: any;
  doUpload: any;
  disabled: boolean = true;
  secondarycondition: any;
  public newitem: boolean = true;
  public showButtonLoader: any;
  public alltags: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public allusers: any;
  public renderhtml: any;
  public allconditions: any;
  public newfeild: any;
  private _onDestroy = new Subject<void>();
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCatData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  myHtml: string;
  public allcategories: any;
  allCategory: any;
  RouteofAdmin:any;
  medType:any;
  showCat:boolean = true;
  // incrementFeild:any;
  
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;
  
  constructor(
    private browserTitle: Title,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private contentService: ContentserviceService,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    protected sanitizer: DomSanitizer
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Medication');
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
        // this.buildBrodcastSelection();
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallMagazineData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallMagazineData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallMagazineData(params.chid);
        }
        this.showCat = false;
      }
      this.getMedicationType()
    });
    this.getRoutes();
  }

  ngAfterViewInit() {
  }


  uirender() {
    var scope = this;
    scope.renderhtml = $('.preview-panel').html();
    scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
  }

  additionalTextChange(e) {
    const rawcontent = <FormControl>this.dataForm.controls.raw_content;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    additionaltextcontent.setValue(rawcontent.value);
  }

  openinstructions(): void {
    const instructions = this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });

    instructions.afterClosed().subscribe(result => {
    });
  }



  // ----------------------------

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }

  modelChangedTags() {
    this.filterTags();
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }



  modelChangedCat() {
    this.filterCat();
  }
  
  private filterCat() {
    if (!this.allcategories) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcat.value;
    if (!search) {
      this.filteredCatData.next(this.allcategories.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredCatData.next(
      this.allcategories.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // initializeCategory() {
  //   this.route.params.subscribe(params => {
  //     this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
  //     this.language = <FormArray>this.dataForm.controls.language_data_fk;
  //     this.langid = params.langid;
  //     if (params.id == 'new') {
  //       this.selfparentid = null;
  //       this.newitem = true;
  //       this.selfparentide.setValue(null);
  //       this.language.setValue(this.langid);
  //     } else {
  //       if (params.langid == 1 && params.chid == 0) {
  //         this.childid = params.id;
  //         this.selfparentid = null;
  //         this.newitem = false;
  //         this.getallMagazineData(this.childid);
  //       } else if (params.langid != 1 && params.chid == 0) {
  //         this.newitem = true;
  //         this.childid = params.id;
  //         this.selfparentid = params.id;
  //         this.getallMagazineData(this.childid);
  //       } else {
  //         this.childid = params.chid;
  //         this.newitem = false;
  //         this.selfparentid = params.id;
  //         this.getallMagazineData(params.chid);
  //       }
  //     }
  //   });
  // }
  // To get All Medications
  // ----------------------------

  getallMagazineData(ide) {
    this.contentService.getOneDataById(ide, 'medication')
    .subscribe(
      (res : any) => this.onMagazineSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  
  private onMagazineSuccess(data) {
    const name = <FormArray>(this.dataForm.controls['drug_concentration']);
    name.value.shift();
    name.controls.shift();
    data = (data as any).data;
    this.medicationdata = data;
    
    this.dataForm.patchValue(this.medicationdata);
    // this.incrementFeild = this.medicationdata.drug_concentration;
    // var newTest = []
    // for(var i=0; i<this.incrementFeild.length; i++){
    //   
    //   var obj = { "name": this.incrementFeild[i]};
    //   newTest.push(obj);
    //   
      // this.dataForm.controls.drug_concentration.patchValue(newTest);
      if (this.medicationdata.drug_concentration.length != 0) {
        for (var i = 0; i < this.medicationdata.drug_concentration.length; i++) {
          name.push(this.initTextOptionswithdata(this.medicationdata.drug_concentration[i]));
        }
      }
  // }
  // for (let k = 0; k < this.medicationdata.drug_concentration; k++) {
  //   let txtformelement = (<FormArray>(this.dataForm.controls['drug_concentration'])).at(k);
  //   txtformelement['controls'].fbtxtoptn.setValue(this.medicationdata.drug_concentration[k]);
  // }
    // this.buildBrodcastSelection().controls.name.patchValue(newTest);
    // 
    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
    var scope = this;
    setTimeout(() => {
      scope.uirender();
    }, 1000);
  }

  // ----------------------------
  initTextOptionswithdata(data) {
    return this.fb.group({
      name: [data, Validators.required]
    });
  }

  // To get Users Data
  // ----------------------------

  getUsers() {
    this.contentService.getGraphQlData('{getAllContentUsers(appMode:false, status:true){id,name,display_name}}')
    .subscribe(
      (res : any) => this.onUsersSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private onUsersSuccess(data) {
    data = (data as any).data;
    this.allusers = data.getAllContentUsers;
  }

  // ----------------------------
 
  
  // To get Route of Administration
  // ----------------------------

  getRoutes(){
   this.RouteofAdmin = [{ name: 'Oral', id: 'oral'}, 
   { name: 'Inhaled', id: 'inhaled'},
   { name: 'Subcutaneous', id: 'subcutaneous'},
   { name: 'Intravenous', id: 'intravenous'},
   { name: 'Implantable', id: 'implantable'}]
  }

  // Submitting Medication Data
  // ----------------------------

  dataObject() {
    let newdruglist = [];
    let postdata = this.dataForm.value;
    postdata.title = this.removeHTMLtags(postdata.title).toString();
    postdata.medication_unit =this.removeHTMLtags(postdata.medication_unit).toString();
    postdata.route_of_administration = JSON.stringify(postdata.route_of_administration);
    postdata.drug_concentration.forEach(function(value){
     var converttoInt = parseFloat(value.name)
      newdruglist.push(converttoInt)
 });
    postdata.drug_concentration = JSON.stringify(newdruglist); 
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.medication_type = postdata.medication_type;
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    
    if (this.newitem == true) {
      postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
      this.contentService.postToBlade(postdata, 'medication')
      .subscribe(
        (res : any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    } else {
      this.contentService.updateToBlade( this.childid,this.selfparentid, postdata, 'medication')
      .subscribe(
        (res : any) => this.onUpadteSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
  }

  private onCreateSuccess () {
    this.systemService.showSuccessSnackBar(("Medication has been added"), "OK");
    this.router.navigate(['content/medication']);
  }

  private onUpadteSuccess () {
    this.systemService.showSuccessSnackBar(("Medication has been updated"), "OK");
    this.router.navigate(['content/medication']);
  }

  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      var formelement = <FormArray>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });

  }

 
// Add or remove Input field 
  buildBrodcastSelection(): FormGroup {
    return this.fb.group({
      name: ["", Validators.required]
    })
  }

  get formData(): FormArray { return <FormArray>this.dataForm.get('drug_concentration'); }

  addMoreBrodcast(): void {
    this.drug_concentration = this.dataForm.get('drug_concentration') as FormArray;
    this.drug_concentration.push(this.buildBrodcastSelection());
  }

  removeBrodcast(index: number) {
    this.drug_concentration = this.dataForm.get('drug_concentration') as FormArray;
    this.drug_concentration.removeAt(index);
  }
  changeOptions(e) {
    this.drug_concentration = this.dataForm.get('brodcast_list') as FormArray;
    while (this.drug_concentration.length !== 1) {
      this.drug_concentration.removeAt(0)
    }
  }

  getMedicationType(){
    this.contentService.medicationTypes()
      .subscribe(
        (res : any) => this.onMedTypeSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
    }
    
    private onMedTypeSuccess(data) {
      data = (data as any).data;
      this.medType = data;
    }
    ​ removeHTMLtags(value:string){
      return value.replace( /(<([^>]+)>)/ig, '');
    }
}
