<h4 style="text-align: center;">Caregiver History</h4>
<div style="margin-top: 15px;overflow-y: auto;overflow-x: hidden;height: 400px;" class="tags-table">


  <table  mat-table [dataSource]="dataSource" multiTemplateDataRows class="table-caegiver widthfluid bordergrey list-table">
    <!-- <ng-container matColumnDef="sl_no">
      <th mat-header-cell *matHeaderCellDef>No</th>
      <td mat-cell *matCellDef="let element" class="camelcased">
        {{dataSource.filteredData.indexOf(element)+1}}
      </td>
    </ng-container> -->
    <ng-container matColumnDef="__old">
      <th class="title" mat-header-cell *matHeaderCellDef >Old Value</th>
      <td style="text-align: center" mat-cell *matCellDef="let element" class="camelcased">
       <span *ngIf="(element.name | keyvalue)?.length > 0"><span style="font-style:bold;">Name :</span>{{element.name?.__old }}<br></span>
       <span *ngIf="(element.gender | keyvalue)?.length > 0"><span style="font-style:bold;">Gender :</span>{{element.gender?.__old }}<br></span>
       <span *ngIf="(element.relation | keyvalue)?.length > 0"><span style="font-style:bold;">Relation :</span>{{element.relation?.__old }}<br></span>
       <span *ngIf="(element.phone | keyvalue)?.length > 0"><span style="font-style:bold;">Phone :</span>{{element.phone?.__old }}<br></span>
       <span *ngIf="(element.country_code | keyvalue)?.length > 0"><span style="font-style:bold;">Country Code :</span>{{element.country_code?.__old }}<br></span>
       <span style="word-break: break-all;" *ngIf="(element.email | keyvalue)?.length > 0" ><span style="font-style:bold;">Email :</span>{{element.email?.__old }}<br></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="__new">
      <th class="title" mat-header-cell *matHeaderCellDef >New Value</th>
      <td style="text-align: center" mat-cell *matCellDef="let element" class="camelcased">
        <span *ngIf="(element.name | keyvalue)?.length > 0"> Name : {{element.name?.__new }}<br></span>
       <span *ngIf="(element.gender | keyvalue)?.length > 0">Gender : {{element.gender?.__new }}<br></span>
       <span *ngIf="(element.relation | keyvalue)?.length > 0">Relation : {{element.relation?.__new }}<br></span>
       <span *ngIf="(element.phone | keyvalue)?.length > 0">Phone : {{element.phone?.__new }}<br></span>
       <span *ngIf="(element.country_code | keyvalue)?.length > 0">Country Code : {{element.country_code?.__new }}<br></span>
       <span style="font-style:bold;word-break: break-all;" *ngIf="(element.email | keyvalue)?.length > 0">Email: {{element.email?.__new}}<br></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="updated_by">
      <th class="time" mat-header-cell *matHeaderCellDef>Updated By</th>
      <td  style="text-align: center" mat-cell *matCellDef="let element" class="camelcased">
        <span style="padding-left: 15px;"> {{element.updated_by}}</span><br>       
      </td>
    </ng-container>
    <ng-container matColumnDef="caregiver_updated_date">
      <th class="time" mat-header-cell *matHeaderCellDef>Date & Time</th>
      <td  style="text-align: center" mat-cell *matCellDef="let element" class="camelcased">
        <span style="padding-left: 15px;"> {{element.created_at | date: 'dd/MM/yyyy'}}</span><br>
        <span style="font-size: 10px;"> {{element.created_at | date: 'h:mm a'}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="caregiverColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

        </div>
      </td>
    </ng-container>
    <!-- <ng-container >
     <span *ngIf="element === null">No Changes Found</span>
    </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="caregiverColumns;sticky:true"></tr>
    <tr mat-row *matRowDef="let element; columns: caregiverColumns;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>