<mat-dialog-content class="mat-typography pump-add">
    <div class="row">
        <div class="col-md-12">
            <h3 class="title">Edit Pump</h3>
            <hr />
            <mat-spinner class="upload-spinner" strokeWidth="3" *ngIf="isloadingpres"></mat-spinner>
            <form [formGroup]="dataForm" validate>
                <mat-form-field appearance="outline" *ngIf="!dataForm?.value?.is_other_pump">
                    <mat-label>Select Brand</mat-label>
                    <mat-select formControlName="pump_id_fk" (selectionChange)="onBrandChange($event)">
                        <mat-option class="camelcased" *ngFor="let pump of allpumpdata" [value]="pump.id">
                            {{pump.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="col-md-12">
                    <mat-checkbox formControlName="is_other_pump">
                        Other Pump
                    </mat-checkbox>
                    <br />
                    <br />
                </div>
                <mat-form-field appearance="outline" *ngIf="dataForm?.value?.is_other_pump"
                    (click)="$event.stopPropagation();">
                    <input type="text" formControlName="other_pump_name" matInput placeholder="Other Pump Name">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Select Type</mat-label>
                    <mat-select formControlName="pump_route_type">
                        <mat-option value="subcutaneous">
                            Subcutaneous
                        </mat-option>
                        <mat-option value="intravenous">
                            Intravenous
                        </mat-option>
                        <mat-option value="implantable">
                            Implantable
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Serial Number</mat-label>
                    <input type="text" matInput placeholder="Seriel Number" formControlName="serial_number">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Expiry Date</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Choose a Start date"
                        formControlName="expiry_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="!dataForm?.value?.is_other_pump">
                    <mat-label>Select Pump Status</mat-label>
                    <mat-select [(value)]="selected_valid_pump" formControlName="pump_status"
                        (selectionChange)="onStatusChange($event)" required>
                        <mat-option *ngFor="let item of valid_pump_status" [value]="item.value">{{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="dataForm?.value?.is_other_pump">
                    <mat-label>Select Pump Status</mat-label>
                    <mat-select formControlName="pump_status" [(value)]="other_pump_selected_status"
                        (selectionChange)="onStatusChange($event)" required>
                        <mat-option value="1">
                            Current
                        </mat-option>
                        <mat-option value="2">
                            Reserve
                        </mat-option>
                        <mat-option value="3">
                            Disabled
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline"
                    *ngIf="!(pumpStatus == 1 || pumpStatus == '1' || pumpStatus == '0' || pumpStatus == 0) && (selected_pump_status == 1 || selected_pump_status == '1')">
                    <mat-label>Select Current Pump</mat-label>
                    <mat-select formControlName="select_active_pump" required>
                        <div class="row"
                            style="border: 1px solid black; padding: 0px 0px 10px 0px;margin-left: 5px;margin-right: 5px;border-radius: 6px;">
                            <div style="padding: 7px 0px 0px 25px;" class="col-md-6">
                                <span>Choose from here</span>
                            </div>
                            <div class="col-md-6" style="text-align: end" (click)="openAddNewPump();">
                                <button mat-button style="color: #FC8D33;">
                                    <mat-icon [ngStyle]="{'color':'#FC8D33'}">add</mat-icon>Add new
                                </button>
                            </div>
                        </div>
                        <mat-option *ngFor="let item of selectNewCurrentPumpDropDown; let i =index" [value]="item.id"
                            (selectionChange)="selectActivePump(item);selected = i">
                            <mat-checkbox [checked]="selected === i" (change)="selectActivePump(item);selected = i">
                                {{item.title}} {{item.other_pump_name}}</mat-checkbox>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Repeat in Days</mat-label>
                    <input type="text" formControlName="custom_days" matInput placeholder="Repeat in Days">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Enter the time</mat-label>
                    <input matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time *"
                        formControlName="t">
                    <owl-date-time #dt1 [pickerType]="'timer'"></owl-date-time>
                </mat-form-field>
                <div>
                    <mat-accordion>
                        <mat-expansion-panel style="margin-bottom: 15px;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Optional Parameters
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <mat-form-field appearance="outline">
                                <mat-label>Manufacturer</mat-label>
                                <input type="text" matInput placeholder="Manufacturer" formControlName="manufacturer">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Label for Remodulin</mat-label>
                                <input type="text" matInput placeholder="Label for Remodulin"
                                    formControlName="label_for_remodulin">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Reason of Addition</mat-label>
                                <input type="text" matInput placeholder="Route of Administration"
                                    formControlName="reason_for_addition">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Date of Change</mat-label>
                                <input matInput [matDatepicker]="picker1" placeholder="Choose a Start date"
                                    formControlName="date_of_change">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Reason for change</mat-label>
                                <input type="text" matInput placeholder="Reason for change"
                                    formControlName="reason_for_change">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Hourly Dose</mat-label>
                                <input type="text" matInput placeholder="Hourly Dose" formControlName="hourly_dose">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Time of Infusion</mat-label>
                                <input matInput [matDatepicker]="picker2" placeholder="Choose a Start date"
                                    formControlName="time_of_infusion">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Battery Status</mat-label>
                                <input type="text" matInput placeholder="Battery status"
                                    formControlName="battery_status">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Tempurature</mat-label>
                                <input type="text" matInput placeholder="Tempurature" formControlName="temperature">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Occulsions</mat-label>
                                <input type="text" matInput placeholder="Occulsions" formControlName="occulsions">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Errors/Malfunctions</mat-label>
                                <input type="text" matInput placeholder="Errors/Malfunctions" formControlName="errors">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Positive Pressure Maintained</mat-label>
                                <input type="text" matInput placeholder="Positive Pressure Maintained"
                                    formControlName="positive_pressure_maintained">
                            </mat-form-field>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <form [formGroup]="demoForm" class="row" validate>
                    <div class="hcprescription-upload-holder">
                        <file-upload class="file-input" style="margin: 15px;" formControlName="files" simple
                            accept=".jpeg,.png,.jpg,.pdf"></file-upload>
                    </div>
                    <img *ngIf="image_path !== undefined" class="image-data img-responsive" [src]="s3url + image_path">
                    <ng-template let-i="index" let-file="file" let-control="control" #item>
                        <div class="file-info">
                            <div class="file-items">
                                <svg _ngcontent-c5="" viewBox="0 0 96 96" class="fileuploadicon">
                                    <g _ngcontent-c5="">
                                        <path _ngcontent-c5="" d="M71.4,30.7c0.6,0.6,1.2,1.5,1.6,2.5c0.4,1.1,0.7,2.1,0.7,2.9v38.6c0,0.9-0.3,1.7-0.9,2.3
                     S71.4,78,70.5,78h-45c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.4-0.9-2.3V21.2c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9h30
                     c0.9,0,1.9,0.2,2.9,0.7c1.1,0.4,1.9,1,2.5,1.6L71.4,30.7z M56.6,22.6v12.6h12.6c-0.2-0.6-0.5-1.1-0.7-1.4L57.9,23.3
                     C57.7,23,57.2,22.8,56.6,22.6z M69.4,73.7V39.4H55.5c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.4-0.9-2.3V22.3H26.6v51.4H69.4z
                     M65.1,58.7v10.7H30.9V63l6.4-6.4l4.3,4.3L54.4,48L65.1,58.7z M37.3,52.3c-1.8,0-3.3-0.6-4.6-1.9c-1.3-1.2-1.9-2.8-1.9-4.6
                     c0-1.8,0.6-3.3,1.9-4.6c1.3-1.2,2.8-1.9,4.6-1.9s3.3,0.6,4.6,1.9c1.3,1.3,1.9,2.8,1.9,4.6c0,1.8-0.6,3.3-1.9,4.6
                     C40.6,51.7,39.1,52.3,37.3,52.3z"></path>
                                    </g>
                                </svg>
                                <div class="file-info"><span class="file-name">{{ file.name }}</span>
                                    (
                                    {{ file.size }} )</div>
                                <div class="file-buttons" (click)="control.removeFile(file)"><span
                                        class="remove-btn"><svg viewBox="0 0 96 96">
                                            <g>
                                                <path
                                                    d="M40.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S39.6,38,39.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4
                         s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4S40.5,67.1,40.5,66.8z M50.5,66.8V39.3
                         c0-0.4-0.1-0.7-0.4-0.9S49.6,38,49.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4
                         h2.5c0.4,0,0.7-0.1,0.9-0.4S50.5,67.1,50.5,66.8z M60.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S59.6,38,59.3,38h-2.5
                         c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4
                         S60.5,67.1,60.5,66.8z M39.3,28h17.5l-1.9-4.6c-0.2-0.2-0.4-0.4-0.7-0.4H41.8c-0.3,0.1-0.5,0.2-0.7,0.4L39.3,28z M75.5,29.3v2.5
                         c0,0.4-0.1,0.7-0.4,0.9S74.6,33,74.3,33h-3.8v37c0,2.2-0.6,4-1.8,5.6S66,78,64.3,78H31.8c-1.7,0-3.2-0.8-4.4-2.3s-1.8-3.4-1.8-5.5
                         V33h-3.8c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.5-0.4-0.9v-2.5c0-0.4,0.1-0.7,0.4-0.9s0.5-0.4,0.9-0.4h12.1l2.7-6.5c0.4-1,1.1-1.8,2.1-2.5
                         s2-1,3.1-1h12.5c1,0,2.1,0.3,3.1,1s1.7,1.5,2.1,2.5l2.7,6.5h12.1c0.4,0,0.7,0.1,0.9,0.4S75.5,28.9,75.5,29.3z">
                                                </path>
                                            </g>
                                        </svg> </span> <span class="removetxt">Remove</span></div>
                            </div>
                        </div>
                    </ng-template>
                </form>
            </form>
        </div>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="center">
    <div>
        <button mat-flat-button color="primary" class="centerbtn" (click)="uploadObject()">Update
            Pump</button>
    </div>
</mat-dialog-actions>