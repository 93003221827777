import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from '../../../shared/shared.module';
import { TherapysetupleftnavComponent } from './therapysetupleftnav.component';


@NgModule({
  declarations: [
    TherapysetupleftnavComponent,
  ],
  imports: [
    CommonModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  exports:[
    TherapysetupleftnavComponent,
  ],
  providers: [
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TherapysetupleftnavModule { }
