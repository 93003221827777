<div *ngIf="!macroNutritient" class="diarylog">
  <img src="https://cdn.dribbble.com/users/159981/screenshots/3269471/checkmark2.gif" *ngIf="showsuccess"
    class="img-responsive" />
  <div class="col-md-12">
    <p *ngIf="!showsuccess" class="generictitle">
      What would you like to log?
      <span>Diary logging is for rare use, Kindly avoid logging on
        regular basis and keep motivating users to log.</span>
    </p>
    <br />
  </div>

  <form [formGroup]="labreportForm" *ngIf="!showsuccess" class="formdiarycss"
    [ngClass]="{mealClass: defaultleftoption == 'Meal'}" validate>
    <div class="col-md-6" *ngIf="showReason">
      <mat-form-field class="customselectboxui" appearance="outline">
        <mat-label>Choose Type</mat-label>
        <mat-select formControlName="labtype" [(ngModel)]="defaultleftoption" required
          (selectionChange)="changeRange($event.value)" placeholder="Choose Type">
          <mat-option class="camelcased" *ngFor="let tag of eligibleLogData" [value]="tag.title">
            {{tag.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-12" *ngIf="!showReason">
      <mat-form-field class="customselectboxui" appearance="outline">
        <mat-label>Choose Type</mat-label>
        <mat-select formControlName="labtype" [(ngModel)]="defaultleftoption" required
          (selectionChange)="changeRange($event.value)" placeholder="Choose Type">
          <mat-option class="camelcased" *ngFor="let tag of eligibleLogData" [value]="tag.title">
            {{tag.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6" *ngIf="defaultleftoption == 'Activity'" >
      <mat-form-field class="customselectboxui" appearance="outline">
        <mat-label>Choose Activity Type</mat-label>
        <mat-select class="notranslate" formControlName="type" placeholder="Choose Type" required>
          <mat-option value="Cycling">Cycling</mat-option>
          <mat-option value="Running">Running</mat-option>
          <mat-option value="Gym">Gym</mat-option>
          <mat-option value="Walking">Walking</mat-option>
          <mat-option value="Aerobic">Aerobic</mat-option>
          <mat-option value="Swimming">Swimming</mat-option>
          <mat-option value="Yoga">Yoga</mat-option>
        </mat-select>
      </mat-form-field>
    </div>




    <!-- <div class="clearfix" *ngIf="defaultleftoption == 'symptoms'"><br/></div>
<div class="col-md-6 " *ngIf="defaultleftoption == 'symptoms'">
    <mat-form-field style="width: 100%;" *ngIf="defaultleftoption == 'symptoms'">
        <mat-select formControlName="symval" placeholder="How severe is your condition?">
          <mat-option value="none">None</mat-option>
          <mat-option value="mild">Mild</mat-option>
          <mat-option value="moderate">Moderate</mat-option>
          <mat-option value="severe">Severe</mat-option>
          <mat-option value="extreme">Extreme</mat-option>
        </mat-select>
      </mat-form-field>
</div> -->
    <!-- <div class="col-md-6 " *ngIf="defaultleftoption == 'symptoms'">
    <mat-form-field style="width: 100%;" *ngIf="defaultleftoption == 'symptoms'">
        <mat-select formControlName="score" placeholder="Score">
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="4">4</mat-option>
          <mat-option [value]="5">5</mat-option>
        </mat-select>
      </mat-form-field>
</div> -->

    <mat-form-field class="col-md-12" appearance="outline" *ngIf="selectedTag=='Infusion Site' && showReason != true">
      <mat-label>Infusion Start Date</mat-label>
      <input matInput [min]="lastLogData" [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date"
        formControlName="on_date" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-md-12" appearance="outline" *ngIf="showReason == true">
      <mat-label>Infusion Change Date</mat-label>
      <input matInput [min]="lastLogData" [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date"
        formControlName="on_date" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-md-12" appearance="outline"
      *ngIf="selectedTag!='Infusion Site' && showReason == undefined ">
      <mat-label>Choose a date</mat-label>
      <input matInput [min]="lastLogData" type="text" [max]="maxDate" [matDatepicker]="picker"
        placeholder="Choose a date" formControlName="on_date" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Activity'" appearance="outline">
      <mat-label>Choose Activity Log Type</mat-label>
      <mat-select formControlName="activity_log_type" (selectionChange)="acitivityInputType($event.value)"
        placeholder="Choose Activity Log Type" required>
        <mat-option value="mins">Hours:Minutes</mat-option>
        <mat-option value="Kcal">Kcal</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- commneted this code , body parameter is divided in to two segments  -->
    <!-- <div class="col-md-6">
      <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Body Parameters: Hip waist ratio'" appearance="outline">
        <mat-label>Choose Measurement Type</mat-label>
        <mat-select class="notranslate" [(ngModel)]="measurement_Value" formControlName="measurement_type"
          placeholder="Choose Type" required>
          <mat-option value="Temperature">Temperature</mat-option>
          <mat-option value="Hip_waist_ratio">Hip waist ratio</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <div class="col-md-12" *ngIf="defaultleftoption == 'Body Parameters: Hip waist ratio'">
      <mat-form-field class="customselectboxui" appearance="outline">
        <mat-label>Choose Hip and Waist Unit</mat-label>
        <mat-select class="notranslate" formControlName="BodyParamUnit" placeholder="Choose Unit"
          (selectionChange)="BodyParamUnit($event.value)" required>
          <mat-option value="cm">cm</mat-option>
          <mat-option value="inch">inch</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Body Parameters: Temperature'"
        appearance="outline">
        <mat-label>Choose Unit</mat-label>
        <mat-select class="notranslate" formControlName="temperature_unit"
          (selectionChange)="temperatureUnit($event.value)" placeholder="Choose Unit" required>
          <mat-option value="Celsius">Celsius</mat-option>
          <mat-option value="Fahrenheit">Fahrenheit</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--Temperature-->
    <div class="col-md-6">
      <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Body Parameters: Temperature'"
        appearance="outline">
        <mat-label>Enter Quantity</mat-label>
        <input matInput placeholder="{{tempplaceholderData}}" minlength="0" maxlength="100" formControlName="value"
          type="number" pattern="^\d+(?:\.\d{0,1})?$" required>
      </mat-form-field>
    </div>



    <!--Hip_waist_ratio(waist)-->
    <mat-form-field class="col-md-6" appearance="outline"
      *ngIf="defaultleftoption == 'Body Parameters: Hip waist ratio'">
      <mat-label>Enter Waist Circumference value</mat-label>
      <input matInput minlength="1" maxlength="100" formControlName="waist"
        placeholder="Waist Range(35-500 cm/14-200 inch)" (change)="waistCircumference($event)" type="number"
        pattern="^\d+(?:\.\d{0,1})?$" required>
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline"
      *ngIf="defaultleftoption == 'Body Parameters: Hip waist ratio'">
      <mat-label>Enter Hip Circumference value</mat-label>
      <input matInput minlength="1" maxlength="100" formControlName="hip" type="number"
        placeholder="Hip Range(35-500cm/14-200 inch)" (change)="hipCircumference($event)" pattern="^\d+(?:\.\d{0,1})?$"
        required>
    </mat-form-field>



    <!--Waist-Hip Ratio-->
    <mat-form-field class="col-md-6" appearance="outline"
      *ngIf="defaultleftoption == 'Body Parameters: Hip waist ratio'">
      <input matInput value="Waist-Hip Ratio" readonly />
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline"
      *ngIf="defaultleftoption == 'Body Parameters: Hip waist ratio'">
      <input matInput minlength="1" maxlength="100" type="number" value="{{aspectRatio}}" pattern="^\d+(?:\.\d{0,1})?$"
        readonly>
    </mat-form-field>


    <!-- <div class="col-md-6">
      <mat-form-field class="customselectboxui" *ngIf="defaultleftoption == 'Measurement'" appearance="outline">
        <mat-label>Choose Measurement Type</mat-label>
        <mat-select class="notranslate" [(ngModel)]="measurement_Value" formControlName="measurement_type"
          placeholder="Choose Type" required>
          <mat-option value="Temperature">Temperature</mat-option>
          <mat-option value="Hip_waist_ratio">Hip waist ratio</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->


    <div class="clearfix"><br /></div>
    <mat-form-field class="col-md-3" *ngIf="defaultleftoption == 'Activity' && showActivityDatePicker"
      appearance="outline">
      <mat-label>Hours</mat-label>
      <input matInput placeholder="hr [0-11]" min="0" max="11" #hr_input (keydown)="checkHourLength($event, hr_input)"
        formControlName="hr_duration" type="number" pattern="^\d+(?:\.\d{0,1})?$" required>
    </mat-form-field>
    <mat-form-field class="col-md-3" *ngIf="defaultleftoption == 'Activity' && showActivityDatePicker"
      appearance="outline">
      <mat-label>Min</mat-label>
      <input matInput placeholder="min [0-59]" #min_input (keydown)="checkMinutesLength($event, min_input)" min="0"
        max="59" formControlName="min_duration" type="number" pattern="^\d+(?:\.\d{0,1})?$" required>
    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Activity' && showActivitykcal" appearance="outline">
      <mat-label>Activity Duration in Kcal</mat-label>
      <input matInput placeholder="Activity Duration (1 - 2000 Kcal)" #kcal_input
        (keydown)="checkKcalLength($event, kcal_input)" min="1" max="2000" formControlName="kcal_duration" type="number"
        required pattern="^\d+(?:\.\d{0,1})?$">
    </mat-form-field>

    <mat-form-field class="col-md-12" *ngIf="defaultleftoption == 'Blood Sugar'" appearance="outline">
      <mat-label>Choose Type</mat-label>
      <mat-select formControlName="type" placeholder="Choose Type" required>
        <mat-option value="Fasting">Fasting Blood Sugar</mat-option>
        <mat-option value="Breakfast">Post Breakfast</mat-option>
        <mat-option value="Lunch">Post Lunch</mat-option>
        <mat-option value="Dinner">Post Dinner</mat-option>
        <mat-option value="post_snacks">Post Snacks</mat-option>
        <mat-option value="pre_breakfast">Pre Breakfast</mat-option>
        <mat-option value="pre_lunch">Pre Lunch</mat-option>
        <mat-option value="pre_dinner">Pre Dinner</mat-option>
        <mat-option value="pre_snacks">Pre Snacks</mat-option>
        <mat-option value="Random">Random</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-8" *ngIf="defaultleftoption == 'Blood Sugar'" appearance="outline">
      <mat-label>Blood sugar value [10-650mg/dL]/[0.5-35mmol/L]</mat-label>
      <input matInput placeholder="Blood Sugar [10-650mg/dL]/[0.5-35mmol/L]" minlength="0" maxlength="650"
        formControlName="duration" type="number" required pattern="^\d+(?:\.\d{0,1})?$">
    </mat-form-field>
    <mat-form-field class="col-md-4" *ngIf="defaultleftoption == 'Blood Sugar'" appearance="outline">
      <mat-label>Choose unit</mat-label>
      <mat-select formControlName="bloodSugar_unit" placeholder="Choose unit" required>
        <mat-option value="mg/dL">mg/dL</mat-option>
        <mat-option value="mmol/L">mmol/L</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-12" *ngIf="defaultleftoption == 'Meal'" appearance="outline">
      <mat-label>Choose Type</mat-label>
      <mat-select formControlName="type" placeholder="Choose Type" required>
        <mat-option value="Breakfast">Breakfast</mat-option>
        <mat-option value="Lunch">Lunch</mat-option>
        <mat-option value="Snacks">Snacks</mat-option>
        <mat-option value="Dinner">Dinner</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-12 full-width-formfield" *ngIf="defaultleftoption == 'Meal'"
      appearance="outline">
      <mat-label>Search Meal</mat-label>
      <input type="text" placeholder="Search Meal" aria-label="food" matInput [formControl]="foodFilterCtrl"
        (keyup)="searchFood($event)" required>
    </mat-form-field>

    <div class="row">


      <div class="col-md-7">
        <!-- <div infiniteScroll  [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="getNextSet()" [scrollWindow]="false" class="alllist" *ngIf="sortedfooddata && defaultleftoption == 'Meal'">
      <span *ngFor="let data of sortedfooddata">
        <span class="foodname">{{data.food_name}} </span>
        <span (click)="addNewFood(data)" class="foodico">
          <i class="material-icons">
            add_circle
          </i>
        </span>
      </span>
    </div> -->
        <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextSet()"
          [scrollWindow]="false" class="alllist" *ngIf="sortedfooddata && defaultleftoption == 'Meal'">
          <div class="row eq-row" style="margin:0">
            <div class="col-md-6" *ngFor="let data of sortedfooddata">
              <div class="list-box">
                <div class="w-100 clearfix">
                  <div class="pull-left" required>
                    <span class="foodname">{{data.food_name}} </span>
                  </div>
                  <div class="pull-right">
                    <span (click)="addNewFood(data)" class="foodico">
                      <i class="material-icons">
                        add_circle
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5" *ngIf="defaultleftoption == 'Meal'">
        <div formArrayName="food" class="alllist">
          <div *ngFor="let meals of labreportForm.controls.food.controls; let j=index" [formGroupName]="j"
            class="boxthekeyvalue">
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="full-width-formfield" appearance="outline">
                <mat-label>Food Name</mat-label>
                <input matInput formControlName="food_item" class="text-capitalize" readonly required>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="full-width-formfield" appearance="outline">
                <mat-label>Select quantity</mat-label>
                <mat-select placeholder="Select quantity" formControlName="quantity" required>
                  <mat-option *ngFor="let entity of newentity" [value]="entity">
                    {{entity}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="full-width-formfield" appearance="outline">
                <mat-label>Select Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="unit" required>
                  <mat-option *ngFor="let unit of newunit" value="{{unit}}">
                    {{unit}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col--12">
              <span (click)="removeFoodItem(j)" class="remove-btn"><i class="material-icons">
                  remove_circle
                </i></span>
            </div>
            <div class="clearfix"><br /></div>
          </div>
        </div>
        <div class="clearfix"><br /></div>
      </div>

    </div>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Blood Pressure'" appearance="outline">
      <mat-label>Systolic Value (Range 30-300 mm Hg)</mat-label>
      <input matInput placeholder="Systolic Value (Range 30-300 mm Hg)" minlength="1" maxlength="400"
        formControlName="systolic_blood_pressure" type="number" pattern="^\d+(?:\.\d{0,1})?$">
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Blood Pressure'" appearance="outline">
      <mat-label>Choose unit</mat-label>
      <mat-select formControlName="systolic_blood_pressure_unit" placeholder="Choose unit">
        <mat-option value="mm Hg">mm Hg</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Blood Pressure'" appearance="outline">
      <mat-label>Diastolic Value (Range 30-300 mm Hg)</mat-label>
      <input matInput placeholder="Diastolic Value (Range 30-300 mm Hg)" minlength="1" maxlength="400"
        formControlName="diastolic_blood_pressure" type="number" pattern="^\d+(?:\.\d{0,1})?$">
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Blood Pressure'" appearance="outline">
      <mat-label>Choose unit</mat-label>
      <mat-select formControlName="diastolic_blood_pressure_unit" placeholder="Choose unit">
        <mat-option value="mm Hg">mm Hg</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Blood Pressure'" appearance="outline">
      <mat-label>Heart rate in bpm (Range 20-200 bpm)</mat-label>
      <input matInput placeholder="Heart rate in bpm (Range 20-200 bpm)" minlength="30" maxlength="350"
        formControlName="heart_rate" type="number" pattern="^\d+(?:\.\d{0,1})?$">
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Peak Flow'" appearance="outline">
      <mat-label>Enter value (50-1000 l/min)</mat-label>
      <input matInput placeholder="Enter value (50-1000 l/min)" formControlName="peak_flow_measure" type="number">
    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Peak Flow'" appearance="outline">
      <mat-label>Choose unit</mat-label>
      <mat-select formControlName="peak_flow_unit" placeholder="Choose unit">
        <mat-option value="l/min">l/min</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Peak Flow'" appearance="outline">
      <mat-label>Choose log type</mat-label>
      <mat-select formControlName="log_type" placeholder="Choose log type">
        <mat-option value="after_medication">After Medication</mat-option>
        <mat-option value="before_medication">Before Medication</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Peak Flow'" appearance="outline">
      <mat-label>Enter Longitutde of log</mat-label>
      <input matInput placeholder="Enter Longitutde of log" formControlName="longitude" type="number">
    </mat-form-field>
    <div class="clearfix"></div>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Peak Flow'" appearance="outline">
      <mat-label>Enter latitude of log</mat-label>
      <input matInput placeholder="Enter latitude of log" formControlName="latitude" type="number">
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Peak Flow'" appearance="outline">
      <mat-label>Enter location name</mat-label>
      <input matInput placeholder="Enter location name" formControlName="location_name">
      <mat-error>Enter only text</mat-error>
    </mat-form-field>


    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Water'" appearance="outline">
      <mat-label>Enter Quantity</mat-label>
      <input matInput placeholder="Enter Quantity [1-10glass]/[20-5000mL]/[0.5-20bottle]" minlength="1" maxlength="100"
        formControlName="quantity" type="number" pattern="^\d+(?:\.\d{0,1})?$" required>
    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Water'" appearance="outline">
      <mat-label>Choose unit</mat-label>
      <mat-select formControlName="water_unit" placeholder="Choose unit" required>
        <mat-option value="glass">Glass</mat-option>
        <mat-option value="ml">ML</mat-option>
        <mat-option value="bottle">Bottle(1Ltr.)</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-12" *ngIf="defaultleftoption == 'Weight'" appearance="outline">
      <mat-label>Total weight (Range 20-400 Kg/20-800 lbs)</mat-label>
      <input matInput placeholder="Total weight (Range 20-400 Kg/20-800 lbs)" minlength="19" maxlength="800"
        formControlName="duration" type="number" pattern="^\d+(?:\.\d{0,1})?$">

    </mat-form-field>
    <mat-form-field class="col-md-6" *ngIf="defaultleftoption == 'Weight'" appearance="outline">
      <mat-label>Choose unit</mat-label>
      <mat-select formControlName="weight_unit" placeholder="Choose unit" required>
        <mat-option value="kg">Kg</mat-option>
        <mat-option value="lbs">lbs</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearance="outline" class="col-md-12" *ngIf="defaultleftoption == 'Infusion Site'">
      <mat-label>Select Site</mat-label>
      <mat-select formControlName="infusion_site" required>
        <mat-option value="abdomen_left">Abdomen Left</mat-option>
        <mat-option value="abdomen_right">Abdomen Right</mat-option>
        <mat-option value="thigh_front_right">Thigh Front Right</mat-option>
        <mat-option value="thigh_front_left">Thigh Front Left</mat-option>
        <mat-option value="thigh_rear_right">Thigh Rear Right</mat-option>
        <mat-option value="thigh_rear_left">Thigh Rear Left</mat-option>
        <mat-option value="upper_arm_rear_right">Upper Arm Rear Right</mat-option>
        <mat-option value="upper_arm_rear_left">Upper Arm Rear Left</mat-option>
        <mat-option value="lower_flank_left">Lower Flank Left</mat-option>
        <mat-option value="lower_flank_right">Lower Flank Right</mat-option>
        <mat-option value="upper_bottocks_right">Upper Buttocks Right</mat-option>
        <mat-option value="upper_buttocks_left">Upper Buttocks Left</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-12" *ngIf="defaultleftoption == 'Infusion Site' && showReason">
      <mat-label>Reason for change</mat-label>
      <!---->
      <mat-select formControlName="site_change_reason" (selectionChange)="changeReason($event.value)" required>
        <!---->
        <mat-option value="scheduled">Scheduled</mat-option>
        <mat-option value="pain">Pain</mat-option>
        <mat-option value="reaction">Reaction</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-12" *ngIf="defaultleftoption == 'Infusion Site'">
      <mat-label>Reminder Days</mat-label>
      <mat-select formControlName="reminder_days" required>
        <mat-option value="15">15</mat-option>
        <mat-option value="20">20</mat-option>
        <mat-option value="25">25</mat-option>
        <mat-option value="30">30</mat-option>
        <mat-option value="35">35</mat-option>
        <mat-option value="40">40</mat-option>
        <mat-option value="45">45</mat-option>
        <mat-option value="50">50</mat-option>
        <mat-option value="55">55</mat-option>
        <mat-option value="60">60</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-12" *ngIf="defaultleftoption == 'Infusion Site' && (infusion_reason == 'reaction')"
      appearance="outline">
      <mat-label>Infusion Site Summary</mat-label>
      <textarea matInput placeholder="Enter Infusion site summary" formControlName="infusion_site_summary"></textarea>
    </mat-form-field>



    <!-- <mat-form-field class="col-md-12"
      *ngIf="defaultleftoption == 'Infusion Site'&& (infusion_reason == 'pain' || infusion_reason == 'reaction')"
      appearance="outline">
      <div class="col-md-12 hcprescription-upload-holder" appearance="outline" style="bottom: 20px;top: -2px;">
        <file-upload class="file-input" formControlName="files" simple accept=".jpeg,.png,.jpg,.pdf">
        </file-upload>
      </div>
    </mat-form-field> -->



    <!-- <input id="file-input" type="file" accept="image/*" name="myfile"   (change)="onSelectFile($event)"
    multiple="multiple" style="margin-top: 30px;" /> -->

    <!-- <form [formGroup]="demoForm"
    *ngIf="defaultleftoption == 'Infusion Site'&& (infusion_reason == 'pain' || infusion_reason == 'reaction')"
    appearance="outline">
    <div class="col-md-12 hcprescription-upload-holder" appearance="outline" style="bottom: 20px;top: -2px;">
      <input id="file-input" type="file" accept="image/*" name="myfile"   (change)="onSelectFile($event)"
      multiple="multiple"/>   
    </div>
  </form> -->


    <!-- <form [formGroup]="demoForm"
    *ngIf="defaultleftoption == 'Infusion Site'&& (infusion_reason == 'pain' || infusion_reason == 'reaction')"
    appearance="outline">
    <div class="col-md-12 hcprescription-upload-holder" appearance="outline" style="bottom: 20px;top: -2px;">
      <file-upload class="file-input" formControlName="files" simple accept=".jpeg,.png,.jpg,.pdf" required>
      </file-upload>   
    </div>
  </form> -->

    <!-- <mat-form-field appearance="outline" *ngIf="defaultleftoption == 'Infusion Site'">
      <mat-label>Choose Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="log_date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

    <div class="col-md-12" style="margin-left: 0%;margin-top: 1%;"
      *ngIf="selectedTag=='Weight' || selectedTag=='Blood Pressure'">
      <mat-radio-group aria-label="Select an option" required formControlName="tag">
        <mat-radio-button [value]="tag" class="generictitle" *ngFor="let tag of MCHITagData">{{tag |
          uppercase}}&nbsp;&nbsp;&nbsp;</mat-radio-button>
      </mat-radio-group>
    </div>


    <div class="col-md-12">
      <span *ngIf="showdateerror" class="text-danger invalidentrycss">Date should be from last 3 months</span>
      <span *ngIf="showerror" class="text-danger invalidentrycss">Invalid values entered</span>
    </div>


    <app-loader showLoader="{{true}}" *ngIf="showButtonLoader"></app-loader>
  </form>
  <div class="col-md-12" *ngIf="!infusionChange">
    <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn"
      *ngIf="!showButtonLoader && firstInfusion"
      [disabled]="!labreportForm.valid || (defaultleftoption == 'Infusion Site' &&  labreportForm.valid  &&  !demoForm.valid)"
      (click)="labreportObject()">
      Log Diary
    </button>
  </div>
  <div class="col-md-12" *ngIf="!firstInfusion">
    <spam class="text-danger invalidentrycss">Infusion Site Already Added!</spam>
  </div>
  <div class="col-md-12" *ngIf="infusionChange">
    <!--  (click)="updateInfusionSite()" commented for disabling image uoload -->
    <button mat-button type="submit" mat-flat-button color="primary" class="labreportbtn" *ngIf="!showButtonLoader"
      (click)="submitInfusionSite()">Update</button>
  </div>
</div>
<!-- Macro Nutritient -->
<div *ngIf="macroNutritient">
  <h4 style="text-align: center;">{{mealType}}</h4>
  <h6 style="text-align: center;">{{totalcalories | number : '.1-2'}} kcal</h6>
  <h5 style="text-align: center;">Macronutritient Breakdown</h5>
  <div  style="text-align: center;margin-left: 70px;">
    <div class="col-md-12" style="text-align: center;margin-right: 5px; ">
      <!-- carbohydrates -->
      <div class="col-md-2 carb" title="carbohydrates" >
        <div class="col-md">
          <img src="assets/img/carb.png" style="height: 37px;">
        </div>
        <div class="col-md" style="margin-top: 10px;">
          <span >{{carb | number : '.1-1'}}g</span>
        </div>
      
      </div>

      <!-- protein -->
      <div class="col-md-2 protein" title="Proteins">
        <div class="col-md">
          <img src="assets/img/protein.png">
        </div>
        <div class="col-md" style="margin-top: 10px;">
          <span >{{protein | number : '.1-1'}}g</span>
        </div>
      </div>

      <!-- fats -->
      <div class="col-md-2 fat" title="Fats">
        <div class="col-md">
          <img src="assets/img/fat.png">
        </div>
        <div class="col-md" style="margin-top: 10px;">
          <span >{{fat | number : '.1-1'}}g</span>
        </div>
      </div>

      <!-- fibers -->
      <div class="col-md-2 fiber" title="Fiber" >
        <div class="col-md">
          <img src="assets/img/fiber.png">
        </div>
        <div class="col-md" style="margin-top: 10px;">
          <span>{{fiber | number : '.1-1'}}g</span>
        </div>
      </div>
    </div>
    <div class="col-md-12" style="margin-top: 8px;">
      <div class="col-md-2">
        <span>Carbs</span>
      </div>
      <div class="col-md-2">
        <span>Protein</span>
      </div>
      <div class="col-md-2" style="margin-left: 20px;">
        <span>Fat</span>
      </div>
      <div class="col-md-2" style="margin-left: 10px;">
        <span>Fibre</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="text-align:center;margin-top: 10px;">
      <button mat-flat-button color="primary" class="primary margin-btn" style="width: 70%;"
        mat-dialog-close>OK</button>
    </div>
  </div>
</div>