import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import moment = require('moment');
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { DeletemodalComponent } from '../../shared/deletemodal/deletemodal.component';
import { UploadMediaDialogComponent } from '../upload-media-dialog/upload-media-dialog.component';
import { ViewmediaComponent } from '../viewmedia/viewmedia.component';

@Component({
  selector: 'app-notification-upload-media',
  templateUrl: './notification-upload-media.component.html',
  styleUrls: ['./notification-upload-media.component.scss']
})
export class NotificationUploadMediaComponent implements OnInit {

  displayedColumns: string[] = ["image", "title", "type", "updated_at","action"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  s3url: any = environment.richMedia;
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public mediastatus: any = "all";
  public mediatype: any = "all";
  public startdate: any = "";
  public enddate: any = "";
  public viewMedia: any;
  public morrow: any = new Date();
  public filterapplied: boolean = false;
  enddateval: any;
  public dataForm = this.fb.group({
    mediatype: ['', Validators.required],
    statustype: ['', Validators.required],
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });

  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() max: null;

  constructor(private browserTitle: Title, public fb: FormBuilder, private systemService: SystemService, public dialog: MatDialog, private contentService: ContentserviceService, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Media Manager');
  }

  ngOnInit() {
    this.setSEOTags();
    this.morrow.setDate(this.morrow.getDate());
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  delete(id) {
    this.contentService.RichMediaHardDelete('media', id).pipe(catchError((error: any) => {
      this.showloader = false;
      this.systemService.showSnackBar(error.error && error.error_message ? error.error.error_message : 'Sorry something went wrong.', 'OK');
      return throwError(error);
    }),
      finalize(() => { this.showloader = false; }))
      .subscribe(data => {
        for (let i = 0; i < this.tabulardata.length; i++) {
          if (this.tabulardata[i].id === id) {
            this.tabulardata.splice(i, 1);
            break;
          }
        }
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      });
  }

  // openDialog(id) {
  //   const dialogRef = this.dialog.open(DeletemodalComponent);
  //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //     if (confirmed) {
  //       this.delete(id);
  //       const a = document.createElement('a');
  //       a.click();
  //       a.remove();
  //       this.snackBar.open('Selected media has been deleted', 'Ok', {
  //         duration: 2000,
  //       });
  //     }
  //   });
  // }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  applyFilterType(val) {
    var filtereddata = null;
    if (val != 'all') {
      filtereddata = this.tabulardata.filter(t => t.type == val);
    }
    else {
      filtereddata = this.tabulardata;
    }
    this.dataSource = new MatTableDataSource<Element>(filtereddata);
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.enddateval = event.target.value;
  }

  // ----------------------------

  // List Magazine 
  // ----------------------------
  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getAllRichMedia(this.searchcriteria, this.page.toString(), "20", this.mediastatus.toString(), this.mediatype.toString(), this.startdate.toString(), this.enddate.toString()   )
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }


  clearfilter() {
    this.dataForm.controls.mediatype.reset();
    this.dataForm.controls.statustype.reset();
    this.dataForm.controls.startdate.reset();
    this.dataForm.controls.enddate.reset();
    this.mediastatus = 'all';
    this.mediatype = 'all';
    this.startdate = '';
    this.enddate = '';
    this.searchcriteria = "";
    this.page = 1;
    this.lastSearch = null;
    this.lastpage = null;
    this.tabulardata = [];
    this.endoflist = false;
    this.search();
    this.filterapplied = false;
  }

  cancelfilter() {
    this.trigger.closeMenu();
  }

  // isActive(status, id) {
  //   this.contentService.setRichMediaStatus(status, 'media', id).pipe(catchError((error: any) => {
  //     this.showloader = false;
  //     this.systemService.showSnackBar(error.error && error.error.error_message ? error.error.error_message : 'Sorry something went wrong.', 'OK');
  //     return throwError(error);
  //   }),
  //     finalize(() => { this.showloader = false; }))
  //     .subscribe(data => {
  //       this.tabulardata.forEach((item) => {
  //         if (item.id === id) {
  //           item.is_active = !status;
  //         }
  //       });
  //       this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  //     });
  // }

  openMedia(element) {
    this.viewMedia = this.dialog.open(ViewmediaComponent, {
      width: "760px",
      height: "512px",
      panelClass: ["custom-modalbox","content-media-panel"],
      data: {
        element: element,
        mediaType: "1"
      }
    });

    this.viewMedia.afterClosed().subscribe((dte) => {
      if (dte) {
        for (let i = 0; i < this.tabulardata.length; i++) {
          if (this.tabulardata[i].id == dte.id) {
            this.tabulardata[i].thumbnail_path = dte.path;
            break;
          }
        }
      }
    })
  }

  applymainfilter() {
    var postdata = this.dataForm.value;
    postdata.mediatype = postdata.mediatype.toString();
    postdata.startdate = moment(postdata.startdate).format('YYYY-MM-DD');
    postdata.enddate = moment(postdata.enddate).format('YYYY-MM-DD');
    postdata.statustype = postdata.statustype.toString();
    this.mediastatus = postdata.statustype;
    this.mediatype = postdata.mediatype;
    this.startdate = postdata.startdate;
    this.enddate = postdata.enddate;
    this.searchcriteria = "";
    this.page = 1;
    this.lastSearch = null;
    this.lastpage = null;
    this.tabulardata = [];
    this.search();
    this.filterapplied = true;
  }

  ngOnDestroy(): void {
  }

  openUploadMedia(){
    const dialogInstance = this.dialog.open(UploadMediaDialogComponent, {
      width: "1028px", 
      height: "490px",
      panelClass: "content-media-panel",
      data: {
        mediaType: "1"
      }
    });
  }

  changeActivation(status, id){

    let question = `Are you sure you want to ${ status ? "de-activate" : "activate" } media?`;

    const dialogInstance = this.dialog.open(ConfirmationModalComponent,{
      data: {
        question: question
      }
    });

    dialogInstance.afterClosed().subscribe((data) => {

      if (data == "confirm"){
        this.contentService.setRichMediaStatus(status, 'media', id).pipe(
          catchError((error: any) => {
            this.showloader = false;
            this.systemService.showSnackBar(error.error && error.error.error_message ? error.error.error_message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
        }),
          finalize(() => { this.showloader = false; }))
          .subscribe(data => {
            this.tabulardata.forEach((item) => {
              if (item.id === id) {
                item.is_active = !status;
              }
            });
            this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
          });
      }
    })

  }

  deleteMedia(id){

    let question = `Are you sure you want to delete media?`;

    const dialogInstance = this.dialog.open(ConfirmationModalComponent,{
      data: {
        question: question
      }
    });

    dialogInstance.afterClosed().subscribe((data) => {
      if (data == "confirm"){
        this.delete(id);
      }
    });

  }


}

export interface PeriodicElement {
  name: string;
  isactive: boolean;
  image: string;
  type: string;
}
