import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthcoachGuardGuard } from './../../shared/guards';
import { BroadcastComponent as HealthCoachBroadcastComponent } from './broadcast.component';
import { CreatebroadcastComponent } from './createbroadcast/createbroadcast.component';

const routes: Routes = [
  { path: '', component: HealthCoachBroadcastComponent, canActivate: [HealthcoachGuardGuard] },
  { path: 'createbroadcast', component: CreatebroadcastComponent, canActivate: [HealthcoachGuardGuard] },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BroadcastRoutingModule { }
