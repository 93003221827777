import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-activatebottomsheet',
  templateUrl: './activatebottomsheet.component.html',
  styleUrls: ['./activatebottomsheet.component.scss']
})
export class ActivatebottomsheetComponent implements OnInit {
  public allCampaigns: any;
  public alllanguages: any;
  public dataForm = this.fb.group({
    phone: ["", Validators.required],
    country_code: ["", Validators.required],
    condition: ["", Validators.required],
    language_data_fk: ["", Validators.required]
  });

  constructor(private contentService: ContentserviceService, private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any,
    private systemService: SystemService, private dialogRef: MatDialogRef<ActivatebottomsheetComponent>, public fb: FormBuilder, private commonService: CommonserviceService) { }

  openLink(event: MouseEvent): void {
    this.dialogRef.close();
    event.preventDefault();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Doctor');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getCampaignData();
    this.getAllLanguages();
  }


  //  To Get Campaign Data 
  // ----------------------------

  getCampaignData() {
    this.commonService.getAllCampaign(this.data.id)
      .subscribe(data => {
        this.allCampaigns = (data as any).data;
      })
  }

  // ----------------------------


  //  Submitting User Data 
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    this.commonService.preActiveUser(postdata, this.data.code)
      .subscribe(
        (res: any) => this.onSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private onSuccess(data) {
    if ((data as any).status == 200) {
      this.systemService.showSnackBar("Patient has been activated Successfully", "OK");
      this.dialogRef.close();
    }
    else {
      this.systemService.showSnackBar((data as any).message, "OK");
      this.dialogRef.close();
    }
  }

  // ----------------------------


  getAllLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
