import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs/index';
import { catchError, delay, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { UserlistService } from '../../../../services/userlist/userlist.service';

@Component({
  selector: 'app-logtagdialog',
  templateUrl: './logtagdialog.component.html',
  styleUrls: ['./logtagdialog.component.scss']
})
export class LogtagdialogComponent implements OnInit {

  public alltags: any;
  public tagForm: any;
  public showButtonLoader: boolean = false;
  public rawdata: any;
  public userID: any;
  public usertags: any;
  public isloading: boolean = false;
  filtertags: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private UserlistService: UserlistService, private formBuilder: FormBuilder, private systemService: SystemService) { }

  ngOnInit() {
    this.alltags = this.data.alltags;
    this.userID = this.data.userID;
    this.usertags = this.data.userTags;
    this.filtertag(this.alltags, this.usertags)
    this.buildtagForm();
  }
  buildtagForm(): void {
    this.tagForm = this.formBuilder.group({
      tag_id: ["", Validators.required]
    });
  }
  filtertag(alltag, selected_tag) {
    var props = ['id', 'title'];
    this.filtertags = alltag.filter(function (o1) {
      // filter out (!) items in result2
      return !selected_tag.some(function (o2) {
        return o1.id === o2.tag_id;          // assumes unique id
      });
    }).map(function (o) {
      // use reduce to make objects with only the required properties
      // and map to apply this to the filtered array as a whole
      return props.reduce(function (newo, name) {
        newo[name] = o[name];
        return newo;
      }, {});
    });

    
  }
  checkDuplicate(id) {
    if (this.usertags.filter(p => p == id).length > 0) {
      return true
    }
    else {
      return false
    }
  }
  saveTag() {
    var scope = this;
    this.isloading = true;
    this.UserlistService.saveUserTag(this.userID, this.tagForm.value.tag_id).pipe(
      delay(100),
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.isloading = false;
        this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
        return throwError(error);
      }),
      finalize(() => { this.showButtonLoader = false; })
    ).subscribe(
      (data) => {
        if (data.status == 200) {
          this.isloading = false;
          //this.systemService.showSnackBar("Tag has been assigned.", "OK");
          this.rawdata = data;
          this.showButtonLoader = false;
          setTimeout(
            function () {
              scope.dialog.closeAll();
            },
            200
          );
        } else {
          this.systemService.showSnackBar(data.message, "OK");
        }
      })
  }
}
