import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from 'src/app/shared/guards/admin-guard.guard';
import { RequestdetailsComponent } from './requestdetails/requestdetails.component';
import { TaskrequestComponent } from './taskrequest.component';


const routes: Routes = [
  { path: '', component: TaskrequestComponent, canActivate: [AdminGuardGuard] },
  { path: 'requestdetails/:id/:hc_id', component: RequestdetailsComponent, canActivate: [AdminGuardGuard] },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskrequestRoutingModule { }
