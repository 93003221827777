import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from '../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-viewsymptommap',
  templateUrl: './viewsymptommap.component.html',
  styleUrls: ['./viewsymptommap.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class ViewsymptommapComponent implements OnInit {
  activecls = 'active';
  public showloader: any;
  public alltags: any;
  displayedColumns: string[] = ["condition_id", "condition_title", "symptom_name","action"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
  public newsection: any = 'new';
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public primarylanguage: any;
  public alllanguages: any;
  public tags: any;
  public allwidgets: any;
  public showButtonLoader: boolean = false;
  public alllogs: any;
  public newentry: boolean = false;
  public status: any = 'active';

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService,private dialog: MatDialog, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Symptom Mapping');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }
  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------

  // Active Deactive Symptoms
  // ----------------------------

  // isActive(status, id) {
  //   this.contentService.setStatus(status, 'symptomstatus', id)
  //     .subscribe(
  //       (res: any) => this.onStatusSuccess(status, id),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  // }

  // private onStatusSuccess(status, id) {
  //   this.tabulardata.forEach((item) => {
  //     if (item.id === id) {
  //       item.is_active = !status;
  //     }
  //   });
  //   this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  // }

  isActive(status, id) {
    let value;
    if(status){
      value = "Deactivate"
    }else{
      value = "Activate"
    }
    let header = "Are you sure you want to "+""+ value +" "+ "Symptom?";
    localStorage.setItem('modal_header',header);
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        localStorage.removeItem("modal_header");
        this.contentService.setStatus(status, 'symptomstatus', id)
        .subscribe(
          (res: any) => this.onChangeStatusSuccess(status, id,value),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Symptom has been '+ value+'d', 'Ok', {
          duration: 2000,
        });
      }
    });
    
  }

  private onChangeStatusSuccess(status, id,value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

 
  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  // List Symptoms 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getAllData(this.searchcriteria, this.page.toString(), "20", this.status, "symptommapping")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.contentService.change_symptomData(this.tabulardata);
      this.dataSource.sort = this.sort;


    }
  }

  // ----------------------------

}

export interface PeriodicElement {
  client_id: string;
  client_name: string;
  widget: string;
}