import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { AlertsService } from '../services/alerts/alerts.service';
import { ContextService } from '../services/context.service';
import { SocketService } from '../services/socket/socket.service';

@Component({
  selector: 'app-hcalerts',
  templateUrl: './hcalerts.component.html',
  styleUrls: ['./hcalerts.component.scss']
})

export class HcalertsComponent implements OnInit {
  rawdata: any;
  alertData: {};
  userassignment: number = 0;
  othernotify: any;
  todaydate: any;
  markreadrawdata: any;
  filternotificationval = 'unread';
  notificationdataarray = [];
  showDate = true;
  globalDate = null;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  paginatedAlerts = new EventEmitter();
  isLoading = false;
  isUpdating = false;
  data: any;
  dataloaded = false;
  getthenewAlert: any;
  showmarkallread = false;
  public timeZone: any;

  public isactive = 'all';
  public showloader: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  searchcriteria: any;
  public page: number = 1;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  dataSource: any;
  public alltags: any;
  private myhash: any = {};
  public filteredtabulardata: any;
  public tagFilters: any;
  public filteredtags: any = [];
  public status: any = 'active';
  public typeFilters: any;
  public filtercategory: any = null;
  public filterval: any = null;
  public notiftype: any = 'all';
  noticationDetect: any;
  public notificationtypes = [
    ['reminder_added_event,reminder_updated_event', 'Reminder'],
    ['medicine_added_event,medicine_updated_event', 'Medicine'],
    ['weight_event', 'Weight'],
    ['low_bp_event,high_bp_event', 'Blood Pressure'],
    ['low_blood_sugar_event,high_blood_sugar_event', 'Blood Sugar'],
    ['onboarding_event', 'Onboarded'],
    ['prescription_upload,prescription_update', 'Prescription'],
    ['caregiver_added,caregiver_updated', 'Care Giver'],
    ['lab_report_event', 'Lab Report'],
    ['doctor_assignment_event,doctor_reassignment_event', 'Doctor Assigned'],
    ['patient_transfer_event', 'Patient Transferred'],
    ['adverse_reaction_event', 'Adverse Reaction|Remodulin'],
    ['medication_added_event', 'Medication Added'],
    ['medication_updated_event', 'Medication Updated'],
    ['pump_added_event', 'Pump Added'],
    ['pump_updated_event', 'Pump Updated'],
    ['medication_survey_added', 'Medication Survey'],
    ['meal_event', 'Meal'],
    ['infusion_site_event', 'Infusion Site']
  ]

  public delayedLoaderSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private AlertsService: AlertsService, private AuthService: AuthenticateService,
    private browserTitle: Title,
    private appContext: ContextService, private router: Router, private SystemService: SystemService, private socketService: SocketService) {
    this.todaydate = new Date();
    this.todaydate = this.todaydate.getDate() + " " + SHORT_MONTHS[this.todaydate.getMonth()] + " " + this.todaydate.getFullYear();
    localStorage.removeItem("notifyPatientName");
    //localStorage.removeItem("selectedNotification");
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Notifications');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
    this.socketService.systemHaveAlerts.next(false);
    this.socketService.newMessages.subscribe(data => {
      if (data.type == "notification") {
        this.socketService.systemHaveAlerts.next(true);
        if (this.tabulardata.filter(a => a.patient_id_data_fk == data.data.patient_id_data_fk).length > 0) {
          var from = (this.tabulardata as any).findIndex(function (item, i) {
            return (data.data.patient_id_data_fk == item.patient_id_data_fk)
          });
          (this.tabulardata as any).splice(from, 1);
        }
        this.tabulardata.unshift(data.data);
      }
    })
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(e, typer) {
    this.filterval = e;
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
    this.notiftype = typer;
  }

  typeFilter(e) {
    var pru = JSON.stringify(e).replace('["', '').replace('"]', '').replace(/\"/g, '');
    this.filtercategory = (e != '' ? pru : null);
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
  }

  getNextPage() {
    this.page++;
    this.search();
  }

  @Output() newAlert(alert) {
    if (this.filternotificationval == "all" || this.filternotificationval == "unread") {
      alert["formatted_time"] = "just now";
      alert["onlydate"] = "New";
      this.notificationdataarray.unshift(alert);
    }

    if (alert.type == "user_assignment") {
      this.userassignment++;
    }

    this.data.unread++;
    this.data.total++;
    this.paginatedAlerts.emit(this.data);
  }

  setstatus(type, value) {
    if (type == 'reminder_added_event' || type == 'reminder_updated_event')//reminder
    {
      if (value != null) {
        return JSON.parse(value).category ? JSON.parse(value).category : '' + " Reminder"
      }
      else {
        return "New Reminder"
      }
    }
    else if (type == 'medicine_added_event')//reminder
    {
      return "Medicine Added"
    }
    else if (type == 'medicine_updated_event')//reminder
    {
      return "Medicine Updated"
    }
    else if (type == 'low_bp_event')//diary
    {
      return "Low Blood Pressure"
    }
    else if (type == 'high_bp_event') //diary
    {
      return "High Blood Pressure"
    }
    else if (type == 'low_blood_sugar_event')//diary
    {
      return "Low Blood Sugar"
    }
    else if (type == 'high_blood_sugar_event') //diary
    {
      return "High Blood Sugar"
    }
    else if (type == 'blood_sugar_event')//diary
    {
      return "Blood Sugar Logged"
    }
    else if (type == 'onboarding_event') //No redirection provided for onboarding_event 
    {
      return "On Boarded"
    }
    else if (type == 'prescription_upload') //prescription
    {
      return "Prescription Added"
    }
    else if (type == 'prescription_update') //prescription
    {
      return "Prescription Updated"
    }
    else if (type == 'caregiver_added') //caregiver
    {
      return "Caregiver Added"
    }
    else if (type == 'caregiver_updated')//caregiver
    {
      return "Caregiver Updated"
    }
    else if (type == 'lab_report_event') //reports tab
    {
      return "Lab Report Added"
    }
    else if (type == 'doctor_assignment_event') //doctor
    {
      return "Doctor Assigned"
    }
    else if (type == 'doctor_reassignment_event') //doctor
    {
      return "Doctor Reassigned"
    }
    else if (type == 'patient_transfer_event') //No redirection provided for patient_transfer_event 
    {
      return "Transferred to you"
    }
    else if (type == 'patient_note_event')//about tab
    {
      return "Patient Note"
    }
    else if (type == 'trigger_event')//trigger
    {
      return "Trigger Values Added"
    }
    else if (type == 'questionnaire_event')//questionnaire
    {
      return "Questionnaire Logged"
    }
    else if (type == 'symptom_event') //symptom
    {
      return "Symptom Added"
    }
    else if (type == 'daily_goals') //No redirection provided for daily_goals
    {
      return "Daily Goals Added"
    }
    else if (type == 'peak_flow')//diary tab
    {
      return "Peak Flow Added"
    }
    else if (type == 'activity_event') //diary tab 
    {
      return "Activity Event"
    }
    else if (type == 'weight_event') //diary tab 
    {
      return "Weight Added"
    }
    else if (type == 'water_alert_event')//diary tab 
    {
      return "Water Added"
    }
    else if (type == 'adverse_reaction_event') //AE(other)
    {
      return "Adverse Reaction | Remodulin"
    }
    else if (type == 'medication_added_event') //medication
    {
      return "Medication Added"
    }
    else if (type == 'medication_updated_event') //medication
    {
      return "Medication Updated"
    }
    else if (type == 'pump_added_event') //pump
    {
      return "Pump Added"
    }
    else if (type == 'pump_updated_event')//pump
    {
      return "Pump Updated"
    }
    else if (type == 'medication_survey_added') //medication survey
    {
      return "Medication Survey"
    }
    else if (type == 'meal_event') //medication survey
    {
      return "Meal"
    }
    else if (type == 'infusion_site_event') //medication survey
    {
      return "Infusion Site";
    } else if (type === 'patient_phone_update_approve_event') {
      return "Number Update Request Accepted";
    } else if (type === 'patient_phone_update_reject_event') {
      return "Number Update Request Rejected";
    } else if (type === 'diet_plan_created_event') {
      return "Diet plan generated";
    }
    else {
      return type
    }
  }

  notificationVerify(selectedNotification, patientName, selectedCondition, createdDateTime, crreatedID) {

    if (selectedNotification == 'medication_added_event' || selectedNotification == 'medication_updated_event' ||
      selectedNotification == 'medication_survey_added' || selectedNotification == 'prescription_upload' ||
      selectedNotification == 'prescription_update' || selectedNotification == 'reminder_added_event' ||
      selectedNotification == 'reminder_updated_event' || selectedNotification == 'medicine_added_event' ||
      selectedNotification == 'medicine_updated_event' || selectedNotification == 'low_bp_event' ||
      selectedNotification == 'high_bp_event' || selectedNotification == 'low_blood_sugar_event' ||
      selectedNotification == 'meal_event' || selectedNotification == 'infusion_site_event' ||
      selectedNotification == 'high_blood_sugar_event' || selectedNotification == 'blood_sugar_event' ||
      selectedNotification == 'caregiver_added' || selectedNotification == 'caregiver_updated' ||
      selectedNotification == 'lab_report_event' || selectedNotification == 'doctor_assignment_event' ||
      selectedNotification == 'doctor_reassignment_event' || selectedNotification == 'patient_note_event' ||
      selectedNotification == 'trigger_event' || selectedNotification == 'questionnaire_event' ||
      selectedNotification == 'symptom_event' || selectedNotification == 'peak_flow' ||
      selectedNotification == 'activity_event' || selectedNotification == 'weight_event' ||
      selectedNotification == 'water_alert_event' || selectedNotification == 'adverse_reaction_event' ||
      selectedNotification == 'pump_added_event' || selectedNotification == 'pump_updated_event' || 'diet_plan_created_event') {
      this.router.navigateByUrl('/healthcoach/home');
      localStorage.setItem("selectedNotification", selectedNotification);
      localStorage.setItem("notifyPatientName", patientName);
      localStorage.setItem("selectedCondition", selectedCondition);
      localStorage.setItem('createdDateTime', createdDateTime);
      let logDate = JSON.parse(crreatedID);
      localStorage.setItem('createdId', logDate.id);
    }
  }


  starfunc(ide, index, starval) {
    var status = starval ? 'unstar' : 'star';
    this.AlertsService.starunstar(ide, status)
      .subscribe(data => {
        this.tabulardata[index].patient_starred = !starval;
      })
  }

  markallread(ide) {
    this.AlertsService.markallread(ide)
      .subscribe(data => {
      })
  }

  openChatOption(userID) {

    this.AlertsService.getUserList(userID)
      .subscribe(data => {

        if (data && data.data && data.data.metadata[0]) {
          let notifyuserid = data.data.metadata[0];
          this.socketService.systemHaveAlerts.next(false);
          this.appContext.showSelectedNotificationUser.next(notifyuserid);

          sessionStorage.setItem('onSelectNotifyUser', JSON.stringify(notifyuserid));
          /**Navigate to page now */
          this.router.navigate(['/healthcoach/home']);
        }
      });
  }

  convertData(d) {
    return d.replace(/_/g, ' ');
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }


  // Health Coach Notification List
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.AlertsService.getAllNotifications(this.searchcriteria, this.page.toString(), "20", this.status, this.filtercategory, this.filterval)
        .subscribe(
          (res: any) => this.getNotificationListSuccess((res as any).data),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        )
    }
  }

  private getNotificationListSuccess(data) {

    this.delayedLoaderSubject$ = new BehaviorSubject<any>(null);
    this.delayedLoaderSubject$.next(["Alerts"]);
    this.isLoading = false;
    this.isUpdating = false;
    this.dataloaded = true;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
      }
      else {
        this.endoflist = true;
      }
    }
    else {
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
    }

  }

  // ----------------------------

  getDatePart(str) {
    return str.split('on')[1];
  }
  covertTime(date) {
    // var newDate = new Date(date);
    // var convertedDate = newDate.toLocaleString('en-US', { timeZone: this.timeZone })
    // return convertedDate
    var newDate = new Date(date);
    newDate.toLocaleString();
    // 
    return newDate;
  }

}
