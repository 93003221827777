import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { DocpatientgeneratedcodeComponent } from './docpatientgeneratedcode.component';
//import { DeeplinkbottomsheetComponent } from './deeplinkbottomsheet/deeplinkbottomsheet.component';
//import { GeneralqrbottomsheetComponent } from './generalqrbottomsheet/generalqrbottomsheet.component';
//import { GenericDeeplinkComponent } from './genericdeeplink/genericdeeplink.component';
//import { QrbottomsheetComponent } from './qrbottomsheet/qrbottomsheet.component';
//import { MealbreakupComponent as DocMealBreakupComponent } from './../components/mealbreakup/mealbreakup.component';

import { AlertsService } from './../services/alerts/alerts.service';
import { DoctorDataService } from './../services/doctor/doctor.service';
import { PatientDataService } from './../services/patient/patient.service';
import { UserListService } from './../services/userlist/user-list.service';
import { DocpatientgeneratedcodeRoutingModule } from './docpatientgeneratedcode.routing.module';
import { DocuserlistnavbarModule } from '../components/docuserlistnavbar/docuserlistnavbar.module';
import { DocleftnavModule } from '../shared/docleftnav/docleftnav.module';

@NgModule({
  declarations: [
    //DocMealBreakupComponent,
    DocpatientgeneratedcodeComponent,
    //ActivatebottomsheetComponent,
    //DeeplinkbottomsheetComponent,
    //QrbottomsheetComponent,
   // GeneralqrbottomsheetComponent,
   // GenericDeeplinkComponent,
  ],
  imports: [
    CommonModule,
    DocpatientgeneratedcodeRoutingModule,
    DocuserlistnavbarModule,
    DocleftnavModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService
  ],
  /*entryComponents: [
    DocMealBreakupComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
  ]*/
})
export class DocpatientgeneratedcodeModule { }
