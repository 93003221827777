<mat-drawer-container class="sidenav-container" autosize>

    <app-docleftnav></app-docleftnav>

    <div class="sidenav-content">
        <div class="row topalertbar leftformsection">
            <div class="col-md-12 toptitlesec">
                <img src="assets/img/wellthylogo.png" style="width: 50px;margin-bottom: 10px;"/>
                <h3 class="alerttitle">Dr. Akash Kumar</h3>
                <div class="show-notification-count">Choose wellthy care intervention program from below to view QR</div>
            </div>
            <div class="clearfix">
            </div>
            <div class="margin-spaces"></div>
            <div class="col-md-12 formitemcss">
                <div class="row">
                    <div class="cardseccss col-md-12">
                            <div class="row">
                                <div class="col-md-4 col-xs-2">
                                    <img src="assets/img/therapy/thyroid-function.svg" style="margin-top: 16px;"/>
                                </div>
                                <div class="col-md-8 col-xs-10">
                                    <h4>Lung Condition</h4>
                                    <p>In association with cipla, we bring you 360 Degree Care.</p>
                                </div>
                            </div>
                    </div>
                    <div class="cardseccss col-md-12">
                        <div class="row">
                            <div class="col-md-4 col-xs-2">
                                <img src="assets/img/therapy/activity.svg" style="margin-top: 16px;"/>
                            </div>
                            <div class="col-md-8 col-xs-10">
                                <h4>Diabetes</h4>
                                <p>In association with cipla, we bring you 360 Degree Care.</p>
                            </div>
                        </div>
                </div>
                </div>
            </div>
            <ng-template #pageLoadingLoader>
                <app-loader showLoader="true"></app-loader>
            </ng-template>
        </div>
    </div>
</mat-drawer-container>