import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PersistenceService } from 'angular-persistence';
import { UserIdleModule } from 'angular-user-idle';
// import { AuthServiceConfig, GoogleLoginProvider, SocialLoginModule } from "angularx-social-login";
import { ImageCropperModule } from 'ngx-image-cropper';
import { environment } from '../environments/environment';
import { ExcelService } from './admin/services/excelservice/excelservice.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SocketService } from './healthcoach/services/socket/socket.service';
import { LoginComponent } from './login/login.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { RelesenoteComponent } from './relesenote/relesenote.component';
import { AuthInterceptor } from './shared/interceptors';
import { AuthenticateService, AwsuploadService, EnsureAuthenticatedService, LoginRedirectService } from './shared/services';
import { SharedModule } from './shared/shared.module';
import { GlobalsearchComponent } from './utility/globalsearch/globalsearch.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HistoryhardsetotpComponent } from './admin/hardsetotp/historyhardsetotp/historyhardsetotp.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
//import { HighchartsChartModule } from 'highcharts-angular';

import { CommonModule } from '@angular/common';
import { SetdoctorpasswordComponent } from './set-doctor-password/setdoctorpassword/setdoctorpassword.component'; 




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigatorComponent,
    GlobalsearchComponent,
    RelesenoteComponent,
    ForgotPasswordComponent,
    HistoryhardsetotpComponent,
    SetdoctorpasswordComponent,
    // ViewdetailsComponent,
  ],
  exports: [
    AppRoutingModule, 
    //HighchartsChartModule
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    // SocialLoginModule,
    ImageCropperModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
  //  HighchartsChartModule,
    ////MatSelectFilterModule,
    UserIdleModule.forRoot({ idle: 300, timeout: 10, ping: 60 }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: AuthServiceConfig, useFactory: provideConfig },
    AwsuploadService,
    AuthenticateService,
    LoginRedirectService,
    EnsureAuthenticatedService,
    PersistenceService,
    SocketService,
    ExcelService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    GlobalsearchComponent
  ]
})
export class AppModule { }

// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.googleClientID)
//   },
// ]);

// export function provideConfig() {
//   return config;
// }
