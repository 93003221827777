import { Component, ElementRef, OnInit, NgZone } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { AuthService, GoogleLoginProvider, SocialUser } from "angularx-social-login";
import { environment } from '../../environments/environment';
import { ContextService } from '../healthcoach/services/context.service';
import { AuthenticateService, SystemService } from '../shared/services';
import { SocketService } from './../healthcoach/services/socket/socket.service';

declare const window: any;
declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  private environmentdata: any = environment;
  // private user: SocialUser;
  private loggedIn: boolean;

  loginForm = this.fb.group({
    email: ["", Validators.required],
    password: ["", Validators.required]
  });

  displayVersion:any;

  hide = true;

  visibility_off = 'assets/img/dls/icons/actiontable/Icon_View.svg';
  visibility = 'assets/img/dls/icons/actiontable/Icon_View.svg'
  
  captcha = {
    enableCaptcha: false,
    invisiblecaptchaKey: "",
  }
  
  rawdata: any;
  checkComb: any;
  errorModel = true;
  errormessage: any;
  showloginform: boolean = false;
  settimer: any = null;
  refresh: string;

  constructor(
    public router:Router,
    public socketService: SocketService, 
    public fb: FormBuilder, 
    private myElement: ElementRef, 
    private AuthenticateService: AuthenticateService,
    // private authService: AuthService, 
    public SystemService: SystemService, 
    public appContext: ContextService,
    public zone: NgZone
  ) {
      

      // used for recaptcha
      let that = this;

      window["captchaResolve"] = function($event){
        // 
        that.zone.run(() => {
          that.captchaResolved($event);
        });
      }

      window["captchaError"] = function(){
        that.zone.run(() => {
          that.captchaError();
        });
      }
  }

  ngOnInit() {    
    this.displayVersion=this.environmentdata.publishVersion;

    let v = document.createElement("script");
    v.type = "text/javascript";
    v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: '',includedLanguages: 'en,es', autoDisplay: false }, 'google_translate_element'); } ";
    this.myElement.nativeElement.appendChild(v);

    let  s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    this.myElement.nativeElement.appendChild(s);

    this.captcha.enableCaptcha = this.environmentdata.captcha;
    // this.captcha.invisiblecaptchaKey = this.environmentdata.invisibleCaptcha;
    this.appContext.showGlobalShimmer.next(false);

    // if(this.environmentdata.org){
    // this.AuthenticateService.generateCSRF().subscribe(data=>
    // }
    
  }

  captchaResolved(captchaResponse: string) {
    if (captchaResponse == null) {
      this.SystemService.showSnackBar("Error in reCaptcha Verification: Null Error ", "OK");
    } else {
      this.AuthenticateService.captchaResponse = captchaResponse;
      this.login();
    }
  }

  captchaError(){
    this.SystemService.showSnackBar("Error in reCaptcha Verification", "OK"); 
  }

  forgotPassword(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/forgotPassword']));
  }

  login() {    
    localStorage.removeItem('timerGetData');
    this.settimer = null;
    this.AuthenticateService.auth(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        (data) => {
          this.signInDataProcessor(data);
        }, error => {
          this.handleErrorResponse(error);
        });
  }

  signInEmail() {
    this.showloginform = !this.showloginform;
  }

  /* Going to call after view load */
  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      
      google.accounts.id.initialize({
        client_id: environment.googleClientID,
        callback: (response) => {
          this.zone.run(() => {
            this.handleGoogleSignIn(response);
          })
        }
      });
      google.accounts.id.renderButton(document.getElementById("googleBtn"), {
        theme: 'outline',
        size: 'large',
      });
      // google.accounts.id.renderButton(
      //   document.getElementById("googleBtn"),
      //   { size: "large", type: "standard", shape: "pill", theme: "outline", text: "signin_with", logo_alignment: "left", width: "400" }  // customization attributes
      // );

    });
  }

  setTimer(countDownDate) {
    // Update the count down every 1 second
    let scope = this;
    let x = setInterval(function () {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      scope.settimer = hours + "h "
        + minutes + "m " + seconds + "s ";

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        scope.settimer = null;
      }
    }, 1000);
  }

  handleErrorResponse(err) {
    switch (err.error.status) {
      case 400:
        this.SystemService.showSnackBar(err.error.message, 'OK');
        this.loginForm.reset();
        this.setTimer(err.error.disable_till);
        break;
      default:
        this.SystemService.showSnackBar(err.error.message, 'OK');
        this.loginForm.reset();
        break;
    }

    window.grecaptcha.reset();
  }

  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
  //     this.AuthenticateService.v3authSocial(this.user.idToken).subscribe(data => {
  //       this.signInDataProcessor(data);
  //     }, error => {
  //       this.SystemService.handleErrorResponse(error);
  //     });
  //   }, (error) => {
  //     this.SystemService.showSnackBar("Error in google auth login : " + error, "OK");
  //   }).catch((catchError) => {
  //     this.SystemService.showSnackBar("Catchy Error in google auth login : " + catchError, "OK");
  //   });
  // }

  signInDataProcessor(userData) {
    this.rawdata = userData;
    if (this.rawdata.status == 200) {
      
      if (this.environmentdata.org == true) {
        this.AuthenticateService.configureApp(this.rawdata.data[0])
        localStorage.setItem('scarletOrganisation', JSON.stringify(this.rawdata.data));
        this.AuthenticateService.initializeData(this.rawdata.data[0]);
      } else {
        this.AuthenticateService.configureApp(this.rawdata.data)
        this.AuthenticateService.initializeData(this.rawdata.data);
      }
    } else {
      if (this.rawdata.status == 500) {
        this.SystemService.showSnackBar("Either username or password is wrong", "OK");
      } else {
        this.SystemService.showSnackBar("Unexpected Error Found", "OK");
      }
    }
  }

  switchBeta(): void {
    window.location.href = 'https://console.wellthy.app/#/login'
  }

  signOut(): void {
    // this.authService.signOut();
  }

  handleGoogleSignIn(response: any) {

    this.AuthenticateService.v3authSocial(response.credential).subscribe(data => {
      this.signInDataProcessor(data);
    }, error => {
      this.SystemService.handleErrorResponse(error);
    });

  }

  ngOnDestroy(){
    localStorage.removeItem('LoginRefresh');
  }

}


