import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { LessonComponent } from './lesson.component';
import { EditlessonComponent } from './editlesson/editlesson.component';
const routes: Routes = [
  { path: '', component: LessonComponent, canActivate: [ContentGuardGuard] },
  { path: 'lesson/edit/:id/:langid/:chid', component: EditlessonComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LessonRoutingModule { }
