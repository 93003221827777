<!-- <div mat-dialog-content>
    <form [formGroup]="deleteForm" validate>
	<p class="deletetext">
		{{message}}
    </p>
    
    </form>
</div>
<div mat-dialog-actions class="mat-btn-holder">
	<button mat-flat-button class="deletebutton" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button mat-stroked-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</div> -->
<div class="pull-right">
  <div class="close-icon">
          <i class="material-icons mat-dialog-close" (click)="onNoClick()">
                  close
          </i>
  </div>
</div>
<div class="row">
    <div class="col-md-12">
      <h4>Delete Adverse Event</h4>
      <hr />
      <!-- <mat-spinner class="upload-spinner" strokeWidth="3" *ngIf="isloadingpres"></mat-spinner>   -->
        <form [formGroup]="deleteForm" validate>
            <mat-form-field class="col-md-12" appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput placeholder="Enter Comment" rows="6" formControlName="delete_reason"></textarea>
              </mat-form-field>
          <div class="col-md-12">
            <button mat-flat-button color="primary" class="centerbtn" (click)="deleteEvent()"
              [disabled]="!(deleteForm.valid)">Delete</button>
          </div>
        </form>
    </div>
  </div>