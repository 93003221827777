<!-- /*==========================================
Title: EDIT/CREATE Question & Answers 
Author: Mandeep singh
Date:   24 April 2020
Last Change :  Added new params
===========================================*/ -->
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/faqqahome/faqqaedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Question
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Question</mat-label>
                                <input matInput placeholder="Enter the Question" formControlName="question" required>
                            </mat-form-field>



                            <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label>Select Faq Category</mat-label>
                                <mat-select formControlName="faq_category_fk" #singleSelect>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmatfaq" ngModel (ngModelChange)="modelChangedFaq()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content notranslate">
                                                <i class="material-icons">
                                                    search
                                                </i>
                                            </div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let faq of filteredFAQData | async" [value]="faq.id">
                                        {{faq.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Therapy</mat-label>
                                    <mat-select formControlName="therapy_ids" multiple required #singleSelectTag>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmattag" ngModel
                                                (ngModelChange)="modelChangedTags()" ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content">
                                                    <i class="material-icons">
                                                        search
                                                    </i></div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let therapy of filteredTherapy | async"
                                            [value]="therapy.id">
                                            {{therapy.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Client</mat-label>
                                    <mat-select formControlName="client_ids" multiple #singleSelectClient>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmatclient" ngModel
                                                (ngModelChange)="modelChangedClient()" ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content">
                                                    <i class="material-icons">
                                                        search
                                                    </i></div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let client of filteredClient | async" [value]="client.id">
                                            {{client.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Tags</mat-label>
                                    <mat-select formControlName="tags" multiple #singleSelectTag>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                                ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content notranslate">
                                                    <i class="material-icons">
                                                        search
                                                    </i>
                                                </div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option class="camelcased select-tags-active"
                                            *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                            {{tag.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <p (click)="openinstructions()" class="instruction-p">Instructions for editor</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-md-12">
                                            <mat-label>Text</mat-label>
                                            <textarea matInput placeholder="Enter the text" formControlName="raw_answer"
                                                (ngModelChange)="additionalTextChange($event)" rows="25"
                                                (keydown)="uirender()" required></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row" id="markdown-editor">
                                        <div class="col-md-12">
                                            <fieldset [disabled]="disabled">
                                                <md-editor formControlName="additionaltext" name="content"
                                                    [upload]="doUpload" [preRender]="preRenderFunc" [height]="'410px'"
                                                    mode="editor" [options]="options" required class="mdeditorcss">
                                                </md-editor>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Question</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside" *ngIf="!(dataForm?.value.question == '')">
                            <div class="panel-group" id="accordion">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                                {{dataForm?.value?.question}}</a>
                                        </h4>
                                    </div>
                                    <div id="collapse1" class="panel-collapse collapse in">
                                        <div class="panel-body">
                                            <div [innerHTML]="renderhtml" class="maincontent"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</mat-drawer-container>