import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { FeedbackviewerComponent } from './feedbackviewer.component';
import { FeedbackviewertableComponent } from './feedbackviewertable/feedbackviewertable.component';
const routes: Routes = [
  { path: '', component: FeedbackviewerComponent, canActivate: [ContentGuardGuard] },
 // { path: 'chapter/edit/:id/:langid/:chid', component: FeedbackviewertableComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeedbackviewerRoutingModule { }
