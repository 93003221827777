import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-campaign-history',
  templateUrl: './campaign-history.component.html',
  styleUrls: ['./campaign-history.component.scss']
})
export class CampaignHistoryComponent implements OnInit {
  userid: any;
  userName: any;
  campaigndata: any;
  public dataSource: any;
  key: any = [];
  value: any = [];
  public allconditions: any;
  public alltherapies: any;
  allclients: any = []
  allhc: any = []
  doctorData: any = [];
  oldHealthFlagDisplayData: any = [];
  newHealthFlagDisplayData: any = [];
  oldDiaryFlagDisplayData: any = [];
  newDiaryFlagDisplayData: any = [];
  oldChatFlagdisplayData: any = [];
  newChatFlagDisplayData: any = [];
  OldProfileFlagdisplayData: any = [];
  NewProfileFlagdisplayData: any = [];
  public filteredTherapy: any = [];
  profileFlagEnable:boolean=false;

  caregiverColumns = ['__old', '__new', 'name', 'caregiver_updated_date'];
  public showloader: any;
  noDataFound: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private browserTitle: Title, private router: Router, public fb: FormBuilder, private commonService: CommonserviceService,
    public dialogRef: MatDialogRef<CampaignHistoryComponent>, private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public dataModal: any,) { }



  ngOnInit(): void {
    let id = this.dataModal.element;
    this.getAllCampaignData(id);
    this.getTherapy();
    this.getallclient();
    this.getHc();
    this.getDoctList();
  }

  getDoctList() {
    this.commonService.getGraphQlData('{getAllDoctorUsers(appMode:false,status:true){code,name,id}}')
      .subscribe(
        (res: any) => this.getDocListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getDocListSuccess(data) {
    data = (data as any).data;
    this.doctorData = data.getAllDoctorUsers;
  }

  getHc() {
    this.commonService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.onHCSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCSuccess(data) {
    data = (data as any).data;
    this.allhc = data.getAllHealthcoaches;
  }

  getallclient() {
    this.commonService.getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.onClientSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onClientSuccess(data) {
    data = (data as any).data;
    this.allclients = data.getAllClients;
  }


  OldHC(element) {
    let oldHCVal: any = [];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        oldHCVal.push(element[i])
      }
    }
    let name = [];
    if (oldHCVal.length != 0) {
      for (var val of oldHCVal) {
        for (let i = 0; i < this.allhc.length; i++) {
          if (val == this.allhc[i].id) {
            name.push(this.allhc[i].name)
          }
        }
      }
     
    }
    return name;
  }
  NewHC(element) {
    let NewHCVal: any = [];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        NewHCVal.push(element[i])
      }
    }
    let name = [];
    if (NewHCVal.length != 0) {
      for (var val of NewHCVal) {
        for (let i = 0; i < this.allhc.length; i++) {
          if (val == this.allhc[i].id) {
            name.push(this.allhc[i].name)
          }
        }
      }
    }
    return name;
  }

  selectedOldDoctor(elementId) {
    if (elementId != null) {
      let name = [];
      let id = elementId.toString();
      for (let i = 0; i < this.doctorData.length; i++) {
        if (id == this.doctorData[i].id) {
          name.push(this.doctorData[i].name)
          return this.doctorData[i].name;
        }
      }
    }
  }

  selectedNewDoctor(elementId) {
    if (elementId != null) {
      let name = [];
      let id = elementId.toString();
      for (let i = 0; i < this.doctorData.length; i++) {
        if (id == this.doctorData[i].id) {
          name.push(this.doctorData[i].name)
          return this.doctorData[i].name;
        }
      }
    }
  }

  selectedOldClient(elementId) {
    if (elementId != null) {
      let name = [];
      let id = elementId.toString();
      for (let i = 0; i < this.allclients.length; i++) {
        if (id == this.allclients[i].id) {
          name.push(this.allclients[i].title)
          return this.allclients[i].title;
        }
      }
    }
  }
  selectedNewClient(elementId) {
    if (elementId != null) {
      let name = [];
      let id = elementId.toString();
      for (let i = 0; i < this.allclients.length; i++) {
        if (id == this.allclients[i].id) {
          name.push(this.allclients[i].title)
          return this.allclients[i].title;
        }
      }
    }
  }



  getTherapy() {
    this.commonService.getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.getTherapyData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyData(data) {
    data = (data as any).data;
    this.alltherapies = data.getAllTherapies;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getAllCampaignData(ide) {
    this.commonService.getOneDataById(ide, 'campaigndata')
      .subscribe(
        (res: any) => this.onCampaignSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onCampaignSuccess(data) {
    data = (data as any).data;
    this.campaigndata = data;
    let campaignDataValue = this.campaigndata.edit_history;
    let dummyArr: any = [];
    for (let i = 0; i < campaignDataValue.length; i++) {
      campaignDataValue[i].changes['created_at'] = campaignDataValue[i].created_at;
      campaignDataValue[i].changes['name'] = campaignDataValue[i].name;
      campaignDataValue[i].changes['new_data'] = campaignDataValue[i].new_data;
      campaignDataValue[i].changes['old_data'] = campaignDataValue[i].old_data;
      dummyArr.push(campaignDataValue[i].changes);
      dummyArr.sort(function(a,b){
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    }
    this.dataSource = new MatTableDataSource(dummyArr);
    if(this.dataSource.filteredData.length === 0){
      this.noDataFound = true;
    }else{
      this.noDataFound = false;
    }
    for (let i = 0; i < dummyArr.length; i++) {
      if (dummyArr[i].profile_flags.length > 0) {
        this.oldChangeContent(dummyArr[i].profile_flags);
        this.newlyChangeContent(dummyArr[i].profile_flags);
      }
    }
  }


  newTherapy(elementId) {
    if (elementId != null) {
      let name = [];
      let id = elementId.toString();
      for (let i = 0; i < this.alltherapies.length; i++) {
        if (id == this.alltherapies[i].id) {
          name.push(this.alltherapies[i].title)
          return this.alltherapies[i].title;
        }
      }
    }
  }

  oldTherapy(elementId) {
    if (elementId != null) {
      let name = [];
      let id = elementId.toString();
      for (let i = 0; i < this.alltherapies.length; i++) {
        if (id == this.alltherapies[i].id) {
          name.push(this.alltherapies[i].title)
          return this.alltherapies[i].title;
        }
      }
    }
  }


  oldHealthFlagContent(element) {
    let oldVal: any = [];
    this.oldHealthFlagDisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-") {
          oldVal.push(element[i][1])
        }
      }
    }
    if (oldVal.includes("BSM")) {
      this.oldHealthFlagDisplayData.push("show Blood Sugar Monitor");
    }
    if (oldVal.includes("EYP")) {
      this.oldHealthFlagDisplayData.push("show Eye Problem");
    }
    if (oldVal.includes("GYM")) {
      this.oldHealthFlagDisplayData.push("show Gym Access");
    }
    if (oldVal.includes("WOP")) {
      this.oldHealthFlagDisplayData.push("show Working Professional");
    }
    if (oldVal.includes("LOA")) {
      this.oldHealthFlagDisplayData.push("Location Access");
    }
    if (oldVal.includes("DBS")) {
      this.oldHealthFlagDisplayData.push("show diabietic");
    }
    if (oldVal.includes("HBP")) {
      this.oldHealthFlagDisplayData.push("show high blood pressure");
    }
    return this.oldHealthFlagDisplayData;
  }

  newHealthFlagContent(element) {
    let newVal: any = [];
    this.newHealthFlagDisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "+") {
          newVal.push(element[i][1])
        }
      }
    }
 
    if (newVal.includes("BSM")) {
      this.newHealthFlagDisplayData.push("show Blood Sugar Monitor");
    }
    if (newVal.includes("EYP")) {
      this.newHealthFlagDisplayData.push("show Eye Problem");
    }
    if (newVal.includes("GYM")) {
      this.newHealthFlagDisplayData.push("show Gym Access");
    }
    if (newVal.includes("WOP")) {
      this.newHealthFlagDisplayData.push("show Working Professional");
    }
    if (newVal.includes("LOA")) {
      this.newHealthFlagDisplayData.push("Location Access");
    }
    if (newVal.includes("DBS")) {
      this.newHealthFlagDisplayData.push("show diabietic");
    }
    if (newVal.includes("HBP")) {
      this.newHealthFlagDisplayData.push("show high blood pressure");
    }
    return this.newHealthFlagDisplayData;
  }


  oldChatFlagContent(element) {
    let oldVal: any = [];
    this.oldChatFlagdisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-") {
          oldVal.push(element[i][1])
        }
      }
    }
    if (oldVal.includes("SCA")) {
      this.oldChatFlagdisplayData.push("show carey");
    }
    if (oldVal.includes("SCO")) {
      this.oldChatFlagdisplayData.push("show coach");
    }
    return this.oldChatFlagdisplayData;
  }

  newChatFlagContent(element) {
    let newVal: any = [];
    this.newChatFlagDisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "+") {
          newVal.push(element[i][1])
        }
      }
    }
   
    if (newVal.includes("SCA")) {
      this.newChatFlagDisplayData.push("show carey");
    }
    if (newVal.includes("SCO")) {
      this.newChatFlagDisplayData.push("show coach");
    }
    return this.newChatFlagDisplayData;
  }



  oldDiaryFlagContent(element) {
    let oldVal: any = [];
    this.oldDiaryFlagDisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-") {
          oldVal.push(element[i][1])
        }
      }
    }
    if (oldVal.includes("ISG")) {
      this.oldDiaryFlagDisplayData.push("insight");
    }
    if (oldVal.includes("STK")) {
      this.oldDiaryFlagDisplayData.push("streaks");
    }

    return this.oldDiaryFlagDisplayData;
  }

  newDiaryFlagContent(element) {
    let newVal: any = [];
    this.newDiaryFlagDisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "+") {
          newVal.push(element[i][1])
        }
      }
    }
  
    if (newVal.includes("ISG")) {
      this.newDiaryFlagDisplayData.push("insight");
    }
    if (newVal.includes("STK")) {
      this.newDiaryFlagDisplayData.push("streaks");
    }
    return this.newDiaryFlagDisplayData;
  }

  ProfileflagsValidate(element) {
    // "STR","SQR","SMS","YOU","SHS"
    let profileFlag = ["TIT", "LGL", "SRM", "SPR", "SFQ",, "SET", "SHD", "SCG", "DOC", "SUB", "THM", "TAC", "PPY", "DET", "CSU", "SPS", "SPD", "APM", "SST",
      "SRW", "ECM", "RAE", "RGH", "LGL",,"SMS","SUT", "STG", "IRM",
      "INR", "UGL", "SMF"];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-" || element[i][0] == "+") {
          if(profileFlag.includes(element[i][1]))
          {
             return {
                'value': true,
                'action': element[i][0]
    };
            //this.profileFlagEnable=true;
          }
        }
      }
    }
   
  }


  chatflagsValidate(element) {
    let chatFlag=["SCA","SCO"];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-" || element[i][0] == "+") {
          if(chatFlag.includes(element[i][1]))
          {
            return {
              'value': true,
              'action': element[i][0]
  };
          }
        }
      }
    }
   
  }

  diaryflagsValidate(element) {
    let diaryFlag=["ISG","STK"];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-" || element[i][0] == "+") {
          if(diaryFlag.includes(element[i][1]))
          {
            return {
              'value': true,
              'action': element[i][0]
  };
          }
        }
      }
    }   
  } 

  healthDetailflagsValidate(element) {
    let healthDetailFlag=["BSM","EYP","GYM","WOP","LOA","DBS","HBP"]
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-" || element[i][0] == "+") {
          if(healthDetailFlag.includes(element[i][1]))
          {
            return {
              'value': true,
              'action': element[i][0]
  };
          }
        }
      }
    }
   
  }
  oldChangeContent(element) {
    let oldVal: any = [];
    this.OldProfileFlagdisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "-") {
          oldVal.push(element[i][1])
        }
      }
    }
    if (oldVal.includes("TIT")) {
      this.OldProfileFlagdisplayData.push("AppDetail page title");
    }
    if (oldVal.includes("LGL")) {
      this.OldProfileFlagdisplayData.push("Mother tongue");
    }
    if (oldVal.includes("SRM")) {
      this.OldProfileFlagdisplayData.push("show reminders");
    }
    if (oldVal.includes("SPR")) {
      this.OldProfileFlagdisplayData.push("show prescription");
    }
    if (oldVal.includes("SFQ")) {
      this.OldProfileFlagdisplayData.push("show FAQ");
    }
    if (oldVal.includes("YOU")) {
      this.OldProfileFlagdisplayData.push("show YOU tab");
    }
    if (oldVal.includes("SET")) {
      this.OldProfileFlagdisplayData.push("show Setting tab");
    }
    if (oldVal.includes("SHD")) {
      this.OldProfileFlagdisplayData.push("Health detail");
    }
    if (oldVal.includes("SCG")) {
      this.OldProfileFlagdisplayData.push("show caregiver");
    }
    if (oldVal.includes("DOC")) {
      this.OldProfileFlagdisplayData.push("show doctor");
    }
    if (oldVal.includes("SUB")) {
      this.OldProfileFlagdisplayData.push("show Subscription");
    }
    if (oldVal.includes("THM")) {
      this.OldProfileFlagdisplayData.push("show theme");
    }
    if (oldVal.includes("TAC")) {
      this.OldProfileFlagdisplayData.push("show t&C");
    }
    if (oldVal.includes("PPY")) {
      this.OldProfileFlagdisplayData.push("show privacy policy");
    }
    if (oldVal.includes("DET")) {
      this.OldProfileFlagdisplayData.push("show app detail");
    }
    if (oldVal.includes("CSU")) {
      this.OldProfileFlagdisplayData.push("customer support");
    }
    if (oldVal.includes("SPS")) {
      this.OldProfileFlagdisplayData.push("Skip preview screen");
    }
    if (oldVal.includes("SPD")) {
      this.OldProfileFlagdisplayData.push("Show Policy Progress data");
    }
    if (oldVal.includes("APM")) {
      this.OldProfileFlagdisplayData.push("Add policy members");
    }
    if (oldVal.includes("SST")) {
      this.OldProfileFlagdisplayData.push("Steps to be shown");
    }
    if (oldVal.includes("SRW")) {
      this.OldProfileFlagdisplayData.push("Show Reward Section");
    }
    if (oldVal.includes("ECM")) {
      this.OldProfileFlagdisplayData.push("coach mark");
    }
    if (oldVal.includes("RAE")) {
      this.OldProfileFlagdisplayData.push("Report AE");
    }
    if (oldVal.includes("RGH")) {
      this.OldProfileFlagdisplayData.push("Resync_GoogleFit");
    }
    if (oldVal.includes("LGL")) {
      this.OldProfileFlagdisplayData.push("Mother tongue");
    }
    if (oldVal.includes("SMS")) {
      this.OldProfileFlagdisplayData.push("show medication survey");
    }
    // if (oldVal.includes("SQR")) {
    //   this.OldProfileFlagdisplayData.push("show Questionnaire");
    // }
    // if (oldVal.includes("STR")) {
    //   this.OldProfileFlagdisplayData.push("show Triggers");
    // }
    // if (oldVal.includes("SAQ")) {
    //   this.OldProfileFlagdisplayData.push("show Aqi");
    // }
    // if (oldVal.includes("SHS")) {
    //   this.OldProfileFlagdisplayData.push("show History");
    // }
    if (oldVal.includes("SUT")) {
      this.OldProfileFlagdisplayData.push("show Utilities");
    }
    if (oldVal.includes("STG")) {
      this.OldProfileFlagdisplayData.push("show Targets");
    }
    if (oldVal.includes("IRM")) {
      this.OldProfileFlagdisplayData.push("show Remodulin");
    }
    if (oldVal.includes("INR")) {
      this.OldProfileFlagdisplayData.push("show Nonremodulin");
    }
    if (oldVal.includes("UGL")) {
      this.OldProfileFlagdisplayData.push("show Geolocation");
    }
    if (oldVal.includes("SMF")) {
      this.OldProfileFlagdisplayData.push("show Manufacturer");
    }
    
    return this.OldProfileFlagdisplayData;
    // return {
    //   'value': this.OldProfileFlagdisplayData,
    //   'length': this.OldProfileFlagdisplayData.length
    // };
  }

  newlyChangeContent(element) {
    let newVal: any = [];
    this.NewProfileFlagdisplayData=[];
    if (element != null) {
      for (let i = 0; i < element.length; i++) {
        if (element[i][0] == "+") {
          newVal.push(element[i][1])
        }
      }
    }
    if (newVal.includes("SMF")) {
      this.NewProfileFlagdisplayData.push("show Manufacturer");
    }
    if (newVal.includes("UGL")) {
      this.NewProfileFlagdisplayData.push("show Geolocation");
    }
    if (newVal.includes("INR")) {
      this.NewProfileFlagdisplayData.push("show Nonremodulin");
    }
    if (newVal.includes("IRM")) {
      this.NewProfileFlagdisplayData.push("show Remodulin");
    }
    if (newVal.includes("STG")) {
      this.NewProfileFlagdisplayData.push("show Targets");
    }
    if (newVal.includes("SUT")) {
      this.NewProfileFlagdisplayData.push("show Utilities");
    }
    // if (newVal.includes("SHS")) {
    //   this.NewProfileFlagdisplayData.push("show History");
    // }
    // if (newVal.includes("SAQ")) {
    //   this.NewProfileFlagdisplayData.push("show Aqi");
    // }
    // if (newVal.includes("STR")) {
    //   this.NewProfileFlagdisplayData.push("show Triggers");
    // }
    // if (newVal.includes("SQR")) {
    //   this.NewProfileFlagdisplayData.push("show Questionnaire");
    // }
    if (newVal.includes("TIT")) {
      this.NewProfileFlagdisplayData.push("AppDetail page title");
    }
    if (newVal.includes("LGL")) {
      this.NewProfileFlagdisplayData.push("Mother tongue");
    }
    if (newVal.includes("SRM")) {
      this.NewProfileFlagdisplayData.push("show reminders");
    }
    if (newVal.includes("SPR")) {
      this.NewProfileFlagdisplayData.push("show prescription");
    }
    if (newVal.includes("SFQ")) {
      this.NewProfileFlagdisplayData.push("show FAQ");
    }
    if (newVal.includes("YOU")) {
      this.NewProfileFlagdisplayData.push("show YOU tab");
    }
    if (newVal.includes("SET")) {
      this.NewProfileFlagdisplayData.push("show Setting tab");
    }
    if (newVal.includes("SHD")) {
      this.NewProfileFlagdisplayData.push("Health detail");
    }
    if (newVal.includes("SCG")) {
      this.NewProfileFlagdisplayData.push("show caregiver");
    }
    if (newVal.includes("DOC")) {
      this.NewProfileFlagdisplayData.push("show doctor");
    }
    if (newVal.includes("SUB")) {
      this.NewProfileFlagdisplayData.push("show Subscription");
    }
    if (newVal.includes("THM")) {
      this.NewProfileFlagdisplayData.push("show theme");
    }
    if (newVal.includes("TAC")) {
      this.NewProfileFlagdisplayData.push("show t&C");
    }
    if (newVal.includes("PPY")) {
      this.NewProfileFlagdisplayData.push("show privacy policy");
    }
    if (newVal.includes("DET")) {
      this.NewProfileFlagdisplayData.push("show app detail");
    }
    if (newVal.includes("CSU")) {
      this.NewProfileFlagdisplayData.push("customer support");
    }
    if (newVal.includes("SPS")) {
      this.NewProfileFlagdisplayData.push("Skip preview screen");
    }
    if (newVal.includes("SPD")) {
      this.NewProfileFlagdisplayData.push("Show Policy Progress data");
    }
    if (newVal.includes("APM")) {
      this.NewProfileFlagdisplayData.push("Add policy members");
    }
    if (newVal.includes("SST")) {
      this.NewProfileFlagdisplayData.push("Steps to be shown");
    }
    if (newVal.includes("SRW")) {
      this.NewProfileFlagdisplayData.push("Show Reward Section");
    }
    if (newVal.includes("ECM")) {
      this.NewProfileFlagdisplayData.push("coach mark");
    }
    if (newVal.includes("RAE")) {
      this.NewProfileFlagdisplayData.push("Report AE");
    }
    if (newVal.includes("RGH")) {
      this.NewProfileFlagdisplayData.push("Resync_GoogleFit");
    }
    if (newVal.includes("LGL")) {
      this.NewProfileFlagdisplayData.push("Mother tongue");
    }
    if (newVal.includes("SMS")) {
      this.NewProfileFlagdisplayData.push("show medication survey");
    }
    
    return this.NewProfileFlagdisplayData;
    //  return {
    //   'value': this.NewProfileFlagdisplayData,
    //   'length':  this.NewProfileFlagdisplayData.length
    // };
  }

}
