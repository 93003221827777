import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { UserListService } from '../../services/userlist/user-list.service';
import { HttpErrorResponse } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-generaldata',
  templateUrl: './generaldata.component.html',
  styleUrls: ['./generaldata.component.scss']
})

export class GeneraldataComponent implements OnInit {
  message: any;
  rawdata: any;
  errors: any;
  sub: any;
  userid: any;
  public morrow: any = new Date();
  public newDate: any;
  periodfilter = 'Last 7 Traces';
  rawprescriptiondata: any;
  userSummary: any;
  userprofiledata: any;
  diarydata: any;
  showgraphdata = [];
  hba1cpercentage: any;
  seviceVariableDatas: any;
  userID: string;
  prescriptionurl = [];
  prescriptionurlraw: any;
  newar = [];
  rawprescriptiondataFiltered: any;
  userDetails: any;
  diffDays: any;
  public allusers: any;
  public data: any;
  public diabetic_since: any;
  public diabetic_sincevalue: any;
  public program_start: any;
  public coach_notes_date: any;
  public diabetic_years: any;
  public today_year: any;
  public clientname: any;
  toggleval: boolean = true;
  buttonclick: boolean;
  spinnerButton: boolean = false;
  isdownloadingreport: boolean = false;
  showButtonLoader: boolean = false;
  followupdata: any;
  allusersdata: any;
  overviewdata: any;
  hasInsights: boolean = false;
  public eligiblelogs: any;
  primary_condition: any;
  secondary_condition: any;
  nix_data: any;
  diabetic_primary_condition: any;
  diabetic_secondary_condition: any;
  isLoaded: boolean = false;
  coach_notes: any;
  public patientlist: any;
  public hcnotes: any;
  public hcnotesdate: any;
  public patientdata: any;
  public patientfollowup: any;
  conditionalElements: HTMLCollection;
  patientfollowupVal: any = 'false';
  public currentdate: any;
  clientDetailsPDFDownload: any;

  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });

  currentLogSelection = "diary";

  constructor(
    private sys: SystemService,
    private UserListService: UserListService,
    private route: ActivatedRoute,
    private AuthenticateService: AuthenticateService,
    private doctorService: DoctorDataService,
    private router: Router,
    private browserTitle: Title,
    public fb: FormBuilder,
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Doctor | Dashboard');
  }

  ngOnInit() {
    this.setSEOTags();
    this.currentLogSelection = "diary";
    this.route.params.subscribe(params => {
      this.userID = params.id;
      this.showgraphdata = [];
      this.patientfollowup = params.followup;
      this.getOnePatientData(this.userID);
      this.currentLogSelection = "diary";
      this.getHCNotes(this.userID);
    });
    let notificationClickPatientName = localStorage.getItem("notifyPatientName");
    let selectedNotification = localStorage.getItem("selectedNotification");
    if (notificationClickPatientName || selectedNotification) {
      this.notificationRoute();
      }
  
  }
  notificationRoute() {
    let notificationStoredValue = localStorage.getItem("selectedNotification");
    if (notificationStoredValue == 'low_bp_event' || notificationStoredValue == 'high_bp_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
      
    }
    else if (notificationStoredValue == 'low_blood_sugar_event' || notificationStoredValue == 'high_blood_sugar_event' || notificationStoredValue == 'blood_sugar_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    else if (notificationStoredValue == 'peak_flow') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    else if (notificationStoredValue == 'activity_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    else if (notificationStoredValue == 'weight_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    else if (notificationStoredValue == 'water_alert_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    else if (notificationStoredValue == 'meal_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    else if (notificationStoredValue == 'infusion_site_event') {
      this.currentLogSelection = "diary";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
    // other section route condtion
    if (notificationStoredValue == "medication_survey_added") {
     
      let val = 'medicationSurvey'
    
    }
    else if (notificationStoredValue == "medication_added_event" || notificationStoredValue == "medication_updated_event") {
    
      let val = 'medication'
     
    }
    else if (notificationStoredValue == "prescription_upload" || notificationStoredValue == "prescription_update") {
      let val = 'prescription'
      
    }
    else if (notificationStoredValue == "reminder_added_event" || notificationStoredValue == "reminder_updated_event" ||
    notificationStoredValue == "medicine_added_event" || notificationStoredValue == "medicine_updated_event") {
      let val = 'reminder'
    
    }
    else if (notificationStoredValue == 'caregiver_added' || notificationStoredValue == 'caregiver_updated') {
      let val = 'caregiver'
     
    }
    else if (notificationStoredValue == 'doctor_assignment_event' || notificationStoredValue == 'doctor_reassignment_event') {
      let val = 'doctor'
     
    }
    else if (notificationStoredValue == 'trigger_event') {
      let val = 'trigger'
     
    }
    else if (notificationStoredValue == 'questionnaire_event') {
      let val = 'questionnaire'
     
    }
    else if (notificationStoredValue == 'symptom_event') {
      let val = 'symptom'
     
    }
    else if (notificationStoredValue == 'adverse_reaction_event') {
      let val = 'adr'
      
    }
    else if (notificationStoredValue == 'pump_added_event' || notificationStoredValue == 'pump_updated_event') {
      this.currentLogSelection = "others";
      this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
    }
  }

  getWholeNumberAge(dateString) {
    return this.UserListService.getAge(dateString);
  }
  /**
   * @name getUsers
   * @description To get all the patients under the particular doctor
   * @param No_Param
   * @returns NULL
   */
  // getUsers() {
  //   this.isLoaded = false;
  //   this.doctorService.getAllUsers().subscribe(
  //     data => {
  //       this.allusersdata = data;
  //       this.getoneuser(this.allusersdata);
  //     },
  //     error => {
  //       this.errors = error;
  //     }
  //   );
  // }
  // getUsers() {
  //   this.query="";
  //   this.status="all";
  //   this.page="1";
  //   this.limit="10";
  //   this.UserListService.getPatientsByDoctor( this.query, this.status, this.page, this.limit).subscribe(
  //     data => {
  //       this.allusersdata = data;
  //       this.getoneuser(this.allusersdata);
  //     },
  //     error => {
  //       if (error.status === 666) {
  //         // this.authService.logout();
  //         //window.location.reload();
  //       }
  //     }
  //   );
  // }

  /**
   * @name getoneuser
   * @description To get particular patient's data
   * @param alldata
   * @returns NULL
   */
  // getoneuser(alldata) {
  //   // this.userprofiledata = alldata.data.users.filter(
  //   //   a => a.id === this.userID
  //   // )[0];
  //   this.getUserData(this.userID);
  //   return this.userprofiledata;
  // }

  /**
   * @name getUserData
   * @description To get the diary data
   * @param userID
   * @returns NULL
   */
  getPatientDairyData(userID) {
    this.doctorService.getUserData(userID)
      .subscribe(
        (res: any) => this.onSuccessPatientDairyData(res),
        (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
      );
  }
  onSuccessPatientDairyData(data) {
    this.rawdata = data;
    this.diarydata = this.rawdata.diary;
  }

  getOnePatientData(patientid) {
    this.doctorService.getPatientProfile(patientid)
      .subscribe(
        (res: any) => this.onSuccessOnePatientData(res),
        (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
      );
  }
  onSuccessOnePatientData(data) {
    this.patientdata = data.data;
    this.clientDetailsPDFDownload = this.patientdata.patient_client_data.title
    
    // localStorage.setItem('patientClient', this.patientdata.patient_client_data.title);
  }

  getHCNotes(patientid) {
    this.doctorService.getPatientHCNotes(patientid)
      .subscribe(
        (res: any) => this.onSuccessHCNotes(res),
        (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
      );
  }

  onSuccessHCNotes(data) {
    this.hcnotes = data;
    if (this.hcnotes.data && this.hcnotes[this.hcnotes.data.length - 1]) {
      this.hcnotesdate = moment(this.hcnotes.data[this.hcnotes.data.length - 1].created_at).format("Do MMM YYYY");
    }
  }

  /**
   * @name getoverviewdata
   * @description To get the overview section data
   * @param userid
   * @returns NULL
   */



  getoverviewdata(userid) {
    this.doctorService.getOneUserData(userid)
      .subscribe(
        (res: any) => this.onSuccessoverviewData(res),
        (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
      );

    // .subscribe(
    //   data => {

    //   },
    //   error => {
    //     this.errors = error;
    //   }
    // );
  }

  onSuccessoverviewData(data) {
    
    $('.secondarycondition').css('margin-top', '0px');
    $('.buttonrectangle:nth-of-type(4)').css('margin-top', '0px');
    this.overviewdata = data;
    if (this.overviewdata.coach_notes) {
      this.coach_notes = this.overviewdata.coach_notes[this.overviewdata.coach_notes.length - 1];
    }
    this.nix_data = this.overviewdata.profile.has_nix_access;
    this.today_year = moment().year();
    this.diabetic_since = this.overviewdata.overview.diabetic_since;
    this.diabetic_years = this.today_year - this.diabetic_since;
    this.diabetic_sincevalue =
      'since ' +
      this.diabetic_since +
      ' (' +
      this.diabetic_years + ((this.diabetic_years == 1) ? ' year)' :
        ' years)');
    this.program_start = moment
      .unix(this.overviewdata.overview.program_start_date)
      .format('Do MMM YY');
    this.coach_notes_date = moment
      .unix(this.overviewdata.coach_notes_updated_at)
      .format('Do MMM YYYY');
    this.primary_condition = this.overviewdata.metadata.primary_condition;
    if (this.primary_condition === 'Chronic Kidney Disease') {
      this.primary_condition = 'CKD';
    }
    if (this.primary_condition === 'Coronary Artery Disease') {
      this.primary_condition = 'CAD';
    }
    if (this.primary_condition === 'Congestive Heart Failure') {
      this.primary_condition = 'CHF';
    }
    if (this.primary_condition === 'Cholesterol' || this.primary_condition === 'High Cholesterol') {
      this.primary_condition = 'Dyslipidemia';
    }
    if (
      this.primary_condition === 'Diabetes Insulin' ||
      this.primary_condition === 'Diabetes Oral' ||
      this.primary_condition === 'Diabetes'
    ) {
      this.diabetic_primary_condition = true;
    } else {
      this.diabetic_primary_condition = false;
    }

    this.secondary_condition = this.overviewdata.metadata.comorbidities;
    this.diabetic_secondary_condition = false;
    if (this.secondary_condition !== null) {
      for (let k = 0; k < this.secondary_condition.length; k++) {
        if (this.secondary_condition[k] === 'Chronic Kidney Disease') {
          this.secondary_condition[k] = 'CKD';
        }
        if (this.secondary_condition[k] === 'Coronary Artery Disease') {
          this.secondary_condition[k] = 'CAD';
        }
        if (this.secondary_condition[k] === 'Cholesterol' || this.secondary_condition[k] === 'High Cholesterol') {
          this.secondary_condition[k] = 'Dyslipidemia';
        }
        if (this.secondary_condition[k] === 'Congestive Heart Failure') {
          this.secondary_condition[k] = 'CHF';
        }
        if (this.secondary_condition[k] === 'Diabetes Insulin') {
          this.secondary_condition[k] = 'Diabetes';
        }
        if (this.secondary_condition[k] === 'Diabetes Oral') {
          this.secondary_condition[k] = 'Diabetes';
        }
      }
      for (let p = 0; p < this.secondary_condition.length; p++) {
        if (this.secondary_condition[p] === 'Diabetes') {
          this.diabetic_secondary_condition = true;
          break;
        }
      }
    }
    this.getEligibleLog(this.userID);

    setTimeout(function () {
      this.conditionalElements = document.getElementsByClassName(
        'secondarycondition'
      );
      if (this.conditionalElements[0].offsetHeight > 60) {
        $('.secondarycondition').css('margin-top', '-20px');
        $('.buttonrectangle:nth-of-type(4)').css('margin-top', '10px');
      }
    }, 50);
  }

  /**
   * @name getEligibleLog
   * @description Mapping for lab reports
   * @param id
   * @returns NULL
   */
  getEligibleLog(id) {
    this.doctorService.getEligibalLogs(id)
      .subscribe(
        (res: any) => this.onSuccessEligibleLog(res),
        (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
      );
  }

  onSuccessEligibleLog(data) {
    this.eligiblelogs = data;
    //     this.eligiblelogs = this.eligiblelogs.logs;
  }


  //   followup(){
  //     this.spinnerButton= true;
  //     this.patientfollowup = ! this.patientfollowup;
  //      this.doctorService.followupPatient(this.userID, (JSON.parse(localStorage.getItem('scarletuserdata')).id).toString() ,(!this.patientfollowup).toString()).subscribe(

  followup(patient_followup) {
    this.followups(patient_followup);
    this.spinnerButton = true;

    if (patient_followup == 'true') {
      this.patientfollowupVal = 'false';
    }
    else if (patient_followup == 'false') {
      this.patientfollowupVal = 'true';
    }

    // localStorage.setItem('patientfollowup', this.patientfollowup);
    this.doctorService.followupPatient(this.userID, (JSON.parse(localStorage.getItem('scarletuserdata')).id).toString(), this.patientfollowupVal.toString()).subscribe(
      data => {
        this.spinnerButton = false;

        this.router.navigate(['doctor/user', this.userID, this.patientfollowupVal]);
      },
      error => {
        this.errors = error;
      }
    )
  }


  /**
   * @name followup
   * @description Followup data manipulation request through service subscription
   * @param No_Param
   * @returns NULL
   */
  followups(patient_followup) {
    
      let datap =
        {
          "d": [
            {
              "identity": localStorage.getItem('scarletuserid'),
              "ts": (Math.floor(new Date().valueOf() / 1000)),
              "type": "event",
              "evtName": "Follow Up Button Clicked",
              "evtData": {
                "Patient name": this.patientdata.name,
                "Patient ID": this.userID,
                "Status": patient_followup == 'false' ? "Followed" : "Unfollowed"
              }
            }
          ]
        }
        this.AuthenticateService
      .sendEventData(datap)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
      })
     
  }

  getDownloadClicks() {
    
    let datap =
      {
        "d": [
          {
            "identity": localStorage.getItem('scarletuserid'),
            "ts": (Math.floor(new Date().valueOf() / 1000)),
            "type": "event",
            "evtName": "Report Downloaded",
            "evtData": {
              "Patient name": this.patientdata.name,
              "Patient ID": this.userID,
            }
          }
        ]
      }
      this.AuthenticateService
    .sendEventData(datap)
    .pipe(
      catchError((error: any) => {
        return throwError(error);
      }),
      finalize(() => {
      })
    )
    .subscribe(data => {
    })
   
}
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataForm.get('enddate').reset();
    this.currentdate = event.target.value;
    this.newDate = new Date(event.target.value);
    this.newDate.setDate(this.newDate.getDate() + 59)
  }
  downloadReport() {
    this.getDownloadClicks();
    
    if (this.clientDetailsPDFDownload == 'Ferrer') {
      var win = window.open('/#/doctor/doctorpdf/' + this.userID + '/' + (this.dataForm.get('startdate').value).getTime() + '/' + (this.dataForm.get('enddate').value).getTime() + '/' + 'ferrer', '_blank');
    } else if (this.clientDetailsPDFDownload == 'Abbott') {
      var win = window.open('/#/doctor/doctorpdf/' + this.userID + '/' + (this.dataForm.get('startdate').value).getTime() + '/' + (this.dataForm.get('enddate').value).getTime() + '/' + 'abbott', '_blank');
    } else {
      var win = window.open('/#/doctor/doctorpdf/' + this.userID + '/' + (this.dataForm.get('startdate').value).getTime() + '/' + (this.dataForm.get('enddate').value).getTime(), '_blank');
    }
    // var win = window.open('/#/doctor/doctorpdf/' + this.userID + '/' + (this.dataForm.get('startdate').value).getTime() + '/' + (this.dataForm.get('enddate').value).getTime(), '_blank');
    this.dataForm.reset();
    // 
    // 
    // this.router.navigate(['doctor/doctorpdf', this.userID, (this.dataForm.get('startdate').value).getTime(), (this.dataForm.get('enddate').value).getTime()]);
    // scope.isdownloadingreport =true;
    // setTimeout(() => {
    //   scope.isdownloadingreport =false;
    // }, 1500);

  }
  ngOnDestroy(): void {
    this.currentLogSelection = "diary";
  }
}