import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { CareycardComponent } from './careycard.component';
import { EditcareycardComponent } from './editcareycard/editcareycard.component';
const routes: Routes = [
  { path: '', component: CareycardComponent, canActivate: [ContentGuardGuard] },
  { path: 'careycard/edit/:id/:langid/:chid', component: EditcareycardComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareycardRoutingModule { }
