<div class="clearfix">
    <h2 class="pull-left title-modal">Task history</h2>
    <div class="pull-right">
        <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive" alt="">
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="container-table">
            <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
              
                <div class="spinner-container" *ngIf="showloader">
                    <app-loader showLoader="{{true}}"></app-loader>
                  </div>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{convertDate(element.created_at)}} </td>
                </ng-container>

                <ng-container matColumnDef="action_type">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.action_type}}</td>
                </ng-container>

                <ng-container matColumnDef="created_by_name">
                    <th mat-header-cell *matHeaderCellDef> Super Admin </th>
                    <td mat-cell *matCellDef="let element"> {{element.created_by_name}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
    </div>
</div>