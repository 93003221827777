<!-- <mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-hcassignleftnav></app-hcassignleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/codegeneratehome/viewcode"></app-breadcrumb>
      <div class="col-md-12 p-0">
        <h3 class="commontitleprimary hc-status-head">
          <app-backbutton></app-backbutton>
          Go back
        </h3>
      </div>


      <div class="col-md-12">
        <div class="example-container" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef> Code </th>
              <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="is_active">
              <th mat-header-cell *matHeaderCellDef> Active </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.is_active}} </td>
            </ng-container>

            <ng-container matColumnDef="is_activated">
              <th mat-header-cell *matHeaderCellDef> Activated </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.is_activated}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>

          </table>
        </div>
      </div>


    </div>
  </div>
</mat-drawer-container> -->



<div class="col-md-12 p-0">

  <div class="clearfix">
    <h2 class="pull-left title-modal">All codes</h2>
    <div class="pull-right">
      <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
    </div>
  </div>

  <div class="example-container" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
    (scrolled)="getNextSet()" [scrollWindow]="false">
    <div class="spinner-container" *ngIf="showloader">
      <app-loader showLoader="{{true}}"></app-loader>
    </div>
    <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>

      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.is_active}} </td>
      </ng-container>

      <ng-container matColumnDef="is_activated">
        <th mat-header-cell *matHeaderCellDef> Activated </th>
        <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.is_activated}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;">
      </tr>

    </table>
  </div>
</div>