<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/quizhome/quizedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Quiz
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Quiz Name</mat-label>
                                <input matInput placeholder="Enter the quiz name" formControlName="title" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Quiz Description</mat-label>
                                <textarea rows='2' matInput placeholder="Enter the description"
                                    formControlName="detailed_text" required></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Success Content</mat-label>
                                <textarea rows='2' matInput placeholder="Enter the success content"
                                    formControlName="success_content"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Retry Content</mat-label>
                                <textarea rows='2' matInput placeholder="Enter the retry content"
                                    formControlName="retry_content"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Tags</mat-label>
                                <mat-select placeholder="Select Tags" formControlName="tags" required multiple
                                    #singleSelectTag>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i></div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option class="camelcased select-tags-active"
                                        *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                        {{tag.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Complexity Level</mat-label>
                                <mat-select formControlName="complexity_level" required>
                                    <mat-option value="Low">
                                        Low
                                    </mat-option>
                                    <mat-option value="Medium">
                                        Medium
                                    </mat-option>
                                    <mat-option value="High">
                                        High
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="checkbox-margin">
                                <mat-checkbox value="checked" formControlName="allow_feedback"
                                    (click)="changeValue(checked)">Allow Feedback
                                </mat-checkbox>
                            </div>

                            <div [ngClass]="!checked ? 'hidefield' : ''">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Feedback</mat-label>
                                    <mat-select formControlName="feedback" #singleSelectFeedback [required]="checked">
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmatfeedback" ngModel
                                                (ngModelChange)="modelChangedFeedback()" ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content">
                                                    <i class="material-icons">
                                                        search
                                                    </i></div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option class="camelcased" *ngFor="let feed of filteredFeedback | async"
                                            [value]="feed.id">
                                            {{feed.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Total Retry</mat-label>
                                <mat-select placeholder="Total Retry" formControlName="total_retry"
                                    (selectionChange)="totalretry($event.value)" [disabled]="isretryDisabled()"
                                    required>
                                    <mat-option [value]="1">
                                        1
                                    </mat-option>
                                    <mat-option [value]="2">
                                        2
                                    </mat-option>
                                    <mat-option [value]="3">
                                        3
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-form-field appearance="outline" class="full-width-formfield" *ngIf="totretry">
                                <mat-label>Total Questions</mat-label>

                                <mat-select placeholder="Total Questions" formControlName="total_question"
                                    (selectionChange)="totalquestion($event.value)" [disabled]="isretryDisabled()"
                                    required>
                                    <mat-option [value]="1">
                                        1
                                    </mat-option>
                                    <mat-option [value]="2">
                                        2
                                    </mat-option>
                                    <mat-option [value]="3">
                                        3
                                    </mat-option>
                                    <mat-option [value]="4">
                                        4
                                    </mat-option>
                                    <mat-option [value]="5">
                                        5
                                    </mat-option>
                                    <mat-option [value]="6">
                                        6
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <div class="col-md-12" *ngIf="totquestion && totretry">
                                <div formArrayName="question_mapping" class="mealbox">
                                    <div *ngFor="let qestionarr of dataForm.get('question_mapping')['controls']; let i=index;"
                                        [formGroupName]="i" class="retrysection">
                                        <p class="customlabel">Retry {{i+1}}</p>
                                        <div formArrayName="retryarray">
                                            <div *ngFor="let question of qestionarr.controls.retryarray.controls; let j=index;"
                                                [formGroupName]="j" class="boxthekeyvalue">
                                                <mat-form-field appearance="outline"
                                                    class="full-width-formfield featureimage"
                                                    *ngIf="(i >= 0 && j >= 0)">
                                                    <mat-label>Select the questions</mat-label>
                                                    <mat-select formControlName="question"
                                                        (selectionChange)="selectedOptions($event.value,j,i)" required
                                                        #singleSelect>
                                                        <mat-option>
                                                            <ngx-mat-select-search [clearSearchInput]="false"
                                                                [placeholderLabel]="'Search Item'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                [formControl]="searchmat" ngModel
                                                                (ngModelChange)="modelChanged($event,(i*quesno + j))"
                                                                ngDefaultControl>
                                                                <div class="mat-select-search-custom-header-content">
                                                                    <i class="material-icons">
                                                                        search
                                                                    </i></div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option class="camelcased"
                                                            *ngFor="let ques of filteredQuizes[i*quesno + j] | async ; let k=index;"
                                                            [disabled]="disableOptions(ques.id,i)" [value]="ques.id">
                                                            {{ques.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <!-- <mat-select formControlName="question"
                                                    (selectionChange)="selectedOptions($event.value,j,i)" required>
                                                    <mat-option class="camelcased"
                                                        *ngFor="let ques of allquestions"
                                                        [disabled]="disableOptions(ques.id,i)" [value]="ques.id">
                                                        {{ques.title}}
                                                    </mat-option>
                                                </mat-select> -->
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Pass Score</mat-label>
                                        <mat-select placeholder="Pass Score" formControlName="pass_score"
                                            [disabled]="isretryDisabled()" required>
                                            <mat-option *ngFor="let score of passscorearray" [value]="score">
                                                {{score}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="clearfix"><br /></div>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="col-md-12 leftpad40">
                            <div class="clearfix"><br /></div>
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Quiz</button>
                        </div> -->
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Quiz</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="iphonetitle">{{dataForm?.value?.title}}</div>
                                    <div class="iphone-subtitle">{{dataForm?.value?.detailed_text}}</div>
                                </div>
                            </div>

                            <div class="timeline" *ngIf="!(dataForm?.value.pass_score == '')">
                                <div class="content" *ngIf="!(dataForm?.value.total_question == '')">
                                    <span class="dot">1</span>
                                    <p>Quiz has {{dataForm?.value?.total_question}} Questions </p>
                                </div>
                                <div class="content" *ngIf="!(dataForm?.value.pass_score == '')">
                                    <span class="dot">2</span>
                                    <p>Get {{dataForm?.value?.pass_score}} to pass quiz</p>
                                </div>
                                <div class="content" *ngIf="!(dataForm?.value.total_question == '')">
                                    <span class="dot last-dot">3</span>
                                    <p>Learn with detailed explanation</p>
                                </div>
                            </div>
                            <div class="start-quiz-btn text-center">
                                <button mat-flat-button color="primary"
                                    *ngIf="!(dataForm?.value.total_question == '')">Start Quiz</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>