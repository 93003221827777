import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-relesenote',
  templateUrl: './relesenote.component.html',
  styleUrls: ['./relesenote.component.scss']
})
export class RelesenoteComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }
  ids: Array<String> = ['one', 'two', 'three', 'four']


  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('releasenote-scroll');
  }
 
  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('releasenote-scroll');
  }
  

}
