import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SharedModule } from './../shared/shared.module';
import { BroadcastRoutingModule } from './broadcast/broadcast.routing.module';
import { ViewbroadcastComponent } from './broadcast/viewbroadcast/viewbroadcast.component';
import { AddhopitalisationComponent } from './components/addhopitalisation/addhopitalisation.component';
import { AddmedicineComponent } from './components/addmedicine/addmedicine.component';
import { AddprescriptionComponent } from './components/addprescription/addprescription.component';
import { AddreminderComponent } from './components/addreminder/addreminder.component';
import { AddsymptomsComponent } from './components/addsymptoms/addsymptoms.component';
import { AdversedrugaddmodalComponent } from './components/adversedrugaddmodal/adversedrugaddmodal.component';
import { AEMailComponent } from './components/adversedrugaddmodal/aemail/aemail.component';
import { DeleteAEComponent } from './components/adversedrugaddmodal/delete-ae/delete-ae.component';
import { EditAdverseEffectComponent } from './components/adversedrugaddmodal/edit-adverse-effect/edit-adverse-effect.component';
import { AlltriggersComponent } from './components/alltriggers/alltriggers.component';
import { CareyChatComponent } from './components/careychat/careychat.component';
import { ChatbotmodalComponent } from './components/chatbotmodal/chatbotmodal.component';
import { DeeplinkComponent } from './components/deeplink/deeplink.component';
import { DiaryLogDialogComponent } from './components/diarylog/diarylog/diarylogdialog.component';
import { InfusionsiteaddComponent } from './components/infusionsiteadd/infusionsiteadd.component';
import { LabreportdialogComponent } from './components/labreportdialog/labreportdialog/labreportdialog.component';
import { MealbreakupComponent } from './components/mealbreakup/mealbreakup/mealbreakup.component';
import { MedicalhistoryaddComponent } from './components/medicalhistoryadd/medicalhistoryadd.component';
import { EditmedicationComponent } from './components/medicationadd/editmedication/editmedication.component';
import { MedicationaddComponent } from './components/medicationadd/medicationadd.component';
import { PaymentlinkComponent } from './components/paymentlink/paymentlink.component';
import { EdithcpumpComponent } from './components/pump/edithcpump/edithcpump.component';
import { PumpaddComponent } from './components/pump/pumpadd/pumpadd.component';
import { EditrouteofadminComponent } from './components/routeofadminadd/editrouteofadmin/editrouteofadmin.component';
import { RouteofadminaddComponent } from './components/routeofadminadd/routeofadminadd.component';
import { ShowmodalComponent } from './components/showmodal/showmodal.component';
import { LogtagdialogComponent } from './components/tabpartials/userdetail/logtagdialog/logtagdialog.component';
import { UploadprescriptionaldialogComponent } from './components/uploadprescriptionaldialog/uploadprescriptionaldialog.component';
import { UploadprescriptionaldialogRoutingModule } from './components/uploadprescriptionaldialog/uploadprescriptionaldialog.routing.module';
import { HcalertsRoutingModule } from './hcalerts/hcalerts.routing.module';
import { HcprofileRoutingModule } from './hcprofile/hcprofile.routing.module';
import { HcsettingsRoutingModule } from './hcsettings/hcsettings.routing.module';
import { HctagsRoutingModule } from './hctags/hctags.routing.module';
import { HctasklistRoutingModule } from './hctasklist/hctasklist-routing.module';
import { HealthcoachRoutingModule } from './healthcoach-routing.module';
import { HomeRoutingModule } from './home/home.routing.module';
import { ContextService as HealthCoachContextService } from './services/context.service';

import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DeviceDialogComponent } from './components/tabpartials/userdetail/olddevice/olddevice.component';
import { sendUserBroadcastModule } from './components/senduserbrodcast/senduserbrodcast/senduserbroadcast.module';
import { userTransferModule } from './components/usertransfer/usertransfer/usertransfer.module';
import { HctasklistModule } from './hctasklist/hctasklist.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CaregiverhistoryComponent } from './components/tabpartials/caregiverhistory/caregiverhistory.component';
import { PolicyModule } from './policy/policy.module';
import { DietPlanpdfreportRoutingModule} from './components/tabpartials/userdetail/dietplanpdfreport/dietplanpdfreport.routing.module';
//import { HighchartsChartModule } from 'highcharts-angular';
//import { HcleftnavComponent as HealthCoachHcleftnavComponent } from './shared/hcleftnav/hcleftnav.component';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'YYYY-MM-DD HH:mm', /* <---- Here i've rewrited the format */
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MM YYYY',
};

@NgModule({
  declarations: [
    EditrouteofadminComponent,
    EdithcpumpComponent,
    EditAdverseEffectComponent,
    AEMailComponent,
    DeleteAEComponent,
    CaregiverhistoryComponent,
  ],
  imports: [
    CommonModule,
   // HighchartsChartModule,
    HealthcoachRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HomeRoutingModule,
    BroadcastRoutingModule,
    HcalertsRoutingModule,
    HcprofileRoutingModule,
    HcsettingsRoutingModule,
    HctagsRoutingModule,
    HctasklistRoutingModule,
    HctasklistModule,
    UploadprescriptionaldialogRoutingModule,
    // sendUserBroadcastModule,
    // userTransferModule,
    SharedModule.forRoot(),
    PickerModule,
    PolicyModule,
    DietPlanpdfreportRoutingModule
  ],
  //exports: [HighchartsChartModule],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

  providers: [
    HealthCoachContextService,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ],
  entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent,
    AddprescriptionComponent,
    PumpaddComponent,
    MedicationaddComponent,
    MedicalhistoryaddComponent,
    RouteofadminaddComponent,
    InfusionsiteaddComponent,
    AdversedrugaddmodalComponent,
    EditmedicationComponent,
    AddhopitalisationComponent,
    DeviceDialogComponent,
    CaregiverhistoryComponent
  ]
})
export class HealthcoachModule { }
