import { filter } from 'rxjs/operators';
import { isString } from 'util';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PersistenceService } from 'angular-persistence';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable, throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { listAnimation } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../content/services/contentservice/contentservice.service';
import { SelectedUserinterface } from '../../models/selecteduserinterface';
import { Userlistinterface } from '../../models/userlistinterface';
import { ContextService } from '../../services/context.service';
import { SocketService } from '../../services/socket/socket.service';
import { UserlistService } from '../../services/userlist/userlist.service';
import * as _ from 'lodash';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
declare var $: any;

@Component({
  selector: 'app-userlistnavbar',
  templateUrl: './userlistnavbar.component.html',
  styleUrls: ['./userlistnavbar.component.scss'],
  animations: [listAnimation]
})

export class UserlistnavbarComponent implements OnInit {

  public displayedColumns: string[] = ['select', 'name'];//'select', 
  clickeduser_id = 0;
  callintervalflag: boolean = false;
  public dataSource: UserListDataSource;
  public selection = new SelectionModel<Userlistinterface>(true, []);
  public highlightedRow: SelectedUserinterface;
  public title$: Subject<string> = this.appContext.moduleTitle;
  public userList: Observable<any>;
  public filterValue: string = "";
  public filterType: Array<string> = [];
  public filterTypeString: string = "All";
  public chatStatusFilterValue: string = "";
  public chatMessageStatus: any;
  public chatHtmlStatus = "Chat Filter";
  public socket: any;
  public filterModel: any = { "Active": false, "Lagging": false, "DayOfProgram": false, "Freemium": false, "Expired": false, "program_ended": false };
  public filterChips: Array<string> = [];
  public lastStatusArray: Array<any> = [];
  public lastStatusReceived: boolean = false;
  public hideAllFilters: boolean = false;
  public disableChatStatusFilter: boolean = false;
  public disableFilterExpiredCase: boolean = false;
  public tagData: any;
  public alltagusers: any;
  public switchcaseval: any = 'default';
  public counter: any = 1;
  public d: any;
  public n: any;
  public ferrerHC: any;
  public hideClients: boolean = false;
  public localStoredData: any;
  public patientList: any = [];
  patientData: any = [];
  progresspatendId: any = [];
  progressShare: any = [];
  displayBtn: string;
  isCall!: boolean;
  public totalPatientSelected = 0;
  isChat!: boolean;
  isNormalChat!: boolean;
  progressPatients: any = [];
  public isLoadingdata: boolean = false;
  totalPatientCountForTask: any;
  selectionArr: any = [];
  programDays: any = [{ "value": "Users who are within 0-24 hours of starting the program", "id": ["0", "1"] },
  { "value": "Users on day 1-3 of the program", "id": ["1", "3"] },
  { "value": "Users on day 4-7 of the program", "id": ["4", "7"] },
  { "value": "Users on day 8-14 of the program", "id": ["8", "14"] },
  { "value": "Users on day 15-21 of the program", "id": ["15", "21"] },
  { "value": "Users on day 22-30 of the program", "id": ["22", "30"] },
  { "value": "Users on day 31-60 of the program", "id": ["31", "60"] },
  { "value": "Users on day 61-90 of the program", "id": ["61", "90"] },
  { "value": "Users on day 91-120 of the program", "id": ["91", "120"] }
  ]

  gestationalAge: any = [
    { "value": "1 Week", "id": "1" },
    { "value": "2 Week", "id": "2" },
    { "value": "3 Week", "id": "3" },
    { "value": "4 Week", "id": "4" },
    { "value": "5 Week", "id": "5" },
    { "value": "6 Week", "id": "6" },
    { "value": "7 Week", "id": "7" },
    { "value": "8 Week", "id": "8" },
    { "value": "9 Week", "id": "9" },
    { "value": "10 Week", "id": "10" },
    { "value": "11 Week", "id": "11" },
    { "value": "12 Week", "id": "12" },
    { "value": "13 Week", "id": "13" },
    { "value": "14 Week", "id": "14" },
    { "value": "15 Week", "id": "15" },
    { "value": "16 Week", "id": "16" },
    { "value": "17 Week", "id": "17" },
    { "value": "18 Week", "id": "18" },
    { "value": "19 Week", "id": "19" },
    { "value": "20 Week", "id": "20" },
    { "value": "21 Week", "id": "21" },
    { "value": "22 Week", "id": "22" },
    { "value": "23 Week", "id": "23" },
    { "value": "24 Week", "id": "24" },
    { "value": "25 Week", "id": "25" },
    { "value": "26 Week", "id": "26" },
    { "value": "27 Week", "id": "27" },
    { "value": "28 Week", "id": "28" },
    { "value": "29 Week", "id": "29" },
    { "value": "30 Week", "id": "30" },
    { "value": "31 Week", "id": "31" },
    { "value": "32 Week", "id": "32" },
    { "value": "33 Week", "id": "33" },
    { "value": "34 Week", "id": "34" },
    { "value": "35 Week", "id": "35" },
    { "value": "36 Week", "id": "36" },
    { "value": "37 Week", "id": "37" },
    { "value": "38 Week", "id": "38" },
    { "value": "39 Week", "id": "39" },
    { "value": "40 Week", "id": "40" },
    { "value": "40+ Week", "id": "40+" },
    { "value": "Not Pregnant", "id": "NA" },
  ]
  isGestationalAge: boolean;
  isProgramFilter: boolean;
  isClient: any;

  constructor(private ContentserviceService: ContentserviceService,
    public socketService: SocketService,
    private appContext: ContextService,
    private userListService: UserlistService,
    private persistenceService: PersistenceService,
    private systemService: SystemService,
    public commonService: CommonserviceService
  ) {
  }


  async ngOnInit() {
    let data = {
      "callintervalflag": false
    }
    localStorage.setItem('callintervalflag', JSON.stringify(data))

    this.displayBtn = localStorage.getItem('hcSelectedType')

    if (this.displayBtn == 'call' || this.displayBtn == 'whatsapp_call') {
      this.isCall = true;
      this.isChat = false;
      this.isNormalChat = false;
      this.getProgress();
    }
    else if (this.displayBtn == 'patient_age' || this.displayBtn == 'activity' || this.displayBtn == 'clinical_parameter' || this.displayBtn == 'data_parameters') {
      this.isNormalChat = true;
      this.isChat = false;
      this.isCall = false;
      this.getProgress();
    }
    else if ((this.displayBtn == 'chat' || this.displayBtn == 'custom_broadcast' || this.displayBtn == 'whatsapp_chat')) {
      this.isChat = true
      this.isCall = false;
      this.isNormalChat = false;
      this.getProgress();
    }

    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.dataSource = new UserListDataSource(this.userListService, this.socketService, this.ContentserviceService, this.persistenceService, this, this.appContext, this.commonService);
    this.getAlltags();

    // this.dataSource.getUserList(1, [], '');
    /**Subscribe message service */
    this.socketService.newMessages.subscribe(data => {

      if (data.type === 'conversation' || data.type === 'payment' || data.type === 'deeplink' || data.type === 'insightChat') {
        let d = {
          "callintervalflag": false
        }
        localStorage.setItem('callintervalflag', JSON.stringify(d))
        let filter;
        if (this.dataSource.filters.length > 0) {
          filter = this.dataSource.filters;
        } else if (this.dataSource.programfilters.length > 0) {
          filter = this.dataSource.programfilters;
        } else {
          filter = [];
        }
        this.dataSource.getUserList(1, filter, '');

        this.socketService.getUserList(1, filter, '').toPromise().then(data1 => {
          this.dataSource.prioritySort(data1)
          // for (let i = 0; i < data1.data.metadata.length;) {
          //    //  User1 is in position 2 in this.dataSource.userListFilterData
          //         //  User1 is in position 3 in API response data1
          //   let index = _.findIndex(this.dataSource.userListFilterData, function (o) { return  o.user_id == data1.data.metadata[i].user_id });
          //   this.dataSource.userListFilterData[i].chatMetadata[0].last_message_details.id = data1.data.metadata[i].chatMetadata[0].last_message_details.id;

          //   if ((data1.data.metadata[i].chatMetadata[0].last_sender)?.charAt(0) == 'U') {
          //     this.dataSource.userListFilterData[i].chatMetadata[0].unread_count = data1.data.metadata[i].chatMetadata[0].unread_count;
          //     // if (this.dataSource.userListFilterData[index].chatMetadata[0].unread_count != data1.data.metadata[index].chatMetadata[0].unread_count) {
          //     //   this.dataSource.userListFilterData[i].chatMetadata[0].unread_count = data1.data.metadata[i].chatMetadata[0].unread_count;
          //     // }
          //     i++;
          //   } else {
          //     i++;
          //   }
          // }
        })

        //change to show unread massage
        if ((this.dataSource.userListFilterData as any).filter(a => data.participants.includes('U' + a.user_id))[0]) {
          (this.dataSource.userListFilterData as any).filter(a => data.participants.includes('U' + a.user_id))[0].chatMetadata[0].last_message_details.body = data.body;
          (this.dataSource.userListFilterData as any).filter(a => data.participants.includes('U' + a.user_id))[0].chatMetadata[0].last_sender = data.sender;
        } else {

          if (data.sender.includes('U')) {
            let patientName = data.senderName;
            patientName = patientName.trim().toLowerCase();
            this.socketService.getUserList(1, this.dataSource.filters, patientName)
              .subscribe(userData => {
                let patientData = userData.data.metadata[0].chatMetadata[0];
                let is_expired = patientData.is_expired;
                let days = patientData.days;
                let is_program_completed = patientData.is_program_completed;
                let dt = {
                  "user_id": parseInt(data.sender.replace('U', '')),
                  "name": data.senderName,
                  "chatMetadata": [{
                    "conv_id": data.conversationId,
                    "unread_count": 1,
                    "self_read_cursor": data.id,
                    "self_delivery_cursor": data.id,
                    "last_sender": data.sender,
                    "last_message_at": data.timestamp,
                    "user_id": parseInt(data.sender.replace('U', '')),
                    "name": data.senderName,
                    "patient_created_at": data.timestamp,
                    "read_cursor": data.id,
                    "delivery_cursor": data.id,
                    "last_message_details": { id: data.id, body: data.body, created_at: data.timestamp },
                    "user": data.sender,
                    "status": "online",
                    "last_seen": null,
                    "days": days,
                    "is_expired": is_expired,
                    "is_program_completed": is_program_completed
                  }],
                  "last_activity_at": data.timestamp
                }
                this.dataSource.userListFilterData.unshift(dt);
                var from = (this.dataSource.userListFilterData as any).findIndex(function (item, i) {
                  return data.participants.includes('U' + item.user_id)
                });
                var oldselect = this.appContext.selectedChatUserRowData.getValue();
                var element = (this.dataSource.userListFilterData as any)[from];
                (this.dataSource.userListFilterData as any).splice(from, 1);
                (this.dataSource.userListFilterData as any).splice(0, 0, element);
                this.dataSource.userListSubject.next(this.dataSource.userListFilterData);
                if (oldselect != null) {
                  this.handleRowSelection(oldselect);
                }
              });
          }
        }

      }

      this.n = new Date().getTime();

      if (data.type == "statusCursor" && data.value == 1) {

        if ((this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0]) {
          (this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0].chatMetadata[0].status = 'online';
          (this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0].status = 'online';
          (this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0].chatMetadata[0].last_seen = this.n;
          // this.sortChatList(this.dataSource.userListFilterData);
          this.dataSource.prioritySort(this.dataSource.userListFilterData);
        } else {
          let filter;
          if (this.dataSource.filters.length > 0) {
            filter = this.dataSource.filters;
          } else if (this.dataSource.programfilters.length > 0) {
            filter = this.dataSource.programfilters;
          } else {
            filter = [];
          }
          this.dataSource.newpage = true
          this.dataSource.getUserList(1, filter, '');
        }
      }
      if (data.type == "statusCursor" && data.value == 0) {
        if ((this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0]) {
          (this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0].chatMetadata[0].status = 'offline';
          (this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0].status = 'offline';
          (this.dataSource.userListFilterData as any).filter(a => data.userId == 'U' + a.user_id)[0].chatMetadata[0].last_seen = this.n;
          // this.sortOfflineChatList(this.dataSource.userListFilterData);
          this.dataSource.prioritySort(this.dataSource.userListFilterData);
        }
      }
      if (data.type == "paymentCursor") {
        if ((this.dataSource.userListFilterData as any).filter(a => data.conversationId == a.chatMetadata[0].conv_id)[0]) {
          var dataval = data.value == 1 ? 'Payment Successful' : 'Payment Failed';
          (this.dataSource.userListFilterData as any).filter(a => data.conversationId == a.chatMetadata[0].conv_id)[0].chatMetadata[0].last_message_details.body = dataval;
        }
      }

      if (data.type === "readCursor") {
        if ((this.dataSource.userListFilterData as any).filter(a => data.conversationId == a.chatMetadata[0].conv_id)[0]) {
          (this.dataSource.userListFilterData as any).filter(a => data.conversationId == a.chatMetadata[0].conv_id)[0].chatMetadata[0].read_cursor = data.value;
          this.dataSource.prioritySort(this.dataSource.userListFilterData);
        }
      }

      if (data.type === "deliveryCursor") {
        if ((this.dataSource.userListFilterData as any).filter(a => data.conversationId == a.chatMetadata[0].conv_id)[0]) {
          (this.dataSource.userListFilterData as any).filter(a => data.conversationId == a.chatMetadata[0].conv_id)[0].chatMetadata[0].delivery_cursor = data.value;
        }
      }

    });

    this.appContext.startMultiUserSelection.next(false);
    this.appContext.isSelectedChatUserIsBot.next(true);
    this.ferrerHC = localStorage.getItem('scarletuserid');
    this.hideClients = this.ferrerHC == "3972" || this.ferrerHC == "3971";

    // if (ferrerHCchecked){
    //   
    //   
    //   this.hideClients == true
    // }
    // this.ferrerHC2 = localStorage.getItem('scarletuserid');


    // this.patientListData()
    let PatientList = this.patientData.forEach(element => {
      this.dataSource.userListFilterData.filter(data => {
        data.userListFilterData.name = element.name;
      })
    });


    let notificationClickPatientName = localStorage.getItem("notifyPatientName");
    let selectedNotification = localStorage.getItem("selectedNotification");

    await this.sleep(1000)
    if (notificationClickPatientName || selectedNotification) {
      //this.SearchPatient = notificationClickPatientName;      
      this.applyFilter(notificationClickPatientName);
      await this.sleep(2000)
    }
  }

  sortChatList(data) {
    data = data.sort((a: any, b: any) => {
      if (b['unread_count'] > 0 && a['unread_count'] > 0) {
        return b['last_activity_at'] - a['last_activity_at']
      }
    });
    data.sort((a: any, b: any) => {
      return a['status'] > b['status'] && (b['unread_count'] == 0 && a['unread_count'] == 0) ? -1 : 1;
    });
    this.dataSource.userListSubject.next(data);
  }
  bubbleSort(array) {
    var done = false;
    while (!done) {
      done = true;
      for (var i = 1; i < array.length; i += 1) {
        if (array[i - 1]['last_activity_at'] < array[i]['last_activity_at']) {
          done = false;
          var tmp = array[i - 1];
          array[i - 1] = array[i];
          array[i] = tmp;
        }
      }
    }
    return array;
  }

  sortOfflineChatList(data) {
    data = data.sort((a: any, b: any) => {
      if (b['unread_count'] > 0 && a['unread_count'] > 0) {
        return b['last_activity_at'] - a['last_activity_at']
      }
    });
    data.sort((a: any, b: any) => {
      return a['status'] > b['status'] && (b['unread_count'] == 0 && a['unread_count'] == 0) ? -1 : 1;
    });
    data.sort((a: any, b: any) => {
      if (b['unread_count'] == 0 && a['unread_count'] == 0) {
        return b['last_activity_at'] - a['last_activity_at']
      }
    });
    this.dataSource.userListSubject.next(data);
  }


  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  checkprogress(id) {

    let data = this.progressPatients.includes(id);
    //  
    return data;

  }
  checkchecked(id) {
    let data = this.progressPatients.includes(id);
    return data;

  }
  getProgress() {
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      hc_id: localStorage.getItem('scarletuserid'),
    }
    this.commonService.getProgress(postData, 'getTaskProgress')
      .subscribe(
        (res: any) => {
          this.totalPatientCountForTask = res.data[0].patient_list.length
          if (res.data[0].progress.length > 0) {
            this.progressPatients = res.data[0].progress[0].patient_completed;
            // this.progressShare.push(this.progressPatients);
            localStorage.setItem("progressPatients", JSON.stringify(this.progressPatients));
          }
          for (let i = 0; i < res.data[0]; i++) {
            if (!this.progresspatendId.includes(res.data[0].progress[i]?.patient_completed[0])) {
              this.progresspatendId.push(res.data[0].progress[i]?.patient_completed[0]);
            }
          }
          // this.getAllPatients();
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  patientListData() {
    this.userListService.current___patientData.subscribe(
      (response: any) => {
        if (response == null) {

        } else {
          this.patientData = response;

        }
      },
      (error: any) => {

      },
      () => { }
    )
  }

  changeName(ch) {
    if (ch == 'respond') {
      return 'Response Due'
    }
    else if (ch == 'waiting') {
      return 'No Response Due'
    }
    else if (ch == 'initiate') {
      return 'First Response Due'
    }
    else {
      return ch
    }
  }
  ngAfterViewInit() {
    /**Get user list after view init */
    //this.socketService.getConversation();
    this.counter = 1;
    this.dataSource.getUserList(1, [], '');
    this.handleUserSelectionFromNotificationPanel(JSON.parse(sessionStorage.getItem('onSelectNotifyUser')));
    /**Subscribe for userListChatStatusCollection events */
    // this.appContext.userListChatStatusCollection.subscribe(data => {
    //   /**Update row sequencing also */
    //   this.dataSource.makeDataSequence(data);
    //   /**Assign status now */
    //   if (this.dataSource.userListFilterData)
    //     this.chatMessageStatus = this.applyStatusExcludeUserFilter(this.dataSource.userListFilterData, data);
    // });
    // /**Link last message status */
    // this.appContext.lastMessageCollection.subscribe(data => {
    //   if (data != null) {
    //     this.lastStatusArray = data;
    //     let xxScope = this;
    //     setTimeout(function () {
    //       xxScope.lastStatusReceived = true;
    //     }, 3500);

    //   }
    // });
    // /**Clear selection */
    this.appContext.multiUserSelectionUsers.subscribe(data => {
      if (data == null || data == "") {
        this.selection.clear()
      }
    });
    // /**User new message row move above according to thieit timestamps */
    // this.appContext.userListSequenceCollection.subscribe(data => {
    //   /**Update row sequencing also */o
    //   this.dataSource.makeDataSequence(data);
    // });
    // /**Hide the filters on multi user selection */
    // this.appContext.startMultiUserSelection.subscribe(data => {
    //   if (data) {
    //     this.hideAllFilters = true;
    //   } else {
    //     this.hideAllFilters = false;
    //   }
    //  });`
  }

  applyStatusExcludeUserFilter(userListDataArray: any, statusArray: any) {
    let userExcludeListDataTemp = userListDataArray.filter(user => { return (user["Onboarding"] || user["Expired"]) });
    let userExcludeListData = userExcludeListDataTemp.map(user => { return user.id });
    for (var prop in statusArray) {
      statusArray[prop] = statusArray[prop].filter(user => { return (!(userExcludeListData.indexOf(user) > -1)) });
    }
    return statusArray;

  }
  clickpatient(element) {
    localStorage.removeItem("notifyPatientName");
    localStorage.removeItem("selectedNotification");
    let d = {
      "callintervalflag": false
    }
    localStorage.setItem('callintervalflag', JSON.stringify(d))
    localStorage.setItem('selectedPatientId', element.user_id);
    localStorage.setItem('selectedPatientName', element.name);

    let uid = localStorage.getItem('scarletuserid');
    let swithchId = sessionStorage.getItem('switchId');

    setInterval(() => {
      let localdata = JSON.parse(localStorage.getItem('userListFilterData'));
      // 
      if (localdata.length > 0) {
        var newArray = localdata.filter(function (el) {
          return el.user_id == element.user_id;
        });
      }
      let flag = JSON.parse(localStorage.getItem('callintervalflag'))
      //  
      if (flag.callintervalflag && element.chatMetadata[0].last_message_details.body != newArray[0].chatMetadata[0].last_message_details.body) {
        //  
        element.chatMetadata[0].last_message_details.body = newArray[0].chatMetadata[0].last_message_details.body;
      }
    }, 2000)
    if (!swithchId) {
      let readrec = { type: 'readCursor', conversationId: element.chatMetadata[0].conv_id, userId: 'H' + uid, value: element.chatMetadata[0].last_message_details.id, participants: ['H' + uid, element.chatMetadata[0].last_sender] };
      this.socketService.setCursor(readrec).subscribe()
      this.clickeduser_id = element.user_id;
      if (element.chatMetadata[0]?.unread_count) {
        element.chatMetadata[0].unread_count = 0;
        element.unread_count = 0;
      }
    }

    let data = [];
    data.push(element);
    this.dataSource.prioritySort(this.dataSource.userListFilterData);
    this.userListService.change___selectedpatient(data);
    if (this.switchcaseval == 'default') {
      this.selection.toggle(element);
      this.handleRowSelection(element);
    }

  }

  handleMultiRowSelection1(row: SelectedUserinterface, event) {
    if (this.displayBtn == 'activity' || this.displayBtn == 'clinical_parameter' || this.displayBtn == 'data_parameters') {
      //   if (this.selection.isSelected(row)) {
      //     this.selection.deselect(row);
      //     let index = this.selectionArr.findIndex(function(o){
      //     return o.user_id === row.id;
      //   })
      // if (index !== -1) this.selectionArr.splice(index, 1);
      //     this.totalPatientSelected--;
      //     localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
      //   } else {
      //     localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
      //     this.totalPatientSelected++;
      //     this.dataSource.getPateintFullData(row);
      //     // this.selection.select(row);
      //     // this.selection.select(this.dataSource.metaObject);

      //   }
      if (event.checked === true) {
        this.totalPatientSelected++;
        this.dataSource.getPateintFullData(row);
        localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
      } else {
        let index = this.selectionArr.findIndex(function (o) {
          return o.user_id === row.id;
        })
        if (index !== -1) this.selectionArr.splice(index, 1);
        this.totalPatientSelected--;
        localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': false }));
      }
      if (this.totalPatientSelected === 0) {
        this.totalPatientSelected = 0;
        //localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': false }));
        return;
      }
    } else {
      // localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': false }));
      // if (this.selection.isSelected(row)) {
      //   this.selection.deselect(row);
      //   let index = this.selectionArr.findIndex(function(o){
      //     return o.user_id === row.id;
      //   })
      // if (index !== -1) this.selectionArr.splice(index, 1);
      //   this.totalPatientSelected--;
      // } else {
      //   this.totalPatientSelected++;
      //   this.dataSource.getPateintFullData(row);
      // }
      if (event.checked === true) {
        this.totalPatientSelected++;
        this.dataSource.getPateintFullData(row);
      } else {
        let index = this.selectionArr.findIndex(function (o) {
          return o.user_id === row.id;
        })
        if (index !== -1) this.selectionArr.splice(index, 1);
        this.totalPatientSelected--;
      }
    }
    // this.handleRowSelection(row);
  }




  getAlltags(): void {
    this.userListService.getalltagsForHc().pipe(
      catchError((error: any) => {
        if (error.error.message.includes("204")) {
          this.tagData = [];
        } else {
          return throwError(error);
        }
      }),
      finalize(() => { })
    ).subscribe(data => {
      data = (data as any).data;

      this.tagData = data;
    });
  }
  applyFilter(filterValue: string) {
    //  let d= this.dataSource.userListFilterData.filter(e => { return e.user_id =='75118'});
    //  
    //  this.dataSource.userListFilterData = [];
    //  d.forEach(e=>{
    //   
    //   this.dataSource.userListFilterData.push(e);
    //  })
    //  

    filterValue = filterValue.trim(); // Remove whitespace
    this.filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.loadmore = true;
    this.counter = 1;
    this.dataSource.newpage = true;
    this.dataSource.userListFilterData = [];
    this.dataSource.getUserList(1, this.dataSource.filters, this.filterValue)
    // await this.sleep(500)
  }

  changeChatStatusFilter(statusType: string) {
    //statusType = statusType.trim();
    let xStatus = "";
    if (statusType == "initiate") {
      xStatus = "First Response Due";
    } else if (statusType == "respond") {
      xStatus = "Response Due";
    } else if (statusType == "waiting") {
      xStatus = "No Response Due";
    } else if (statusType == "All") {
      xStatus = "No Filter";
    }
    this.chatHtmlStatus = xStatus;//statusType.charAt(0).toUpperCase() + statusType.slice(1);
    this.chatStatusFilterValue = statusType;
    this.dataSource.applyChatStatusFilter(statusType, this.filterType);
  }

  getCounts(inputKey, isClient?: boolean): number {
    isClient = isClient ? isClient : false;
    return this.dataSource.getCounts(inputKey, isClient);
  }

  removeChatStatusFilter(): void {
    this.changeChatStatusFilter("All");
  }

  removeFilter(filterModel: string): void {
    if (this.isGestationalAge) {
      for (let i = 0; i < this.dataSource.gestationalAgefilters.length; i++) {
        for (let j = 0; i < this.dataSource.gestationalAgefilters[i].value.length; j++) {
          if ((this.dataSource.gestationalAgefilters[i].value[j] + " Week") === filterModel) {
            const index = this.dataSource.gestationalAgefilters[i].value[j].indexOf(this.dataSource.gestationalAgefilters[i].value[j]);
            if (index > -1) { // only splice array when item is found
              this.dataSource.gestationalAgefilters.splice(index, 1); // 2nd parameter means remove one item only
            }
            this.changeFilter(filterModel, false, '', false, true);
            return;
          } else if ((this.dataSource.gestationalAgefilters[i].value[j]) === "NA" && filterModel === "Not Pregnant") {
            const index = this.dataSource.gestationalAgefilters[i].value[j].indexOf(this.dataSource.gestationalAgefilters[i].value[j]);
            if (index > -1) { // only splice array when item is found
              this.dataSource.gestationalAgefilters.splice(index, 1); // 2nd parameter means remove one item only
            }
            this.changeFilter(filterModel, false, '', false, true);
            return;
          }
        }
      }
    } else
      if (this.isProgramFilter) {
        let obj = this.programDays.find(o => o.value === filterModel);
        for (let i = 0; i < this.dataSource.programfilters.length; i++) {
          for (let j = 0; j < this.dataSource.programfilters[i].value.length; j++) {
            if (obj.id[1] === this.dataSource.programfilters[i].value[j][1]) {
              const index = this.dataSource.programfilters[i].value.indexOf(this.dataSource.programfilters[i].value[j]);
              this.dataSource.programfilters[i].value.splice(index, 1);
              this.changeFilter(filterModel, false, '', true, false);
              return;
            }
          }
        }
      } else
        if (this.isClient) {
          let obj = this.dataSource.clientList.find(o => o.title === filterModel);
          for (let i = 0; i < this.dataSource.filters.length; i++) {
            for (let j = 0; j < this.dataSource.filters[i].value.length; j++) {
              if (parseInt(obj.id) === this.dataSource.filters[i].value[j]) {
                const index = this.dataSource.filters[i].value.indexOf(this.dataSource.filters[i].value[j]);
                this.dataSource.filters[i].value.splice(index, 1);
                this.changeFilter(filterModel, true, '', false, false);
                return;
              }
            }
          }
        } else {
          let obj = this.tagData.find(o => o.title === filterModel);
          for (let i = 0; i < this.dataSource.filters.length; i++) {
            for (let j = 0; j < this.dataSource.filters[i].value.length; j++) {
              if ((obj.id) === this.dataSource.filters[i].value[j]) {
                const index = this.dataSource.filters[i].value.indexOf(this.dataSource.filters[i].value[j]);
                this.dataSource.filters[i].value.splice(index, 1);
                this.changeFilter(filterModel, false, '', false, false);
                return;
              }
            }
          }
        }
  }



  handleExpiredBehaviour(filterModel: any, currentModel: string): boolean {
    let isFilterModelHaveAnything = false;
    let isExpiredFilterHave = false;
    for (let key in filterModel) {
      if (filterModel[key] && key != "Expired") {
        isFilterModelHaveAnything = true;
      }
      if (key == "Expired" && filterModel[key]) {
        isExpiredFilterHave = true;
      }
    }
    if (currentModel == "Expired" && isFilterModelHaveAnything) {
      /**Expired cannot be mingle with others */
      this.systemService.showSnackBar("You cannot use expired status filter with others.", "Ok");
      //prevent default event
      this.filterModel[currentModel] = !this.filterModel[currentModel];
      return false;
    } else if (isExpiredFilterHave && isFilterModelHaveAnything) {
      this.systemService.showSnackBar("All other status filters are disable with expired filter.", "Ok");
      this.disableFilterExpiredCase = true;
      this.disableChatStatusFilter = true;
      return false;
    } else if (isExpiredFilterHave) {
      this.disableFilterExpiredCase = true;
      this.disableChatStatusFilter = true;
      return true;
    } else {
      this.disableFilterExpiredCase = false;
      this.disableChatStatusFilter = false;
      return true;
    }
  }

  changeFilter(filterModel, isClient?: boolean, event?: any, isProgramFilter?: boolean, isGestationalAge?: boolean,): void {
    if (event === true) {
      this.removeFilter(filterModel);
      return;
    } else {
      this.isClient = isClient ? isClient : false;
      this.isProgramFilter = isProgramFilter ? isProgramFilter : false;
      this.isGestationalAge = isGestationalAge ? isGestationalAge : false;
      this.filterModel[filterModel] = !this.filterModel[filterModel];
      let clientList = this.dataSource.clientList.map(client => { return client.title });
      /**Check for expired special condition also */
      if (!this.handleExpiredBehaviour(this.filterModel, filterModel)) {
        return;
      }
      //Make all normal filter staus to false if we select client chip
      if (this.isClient) {
        if (clientList.includes(filterModel)) {
          for (let filterKey in this.filterModel) {
            if (!clientList.includes(filterKey)) {
              this.filterModel[filterKey] = false;
            }
          }
        }
      } else if (this.isProgramFilter) {
        if (this.programDays.some(e => e.value === filterModel)) {
          for (let filterKey in this.filterModel) {
            if (!this.programDays.some(e => e.value === filterKey)) {
              this.filterModel[filterKey] = false;
            }
          }
        }
      } else if (this.isGestationalAge) {
        if (this.gestationalAge.some(e => e.value === filterModel)) {
          for (let filterKey in this.filterModel) {
            if (!this.gestationalAge.some(e => e.value === filterKey)) {
              this.filterModel[filterKey] = false;
            }
          }
        }
      }
      else {
        for (let filterKey in this.filterModel) {
          if (clientList.includes(filterKey)) {
            this.filterModel[filterKey] = false;
          }
          if (this.programDays.some(e => e.value === filterKey)) {
            for (let filterKey in this.filterModel) {
              if (this.programDays.some(e => e.value === filterKey)) {
                this.filterModel[filterKey] = false;
              }
            }
          }
          if (this.gestationalAge.some(e => e.value === filterKey)) {
            for (let filterKey in this.filterModel) {
              if (this.gestationalAge.some(e => e.value === filterKey)) {
                this.filterModel[filterKey] = false;
              }
            }
          }
        }
      }

      if (filterModel == 'amateur' || filterModel == 'average' || filterModel == 'proficient') {
        for (let filterKey in this.filterModel) {
          if (filterModel == 'amateur') {
            this.filterModel['average'] = false;
            this.filterModel['proficient'] = false;
          }
          if (filterModel == 'average') {
            this.filterModel['amateur'] = false;
            this.filterModel['proficient'] = false;
          }
          if (filterModel == 'proficient') {
            this.filterModel['amateur'] = false;
            this.filterModel['average'] = false;
          }
        }
      }
      if (filterModel == 'initiate' || filterModel == 'respond' || filterModel == 'waiting') {
        for (let filterKey in this.filterModel) {
          if (filterModel == 'initiate') {
            this.filterModel['respond'] = false;
            this.filterModel['waiting'] = false;
          }
          if (filterModel == 'respond') {
            this.filterModel['initiate'] = false;
            this.filterModel['waiting'] = false;
          }
          if (filterModel == 'waiting') {
            this.filterModel['initiate'] = false;
            this.filterModel['respond'] = false;
          }
        }
      }
      var tags = [];
      this.tagData.forEach(el => {
        tags.push(el.title);
      });
      for (let filterKey in this.filterModel) {
        if (tags.includes(filterKey)) {
          this.filterModel[filterKey] = false;
        }
      }
      /**Get selected filters */
      this.filterChips = [];

      for (let chip in this.filterModel) {
        if (this.filterModel.hasOwnProperty(chip) && this.filterModel[chip]) {
          this.filterChips.push(chip);
        }
      }
      this.filterType = this.filterChips

      setTimeout(() => {
        if ($('.mat-chip-list-wrapper').height() > 100 && $('.mat-chip-list-wrapper').height() < 130) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 247px)');
        }
        else if ($('.mat-chip-list-wrapper').height() > 60 && $('.mat-chip-list-wrapper').height() < 90) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 207px)');
        }
        else if ($('.mat-chip-list-wrapper').height() > 30 && $('.mat-chip-list-wrapper').height() < 60) {
          $('#tbpatientcont').css('height', 'calc(98vmin - 144px)');
        }
        else {
          $('#tbpatientcont').css('height', 'calc(106vmin - 144px)');
          // $('#tbpatientcont').css('height', 'calc(100vmin - 170px)');
        }
      }, 100);
      this.dataSource.filterType(this.filterType, this.chatStatusFilterValue, isClient, this.filterValue, isProgramFilter, isGestationalAge);
    }
  }

  changeFilterbytag(id, title) {
    var indexval = null
    if (this.dataSource.filters.length > 0 && this.dataSource.filters[0].filter_type !== 'tagFilter') {
      this.filterChips = [];
      this.dataSource.filters = [];
      this.filterModel = [];
      this.dataSource.filters.push({
        "filter_type": "tagFilter",
        "value": [id]
      })

      if (id != null) {
        this.filterChips.push(title);
        this.counter = 1;
        this.dataSource.userListFilterData = [];
        this.dataSource.getUserList(1, this.dataSource.filters, this.filterValue);
      }
      setTimeout(() => {

        if ($('.mat-chip-list-wrapper').height() > 100 && $('.mat-chip-list-wrapper').height() < 130) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 247px)');
        }
        else if ($('.mat-chip-list-wrapper').height() > 60 && $('.mat-chip-list-wrapper').height() < 90) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 207px)');
        }
        else if ($('.mat-chip-list-wrapper').height() > 30 && $('.mat-chip-list-wrapper').height() < 60) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 167px)');
        }
        else {
          $('#tbpatientcont').css('height', 'calc(100vmin - 170px)');
        }
      }, 100);
    } else {
      for (var k = 0; k < this.filterChips.length; k++) {
        if (this.tagData.filter(a => a.title == this.filterChips[k]).length > 0) {
          indexval = k;
        }
      }
      if (indexval != null) {
        this.filterChips.splice(indexval, 1)
      }

      this.dataSource.filters = [];
      this.filterModel = [];
      this.dataSource.filters.push({
        "filter_type": "tagFilter",
        "value": [id]
      })

      if (id != null) {
        this.filterChips.push(title);
        this.counter = 1;
        this.dataSource.userListFilterData = [];
        this.dataSource.getUserList(1, this.dataSource.filters, this.filterValue);
      }
      setTimeout(() => {

        if ($('.mat-chip-list-wrapper').height() > 100 && $('.mat-chip-list-wrapper').height() < 130) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 247px)');
        }
        else if ($('.mat-chip-list-wrapper').height() > 60 && $('.mat-chip-list-wrapper').height() < 90) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 207px)');
        }
        else if ($('.mat-chip-list-wrapper').height() > 30 && $('.mat-chip-list-wrapper').height() < 60) {
          $('#tbpatientcont').css('height', 'calc(100vmin - 167px)');
        }
        else {
          $('#tbpatientcont').css('height', 'calc(100vmin - 170px)');
        }
      }, 100);
    }
  }

  // istagchecked(ide)
  // {
  //   var k = (this.dataSource.filters).filter(a => a.value[0] == ide)[0]
  //   if(k)
  //   {
  //     return true
  //   }
  //   else
  //   {
  //     return false
  //   }
  // }


  /**HTML Fn */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.dataSource.length();
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.userListFilterData.forEach(row => this.selection.select(row));
    /**Now sednd ata to subject */
    this.appContext.multiUserSelectionUsers.next(this.selection.selected);
  }

  handleRowSelection(row: any): void {

    if (this.switchcaseval == 'default') {
      this.highlightedRow = row;
      /**Emit row selection event */
      let previousUser = this.appContext.selectedChatUser.getValue();
      if ((previousUser != row.user_id) && row.user_id != "" && row.user_id != null && (row != this.dataSource.chatBot)) {
        /**Assign row data also */
        this.appContext.selectedChatUserRowData.next(row);
        this.appContext.selectedChatUser.next(row.user_id);
        this.appContext.selectedChatUser1.next(row);
        /**Change watcher */
        this.appContext.changeWatcher.next(true);
        /**Change User Name */
        this.appContext.userName.next(row.name);
        if (row.chatMetadata[0].gestational_age === null) {
          this.appContext.gestationalAge.next(null);
        } else {
          this.appContext.gestationalAge.next(row.chatMetadata[0].gestational_age);
        }
        /**Off chat bot event */
        this.appContext.isSelectedChatUserIsBot.next(false);
      } else if (row == this.dataSource.chatBot) {


        /**Assign row data also */
        this.appContext.selectedChatUserRowData.next(row);

        this.appContext.selectedChatUser.next(row.user_id);
        /**Change User Name */
        this.appContext.userName.next(row.name);
        if (row.chatMetadata[0].gestational_age === null) {
          this.appContext.gestationalAge.next(null);
        } else {
          this.appContext.gestationalAge.next(row.chatMetadata[0].gestational_age);
        }
        /**Off chat bot event */
        this.appContext.isSelectedChatUserIsBot.next(true);
      }
    }

    this.selectionArr.push(row)
    // this.selection.select(row);
    this.appContext.multiUserSelectionUsers1.next(this.selectionArr);
  }

  handleUserSelectionFromNotificationPanel(row): void {

    if (row !== null) {
      var scope = this;
      setTimeout(() => {
        scope.highlightedRow = row;
        scope.selection.toggle(row);
        /**Assign row data also */
        this.appContext.selectedChatUserRowData.next(row);

        this.appContext.selectedChatUser.next(row.user_id);
        /**Change watcher */
        this.appContext.changeWatcher.next(true);
        /**Change User Name */
        this.appContext.userName.next(row.name);
        if (row.chatMetadata[0].gestational_age === null) {
          this.appContext.gestationalAge.next(null);
        } else {
          this.appContext.gestationalAge.next(row.chatMetadata[0].gestational_age);
        }
        /**Off chat bot event */
        this.appContext.isSelectedChatUserIsBot.next(false);

      }, 1000);
      sessionStorage.removeItem('onSelectNotifyUser');
    }
    else {
    }
    //this.appContext.showSelectedNotificationUser.next(null)
    // this.appContext.selectedChatUser.next(rowID);
    // /**Assign row data also */
    // this.appContext.selectedChatUserRowData.next({id:rowID, name:"Moved"});
    // /**Change watcher */
    // this.appContext.changeWatcher.next(true);
    // /**Change User Name */
    // this.appContext.userName.next(rowID.name);
  };

  async startBrodcast() {

    /**DO NOT CHANGE SEQUENCE */
    /**Set Mode */
    await this.appContext.startMultiUserSelectionMode.next("brodcast");
    /**Start Brodcast */
    await this.appContext.startMultiUserSelection.next(true);
    /**Show select column */
    this.switchcaseval = 'selection';
    /**Clear all selection */
    await this.selection.clear();
    /**Remove bot from list and subscribe */
  }

  async getNextPage() {
    this.counter = this.counter + 1;
    this.dataSource.newpage = true;
    if (this.displayBtn == 'call' || this.displayBtn == 'whatsapp_call' || this.displayBtn == 'patient_age' || this.displayBtn == 'activity' || this.displayBtn == 'clinical_parameter' || this.displayBtn == 'data_parameters') {
      await this.dataSource.listPatient(this.counter);
    } else {
      let filter = [];
      if (this.dataSource.filters.length > 0) {
        filter = this.dataSource.filters;
      } else if (this.dataSource.programfilters.length > 0) {
        filter = this.dataSource.programfilters;
      }
      this.dataSource.getUserList(this.counter, filter, this.filterValue);
    }
  }


  startUserTransfer(): void {
    /**DO NOT CHANGE SEQUENCE */
    /**Set Mode */

    this.appContext.startMultiUserSelectionMode.next("transfer");
    /**Start Brodcast */
    this.appContext.startMultiUserSelection.next(true);
    /**Show select column */
    //this.displayedColumns.unshift("select");
    /**Clear all selection */
    this.switchcaseval = 'selection';
    this.selection.clear();
  }

  handleMultiRowSelection(row: SelectedUserinterface, $event) {


    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
    /**Now sednd ata to subject */
    this.appContext.multiUserSelectionUsers.next(this.selection.selected);
    //this.appContext.multiUserSelectionUsers1.next(this.selection.selected);
  }


  stopBrodcastorTransfer(): void {
    /**Stop Brodcast */
    this.appContext.startMultiUserSelection.next(false);
    /**Remove Mode */
    this.appContext.startMultiUserSelectionMode.next(null);
    /**Hide select column */
    this.switchcaseval = 'default';
    /**Add bot back to list */
    //this.dataSource.userListSubject.next(this.dataSource.userListFilterData);
  }

}

export class UserListDataSource implements DataSource<Userlistinterface> {

  public userListSubject = new BehaviorSubject<Userlistinterface[]>(null);
  public userListFilterData: any = [];
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loadingError$ = new Subject<boolean>();
  public loading$ = this.loadingSubject.asObservable();
  public dummyObs = new Observable<any>();
  public chatBot = { id: 'notepadBot', name: 'Scarlet Bot' };
  public clientList: any;
  public filters: any = [];
  public query: any;
  public newpage: boolean = false;
  public loadmore: boolean = true;
  public patientData: any;
  public clickeduser_id = 0;
  public switchcaseval: any = 'default';
  public highlightedRow: SelectedUserinterface;
  public selection = new SelectionModel<Userlistinterface>(true, []);
  public notificationSelectedData: any;
  public dataSource: UserListDataSource;
  public page: any;
  public patientMetaData: any;
  public metaObject: any;
  public programfilters: any = [];
  public gestationalAgefilters: any = [];


  constructor(private userListService: UserlistService, private socketService: SocketService, private ContentserviceService: ContentserviceService, private persistenceService: PersistenceService,
    private parentScope: UserlistnavbarComponent, private appContext: ContextService, private commonService: CommonserviceService) {


  }

  connect(collectionViewer: CollectionViewer): Observable<Userlistinterface[]> {
    return this.userListSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userListSubject.complete();
    this.loadingSubject.complete();
  }

  filter(filterValue: string): void {

    let data = this.userListFilterData;
    let searchedData;
    if (filterValue == "") {
      searchedData = data;
    } else {
      searchedData = data.filter((row) => {
        return (JSON.stringify(row).toLowerCase().includes(filterValue));
      });
    }
    /**Remove onboarding and expired clients */
    // && !user["Expired"]
    //searchedData = searchedData.filter(user => { return (!user["Onboarding"]) });
    /**Push Data with sequence */
    this.makeDataSequence(this.appContext.userListChatStatusCollection.getValue(), searchedData);
    //this.userListSubject.next(searchedData);
  }

  length(): number {
    return this.userListSubject.getValue().length;
  }

  data(): Array<Userlistinterface> {
    let data = this.userListSubject.getValue();
    return data;
  }

  filterType(filterType: Array<string>, statusType: string, isClient?: boolean, filterstring?: string, isProgramFilter?: boolean, isGestationalAge?: boolean): void {
    this.filters = [];
    var filterids = [];
    if (isClient == true) {
      filterType.forEach(el => {
        filterids.push(parseInt(this.clientList.filter(e => e.title == el)[0].id))
      });
      this.filters.push({
        "filter_type": "clientFilter",
        "value": filterids
      })
    } else if (isProgramFilter == true) {
      filterType.forEach((el, i) => {
        if (i === filterType.length - 1) {
          this.parentScope.programDays.some(element => {
            if (element.value === el) {
              if (this.programfilters.length > 0) {
                this.programfilters.map(obj => {
                  if (obj.filter_type === 'dayOfProgramFilter') {
                    if (obj.value !== null && obj.value !== undefined) {
                      obj.value.push(element.id)
                      if (obj[obj] === null) {
                        delete obj[obj];
                      }
                      return { obj };
                    }
                    obj.map(JSON.stringify).filter((e, i, a) => i === a.indexOf(e)).map(JSON.parse)
                    return { obj };
                  }
                });
              } else {
                this.programfilters.push({
                  "filter_type": 'dayOfProgramFilter',
                  "value": [element.id]
                })
                return;
              }
              return;
            }
          });
        }
      });

    } else if (isGestationalAge == true) {
      filterType.forEach((el, i) => {
        this.parentScope.gestationalAge.some(element => {
          if (element.value === el) {
            if (this.gestationalAgefilters.length > 0) {
              this.gestationalAgefilters.map(obj => {
                if (obj.filter_type === 'gestationalAgeFilter') {
                  if (obj.value !== null && obj.value !== undefined) {
                    let uniqueValues
                    obj.value.push(element.id)
                    uniqueValues = nums => [...new Set(obj)];
                    return {
                      uniqueValues
                    };
                  }
                }
              });
            } else {
              this.gestationalAgefilters.push({
                "filter_type": 'gestationalAgeFilter',
                "value": [element.id]
              })
              return;
            }
            return;
          }
        });
      });
    } else {
      filterType.forEach((el, i) => {
        if (el == 'Active') {
          this.filters.push({
            "filter_type": "activePatients",
            "value": null
          })
        }
        if (el == 'Lagging') {
          this.filters.push({
            "filter_type": "inactivePatients",
            "value": null
          })
        }
        if (el == 'Onboarding') {
          this.filters.push({
            "filter_type": "onboardingPatients",
            "value": null
          })
        }
        if (el == 'Expired') {
          this.filters.push({
            "filter_type": "expiredPatients",
            "value": null
          })
        }
        if (el == 'program_ended') {
          this.filters.push({
            "filter_type": "program_complete_filter",
            "value": null
          })
        }
        if (el == 'amateur' || el == 'average' || el == 'proficient') {
          this.filters.push({
            "filter_type": "knowledgeFilter",
            "value": el
          })
        }
        if (el == 'initiate') {
          this.filters.push({
            "filter_type": "firstResponseDue",
            "value": null
          })
        }
        if (el == 'respond') {
          this.filters.push({
            "filter_type": "responseDue",
            "value": null
          })
        }
        if (el == 'waiting') {
          this.filters.push({
            "filter_type": "noResponseDue",
            "value": null
          })
        }
      });
    }
    if (filterType.length === 0) {
      this.programfilters = [];
    }
    this.loadmore = true;
    this.parentScope.counter = 1;
    if (this.filters.length > 0 && filterType.length > 0) {
      this.userListFilterData = [];
      this.getUserList(1, this.filters, filterstring)
    } else if (this.programfilters.length > 0 && filterType.length > 0) {
      this.userListFilterData = [];
      this.programfilters[0].value = this.multiDimensionalUnique(this.programfilters[0].value);
      this.getUserList(1, this.programfilters, filterstring)
    } else if (this.gestationalAgefilters.length > 0 && filterType.length > 0) {
      this.userListFilterData = [];
      this.gestationalAgefilters[0].value = [...new Set(this.gestationalAgefilters[0].value)];
      this.getUserList(1, this.gestationalAgefilters, filterstring)
    } else {
      this.userListFilterData = [];
      this.getUserList(1, this.filters, filterstring)
    }

  }
  multiDimensionalUnique(arr) {
    var uniques = [];
    var itemsFound = {};
    for (var i = 0, l = arr.length; i < l; i++) {
      var stringified = JSON.stringify(arr[i]);
      if (itemsFound[stringified]) { continue; }
      uniques.push(arr[i]);
      itemsFound[stringified] = true;
    }
    return uniques;
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  addProgramDays(el) {
    this.parentScope.programDays.some(element => {
      if (element.value === el) {
        return true;
      }
      return false;
    });
  }
  clientFilterType(filterType: Array<string>, statusType: string): void {
    let statusTypeArray = this.appContext.userListChatStatusCollection.getValue();
    /**Apply first chat status filter */
    let data = this.userListFilterData;
    let filteredData = ((statusType == "All" || statusType == "") ? data : data.filter(data => { return statusTypeArray[statusType].includes(data.id) }));
    /*apply client filter type */
    let clientObjectData = this.clientList.filter(client => { return filterType.includes(client.client_sku) });
    let clientObjectIDArray = clientObjectData && clientObjectData.map(client => { return client.object_id });

    /**Now filter the data */
    data = (filterType.length == 0 ?
      filteredData.filter(user => { return (!user["Onboarding"] && !user["Expired"]) })
      :
      filteredData.filter(function (data) {
        //return clientObjectIDArray.includes(data.client_id);

        let x = clientObjectIDArray.filter(id => { return id == data.client_id });
        if (x.length == filterType.length) {
          return true;
        } else {
          return false;
        }
      })
    );
    /**APPLY SPECIAL CONDITION FOR NOT EXPIREX USER */
    if (!filterType.includes("Expired")) {
      //No expired filter means removed all expired users from list
      data = data.filter((user) => !user["Expired"]);
    }
    /**Push Data with sequence */
    this.makeDataSequence(this.appContext.userListChatStatusCollection.getValue(), data);
  }

  getCounts(inputKey, isClient?: boolean): number {
    if (isClient) {
      return this.getClientCount(inputKey);
    }
    if (this.userListFilterData && this.userListFilterData.length > 0) {
      /**Hide onboarding & expired user from list */
      return (inputKey == "All" ? this.userListFilterData.filter(user => { return (!user["Onboarding"] && !user["Expired"]) }).length : this.userListFilterData.filter(user => user[inputKey]).length);
    } else {
      return 0;
    }
  }

  getClientCount(inputKey): number {
    /**First get object_id according to client sku */
    let clientObjectData = this.clientList.find(client => { return client.client_sku == inputKey });
    let clientObjectID = clientObjectData && clientObjectData.object_id;
    if (this.userListFilterData && this.userListFilterData.length > 0) {
      /**Hide onboarding & expired user from list */
      return this.userListFilterData.filter(user => {
        if ((user as any).Expired == false) {
          return ((user.client_id == clientObjectID) && clientObjectID != null)
        }
      }).length;
    } else {
      return 0;
    }
  }

  isClient(inputKey): boolean {
    let clientObjectData = this.clientList.filter(client => { return client.client_sku == inputKey });
    return clientObjectData.length > 0 ? true : false;
  }

  processChatStatus(data, cbScope): void {
    //resp wait
    let respArray = data.resp;
    let waitArray = data.wait;
    let combinedArray = [...respArray, ...waitArray];
    let tinitArray = cbScope.userListFilterData.filter(user => { return (!combinedArray.includes(user.id) && user.id != cbScope.chatBot.id) });
    /**Push only IDS */
    let initArray = tinitArray.map(user => user.id);
    /**Emit message Now */
    cbScope.appContext.userListChatStatusCollection.next({ initiate: initArray, waiting: waitArray, respond: respArray });
    /**Check for respond */
    cbScope.showBoldForNewMessages(respArray, cbScope);
  }

  showBoldForNewMessages(reaspondData: any, cbScope: any): void {
    cbScope.userListFilterData.map(user => {
      if (reaspondData.includes(user.id)) {
        return user["notificationCount"] = 1;
      } else {
        return user["notificationCount"] = 0;
      }
    });
  }

  applyChatStatusFilter(statusType: any, filterType: any): void {
    /**First check for filter type */
    /**Hide onboarding & expired user from list */
    let data = (filterType.length == 0 ?
      this.userListFilterData.filter(user => { return (!user["Onboarding"] && !user["Expired"]) })
      :
      this.userListFilterData.filter(function (data) {
        let x = filterType.filter(filt => data[filt]);
        if (x.length == filterType.length) {
          return true;
        } else {
          return false;
        }
      })
    );
    /**Now check for status type */
    let statusTypeArray = this.appContext.userListChatStatusCollection.getValue();
    let filteredData = (statusType == "All" ? data : data.filter(data => { return statusTypeArray[statusType].includes(data.id) }));
    //this.userListSubject.next(filteredData);
    /**Push Data with sequence */
    this.makeDataSequence(this.appContext.userListChatStatusCollection.getValue(), filteredData);
  }

  getUserList(page, filters, query, total_patient_lenght?): void {
    let selectedData = JSON.parse(localStorage.getItem('selectedpatentdata'));
    if (selectedData.selectedpatentflag) {
      var postdata = {
        task_id_fk: localStorage.getItem('newTaskID').toString(),
        user_id_fk: localStorage.getItem('scarletuserid'),
        page: this.parentScope.counter.toString(),
        limit: "20",
      }
      this.commonService.TaskPost(postdata, 'getPatientList')
        .subscribe((res: any) => {
          if (res.data.length > 0) {
            this.userListFilterData = this.userListFilterData.concat(res.data);
            this.userListSubject.next(this.userListFilterData);
            this.parentScope.isLoadingdata = false;
          } else {
            this.loadmore = false;
          }
        })
    } else {
      if (this.parentScope.counter <= 1) {
        this.parentScope.selection.clear();
        this.appContext.multiUserSelectionUsers.next(this.parentScope.selection.selected);
      }
      //
      var scope = this;
      this.loadingSubject.next(true);
      this.getClientList();
      this.socketService.getUserList(page, filters, query).pipe(
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(data => {
        let bde;
        bde = data;
        let notificationClickPatient = localStorage.getItem("notifyPatientName");
        if (notificationClickPatient) {
          this.notificationSelectedData = bde.data.metadata[0];
        }
        if (bde.data && bde.data.metadata) {
          bde = bde.data.metadata;
        }
        if (this.newpage == true && bde.data?.metadata.length > 0) {
          this.userListFilterData = [];
          this.userListFilterData = bde;
        }
        else {
          if (bde.length > 0) {
            for (let bd of bde) {
              this.userListFilterData.push(bd);
            }
            this.loadmore = true;
          } else {
            this.loadmore = false;
          }
        }

        localStorage.setItem("userListFilterData", JSON.stringify(bde));
        localStorage.setItem("patientLength", bde.length);
        if (bde.length == 0) {
          scope.loadmore = false;
        }
        this.newpage = false;
        let reduceData = this.userListFilterData;
        reduceData = reduceData.reduce((res, product) => {
          if (!res[product.user_id]) {
            // The product does not exist in the map, add it
            res[product.user_id] = product;
          } else if (res[product.user_id].last_activity_at < product.last_activity_at) {
            // A cheaper product exists, replace it.
            res[product.user_id] = product;
          }
          return res;
        }, {});
        this.userListFilterData = Object.values(reduceData);
        // this.userListFilterData = this.userListFilterData.filter((v, i, a) => a.findLastIndex(v2 => (v2.user_id === v.user_id)) === i)
        this.prioritySort(this.userListFilterData)
        // this.userListFilterData = this.userListFilterData.sort((a: any, b: any) => {
        //   if (b['unread_count'] > 0 && a['unread_count'] > 0) {
        //     return b['last_activity_at'] - a['last_activity_at']
        //   }
        // });
        // this.userListFilterData.sort((a: any, b: any) => {
        //   return a['status'] > b['status'] && (b['unread_count'] == 0 && a['unread_count'] == 0) ? -1 : 1;
        // });
        // this.userListSubject.next(this.userListFilterData);
        let notificationClickPatientName = localStorage.getItem("notifyPatientName");
        if (notificationClickPatientName) {
          this.clickNotificationSelectedData(this.notificationSelectedData);
        }
      });
    }
  }
  prioritySort(userListFilterData: any) {
    let priority1 = [];
    let priority2 = [];
    let priority3 = [];
    for (let am of userListFilterData) {
      if (am.unread_count > 0) {
        priority1.push(am)
      } else if (am.status == 'online') {
        priority2.push(am)
      } else {
        priority3.push(am)
      }
    }
    priority1 = this.parentScope.bubbleSort(priority1)
    priority2 = this.parentScope.bubbleSort(priority2)
    priority3 = this.parentScope.bubbleSort(priority3)
    let sortedMember: any = [...priority1, ...priority2, ...priority3];
    this.userListSubject.next(sortedMember);
  }

  clickNotificationSelectedData(element) {
    let d = {
      "callintervalflag": false
    }
    localStorage.setItem('callintervalflag', JSON.stringify(d))
    localStorage.setItem('selectedPatientId', element.user_id);
    localStorage.setItem('selectedPatientName', element.name);

    let uid = localStorage.getItem('scarletuserid');
    // let deliveryrec = { type: 'deliveryCursor', conversationId: scope.convoid, userId: 'H' + scope.uid, value: data.messages[data.messages.length - 1].id, participants: ['H' + scope.uid, data.messages[data.messages.length - 1].sender] };
    let readrec = { type: 'readCursor', conversationId: element.chatMetadata[0].conv_id, userId: 'H' + uid, value: element.chatMetadata[0].last_message_details.id, participants: ['H' + uid, element.chatMetadata[0].last_sender] };
    // this.socketService.setCursor(deliveryrec)
    //     .subscribe(data => {
    //         
    //     }, (error) => {
    //     })
    this.socketService.setCursor(readrec)
      .subscribe(data => {

      }, (error) => {
      })
    this.clickeduser_id = element.user_id;

    setInterval(() => {
      let localdata = JSON.parse(localStorage.getItem('userListFilterData'));
      // 
      var newArray = localdata.filter(function (el) {
        return el.user_id == element.user_id;
      });

      let flag = JSON.parse(localStorage.getItem('callintervalflag'))
      //  
      if (flag.callintervalflag && element.chatMetadata[0].last_message_details.body != newArray[0].chatMetadata[0].last_message_details.body) {
        //  
        element.chatMetadata[0].last_message_details.body = newArray[0].chatMetadata[0].last_message_details.body;
      }
    }, 2000)

    element.chatMetadata[0].unread_count = 0;
    let data = [];
    data.push(element);
    this.userListService.change___selectedpatient(data);
    this.switchcaseval = 'default'
    this.selection = new SelectionModel<Userlistinterface>(true, []);
    if (this.switchcaseval == 'default') {
      this.selection.toggle(element);
      this.handleRowSelection1(element);


    }

  }

  handleRowSelection1(row: any): void {

    this.switchcaseval = 'default'
    this.selection = new SelectionModel<Userlistinterface>(true, []);

    if (this.switchcaseval == 'default') {
      this.highlightedRow = row;
      /**Emit row selection event */
      let previousUser = this.appContext.selectedChatUser.getValue();
      if ((previousUser != row.user_id) && row.user_id != "" && row.user_id != null) {
        /**Assign row data also */
        this.appContext.selectedChatUserRowData.next(row);
        this.appContext.selectedChatUser.next(row.user_id);
        this.appContext.selectedChatUser1.next(row);
        /**Change watcher */
        this.appContext.changeWatcher.next(true);
        /**Change User Name */
        this.appContext.userName.next(row.name);
        if (row.chatMetadata[0].gestational_age === null) {
          this.appContext.gestationalAge.next(null);
        } else {
          this.appContext.gestationalAge.next(row.chatMetadata[0].gestational_age);
        }

        /**Off chat bot event */
        this.appContext.isSelectedChatUserIsBot.next(false);
      } else if (row == this.dataSource.chatBot) {
        /**Assign row data also */
        this.appContext.selectedChatUserRowData.next(row);

        this.appContext.selectedChatUser.next(row.user_id);
        /**Change User Name */
        this.appContext.userName.next(row.name);
        if (row.chatMetadata[0].gestational_age === null) {
          this.appContext.gestationalAge.next(null);
        } else {
          this.appContext.gestationalAge.next(row.chatMetadata[0].gestational_age);
        }
        /**Off chat bot event */
        this.appContext.isSelectedChatUserIsBot.next(true);
      }
    }
    this.appContext.multiUserSelectionUsers1.next(this.selection.selected);
  }

  getClientList() {
    this.ContentserviceService
      .getGraphQlData('{getAllClients(appMode:false,status:true){id,title,is_test}}')
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.clientList = data.getAllClients;
        this.clientList = this.clientList.filter(client => { return !client.is_test });
      })
  }

  makeDataSequence(data: any, userDataDirect?: any): void {
    let sData = this.appContext.userListSequenceCollection.getValue().map(chat => { return chat.userID });
    if (sData.length > 0) {
      sData.reverse();
      let upperRowUsers = Array.from(new Set(sData));
      let userData = this.userListSubject.getValue();
      if (userDataDirect)
        userData = userDataDirect;
      let boldData = [];
      if (data.respond && data.respond.length > 0) {
        boldData = data.respond;
      }
      let upperRows = [];
      userData = userData ? userData : [];
      let belowRows = userData.filter(user => { return !upperRowUsers.includes(user.id) });
      let combinedArray;
      combinedArray = [...upperRows, ...belowRows];
      this.userListSubject.next(combinedArray);
    } else {
      if (userDataDirect) {
        this.userListSubject.next(userDataDirect);
      } else {
        this.userListSubject.next(this.userListSubject.getValue());
      }
    }

  }

  processData(data: any): any {
    return data;
  }

  getPateintFullData(selectedValue) {
    if (selectedValue.id) {
      if (this.parentScope.counter <= 1) {
        this.parentScope.selection.clear();
        this.appContext.multiUserSelectionUsers.next(this.parentScope.selection.selected);
      }
      //
      var scope = this;
      this.loadingSubject.next(true);
      this.getClientList();
      let queryString = selectedValue.name;
      if (typeof (queryString) == 'string') {
        // if(total_patient_lenght !== undefined){
        //   const el = document.getElementById('chatList');
        //   const el1 = document.getElementById('patientcount');
        //   el.style.display = 'none';
        //   el1.style.display = 'none';
        // }
        let page = '1';
        let filters = [];
        this.socketService.getUserList(page, filters, selectedValue.name).pipe(
          catchError((error: any) => {
            this.loadingError$.next(true);
            return throwError(error);
          }),
          finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
          this.patientMetaData = data.data.metadata;
          this.metaObject = this.patientMetaData.find(metaData => metaData.user_id === selectedValue.id);
          this.parentScope.handleRowSelection(this.metaObject);


          // let x = _.filter(
          //   data.data.metadata, function (o) {
          //     return o.user_id == selectedValue.id;
          //   }
          // );


          // bde.push(x[0]);
          // if (bde.data && bde.data.metadata) {
          //   bde = bde.data.metadata;

          // }
          // if (this.newpage == true && bde != []) {
          //   
          //   bde.forEach(el => {
          //     this.userListFilterData.push(el);
          //   });
          // }
          // else {
          //   
          //   this.userListFilterData=[];
          //   this.userListFilterData = bde;
          // }

          // if (bde.length == selectedData.selectedpatent.length) {

          // this.userListFilterData = [];
          // this.userListFilterData = bde;
          // localStorage.setItem("userListFilterData", JSON.stringify(bde));
          // localStorage.setItem("patientLength", bde.length);
          // if (bde.length <= 20) {
          //   scope.loadmore = true;
          // }

          this.newpage = false;
          // this.userListSubject.next(this.userListFilterData);


          // }
          // localStorage.setItem("userListFilterData",JSON.stringify(bde));
          // localStorage.setItem("patientLength", bde.length);
          // if (bde.length == 0) {
          //   scope.loadmore = false;
          // }
          // 
          // this.newpage = false;
          // this.userListSubject.next(this.userListFilterData);
        });
      }
      // if((total_patient_lenght-1) === index ){
      //   const el = document.getElementById('chatList');
      //   const el1 = document.getElementById('patientcount');
      //   setTimeout(() => {  this.parentScope.isLoadingdata = false;
      //     el.style.display = 'block';
      //     el1.style.display = 'inline';
      //    }, 5000);

      // }
      // if(!arr[index + 1]) {
      //   // this.parentScope.isLoadingdata = false;
      // }

    } else {
      // var finaluserlist = [];
      if (this.parentScope.counter <= 1) {
        this.parentScope.selection.clear();
        this.appContext.multiUserSelectionUsers.next(this.parentScope.selection.selected);
      }
      let page = '1';
      let filters = [];
      var scope = this;
      this.loadingSubject.next(true);
      this.getClientList();
      this.socketService.getUserList(page, filters, query).pipe(
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(data => {

        let bde = data;

        let notificationClickPatient = localStorage.getItem("notifyPatientName");

        if (notificationClickPatient) {
          this.notificationSelectedData = bde.data.metadata[0];
        }

        if (bde.data && bde.data.metadata) {
          bde = bde.data.metadata;
        }
        if (this.newpage == true && bde != []) {

          // finaluserlist = bde;
          bde.forEach(el => {
            this.userListFilterData.push(el);
          });
        }
        else {

          this.userListFilterData = [];
          this.userListFilterData = bde;
        }


        localStorage.setItem("userListFilterData", JSON.stringify(bde));
        localStorage.setItem("patientLength", bde.length);
        if (bde.length == 0) {
          scope.loadmore = false;
        }

        this.newpage = false;
        this.userListSubject.next(this.userListFilterData);

        let notificationClickPatientName = localStorage.getItem("notifyPatientName");

        if (notificationClickPatientName) {
          this.clickNotificationSelectedData(this.notificationSelectedData);
        }

      });

    }
  }
  listPatient(counter) {
    this.getUserList(1, [], '');
    // let patentId = []
    // var postdata = {
    //   task_id_fk: localStorage.getItem('newTaskID').toString(),
    //   user_id_fk: localStorage.getItem('scarletuserid'),
    //   page: counter.toString(),
    //   limit: "20",
    // }
    // this.commonService.TaskPost(postdata, 'getPatientList')
    //   .subscribe((res: any) => {
    //     if (res.data.length > 0) {
    //       this.parentScope.isLoadingdata = true;
    //       let prev_data = JSON.parse(localStorage.getItem("selectedpatentdata"));
    //       let total_patient_lenght = prev_data.selectedpatent.length + res.data.length
    //       res.data.forEach((d,index) => {
    //         let patientData: any = {
    //           id: d.id,
    //           name: d.name
    //         };

    //         patentId.push(patientData);

    //       })
    //       if (patentId.length == res.data.length) {
    //         let data = {
    //           "selectedpatent": patentId,
    //         }

    //         for (let i = 0; i <= patentId.length; i++) {
    //           if (patentId[i] !== undefined) {
    //             prev_data.selectedpatent.push(patentId[i]);
    //           }
    //           localStorage.setItem("selectedpatentdata", JSON.stringify(prev_data));
    //           this.getUserList(1, [], '',total_patient_lenght)
    //         }

    //       }
    //     } else {
    //       this.parentScope.isLoadingdata = false;
    //       this.loadmore = false;
    //     }
    //     // this.dataSource.getUserList(this.counter, this.dataSource.filters, this.filterValue);
    //   })
  }

}
