import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, delay, finalize } from 'rxjs/internal/operators';
import { ShowmodalComponent } from 'src/app/healthcoach/components/showmodal/showmodal.component';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

import { Userdetailsinterface } from '../../../healthcoach/models/userdetailsinterface';
import { ContextService } from '../../../healthcoach/services/context.service';
import { SocketService } from '../../../healthcoach/services/socket/socket.service';
import { UserdetailsService } from '../../../healthcoach/services/userdetails/userdetails.service';
import { UserlistService } from '../../../healthcoach/services/userlist/userlist.service';
import { AuthenticateService } from 'src/app/shared/services';

@Component({
  selector: 'app-otherdetails',
  templateUrl: './otherdetails.component.html',
  styleUrls: ['./otherdetails.component.scss']
})

export class OtherdetailsComponent implements OnInit {
  relationship: any = relationship;
  public prescriptionSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  private prescriptionFilterData: any;
  public prescriptionUrlRaw: any;
  public presData: Array<Object> = [];
  public medicineData: Array<Object> = [];
  public reminderData: Array<Object> = [];
  public selectedWeek: string = "1";
  public s3url: any = environment.s3url;
  public documentPlaceHolder: string = '/assets/img/pdficon.jpg';
  public subscribeObj: any;
  public selected: any = 'prescription';
  public caregiverData: any = '';
  public doctorData: any = '';
  public addmeddata: any;
  public selectedUserID: string;
  public calldata: any;
  public updateDocData: boolean = false;
  public tempDocData: any;
  public allsymptoms: any;
  public alltriggers: any;
  public allQuestionnaire: any;
  public showmore: boolean = false;
  public infusionSiteData: any;
  public medicationData: any;
  public pumpData: any;
  public MedicalHistoryData: any;
  public routeofAdminData: any;
  public allROA: any;
  public MedicalData: any;
  public hospitalizationData: any;

  pageno: any;
  docdata: any;
  public userClientDetails: any;
  public userDataQ: any;
  public callogForm = this.fb.group({
    startdate: [''],
    enddate: ['']
  });
  public caregiverForm = this.fb.group({
    name: ["", Validators.required],
    phone: ["", Validators.required],
    relationship: ["", Validators.required],
    country_code: ["", Validators.required],
    email: ["", Validators.required],
    gender: ["", Validators.required]
  });
  public doctorForm = this.fb.group({
    doctorCode: ["", Validators.required]
  });
  addremdata: any;

  prescriptionDisplayColumns: string[] = ["addedOn", "title", "attachments"];
  remindersDisplayColumns: string[] = ["reminderAddedOn", "name", "type", "scheduledDays", "scheduledTime"];
  symptomsDisplayColumns: string[] = ["symptoms", "severity","date"]

  renderingDataSource = [];
  rendreingDisplayColumns = [];

  constructor(
    private route: ActivatedRoute, 
    private SystemService: SystemService, 
    public fb: FormBuilder, 
    public dialog: MatDialog, 
    private appContext: ContextService, 
    private userDetailService: UserdetailsService, 
    private socketService: SocketService, 
    private UserlistService: UserlistService, 
    private AuthenticateService: AuthenticateService
  ) { 
    this.rendreingDisplayColumns = [...this.prescriptionDisplayColumns];
  }

  displayedColumns: string[] = ['date', 'name', 'reason'];
  dataSource = ELEMENT_DATA;

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.selected = 'prescription';
      this.selectedUserID = params.id;
      this.doctorForm.reset();
      this.callogForm.reset();
      this.caregiverForm.reset();
      this.calldata = [];
      this.rendreingDisplayColumns = [...this.prescriptionDisplayColumns];
      this.initComponentDraw(this.selectedUserID);
      this.selectedUserID = this.selectedUserID;
      this.getUserDetails(this.selectedUserID);
      let selectedNotificationData = localStorage.getItem("selectedNotification");

     
     if (selectedNotificationData == "medication_added_event" || selectedNotificationData == "medication_updated_event") {
        this.selected = "medication";
        let val = 'medication'
        this.onSelectChange(val);
        this.getMediData();
        this.medicationClicked();
      }
      else if (selectedNotificationData == "prescription_upload" || selectedNotificationData == "prescription_update") {
        this.selected = "prescription";
         this.prescriptionClicked();
          this.rendreingDisplayColumns = [...this.prescriptionDisplayColumns];
          this.renderingDataSource = [...this.presData];
      }
      else if (selectedNotificationData == "reminder_added_event" || selectedNotificationData == "reminder_updated_event" ||
        selectedNotificationData == "medicine_added_event" || selectedNotificationData == "medicine_updated_event") {
        this.selected = "reminder";
        this.rendreingDisplayColumns = [...this.remindersDisplayColumns];
        this.getRemainder();
      }
    
      else if (selectedNotificationData == 'trigger_event') {
        this.selected = "trigger";
        this.getTriggers(1);
      }
      else if (selectedNotificationData == 'questionnaire_event') {
        this.selected = "questionnaire";
        this.getQuestionnaires(1);
      }
      else if (selectedNotificationData == 'symptom_event') {
        this.selected = "symptom";
        this.getSymptoms(1);
      }
     
      else if (selectedNotificationData == 'pump_added_event' || selectedNotificationData == 'pump_updated_event') {
        this.selected = "pump";
        this.getPumpData();
      }
    });
    
  }

  openDialogImg(url, img) {
    this.dialog.open(ShowmodalComponent, {
      width: 'auto',
      height: '80%',
      data: {
        dataurl: url,
        type: img
      }
    });
  }

  onSuccessuserSettingsDetails(data) {
    data = (data == null) ? [] : data;
    let presRawData = data.prescription_data;
    this.medicineData = data.medicine_data;
    // this.reminderData = data.reminder_data;
    this.caregiverData = data.caregiver_data;
    this.doctorData = '';
    this.doctorData = data.doctor_data;
    this.tempDocData = this.doctorData;
    this.prescriptionFilterData = data;
    // this.infusionSiteData = data.infusion_data;
    // this.medicationData = data.medication_data;
    // this.pumpData = data.pump_data;

    if (!data.prescriptions) {
      this.prescriptionSubject$.next(data);
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
    }

    /**Check for empty prescription data again for no data loader */
    this.presData = [];

    if (presRawData) {
      for (var i = 0; i < presRawData.length; i++) {
        presRawData[i].prescription_ids.forEach(element => {
          this.presData.push({
            'date': presRawData[i].created_at,
            'path': element.path,
            'type': element.media_type,
            'mime': element.mime,
            'name': presRawData[i].name,
            'url': ""
          })
        })

        // commented because mime is undefined above
        // if (i == (presRawData.length - 1)) {
        //   this.presData.forEach(elem => {
        //     this.geturl(elem['path'], elem['mime'], datap => {
        //       elem['url'] = datap;
        //     });
        //   });
        // }
      }
    }

    this.renderingDataSource = [...this.presData];

  }

  initComponentDraw(selectedUserID: string) {
    this.updateDocData = false;
    this.loadingSubject$.next(true);
    /**Reinitialise prescription subject */
    this.prescriptionSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**API Call */
    this.userDetailService.getUserRemSettingDetails(selectedUserID)
      .subscribe(
        (res: any) => this.onSuccessuserSettingsDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  getUserDetails(selectedUserID: string) {

    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    this.prescriptionSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**Call API */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      
      data = (data == null) ? [] : data;
      this.prescriptionSubject$.next(data);
      this.userDataQ = data;
      this.userClientDetails = data.patient_client_data.title;
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
    });
  }

  getRemainder() {
    this.userDetailService.getUserRemSettingDetails(this.selectedUserID)
      .subscribe(
        (res: any) => this.onSuccessRemainderDetails(res),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessRemainderDetails(data) {

    let scope = this;

    this.reminderData = data.data.reminder_data;

    this.reminderData.forEach((data: any, index) => {
      let daysArray = data.days;
      let timeArray = data.time;
      let daysTextArray: any = [];
      let timeTextArray: any = []; 
      
      if(daysArray.length > 0) {
        for(let i =0;i<daysArray.length;i++){
          daysTextArray.push(scope.convertDayNumToMiniDay(daysArray[i]));
        }

        daysTextArray = daysTextArray.join(', ');
      } else {
        daysTextArray = " ";
      }

      if(timeArray.length > 0){
        for(let i =0;i<timeArray.length;i++){
          timeTextArray.push(timeArray[i].t);
        }

        timeTextArray = timeTextArray.join(', ');
      } else {
        timeTextArray = " ";
      }
      
      this.reminderData[index]["scheduledDays"] = daysTextArray;
      this.reminderData[index]["scheduledTime"] = timeTextArray;

    });

    this.renderingDataSource = [...this.reminderData];
  }

  onSelectChange(e) {
    this.pageno = 1;
    this.showmore = false;

    switch(e.value) {
      case "prescription": 
        this.prescriptionClicked();
        this.rendreingDisplayColumns = [...this.prescriptionDisplayColumns];
        this.renderingDataSource = [...this.presData];
        break;

      case "reminder": 
        this.rendreingDisplayColumns = [...this.remindersDisplayColumns];
        this.getRemainder();
        break;

      case "symptom": 
        this.getSymptoms(1);
        this.rendreingDisplayColumns = [...this.symptomsDisplayColumns];
        break;

      case "trigger": 
        this.getTriggers(1);
        break;

      case "questionnaire": 
        this.getQuestionnaires(1);
        break;

      case "infusionsite": 
        this.getInfusionSite();
        break;

      case "pump": 
        this.getPumpData();
        break;

      case "medication": 
        this.getMediData();
        this.medicationClicked();
        break;

      case "medicalhistory": 
        this.getMedicalHistory();
        break;

      case "roa": 
        this.getRouteOfAdmin();
        break;

      case "hospitalisation": 
        this.getHospitalization();
        break;
    }
  }

  goNextPage(e) {
    this.pageno = this.pageno + 1;
    if (e == 'symptom') {
      this.getSymptoms(this.pageno);
    }
    if (e == 'trigger') {
      this.getTriggers(this.pageno);
    }
    if (e == 'questionnaire') {
      this.getQuestionnaires(this.pageno);
    }
  }

  getSymptoms(pageno) {
    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.selectedUserID).toString()
    }
    this.userDetailService.getMisc(data, 'symptoms')
      .subscribe(
        (res: any) => this.onSuccessSymptomsDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessSymptomsDetails(data) {

    if (data.length < 10) {
      this.showmore = false;
    } else {
      this.showmore = true;
    }

    if (this.pageno == 1 || this.pageno == undefined) {
      this.allsymptoms = data;
    } else {
      this.allsymptoms = [...this.allsymptoms, ...data];
    }

    let symptomsArray = [];
    for(let i = 0 ; i < this.allsymptoms.length ; i++){
      for(let j = 0 ; j < this.allsymptoms[i].symptom_ids.length ; j++){
        this.allsymptoms[i].symptom_ids[j]["log_date"] = this.allsymptoms[i].log_date
      }
    }
    this.allsymptoms.forEach((data: any) => {
      symptomsArray.push(...data.symptom_ids);
    });

    console.log(symptomsArray);
    this.renderingDataSource = [...symptomsArray];

    // const modified = this.allsymptoms.map(function (ComparisonResult) {
    //   ComparisonResult.symptom_ids = ComparisonResult.symptom_ids.filter(x => x.value != 'none');
    //   return ComparisonResult;
    // });
}

  getTriggers(pageno) {
    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.selectedUserID).toString()
    }
    this.userDetailService.getMisc(data, 'triggers')
      .subscribe(
        (res: any) => this.onSuccessTriggersDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }


  onSuccessTriggersDetails(data) {
    if (data.length < 10) {
      this.showmore = false;
    }
    else {
      this.showmore = true;
    }
    if (this.pageno == 1 || this.pageno == undefined) {
      this.alltriggers = data;
    }
    else {
      for (var k = 0; k < data.length; k++) {
        this.alltriggers.push(data[k]);
      }
    }
  }


  getInfusionSite() {
    let data = {
      "start_date": "2021-02-18T18:29:59.099Z",
      "end_date": "2021-02-19T18:29:59.099Z",
      "patient_id_data_fk": (this.selectedUserID).toString()
    }
    this.userDetailService.getInfusionSitedata(data)
      .subscribe(
        (res: any) => this.onSuccessInfusionDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessInfusionDetails(data) {
    this.infusionSiteData = data;
    if (data.length < 10) {
      this.showmore = false;
    }
    else {
      this.showmore = true;
    }
    // if (this.pageno == 1 || this.pageno == undefined) {
    //   this.alltriggers = data;
    // }
    // else {
    // for (var k = 0; k < data.length; k++) {
    //   this.infusionSiteData.push(data[k]);
    // }
    // }
  }

  //get ROA
  getRouteOfAdmin() {
    var patient_id_data_fk = this.selectedUserID.toString();
    this.userDetailService.getROAData(patient_id_data_fk)
      .pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false); })
      ).subscribe(data => {
        
        data = (data as any).data;
        this.routeofAdminData = data;

        // for (var j = 0; j < this.routeofAdminData.length; j++) {
        //   this.allROA.push(this.routeofAdminData[j]);
        //   
        // }
        this.routeofAdminData = new MatTableDataSource<Element>(this.routeofAdminData);

      });
  }


  getPumpData() {
    let data = {
      "patient_id_fk": (this.selectedUserID).toString()
    }
    this.userDetailService.getPumpdetails(data)
      .subscribe(
        (res: any) => this.onSuccessPumpDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessPumpDetails(data) {
    this.pumpData = data;
    if (data.length < 10) {
      this.showmore = false;
    }
    else {
      this.showmore = true;
    }


    // if (this.pageno == 1 || this.pageno == undefined) {
    //   this.alltriggers = data;
    // }
    // else {
    // for (var k = 0; k < data.length; k++) {
    //   this.pumpData.push(data[k]);
    // }
    // }
  }

  getMediData() {
    let data = {
      "language_data_fk": "1",
      "patient_id_fk": (this.selectedUserID).toString()
    }
    this.userDetailService.getMedicationdata(data)
      .subscribe(
        (res: any) => this.onSuccessMedDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessMedDetails(data) {
    this.medicationData = data;
    if (data.length < 10) {
      this.showmore = false;
    }
    else {
      this.showmore = true;
    }
    

    // if (this.pageno == 1 || this.pageno == undefined) {
    //   this.alltriggers = data;
    // }
    // else {
    // for (var k = 0; k < data.length; k++) {
    //   this.pumpData.push(data[k]);
    // }
    // }
  }

  // Medical History Listing

  getMedicalHistory() {
    this.userDetailService.getUserMedicalHistory(this.selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe((data: any) => {
      
      this.MedicalHistoryData = data.data;
      
    });
  }


  getQuestionnaires(pageno) {
    let data = {
      "page": pageno.toString(),
      "limit": "10",
      "patient_data_fk": (this.selectedUserID).toString()
    }
    this.userDetailService.getMisc(data, 'questionnaire')
      .subscribe(
        (res: any) => this.onSuccessQuestionnairesDetails((res as any).data),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessQuestionnairesDetails(data) {
    
    if (data.length < 10) {
      this.showmore = false;
    }
    else {
      this.showmore = true;
    }
    if (this.pageno == 1 || this.pageno == undefined) {
      this.allQuestionnaire = data;
    }
    else {
      for (var k = 0; k < data.length; k++) {
        this.allQuestionnaire.push(data[k]);
      }
    }
  }

  // 

  getHospitalization() {
    // let data = {
    //   "patient_id_fk": (this.selectedUserID).toString()
    // }
    this.userDetailService.getUserMedicalHistory(this.selectedUserID)
      .subscribe(
        (res: any) => this.gethospitaizationData(res),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  private gethospitaizationData(data) {
    data = (data as any).data;
    
    if (data.id) {
      
      this.MedicalData = data;
      this.hospitalizationData = data.hospitalisations;
      // this.showMedicalHistory = true;
    } else {
      // this.showMedicalHistory = false;
    }
  }

  isArray(obj: any) {
    return Array.isArray(obj)
  }
  applylogfilter() {
    var postdata = this.callogForm.value;
    var scope = this;
    var startd = null;
    var endd = null;
    startd = postdata.startdate;
    endd = postdata.enddate;
    startd.setHours(0, 0, 0, 0);
    endd.setHours(23, 59, 59, 99);
    startd = moment(startd).format("YYYY-MM-DD HH:mm:ss+05:30");
    endd = moment(endd).format("YYYY-MM-DD HH:mm:ss+05:30");
    let localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.getUserPhonenumber(scope.appContext.selectedChatUser.getValue(), data => {
      this.userDetailService.getcalllogs(endd, startd, localStoredHCData.phone, '+' + data.country_code + data.phone)
        .subscribe(
          (res: any) => this.onSuccesscallLogDetails(res),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        );
    });
  }

  onSuccesscallLogDetails(data) {
    this.calldata = data.objects;
  }

  getUserPhonenumber(id, cb) {
    this.UserlistService
      .getGraphQlData('{getOnePatient(appMode:false,patientId:' + id + '){country_code,phone}}')
      .subscribe(
        (res: any) => this.onSuccessPhonNumberDetails(res, cb),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessPhonNumberDetails(data, cb) {
    cb(data.getOnePatient)
  }

  // convertdate(d) {
  //   let daysArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ];
  //   return daysArray[d+1];
  // }

  convertDayNumToMiniDay(d) {
    let daysArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return daysArray[d-1];
  }

  geturl(p, t, cb) {
    this.socketService.getfileurl(p, t, data => {
      cb(data);
    })
  }

  careObject() {
    var postdata = this.caregiverForm.value;
    postdata.name = postdata.name.toString();
    postdata.phone = postdata.phone.toString();
    postdata.relationship = postdata.relationship.toString();
    postdata.country_code = postdata.country_code.toString();
    postdata.email = postdata.email.toString();
    postdata.gender = postdata.gender.toString();
    this.userDetailService.addDetails(postdata, this.selectedUserID.toString(), 'caregiver')
      .subscribe(
        (res: any) => this.onSuccessCaregiverDetails(res),
        (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
      );
  }

  onSuccessCaregiverDetails(data) {
    this.SystemService.showSnackBar("Caregiver details has been updated", "OK");
    this.initComponentDraw(this.selectedUserID);
    // this.caregiverForm.resetForm();
    this.caregiverForm.reset();
  }

  docObject() {
    var docdata = this.doctorForm.value;
    docdata.doctorCode = docdata.doctorCode;
    if (!this.updateDocData) {
      this.userDetailService.addDocDetails(docdata, this.selectedUserID.toString(), 'doc_code')
        .subscribe(
          (res: any) => this.onSuccessDoctorDetails(res),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        );
    }
    else {
      this.userDetailService.updateDocDetails(docdata, this.selectedUserID.toString(), 'doc_code', this.tempDocData.id.toString()).pipe(
        catchError((error: any) => {
          this.SystemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        finalize(() => {
        })
      )
        .subscribe(
          (res: any) => this.onSuccessUpdateDoctorDetails(res),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        );
    }

  }

  onSuccessDoctorDetails(data) {
    this.SystemService.showSuccessSnackBar("Doctor has been updated", "OK");
    this.initComponentDraw(this.selectedUserID);
    this.doctorForm.reset();
  }
  onSuccessUpdateDoctorDetails(data) {
    this.SystemService.showSuccessSnackBar("Doctor has been updated", "OK");
    this.initComponentDraw(this.selectedUserID);
    this.doctorForm.reset();
  }

  updateDoc() {
    this.updateDocData = true;
    this.doctorData = '';
    this.tempDocData.doctorCode = this.tempDocData.code;
    this.doctorForm.patchValue(this.tempDocData);
  }

  gender(data) {
    if (data == 'm') {
      return 'Male';
    } else if (data == 'f') {
      return 'Female';
    } else {
      return 'Others';
    }
  }

  convertIsoData(d) {
    var date = new Date(d);
    let options:any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);

  }
  convertIsoDatetime(d) {
    var date = new Date(d);
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleTimeString('en-US');
  }

  medData(d) {
    var x = d.toString().replace(/"/g, "");

    var date = new Date(x);
    let options:any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }

  conversion(c) {
    if (c) {
      var x = c.toString().replace(/"/g, "");
      var color = x.replace(/[\[\]]+/g, '')
    }
    else {
      return 'No color';
    }

    return color;
  }

  duration(dur) {
    if (dur == 1) {
      return 'Daily';
    }
    else if (dur == 2) {
      return 'Alternate';
    }
    else {
      return dur + " days"
    }
  }

  preMeal(meal) {
    if (meal == true) {
      return 'Yes'
    }
    else
      return 'No'
  }

  dosage(dose) {
    var med_dose = dose.toString().replace(/[\[\]]+/g, '')
    return med_dose;
  }

  prescriptionClicked() {
    this.getClicked('Prescription Clicked')
  }

  medicationClicked() {
    this.getClicked('Medication Clicked')
  }

  getClicked(eventName) {

    let datap =
    {
      "d": [
        {
          "identity": localStorage.getItem('scarletuserid'),
          "ts": (Math.floor(new Date().valueOf() / 1000)),
          "type": "event",
          "evtName": eventName,
          "evtData": {
            "Patient name": this.userDataQ.name,
            "Patient ID": this.userDataQ.id,
          }
        }
      ]
    }
    this.AuthenticateService
      .sendEventData(datap)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
      })

  }

}
export enum relationship {

}

export interface PeriodicElement {
  name: string;
  date: string;
  reason: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { date: '21 Jan', name: 'Inhalation', reason: 'Rate of extend of absorption of the drug is higher' },
  { date: '12 Jan', name: 'Intravenous', reason: 'Rate of extend of absorption of the drug is higher' },
  { date: '08 Jan', name: 'Implantable', reason: 'Rate of extend of absorption of the drug is higher' },
  { date: '08 Jan', name: 'Oral', reason: 'Rate of extend of absorption of the drug is higher' },
];