import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { EditMedicationComponent } from './edit-medication/edit-medication.component';
import { MedicationComponent } from './medication.component';

// import { EditmagazinecategoryComponent } from './editmagazinecategory/editmagazinecategory.component';
const routes: Routes = [
  { path: '', component: MedicationComponent, canActivate: [ContentGuardGuard] },
  { path: 'medication/edit/:id/:langid/:chid', component: EditMedicationComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedicationRoutingModule { }
