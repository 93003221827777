import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, SystemService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class BotlearningService extends HttpService{

  constructor(private http: HttpClient, private systemService : SystemService) {
    super(http)
   }

  public getIntents(): Observable<any> {
    return this.get('https://cuedev.wellthy.me/intents/get')
  }

  public postBotLearningData(mesID: string, data: any): Observable<any> { 
    return this.post('https://cuedev.wellthy.me/intents/set', data)
  }
}
