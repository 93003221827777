<div class="col-md-12">

  <h2 class="commontitleprimary text-capitalize" style="text-align: center;">
    <span style="color:var(--primary-orange);">{{campaigndata.title}}</span>
  </h2>
  <div class="close-icon">
    <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()">
  </div>
</div>
  <div class="container-table col-md-12" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
    [scrollWindow]="false">
    <div class="spinner-container" *ngIf="showloader">
      <app-loader showLoader="{{true}}"></app-loader>
    </div>
  
    <table mat-table [dataSource]="dataSource"multiTemplateDataRows class="widthfluid bordergrey list-table" style="margin-bottom:50px ;border-collapse: inherit;border-spacing: 0px 0.5em;">
          <ng-container matColumnDef="__old">
          <th mat-header-cell *matHeaderCellDef style="padding-left: 35px;"  class="col-md-3">Old Value</th>
          <td mat-cell *matCellDef="let element" class="camelcased"  class="col-md-3">
          <span *ngIf="(element.title | keyvalue)?.length > 0"><strong>Title : </strong>{{element.title?.__old}}<br></span>
          <span *ngIf="(element.detailed_text | keyvalue)?.length > 0"><strong>Description :</strong>{{element.detailed_text?.__old}}<br></span>
          <span *ngIf="(element.is_paid | keyvalue)?.length > 0"> <strong>Is Paid :</strong>{{element.is_paid?.__old}}<br></span>
          <span *ngIf="(element.type | keyvalue)?.length > 0"><strong>Type :</strong>{{element.type?.__old}}<br></span>
          <span *ngIf="(element.has_coach_access | keyvalue)?.length > 0"><strong>Has Healthcoach Access :</strong>{{element.has_coach_access?.__old}}<br></span>
          <span *ngIf="(element.coach_ids | keyvalue)?.length > 0"><strong>Selected Healthcoach :</strong>{{OldHC(element.old_data.coach_ids)}}<br></span><!--{{element.coach_ids?.__old}}-->
          <span *ngIf="(element.is_test | keyvalue)?.length > 0">  <strong>Is Test Campaign :</strong>{{element.is_test?.__old}}<br></span>
          <span *ngIf="(element.has_client | keyvalue)?.length > 0"><strong>Has client :</strong>{{element.has_client?.__old}}<br></span>
          <span *ngIf="(element.client_data | keyvalue)?.length > 0"><strong>Selected Client :</strong>{{selectedOldClient(element.client_data?.__old)}}<br></span>
          <span *ngIf="(element.start_date | keyvalue)?.length > 0"><strong> Start Date :</strong>{{element.start_date?.__old | date: 'dd/MM/yyyy'}}<br></span><!-- {{element.created_at | date: 'dd/MM/yyyy'}}-->
          <span *ngIf="(element.has_end_date | keyvalue)?.length > 0"> <strong> Has End Date :</strong>{{element.has_end_date?.__old | date: 'dd/MM/yyyy'}}<br></span>
          <span *ngIf="(element.end_date | keyvalue)?.length > 0"><strong> End Date :</strong>{{element.end_date?.__old | date: 'dd/MM/yyyy'}}<br></span>
          <span *ngIf="(element.has_doctor_access | keyvalue)?.length > 0"><strong>Has Doctor Access :</strong>{{element.has_doctor_access?.__old}}<br></span>
          <span *ngIf="(element.doctor_data | keyvalue)?.length > 0"><strong>Selected Doctor :</strong>{{selectedOldDoctor(element.doctor_data?.__old)}}<br></span>         
          <span *ngIf="(element.therapy_data_fk | keyvalue)?.length > 0"><strong> Selected Therapy :</strong> {{oldTherapy(element.therapy_data_fk?.__old)}}<br></span>
          <span *ngIf="(element.show_support_phone_number | keyvalue)?.length > 0">   <strong> Show Support Phone Number :</strong>{{element.show_support_phone_number?.__old}}<br></span>
          <span *ngIf="(element.show_support_email_id | keyvalue)?.length > 0"> <strong>Show Support Email :</strong> {{element.show_support_email_id?.__old}}<br></span>
          <span *ngIf="(element.show_questionnaire | keyvalue)?.length > 0"><strong> Show Questionnaire :</strong>{{element.show_questionnaire?.__old}}<br></span>        
          <span *ngIf="(element.show_triggers | keyvalue)?.length > 0">   <strong> Show Trigger :</strong>{{element.show_triggers?.__old}}<br></span>
          <span *ngIf="(element.show_aqi | keyvalue)?.length > 0"> <strong>Show AQI :</strong>{{element.show_aqi?.__old}}<br></span>
          <span *ngIf="(element.show_bmi | keyvalue)?.length > 0"> <strong>Show BMI :</strong>{{element.show_bmi?.__old}}<br></span>
          <span *ngIf="(element.pump_access | keyvalue)?.length > 0"><strong>Pump Access :</strong>{{element.pump_access?.__old}}<br></span>
          <span *ngIf="(element.medication_access | keyvalue)?.length > 0"><strong>Medication Access :</strong>{{element.medication_access?.__old}}<br></span><!--{{!userView.name?'-':userView.name}}-->
          <span *ngIf="(element.lab_report_access | keyvalue)?.length > 0"><strong>Lab Report Access :</strong>{{element.lab_report_access?.__old}}<br></span>
          <span *ngIf="(element.show_history | keyvalue)?.length > 0"><strong>Show history :</strong>{{element.show_history?.__old}}<br></span>
          <span *ngIf="(element.show_utilities | keyvalue)?.length > 0"><strong>Show Utilities :</strong>{{element.show_utilities?.__old}}<br></span>
          <span *ngIf="(element.remodulin | keyvalue)?.length > 0"><strong>Remodulin :</strong>{{element.remodulin?.__old}}<br></span>
          <span *ngIf="(element.is_remodulin | keyvalue)?.length > 0"><strong>Remodulin :</strong>{{element.is_remodulin?.__old}}<br></span>
          <span *ngIf="(element.is_nonremodulin | keyvalue)?.length > 0"><strong>Non Remodulin :</strong>{{element.is_nonremodulin?.__old}}<br></span>
          <span *ngIf="(element.use_geolocation | keyvalue)?.length > 0"><strong>Used Geolocation :</strong>{{element.use_geolocation?.__old}}<br></span>
          <span *ngIf="(element.show_manufacturer | keyvalue)?.length > 0"><strong>Show Manufacturer :</strong>{{element.show_manufacturer?.__old}}<br></span>
          <span *ngIf="(element.default_weight_unit | keyvalue)?.length > 0"><strong>Default Weight :</strong>{{element.default_weight_unit?.__old}}<br></span>
          <span *ngIf="(element.default_height_unit | keyvalue)?.length > 0"><strong>Default Height :</strong>{{element.default_height_unit?.__old}}<br></span>
          <span *ngIf="ProfileflagsValidate(element.profile_flags)?.value"><strong>Profile Flags <strong *ngIf="ProfileflagsValidate(element.profile_flags).action === '+'">Unchecked</strong><strong *ngIf="ProfileflagsValidate(element.profile_flags).action !=='+'">Checked</strong>:</strong>{{oldChangeContent(element.profile_flags)}}<br></span>
           <span *ngIf="chatflagsValidate(element.profile_flags)?.value"><strong>Selected Chat Access <strong *ngIf="chatflagsValidate(element.profile_flags).action === '+'">Unchecked</strong><strong *ngIf="chatflagsValidate(element.profile_flags).action !=='+'">Checked</strong>:</strong>{{oldChatFlagContent(element.profile_flags)}}<br></span>
           <span *ngIf="diaryflagsValidate(element.profile_flags)?.value"><strong>Selected Diary Access <strong *ngIf="diaryflagsValidate(element.profile_flags).action === '+'">Unchecked</strong><strong *ngIf="diaryflagsValidate(element.profile_flags).action !=='+'">Checked</strong>:</strong>{{oldDiaryFlagContent(element.profile_flags)}}<br></span>
           <span *ngIf="healthDetailflagsValidate(element.profile_flags)"><strong>Health Detail <strong *ngIf="healthDetailflagsValidate(element.profile_flags).action === '+'">Unchecked</strong><strong *ngIf="healthDetailflagsValidate(element.profile_flags).action !=='+'">Checked</strong>:</strong>{{oldHealthFlagContent(element.profile_flags)}}<br></span>
           <span *ngIf="(element.pro_flags | keyvalue)?.length > 0"><strong>Selected Profile Access Unchecked:</strong>{{element.pro_flags?.__old}}<br></span>
           <span *ngIf="(element.show_medsurvey | keyvalue)?.length > 0"><strong> Show Medication Survey :</strong>{{element.show_medsurvey?.__old}}<br></span>
           <span *ngIf="(element.max_upload_limit | keyvalue)?.length > 0"><strong>Prescription Count :</strong>{{element.max_upload_limit?.__old}}<br></span>
           <span *ngIf="(element.duration | keyvalue)?.length > 0"><strong>Duration :</strong>{{element.duration?.__old}}<br></span>


        </td>
      </ng-container>
      <ng-container matColumnDef="__new">
        <th mat-header-cell *matHeaderCellDef>New Value</th>="element.is_active ? 'circle green' : 'circle red'"  class="col-md-3">
        <td mat-cell *matCellDef="let element" class="camelcased"  class="col-md-3">  
          <span *ngIf="(element.title | keyvalue)?.length > 0"><strong>Title : </strong>{{element.title?.__new}}<br></span>
          <span *ngIf="(element.detailed_text | keyvalue)?.length > 0"><strong>Description :</strong>{{element.detailed_text?.__new}}<br></span>
          <span *ngIf="(element.is_paid | keyvalue)?.length > 0"> <strong>Is Paid :</strong>{{element.is_paid?.__new}}<br></span>
          <span *ngIf="(element.type | keyvalue)?.length > 0"><strong>Type :</strong>{{element.type?.__new}}<br></span>
          <span *ngIf="(element.has_coach_access | keyvalue)?.length > 0"><strong>Has healthcoach Access :</strong>{{element.has_coach_access?.__new}}<br></span>
          <span *ngIf="(element.coach_ids | keyvalue)?.length > 0"><strong>Selected Healthcoach :</strong>{{NewHC(element.new_data.coach_ids)}}<br></span>
          <span *ngIf="(element.is_test | keyvalue)?.length > 0"> <strong> Is Test Campaign :</strong>{{element.is_test?.__new}}<br></span>
          <span *ngIf="(element.has_client | keyvalue)?.length > 0"><strong>Has client :</strong>{{element.has_client?.__new}}<br></span>
          <span *ngIf="(element.client_data | keyvalue)?.length > 0"><strong>Selected Client :</strong>{{selectedNewClient(element.client_data?.__new)}}<br></span>
          <span *ngIf="(element.start_date | keyvalue)?.length > 0"><strong> Start Date :</strong>{{element.start_date?.__new | date: 'dd/MM/yyyy'}}<br></span>
          <span *ngIf="(element.has_end_date | keyvalue)?.length > 0">  <strong>Has End Date :</strong>{{element.has_end_date?.__new | date: 'dd/MM/yyyy'}}<br></span>
          <span *ngIf="(element.end_date | keyvalue)?.length > 0"><strong> End Date :</strong>{{element.end_date?.__new | date: 'dd/MM/yyyy'}}<br></span>
          <span *ngIf="(element.has_doctor_access | keyvalue)?.length > 0"><strong>Has Doctor Access :</strong>{{element.has_doctor_access?.__new}}<br></span>
          <span *ngIf="(element.doctor_data | keyvalue)?.length > 0"><strong>Selected Doctor :</strong>{{selectedNewDoctor(element.doctor_data?.__new)}}<br></span>         
          <span *ngIf="(element.therapy_data_fk | keyvalue)?.length > 0"> <strong>Selected Therapy :</strong> {{newTherapy(element.therapy_data_fk?.__new) }}<br></span>
          <span *ngIf="(element.show_support_phone_number | keyvalue)?.length > 0"> <strong>   Show Support Phone Number :</strong>{{element.show_support_phone_number?.__new}}<br></span>
          <span *ngIf="(element.show_support_email_id | keyvalue)?.length > 0"> <strong>Show Support Email :</strong> {{element.show_support_email_id?.__new}}<br></span>
          <span *ngIf="(element.show_questionnaire | keyvalue)?.length > 0"> <strong>Show Questionnaire :</strong>{{element.show_questionnaire?.__new}}<br></span>        
          <span *ngIf="(element.show_triggers | keyvalue)?.length > 0">   <strong> Show Trigger :</strong>{{element.show_triggers?.__new}}<br></span>
          <span *ngIf="(element.show_aqi | keyvalue)?.length > 0"> <strong>Show AQI :</strong>{{element.show_aqi?.__new}}<br></span>
          <span *ngIf="(element.show_bmi | keyvalue)?.length > 0"><strong> Show BMI :</strong>{{element.show_bmi?.__new}}<br></span>
          <span *ngIf="(element.pump_access | keyvalue)?.length > 0"><strong>Pump Access :</strong>{{element.pump_access?.__new}}<br></span>
          <span *ngIf="(element.medication_access | keyvalue)?.length > 0"><strong>Medication Access :</strong>{{element.medication_access?.__new}}<br></span>
          <span *ngIf="(element.lab_report_access | keyvalue)?.length > 0"><strong>Lab Report Access :</strong>{{element.lab_report_access?.__new}}<br></span>
          <span *ngIf="(element.show_history | keyvalue)?.length > 0"><strong>Show history :</strong>{{element.show_history?.__new}}<br></span>
          <span *ngIf="(element.show_utilities | keyvalue)?.length > 0"><strong>Show Utilities :</strong>{{element.show_utilities?.__new}}<br></span>
          <span *ngIf="(element.remodulin | keyvalue)?.length > 0"><strong>Remodulin :</strong>{{element.remodulin?.__new}}<br></span>
          <span *ngIf="(element.is_remodulin | keyvalue)?.length > 0"><strong>Remodulin :</strong>{{element.is_remodulin?.__new}}<br></span>
          <span *ngIf="(element.is_nonremodulin | keyvalue)?.length > 0"><strong>Non Remodulin :</strong>{{element.is_nonremodulin?.__new}}<br></span>
          <span *ngIf="(element.use_geolocation | keyvalue)?.length > 0"><strong>Used Geolocation :</strong>{{element.use_geolocation?.__new}}<br></span>
          <span *ngIf="(element.show_manufacturer | keyvalue)?.length > 0"><strong>Show Manufacturer :</strong>{{element.show_manufacturer?.__new}}<br></span>
          <span *ngIf="(element.default_weight_unit | keyvalue)?.length > 0"><strong>Default Weight :</strong>{{element.default_weight_unit?.__new}}<br></span>
          <span *ngIf="(element.default_height_unit | keyvalue)?.length > 0"><strong>Default Height :</strong>{{element.default_height_unit?.__new}}<br></span>
          <span *ngIf="ProfileflagsValidate(element.profile_flags)?.value"><strong>Profile Flags <strong *ngIf="ProfileflagsValidate(element.profile_flags).action === '+'">Checked</strong><strong *ngIf="ProfileflagsValidate(element.profile_flags).action !== '+'">Unchecked</strong>:</strong>{{newlyChangeContent(element.profile_flags)}}<br></span>
           <span *ngIf="chatflagsValidate(element.profile_flags)?.value"><strong>Selected Chat Access <strong *ngIf="chatflagsValidate(element.profile_flags).action === '+'">Checked</strong><strong *ngIf="chatflagsValidate(element.profile_flags).action !== '+'">Unchecked</strong>:</strong>{{newChatFlagContent(element.profile_flags)}}<br></span>
           <span *ngIf="diaryflagsValidate(element.profile_flags)?.value"><strong>Selected Diary Access <strong *ngIf="diaryflagsValidate(element.profile_flags).action === '+'">Checked</strong><strong *ngIf="diaryflagsValidate(element.profile_flags).action !== '+'">Unchecked</strong>:</strong>{{newDiaryFlagContent(element.profile_flags)}}<br></span>
           <span *ngIf="healthDetailflagsValidate(element.profile_flags?.value)"><strong>Health Detail <strong *ngIf="healthDetailflagsValidate(element.profile_flags).action === '+'">Checked</strong><strong *ngIf="healthDetailflagsValidate(element.profile_flags).action !== '+'">Unchecked</strong>:</strong>{{newHealthFlagContent(element.profile_flags)}}<br></span>
           <span *ngIf="(element.pro_flags | keyvalue)?.length > 0"><strong>Seleceted Profile Access Checked:</strong>{{element.pro_flags?.__new}}<br></span>
           <span *ngIf="(element.show_medsurvey | keyvalue)?.length > 0"> <strong>Show Medication Survey :</strong>{{element.show_medsurvey?.__new}}<br></span>
           <span *ngIf="(element.max_upload_limit | keyvalue)?.length > 0"><strong>Prescription Count :</strong>{{element.max_upload_limit?.__new}}<br></span>
           <span *ngIf="(element.duration | keyvalue)?.length > 0"><strong>Duration :</strong>{{element.duration?.__new}}<br></span>
        </td>
      </ng-container>   
      
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef  class="col-md-1"> Updated By </th>
        <td mat-cell *matCellDef="let element" class="camelcased"  class="col-md-1">
          <span> {{element.name}}</span>     
        </td>
      </ng-container> 

      <ng-container matColumnDef="caregiver_updated_date">
        <th mat-header-cell *matHeaderCellDef  class="col-md-1"> Date&Time </th>
        <td mat-cell *matCellDef="let element" class="camelcased"  class="col-md-1">
          <span> {{element.created_at | date: 'dd/MM/yyyy'}}</span><br>
          <span style="font-size: 10px;"> {{element.created_at | date: 'h:mm a'}}</span>
        </td>
      </ng-container>    
     

      <tr mat-header-row *matHeaderRowDef="caregiverColumns;sticky:true"></tr>
      <tr mat-row *matRowDef="let element; columns: caregiverColumns;" class="example-element-row"></tr>
    </table>
  <!-- No data found -->
    <div *ngIf="noDataFound" style="text-align: center;margin-top: 30%;">
      <span>
        No Data Found
      </span>
    </div>
  </div>





  <!--  <div class="" *ngIf="tabulardata.length == 0">
      <p class="center">No transfer history found for this user</p>
    </div> -->
