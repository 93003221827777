import { CommonserviceService } from './../../../../admin/services/commonservice/commonservice.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-condition',
  templateUrl: './edit-condition.component.html',
  styleUrls: ['./edit-condition.component.scss']
})

export class EditConditionComponent implements OnInit {
  public conditionid: any;
  public alllanguages: any;
  public control1: any;
  public filterlanguages: any;
  public languagedata: any;
  public dataForm = this.fb.group({
    detailed_text: ['', [Validators.required]],
    language_translation: this.fb.array([]),
    created_by_fk: ['']
  });

  constructor(private router: Router, private route: ActivatedRoute, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService,private commonService:CommonserviceService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.conditionid = params.id;
      this.getAllLanguages();
    })
  }


  // To Get Conditions 
  // ----------------------------

  getConditionData(id) {
    this.contentService
      .getOneDataById(id, 'condition')
      .subscribe(
        (res: any) => this.getConditionsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsSuccess(data) {
    const control = <FormArray>this.dataForm.controls['language_translation'];
    data = (data as any).data;
    this.languagedata = data;
    if(this.alllanguages){
      if (!this.languagedata.language_translation) {
        this.generateOptions(this.alllanguages);
      }
      else if (this.languagedata.language_translation.length != 0) {
        for (var i = 0; i < this.languagedata.language_translation.length; i++) {
          control.push(this.initTypeDataOptions(this.languagedata.language_translation[i].text, this.languagedata.language_translation[i].id));
        }
      }
      else {
        this.generateOptions(this.alllanguages);
      }
    }
    this.dataForm.patchValue(this.languagedata);
  }

  // ----------------------------

  initTypeOptions(id) {
    return this.fb.group({
      text: ['', [Validators.required]],
      id: [id]
    });
  }

  initTypeDataOptions(text, id) {
    return this.fb.group({
      text: [text],
      id: [id]
    });
  }

  getLang(data) {
    for (var i = 0; i < this.alllanguages.length; i++) {
      if (data == this.alllanguages[i].id) {
        return this.alllanguages[i].name;
      }
    }
  }

  generateOptions(alllanguages) {
    var language = [];
    language = alllanguages;
    const control = <FormArray>this.dataForm.controls['language_translation'];
    for (var i = 0; i < language.length; i++) {
      if (language[i].id != 1) {
        control.push(this.initTypeOptions(language[i].id));
      }
    }
  }


  // To Get Languages 
  // ----------------------------
  
  getAllLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.getConditionData(this.conditionid);
  }

  // ----------------------------


  // Submitting Conditions Data 
  // ----------------------------

  dataOject() {
    var postdata = this.dataForm.value;
    let detailed_text = this.commonService.isHTML( this.dataForm.value.detailed_text);
    let language_translation = this.commonService.isHTML( this.dataForm.value.language_translation);
   
    if(detailed_text  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    } 
    if(language_translation  === true){
      this.dataForm.controls.language_translation.patchValue('')
      return;
    } 
    postdata.language_translation = JSON.stringify(postdata.language_translation);
    postdata.created_by_fk = localStorage.getItem('scarletuserid').toString();
    this.contentService
      .updateCondition(postdata, 'condition', this.conditionid)
      .subscribe(
        (res: any) => this.onUpdateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Condition has been updated"), "OK");
    this.router.navigate(['content/conditionlist']);
  }
  // ----------------------------

}
