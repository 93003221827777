import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../shared/guards';
import { PaymentmappingComponent } from './paymentmapping.component';
import { EditpaymentmappingComponent } from './editpaymentmapping/editpaymentmapping.component';

const routes: Routes = [
  { path: '', component: PaymentmappingComponent, canActivate: [AdminGuardGuard] },
  { path: 'paymentmapping/edit/:id', component: EditpaymentmappingComponent, canActivate: [AdminGuardGuard] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentmappingRoutingModule {}