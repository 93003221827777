import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { MobileNumberChangeAPIService } from 'src/app/shared/services/mobile-number-change-api.service';

@Component({
  selector: 'app-phone-number-change-log',
  templateUrl: './phone-number-change-log.component.html',
  styleUrls: ['./phone-number-change-log.component.scss']
})


export class PhoneNumberChangeLogComponent implements OnInit {
  mySource = [];
  displayedColumns: string[] =  ['id', "newNumber", "reasonHC", "healthCoach", "status","adminName", "reasonAdmin", "dateAndTime"];
  pageNumber = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PhoneNumberChangeLogComponent>,
    private mobileApiService: MobileNumberChangeAPIService,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {

    this.callLogApi();

  }

  callLogApi(){
    this.mobileApiService.getUserPhoneChangeLog(this.data, this.pageNumber).subscribe((data) => {
      if(data.status === 200) {
        this.mySource = [...this.mySource, ...data.data];
      }
    }, (error) => {
      this.systemService.handleErrorResponse(error);
    }); 
  }

  close(){
    this.dialogRef.close();
  }

  convertDate(date) {
    var d = new Date(date);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()
    
    return currentdate + ' ' + month + ' ' + year + ', ' + d.toLocaleTimeString();;

  }

  getNextSet(){
    this.pageNumber += 1;
    this.callLogApi();
  }
}
