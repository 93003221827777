<div [hidden]="showqrlink == true">
    <div class="clearfix"><br /></div>
    <h5 class="activatetitlecss">All Offerings</h5>
    <div class="col-md-12 col-xs-12">
        <div class="col-xs-12 col-md-4 navigatorcontainercss qrcodegeneral" *ngFor="let camp of allCampaigns"
            (click)="dataObject(camp.id)">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <h5 class="text-capitalize">{{camp.title}}</h5>
                </div>
            </div>
        </div>
        <div class="clearfix"><br /></div>
    </div>
    <div class="clearfix"><br /></div>
</div>
<div class="sidenav-content" [hidden]="showqrlink != true">
    <div id="content">
        <div class="col-md-12 actiobtncss">
            <div class="col-md-6">
                <button mat-icon-button class="commonbackbtn qrbckbtn" (click)="openLink($event)"><i
                        class="material-icons">keyboard_arrow_left</i></button>
            </div>
            <div class="col-md-6" style="padding-right: 0;">
                <canvas [hidden]="true" #canvas></canvas>
                <button mat-icon-button class="commonbackbtn pull-right qrsharebtn" style="margin-left: 10px;"><i
                        class="material-icons">share</i></button>

                <button mat-icon-button class="commonbackbtn pull-right qrdownloadbtn" download="xyz.png"
                    (click)="downloadQR(campaignid)"><i class="material-icons">cloud_download</i></button>
            </div>
        </div>
        <div class="showdownload">
            <!-- <div class="bgsvg">
                <div class="bgsvg-inner">
                    <p class="stepsheading">STEP 1</p>
                    <p class="stepsdescription">Download the Wellthy Care App and sign in with your number.</p>
                    <p class="stepsheading stepsheadingmargin">STEP 2</p>
                    <p class="stepsdescription">Scan the QR code below</p>
                   <div class="overlay"></div>
                    <button class="downloadbutton" mat-stroked-button color="primary" (click)="downloadQR()">Download</button>
                </div>
            </div> -->
            <div class="qrbox">
                <img src="assets/img/wellthy_logo_2.png" class="wellthylogo" />
                <img [src]="imgurl" class="img-url" />
            </div>
            <div class="clearfix"><br /><br /></div>
        </div>
    </div>
</div>