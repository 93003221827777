import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { MobileNumberChangeAPIService } from 'src/app/shared/services/mobile-number-change-api.service';
import { NumberChangeActionComponent } from './number-change-action/number-change-action.component';

@Component({
  selector: 'app-mobile-change-number-request',
  templateUrl: './mobile-change-number-request.component.html',
  styleUrls: ['./mobile-change-number-request.component.scss']
})


export class MobileChangeNumberRequestComponent implements OnInit {

  public searchTerm = '';
  public dataSource: any = [];
  public displayedColumns: string[] = ['old_number', 'new_number', 'reason','created_at', 'request_by', 'status', 'action'];

  public pageNumber: number = 1;

  public admin;

  constructor(
    private apiService: MobileNumberChangeAPIService,
    private systemService: SystemService,
    private dialog: MatDialog, 
  ) {

    let adminData = JSON.parse(localStorage.getItem('scarletuserdata'))

    this.admin = {
      id: adminData.id, 
      name: adminData.name
    }
  }

  ngOnInit(): void {
    this.search();
  }

  textSearch(){
    this.pageNumber = 1;
    this.dataSource = [];
    this.search();
  }

  search(){
    this.apiService.getAllPhoneChangeRequest(this.searchTerm, this.pageNumber).subscribe((data) => {

      if (data.status === 200){
        this.dataSource = [...this.dataSource, ...data.data];
      }
      
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }

  getNextSet(){
    this.pageNumber += 1;
    this.search();
  }

  requestAction(requestId, action: number){

    const changeActionDialog = this.dialog.open(NumberChangeActionComponent, {
      data: {
        requestId: requestId, 
        action: action, 
        adminId: this.admin.id,
        adminName: this.admin.name
      }
    })

    changeActionDialog.afterClosed().subscribe((data) => {
      if (data){
        
        let source = [...this.dataSource];

        for(let i=0;i<source.length;i++){
          if (source[i].id === requestId){
            source[i].request_status = action;
            break;
          }
        }

        this.dataSource = [];
        this.dataSource = [...source];

      }
    })
  }
}
