import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as jsPDF from "jspdf";
import * as moment from 'moment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { finalize } from 'rxjs/internal/operators/finalize';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import * as html2canvas from '../../../../assets/js/html2canvas.js';
import { SHORT_MONTHS } from '../../../shared/data';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { UserListService } from '../../services/userlist/user-list.service';

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-doctorpdfreport',
  templateUrl: './doctorpdfreport.component.html',
  styleUrls: ['./doctorpdfreport.component.scss']
})
export class DoctorpdfreportComponent implements OnInit {
  public displayedColumns1: string[] = ['dateTime', 'value', 'recheckedValue'];
  public displayedColumns2: string[] = ['mostFrequentEvents', 'mostSevereEvents'];
  public displayedColumns3: string[] = ['tests', 'value1', 'value2', 'v1v2', 'normalRange'];
  public displayedColumns4: string[] = ['date', 'name', 'pump_route_type', 'reason'];
  public displayedColumns5: string[] = ['routeDate', 'routeOfAdministration', 'reasonOfAddition'];
  public displayedColumns6: string[] = ['innfusionDate', 'innfusionSite', 'reasonOfChange', 'interval'];
  public displayedColumns7: string[] = ['medicationDate', 'drugName', 'dose', 'flowRate'];
  public displayedColumns8: string[] = ['frequentEvent', 'mostSeverEvents'];
  dataSource1: any;
  dataSource2 = data2;
  // dataSource3 = data3;
  public displayedColumns: string[] = ['items', 'breakfast', 'lunch', 'dinner'];
  // dataSource = ELEMENT_DATA;
  public displayedColumnsActivity: string[] = ['target', 'achieved'];
  // public dataSourceActivity = ACTIVTY;
  public currentdate: any;
  public reportData: any;
  public bloodPressureData: any;
  private userID: any;
  public bloodSugarPostMealData: any;
  public fastingBloodSugarData: [];
  public symptomTrackingData = [];
  public fluidData: any = [];
  public mealTableData: any;
  public labReportTableData: any;
  public ActivityData: any;
  public profileData: any;
  public clientData: any;
  public weightBmiData: any;
  public bodyMeasurement:any;
  s3url: any = environment.s3url;
  public hcNote: any;
  public fluidIntakeData: any
  public fastingData: any = [];
  public fastingArrData: any = [];
  public symptomTrackingValues: any = []
  public postMealData: any = [];
  public bloodPressureValues: any = [];
  public hidePostMealGraph: boolean = false;
  public hideBPGraph: boolean = false;
  public hideFastingGraph: boolean = false;
  public hideSymptomGraph: boolean = false;
  public hideFluidGraph: boolean = false;
  public symptomsOccuredData: any;
  public labreportValues: any = [];
  public daterange: any = {};
  public datediff: any;
  hypoglycemicdata: any;
  public timeleftval: any;
  userAge: any;
  public ferrerStatus: boolean = false;
  public abbottUser: boolean = false;
  public otherClient: boolean = false;
  public medicationData: any = [];
  public infusionSiteData: any = [];
  public pumpData: any;
  public routeOfAdministrationData: any = [];
  public symptomData: any = [];
  public labReportData: any = [];
  public hcNoteData: any = [];
  public prescriptionData: any = [];

  constructor(private browserTitle: Title, private systemService: SystemService, private route: ActivatedRoute, private doctorService: DoctorDataService,private userService : UserListService) { }

  ngOnInit(): void {
    var patientClinet = localStorage.getItem('patientClient');
    // if(patientClinet == 'Ferrer'){
    //   this.ferrerStatus = true;
    // }else if(patientClinet == 'Abbott'){
    //    this.abbottUser = true;
    // }else{
    //   this.otherClient = true;
    // }
    this.setSEOTags();
    this.route.params.subscribe((params) => {
      this.userID = params.id;
      this.daterange['startdate'] = (new Date(parseInt(params.startdate))).toISOString();
      this.daterange['enddate'] = (new Date(parseInt(params.enddate))).toISOString();
      this.datediff = moment(parseInt(params.enddate)).diff(moment(parseInt(params.startdate)), 'days')
      this.reportData = '';
      this.getGraphData(this.daterange);
      // this.getFerrerData(this.daterange);
      // this.getAbbottData(this.daterange);
      this.countdown()
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Doctor | Report');
  }

  countdown() {
    var timeleft = 5;
    var scope = this;
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        scope.timeleftval = "Finished";
      } else {
        scope.timeleftval = "Report will be downloaded in " + timeleft + " Sec";
      }
      timeleft -= 1;
    }, 1000);
  }

  convertIsoData(d) {
    var date = new Date(d);
    let options:any = { weekday: 'long', year: 'numeric', month: 'long' };
    return date.toLocaleDateString(undefined, options);

  }

  startWithZero(n) {
    return n > 10 ? "" + n : "0" + n;
  }
  convertIsoDatetime(d) {
    var date = new Date(d);
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleTimeString('en-US');
  }


  pdfDownload() {
    
    // document.getElementById("section1").style.display = "block";
    var scope = this;
    let data = document.getElementById('section1');
    var w = data.offsetWidth;
    var h = data.offsetHeight;
    var options = {
      allowTaint: false,
      useCORS: true,
      dpi: 96
    }

    let data1 = document.getElementById('section2');
    var w1 = data1.offsetWidth;
    var h1 = data1.offsetHeight;
    var options1 = {
      allowTaint: false,
      useCORS: true,
      dpi: 96
    }

    let data2 = document.getElementById('section3');
    var w2 = data2.offsetWidth;
    var h2 = data2.offsetHeight;
    var options2 = {
      allowTaint: false,
      useCORS: true,
      dpi: 96
    }

    html2canvas(data, options).then(canvas => {
      var img = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var doc = new jsPDF('P', 'mm', 'a4', true);
      doc.addImage(img, 'JPG', 0, 0, imgWidth, imgHeight, 'FAST');
      html2canvas(data1, options1).then(canvas1 => {
        var img1 = canvas1.toDataURL("image/png");
        var imgWidth1 = 210;
        var imgHeight1 = canvas1.height * imgWidth1 / canvas1.width;
        doc.addPage();
        doc.addImage(img1, 'JPG', 0, 0, imgWidth1, imgHeight1, undefined, 'FAST');
        html2canvas(data2, options2).then(canvas2 => {
          var img2 = canvas2.toDataURL("image/png");
          var imgWidth2 = 210;
          var imgHeight2 = canvas2.height * imgWidth2 / canvas2.width;
          doc.addPage();
          doc.addImage(img2, 'JPG', 0, 0, imgWidth2, imgHeight2, undefined, 'FAST');
          doc.save(scope.profileData.name + '.pdf');
          // window.close()
        });
      });
    });
    // document.getElementById("pdf").style.display = "none";
  }

  getGraphData(postdata) {
    var startd = postdata.startdate;
    var endd = postdata.enddate;
    var dep = moment(startd).toISOString();
    var pep = moment(endd).endOf('day');
    this.doctorService.getDoctorReport(dep, pep.toISOString(), this.userID)
      .pipe(
        catchError((error: any) => {
          this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
          return throwError(error);
        }),
        finalize(() => { })
      ).subscribe(
        (data) => {
          
          var postMealData = [];
          // var fastingData = [];
          this.reportData = data;
          
          this.hypoglycemicdata = this.reportData.data.blood_sugar.hypoglycemic_events;
          this.bloodPressureData = this.reportData.data.blood_pressure;
          this.bloodSugarPostMealData = this.reportData.data.blood_sugar;
          this.fluidIntakeData = this.reportData.data.fluid_data;
          this.weightBmiData = this.reportData.data.patient_bmi_data;
          this.bodyMeasurement=this.reportData.data.body_measurement;
          this.symptomTrackingValues = this.reportData.data.symptom.questionnaire_tracking;
          var fluidKey = [];
          var fluidVal = [];
          for (var i = 0; i < this.reportData.data.fluid_data.length; i++) {
            if (this.reportData.data.fluid_data[i].value != 0) {
              this.fluidData.push(this.reportData.data.fluid_data[i]);
              fluidKey.push(this.convertDate(this.reportData.data.fluid_data[i].key));
              fluidVal.push(this.reportData.data.fluid_data[i].value);
            }
          }

          for (var j = 0; j < this.bloodSugarPostMealData.post_meal.length; j++) {
            if (this.bloodSugarPostMealData.post_meal[j].value.value != "0") {
              this.postMealData.push(this.bloodSugarPostMealData.post_meal[j]);
            }
          }
          
          var fastingKey = [];
          var fastingval = [];
          var fastingArrData = [];
          for (var k = 0; k < this.reportData.data.blood_sugar.fasting.length; k++) {
            if (this.reportData.data.blood_sugar.fasting[k].value.value != "0") {
              this.fastingData.push(this.reportData.data.blood_sugar.fasting[k]);
              fastingKey.push(this.convertDate(this.reportData.data.blood_sugar.fasting[k].key));
              fastingval.push(this.reportData.data.blood_sugar.fasting[k].value.value);
            }
          }

          this.reportData.data.blood_sugar.fasting.forEach((item) => {
            var existing = this.fastingArrData.filter((v, i) => {
              return v.key == item.key;
            });
            if (existing.length) {
              var existingIndex = this.fastingArrData.indexOf(existing[0]);
              if (item.value.meal_type == "fasting" && item.value.meal_type != 0) {
                this.fastingArrData[existingIndex].value = this.fastingArrData[existingIndex].value.concat(item.value);
              }
            } else {
              if (item.value.meal_type == "fasting" && item.value.meal_type != 0) {
                item.value = [item.value];
                this.fastingArrData.push(item);
              }
            }
          });

          for (var l = 0; l < this.symptomTrackingValues.length; l++) {
            if (this.symptomTrackingValues[l].value != 0) {
              this.symptomTrackingData.push(this.symptomTrackingValues[l]);
            }
          }

          var pressureBottomLabel = [];
          var systolicValues = [];
          var diastolicValues = [];
          var heartRateValues = [];

          for (var p = 0; p < this.bloodPressureData.length; p++) {
            if (this.bloodPressureData[p].value.systolic != 0) {
              systolicValues.push(this.bloodPressureData[p].value.systolic);
              diastolicValues.push(this.bloodPressureData[p].value.diastolic)
              pressureBottomLabel.push(this.convertDate(this.bloodPressureData[p].key));
              heartRateValues.push(this.bloodPressureData[p].value.heart_rate);
            }
          }
          this.bloodPressureValues = systolicValues;

          if (data) {
            this.mealDataModification(this.reportData);
            this.labReportDataModification(this.reportData);
            this.ActivityDataModification(this.reportData);
            this.symptomsOccuredDataModification(this.reportData);
            this.hypoglycemicData(this.hypoglycemicdata)
          }
          this.profileData = this.reportData.data.meta_data;
          this.userAge = this.userService.getAge(this.reportData.data.meta_data.dob)
          this.clientData = this.reportData.data.client_data;
          

          this.hcNote = this.reportData.data.hc_note_data;

          if (this.fluidData.length != 0) {
            this.fluidIntakeChart(fluidKey, fluidVal);
            this.hideFluidGraph = true;
          }
          else {
            this.hideFluidGraph = false;
          }

          if (this.postMealData.length != 0) {
            this.postMealSugar(this.postMealData);
            this.hidePostMealGraph = true;
          }
          else {
            this.hidePostMealGraph = false;
          }

          if (pressureBottomLabel.length != 0) {
            this.bloodPressure(pressureBottomLabel, systolicValues, diastolicValues, heartRateValues);
            this.hideBPGraph = true;
          }
          else {
            this.hideBPGraph = false;
          }
          /*if (this.fastingData.length != 0) {
            this.fastingBloodSugar(fastingKey, fastingval);
            this.hideFastingGraph = true;
          }
          else {
            this.hideFastingGraph = false;
          }*/
          if (this.fastingArrData.length != 0) {
            this.fastingBloodSugar(this.fastingArrData);
            this.hideFastingGraph = true;
          }
          else {
            this.hideFastingGraph = false;
          }
          if (this.symptomTrackingData.length != 0) {
            this.symptomTracking(this.symptomTrackingData);
            this.hideSymptomGraph = true;
          }
          else {
            this.hideSymptomGraph = false;
          }
        })
  }

  hypoglycemicData(data) {
    this.dataSource1 = new MatTableDataSource<Element>(data);
  }

  symptomsOccuredDataModification(data) {
    let symptomData = [];
    let frequentAndSevereData = {};
    if (data.data && data.data.symptom &&
      data.data.symptom.frequent && data.data.symptom.frequent.length > 0) {
      let symptomFrequent = data.data.symptom.frequent;
      let symptomSevere = data.data.symptom.severe;
      symptomFrequent.forEach((element, i) => {
        frequentAndSevereData = {
          frequent_symptom_title: element.symptom_title,
          frequent_occurance: element.occurance
        }
        if (symptomSevere && symptomSevere.length > 0) {
          symptomSevere.forEach((ele, j) => {
            if (i == j) {
              frequentAndSevereData["severe_symptom_title"] = ele.symptom_title;
              frequentAndSevereData["severe_occurance"] = ele.occurance;
            }
          });
        }
        symptomData.push(frequentAndSevereData);
      });
      this.symptomsOccuredData = symptomData;
    }
  }
  mealDataModification(data) {
    let meal = [];
    if (data.data && data.data.meal_data) {
      let mealData = data.data.meal_data;
      for (let key in mealData) {
        let keyData = key == "carbohydrates" ? "Carb" : key == "fibres" ? "Fibre" :
          key == "fats" ? "Fat" : key == "proteins" ? "Protein" : key;
        mealData[key]["item"] = keyData;
        meal.push(mealData[key]);
      }
    }
    this.mealTableData = meal;
  }

  rangeDataCss(v1, v2, type) {
    
    if (type == 'CBC ESR') {
      if ((v1 < 0 || v1 > 20) || (v2 < 0 || v2 > 20)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'BNP') {
      if (v1 > 100 || v2 > 100) {
        return true;
      }
      else {
        return false;
      }
    }

    if (type == 'Blood Urea') {
      if ((v1 < 8 || v1 > 23) || (v2 < 8 || v2 > 23)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'CBC MCH') {
      if ((v1 < 27 || v1 > 33) || (v2 < 27 || v2 > 33)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'CBC MCHC') {
      if ((v1 < 33 || v1 > 37) || (v2 < 33 || v2 > 37)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'CBC MCV') {
      if ((v1 < 80 || v1 > 100) || (v2 < 80 || v2 > 100)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Mean Platelet Volume') {
      if ((v1 < 6.5 || v1 > 12) || (v2 < 6.5 || v2 > 12)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Platelet Count') {
      if ((v1 < 150 || v1 > 400) || (v2 < 150 || v2 > 400)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'CBC RBC') {
      if ((v1 < 4.5 || v1 > 5.5) || (v2 < 4.5 || v2 > 5.5)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'CBC RDW') {
      if ((v1 < 39 || v1 > 46) || (v2 < 39 || v2 > 46)) {
        return true;
      }
      else {
        return false;
      }
    }

    if (type == 'CBC WBC Differential Count') {

    }
    if (type == 'Cholesterol') {

    }
    if (type == 'Coagulation Factor') {

    }
    if (type == 'CBC WBC') {
      if ((v1 < 4 || v1 > 10) || (v2 < 4 || v2 > 10)) {
        return true;
      }
      else {
        return false;
      }
    }

    if (type == 'eGFR') {

      if (v1 < 90 || v2 < 90) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Electrolytes') {

    }
    if (type == 'Haemoglobin') {
      if ((v1 < 12 || v1 > 17) || (v2 < 12 || v2 > 17)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'HBA1C') {
      if (v1 > 5.7 || v2 > 5.7) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'NT Pro BNP') {
      if (v1 > 300 || v2 > 300) {
        return true;
      }
      else {
        return false;
      }

    }
    if (type == 'Serum Creatinine') {
      if ((v1 < 0.7 || v1 > 1.3) || (v2 < 0.7 || v2 > 1.3)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Serum Uric Acid') {
      if ((v1 < 4 || v1 > 8.5) || (v2 < 4 || v2 > 8.5)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Thyroid Function') {

    }
    if (type == 'Urine Albumin') {
      if (v1 > 30 || v2 > 30) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Urine Creatinine') {
      if ((v1 < 1000 || v1 > 2000) || (v2 < 1000 || v2 > 2000)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Urine Uric Acid') {

      if ((v1 < 250 || v1 > 750) || (v2 < 250 || v2 > 750)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Spirometry') {

    }
    if (type == 'FeNO Test') {
      if ((v1 < 25 || v1 > 50) || (v2 < 25 || v2 > 50)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'IgE') {
      if ((v1 < 10 || v1 > 179) || (v2 < 10 || v2 > 179)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Pulse Oximetry') {
      if ((v1 < 95 || v1 > 100) || (v2 < 95 || v2 > 100)) {
        return true;
      }
      else {
        return false;
      }
    }




    if (type == 'LDL') {
      if (v1 > 130 || v2 > 130) {
        return true;
      }
      else {
        return false;
      }

    }
    if (type == 'HDL') {
      if (v1 < 40 || v2 < 40) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'APTT') {
      if ((v1 < 25 || v1 > 40) || (v2 < 25 || v2 > 40)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Fibrinogen') {
      if ((v1 < 150 || v1 > 400) || (v2 < 150 || v2 > 400)) {
        return true;
      }
      else {
        return false;
      }

    }
    if (type == 'Bicarbonate') {
      if ((v1 < 23 || v1 > 28) || (v2 < 23 || v2 > 28)) {
        return true;
      }
      else {
        return false;
      }

    }
    if (type == 'Chloride') {
      if ((v1 < 98 || v1 > 106) || (v2 < 98 || v2 > 106)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Potassium') {
      if ((v1 < 3.5 || v1 > 5) || (v2 < 3.5 || v2 > 5)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Sodium') {
      if ((v1 < 136 || v1 > 145) || (v2 < 136 || v2 > 145)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Basophil') {
      if (v1 > 2 || v2 > 2) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Eosinophil') {
      if ((v1 < 0 || v1 > 0.6) || (v2 < 0 || v2 > 0.6)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Immature Granulocyte') {
      if ((v1 < 0 || v1 > 0.5) || (v2 < 0 || v2 > 0.5)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Lymphocyte') {
      if ((v1 < 20 || v1 > 40) || (v2 < 20 || v2 > 40)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Monocyte') {
      if ((v1 < 0 || v1 > 10) || (v2 < 0 || v2 > 10)) {
        return true;
      }
      else {
        return false;
      }
    }
    if (type == 'Neutrophile') {
      if ((v1 < 40 || v1 > 80) || (v2 < 40 || v2 > 80)) {
        return true;
      }
      else {
        return false;
      }
    }

  }


  labReportDataModification(data) {
    let labData = [];
    let labReportData = [];
    let lab;
    var scope = this;
    this.labreportValues = data.data.lab_report_data;
    
    if (data.data && data.data.lab_report_data) {
      labData = data.data.lab_report_data;
      labData.forEach((obj) => {
        if (obj.log_data.length > 0) {
          if (multiData.includes(obj.lab_report)) {
            if (obj.lab_report == 'cholesterol') {
              let ldlData = [];
              let hdlData = [];
              obj.log_data.forEach((ele) => {
                let ldl_Data = { id: ele.id, measure: ele.ldl, unit: ele.hdl_unit, lab_report_title: 'LDL', lab_report: 'ldl', log_date: ele.log_date };
                let hdl_Data = { id: ele.id, measure: ele.hdl, unit: ele.hdl_unit, lab_report_title: 'HDL', lab_report: 'hdl', log_date: ele.log_date };
                ldlData.push(ldl_Data);
                hdlData.push(hdl_Data);
              });
              let ldlFinalData = {
                lab_report_title: ldlData[0].lab_report_title,
                lab_report: ldlData[0].lab_report,
                unit: ldlData[0].unit,
                log_date: (ldlData[0].log_date) ? ((ldlData[0].log_date).substring(0, ldlData[0].log_date.indexOf('T'))) : (ldlData[0].log_date),
                value1: ldlData[0],
                value2: ldlData[1],
                range: rangedata[ldlData[0].lab_report],
                rangeBoldData: this.rangeDataCss(ldlData[0]?.measure, ldlData[1]?.measure, 'LDL')
              }
              labReportData.push(ldlFinalData);
              let hdlFinalData = {
                lab_report_title: hdlData[0].lab_report_title,
                lab_report: hdlData[0].lab_report,
                unit: hdlData[0].unit,
                log_date: (hdlData[0].log_date) ? ((hdlData[0].log_date).substring(0, hdlData[0].log_date.indexOf('T'))) : (hdlData[0].log_date),
                value1: hdlData[0],
                value2: hdlData[1],
                range: rangedata[hdlData[0].lab_report],
                rangeBoldData: this.rangeDataCss(hdlData[0]?.measure, hdlData[1]?.measure, 'HDL')
              }
              labReportData.push(hdlFinalData);

            }
            if (obj.lab_report == 'coagulation_factor') {
              let apttData = [];
              let fibrinogenData = [];
              obj.log_data.forEach((ele) => {
                let aptt_Data = { id: ele.id, measure: ele.aptt, unit: ele.aptt_unit, lab_report_title: 'APTT', lab_report: 'aptt', log_date: ele.log_date };
                let fibrinogen_Data = { id: ele.id, measure: ele.fibrinogen, unit: ele.fibrinogen_unit, lab_report_title: 'Fibrinogen', lab_report: 'fibrinogen', log_date: ele.log_date };
                apttData.push(aptt_Data);
                fibrinogenData.push(fibrinogen_Data);
              });
              let apttFinalData = {
                lab_report_title: apttData[0].lab_report_title,
                lab_report: apttData[0].lab_report,
                unit: apttData[0].unit,
                log_date: (apttData[0].log_date) ? ((apttData[0].log_date).substring(0, apttData[0].log_date.indexOf('T'))) : (apttData[0].log_date),
                value1: apttData[0],
                value2: apttData[1],
                range: rangedata[apttData[0].lab_report],
                rangeBoldData: this.rangeDataCss(apttData[0]?.measure, apttData[1]?.measure, 'APTT')

              }
              labReportData.push(apttFinalData);

              let fibrinogenFinalData = {
                lab_report_title: fibrinogenData[0].lab_report_title,
                lab_report: fibrinogenData[0].lab_report,
                unit: fibrinogenData[0].unit,
                log_date: (fibrinogenData[0].log_date) ? ((fibrinogenData[0].log_date).substring(0, fibrinogenData[0].log_date.indexOf('T'))) : (fibrinogenData[0].log_date),
                value1: fibrinogenData[0],
                value2: fibrinogenData[1],
                range: rangedata[fibrinogenData[0].lab_report],
                rangeBoldData: this.rangeDataCss(fibrinogenData[0]?.measure, fibrinogenData[1]?.measure, 'Fibrinogen')
              }
              labReportData.push(fibrinogenFinalData);
            }
            if (obj.lab_report == 'electrolytes') {
              let bicarbonateData = [];
              let chlorideData = [];
              let potassiumData = [];
              let sodiumData = [];

              obj.log_data.forEach((ele) => {
                let bicarbonate_Data = { id: ele.id, measure: ele.bicarbonate, unit: ele.bicarbonate_unit, lab_report_title: 'Bicarbonate', lab_report: 'bicarbonate', log_date: ele.log_date };
                let chloride_Data = { id: ele.id, measure: ele.chloride, unit: ele.chloride_unit, lab_report_title: 'Chloride', lab_report: 'chloride', log_date: ele.log_date };
                let potassium_Data = { id: ele.id, measure: ele.potassium, unit: ele.potassium_unit, lab_report_title: 'Potassium', lab_report: 'potassium', log_date: ele.log_date };
                let sodium_Data = { id: ele.id, measure: ele.sodium, unit: ele.sodium_unit, lab_report_title: 'Sodium', lab_report: 'sodium', log_date: ele.log_date };

                bicarbonateData.push(bicarbonate_Data);
                chlorideData.push(chloride_Data);
                potassiumData.push(potassium_Data);
                sodiumData.push(sodium_Data);

              });
              let bicarbonateFinalData = {
                lab_report_title: bicarbonateData[0].lab_report_title,
                lab_report: bicarbonateData[0].lab_report,
                unit: bicarbonateData[0].unit,
                log_date: (bicarbonateData[0].log_date) ? ((bicarbonateData[0].log_date).substring(0, bicarbonateData[0].log_date.indexOf('T'))) : (bicarbonateData[0].log_date),
                value1: bicarbonateData[0],
                value2: bicarbonateData[1],
                range: rangedata[bicarbonateData[0].lab_report],
                rangeBoldData: this.rangeDataCss(bicarbonateData[0]?.measure, bicarbonateData[1]?.measure, 'Bicarbonate')
              }
              labReportData.push(bicarbonateFinalData);

              let chlorideFinalData = {
                lab_report_title: chlorideData[0].lab_report_title,
                lab_report: chlorideData[0].lab_report,
                unit: chlorideData[0].unit,
                log_date: (chlorideData[0].log_date) ? ((chlorideData[0].log_date).substring(0, chlorideData[0].log_date.indexOf('T'))) : (chlorideData[0].log_date),
                value1: chlorideData[0],
                value2: chlorideData[1],
                range: rangedata[chlorideData[0].lab_report],
                rangeBoldData: this.rangeDataCss(chlorideData[0]?.measure, chlorideData[1]?.measure, 'Chloride')

              }
              labReportData.push(chlorideFinalData);

              let potassiumFinalData = {
                lab_report_title: potassiumData[0].lab_report_title,
                lab_report: potassiumData[0].lab_report,
                unit: potassiumData[0].unit,
                log_date: (potassiumData[0].log_date) ? ((potassiumData[0].log_date).substring(0, potassiumData[0].log_date.indexOf('T'))) : (potassiumData[0].log_date),
                value1: potassiumData[0],
                value2: potassiumData[1],
                range: rangedata[potassiumData[0].lab_report],
                rangeBoldData: this.rangeDataCss(potassiumData[0]?.measure, potassiumData[1]?.measure, 'Potassium')

              }
              labReportData.push(potassiumFinalData);

              let sodiumFinalData = {
                lab_report_title: sodiumData[0].lab_report_title,
                lab_report: sodiumData[0].lab_report,
                unit: sodiumData[0].unit,
                log_date: (sodiumData[0].log_date) ? ((sodiumData[0].log_date).substring(0, sodiumData[0].log_date.indexOf('T'))) : (sodiumData[0].log_date),
                value1: sodiumData[0],
                value2: sodiumData[1],
                range: rangedata[sodiumData[0].lab_report],
                rangeBoldData: this.rangeDataCss(sodiumData[0]?.measure, sodiumData[1]?.measure, 'Potassium')

              }
              labReportData.push(sodiumFinalData);

            }
            if (obj.lab_report == 'thyroid_function') {
              
            }
            if (obj.lab_report == 'spirometry') {
              
            }
            if (obj.lab_report == 'cbc_wbc_differential_count') {
              let basophilData = [];
              let eosinophilData = [];
              let immature_granulocyteData = [];
              let lymphocyteData = [];
              let monocyteData = [];
              let neutrophileData = [];

              obj.log_data.forEach((ele) => {
                let basophil_Data = { id: ele.id, measure: ele.basophil, unit: ele.basophil_unit, lab_report_title: 'Basophil', lab_report: 'basophil', log_date: ele.log_date };
                let eosinophil_Data = { id: ele.id, measure: ele.eosinophil, unit: ele.eosinophil_unit, lab_report_title: 'Eosinophil', lab_report: 'eosinophil', log_date: ele.log_date };
                let immature_granulocyte_Data = { id: ele.id, measure: ele.immature_granulocyte, unit: ele.immature_granulocyte_unit, lab_report_title: 'Immature Granulocyte', lab_report: 'immature_granulocyte', log_date: ele.log_date };
                let lymphocyte_Data = { id: ele.id, measure: ele.lymphocyte, unit: ele.lymphocyte_unit, lab_report_title: 'Lymphocyte', lab_report: 'lymphocyte', log_date: ele.log_date };
                let monocyte_Data = { id: ele.id, measure: ele.monocyte, unit: ele.monocyte_unit, lab_report_title: 'Monocyte', lab_report: 'monocyte', log_date: ele.log_date };
                let neutrophile_Data = { id: ele.id, measure: ele.neutrophile, unit: ele.neutrophile_unit, lab_report_title: 'Neutrophile', lab_report: 'neutrophile', log_date: ele.log_date };

                basophilData.push(basophil_Data);
                eosinophilData.push(eosinophil_Data);
                immature_granulocyteData.push(immature_granulocyte_Data);
                lymphocyteData.push(lymphocyte_Data);
                monocyteData.push(monocyte_Data);
                neutrophileData.push(neutrophile_Data);
              });
              let basophilFinalData = {
                lab_report_title: basophilData[0].lab_report_title,
                lab_report: basophilData[0].lab_report,
                unit: basophilData[0].unit,
                log_date: (basophilData[0].log_date) ? ((basophilData[0].log_date).substring(0, basophilData[0].log_date.indexOf('T'))) : (basophilData[0].log_date),
                value1: basophilData[0],
                value2: basophilData[1],
                range: rangedata[basophilData[0].lab_report],
                rangeBoldData: this.rangeDataCss(basophilData[0]?.measure, basophilData[1]?.measure, 'Basophil')

              }
              labReportData.push(basophilFinalData);

              let eosinophilFinalData = {
                lab_report_title: eosinophilData[0].lab_report_title,
                lab_report: eosinophilData[0].lab_report,
                unit: eosinophilData[0].unit,
                log_date: (eosinophilData[0].log_date) ? ((eosinophilData[0].log_date).substring(0, eosinophilData[0].log_date.indexOf('T'))) : (eosinophilData[0].log_date),
                value1: eosinophilData[0],
                value2: eosinophilData[1],
                range: rangedata[eosinophilData[0].lab_report],
                rangeBoldData: this.rangeDataCss(eosinophilData[0]?.measure, eosinophilData[1]?.measure, 'Eosinophil')

              }
              labReportData.push(eosinophilFinalData);

              let immature_granulocyteDataFinalData = {
                lab_report_title: immature_granulocyteData[0].lab_report_title,
                lab_report: immature_granulocyteData[0].lab_report,
                unit: immature_granulocyteData[0].unit,
                log_date: (immature_granulocyteData[0].log_date) ? ((immature_granulocyteData[0].log_date).substring(0, immature_granulocyteData[0].log_date.indexOf('T'))) : (immature_granulocyteData[0].log_date),
                value1: immature_granulocyteData[0],
                value2: immature_granulocyteData[1],
                range: rangedata[immature_granulocyteData[0].lab_report],
                rangeBoldData: this.rangeDataCss(immature_granulocyteData[0]?.measure, immature_granulocyteData[1]?.measure, 'Immature Granulocyte')

              }
              labReportData.push(immature_granulocyteDataFinalData);

              let lymphocyteFinalData = {
                lab_report_title: lymphocyteData[0].lab_report_title,
                lab_report: lymphocyteData[0].lab_report,
                unit: lymphocyteData[0].unit,
                log_date: (lymphocyteData[0].log_date) ? ((lymphocyteData[0].log_date).substring(0, lymphocyteData[0].log_date.indexOf('T'))) : (lymphocyteData[0].log_date),
                value1: lymphocyteData[0],
                value2: lymphocyteData[1],
                range: rangedata[lymphocyteData[0].lab_report],
                rangeBoldData: this.rangeDataCss(lymphocyteData[0]?.measure, lymphocyteData[1]?.measure, 'Lymphocyte')

              }
              labReportData.push(lymphocyteFinalData);

              let monocyteFinalData = {
                lab_report_title: monocyteData[0].lab_report_title,
                lab_report: monocyteData[0].lab_report,
                unit: monocyteData[0].unit,
                log_date: (monocyteData[0].log_date) ? ((monocyteData[0].log_date).substring(0, monocyteData[0].log_date.indexOf('T'))) : (monocyteData[0].log_date),
                value1: monocyteData[0],
                value2: monocyteData[1],
                range: rangedata[monocyteData[0].lab_report],
                rangeBoldData: this.rangeDataCss(monocyteData[0]?.measure, monocyteData[1]?.measure, 'Monocyte')

              }
              labReportData.push(monocyteFinalData);

              let neutrophileFinalData = {
                lab_report_title: neutrophileData[0].lab_report_title,
                lab_report: neutrophileData[0].lab_report,
                unit: neutrophileData[0].unit,
                log_date: (neutrophileData[0].log_date) ? ((neutrophileData[0].log_date).substring(0, neutrophileData[0].log_date.indexOf('T'))) : (neutrophileData[0].log_date),
                value1: neutrophileData[0],
                value2: neutrophileData[1],
                range: rangedata[neutrophileData[0].lab_report],
                rangeBoldData: this.rangeDataCss(neutrophileData[0]?.measure, neutrophileData[1]?.measure, 'Neutrophile')

              }
              labReportData.push(neutrophileFinalData);
              
            }
            if (obj.lab_report == 'eosinophil_count') {
              if (obj.log_data[0] && obj.log_data[0].absolute_eosinophils >= 0) {
                obj.log_data[0]['measure'] = obj.log_data[0].absolute_eosinophils;
              }
              if (obj.log_data[1]) {
                obj.log_data[1]['measure'] = obj.log_data[1].absolute_eosinophils;
              }
              lab = {
                lab_report_title: obj.lab_report_title,
                lab_report: obj.lab_report,
                unit: obj.log_data[0].absolute_eosinophils_unit,
                log_date: (obj.log_data[0].log_date) ? (obj.log_data[0].log_date).substring(0, obj.log_data[0].log_date.indexOf('T')) : (obj.log_data[0].log_date),
                value1: obj.log_data[0],
                value2: obj.log_data[1],
                range: rangedata["absolute_eosinophils"],
                rangeBoldData: this.rangeDataCss(obj?.log_data[0]?.measure, obj?.log_data[1]?.measure, 'Neutrophile')

              };
              labReportData.push(lab);
            }
          } else {
            lab = {
              lab_report_title: obj.lab_report_title,
              lab_report: obj.lab_report,
              unit: obj.log_data[0].unit,
              log_date: (obj.log_data[0].log_date) ? (obj.log_data[0].log_date).substring(0, obj.log_data[0].log_date.indexOf('T')) : (obj.log_data[0].log_date),
              value1: obj.log_data[0],
              value2: obj.log_data[1],
              range: rangedata[obj.lab_report],
              rangeBoldData: this.rangeDataCss(obj.log_data[0]?.measure, obj.log_data[1]?.measure, obj.lab_report_title)
            }
            labReportData.push(lab);
          }
        }
      });
      this.labReportTableData = labReportData;
      setTimeout(() => {
        scope.pdfDownload()
      }, 2000);
      
    }
  }

  ActivityDataModification(data) {
    let activity = [];
    if (data.data && data.data.activity_data) {
      let actData = data.data.activity_data;
      for (let key in actData) {
        actData[key]["key"] = key == "daily" ? 'day' : key == "weekly" ? 'week' : key;
        activity.push(actData[key]);
      }
    }
    this.ActivityData = activity;
  }


  fluidIntakeChart(key, val) {
    google.charts.load("current", { packages: ['corechart'] });
    google.charts.setOnLoadCallback(drawChart);
    function drawChart() {
      var Combined = new Array();
      Combined[0] = ['key', 'value', { role: 'annotation' }];
      for (var i = 0; i < key.length; i++) {
        
        // if (reportData.data.fluid_data[i].value != "0") {
        Combined[i + 1] =
          [
            key[i],
            parseInt(val[i]),
            parseInt(val[i])
          ];
        // }
      }

      
      var data = new google.visualization.arrayToDataTable(Combined);
      var options = {
        chartArea: {
          left: 50,
          width: '100%'
        },
        width: '100%',
        height: '100%',
        colors: ['#c0c0c0'],
        bar: { groupWidth: "2" },
        legend: 'none',
        fontName: 'AvenirNext-Regular',
        annotations: {
          stem: {
            color: "transparent",
          },
          textStyle: {
            color: "#2e2e2e",
          }
        },
        hAxis: {
          // title: 'Age',
          // minorGridlines:{count:0},
          minValue: 0,
          maxValue: 15,
          slantedText: false,
          showTextEvery: Combined.length > 7 ? 2 : 1,
          baselineColor: '#eaeaea',
        },
        vAxis: {
          // minorGridlines:{count:0},
          minorGridlines: { color: '#efefef' },
          baselineColor: '#eaeaea',
          title: 'ml',
          minValue: 0,
          maxValue: 15
        },
      };
      var chart = new google.visualization.ColumnChart(document.getElementById("fluidIntakeChart"));
      chart.draw(data, options);
    }
  }

  postMealSugar(data) {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);
    var Combined = new Array();
    Combined[0] = ['X', 'breakfast', { role: 'annotation' }, 'lunch', { role: 'annotation' }, 'dinner', { role: 'annotation' }];
    ////////////////
    var tempOutput = [];
    /// Combing data based on key(date) /
    data.forEach((item) => {
      var existing = tempOutput.filter((v, i) => {
        return v.key == item.key;
      });
      if (existing.length) {
        var existingIndex = tempOutput.indexOf(existing[0]);
        tempOutput[existingIndex].value = tempOutput[existingIndex].value.concat(item.value);
      } else {
        item.value = [item.value];
        tempOutput.push(item);
      }
    });
    // Final output of format [date,parseInt(breakfat_value),parseInt(breakfat_value),parseInt(lunch_value),parseInt(lunch_value),parseInt(diner_value),parseInt(diner_value)] /

    tempOutput.forEach((item) => {
      let entity = [];
      let mealValuse = ['breakfast', 'lunch', 'dinner'];
      let values = item.value;
      entity.push(this.convertDate(item.key));
      mealValuse.forEach((mealType) => {
        values.find(item =>
          (item.meal_type == mealType));
        if (item && item.value) {
          entity.push(parseInt(item.value[0].value), parseInt(item.value[0].value))
        } else {
          entity.push(null, null)
        }
      })
      Combined.push(entity);
    })

    /*
      Combined[0] = ['X', 'breakfast', { role: 'annotation' }, 'lunch', { role: 'annotation' }, 'dinner', { role: 'annotation' }];
  
      for (var i = 0; i < data.length; i++) {
        var breakfastData = null;
        var lunchVal = null;
        var dinnerVal = null;
        if (data[i].value.meal_type == "breakfast") {
          var breakfastData = data[i].value.value;
        }
        if (data[i].value.meal_type == "lunch") {
          var lunchVal = data[i].value.value;
        }
        if (data[i].value.meal_type == "dinner") {
          var dinnerVal = data[i].value.value;
        }
  
        Combined[i + 1] = [this.convertDate(data[i].key),
        parseInt(breakfastData), parseInt(breakfastData),
        parseInt(lunchVal), parseInt(lunchVal),
        parseInt(dinnerVal), parseInt(dinnerVal)];
      }
      */
    function drawChart() {
      var data = new google.visualization.arrayToDataTable(Combined);
      var options = {
        legend: 'none',
        lineSize: 0,
        pointSize: 10,
        colors: ['#c0c0c0'],
        chartArea: {
          left: 45,
          width: '100%'
        },
        annotations: {
          stem: {
            color: "transparent",
          },
          textStyle: {
            color: "#2e2e2e",
          }
        },
        vAxis: {
          // minorGridlines:{count:0},
          baselineColor: '#eaeaea',
          title: 'mg/dL',
          minorGridlines: { color: '#efefef' },
        },
        fontName: 'AvenirNext-Regular',
        series: {
          0: { pointShape: 'circle', color: '#a3a3a3', pointSize: 6 },
          1: { pointShape: 'diamond', color: '#5c5c5c', pointSize: 6 },
          2: { pointShape: 'square', color: '#c0c0c0', pointSize: 6 },
        }
      };

      var chart = new google.visualization.LineChart(document.getElementById('postMealSugar'));
      chart.draw(data, options);
    }
  }


  fastingBloodSugar(fastingArrData) {

    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(drawChart);
    var arrMaxLen = 0;
    fastingArrData.forEach((item) => {
      if (item.value.length > arrMaxLen) {
        arrMaxLen = item.value.length;
      }
    })
    var Combined = new Array();
    var graphData = new Array();
    Combined[0] = ['X', 'value', { role: 'annotation' }];
    for (let i = 1; i < arrMaxLen; i++) {
      Combined[0].push('value', { role: 'annotation' });
    }

    fastingArrData.forEach((item) => {
      let entity = [];
      let values = item.value;
      let valueLen = item.value.length;
      //
      entity.push(this.convertDate(item.key));
      for (let i = 0; i < arrMaxLen; i++) {
        if (i < valueLen) {
          entity.push(parseInt(values[i].value), parseInt(values[i].value));
        } else {
          entity.push(null, null);
        }
      }
      Combined.push(entity);
    })

    function drawChart() {
      var view = new google.visualization.arrayToDataTable(Combined, false);

      var options = {

        legend: 'none',
        // title: 'Age vs. Weight comparison',
        chartArea: {
          left: 45,
          width: '100%'
        },
        colors: ['#898989'],
        fontName: 'AvenirNext-Regular',
        annotations: {
          stem: {
            color: "transparent",
          },
          textStyle: {
            color: "#2e2e2e",
          }
        },
        hAxis: {
          // title: 'Age',
          minValue: 0,
          maxValue: 15,
          slantedText: false,

        },
        vAxis: {
          minorGridlines: { color: '#efefef' },
          baselineColor: '#eaeaea',
          minValue: 0,
          maxValue: 15,
          title: 'mg/dL',
        },
        series: {
          0: { pointShape: 'circle', color: '#a3a3a3', pointSize: 6 }
        }
      };

      var chart = new google.visualization.ScatterChart(document.getElementById('fastingBloodSugar'));

      chart.draw(view, options);
    }


  }


  /*fastingBloodSugar(fastingkey, fastingval) {

    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      var Combined = new Array();
      Combined[0] = ['key', 'value', { role: 'annotation' }];

      for (var i = 0; i < fastingkey.length; i++) {
        // if (reportData.data.fluid_data[i].value != "0") {
        Combined[i + 1] = [fastingkey[i], parseInt(fastingval[i]), parseInt(fastingval[i])];
        // }
      }
      
      var view = new google.visualization.arrayToDataTable(Combined, false);

      var options = {

        legend: 'none',
        // title: 'Age vs. Weight comparison',
        chartArea: {
          left: 45,
          width: '100%'
        },
        colors: ['#898989'],
        fontName: 'AvenirNext-Regular',
        annotations: {
          stem: {
            color: "transparent",
          },
          textStyle: {
            color: "#2e2e2e",
          }
        },
        hAxis: {
          // title: 'Age',
          minValue: 0,
          maxValue: 15,
          slantedText: false,

        },
        vAxis: {
          minorGridlines: { color: '#efefef' },
          baselineColor: '#eaeaea',
          minValue: 0,
          maxValue: 15,
          title: 'mg/dL',
        },
        series: {
          0: { pointShape: 'circle', color: '#a3a3a3', pointSize: 6 }
        }
      };

      var chart = new google.visualization.ScatterChart(document.getElementById('fastingBloodSugar'));

      chart.draw(view, options);
    }
  }
  */

  bloodPressure(pressureBottomLabel, systolicValues, diastolicValues, heartRateValues) {
    var scope = this;
    

    var pressureSeriesData = systolicValues;
    var diastolicValue = diastolicValues;

    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(drawChart);
    function drawChart() {
      var Combined = new Array();

      Combined[0] = ['range', 'systolic', 'diastolic', 'systolic', 'diastolic', 'pulserate'];
      for (var i = 0; i < pressureSeriesData.length; i++) {
        Combined[i + 1] = [pressureBottomLabel[i], parseInt(pressureSeriesData[i]), parseInt(pressureSeriesData[i]), parseInt(diastolicValue[i]), parseInt(diastolicValue[i]), parseInt(heartRateValues[i])];
      }
      

      var data = new google.visualization.arrayToDataTable(Combined, false);

      data.insertColumn(1, { type: 'string', role: 'annotation' });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        data.setValue(i, 1, (data.getValue(i, 2) + '-' + data.getValue(i, 4) + ', ' + data.getValue(i, 6)));
      }

      // data.insertColumn(7, {type: 'string', role: 'annotation'});
      // for (var i = 0; i < data.getNumberOfRows(); i++) {
      //   data.setValue(i, 7, data.getValue(i, 6) + "");
      // }


      var options = {
        legend: 'none',
        fontName: 'AvenirNext-Regular',
        fontSize: '12',
        chartArea: {
          left: 30,
          width: '100%'
        },
        vAxis: {
          minorGridlines: { color: '#efefef' },
          baselineColor: '#eaeaea',
        },
        bar: { groupWidth: '4' }, // Remove space between bars.
        candlestick: {
          fallingColor: { strokeWidth: 0, fill: '#5c5c5c' },
          risingColor: { strokeWidth: 0, fill: '#5c5c5c' }
        },
        seriesType: 'candlesticks',
        series: { 1: { type: 'scatter', pointShape: 'triangle', color: '#a3a3a3', pointSize: 8 } }
      };
      var chart = new google.visualization.ComboChart(document.getElementById('bloodPressure'));
      chart.draw(data, options);
    }
  }

  symptomTracking(symptomTrackingData) {
    // var symptomTrackingData = this.reportData.data.symptom.questionnaire_tracking;
    
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      var Combined = new Array();
      Combined[0] = ['key', 'value', { role: 'annotation' }];

      for (var i = 0; i < symptomTrackingData.length; i++) {
        // if (reportData.data.fluid_data[i].value != "0") {
        Combined[i + 1] = [this.convertDate(symptomTrackingData[i].key), parseInt(symptomTrackingData[i].value), parseInt(symptomTrackingData[i].value)];
        // }
      }

      var view = new google.visualization.arrayToDataTable(Combined, false);

      var options = {
        chartArea: {
          left: 30,
          width: '100%'
        },
        annotations: {
          stem: {
            color: "transparent",
          },
          textStyle: {
            color: "#2e2e2e",
          }
        },
        fontName: 'AvenirNext-Regular',
        colors: ['#c0c0c0'],
        hAxis: {
          minValue: 0, maxValue: 15, slantedText: false,
        },
        vAxis: {
          minValue: 0, maxValue: 15, minorGridlines: { color: '#cccccc' },
        },
        legend: 'none'
      };


      var chart = new google.visualization.ScatterChart(document.getElementById('symptomTracking'));

      chart.draw(view, options);
    }
  }

  getweightvariation(a, b) {
    var variation = parseInt(a) - parseInt(b);
    if (variation >= 0) { return 'Gained ' + variation + ' Kgs since onboarding' }
    else { return 'Lost ' + (variation * -1) + ' Kgs since onboarding' }
  }
  getbodyTemptvariation(a, b,unit) {
    var variation = parseInt(a) - parseInt(b);
    if (variation >= 0) 
    { return 'Temperature' + variation + + unit }
    else 
    { return 'Temperature' + (variation * -1) + unit}
  }

  getBMIPercentage(a) {
    
    var css = 'margin-left:' + (a * 1.6) + '%';
    if (this.weightBmiData.bmi < 18.5) {
      css = 'margin-left:' + 8 + '%';
    }
    else if(this.weightBmiData.bmi > 29.5){

      css = 'margin-left:' + 67 + '%';

    }
    return css
  }
  getbodyTempPercentage(a) {
    var css;
    if (a < 35.8) {
      css = 'margin-left:' + -5 + '%';
      return css;
    } else if(a > 39.6 && a < 45.5){
      css = 'margin-left:' + 50 + '%';
      return css;
    }else if(a == 45.5){
      css = 'margin-left:' + 65+ '%';
      return css;
    }else if(a >  45.5){
      css = 'margin-left:' + 80+ '%';
      return css;
    }else{
      css = 'margin-left:' + (a/2) + '%';
      return css
    }
   
  }
  getHipWaistPercentage(a,gender) {
    var css;
    if(gender === 'f'){
      if (a <= 0.80) {
        css = 'margin-left:' + -5 + '%';
        return css;
      } else if(a > 0.80 && a <= 0.85){
        css = 'margin-left:' + 50 + '%';
        return css;
      }else if(a > 0.85 && a <= 0.86){
        css = 'margin-left:' + 40+ '%';
        return css;
      }else if(a > 0.86 && a <= 1-0){
        css = 'margin-left:' + 60+ '%';
        return css;
      }else if(a > 1-0)
        css ='margin-left:' + 80+ '%';
        return css
      
    }else if(gender === 'm'){
      if (a <= 0.95) {
        css = 'margin-left:' + -5 + '%';
        return css;
      } else if(a > 0.95 && a <= 1.0){
        css = 'margin-left:' + 7 + '%';
        return css;
      }else if(a > 1.0 && a <= 1.1){
        css = 'margin-left:' + 45+ '%';
        return css;
      }else if(a > 1.1 && a < 2.0){
        css = 'margin-left:' + 50+ '%';
        return css;
      }else if(a > 2.0){
        css = 'margin-left:' + 70+ '%';
        return css;
      }
    }
  
   
  }
  convertDate(key) {
    var newdate = key.split("-").reverse().join("-");
    var d = new Date(newdate);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()
    return currentdate + ' ' + month;
  }
  readableText(d) {
    return d.replace(/_/g, ' ');
  }

}




export interface PeriodicElement {
  items: string;
  breakfast: number;
  lunch: number;
  dinner: number;
}
export interface ActivityElement {
  target: any;
  achieved: any;
}


const data1 = [
  { dateTime: '01/05/20, 10:50 AM', value: 67, recheckedValue: 89 },
  { dateTime: '13/05/20, 12:00 AM', value: 64, recheckedValue: 79 },
  { dateTime: '30/05/20, 10:50 AM', value: 64, recheckedValue: 80 },
];
const data2 = [
  { mostFrequentEvents: 'Sweating chills(6)', mostSevereEvents: 'Difficulty in breathing' },
  { mostFrequentEvents: 'Fainting(5)', mostSevereEvents: 'Swelling in legs' },
  { mostFrequentEvents: 'Tiredness or confusion(3)', mostSevereEvents: 'Indigestion & heart burn' },
];
const data3 = [
  { tests: 'HbA1c (%)', value1: '5.2 (02/01/20)', value2: '4.9 (02/01/20)', v1v2: '0.3', normalRange: '<5.7' },
];

const rangedata = {
  hba1c: '< 5.7',
  hdl: '≥ 40',
  ldl: '≤ 130',
  triglycerides: '< 150',
  urine_creatinine: '1000-2000',
  serum_uric_acid: '4 - 8.5',
  urine_uric_acid: '250 - 750',
  blood_urea: '8 - 23',
  serum_creatinine: '0.7 – 1.3',
  urine_albumin: '< 30',
  egfr: '> 90',
  thyroid_function_t3: '70 – 200',
  thyroid_function_totalt4: '5.5 – 12.5',
  thyroid_function_freet4: '0.9 – 2.3',
  thyroid_function_tsh: '0.5 – 5.0',
  tsh: '0.5 – 5.0',
  sodium: '136 – 145',
  potassium: '3.5 – 5',
  chloride: '98 – 106',
  bicarbonate: '23 – 28',
  haemoglobin_f: '12 - 16',
  haemoglobin: '12 - 16',
  haemoglobin_m: '14 - 17',
  bnp: '< 100',
  nt_pro_bnp: '< 300',
  aptt: '25-40',
  coagulation_factor_aptt: '25-40',
  fibrinogen: '150–400',
  coagulation_factor_fibrinogen: '150–400',
  white_blood_cell_count: '4-10 x 10^3',
  coagulation_factor_whitebloodcellcount: '0.1x 10^3 - 99 x 10^3',
  cbc_wbcdifferentialcount: '0.1 to 100',
  cbc_wbc_differential_count: '0.1 to 100',
  cbc_wbc: '0.1 to 100',
  neutrophile: '40-80',
  lymphocyte: '20-40',
  monocyte: '0-10',
  eosinophil: '0-0.6',
  basophil: '< 2',
  immature_granulocyte: '0-0.5',
  red_blood_cell_count: '4.5-5.5 x 106',
  cbc_rbc: '4.5-5.5 x 106',
  erythrocyte_count: '4.5-5.5 x 106',
  hematocrit: '35-50',
  cbc_hct: '35-50',
  cbc_meancorpuscularvolume: '80 – 100',
  cbc_mcv: '80 – 100',
  cbc_meancorpuscularhemoglobin: '27-33',
  cbc_mch: '27-33',
  cbc_meancorpuscularhemoglobinconcentration: '33-37',
  cbc_mchc: '33-37',
  redcelldistributionwidth: '39-46',
  cbc_rdw: '39-46',
  platelet_count: '150-400 x 10^3',
  cbc_esr: '0-20',
  mean_platelet_volume: '6.5-12',
  'sgo/ast': '< 48',
  'sgpt/alt': '< 40',
  alp: '50-120',
  ggt: '< 50',
  fev1: '0 to 99',
  fev1_l: '0 to 999',
  fvc: '0 to 99',
  fvc_l: '0 to 999',
  'fev/fvc': '0 to 99',
  ige: '10-179',
  blood_eosinophils: '< 6',
  sputum_eosinophils: '< 2',
  absolute_eosinophils: '0-450',
  'fenotest/exhalednitricoxide': '25-50',
  feno_test: '25-50',
  pulse_oximetry: '95-100',
  activity: '1 - 1440',
  dia: '30 - 300',
  sys: '30 - 300',
  heart_rate: '40 - 200',
  water: '1 - 12 glasses in ml 100 ml to 1780 ml',
  peakflow: '50-1000',
  weight: '20 - 200',

};

const multiData = ['cholesterol', 'coagulation_factor', 'electrolytes', 'thyroid_function', 'spirometry', 'eosinophil_count', 'cbc_wbc_differential_count']