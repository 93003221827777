<span class="slidetogglecss" (click)="togglenav()">
  <i [ngClass]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">
    {{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}
  </i>
</span>

<div class="userlistnav">
  <div class="row">
    <div class="col-md-12 docleftnavtopsection">
      <button class="hospitalFilter" *ngIf="arrHopitalList.length > 0" mat-button [matMenuTriggerFor]="menu">
        <img src="assets/img/dls/icons/hcicons/HC_User_FIlter.svg" alt=""> Filter by Hospital
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let item of arrHopitalList" (click)="getDatabyHospital(item.id)">{{item.name}}</button>
      </mat-menu>
      <!---Search Bar--->
      <form>
        <div class="form-group has-feedback has-search doc-user-list-search">
          <input class="form-control" [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" placeholder="Search patients">
          <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
            <mat-icon matSuffix>search</mat-icon>
          </button>
        </div>
      </form>

      <!---Toggle Buttons--->
      <mat-button-toggle-group appearance="fontStyle" name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup" [(ngModel)]="togglebtnval" class="condition-buttons">
        <mat-button-toggle value="all" [checked]="true" (change)="generalusers()" class="btn1 btn-outline tabbutton tabbuttonMobile notranslate">
        <!-- class="btn1 btn-filled tabbuttoncondition tabbuttonconditionMobile notranslate"-->
          {{'Doctor.FollowUp' | translate}}

        </mat-button-toggle>
        <mat-button-toggle value="needsfollowup" (change)="needsfollowup()" class="btn1 btn-outline tabbutton tabbuttonMobile notranslate">
          {{'Doctor.NeedsFollowUp' | translate}}

        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!--Table-->
    <div *ngIf="isnouser == true" class="nouserassigned">No Users assigned</div>

    <div class="example-container userlisttablecontainer" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">
      <table mat-table [dataSource]="dataSource" class="doc-user-list-table">
        <ng-container matColumnDef="name">
          <td mat-cell *matCellDef="let element" class="userlistbox">
            <div class="patient-name-wrapper">
              <span class="patient-name notranslate">{{element.name}}</span>
              <span class="age-gender"> {{getAge((element.dob))}} <b>{{(element.gender)=="m"?"M":"F"}}</b></span>
            </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let element; columns: displayedColumns;" [ngClass]="{highlighted: selectedRowIndex == element.patient_id}" (click)="highlight(element)"
          [routerLink]="['/doctor/user/',element.patient_id, element.doing_follow_up]" routerLinkActive="active"></tr>
      </table>
    </div>
  </div>
</div>