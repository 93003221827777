import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AdminsectionleftnavComponent } from './adminsectionleftnav.component';

@NgModule({
  declarations: [
    AdminsectionleftnavComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    SharedModule.forRoot()
  ],
  exports:[
    AdminsectionleftnavComponent
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AdminsectionleftnavModule {}