import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../../shared/shared.module';
import { DocLabReportsComponent } from './doclabreports.component';

import { AlertsService } from './../../services/alerts/alerts.service';
import { DoctorDataService } from './../../services/doctor/doctor.service';
import { PatientDataService } from './../../services/patient/patient.service';
import { UserListService } from './../../services/userlist/user-list.service';
import { DocLabReportsRoutingModule } from './doclabreports.routing.module';

@NgModule({
  declarations: [
    DocLabReportsComponent
  ],
  imports: [
    CommonModule,
    DocLabReportsRoutingModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService
  ],
  exports:[
    DocLabReportsComponent
  ]
  /*entryComponents: [
    DocMealBreakupComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
  ]*/
})
export class DocLabReportsModule { }
