import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ContextService as HealthCoachContextService  } from 'src/app/healthcoach/services/context.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { KnowuserComponent } from './knowuser.component';
import {AutosizeModule} from 'ngx-autosize';
import { EditNotesComponent } from './edit-notes/edit-notes.component';
import { NotesLogComponent } from './notes-log/notes-log.component';

@NgModule({
  declarations: [
    KnowuserComponent,
    EditNotesComponent,
    NotesLogComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    AutosizeModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    KnowuserComponent
  ]
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class knowUserModule { }
