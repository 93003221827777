import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { Userdetailsinterface } from '../../../models/userdetailsinterface';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';

@Component({
  selector: 'app-programprogress',
  templateUrl: './programprogress.component.html',
  styleUrls: ['./programprogress.component.scss']
})

export class ProgramprogressComponent implements OnInit {
  public userProgressSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public selectedUserID: string;
  public udata: any = {};
  public currentWeek: number = 1;
  public expectedWeek: number = 1;
  public totalWeeks: number = 1;
  public diameterValue: number = 0;
  public currentWeekArray: any = [];
  private tabID: number = 4;
  public subscribeObj: any;
  public selectedWeek: any;
  public userProgress:any;

  constructor(private appContext: ContextService, private userDetailService: UserdetailsService) { }

  ngOnInit() {
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getprogdetails(this.selectedUserID);
        //this.initComponentDraw(this.currentWeek);
      }
    });
  }

  generateWeekList(currentWeek): void {
    var scope = this;
    this.currentWeekArray = [];
    for (var i = 1; i <= currentWeek; i++) {
      this.currentWeekArray.push(i);
    }
    setTimeout(() => {
      scope.initComponentDraw(this.currentWeekArray[0][0], 0);
    }, 100);
  }

  getprogdetails(selectedUserID: string) {
    var scope = this;
    this.loadingSubject$.next(true);
    /**Reinitiliase program detail subject */
    /**API Call */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      data = data == null ? [] : data;
      
      // var pi = data.therapy_data.therapy_levels + '_levels';
      this.currentWeek = data.therapy_data.therapy_levels.split(',');
      this.currentWeekArray = [];
      this.currentWeekArray = [this.currentWeek];
      setTimeout(() => {
        scope.initComponentDraw(this.currentWeekArray[0][0], 0);
      }, 100);
    });
  }

  initComponentDraw(level, number) {
    /**Assign week */

    this.loadingSubject$.next(true);
    /**Reinitialise progress subject */
    this.userProgressSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    this.udata = {
      "patient_id": this.selectedUserID.toString(),
      "level_id": level.toString(),
      "bypass_suitable_module_check": "false",
      "bypass_patient_progress": "false"
    };
    this.userDetailService.getUserProgramProgress(this.udata).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      if (data) {
        data = data.data[0] == null ? [] : data.data[0];
        this.selectedWeek = number + 1;
      }
      else {
        data = null
      }
      this.userProgressSubject$.next(data);
      this.loadingSubject$.next(false);
      this.loadingSubject$.next(false);
    });
  }

  ngOnDestroy() {
    this.subscribeObj.unsubscribe();
  }

}
