import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import * as jsPDF from "jspdf";
import * as moment from 'moment';
import { SystemService } from 'src/app/shared/services';
import * as html2canvas from '../../../../assets/js/html2canvas.js';
import { ChangePasswordHistoryComponent } from '../../doctor/change-password-history/change-password-history.component.js';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { ChangepasswordComponent } from '../../shared/changepassword/changepassword.component';
import { UserprofileviewComponent } from '../../shared/userprofileview/userprofileview.component';
import { CreatehcComponent } from './createhc/createhc.component';
import { DownloadreportComponent } from './downloadreport/downloadreport.component';
declare var $: any;

@Component({
  selector: 'app-hcassignhome',
  templateUrl: './hcassignhome.component.html',
  styleUrls: ['./hcassignhome.component.scss'],

})

export class HcassignhomeComponent implements OnInit {
  public expandedElement: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'no_of_patients', 'email', 'is_on_leave', 'action'];
   public isactive = 'all';
  // public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public edithcstatus: any;
  public localStoredData: any;
  public userData: any;
  public sessionstart: boolean = false;
  public sessionname: any = null;
  public reportdata: any;
  constructor(private browserTitle: Title, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog, fb: FormBuilder,) {
    this.createStoreForm = fb.group({
      'user': ['', Validators.required],
    });
  }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  createStoreForm: FormGroup;

  viewProfile(element): void {
    const profile = this.dialog.open(UserprofileviewComponent, {
      width: '750px',
      data: {
        element: element
      }
    });

    // profile.afterClosed().subscribe(result => {
    // });
  }
  cancelSearch() {
    this.searchcriteria = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();

  }
  editHC(element): void {
    const hcCreation = this.dialog.open(CreatehcComponent, {
      width: '1028px',
      data: {
        element: element
      }
    });

    hcCreation.afterClosed().subscribe(result => {
      this.search();
    });
  }


  changepassword(element): void {
    const password = this.dialog.open(ChangepasswordComponent, {
      width: '450px',
      data: {
        element: element
      }
    });

    password.afterClosed().subscribe(result => {
      this.search();
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Health Coach');
  }

  ngOnInit() {
    let data = localStorage.getItem("HealthcoachSearch");
    
    this.setSEOTags();
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.localStoredData.user_acl == 'superadmin' ? true : false);
    //this.searchcriteria = "";
    this.createStoreForm.value.user = "";
    this.page = 1;
    if (data != null) {
      this.createStoreForm.patchValue({
        'user': data,
      });
      this.livesearch();
      localStorage.removeItem("HealthcoachSearch")
    }
    else if (data == null) {
      this.search();
 
    }

  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    // this.searchcriteria = "";
    this.createStoreForm.value.user = "";
    this.search();
  }

  activeclass(onleave, active) {
    return onleave ? 'onleave-cls' : (active ? 'active-cls' : 'inactive-cls');
  }

  typeFilter(fln) {
    this.filteredtypes = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.doctor_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }


  // Active Deactive Health Coach 
  // ----------------------------

  isActive(status, id) {
    this.commonService.setStatus(status, 'users', id, this.localStoredData.id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  livesearch(status?) {
    if(status !=' '){
      this.status = 'all';
      this.activecls = 'all';
    }
    if (this.lastSearch != this.createStoreForm.value.user) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // Health Coach List 
  // ----------------------------

  search() {
    if (this.lastSearch != this.createStoreForm.value.user || this.page != this.lastpage) {
      this.lastSearch = this.createStoreForm.value.user;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllData(this.createStoreForm.value.user, this.page.toString(), "20", this.status, "users", "healthcoach")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSearchDataSuccess(data) {
    data = data.data;
    
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
    if (this.filteredtypes.length !== 0) {
      this.typeFilter(this.filteredtypes);
    }
    else {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    
  }

  // ----------------------------


  // Switch To session
  // ----------------------------

  switchSession(ide, name) {
    var scope = this;
    var originalID = localStorage.getItem('scarletuserid');
    if (localStorage.getItem('scarletuserid') != JSON.parse(localStorage.getItem('scarletuserdata')).id) {
      this.systemService.showSnackBar('Session is already running in background', 'OK')
      return
    }
    this.sessionstart = true;
    this.sessionname = name;
    sessionStorage.setItem('switchId', originalID)
    sessionStorage.setItem('switchId1', originalID)
    localStorage.setItem('scarletuserid', ide)
    sessionStorage.setItem('onhibernate1', 'true')
    sessionStorage.setItem('onhibernate', 'true')
    var bodydata = {
      "user_data_fk": originalID.toString(),
      "switched_user_data_fk": ide.toString(),
      "switch_type": "healthcoach",
      "switch_start_at": moment(),
      "switch_end_at": moment()
    }
    this.commonService.performSwitch(bodydata)
      .subscribe(data => {
        
      })
    var win = window.open('/#/healthcoach/hctasks');
    var timer = setInterval(function () {
      if (win.closed) {
        clearInterval(timer);
        scope.sessionstart = false;
        scope.sessionname = null;
        sessionStorage.removeItem('switchId')
        localStorage.setItem('scarletuserid', JSON.parse(localStorage.getItem('scarletuserdata')).id)
      }
    }, 1000);
    window.onunload = function () {
      sessionStorage.removeItem('switchId')
      localStorage.setItem('scarletuserid', JSON.parse(localStorage.getItem('scarletuserdata')).id)
      scope.sessionstart = false;
      scope.sessionname = null;
      if (win && !win.closed) {
        win.close();
      }
    };
  }

  // ----------------------------


  pdfDownload() {
    document.getElementById("pdf").style.display = "block";
    let data = document.getElementById('pdf');
    var w = data.offsetWidth;
    var h = data.offsetHeight;
    var options = {
      dpi: 300
    }
    html2canvas(data, options).then(canvas => {
      var img = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var doc = new jsPDF('P', 'mm', 'a4');
      doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight);
      doc.save('samplefile.pdf');
    });
    document.getElementById("pdf").style.display = "none";
  }


  cumulativepdfDownload() {
    document.getElementById("pdf").style.display = "block";
    let data = document.getElementById('pdf');
    var w = data.offsetWidth;
    var h = data.offsetHeight;
    var options = {
      dpi: 300
    }
    html2canvas(data, options).then(canvas => {
      var img = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var doc = new jsPDF('P', 'mm', 'a4');
      doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight);
      doc.save('samplefile.pdf');
      // var img = canvas.toDataURL("image/png");
      // var tWindow = window.open("");
      // $(tWindow.document.body)
      //     .html("<img id='Image' src=" + img + " style='width:100%;'></img>")
      //     .ready(function() {
      //         tWindow.focus();
      //         tWindow.print();
      //     });

    });
    document.getElementById("pdf").style.display = "none";
  }


  downloadReport(element): void {
    const download = this.dialog.open(DownloadreportComponent, {
      width: '450px',
      data: {
        element: element,
        type: 'solo'
      }
    });

    download.afterClosed().subscribe(result => {
      this.reportdata = result.data;
      var scope = this;
      setTimeout(() => {
        scope.pdfDownload()
      }, 500);
    });
  }

  downloadcumulative() {
    const cumulativedownload = this.dialog.open(DownloadreportComponent, {
      width: '450px',
      data: {
        type: 'cumulative'
      }
    });

    cumulativedownload.afterClosed().subscribe(result => {
      this.reportdata = result.data;
      var scope = this;
      setTimeout(() => {
        scope.cumulativepdfDownload()
      }, 500);
    });

  }

  getroundown(a) {
    return Math.floor(a);
  }


  // Force Logout From the HC 
  // ----------------------------

  forceLogout(id) {
    this.commonService.logoutUser(id)
      .subscribe(
        (res: any) => this.onLogoutSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onLogoutSuccess() {
    this.systemService.showSuccessSnackBar(("Selected Health Coach has been Logout from Health Coach Dashboard"), "OK");
  }

  // ----------------------------
  changepasswordHistory(element): void {
    localStorage.setItem("userType", 'HealthCoach')
    const history = this.dialog.open(ChangePasswordHistoryComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    history.afterClosed().subscribe(result => {
    });
  }
}


export interface PeriodicElement {
  name: string;
  no_of_patients: string;
  status: string;
  email: string;
  // active_time: string;
}