import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-assigntask',
  templateUrl: './assigntask.component.html',
  styleUrls: ['./assigntask.component.scss']
})
export class AssigntaskComponent implements OnInit {
  public dataVal: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
   public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any=[]; 
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any;
  isLoading: boolean = false;
  filters: any;
  sort_by: any;
  sort_direction: any;
  public dataForm = this.fb.group({
    hc_ids: [[], Validators.required],
  });
  constructor(public fb: FormBuilder,public dialogRef: MatDialogRef<AssigntaskComponent>,public commonService: CommonserviceService,
    private systemService: SystemService,private router: Router,@Inject(MAT_DIALOG_DATA) private data: any) { }
    @ViewChild(MatSort, { static: false }) sort: MatSort;

  ngOnInit(): void {
    this.dataVal = this.data
    this.search()
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  livesearch() {
    this.dataSource=[];
    for(let i=0;i<this.dataForm.value.hc_ids.length;i++){
      this.dataSource.push(this.dataForm.value.hc_ids[i]);
    }
    
   
    // if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    // }
  }

   //  HC List
  // ----------------------------

  search() {
    if (this.searchcriteria) {
      this.searchcriteria = this.searchcriteria
    } else {
      this.searchcriteria = ''
    }
    this.lastSearch = this.searchcriteria;
    this.lastpage = this.page;
    this.isLoading = true;
    var postData = {
      q: this.searchcriteria,
      page: this.page.toString(),
      limit: "20",
    }
    this.commonService.TaskPost(postData, 'getHCListForAssignment')
      .subscribe(
        (res: any) => this.onHCListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCListSuccess(data) {
    data = data.data;
    // this.dataSource = data;
    
    for(let i=0;i<data.length;i++){
      let check=_.findIndex(this.dataSource, function(o) { return o.id == data[i].id; });
      if(check==-1){
        this.dataSource.push(data[i]);
      }
     
    }
    this.isLoading = false;
    
    // if (data.length == 0) {
    //   if (this.onit == true) {
    //     this.tabulardata = [];
    //     this.emptyplaceholder = true;
    //     this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    //   }
    //   else {
    //     this.endoflist = true;
    //     this.emptyplaceholder = false;
    //   }
    // }
    // else {
    //   this.emptyplaceholder = false;

    //   if (this.onit) {
    //     this.tabulardata = data;
    //   }
    //   else {
    //     for (var k = 0; k < data.length; k++) {
    //       this.tabulardata.push(data[k]);
    //     }
    //   }
    //   this.onit = false;
    //   
    //   this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    //   this.dataSource.sort = this.sort;
    //   this.isLoading = false;
    // }
  }

 async dataObject(){
   
   let hc_ids=[];
   for(let i=0;i<this.dataForm.value.hc_ids.length;i++){
     hc_ids.push(this.dataForm.value.hc_ids[i].id);
   }
   
    
    if(hc_ids.length==this.dataForm.value.hc_ids.length){
      var postData = {
        task_library_id: this.dataVal.id.toString(),
        hc_ids: JSON.stringify(hc_ids),
        has_expiry: "true",
        expiry_date:this.dataVal.expiry_date,
        created_by_fk:localStorage.getItem('scarletuserid').toString()
       }
      await this.commonService.TaskPost(postData, 'postHCForAssignment')
         .subscribe(
           (res: any) => {
         
             this.onHCAssignSuccess(res);
           
           },
           (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
         )
    }
  
  }
  private onHCAssignSuccess(res) {
    
    this.systemService.showSuccessSnackBar(("Task has been Assigned"), "OK");
    this.closeModal();
  }
  
}


