import { I } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admindeletemodal',
  templateUrl: './admindeletemodal.component.html',
  styleUrls: ['./admindeletemodal.component.scss']
})
export class AdmindeletemodalComponent implements OnInit {

  message: string = "Are you sure?";
  confirmButtonText = "Delete";
  cancelButtonText = "Cancel";  

  
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AdmindeletemodalComponent>) {}

  ngOnInit() {
    this.dialogRef.updateSize('386px', '120px');
    if(this.data.confirmmsg){
this.confirmButtonText=this.data.confirmmsg;
    }
    if(this.data.cancelmsg){
      this.cancelButtonText=this.data.cancelmsg;
          }
  }
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onCancelClick(): void {
    
    this.dialogRef.close(false);
    
  }

}
