import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject} from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

declare var $: any;

@Component({
  selector: 'app-editfeedback',
  templateUrl: './editfeedback.component.html',
  styleUrls: ['./editfeedback.component.scss']
})

export class EditfeedbackComponent implements OnInit {
  public selfparentid: any;
  chooseMedia: any;
  s3url: any = environment.s3url;
  public newitem: boolean = false;
  public childid: any;
  public langid: any;
  public showButtonLoader: any;
  feedbackdata: any;
  public typeselected: any;
  checked: boolean = false;
  fbdata: any;
  alltags: any;
  formControls = [];
  nvfboptions = [];
  public selfparentide: any;
  public language: any;
  public allmedia: any = [];
  public imageData: any;
  public imageUrl: any;
  public addingClass: any = '';
  public noofoptions: any;
  public allmediatitle: any = [];
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: [''],
    type: ['', Validators.required],
    feedback_main_question: ['', [Validators.required, whiteSpaceValidation]],
    feedback_main_question_options_count: ['', Validators.required],
    ask_negative_feedback: ['false'],
    negative_feedback_question: [''],
    tags: [[], Validators.required],
    response_files_array: [[]],
    feedback_main_question_text_options: this.fb.array([]),
    main_media_options: this.fb.array([]),
    negative_feedback_options: this.fb.array([]),
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required]
  });
  public searchmattag: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;

  constructor(private browserTitle: Title, public fb: FormBuilder, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private contentService: ContentserviceService, private systemService: SystemService, private router: Router, public dialog: MatDialog) { }


  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  initFbTextOptions() {
    return this.fb.group({
      fbtxtoptn: ['', [Validators.required, Validators.pattern("^[^,]+$")]],
    });
  }

  initFbImgOptions() {
    return this.fb.group({
      fbimgoptn: []
    });
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initnvFbOptions() {
    return this.fb.group({
      nvfboptn: []
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Feedback');
  }

  ngOnInit() {
    this.setSEOTags();
    this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
    this.language = <FormArray>this.dataForm.controls.language_data_fk;
    this.route.params.subscribe(params => {
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getAllFeedbackData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getAllFeedbackData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getAllFeedbackData(params.chid);
        }
      }
      this.getTags();
    });
  }

  modelChangedTags() {
    this.filterTags();
  }

  commaValidation() {
    $('.textName').keypress(function (e) {
      var regex = new RegExp("^[^,]+$");
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      }
      else {
        e.preventDefault();
        return false;
      }
    });
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}',false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------

  changeOptions(e) {
    this.allmedia = [];
    this.typeselected = e;
    const gnrtoptnformcontrol = <FormControl>(this.dataForm.controls['feedback_main_question_options_count']);
    gnrtoptnformcontrol.reset();
    const mainmedia = <FormArray>(this.dataForm.controls['main_media_options']);
    const feedbacktext = <FormArray>(this.dataForm.controls['feedback_main_question_text_options']);
    mainmedia.clear();
    feedbacktext.clear();
    this.addingClass = '';
  }

  changeValue(value) {
    this.checked = !value;
    this.dataForm.value.ask_negative_feedback = this.checked;
    var control3 = <FormArray>(
      this.dataForm.controls['negative_feedback_options']
    );
    while (control3.length !== 0) {
      control3.removeAt(0);
    }
    for (let k = 0; k < 4; k++) {
      control3.push(this.initnvFbOptions());
    }
  }

  generateOptions(e) {
    // this.allmedia = [];
    this.noofoptions = e;
    if (this.typeselected == 'image') {
      this.imageOptions(e);
      this.addingClass = 'mealbox';
    } else {
      this.textOptions(e);
      this.addingClass = 'mealbox1';

    }
  }

  imageOptions(e) {
    var control2 = <FormArray>this.dataForm.controls['main_media_options'];
    if (control2.length !== 0) {
      if (control2.length < e) {
        for (let l = control2.length; l < e; l++) {
          control2.push(this.initFbImgOptions());
        }
      }
      if (control2.length > e) {
        for (let l = control2.length; l > e; l--) {
          control2.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        control2.push(this.initFbImgOptions());
      }
    }
  }

  textOptions(e) {
    var control1 = <FormArray>(
      this.dataForm.controls['feedback_main_question_text_options']
    );
    if (control1.length !== 0) {
      if (control1.length < e) {
        for (let l = control1.length; l < e; l++) {
          control1.push(this.initFbTextOptions());
        }
      }
      if (control1.length > e) {
        for (let l = control1.length; l > e; l--) {
          control1.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        control1.push(this.initFbTextOptions());
      }
    }
  }


  // To Get Media
  // ----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
    .subscribe((res: any) => this.getMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // ----------------------------


  // To Get All Feedback
  // ----------------------------

  getAllFeedbackData(ide) {
    this.contentService
      .getOneDataById(ide, 'feedback')
      .subscribe((res: any) => this.getFeedbackDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getFeedbackDataSuccess(data) {
    this.fbdata = data;
    if (this.fbdata.type == 'text') {
      this.textOptions(this.fbdata.feedback_main_question_options_count);
      this.typeselected = 'text';
      for (let k = 0; k < this.fbdata.feedback_main_question_options_count; k++) {
        let txtformelement = (<FormArray>(this.dataForm.controls['feedback_main_question_text_options'])).at(k);
        txtformelement['controls'].fbtxtoptn.setValue(this.fbdata.feedback_main_question_text_options[k]);
      }
    }
    if (this.fbdata.type == 'image') {
      this.typeselected = 'image';
      this.imageOptions(this.fbdata.feedback_main_question_options_count);
      for (let k = 0; k < this.fbdata.feedback_main_question_options_count; k++) {
        let imgformelement = (<FormArray>(this.dataForm.controls['main_media_options'])).at(k);
        imgformelement['controls'].fbimgoptn.setValue(this.fbdata.media[k]);
      }
    }

    this.changeValue(!this.fbdata.ask_negative_feedback);
    for (let p = 0; p < 4; p++) {
      let nvfbformelement = (<FormArray>(this.dataForm.controls['negative_feedback_options'])).at(p);
      nvfbformelement['controls'].nvfboptn.setValue(this.fbdata.negative_feedback_options[p]);
    }
    for (let i = 0; i < this.fbdata.media_joined_data.length; i++) {
      this.allmedia[i] = this.fbdata.media_joined_data[i].path;
      this.allmediatitle[i] = this.fbdata.media_joined_data[i].title;
    }
    this.fbdata.response_files_array = JSON.parse(this.fbdata.response_files_array);
    this.dataForm.patchValue(this.fbdata);
    this.selfparentide.setValue(this.selfparentid);
    (<FormArray>this.dataForm.controls.language_data_fk).setValue(this.langid);
  }

  // ----------------------------


  // Submitting Feedback data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    var mainimgoptn = [];
    var textoption = []; 
    var duptxtoptn = [];
    if (postdata.type == 'image') {
      postdata.main_media_options.forEach(el => { mainimgoptn.push(el.fbimgoptn); });
      if (postdata.feedback_main_question_options_count == 2) {
        postdata.response_files_array = ['feedback_good', 'feedback_bad'];
      }
      else {
        postdata.response_files_array = ['feedback_good', 'feedback_average', 'feedback_bad'];
      }
    }
    else {
      postdata.feedback_main_question_text_options.forEach(el => { textoption.push(el.fbtxtoptn); });
      duptxtoptn = textoption.map(v => v.toLowerCase());
    }
    if (!mainimgoptn.every((e, i, a) => a.indexOf(e) === i)) {
      postdata.type = 'image';
      this.systemService.showSnackBar("Please select the unique images to create the feedback.", "OK");
    }
    else if (!duptxtoptn.every((e, i, a) => a.indexOf(e) === i)) {
      postdata.type = 'text';
      this.systemService.showSnackBar("Please enter the unique texts to create the feedback.", "OK");
    }
    else {
      postdata.main_media_options = JSON.stringify(mainimgoptn);
      postdata.title = this.removeHTMLtags(postdata.title).toString();
      postdata.detailed_text = this.removeHTMLtags(postdata.detailed_text).toString();
      postdata.feedback_main_question == this.removeHTMLtags(postdata.feedback_main_question).toString();
      postdata.feedback_main_question_options_count = postdata.feedback_main_question_options_count.toString();
      postdata.tags = JSON.stringify(postdata.tags);
      if (postdata.ask_negative_feedback == '') {
        postdata.ask_negative_feedback = 'false';
      } else {
        postdata.ask_negative_feedback = postdata.ask_negative_feedback.toString();
      }
      if (postdata.ask_negative_feedback == 'true') {
        postdata.negative_feedback_question = postdata.negative_feedback_question.toString();
        var nvfeedbackoption = [];
        postdata.negative_feedback_options.forEach(el => { nvfeedbackoption.push(el.nvfboptn); });
        postdata.negative_feedback_options = JSON.stringify(nvfeedbackoption);
      }
      postdata.negative_feedback_options = JSON.stringify(postdata.negative_feedback_options);

      if (postdata.type == 'text') {
        postdata.response_files_array = [];
        for (let i = 0; i < postdata.feedback_main_question_options_count; i++) {
          postdata.response_files_array.push(null);
        }
      }
      postdata.type = postdata.type.toString();
      textoption = (textoption)
      postdata.feedback_main_question_text_options = JSON.stringify(textoption);
      postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
      postdata.language_data_fk = this.langid;
      postdata.language_data_fk = postdata.language_data_fk.toString();
      postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
      if (postdata.response_files_array.length == postdata.feedback_main_question_options_count) {
        postdata.response_files_array = JSON.stringify(postdata.response_files_array);
        if (this.newitem == true) {
          this.contentService
            .postToBlade(postdata, 'feedback')
            .subscribe((res: any) => this.onCreateSuccess(res),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
            )
        }
        else {
          this.contentService
            .updateToBlade(this.childid, this.selfparentid, postdata, 'feedback')
            .subscribe((res: any) => this.onUpdateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
            )
        }
      }
      else {
        this.systemService.showSnackBar(("Number of Feedback response should be equal to feedback options count."), "OK");
      }
    }
  }

  private onCreateSuccess(data) {
    this.feedbackdata = data;
    this.systemService.showSuccessSnackBar(("Feedback has been added"), "OK");
    this.router.navigate(['content/feedback']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Feedback has been updated"), "OK");
    this.router.navigate(['content/feedback']);
  }

  // ----------------------------

  openmediabox(e) {
    this.typeselected = 'image';
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = (<FormArray>(
        this.dataForm.controls['main_media_options']
      )).at(e);
      formelement['controls'].fbimgoptn.setValue(data.id);
      this.allmedia[e] = data.path;
      this.allmediatitle[e] = data.title;
    });
  }
  ​ removeHTMLtags(value:any){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
