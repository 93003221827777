import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-editonboardingcontent',
  templateUrl: './editonboardingcontent.component.html',
  styleUrls: ['./editonboardingcontent.component.scss']
})

export class EditonboardingcontentComponent implements OnInit {
  public newitem: boolean = true;
  public showButtonLoader: any;
  public selfparentid: any;
  public childid: any;
  public langid: any;
  public widgetdata: any;
  public ide: any;
  public selfparentide: any;
  public language: any;
  public wid: any;
  public pretextdata = [];
  public posttextdata = [];
  public widgets = [];
  public widgetid = [];
  public widgetname: any;
  public skiptextcount: any;
  public pretextcount: any;
  public posttextcount: any;
  public parentid: any;
  public therapycontentstrategy = {
    // While adding the array for newly created widgets, make the required changes in widgetName(), as well.
    // 'id' : ['pre_text','post_text','title','sub_title','image_text','skip_text']
    '39': [false, false, true, true, false, false], //KNOWLEDGE_LEVEL
    '37': [true, true, false, false, false, false], //OTP_VERIFICATION
    '22': [false, false, true, true, false, false], //THERAPY_LANGUAGE
    '21': [false, false, true, false, false, false], //ONBOARDING_LANGUAGE
    '20': [true, true, false, false, false, false], //USER_HEALTH_DETAILS
    '19': [true, true, false, false, false, false], //ACTIVITY_LEVEL_QUESTION
    '18': [true, true, false, false, false, true], //EMAIL
    '17': [false, false, true, true, false, false], //BMI
    '16': [true, true, false, false, false, false], //USERNAME
    '15': [true, true, true, true, false, false], //SECONDARY_CONDITIONS
    '14': [true, true, false, false, false, false], //ACTIVATION_CODE
    '13': [true, true, false, false, false, false], //MOBILE_VERIFICATION
    '12': [false, false, true, true, false, false], //DISCLAIMER
    '11': [false, false, true, true, false, true], //T_AND_C
    '10': [false, true, true, false, false, false], //CAREY_INTRO
    '1': [false, false, false, false, true, false], //CAREY_IMAGE
  }

  public dataForm = this.fb.group({
    pre_text: this.fb.array([]),
    post_text: this.fb.array([]),
    title: [""],
    sub_title: [""],
    image_text: [""],
    skip_text: this.fb.array([]),
    pre_text_count: [""],
    post_text_count: [""],
    skip_text_count: [""],
    widget_data_fk: [Validators.required],
    created_by_data_fk: ["5", Validators.required],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required]
  });

  initPreTextOptions() {
    return this.fb.group({
      pretxtoptn: []
    });
  }

  initPostTextOptions() {
    return this.fb.group({
      posttxtoptn: []
    });
  }

  initSkipTextOptions() {
    return this.fb.group({
      skiptxtoptn: []
    });
  }

  constructor(private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.wid = <FormArray>this.dataForm.controls.widget_data_fk;
      this.langid = params.langid;
      this.wid.setValue(params.wid);
      this.parentid = params.id;
      this.widgetName(this.parentid);
      if (params.id == 'new') {
        this.selfparentid = null;
        this.selfparentide.setValue(null);
        this.newitem = true;
        this.language.setValue(this.langid);
      }
      else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.getWidgetMapper(this.childid);
          this.newitem = false;
        }
        else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getWidgetMapper(this.childid);
        }
        else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getWidgetMapper(params.chid);
        }
      }
    });
  }

  showparamval(index) {
    var wint = parseInt(this.parentid);
    return this.therapycontentstrategy[wint][index];
  }

  widgetName(a) {
    this.widgets = ['OTP Verification', 'Therapy Language', 'Onboarding Language', 'User Health Details', 'Activity Level Question', 'Email', 'BMI', 'Username', 'Secondary Conditions', 'Activation Code', 'Mobile Verification', 'Disclaimer', 'T&C', 'Carey Introduction', 'Carey Name'];
    this.widgetid = [39, 37, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 1];
    for (let k = 0; k <= 15; k++) {
      if (a == this.widgetid[k]) {
        this.widgetname = this.widgets[k];
        break;
      }
    }
  }

  changeOptions(e, type) {
    if (type == 'pre_text') {
      this.pretextcount = e;
      var control1 = <FormArray>this.dataForm.controls['pre_text'];
      while (control1.length !== 0) {
        control1.removeAt(0);
      }
      for (var k = 0; k < e; k++) {
        control1.push(this.initPreTextOptions());
      }
    }
    if (type == 'post_text') {
      this.posttextcount = e;
      var control2 = <FormArray>this.dataForm.controls['post_text'];
      while (control2.length !== 0) {
        control2.removeAt(0);
      }
      for (var k = 0; k < e; k++) {
        control2.push(this.initPostTextOptions());
      }
    }
    if (type == 'skip_text') {
      this.skiptextcount = e;
      var control3 = <FormArray>this.dataForm.controls['skip_text'];
      while (control3.length !== 0) {
        control3.removeAt(0);
      }
      for (var k = 0; k < e; k++) {
        control3.push(this.initSkipTextOptions());
      }
    }
  }


  // To get All Widgets
  // ----------------------------

  getWidgetMapper(ide) {
    this.contentService.getOneDataById(ide, "onboardingflowmapper")
      .subscribe(
        (res: any) => this.getWidgetMappSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getWidgetMappSuccess(data) {
    this.widgetdata = data;
    if (this.widgetdata.pre_text !== null && this.widgetdata.pre_text.length > 0) {
      this.pretextcount = (this.widgetdata.pre_text).length;
      const pretextcount = <FormControl>this.dataForm.controls['pre_text_count'];
      pretextcount.setValue(this.pretextcount.toString());
      this.changeOptions(this.pretextcount, 'pre_text');
      for (let k = 0; k < this.pretextcount; k++) {
        let pretxtformelement = (<FormArray>(this.dataForm.controls['pre_text'])).at(k);
        pretxtformelement['controls'].pretxtoptn.setValue(this.widgetdata.pre_text[k]);
      }
    }
    else {
      this.widgetdata.pre_text = [];
    }
    if (this.widgetdata.post_text !== null && this.widgetdata.post_text.length > 0) {
      this.posttextcount = (this.widgetdata.post_text).length;
      const posttextcount = <FormControl>this.dataForm.controls['post_text_count'];
      posttextcount.setValue(this.posttextcount.toString());
      this.changeOptions(this.posttextcount, 'post_text');
      for (let k = 0; k < this.posttextcount; k++) {
        let posttxtformelement = (<FormArray>(this.dataForm.controls['post_text'])).at(k);
        posttxtformelement['controls'].posttxtoptn.setValue(this.widgetdata.post_text[k]);
      }
    }
    else {
      this.widgetdata.post_text = [];
    }
    if (this.widgetdata.skip_text !== null && this.widgetdata.skip_text.length > 0) {
      this.skiptextcount = (this.widgetdata.skip_text).length;
      const skiptextcount = <FormControl>this.dataForm.controls['skip_text_count'];
      skiptextcount.setValue(this.skiptextcount.toString());
      this.changeOptions(this.skiptextcount, 'skip_text');
      for (let k = 0; k < this.skiptextcount; k++) {
        let skiptxtformelement = (<FormArray>(this.dataForm.controls['skip_text'])).at(k);
        skiptxtformelement['controls'].skiptxtoptn.setValue(this.widgetdata.skip_text[k]);
      }
    }
    else {
      this.widgetdata.skip_text = [];
    }
    this.dataForm.patchValue(this.widgetdata);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
  }


  // Submitting onboarding Content Data
  // -----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    delete postdata.pre_text_count;
    delete postdata.post_text_count;
    delete postdata.skip_text_count;
    postdata.created_by_data_fk = postdata.created_by_data_fk.toString();
    var skiptextoptn = [];
    postdata.skip_text.forEach(el => {
      skiptextoptn.push(el.skiptxtoptn);
    });
    postdata.skip_text = JSON.stringify(skiptextoptn);
    var pretextoptn = [];
    postdata.pre_text.forEach(el => {
      pretextoptn.push(el.pretxtoptn);
    });
    postdata.pre_text = JSON.stringify(pretextoptn);
    var posttextoptn = [];
    postdata.post_text.forEach(el => {
      posttextoptn.push(el.posttxtoptn);
    });
    postdata.post_text = JSON.stringify(posttextoptn);
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    postdata.widget_data_fk = postdata.widget_data_fk.toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    if (this.newitem == true) {
      this.contentService.postToBlade(postdata, 'onboardingflowmapper', true)
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'onboardingflowmapper', true)
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Onboarding content has been added"), "OK");
    this.router.navigate(['content/onboardingcontent'])
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Onboarding content has been updated"), "OK");
    this.router.navigate(['content/onboardingcontent'])
  }

  // -----------------------------

}
