import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DoctorGuardGuard } from '../shared/guards';
//import { ActivationhomeComponent } from './activationhome/activationhome.component';
//import { AllqrComponent } from './allqr/allqr.component';
//import { DoctorpdfreportComponent } from './components/doctorpdfreport/doctorpdfreport.component';
//import { GeneraldataComponent as DoctoruserviewComponent } from './components/generaldata/generaldata.component';
//import { DocactivationComponent } from './docactivation/docactivation.component';
//import { DocalertsComponent } from './docalerts/docalerts.component';
//import { DocpatientgeneratedcodeComponent } from './docpatientgeneratedcode/docpatientgeneratedcode.component';
//import { DocsettingsComponent as DocSettingsComponent } from './docsettings/docsettings.component';
//import { MainpanelComponent as DocMainPanelComponent } from './mainpanel/mainpanel.component';
//import { QuickAccessComponent } from './modals/quick-access/quick-access.component';
//import { ResetpasswordComponent } from './modals/resetpassword/resetpassword.component';

const routes: Routes = [
  //{ path: 'home', component: DocMainPanelComponent },
  { path: 'home', loadChildren: () => import('./mainpanel/mainpanel.module').then(m => m.MainpanelModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'settings', component: DocSettingsComponent, canActivate: [DoctorGuardGuard] },
  { path: 'settings', loadChildren: () => import('./docsettings/docsettings.module').then(m => m.DocsettingsModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'notifications', component: DocalertsComponent, canActivate: [DoctorGuardGuard] },
  { path: 'notifications', loadChildren: () => import('./docalerts/docalerts.module').then(m => m.DocalertsModule), canActivate: [DoctorGuardGuard] },
  { path: 'activation', loadChildren: () => import('./docactivation/docactivation.module').then(m => m.DocactivationModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'activation', component: DocactivationComponent, canActivate: [DoctorGuardGuard] },
  //{ path: 'generate/:id', component: DocpatientgeneratedcodeComponent, canActivate: [DoctorGuardGuard] },
  { path: 'generate/:id', loadChildren: () => import('./docpatientgeneratedcode/docpatientgeneratedcode.module').then(m => m.DocpatientgeneratedcodeModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'user/:id/:followup', component: DoctoruserviewComponent, canActivate: [DoctorGuardGuard] },
  { path: 'user/:id/:followup', loadChildren: () => import('./components/generaldata/generaldata.module').then(m => m.GeneraldataModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'serviceagreement', component: QuickAccessComponent, canActivate: [DoctorGuardGuard] },
  //{ path: 'resetpassword', component: ResetpasswordComponent, canActivate: [DoctorGuardGuard] },
  { path: 'resetpassword', loadChildren: () => import('./modals/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule), canActivate: [DoctorGuardGuard] },
  { path: 'serviceagreement', loadChildren: () => import('./modals/quick-access/quick-access.module').then(m => m.QuickAccessModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'allqr', component: AllqrComponent, canActivate: [DoctorGuardGuard] },
  { path: 'allqr', loadChildren: () => import('./allqr/allqr.module').then(m => m.AllqrModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'activate', component: ActivationhomeComponent, canActivate: [DoctorGuardGuard] },
  { path: 'activate', loadChildren: () => import('./activationhome/activationhome.module').then(m => m.ActivationhomeModule), canActivate: [DoctorGuardGuard] },
  { path: 'doctorpdf/:id/:startdate/:enddate', loadChildren: () => import('./components/doctorpdfreport/doctorpdfreport.module').then(m => m.DoctorpdfreportModule), canActivate: [DoctorGuardGuard] },
  //{ path: 'doctorpdf/:id/:startdate/:enddate', component: DoctorpdfreportComponent, canActivate: [DoctorGuardGuard] }
  { path: 'settings/serviceagreement-es', loadChildren: () => import('./modals/spanish-tnc/spanish-tnc.module').then(m => m.SpanishTncModule), canActivate: [DoctorGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoctorRoutingModule { }
