<div class="userdetailcontent">
  <div class="col-md-12 dotted-border">
    <div class="col-md-12 no-padding">
      <ul class="list-inline table-filter-btn">
        <li [class]="activecls == 'therapy' ? 'active' : ''">
          <a (click)="filterData('therapy')">
            Therapy
          </a>
        </li>
        <li [class]="activecls == 'general' ? 'active' : ''">
          <a (click)="filterData('general')">
            General
          </a>
        </li>

      </ul>
    </div>
    <div class="level-data" *ngIf="activecls == 'therapy'">


      <div class="row">
        <div class="col-md-12">
          <button mat-flat-button color="primary" [matMenuTriggerFor]="progressmenu" class="programprogressmenu">
            Level {{selectedWeek}} <mat-icon class="bottomarrowfloat">expand_more</mat-icon>
          </button>
          <mat-menu #progressmenu="matMenu">
            <button mat-menu-item *ngFor="let week of currentWeekArray[0];let i = index"
              (click)="initComponentDraw(week, i)">
              <span>Level {{i+1}}</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="row">
        <div class="userprogresssection">
          <div *ngIf="(userProgressSubject$ | async) as userData; else loadingOrError">
            <div
              *ngIf="userData?.module_data && (userData?.progress_is_completed != false || userData?.progress_status == 'ongoing')">
              <div class="clearfix"></div>
              <div class="matcardbottomzero col-md-12">
                <mat-card style="padding-left: 25px;">
                  <mat-card-header>
                    <mat-card-title>
                      <div class="align ">
                        <p>{{userData?.title}} </p>
                        <button mat-icon-button class="copyux" style="right: 43px;"
                          (click)="copyUXID('level',userData?.uuxid, userData?.title)">Send</button>
                      </div>
                    </mat-card-title>
                  </mat-card-header>
                </mat-card>
              </div>
              <mat-accordion class="col-md-12">
                <mat-expansion-panel *ngFor="let module of userData?.module_data; let i = index;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="align">
                        <p>Module - {{module.title}}</p>
                      </div>

                    </mat-panel-title>
                    <!-- <button class="copyux" mat-icon-button style="right: 75px;top:10px"
                      (click)="copyUXID('module',module?.uuxid, module?.title)"
                      [disabled]="module?.progress_status != 'ongoing'">Send</button> -->
                  </mat-expansion-panel-header>
                  <div *ngFor="let chapter of module.chapter_data; let p = index;" class="chaptercard">
                    <div class="align" style="padding-left: 0;">
                      <span class="moveupthebox">Chapter - <p class="chapter-p">{{chapter.title}}</p></span>
                      <button class="copyux" mat-icon-button style="right: 40px;"
                        (click)="copyUXID('chapter',chapter?.uuxid, chapter?.title)"
                        [disabled]="chapter?.progress_status != 'ongoing'">Send</button>
                    </div>
                    <div class="subpartcards" *ngIf="chapter.subpart_data">
                      <div style="display: block;margin-bottom: 10px;" class="title">Lesson & Quiz</div>
                      <div *ngFor="let subpartchap of chapter.subpart_data"
                        [class]="subpartchap.data?.progress_is_completed == true ? 'lessonquizcard': 'greycard'">
                        <div class="align">
                          <p>
                            {{subpartchap.data?.title}}
                          </p>
                          <button class="copyux" mat-icon-button
                            (click)="copyUXID(subpartchap.type,subpartchap.data?.uuxid,subpartchap.data?.title)"
                            [disabled]="subpartchap.data?.progress_status != 'ongoing'">Send</button>
                        </div>
                      </div>
                    </div>
                    <!-- <div *ngIf="chapter.carey_card_data">
                      <div class="title">Carey Card</div>
                    </div>
                    <div *ngIf="chapter.carey_card_data" class="chaptercard-margin"
                      [class]="chapter.carey_card_data?.progress_is_completed == true ? 'careycard': 'greycard'">
                      <div class="align">
                        <p>{{chapter.carey_card_data.title}} </p>
                        <button class="copyux" mat-icon-button
                          (click)="copyUXID('',chapter.carey_card_data?.uuxid,chapter.carey_card_data?.title )">Send</button>
                      </div>
                    </div> -->
                    <div *ngIf="chapter.magazine_data">
                      <div class="title">Magazine</div>
                    </div>
                    <div class="magazinecard-margin" *ngIf="chapter.magazine_data"
                      [class]="chapter.magazine_data.progress_is_completed == true ? 'magazinecard': 'greycard'">
                      <div class="align">
                        <p>{{chapter.magazine_data.title}}</p>
                        <button class="copyux" mat-icon-button
                          (click)="copyUXID('magazine',chapter.magazine_data?.uuxid,chapter.magazine_data?.title)">Send</button>
                      </div>
                    </div>
                    <!-- <div *ngIf="chapter.feedback">
                      <div class="title">Feedback</div>
                    </div>
                    <div class="feedback chaptercard-margin" *ngIf="chapter.feedback"
                      [class]="chapter.feedback.progress_is_completed == true ? 'feedback': 'greycard'">
                      <div class="align">
                        <p>{{chapter.feedback.title}}</p>
                        <button class="copyux" mat-icon-button
                          (click)="copyUXID('',chapter.feedback?.uuxid, chapter.feedback?.title)">Send</button>
                      </div>
                    </div> -->
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div style="clear: both;"><br /><br /></div>
            </div>
          </div>
          <ng-template #loadingOrError>
            <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async">
            </app-loadorerror>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="general-data" *ngIf="activecls == 'general'">
      <div class="col-md-6 uxuid-data no-padding">
        <h2 class="commontitleprimary">Profile Deeplinks</h2>
        <ul class="list-group">
          <li class="list-group-item">Clinical details
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('clinical_details','Clinical details')">Send</button>
          </li>
          <li class="list-group-item">App details
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('appdetails','App details')">Send</button>
          </li>
          <li class="list-group-item">Profile Progress
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('profileprogress','Profile Progress')">Send</button>
          </li>

          <li class="list-group-item">Personal Details
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('personal_details','Personal Details')">Send</button>
          </li>
          <li class="list-group-item">Care Team
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('careteam','Care Team')">Send</button>
          </li>
          <li class="list-group-item">Health kit
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('healthkit','Health kit')">Send</button>
          </li>
          <li class="list-group-item">tandc
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('tandc','tandc')">Send</button>
          </li>
          <li class="list-group-item">Privacy
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('privacy','Privacy')">Send</button>
          </li>
        </ul>
      </div>

      <div class="col-md-6 uxuid-data">
        <h2 class="commontitleprimary">Quick Action Deeplinks</h2>
        <ul class="list-group">
          <li class="list-group-item">Healthcoach
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('healthcoach','Healthcoach')">Send</button>
          </li>
          <li class="list-group-item">Log
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log','Log')">Send</button>
          </li>
          <li class="list-group-item">Profile Progress
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('profileprogress','Profile Progress')">Send</button>
          </li>

          <li class="list-group-item">Reminder
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('reminder','Reminder Deeplink')">Send</button>
          </li>
          <li class="list-group-item">Medicine
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('medicine','Medicine')">Send</button>
          </li>
          <li class="list-group-item">FAQ
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('faq','FAQ')">Send</button>
          </li>
        </ul>
      </div>

      <div class="clearfix"></div>

      <div class="col-md-6 uxuid-data no-padding">
        <h2 class="commontitleprimary">Logging Deeplinks</h2>
        <ul class="list-group">
          <li class="list-group-item">Meal
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Meal','Meal')">Send</button>
          </li>
          <li class="list-group-item">Activity
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Activity','Activity')">Send</button>
          </li>
          <li class="list-group-item">Blood sugar
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('log/Blood Sugar','Blood sugar')">Send</button>
          </li>

          <li class="list-group-item">Blood pressure
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('log/Blood Pressure','Blood pressure')">Send</button>
          </li>
          <li class="list-group-item">Water
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Water','Water')">Send</button>
          </li>
          <li class="list-group-item">Peak Flow
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('log/Peak Flow','Peak Flow')">Send</button>
          </li>
          <li class="list-group-item">Weight
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Weight','Weight')">Send</button>
          </li>
          <li class="list-group-item">Symptom
            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Symptoms','Symptom')">Send</button>
          </li>
          <li class="list-group-item">Lab report
            <button class="copystaticux" mat-icon-button
              (click)="copyDeeplink('log/Lab Reports','Lab report')">Send</button>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>