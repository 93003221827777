<div class="col-md-12">
  <div class="col-md-12">
    <h3 class="broadcasttitle" style="float: left;">Broadcast</h3>
    <div class="close-icon mt-15 pull-right">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        close
      </i>
    </div>
    <!-- <button mat-button class="no-hover-effect" [mat-dialog-close]="true" class="mt-15 pull-right"><i class="material-icons">clear</i></button> -->
    <div class="clearfix"><br /><br /><br /><br /></div>

    
    <table class="table table-user-information">
      <tbody>
        <tr>
          <td>Name</td>
          <td> <b> {{broadcastData?.name}}</b></td>
        </tr>
        <tr>
          <td>Category</td>
          <td><b>{{broadcastData?.type}}</b></td>
        </tr>
        <tr>
          <td>Message</td>
          <td> <b>{{broadcastData?.message}}</b></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="broadcastData.mime_type == 'video'">
      <video *ngIf="broadcastData.media_url" [src]="broadcastImageUrl"
        style="width: auto;max-width:100%;max-height: 300px;" controls></video>
    </div>
    <div *ngIf="broadcastData.mime_type == 'audio'">
      <audio *ngIf="broadcastData.media_url" [src]="broadcastImageUrl" controls></audio>
    </div>
    <div *ngIf="broadcastData.mime_type == 'image'">
      <img *ngIf="broadcastData.media_url" src="{{broadcastImageUrl}}"
        style="width: auto;max-width:100%;max-height: 300px;" />
    </div>
    <div *ngIf="broadcastData.mime_type == 'application'">
      <a *ngIf="broadcastData.media_url" [href]="docurl" target="_blank"> <img [src]="broadcastImageUrl"
          class="pdfboxcss" /> </a>
    </div>
  </div>
</div>