<mat-drawer-container class="sidenav-container" autosize>
  <div class="switchsession" *ngIf="sessionstart">
    <div class="innerbox row">
      <img src="assets/img/Loading.gif" class="col-md-4 zeropadright" style="width: 72px;" />
      <p class="col-md-8 zeropadright padtop10"> Accessing <br /> <b class="accesshcname">{{sessionname.split('
          ')[0]}}'s </b> Dashboard.</p>
    </div>
  </div>
  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-hcassignleftnav></app-hcassignleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/hchome"></app-breadcrumb>
      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <!-- [routerLink]="['/admin/hccreate/edit',newsection]" -->
          <button mat-icon-button class="create-btn" (click)="editHC('newsection')">
            Create healthcoach
          </button>
        </h3>
      </div>

      <div class="filtter-search">
        <div class="col-md-12">
          <form [formGroup]="createStoreForm">
            <div class="form-group has-feedback has-search">
              <input class="form-control"  type="text" name="user" class="form-control" [formControl]="createStoreForm.controls['user']"
                placeholder="Search healthcoach"><!--[(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}"-->
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch('all')">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              <button class="clear-search" mat-button *ngIf="searchcriteria" matSuffix
                                mat-icon-button aria-label="Clear" (click)="cancelSearch()">
                                <mat-icon>close</mat-icon>
                            </button>
            </div>
          </form>

        </div>
      </div>


      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''">
            <a (click)="tableSort('all')" class="all">All</a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">Active</a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">Inactive</a>
          </li>
          <li [class]="activecls == 'onleave' ? 'active' : ''">
            <a (click)="tableSort('onleave')" class="onleave-filter">Onleave</a>
          </li>
        </ul>
      </div>

      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Healthcoach </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="no_of_patients">
              <th mat-header-cell *matHeaderCellDef> Patient assigned </th>
              <td mat-cell *matCellDef="let element" class="notranslate"> {{element.number_of_patients}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td class="camelcased" mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="is_on_leave">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element"><span class="status-dot"
                  [ngClass]="activeclass(element.is_on_leave,element.is_active)"></span>{{element.is_on_leave?'On
                leave':(element.is_active?'Active':'Inactive')}}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">
                  <!-- [routerLink]="['/admin/hccreate/edit',element.id]" -->
                  <button mat-button (click)="editHC(element.id)" tooltip="Edit HC">
                    <img src="assets/img/dls/admin-table/Icon_Edit_hover.svg" alt="">
                  </button>
                  <button mat-button (click)="viewProfile(element)" tooltip="View Profile">
                    <img src="assets/img/dls/admin-table/Icon_View profile.svg" alt="">
                  </button>
                  <button mat-button (click)="changepassword(element)" tooltip="Change Password">
                    <img src="assets/img/dls/admin-table/Changepassword.svg" alt="">

                  </button>

                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="switchSession(element.id,element.name)"
                      class="no-hover-effect">Access
                      Dashboard</button>                    


                    <button mat-menu-item  (click)="changepasswordHistory(element)"  *ngIf="element.show_history === true"  disableRipple class="no-hover-effect">
                      Change Password History
                    </button>
                    <button mat-menu-item   *ngIf="element.show_history === false" style="opacity:0.5;cursor: not-allowed;"   matTooltip="This healthcoach has no password change history"  disableRipple class="no-hover-effect">
                      Change Password History
                    </button>
                  
                  
                    <button mat-menu-item (click)="forceLogout(element.id)" disableRipple class="no-hover-effect">Force
                      Logout
                    </button>
                    <button mat-menu-item *ngIf="element.is_active" [routerLink]="['/admin/hcstatus',element.id]">Change
                      Status</button>
                    <button mat-menu-item *ngIf="element.is_active" [routerLink]="['/admin/hctimelog',element.id]">View
                      Time Logs</button>
                    <button mat-menu-item (click)="downloadReport(element)">Download Log Reports</button>
                    <button mat-menu-item disableRipple class="no-hover-effect active-green"
                    [ngClass]="{'my-class': element.is_active == true}"
                    (click)="isActive(element.is_active,element.id)"
                    *ngIf="!element.is_on_leave && userData">{{element.is_active? "Deactivate" :
                    "Activate"}}</button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>


            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple [matMenuTriggerFor]="menu3" class="no-hover-effect">Edit
                        Healthcoach
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <button mat-button (click)="viewProfile(element)" disableRipple class="no-hover-effect">View
                        Profile
                      </button>
                      <button mat-button (click)="changepassword(element)" disableRipple
                        [ngClass]="{'no-border-right': element.is_on_leave}" class="no-hover-effect">Change Password
                      </button>
                      <button mat-button (click)="switchSession(element.id,element.name)" class="no-hover-effect">Access
                        Dashboard</button>
                      <button mat-button (click)="forceLogout(element.id)" disableRipple class="no-hover-effect">Force
                        Logout
                      </button>
                      <button mat-button disableRipple class="no-hover-effect active-green"
                        [ngClass]="{'my-class': element.is_active == true}"
                        (click)="isActive(element.is_active,element.id)" style="margin-left:10px;"
                        *ngIf="!element.is_on_leave && userData">{{element.is_active? "Deactivate" :
                        "Activate"}}</button>
                      <mat-menu #menu3="matMenu" class="hc-options">
                        <button mat-menu-item *ngIf="element.is_active"
                          [routerLink]="['/admin/hcstatus',element.id]">Change Status</button>
                        <button mat-menu-item [routerLink]="['/admin/hccreate/edit',element.id]">Edit Profile</button>
                        <button mat-menu-item *ngIf="element.is_active"
                          [routerLink]="['/admin/hctimelog',element.id]">View Time Logs</button>
                        <button mat-menu-item (click)="downloadReport(element)">Download Log Reports</button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>
      </div>

    </div>
  </div>
  <div class="wrapper col-md-12" id="pdf" style="display: none;">
    <div class="title-in-border col-md-12">
      <h1><span>Overall stats</span></h1>
      <div class="stats-data">
        <div class="data">
          <p class="key">Healthcoach</p>
          <p class="value">{{reportdata?.name}}</p>
        </div>
        <div class="data data-timelogged">
          <p class="key">Time Logged Today</p>
          <p class="value">{{getroundown((reportdata?.total_logs * 6) / 60)}} Hrs
            {{(reportdata?.total_logs * 6) % 60 | number : '1.0-0'}} Min</p>
        </div>
        <div class="data">
          <p class="key">Breaks</p>
          <p class="value">{{reportdata?.total_breaks}}</p>
        </div>
        <div class="data data-breaks">
          <p class="key">Total Days</p>
          <p class="value">{{reportdata?.datediff}}</p>
        </div>
      </div>
    </div>
    <div class="title-in-border col-md-12">
      <h1><span>Daily stats</span></h1>
      <div class="stats-data">
        <table style="width: 100%;">
          <tr>
            <th>Date</th>
            <th>Time Logged</th>
            <th>Breaks</th>
          </tr>
          <tr *ngFor="let data of reportdata?.alldata">
            <td>{{data?.date}}</td>
            <td>{{getroundown((data?.total_logs * 6) / 60)}} Hrs
              {{(reportdata?.total_logs * 6) % 60 | number : '1.0-0'}} Min</td>
            <td>{{data?.total_breaks}}</td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</mat-drawer-container>