import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SHORT_MONTHS } from 'src/app/shared/data/constants';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-patient-phone-change-log',
  templateUrl: './patient-phone-change-log.component.html',
  styleUrls: ['./patient-phone-change-log.component.scss']
})

export class PatientPhoneChangeLogComponent implements OnInit {

  mySource = [];
  displayedColumns: string[] =  ['id', "newNumber", "reasonHC", "healthCoach", "status","adminName", "reasonAdmin", "dateAndTime"];
  pageNumber = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PatientPhoneChangeLogComponent>,
    private systemService: SystemService,
    private commonService: CommonserviceService
  ) { }

  ngOnInit(): void {
    this.callLogApi();
  }

  callLogApi(){
    this.commonService.getUserPhoneChangeLog(this.data.id, this.pageNumber).subscribe((data) => {
      if(data.status === 200) {
        this.mySource = [...this.mySource, ...data.data];
      }
    }, (error) => {
      this.systemService.handleErrorResponse(error);
    }); 
  }

  close(){
    this.dialogRef.close();
  }

  convertDate(date) {
    var d = new Date(date);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()
    
    return currentdate + ' ' + month + ' ' + year + ', ' + d.toLocaleTimeString();;

  }

  getNextSet(){
    this.pageNumber += 1;
    this.callLogApi();
  }

}
