import { post } from 'jquery';
/*==========================================
Title:  created_by_data_fk changes
Author: Soundharya AM
Date:   23 April 2020
Last Change : dynamic data from localstorage has been added for created_by_data_fk 
===========================================*/
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject} from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

@Component({
  selector: 'app-editquestion',
  templateUrl: './editquestion.component.html',
  styleUrls: ['./editquestion.component.scss']
})

export class EditquestionComponent implements OnInit {
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: [''],
    type: ['', Validators.required],
    option_count: ['', Validators.required],
    options: this.fb.array([]),
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    tags: ["", Validators.required],
    correct_explanation: ['', [Validators.required, whiteSpaceValidation]],
    wrong_explanation: ['', [Validators.required, whiteSpaceValidation]]
  });
  initOptions() {
    return this.fb.group({
      text: ['', this.noWhitespaceValidator],
      media_id: [],
      is_correct: ['', Validators.required]
    });
  }
  title: any = 'New Question';
  htmlContent: any;
  chooseMedia: any;
  questiondata: any;
  s3url: any = environment.s3url;
  public typeselected: any;
  public newitem: boolean = true;
  public showButtonLoader: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any = [];
  public imageData: any;
  public tempImage: any;
  public textCount: any;
  public alltags: any;
  public textCountArray: any = [];
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  constructor(private browserTitle: Title, public fb: FormBuilder, public cdr: ChangeDetectorRef, public dialog: MatDialog, private contentService: ContentserviceService,
    private systemService: SystemService, private route: ActivatedRoute, private router: Router) { }

  // Validator For Whitespaces
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Question');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallQuestionData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallQuestionData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallQuestionData(params.chid);
        }
      }
    });
  }

  generateOptions(e) {
    // this.allmedia = [];
    this.textCountArray = [];
    const control = <FormArray>this.dataForm.controls['options'];
    if (control.length !== 0) {
      if (control.length < e) {
        for (let l = control.length; l < e; l++) {
          control.push(this.initOptions());
          // this.allmedia.push("");
        }
      }
      if (control.length > e) {
        for (let l = control.length; l > e; l--) {
          control.removeAt(l - 1);
          this.allmedia.pop();
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        control.push(this.initOptions());
        this.allmedia.push("");
      }
    }
  }

  // To Get Media
  // ----------------------------

  getMedia(id, count) {
    this.allmedia = [];
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
    .subscribe((res: any) => this.getMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getMediaSuccess(data) {
    this.imageData = data.getOneMedia.thumbnail_path;
    this.allmedia.push(this.imageData);
  }

  // ----------------------------


  // To Get All Questions
  // ----------------------------

  getallQuestionData(ide) {
    this.contentService.getOneDataById(ide, 'question')
      .subscribe((res: any) => this.getallQuestionSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getallQuestionSuccess(data) {
    const control = <FormArray>this.dataForm.controls['options'];
    
    this.questiondata = data;
    for (var p = 0; p < this.questiondata.options.length; p++) {
      control.push(this.initOptions());
    }
    this.dataForm.patchValue(this.questiondata);
    if (this.questiondata.type == 'image') {
      for (var j = 0; j < this.questiondata.options.length; j++) {
        this.getMedia(this.questiondata.options[j].media_id, this.questiondata.option_count);
        this.textCountArray[j] = (this.questiondata.options[j].text);
      }
    }

    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
  }

  // ----------------------------

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  modelChangedTags() {
    this.filterTags();
  }

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}',false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------


  // Submitting Question data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.title = this.removeHTMLtags(postdata.title)
    postdata.correct_explanation = this.removeHTMLtags(postdata.correct_explanation)
    postdata.wrong_explanation = this.removeHTMLtags(postdata.wrong_explanation)
    var correctoption = [];
    var optiontext = [];
    var mediaoption = [];
    postdata.options.forEach(el => {
      correctoption.push(el.is_correct);
      optiontext.push(el.text);
      mediaoption.push(el.media_id);
    });
    optiontext = optiontext.map(v => v.toLowerCase());
    if ((correctoption.filter(x => x === true).length) !== 1) {
      this.systemService.showSnackBar("Please set only one 'Is this a correct option?' value as 'Yes'", "OK");
    }
    else if (!optiontext.every((e, i, a) => a.indexOf(e) === i)) {
      this.systemService.showSnackBar("Please enter the unique texts to create the question", "OK");
    }
    else if (!mediaoption.every((e, i, a) => a.indexOf(e) === i) && postdata.type == 'image') {
      this.systemService.showSnackBar("Please select the unique images to create the question", "OK");
    }
    else {
      if (postdata.type == 'text') {
        postdata.options.forEach(el => {
          el.media_id = null;
        });
      }
      postdata.options = JSON.stringify(postdata.options);
      postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
      postdata.language_data_fk = postdata.language_data_fk.toString();
      postdata.option_count = postdata.option_count.toString();
      postdata.type = postdata.type.toString();
      postdata.tags = JSON.stringify(postdata.tags);
      postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
      delete postdata.image_url;
      if (this.newitem == true) {
        this.contentService
          .postToBlade(postdata, 'question')
          .subscribe((res: any) => this.onCreateSuccess(),
           (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      } else {
        this.contentService
          .updateToBlade(this.childid, this.selfparentid, postdata, 'question')
          .subscribe((res: any) => this.onUpdateSuccess(),
           (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Question has been added"), "OK");
    this.router.navigate(['content/question']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Question has been updated"), "OK");
    this.router.navigate(['content/question']);
  }

  // ----------------------------


  openmediabox(type, index) {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: type
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = (<FormArray>this.dataForm.controls['options']).at(
        index
      );
      formelement['controls'].media_id.setValue(data.id);
      this.allmedia[index] = data.path;
    });
  }
  ​ removeHTMLtags(value:string){
    return value.toString().replace( /(<([^>]+)>)/ig, '');
  }
}
