import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HctasklistRoutingModule } from './hctasklist-routing.module';
import { ExecutehctaskComponent } from './executehctask/executehctask.component';
import { HctasklistComponent } from './hctasklist.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HcleftnavModule } from '../shared/hcleftnav/hcleftnav.module';
import { CompletehctaskComponent } from './completehctask/completehctask.component';
import { HctaskmanualComponent } from './hctaskmanual/hctaskmanual.component';
import { HctasksemiautoComponent } from './hctasksemiauto/hctasksemiauto.component';
import { HctasksemiautoperformComponent } from './hctasksemiauto/hctasksemiautoperform/hctasksemiautoperform.component';
import { UserlistnavbarModule } from '../components/userlistnavbar/userlistnavbar.module';
import { ViewbroadcastComponent } from '../broadcast/viewbroadcast/viewbroadcast.component';
import { MealbreakupComponent } from 'src/app/doctor/components/mealbreakup/mealbreakup.component';
import { LabreportdialogComponent } from '../components/labreportdialog/labreportdialog/labreportdialog.component';
import { DiaryLogDialogComponent } from '../components/diarylog/diarylog/diarylogdialog.component';
import { ShowmodalComponent } from '../components/showmodal/showmodal.component';
import { ChatbotmodalComponent } from '../components/chatbotmodal/chatbotmodal.component';
import { LogtagdialogComponent } from '../components/tabpartials/userdetail/logtagdialog/logtagdialog.component';
import { AddmedicineComponent } from '../components/addmedicine/addmedicine.component';
import { UploadprescriptionaldialogComponent } from '../components/uploadprescriptionaldialog/uploadprescriptionaldialog.component';
import { AddreminderComponent } from '../components/addreminder/addreminder.component';
import { AddsymptomsComponent } from '../components/addsymptoms/addsymptoms.component';
import { AlltriggersComponent } from '../components/alltriggers/alltriggers.component';
import { DeeplinkComponent } from '../components/deeplink/deeplink.component';
import { PaymentlinkComponent } from '../components/paymentlink/paymentlink.component';
import { CareyChatComponent } from '../components/careychat/careychat.component';
import { AddprescriptionComponent } from '../components/addprescription/addprescription.component';
import { PumpaddComponent } from '../components/pump/pumpadd/pumpadd.component';
import { MedicationaddComponent } from '../components/medicationadd/medicationadd.component';
import { EditmedicationComponent } from '../components/medicationadd/editmedication/editmedication.component';
import { MedicalhistoryaddComponent } from '../components/medicalhistoryadd/medicalhistoryadd.component';
import { RouteofadminaddComponent } from '../components/routeofadminadd/routeofadminadd.component';
import { InfusionsiteaddComponent } from '../components/infusionsiteadd/infusionsiteadd.component';
import { AdversedrugaddmodalComponent } from '../components/adversedrugaddmodal/adversedrugaddmodal.component';
import { AddhopitalisationComponent } from '../components/addhopitalisation/addhopitalisation.component';
import { ContextService as HealthCoachContextService } from './../services/context.service';
import { ChatsectionComponent } from '../components/chatsection/chatsection.component';
import { UserdetailsectionComponent } from '../components/userdetailsection/userdetailsection.component';
import { UserdetailComponent } from '../components/tabpartials/userdetail/userdetail.component';
import { ProgramdetailComponent } from '../components/tabpartials/programdetail/programdetail.component';
import { KnowuserComponent } from '../components/tabpartials/knowuser/knowuser.component';
import { ProgramprogressComponent } from '../components/tabpartials/programprogress/programprogress.component';
import { DiaryComponent } from '../components/tabpartials/diary/diary.component';
import { PrescriptionsComponent } from '../components/tabpartials/prescriptions/prescriptions.component';
import { TargetsComponent } from '../components/tabpartials/targets/targets.component';
import { LabreportsComponent } from '../components/tabpartials/labreports/labreports.component';
import { LoadorerrorComponent } from '../components/utilities/loaderror/loadorerror/loadorerror.component';
import { NodataComponent } from '../components/utilities/nodata/nodata/nodata.component';
import { LoaderComponent } from '../components/utilities/loader/loader/loader.component';
import { RenderchatsComponent } from '../components/renderchats/renderchats.component';
import { ActivityComponent } from '../components/tabpartials/activity/activity.component';
import { PumpComponent } from '../components/pump/pump.component';
import { HomeModule } from '../home/home.module';
import { chatSectionModule } from '../components/chatsection/chatsection.module';
// import { usertransferModule } from '../components/usertransfer/usertransfer/usertransfer.module';
import { userdetailsectionModule } from '../components/userdetailsection/userdetailsection.module';
import { UserDetailModule } from '../components/tabpartials/userdetail/userdetail.module';
import { ActivityModule } from '../components/tabpartials/activity/activity.module';
import { knowUserModule } from '../components/tabpartials/knowuser/knowuser.module';
import { ProgramDetailModule } from '../components/tabpartials/programdetail/programdetail.module';
import { ProgramProgressModule } from '../components/tabpartials/programprogress/programprogress.module';
import { dairyModule } from '../components/tabpartials/diary/dairy.module';
import { LabReportModule } from '../components/tabpartials/labreports/labreports.module';
import { prescriptionsModule } from '../components/tabpartials/prescriptions/prescriptions.module';
import { TargetsModule } from '../components/tabpartials/targets/targets.module';
import { LoadoErrorModule } from '../components/utilities/loaderror/loadorerror/loadoerror.module';
import { NoDataModule } from '../components/utilities/nodata/nodata/nodata.module';
import { LoaderModule } from '../components/utilities/loader/loader/loader.module';
import { RenderChatsModule } from '../components/renderchats/renderchats.module';
import { TasksModule } from '../components/tabpartials/tasks/tasks.module';
import { sendUserBroadcastModule } from '../components/senduserbrodcast/senduserbrodcast/senduserbroadcast.module';
import { userTransferModule } from '../components/usertransfer/usertransfer/usertransfer.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';


// import { chatSectionModule } from '../components/chatsection/chatSection.module';


@NgModule({
  declarations: [ExecutehctaskComponent, HctasklistComponent, CompletehctaskComponent, HctaskmanualComponent, HctasksemiautoComponent, HctasksemiautoperformComponent,
  ],

  imports: [
    HctasklistRoutingModule,
    CommonModule, 
    HcleftnavModule,
    UserlistnavbarModule,
    chatSectionModule,
    userdetailsectionModule,
    UserDetailModule,
    ActivityModule,
    knowUserModule,
    ProgramDetailModule,
    ProgramProgressModule,
    dairyModule,
    LabReportModule,
    prescriptionsModule,
    TargetsModule,
    LoadoErrorModule,
    NoDataModule,
    LoaderModule,
    RenderChatsModule,
    TasksModule,
    sendUserBroadcastModule,
    userTransferModule,
    SharedModule.forRoot(),
    PickerModule
  ],
  providers: [
    HealthCoachContextService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent,
    AddprescriptionComponent,
    PumpaddComponent,
    MedicationaddComponent,
    EditmedicationComponent,
    MedicalhistoryaddComponent,
    RouteofadminaddComponent,
    InfusionsiteaddComponent,
    AdversedrugaddmodalComponent,
    AddhopitalisationComponent
  ]
})


export class HctasklistModule { }
