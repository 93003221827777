import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';


@Component({
  selector: 'app-infusionsiteadd',
  templateUrl: './infusionsiteadd.component.html',
  styleUrls: ['./infusionsiteadd.component.scss']
})
export class InfusionsiteaddComponent implements OnInit {
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedUserID: any;
  bodydata: any;
  public isinvalid: boolean = false;
  private allfiles: any;
  public uploadedfiletype: any;
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
  prescription_type: any;
  responseUrl: any;
  private responsethumbUrl: any;
  upload_prescription_type: any;
  upload_type: any = upload_type;
  upload_doc_type: any;
  private resdata: any;
  public image_path: any;
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public infoForm = this.fb.group({
    patient_id_data_fk: [''],
    infusion_site: ['', [Validators.required]],
    site_change_reason: ['' ,Validators.required],
    reminder_days: ['',Validators.required],
    log_date: ['', Validators.required]
  });
  isloadingpres: boolean = false;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public demoForm = new FormGroup({
    files: this.filesControl,
  });

  constructor(public router: Router, public fb: FormBuilder, public dialog: MatDialog, private appContext: ContextService, private userDetailService: UserdetailsService, private systemService: SystemService, private commonService: CommonserviceService) { }

  ngOnInit(): void {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
  }

  addInfusionSite() {
    var bodydata = this.infoForm.value;
    bodydata.infusion_site = bodydata.infusion_site;
    bodydata.site_change_reason = bodydata.site_change_reason;
    bodydata.reminder_days = bodydata.reminder_days; 
    bodydata.patient_id_data_fk = this.selectedUserID.toString();
    bodydata.logged_by = 'hc'
    bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
    // const momentDate = new Date(this.infoForm.value.log_date); // Replace event.value with your date value 
    bodydata.log_date = bodydata.log_date;
    // this.userDetailService.addInfusionSite(bodydata).pipe(
    //   catchError((error: any) => {
    //     this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
    //     return throwError(error);
    //   }),
    //   finalize(() => {
    //   })
    // ).subscribe(
    //   (data) => {
    //     this.dialog.closeAll();
    //     this.systemService.showSnackBar("Infusion Site added.", "OK");
    //     // this.rawdata = data;
    //     // this.showButtonLoader = false;

    //   })
  }

}
export enum upload_type {
  prescription_pdf,
  prescription_image
}

