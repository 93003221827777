import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { ApproveDialogComponent } from '../publish-request/approve-dialog/approve-dialog.component';
import { CheckmultilingualComponent } from '../publish-request/checkmultilingual/checkmultilingual.component';
import { PublishdetailsComponent } from '../publish-request/publishdetails/publishdetails.component';
import { StartstagingComponent } from '../publish-request/startstaging/startstaging.component';
import { ViewcommentComponent } from '../publish-request/viewcomment/viewcomment.component';
import { CommonserviceService } from '../services/commonservice/commonservice.service';

@Component({
  selector: 'app-publish-request',
  templateUrl: './publish-request.component.html',
  styleUrls: ['./publish-request.component.scss'],
  animations: [
    detailExpandTable
  ]
})

export class PublishRequestComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['title', 'level', 'status','action'];
   public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  expandedElement: any;
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  actRoute: string;
  actRouteEdit: string;
  stagingdetails: any;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Publish Request');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  viewComment(element): void {
    this.dialog.open(ViewcommentComponent, {
      width: '750px',
      data: {
        element: element
      }
    });
  }

  viewDetails(element): void {
    this.dialog.open(PublishdetailsComponent, {
      width: '750px',
      data: {
        element: element
      }
    });
  }

  openStaging(element): void {
    this.dialog.open(StartstagingComponent, {
      width: '350px',
      data: {
        element: element
      }
    });
  }

  statusConvert(e) {
    return e.replace(/_/g, " ").toLowerCase();
  }


  // To delete Request
  // ----------------------------

  delete(id) {
    this.commonService.hardDelete('patient', id)
      .subscribe(
        (res: any) => this.onDeleteSuccess(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // To Open Dialog for Approval
  // ----------------------------

  openApproveDialog(approve, id) {
    this.stagingdetails = this.dialog.open(ApproveDialogComponent, {
      width: '750px',
      height: '500px',
      data: {
        approved: approve,
        id: id
      }
    });
    this.stagingdetails.afterClosed().subscribe(data => {
      this.lastpage = 0;
      this.page = 1;
      this.tabulardata = [];
      this.search();
    });
  }

  // Check multilingual

  openCheckMultilingialDialog(id) {
    this.dialog.open(CheckmultilingualComponent, {
      width: '750px',
      height: '500px',
      data: {
        id: id
      }
    });
  }

  // ----------------------------


  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  typeFilter(fln) {
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.patient_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // To Search OTP List
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllPatients(this.searchcriteria, this.page.toString(), "20", "all", "request", false)
        .subscribe(
          (res: any) => this.onSearchSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSearchSuccess(data) {
    data = data.data;
    
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------

}

export interface PeriodicElement {
  name: string;
  phone: string;
}
