<div class="overlay">
    <div class="Downloader">{{timeleftval}}</div>
    <div class="bgaround"></div>
</div>
<div style="width: 940px; overflow: auto;height: 100vmin;">
    <div class="">
        <div id="section1" class="wrapper sec1">
            <div class="row">
                <div class="secondpagedefaultheight">
                    <div class="center-row">
                        <div class="col-md-6">
                            <div class="logo-section pull-left">
                                <img src="assets/img/doctor/report-wellthy-d.png"
                                    [class]="clientData?.logo_media ? 'img-responsive mr-15' : 'img-responsive mr0'"
                                    alt="" />
                                <img *ngIf="clientData?.logo_media"
                                    [class]="clientData?.logo_media ? 'image-data img-responsive mr0' : 'image-data img-responsive'"
                                    [src]="s3url + clientData?.logo_media" crossorigin="*" />
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="report-title pull-right">
                                <h4 style="padding-bottom: 10px;">{{profileData?.primary_condition}} Report,
                                    {{(daterange?.startdate | date:'MMM') == (daterange?.enddate | date:'MMM') ? '' :
                                    (daterange?.startdate | date:'MMM') + '-'}}{{daterange?.enddate | date:'MMM y'}}
                                </h4>
                                <h5>Comorbidity:
                                    {{profileData?.conditions ? profileData?.conditions.join() : 'None Selected'}}</h5>
                                <p class="text-capitalize">{{profileData?.name}}, {{profileData?.gender}},

                                    {{userAge}}
                                </p>
                                <p>Program Start Date: {{profileData?.therapy_start_date | date:'MMM d, y'}}</p>
                                <p>Doctor Name: {{profileData?.doctor_name}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12 p-0">
                        <hr style="margin-top: 10px;">
                    </div>
                    <!----------Pump Details------------->
                    <div class="lifestyle center-row">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">Pump Details </h3>
                                </div>
                                <div class="table-holder">
                                    <table mat-table [dataSource]="pumpData" class="col-md-12">
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef> Date
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.created_at | date: 'dd/MM/yyyy'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>Pump</th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.title}} </span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pump_route_type">
                                            <th mat-header-cell *matHeaderCellDef> Pump Type </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="text-capitalize">{{element.pump_route_type}}</span>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="reason">
                                            <th mat-header-cell *matHeaderCellDef>Reason For Change </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="text-capitalize">
                                                    <!-- {{element.reason}} -->
                                                    {{element.reason_for_change == null ? ' Not Available' :
                                                    element.reason_for_change}}
                                                </span>
                                            </td>

                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
                                    </table>
                                    <p class="nodata-placeholder" *ngIf="!pumpData">No Data
                                        Available
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!----------Route of Administration------------->
                    <div class="lifestyle center-row">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">Route of Administration </h3>
                                </div>
                                <div class="table-holder">
                                    <table mat-table [dataSource]="routeOfAdministrationData" class="col-md-12">
                                        <ng-container matColumnDef="routeDate">
                                            <th mat-header-cell *matHeaderCellDef> Date
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.created_at | date: 'dd/MM/yyyy'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="routeOfAdministration">
                                            <th mat-header-cell *matHeaderCellDef> Route of
                                                Administration</th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.route_of_administration}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="reasonOfAddition">
                                            <th mat-header-cell *matHeaderCellDef> Reason for
                                                Addition </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>
                                                    <!-- {{element.reason_for_addition}} -->
                                                    {{element.reason_for_addition == null ? '-- Not Available --' :
                                                    element.reason_for_addition}}

                                                </span>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns5;"></tr>
                                    </table>
                                    <p class="nodata-placeholder" *ngIf="!routeOfAdministrationData">No Data
                                        Available
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!----------Infusion Changes------------->
                    <div class="lifestyle center-row">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">Infusion Changes </h3>
                                </div>
                                <div class="table-holder">

                                    <table mat-table [dataSource]="infusionSiteData" class="col-md-12">
                                        <ng-container matColumnDef="innfusionDate">
                                            <th mat-header-cell *matHeaderCellDef style="width: 35%;"> Date
                                            </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.log_date | date: 'dd/MM/yyyy'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="innfusionSite">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Infusion Site
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span
                                                    class="text-capitalize">{{readableText(element.infusion_site)}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="reasonOfChange">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Reason for
                                                Change </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span class="text-capitalize">
                                                    {{element.site_change_reason == null ? ' Not Available' :
                                                    element.site_change_reason}}
                                                    <!-- {{element.site_change_reason}} -->
                                                </span>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="interval">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Interval </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.reminder_days}}</span>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns6"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns6;"></tr>
                                    </table>
                                    <p class="nodata-placeholder" *ngIf="!infusionSiteData">No Data
                                        Available
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 tncsection">
                    <div class="col-md-8">
                        <span>* For more info refer to Detailed Report - page 1,</span>
                        <span>** For more info refer to Detailed Report - Page 2</span>
                    </div>
                    <div class="col-md-4 alignright">
                        <span>Page 1/3</span>
                    </div>
                </div>
            </div>

        </div>
        <div id="section2" class="wrapper">
            <div class="row">
                <div class="secondpagedefaultheight">
                    <!----------Medication------------->
                    <div class="lifestyle center-row">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">Medication </h3>
                                </div>
                                <div class="table-holder">

                                    <table mat-table [dataSource]="medicationData" class="col-md-12">
                                        <ng-container matColumnDef="medicationDate">
                                            <th mat-header-cell *matHeaderCellDef> Date
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.created_at | date: 'dd/MM/yyyy'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="drugName">
                                            <th mat-header-cell *matHeaderCellDef> Drug Name
                                            </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.title}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="dose">
                                            <th mat-header-cell *matHeaderCellDef>Dose</th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.dose}} {{element.dose_unit}}</span>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="flowRate">
                                            <th mat-header-cell *matHeaderCellDef> Drug Concentration </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.drug_concentration}}
                                                    {{element.drug_concentration_unit}}</span>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns7"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns7;"></tr>
                                    </table>
                                    <p class="nodata-placeholder" *ngIf="!medicationData">No Data
                                        Available
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!------------------------->

                    <!----------Hospitalisation------------->
                    <div class="lifestyle center-row">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">Hospitalisation </h3>
                                </div>
                                <div class="table-holder">

                                    <table mat-table [dataSource]="hospitalisationData" class="col-md-12">
                                        <ng-container matColumnDef="duration">
                                            <th mat-header-cell *matHeaderCellDef> Duration
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.duration_from | date: 'dd/MM/yyyy'}} to
                                                    {{element.duration_to | date: 'dd/MM/yyyy'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="procedures">
                                            <th mat-header-cell *matHeaderCellDef> Procedures
                                            </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.procedures}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="reason">
                                            <th mat-header-cell *matHeaderCellDef>Reason</th>
                                            <td mat-cell *matCellDef="let element">
                                                <span>{{element.reason}}</span>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="stay_details">
                                            <th mat-header-cell *matHeaderCellDef> Stay Details </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.stay_details}}</span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef> Type </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.type}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="next_doctor_visit">
                                            <th mat-header-cell *matHeaderCellDef> Next Doctor Visit </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span>{{element.next_doctor_visit}}</span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="hsopitalisationColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: hsopitalisationColumns;"></tr>
                                    </table>
                                    <p class="nodata-placeholder" *ngIf="!hospitalisationData">No Data
                                        Available
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="lifestyle center-row mt0 pt0">
                        <div class="col-md-12">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h3 class="title pb10"> SYMPTOMS OCCURED <span>Based on {{datediff}} days</span>
                                    </h3>
                                </div>
                                <div class="table-holder">
                                    <table *ngIf="symptomsOccuredData" mat-table [dataSource]="symptomsOccuredData"
                                        class="col-md-12">
                                        <ng-container matColumnDef="mostFrequentEvents">
                                            <th mat-header-cell *matHeaderCellDef> Most frequent events
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span class="normalFontWeight text-capitalize">
                                                    {{element?.frequent_symptom_title}}
                                                </span>
                                                <!-- {{element.mostFrequentEvents.substr(0, element.mostFrequentEvents.indexOf('('))}}</span> -->
                                                <span class="boldFontWeight">
                                                    &nbsp; &nbsp; &nbsp; {{' x ' + element?.frequent_occurance + ''}}
                                                    <!-- {{element.mostFrequentEvents.substr(element.mostFrequentEvents.indexOf('('))}} -->
                                                </span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="mostSevereEvents">
                                            <th mat-header-cell *matHeaderCellDef> Most severe events </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span *ngIf="element?.severe_symptom_title" class="text-capitalize">
                                                    {{element?.severe_symptom_title}}
                                                </span>
                                                <span *ngIf="!element?.severe_symptom_title">
                                                    -
                                                </span>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                                    </table>


                                    <p class="nodata-placeholder" *ngIf="!symptomsOccuredData">No Data
                                        Available
                                    </p>
                                    <div class="labels labels-border" *ngIf="symptomsOccuredData">
                                        Listed in the order of frequency & severity highest to lowest
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lifestyle center-row pt0">
                        <div class="col-md-12">
                            <div class="meal-target">
                                <div class="title-holder">
                                    <h3 class="title mt0 pb10">HC NOTE</h3>
                                </div>
                                <p class="hc-notep" *ngFor="let note of hcNoteData; let i=index;">
                                    <span *ngIf="hcNoteData?.length == i+1">{{note.content}}</span>
                                </p>
                                <p class="nodata-placeholder" *ngIf="hcNoteData == ''">
                                    No Data Available
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 tncsection2">
                    <div class="col-md-8">
                        <span>* For more info refer to Detailed Report - page 1,</span>
                        <span>** For more info refer to Detailed Report - Page 2</span>
                    </div>
                    <div class="col-md-4 alignright">
                        <span>Page 2/3</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="section3" class="wrapper">
            <div class="row">
                <div class="secondpagedefaultheight">
                    <div class="lifestyle center-row">
                        <div class="col-md-12">
                            <div class="meal-target mt0">
                                <div class="title-holder">
                                    <h3 class="title pb10">LAB REPORTS </h3>
                                </div>
                                <div class="table-holder">
                                    <table mat-table [dataSource]="labReportTableData" class="col-md-12">
                                        <ng-container matColumnDef="tests">
                                            <th mat-header-cell *matHeaderCellDef style="width: 35%;"> Tests
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">{{changeTextInList(element?.lab_report_title)
                                                    + '(' + element?.unit + ')'}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="value1">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Value
                                                1 </th>
                                            <td mat-cell *matCellDef="let element">
                                                <span *ngIf="element?.value2?.measure"
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">
                                                    {{element?.value2?.measure}}
                                                    <span *ngIf="element?.log_date">
                                                        {{'(' + (element?.log_date | date: 'dd/MM/yy') + ')'}}
                                                    </span>
                                                </span>
                                                <span *ngIf="!element?.value2?.measure">
                                                    -
                                                </span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="value2">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> Value
                                                2 </th>
                                            <td mat-cell *matCellDef="let element">

                                                <span *ngIf="element?.value1?.measure"
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">
                                                    {{element?.value1?.measure}}
                                                    <span *ngIf="element?.log_date">
                                                        {{'(' + (element?.log_date | date: 'dd/MM/yy') + ')'}}
                                                    </span>
                                                </span>
                                                <span *ngIf="!element?.value1?.measure">
                                                    -
                                                </span>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="v1v2">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;"> V1-V2
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <p
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData display-flex nomargin' : 'nomargin'">
                                                    <span class="v1v2values tbitemval1">{{(element?.value1?.measure -
                                                        element?.value2?.measure) < 0 ? (element?.value1?.measure -
                                                            element?.value2?.measure) *-1 :(element?.value1?.measure -
                                                            element?.value2?.measure)}}</span>
                                                            <span style="position: relative;top: 4px; "
                                                                *ngIf="(element?.value1?.measure && element?.value2?.measure && ((element?.value1?.measure - element?.value2?.measure) > 0)) ">
                                                                <img style="width: 11px;"
                                                                    src="assets/img/doctor/uparrow.png"
                                                                    class="img-responsive" alt=""> </span>
                                                            <span style="position: relative;top: 4px;"
                                                                *ngIf="(element?.value1?.measure && element?.value2?.measure && ((element?.value1?.measure - element?.value2?.measure) < 0)) ">
                                                                <img style="width: 11px;transform: rotate(180deg);"
                                                                    src="assets/img/doctor/uparrow.png"
                                                                    class="img-responsive" alt=""> </span>
                                                </p>

                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="normalRange">
                                            <th mat-header-cell *matHeaderCellDef style="width: 16.25%;">
                                                Normal Range </th>
                                            <td mat-cell *matCellDef="let element"> <span
                                                    [ngClass]="(element.rangeBoldData) ? 'boldData ' : ''">
                                                    {{element?.range}}</span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                                    </table>
                                    <div class="labels labels-border pull-left">
                                        <div class="label-inline">
                                            <img src="assets/img/doctor/uparrow.png" class="img-responsive" alt="">
                                            Increase
                                        </div>
                                        <div class="label-inline">
                                            <img src="assets/img/doctor/uparrow.png" class="img-responsive img-decrease"
                                                alt="">
                                            Decrease
                                        </div>
                                    </div>
                                </div>
                                <p class="nodata-placeholder" *ngIf="!labReportTableData">
                                    No Data Available
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 tncsection2">
                    <div class="col-md-8">
                        <span>* For more info refer to Detailed Report - page 1,</span>
                        <span>** For more info refer to Detailed Report - Page 2</span>
                    </div>
                    <div class="col-md-4 alignright">
                        <span>Page 3/3</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>