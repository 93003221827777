<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav *ngIf="adminData?.user_type != 'scarlet'"></app-adminleftnav>

  <div class="sidenav-content">
    <div class="col-md-8 col-md-offset-2 hcprofile">

      <div class="media knowusercontent">
        <div class="media-left media-middle">
          <div class="card-avatar">
            <img class="media-object" [src]="s3url + adminData.profile_image?.path" />
          </div>
        </div>
        <div class="media-body">
          <div class="clearfix">
            <h4 class="media-heading pull-left">{{adminData?.name}} <span *ngIf="adminData.email.length>2">&nbsp;
                •</span> &nbsp;
              {{adminData?.email}}</h4>
            <div class="jeg_nav_item jeg_dark_mode pull-right">
              <label class="dark_mode_switch">
                <input type="checkbox" class="jeg_dark_mode_toggle" type="checkbox" id="checkbox"
                  [(ngModel)]=isThemeDark (change)="toggleDarkTheme(isThemeDark)">
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <p class="text-capitalize">{{adminData?.user_type}} &nbsp;
            • &nbsp; {{adminData?.phone}}</p>
        </div>
      </div>



      <!-- <mat-card class="hcprofile-card knowusercontent">
        <mat-card-header>
          <div mat-card-avatar><img [src]="s3url + adminData.profile_image?.path" /></div>
          <mat-card-title class="card-container-left">{{adminData?.name}} <span *ngIf="adminData.email.length>2">&nbsp;
              •</span> &nbsp;
            {{adminData?.email}}</mat-card-title>
          <mat-card-title class="card-container-right">
            <div class="jeg_nav_item jeg_dark_mode">
              <label class="dark_mode_switch">
                <input type="checkbox" class="jeg_dark_mode_toggle" type="checkbox" id="checkbox"
                  [(ngModel)]=isThemeDark (change)="toggleDarkTheme(isThemeDark)">
                <span class="slider round"></span>
              </label>
            </div>
          </mat-card-title>

          <mat-card-subtitle class="creator-title text-capitalize">{{adminData?.user_type}} &nbsp;
            • &nbsp; {{adminData?.phone}}</mat-card-subtitle>
        </mat-card-header>

      </mat-card> -->
      <hr />



      <div class="clearfix"><br /></div>
      <div class="reset-password">
        <mat-accordion>
          <form [formGroup]="dataForm" (ngSubmit)="changePassword()" validate>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Change Password
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <br>
                <mat-form-field appearance="outline" class="col-md-12 col-xs-12">
                  <mat-label>New Password</mat-label>

                  <input matInput type="password" formControlName="new_password" placeholder="New Password">
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline" class="col-md-12 col-xs-12">
                  <mat-label>Confirm Password</mat-label>

                  <input matInput type="password" formControlName="confirmPassword" placeholder="Confirm Password">
                </mat-form-field>
                <p *ngIf="this.error" class="errorcls">{{this.message}}</p>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-flat-button color="primary" class="centerbtn" style="margin:15px 0;"
                    [disabled]="!dataForm.valid">Reset Password</button>
                </div>
              </div>
            </mat-expansion-panel>
          </form>
        </mat-accordion>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <h4 class="h4title">Or</h4>
          <br>
          <button mat-raised-button class="centerbtn pull-left" *ngIf="adminData?.user_type == 'scarlet'"
            [routerLink]="['/application']" style="margin-right: 15px;">
            <i class="material-icons">
              dashboard
            </i> Dashboard</button>

          <button mat-raised-button class="centerbtn pull-left" (click)="logoutAccount()">
            <i class="material-icons">
              exit_to_app
            </i> Logout from all devices</button>
          <div class="clearfix"><br /><br /></div>
          <div class="col-md-6 p-0">
            <a href="https://documents.wellthy.app/termsandcondition" target="_blank" class="tncandpolicy" rel="noopener">Terms and
              Condition</a>
            <a href="https://documents.wellthy.app/privacypolicy" target="_blank" class="tncandpolicy" rel="noopener">Privacy
              Policy</a>
            <a href="mailto:helpdesk@wellthy.care?subject=Opt out from Admin Dashboard&body=Hi, I want to opt out from Admin dashboard."
              target="_blank" class="tncandpolicy">Opt Out</a>
          </div>
        </div>
      </div>
      <br />
      <ng-template #buttonSubmitLoader>
        <app-loader showLoader="{{true}}"></app-loader>
      </ng-template>
    </div>
  </div>
</mat-drawer-container>