import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DietPlanpdfreportComponent } from './dietplanpdfreport.component';
import { HealthcoachGuardGuard } from '../../../../../shared/guards';


const routes: Routes = [
   { path: '', component: DietPlanpdfreportComponent, canActivate: [HealthcoachGuardGuard]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DietPlanpdfreportRoutingModule { }