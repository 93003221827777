<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="healthcoach/hctasklist"></app-breadcrumb>
        <div class="main-wrapper mt-30">
            <div class="row">

                <div class="filter-search">
                    <div class="col-md-2">
                        <!-- <button mat-button [matMenuTriggerFor]="animals" class="scar-dropdown">Filter</button> -->
                        <button *ngIf="therapyDataFilter.length==0 && clientDataFilter.length==0 && ownerDataFilter.length==0 && priorityDataFilter.length==0 && expiryDataFilter.length==0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown" >Filter</button>
                        <button *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown1">Filter</button>
                        <mat-menu #animals="matMenu" class="multi-menu-list">
                            <button mat-menu-item [matMenuTriggerFor]="therapy">Select therapy</button>
                            <button mat-menu-item [matMenuTriggerFor]="client">Select client</button>
                            <button mat-menu-item [matMenuTriggerFor]="owner">Select owner</button>
                            <button mat-menu-item [matMenuTriggerFor]="priority">Select priority</button>
                            <button mat-menu-item [matMenuTriggerFor]="expiry">Select expiry</button>
                        </mat-menu>

                        <mat-menu #therapy="matMenu">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"  [(ngModel)]="chosenTherapy">
                                <mat-radio-button *ngFor="let therapy of therapyData" value="therapy.id"
                                    (change)="selectedTherapy(therapy.id,therapy.title)">
                                    {{therapy.title}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-menu>

                        <mat-menu #client="matMenu">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group" [(ngModel)]="chosenClient">
                                <mat-radio-button *ngFor="let client of clientData" value="client.id"
                                    (change)="selectedClient(client.id,client.title)">
                                    {{client.title}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-menu>

                        <mat-menu #owner="matMenu">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"  [(ngModel)]="chosenOwner">
                                <mat-radio-button *ngFor="let owner of ownerList" value="id"
                                    (change)="selectedOwner(owner.id,owner.name)">
                                    {{owner.name}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-menu>

                        <mat-menu #priority="matMenu" class="custom-menu-filter">
                            <!-- <form validate class="filter-form"> -->
                                <div class="outerlayout">
                                    <div (click)="$event.stopPropagation();">
                                        <h5 class="scar-dropdown-label">Choose from here</h5>
                                        <mat-checkbox [(ngModel)]="highPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox>High</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="mediumPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox1>Medium</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="lowPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox2>Low</mat-checkbox>
                                        <button mat-button class="btn btn-filled"
                                            (click)="selectedPriority()">Done</button>
                                        <button mat-button class="btn btn-outline"
                                            (click)="clear(myCheckBox,myCheckBox1,myCheckBox2)">Clear</button>
                                    </div>
                                </div>
                            <!-- </form> -->
                        </mat-menu>

                        <mat-menu #expiry="matMenu">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group" [(ngModel)]="Chosendate">
                                <mat-radio-button value="Today"  (change)="tagfilter($event.value)">
                                    Today
                                </mat-radio-button>
                            </mat-radio-group>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group" [(ngModel)]="Chosendate">
                                <mat-radio-button value="Thisweek" (change)="tagfilter($event.value)">
                                    This week
                                </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group" [(ngModel)]="Chosendate">
                          
                                <mat-radio-button value="customDate" (click)="$event.stopPropagation();"
                                    style="overflow: scroll;" (click)="openCalendar($event)">
                                    Custom date
                                </mat-radio-button>
                                <input type="text" matInput ngxDaterangepickerMd style="display: none;"
                                [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" startKey="startDate"
                                endKey="endDate" [(ngModel)]="selected" name="daterange"
                                (change)='selectDateRange()' placeholder="choose date" />
                            </mat-radio-group>
                        </mat-menu>


                    </div>
                    <div class="col-md-10">
                        <form>
                            <div class="form-group has-feedback has-search">
                                <input class="form-control" placeholder="Search" [(ngModel)]="searchcriteria"
                                    [ngModelOptions]="{standalone: true}">
                                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                                    <mat-icon matSuffix>search</mat-icon>
                                </button>
                                <button class="clear-search" mat-button *ngIf="searchcriteria" matSuffix
                                mat-icon-button aria-label="Clear" (click)="cancelSearch()">
                                <mat-icon>close</mat-icon>
                            </button>
                            </div>
                        </form>
                    </div>
                    

                    <mat-chip-list *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0">
                        <br />
                        <mat-chip class="notranslate" selectable="true" removable="true" *ngFor="let data of therapyDataFilter" (removed)="remove(data)">
                            {{data.therapyName}}                          
                                <mat-icon matChipRemove>cancel</mat-icon>
                         </mat-chip>
                        <mat-chip   class="notranslate" selectable="true" removable="true" *ngFor="let data of clientDataFilter" (removed)="remove1(data)">
                            {{data.clientName}}                           
                                <mat-icon matChipRemove>cancel</mat-icon>                          
                        </mat-chip>
                        <mat-chip  class="notranslate" selectable="true" removable="true"  *ngFor="let data of ownerDataFilter" (removed)="remove2(data)">
                            {{data.ownerName}}                          
                                <mat-icon matChipRemove>cancel</mat-icon>                            
                        </mat-chip>
                        <mat-chip  class="notranslate" selectable="true" removable="true" *ngFor="let data of priorityDataFilter" (removed)="remove3(data)">
                            {{data.title}}                           
                                <mat-icon matChipRemove>cancel</mat-icon>                          
                        </mat-chip>
                        <mat-chip  class="notranslate" selectable="true" removable="true" *ngFor="let data of expiryDataFilter" (removed)="remove4(data)">
                            {{data.title}}                          
                                <mat-icon matChipRemove>cancel</mat-icon>                           
                        </mat-chip>
                        <mat-chip>
                            <span style="border-color: #EE6505 ;color: #EE6505 ;background-color:white"
                                *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0"
                                (click)="clearChips()">
                                clear all
                            </span>
                        </mat-chip>
                        <br /> <br /><br />
                    </mat-chip-list>
                </div>

                <div class="col-md-12">
                    <div class="container-table">
                        <table mat-table [dataSource]="dataSource" matSort class="widthfluid bordergrey list-table">
                            <ng-container matColumnDef="task_id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Task ID </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'bold-text': element.priority == '2', 'red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday }">
                                    <!-- <span [class]="element.status == 'published' ? 'circle green' : 'circle red'"></span> -->
                                    <span [ngClass]="checkcolor(element)"></span>
                                    {{element.task_display_id}}
                                </td>
                            </ng-container>
                          
                            <ng-container matColumnDef="task_summary">
                                <th mat-header-cell *matHeaderCellDef> To do task </th>
                                <td mat-cell class="text-capitalize" *matCellDef="let element"  [ngClass]="{'bold-text': element.priority == '2','red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday}">
                                    {{element.task_summary}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="priority">
                                <!-- mat-sort-header -->
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
                                <td mat-cell class="text-capitalize" *matCellDef="let element"  
                                [ngClass]="{'bold-text': element.priority == '2','priority-high':element.priority == '2','priority-low ':element.priority == '0','priority-medium':element.priority == '1'}">
                                    <span *ngIf="element.priority == '2'" class="priority high"></span>
                                    <span *ngIf="element.priority == '0'" class="priority low"></span>
                                    <span *ngIf="element.priority == '1'" class="priority medium"></span>
                                    {{priorityInList(element.priority)}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="expiry_date">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Expiry </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'bold-text': element.priority == '2','red-text':element.status == '0'||element.status == '2' && element.expiry_date < getToday}"> {{element.expiry_date|date:'dd-MMM-yyy'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> Status </th>
                                <td mat-cell class="text-capitalize" *matCellDef="let element"
                                    >
                                    <span *ngIf="element.status == '2' && element.expiry_date > getToday">
                                        <img src="assets/img/dls/icons/Icon_Pending.svg"
                                            class="img-responsive" alt="Wellthy"></span>
                                    <!-- <span *ngIf="failed">
                                        <img src="assets/img/dls/icons/Icon_Incomplete.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </span> -->
                                    <span *ngIf="element.status == '2' &&  element.expiry_date < getToday">
                                        <img src="assets/img/dls/icons/Icon_Incomplete.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </span>
                                    <span *ngIf="element.status == '3'">
                                        <img src="assets/img/dls/icons/Icon_Pending.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </span>
                                    <span *ngIf="element.status == '4'">
                                        <img src="assets/img/dls/icons/Icon_complete.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </span>
                                    {{statusInList(element)}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="action-button-row" >
                                        <button (click)="routeChange(element)"
                                            routerLinkActive="router-link-active" mat-button tooltip="Perform"
                                            placement="bottom" [disabled]="element.expiry_date < getToday || element.status == '4'">
                                            <img src="assets/img/dls/icons/Icon_Perform.svg" class="img-responsive"
                                                alt="Wellthy">
                                        </button>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <div style="text-align: center;font-size: 20px;margin-top: 80px;"
                                *ngIf="dataList.length==0">No
                                Data Found</div>
                        <div class="spinner-holder" *ngIf="isLoading">
                            <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate"
                                diameter="40">
                            </mat-progress-spinner>
                        </div>
                        <div style="height: 100px;"></div>
                        <!-- <div *ngIf="!tabulardata.length">
                            <p>Tasks are not assigned</p>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</mat-drawer-container>