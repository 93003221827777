<div mat-dialog-content style="text-align: center; ">
	<div class="deletetext">
		{{message}}
	</div>
</div>
<div mat-dialog-actions class="mat-btn-holder" style="margin-left: 22px;">
	<button mat-stroked-button mat-dialog-close class="btn btn-outline"
		(click)="onCancelClick()">{{cancelButtonText}}</button>

	<button mat-flat-button class="deletebutton" class="btn btn-filled"
		(click)="onConfirmClick()">{{confirmButtonText}}</button>
</div>