
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../../content/services/contentservice/contentservice.service';

@Component({
  selector: 'app-edithclist',
  templateUrl: './edithclist.component.html',
  styleUrls: ['./edithclist.component.scss']
})

export class EdithclistComponent implements OnInit {
  public allhc: any;
  public selectedOption: any;
  userhc: any;
  public showloader: boolean = false;
  public localStoredHCData: any;
  public postdata = {
    "created_by_data_fk": '',
    'is_test_code': '',
    'coach_ids': '',
    'has_coach_access': '',
    'client_data': '',
    'has_client': '',
    'campaign_data': '',
    'request_name': '',
    'has_campaign': ''
  };

  constructor(private browserTitle: Title, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService,
    public dialogRef: MatDialogRef<EdithclistComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Health Coach');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getHC();
    this.userhc = this.data.element;
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
  }


  // To Get Health Coach Data
  // ----------------------------

  getHC() {
    this.contentService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name,display_name}}')
      .subscribe(
        (res: any) => this.getHCData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getHCData(data) {
    data = (data as any).data;
    this.allhc = data.getAllHealthcoaches;
    var dataarray = []
    for (var i = 0; i < this.userhc.healthcoach_name.length; i++) {
      for (var j = 0; j < this.allhc.length; j++) {
        if (this.userhc.healthcoach_name[i].id == this.allhc[j].id) {
          dataarray.push(this.allhc[j].id);
        }
      }
    }
    this.selectedOption = dataarray;
  }

  // ----------------------------


  // Submitting HC Data
  // ----------------------------

  dataObject() {
    this.postdata.created_by_data_fk = this.localStoredHCData.id.toString();

    if (this.userhc.is_test_code == true) {
      this.postdata.is_test_code = 'true';
    }
    else {
      this.postdata.is_test_code = 'false';
    }
    if (this.selectedOption) {
      this.postdata.coach_ids = this.selectedOption;
      this.postdata.has_coach_access = 'true';
    }
    else {
      this.postdata.coach_ids = this.selectedOption;
    }
    if (this.userhc.client_id) {
      this.postdata.has_client = TrueFalsedata[0];
      this.postdata.client_data = this.userhc.client_id.toString();
    }
    else {
      this.postdata.has_client = TrueFalsedata[1];
      this.postdata.client_data = '';
    }
    if (this.userhc.campaign_id) {
      this.postdata.has_campaign = "true";
      this.postdata.campaign_data = this.userhc.campaign_id.toString();
    }
    else {
      this.postdata.has_campaign = "false";
      this.postdata.campaign_data = '';
    }
    this.postdata.coach_ids = JSON.stringify(this.postdata.coach_ids);
    this.postdata.request_name = this.removeHTMLtags(this.userhc.request_name)
    this.contentService.updateActivationCode(this.postdata, this.userhc.id)
      .subscribe(
        (res: any) => this.onEditSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onEditSuccess(data) {
    data = (data as any);
    this.dialog.closeAll();
    this.systemService.showSuccessSnackBar(" Successfully Updated Healthcoach", "OK");
  }

  // ----------------------------

  closeModal(): void {
    this.dialogRef.close();
  }

  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}

export enum TrueFalsedata {
  'true',
  'false'
}