import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AdminusersRoutingModule } from './adminusers.routing.module';
import { AdminusersComponent } from './adminusers.component';
import { EdituserComponent } from './edituser/edituser.component';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { SharedTranslateModule } from 'src/app/shared/shared-translate.module';
import { ChangeuserroleComponent } from '../../admin/adminsection/changeuserrole/changeuserrole.component';


@NgModule({
  declarations: [
    
    AdminusersComponent,
    EdituserComponent,
    ChangeuserroleComponent
    
  ],
  imports: [
    SharedModule.forRoot(),
    CommonModule,
    AdminusersRoutingModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    ImageCropperModule,
    SharedTranslateModule
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AdminusersModule {}