<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <app-adminsectionleftnav></app-adminsectionleftnav>
        <div class="mainrightcontainersecondary">
            <app-breadcrumb route="admin/taskadmin"></app-breadcrumb>
            <div class="main-wrapper">
                <!-- <mat-select [formControl]="toppingsControl" placeholder="Filter" multiple class="sortcommonwhite"
                style="width: 100%;">
                <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}
                </mat-option>
               </mat-select> -->
                <div class="row">
                    <div class="filter-search mt-40 mb-40">
                        <div class="col-md-2">


                            <!-- <button mat-button [matMenuTriggerFor]="animals" class="scar-dropdown">Filter</button> -->
                            <button *ngIf="expiryDataFilter.length==0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown" >Filter</button>
                            <button *ngIf="expiryDataFilter.length!=0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown1">Filter</button>
                            <mat-menu #animals="matMenu" class="admintask-library">
                                <button mat-menu-item [matMenuTriggerFor]="vertebrates">Select Date</button>
                                <button mat-menu-item [matMenuTriggerFor]="invertebrates">Performance</button>
                            </mat-menu>

                            <mat-menu #vertebrates="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"  [(ngModel)]="Chosendate">
                                    <mat-radio-button value="Today" (change)="tagfilter($event.value)">
                                        Today
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"  [(ngModel)]="Chosendate">
                                    <mat-radio-button value="Thisweek" (change)="tagfilter($event.value)">
                                        This week
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"  [(ngModel)]="Chosendate">
                                    <mat-radio-button value="customeDate" (click)="$event.stopPropagation();"
                                        (click)="openCalendar($event)">
                                        Custom date
                                    </mat-radio-button>
                                    <!-- <input type="text" style="display: none;"  value="customeDate" (click)="tagfilter($event.value)"
                                        ngxDaterangepickerMd [(ngModel)]="selected" /> -->
                                    <input type="text" matInput ngxDaterangepickerMd style="display: none;"
                                        [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" startKey="startDate"
                                        endKey="endDate" [(ngModel)]="selected" name="daterange" value="customeDate"
                                        (change)='selectDateRange()' placeholder="choose date" />
                                </mat-radio-group>
                            </mat-menu>

                            <mat-menu #invertebrates="matMenu">
                                <button mat-menu-item (click)="performanceFilter('htl')">High to low</button>
                                <button mat-menu-item (click)="performanceFilter('lth')">Low to high</button>
                            </mat-menu>
                        </div>

                        <div class="col-md-10">
                            <form>
                                <div class="form-group has-feedback has-search">

                                    <input class="form-control" [(ngModel)]="searchcriteria"
                                        [ngModelOptions]="{standalone: true}" placeholder="Search">
                                    <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                                        <mat-icon matSuffix>search</mat-icon>
                                    </button>
                                    <button class="clear-search" mat-button *ngIf="searchcriteria" matSuffix
                                        mat-icon-button aria-label="Clear" (click)="cancelSearch()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="col-md-12 filterchipcss">
                            <mat-chip-list>
                                <mat-chip class="notranslate" selectable="true" removable="true"
                                [removable]="removable" (removed)="removeOption(tagFilters)">
                                    {{tagFilters}}<mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div> -->
                    </div>

                    <!-- <mat-chip *ngIf="chatStatusFilterValue != '' && chatStatusFilterValue != 'All' && chatStatusFilterValue != 'all'" color="primary" selectable="true" removable="true" (removed)="removeChatStatusFilter(chatStatusFilterValue)">{{chatStatusFilterValue}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip> -->

                    <!-- <div class="col-md-12">

                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let topping of toppingsControl.value" [removable]="true"
                                    (removed)="onToppingRemoved(topping)">
                                    {{ topping }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                    </div> -->

                    <mat-chip-list *ngIf="expiryDataFilter.length!=0 || performanceDataFilter.length!=0">
                        <br />
                        <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of expiryDataFilter" (removed)="remove4(data)">
                            {{data.title}}

                            <mat-icon matChipRemove>cancel</mat-icon>

                        </mat-chip>
                        <!-- <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of performanceDataFilter" (removed)="remove3(data)">
                            {{data.performance}}
                            <mat-icon matChipRemove>cancel</mat-icon>

                        </mat-chip> -->
                        <mat-chip class="notranslate" selectable="true" removable="true"
                        *ngFor="let data of performanceDataFilter" (removed)="remove3(data)">
                        {{data.title}}

                        <mat-icon matChipRemove>cancel</mat-icon>

                    </mat-chip>
                        <mat-chip>
                            <span style="border-color: #EE6505 ;color: #EE6505 ;background-color:white"
                                *ngIf=" expiryDataFilter.length!=0 || performanceDataFilter.length!=0"
                                (click)="clearChips()">
                                clear all
                            </span>
                        </mat-chip>
                        <br />
                    </mat-chip-list>
                    <br />
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="task-details">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-over">
                                        <h3 class="">Task details</h3>
                                    </div>
                                

                                <div class="col-md-3">
                                    <div class="items">
                                        <h4>Task assigned</h4>
                                        <h5 class="notranslate">{{overView?.total}}</h5>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="items">
                                        <h4>Completed</h4>
                                        <h5 class="like notranslate"><img src="assets/img/dls/icons/Icon_complete.svg"
                                                class="img-responsive" alt="Wellthy"> {{overView?.completed}}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="items">
                                        <h4>Failed</h4>
                                        <h5 class="dislike notranslate"><img src="assets/img/dls/icons/Icon_Incomplete.svg"
                                                class="img-responsive" alt="Wellthy"> {{overView?.failed}}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="items">
                                        <h4>Pending</h4>
                                        <h5 class="pending notranslate"><img src="assets/img/dls/icons/Icon_Pending.svg"
                                                class="img-responsive" alt="Wellthy"> {{overView?.pending ?
                                            overView.pending
                                            : '0'}}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="container-table">
                            <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table" matSort>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Healthcoaches</th>
                                    <td mat-cell *matCellDef="let element"  [ngClass]="{'bold-text': element.priority == '2'}">
                                        <!-- <span [class]="element.total==element.completed? 'circle green' : 'circle red'"></span> -->
                                        {{element.name}}
                                    </td>
                                    <!-- <td mat-cell *matCellDef="let element"
                                    [ngClass]="{'bold-text': element.priority == '2'}">
                                    <span [class]="element.is_approved? 'circle green' : 'circle red'"></span>
                                    {{element.task_library_id}}
                                </td> -->
                                </ng-container>

                                <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                                    <td mat-cell *matCellDef="let element"> {{element.total}}</td>
                                </ng-container>

                                <ng-container matColumnDef="completed">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed </th>
                                    <td mat-cell *matCellDef="let element"> {{element.completed}} </td>
                                </ng-container>

                                <ng-container matColumnDef="pending">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Pending </th>
                                    <td mat-cell *matCellDef="let element"> {{element.pending}} </td>
                                </ng-container>
                                <ng-container matColumnDef="failed">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Failed </th>
                                    <td mat-cell *matCellDef="let element"> {{element.failed}}</td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef style="width: 80px;"> Action </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="action-button-row">
                                            <button mat-button [routerLink]="['task/assigntask', element.hc_id]"
                                                tooltip="View details" class="hover-btn-image">
                                                <div class="button-bg-image view-btn"></div>
                                            </button>

                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <div style="text-align: center;font-size: 20px;margin-top: 80px;"
                                *ngIf="dataList.length==0">No
                                Data Found</div>
                            <div class="spinner-holder" *ngIf="isLoading">
                                <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate"
                                    diameter="40">
                                </mat-progress-spinner>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</mat-drawer-container>