
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { isObject } from 'rxjs/internal/util/isObject';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { ExcelService } from '../../services/excelservice/excelservice.service';
import { CreatecodegenComponent } from './createcodegen/createcodegen.component';
import { EdithclistComponent } from './edithclist/edithclist.component';
import { GeneratecodebyrequestComponent } from './generatecodebyrequest/generatecodebyrequest.component';
import { ViewallactivationComponent } from './viewallactivation/viewallactivation.component';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],

})

export class ActivationComponent implements OnInit {
  displayedColumns: string[] = ['request_name', 'therapy_title', 'code_count', 'healthcoach_name', 'client_name', 'action'];
  dataSource: any;
  public isactive = 'all';
  public typeFilters: any;
  public newsection: any = 'new';
  searchcriteria: any;
  public page: number = 1;
  public showloader: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  public lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public tabulardata: any;
  public alltags: any;
  private myhash: any = {};
  public filteredtabulardata: any;
  public activecls = 'active';
  public tagFilters: any;
  public filteredtags: any = [];
  public status: any = 'active';
  public therapyid: any = '';
  public activationdata: any;
  public generatecode: any;
  public edithc: any;
  public filteredtypes: any = [];
  xpandStatus=true;
  data: any = [{
    case_worked: "abc",
    note: "Test",
    id: "1234"
  },
  {
    case_worked: "def",
    note: "test 1",
    id: "1234"
  },
  {
    case_worked: "def",
    note: "Test 2",
    id: "3456"
  }];

  constructor(private excelService: ExcelService, private browserTitle: Title, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Activation Code');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.getTags();
    this.getLanguages();
    this.search();
  }


  // To Download Codes 
  // ----------------------------

  exportAsXLSX(element, status): void {
    var data = {
      "generation_request_id": element.id,
      "status": status
    }
    this.commonService.getAllActivationCodes(data)
      .subscribe(data => {
        this.activationdata = data;
        this.activationdata = this.activationdata.data;
        if (this.activationdata.length > 0) {
          this.activationdata = JSON.stringify(this.activationdata);
          this.activationdata = this.activationdata.replace(/false/g, "\"No\"").replace(/true/g, "\"Yes\"").replace(/is_activated/g, "Is_Active");
          this.activationdata = JSON.parse(this.activationdata);
          var xcelname = element.request_name + '__' + element.therapy_title;
          this.excelService.exportAsExcelFile(this.activationdata, xcelname);
          this.activationdata = [];
        }
        else {
          this.systemService.showSnackBar('No activation Found', 'OK');
        }
      })
  }

  // ----------------------------


  createCode(element) {
    this.dialog.open(CreatecodegenComponent, {
      width: '900px',
      height: 'auto',
      data: {
        element: element
      }
    });
    // this.generatecode.afterClosed().subscribe(data => {
    //   this.lastpage = 0;
    //   this.page = 1;
    //   this.tabulardata = [];
    //   this.search();
    // });
  }



  generateCode(element) {
    this.dialog.open(GeneratecodebyrequestComponent, {
      width: '700px',
      height: 'auto',
      data: {
        element: element
      }
    });
    this.generatecode.afterClosed().subscribe(data => {
      this.lastpage = 0;
      this.page = 1;
      this.tabulardata = [];
      this.search();
    });
  }

  viewCode(element) {
    this.dialog.open(ViewallactivationComponent, {
      width: '900px',
      height: 'auto',
      data: {
        element: element
      }
    });
  }





  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  showdata(element) {
    this.edithc = this.dialog.open(EdithclistComponent, {
      width: '700px',
      height: 'auto',
      data: {
        element: element
      }
    });
    this.edithc.afterClosed().subscribe(data => {
      this.lastpage = 0;
      this.page = 1;
      this.tabulardata = [];
      this.search();
    });
  }


  // To Get Language
  // ----------------------------

  getLanguages() {
    this.commonService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getLanguageData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLanguageData(data) {
    data = (data as any).data;
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  // ----------------------------


  // To Get Tags
  // ----------------------------

  getTags() {
    this.commonService.getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getTagsData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTagsData(data) {
    data = (data as any).data;
    this.alltags = data.getParentTags;
    this.alltags.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  // ----------------------------


  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }


  // To Get Activation List
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllActivationData(this.searchcriteria, this.page.toString(), "20", this.status, this.therapyid, "activationdata")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSearchDataSuccess(data) {
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------

}


export interface PeriodicElement {
  request_name: string;
  therapy_title: string;
  total: string;
  healthcoach_name: string;
}



