import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-viewcomment',
  templateUrl: './viewcomment.component.html',
  styleUrls: ['./viewcomment.component.scss']
})
export class ViewcommentComponent implements OnInit {
  
  showcomment: any;
  constructor(public dialogRef: MatDialogRef<ViewcommentComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private contentService: ContentserviceService, private systemService: SystemService) { }
  public ide: any;

  ngOnInit(): void {
    this.ide = this.data.element;
    this.showcomment = this.data.element;
    this.getAllLevelID();
  }

  getAllLevelID() {
    this.contentService
      .getRequestById(this.ide)
      .subscribe((res: any) => this.ongetAllLevelDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetAllLevelDataSuccess(data) {
    
    this.showcomment = data;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
