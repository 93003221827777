import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../services/commonservice/commonservice.service';
import { ChangepasswordComponent } from '../shared/changepassword/changepassword.component';
import { UserprofileviewComponent } from '../shared/userprofileview/userprofileview.component';
import { TranslateService } from '@ngx-translate/core';
import { EdituserComponent } from './edituser/edituser.component';
import { ChangeuserroleComponent } from './changeuserrole/changeuserrole.component';
import { AdmindeletemodalComponent } from '../shared/admindeletemodal/admindeletemodal.component';
import { ChangePasswordHistoryComponent } from '../doctor/change-password-history/change-password-history.component';

@Component({
  selector: 'app-adminusers',
  templateUrl: './adminusers.component.html',
  styleUrls: ['./adminusers.component.scss'],
  animations: [detailExpandTable]
})

export class AdminusersComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'email', 'user_type','action'];
  public isactive = 'all';
  public newsection: any = 'new';
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any = [];
  filterdata: any = [];
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public filteredtabulardata: any;
  public fliterval: any;
  activecls = 'active';
  public filteredtypes: any = [];
  public showButtonLoader: boolean = false;
  public userData: any;
  public user_data: any;
  public status = "active";
  public update_role_data: any;
  public localStoredData: any;
  public expandedElement: any;
  selected_role: any;
  edithc:any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog, private translate:TranslateService) { }


  viewProfile(element): void {
    this.dialog.open(UserprofileviewComponent, {
      width: '750px',
      data: {
        element: element
      }
    });
  }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | User');
  }
  showdata(element) {
    this.edithc = this.dialog.open(ChangeuserroleComponent, {
      width: '700px',
      height: 'auto',
      data: {
        element: element
      }
    });
    this.edithc.afterClosed().subscribe(data => {
      this.lastpage = 0;
      this.page = 1;
      this.tabulardata = [];
      this.search();
    });
  }
  ngOnInit() {
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.localStoredData.user_acl == 'superadmin' ? true : false);
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  changepassword(element): void {
  
    this.dialog.open(ChangepasswordComponent, {
      width: '724px',
      data: {
        element: element
      }
    });
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.tabulardata = '';
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
    else {
      this.tabulardata = '';
      this.page = 1;
      this.lastpage = 0;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  editScarlet(element): void {
    this.dialog.open(EdituserComponent, {
      width: '1028px',
      data: {
        element: element
      }
    });
  }


  // Change Scarlet User Role 
  // ----------------------------

  changeRole(userdata, role) {
    let tempdata = {};
    tempdata['user_type'] = role;
    tempdata['user_role'] = role + "-role";
    tempdata['user_acl'] = JSON.stringify([role]);
    tempdata['tc_accepted'] = userdata.tc_accepted.toString();
    tempdata['profile_image'] = userdata.profile_image.toString();
    tempdata['std_code'] = userdata.std_code.toString();
    tempdata['phone'] = userdata.phone;
    tempdata['display_name'] = userdata.display_name;
    tempdata['display_bio'] = userdata.display_bio;
    tempdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    this.commonService.updateToBlade(userdata.id, tempdata, 'users')
      .subscribe(
        (res: any) => this.onChangeRoleSuccess(role, userdata.id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onChangeRoleSuccess(role, id) {
    for (var i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id == id) {
        this.tabulardata[i].user_type = role;
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.systemService.showSuccessSnackBar(("User Role has been updated"), "OK");
  }

  // ----------------------------

  // Active Deactive Scarlet User 
  // ----------------------------

  isActive(status, id) {
    this.commonService.setStatus(status, 'users', id, this.localStoredData.id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // List Scarlet User 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;

      this.commonService.getAllData(this.searchcriteria, this.page.toString(), "20", this.status, "users", "scarlet")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------


  // Force Logout From the Scarlet  
  // ----------------------------

  forceLogout(id) {
    const dialogRef = this.dialog.open(AdmindeletemodalComponent,{
      data: {
        confirmmsg: "Yes",
        cancelmsg: "No"
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.commonService.logoutUser(id)
        .subscribe(
          (res : any) => {
            this.systemService.showSuccessSnackBar(("Selected Scarlet has been Logout from Dashboard"), "OK");
          },
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
      }
      });
  
  }
  // openDialog(id) {
  //   const dialogRef = this.dialog.open(AdmindeletemodalComponent);
  //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //     if (confirmed) {
  //       this.delete(id);
  //       const a = document.createElement('a');
  //       a.click();
  //       a.remove();
  //       this.snackBar.open('Selected otp has been deleted', 'Ok', {
  //         duration: 2000,
  //       });
  //     }
  //   });
  // }
  private onLogoutSuccess () {
    this.systemService.showSuccessSnackBar(("Selected Scarlet has been Logout from Dashboard"), "OK");
  }

  // ----------------------------
  changepasswordHistory(element): void {
    localStorage.setItem("userType",'Scarlet User')
    const history = this.dialog.open(ChangePasswordHistoryComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    history.afterClosed().subscribe(result => {
    });
    }
}

