<mat-drawer-container class="sidenav-container" autosize>

  <app-docleftnav></app-docleftnav>
  <div class="sidenav-content">
    <app-breadcrumb route="doctor/doctorHome"></app-breadcrumb>
    <app-docuserlistnavbar></app-docuserlistnavbar>
    <div style="overflow-y: hidden">
      <div class="nouserselected-card">
        <div style="position: absolute;top: 28%;transform: translateY(-45%);width: calc(100% - 750px);margin-left: 200px;">
          <img src="assets/img/healthcoach/noresult.svg">
          <span>Hola! Get started by selecting a user.</span>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>