import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../services/commonservice/commonservice.service';
import { MapDoctorComponent } from './map-doctor/map-doctor.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-activationsearch',
  templateUrl: './activationsearch.component.html',
  styleUrls: ['./activationsearch.component.scss']
})

export class ActivationsearchComponent implements OnInit {
  public searchcriteria: any;
  public activationdata: any;
  public otherdata: any;
  public lastcode: any;
  doctorData: any;
  public searchPattern = "^$|^[A-Za-z0-9]+";
  showplaceholder: boolean = false;

  constructor(private commonService: CommonserviceService, private systemService: SystemService, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit() {
    this.getDoctList();
  }


  // To Get Activation Code Details
  // ----------------------------

  livesearch() {
    if (this.searchcriteria) {
      this.commonService.getActivationCodeDetail(this.searchcriteria)
        .subscribe(
          (res: any) => this.onSuccessactivationData(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.systemService.showSnackBar(("Enter a valid Activation Code"), "OK");
    }
  }

  private onSuccessactivationData(data) {
    data = (data as any).data;
    this.activationdata = data;
    
    this.commonService.getactivationRequestData(this.activationdata.id)
      .subscribe(datap => {
        this.lastcode = this.searchcriteria;
        this.showplaceholder = false;
        this.otherdata = datap;
        this.otherdata = this.otherdata.data;
      })
  }

  // ----------------------------

  // To Get Doc List
  // ----------------------------

  getDoctList() {
    this.commonService.getGraphQlData('{getAllDoctorUsers(appMode:false,status:true){code,name,id}}')
      .subscribe(
        (res: any) => this.onDocListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDocListSuccess(data) {
    data = data.data;
    this.doctorData = data.getAllDoctorUsers;
  }

  // ----------------------------


  //  Map Doctor Modal
  // ----------------------------

  mapDoctor() {
    const mapDoc = this.dialog.open(MapDoctorComponent, {
      width: '450px',
      data: {
        element: {
          'doct_data': this.doctorData,
          'patient_data': this.activationdata
        }
      }
    });
    mapDoc.afterClosed().subscribe(result => {
      this.searchcriteria = '';
      this.activationdata = '';
    });
  }

  // ----------------------------


  // To Reset Code
  // ----------------------------

  resetCode() {
    if (confirm('Are you sure you want to reset the code?')) {
      this.commonService.resetActivationcode(this.lastcode)
        .subscribe(
          (res: any) => this.onSuccessStatus(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSuccessStatus() {
    this.systemService.showSuccessSnackBar(("Activation code has been reset successfully"), "OK");
    this.searchcriteria = '';
    this.activationdata = '';
  }

  // ----------------------------
  HealthcoachSearch() {
    let data = this.activationdata.patient_data.healthcoach_data.name;   
    localStorage.setItem("HealthcoachSearch", data);
  }
  PatientSearch() {
    let phone = this.activationdata.patient_data.phone;  
    localStorage.setItem("patientsearch", phone)
  }
}
