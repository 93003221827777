<div class="view-task">

    <div class="pull-right" style="display: inline-block;">
        <img src="assets/img/dls/icons/cross.svg" [mat-dialog-close]="true" class="img-responsive" alt="">
    </div>
    <br />
    <h4>Task details</h4>
    <div style="margin-top:0%;margin-bottom: 10px;width: 100%;" class="pull-left title-modal">
    {{taskDetails?.task_summary}}
    </div>
    <mat-dialog-content style="margin-top: 0%;">
        <div class="holder-box" style="margin-top: 0%;">
            <div class="row" style="margin-top: 0%;">
                <div class="col-md-3">
                    <div class="content">
                        <h5>Scope</h5>
                        <p>{{taskDetails?.scope}}</p>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="content">
                        <h5>Priority</h5>
                        <p>{{priorityInList(taskDetails?.priority)}}</p>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="content">
                        <h5>Expiry</h5>
                        <p>{{convertDate(taskDetails?.expiry_date)}}</p>
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="content">
                        <h5>Condition</h5>
                        <p style="line-height: 30px;">{{taskDetails?.filter_conditions_summary}}</p>
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="content">
                        <h5>Message</h5>
                        <!-- <p style="line-height: 30px;" *ngFor="let language of taskdata.template">{{language.description}}</p> -->
                        <!-- <textarea class="tab-text-area"  
                         *ngFor="let language of taskdata.template"
                        readonly>{{language.description}}</textarea> -->

                        <form [formGroup]="thirdFormGroup" autocomplete="off" >                          
                            <div class="row"  *ngIf="!isTemplate" >
                                <div class="col-md-12">
                                    <div class="language-tab mb-30">

                                        <div>
                                            <mat-menu #menu="matMenu" style="margin-left: 0%;">
                                                <button *ngFor="let data of templateData"
                                                    mat-menu-item>{{data.id}}</button>
                                            </mat-menu>
                                            <mat-tab-group [selectedIndex]="0" style="margin-left: 0%;">
                                                <mat-tab *ngFor="let data of templateData; "
                                                    [label]="data.id" style="margin-left: 0%;">
                                                    <!-- [label]="tab.value.name" -->
                                                    <ng-template mat-tab-label style="margin-left: 0%;">
                                                        <div style="margin-left: 0%;" >
                                                            {{data.id}}
                                                        </div>
                                                        <!-- <button mat-icon-button (click)="closeTab(index)"
                                                        class="close-btn">
                                                        <mat-icon>close</mat-icon>
                                                    </button> -->
                                                    </ng-template>
                                                    <!-- Contents for {{tab.value.data}} tab -->
                                                    <!-- <mat-form-field class="example-full-width"> -->
                                                        
                                                    <textarea class="tab-text-area"
                                                        readonly> {{data.description}}</textarea>
                                                    <!-- </mat-form-field> -->
                                                </mat-tab>
                                                <!-- <mat-tab disabled>
                                                <ng-template mat-tab-label>
                                                    <button mat-button [matMenuTriggerFor]="menu"
                                                        class="add-">
                                                        <mat-icon aria-hidden="false" aria-label="Add">
                                                            add
                                                        </mat-icon>
                                                        Add Language
                                                    </button>
                                                </ng-template>
                                            </mat-tab> -->
                                            </mat-tab-group>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>


                    </div>

                </div>
            </div>
        </div>


        <h4>Task history</h4>

        <div class="row">
            <div class="col-md-12">
                <div class="container-table">
                    <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">

                        <ng-container matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let element"> {{convertDate(element.created_at)}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action_type">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element"> {{element.action_type}}</td>
                        </ng-container>

                        <ng-container matColumnDef="created_by_name">
                            <th mat-header-cell *matHeaderCellDef> Super admin </th>
                            <td mat-cell *matCellDef="let element"> {{element.created_by_name}} </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>


                </div>
            </div>
        </div>

    </mat-dialog-content>
</div>