<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">

        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Onboarding Flow
            </h3>
            <div class="clearfix">
                <br />
                <br>
            </div>
        </div>
        <div class="col-md-12">
            <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                <div class="col-md-11 leftformsection">
                    <div class="row">
                        <div class="col-md-12" *ngIf="newitem">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Clients</mat-label>
                                <mat-select formControlName="client_id" required>
                                    <mat-option value="noclient">
                                        No client
                                    </mat-option>
                                    <mat-option *ngFor="let client of allclients" [value]="client.id">
                                        {{client.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-md-5">
                                        <div *ngFor="let wid of allwidgets">
                                                <a (click)="movetoRight(wid.id, wid.title)" class="widget-btn">{{wid.title}}</a> 
                                        </div>  
                                    </div>
                                    <div class="col-md-6 col-md-offset-1">
                                            <ul id="sortable">
                                                </ul>
                                    </div>
                                    <div class="clearfix"><br/></div> -->
                        <div class="col-md-6">
                            <div class="example-container">
                                <h3>Widgets</h3>

                                <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
                                    [cdkDropListConnectedTo]="[doneList]" class="example-list"
                                    (cdkDropListDropped)="drop($event)">
                                    <div class="example-box-widget" *ngFor="let item of todo" cdkDrag>
                                        <div>
                                            <img src="assets/img/hamburger_menu.png">
                                        </div>
                                        <div style="margin-left: 10px;">
                                            {{item.title}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="example">
                                <h3>Final Onboarding Flow</h3>

                                <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
                                    [cdkDropListConnectedTo]="[todoList]" class="example-list"
                                    (cdkDropListDropped)="drop($event)" data-placeholder='Drag widgets form widget list to create onboarding flow'>
                                    <div class="example-box-widget" *ngFor="let item of done" cdkDrag>
                                        <div>
                                            <img src="assets/img/hamburger_menu.png">
                                        </div>
                                        <div style="margin-left: 10px;">
                                            {{item.title}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button mat-flat-button color="primary" class="centerbtn mt-15"
                                        [disabled]="done.length === 0">{{newitem ? 'New' : 'Update'}} Onboarding
                                        Flow</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
             
            </form>
        </div>
    </div>
</mat-drawer-container>