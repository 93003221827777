<mat-dialog-content class="mat-typography pump-add">
    <h3>Update Route of Administration</h3>

    <form [formGroup]="editRoaForm" validate>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Select Route of Administration</mat-label>
                    <mat-select formControlName="route_of_administration">
                        <mat-option value="oral">
                            Oral
                        </mat-option>
                        <mat-option value="inhaled">
                            Inhaled
                        </mat-option>
                        <mat-option value="subcutaneous">
                            Subcutaneous
                        </mat-option>
                        <mat-option value="intravenous">
                            Intravenous
                        </mat-option>
                        <mat-option value="implantable">
                            Implantable
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Select Time</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="log_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Reason For Addition</mat-label>
                    <textarea formControlName="reason_for_addition" rows="5" matInput
                        placeholder="Reason For Addition"></textarea>

                </mat-form-field>
            </div>

        </div>

    </form>


</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-flat-button color="primary" class="submit-btn" [disabled]="!editRoaForm.valid"
        (click)="editRouteofAdmin()">Edit Route of Administration</button>
</mat-dialog-actions>