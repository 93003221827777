import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../shared/guards';
import { ProfileimageComponent } from './profileimage.component';

const routes: Routes = [
  { path: '', component: ProfileimageComponent, canActivate: [AdminGuardGuard] },
  //{ path: 'settings/userprofile', component: ProfileimageComponent, canActivate: [AdminGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileimageRoutingModule {}