import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { EditorinstructionsComponent } from 'src/app/content/shared/editorinstructions/editorinstructions.component';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../../utility/validation';
declare var $: any;

@Component({
  selector: 'app-editquestionanswer',
  templateUrl: './editquestionanswer.component.html',
  styleUrls: ['./editquestionanswer.component.scss']
})

export class EditquestionanswerComponent implements OnInit {
  public newitem: boolean = false;
  chooseMedia: any;
  public showButtonLoader: boolean = false;
  public selfparentid: any;
  public childid: any;
  public selectedoptn: any = [];
  public langid: any;
  checked: boolean = false;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public chapoptntype: any = [];
  userdata: any;
  allfeedback: any;
  public hclist: any;
  userDetails: any;
  public alltherapies: any;
  public allfaq: any;
  disabled: boolean = true;
  doUpload: any;
  public renderhtml: any;
  public allclients: any;
  alltags: any;
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }
  public dataForm = this.fb.group({
    question: ['', [Validators.required, whiteSpaceValidation]],
    answer: [''],
    faq_category_fk: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    client_ids: [[]],
    therapy_ids: [[]],
    raw_answer: [''],
    additionaltext: [''],
    tags: [[]]
  });
  public searchmatclient: FormControl = new FormControl();
  public searchmattag: FormControl = new FormControl();
  public searchmatfaq: FormControl = new FormControl();
  public filteredFAQData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredFAQ: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchnewmattag: FormControl = new FormControl();

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  constructor(private browserTitle: Title, private router: Router, private route: ActivatedRoute, public dialog: MatDialog,
  public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService,
  private cdr: ChangeDetectorRef, protected sanitizer: DomSanitizer,private commonService:CommonserviceService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Question And Answer');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getFAQList();
    this.getallclient();
    this.getTherapy();
    this.getTags();
  }

  uirender() {
    var scope = this;
    scope.renderhtml = $('.preview-panel').html();
    scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
  }

  openinstructions(): void {
    const instructions = this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });

    instructions.afterClosed().subscribe(result => {
    });
  }

  additionalTextChange(e) {
    const raw_answer = <FormControl>this.dataForm.controls.raw_answer;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    let checkHTML = this.commonService.isHTML(raw_answer.value);
    if(checkHTML !== true){
      additionaltextcontent.setValue(raw_answer.value);
    }else{
      raw_answer.setValue('');
      additionaltextcontent.setValue('');
    }
  }


  initializeCategory() {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getQuestiondata(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getQuestiondata(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getQuestiondata(params.chid);
        }
      }
    });
  }

  modelChangedClient() {
    this.filterClients();
  }

  modelChangedFaq() {
    this.filterFaq();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  modelChangedTags() {
    this.filterTags();
  }

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterTags() {
    if (!this.alltherapies) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTherapy.next(this.alltherapies.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.alltherapies.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  private filterFaq() {
    if (!this.allfaq) {
      return;
    }
    // get the search keyword
    let search = this.searchmatfaq.value;
    if (!search) {
      this.filteredFAQ.next(this.allfaq.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredFAQ.next(
      this.allfaq.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


  // Get FAQ List
  // ----------------------------

  getFAQList() {
    this.contentService
      .getGraphQlData('{getParentFaqCategory(status:true){id,title}}')
      .subscribe((res: any) => this.getFQAListSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getFQAListSuccess(data) {
    this.allfeedback = data.getParentFaqCategory;
    this.filteredFAQData.next(this.allfeedback.slice());
    this.initializeCategory();
  }

  // ----------------------------


  // Get Therapy Data
  // ----------------------------

  getTherapy() {
    this.contentService
      .getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}', true)
      .subscribe((res: any) => this.getAllTherapySuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllTherapySuccess(datap) {
    var alltherapy = [];
    datap = (datap as any).data;
    datap.getAllTherapies.forEach(el => {
      alltherapy.push(el);
    });
    this.alltherapies = alltherapy;
    this.filteredTherapy.next(this.alltherapies.slice());
  }

  // ----------------------------



  // Get All Client
  // ----------------------------

  getallclient() {
    this.contentService
      .getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .subscribe((res: any) => this.getAllClientSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllClientSuccess(data) {
    this.allclients = data.getAllClients;
    this.filteredClient.next(this.allclients.slice());
  }

  // ----------------------------


  // Get Question Data
  // ----------------------------

  getQuestiondata(ide) {
    this.contentService.getOneDataById(ide, "faqqa")
      .subscribe((res: any) => this.getQuestionSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getQuestionSuccess(data) {
    this.userdata = data;
    this.userdata.faq_category_fk = data.faqCategoryFkId.toString();
    this.dataForm.patchValue(this.userdata);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
    setTimeout(() => {
      this.uirender();
    }, 1000);
  }

  // ----------------------------

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  modelTags() {
    this.filternewTags();
  }
  
  private filternewTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchnewmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  // -----------------------

  
  // Submitting Question Answer
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.question = this.removeHTMLtags(postdata.question.toString());
    postdata.answer = postdata.answer.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.faq_category_fk = postdata.faq_category_fk.toString();
    postdata.client_ids = typeof postdata.client_ids == 'string' ? postdata.client_ids : JSON.stringify(postdata.client_ids);
    postdata.therapy_ids = typeof postdata.therapy_ids == 'string' ? postdata.therapy_ids : JSON.stringify(postdata.therapy_ids);
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    postdata.answer = $('.preview-panel').html();
    postdata.raw_answer = postdata.raw_answer.toString();
    postdata.tags = JSON.stringify(postdata.tags);
    delete postdata.additionaltext;
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'faqqa', true)
        .subscribe((res: any) => this.onCurrentSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'faqqa', true)
        .subscribe((res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onCurrentSuccess() {
    this.systemService.showSuccessSnackBar(("Question has been added"), "OK");
    this.router.navigate(['/content/faq/question_answers']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Question has been updated"), "OK");
    this.router.navigate(['/content/faq/question_answers']);
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
  // ----------------------------

}

