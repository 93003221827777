<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/app-master"></app-breadcrumb>
      <div class="col-md-12">
        <h3 class="commontitleprimary" style="margin-left: 15px;">
          App Actions
        </h3>
      </div>

      <div class="clearfix"></div>
      <div class="example-container col-md-12">
        <div class="title-in-border col-md-12" style="margin: 40px 15px 20px 15px;width: calc(100% - 40px);">
          <h1><span>Stats</span></h1>
          <div class="stats-data">
            <div class="data">
              <p class="key">Server Version</p>
              <p class="value">{{serverdata ? serverdata.value : '-'}}</p>
            </div>
            <div class="data data-timelogged">
              <p class="key">Server Down</p>
              <p class="value">{{serverDowndata ? (serverDowndata.value =='true' ? 'Yes' : 'No') : '-'}}</p>
            </div>
            <div class="data">
              <p class="key">Default Phone</p>
              <p class="value">{{wellthyPhoneNumberdata ? wellthyPhoneNumberdata.value : '-'}}
              </p>
            </div>
            <div class="data data-breaks">
              <p class="key">Default Email</p>
              <p class="value">{{wellthyEmaildata ? wellthyEmaildata.value : '-'}}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="top-margin" style="clear: both;">
          <div *ngFor="let data of appMasterData">
            <mat-card class="example-card  col-md-4"
              *ngIf="data.key != 'IS_SERVER_DOWN' && data.key != 'SERVER_LIVE_DATE' && data.key != 'SERVER_VERSION_START_NUMBER' && data.key != 'SERVER_VERSION_START_SUBNUMBER' && data.key != 'WELLTHY_DEFAULT_EMAIL_ID' && data.key != 'WELLTHY_DEFAULT_PHONE_NUMBER' && data.key != 'SERVER_VERSION'">
              <div class="data-card">
                <mat-card-header class="no-margin">
                  <mat-card-title><span class="head-text">{{convertData(data.key)}}</span></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <p class="result">
                    {{covertString(data.value)}}
                    <span *ngIf="data.key == 'WELLTHY_DEFAULT_EMAIL_ID' || data.key == 'WELLTHY_DEFAULT_PHONE_NUMBER' "
                      (click)="OpenDialog(data)" class="material-icons pointer">
                      create
                    </span>
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <button class="update-btn" mat-button
                    *ngIf="data.key == 'IOS_MIN_APP_VERSION' || data.key == 'IOS_CURRENT_APP_VERSION' || data.key == 'ANDROID_MIN_APP_VERSION' || data.key == 'ANDROID_CURRENT_APP_VERSION'"
                    (click)="OpenDialog(data)">Update Version</button>
                  <button class="update-btn" mat-button
                    *ngIf="data.value == 'false' && (data.key == 'FORCE_UPDATE' || data.key == 'FORCE_LOGOUT')"
                    (click)="updateData(data.id,'true')">Activate</button>
                  <button class="update-btn" mat-button
                    *ngIf="data.value == 'true' && (data.key == 'FORCE_UPDATE' || data.key == 'FORCE_LOGOUT')"
                    (click)="updateData(data.id,'false')">Deactivate</button>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
        </div> -->

        <div class="clearfix"></div>
        <div class="app-actioncard ios">
          <div class="row d-flex">
            <div class="col-md-1">
              <div class="icons">
                <img src="assets/img/appicons/ios.svg" class="img-responsive" alt="">
              </div>
            </div>
            <div class="col-md-5">
              <div class="titleholder">
                <h3>Force Update is on for {{ iosminapp?.value + ' and less'}}</h3>
                <h5>Change the Min app version to turn Off/On </h5>
              </div>
            </div>
            <div class="col-md-3">
              <div class="text-btn">
                <div class="col-md-8 left">
                  <h6>Min Version</h6>
                  <h3>{{iosminapp?.value}}</h3>
                </div>
                <div class="col-md-4 right">
                  <button class="btn btn-primary" (click)="OpenDialog(iosminapp)">
                    <img src="assets/img/appicons/edit.svg" class="img-responsive" alt="">
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="text-btn">
                <div class="col-md-8 left">
                  <h6>Max Version</h6>
                  <h3>{{ioscurrentapp?.value}}</h3>
                </div>
                <div class="col-md-4 right">
                  <button class="btn btn-primary" (click)="OpenDialog(ioscurrentapp)">
                    <img src="assets/img/appicons/edit.svg" class="img-responsive" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="app-actioncard android">
          <div class="row d-flex">
            <div class="col-md-1">
              <div class="icons">
                <img src="assets/img/appicons/android.svg" class="img-responsive" alt="">
              </div>
            </div>
            <div class="col-md-5">
              <div class="titleholder">
                <h3>Force Update is on for {{ androidminapp?.value + ' and less'}}</h3>
                <h5>Change the Min app version to turn Off/On </h5>
              </div>
            </div>
            <div class="col-md-3">
              <div class="text-btn">
                <div class="col-md-8 left">
                  <h6>Min Version</h6>
                  <h3>{{androidminapp?.value}}</h3>
                </div>
                <div class="col-md-4 right">
                  <button class="btn btn-primary" (click)="OpenDialog(androidminapp)">
                    <img src="assets/img/appicons/edit.svg" class="img-responsive" alt="">
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="text-btn">
                <div class="col-md-8 left">
                  <h6>Max Version</h6>
                  <h3>{{androidcurrentapp?.value}}</h3>
                </div>
                <div class="col-md-4 right">
                  <button class="btn btn-primary" (click)="OpenDialog(androidcurrentapp)">
                    <img src="assets/img/appicons/edit.svg" class="img-responsive" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</mat-drawer-container>