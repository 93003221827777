import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardGuard } from '../../../shared/guards';
import { UserprofileviewComponent } from './userprofileview.component';

const routes: Routes = [
    { path: '', component: UserprofileviewComponent, canActivate: [AdminGuardGuard] },
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserprofileviewRoutingModule {}