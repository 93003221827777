import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { AssigntaskComponent } from './assigntask/assigntask.component';
// import { PrefillComponent } from './prefill/prefill.component';
import { TasklibraryRoutingModule } from './tasklibrary-routing.module';
// import { ViewtaskComponent } from './viewtask/viewtask.component';
import { TasklistComponent } from './assigntask/tasklist/tasklist.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [TasklistComponent],
  imports: [
    CommonModule,
    TasklibraryRoutingModule,
    SharedModule.forRoot(),
    PickerModule
  ]
})
export class TasklibraryModule { }
