import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
@Component({
  selector: 'app-publishdetails',
  templateUrl: './publishdetails.component.html',
  styleUrls: ['./publishdetails.component.scss']
})
export class PublishdetailsComponent implements OnInit {
  public viewDetails: any;


  constructor(public dialogRef: MatDialogRef<PublishdetailsComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private CommonserviceService: CommonserviceService, private systemService: SystemService) { }
  public ide: any;
  ngOnInit(): void {
    
    this.ide = this.data.element;
    this.viewDetails = this.data.element;
    this.getAllLevelID();
  }

  getAllLevelID() {
    this.CommonserviceService
      .getRequestById(this.ide)
      .subscribe((res: any) => this.ongetAllLevelDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetAllLevelDataSuccess(data) {
    
    this.viewDetails = data;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
