<div class="userdetailcontent matcardbottomzero">

  <div class="options-wrapper">
    <div class="show-more-wrapper">
      <button *ngIf="showloadmore" (click)="getNextSet()" class="loadmore"> Load More</button>
    </div>
  </div>

  <div class="knowusercontent">
    <div class="allcards">

      <div #scrollToBottom>

        <ng-container *ngIf="knowUserData?.length == 0">
          <div class="nodata-note">
            <p *ngIf="!isArchiveActive">No Doctor Notes found</p>
            <p *ngIf="isArchiveActive">No Archive Notes found</p>
          </div>
        </ng-container>

        <div class="notes-wrapper">

          <mat-card class="knowaboutuser-card" *ngFor="let data of knowUserData">

            <mat-card-header>
              <mat-card-title>

                <span class="note-header">
                  <span>
                    {{data.created_by_name}}
                    <strong *ngIf="data.type == 'self'" class="knowusertype">( Self )</strong>
                    <strong *ngIf="data.type == 'doctor'" class="knowusertype">( Doctor )</strong>
                  </span>
                </span>

              </mat-card-title>
            </mat-card-header>

            <mat-card-content class="preserve-new-lines">
              <div class="about-notes-content">{{data.content}}</div>
              <div class="knowaboutdate">{{convertIsoDate(data.created_at)}}
                <span *ngIf="data.edit_history.length > 0" class="edited-text" (click)="openNotesLog(data.edit_history)">(Edited)</span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingOrError>
    <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async"></app-loadorerror>
  </ng-template>
</div>