import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-therapysetupleftnav',
  templateUrl: './therapysetupleftnav.component.html',
  styleUrls: ['./therapysetupleftnav.component.scss']
})
export class TherapysetupleftnavComponent implements OnInit {
  showingnav: boolean = true;
  public userData: any;
  constructor() {
  }

  ngOnInit() {
    
    this.userData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.userData.email == 'central@wellthy.care' ? true : false);
  }
  togglenav() {
    if (this.showingnav) {
      $(".userlistnavcontent").css('width', '0px');
      $(".mainrightcontainersecondary").css('marginLeft', '0px');
      $(".slidetogglecss").css('marginLeft', '-15px');

    }
    else {
      $(".userlistnavcontent").css('width', '200px');
      $(".mainrightcontainersecondary").css('marginLeft', '210px');
      $(".slidetogglecss").css('marginLeft', '185px');
    }
    this.showingnav = !this.showingnav;
  }

}
