<mat-dialog-content class="mat-typography pump-add">
    <h3>Medical History</h3>
    <form [formGroup]="dataForm" validate>
        <!-- <div class="row">
            <div class="col-md-12">
                <p class="current-condition">Current Condition</p>
            </div>
        </div> -->
        <div class="form-card">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-over" style="width: 100px;">PAH Data</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Select PAH Subtype</mat-label>
                        <mat-select formControlName="sub_type" (selectionChange)="onChange($event)">
                            <mat-option value="idiopathic">
                                Idiopathic
                            </mat-option>
                            <mat-option value="heritable">
                                Heritable
                            </mat-option>
                            <mat-option value="other">
                                Other
                            </mat-option>
                        </mat-select>
                        
                        
                    </mat-form-field>
                    <div [hidden]="hideDiv">
                        <mat-form-field appearance="outline">
                            <mat-label>Other PAH Subtype</mat-label>
                            <input type="text" matInput placeholder="Other PAH Subtype"
                                    formControlName="other_subtype">
                        </mat-form-field>
                    </div>
                    
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Select Current function classes</mat-label>
                        <mat-select formControlName="functional_class">
                            <mat-option value="1">
                                1
                            </mat-option>
                            <mat-option value="2">
                                2
                            </mat-option>
                            <mat-option value="3">
                                3
                            </mat-option>
                            <mat-option value="4">
                                4
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Date of Diagonosis</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Date of Diagonosis" formControlName="date_of_diagnosis">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                       
                    </mat-form-field>
                </div>
            </div>
            
        </div>
        <div class="form-card">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-over" style="width: 130px;">Comorbidities</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Condition Name</mat-label>
                            <input type="text" matInput placeholder="Condition Name"
                                formControlName="condition">
                        </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Date of Diagonosis</mat-label>
                        <input matInput [matDatepicker]="picker1" placeholder="Date of Diagonosis" formControlName="cur_date_of_diagnosis">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="form-card">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-over" style="width: 200px;">Previous Comorbidities</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Condition Name</mat-label>
                            <input type="text" matInput placeholder="Condition Name"
                                formControlName="previous_condition">
                        </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Date of Diagonosis</mat-label>
                        <input matInput [matDatepicker]="picker2" placeholder="Date of Diagonosis" formControlName="pre_date_of_diagnosis">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>End Date</mat-label>
                        <input matInput [matDatepicker]="picker3" placeholder="Date of Diagonosis" formControlName="end_date">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </div>
                
            </div>
        </div>
        <div class="form-card">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-over" style="width: 70px;">Other</div>
                </div>
            </div>
        <mat-form-field appearance="outline">
            <mat-label>Allergies</mat-label>
            <input type="text" matInput placeholder="Allergies"
                formControlName="allergies">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>General Symptoms</mat-label>
            <input type="text" matInput placeholder="Allergies"
                formControlName="general_symptoms">
        </mat-form-field>
        </div>
    </form>


</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-flat-button color="primary" class="submit-btn" (click)="uploadObject()">Add Medical History</button>
</mat-dialog-actions>