<mat-drawer #drawer class="sidenav" mode="side" opened>
    <div class="smallnavigator1">
        <a *ngIf="!swithchId" [routerLink]="['/healthcoach/hcprofile']" routerLinkActive="active" class="hcprofile"><img
                [src]="s3url + localStoredHCData.profile_image.path" class="hclogo center-block" /></a>
        <a *ngIf="swithchId" class="hcprofile switchcss"><img [src]="s3url + localStoredHCData.profile_image.path"
                class="hclogo center-block" /></a>
        <ul class="alloption">
            <li *ngIf="localStoredHCData.client_ids[0] != '9'">
                <a [routerLink]="['/healthcoach/hctasks']" routerLinkActive="active">
                    <div class="container-a taskexecution"></div>
                    <div class="name">Task</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/healthcoach/broadcast']" routerLinkActive="active">
                    <div class="container-a broadcast"></div>
                    <div class="name">Broadcast</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/healthcoach/home']" routerLinkActive="active" (click)="chat();">
                    <div class="container-a chat-view"></div>
                    <div class="name">Chat</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/healthcoach/tags']" routerLinkActive="active">
                    <div class="container-a tag-view"></div>
                    <div class="name">Tag</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/healthcoach/hcalerts']" routerLinkActive="active">
                    <div class="container-a notification-view"></div>
                    <div class="name">Notification</div>
                    <div class="activecircle"></div>
                    <div class="circlebox" *ngIf="haveAlerts">
                        <i class="material-icons whiteicon">
                            fiber_manual_record
                        </i>
                    </div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/healthcoach/policies']" routerLinkActive="active">
                    <div class="container-a policy"></div>
                    <div class="name">Policies</div>
                </a>
            </li>
        </ul>
        <div class="bottom-menu" *ngIf="!swithchId">
            <a [routerLink]="['/application']" routerLinkActive="router-link-active">
                <img src="assets/img/dls/icons/switch-dashboard.svg" alt="Wellthy">
            </a>
        </div>
    </div>
</mat-drawer>