import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from '../../../shared/shared.module';
import { MediaSetupleftNavComponent } from './media-setupleft-nav.component';



@NgModule({
  declarations: [
    MediaSetupleftNavComponent
  ],
  imports: [
    CommonModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  exports:[
    MediaSetupleftNavComponent,
  ],
})
export class MediaSetupleftNavModule { }
