<div class="clearfix">
  <h2 class="pull-left title-modal"> {{newitem ? 'Create' : 'Update'}} Healthcoach
  </h2>
  <div class="pull-right">
    <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
  </div>
</div>
<mat-dialog-content>
  <div class="form-wrapper">
    <form [formGroup]="dataForm" validate>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <div class="image-placeholder">
                <mat-spinner class="upload-spinner" *ngIf="isloading"></mat-spinner>
                <div class="profile-image" *ngIf="!isloading">
                  <div *ngIf="!uploadedparam">
                    <img src="assets/img/dls/admin-table/Icon_View profile.svg" class="img-responsive img-iconplace">
                    <!-- <span>Upload Image </span> -->
                  </div>
                  <img *ngIf="uploadedparam" class="image-data img-responsive" [src]="s3url + image_path">
                </div>
                <label for="upload-photo" *ngIf="newitem">
                  <span class="material-icons">
                    cloud_upload
                  </span>
                  <span style="margin-left: 10px;">Upload pic</span>
                </label>
                <label for="upload-photo" class="edit-icon" *ngIf="!newitem">
                  <span class="material-icons">
                    edit
                  </span>
                </label>
              </div>

              <input type="file" (change)="fileChangeEvent($event)" accept=".jpeg,.png,.jpg" id="upload-photo" />

              <form [formGroup]="demoForm" (change)="uploadImage()" validate [class]="isinvalid ? 'invalidcls' : ''">
                <file-upload formControlName="files" simple accept=".jpeg,.png,.jpg" class="file-uplaod-html">
                  <ng-template let-file #placeholder>

                    <ng-container *ngIf="file; else emptyList">
                      {{ img_file_name }}
                    </ng-container>
                    <ng-container *ngIf="!file">
                      Choose a file...
                    </ng-container>

                  </ng-template>

                  <ng-template let-control="control" #button>
                    <i class="material-icons">
                      cloud_upload
                    </i>
                  </ng-template>
                </file-upload>

              </form>
            </div>
            <div class="col-md-9" style="padding:0">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" formControlName="name" required>

              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Display Name</mat-label>
                <input matInput placeholder="Display Name" formControlName="display_name" required>
              </mat-form-field>

              <div class="col-md-12">
                <div class="gender-wrapper">
                  <span class="gender">Gender *</span>
                  <mat-radio-group aria-label="Select an option" formControlName="gender" required>
                    <mat-radio-button value="m">Male</mat-radio-button>
                    <mat-radio-button value="f">Female</mat-radio-button>
                    <mat-radio-button value="o">Other</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <mat-form-field class="col-md-6" appearance="outline">
                <mat-label>Country code</mat-label>
                <!-- <span matPrefix>+ &nbsp;</span> -->
                <input type=“number” matInput placeholder="Country code" formControlName="std_code" minlength="1"
                  maxlength="3" pattern="^\d+(?:\.\d{0,1})?$" oninput="validity.valid||(value='');" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Phone Number</mat-label>
                <input [pattern]="'^[0-9]+$'" minlength="8" maxlength="12" matInput placeholder="Phone Number"
                  formControlName="phone" required>
                <mat-error>Please enter the valid phone number</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6" *ngIf="newitem">
                <mat-label>Email ID</mat-label>
                <input type="email" matInput autocomplete="off" placeholder="Email ID" formControlName="email" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Whatsapp Number</mat-label>
                <input minlength="8" maxlength="17" matInput autocomplete="off" placeholder="+XX-XXXXXXXXXX" formControlName="whatsapp_number">
                <mat-error>Please enter the valid number</mat-error>
               
              </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="!newitem && userData">
              <div class="current-email">
                <h5 class="text" *ngIf="userdata">{{userdata.email}}</h5>
                <h5 *ngIf="!userdata">-</h5>
                <button mat-button (click)="changeEmail(userdata.email)" disableRipple class="changehc-btn">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>Select Client</mat-label>
              <mat-select formControlName="client_ids" multiple #singleSelectClient>
                <mat-option>
                  <ngx-mat-select-search class="tagmat" [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
                    [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmatclient" ngModel
                    (ngModelChange)="modelChangedClient()" ngDefaultControl>
                    <div class="mat-select-search-custom-header-content">
                      <i class="material-icons">
                        search
                      </i>
                    </div>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let client of filteredClient | async" [value]="client.id">
                  {{client.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>About Me</mat-label>
              <textarea matInput placeholder="About Me" rows="6" formControlName="display_bio" required></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-flat-button color="primary" class="btn btn-filled" (click)="dataObject()"
  [disabled]="!dataForm.valid">{{newitem ? 'Create' : 'Update'}}
  </button>
</mat-dialog-actions>