import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface StateGroup {
  letter: string;
  names: any;
}
export const _filter = (opt: any, value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-globalsearch',
  templateUrl: './globalsearch.component.html',
  styleUrls: ['./globalsearch.component.scss']
})
export class GlobalsearchComponent implements OnInit {

  stateForm: FormGroup = this._formBuilder.group({
    stateGroup: '',
  });

  stateGroups: StateGroup[] = [{
    letter: 'A',
    names: [
      {
        "name": "Admin Section",
        "url": "admin/users",
        "acl": "admin"
      },
      {
        "name": "Activation Code Section",
        "url": "admin/codegenerate",
        "acl": "admin"
      },
      {
        "name": "Application",
        "url": "application",
        "acl": "content"
      }
    ]
  },
  {
    letter: 'C',
    names: [
      {
        "name": "Carey Card",
        "url": "content/careycard",
        "acl": "content"
      },
      {
        "name": "Create Carey Card",
        "url": "content/careycard/edit/new/1/0",
        "acl": "content"
      },
      {
        "name": "Chapter",
        "url": "content/chapter",
        "acl": "content"
      },
      {
        "name": "Create Chapter",
        "url": "content/chapter/edit/new/1/0",
        "acl": "content"
      }
    ]
  }];

  stateGroupOptions: Observable<StateGroup[]>;

  constructor(private _formBuilder: FormBuilder, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.stateGroupOptions = this.stateForm.get('stateGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
  }
  livesearch() {
    var kp = this._filterGroup(this.stateForm.value.stateGroup);
    if (kp[0]) {
      this.router.navigate([kp[0].names[0].url]);
      this.dialog.closeAll();
    }
  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({ letter: group.letter, names: _filter(group.names, value) }))
        .filter(group => group.names.length > 0);
    }

    return this.stateGroups;
  }
}

