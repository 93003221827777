<mat-drawer-container class="sidenav-container" autosize>
  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/hardsetotphome"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="create-btn" [routerLink]="['/admin/hardsetotp/edit','new']">
            Create New Hardset OTP
          </button>
        </h3>
      </div>

      <div class="col-md-12">
        <form>
          <div class="form-group has-feedback has-search">
           
            <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
              placeholder="Search here">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>

      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''">
            <a (click)="tableSort('all')" class="all">
              All
            </a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">
              Active
            </a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">
              Inactive
            </a>
          </li>
        </ul>
      </div>
      
      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
        (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
                +{{element.country_code}} - {{element.phone}}
              </td>
            </ng-container>

            <ng-container matColumnDef="otp">
              <th mat-header-cell *matHeaderCellDef> OTP</th>
              <td mat-cell *matCellDef="let element"> {{element.otp}} </td>
            </ng-container>

            
            <ng-container matColumnDef="expiry_date">
              <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
              <td mat-cell *matCellDef="let element"> 
              <span *ngIf="element.expiry_date">{{element.expiry_date|date:'dd-MMM-yyy'}}</span>  
              <span *ngIf="!element.expiry_date">-</span>  
              </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef>Created By</th>
              <td mat-cell *matCellDef="let element">{{element.source_user_name?element.source_user_name:'-'}} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef>Created At</th>
              <td mat-cell *matCellDef="let element"> 
              <span *ngIf="element.created_at">{{element.created_at|date:'dd-MMM-yyy'}}</span>  
              <span *ngIf="!element.created_at">-</span>  
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button  [routerLink]="['/admin/hardsetotp/edit',element.id]">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit" alt="Wellthy">
                  </button>
                  <button mat-button (click)="viewProfile(element)">
                    <img src="assets/img/dls/admin-table/Icon_View profile.svg" tooltip="View Profile" alt="Wellthy">
                  </button>
               
                  <button mat-button (click)="viewhistory(element)" tooltip="History" *ngIf="element.show_history === true">
                    <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy" >
                  </button> 
                  
                  <button mat-button  *ngIf="element.show_history === false"  matTooltip="This user has no Hardset otp change history" style="opacity:0.5;cursor: not-allowed;">
                    <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="Wellthy"  disabled>
                  </button>                 

                  <button tooltip="Remove" mat-button (click)="openDialog(element.id)" [ngClass]="{disabled: !element.logoMediaId && !element.bannerMediaId && !element.coloredLogoMediaId && !element.darkLogoMediaId}">
                    <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="Wellthy">
                  </button>
                </div>
              </td>
            </ng-container>
           
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
           
          </table>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>