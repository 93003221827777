<div class="row">
    <div class="col-md-12">
        <div class="row">
    
            <div class="col-md-8">
                <p class="title-cont">Edit Note</p>
            </div>
    
            <div class="col-md-4">
                <div class="copy-close-wrapper">
                    <div class="close-icon" (click)="close()"></div>
                </div>
            </div>
    
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="log-table-wrapper">
                    <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey">

                        <!-- Old Content -->
                        <ng-container matColumnDef="oldContent">
                            <th mat-header-cell *matHeaderCellDef> Old Note </th>
                            <td mat-cell *matCellDef="let element"> {{element.old_content}} </td>
                        </ng-container>
        
                        <!-- New Content -->
                        <ng-container matColumnDef="newContent">
                            <th mat-header-cell *matHeaderCellDef> New Note </th>
                            <td mat-cell *matCellDef="let element"> {{element.new_content}} </td>
                        </ng-container>
        
                        <!-- Date -->
                        <ng-container matColumnDef="changeDate">
                            <th mat-header-cell *matHeaderCellDef> Change Date & Time </th>
                            <td mat-cell *matCellDef="let element"> {{ convertDate(element.created_at)}} </td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>    
                </div>
                
            </div>
        </div>
    </div>     
</div>