import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from '../../../shared/guards';
import { ChangepasswordComponent } from './changepassword.component';

const routes: Routes = [
    { path: '', component: ChangepasswordComponent, canActivate: [AdminGuardGuard] },
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChangepasswordRoutingModule {}