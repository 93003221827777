/*==========================================
Title: user_id_fk changes
Author: Soundharya AM
Date:   21 April 2020	
Last Change : user_id_fk has been added to service call
===========================================*/
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-magazinecollection',
  templateUrl: './magazinecollection.component.html',
  styleUrls: ['./magazinecollection.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class MagazinecollectionComponent implements OnInit {
  displayedColumns: string[] = ['title', 'tags', 'updatedat'];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  public tags: any;
  public alltags: any;
  private myhash: any = {};
  public filteredtabulardata: any;
  activecls = 'active';
  public conditionFilters: any;
  public filteredconditions: any = [];
  public status: any = 'active';

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Magazine Collection');
  }
  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = '';
    this.page = 1;
    this.getLanguages();
    this.getConditions();
    this.search();
  }


  delete(id) {
    this.contentService.hardDelete('magazinecollection', id).pipe(catchError((error: any) => {
      this.showloader = false;
      this.systemService.showSnackBar(error.error && error.error_message ? error.error.error_message : 'Sorry something went wrong.', 'OK');
      return throwError(error);
    }),
      finalize(() => { this.showloader = false; }))
      .subscribe(data => {
        for (let i = 0; i < this.tabulardata.length; i++) {
          if (this.tabulardata[i].id === id) {
            this.tabulardata.splice(i, 1);
          }
        }
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      });
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredconditions = [];
    this.conditionFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.searchcriteria = "";
    this.endoflist = false;
    this.search();
  }


  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Magazine Collection has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  conditionfilter(fln) {
    this.filteredconditions = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.comorbidities.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }

  isActive(status, id) {
    this.contentService.setStatus(status, 'magazinecollection', id).pipe(catchError((error: any) => {
      this.showloader = false;
      this.systemService.showSnackBar(error.error && error.error.error_message ? error.error.error_message : 'Sorry something went wrong.', 'OK');
      return throwError(error);
    }),
      finalize(() => { this.showloader = false; }))
      .subscribe(data => {
        this.tabulardata.forEach((item) => {
          if (item.id === id) {
            item.is_active = !status;
          }
        });
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      });
  }

  getConditions() {
    this.showloader = true;
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}',false)
      .pipe(
        catchError((error: any) => {
          this.showloader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showloader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.alltags = data.getAllConditions;
        this.alltags.forEach(element => {
          this.myhash[element.id] = element.title;
        });
      })
  }

  getConditionsName(comorbidities) {
    comorbidities = comorbidities.split(',').map(comorbidities => this.myhash[comorbidities]).join(',');
    return comorbidities;
  }

  getLanguages() {
    this.showloader = true;
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .pipe(
        catchError((error: any) => {
          this.showloader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showloader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.alllanguages = data.getAllLanguages;
        this.primarylanguage = this.alllanguages;
        this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
      });
  }
  getChildID(itemData: any, languageID) {
    if (
      !isObject(itemData) ||
      !itemData.child_data ||
      !isArray(itemData.child_data) ||
      !(Number(languageID) > 0)
    ) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }
  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  getUsername(id) {
    return this.contentService.allusers[id];
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = false;
      this.contentService
        .getSearchData(this.searchcriteria, this.page.toString(), '20', this.status, 'magazinecollection')
        .pipe(
          catchError((error: any) => {
            this.showloader = false;
            this.systemService.showSnackBar(
              error.error && error.error.error_message
                ? error.error.error_message
                : 'Sorry something went wrong.',
              'OK'
            );
            return throwError(error);
          }),
          finalize(() => {
            this.showloader = false;
          })
        )
        .subscribe(data => {
          data = (data as any).data;
          if (data.length == 0) {
            if (this.onit == true) {
              this.tabulardata = [];
              this.emptyplaceholder = true;
              this.dataSource = new MatTableDataSource<Element>(
                this.tabulardata
              );
            } else {
              this.endoflist = true;
              this.emptyplaceholder = false;
            }
          } else {
            this.emptyplaceholder = false;
            if (this.onit) {
              this.tabulardata = data;
            } else {
              for (var k = 0; k < data.length; k++) {
                this.tabulardata.push(data[k]);
              }
            }
            this.onit = false;
            this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
          }
          if (this.filteredconditions.length !== 0) {
            this.conditionfilter(this.filteredconditions);
          }
          else {
            this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
          }
        });
    }
  }
}
export interface PeriodicElement {
  title: string;
  tags: string;
  isactive: boolean;
}


