/*==========================================
Title:  created_by_data_fk changes
Author: Soundharya AM
Date:   23 April 2020
Last Change : dynamic data from localstorage has been added for created_by_data_fk 
===========================================*/

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject, Subject, throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

@Component({
  selector: 'app-editmagazinecollection',
  templateUrl: './editmagazinecollection.component.html',
  styleUrls: ['./editmagazinecollection.component.scss']
})

export class EditmagazinecollectionComponent implements OnInit {

  title: any = 'New Magazine Collection';
  htmlContent: any;
  chooseMedia: any;
  magazinecollectiondata: any;
  public newitem: boolean = true;
  public showButtonLoader: any;
  s3url: any = environment.s3url;
  public allconditions: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public complexityenum: any = complexityenum;
  public allmagazines: any;
  public optioncount: any;
  public selectedoptn: any = [];
  public control1: any = [];
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: [''],
    image_media: ['', Validators.required],
    subpart_count: ['', Validators.required],
    subpart_data: this.fb.array([]),
    knowledge_level: ['', Validators.required],
    comorbidities: [],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    clients: [[]],
  });
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmatclient: FormControl = new FormControl();
  public searchmat: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  filteredMagazines: Array<ReplaySubject<any[]>> = [];
  public allclients: any;

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  constructor(private browserTitle: Title, public fb: FormBuilder, private cdr: ChangeDetectorRef, public dialog: MatDialog, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute, private router: Router) { }

  initTextOptions() {
    return this.fb.group({
      txtoptn: []
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Magazine Collection');
  }
  ngOnInit() {
    this.setSEOTags();
    this.getMagazines(data => {
      this.route.params.subscribe(params => {
        this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
        this.language = <FormArray>this.dataForm.controls.language_data_fk;
        this.langid = params.langid;
        if (params.id == 'new') {
          this.selfparentid = null;
          this.newitem = true;
          this.selfparentide.setValue(null);
          this.language.setValue(this.langid);
        } else {
          if (params.langid == 1 && params.chid == 0) {
            this.childid = params.id;
            this.selfparentid = null;
            this.newitem = false;
            this.getMagazineCollectionData(this.childid);
          } else if (params.langid != 1 && params.chid == 0) {
            this.newitem = true;
            this.childid = params.id;
            this.selfparentid = params.id;
            this.getMagazineCollectionData(this.childid);
          } else {
            this.childid = params.chid;
            this.newitem = false;
            this.selfparentid = params.id;
            this.getMagazineCollectionData(params.chid);
          }
        }
        this.getallclient();
        this.getConditions();
      });
    });

  }

  modelChangedClient() {
    this.filterClients();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChanged(index) {
    this.filterBanksMulti(index);
  }

  getallclient() {
    this.contentService
      .getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.allclients = data.getAllClients;
        this.filteredClient.next(this.allclients.slice());
      })
  }

  private filterBanksMulti(index) {
    if (!this.allmagazines) {
      return;
    }
    // get the search keyword
    let search = this.searchmat.value;
    if (!search) {
      this.filteredMagazines[index].next(this.allmagazines.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredMagazines[index].next(
      this.allmagazines.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  changeOptions(e) {
    this.optioncount = e;
    this.control1 = <FormArray>this.dataForm.controls['subpart_data'];
    if (this.control1.length !== 0) {
      if (this.control1.length < e) {
        for (let j = 0; j < e; j++) {
          this.filteredMagazines[j] = new ReplaySubject<any[]>(1);
        }
        for (let l = this.control1.length; l < e; l++) {
          this.control1.push(this.initTextOptions());
        }
        this.selectOptions();
      }
      if (this.control1.length > e) {
        for (let j = 0; j > e; j--) {
          this.filteredMagazines[j] = new ReplaySubject<any[]>(1);
        }
        for (let l = this.control1.length; l > e; l--) {
          this.control1.removeAt(l - 1);
        }
        this.selectOptions();
      }
    }
    else {
      for (let j = 0; j < e; j++) {
        this.filteredMagazines[j] = new ReplaySubject<any[]>(1);
      }
      for (var k = 0; k < this.optioncount; k++) {
        this.control1.push(this.initTextOptions());
      }
    }
  }

  selectOptions() {
    this.selectedoptn = [];
    if (this.control1 && this.control1.value) {
      for (let l = 0; l < this.allmagazines.length; l++) {
        for (let k = 0; k < this.control1.value.length; k++) {
          if (this.allmagazines[l].id == this.control1.value[k].txtoptn) {
            this.selectedoptn[k] = this.allmagazines[l];
          }
        }
      }
    }
  }

  selectedOptions(e, j) {
    for (let k = 0; k < this.allmagazines.length; k++) {
      if (e == this.allmagazines[k].id) {
        this.selectedoptn[j] = this.allmagazines[k];
      }
    }
  }

  disableOptions(e) {
    for (let k = 0; k < this.selectedoptn.length; k++) {
      if (this.selectedoptn[k] && this.selectedoptn[k].id && e == this.selectedoptn[k].id) {
        return true;
      }
    }
  }

  getMagazineCollectionData(ide) {
    this.contentService
      .getOneDataById(ide, 'magazinecollection')
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.magazinecollectiondata = data;
        this.optioncount = this.magazinecollectiondata.subpart_count.toString();
        const formtxtoptncount = <FormControl>this.dataForm.controls['subpart_count'];
        formtxtoptncount.setValue(this.optioncount);
        this.changeOptions(this.magazinecollectiondata.subpart_count);
        for (let k = 0; k < this.optioncount; k++) {
          let txtformelement = (<FormArray>(this.dataForm.controls['subpart_data'])).at(k);
          txtformelement['controls'].txtoptn.setValue(parseInt(this.magazinecollectiondata.subpart_data[k]));
          this.selectOptions();
        }

        this.dataForm.patchValue(this.magazinecollectiondata);
        this.getMedia(this.magazinecollectiondata.image_media);
        this.selfparentide.setValue(this.selfparentid);
        this.language.setValue(this.langid);
      });
  }

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.title = postdata.title.toString();
    postdata.detailed_text = postdata.detailed_text.toString();
    postdata.image_media = postdata.image_media.toString();
    postdata.subpart_count = postdata.subpart_count.toString();
    var subparttext = [];
    postdata.subpart_data.forEach(el => {
      subparttext.push(el.txtoptn);
    });
    postdata.clients = JSON.stringify(postdata.clients);
    postdata.subpart_data = JSON.stringify(subparttext);
    postdata.knowledge_level = postdata.knowledge_level.toString();
    postdata.comorbidities = JSON.stringify(postdata.comorbidities);
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'magazinecollection')
        .pipe(
          catchError((error: any) => {
            this.showButtonLoader = false;
            this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
            this.showButtonLoader = false;
          })
        )
        .subscribe(data => {
          this.systemService.showSuccessSnackBar(("Magazine has been added"), "OK");
          this.router.navigate(['content/magazinecollection']);
        });
    } else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'magazinecollection')
        .pipe(
          catchError((error: any) => {
            this.showButtonLoader = false;
            this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
            this.showButtonLoader = false;
          })
        )
        .subscribe(data => {
          this.systemService.showSuccessSnackBar(("Magazine has been updated"), "OK");
          this.router.navigate(['content/magazinecollection']);
          for (let i = 0; i <= this.optioncount; i++) {
            this.modelChanged(i);
          }
        });
    }
  }
  getConditions() {
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}',false)
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.allconditions = data.getAllConditions;
      });
  }

  getMagazines(cb) {
    this.contentService
      .getGraphQlData('{getParentMagazines(status:true){id,title}}')
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.allmagazines = data.getParentMagazines;
        for (let j = 0; j < this.allmagazines; j++) {
          this.filteredMagazines[j].next(this.allmagazines.slice());
        }
        cb(true);
      });
  }

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
          return throwError(error);
        }),
        finalize(() => { this.showButtonLoader = false; })
      ).subscribe(
        (data) => {
          this.imageData = data.getOneMedia;
          this.allmedia = this.imageData.thumbnail_path;
        })
  }
  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      var formelement = <FormArray>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }
}

export enum complexityenum {
  amateur,
  average,
  proficient
}
