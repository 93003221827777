import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setdoctorpassword',
  templateUrl: './setdoctorpassword.component.html',
  styleUrls: ['./setdoctorpassword.component.scss']
})
export class SetdoctorpasswordComponent implements OnInit {

  public hideNewPwd = true;
  public hideConfirmPwd = true;
  private environmentdata: any = environment;
  displayVersion: any;
  isLinear = false;
  firstFormGroup: FormGroup;
  dataForm: FormGroup;
  error: boolean = false;
  token: any;
  message: any;
  doctorCodeDetect!: boolean;
  inputValidate: any;
  doctorCode_UserNameValidate: boolean = false;
  doctorCode_ErrorMessage: any;
  UserEmailFlag: boolean = true;
  newPwdFlag: boolean = false;
  matchPassword: any;
  matchPassword1: any;
  comparePasswords: any;
  NewPassword: any;
  ConfirmPassword: any;
  refresh: string;

  enableCaptcha: boolean = false;
  enableDonebtn: boolean = false;
  UUID: any;
  docCode: any;
  enablePasswordReset: boolean = false;
  linkValiditymsg: HttpErrorResponse;

  constructor(
    private _formBuilder: FormBuilder, 
    public router: Router, 
    private systemService: SystemService, 
    public commonService: CommonserviceService,
    public zone: NgZone,
    private route: ActivatedRoute
  ) {
    
    // this.refresh = window.localStorage.getItem('refresh');
    // if (this.refresh===null){
    //     window.location.reload();
    //     window.localStorage.setItem('refresh', "1");
    // }

    // used for recaptcha
    let that = this;

    window["captchaResolve"] = function($event){
      that.zone.run(() => {
        that.captchaResolved($event);
      });
    }

    window["captchaError"] = function(){
      that.zone.run(() => {
        that.captchaError();
      });
    }
   }

  ngOnInit() {
    localStorage.clear();
    this.route.queryParams
    .subscribe(params => {
      console.log(params); 
      this.UUID = params.uuid;
      this.docCode = params.code;
    }
  );
    this.isValidLink(this.UUID);

    this.displayVersion = this.environmentdata.publishVersion;
    this.firstFormGroup = this._formBuilder.group({
      doctor_code: ['', Validators.required],
    });
  

    this.dataForm = this._formBuilder.group({
      new_password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      // { validator: this.checkPasswords }
    );
  }
  isValidLink(uuid:any) {
    this.commonService.validateDocPasswordLink(uuid)
    .subscribe(
      (res: any) =>{
        if(res.status === 200){
          this.enablePasswordReset = true;
        }
      },
      (err: HttpErrorResponse) => {
        this.linkValiditymsg = err.error.message;
      }
    )
  }

  captchaResolved(captchaResponse: string): void {
    if (captchaResponse == null) {
      this.systemService.showSnackBar("Error in reCaptcha Verification", "OK");
    } else {
      this.commonService.captchaResponse = captchaResponse;
      this.createPassword();
    }
  }

  captchaError(){
    this.systemService.showSnackBar("Error in reCaptcha Verification", "OK"); 
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirmPassword').value;
    if(pass === undefined || confirmPass === undefined || pass === null || confirmPass === null ||
      pass === '' || confirmPass === ''){
      this.enableDonebtn = false;
    }
    if (pass == confirmPass) {
      return pass === confirmPass ? null : { notSame: true }
    }else{
      this.enableDonebtn = false;
    }
  }

  PasswordTextChange(event: any) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    this.matchPassword = re.test(event.target.value);
    if (this.matchPassword == true) {
      this.NewPassword = event.target.value;
      if(this.NewPassword !== '' || this.NewPassword !== undefined  && this.ConfirmPassword !== '' || this.ConfirmPassword !== undefined){
        if ((this.NewPassword != this.ConfirmPassword)) {
          this.comparePasswords = "PasswordNotMatched";
          this.enableDonebtn = false;
        }else{
          this.comparePasswords="";
          this.enableDonebtn = true;
        }
      }
    }else{
      this.comparePasswords="";
      this.enableDonebtn = false;
    }
  }

  confirmPasswordTextChange(event: any) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    this.matchPassword1 = re.test(event.target.value);
    if (this.matchPassword1 == true) {
      this.ConfirmPassword = event.target.value;
      if(this.NewPassword !== '' || this.NewPassword !== undefined  && this.ConfirmPassword !== '' || this.ConfirmPassword !== undefined){
        if ((this.NewPassword != this.ConfirmPassword)) {
          this.comparePasswords = "PasswordNotMatched";
          this.enableDonebtn = false;
        }else{
          this.comparePasswords="";
          this.enableDonebtn = true;
        }
      }
    }else{
      this.comparePasswords="";
      this.enableDonebtn = false;
    }
  }

  back() {
    this.router.navigate(['/login']);
  }

  public ngAfterViewInit() {
    
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  sendOTP(stepper) {
    if (this.firstFormGroup.controls['doctor_code'].value.length > 6) {
      this.inputValidate = "Doctor Code";
      this.goForward(stepper);
    }
  }

  replacePhoneNumbers(number) {
    let cuantos = number.length;
    let tele = [];
    for (let i = 0; i < cuantos; i++) {
      if (i < 8) {
        tele[i] = number[i].replace(/^\d+$/, "*");
      } else {
        tele[i] = number[i];
      }
    }

    let full_phone = "";
    for (let i = 0; i < cuantos; i++) {
      full_phone += tele[i];
    }
    return full_phone;
    //return number;
  }

  keyFunc() {
    this.doctorCode_UserNameValidate = false;
  }



  createPassword() {
    
    let newPasswordData: any = {}
    newPasswordData.token = this.token;
    newPasswordData.new_password = this.dataForm.controls.new_password.value;
    newPasswordData.password_changed_via = this.inputValidate;
    newPasswordData.uuid = this.UUID;
    newPasswordData.code = this.docCode;

    let loginUser = localStorage.getItem('app')
    if (loginUser) {
      newPasswordData.source_of_password_change = loginUser;
    }
    else {
      newPasswordData.source_of_password_change = "self";
    }
    
    if (this.hasWhiteSpace(this.dataForm.value.new_password) == false) {
      this.commonService.createPassword1(newPasswordData)
        .subscribe(
          (res: any) => this.onStatusSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {  
      this.error = true;
      if (this.hasWhiteSpace(this.dataForm.value.new_password) == true) {
        this.message = "Enter valid password, Your password should not be empty or contain spaces";
      }
      this.systemService.showSnackBar((this.message), "OK");
    }
  }

  private async onStatusSuccess() {
    this.systemService.showSuccessSnackBar(("The password has been updated"), "OK");
    await this.sleep(3000)
    this.router.navigate(['/login']);
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  ngOnDestroy(){
    localStorage.removeItem('refresh');
  }

  
}
