import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { local } from 'd3-selection';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';


@Component({
  selector: 'app-addprescription',
  templateUrl: './addprescription.component.html',
  styleUrls: ['./addprescription.component.scss']
})

export class AddprescriptionComponent implements OnInit {
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedUserID: any;
  bodydata: any;
  public isinvalid: boolean = false;
  private allfiles: any;
  public uploadedfiletype: any;
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
  prescription_type: any;
  responseUrl: any;
  private responsethumbUrl: any;
  upload_prescription_type: any;
  upload_type: any = upload_type;
  upload_doc_type: any;
  private resdata: any;
  public image_path: any;
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public dataForm = this.fb.group({
    patient_id_fk: [''],
    name: ['', [Validators.required,Validators.minLength(3)]],
    prescription_ids: [''],
    test_date: ['', Validators.required]
  });
  public saveAsMedia = this.fb.group({
    patient_id_fk: [''],
    name: ['', [Validators.required]],
    prescription_ids: [''],
    test_date: ['', Validators.required]
  });
  isloadingpres: boolean = false;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public demoForm = new FormGroup({
    files: this.filesControl,
  });

  mediaSaveas: string;
  imageFile: any;
  saveAsDocURL: string;
  public documentPlaceHolder: string = '/assets/img/pdficon.jpg';
  fileType: any;
  mediaUploadsize: any;

  constructor(
    public router: Router, 
    public fb: FormBuilder, 
    public dialog: MatDialog, 
    private appContext: ContextService, 
    private userDetailService: UserdetailsService, 
    private systemService: SystemService, 
    private commonService: CommonserviceService
  ) { }

  ngOnInit(): void {
    this.mediaSaveas = localStorage.getItem('mediaSaveas');
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.saveAsDocURL = localStorage.getItem('saveAsDocUrl');
    this.mediaUploadsize = JSON.parse(JSON.parse(localStorage.getItem('mediaUploadSize')));
    if(this.saveAsDocURL){
      this.fileType  = this.saveAsDocURL.split('/').pop().split('?')[0].split('.').pop();
    }
    this.dataForm = new FormGroup({
      patient_id_fk: new FormControl(''),
      name: new FormControl("", [Validators.required, Validators.minLength(3)]),
      prescription_ids: new FormControl(''),
      test_date: new FormControl('', Validators.required)
    });
  }
  public myError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  uploadObject() {    
    this.bodydata = this.dataForm.value;
    let name = this.commonService.isHTML(this.dataForm.value.name);
    if(name  === true){
      this.dataForm.controls.name.patchValue('')
      return;
    }
    this.bodydata.patient_id_fk = this.selectedUserID.toString();
    // const momentDate = new Date(this.dataForm.value.test_date); // Replace event.value with your date value
    // this.bodydata.test_date = moment(this.dataForm.value.test_date).format("YYYY-MM-DD");
    this.bodydata.test_date = this.dataForm.value.test_date;
    this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');

    this.demoForm.value.files.forEach(el => {
      if (((el.size / (1024 * 1024)) > this.mediaUploadsize.video) && (el.type.includes('video'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.image) && (el.type.includes('image'))) {
        this.isinvalid = true;
      }
      else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.document) && (el.type.includes('pdf'))) {
        this.isinvalid = true;
      }
      else {
        this.isinvalid = false;
        this.isloadingpres = false;
      }
    });
    if (this.isinvalid != true) {
      if (this.demoForm.status == 'INVALID') {
        this.isinvalid = true;
        this.isloadingpres = false;
      }
      else {
        this.isinvalid = false;
        this.allfiles = this.demoForm.value.files;
        this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];

        if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
          this.isloadingpres = false;
          this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
        }
        else {
          var imageinfo = {
            detailed_text: this.dataForm.value.name,
            title: this.dataForm.value.name,
            type: this.uploadedfiletype,
          }
          this.isloadingpres = true;
          var scope = this;
          var last = false;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloadingpres = true;
              imagedetails = "";
              imagedetails = imageinfo;
              if (scope.allfiles[i].type.includes('pdf')) {
                scope.prescription_type = 'prescription_pdf';
              }
              if (scope.allfiles[i].type.includes('image')) {
                scope.prescription_type = 'prescription_image';
              }
              scope.commonService.getS3URL(scope.prescription_type, scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                          });
                        }, (error) => {
                          scope.isloadingpres = false;
                          scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                        })
                  }, (error) => {
                    scope.isloadingpres = false;
                    scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                  });
            }
          }
        }
      }
    }

  }

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('prescription_image', thtype, ele.size, ele.name + "." + (thtype).split('/')[1])
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                      //scope.sendMqttRequest('Prescription')
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else if (ele.type.match('pdf')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.setAttribute('crossorigin', 'anonymous');
        tempImg.src = '/assets/img/pdficon.jpg';
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('prescription_image', thtype, myFile.size, 'image.png')
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);

    }
    else {
      this.isloadingpres = true;
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, thumbpath, imageinfo) {
      if (scope.mediaSaveas !== "1") {
        if (scope.allfiles[0].type.includes('pdf')) {
          scope.upload_prescription_type = upload_type[0];
          scope.upload_doc_type = 'document';
        }
        if (scope.allfiles[0].type.includes('image')) {
          scope.upload_prescription_type = upload_type[1];
          scope.upload_doc_type = 'gallary';
        }
      } else {
        if (scope.allfiles.type.includes('pdf')) {
          scope.upload_prescription_type = upload_type[0];
          scope.upload_doc_type = 'document';
        }
        if (scope.allfiles.type.includes('image')) {
          scope.upload_prescription_type = upload_type[1];
          scope.upload_doc_type = 'gallary';
        }
      }
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, scope.upload_prescription_type, scope.upload_doc_type)
        .subscribe(
          (data) => {
            scope.resdata = data;
            scope.image_path = scope.resdata.data.path;
            scope.dataForm.reset();
            scope.demoForm.reset();
            scope.isloadingpres = false;
            scope.bodydata.prescription_ids = [scope.resdata.data.id];
            if (scope.resdata.status == 201) {
              scope.bodydata.test_date = moment(scope.bodydata.test_date).format('YYYY-MM-DD');
              scope.userDetailService.uploadPres(scope.bodydata).pipe(
                delay(100),
                catchError((error: any) => {
                  scope.loadingError$.next(true);
                  scope.isloadingpres = true;
                  return throwError(error);
                }),
                finalize(() => { scope.loadingSubject$.next(false); })
              ).subscribe(data => {
                scope.commonService.sendRefresh('Refesh the page');
                scope.closeDialog();
                scope.systemService.showSuccessSnackBar("Prescription has been uploaded", "Ok");

              })
            }
            else {
              scope.isloadingpres = true;
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          })
    }
  }



  dataURItoBlobb(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }
  ngOnDestroy() {
    localStorage.removeItem('filesaveAs');
    localStorage.removeItem('mediaSaveas');
    localStorage.removeItem('saveAsDocUrl');
  }


  saveAs() {
    this.bodydata = this.saveAsMedia.value;
    let name = this.commonService.isHTML(this.dataForm.value.name);
    if(name  === true){
      this.dataForm.controls.name.patchValue('')
      return;
    }
    this.bodydata.patient_id_fk = this.selectedUserID.toString();
    const momentDate = new Date(this.saveAsMedia.value.test_date); // Replace event.value with your date value
    this.bodydata.test_date = moment(momentDate).format("YYYY-MM-DD");
    this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
    this.imageFile = this.dataURLtoFile(JSON.parse(localStorage.getItem('filesaveAs')), this.saveAsMedia.value.name);

    if (this.isinvalid != true) {
      if (this.saveAsMedia.status == 'INVALID') {
        this.isinvalid = true;
        this.isloadingpres = false;
      }
      else {
        this.isinvalid = false;
        this.allfiles = this.imageFile;
        this.uploadedfiletype = (this.allfiles.type).split('/')[1];

        if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
          this.isloadingpres = false;
          this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
        }
        else {
          var imageinfo = {
            detailed_text: this.saveAsMedia.value.name,
            title: this.saveAsMedia.value.name,
            type: this.uploadedfiletype,
          }
          this.isloadingpres = true;
          var scope = this;
          var last = false;
          var imagedetails;
          processfiles(0);
          function processfiles(i) {
            if (imageinfo) {
              scope.isloadingpres = true;
              imagedetails = "";
              imagedetails = imageinfo;
              if (scope.allfiles.type.includes('pdf')) {
                scope.prescription_type = 'prescription_pdf';
                scope.upload_doc_type = 'document';
              }
              if (scope.allfiles.type.includes('image')) {
                scope.prescription_type = 'prescription_image';
                scope.upload_doc_type = 'gallary';
              }

              scope.commonService.getS3URL(scope.prescription_type, scope.allfiles.type, scope.allfiles.size, scope.allfiles.name + "." + (scope.allfiles.type).split('/')[1])
                .subscribe(
                  (data) => {
                    scope.responseUrl = data;
                    
                    scope.commonService.uploadToS3(scope.allfiles, scope.responseUrl.data.signed_request)
                      .subscribe(
                        (data) => {
                          scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles, imagedetails, data => {
                          });
                        }, (error) => {
                          scope.isloadingpres = true;
                          scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                        })
                  }, (error) => {
                    scope.isloadingpres = false;
                    scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                  });
            }
          }
        }
      }
    }
  }
  // ***Here is code for converting "Base64" to javascript "File Object".***
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
export enum upload_type {
  prescription_pdf,
  prescription_image
}

