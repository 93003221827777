import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ActivationsearchRoutingModule } from './activationsearch.routing.module';
import { ActivationsearchComponent } from './activationsearch.component';
import { MapDoctorComponent } from './map-doctor/map-doctor.component';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { SharedTranslateModule } from './../../shared/shared-translate.module';


@NgModule({
  declarations: [

    ActivationsearchComponent,
    MapDoctorComponent

  ],
  imports: [
    CommonModule,
    ActivationsearchRoutingModule,
    ImageCropperModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    SharedModule.forRoot(),
    SharedTranslateModule
  ],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class ActivationsearchModule {}
