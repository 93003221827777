import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-transferlistdata',
  templateUrl: './transferlistdata.component.html',
  styleUrls: ['./transferlistdata.component.scss']
})

export class TransferlistdataComponent implements OnInit {
  userid: any;
  public searchcriteria: any;
  public showloader: any;
  lastSearch: any;
  status: any = status;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any = [];
  filterdata: any = [];
  public emptyplaceholder: boolean = false;
  dataSource: any;
  tabledata: any;
  displayedColumns: string[] = ['created_at', 'current_hc', 'old_hc'];
  public userName: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TransferlistdataComponent>, private commonService: CommonserviceService, private systemService: SystemService) { }

  ngOnInit(): void {

    this.userid = this.data.element.id;
    this.userName = this.data.element.name
    
    this.searchcriteria = "";
    this.page = 1;
    this.gettransferlistdata(this.userid);
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  convertDate(date) {
    var d = new Date(date);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()
    
    return currentdate + ' ' + month + ' ' + year + ',' + d.toLocaleTimeString();;

  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.gettransferlistdata(this.userid);
    }
  }


  // To Get Transfer List 
  // ----------------------------

  gettransferlistdata(id) {
    if (this.page != this.lastpage) {
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.gettransferlistdata(this.page.toString(), "20", id.toString())
        .subscribe(
          (res: any) => this.onTransferDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onTransferDataSuccess(data) {
    
    this.tabledata = data;
    if (this.tabledata.data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = this.tabledata.data;
      }
      else {
        for (var k = 0; k < this.tabledata.data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }

  // ----------------------------

}
