import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';

@Component({
  selector: 'app-qrbottomsheet',
  templateUrl: './qrbottomsheet.component.html',
  styleUrls: ['./qrbottomsheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrbottomsheetComponent implements OnInit {
  public allCampaigns: any;
  public alldata: any;
  public showqrlink: boolean = false;
  public imgurl: any;
  public campaignid: any;
  public alllanguages: any;
  public dataForm = this.fb.group({
    phone: ["", Validators.required],
    country_code: ["", Validators.required],
    condition: ["", Validators.required],
     language_data_fk: ["",Validators.required]
  });
  constructor(private contentService: ContentserviceService,private cd: ChangeDetectorRef, private systemService: SystemService, private _bottomSheetRef: MatBottomSheetRef<QrbottomsheetComponent>, public fb: FormBuilder, private doctorService: DoctorDataService) { }
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  ngOnInit() {
    this.getCampaignData();
    this.getAllLanguages();
  }
  getCampaignData() {
    this.doctorService.getAllCampaign()
      .subscribe(data => {
        this.allCampaigns = (data as any).data;
      })
  }
  dataObject() {
    var postpredata = this.dataForm.value;
    this.doctorService.deeplinkAndQr(postpredata, 'qr')
      .subscribe(
        (res: any) => this.onSuccessdeepCampainData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  onSuccessdeepCampainData(data) {
    var scope = this;
    if ((data as any).status == 200) {
      setInterval(() => {
        scope.alldata = (data as any).data;
        this.campaignid = scope.alldata;
        var dataset = '\"campaign_id\":\"' + scope.alldata.campaign_id + '\"' + encodeURIComponent('&') + '\"user_code\":\"' + scope.alldata.user_code + '\"' + encodeURIComponent('&')+ '\"language_id\":\"' + scope.alldata.language_id + '\"' + encodeURIComponent('&')+ "\"token\":\"" + encodeURIComponent(scope.alldata.token) + '\"';
        this.imgurl = "https://chart.googleapis.com/chart?cht=qr&chl=" + dataset + "&chs=232x232&choe=UTF-8&chld=H%7C2";
        scope.showqrlink = true;
        scope.cd.markForCheck();
      }, 1000);
    }
    else {
      this.systemService.showSnackBar((data as any).message, "OK");
      this._bottomSheetRef.dismiss();
    }
  }

  downloadQR(data) {
    var canvas: HTMLCanvasElement = this.canvas.nativeElement;
    var context = canvas.getContext('2d');
    let pdfName = this.dataForm.value.phone;
    let scope = this;
    setTimeout(() => {
      let img1 = new Image();
      let img2 = new Image();
      let img3 = new Image();
      img1.crossOrigin = "Anonymous";
      img1.onload = function () {
        // canvas.width = img1.width;
        // canvas.height = img1.height;
        var cW = 300;
        var cH = 300;
        canvas.width = cW;
        canvas.height = cH
        var dataset = '\"campaign_id\":\"' + scope.alldata.campaign_id + '\"' + encodeURIComponent('&') + '\"user_code\":\"' + scope.alldata.user_code + '\"' + encodeURIComponent('&') + "\"token\":\"" + encodeURIComponent(scope.alldata.token) + '\"';
        img2.src = "https://chart.googleapis.com/chart?cht=qr&chl=" + dataset + "&chs=545x545&choe=UTF-8&chld=H%7C2";
        img2.crossOrigin = "Anonymous";
      };
      img2.onload = function () {
        img3.src = "assets/img/Wellthy_QR_Logo_2.png";
        img3.crossOrigin = "Anonymous";
        img3.onload = function () {
          //context.globalAlpha = 1.0;
          // context.drawImage(img1, 0, 0, 964, 1692);
          //context.globalAlpha = 01.0; //Remove if pngs have alpha
          // context.drawImage(img2, 188, 596, 595, 595);
          // context.drawImage(img3, 417, 827, 130, 130);
          context.drawImage(img2, 0, 0, 300, 300);
          context.drawImage(img3, 120, 120, 65, 65);
          let fileName = pdfName + "_" + JSON.parse(localStorage.getItem('scarletuserdata')).name + "_" + ".png";
          //pdf.save(pdfName + ".pdf");
          scope.download(canvas, fileName);
        }
      };

      img1.src = 'assets/img/Doctor_Stand.png';
    }, 2000);
  }

  download(canvas, filename) {
    var lnk = document.createElement('a'), e;
    lnk.download = filename;
    lnk.href = canvas.toDataURL("image/png;base64");
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent("click", true, true, window,
        0, 0, 0, 0, 0, false, false, false,
        false, 0, null);
      lnk.dispatchEvent(e);
    } else {
      lnk.click();
    }
  }

  getAllLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
  }

}
