<span class="slidetogglecss" (click)="togglenav()">
    <i [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">
        {{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}
    </i>
</span>
<div class="userlistnavcontent">
    <div class="row">
        <div class="col-md-12 submenubar">
            <h3 class="userlisttitle">Admin</h3>
            <div class="menu-holder">
                <a [routerLink]="['/admin/users']" routerLinkActive="active">Scarlet users</a>
                <a [routerLink]="['/admin/doctors']" routerLinkActive="active">Doctors</a>
                <a [routerLink]="['/admin/patients']" routerLinkActive="active">Patients</a>
                <a [routerLink]="['/admin/activationsearch']" routerLinkActive="active">Activation Search</a>
                <a [routerLink]="['/admin/publishrequests']" routerLinkActive="active" *ngIf="!localStoredData?.organization_data?.scarlet_acl_restriction?.includes('campaign')">Publish Requests</a>
                <a [routerLink]="['/admin/taskadmin']" routerLinkActive="active">Assign Task</a>
                <a [routerLink]="['/admin/hardsetotp']" routerLinkActive="active" >Hardset OTP</a>
                <a [routerLink]="['/admin/hospital']" routerLinkActive="active" >Hospital List</a>
                <hr *ngIf="userData" > 
                <a [routerLink]="['/admin/client']" routerLinkActive="active" *ngIf="userData && !localStoredData?.organization_data?.scarlet_acl_restriction?.includes('activation')">Clients</a>
                
                <a [routerLink]="['/admin/app-master']" routerLinkActive="active" *ngIf="userData">App Actions</a>

                <a [routerLink]="['/admin/mobilechange']" routerLinkActive="active">
                    Mobile Number Change
                    <div class="notification-icon-wrapper" *ngIf="hasPhoneNumberRequest">
                        <i class="material-icons whiteicon">
                            fiber_manual_record
                        </i>
                    </div>
                </a>

                <a [routerLink]="['/admin/apilogs']" routerLinkActive="active">API Logs</a>
            </div>
        </div>
    </div>
</div>