<div class="clearfix">
    <h2 class="pull-left title-modal">Re-assign</h2>
    <div class="pull-right">
        <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive" alt="">
    </div>
</div>
<form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
    <div class="row row-wrapper mb-15 d-flex align-items-center">
        <div class="col-md-4">
            <mat-checkbox class="example-margin" formControlName="has_expiry">Has expiry
            </mat-checkbox>
        </div>
        <div class="col-md-4" *ngIf="dataForm?.value?.has_expiry">
            <mat-form-field appearance="outline" floatLabel="never">
                <!-- <mat-label>Choose a date</mat-label> -->
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="expiry_date" [min]="todaydate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button mat-button class="btn btn-filled">Re-assign</button>
        </div>
    </div>
</form>