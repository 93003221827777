import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from './../../shared/shared.module';
import { BroadcastComponent as HealthCoachBroadcastComponent } from './broadcast.component';
import { BroadcastRoutingModule } from './broadcast.routing.module';
import { CreatebroadcastComponent } from './createbroadcast/createbroadcast.component';
import { ViewbroadcastComponent } from './viewbroadcast/viewbroadcast.component';
import { ContextService as HealthCoachContextService } from './../services/context.service';
import { HcleftnavModule } from '../shared/hcleftnav/hcleftnav.module';

@NgModule({
  declarations: [
    HealthCoachBroadcastComponent,
    CreatebroadcastComponent,
    ViewbroadcastComponent
  ],
  imports: [
    CommonModule,
    BroadcastRoutingModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    HcleftnavModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class BroadcastModule { }
