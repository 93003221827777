import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-aleartmodel',
  templateUrl: './aleartmodel.component.html',
  styleUrls: ['./aleartmodel.component.scss']
})
export class AleartmodelComponent implements OnInit {

  message: string = "There are unsaved changes! Are you sure?";
  confirmButtonText = "Confirm";
  cancelButtonText = "Cancel";


  constructor(private dialogRef: MatDialogRef<AleartmodelComponent>) { }
  ngOnInit() {
    this.dialogRef.updateSize('386px', '150px');
  }

  onConfirmClick(): void {
    
    this.dialogRef.close(true);
    
  }
  onCancelClick(): void {
    
    this.dialogRef.close(false);
    
  }

}
