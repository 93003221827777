<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>

    <div class="sidenav-content">

        <app-userlistnavbar></app-userlistnavbar>

        <ng-container [ngSwitch]="userSelectionStarted" style="margin-top: 0%;">
            <ng-container *ngSwitchCase="'no'" style="margin-top: 0%;">
                <ng-container [ngSwitch]="showUserDetailsSection" style="margin-top: 0%;">
                    <div class="mainrightcontainer" style="margin-top: 0%;">
                        <app-chatsection [isFullWidth]="showUserDetailsSection"></app-chatsection>
                        <app-userdetailsection *ngSwitchCase="'yes'"></app-userdetailsection>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'brodcast'">
                <app-senduserbrodcast></app-senduserbrodcast>
            </ng-container>
            <ng-container *ngSwitchCase="'transfer'">
                <app-usertransfer></app-usertransfer>
            </ng-container>
        </ng-container>

    </div>
  
</mat-drawer-container>