import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticateService, HttpService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ContentserviceService extends HttpService {
  public s3tokenurl: any = environment.serverUrl;
  public graphql: any = environment.gqlUrl + 'gql?query=query';
  public microserviceUrl: any = environment.microserviceUrl;
  public allusers: any;
  public allextension: any = {
    tag: 'tag',
    magazine: 'magazine',
    magazinecollection: 'magazinecollection',
    magazinecategory: 'magazine/type',
    question: 'question',
    feedback: 'feedback',
    quiz: 'quiz',
    language: 'language',
    lesson: 'lesson',
    chapter: 'chapter',
    intent: 'intent',
    careycard: 'careycard',
    module: 'module',
    level: 'level',
    therapy: 'therapy',
    savelevel: 'therapy/levels',
    activationcode: 'activationcode',
    activationData: 'activationcode/completedetails',
    obflow: 'obflow/widgets',
    obflowcreate: 'obflow',
    condition: 'condition',
    media: 'media',
    onboardingflowmapper: 'widgetmapper',
    comorbidities: 'comorbidities',
    campaigndata: 'campaign',
    usercreate: 'users',
    patient: 'patient',
    resetpassword: 'users/update/passsword/normal',
    faqcategory: 'faq/category',
    category: 'faq/category',
    faqqa: 'faq/qa',
    questionnaire: 'questionnaire',
    symptommapping: 'symptom/mapping',
    logmapping: 'log/mapping',
    questionnairemapping: 'questionnaire/mapping',
    triggermapping: 'trigger/priority/mapping',
    logstatus: 'priority/mapping/log',
    symptomstatus: 'priority/mapping/symptom',
    trigger: 'trigger',
    medicinelist: 'medicine/list',
    medicinesearch: 'medicine/list',
    mealData: 'meal',
    meal: 'meal',
    pump:'pump',
    medication:'medication',
    healthgoals:'healthgoals',
    productplan:'product',
    productmapping: 'product/mapping',
  };
  public microServiceURL : any = environment.microserviceUrl;
  constructor(private http: HttpClient, private authService: AuthenticateService) {
    super(http)
  }

  postToBlade(data, service, org?): Observable<string> {
    if (org == true && environment.org == true) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service], data)
  }

  getHomeFeedData(data): Observable<string> {
    return this.post(this.s3tokenurl + 'homefeed/level/data', data)
  }


  updatePassword(id, data): Observable<string> {
    return this.patch(this.s3tokenurl + 'users/update/passsword/normal/' + id, data)
  }

  hardDelete(service, id): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { user_id_fk: localStorage.getItem('scarletuserid') }
    }
    return this.delete(this.s3tokenurl + this.allextension[service] + '/delete/' + id, options)
  }

  RichMediaHardDelete(service, id): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { user_id_fk: localStorage.getItem('scarletuserid'),
              id:id.toString()
    }
    }
    return this.delete(this.microServiceURL +'taskmanagement/'+ this.allextension[service] + '/delete/id', options)
  }

  setStatus(isactive, service, id): Observable<string> {
    let bodydata = {
      user_id_fk: localStorage.getItem('scarletuserid')
    }

    if (isactive) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: bodydata
      }
      return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, options)
    }
    else {
      return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, bodydata)
    }
  }

  setRichMediaStatus(isactive, service, id): Observable<string> {
    let bodydata = {
      user_id_fk: JSON.parse(localStorage.getItem('scarletuserid')).toString()
    }
    if (isactive) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: bodydata
      }
      return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, options)
    }
    else {
      return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, bodydata)
    }
  }
  setStatusCamp(isactive, service, id): Observable<string> {
    if (isactive) {
      return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, {})
    }
    else {
      return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, {})
    }
  }

  updateActivationCode(data, id): Observable<string> {
    return this.http.patch<any>(this.s3tokenurl + 'activationcode/' + id, data)
  }

  updateToBlade(chid, id, data, service, org?): Observable<string> {
    if (org == true && environment.org == true) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.patch(this.s3tokenurl + this.allextension[service] + '/' + chid, data)
  }

  updateMealToBlade(id, data, service): Observable<string> {
    return this.patch(this.s3tokenurl + this.allextension[service], data)
  }


  updateMappingToBlade(data, id, service): Observable<string> {
    return this.patch(this.s3tokenurl + this.allextension[service] + '/' + id, data)
  }

  public getAllData(typetext, page, limit, status, service, org?): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    if (org == true && environment.org == true) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }

  updateCondition(bodydata, service, id): Observable<any> {
    return this.patch(this.s3tokenurl + this.allextension[service] + '/' + id, bodydata)
  }

 public getAllConditions(typetext,page, limit, status, service,org?): Observable<any> {
    //return this.get(this.s3tokenurl + this.allextension[service] + '?page=' + page + '&limit=' + limit + '&status=' + status)
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    if (org == true && environment.org == true) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }
  getMappingData(typetext, page, limit, status, service): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    return this.post(this.s3tokenurl + this.allextension[service] + '/data', bodydata)
  }

  public getOneDataById(id, service): Observable<any> {
    return this.get(this.s3tokenurl + this.allextension[service] + '/get/' + id)
  }
  
  public getOneDataById1(isactive, service, id): Observable<any> {
    //return this.get(this.s3tokenurl + this.allextension[service] + '/get/' + id)
    let bodydata = {
      user_id_fk: JSON.parse(localStorage.getItem('scarletuserid')).toString()
    }
    if (isactive) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: bodydata
      }
      return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, options)
    }
    else {
      return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, bodydata)
    }
  }
  public getmedDataById(id, service): Observable<any> {
    return this.get(this.s3tokenurl + this.allextension[service] + '/' + id)
  }

  public getGeneralRequest(service): Observable<any> {
    return this.get(this.s3tokenurl + this.allextension[service])
  }

  public getSearchData(typetext, page, limit, status, service, org?): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      language_id: '1',
      group_mode: 'true',
    };
    if (org == true && environment.org == true) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/data', bodydata)
  }
  public getSearchDataRequest(typetext, tags, page, limit, status, service, org?): Observable<any> {
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      language_id: '1',
      group_mode: 'true',
      tags: tags,
    };
    if (org == true && environment.org == true) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/data', bodydata)
  }

  public getSearchDataMC(typetext, page, limit, status, service): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      language_id: '1'
    };
    return this.post(this.s3tokenurl + this.allextension[service] + '/data', bodydata)
  }

  public getAllLogsForCondition(condid): Observable<any> {
    return this.get(this.s3tokenurl + 'log/getAll/' + condid)
  }

  createLogPriority(data): Observable<string> {
    return this.post(this.s3tokenurl + 'priority/mapping/log', data)
  }

  updateLogPriority(data, id): Observable<string> {
    return this.patch(this.s3tokenurl + 'priority/mapping/log/' + id, data)
  }
  updatetriggerPriority(data, id): Observable<string> {
    return this.patch(this.s3tokenurl + 'trigger/priority/mapping/' + id, data)
  }


  public getAllTriggerForCondition(condid): Observable<any> {
    return this.get(this.s3tokenurl + 'trigger/getAll/' + condid)
  }

  getTriggerPriorityForCondition(id): Observable<any> {
    return this.get(this.s3tokenurl + 'trigger/priority/mapping/' + id)
  }
  createTriggerriority(data): Observable<string> {
    return this.post(this.s3tokenurl + 'trigger/priority/mapping', data)
  }

  public getLogPriorityForCondition(id): Observable<any> {
    return this.get(this.s3tokenurl + 'priority/mapping/log/' + id)
  }

  createSymptomPriority(data): Observable<string> {
    return this.post(this.s3tokenurl + 'priority/mapping/symptom', data)
  }

  updateSymptomPriority(data, mappingid): Observable<string> {
    return this.patch(this.s3tokenurl + 'priority/mapping/symptom/' + mappingid, data)
  }

  public getAllSymptomsForCondition(condid): Observable<any> {
    return this.get(this.s3tokenurl + 'symptom/getAll/' + condid)
  }

  public getSymptomPriorityForCondition(condid): Observable<any> {
    return this.get(this.s3tokenurl + 'priority/mapping/symptom/' + condid)
  }

  public getLevelDifference(levelid): Observable<any> {
    return this.post(this.s3tokenurl + 'request/level/difference', { level_data_fk: levelid })
  }
  public createRequest(body): Observable<any> {
    return this.post(this.s3tokenurl + 'request', body)
  }

  public getRequestById(id): Observable<any> {
    return this.get(this.s3tokenurl + 'request/get/' + id)
  }

  public createDynamicLink(data): Observable<string> {
    return this.post(this.s3tokenurl + 'deeplink', { "query": data })
  }

  public getGraphQlData(parameter: string, org?): Observable<any> {
    if (org != false && environment.org == true) {
      var orgdata = 'status:true,organization_data:"' + this.authService.getorg() + '"';
      parameter = parameter.replace('status:true', orgdata)
    }
    return this.get(encodeURI(this.graphql + parameter))
  }
  public getAllGraphQlData(parameter: string, org?): Observable<any> {
    if (org != false && environment.org == true) {
      var orgdata = 'status:true,organization_data:"' + this.authService.getorg() + '"';
      parameter = parameter.replace('status:false', orgdata)
    }
    return this.get(encodeURI(this.graphql + parameter))
  }


  getS3URL(path, type, size, filen): Observable<string> {
    var s3data = {
      "path": path,
      "mime": type,
      "size": size.toString(),
      "filename": filen
    }
    return this.post(this.s3tokenurl + 'media/s3/token', s3data)
  }

  //Rich media GetS3 URL 
  getRichMediaS3URL(path, type, size, filename): Observable<string> {
    var s3data = {
      "path": path,
      "mime": type,
      "size": size.toString(),
      "filename": filename
    }
    return this.post(this.microserviceUrl + 'taskmanagement/media/s3/token', s3data)
  }

  uploadToS3(file, url): Observable<any> {
    return this.put(url, file)
  }

  postImageToBlade(path, size, title, description, mime, thumbnail_path, fk): Observable<string> {
    title = this.removeHTMLtags(title);
    description = this.removeHTMLtags(description);
    var imagedata = {
      "path": path,
      "size": size,
      "title": title,
      "detailed_text": description,
      "mime": mime,
      "thumbnail_path": thumbnail_path,
      "created_by_data_fk": fk
    }
    return this.post(this.s3tokenurl + 'media/create', imagedata)
  }

  public getAllMedias(): Observable<any> {
    return this.http.get<any>(this.s3tokenurl + 'media/get?page=1&limit=10&status=active')
  }

  public getMediasSearch(typetext, page, limit, status, type, sdate, edate): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      media_type: type,
      date_start: sdate,
      date_end: edate
    }
    return this.post(this.s3tokenurl + 'media/search', bodydata)
  }
  public getAllRichMedia(typetext, page, limit, status, type, sdate, edate): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      media_type: 'all',
      date_start: sdate,
      date_end: edate
    } 

    return this.post(this.microServiceURL + 'taskmanagement/media/search', bodydata)
  }

  updateThumbnail(path, id, created_by_fk): Observable<string> {
    var imagedata = {
      "thumbnail_path": path,
      "created_by_data_fk": String(created_by_fk)
    }
    return this.patch(this.s3tokenurl + 'media/update/' + id, imagedata)
  }

  public getPumpData(page, limit, status, service): Observable<any> {
    let bodydata = {
      page: page,
      limit: limit,
      status: status
    };
    return this.get(this.s3tokenurl + service+'?status='+status+'&page='+page+'&limit='+limit, bodydata)
  }
  public medicationTypes(): Observable<any> {
    return this.http.get<any>(this.s3tokenurl + 'medication/type')
  }
  private __symptomData = new BehaviorSubject<any>(null);
  current__symptomData= this.__symptomData.asObservable();
  change_symptomData(symptomValue:any){
    this.__symptomData.next(symptomValue)
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
