import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment = require('moment');
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';


@Component({
  selector: 'app-addreminder',
  templateUrl: './addreminder.component.html',
  styleUrls: ['./addreminder.component.scss']
})
export class AddreminderComponent implements OnInit {
  public selectedUserID: any;
  public typeFilters: any;
  public remType: any;
  public showButtonLoader: boolean;
  public schedule_data: any;
  noofoptions: any;
  public dosageData: any;
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  isReminder: any;
  public addDoctorVisitform: FormGroup;

  constructor(public dialog: MatDialog, private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder, private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.isReminder = this.data.isReminder;
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.addDoctorVisitform = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.minLength(3)]),
      start_date: new FormControl('', Validators.required),
      time: new FormControl(this.initTextOptions(), Validators.required)
    });
  }
  public addremainderform = this.fb.group({
    name: ["", [Validators.required, whiteSpaceValidation]],
    dosage_name: [''],
    dosage_value: [''],
    dosage_unit: [''],
    meal_type: [''],
    sub_type: [''],
    medicine_shape: [''],
    time: this.fb.array([]),
    morning_dose: [''],
    afternone_dose: [''],
    evening_dose: [''],
    type: [''],
    days: [''],
    featurecount: ['']
  });


  public myError = (controlName: string, errorName: string) => {
    return this.addDoctorVisitform.controls[controlName].hasError(errorName);
  }

  initTextOptions() {
    return this.fb.group({
      e: [true],
      t: ['', Validators.required],
      i: [this.generateRandomNumber()]
    });
  }

  generateRandomNumber() {
    return Math.random().toString(36).replace(/[^0-9]+/g, "").substr(0, 5);
  }

  generateOptions(e) {
    this.noofoptions = e;
    for (let j = 0; j < e; j++) {
      this.filteredTags[j] = new ReplaySubject<any[]>(1);
    }
    const feature = <FormArray>(this.addremainderform.controls['time']);
    if (feature.length !== 0) {
      if (feature.length < e) {
        for (let l = feature.length; l < e; l++) {
          feature.push(this.initTextOptions());
        }
      }
      if (feature.length > e) {
        for (let l = feature.length; l > e; l--) {
          feature.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        feature.push(this.initTextOptions());
      }
    }
  }

  convertIsoDatetime(d) {
    var date = new Date(d);

    // var UTCdate = date.toUTCString()
    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // var getHr = date.getUTCHours()
    // var getMin = date.getUTCMinutes()
    // var getTime = getHr +":"+getMin
    // 
    //  return getTime.toString();
    const number = moment(date).format("HH:mm");

    // return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return number;
  }


  dataObject() {
    var t = [];
    var e = [];
    var i = [];
    var postdata = this.addremainderform.value;
    // postdata.name = postdata.name.toString();
    // postdata.schedule = postdata.schedule.toString();
    // postdata.category = postdata.category.toString();
    // var datum = this.addremainderform.value.start_date.toUTCString();
    // var logdate = null;
    // logdate = moment(datum).toISOString();
    // postdata.start_date_time = logdate;
    // postdata.patient_id_fk = this.selectedUserID.toString();
    if (postdata.type == 'meal') {

    }
    if (postdata.medicine_type == 'tablet') {
      postdata.volume = '0';
      postdata.num_shots = '0';
      postdata.color = [postdata.first_color];

    }
    else if (postdata.medicine_type == 'capsule') {
      postdata.color = [postdata.first_color, postdata.second_color];
      postdata.volume = '0';
      postdata.num_shots = '0';

    }
    else if (postdata.medicine_type == 'syrup') {
      postdata.num_units = '0';
      postdata.num_shots = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'insulin_pen') {
      postdata.volume = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'ointment') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.num_shots = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'injectable') {
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
    }
    else if (postdata.medicine_type == 'mdi') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'dpi') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'bai') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'nasal_spray') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'dpi_revolizer') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
    }
    else if (postdata.medicine_type == 'dpi_multihaler') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'dpi_rotahaler') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else if (postdata.medicine_type == 'nebulizer') {
      postdata.volume = '0';
      postdata.num_units = '0';
      postdata.medicine_shape = 'round';
      postdata.color = [postdata.first_color];
    }
    else {

    }

    // postdata.dosage = postdata.morning_dose,postdata.afternone_dose,postdata.evening_dose;
    // postdata.dosage = (postdata.dosage).toString();
    postdata.name = postdata.name.toString();
    postdata.meal_type = postdata.meal_type.toString();
    // postdata.sub_type = postdata.sub_type.toString();
    // postdata.medicine_shape = postdata.medicine_shape.toString();
    postdata.days = JSON.stringify(postdata.days);
    // postdata.dosage_name = postdata.dosage_name.toString();
    delete postdata.start_date;
    delete postdata.morning_dose;
    delete postdata.afternone_dose;
    delete postdata.evening_dose;

    var newArray = [];
    if (this.addremainderform.value.time.length != 0) {
      newArray = postdata.time.map(el => {
        return {
          e: el.e,
          t: this.convertIsoDatetime(el.t),
          i: el.i + "_" + this.convertIsoDatetime(el.t)
        }
      });
      // postdata.time.forEach(el => {
      //   e.push(el.e);
      //   t.push(this.convertIsoDatetime(el.t));
      //   i.push(el.i);
      //   newArray = e.concat(t, i);
      // });
    }

    postdata.time = JSON.stringify(newArray);
    postdata.patient_id_fk = this.selectedUserID.toString();
    postdata['created_by_fk'] = localStorage.getItem('scarletuserid')
    delete postdata.featurecount;
    // postdata.time = JSON.stringify([{"e":true, "t": "06:30", "i": "12df"}, {"e":true, "t": "19:30", "i": "12rt"}]);

    this.dialog.closeAll();
    this.UserdataService.addReminder(postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.sendMqttRequest('Reminder');
        this.systemService.showSnackBar("Reminder added sucessfully.", "OK");
        this.showButtonLoader = false;
      })
  }

  sendMqttRequest(type) {
    var obj = {
      link: "https://wellthy.page.link/B421",
      type: type
    }
    this.appContext.changeMessage(obj);
  }
  addDoctorVisit() {
    let e = 1;
    var postdata = this.addDoctorVisitform.value;
    let timearr = [{
      e: true,
      t: postdata.time,
      i: [this.generateRandomNumber()]
    }
    ];
    postdata.time = timearr

    postdata.type = 'doctorvisit';
    postdata.days = JSON.stringify([1,2,3,4,5,6,7]);
    postdata.patient_id_fk = this.selectedUserID.toString();
    postdata['created_by_fk'] = localStorage.getItem('scarletuserid');
    var newArray = [];
    if (this.addDoctorVisitform.value.time.length != 0) {
      newArray = postdata.time.map(el => {
        return {
          e: el.e,
          t: this.convertIsoDatetime(el.t),
          i: el.i + "_" + this.convertIsoDatetime(el.t)
        }
      });
    }
    postdata.time = JSON.stringify(newArray);
    this.UserdataService.addReminder(postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.sendMqttRequest('Reminder');
        this.systemService.showSnackBar("Reminder added sucessfully.", "OK");
        this.showButtonLoader = false;
      })

  }
}

export enum schedule_data {
  medicine,
  meal,
  activity,
  water
}
export enum category {

}




