<div class="row">
    <div class="col-md-12">
        <div class="row">
    
            <div class="col-md-8">
                <p class="title-cont">Update Patient Mobile Number</p>
            </div>
    
            <div class="col-md-4">
                <div class="copy-close-wrapper">
                    <div class="close-icon" (click)="close()"></div>
                </div>
            </div>
    
        </div>

        <div class="row">

            <form class="mobile-number-form">

                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Current Country Code</mat-label>
                        <mat-select [(ngModel)]="currentCountryCode" name="current country code" [disabled]="true" >
                            <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.code">{{countryCode.code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-9">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Current Number</mat-label>
                        <input matInput [ngModel]="currentNumber" [disabled]="true" name="currentNumber">
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>New Country Code</mat-label>
                        <mat-select [(ngModel)]="newCountryCode" name="new-country-code" required>
                            <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.code">{{countryCode.code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>New Number</mat-label>
                        <input matInput [(ngModel)]="newNumber" (keypress)="omit_special_char($event)"  type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" placeholder="Enter Mobile Number" required name="new number">
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <button class="btn btn-primary otp-btn" [disabled]="!isValidMobileNumber()" (click)="sendOTP()">Send Code</button>
                </div>

                <div class="col-md-9">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Verification Code</mat-label>
                        <input matInput (keypress)="omit_special_char($event)" [disabled]="enableOTPfield" [(ngModel)]="otpNumber" pattern="[0-9]" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="6" placeholder="Enter Verification Code" name="otp" (ngModelChange)="otpChange($event)" name="Verification Code">
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <button class="btn btn-primary otp-btn" [disabled]="disableVerifyOtpBtn" (click)="verifyOTP()">Verify Code</button>
                </div>

                <div class="col-md-9">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Change Reason</mat-label>
                        <input matInput [(ngModel)]="reason" [disabled]="disableReasoneBtn" type="text" placeholder="Reason to change Number" (ngModelChange)="reasonChange($event)" name="reason">
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <button class="btn btn-primary otp-btn" [disabled]="disableUpdateBtn" (click)="updateNumber()">Update Number</button>
                </div>

            </form>
        </div>
    </div>     
</div>
