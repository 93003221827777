import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { TagComponent } from './tag.component';
import { EdittagComponent } from './edittag/edittag.component';
const routes: Routes = [
  { path: '', component: TagComponent, canActivate: [ContentGuardGuard] },
  { path: 'tag/edit/:id/:langid/:chid', component: EdittagComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TagRoutingModule { }
