<span class="slidetogglecss" (click)="togglenav()">
    <i [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">
        {{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}
    </i>
</span>
<div class="userlistnavcontent">
    <div class="row">
        <div class="col-md-12 submenubar">
            <h3 class="userlisttitle">HealthCoach Assignment</h3> 
            <a [routerLink]="['/admin/hchome']" routerLinkActive="active">Healthcoach</a>
            <a [routerLink]="['/admin/hcuserassign']" routerLinkActive="active">User Assignment</a>
            <a [routerLink]="['/admin/codegenerate']" routerLinkActive="active" *ngIf="!localStoredData?.organization_data?.scarlet_acl_restriction?.includes('activation')">Activation Code</a>
            <a [routerLink]="['/admin/campaign']" routerLinkActive="active" *ngIf="!localStoredData?.organization_data?.scarlet_acl_restriction?.includes('campaign')">Campaign</a>
           </div>
    </div>
</div>