<form [formGroup]="dataForm">
  <div class="row">

  <h2 *ngIf="appMasterData?.key == 'IOS_MIN_APP_VERSION'" class="col-md-12"> Update iOS Min App Version</h2>
  <h2 *ngIf="appMasterData?.key == 'IOS_CURRENT_APP_VERSION'" class="col-md-12"> Update iOS Current App Version</h2>
  <h2 *ngIf="appMasterData?.key == 'ANDROID_MIN_APP_VERSION'" class="col-md-12"> Update Android Min App Version</h2>
  <h2 *ngIf="appMasterData?.key == 'ANDROID_CURRENT_APP_VERSION'" class="col-md-12"> Update Android Current App Version</h2>

  <mat-form-field appearance="outline" class="col-md-12" *ngIf="app_version_data">
    <mat-label>Version number</mat-label>
    <input type="text" matInput placeholder="Version number" formControlName="version_data" required>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-md-12" *ngIf="email_data">
    <mat-label>Email</mat-label>
    <input type="text" matInput placeholder="Version number" formControlName="email" required>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-md-12" *ngIf="phonenumber_data">
    <mat-label>Phone Number</mat-label>
    <input type="text" matInput placeholder="Version number" formControlName="phone_number" required>
  </mat-form-field>

  <div class="col-md-12">
    <button mat-flat-button color="primary" class="update-btn" (click)="dataObject()"
      [disabled]="!dataForm.valid">Update
    </button>
  </div>
  </div>

</form>