import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from '../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.scss'],
  animations: [detailExpandTable]
})

export class MedicationComponent implements OnInit {
  displayedColumns: string[] = ["title","updatedat","action"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  activecls = 'active';
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  public tags: any;
  public filteredtabulardata: any;
  public typeFilters: any;
  public filteredtypes: any = [];
  public status: any = 'active';
  
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Medication');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.getLanguages();
    this.search();
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Medication has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  delete(id) {
    this.contentService.hardDelete('medication', id).pipe(catchError((error: any) => {
      this.showloader = false;
      this.systemService.showSnackBar(error.error && error.error_message ? error.error.error_message : 'Sorry something went wrong.', 'OK');
      return throwError(error);
    }),
      finalize(() => { this.showloader = false; }))
      .subscribe(data => {
        for (let i = 0; i < this.tabulardata.length; i++) {
          if (this.tabulardata[i].id === id) {
            this.tabulardata.splice(i, 1);
          }
        }
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      });
  }

  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  typeFilter(ab) {
    this.filteredtypes = ab;
    if (ab.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTypes);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTypes(t) {
      if (t.type.split(',').filter(typeItem => ab.includes(typeItem)).length > 0) {
        return true;
      }
    }
  }

    // ----------------------------

  // Active Deactive 
  // ----------------------------

  // isActive(status, id) {
  //   this.contentService.setStatus(status, 'medication', id)
  //     .subscribe(
  //       (res: any) => this.onStatusSuccess(status, id),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  // }

  // private onStatusSuccess(status, id) {
  //   this.tabulardata.forEach((item) => {
  //     if (item.id === id) {
  //       item.is_active = !status;
  //     }
  //   });
  //   this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  // }

  isActive(status, id) {
    let value;
    if(status){
      value = "Deactivate"
    }else{
      value = "Activate"
    }
    let header = "Are you sure you want to "+""+ value +" "+ "medication?";
    localStorage.setItem('modal_header',header);
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        localStorage.removeItem("modal_header");
        this.contentService.setStatus(status, 'medication', id)
        .subscribe(
          (res: any) => this.onChangeStatusSuccess(status, id,value),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected medication has been '+ value+'d', 'Ok', {
          duration: 2000,
        });
      }
    });
    
  }

  private onChangeStatusSuccess(status, id,value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------

  //  Medication List 
  // ----------------------------
  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getSearchData(this.searchcriteria, this.page.toString(), "20", this.status, "medication")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
        this.dataSource.sort = this.sort;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }


  // To get All Languages
  // ----------------------------

  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }



}
