
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import * as XLSX from 'xlsx';
import { ReplaySubject, Subject } from 'rxjs/index';
import { MatTableDataSource } from '@angular/material/table';
import { bool } from 'aws-sdk/clients/signer';
type AOA = any[][];

@Component({
  selector: 'app-patientonsite',
  templateUrl: './patientonsite.component.html',
  styleUrls: ['./patientonsite.component.scss']
})
export class PatientonsiteComponent implements OnInit {
  public patient_status: any;
  public allconditions: any;
  public emptycondition: boolean = false;
  public therapy_name: any;
  public searchmattag: FormControl = new FormControl();
  public searchmatcampaign: FormControl = new FormControl();
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCampaign: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,

  public dialogRef: MatDialogRef<PatientonsiteComponent>, private commonService: CommonserviceService, private systemService: SystemService,public fb: FormBuilder,
  private browserTitle: Title
  ) { }
  public showButtonLoader: any;
  public userView: any;
  public therapy_data: boolean = false;
  public conditions: any;
  public myhash = {};
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = [];
   public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  alltherapies:any;
  allcampaigns:any;
  image_path:any;
  uploadedparam:boolean = false;
  rawData:any;
  header: string[] = [];
  newHeader = [];
  rows:any;
  cols:any; 
  showSuccess:boolean = false;
  patient_data: [];
  public getExcelHeaders: any = [];
  public patient_list: any = [];
  public allHeaders: any = [];
  uploadeddata:any;
  successData:any;
  errorData:any;
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Patient Profile');
  }
  public dataForm = this.fb.group({
    rows: [''],
    cols: [''],  
  });
  public demoForm = this.fb.group({
    files: []
  })
  public dropdown = this.fb.group({
    is_checked: ['',Validators.required],
    campaign: ['', Validators.required]
  })
  public demoForm1 = this.fb.group({
    selectedHeader: ['']
  })
  
  

  ngOnInit() {
    this.dataForm.disable()
    this.setSEOTags();
    this.getTherapy();
    this.getCampaigns();
    this.getConditions();
    this.userView = this.data.element;
    
    this.patient_status = this.userView.status.replace(/_/g, " ").toLowerCase();
    if (this.userView.therapyId == null) {
      this.therapy_data = true;
    }
    else {
      // this.getTherapy(this.userView.therapyId);
    }
    if (this.userView.conditions == null) {
      this.emptycondition = true;
    }
    else {
      // this.getConditions();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  gender(data) {
    if (data == 'm') {
      return 'Male';
    }
    else if (data == 'f') {
      return 'Female';
    }
    else {
      return 'Others';
    }
  }

  getConditionName(allconditions) {
    allconditions = allconditions.map(i => this.myhash[i]);
    return allconditions;
  }
  modelChangedTags() {
    this.filterTags();
  }


  //  Get Therapy Data
  // ----------------------------

  getTherapy() {
    this.commonService.getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
    .subscribe(
      (res: any) => this.getTherapyData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getTherapyData(data) {
    data = (data as any).data;
    this.alltherapies = data.getAllTherapies;
  }

  // ----------------------------
  private filterTags() {
    if (!this.alltherapies) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTherapy.next(this.alltherapies.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.alltherapies.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  //  Get Patient Condition Data
  // ----------------------------

  getConditions() {
    this.commonService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getConditionsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsSuccess( data) {
    this.allconditions = data.getAllConditions;
  }

  // ----------------------------

  onFileChange() {
    // var file = this.demoForm.value.files
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(this.demoForm.value);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.rawData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));         
      
      // if(!(this.rawData[0].includes("Name"))){
      //   for (var input = 0; input <= this.rawData[0].length; input++) {
      //     this.newHeader.push('empty'+input);
      //    }
      //    this.data = XLSX.utils.sheet_to_json(ws, {header:this.newHeader});
        
      // }else{
        this.data = (XLSX.utils.sheet_to_json(ws, { raw: false,dateNF:'yyyy-mm-dd' }));
      // }
      
        var count=0;
        for(var prop in this.data[0]) {
           if (this.data[0].hasOwnProperty(prop)) {
              ++count;
           }
        }
         
         this.image_path = target.files[0].name;
         this.uploadedparam = true;
         this.rows = this.data.length;
         this.cols = count;
    };
    reader.readAsBinaryString(target.files[0]);
  }

   // To Get Campaign Data 
  // ----------------------------

  getCampaigns() {
    this.commonService
      .getGraphQlData('{getAllCampaigns(appMode: false, status:true){id,title,has_client}}')
      .subscribe(
        (res: any) => this.getCampaignData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCampaignData(data) {
    data = (data as any).data;
    this.allcampaigns = data.getAllCampaigns;
    this.filteredCampaign.next(this.allcampaigns.slice());
  }


  private filterCampaign() {
    if (!this.allcampaigns) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcampaign.value;
    if (!search) {
      this.filteredCampaign.next(this.allcampaigns.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredCampaign.next(
      this.allcampaigns.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  modelChangedCampaign() {
    this.filterCampaign();
  }

  getFile(){
    this.showloader = true;
    this.header = Object.keys(this.data[0]);
    this.displayedColumns = this.header; 
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.showloader = false;
  }

  getSelectedHeaders(selectedname,excel_header){
    // this.mappingHeaders = this.mappingHeaders.filter((item) => item.name !== selectedname)
    if(selectedname == 'Patient_Partner_ID'){
      // filter the modules
    this.filteredCampaign.next(
      this.allcampaigns.filter(a => a.has_client == true)
    );
    }
    var obj = {};
    if(this.getExcelHeaders.length){
      var newArr = []
      var key;
      this.getExcelHeaders.forEach(function (arrayItem) {
        key =  Object.keys(arrayItem).find(key => arrayItem[key] === excel_header);
        
  });
  if(key){
    this.updateValue(key,selectedname)
  }else{
    obj[selectedname] = excel_header; 
    this.getExcelHeaders.push(obj) 
  }   
    }else{
      obj[selectedname] = excel_header; 
      this.getExcelHeaders.push(obj) 
    }
  }

  updateValue(key,selectedname){
    var key = key;
    var selectedname = selectedname;
//Log object to Console.

//Update object's name property.
this.getExcelHeaders = this.getExcelHeaders.map(({
  key: selectedname,
  ...rest
}) => ({
  selectedname,
  ...rest
}));


//Log object to console again.

  }

  mappingHeaders = [
    {
      id:'0',
      name:'name',
      class: "bold"
    },
    {
      id:'1',
      name:'phone',
      class: "bold"
    },
    {
      id:'2',
      name:'country_code',
      class: "bold"
    },
    {
      id:'3',
      name:'age'
    },
    {
      id:'4',
      name:'dob',
      class: "bold"
    },
    {
      id:'5',
      name:'height',
      class: "bold"
    },
    {
      id:'6',
      name:'weight',
      class: "bold"
    },
    {
      id:'7',
      name:'gender',
      class: "bold"
    },
    {
      id:'8',
      name:'height_units',
      class: "bold"
    },
    {
      id:'9',
      name:'weight_units',
      class: "bold"
    },
    {
      id:'10',
      name:'Patient_Partner_ID'
    },
    {
      id:'11',
      name:'email'
    },
    {
      id:'12',
      name:'conditions'
    }
   
  ]


  getPatientlist(){
   this.allHeaders = this.getExcelHeaders.reduce(((r, c) => Object.assign(r, c)), {});

    this.patient_list = this.data.map(el => ({
      phone: (el[this.allHeaders.phone]).toString(),
      country_code: el[this.allHeaders.country_code],
      age: el[this.allHeaders.age],
      name: el[this.allHeaders.name],
      dob: el[this.allHeaders.dob],
      height_unit: el[this.allHeaders.height_units],
      height: el[this.allHeaders.height],
      weight_unit: el[this.allHeaders.weight_units],
      weight: el[this.allHeaders.weight],
      gender: el[this.allHeaders.gender],
      patient_partner_id: el[this.allHeaders.Patient_Partner_ID],
      email: el[this.allHeaders.email],
      // conditions: el[this.allHeaders.conditions]
      conditions: []
    }));
  }



  uploadPatients(){
    var postData = {}
    this.getPatientlist();
    postData['campaign_id_fk'] = this.dropdown.value.campaign;
    postData['uploaded_by_fk'] = localStorage.getItem('scarletuserid');
    postData['file_name'] = this.demoForm.value.files[0].name.toString();
    // postData['file_path_fk'] = "";
    postData['patient_list'] = JSON.stringify(this.patient_list); 
    this.commonService.onsiteEnroll(postData)
      .subscribe(
        (res: any) => this.onatientsuploadSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onatientsuploadSuccess(data) {
    // this.systemService.showSuccessSnackBar(("patients has been uploaded"), "OK");
    this.uploadeddata = data.data;
    this.successData = this.uploadeddata.filter(a => a.status == 'success');
    this.errorData = this.uploadeddata.filter(a => a.status == 'error');
    this.showSuccess = true;
  }


  exportToExcel($event) {
	  const fileName = 'error_list.xlsx';
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.errorData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'error_list');

		XLSX.writeFile(wb, fileName);
  }


}


