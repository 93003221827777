import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { ConditionComponent } from './condition/condition.component';
import { EditConditionComponent } from './condition/edit-condition/edit-condition.component';
const routes: Routes = [
  { path: '', component: ConditionComponent, canActivate: [ContentGuardGuard] },
  { path: 'conditionlist/edit/:id', component: EditConditionComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConditionRoutingModule { }
