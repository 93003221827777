import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { EdithealthgoalComponent } from './edithealthgoal/edithealthgoal.component';
import { HealthgoalComponent } from './healthgoal.component';
const routes: Routes = [
  { path: '', component: HealthgoalComponent, canActivate: [ContentGuardGuard] },
  { path: 'healthgoal/edit/:id/:langid/:chid', component: EdithealthgoalComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthgoalRoutingModule { }
