import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../../shared/guards';
import { ActivationComponent } from './activation.component';
import { CreatecodegenComponent } from './createcodegen/createcodegen.component';
import { EdithclistComponent } from './edithclist/edithclist.component';
import { ViewallactivationComponent } from './viewallactivation/viewallactivation.component';
import { GeneratecodebyrequestComponent } from './generatecodebyrequest/generatecodebyrequest.component';

const routes: Routes = [
  { path: '', component: ActivationComponent, canActivate: [AdminGuardGuard] },
  //{ path: 'codegenerate', component: ActivationComponent, canActivate: [AdminGuardGuard] },
  { path: 'createcodegen/edit/:id', component: CreatecodegenComponent, canActivate: [AdminGuardGuard] },
  { path: 'viewallactivation/:id', component: ViewallactivationComponent, canActivate: [AdminGuardGuard] },
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivationRoutingModule {}