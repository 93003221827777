import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';

@Component({
  selector: 'app-genericdeeplink',
  templateUrl: './genericdeeplink.component.html',
  styleUrls: ['./genericdeeplink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDeeplinkComponent implements OnInit {
  public allCampaigns: any;
  public alldata: any;
  public alllanguages: any;
  public showdeeplink: boolean = false;
  public dataForm = this.fb.group({
    condition: ["", Validators.required],
    language_data_fk: ["",Validators.required]
  });
  constructor(private contentService: ContentserviceService,private browserTitle: Title, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private cd: ChangeDetectorRef, private systemService: SystemService,
    private _bottomSheetRef: MatBottomSheetRef<GenericDeeplinkComponent>,
    public fb: FormBuilder,
    private doctorService: DoctorDataService) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Doctor');
  }
  ngOnInit() {
    this.setSEOTags();
    this.getCampaignData();
    this.getAllLanguages();
  }
  getCampaignData() {
    this.doctorService.getAllCampaign()
      .subscribe(data => {
        this.allCampaigns = (data as any).data;
      })
  }
  dataObject() {
    var postpredata = this.dataForm.value;
    this.doctorService.deeplinkAndQr(postpredata, 'deeplink')
      .subscribe(
        (res: any) => this.onSuccessdeeplinkData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );

  }
  onSuccessdeeplinkData(data) {
    var scope = this;
    if ((data as any).status == 200) {
      setInterval(() => {
        scope.alldata = (data as any).data;
        scope.showdeeplink = true;
        scope.cd.markForCheck();
      }, 1000);
    }
    else {
      this.systemService.showSnackBar((data as any).message, "OK");
      this._bottomSheetRef.dismiss();
    }
  }

  copyURL() {
    var abc = this.alldata.deeplink.shortLink;
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = abc;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }

  getAllLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
  }

}
