import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-faqleftnav',
  templateUrl: './faqleftnav.component.html',
  styleUrls: ['./faqleftnav.component.scss']
})
export class FaqleftnavComponent implements OnInit {

  showingnav: boolean = true;
  constructor() {
  }

  ngOnInit() {
  }
  togglenav() {
    if (this.showingnav) {
      $(".userlistnavcontent").css('width', '0px');
      $(".mainrightcontainersecondary").css('marginLeft', '0px');
      $(".slidetogglecss").css('marginLeft', '-15px');

    }
    else {
      $(".userlistnavcontent").css('width', '200px');
      $(".mainrightcontainersecondary").css('marginLeft', '210px');
      $(".slidetogglecss").css('marginLeft', '185px');
    }
    this.showingnav = !this.showingnav;
  }

}
