import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-showmodal',
  templateUrl: './showmodal.component.html',
  styleUrls: ['./showmodal.component.scss']
})
export class ShowmodalComponent implements OnInit {

  dataurl: any;
  type: any;
  documentPlaceHolder = '/assets/img/pdficon.jpg';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataurl = this.data.dataurl;
    this.type = this.data.type;
  }
}
