import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PolicyRoutingModule } from './policy-routing.module';
import { PolicyComponent } from './policy.component';

import { SharedModule } from './../../shared/shared.module';
import { HcleftnavModule } from '../shared/hcleftnav/hcleftnav.module';
import { PolicyMobileNumberVerificationModalComponent } from './policy-mobile-number-verification-modal/policy-mobile-number-verification-modal.component';
import { PolicyIsDeletedFilterPipe } from './policy-is-deleted-filter.pipe';

@NgModule({
  declarations: [
    PolicyComponent, 
    PolicyMobileNumberVerificationModalComponent, 
    PolicyIsDeletedFilterPipe
  ],
  imports: [
    SharedModule,
    HcleftnavModule,
    CommonModule,
    PolicyRoutingModule
  ]
})
export class PolicyModule { }
