import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-generalqrbottomsheet',
  templateUrl: './generalqrbottomsheet.component.html',
  styleUrls: ['./generalqrbottomsheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralqrbottomsheetComponent implements OnInit {
  public allCampaigns: any;
  public alldata: any;
  public showqrlink: boolean = false;
  public imgurl: any;
  public campaignid: any;
  constructor(private systemService: SystemService,private cd: ChangeDetectorRef, private _bottomSheetRef: MatBottomSheetRef<GeneralqrbottomsheetComponent>, public fb: FormBuilder, private doctorService: DoctorDataService) { }
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  ngOnInit() {
    this.getCampaignData();
  }
  getCampaignData() {
    this.doctorService.getAllCampaign()
      
      .subscribe(
        (res: any) => this.onSuccessCampaignData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  onSuccessCampaignData(data){
    var scope = this;
    setInterval(() => {
      scope.allCampaigns = (data as any).data;
      scope.cd.markForCheck();
    }, 100);
  }
  
  dataObject(campaignid) {
    var scope = this;
    setInterval(() => {
      this.campaignid = campaignid;
      var dataset = '\"campaign_id\":\"' + campaignid + '\"' + encodeURIComponent('&') + '\"user_code\":\"' + JSON.parse(localStorage.getItem('scarletuserdata')).code + '\"';
      this.imgurl = "https://chart.googleapis.com/chart?cht=qr&chl=campaign_id:" + campaignid + "&chs=232x232&choe=UTF-8&chld=H%7C2";
      scope.showqrlink = true;
      scope.cd.markForCheck();
    }, 1000);
  }

  downloadQR(data) {
    var canvas: HTMLCanvasElement = this.canvas.nativeElement;
    var context = canvas.getContext('2d');
    let pdfName = data;
    let scope = this;
    setTimeout(() => {
      let img1 = new Image();
      let img2 = new Image();
      let img3 = new Image();
      img1.crossOrigin = "Anonymous";
      img1.onload = function () {
        // canvas.width = img1.width;
        // canvas.height = img1.height;
        var cW = 300;
        var cH = 300;
        canvas.width = cW;
        canvas.height = cH
        var dataset = '\"campaign_id\":\"' + data + '\"' + encodeURIComponent('&') + '\"user_code\":\"' + JSON.parse(localStorage.getItem('scarletuserdata')).code + '\"';
        img2.src = "https://chart.googleapis.com/chart?cht=qr&chl=campaign_id:" + data + "&chs=545x545&choe=UTF-8&chld=H%7C2";
        img2.crossOrigin = "Anonymous";
      };
      img2.onload = function () {
        img3.src = "assets/img/Wellthy_QR_Logo_2.png";
        img3.crossOrigin = "Anonymous";
        img3.onload = function () {
          // context.drawImage(img1, 0, 0, 964, 1692);
          // context.drawImage(img2, 188, 596, 595, 595);
          // context.drawImage(img3, 417, 827, 130, 130);
          context.drawImage(img2, 0, 0, 300, 300);
          context.drawImage(img3, 120, 120, 65, 65);
          let fileName = pdfName + "_" + JSON.parse(localStorage.getItem('scarletuserdata')).name + "_" + ".png";
          scope.download(canvas, fileName);
        }
      };

      img1.src = 'assets/img/Doctor_Stand.png';
    }, 2000);
  }

  download(canvas, filename) {
    var lnk = document.createElement('a'), e;
    lnk.download = filename;
    lnk.href = canvas.toDataURL("image/png;base64");
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent("click", true, true, window,
        0, 0, 0, 0, 0, false, false, false,
        false, 0, null);
      lnk.dispatchEvent(e);
    } else {
      lnk.click();
    }
  }
}

