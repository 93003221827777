import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { UserListService } from '../../services/userlist/user-list.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import moment = require('moment');
import { formatDate } from '@angular/common';
import { ContextService } from 'src/app/healthcoach/services/context.service';

declare var $: any;

@Component({
  selector: 'app-docuserlistnavbar',
  templateUrl: './docuserlistnavbar.component.html',
  styleUrls: ['./docuserlistnavbar.component.scss']
})

export class DocuserlistnavbarComponent implements OnInit {
  public rawdata: any;
  public errors: any;
  public displayedColumns = [];
  public dataSource: any;
  public programData: any;
  public needsFollowUpUsers: any;
  public userprofiledata: any;
  public userID: string;
  public diffDays: any;
  public userSummary: any;
  public diarydata: any;
  public userdiarydata: any;
  public selectedRowIndex: any;
  public togglebtnval: any = 'all';
  private status: any = 'all';
  showingnav: boolean = true;
  allPrograms: any;
  specialization: any;
  userdata: any;
  doctorProgram: any;
  getprogdata: any;
  isnouser: boolean = false;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public showloader: any;
  public emptyplaceholder: boolean = false;
  arrHopitalList: string;

  constructor(
    private UserListService: UserListService,
    private translate: TranslateService,
    private authService: AuthenticateService,
    private sys: SystemService,
    private doctorService: DoctorDataService,
    private userService: UserListService,
    private router: Router,
    private appContext: ContextService,
  ) { }

  async ngOnInit() {
    this.searchcriteria = "";
    this.page = 1;
    this.search();
    this.displayedColumns = ['name'];
    this.arrHopitalList = JSON.parse(localStorage.getItem('hopital_data'));
    this.selectedRowIndex = window.location.href.split('/')[
      window.location.href.split('/').length - 1
    ];
    this.UserListService.cSubject.subscribe(value => {
      if (this.rawdata && value) {
        for (let k = 0; k < this.rawdata.length; k++) {
          if (this.rawdata[k].id === value.userId) {
            this.rawdata[k].needs_followup = value.followup;
          }
        }
        this.dataSource = new MatTableDataSource<Element>(this.rawdata);
        if (this.togglebtnval === 'needsfollowup') {
          this.needsfollowup();
        }
      }

    });

  }
  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /**
   * @name getPrograms
   * @description Gets doctor's specialization
   * @param No_Param
   * @returns NULL
   */
  // getPrograms() {
  //   this.doctorService.getAllProgram().subscribe(
  //     data => {
  //       this.getprogdata = data;
  //       this.allPrograms = this.getprogdata.programs;
  //       this.allPrograms = this.allPrograms.filter(
  //         a => a.is_master_program && a.is_active
  //       );
  //       this.userdata = JSON.parse(localStorage.getItem('scarletuserdata'));
  //       this.specialization = this.userdata.doctor.specialization;
  //       for (let i = 0; i < this.allPrograms.length; i++) {
  //         if (this.allPrograms[i].id === this.specialization) {
  //           this.doctorProgram = this.allPrograms[i].program_type;
  //           if (this.doctorProgram === 'Chronic Kidney Disease') {
  //             this.doctorProgram = 'CKD';
  //           }
  //           if (this.doctorProgram === 'Coronary Artery Disease') {
  //             this.doctorProgram = 'CAD';
  //           }
  //           if (this.doctorProgram === 'Congestive Heart Failure') {
  //             this.doctorProgram = 'CHF';
  //           }
  //           if (this.doctorProgram === 'Diabetes Insulin') {
  //             this.doctorProgram = 'Diabetes';
  //           }
  //           if (this.doctorProgram === 'Diabetes Oral') {
  //             this.doctorProgram = 'Diabetes';
  //           }
  //         }
  //       }
  //     },
  //     error => {
  //       this.sys.handleErrorResponse(error);
  //       if (error.status === 666) {
  //         this.authService.logout()
  //         .pipe(
  //           catchError((error: any) => {

  //             this.sys.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
  //             return throwError(error);
  //           }),
  //           finalize(() => {

  //           })
  //         )
  //         .subscribe(data => {
  //           this.sys.showSuccessSnackBar(("Logout Successfully"), "OK");
  //           window.location.reload();
  //         });
  //       }
  //     }
  //   );
  // }

  /**
   * @name togglenav
   * @description Toggling button for userlistnavbar
   * @param No_Param
   * @returns NULL
   */
  togglenav() {
    if (this.showingnav) {
      $('.userlistnav').css('width', '0px');
      $('.userlistnav').css('paddingLeft', '0px');
      $('.userlistnav').css('paddingRight', '0px');
      $('.mainrightcontainersecondary').css('marginLeft', '0px');
      $('.slidetogglecss').css('marginLeft', '-15px');
      $('.docuserdetails').css('width', 'calc(100% - 2px)');
      // $('.followbtn').css('margin-left', '130px');
      $('.downloadbtn').css('margin-left', '60px');
    } else {
      $('.userlistnav').css('width', '320px');
      $('.userlistnav').css('paddingLeft', '15px');
      $('.userlistnav').css('paddingRight', '15px');
      $('.mainrightcontainersecondary').css('marginLeft', '320px');
      $('.slidetogglecss').css('marginLeft', '305px');
      $('.docuserdetails').css('width', 'calc(100% - 320px');
      // $('.followbtn').css('margin-left', '30px');
      $('.downloadbtn').css('margin-left', '0px');
    }
    this.showingnav = !this.showingnav;
  }

  /**
   * @name getUsers
   * @description Gets patients data like name, age, phone number and gender
   * @param No_Param
   * @returns NULL
   */


  // getUsers() {
  //   this.query = "";
  //   this.status = "all";
  //   this.page = "1";
  //   this.limit = "10";
  //   this.doctorService.getPatientsByDoctor(this.query, this.status, this.page, this.limit).subscribe(
  //     data => {
  //       this.rawdata = data;
  //       localStorage.setItem('patientlistdata', this.rawdata);
  //       this.displayedColumns = ['name'];
  //       this.dataSource = new MatTableDataSource<Element>(this.rawdata);
  //       this.generalusers();
  //     },
  //     error => {
  //       if (error.status === 666) {
  //         this.authService.logout()
  //         .pipe(
  //           catchError((error: any) => {

  //             this.sys.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
  //             return throwError(error);
  //           }),
  //           finalize(() => {

  //           })
  //         )
  //         .subscribe(data => {
  //           this.sys.showSuccessSnackBar(("Logout Successfully"), "OK");
  //           window.location.reload();
  //         });
  //       }
  //     }
  //   );
  // }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.doctorService.getPatientsByDoctor(this.searchcriteria, this.status, this.page.toString(), "20")
        .subscribe(
          (res: any) => this.onSuccesstableData(res),
          (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
        );
    }
  }

  async onSuccesstableData(data) {
    if (data.data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.isnouser = false;
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data.data;
      }
      else {
        for (var k = 0; k < data.data.length; k++) {
          this.tabulardata.push(data.data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);

    let notificationClickPatientName = localStorage.getItem("notifyPatientName");
    let selectedNotification = localStorage.getItem("selectedNotification");
    let patient_id = localStorage.getItem("patient_id");
    if (notificationClickPatientName || selectedNotification) {
      for (let i in this.tabulardata) {
        if (this.tabulardata[i].patient_id === JSON.parse(patient_id)) {
          this.applyFilter(notificationClickPatientName)
          this.highlight(this.tabulardata[i])

        }
      }
      await this.sleep(2000)
    }
  }


  /**
   * @name applyFilter
   * @description Search filter for userlist table
   * @param No_Param
   * @returns NULL
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.highlight();
  }

  getAge(dateString) {
    return this.userService.getAge(dateString);
  }
  /**
   * @name generalusers
   * @description Setting the dataSource for all patients
   * @param No_Param
   * @returns NULL
   */
  generalusers() {
    this.status = "all";
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
    setTimeout(() => {
      this.getFolloupData()
    }, 500);
  }

  getFolloupData() {
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.tabulardata = this.tabulardata.filter(a => a.doing_follow_up == true);
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  /**
   * @name needsfollowup
   * @description Setting the dataSource for patients who needs followup
   * @param No_Param
   * @returns NULL
   */
  needsfollowup() {
    this.status = "inactive";
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();

    setTimeout(() => {
      this.getneedFolloupData()
    }, 500);

  }

  getneedFolloupData() {
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.tabulardata = this.tabulardata.filter(a => a.doing_follow_up == false);
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  /**
   * @name highlight
   * @description To highlight the selected row in the userlist table
   * @param No_Param
   * @returns NULL
   */
  highlight(row) {
    // localStorage.setItem('patientfollowup', row.doing_follow_up);
    let data = {
      "d": [
        {
          "identity": localStorage.getItem('scarletuserid'),
          "ts": (Math.floor(new Date().valueOf() / 1000)),
          "profileData": {
            "Name": JSON.parse(localStorage.getItem('scarletuserdata')).name,
            "Email": JSON.parse(localStorage.getItem('scarletuserdata')).email,
          },
          "type": "event",
          "evtName": "Patient Clicked",
          "evtData": {
            "Patient name": row.name,
            "Patient ID": row.id
          }
        }
      ]
    }
    this.appContext.changeWatcher.next(true);
    this.appContext.selectedChatUser.next(row.user_id);


    this.authService
      .sendEventData(data)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
      })
    this.router.navigate(['/doctor/user/' + row.patient_id, row.doing_follow_up]);
    this.selectedRowIndex = row.id;
    
  }
  getDatabyHospital(id: any) {
    this.onit = true;
    this.doctorService.getPatientListbyHospitalId(id, this.page)
      .subscribe(
        (res: any) => this.onSuccesstableData(res),
        (err: HttpErrorResponse) => this.sys.handleErrorResponse(err)
      );
  }

}
