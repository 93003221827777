import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';

import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { Userdetailsinterface } from 'src/app/healthcoach/models/userdetailsinterface';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { ActivatedRoute } from '@angular/router';
import { DoctorDataService } from '../../services/doctor/doctor.service';

@Component({
  selector: 'doc-knowuser',
  templateUrl: './doctornotes.component.html',
  styleUrls: ['./doctornotes.component.scss']
})

export class DoctornotesComponent implements OnInit {
  public knowUserSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public knowUserCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public tabID: number = 1;
  public knowUserForm: FormGroup;
  public selectedChatUserID: string;
  public subscribeObj: any;
  public noteType: string = "healthcoach";
  public noteText: string = "self";
  public pagecount: number = 1;
  public showloadmore: boolean = false;
  healthcoachID: any;
  knowUserData: any;
  public rawdata: any;
  public isArchiveActive: boolean = false;

  @ViewChild("scrollToBottom", { static: false }) private scrollToBottomContainer: ElementRef;
  @ViewChild("form", { static: false }) tsForm;
  selectedUserID: any;

  constructor(private appContext: ContextService, private userDetailService: UserdetailsService,
    private formBuilder: FormBuilder, private systemService: SystemService, public translate: TranslateService,
    private dialog: MatDialog,  private route: ActivatedRoute,private doctorService:DoctorDataService) { }

  ngOnInit() {
    this.healthcoachID = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.route.params.subscribe(params => {
      this.selectedChatUserID = params.id;
    });
    this.buildKnowUserForm();
    this.knowUserData = [];
    this.pagecount = 1;
    this.showloadmore = false;
    this.initComponentDraw(this.selectedChatUserID);

    let notificationStoredValue = localStorage.getItem("selectedNotification");
    if (notificationStoredValue == 'patient_note_event') {
      this.selectedChatUserID = this.appContext.selectedChatUser.getValue();
      this.initComponentDraw(this.selectedChatUserID);
    }


  }

  buildKnowUserForm(): void {
    this.knowUserForm = this.formBuilder.group({
      note: ["", Validators.required],
      type: ["healthcoach"]
    });
  }

  setNoteType(noteType: string): void {
    this.noteType = noteType;
    if (noteType == "doctor") {
      this.noteText = "doctor";
    } else if (noteType == "healthcoach") {
      this.noteText = "self";
    }
  }

  getNextSet() {
    this.pagecount++;
    this.initComponentDraw(this.selectedChatUserID)
  }

  initComponentDraw(id) {

    this.doctorService.getPatientHCNotes(id).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      this.showloadmore = (data.length >= 20) ? true : false;

      if (this.pagecount == 1) {
        this.knowUserData = data
      }
      else {
        (data as any).forEach(el => {
          this.knowUserData.push(el)
        });
      }

      this.sortData();

      let scope = this;
      setTimeout(function () {
        scope.scrollToBottom();
      }, 200);

    });
  }

  sortData() {
    return this.knowUserData.sort((a, b) => {
      return <any>new Date(a.created_at) - <any>new Date(b.created_at);
    });
  }

  sortByKey(arrp, keyp) {
    return arrp.sort(function (a, b) {
      var x = a[keyp];
      var y = b[keyp];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  convertIsoDate(d) {
    var date = new Date(d);
    return date.toLocaleString();
  }

  scrollToBottom(): void {
    try {
      this.scrollToBottomContainer.nativeElement.scrollIntoView(false);//scrollTop = this.scrollToBottomContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  ngOnDestroy() {
    this.subscribeObj.unsubscribe();
    // this.knowUserSubject$.unsubscribe();
    // this.loadingError$.unsubscribe();
    // this.loadingSubject$.unsubscribe();
  }


}
