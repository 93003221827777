<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb *ngIf="!newitem" route="content/medicationhome/medicationedit"></app-breadcrumb>
        <app-breadcrumb *ngIf="newitem" route="content/medicationhome/medicationCreate"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Medication
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield ">
                                    <mat-label>Medication Type</mat-label>
                                    <mat-select formControlName="medication_type" #singleSelect required [disabled]="!showCat">
                                        <mat-option *ngFor="let obj of medType" [value]="obj">
                                            {{obj}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="Dose-Range" *ngIf="showCat">
                                    Note: Type cannot be changed later
                                </div>
                              
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Medication Unit</mat-label>
                                    <input matInput placeholder="Enter Medication Unit"
                                        formControlName="medication_unit" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield ">
                                    <mat-label>Route of Administration</mat-label>
                                    <mat-select formControlName="route_of_administration" multiple #singleSelect required>
                                        <mat-option *ngFor="let obj of RouteofAdmin" [value]="obj.id">
                                            {{obj.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <div formArrayName="drug_concentration"
                                    *ngFor="let pl of formData.controls; let i = index;">
                                    <div [formGroupName]="i" class="gray-bg">
                                        <a class="pull-right removecircle" href="javascript:;"
                                            (click)="removeBrodcast(i)"><i *ngIf="i > 0" tooltip="Remove this row"
                                                class="material-icons">remove_circle</i></a>
                                        <mat-form-field appearance="outline" class="full-width-formfield">
                                            <mat-label>Drug Concentration</mat-label>
                                            <input matInput placeholder="Enter Drug Concentration" type="text"
                                                formControlName="name" required>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button mat-icon-button class="pluscenterbtn" type="button" (click)="addMoreBrodcast()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>

                            <div class="clearfix"><br /></div>

                        </div>
                    </div>
                    <div class="col-md-12">
                        <button mat-flat-button color="primary" class="centerbtn btn-left"
                            [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Medication</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>