import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../../shared/shared.module';
import { MediainformationComponent } from './../../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../../shared/deletemodal/deletemodal.component';
import { CreatequestionnairComponent } from './createquestionnair.component';
import { CreatequestionnairRoutingModule } from '../createquestionnair/createquestionnair.routing.module';
import { ContentleftnavModule } from '../../shared/contentleftnav/contentleftnav.module';
import { ContentnavbarModule } from '../../shared/contentnavbar/contentnavbar.module';
import { TherapysetupleftnavModule } from '../../shared/therapysetupleftnav/therapysetupleftnav.module';
import { FaqleftnavModule } from '../../shared/faqleftnav/faqleftnav.module';


@NgModule({
  declarations: [

    CreatequestionnairComponent,
  ],
  imports: [
    CommonModule,
    CreatequestionnairRoutingModule,
    ContentleftnavModule,
    ContentnavbarModule,
    TherapysetupleftnavModule,
    FaqleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CreatequestionnairModule { }
