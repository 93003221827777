import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DoctorGuardGuard } from './../../../shared/guards';
import { AbbottreportComponent } from './abbottreport/abbottreport.component';
import { DoctorpdfreportComponent } from './doctorpdfreport.component';
import { FerrerreportComponent } from './ferrerreport/ferrerreport.component';

const routes: Routes = [
   { path: '', component: DoctorpdfreportComponent, canActivate: [DoctorGuardGuard] },
   { path: 'doctorpdf/:id/:startdate/:enddate/ferrer', component: FerrerreportComponent, canActivate: [DoctorGuardGuard] },
   { path: 'doctorpdf/:id/:startdate/:enddate/abbott', component: AbbottreportComponent, canActivate: [DoctorGuardGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoctorpdfreportRoutingModule { }