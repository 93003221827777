import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SHORT_MONTHS } from 'src/app/shared/data';

@Component({
  selector: 'app-notes-log',
  templateUrl: './notes-log.component.html',
  styleUrls: ['./notes-log.component.scss']
})
export class NotesLogComponent implements OnInit {

  dataSource = [];
  displayedColumns = ["oldContent", "newContent", "changeDate"];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialogRef: MatDialogRef<NotesLogComponent>,
  ) { }

  ngOnInit(): void {
    this.dataSource = this.data;
  }

  close(){
    this.dialogRef.close();
  }

  convertDate(createdAt) {
    let d = new Date(createdAt);
    let month = SHORT_MONTHS[d.getMonth()];
    let currentdate = d.getDate()
    let year = d.getFullYear()
    
    return currentdate + ' ' + month + ' ' + year + ',' + d.toLocaleTimeString();;

  }
}
