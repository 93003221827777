import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { MediamanagerComponent } from './mediamanager.component';
import { ViewmediaComponent } from './viewmedia/viewmedia.component';
import { ChoosemediamodalComponent } from './choosemediamodal/choosemediamodal.component';
import { UploadmediaComponent } from './uploadmedia/uploadmedia.component';
import { MediainformationComponent } from './uploadmedia/mediainformation/mediainformation.component';
import { NotificationUploadMediaComponent } from './notification-upload-media/notification-upload-media.component';


const routes: Routes = [
  { path: '', component: MediamanagerComponent, canActivate: [ContentGuardGuard] },
  { path: 'media/upload', component: UploadmediaComponent, canActivate: [ContentGuardGuard] },
  { path: 'media/view', component: ViewmediaComponent, canActivate: [ContentGuardGuard] },
  { path: 'media/view', component: ViewmediaComponent, canActivate: [ContentGuardGuard] },
  { path: 'notification', component: NotificationUploadMediaComponent, canActivate: [ContentGuardGuard] },


    
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MediamanagerRoutingModule { }
