<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="admin/tasklibrary"></app-breadcrumb>
        <div class="main-wrapper">
            <!-- 
            <input type="text" ngxDaterangepickerMd [(ngModel)]="selected" />
            <div>Selected : {{selected | json}}</div> -->
            <!-- <span tooltip="Tooltip" placement="top" show-delay="500">Tooltip on top</span> -->
            <div class="row">
                <div class="filter-search mt-10 mb-40">
                    <div class="col-md-12">
                        <button mat-button class="btn create-btn btn-medium btn-filled" (click)="createTask()">
                            Create task
                        </button>
                    </div>
                    <div class="filter-search">
                        <div class="col-md-2">
                            <button *ngIf="therapyDataFilter.length==0 && clientDataFilter.length==0 && ownerDataFilter.length==0 && priorityDataFilter.length==0 && expiryDataFilter.length==0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown" >Filter</button>
                            <button *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0" mat-button [matMenuTriggerFor]="animals" class="scar-dropdown1">Filter</button>
                            <mat-menu #animals="matMenu" class="multi-menu-list">
                                <button mat-menu-item [matMenuTriggerFor]="therapy">Select therapy</button>
                                <button mat-menu-item [matMenuTriggerFor]="client">Select client</button>
                                <button mat-menu-item [matMenuTriggerFor]="owner">Select owner</button>
                                <button mat-menu-item [matMenuTriggerFor]="priority">Select priority</button>
                                <button mat-menu-item [matMenuTriggerFor]="expiry">Select expiry</button>
                            </mat-menu>

                            <mat-menu #therapy="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="chosenTherapy">
                                    <mat-radio-button *ngFor="let therapy of therapyData" value="therapy.id"
                                        (change)="selectedTherapy(therapy.id,therapy.title)">
                                        {{therapy.title}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>

                            <mat-menu #client="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="chosenClient">
                                    <mat-radio-button *ngFor="let client of clientData" value="client.id"
                                        (change)="selectedClient(client.id,client.title)">
                                        {{client.title}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                            <mat-menu #owner="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="chosenOwner">
                                    <mat-radio-button *ngFor="let owner of ownerList" value="id"
                                        (change)="selectedOwner(owner.id,owner.name)">
                                        {{owner.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>

                            <mat-menu #priority="matMenu" class="custom-menu-filter">

                                <div class="outerlayout">
                                    <div (click)="$event.stopPropagation();">
                                        <h5 class="scar-dropdown-label">Choose from here</h5>
                                        <mat-checkbox [(ngModel)]="highPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox>High</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="mediumPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox1>Medium</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="lowPriority" [ngModelOptions]="{standalone: true}"
                                            #myCheckBox2>Low</mat-checkbox>
                                        <button mat-button class="btn btn-filled"
                                            (click)="selectedPriority()">Done</button>
                                        <button mat-button class="btn btn-outline"
                                            (click)="clear(myCheckBox,myCheckBox1,myCheckBox2)">Clear</button>
                                    </div>
                                </div>

                            </mat-menu>
                            <mat-menu #expiry="matMenu">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="Chosendate">
                                    <mat-radio-button value="Today" (change)="tagfilter($event.value)">
                                        Today
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="Chosendate">
                                    <mat-radio-button value="Thisweek" (change)="tagfilter($event.value)">
                                        This week
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="scar-radio-group"
                                    [(ngModel)]="Chosendate">

                                    <mat-radio-button value="customDate" (click)="$event.stopPropagation();"
                                        style="overflow: scroll;" (click)="openCalendar($event)">
                                        Custom date
                                    </mat-radio-button>
                                    <input type="text" matInput ngxDaterangepickerMd style="display: none;"
                                        [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" startKey="startDate"
                                        endKey="endDate" [(ngModel)]="selected" name="daterange"
                                        (change)='selectDateRange()' placeholder="choose date" />
                                </mat-radio-group>
                            </mat-menu>


                        </div>
                        <div class="col-md-10">
                            <form>
                                <div class="form-group has-feedback has-search">
                                    <input class="form-control" [(ngModel)]="searchcriteria"
                                        [ngModelOptions]="{standalone: true}" placeholder="Search">
                                    <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                                        <mat-icon matSuffix>search</mat-icon>
                                    </button>
                                    <button class="clear-search" mat-button *ngIf="searchcriteria" mat-icon-button
                                        aria-label="Clear" (click)="cancelSearch()">
                                        <mat-icon matSuffix>close</mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>




                    <mat-chip-list
                        *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0">
                        <br />
                        <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of therapyDataFilter" (removed)="remove(data)">
                            {{data.therapyName}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of clientDataFilter" (removed)="remove1(data)">
                            {{data.clientName}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of ownerDataFilter" (removed)="remove2(data)">
                            {{data.ownerName}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of priorityDataFilter" (removed)="remove3(data)">
                            {{data.title}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="notranslate" selectable="true" removable="true"
                            *ngFor="let data of expiryDataFilter" (removed)="remove4(data)">
                            {{data.title}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip>
                            <span style="border-color: #EE6505 ;color: #EE6505 ;background-color:white"
                                *ngIf="therapyDataFilter.length!=0 || clientDataFilter.length!=0 || ownerDataFilter.length!=0 || priorityDataFilter.length!=0 || expiryDataFilter.length!=0"
                                (click)="clearChips()">
                                clear all
                            </span>
                        </mat-chip>
                        <br /> <br />
                    </mat-chip-list>
                </div>



                <div class="row">
                    <div class="col-md-12">
                        <div class="container-table table-wrapper">
                            <table mat-table [dataSource]="dataSource"  matSort class="widthfluid bordergrey list-table">
                                <ng-container matColumnDef="task_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 170px;"> Task ID
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2'}">
                                        <span
                                            [class]="(element.status == 'published') ? 'circle green' : 'circle red'"></span>
                                        {{element.task_display_id}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="task_summary">
                                    <th mat-header-cell *matHeaderCellDef style="width:400px;"> To do task </th>
                                    <td mat-cell class="text-capitalize" *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2'}"
                                        style="padding: 5px;">
                                        {{element.task_summary}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="category">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                                    <td mat-cell class="text-capitalize" *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2'}"> {{element.category}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="priority">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
                                    <td mat-cell class="text-capitalize" *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2','priority-high':element.priority == '2','priority-low ':element.priority == '0','priority-medium':element.priority == '1'}">
                                        <span *ngIf="element.priority == '2'" class="priority high"></span>
                                        <span *ngIf="element.priority == '0'" class="priority low"></span>
                                        <span *ngIf="element.priority == '1'" class="priority medium"></span>
                                        {{priorityInList(element.priority)}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="expiry_date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiry </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="{'bold-text': element.priority == '2'}">
                                        {{element.expiry_date|date:'dd-MMM-yyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef style="width: 300px;"> Actions</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="action-button-row">
                                            <button mat-button (click)='viewHistory(element.id)' tooltip="View Task"
                                                class="hover-btn-image">
                                                <div class="button-bg-image view-btn"></div>
                                            </button>
                                            <button mat-button [disabled]="element.status == 'published'"
                                                [routerLink]="['edit', element.id]" (click)='getTaskID(element.id)'
                                                tooltip="Edit Task" class="hover-btn-image">
                                                <div class="button-bg-image edit-btn"></div>
                                            </button>
                                            <button mat-button
                                                [disabled]="(element.status == 'published') || (!element.expiry_date)"
                                                (click)='assignTask(element)' tooltip="Assign Task"
                                                class="hover-btn-image">
                                                <div class="button-bg-image assign-btn"></div>
                                            </button>

                                            <button mat-button [matMenuTriggerFor]="menu">
                                                <mat-icon class="button-bg-image more-btn">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" >
                                                <button mat-menu-item class="d-flex align-items-center hover-btn-image" [disabled]="element.task_summary == null"
                                                    (click)='duplicateTask(element.id)'>
                                                    <span class="button-bg-image duplicate-btn"  *ngIf="element.task_summary != null"></span>
                                                    <span class="button-bg-image duplicate-btn"  *ngIf="element.task_summary == null" disabled></span>
                                                    <span class="drop-menu-items">Duplicate</span>
                                                </button>
                                                <button mat-menu-item [disabled]="element.status == 'published'"
                                                    class="d-flex align-items-center hover-btn-image"
                                                    (click)='deleteTask(element.id)'>
                                                    <span class="button-bg-image delete-btn"  *ngIf="element.status != 'published'"></span>
                                                    <span class="button-bg-image delete-btn" *ngIf="element.status == 'published'" disabled></span>
                                                    <span class="drop-menu-items">Delete</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <div style="text-align: center;font-size: 20px;margin-top: 80px;"
                                *ngIf="dataList.length==0">No
                                Data Found</div>
                            <!-- <div class="spinner-holder" *ngIf="isLoading">
                                <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate"
                                    diameter="40">
                                </mat-progress-spinner>
                            </div> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</mat-drawer-container>