import { CommonserviceService } from './../../../admin/services/commonservice/commonservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-edittherapydesign',
  templateUrl: './edittherapydesign.component.html',
  styleUrls: ['./edittherapydesign.component.scss']
})

export class EdittherapydesignComponent implements OnInit {
  title: any = "New Therapy";
  public selfparentid: any;
  public showButtonLoader: boolean = false;
  public newitem: boolean = false;
  public childid: any;
  public langid: any;
  complexityenum: any = complexityenum;
  alltags: any;
  lessondata: any;
  chooseMedia: any;
  therapydata: any;
  thrapypatchdata: any;
  allconditions: any;
  alllanguages: any;
  primarylanguage: any;
  therapyid: any;
  buttontext: any;
  primarycondition: any;
  secondarycondition: any;
  public secondaryoption: any = [];
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: [''],
    alias_name: ['', Validators.required],
    complexity_level: ['', Validators.required],
    master_condition: ['', Validators.required],
    comorbilities: [[], Validators.required],
    // knowledge_levels: ['', Validators.required],
    languages: ['', Validators.required],
    code: ['', [Validators.required, whiteSpaceValidation]],
    level_count: ['', [Validators.required, Validators.max(30), Validators.min(1)]],
    created_by_data_fk: [''],
    tags: [[]],
  });
ActiveTherapyValidate:any;
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(private browserTitle: Title, private route: ActivatedRoute, private router: Router, public fb: FormBuilder, public dialog: MatDialog, private contentService: ContentserviceService, private systemService: SystemService,private commonService:CommonserviceService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Therapy Design');
  }

  ngOnInit() {
    this.ActiveTherapyValidate=localStorage.getItem("ActiveTherapy");
    this.setSEOTags();
    this.getConditions(data => {
      this.getLanguages();
      this.route.params.subscribe(params => {
        this.therapyid = params.id;
        if (this.therapyid != 'new') {
          this.buttontext = "Update Therapy";
          this.getAllTherapyData(params.id)
        }
        else {
          this.buttontext = "New Therapy";
        }
      })
      this.getTags();
    });
  }


  // To get Conditions
  // ----------------------------

  getConditions(cb) {
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getConditionsSuccess(cb, (res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsSuccess(cb, data) {
    
    
    this.allconditions = data.getAllConditions;
    
    cb(true);
  }

  // ----------------------------

  // To get All Conditions
  // ----------------------------

  getLanguages() {
    this.contentService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getLanguagesSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLanguagesSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  secondaryConditions(a) {
    this.secondaryoption = a;
  }

  primaryCondition(e) {
    this.primarycondition = e;
    var newarr = [];
    for (let k = 0; k < this.allconditions.length; k++) {
      if (this.allconditions[k].id != this.primarycondition) {
        newarr.push(this.allconditions[k]);
      }
    }
    this.secondarycondition = newarr;
  }

  // ----------------------------

  // To get All Therapy Data
  // ----------------------------

  getAllTherapyData(ide) {
    this.contentService
      .getOneDataById(ide, 'therapy')
      .subscribe(
        (res: any) => this.getAllTherapyDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllTherapyDataSuccess(data) {
    this.thrapypatchdata = data;
    this.thrapypatchdata.master_condition = this.thrapypatchdata.master_condition.toString();
    this.primaryCondition(this.thrapypatchdata.master_condition);
    this.primarycondition = this.thrapypatchdata.master_condition;
    this.secondaryConditions(this.thrapypatchdata.comorbilities);
    this.dataForm.patchValue(this.thrapypatchdata);
    if (this.thrapypatchdata.comorbilities.length == 0) {
      const formtxtoptncount = <FormControl>this.dataForm.controls['comorbilities'];
      formtxtoptncount.setValue(['none']);
      this.secondaryoption = "none";
    }
  }

  // ----------------------------

  modelChangedTags() {
    this.filterTags();
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // Submitting Therapy Data
  // ----------------------------
  dataObject() {
    var postdata = this.dataForm.value;
    if (this.secondaryoption == 'none') {
      postdata.comorbilities = "[]";
    }
    else {
      postdata.comorbilities = JSON.stringify(postdata.comorbilities);
    }
    let detailed_text = this.commonService.isHTML( this.dataForm.value.detailed_text);
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let alias_name = this.commonService.isHTML( this.dataForm.value.alias_name);
    if(detailed_text  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    }
    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    if(alias_name  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    postdata.title = postdata.title.toString();
    postdata.detailed_text = postdata.detailed_text.toString();
    postdata.alias_name = postdata.alias_name.toString();
    postdata.code = postdata.code.toString();
    postdata.master_condition = postdata.master_condition.toString();
    postdata.level_count = postdata.level_count.toString();
    // postdata.knowledge_levels = JSON.stringify(postdata.knowledge_levels);
    postdata.languages = JSON.stringify(postdata.languages);
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.tags = JSON.stringify(postdata.tags);
    if (this.therapyid == 'new') {
      this.contentService
        .postToBlade(postdata, 'therapy', true)
        .subscribe(
          (res: any) => this.onCreateSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      delete postdata.languages;
      this.contentService
        .updateToBlade(this.therapyid, this.therapyid, postdata, 'therapy', true)
        .subscribe(
          (res: any) => this.onUpdateSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

  private onCreateSuccess(data) {
    this.therapydata = data;
    this.systemService.showSuccessSnackBar("Therapy saved successfully", "OK");
    this.router.navigate(['content/therapy'])
  }

  private onUpdateSuccess(data) {
    this.therapydata = data;
    this.systemService.showSuccessSnackBar("Therapy updated successfully", "OK");
    this.router.navigate(['content/therapy']);
  }

  // ----------------------------

}

export enum complexityenum {
  High,
  Medium,
  Low
}
