import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as AWSMqttClient from 'aws-mqtt';
import * as AWS from 'aws-sdk';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { SystemService } from '../../../shared/services/system.service';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../services/context.service';

declare var TextDecoder: any

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class SocketService extends HttpService {

  public prodUrl: string = environment.cueSocketUrl;
  public chatlistener: string = environment.chatlistener;
  private userListAPI = this.prodUrl + 'metadata/user/patients';
  private socket: any;
  public socketData: EventEmitter<any> = new EventEmitter<any>();
  public convoData: EventEmitter<any> = new EventEmitter<any>();
  public allmessages: EventEmitter<any> = new EventEmitter<any>();
  public systemHaveAlerts: EventEmitter<any> = new EventEmitter<any>();
  public newMessages: EventEmitter<any> = new EventEmitter<any>();
  public replytoid: EventEmitter<any> = new EventEmitter<any>();
  public getonMessage: EventEmitter<any> = new EventEmitter<any>();
  public connectioncheck: EventEmitter<boolean> = new EventEmitter();
  private myhash: any = {};
  private allconversations: any;
  public waitingUserIds: any = [];
  public respondUserIds: any = [];
  public isinitiated: boolean = true;
  public allconvdata: any;
  public client: any;
  public clientid: any;
  public registerToken: any = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQzMjgsImlhdCI6MTU2Mjc0OTc1NX0.QgIXPnmtuzkrpIswdSUKpULdwNYB1Q4UAK4G2eXXln8';
  public image: any;
  public bucketName: any;
  public IdentityPoolId: any;
  public s3: any;

  constructor(
    private http: HttpClient, 
    private appContext: ContextService, 
    private systemService: SystemService
  ) {
    super(http)
  }

  replyonid(ide) {
    this.replytoid.next(ide);
  }

  getSocketConnection(a = false) {
    
    this.getConversation();
    if (a) {
      this.onNewMessageDoc();
    }
    else {
      this.onNewMessage();
    }
  }

  getConversation() {
    let scope = this;
    let count = 0;
    let hcid = localStorage.getItem('scarletuserid');
    let deliveryrec = {};

    this.getallconversations(hcid)
      .subscribe(data => {
        this.allconvdata = data;
        this.allconvdata = this.allconvdata.metadata;
        if (this.allconvdata.length > 0) {
          this.allconvdata.forEach(element => {
            count = count + 1;
            scope.myhash[element.user] = element;
            scope.appContext.addUserNewMessage(element.user, element.last_message_details.body, element.last_message_at, (element.last_sender == hcid));
            if (element.last_sender != hcid) {
              deliveryrec = { type: 'deliveryCursor', conversationId: element.conv_id, userId: hcid, value: element.last_message_details.id, participants: [hcid, element.user] };
              if (element.conv_id && element.last_message_details.id) {
                this.setCursor(deliveryrec)
                  .subscribe(data => {
                  }, (error) => {
                  })
              }
            }
            if (count == this.allconvdata.length) {
              scope.convoData.emit(scope.myhash);
              scope.allconversations = true;
            }
          })
        }
      })
    /* scope.socket.on('conversations', function (convs) {
          if (convs.length > 0) {
            convs.forEach(element => {
              count = count + 1;
              scope.myhash[element.participants.filter(a => a != hcid)[0]] = element;
              scope.appContext.addUserNewMessage(element.participants.filter(a => a != hcid)[0], element.lastMessage, element.lastActivity, (element.lastSenderID == hcid)); 
              if (element.lastSenderID != hcid) {
                deliveryrec = { type: 'deliveryReceipt', conversationID: element.conversationID, receiver: hcid, sender: element.lastSenderID, timeStamp: element.lastActivity };
                scope.socket.emit('deliveryReceipt', deliveryrec);
              }
              if (count == convs.length) {
                scope.convoData.emit(scope.myhash);
                scope.allconversations = true;
              }
            })
          }
        });  */
  }
  onDisconnect() {
    /**Empty socket data */
    this.appContext.masterSocketIO.next(null);
    this.socket.disconnect();
  }

  public getUserList(page, filtertype, query): Observable<any> {
    query = this.removeHTMLtags(query);
    let bodydata = {
      "filters": filtertype,
      "pageNumber": page,
      "userId": 'H' + localStorage.getItem('scarletuserid'),
      "query_string": query,
      // "language_data_fk":''
    }
    if (environment.org) {
      bodydata.filters.push({
        "filter_type": "organizationFilter",
        "value": parseInt(this.systemService.getorg())
      })
    }
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.userListAPI, bodydata, { headers: headers })
  }

  public setCursor(messageJson): Observable<any> {
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.prodUrl + 'conversation/updateCursor', messageJson, { headers: headers })
  }

  public deleteMessage(conv, id): Observable<any> {
    let messageJson = {
      "conversationId": conv,
      "messages": [id]
    }
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.prodUrl + 'message/deleteMessages', messageJson, { headers: headers })
  }

  public getallconversations(userid): Observable<any> {
    let messageJson = {
      "userId": userid,
      "pageNum": 1
    }
    if (environment.org) {
      messageJson['organizationFilter'] = this.systemService.getorg()
    }
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.prodUrl + 'conversation/metadata', messageJson, { headers: headers })
  }
  public publishTyping(typingjson, usid) {
    this.client.publish('/' + this.chatlistener + '/user/' + 'U' + usid, JSON.stringify(typingjson));
  }
  public emitNewMessage(messageJson): Observable<any> {
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.prodUrl + 'conversation/message', messageJson, { headers: headers })
  }

  fetchAllMessages(messageJson): Observable<any> {
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.prodUrl + 'message/getMessages', messageJson, { headers: headers })
  }
  registerUser(): Observable<any> {
    let messageJson = {
      "user_object_id": localStorage.getItem('scarletuserid'),
      "type": "web"
    }
    let headers = new HttpHeaders();
    return this.post(this.prodUrl + 'user/register', messageJson, { headers: headers })
  }

  watchUser(usrid): Observable<any> {
    let messageJson = {
      "userId": localStorage.getItem('scarletuserid'),
      "watchUserId": usrid
    }
    let headers = new HttpHeaders();
    return this.post(this.prodUrl + 'user/watch', messageJson, { headers: headers })
  }
  unWatchUser(usrid): Observable<any> {
    let messageJson = {
      "userId": localStorage.getItem('scarletuserid'),
      "watchUserId": usrid
    }
    let headers = new HttpHeaders();
    return this.post(this.prodUrl + 'user/unwatch', messageJson, { headers: headers })
  }

  fileEvent(fileInput: any, ide, cb) {
    const file = fileInput;
    //I store this in a variable for retrieval later
    this.image = file.name;
    var bucketname;
    if (file.type.split("/")[0] === 'image') {
      bucketname = 'wellthy-cue2'
    }
    else {
      bucketname = 'wellthy-cue2-multimedia'
    }
    this.s3.upload({ Key: 'conversations/' + ide + '/' + 'attachments/' + file.name, Bucket: bucketname, Body: file }, function (err, data) {
      
      if (err) {
        cb(false);
      }
      else {
        cb(data.Key)
      }
    });
  }

  getfileurl(path, file, cb) {
    let bucketname;

    if (file.split("/")[0] === 'image') {
      bucketname = 'wellthy-cue2'
    } else {
      bucketname = 'wellthy-cue2-multimedia'
    }

    const s3Params = {
      Bucket: bucketname,
      Key: path,
      Expires: 560
    };

    this.s3.getSignedUrl('getObject', s3Params, (err, data) => {
      if (err) {
        cb(false)
      }
      else {
        cb(data)
      }
    });
  }

  onNewMessage() {
    let scope = this;
    let hcid = localStorage.getItem('scarletuserid');
    this.clientid = localStorage.getItem('scarletuserid') + "-" + (Math.floor((Math.random() * 100000) + 1));

    let credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'ap-south-1:f5cfb795-d82c-49e2-8847-0c0518af138e'
    })

    AWS.config.update({
      region: 'ap-south-1'
    });
    /*--------------------------*/
    const AWSService = AWS;
    const region = 'ap-south-1';
    this.bucketName = 'wellthy-cue2';
    this.IdentityPoolId = 'ap-south-1:f5cfb795-d82c-49e2-8847-0c0518af138e';
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: this.IdentityPoolId
      })
    });

    this.s3 = new AWSService.S3({
      apiVersion: '2020-03-01',
      params: { Bucket: this.bucketName }
    });

    /*------------------------*/
    this.client = new AWSMqttClient({
      region: 'ap-south-1',
      credentials: credentials,
      endpoint: 'am8hbq0e8x6ij-ats.iot.ap-south-1.amazonaws.com', // NOTE: get this value with `aws iot describe-endpoint`
      expires: 600, // Sign url with expiration of 600 seconds
      clientId: this.clientid,
      will: {
        topic: 'WillMsg',
        payload: 'Connection Closed abnormally..!',
        qos: 0,
        retain: false
      }
    })

    this.client.on('connect', () => {
      
      if (environment.org) {
        scope.client.subscribe('/' + this.chatlistener + '/user/H' + hcid + '/' + this.systemService.getorg())
      }
      else {
        scope.client.subscribe('/' + this.chatlistener + '/user/H' + hcid)
      }
    })

    this.client.on('message', (topic, message) => {
      var mdata = new TextDecoder("utf-8").decode(message);
      let data = JSON.parse(mdata);
      if (data.type == "notification") {
        scope.systemHaveAlerts.next(true);
      }
      scope.newMessages.next(data);
    })

    this.client.on('close', () => {
    })

    this.client.on('offline', () => {
    })

    this.client.on('disconnect', () => {
    })

  }



  onNewMessageDoc() {
    var scope = this;
    var docid = localStorage.getItem('scarletuserid');
    this.clientid = localStorage.getItem('scarletuserid') + "-" + (Math.floor((Math.random() * 100000) + 1));
    var credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'ap-south-1:f5cfb795-d82c-49e2-8847-0c0518af138e'
    })
    AWS.config.update({
      region: 'ap-south-1'
    });
    /*--------------------------*/
    const AWSService = AWS;
    const region = 'ap-south-1';
    this.bucketName = 'wellthy-cue2';
    this.IdentityPoolId = 'ap-south-1:f5cfb795-d82c-49e2-8847-0c0518af138e';
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: this.IdentityPoolId
      })
    });
    this.s3 = new AWSService.S3({
      apiVersion: '2020-03-01',
      params: { Bucket: this.bucketName }
    });

    /*------------------------*/
    this.client = new AWSMqttClient({
      region: 'ap-south-1',
      credentials: credentials,
      endpoint: 'am8hbq0e8x6ij-ats.iot.ap-south-1.amazonaws.com', // NOTE: get this value with `aws iot describe-endpoint`
      expires: 600, // Sign url with expiration of 600 seconds
      clientId: this.clientid,
      will: {
        topic: 'WillMsg',
        payload: 'Connection Closed abnormally..!',
        qos: 0,
        retain: false
      }
    })
    var scope = this;
    this.client.on('connect', () => {
      if (environment.org) {
        scope.client.subscribe('/' + this.chatlistener + '/doctor/' + docid + '/' + this.systemService.getorg())
      }
      else {
        scope.client.subscribe('/' + this.chatlistener + '/doctor/' + docid)
      }
    })
    this.client.on('message', (topic, message) => {
      var mdata = new TextDecoder("utf-8").decode(message);
      let data = JSON.parse(mdata);
      this.appContext.docSystemHaveAlerts.next(true);
    })
    this.client.on('close', () => {
    })
    this.client.on('offline', () => {
    })
    this.client.on('disconnect', () => {
    })

  }

  getConv() {
    return this.allconversations
  }
  getHashMap() {
    return this.myhash
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
