import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-setupleft-nav',
  templateUrl: './media-setupleft-nav.component.html',
  styleUrls: ['./media-setupleft-nav.component.scss']
})
export class MediaSetupleftNavComponent implements OnInit {

  showingnav: boolean = true;
  public userData: any;
  constructor() {
  }

  ngOnInit() {
   
  }
  togglenav() {
    if (this.showingnav) {
      $(".userlistnavcontent").css('width', '0px');
      $(".mainrightcontainersecondary").css('marginLeft', '0px');
      $(".slidetogglecss").css('marginLeft', '-15px');

    }
    else {
      $(".userlistnavcontent").css('width', '200px');
      $(".mainrightcontainersecondary").css('marginLeft', '210px');
      $(".slidetogglecss").css('marginLeft', '185px');
    }
    this.showingnav = !this.showingnav;
  }
}
