

import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { CampaignqrComponent } from '../campaign/campaignqr/campaignqr.component';
import { DeletemodalComponent } from './../../../content/shared/deletemodal/deletemodal.component';
import { CampaignHistoryComponent } from './campaign-history/campaign-history.component';
import { CampaigndeeplinkComponent } from './campaigndeeplink/campaigndeeplink.component';
import { CampaignviewComponent } from './campaignview/campaignview.component';
import { CreatecampaignComponent } from './createcampaign/createcampaign.component';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  
})

export class CampaignComponent implements OnInit {
  displayedColumns: string[] = ['title', 'type', 'client_data', 'coach_ids','action'];
  dataSource: any;
  doc: any;
  public isactive = 'all';
  public newsection: any = 'new';
  searchcriteria: any;
  public page: number = 1;
  public showloader: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  tabulardata: any;
  public alltypes: any;
  private myhash: any = {};
  private myhashtwo: any = {};
  public filteredtabulardata: any;
  activecls = 'active';
  public typeFilters: any;
  public filteredtypes: any = [];
  public status: any = 'active';
  showButtonLoader: boolean;
  public allhc: any;
  public allclients: any;
  public tagFilters: any;
  public localStoredData: any;

  constructor(private browserTitle: Title,private _bottomSheet: MatBottomSheet, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }
  
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('MatSort', { static: false }) sort: MatSort;
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Campaign');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.searchcriteria = "";
    this.page = 1;
    this.getHc();
    this.getallclient();
    this.getLanguages();
    this.search();
  }
  

  // To Delete Campaign 
  // ----------------------------

  delete(id) {
    this.commonService.campaignDelete(id)
    .subscribe(
      (res: any) => this.onDeleteSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------
  
  getNextSet() {
    
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  
  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }
  
  
  tagfilter(fln) {
    this.filteredtypes = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }
  

  // To Open Delete Modal 
  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected campaign has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  // ----------------------------

  showQR(id) {
    this.dialog.open(CampaignqrComponent, {
      width: '500px',
      height: 'auto',
      panelClass: 'docqr',
      data: {
        campaign_id: id
      }
    });
  }

  downloadQR(data) {
    var canvas: HTMLCanvasElement = this.canvas.nativeElement;
    var context = canvas.getContext('2d');
    let pdfName = data.title + "-" + data.type + "-" + data.id;
    let scope = this;
    setTimeout(() => {
      let img1 = new Image();
      let img2 = new Image();
      let img3 = new Image();
      img1.crossOrigin = "Anonymous";
      img1.onload = function () {
        var cW = 300;
        var cH = 300;
        canvas.width = cW;
        canvas.height = cH
        img2.src = "https://chart.googleapis.com/chart?cht=qr&chl=campaign_id:" + data.id + "&chs=545x545&choe=UTF-8&chld=H%7C2";
        img2.crossOrigin = "Anonymous";
      };
      img2.onload = function () {
        img3.src = "assets/img/Wellthy_QR_Logo_2.png";
        img3.crossOrigin = "Anonymous";
        img3.onload = function () {
          // context.drawImage(img1, 0, 0, 964, 1692);
          // context.drawImage(img2, 188, 596, 595, 595);
          // context.drawImage(img3, 417, 827, 130, 130);
          // context.drawImage(img1, 0, 0, 964, 1692);
          context.drawImage(img2, 0, 0, 300, 300);
          context.drawImage(img3, 120, 120, 65, 65);
          let fileName = pdfName + ".png";
          scope.download(canvas, fileName);
        }
      };
      img1.src = 'assets/img/Doctor_Stand.png';
    }, 2000);
  }

  download(canvas, filename) {
    var lnk = document.createElement('a'), e;
    lnk.download = filename;
    lnk.href = canvas.toDataURL("image/png;base64");
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent("click", true, true, window,
        0, 0, 0, 0, 0, false, false, false,
        false, 0, null);
      lnk.dispatchEvent(e);
    } else {
      lnk.click();
    }
  }

  getUsername(id) {
    return this.commonService.allusers[id];
  }
  
  
  // Active Deactive Campaign 
  // ----------------------------

  isActive(status, id) {
    this.commonService.campaignStatus(status, 'campaigndata', id, this.localStoredData.id.toString())
    .subscribe(
      (res: any) => this.onStatusSuccess(status, id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------
  

  // To Get Language 
  // ----------------------------

  getLanguages() {
    this.commonService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
    .subscribe(
      (res: any) => this.onLanguageSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onLanguageSuccess(data) {
    data = (data as any).data;
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  // ----------------------------
  

  // To Get Health Coach Data 
  // ----------------------------

  getHc() {
    this.commonService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name}}')
    .subscribe(
      (res: any) => this.onHCStatusSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onHCStatusSuccess(data) {
    data = (data as any).data;
    this.allhc = data.getAllHealthcoaches;
    this.allhc.forEach(element => {
      this.myhash[element.id] = element.name;
    });
  }

  // ----------------------------
  
  getHCName(allhc) {
    allhc = allhc.map(i => this.myhash[i]);
    return allhc;
  }
  

  // To Get All Client  
  // ----------------------------

  getallclient() {
    this.commonService.getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
    .subscribe(
      (res: any) => this.onClientStatusSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onClientStatusSuccess(data){
    data = (data as any).data;
    this.allclients = data.getAllClients;
    this.allclients.forEach(element => {
      this.myhashtwo[element.id] = element.title;
    });
  }
 
  getClientName(clients) {
    clients = this.myhashtwo[clients];
    return clients;
  }
  
  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }
  
  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }
  
  filtercontent(a) {
    this.tabulardata = [];
    for (var j = 0; j < a.length; j++) {
      if (a[j].type == 'campaign_paid' || a[j].type == 'demo_campaign' || a[j].type == 'campaign_free') {
        this.tabulardata.push(a[j]);
      }
    }
  }
  

  // Campaign List
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllGeneralData(this.searchcriteria, this.page.toString(), "20", this.status, "campaigndata")
      .subscribe(
        (res: any) => this.onSearchDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onSearchDataSuccess(data) {
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.filtercontent(this.tabulardata);
      if (this.tabulardata.length < 15) {
        this.getNextSet();
      }
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
    if (this.filteredtypes.length !== 0) {
      this.tagfilter(this.filteredtypes);
    }
    else {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }

  // ----------------------------
  
  removeunderscore(a) {
    a = a.replace('_', ' ')
    return a
  }
  
  viewCampaignDetails(element): void {  
    const profile = this.dialog.open(CampaignviewComponent, {
      width: '900px',
      data: {
        element: element
      }
    });
    profile.afterClosed().subscribe(result => {
    });
  }

  openCampaignDeeplink(el): void {
    this._bottomSheet.open(CampaigndeeplinkComponent, {
      data: {
        id: el,
      },
      panelClass: 'custom-bottom-css'
    });
  }

  createCampaign(element) {
    this.dialog.open(CreatecampaignComponent, {
      width: '900px',
      data: {
        element: element
      }
    });
    // this.generatecode.afterClosed().subscribe(data => {
    //   this.lastpage = 0;
    //   this.page = 1;
    //   this.tabulardata = [];
    //   this.search();
    // });
  }

  CampaignInfo(element): void {
   // localStorage.setItem("userType", 'doctor')
    // const history = this.dialog.open(CampaignHistoryComponent, {
    //   width: '900px',
    //   height: 'auto',
    //   data: {
    //     element: element
    //   }
    // });

    // history.afterClosed().subscribe(result => {
    // });
    this.dialog.open(CampaignHistoryComponent, {
      width: '900px',
      height: '900px',
      data: {
        element: element
      }
    });
  }

}

export interface PeriodicElement {
  title: string;
  type: string;
  is_paid: string;
  is_test: string;
}
