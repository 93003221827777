import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { PatientuserdetailsComponent } from '../patient/patientuserdetails/patientuserdetails.component';
import { CommonserviceService } from '../services/commonservice/commonservice.service';
import { AdmindeletemodalComponent } from '../shared/admindeletemodal/admindeletemodal.component';
import { GoogleAppleRawdataComponent } from '../shared/googleapplerawdata/googleapplerawdata.component';
import { EditPatientInformationComponent } from './edit-patient-information/edit-patient-information.component';
import { LoginhistoryComponent } from './loginhistory/loginhistory.component';
import { PatientEventsComponent } from './patient-events/patient-events.component';
import { PatientInformationComponent } from './patient-information/patient-information.component';
import { PatientPhoneChangeLogComponent } from './patient-phone-change-log/patient-phone-change-log.component';
import { PatientonsiteComponent } from './patientonsite/patientonsite.component';
import { PatientpaymentinfoComponent } from './patientpaymentinfo/patientpaymentinfo.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class PatientComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'phone', 'status', 'action'];
  public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  actRoute: string;
  actRouteEdit: string;
  public viewMedia: any;
  public morrow: any = new Date();
  public filterapplied: boolean = false;
  public startd: any;
  public endd: any;
  enddateval: any;
  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });
  createStoreForm: FormGroup;
  userData: any;
  superadmin: boolean = false;
  localStoredData: any;
  deviceData: any;
  getLatestDevice: any;
  latestDeviceId: any;
  device_type: any;
  app_version: any;
  device_version: any;
  whatsapp: any;
  locationAccess: any;
  camera: any;
  googlefit: any;
  applefit: any;
  gallery: any;
  call: any;
  is_device_rooted: any;
  is_debug_app: any;
  ratingValue: any;
  isRating: any;
  rating_given_at: any;
  showRating: boolean;
  permissions: any;
  appContext: any;
  Resync_GoogleFit: any;
  Resync_Healthkit: any;
  ask_for_resync_google_health: any;
  constructor(
    private browserTitle: Title, 
    private fb: FormBuilder, 
    private commonService: CommonserviceService, 
    private systemService: SystemService, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar,
    private userDetailService:UserdetailsService
  ) {
    this.createStoreForm = fb.group({
      'user': ['', Validators.required],
    });
  }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Patient Details');
  }

  ngOnInit() {

    let data = localStorage.getItem("patientsearch");

    this.setSEOTags()
    //this.searchcriteria = "";
    this.createStoreForm.value.user = "";
    this.page = 1;
    if (data != null) {
      this.createStoreForm.patchValue({
        'user': data,
      });
      this.livesearch();
      localStorage.removeItem("patientsearch")
    }
    else if (data == null) {
      this.search();
    }
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    // this.localStoredData = (this.localStoredData.email == 'central@wellthy.care' ? true : false);
    this.superadmin = (this.localStoredData.user_acl == 'superadmin' ? true : false);
  }

  viewProfile(element): void {
    const profile = this.dialog.open(PatientuserdetailsComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
    });

  }
  viewPayment(element): void {
    const profile = this.dialog.open(PatientpaymentinfoComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
    });

  }

  statusConvert(e) {
    return e.replace(/_/g, " ").toLowerCase();
  }

  // To delete Patient 
  // ----------------------------

  delete(id) {
    this.commonService.hardDelete('patient', id)
      .subscribe(
        (res: any) => this.onDelete(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDelete(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // To open dialog box 
  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected patient has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  // ----------------------------

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    //this.searchcriteria = "";
    this.createStoreForm.value.user = "";
    this.search();
  }

  typeFilter(fln) {
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.patient_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }

  livesearch() {


    if (this.lastSearch != this.createStoreForm.value.user) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // Force Logout From the App 
  // ----------------------------

  forceLogout(id) {
    this.commonService.logoutPatient(id)
      .subscribe(
        (res: any) => this.onStatusSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess() {
    this.systemService.showSuccessSnackBar(("Selected patient has been Logout from app"), "OK");
  }

  // ----------------------------


  // Patient List
  // ----------------------------

  search() {
    if (this.lastSearch != this.createStoreForm.value.user || this.page != this.lastpage) {
      this.lastSearch = this.createStoreForm.value.user;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllPatientsFilter(this.createStoreForm.value.user, this.page.toString(), "10", "all", "patient", this.startd, this.endd)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;

      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------


  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.enddateval = event.target.value;
  }

  applymainfilter() {
    var postdata = this.dataForm.value;
    this.startd = postdata.startdate;
    this.endd = postdata.enddate;
    this.startd.setHours(0, 0, 0, 0);
    this.endd.setHours(23, 59, 59, 99);
    this.page = 1;
    this.lastSearch = this.createStoreForm.value.user;
    this.lastpage = null;
    this.tabulardata = [];
    this.search();
    this.filterapplied = true;
  }

  patientonsite(): void {
    const profile = this.dialog.open(PatientonsiteComponent, {
      width: '1000px',
      height:'50%',
      data: {
        // element: element
      }
    });

    profile.afterClosed().subscribe(() => {
      //this.searchcriteria = "";
      this.createStoreForm.value.user = "";
      this.page = 1;

      setTimeout(function () {
        this.search();
      }, 1000);
    });

  }

  PatientInfo(element): void {
    localStorage.setItem("userType", 'doctor')
    
    this.dialog.open(PatientInformationComponent, {
      width: '900px',
      data: {
        element: element
      }
    });
  }

  patientPhoneLog(element): void {

    this.dialog.open(PatientPhoneChangeLogComponent, {
      width: "1024px",
      data: element
    });

  }

  EditPatient(element): void {
    //localStorage.setItem("PatientId", 'element.id')
    localStorage.setItem("userType", 'doctor')
    const history = this.dialog.open(EditPatientInformationComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    history.afterClosed().subscribe(result => {
    });
  }

  checkStatus(element) {
    if (element.status != 'ACTIVATION_CODE_VERIFIED') {
      return true;
    } else if (element.status != 'ONBOARDED') {
      return true;
    } else if (element.status != 'SECONDARY_CONDITION_ENTERED') {
      return true;
    }
  }
  loginHistory(id){
  localStorage.setItem("userType", 'doctor')
    const history = this.dialog.open(LoginhistoryComponent, {
      width: '900px',
      height:'900px',
      data: {
        element: id
      }
    });

    history.afterClosed().subscribe(result => {
    });
  }
  triggerHealthkit(id:any){
    this.getpermissionData(id)
}
  
  getRawdata(id:any){
      const profile = this.dialog.open(GoogleAppleRawdataComponent, {
        width: '100%',
        height:'90%',
        data: {
          element: id
        }
      });
  
      profile.afterClosed().subscribe(result => {
      });
  
  }
  getpermissionData(id) {
    this.userDetailService.getpatientPermissiondata(id)
      .subscribe(
        (res: any) => this.successpermisonData(res,id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private successpermisonData(data,id) {
    data = (data as any).data;
    this.deviceData = data;
    if (data.length && data[0].rating_number != 0) {
      this.ratingValue = data[0].rating_number.toString();
      this.isRating = data[0].ask_for_rating;
      this.rating_given_at = data[0].rating_given_at
    } else {
      this.isRating = false;
    }
    if (data.length) {
      this.getLatestDevice = data.filter(a => a.is_active == true);
      if (this.getLatestDevice.length) {
        if (this.getLatestDevice[0].device_id != "false") {
          this.latestDeviceId = this.getLatestDevice[0].device_id;
          this.device_type = this.getLatestDevice[0].device_type;
          this.app_version = this.getLatestDevice[0].app_version;
          this.device_version = this.getLatestDevice[0].device_version;
          this.whatsapp = this.getLatestDevice[0].whatsapp_opt_in
          this.locationAccess = this.getLatestDevice[0].location_access
          this.camera = this.getLatestDevice[0].camera_access
          this.googlefit = this.getLatestDevice[0].google_fit_access
          this.applefit = this.getLatestDevice[0].apple_fit_access
          this.ask_for_resync_google_health = this.getLatestDevice[0].ask_for_resync_google_health
          this.gallery = this.getLatestDevice[0].gallery_access
          this.call = this.getLatestDevice[0].call_opt_in
          this.is_device_rooted = this.getLatestDevice[0].is_device_rooted
          this.is_debug_app = this.getLatestDevice[0].is_debug_app
        } 
      } 
    }
    let permission_type;
    if( this.device_type === 'iOS'){
      this.applefit = true
      permission_type = 'Resync_HealthKit'
      this.Resync_Healthkit = true;
    }else{
      this.googlefit = true
      permission_type = 'Resync_GoogleFit'
      this.Resync_GoogleFit = true;
    }
    this.askPermission(permission_type,id,this.latestDeviceId )
  }

 // To save permissions for patient
 askPermission(permission,patient_id,device_id) {
  let postdata:any;
  if (permission == "Resync_GoogleFit") {
    postdata = {
      "patient_id_fk": patient_id.toString(),
      "gallery_access": this.gallery.toString(),
      "google_fit_access": this.googlefit.toString(),
      "apple_fit_access": this.applefit.toString(),
      "location_access": this.locationAccess.toString(),
      "camera_access": this.camera.toString(),
      "whatsapp_opt_in": this.whatsapp.toString(),
      "call_opt_in": this.call.toString(),
      "ask_for_rating": this.isRating.toString(),
      "device_id": device_id.toString(),
      'ask_for_resync_google_health': this.ask_for_resync_google_health.toString(),
      'fit_kit_data_asked':this.Resync_GoogleFit.toString()

    }
  }else if (permission == "Resync_HealthKit") {
    postdata = {
      "patient_id_fk": patient_id.toString(),
      "gallery_access": this.gallery.toString(),
      "google_fit_access": this.googlefit.toString(),
      "apple_fit_access": this.applefit.toString(),
      "location_access": this.locationAccess.toString(),
      "camera_access": this.camera.toString(),
      "whatsapp_opt_in": this.whatsapp.toString(),
      "call_opt_in": this.call.toString(),
      "ask_for_rating": this.isRating.toString(),
      "device_id": localStorage.getItem('latestdeviceid').toString(),
      'ask_for_resync_google_health': this.ask_for_resync_google_health.toString(),
      'fit_kit_data_asked':this.Resync_Healthkit.toString()
    }
  }else {
    postdata = {
      "patient_id_fk": patient_id.toString(),
      "gallery_access": this.gallery.toString(),
      "google_fit_access": this.googlefit.toString(),
      "apple_fit_access": this.applefit.toString(),
      "location_access": this.locationAccess.toString(),
      "camera_access": this.camera.toString(),
      "whatsapp_opt_in": this.whatsapp.toString(),
      "call_opt_in": this.call.toString(),
      "ask_for_rating": this.isRating.toString(),
      "device_id": localStorage.getItem('latestdeviceid').toString(),
     
    }
  }
  this.userDetailService.postPermissionData(postdata)
    .subscribe(
      (res: any) => this.successpermissions(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  getpermissionRequest(permission: any, event: any) {
    
  }

  successpermissions(res: any): void {
   
  }

  openPatientEvents(element){
    
    this.dialog.open(PatientEventsComponent, {
      data: element,
      width: "1024px"
    })
  }
}


export interface PeriodicElement {
  name: string;
  phone: string;

}
