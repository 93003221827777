import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { isObject } from 'rxjs/internal/util/isObject';
import { isArray } from 'util';
@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss'],
  animations: [detailExpandTable]
})

export class ConditionComponent implements OnInit {
  displayedColumns: string[] = ['title', 'detailed_text', 'action'];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;

  tabulardata: any;
  public isactive = 'all';
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  activecls = 'active';
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  public tags: any;
  public filteredtabulardata: any;
  public typeFilters: any;
  public filteredtypes: any = [];
  public status: any = 'active';

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private contentService: ContentserviceService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.page = 1;
    this.searchcriteria = "";
    this.getLanguages();
    this.search();
  }

  typeFilter(ab) {
    this.filteredtypes = ab;
    if (ab.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTypes);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTypes(t) {
      if (t.type.split(',').filter(typeItem => ab.includes(typeItem)).length > 0) {
        return true;
      }
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  // ----------------------------
  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
    else {
      this.page = 1;
      this.lastpage = 0;
      this.search();
    }

  }

  // List Conditions 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getAllConditions(this.searchcriteria, this.page.toString(), "20", this.status, "condition")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------

  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

}
