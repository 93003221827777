import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';


@Component({
  selector: 'app-genericdeeplink',
  templateUrl: './genericdeeplink.component.html',
  styleUrls: ['./genericdeeplink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GenericDeeplinkComponent implements OnInit {
  public allCampaigns: any;
  public alldata: any;
  public alllanguages: any;
  public showdeeplink: boolean = false;
  public dataForm = this.fb.group({
    condition: ["", Validators.required],
    language_data_fk: ["",Validators.required]
  });

  constructor(private contentService: ContentserviceService,private browserTitle: Title,
     @Inject(MAT_DIALOG_DATA) public data: any, private cd: ChangeDetectorRef, private systemService: SystemService,
      private dialogRef: MatDialogRef<GenericDeeplinkComponent>, public fb: FormBuilder, private commonService: CommonserviceService) { }

  openLink(event: MouseEvent): void {
    this.dialogRef.close();
    event.preventDefault();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Doctor');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getCampaignData();
    this.getAllLanguages();
  }


  // To Get Campaign Data
  // ----------------------------

  getCampaignData() {
    this.commonService.getAllCampaign(this.data.id)
    .subscribe(data => {
      this.allCampaigns = (data as any).data;
    })
  }

  // ----------------------------


  //  Submitting User Data 
  // ----------------------------

  dataObject() {
    var postpredata = this.dataForm.value;
    this.commonService.deeplinkAndQr(postpredata, 'deeplink', this.data.code)
    .subscribe(
      (res : any) => this.onSuccessData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  private onSuccessData(data) {
    var scope = this;
    if ((data as any).status == 200) {
      setInterval(() => {
        scope.alldata = (data as any).data;
        scope.showdeeplink = true;
        scope.cd.markForCheck();
      }, 1000);
    }
    else {
      this.systemService.showSnackBar((data as any).message, "OK");
      this.dialogRef.close();
    }
  }

  // ----------------------------

  //  To Copy URL
  // ----------------------------

  copyURL() {
    var abc = this.alldata.deeplink.shortLink;
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = abc;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }

  // ----------------------------

  getAllLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
  }


  closeModal(): void {
    this.dialogRef.close();
  }

}
