import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-careycard',
  templateUrl: './careycard.component.html',
  styleUrls: ['./careycard.component.scss'],
  animations: [
    detailExpandTable
  ]
})

export class CareycardComponent implements OnInit {
  displayedColumns: string[] = ['title', 'type', 'tags', 'updatedat','action'];
  dataSource: any;
   public isactive = 'all';
  public newsection: any = 'new';
  searchcriteria: any;
  public page: number = 1;
  public showloader: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  tabulardata: any;
  public alltags: any;
  private myhash: any = {};
  public filteredtabulardata: any;
  public typeFilters: any;
  public filteredtags: any = [];
  Lastfilteredtags: any;
  public status: any = 'active';
  activecls = 'active';

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Carey Card');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.getTags();
    this.getLanguages();
    this.search();
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtags = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }


  // Delete CareyCards
  // ----------------------------

  delete(id) {
    this.contentService.hardDelete('careycard', id)
    .subscribe(
      (res : any) => this.ondeleteCardSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private ondeleteCardSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected carey card has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }


  // get languages
  // ----------------------------

  getLanguages() {
    this.contentService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  // ----------------------------

  tagfilter(fln) {
    this.filteredtags = fln;
    this.tabulardata = [];
    this.page = 1;
    this.lastpage = 0;
    this.search();
  }



  getUsername(id) {
    return this.contentService.allusers[id];
  }

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}',false)
      .subscribe( (res: any) => this.ongetTagsSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.alltags.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  getTagName(tags) {
    tags = tags.split(',').map(tags => this.myhash[tags]).join(',');
    return tags;
  }

  // ----------------------------

  // Active Deactive Carey Card User 
  // ----------------------------

  isActive(status, id) {
    let value;
    if (status) {
      value = "Deactivate"
    } else {
      value = "Activate"
    }
    this.contentService.setStatus(status, 'careycard', id)
    .subscribe(
      (res: any) => this.onStatusSuccess(status, id,value),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onStatusSuccess(status, id,value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------


  // List Carey Cards 
  // ----------------------------

  search() {
    if (this.Lastfilteredtags == '' || this.Lastfilteredtags != this.filteredtags || this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.searchcriteria = this.removeHTMLtags(this.searchcriteria)
      this.lastSearch = this.searchcriteria;
      this.Lastfilteredtags = this.filteredtags;
      if (this.filteredtags) {
        this.filteredtags = this.filteredtags.toString();
      }
      else {
        this.filteredtags = this.filteredtags;
      }
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getSearchDataRequest(this.searchcriteria, this.filteredtags, this.page.toString(), "20", this.status, "careycard")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}

export interface PeriodicElement {
  title: string;
  type: string;
  tags: string;
  complexity: string;
}