import { Location } from '@angular/common';
import {Component,Input, OnInit} from '@angular/core';
@Component({
  selector: 'app-backbutton',
  templateUrl: './backbutton.component.html',
  styleUrls: ['./backbutton.component.scss']
})
export class BackbuttonComponent implements OnInit {

  @Input() url: string;

  constructor(private location: Location) { }

  ngOnInit(): void { }

  back() {
    this.location.back();
  }

}
