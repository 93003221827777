import { Component, OnInit } from '@angular/core';
import { SystemService } from 'src/app/shared/services';
import { APILogsAPIService } from 'src/app/shared/services/api-logs.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/content/shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-api-logs',
  templateUrl: './api-logs.component.html',
  styleUrls: ['./api-logs.component.scss']
})


export class APILogsComponent implements OnInit {

  public dataSource: any = [];
  public displayedColumns: string[] = ['Id', 'Name', 'URL', 'status', 'Request', 'Response', 'created_at', 'action'];
  public statusFilters: any = [];
  public providerFilters: any = [];
  public searchcriteria: any  = '';

  public pageNumber: number = 1;

  public admin;

  constructor(
    private apiService: APILogsAPIService,
    private systemService: SystemService,
    private dialog: MatDialog
  ) {

    let adminData = JSON.parse(localStorage.getItem('scarletuserdata'))

    this.admin = {
      id: adminData.id, 
      name: adminData.name
    }
  }

  ngOnInit(): void {
    this.search();
  }

  textSearch(){
    this.pageNumber = 1;
    this.dataSource = [];
    this.search();
  }

  statusFilter() {
    this.dataSource = [];
    this.search();
  }

  providerFilter() {
    this.dataSource = [];
    this.search();
  }

  search() {
    let filter = {
      q: this.searchcriteria,
      provider: this.providerFilters.toString(),
      status: this.statusFilters.toString(),
      page: this.pageNumber.toString(),
      limit: "20"
    }
    this.apiService.search(filter).subscribe((data) => {

      if (data.status === 200){
        this.dataSource = [...this.dataSource, ...data.data];
      }
      
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }

  getNextSet(){
    this.pageNumber += 1;
    this.search();
  }

  retry(id, provider) {
    let successMessage;
    let confirmationMessage;
    if(provider == 'freshsales' || provider == 'onehash') {
        successMessage = `CRM ${provider} Lead created successfully.`;
        confirmationMessage = `CRM ${provider} API request will be retried, it will create a Lead in CRM ${provider}`;
    } else if (provider == 'twilio' || provider == 'kaleyra' || provider == 'gupshup') {
      successMessage = `SMS using ${provider} Sent successfully.`;
      confirmationMessage = `SMS ${provider} API request will be retried, it will send a SMS using ${provider}`;
    }
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      data: {
        question: `${confirmationMessage}, Do you wish to continue?`
      }
    });

    confirmationDialog.afterClosed().subscribe((data) => {
      if (data === 'confirm') {
        this.apiService.retry(id).subscribe((data) => {

          if (data.data.response.statusCode === 200) {
            
            this.systemService.showSuccessSnackBar(successMessage, 'OK');
    
            let source = [...this.dataSource];
    
            for(let i=0;i<source.length;i++) {
              if (source[i].id === id) {
                  source[i] = data.data;
                  break;
              }
            }
    
            this.dataSource = [];
            this.dataSource = [...source];
            this.dataSource = [...this.dataSource];
          } else {
            this.systemService.handleErrorResponse(data);
          }
          
        },
        (error) => {
          this.systemService.handleErrorResponse(error);
        });
      }
    });
  }

  clickToCopy(data) {
    var abc = JSON.stringify(data);
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = abc;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }
}
