import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppMasterRoutingModule } from './app-master.routing.module';
import { AppMasterComponent } from './app-master.component';
import { UpdateValComponent } from './update-val/update-val.component';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';



@NgModule({
  declarations: [
    
    AppMasterComponent,
    UpdateValComponent 
  ],
  imports: [
    CommonModule,
    AppMasterRoutingModule,
    ImageCropperModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AppMasterModule {}