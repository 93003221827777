<div class="uploadprescription">
    <form [formGroup]="uploadPrescriptionForm" (ngSubmit)="onsubmit()">
        <div class="row">
            <p class="col-md-12">Prescription Upload</p>
            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Title</mat-label>
                <input matInput formControlName="name" placeholder="Title" autocomplete="off">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12">
                <input matInput  [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date" formControlName="test_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

        </div>
        <div class="row">
            <div class="col-md-12">
                <button mat-button type="submit" mat-flat-button color="primary" class="uploadmodalbtn">Upload
                    Prescription</button>
            </div>
        </div>
    </form>
</div>