<!-- /*==========================================
Title:  Add/ Edit Trigger
Author: Nikhil Raj A
Date:   11 May 2020
Last Change : Mobile UI Updates
===========================================*/ -->
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb *ngIf="newitem" route="content/triggershome/triggersCreate"></app-breadcrumb>
        <app-breadcrumb *ngIf="!newitem" route="content/triggershome/triggersedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary"><button mat-icon-button class="commonbackbtn"
                    [routerLink]="['/content/triggers']"><i
                        class="material-icons">keyboard_arrow_left</i></button>{{newitem ? 'New' : 'Update'}} Trigger
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Title</mat-label>
                                <input matInput placeholder="Enter the title" formControlName="title" required>
                            </mat-form-field>
                            
                            <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                <mat-label>Select Image</mat-label>
                                <input matInput (click)="openmediabox()" formControlName="image_media" required
                                    readonly>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Primary Condition</mat-label>
                                <mat-select formControlName="comorbilities" multiple required>
                                    <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                        [value]="condition.id">
                                        {{condition.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="col-md-12">
                                <div class="row">
                                    <p (click)="openinstructions()" class="instruction-p">Instructions for editor</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-md-12">
                                            <mat-label>Text</mat-label>
                                            <textarea matInput placeholder="Enter the text"
                                                formControlName="raw_content"
                                                (ngModelChange)="additionalTextChange($event)" rows="25"
                                                (keydown)="uirender()" required></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row" id="markdown-editor">
                                        <div class="col-md-12">
                                            <fieldset [disabled]="disabled">
                                                <md-editor formControlName="additionaltext" name="content"
                                                    [upload]="doUpload" [preRender]="preRenderFunc" [height]="'410px'"
                                                    mode="editor" [options]="options" required
                                                    class="mdeditorcss">
                                                </md-editor>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Trigger</button>
                        </div>


                    </div>




                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside">
                            <div class="nav-link" *ngIf="!(dataForm?.value.title == '')">
                                <mat-checkbox class="custom-frame" [disableRipple]="true" labelPosition="before">
                                    <div class="imgbox">
                                        <div class="options-image">
                                            <img *ngIf="allmedia"
                                                [src]="s3url + allmedia"
                                                class="img-responsive" />
                                        </div>
                                    </div>
                                    <span> {{dataForm?.value?.title}}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>