import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IPersistenceContainer, PersistenceService, StorageType } from 'angular-persistence';
import { ContextService } from '../../services/context.service';
import { ActivityComponent } from '../tabpartials/activity/activity.component';
import { DiaryComponent } from '../tabpartials/diary/diary.component';
import { KnowuserComponent } from '../tabpartials/knowuser/knowuser.component';
import { LabreportsComponent } from '../tabpartials/labreports/labreports.component';
import { PrescriptionsComponent } from '../tabpartials/prescriptions/prescriptions.component';
import { ProgramdetailComponent } from '../tabpartials/programdetail/programdetail.component';
import { ProgramprogressComponent } from '../tabpartials/programprogress/programprogress.component';
import { TargetsComponent } from '../tabpartials/targets/targets.component';
import { TasksComponent } from '../tabpartials/tasks/tasks.component';
import { UserdetailComponent } from '../tabpartials/userdetail/userdetail.component';



@Component({
  selector: 'app-userdetailsection',
  templateUrl: './userdetailsection.component.html',
  styleUrls: ['./userdetailsection.component.scss']
})
export class UserdetailsectionComponent implements OnInit, AfterViewInit {
  @ViewChild('tabGroup', { static: false }) tabGroup;

  public spinnerval = 50;
  public spinnermode = 'determinate';
  public spinnercolor = 'accent';
  public subscribeObjp;
  public localStoredHCData: any;
  taskDisplay: any;
  displayTask: any
  hideTaskDisplay: boolean = false;
  public tabComponentMap = [ActivityComponent, KnowuserComponent, UserdetailComponent, ProgramdetailComponent,
    ProgramprogressComponent, DiaryComponent, PrescriptionsComponent,
    TargetsComponent, LabreportsComponent, TasksComponent];

  private container: IPersistenceContainer;

  constructor(private appContext: ContextService,
    private persistenceService: PersistenceService) {
    /** Set session storage container namespace */
    this.container = this.persistenceService.createContainer(
      'com.scarlet.hc',
      { type: StorageType.LOCAL, oneUse: false }
    );
  }

  ngOnInit() {
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.taskDisplay = localStorage.getItem('taskDisplay')
    let data = localStorage.getItem("disableTask");
    if (data) {
      this.hideTaskDisplay = true;
    }
    
    if (this.taskDisplay == "true") {
      this.displayTask = "display"
    }
    this.subscribeObjp = this.appContext.changeWatcherp.subscribe(data => {
      if (this.appContext.selectedTabActivity.getValue() != null) {
        this.appContext.selectedTab.next(this.appContext.selectedTabActivity.getValue());
        this.tabGroup.selectedIndex = this.appContext.selectedTabActivity.getValue();
        this.appContext.changeWatcher.next(true);

      }
    })
    
  }
  ngOnDestroy(): void {
    this.subscribeObjp.unsubscribe();
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.appContext.selectedTab.next(tabChangeEvent.index);
    /**Store tab also */
    this.container.set("hc.selectedusertab", tabChangeEvent.index);
    /**Change watcher */
    this.appContext.changeWatcher.next(true);
  }



  ngAfterViewInit() {
    // && this.container.get("hc.selectedusertab") != "0"

    let scope = this;
    setTimeout(function () {
      if (scope.container.get("hc.selectedusertab") || scope.container.get("hc.selectedusertab") == "0") {
        this.taskDisplay = localStorage.getItem('taskDisplay')
        
        if (this.taskDisplay == "true") {
          this.displayTask = "display"
        }
        else {
          this.displayTask = "Not Display"
        }
        let index = scope.container.get("hc.selectedusertab");
        scope.appContext.selectedTab.next(index);
        let notificationStoredValue = localStorage.getItem("selectedNotification");      
        if (this.displayTask == "display") {
          scope.tabGroup.selectedIndex = 8;
        }
        else if (notificationStoredValue == 'medication_added_event' || notificationStoredValue == 'medication_updated_event' ||
          notificationStoredValue == 'medication_survey_added' || notificationStoredValue == 'prescription_upload' ||
          notificationStoredValue == 'prescription_update' || notificationStoredValue == 'reminder_added_event' ||
          notificationStoredValue == 'reminder_updated_event' || notificationStoredValue == 'medicine_added_event' ||
          notificationStoredValue == 'medicine_updated_event' || notificationStoredValue == 'caregiver_added' ||
          notificationStoredValue == 'caregiver_updated' || notificationStoredValue == 'doctor_assignment_event' ||
          notificationStoredValue == 'doctor_reassignment_event' || notificationStoredValue == 'trigger_event' ||
          notificationStoredValue == 'questionnaire_event' || notificationStoredValue == 'symptom_event' ||
          notificationStoredValue == 'adverse_reaction_event' || notificationStoredValue == 'pump_added_event' || 
          notificationStoredValue == 'pump_updated_event') {
          scope.tabGroup.selectedIndex = 7;
        }
        else if (notificationStoredValue == 'diet_plan_created_event') {
          scope.tabGroup.selectedIndex = 2; // Details Tab
        }
        else if (notificationStoredValue == 'low_bp_event' || notificationStoredValue == 'high_bp_event' || 
        notificationStoredValue == 'low_blood_sugar_event' || notificationStoredValue == 'high_blood_sugar_event' || 
        notificationStoredValue == 'blood_sugar_event'||    notificationStoredValue == 'peak_flow' ||  
        notificationStoredValue == 'activity_event' ||      notificationStoredValue == 'weight_event' ||   
        notificationStoredValue == 'water_alert_event' ||notificationStoredValue == 'meal_event' 
         || notificationStoredValue == 'infusion_site_event'  ) {
          scope.tabGroup.selectedIndex = 5;
        }
        else if (notificationStoredValue == 'lab_report_event') {
          scope.tabGroup.selectedIndex = 6;
        }
        // else if (notificationStoredValue == 'patient_note_event') {
        //   scope.tabGroup.selectedIndex = 1;
        // }
        else if(this.displayTask == "Not Display") 
        {
          localStorage.removeItem('taskDisplay')
          //localStorage.removeItem('selectedNotification')
          scope.tabGroup.selectedIndex = index;
        }
        //scope.tabGroup.selectedIndex = 8;
        /**Change watcher */
        scope.appContext.changeWatcher.next(true);
      }
    }, 100);
  }

}