
  
  <!-- doctor details -->
  <div  class="profile-wrapper">
    <!-- <div class="profile-info clearfix">
      <div class="close-icon">
        <i class="material-icons mat-dialog-close" (click)="onNoClick()">
          <img src="assets/img/dls/icons/cross.svg" alt="">
        </i>
      </div>
    </div> -->
    <div class="profile-info clearfix">
        <div class="media">
          <!-- <div class="media-left media-middle" *ngIf="image_path != null">
            <div class="image-holder">
              <img class="media-object" [src]="s3url + image_path" alt="Wellthy">
            </div>
          </div> -->
          <div class="media-body media-middle">
            <h4 class="media-heading text-capitalize">{{userView.name}}</h4>
            <p>{{userView.email}}</p>
            <!-- <div class="profile-content">{{userView.email}}</div> -->
          </div>
        </div>
        <div class="close-icon">
          <i class="material-icons mat-dialog-close" (click)="onNoClick()">
            <img src="assets/img/dls/icons/cross.svg" alt="">
          </i>
        </div>
      </div>
  
    <div class="hc-details1">
      <div class="text-over1">Details</div>
      <div class="row">
        <div class="col-md-3">
            <div class="email">
              <h5>Country code</h5>
              <p *ngIf="userView.country_code">+{{userView.country_code}}</p>
              <p *ngIf="!userView.country_code">-</p>
            </div>
          </div>
        <div class="col-md-3">
          <div class="email">
            <h5>Phone Number</h5>
            <p *ngIf="userView.phone">{{userView.phone}}</p>
            <p *ngIf="!userView.phone">-</p>
          </div>
        </div>
       
        <div class="col-md-3">
            <div class="email">
              <h5>Hardset OTP</h5>
              <p *ngIf="userView.otp">{{userView.otp}}</p>
              <p *ngIf="!userView.otp">-</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="location">
              <h5>Trial</h5>
              <p *ngIf="userView.is_dummy">{{userView.is_dummy}}</p>
              <p *ngIf="!userView.is_dummy">-</p>
            </div>
          </div>
        
      </div>
      <div class="row">
        <div class="col-md-3">
            <div class="location">
              <h5>Expiry_date</h5>
              <p *ngIf="userView.expiry_date">{{userView.expiry_date|date:'dd-MMM-yyy'}}</p>
              <p *ngIf="!userView.expiry_date">-</p>
            </div>
          </div>
        <div class="col-md-3">
          <div class="email">
            <h5>Created By</h5>
            <p *ngIf="userView.created_by">{{userView.created_by}}</p>
            <p *ngIf="!userView.created_by">-</p>
          </div>
        </div>
       
  
        <div class="col-md-3">
          <div class="location">
            <h5>Beneficiary name</h5>
            <p *ngIf="userView.beneficiary_name">{{userView.beneficiary_name}}</p>
            <p *ngIf="!userView.beneficiary_name">-</p>
          </div>
        </div>
        <div class="col-md-3">
            <div class="location">
              <h5>Requester info</h5>
              <p *ngIf="userView.requester_info">{{userView.requester_info}}</p>
              <p *ngIf="!userView.requester_info">-</p>
              
            </div>
          </div>
        
     
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="about-me mt-30">
            <h5>Reason for HardSet</h5>
            <p *ngIf="userView.reason_for_hardset_otp">{{userView.reason_for_hardset_otp}}</p>
            <p *ngIf="!userView.reason_for_hardset_otp">-</p>
          </div>
        </div>
      </div>
    </div>
  </div>