<!--==========================================
Title: Profile image
Author: Soundharya AM
Date:   22 April 2020
Last Change :  Dynamic profile image changes on side navbar
==========================================-->

<mat-drawer #drawer class="sidenav" mode="side" opened>
    <div class="smallnavigator">
        <div class="logo-holder">
            <a [routerLink]="['/content/profile']" routerLinkActive="active" class="hcprofile"><img
                    [src]="s3url + userdata.profile_image.path" class="hclogo center-block" /></a>
        </div>

        <ul class="alloption">
            <li>
                <a [routerLink]="['/content/home']" routerLinkActive="active">
                    <div class="container-a home"></div>
                    <div class="name">Home</div>
                </a>
            </li>

            <li>
                <a [routerLink]="['/content/therapy']" routerLinkActive="active" [ngClass]="activeRouteTherapy()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a therapy"></div>
                    <div class="name">Therapy</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/content/onboarding']" routerLinkActive="active" [ngClass]="activeRouteConditions()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a onboarding"></div>
                    <div class="name">Onboard</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/content/media']" routerLinkActive="active" [ngClass]="mediaRouteConditons()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a media"></div>
                    <div class="name">Media</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/content/faq/category']" routerLinkActive="active" [ngClass]="activeRouteFaq()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a onboarding misc"></div>
                    <div class="name">Misc</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/content/staticuuxid']" routerLinkActive="active" [ngClass]="activeRouteUuxid()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a uuxid"></div>
                    <div class="name">UUXID</div>
                </a>
            </li>
            <!-- <li>
                <a [routerLink]="['/content/ingredient']" routerLinkActive="active" [ngClass]="activeRouteRecipe()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a ingrediant"></div>
                    <div class="name">Nutrition</div>
                </a>
            </li> -->
        </ul>

        <div class="bottom-menu">
            <a [routerLink]="['/application']" routerLinkActive="router-link-active">
                <!-- <div class="notranslate">
                    <i class="material-icons">
                        dashboard
                    </i>
                </div>
                <div>Switch</div> -->
                <img src="assets/img/dls/icons/switch-dashboard.svg" alt="">
            </a>
        </div>
    </div>
</mat-drawer>