<form [formGroup]="stateForm">
    <mat-form-field appearance="outline" style="width: 100%;">
      <input type="text"
             matInput
             placeholder="Scarlet Search"
             formControlName="stateGroup"
             required
             [matAutocomplete]="autoGroup">
        <mat-autocomplete #autoGroup="matAutocomplete">
          <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let gp of group.names" [value]="gp.name">
              {{gp.name}}
            </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-fab class="searchbtnp" (click)="livesearch()">
        <mat-icon matSuffix>search</mat-icon>
      </button>
  </form>