<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-uuxidleftnav></app-uuxidleftnav>
        <div class="mainrightcontainersecondary">

            <app-breadcrumb route="content/dynamicLinks"></app-breadcrumb>


            <div class="containersecondary-scroll">
                <div class="row m-0">
                    <div class="col-md-9">
                        <h2 class="commontitleprimary">Home Feed</h2>
                        <form [formGroup]="dataForm" validate>
                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Level</mat-label>
                                    <mat-select formControlName="level_id" required #singleSelectTag>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmattag" ngModel
                                                (ngModelChange)="modelChangedTags()" ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content">
                                                    <div class="notranslate">
                                                        <i class="material-icons form-control-feedback">
                                                            search
                                                        </i>
                                                    </div>
                                                </div>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option class="camelcased select-tags-active"
                                            *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                            {{tag.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Knowledge levels</mat-label>
                                    <mat-select formControlName="knowledge_level" >
                                        <mat-option value="amateur">
                                            Amateur
                                        </mat-option>
                                        <mat-option value="average">
                                            Average
                                        </mat-option>
                                        <mat-option value="proficient">
                                            Proficient
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> -->

                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Therapy</mat-label>
                                    <mat-select formControlName="therapy_id" required #singleSelectTag>
                                        <mat-option class="camelcased" *ngFor="let therapy of alltherapies"
                                            [value]="therapy.id">
                                            {{therapy.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select bypass suitable module check</mat-label>
                                    <mat-select placeholder="Select bypass suitable module check" class="select-role"
                                        formControlName="bypass_suitable_module_check" required>
                                        <mat-option value="true">True </mat-option>
                                        <mat-option value="false">False</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select bypass Patient Progress</mat-label>
                                    <mat-select placeholder="Select bypass Patient Progress" class="select-role"
                                        formControlName="bypass_patient_progress" required>
                                        <mat-option value="true">True </mat-option>
                                        <mat-option value="false">False</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button mat-flat-button [disabled]="!dataForm.valid" color="primary"
                                        class="submit-btn" (click)="dataObject()">Show Data
                                        User</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-9">
                        <div *ngIf="(userProgressSubject$ | async) as userData; else loadingOrError"
                            class="userprogresssection">
                            <div
                                *ngIf="userData?.module_data && (userData?.progress_is_completed != false || userData?.progress_status == 'ongoing')">
                                <div class="clearfix"></div>
                                <div class="matcardbottomzero col-md-12">
                                    <mat-card style="padding-left: 10px;">
                                        <mat-card-header>
                                            <mat-card-title>
                                                <div class="align">
                                                    <p>{{userData?.title}}</p>
                                                    <button mat-icon-button class="copyux" style="right: 43px;"
                                                        (click)="copyUXID('level',userData?.uuxid, userData?.title)">Copy
                                                        UUXID</button>
                                                </div>

                                            </mat-card-title>
                                        </mat-card-header>
                                    </mat-card>
                                </div>
                                <mat-accordion class="col-md-12">
                                    <mat-expansion-panel *ngFor="let module of userData?.module_data; let i = index;">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="align">
                                                    <p> {{i+1}}.{{module.title}}</p>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <button class="copyux" mat-icon-button style="right: 75px;top:10px"
                                            (click)="copyUXID('module',module?.uuxid, module?.title)">Copy
                                            UUXID</button>
                                        <div *ngFor="let chapter of module.chapter_data; let p = index;"
                                            class="chaptercard">
                                            <div class="align">
                                                <span class="moveupthebox">Chapter - <p class="chapter-p">
                                                        {{chapter.title}} </p> </span>
                                                <button class="copyux" mat-icon-button style="right: 40px;"
                                                    (click)="copyUXID('chapter',chapter?.uuxid, chapter?.title)">Copy
                                                    UUXID</button>

                                            </div>

                                            <div class="subpartcards" *ngIf="chapter.subpart_data">
                                                <b style="display: block;margin-bottom: 10px;">Lesson & Quiz</b>
                                                <div *ngFor="let subpartchap of chapter.subpart_data"
                                                    [class]="subpartchap.data?.progress_is_completed == true ? 'lessonquizcard': 'greycard'">
                                                    <div class="align">
                                                        <p> {{subpartchap.data?.title}}</p>
                                                        <button class="copyux" mat-icon-button
                                                            (click)="copyUXID(subpartchap.type,subpartchap.data?.uuxid,subpartchap.data?.title)">Copy
                                                            UUXID</button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="chapter.carey_card_data">
                                                <div class="title">Carey Card</div>
                                            </div>
                                            <div *ngIf="chapter.carey_card_data"
                                                [class]="chapter.carey_card_data?.progress_is_completed == true ? 'careycard': 'greycard'">
                                                <div class="align">
                                                    <p>{{chapter.carey_card_data.title}} </p>
                                                    <button class="copyux" mat-icon-button
                                                        (click)="copyUXID('',chapter.carey_card_data?.uuxid)">Copy
                                                        UUXID</button>
                                                </div>

                                            </div>
                                            <div *ngIf="chapter.magazine_data">
                                                <div class="title">Magazine</div>
                                            </div>
                                            <div class="magazinecard magazinecard-margin" *ngIf="chapter.magazine_data"
                                                [class]="chapter.magazine_data.progress_is_completed == true ? 'magazinecard': 'greycard'">
                                                <div class="align">
                                                    <p> {{chapter.magazine_data.title}}</p>
                                                    <button class="copyux" mat-icon-button
                                                        (click)="copyUXID('magazine',chapter.magazine_data?.uuxid)">Copy
                                                        UUXID</button>
                                                </div>

                                            </div>
                                            <div *ngIf="chapter.feedback">
                                                <div class="title">Feedback</div>
                                            </div>
                                            <div class="feedback magazinecard-margin" *ngIf="chapter.feedback"
                                                [class]="chapter.feedback.progress_is_completed == true ? 'feedback': 'greycard'">
                                                <div class="align">
                                                    <p> {{chapter.feedback.title}} </p>
                                                    <button class="copyux" mat-icon-button
                                                        (click)="copyUXID('',chapter.feedback?.uuxid)">Copy
                                                        UUXID</button>
                                                </div>

                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                                <div style="clear: both;"><br /><br /></div>
                            </div>
                            <!-- <app-nodata [noRecordFound]="!userData.skills && !userData.challenges && !userData.lessons && !userData.quizzes"></app-nodata> -->
                        </div>

                        <ng-template #loadingOrError>
                            <app-loadorerror [loadError]="loadingError$ | async"
                                [loadSubject]="loadingSubject$ | async">
                            </app-loadorerror>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>