<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-hcassignleftnav></app-hcassignleftnav>

    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/hcassignhome"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          User Assignment
        </h3>
      </div>

      <div class="col-md-12">
        <form>
          <div class="form-group has-feedback has-search">
            <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
              placeholder="Search patient here">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>

      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
            <ng-container matColumnDef="name">
              <th scope="col" mat-header-cell *matHeaderCellDef> Patient Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name?element.name:'-'}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th scope="col" mat-header-cell *matHeaderCellDef> Phone Number </th>
              <td mat-cell *matCellDef="let element">+{{element.country_code}}-{{element.phone}} </td>
            </ng-container>
           
            <ng-container matColumnDef="activationCode">
              <th scope="col" mat-header-cell *matHeaderCellDef> Activation Code </th>
              <td mat-cell *matCellDef="let element">   {{element.activation_code?element.activation_code:'-'}} </td>
            </ng-container>

            <ng-container matColumnDef="conditions">
              <th scope="col" mat-header-cell *matHeaderCellDef> Primary Condition </th>
              <td mat-cell *matCellDef="let element">
                {{element.master_condition_data?element.master_condition_data.name:'-'}} </td>
            </ng-container>

            <ng-container matColumnDef="healthcoach">
              <th scope="col" mat-header-cell *matHeaderCellDef> Healthcoach </th>
              <td mat-cell *matCellDef="let element"> {{element.hc_data?element.hc_data.name:'-'}} </td>
            </ng-container>


            <ng-container matColumnDef="action">
              <th scope="col" mat-header-cell *matHeaderCellDef style="width: 240px;"> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button (click)="changeHealthCoach(element)" tooltip="Change healthcoach">
                    <img src="assets/img/dls/admin-table/Icon_Change Healthcoach.svg" alt="">
                  </button>
                  <button mat-button (click)="viewProfile(element)" tooltip="View profile">
                    <img src="assets/img/dls/admin-table/Icon_View profile.svg" alt="">
                  </button>
                  <button mat-button (click)="viewHistory(element)" disableRipple tooltip="Transfer history" *ngIf="element.show_history === true">
                    <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt="">
                  </button>

                  <button mat-button  disableRipple  *ngIf="element.show_history === false"   matTooltip="This user is not having any Transfer  history" style="opacity:0.5;cursor: not-allowed;"><!-- [disabled]="element.show_history === true" [ngClass]="{disabled:element.show_history == true }" -->
                    <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt=""  disabled >                 
                  </button>

                </div>
              </td>
            </ng-container>
            <!-- 
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" *ngIf="element.name"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button (click)="changeHealthCoach(element)" disableRipple
                        class="no-hover-effect changehc-btn">Change
                        Healthcoach
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <button mat-button (click)="viewProfile(element)" disableRipple style="width: 130px;"
                        class="no-hover-effect">View Profile
                      </button>
                      <button mat-button (click)="viewHistory(element.id)" disableRipple
                        class="no-hover-effect view-btn">View Transfer History
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>