import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdminleftnavModule } from '../../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../../shared/adminsectionleftnav/adminsectionleftnav.module';
import { HcassignleftnavModule } from '../../shared/hcassignleftnav/hcassignleftnav.module';
import { SharedModule } from './../../../shared/shared.module';
import { ActivationComponent } from './activation.component';
import { ActivationRoutingModule } from './activation.routing.module';
import { CreatecodegenComponent } from './createcodegen/createcodegen.component';
import { EdithclistComponent } from './edithclist/edithclist.component';
import { GeneratecodebyrequestComponent } from './generatecodebyrequest/generatecodebyrequest.component';
import { ViewallactivationComponent } from './viewallactivation/viewallactivation.component';


@NgModule({
  declarations: [
    
    ActivationComponent,
    CreatecodegenComponent,
    EdithclistComponent,
    ViewallactivationComponent,
    GeneratecodebyrequestComponent
    
  ],
  imports: [
    CommonModule,
    ActivationRoutingModule,
    ImageCropperModule,
    HcassignleftnavModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class ActivationModule {}