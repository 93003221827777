/*==========================================
Title: Table search
Author: Soundharya AM
Date:   May 08, 2020
Last Change : Front-end Table search 
===========================================*/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../services/commonservice/commonservice.service';
import { AdmindeletemodalComponent } from '../shared/admindeletemodal/admindeletemodal.component';
import { UserprofileviewComponent } from '../shared/userprofileview/userprofileview.component';
import { HistoryhardsetotpComponent } from './historyhardsetotp/historyhardsetotp.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';

@Component({
  selector: 'app-hardsetotp',
  templateUrl: './hardsetotp.component.html',
  styleUrls: ['./hardsetotp.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class HardsetotpComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  displayedColumns: string[] = ['phone', 'otp', 'expiry_date', 'createdBy','created_at', 'action'];
  public newsection: any = 'new';
  public endoflist: boolean = false;
  public onit: boolean = true;
  public tabulardata: any;
  dataSource: any;
  public page: number = 1;
  public lastpage: number = 0;
  lastSearch: any;
  public status = "active";
  activecls = 'active';
  public isactive = 'all';
  public typeFilters: any;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public showButtonLoader: boolean = false;

  constructor(private browserTitle: Title, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Hard Reset OTP');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  statusConvert(e) {
    return e.replace(/_/g, " ").toLowerCase();
  }


  // To Delete Number 
  // ----------------------------

  delete(id) {
    this.commonService.hardDelete('hardsetotp', id)
      .subscribe(
        (res: any) => this.onSuccessDelete(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onSuccessDelete(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------


  // To Open Dialog Box for Delete 
  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(AdmindeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected otp has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }
  viewhistory(element): void {

    const history = this.dialog.open(HistoryhardsetotpComponent, {
      width: '1000px',
      data: {
        element: element
      }
    });

    history.afterClosed().subscribe(result => {
    });
  }
  viewProfile(element): void {
    const profile = this.dialog.open(ViewprofileComponent, {
      width: '750px',
      data: {
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
    });
  }
  // ----------------------------


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
    }
    else {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
    }

    this.search();
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  // To Search OTP List
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;

      this.commonService.getAllHelperOTP(this.searchcriteria, this.page.toString(), "20",this.status)
        .subscribe(
          (res: any) => this.onSearchSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSearchSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
      }
    }
    else {
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }

  }

}

export interface PeriodicElement {
  otp: string;
  phone: string;
  expiry_date: string;
}