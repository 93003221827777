import { CommonserviceService } from './../../../admin/services/commonservice/commonservice.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-createquestionnair',
  templateUrl: './createquestionnair.component.html',
  styleUrls: ['./createquestionnair.component.scss']
})

export class CreatequestionnairComponent implements OnInit {
  chooseMedia: any;
  public allmedia: any;
  optioncount: any;
  optioncountbool: boolean = false;
  public totoption: any;
  questioncount: any;
  sumscorebool: boolean;
  alltags: any;
  rangecount: any;
  public language: any;
  public selfparentide: any;
  public langid: any;
  public selfparentid: any;
  public newitem: boolean = true;
  public childid: any;
  public quesdata: any;
  isLinear = false;
  newQuestion:boolean=false;
  currentrange: any;
  public searchmattag: FormControl = new FormControl();
  public showButtonLoader: boolean = false;
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public number:any = [];

  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    image_media: ['', Validators.required],
    type: ['', Validators.required],
    created_by_data_fk: [''],
    question_count: ["", Validators.required],
    repeat_on_day: [],
    skip_repeat_on_day: [],
    highest_score: [],
    lowest_score: [],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    questions: this.fb.array([]),
    tags: [[], Validators.required],
  });
  public rangeForm = this.fb.group({
    select_range: ["", Validators.required],
    range: this.fb.array([])
  })

  @ViewChild(MatHorizontalStepper, { static: false }) stepper: MatHorizontalStepper;

  initQuestionArray() {
    return this.fb.group({
      question: this.fb.control(""),
      is_multi_select: this.fb.control(""),
      option_count: this.fb.control(""),
      options: this.fb.array([])
    })
  }

  initRangeArray() {
    return this.fb.group({
      category_color: this.fb.control("#f1f1f1"),
      upper_limit: this.fb.control(""),
      lower_limit: this.fb.control(""),
      title: this.fb.control(""),
      category: this.fb.control(""),
      feedback_count: this.fb.control(""),
      feedback: this.fb.array([])
    })
  }

  modelChangedTags() {
    this.filterTags();
  }

  getControls() {
    return (<FormArray>this.dataForm.get('questions')).controls;
  }

  initArray() {
    return this.fb.group({
      text: this.fb.control(""),
      score: this.fb.control("")
    })
  }

  initlist() {
    return this.fb.group({
      list: this.fb.control("")
    })
  }

  initAddlist(e) {
    return this.fb.group({
      list: this.fb.control(e)
    })
  }

  constructor(private browserTitle: Title, private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService,private commonService:CommonserviceService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Questionnaire');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe((params) => {
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.selfparentide.setValue(null)
        this.newitem = true;
        this.newQuestion=true;
        this.language.setValue(this.langid);
      }
      else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.getQuesData(this.childid);
          this.newitem = false;
          this.newQuestion=false;
        }
        else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getQuesData(this.childid);
          this.newQuestion=false;
        }
        else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getQuesData(params.chid);
          this.newQuestion=false;
        }
      }
      this.getTags();
      this.getnumber();
    });
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------

  questionCount(e) {
    this.questioncount = e;
    const control1 = <FormArray>this.dataForm.controls['questions'];
    while (control1.length !== 0) {
      control1.removeAt(0);
    }
    for (let k = 0; k < e; k++) {
      control1.push(this.initQuestionArray());
    }
  }

  feedbackCount(e, index) {
    const control2 = (<FormArray>this.rangeForm.controls['range']).at(index).get('feedback') as FormArray;
    while (control2.length !== 0) {
      control2.removeAt(0);
    }
    for (let k = 0; k < e; k++) {
      control2.push(this.initlist());
    }
  }

  questionOptionCount(e, index) {
    const control2 = (<FormArray>this.dataForm.controls['questions']).at(index).get('options') as FormArray;
    while (control2.length !== 0) {
      control2.removeAt(0);
    }
    for (let k = 0; k < e; k++) {
      control2.push(this.initArray());
    }
  }

  rangeCount(e) {
    this.rangecount = e;
    const range_control = <FormArray>this.rangeForm.controls['range'];
    while (range_control.length !== 0) {
      range_control.removeAt(0);
    }
    for (let k = 0; k < e; k++) {
      range_control.push(this.initRangeArray());
    }
    this.setLimit();
  }

  setLimit() {
    const range_control = <FormArray>this.rangeForm.controls['range'];
    var lowscore = parseInt(this.dataForm.controls['lowest_score'].value);
    this.currentrange = this.dataForm.controls['highest_score'].value - this.dataForm.controls['lowest_score'].value;
    for (var i = 0; i < range_control.value.length; i++) {
      range_control.at(i).get('lower_limit').setValue((this.currentrange / range_control.value.length) * i + lowscore);
      range_control.at(i).get('upper_limit').setValue((this.currentrange / range_control.value.length) * i + lowscore + (this.currentrange / range_control.value.length));
    }
  }

  getScore(ip) {
    const control2 = (<FormArray>this.dataForm.controls['questions']).at(ip).get('options') as FormArray;
    var array = [];
    for (var k = 0; k < control2.length; k++) {
      array[k] = k + 1;
    }
    return array;
  }


  initQuestionOptions() {
    if (this.optioncountbool) {
      const optioncount = <FormControl>this.dataForm.controls['option_count'];
      const control2 = <FormArray>this.dataForm.controls['options'];
      while (control2.length !== 0) {
        control2.removeAt(0);
      }
      for (let k = 0; k < this.optioncount; k++) {
        control2.push(this.initArray());
      }
    }
  }

  setlowhigh() {
    var totallow = 0;
    var totalhigh = 0
    var questionlowcount = 0;
    var questionhighcount = 0;
    var postdata = this.dataForm.value;
    postdata.questions.forEach(el => {
      delete el.option_count;
      el.options.forEach(op => {
        if (questionlowcount == 0) { questionlowcount = op.score; }
        else {
          if (questionlowcount > op.score) {
            questionlowcount = op.score;
          }
        }
        if (questionhighcount == 0) { questionhighcount = op.score; }
        else {
          if (questionhighcount < op.score) {
            questionhighcount = op.score;
          }
        }
      });
      totallow = totallow + questionlowcount;
      totalhigh = totalhigh + questionhighcount;
      questionlowcount = 0;
      questionhighcount = 0;
    });
    if (totallow == totalhigh) {
      this.systemService.showSnackBar("High Score and Low Score is same, Change it", "Ok");
    }
    else {
      if ((<FormControl>this.dataForm.controls['lowest_score']).value == totallow && (<FormControl>this.dataForm.controls['highest_score']).value == totalhigh) {
        this.stepper.next();
      }
      else {
        (<FormControl>this.dataForm.controls['lowest_score']).setValue(totallow);
        (<FormControl>this.dataForm.controls['highest_score']).setValue(totalhigh);
        this.rangeForm.reset();
        this.stepper.next();
      }
    }
  }


  // Submitting Questionnair Data
  // ----------------------------

  dataObject() {
    var postdata;
    postdata = this.dataForm.value;
    var newrange = this.rangeForm.value.range;
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let questions = this.commonService.isHTML( this.dataForm.value.questions);
    let range = this.commonService.isHTML( this.rangeForm.value.range);

    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }

    if(questions  === true){
      this.dataForm.controls.questions.patchValue('')
      return;
    }
    if(range  === true){
      this.rangeForm.controls.range.patchValue('')
      return;
    }
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.question_count = postdata.question_count.toString();
    postdata.repeat_on_day = postdata.repeat_on_day.toString();
    postdata.skip_repeat_on_day = postdata.skip_repeat_on_day.toString();
    postdata.highest_score = postdata.highest_score.toString();
    postdata.lowest_score = postdata.lowest_score.toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();

  
    if (typeof postdata.image_media != "string") {
      postdata.image_media = postdata.image_media.toString();
    }
    for (var k = 0; k < this.rangeForm.value.range.length; k++) {
      var kpi = [];
      for (var j = 0; j < this.rangeForm.value.range[k].feedback.length; j++) {
        kpi.push(this.rangeForm.value.range[k].feedback[j].list);
      }
      newrange[k].feedback = kpi;
      kpi = [];
    }
    postdata.range = JSON.stringify(newrange);
    postdata.questions = JSON.stringify(postdata.questions);
    postdata.generate_feedback = "true";
    if (this.newitem == true) {
      this.contentService.postToBlade(postdata, 'questionnaire')
      .subscribe((res: any) => this.onCreateSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
    }
    else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'questionnaire')
      .subscribe((res: any) => this.onUpdateSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Questionnair has been added"), "OK");
    this.router.navigate(['content/questionnair']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Questionnair has been updated"), "OK");
    this.router.navigate(['content/questionnair']);
  }

  // ----------------------------


  // To Get Questions Data
  // ----------------------------

  getQuesData(ide) {
    this.contentService.getOneDataById(ide, "questionnaire")
    .subscribe((res: any) => this.getQuesDataSuccess((res as any).data),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getQuesDataSuccess(data) {
    this.quesdata = data;
    const control1 = <FormArray>this.dataForm.controls['questions'];
    for (var k = 0; k < this.quesdata.questions.length; k++) {
      control1.push(this.initQuestionArray());
      this.quesdata.questions[k].options.forEach(el => {
        const control2 = (<FormArray>this.dataForm.controls['questions']).at(k).get('options') as FormArray;
        const control3 = (<FormArray>this.dataForm.controls['questions']).at(k).get('option_count') as FormArray;
        control3.setValue(this.quesdata.questions[k].options.length);
        control2.push(this.initArray());
      });
    }
    this.dataForm.patchValue(this.quesdata);
    var imageid = <FormArray>this.dataForm.controls.image_media;
    imageid.setValue(this.quesdata.imageMediaId);
    for (var k = 0; k < this.quesdata.range.length; k++) {
      const control2 = (<FormArray>this.rangeForm.controls['range']);
      control2.push(this.initRangeArray());
    }

    for (var p = 0; p < this.quesdata.range.length; p++) {

      const control2 = (<FormArray>this.rangeForm.controls['range']).at(p).get('feedback') as FormArray;
      const control23 = (<FormArray>this.rangeForm.controls['range']).at(p).get('feedback_count');
      this.quesdata.range[p].feedback.forEach(element => {
        control2.push(this.initAddlist(element));
      });
      control23.setValue(this.quesdata.range[p].feedback.length);
    }

    this.rangeForm.controls['range'].patchValue(this.quesdata.range);
    this.rangeForm.controls['select_range'].setValue(this.quesdata.range.length);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
  }

  // ----------------------------

  getnumber(){
    for (var input = 2; input <= 50; input++) {
      this.number.push(input);
     }
     
  }

}
