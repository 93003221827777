<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>

    <div class="sidenav-content">

        <div class="row">
            <div class="col-md-3">

                <div class="user-list">
                    <form class="search-box">
                        <div class="form-group has-feedback has-search">
                            <input class="form-control" placeholder="Search">
                            <button mat-fab class="searchbtn" style="display: none;">
                                <mat-icon matSuffix>search</mat-icon>
                            </button>
                        </div>
                    </form>


                    <ul class="list-group">
                        <li class="list-group-item">
                            Alexa Leech
                        </li>
                        <li class="list-group-item">
                            Alexa Leech
                        </li>
                        <li class="list-group-item">
                            Alexa Leech
                        </li>

                    </ul>

                </div>

            </div>
            <div class="col-md-5">
                <div class="chat-layout">
                    <div class="nopatient">
                        <h3>No patient selected</h3>
                    </div>

                    <div class="details">
                        <div class="date">
                            03 Mar 2021
                        </div>
                        <div class="template">
                            <h6 class="text-capitalize">template</h6>
                            <p>Hello Alexa, since you are diabetic it is better to stay active. Lets start with 10 mins
                                of
                                walking everyday.</p>
                            <button class="btn btn-disabled">Call now</button>


                            <div class="call">
                                <div class="calling-ui">
                                    <div class="title">
                                        <h5>Alexa Leech</h5>
                                        <p>
                                            <img src="assets/img/dls/icons/calling/calling.svg" class="img-responsive d-inline-flex mr-5"
                                                alt="Wellthy"> 02:30 mins
                                        </p>
                                    </div>
                                    <div class="pause">
                                        <img src="assets/img/dls/icons/calling/pause.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </div>
                                    <div class="mute">
                                        <img src="assets/img/dls/icons/calling/mute.svg" class="img-responsive"
                                            alt="Wellthy">
                                    </div>
                                    <div class="mute">
                                        <img src="assets/img/dls/icons/calling/more-verticle.svg" class="img-responsive"
                                            alt="Wellthy">

                                    </div>
                                    <div class="cut">
                                        <img src="assets/img/dls/icons/calling/cut-call.svg" class="img-responsive"
                                            alt="Wellthy">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-md-4">

                <div class="task-details">


                    <div class="task-wrapper">
                        <div class="text-over">
                            <h3>Task details</h3>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h6>Task type</h6>
                                <div class="tasktype-wrapper">
                                    <div class="task-type">Send broadcast to patient</div>
                                    <div class="task-type">Get them to log 30 mins of activity this week</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <h6>Priority</h6>
                            <div class="preview-sub text-capitalize">
                                High
                            </div>

                        </div>
                        <div class="col-md-4">
                            <h6>Scope</h6>
                            <div class="preview-sub text-capitalize">Global
                            </div>

                        </div>
                        <div class="col-md-4">
                            <h6>Expiry</h6>
                            <div class="preview-sub">21 Aug,2021
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <h6>Condition</h6>
                            <div class="preview-sub">
                                Patient who is diabetic and has logged less than 15
                                mins of activity last week.
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- commented it since the requirement is not freezed -->
                <!-- <div class="releted-notes">
                    <h5>Related notes</h5>
                    <button mat-icon-button class="plusrectangletransparent mb-15">
                        <mat-icon>add</mat-icon>
                    </button>
                </div> -->
            </div>
        </div>

    </div>

</mat-drawer-container>