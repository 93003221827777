import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { TriggersComponent } from './triggers.component';
import { EdittriggerComponent } from './edittrigger/edittrigger.component';
import { TriggersmappingComponent } from './triggersmapping/triggersmapping.component';
import { CreatetriggermappingComponent } from './triggersmapping/createtriggermapping/createtriggermapping.component';


const routes: Routes = [
  { path: '', component: TriggersComponent, canActivate: [ContentGuardGuard] },
  { path: 'triggers/edit/:id/:langid/:chid', component: EdittriggerComponent, canActivate: [ContentGuardGuard] },
  { path: 'trigger/mapping', component: TriggersmappingComponent, canActivate: [ContentGuardGuard] },
  { path: 'triggers/mapping/create/:condid/:mappingid', component: CreatetriggermappingComponent, canActivate: [ContentGuardGuard] },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TriggersRoutingModule { }
