<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb *ngIf="!newitem" route="content/mealhome/mealedit"></app-breadcrumb>
        <app-breadcrumb *ngIf="newitem" route="content/mealhome/mealCreate"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Meal
            </h3>
        </div>
        <div class="col-md-12 scroll-area">
            <div class="col-md-10 form-wrapper">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Food Name</mat-label>
                                    <input matInput placeholder="Enter Name" formControlName="food_name" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Common names</mat-label>
                                    <input matInput placeholder="Enter the Common names" formControlName="common_names"
                                        required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">

                                    <mat-select placeholder="Food Group" formControlName="food_group" required>
                                        <mat-option value="nonveg">Non-veg (contains meat or fish)</mat-option>
                                        <mat-option value="egg">Egg (contains egg)</mat-option>
                                        <mat-option value="veg">Veg (does not contain meat, fish egg)</mat-option>
                                        <mat-option value="vegan"> Vegan (a veg dish that does not contain dairy)
                                        </mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield ">
                                    <mat-label>Food Type</mat-label>
                                    <mat-select placeholder="Food Time" formControlName="meal_time" required>

                                        <mat-option value="B">breakfast</mat-option>
                                        <mat-option value="L">Lunch</mat-option>
                                        <mat-option value="S">Snack</mat-option>
                                        <mat-option value="D">Dinner</mat-option>
                                        
                                    </mat-select>

                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Unit</mat-label>
                                    <input matInput placeholder="Unit (Eg bowl, plate, number (count) etc)"
                                        formControlName="unit" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Sub Description of unit name</mat-label>
                                    <input matInput placeholder="Enter Sub Description of unit name "
                                        formControlName="unit_serving" required>

                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>unit value</mat-label>
                                    <input matInput placeholder="Enter unit value " formControlName="unit_measure"
                                        required>

                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Food Class</mat-label>
                                    <input matInput placeholder="E.g. full dish, meat, curry, condiment, bread etc"
                                        formControlName="food_class" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Cuisine</mat-label>
                                    <input matInput
                                        placeholder="Cuisine (Country or region it originated from and belongs to)"
                                        formControlName="cuisine" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">

                                    <mat-select placeholder="Food Type" formControlName="course_type" required>
                                        <mat-option value="Main">Main</mat-option>
                                        <mat-option value="side">side</mat-option>
                                        <mat-option value="beverage">beverage</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Priority 1</mat-label>
                                    <input matInput placeholder="Priority 1 " formControlName="priority1" required>
                                </mat-form-field>
                            </div>




                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Priority 2</mat-label>
                                    <input matInput placeholder="Priority 2 " formControlName="priority2" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Priority 3</mat-label>
                                    <input matInput placeholder="Priority 3" formControlName="priority3" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Cook Time (In minutes)</mat-label>
                                    <input type="input" matInput placeholder="Enter Cook time with unit (Eg 22min)"
                                        formControlName="cook_time" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Prepration Time before cooking (In minutes)</mat-label>
                                    <input type="input" matInput placeholder="Preparation time with unit(Eg 22min)"
                                        formControlName="prep_time" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Is Packaged</mat-label>
                                    <mat-select placeholder="Is Packaged*" formControlName="is_packaged" required>
                                        <mat-option value="true">Yes</mat-option>
                                        <mat-option value="false">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Is Processed</mat-label>
                                    <mat-select placeholder="Is Processed*" formControlName="is_processed" required>
                                        <mat-option value="true">Yes</mat-option>
                                        <mat-option value="false">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Is End Product</mat-label>
                                    <mat-select placeholder="Is End Product*" formControlName="is_end_product" required>
                                        <mat-option value="true">Yes</mat-option>
                                        <mat-option value="false">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Is Searchable</mat-label>
                                    <mat-select placeholder="Is Searchable*" formControlName="is_searchable" required>
                                        <mat-option value="true">Yes</mat-option>
                                        <mat-option value="false">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Is Recommendable Food</mat-label>
                                    <mat-select placeholder="Is Recommendable Food*" formControlName="is_recommendable"
                                        required>
                                        <mat-option value="true">Yes</mat-option>
                                        <mat-option value="false">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>



                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Recipe source link</mat-label>
                                    <input matInput placeholder="Recipe source link"
                                        formControlName="recipe_source_link" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Number of servings</mat-label>
                                    <input matInput placeholder="Number of servings (as per recipe URL/source)"
                                        formControlName="num_servings" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput placeholder="Description of food as per recipe source"
                                        formControlName="description" required></textarea>
                                </mat-form-field>
                            </div>

                            <div class="clearfix"></div>
                            <h2 class="commontitleprimary sub-main">Nutrition Data</h2>

                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Calories Calculated For (In grams)</mat-label>
                                    <input type="number" matInput placeholder="Calories Calculated For"
                                        formControlName="calories_calculated_for" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Calories kcal(In Kcal)</mat-label>
                                    <input type="number" matInput placeholder="Calories kcal"
                                        formControlName="calories_kcal" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Carbohydrates(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter Carbohydrates in g"
                                        formControlName="carbohydrates" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Proteins(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter proteins in g"
                                        formControlName="proteins" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Fats(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter fats in g " formControlName="fats"
                                        required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Fibers(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter fibers in g "
                                        formControlName="fibers" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Total Sugar(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter Total Sugar in g "
                                        formControlName="total_sugar" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Sodium(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter sodium in mg "
                                        formControlName="sodium" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Added Sugar(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter Added Sugar in g "
                                        formControlName="added_sugar" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Fatty Acid Saturated(In grams) </mat-label>
                                    <input type="number" matInput placeholder="Enter Fatty Acid Saturated in g "
                                        formControlName="fatty_acid_saturated" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Fatty Acid Monounsaturated</mat-label>
                                    <input type="number" matInput placeholder="Enter Fatty Acid Monounsaturated in g"
                                        formControlName="fatty_acid_monounsaturated" required>
                                </mat-form-field>
                            </div>


                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Fatty Acid Polyunsaturated(In grams)</mat-label>
                                    <input type="number" matInput placeholder="Enter Fatty Acid Polyunsaturated in g"
                                        formControlName="fatty_acid_polyunsaturated" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Potassium</mat-label>
                                    <input type="number" matInput placeholder="Enter potassium in mg"
                                        formControlName="potassium" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Cholesterol(In Mg)</mat-label>
                                    <input type="number" matInput placeholder="Enter cholesterol in mg"
                                        formControlName="cholesterol" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>GI Est Value(In abs)</mat-label>
                                    <input type="number" matInput placeholder="Enter GI Est value in abs"
                                        formControlName="gi_est" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>GI der Value(In abs)</mat-label>
                                    <input type="number" matInput placeholder="Enter GI der value in abs"
                                        formControlName="gi_der" required>
                                </mat-form-field>
                            </div>

                            <div class="clearfix"></div>
                            <h2 class="commontitleprimary sub-main">Alias Name </h2>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Count</mat-label>
                                    <mat-select formControlName="alias_name_options_count"
                                        (selectionChange)="generateOptions($event.value)" required>
                                        <mat-option [value]="2">2</mat-option>
                                        <mat-option [value]="3">3</mat-option>
                                        <mat-option [value]="4">4 </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft p-0">
                                <div formArrayName="food_alias" [class]="addingClass">
                                    <div *ngFor="let option of dataForm.get('food_alias')['controls']; let j=index;"
                                        [formGroupName]="j" class="boxthekeyvalue">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>Alias {{j+1}}</p>
                                            </div>
                                            <div class="col-md-12">
                                                <mat-form-field appearance="outline" class="col-md-6">
                                                    <mat-label>Alias name {{j+1}}</mat-label>
                                                    <input class="textName" matInput formControlName="alias" required>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="col-md-6">
                                                    <mat-label>Primary Name</mat-label>
                                                    <mat-select placeholder="Primary Name*"
                                                        formControlName="is_primary">
                                                        <mat-option value="true">Yes</mat-option>
                                                        <mat-option value="false">No</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 zeropadleft">
                                <button mat-flat-button type="submit" color="primary" [disabled]="!dataForm.valid"
                                    class="centerbtn" style="margin-top:15px;">{{newitem ? 'New' : 'Update'}}
                                    Meal</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>