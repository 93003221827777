
<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-faqleftnav></app-faqleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="content/conditionlist"></app-breadcrumb>

      <div class="col-md-12">
        <div class="row">

            <div class="col-md-12" style="top: 10px;">
                <form>
                    <div class="form-group has-feedback has-search">
                        <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}"
                            class="form-control" placeholder="Search">
                        <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                            <mat-icon matSuffix>search</mat-icon>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

      <!-- <div class="col-md-12">
        <h3 class="commontitleprimary">
          Condition List
        </h3>
      </div> -->
      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">

          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table mapping-table">


            <ng-container matColumnDef="title" class="col-width">
              <th mat-header-cell *matHeaderCellDef style="width: 130px;"> Condition Title </th>
              <td mat-cell class="camelcased" *matCellDef="let element"> {{element.title}} </td>
            </ng-container>
            <ng-container matColumnDef="detailed_text" class="col-width">
              <th mat-header-cell *matHeaderCellDef style="width: 130px;"> Detailed Text </th>
              <td mat-cell class="camelcased" *matCellDef="let element"> {{element.detailed_text}} </td>
            </ng-container>
<!-- 
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 50px;"
                        [routerLink]="['/content/conditionlist/edit',element.id]" class="no-hover-effect last-btn">Edit
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                  <div class="action-button-row">
                      <button mat-button disableRipple class="no-hover-effect"  [matMenuTriggerFor]="menu3">
                          <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit Condition"
                              alt="">
                      </button>
                     
                      <mat-menu #menu3="matMenu">
                          <button mat-menu-item *ngFor="let language of alllanguages"
                          [routerLink]="['/content/conditionlist/edit',element.id]"><span
                                  *ngIf="getChildID(element, language.id) || language.id == 1">
                                  <mat-icon>done</mat-icon>
                              </span>{{language.name}}</button>
                      </mat-menu>
                  </div>
              </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">


          </table>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>