<div class="disclaimer">
    <h2 mat-dialog-title class="text-center">Términos y Condiciones de uso del Dashboard </h2>
    <div class="mat-typography content">
  
      <p>Actualizado el 8 de junio de 2021
      </p>
      <p>Bienvenido a la herramienta de monitorización de PAHcare (el “Dashboard”). Este Dashboard es operada por Ferrer Internacional, S.A., con domicilio social en Avda. Diagonal 549, 08029 Barcelona, España (en adelante denominada la “Empresa” o “nos” o “nosotros”). Le ofrecemos este Dashboard como parte integral de nuestro programa de apoyo al paciente, que proponemos a los pacientes con hipertensión arterial pulmonar. A través de este programa prestaremos a los pacientes una variedad de servicios, mediante una combinación de inteligencia artificial y asesores de salud en el contexto de las modificaciones de la salud y el estilo de vida, incluido la ayuda de asesores humanos, el apoyo de contenidos estructurados, el apoyo de asesores virtuales y otros servicios relacionados que podamos especificar ocasionalmente (en conjunto, los “Servicios”). El Dashboard es una herramienta para los médicos con el fin de visualizar y monitorizar el estado de salud del paciente a base de los datos e informaciones introducidas por el mismo paciente al utilizar la Plataforma.</p>
      <p>Ferrer es el fabricante legal de la Plataforma y sus partes integrales como el Dashboard, mientras que Wellthy Therapeutics es el desarrollador conjunto.</p>
      <p>PAHcare es un producto sanitario según la definición de la Directiva 93/42/CEE </p>
      <p>
        Esta Plataforma está destinada a facilitar la gestión de la enfermedad en pacientes con Hipertensión Arterial Pulmonar (HAP).
      </p>
      <p>Fabricante: Ferrer Internacional, S.A., Gran Vía de Carlos III, 94 08028 Barcelona, España. </p>
        <p dir="ltr">
          <img 
            src="assets/img/celogo.jpg"
            width="70" height="49" />
        </p>
     
      
      <h4>1. Aplicabilidad y modificación de las condiciones de uso</h4>
  
      <p>a) Estos términos y condiciones de uso (los “Términos y Condiciones de Uso”), se aplican a todos los médicos (en adelante “usuario” o “usted”) que usan el Dashboard. Le rogamos que lea detenidamente estos Términos y Condiciones de Uso antes de utilizar el Dashboard. </p>
      <p>b) Su uso del Dashboard significa su aceptación de los Términos y Condiciones de Uso y su aceptación de estar legalmente obligado por el mismo.</p>
      <p>c) Nos reservamos el derecho a modificar estos Términos y Condiciones de Uso cuando sea razonablemente necesario y de forma razonable para el usuario de acuerdo con las leyes aplicables. En particular, nos reservamos el derecho de modificar estos Términos y Condiciones de Uso en caso de dificultades, para cumplir con las leyes aplicables y/o cuando las modificaciones no produzcan ninguna desventaja para el usuario. Para asegurarnos de que está al corriente de cualquier cambio, le enviaremos la correspondiente notificación de modificación de los Términos y Condiciones de Uso a la misma dirección de correo electrónico que usted ha usado para acceder al Dashboard. En cualquier caso, si introducimos algún cambio en los Términos y Condiciones de Uso , usted tendrá derecho a rescindirlo, dándose de baja del Dashboard, sin coste alguno</p>
      <p>d) Nada de lo dispuesto en los Términos y Condiciones de Uso debe interpretarse como una concesión de derechos a terceros beneficiarios.</p>
      

      <h4>2. Acceso</h4>
      <p>a) El Dashboard de PAHcare está desarrollada para visualizar el progreso del paciente en la gestión de su enfermedad; la hipertensión arterial pulmonar (HAP). Se puede acceder a través del siguiente enlace <a href="https://console.wellthy.app/#/login">https://console.wellthy.app/#/login</a></p>
      <p>b) Ferrer facilitara el nombre de usuario y la contraseña para poder acceder al Dashboard.</p>
      <p>c) Una vez entrada y aceptados los “Términos y Condiciones de Uso”, el Dashboard estará listo para ser utilizado con el fin de visualizar de forma segura información de salud introducido por los pacientes para monitorizar la gestión de la HAP por parte de los pacientes. </p>
     
      <h4>3. Advertencias</h4>
       <p>-  La Plataforma PAHcare™ no pretende sustituir la atención prestada por un profesional sanitario autorizado, incluidas las prescripciones, el diagnóstico o el tratamiento.</p>
      <p>-  El Dashboard no está pensado para analizar los datos introducidos por el paciente en la Plataforma ni recomendar acciones o decisiones médicas. El Dashboard solo visualiza de forma adecuada esos datos y facilita la monitorización del progreso del paciente en gestionar su enfermedad.</p>
      <p>-  Ni la Plataforma, ni el Dashboard no están pensados para soportar o transmitir llamadas o comunicaciones de emergencia o en tiempo crítico a ningún tipo de hospital, fuerzas del orden, unidad de atención médica o cualquier otro tipo de servicio de emergencia o en tiempo crítico.</p>
  
      <h4>4. Inclusión o Exclusión de los pacientes</h4>
      <p>La decisión de ofrecer o no ofrecer el uso de la Plataforma PAHcare a los pacientes queda a criterio del médico tratante (usted), que puede guiarse por las siguientes pautas:</p>
      <p>-Criterios de inclusión: </p>
      <li>Ausencia de comorbilidades complicadas: diagnosticado de hipertensión arterial pulmonar (HAP) idiopática, hereditaria o asociada (enfermedad del tejido conectivo, fármacos o toxinas) según las recomendaciones de consenso de la Organización Mundial de la Salud, es decir, “Hipertensión pulmonar - Grupo 1 de la OMS”</li>
      <li>Régimen de medicación específico para la HAP clínicamente estable durante los tres meses anteriores a la inscripción. Se incluirán los sujetos con un solo ajuste de diuréticos en los tres meses anteriores.</li>
      <li>Cateterismo cardíaco en los últimos 13 meses consistente con HAP, específicamente presión arterial pulmonar media (PAPm) ≥25 mmHg (en reposo), presión capilar pulmonar en cuña (PCWP) (o presión diastólica final del ventrículo izquierdo) ≤15 mmHg, y resistencia vascular pulmonar (PVR) >3 mmHg/L/min en los últimos 12 meses, los pacientes deben haber sido sometidos a una radiografía de tórax consistente con el diagnóstico de HAP.</li>
      <li>Una prueba de caminata de seis minutos (6MWT) sin estímulo de entre 200 y 450 metros en el momento del examen. 
        Edad ≥18 años.
        </li>
        <li>Diagnóstico de HAP (Hipertensión Pulmonar Grupo 1 de la OMS) de cualquier clase funcional de la OMS (I a IV).</li>
        <li>Considerado apto por parte del médico tratante para el programa de autocuidados para la HAP o su cuidador.</li>
        <li>Ha aceptado estos Términos y Condiciones</li>
        <li>Teléfono inteligente y conectividad a Internet</li>
      
        <p>- Criterios de exclusión:</p>
        <li>Incapacidad para leer, hablar o entender los idiomas incluidos en la Aplicación.</li>
        <li>Discapacidad auditiva y no uso de audífonos. La Aplicación se utiliza también a través de la intervención telefónica, y no podrá satisfacer las necesidades de este perfil de paciente.</li>
      <li>Discapacidad visual, la aplicación no podrá satisfacer las necesidades de este perfil de paciente.</li>
     <li>Discapacidades intelectuales, el paciente puede necesitar atención especializada</li>
     <li>Embarazo, periodo de lactancia o planificando un embarazo en los próximos 6 meses. </li>
     <li>Pacientes que hayan sido sometidos a una intervención quirúrgica importante en los 30 días anteriores a la inclusión en el estudio o que sufren complicaciones que puedan dificultar la utilización completa del apoyo al paciente.</li>
      
     <h4>5. Confidencialidad</h4>
     <p>a) Al hacer uso del Dashboard, se compromete a mantener la confidencialidad de los datos personales y de salud de los pacientes a los que pueda acceder, limitando el tratamiento a las finalidades acordadas, como lo es la monitorización del progreso del paciente en la gestión de su enfermedad. </p>
      <p>b) Usted es responsable de mantener la confidencialidad de su cuenta y su contraseña y de restringir el acceso a su ordenador o a su móvil u otros dispositivos similares para evitar el acceso no autorizado a su cuenta. Le pedimos que proteja su contraseña y su cuenta y se asegure de que otras personas no tengan acceso a ella. Se compromete a notificarnos inmediatamente cualquier uso no autorizado de la información de su cuenta o cualquier otra violación de la seguridad. La Empresa no puede ser, ni será, responsable de ninguna pérdida o daño que se derive de su incumplimiento de estas condiciones. Podrá ser considerado responsable de las pérdidas sufridas por la Empresa o por cualquier otro usuario registrado de la Plataforma debido a que no haya mantenido la información de su cuenta de forma segura y confidencial. </p>

  
      <h4>6. Contenido de la Plataforma</h4>
      <p>Previa aceptación por su parte de los presentes Términos y Condiciones de Uso , le concederemos acceso al Dashboard. Sin embargo, toda la información, del Dashboard sigue siendo propiedad intelectual de la Empresa. Además, todas las marcas comerciales, marcas de servicios, nombres comerciales y secretos comerciales son propiedad de la Empresa. Cualquier uso no autorizado de la información, del Dashboard se considerará un incumplimiento material de los Términos y Condiciones de Uso y nos reservamos el derecho a rescindir el Servicio.</p>
      
      <h4>7. Condiciones de uso</h4>
  
      <p>a) Usted Utilizará el Dashboard únicamente para fines legítimos y adecuados, y no se dedicará a ninguna actividad que no esté prevista en el Dashboard. </p>
     <p>b) Su acceso al Dashboard puede suspenderse ocasionalmente o restringirse para realizar reparaciones, mantenimiento o para introducir nuevas instalaciones o servicios, en cuyo caso le informaremos. </p>
     <p>c) Debido a las limitaciones inherentes al uso de Internet y de otras comunicaciones electrónicas, el acceso y el uso del Dashboard puede estar sujeto a limitaciones justificadas, a retrasos, a omisiones y a otros problemas relacionados con ella.</p> 
     <p>d) Los Servicios no pretenden ser ni constituyen un sustituto del asesoramiento, diagnóstico o tratamiento médico profesional y se ofrecen únicamente con fines informativos. Se aclara expresamente que la información tiene únicamente fines informativos. No ofrecemos ninguna garantía, ya sea expresa o implícita, con respecto a la información proporcionada en el Dashboard. En ningún caso seremos responsables ante usted o cualquier otra persona por cualquier decisión tomada o acción realizada por usted en base a dicha Información o a cualquier otra información proporcionada en el Dashboard.</p>
     
     <h4>8. Limitación de la responsabilidad</h4>
      <p>Reconoce que está accediendo a los Servicios prestados a través del Dashboard bajo los términos y condiciones establecidos en el presente documento, incluyendo, sin limitación, el reconocimiento de que la información proporcionada a través del Dashboard es solo para fines informativos y que no constituye asesoramiento médico y/o análisis del estado del paciente.  </p>
      <h4>9. Finalización</h4>
      <p>El presente Términos y Condiciones de Uso se celebra por tiempo indefinido. Sin perjuicio de lo anterior, tiene derecho a rescindir el Servicio y a darse de baja del Dashboard en cualquier momento sin causa. También tenemos derecho a rescindir el servicio notificándoselo a usted por escrito con una antelación razonable.</p>
      <h4>10. Ley aplicable </h4>
      <p>Acepta que la interpretación de este Acuerdo y la resolución de cualquier disputa que surja bajo este Acuerdo se regirá por las leyes de España.</p>
      <h4>11. Jurisdicción</h4>
      <p>Cualquier disputa, reclamación o controversia que surja de o en relación con este Acuerdo será remitida y resuelta finalmente por los tribunales de la ciudad de Barcelona, España o los tribunales aplicables de acuerdo con la legislación vigente.
      </p>
      <h4>12. Fuerza mayor</h4>
      <p>La Empresa no será responsable de los retrasos o fallos en el cumplimiento de sus obligaciones en virtud del presente Términos y Condiciones de Uso que se deriven de circunstancias o causas ajenas a su control real, incluidos, entre otros, los casos de fuerza mayor, los actos o amenazas de terrorismo, la guerra u otros actos de violencia, o cualquier ley, orden o requisito de cualquier organismo o autoridad gubernamental, los ciberataques, o cualquier incumplimiento o fallo imprevisto de los sistemas o redes informáticos de la Empresa.  En caso de que se produzca dicho retraso o fallo, la Empresa tiene derecho a dejar de prestar temporalmente los Servicios, suspender el acceso al Dashboard o llevar a cabo cualquier otra acción que sea necesaria, según determine a su entera y razonable discreción.</p>
  
      <h4>13. Notificaciones </h4>
      <p>Todas las notificaciones y otras comunicaciones requeridas o permitidas por el presente documento a la otra parte, incluyendo, sin limitación, sus quejas y discrepancias con respecto al Dashboard, cualquier comunicación de cualquier abuso o violación Términos y Condiciones de Uso que encuentre o de cualquier contenido objetable en el Dashboard, deberán ser por escrito, y se enviarán correo electrónico con acuse de recibo dirigido a la dirección de la otra parte como se establece a continuación.
      </p>
      <p>Si es a la Empresa:</p>
  
      <p>a la dirección de correo electrónico: <a href="pahcare@wellthy.care">pahcare@wellthy.care</a></p>
     
      <h4>14. Total entendimiento </h4>
      <p>El Términos y Condiciones de Uso contiene todo el entendimiento de las partes, y no hay otros acuerdos o promesas escritas u orales entre las partes con respecto al objeto del Acuerdo, aparte de los contenidos o las referencias en los Términos y Condiciones de Uso . Los Términos y Condiciones de Uso sustituyen a cualquier acuerdo o entendimiento anterior suscrito por las partes. </p>
     <!-- <p></p> -->
  
     
    </div>
  
  </div>
  
  
  <div align="center" class="accept-button text-center">
    <p>
        Ha leído estos Términos y Condiciones de Uso, al aceptarlos, acepta todas las disposiciones contenidas anteriormente.
    </p>
    <button *ngIf="showButton" mat-raised-button class="accept-btn" color="accent" (click)="serviceAgreement()">I Agree</button>
  </div>