import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { EditpumpComponent } from './editpump/editpump.component';
import { PumpComponent } from './pump.component';
const routes: Routes = [
  { path: '', component: PumpComponent, canActivate: [ContentGuardGuard] },
  { path: 'pump/edit/:id/:chid', component: EditpumpComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PumpRoutingModule { }
