<mat-drawer-container class="sidenav-container" autosize>
    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <app-adminsectionleftnav></app-adminsectionleftnav>
        <div class="mainrightcontainersecondary">
            <div class="col-md-10">
                <h3 class="commontitleprimary">
                    <button mat-icon-button class="create-btn" (click)="createHospital('newsection')">
                        Create Hospital
                    </button>
                </h3>
            </div>
            <div class="col-md-2">
            </div>
            <div class="clearfix"><br /></div>
            <!-- <div class="col-md-2">
                <mat-select [(ngModel)]="typeFilters" placeholder="Filter" multiple class="sortcommonwhite"
                    (selectionChange)="typeFilter($event.value)" style="width: 100%;">
                    <mat-option value="Freemium">Freemium</mat-option>
                    <mat-option value="Regular">Regular</mat-option>
                </mat-select>
            </div> -->
            <div class="col-md-12">
                <form>
                    <div class="form-group has-feedback has-search">
                        <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                            placeholder="Search User here">
                        <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                            <mat-icon matSuffix>search</mat-icon>
                        </button>
                    </div>
                </form>
            </div>

            <div class="col-md-12">
                <ul class="list-inline table-filter-btn">
                    <li [class]="activecls == 'all' ? 'active' : ''">
                        <a (click)="tableSort('all')" class="all">
                            All
                        </a>
                    </li>
                    <!-- <li [class]="activecls == 'active' ? 'active' : ''">
                        <a (click)="tableSort('active')" class="active-filter">
                            Active
                        </a>
                    </li>
                    <li [class]="activecls == 'inactive' ? 'active' : ''">
                        <a (click)="tableSort('inactive')" class="inactive-filter">
                            Inactive
                        </a>
                    </li> -->
                </ul>
            </div>

            <div class="col-md-12">
                <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                    (scrolled)="getNextSet()" [scrollWindow]="false">
                    <div class="spinner-container" *ngIf="showloader">
                        <app-loader showLoader="{{true}}"></app-loader>
                    </div>
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows
                        class="widthfluid bordergrey list-table">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell class="camelcased" *matCellDef="let element">
                                <!-- <span [class]="element.is_active ? 'circle green' : 'circle red'"></span> -->
                            <span class="circle green"></span>
                                {{element.name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                        </ng-container>

                        <ng-container matColumnDef="contact_info">
                            <th mat-header-cell *matHeaderCellDef> Phone Number </th>
                            <td mat-cell *matCellDef="let element">+{{element.contact_info}} </td>
                        </ng-container>

                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef> Address </th>
                            <td mat-cell class="camelcased" *matCellDef="let element"> {{element.address}} </td>
                        </ng-container>

                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef> City </th>
                            <td mat-cell class="camelcased" *matCellDef="let element"> {{element.city}} </td>
                        </ng-container>

                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef> Country </th>
                            <td mat-cell class="camelcased" *matCellDef="let element"> {{element.country}} </td>
                        </ng-container>


                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="min-width:300px;">Action </th>
                            <td mat-cell class="text-capitalize" *matCellDef="let element">
                                <div class="action-button-row">

                                    <button mat-button (click)="createHospital(element)">
                                        <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit"
                                            alt="Wellthy">
                                    </button>
                                    <button mat-button (click)="openDialog(element.id)" tooltip="Delete Hospital">
                                        <img src="assets/img/dls/admin-table/Icon_Delete.svg" alt="Wellthy">
                                      </button>
                                    <!-- <button mat-button class="active-green last-btn my-class"
                                    (click)="isActive(element.is_active,element.id)">
                                    <span *ngIf="element.is_active == true">
                                        <img src="assets/img/dls/admin-table/Icon_Deactivate.svg" alt="">
                                    </span>
                                    <span *ngIf="element.is_active == false">
                                        <img src="assets/img/dls/admin-table/Icon_Activate.svg" alt="">
                                    </span>
                                    {{element.is_active? "Deactivate" : "Activate"}}</button> -->
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>