import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  if (window) {
    var oldConsoleLog = console.log;
    window.console.log = function () { };
    window['console']['log'] = oldConsoleLog;
    const style = 'color:red; font-size:30px; font-weight: bold; -webkit-text-stroke: 1px black;'
    console.log("%c Wellthy Welcomes you", style);
  }
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
