<div class="col-md-12">
  <h2 class="commontitleprimary text-capitalize">{{userName}}</h2>
  <div class="close-icon">
    <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()">
  </div>

  <div class="container-table" *ngIf="tabulardata.length != 0" infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">
    <div class="spinner-container" *ngIf="showloader">
      <app-loader showLoader="{{true}}"></app-loader>
    </div>
    <table mat-table [dataSource]="dataSource" class="widthfluid bordergrey list-table">
      <!-- Weight Column -->
      <ng-container matColumnDef="current_hc">
        <th mat-header-cell *matHeaderCellDef> Current healthcoach </th>
        <td mat-cell *matCellDef="let element"> {{element.current_hc_data.name}} </td>
      </ng-container>

      <ng-container matColumnDef="old_hc">
        <th mat-header-cell *matHeaderCellDef> Previous healthcoach </th>
        <td mat-cell class="text-capitalize" *matCellDef="let element">
          {{element.old_hc_data?element.old_hc_data.name:'-' }} </td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Transfer date</th>
        <td mat-cell class="text-capitalize" *matCellDef="let element"> {{convertDate(element.created_at)}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;">
      </tr>

    </table>
  </div>
  <div class="" *ngIf="tabulardata.length == 0">
    <p class="center">No transfer history found for this user</p>
  </div>
</div>