import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { StaticUuxidComponent } from './static-uuxid.component';
const routes: Routes = [
  { path: '', component: StaticUuxidComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticUuxidRoutingModule { }
