import { CreateHospitalComponent } from './../create-hospital/create-hospital.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { ActivatebottomsheetComponent } from '../activatebottomsheet/activatebottomsheet.component';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-hospital-list',
  templateUrl: './hospital-list.component.html',
  styleUrls: ['./hospital-list.component.scss']
})
export class HospitalListComponent implements OnInit {
  public expandedElement: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  s3url: any = environment.s3url;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'type', 'contact_info', 'address', 'city','country','action'];
  public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public localStoredData: any;

  constructor(private browserTitle: Title, private _bottomSheet: MatBottomSheet, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;


  ngOnInit() {
    this.setSEOTags();
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  openPreActive(el): void {
    this.dialog.open(ActivatebottomsheetComponent, {
      width: '700px',
      data: {
        id: el.id,
        code: el.code,
        name: el.name
      }
    });
  }

 

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  typeFilter(fln) {
    this.filteredtypes = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.doctor_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }


  // Active Deactive Scarlet User 
  // ----------------------------

  isActive(status, id) {
    this.commonService.setStatus(status, 'users', id, this.localStoredData.id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;

      this.search();
    }
    else {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // List Scarlet User 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllHospital(this.searchcriteria)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  createHospital(element): void {
    const createDr = this.dialog.open(CreateHospitalComponent, {
      width: '1024px',
      data: {
        element: element
      }
    });

    createDr.afterClosed().subscribe(result => {
      this.search();
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Doctor');
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.systemService.showSuccessSnackBar('Selected Hospital has been deleted', 'Ok')
      }
    });
  }
  delete(id) {
    this.commonService.deleteHospital(id)
      .subscribe(
        (res: any) => this.onDelete(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onDelete(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }
}

