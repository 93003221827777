import { CommonserviceService } from './../../../admin/services/commonservice/commonservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { EditorinstructionsComponent } from '../../shared/editorinstructions/editorinstructions.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';
declare var $: any;

@Component({
  selector: 'app-edithealthgoal',
  templateUrl: './edithealthgoal.component.html',
  styleUrls: ['./edithealthgoal.component.scss']
})

export class EdithealthgoalComponent implements OnInit {
  s3url: any = environment.s3url;
  categories = ['General', 'Healthy Eating', 'Being Active', 'Symptom Management', 'Self Monitoring', 'Medication', 'Mind & Body', 'First Trimester', '  Second Trimester', 'Third Trimester', 'Other']
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }

  public dataForm = this.fb.group({
    title: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required]
  });

  public searchmatcat: FormControl = new FormControl();
  public filteredCat: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  title: any = 'New Health Goal';
  htmlContent: any;
  public mode: any;
  chooseMedia: any;
  healthgoal: any;
  doUpload: any;
  disabled: boolean = true;
  secondarycondition: any;
  public newitem: boolean = true;
  public showButtonLoader: any;
  public alltags: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public allusers: any;
  public renderhtml: any;
  public allconditions: any;
  private _onDestroy = new Subject<void>();
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCatData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  myHtml: string;
  public allcategories: any;
  allCategory: any;

  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;

  constructor(
    private browserTitle: Title,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private contentService: ContentserviceService,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    protected sanitizer: DomSanitizer,
    private commonService:CommonserviceService
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Magazine');
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallHealthGoalData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallHealthGoalData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallHealthGoalData(params.chid);
        }
      }
      this.getCatList();
      this.getTags();
      this.getUsers();
      this.getConditions();
    });
  }

  ngAfterViewInit() {
  }

  uirender() {
    var scope = this;
    scope.renderhtml = $('.preview-panel').html();
    scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
  }

  additionalTextChange(e) {
    const rawcontent = <FormControl>this.dataForm.controls.raw_content;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    additionaltextcontent.setValue(rawcontent.value);
  }

  openinstructions(): void {
    const instructions = this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });

    instructions.afterClosed().subscribe(result => {
    });
  }


  // To get Conditions
  // ----------------------------

  getConditions() {
    this.contentService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.onConditionsSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onConditionsSuccess(data) {
    data = (data as any).data;
    this.allconditions = data.getAllConditions;
  }

  // ----------------------------

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }

  modelChangedTags() {
    this.filterTags();
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }



  modelChangedCat() {
    this.filterCat();
  }

  private filterCat() {
    if (!this.allcategories) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcat.value;
    if (!search) {
      this.filteredCatData.next(this.allcategories.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredCatData.next(
      this.allcategories.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  getCatList() {
    this.contentService
      .getGraphQlData('{getParentMagazineTypes(status:true){id,title}}', false)
      .subscribe((res: any) => this.getCatListSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCatListSuccess(data) {
    this.allCategory = data.getParentMagazineTypes;
    
    this.filteredCatData.next(this.allCategory.slice());
    
    this.initializeCategory();
  }

  initializeCategory() {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getallHealthGoalData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getallHealthGoalData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getallHealthGoalData(params.chid);
        }
      }
    });
  }
  // To get Health Goal
  // ----------------------------

  getallHealthGoalData(ide) {
    this.contentService.getOneDataById(ide, 'healthgoals')
      .subscribe(
        (res: any) => this.onMagazineSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onMagazineSuccess(data) {
    data = (data as any).data;
    this.healthgoal = data;
    this.dataForm.patchValue(this.healthgoal);
    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
    var scope = this;
    setTimeout(() => {
      scope.uirender();
    }, 1000);
  }

  // ----------------------------


  // To get Users Data
  // ----------------------------

  getUsers() {
    this.contentService.getGraphQlData('{getAllContentUsers(appMode:false, status:true){id,name,display_name}}')
      .subscribe(
        (res: any) => this.onUsersSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onUsersSuccess(data) {
    data = (data as any).data;
    this.allusers = data.getAllContentUsers;
  }

  // ----------------------------


  // Submitting Health Goal
  // ----------------------------

  dataObject() {
    let postdata = this.dataForm.value;
    let title = this.commonService.isHTML(this.dataForm.value.title);
    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    postdata.title = postdata.title.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    if (this.newitem == true) {
      this.contentService.postToBlade(postdata, 'healthgoals')
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    } else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'healthgoals')
        .subscribe(
          (res: any) => this.onUpadteSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Healthgoals are added"), "OK");
    this.router.navigate(['content/healthgoal']);
  }

  private onUpadteSuccess() {
    this.systemService.showSuccessSnackBar(("Healthgoals are updated"), "OK");
    this.router.navigate(['content/healthgoal']);
  }

  // ----------------------------


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService.getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------


  // To Get Media
  // ----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe((res: any) => this.ongetMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private ongetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // ----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      }
    });
    this.chooseMedia.afterClosed().subscribe(data => {
      var formelement = <FormArray>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });

  }

}
