<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-contentnavbar></app-contentnavbar>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="content/lessonhome"></app-breadcrumb>
      <div class="col-md-12">
        <!-- <h3 class="commontitleprimary notranslate"><button mat-icon-button class="create-btn"
            [routerLink]="['/content/lesson/edit', newsection , primarylanguage, 0]">
            Create New Lesson </button>
        </h3>-->
        <h3 class="commontitleprimary notranslate"><button mat-icon-button class="create-btn"
            [routerLink]="['/content/lesson/edit', newsection , primarylanguage, 0]">
            {{'Content.LessonButtonTitle' | translate}} </button>


        </h3>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <!-- <mat-select placeholder="Filter Tags" multiple class="sortcommonwhite"
                  (selectionChange)="tagfilter($event.value)">
                  <mat-option *ngFor="let tag of alltags" [value]="tag.id">{{tag.title}}</mat-option>
                </mat-select> -->
                <mat-select placeholder="Filter Tags" multiple class="sortcommonwhite"
                  (selectionChange)="tagfilter($event.value)">
                  <mat-option *ngFor="let tag of alltags" [value]="tag.id">{{tag.title}}</mat-option>
                </mat-select>
              </div>
              <div class="col-md-10">
                <form>
                  <div class="form-group has-feedback has-search">
                    <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                      placeholder="Search">
                    <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                      <mat-icon matSuffix>search</mat-icon>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''">
            <a (click)="tableSort('all')" class="all">
              All
            </a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">
              Active
            </a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">
              Inactive
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table">
            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell class="camelcased" *matCellDef="let element" data-toggle="tooltip" title="{{element.title}}">
                <span [class]="element.is_active === true? 'circle green' : 'circle red'"></span>
                {{element.title | truncate:[40, '...']}}
              </td>
            </ng-container>


            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let element" class="typecaps"> {{element.type}} </td>
            </ng-container>

            <!-- Tags Column -->
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef> Tags </th>
              <td mat-cell class="camelcased" *matCellDef="let element"> {{getTagName(element.tags)}} </td>
            </ng-container>

            <!-- updatedat Column -->
            <ng-container matColumnDef="updatedat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated </th>
              <td mat-cell class="camelcased" *matCellDef="let element">{{element.updated_at | daysago}}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button disableRipple
                     [matMenuTriggerFor]="menu3"                   
                    class="no-hover-effect">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg"   tooltip="Edit details" alt="">
                  </button>
                  <button  mat-button disableRipple class="no-hover-effect active-green"
                  [ngClass]="{'my-class': element.is_active == true}"
                  (click)="isActive(element.is_active,element.id)">
                  <span *ngIf="element.is_active == true">
                    <img src="assets/img/dls/admin-table/Icon_Deactivate.svg"  tooltip="De-activate" alt="">
                  </span>
                  <span *ngIf="element.is_active == false">
                    <img src="assets/img/dls/admin-table/Icon_Activate.svg"  tooltip="Activate" alt="">
                  </span></button>
                  <button  tooltip ="Delete" class="last-btn no-hover-effect" mat-button  [disabled]="element.is_active === true" [ngClass]="{disabled:element.is_active == true }" (click)="openDialog(element.id)">
                  <img src="assets/img/dls/admin-table/Icon_Delete.svg" style="height: 28px;" alt=""></button>
                  <mat-menu #menu3="matMenu">
                    <button mat-menu-item *ngFor="let language of alllanguages"
                      [routerLink]="['/content/lesson/edit', element.id,  language.id, getChildID(element, language.id)]"><span
                        *ngIf="getChildID(element, language.id) || language.id == 1"><mat-icon>done</mat-icon>
                      </span>{{language.name}}</button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple style="padding-left: 0;text-align: left;width: 72px;"
                        [matMenuTriggerFor]="menu3" class="no-hover-effect">Edit
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <button mat-button disableRipple class="no-hover-effect active-green"
                        [ngClass]="{'my-class': element.is_active == true}"
                        (click)="isActive(element.is_active,element.id)">{{element.is_active? "Deactivate" :
                        "Activate"}}</button>
                      <button class="last-btn no-hover-effect" mat-button disableRipple
                        *ngIf="element.is_active == false" (click)="openDialog(element.id)">Delete</button>
                      <mat-menu #menu3="matMenu">
                        <button mat-menu-item *ngFor="let language of alllanguages"
                          [routerLink]="['/content/lesson/edit', element.id,  language.id, getChildID(element, language.id)]"><span
                            *ngIf="getChildID(element, language.id) || language.id == 1">
                            <mat-icon>done</mat-icon>
                          </span>{{language.name}}</button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">
            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>