<mat-drawer-container class="sidenav-container" autosize>

  <app-hcleftnav></app-hcleftnav>

  <div class="sidenav-content">
    <div class="col-md-12 topalertbar">

      <div class="col-md-12">
        <div class="clearfix">
          <h3 class="alerttitle pull-left">Notifications</h3>
        </div>
      </div>

      <div class="clearfix"><br /><br /><br /><br /></div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2">
            <mat-select [(ngModel)]="typeFilters" placeholder="Filter" multiple class="sortcommonwhite"
              (selectionChange)="typeFilter($event.value)">
              <mat-option *ngFor="let types of notificationtypes" value="{{types[0]}}">{{types[1]}}</mat-option>
            </mat-select>
          </div>
          <div class="col-md-10">

            <form>
              <div class="form-group has-feedback has-search">
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div class="list-filter table-filter-btn">
              <ul class="list-inline table-filter-holder">
                <li>
                  <a (click)="tableSort(null,'all')" [class]="notiftype == 'all' ? 'bold all': 'all'">
                    All
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('has_seen=false','unread')" [class]="notiftype == 'unread'? 'bold all': 'all'">
                    Unread
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('has_seen=true','read')" [class]="notiftype == 'read'? 'bold all': 'all'">
                    Read
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('patient_starred=true','star')"
                    [class]="notiftype == 'star'? 'bold all': 'all'">
                    Starred
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('patient_starred=false','unstar')"
                    [class]="notiftype == 'unstar'? 'bold all': 'all'">
                    Unstarred
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="example-container" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
        (scrolled)="getNextPage()" [scrollWindow]="false">
        <mat-card *ngFor="let data of tabulardata;let i = index;" class="alert-card" id="{{data?.patient_id_data_fk}}">
          <div class="clearfix">
            <mat-card-title class="pull-left notranslate" >{{data?.name}}</mat-card-title>
            <div class="pull-right">
              <ul class="list-inline">
                <li>
                  <i (click)="starfunc(data?.patient_id_data_fk, i, data?.patient_starred)"
                    class="material-icons more-vert notranslate">{{data?.patient_starred ? 'star' : 'star_outline'}}</i>
                </li>
                <li>
                  <div class="more-vert" [matMenuTriggerFor]="menu"><i class="material-icons notranslate">more_vert</i></div>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="markallread(data?.patient_id_data_fk)">Mark All Read</button>
                    <button mat-menu-item (click)="openChatOption(data?.patient_id_data_fk)">View Profile</button>
                  </mat-menu>
                </li>
              </ul>
            </div>
          </div>
          <div class="snack-wrapper">
            <div class="snack-holder" *ngFor="let notif of data?.notifications.slice(0, 3);" >
              <div class="content" >
                <img [src]="'assets/img/notification_coloured_icons/'+(notif?.type)+'.svg'" alt=""
                  [class]="notif?.has_seen ? 'img-responsive hasseencss' : 'img-responsive'">
                <h6 (click)="notificationVerify(notif?.type,data?.name,notif?.value,notif?.created_at,notif?.value)" class="pointer" >{{setstatus(notif?.type, notif?.value)}}</h6>
              </div>
              <div class="content content-second">
                <p>{{covertTime(notif?.created_at)| date:'MMM d, h:mm a'}}</p>
              </div>
            </div>
            <div class="snack-holder" *ngIf="data?.notifications[3]">
              <button mat-stroked-button (click)="openChatOption(data?.patient_id_data_fk)">View All</button>
            </div>
          </div>
        </mat-card>
      </div>

      <ng-template #pageLoadingLoader>
        <app-loader showLoader="{{true}}"></app-loader>
      </ng-template>
    </div>
  </div>
</mat-drawer-container>