import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { MagazineComponent } from './magazine.component';
import { EditmagazineComponent } from './editmagazine/editmagazine.component';
const routes: Routes = [
  { path: '', component: MagazineComponent, canActivate: [ContentGuardGuard] },
  { path: 'magazine/edit/:id/:langid/:chid', component: EditmagazineComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MagazineRoutingModule { }
