import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject, Subject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-editlevels',
  templateUrl: './editlevels.component.html',
  styleUrls: ['./editlevels.component.scss']
})

export class EditlevelsComponent implements OnInit {
  public showButtonLoader: boolean = false;
  alllevels: any;
  therapylevels: any;
  therapydata: any;
  therapyid: any;
  knowledgelevel: string;
  selectedoptn: any = [];
  public dataForm = this.fb.group({
    // knowledge_level_key: [],
    therapy_levels: this.fb.array([])
  });

  private _onDestroy = new Subject<void>();
  public searchmatquiz: FormControl = new FormControl();
  filteredLevel: Array<ReplaySubject<any[]>> = [];

  @ViewChild('singleSelectQuiz', { static: false }) singleSelectQuiz: MatSelect;



  constructor(private browserTitle: Title, private route: ActivatedRoute, private cdr: ChangeDetectorRef, public router: Router, public fb: FormBuilder, public dialog: MatDialog, private contentService: ContentserviceService, private systemService: SystemService) { }

  initLevelOptions() {
    return this.fb.group({
      leveloption: []
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Therapy Design Update Level');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.therapyid = params.id;
      this.knowledgelevel = params.kl;
    });
    this.getLevels();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChangedQuiz(event, index) {
    this.filterLevel(index);
  }

  filterLevel(index) {
    if (!this.alllevels) {
      return;
    }
    // get the search keyword
    let search = this.searchmatquiz.value;
    if (!search) {
      this.filteredLevel[index].next(this.alllevels.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredLevel[index].next(
      this.alllevels.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // ----------------------------

  // To get All Levels
  // ----------------------------

  getLevels() {
    this.contentService
      .getGraphQlData('{getParentLevels(status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getLevelsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLevelsSuccess(data) {
    this.alllevels = data.getParentLevels;
    
    for (let j = 0; j < this.alllevels; j++) {
      this.filteredLevel[j].next(this.alllevels.slice());
    }
    for (let j = 0; j < this.alllevels.length; j++) {
      this.filteredLevel[j] = new ReplaySubject<any[]>(1);
    }
    this.getAllTherapyData(this.therapyid);
  }

  // ----------------------------

  // To get All Levels
  // ----------------------------

  getAllTherapyData(ide) {
    this.contentService
      .getOneDataById(ide, 'therapy')
      .subscribe(
        (res: any) => this.getAllTherapyDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllTherapyDataSuccess(data) {
    this.therapydata = data;
    var control1 = <FormArray>this.dataForm.controls['therapy_levels'];
    while (control1.length !== 0) {
      control1.removeAt(0);
    }
    for (let k = 0; k < this.therapydata.level_count; k++) {
      control1.push(this.initLevelOptions());
    }
    
    this.dataForm.patchValue(this.therapydata);

    for (let k = 0; k < this.therapydata.level_count; k++) {
      const formelement = (<FormArray>(this.dataForm.controls['therapy_levels'])).at(k);
      formelement['controls'].leveloption.setValue(this.therapydata.therapy_levels[k]);
      this.selectedOptions(this.therapydata.therapy_levels[k], k);
      this.disableOptions(this.therapydata.therapy_levels[k]);
    }

    // if (this.knowledgelevel == 'amateur' && this.therapydata.amateur_levels !== null) {
    //   for (let k = 0; k < this.therapydata.level_count; k++) {
    //     const formelement = (<FormArray>(this.dataForm.controls['therapy_levels'])).at(k);
    //     formelement['controls'].leveloption.setValue(this.therapydata.amateur_levels[k]);
    //     this.selectedOptions(this.therapydata.amateur_levels[k], k);
    //     this.disableOptions(this.therapydata.amateur_levels[k]);
    //   }
    // }
    // else if (this.knowledgelevel == 'average' && this.therapydata.average_levels !== null) {
    //   for (let k = 0; k < this.therapydata.level_count; k++) {
    //     const formelement = (<FormArray>(this.dataForm.controls['therapy_levels'])).at(k);
    //     formelement['controls'].leveloption.setValue(this.therapydata.average_levels[k]);
    //     this.selectedOptions(this.therapydata.average_levels[k], k);
    //     this.disableOptions(this.therapydata.average_levels[k]);
    //   }
    // }
    // else if (this.knowledgelevel == 'proficient' && this.therapydata.proficient_levels !== null) {
    //   for (let k = 0; k < this.therapydata.level_count; k++) {
    //     const formelement = (<FormArray>(this.dataForm.controls['therapy_levels'])).at(k);
    //     formelement['controls'].leveloption.setValue(this.therapydata.proficient_levels[k]);
    //     this.selectedOptions(this.therapydata.proficient_levels[k], k);
    //     this.disableOptions(this.therapydata.proficient_levels[k]);
    //   }
    // }

  }

  selectedOptions(e, j) {
    if (this.alllevels) {
      for (let k = 0; k < this.alllevels.length; k++) {
        if (e == this.alllevels[k].id) {
          this.selectedoptn[j] = this.alllevels[k];
        }
      }
    }

  }

  disableOptions(e) {
    for (let k = 0; k < this.selectedoptn.length; k++) {
      if (this.selectedoptn[k] && this.selectedoptn[k].id && e == this.selectedoptn[k].id) {
        return true;
      }
    }
  }


  // Submitting levels Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    // postdata.knowledge_level_key = this.knowledgelevel.toString();
    var levelsarray = [];
    postdata.therapy_levels.forEach(el => {
      levelsarray.push(el.leveloption);
    });
    postdata.therapy_levels = JSON.stringify(levelsarray);
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    this.contentService
      .updateToBlade(this.therapyid, '', postdata, 'savelevel')
      .subscribe(
        (res: any) => this.onTherapySavedSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTherapySavedSuccess(data) {
    this.therapylevels = data;
    this.systemService.showSuccessSnackBar("Therapy saved successfully", "OK");
    this.router.navigate(['content/therapy']);
  }

  // ----------------------------

}
