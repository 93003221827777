import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-prefill',
  templateUrl: './prefill.component.html',
  styleUrls: ['./prefill.component.scss']
})
export class PrefillComponent implements OnInit {
  public searchcriteria: any;
  dataSource: any;
  lastSearch: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  public isactive = 'active';
  public status = 'active';
  filters: any;
  sort_by: any;
  sort_direction: any

  constructor(public dialogRef: MatDialogRef<PrefillComponent>, public commonService: CommonserviceService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.search();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  //  Task List
  // ----------------------------

  search() {
    if (this.searchcriteria) {
      this.searchcriteria = this.searchcriteria
    } else {
      this.searchcriteria = ''
    }
    this.sort_by = ""
    this.sort_direction = ""
    this.filters = { "therapy_id": "", "client_id": "", "owner_id": "", "priority": [], "expiry_start_date": "", "expiry_end_date": "" }
    this.commonService.getTaskList(this.searchcriteria, this.page.toString(), '20', this.status, this.sort_by, this.sort_direction, this.filters)
      .subscribe(
        (res: any) => this.onTaskListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTaskListSuccess(data) {
    data = data.data;
    this.dataSource = data;
    this.dataSource = data.filter(a => a.task_summary != null)
  }


  // To get one task details

  getDatabyId(id) {
    this.commonService.getOneTaskData(id)
      .subscribe(
        (res: any) => this.getOneTaskSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getOneTaskSuccess(data) {
    data = data.data;
    
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: data })
  }


}



