<div class="profile-wrapper">
  <div class="profile-info clearfix">
    <h4>Patients Upload</h4>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close" (click)="onNoClick()">
        close
      </i>
    </div>
  </div>
  <mat-dialog-content>

    <div class="hc-details" *ngIf="!showSuccess">
      <form [formGroup]="dataForm" validate>
        <div class="row">
          <form [formGroup]="demoForm" validate>
            <div class="col-md-4 zeropadleft">
              <div class="image-placeholder">
                <!-- <mat-spinner class="upload-spinner"></mat-spinner> -->
                <div class="profile-image">
                  <div class="profile-dummy-img" *ngIf="!uploadedparam">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                    <figcaption>Supported formats: .xlsx
                      <br>
                      Max size: ~5 MB
                    </figcaption>
                  </div>
                  <div class="profile-dummy-img" *ngIf="uploadedparam">
                    <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/excel.svg" height="95px">
                    <figcaption>{{image_path}}
                      <br>
                    </figcaption>
                  </div>
                </div>
              </div>

              <file-upload simple accept=".xlsx" class="file-uplaod-html" formControlName="files"
                (change)="onFileChange()">
                <ng-template let-file #placeholder>
                  <ng-container *ngIf="file; else emptyList">
                    {{ img_file_name }}
                  </ng-container>
                  <ng-container *ngIf="!file">
                    Choose a file...
                  </ng-container>
                </ng-template>
                <ng-template let-control="control" #button>
                  <i class="material-icons">
                    cloud_upload
                  </i>
                </ng-template>
              </file-upload>
            </div>

          </form>

          <div class="col-md-8 excel-count">
            <div class="col-md-8 row-col box1">
              <div class="text-total">Total Rows = {{rows?rows:'0'}}</div>
            </div>
            <div class="col-md-8 row-col box2">
              <div class="text-total">Total Columns = {{cols?cols:'0'}}</div>
            </div>

            <div class="col-md-10 zeropadleft zeromarginleft">
              <button mat-flat-button color="primary" (click)="getFile()" class="centerbtn mt-15">Preview Data</button>
            </div>
          </div>
        </div>
        <!-- <div class="row">
                <div class="col-md-12 zeropadleft zeromarginleft">
                    <button mat-flat-button color="primary" class="centerbtn mt-15"
                        [disabled]="!dataForm.valid">Preview Data</button>
                </div>
            </div> -->
      </form>
    </div>



    <div class="container-table" *ngIf="dataSource && !showSuccess">
      <div class="spinner-container" *ngIf="showloader">
        <app-loader showLoader="{{true}}"></app-loader>
      </div>
      <!-- <table class="table table-bordered">
            <tbody>
              <tr *ngFor="let row of dataSource">
                <td *ngFor="let val of row">{{val}}</td>
              </tr>
            </tbody>
          </table> -->
      <form [formGroup]="demoForm1" validate>
      <table mat-table  [dataSource]="dataSource" multiTemplateDataRows
        class="widthfluid bordergrey list-table">
        <ng-container *ngFor="let prop of displayedColumns">
          <ng-container [matColumnDef]="prop">
            <mat-header-cell *matHeaderCellDef> {{prop}}
              <mat-form-field class="col-md-6">
                <mat-select placeholder="Map with" panelClass="example-panel-dark-blue" formControlName="selectedHeader"
                  (selectionChange)="getSelectedHeaders($event.value,prop)">
                  <mat-option *ngFor="let cust of mappingHeaders" [value]="cust.name" [ngClass]="cust.class"> {{cust.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element[prop]}}
            </mat-cell>
          </ng-container>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
      </form>
    </div>

    <div [formGroup]="dropdown">
      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin" *ngIf="dataSource && !showSuccess" style="margin-top: 10px;">
        <mat-checkbox value="checked" formControlName="is_checked">
          I am confirming that uploaded excel/csv is having its first row as header and first row will be skipped while
          uploading the data.
        </mat-checkbox>
        <br />
        <br />
      </div>

      <div class="row" *ngIf="dataSource && !showSuccess">
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Select Campaign</mat-label>
          <mat-select placeholder="Select Campaign" class="select-role" formControlName="campaign" required>
            <mat-option>
              <ngx-mat-select-search class="tagmat" [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
                [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmatcampaign" ngModel
                (ngModelChange)="modelChangedCampaign()" ngDefaultControl>
                <div class="mat-select-search-custom-header-content">
                  <i class="material-icons">
                    search
                  </i></div>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let campaign of filteredCampaign | async" [value]="campaign.id">
              {{campaign.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row" *ngIf="dataSource && !showSuccess">
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Select Primary Condition</mat-label>
          <mat-select formControlName="campaign" required>
              <mat-option class="camelcased" *ngFor="let condition of allconditions"
                  [value]="condition.id">
                  {{condition.title}}
              </mat-option>
          </mat-select>
      </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="col-md-6">
          <mat-select placeholder="Select Condition" class="select-role" formControlName="campaign" required>
            <mat-option>
              <ngx-mat-select-search class="tagmat" [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
                [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmatcampaign" ngModel
                (ngModelChange)="modelChangedCampaign()" ngDefaultControl>
                <div class="mat-select-search-custom-header-content">
                  <i class="material-icons">
                    search
                  </i></div>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let campaign of filteredCampaign | async" [value]="campaign.id">
              {{campaign.title}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>

      <div class="row" *ngIf="showSuccess">
        <div class="col-md-11 upload-success">
          <div style="margin-top: 25px;margin-left: 92px;">
          <img src="assets/img/checkmark.png" class="col-md-4 zeropadright" style="width: 72px;" />
          <div class="upload-text">
            Upload successful
          </div>
          <span class="small-text">
           New Patient Added : {{successData.length?successData.length:'0'}}
          </span>
          <br>
          <span class="small-text">
            {{errorData.length?errorData.length:'0'}} Records failed to upload <div class="error-list" *ngIf="errorData"><a (click)="exportToExcel($event)"><ul>Error list</ul></a></div>
          </span>
        </div>
        </div>
      </div>

      <div class="row" *ngIf="dataSource && !showSuccess">
        <div class="col-md-12">
          <button mat-flat-button color="primary" class="centerbtn mt-15"
            [disabled]="!dropdown.valid" (click)="uploadPatients()">Upload</button>
        </div>
      </div>
      <!-- <div class="row" *ngIf="showSuccess">
        <div class="col-md-12">
          <button mat-flat-button mat-dialog-close class="centerbtn mt-15" (click)="onNoClick()">Upload</button>
        </div>
      </div> -->
    </div>


  </mat-dialog-content>


</div>