<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-therapysetupleftnav></app-therapysetupleftnav>
    <div class="mainrightcontainersecondary">
      <div class="col-md-8">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="commonbackbtn"
            routerLink="/content/activationcode">
            <i class="material-icons">keyboard_arrow_left</i>
          </button>
          Therapy Code</h3>
        <div class="clearfix">
          <br />
          <br />
        </div>
      </div>
      <div class="col-md-4">

      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <form>
              <div class="form-group has-feedback has-search">
                
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="example-container" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <table mat-table [dataSource]="dataSource" class="list-table widthfluid bordergrey">
            <!-- Name Column -->
            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef> Code </th>
              <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>
            <ng-container matColumnDef="isactive">
              <th mat-header-cell *matHeaderCellDef> Is activated? </th>
              <td mat-cell *matCellDef="let element"> {{element.is_activated ? 'Yes' : 'No'}} </td>
            </ng-container>
            <ng-container matColumnDef="hashc">
              <th mat-header-cell *matHeaderCellDef> Has healthcoach? </th>
              <td mat-cell *matCellDef="let element"> {{element.has_coach_access ? 'Yes' : 'No'}} </td>
            </ng-container>
            <ng-container matColumnDef="hasclient">
              <th mat-header-cell *matHeaderCellDef> Has client? </th>
              <td mat-cell *matCellDef="let element"> {{element.has_client ? 'Yes' : 'No'}} </td>
            </ng-container>
            <ng-container matColumnDef="hascampaign">
              <th mat-header-cell *matHeaderCellDef> Has campaign? </th>
              <td mat-cell *matCellDef="let element"> {{element.has_campaign ? 'Yes' : 'No'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

      </div>
    </div>
  </div>

</mat-drawer-container>