import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { QuickAccessComponent } from '../modals/quick-access/quick-access.component';


@Component({
  selector: 'app-mainpanel',
  templateUrl: './mainpanel.component.html',
  styleUrls: ['./mainpanel.component.scss']
})
export class MainpanelComponent implements OnInit {
  public rawdata: any;
  public errors: any;
  public isAgreement: any;
  public dialogRef: any;

  constructor(private browserTitle:Title,  public dialog: MatDialog) { }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Doctor | Home');
  }

  ngOnInit() {
    this.setSEOTags();
  }

  /**
   * @name openDialog
   * @description Displaying the service level agreement when the doctor login for the first time
   * @param No_param
   * @returns NULL
   */
  openDialog() {
    this.dialogRef = this.dialog.open(QuickAccessComponent, {
      height: '350',
      disableClose: true,
    });
  }
  
  /**
   * @name closeDialog
   * @description Closes the serice level agreement
   * @param No_param
   * @returns NULL
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
