<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <!-- <app-hcassignleftnav></app-hcassignleftnav> -->
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <div class="breadcrumbholder">
        <app-breadcrumb route="admin/clienthome/clientedit"></app-breadcrumb>
      </div>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          Update client
        </h3>
      </div>
      <div class="col-md-12 scroll-area">
        <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
          <div class="form-wrapper">
            <div class="row">
              <div class="col-md-12">
                <div class="row">

                  <div class="col-md-12" style="padding:0">

                    <mat-form-field appearance="outline" class="col-md-6">
                      <mat-label>Detailed text</mat-label>
                      <input matInput placeholder="Detailed text" formControlName="detailed_text">

                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                      <mat-label>Privacy Policy URL</mat-label>
                      <input matInput placeholder="Privacy Policy URL" formControlName="privacy_policy_url">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                      <mat-label>Phone Number</mat-label>
                      <input matInput placeholder="Phone Number" formControlName="support_phone_number">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                      <mat-label>Email ID</mat-label>
                      <input matInput placeholder="Email ID" formControlName="support_email_id">
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                      <mat-label>Support Caregiver?</mat-label>
                      <mat-select placeholder="Support Caregiver" class="select-role"
                        formControlName="supports_caregiver">
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                      </mat-select>
                    </mat-form-field>



                    <div class="col-md-12 zeropadleft">
                      <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Has healthcoach access?</mat-label>
                        <mat-select (selectionChange)="HCAccess($event.value);coachaccess = $event.value"
                          formControlName="has_coach_access">
                          <mat-option [value]="true">Yes</mat-option>
                          <mat-option [value]="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div *ngIf="coachaccess">
                        <mat-form-field appearance="outline" class="col-md-6">
                          <mat-label>Select Healthcoach</mat-label>
                          <mat-select formControlName="coach_ids" multiple #singleSelectHC [required]="coachaccess">
                            <mat-option>
                              <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                [placeholderLabel]="'Search Item'" [noEntriesFoundLabel]="'No matching records found'"
                                [formControl]="searchmathc" ngModel (ngModelChange)="filterHC()" ngDefaultControl>
                                <div class="mat-select-search-custom-header-content">
                                  <i class="material-icons">
                                    search
                                  </i>
                                </div>
                              </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let hc of filteredHC | async" [value]="hc.id">
                              {{hc.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="col-md-12 zeropadleft">
                      <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Has trail period?</mat-label>
                        <mat-select (selectionChange)="trailAccess($event.value);trailaccess = $event.value"
                          formControlName="has_trial_period">
                          <mat-option [value]="true">Yes</mat-option>
                          <mat-option [value]="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-md-6" *ngIf="trailaccess">
                        <mat-label>Trail Days</mat-label>
                        <input matInput placeholder="Enter Trail Days" formControlName="trial_days">
                      </mat-form-field>

                    </div>
                    <div class="col-md-12 zeropadleft">
                      <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Clock Format</mat-label>
                        <mat-select formControlName="ampm_format" required>
                          <mat-option value="true">12 hrs</mat-option>
                          <mat-option value="false">24 hrs</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-md-3">
                      <div class="row" style="height: 50px;"><h5>Logo media</h5></div>
                      <div class="image-placeholder">
                        <mat-spinner class="upload-spinner" *ngIf="isloading"></mat-spinner>
                        <div class="profile-image" *ngIf="!isloading">
                          <div class="profile-dummy-img" *ngIf="!uploadedparam">
                            <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                            <figcaption>Supported formats: .jpeg, .png, .jpg
                              <br>
                              Max size: ~5 MB
                            </figcaption>
                          </div>
                          <img *ngIf="uploadedparam" class="image-data img-responsive"
                            [src]="s3url + log_media_image_path">
                        </div>
                        <form [formGroup]="demoForm" (change)="uploadImage('logo_media')" validate
                          [class]="isinvalid ? 'invalidcls' : ''">
                          <file-upload formControlName="logo_media" simple accept=".jpeg,.png,.jpg"
                            class="file-uplaod-html">
                            <ng-template let-file #placeholder>

                              <ng-container *ngIf="file; else emptyList">
                                {{ img_file_name }}
                              </ng-container>
                              <ng-container *ngIf="!file">
                                Choose a file...
                              </ng-container>

                            </ng-template>

                            <ng-template let-control="control" #button>
                              <i class="material-icons">
                                edit
                              </i>
                            </ng-template>
                          </file-upload>

                        </form>
                      </div>

                    </div>

                    <div class="col-md-3">
                      <div class="row" style="height: 50px;"><h5>Banner Media</h5></div>
                      <div class="image-placeholder">
                        <mat-spinner class="upload-spinner" *ngIf="isbannerloading"></mat-spinner>
                        <div class="profile-image" *ngIf="!isbannerloading">
                          <div class="profile-dummy-img" *ngIf="!uploadedBannermedia">
                            <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                            <figcaption>Supported formats: .jpeg, .png, .jpg
                              <br>
                              Max size: ~5 MB
                            </figcaption>
                          </div>
                          <img *ngIf="uploadedBannermedia" class="image-data img-responsive"
                            [src]="s3url + banner_media_image_path">
                        </div>
                        <form [formGroup]="demoForm" (change)="uploadImage('banner_media')" validate
                          [class]="isinvalid ? 'invalidcls' : ''">
                          <file-upload formControlName="banner_media" simple accept=".jpeg,.png,.jpg"
                            class="file-uplaod-html">
                            <ng-template let-file #placeholder>

                              <ng-container *ngIf="file; else emptyList">
                                {{ img_file_name }}
                              </ng-container>
                              <ng-container *ngIf="!file">
                                Choose a file...
                              </ng-container>

                            </ng-template>

                            <ng-template let-control="control" #button>
                              <i class="material-icons">
                                edit
                              </i>
                            </ng-template>
                          </file-upload>

                        </form>
                      </div>

                    </div>


                    <div class="col-md-3">
                      <div class="row" style="height: 50px;"> <h5>Colored Logo Image</h5></div>
                      <div class="image-placeholder">
                        <mat-spinner class="upload-spinner" *ngIf="iscoloredloading"></mat-spinner>
                        <div class="profile-image" *ngIf="!iscoloredloading">
                          <div class="profile-dummy-img" *ngIf="!uploadedcoloredmedia">
                            <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                            <figcaption>Supported formats: .jpeg, .png, .jpg
                              <br>
                              Max size: ~5 MB
                            </figcaption>
                          </div>
                          <img *ngIf="uploadedcoloredmedia" class="image-data img-responsive"
                            [src]="s3url + colored_logo_media_path">
                        </div>
                        <form [formGroup]="demoForm" (change)="uploadImage('colored_logo_media')" validate
                          [class]="isinvalid ? 'invalidcls' : ''">
                          <file-upload formControlName="colored_logo_media" simple accept=".jpeg,.png,.jpg"
                            class="file-uplaod-html">
                            <ng-template let-file #placeholder>

                              <ng-container *ngIf="file; else emptyList">
                                {{ img_file_name }}
                              </ng-container>
                              <ng-container *ngIf="!file">
                                Choose a file...
                              </ng-container>

                            </ng-template>

                            <ng-template let-control="control" #button>
                              <i class="material-icons">
                                edit
                              </i>
                            </ng-template>
                          </file-upload>

                        </form>
                      </div>

                    </div>

                   

                    <div class="col-md-3">
                      <div class="row" style="height: 50px;"><h5>Banner Media For Logging (Optional)</h5></div>
                      <div class="image-placeholder">
                        <mat-spinner class="upload-spinner" *ngIf="isbannerloggingLight"></mat-spinner>
                        <div class="profile-image" *ngIf="!isbannerloggingLight">
                          <div class="profile-dummy-img" *ngIf="!uploadedBannerloggingLight">
                            <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                            <figcaption>Supported formats: .jpeg, .png, .jpg
                              <br>
                              Max size: ~5 MB
                            </figcaption>
                          </div>
                          <img *ngIf="uploadedBannerloggingLight" class="image-data img-responsive"
                            [src]="s3url + banner_media_logging_image_path">
                        </div>
                        <form [formGroup]="demoForm" (change)="uploadImage('banner_media_logging_light')" validate
                          [class]="isinvalid ? 'invalidcls' : ''">
                          <file-upload formControlName="banner_media_logging_light" simple accept=".jpeg,.png,.jpg"
                            class="file-uplaod-html">
                            <ng-template let-file #placeholder>

                              <ng-container *ngIf="file; else emptyList">
                                {{ img_file_name }}
                              </ng-container>
                              <ng-container *ngIf="!file">
                                Choose a file...
                              </ng-container>

                            </ng-template>

                            <ng-template let-control="control" #button>
                              <i class="material-icons">
                                edit
                              </i>
                            </ng-template>
                          </file-upload>

                        </form>
                      </div>

                    </div>

                  </div>

                </div>
                <div class="row">
                  <!-- <div class="col-md-9" style="padding:0"> -->
                  <div class="col-md-3">
                    <div class="row" style="height: 50px;"> <h5>Dark Logo media (optional)</h5></div>
                      <div class="image-placeholder">
                        <mat-spinner class="upload-spinner" *ngIf="isdarklogoloading"></mat-spinner>
                        <div class="profile-image" *ngIf="!isdarklogoloading">
                        <div class="profile-dummy-img" *ngIf="!uploadeddarkparam">
                          <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                          <figcaption>Supported formats: .jpeg, .png, .jpg
                            <br>
                            Max size: ~5 MB
                          </figcaption>
                        </div>
                        <img *ngIf="uploadeddarkparam" class="image-data img-responsive"
                          [src]="s3url + dark_log_media_image_path">
                        </div>
                      
                      <form [formGroup]="demoForm" (change)="uploadImage('dark_logo_media')" validate
                        [class]="isinvalid ? 'invalidcls' : ''">
                        <file-upload formControlName="dark_logo_media" simple accept=".jpeg,.png,.jpg" class="file-uplaod-html">
                          <ng-template let-file #placeholder>
    
                            <ng-container *ngIf="file; else emptyList">
                              {{ img_file_name }}
                            </ng-container>
                            <ng-container *ngIf="!file">
                              Choose a file...
                            </ng-container>
                            
                          </ng-template>
    
                          <ng-template let-control="control" #button>
                            <i class="material-icons">
                              cloud_upload
                            </i>
                          </ng-template>
                        </file-upload>
    
                      </form>
                    </div>
                  </div>
                    <div class="col-md-3">
                      <div class="row" style="height: 50px;"><h5> Dark Banner Media (optional)</h5></div>
                      <div class="image-placeholder">
                        <mat-spinner class="upload-spinner" *ngIf="isdarkbannerloading"></mat-spinner>
                        <div class="profile-image" *ngIf="!isdarkbannerloading">
                        <div class="profile-dummy-img" *ngIf="!uploadedDarkBannermedia">
                          <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                          <figcaption>Supported formats: .jpeg, .png, .jpg
                            <br>
                            Max size: ~5 MB
                          </figcaption>
                        </div>
                        <img *ngIf="uploadedDarkBannermedia" class="image-data img-responsive"
                          [src]="s3url + dark_banner_media_image_path">
                        </div>
                      
                      <form [formGroup]="demoForm" (change)="uploadImage('dark_banner_media')" validate
                        [class]="isinvalid ? 'invalidcls' : ''">
                        <file-upload formControlName="dark_banner_media" simple accept=".jpeg,.png,.jpg" class="file-uplaod-html">
                          <ng-template let-file #placeholder>
    
                            <ng-container *ngIf="file; else emptyList">
                              {{ img_file_name }}
                            </ng-container>
                            <ng-container *ngIf="!file">
                              Choose a file...
                            </ng-container>
                            
                          </ng-template>
    
                          <ng-template let-control="control" #button>
                            <i class="material-icons">
                              cloud_upload
                            </i>
                          </ng-template>
                        </file-upload>
    
                      </form>
                    </div>
                    </div>
                    

                    <div class="col-md-3">
                      <div class="row" style="height: 50px;"> <h5>Dark Colored Logo (optional)</h5></div>
                        <div class="image-placeholder">
                          <mat-spinner class="upload-spinner" *ngIf="isdarkcoloredloading"></mat-spinner>
                          <div class="profile-image" *ngIf="!isdarkcoloredloading">
                          <div class="profile-dummy-img" *ngIf="!uploadedDarkcoloredmedia">
                            <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                            <figcaption>Supported formats: .jpeg, .png, .jpg
                              <br>
                              Max size: ~5 MB
                            </figcaption>
                          </div>
                          <img *ngIf="uploadedDarkcoloredmedia" class="image-data img-responsive"
                            [src]="s3url + dark_colored_logo_media_path">
                          </div>
                       
                        <form [formGroup]="demoForm" (change)="uploadImage('dark_colored_logo_media')" validate
                          [class]="isinvalid ? 'invalidcls' : ''">
                          <file-upload formControlName="dark_colored_logo_media" simple accept=".jpeg,.png,.jpg" class="file-uplaod-html">
                            <ng-template let-file #placeholder>
      
                              <ng-container *ngIf="file; else emptyList">
                                {{ img_file_name }}
                              </ng-container>
                              <ng-container *ngIf="!file">
                                Choose a file...
                              </ng-container>
                              
                            </ng-template>
      
                            <ng-template let-control="control" #button>
                              <i class="material-icons">
                                cloud_upload
                              </i>
                            </ng-template>
                          </file-upload>
      
                        </form>
                      </div>
                    </div>
                      <!-- </div> -->

                      <div class="col-md-3">
                        <div class="row" style="height: 50px;"> <h5> Dark Banner Media For Logging (Optional)</h5></div>
                          <div class="image-placeholder">
                            <mat-spinner class="upload-spinner" *ngIf="isdarkcoloredloggingLight"></mat-spinner>
                            <div class="profile-image" *ngIf="!isdarkcoloredloggingLight">
                            <div class="profile-dummy-img" *ngIf="!uploadedDarkLoggingLight">
                              <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                              <figcaption>Supported formats: .jpeg, .png, .jpg
                                <br>
                                Max size: ~5 MB
                              </figcaption>
                            </div>
                            <img *ngIf="uploadedDarkLoggingLight" class="image-data img-responsive"
                              [src]="s3url + dark_banner_media_logging_light_image_path">
                            </div>
                          
                          <form [formGroup]="demoForm" (change)="uploadImage('banner_media_logging_dark')" validate
                            [class]="isinvalid ? 'invalidcls' : ''">
                            <file-upload formControlName="banner_media_logging_dark" simple accept=".jpeg,.png,.jpg" class="file-uplaod-html">
                              <ng-template let-file #placeholder>
        
                                <ng-container *ngIf="file; else emptyList">
                                  {{ img_file_name }}
                                </ng-container>
                                <ng-container *ngIf="!file">
                                  Choose a file...
                                </ng-container>
                                
                              </ng-template>
        
                              <ng-template let-control="control" #button>
                                <i class="material-icons">
                                  cloud_upload
                                </i>
                              </ng-template>
                            </file-upload>
        
                          </form>
                        </div>
                        </div>
                </div>
  
                <div class="row">
                  <!-- <mat-form-field appearance="outline" class="col-md-12">
                    <mat-label>About Me</mat-label>
                    <textarea matInput placeholder="About Me" rows="6" formControlName="display_bio" required></textarea>
                  </mat-form-field> -->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <!-- <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Carey Card</button> -->
                <button mat-flat-button color="primary" class="btn btn-filled mt-30" [disabled]="!dataForm.valid">Update
                  Client</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-drawer-container>