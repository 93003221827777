
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">

        <app-breadcrumb *ngIf="newitem" route="content/logginghome/loggingcreate"></app-breadcrumb>
        <app-breadcrumb *ngIf="!newitem"  route="content/logginghome/loggingedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Logging Flow</h3>
            <div class="clearfix">
                <br />
                <br>
            </div>
        </div>
        <div class="col-md-12">
            <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                <div class="col-md-11 leftformsection">
                    <div class="row">
                        <div class="col-md-12" *ngIf="newitem">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Condition</mat-label>
                                <mat-select formControlName="condition_id_data_fk"
                                    (selectionChange)="getLogs($event.value)">
                                    <mat-option class="camelcased" *ngFor="let condition of allconditions" [disabled]="checkSymptom(condition.id)" 
                                        [value]="condition.id">
                                        {{condition.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="todo.length>0||done.length>0">
                            <div class="col-md-6">
                                <div class="example-container">
                                    <h3>Logs</h3>
                                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
                                        [cdkDropListConnectedTo]="[doneList]" class="example-list"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item.title}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="example">
                                    <h3>Final logging Flow</h3>
                                    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
                                        [cdkDropListConnectedTo]="[todoList]" class="example-list"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="example-box" *ngFor="let item of done" cdkDrag>{{item.title}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 zeromarginleft">
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                class="btn" [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Logging
                                Flow</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-drawer-container>