import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-choosemediamodal',
  templateUrl: './choosemediamodal.component.html',
  styleUrls: ['./choosemediamodal.component.scss']
})

export class ChoosemediamodalComponent implements OnInit {
  displayedColumns: string[] = ["image", "title", "type", "action"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  public showButtonLoader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  s3url: any = environment.s3url;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  viewMedia: any;
  mediaTitleName: any;
  mediaDescription: any;
  mediaMime: any;
  mediaType: any;
  mediaPath: any;
  public chosenType: any;
  public mediaselection: any;

  constructor(private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChoosemediamodalComponent>, private contentService: ContentserviceService, private systemService: SystemService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Media');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  applyFilterType(val) {
    var filtereddata = null;
    if (val != 'all') {
      filtereddata = this.tabulardata.filter(t => t.type == val);
    }
    else {
      filtereddata = this.tabulardata;
    }
    this.dataSource = filtereddata;
  }
  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }
  selectmedia(grp) {
    if (grp && grp.value) {
      var path = this.tabulardata.filter(d => d.id == grp.value)[0].path;
      var title = this.tabulardata.filter(d => d.id == grp.value)[0].title;
      var mediadata = {
        id: grp.value,
        path: path,
        title: title
      }
      this.dialogRef.close(mediadata);
    }
    else {
      this.systemService.showSnackBar('Please select media', 'OK');
    }
  }
  mediaselect() {
    this.mediaselection = true;
  }

  // List Images 
  // ----------------------------
  search() {
    var mediatype;
    if (this.data.type) {
      mediatype = true
    }
    else {
      mediatype = false
    }
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.contentService.getMediasSearch(this.searchcriteria, this.page.toString(), "20", "active", this.data.type, "", "")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    var mediatype;
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = this.tabulardata;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      if (mediatype == true) {
        data = data.filter(dt => dt.type == this.data.type)
      }
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = this.tabulardata;
    }
  }

}

