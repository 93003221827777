
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';
import { AEMailComponent } from '../aemail/aemail.component';
@Component({
  selector: 'app-edit-adverse-effect',
  templateUrl: './edit-adverse-effect.component.html',
  styleUrls: ['./edit-adverse-effect.component.scss']
})
export class EditAdverseEffectComponent implements OnInit {
  public dataVal: any;
  public selectedUserID: any;
  public typeFilters: any;
  adversedata:any;
  emaildata:any;
  ROAList:any;
  showButtonLoader: boolean;
  allMedications: any;
  public editAdverseform = this.fb.group({
    qualification: ["", Validators.required],
    country: [],
    adverse_reaction: [],
    adverse_start_date: [],
    adverse_end_date: [],
    adverse_outcome: [],
    situtation_to_patient: [[], Validators.required],
    action_taken: [[], Validators.required],
    causality_relatedness: [[], Validators.required],
    adverse_reaction_abated: [[], Validators.required],
    adverse_reaction_disappeared: [[], Validators.required],
    adverse_reaction_recurred: [[], Validators.required],
    medicinal_product: this.fb.array([
      this.buildBrodcastSelection(),
    ]),
    remodulin: [''],
    is_remodulin: [''],
    is_nonremodulin:[''],
    log_type: ['', Validators.required],
    mrd_date: ['', Validators.required],
    activation_code: [''],
    AdittionalInfo : [''],

    ae_form_submitted:['']
  });

  public medicinal_product: FormArray;

  IsUpdate!:boolean;
  constructor(public dialog: MatDialog, private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any, private systemService: SystemService,private commonService:CommonserviceService) { }

  ngOnInit() {
    
    let display=localStorage.getItem("eyeIconDisplay");
    if(display=="eyeIconDisplay")
    {
      this.IsUpdate=true;
    }
    this.dataVal = this.data;
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getallMedications();
    
  }

  convertISODate(log_date){
    var newlogdate = new Date(log_date);
    var mm = (newlogdate.getMonth() + 1).toString().padStart(2, "0");
    var dd = newlogdate.getDate().toString().padStart(2, "0");
    var yy = newlogdate.getFullYear();
    return yy + '-' + mm + '-' + dd;
  }
  convertDateFormat(log_date){
    const date = new Date(log_date);
    const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'short', year: 'numeric'
}).replace(/ /g, '-');
    // var newlogdate = new Date(log_date);
    // var mm = (newlogdate.getMonth() + 1).toString().padStart(2, "0");
    // var dd = newlogdate.getDate().toString().padStart(2, "0");
    // var yy = newlogdate.getFullYear();
    return formattedDate;
  }

  // get All medication

 async getallMedications() {
  this.UserdataService.getGraphQlData('{getAllMedication(status:true){id,title,route_of_administration,drug_concentration,medication_type}}')
    .subscribe(
      (res: any) => this.onSuccessUser(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
}

private onSuccessUser(data) {
  data = (data as any).data;
  
  this.allMedications = data.getAllMedication.filter(x => x.medication_type === 'remodulin');
  this.getallAdverseData();
}

getROAList(allROA){ 
  this.allMedications.filter(x => x.title === allROA);
  this.allMedications.filter((x) => {
    if (x.title === allROA) {
      this.ROAList = x.route_of_administration;
    }
  });
  // this.getDrugConc(allROA)
}
  dataObject() {
    var postdata = this.editAdverseform.value;
    let adverse_reaction = this.commonService.isHTML( this.editAdverseform.value.adverse_reaction);
    let AdittionalInfo = this.commonService.isHTML( this.editAdverseform.value.AdittionalInfo);
    if(adverse_reaction  === true){
      this.editAdverseform.controls.adverse_reaction.patchValue('')
      return;
    }
    if(AdittionalInfo  === true){
      this.editAdverseform.controls.AdittionalInfo.patchValue('')
      return;
    }
    postdata.qualification = postdata.qualification;
    postdata.country = postdata.country.toString();
    postdata.situtation_to_patient = JSON.stringify(postdata.situtation_to_patient);
    postdata.action_taken = JSON.stringify(postdata.action_taken);
    postdata.causality_relatedness = JSON.stringify(postdata.causality_relatedness);
    postdata.adverse_reaction_abated = JSON.stringify(postdata.adverse_reaction_abated);
    postdata.adverse_reaction_disappeared = JSON.stringify(postdata.adverse_reaction_disappeared);
    postdata.adverse_reaction_recurred = JSON.stringify(postdata.adverse_reaction_recurred);
    postdata.is_remodulin = 'true'; 
    postdata.is_nonremodulin = 'false';
    var newArray = [];
    if (this.editAdverseform.value.medicinal_product.length != 0) {
      newArray = postdata.medicinal_product.map(el => {
        return {
          name: el.name,
          manufacturer: "",
          daily_dose:el.daily_dose,
          indication:el.indication,
          administration_route:el.administration_route,
          start_date:this.convertDateFormat(el.start_date),
          end_date:this.convertDateFormat(el.end_date)
        }
      });
    }
    postdata.medicinal_product = JSON.stringify(newArray);
    postdata.adverse_reaction = postdata.adverse_reaction.toString();
    if(postdata.adverse_start_date == null){
      postdata.adverse_start_date = ""
    }else{
      postdata.adverse_start_date = this.convertISODate(postdata.adverse_start_date);
    }

    if(postdata.adverse_end_date == null){
      postdata.adverse_end_date = ""
    }else{
      postdata.adverse_end_date = this.convertISODate(postdata.adverse_end_date);
    }
    
    postdata.adverse_outcome = postdata.adverse_outcome.toString();
    postdata.patient_medical_history =  JSON.stringify([{ "title": "" , "message": postdata.AdittionalInfo }]);
    postdata.hc_data_fk = localStorage.getItem('scarletuserid');
    postdata.log_type = postdata.log_type.toString();
    postdata.patient_data_fk = this.selectedUserID.toString(); 
    const momentDate = new Date(postdata.mrd_date);
    postdata.mrd_date  = moment(momentDate).format("YYYY-MM-DD");
    if(postdata.activation_code == null){
      postdata.activation_code = ""
    }else{
      postdata.activation_code =  postdata.activation_code;
    }
    postdata.ae_form_submitted = "true";
    delete postdata.remodulin;
    delete postdata.AdittionalInfo;
    this.UserdataService.updateAdversedata(this.dataVal,postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.showButtonLoader = false;
        this.systemService.showSuccessSnackBar("AE form is updated.", "OK");
        // this.openEmailDialog(data);
      })
  }

  openEmailDialog(data) {
    this.emaildata = this.dialog.open(AEMailComponent, {
      width: '500px',
      height: 'auto',
      data: {
        dataKey: data,
      }
    });
    this.emaildata.afterClosed().subscribe(() => {
    });
  }

  // Add or remove Input field 
  buildBrodcastSelection(): FormGroup {
    return this.fb.group({
      name: [""],
      manufacturer: [""],
      daily_dose: [""],
      indication: [""],
      administration_route: [""],
      start_date: [""],
      end_date: [""],
    })
  }

  get formData(): FormArray { return <FormArray>this.editAdverseform.get('medicinal_product'); }

  addMoreMedicine(): void {
    this.medicinal_product = this.editAdverseform.get('medicinal_product') as FormArray;
    this.medicinal_product.push(this.buildBrodcastSelection());
  }

  removeBrodcast(index: number) {
    this.medicinal_product = this.editAdverseform.get('medicinal_product') as FormArray;
    this.medicinal_product.removeAt(index);
  }


  getallAdverseData() {
    this.UserdataService.getOneAdversedata(this.dataVal)
    .subscribe(
      (res : any) => this.onMagazineSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  
  private onMagazineSuccess(data) {
    const name = <FormArray>(this.editAdverseform.controls['medicinal_product']);
    // name.value.shift();
    
    data = (data as any).data;
    this.adversedata = data;
    // this.getallMedications();
    
    this.editAdverseform.patchValue(this.adversedata);
      if (this.adversedata.medicinal_product.length != 0) {
        name.controls.shift();
        for (var i = 0; i < this.adversedata.medicinal_product.length; i++) {
          name.push(this.initTextOptionswithdata(this.adversedata.medicinal_product[i]));
        }
      }
      if(this.adversedata.is_remodulin){
        this.editAdverseform.controls.remodulin.patchValue('is_remodulin');
      }else if(this.adversedata.is_nonremodulin){
        this.editAdverseform.controls.remodulin.patchValue('is_nonremodulin');
      }else{
        this.editAdverseform.controls.remodulin.patchValue('none');
      }
      // this.editAdverseform.controls.remodulin.patchValue(this.adversedata.is_remodulin);
      // this.editAdverseform.controls.is_nonremodulin.patchValue(this.adversedata.is_nonremodulin);
      this.editAdverseform.controls.action_taken.patchValue(this.adversedata.action_taken.toString())
      this.editAdverseform.controls.causality_relatedness.patchValue(this.adversedata.causality_relatedness.toString())
      this.editAdverseform.controls.adverse_reaction_abated.patchValue(this.adversedata.adverse_reaction_abated.toString())
      this.editAdverseform.controls.adverse_reaction_disappeared.patchValue(this.adversedata.adverse_reaction_disappeared.toString())
      this.editAdverseform.controls.adverse_reaction_recurred.patchValue(this.adversedata.adverse_reaction_recurred.toString())
      this.editAdverseform.controls.AdittionalInfo.patchValue(this.adversedata.patient_medical_history[0].message)
    // this.selfparentide.setValue(this.selfparentid)
    // this.language.setValue(this.langid);
    var scope = this;
    setTimeout(() => {
      // scope.uirender();
    }, 1000);
  }

  initTextOptionswithdata(data) {
    this.getROAList(data.name);
    return this.fb.group({
      name: [data.name],
      manufacturer: [data.manufacturer],
      daily_dose: [data.daily_dose],
      indication: [data.indication],
      administration_route: [data.administration_route],
      start_date: [moment(data.start_date).format("YYYY-MM-DD")],
      end_date: [moment(data.end_date).format("YYYY-MM-DD")]
    })
  }

  countyList = [
    // {"name": "Afghanistan", "code": "AF"},
    // {"name": "Åland Islands", "code": "AX"},
    // {"name": "Albania", "code": "AL"},
    // {"name": "Algeria", "code": "DZ"},
    // {"name": "American Samoa", "code": "AS"},
    // {"name": "AndorrA", "code": "AD"},
    // {"name": "Angola", "code": "AO"},
    // {"name": "Anguilla", "code": "AI"},
    // {"name": "Antarctica", "code": "AQ"},
    // {"name": "Antigua and Barbuda", "code": "AG"},
    // {"name": "Argentina", "code": "AR"},
    // {"name": "Armenia", "code": "AM"},
    // {"name": "Aruba", "code": "AW"},
    // {"name": "Australia", "code": "AU"},
    // {"name": "Austria", "code": "AT"},
    // {"name": "Azerbaijan", "code": "AZ"},
    // {"name": "Bahamas", "code": "BS"},
    // {"name": "Bahrain", "code": "BH"},
    // {"name": "Bangladesh", "code": "BD"},
    // {"name": "Barbados", "code": "BB"},
    // {"name": "Belarus", "code": "BY"},
    // {"name": "Belgium", "code": "BE"},
    // {"name": "Belize", "code": "BZ"},
    // {"name": "Benin", "code": "BJ"},
    // {"name": "Bermuda", "code": "BM"},
    // {"name": "Bhutan", "code": "BT"},
    // {"name": "Bolivia", "code": "BO"},
    // {"name": "Bosnia and Herzegovina", "code": "BA"},
    // {"name": "Botswana", "code": "BW"},
    // {"name": "Bouvet Island", "code": "BV"},
    // {"name": "Brazil", "code": "BR"},
    // {"name": "British Indian Ocean Territory", "code": "IO"},
    // {"name": "Brunei Darussalam", "code": "BN"},
    // {"name": "Bulgaria", "code": "BG"},
    // {"name": "Burkina Faso", "code": "BF"},
    // {"name": "Burundi", "code": "BI"},
    // {"name": "Cambodia", "code": "KH"},
    // {"name": "Cameroon", "code": "CM"},
    // {"name": "Canada", "code": "CA"},
    // {"name": "Cape Verde", "code": "CV"},
    // {"name": "Cayman Islands", "code": "KY"},
    // {"name": "Central African Republic", "code": "CF"},
    // {"name": "Chad", "code": "TD"},
    // {"name": "Chile", "code": "CL"},
    // {"name": "China", "code": "CN"},
    // {"name": "Christmas Island", "code": "CX"},
    // {"name": "Cocos (Keeling) Islands", "code": "CC"},
    // {"name": "Colombia", "code": "CO"},
    // {"name": "Comoros", "code": "KM"},
    // {"name": "Congo", "code": "CG"},
    // {"name": "Congo, The Democratic Republic of the", "code": "CD"},
    // {"name": "Cook Islands", "code": "CK"},
    // {"name": "Costa Rica", "code": "CR"},
    // {"name": "Cote D Ivoire", "code": "CI"},
    // {"name": "Croatia", "code": "HR"},
    // {"name": "Cuba", "code": "CU"},
    // {"name": "Cyprus", "code": "CY"},
    // {"name": "Czech Republic", "code": "CZ"},
    // {"name": "Denmark", "code": "DK"},
    // {"name": "Djibouti", "code": "DJ"},
    // {"name": "Dominica", "code": "DM"},
    // {"name": "Dominican Republic", "code": "DO"},
    // {"name": "Ecuador", "code": "EC"},
    // {"name": "Egypt", "code": "EG"},
    // {"name": "El Salvador", "code": "SV"},
    // {"name": "Equatorial Guinea", "code": "GQ"},
    // {"name": "Eritrea", "code": "ER"},
    // {"name": "Estonia", "code": "EE"},
    // {"name": "Ethiopia", "code": "ET"},
    // {"name": "Falkland Islands (Malvinas)", "code": "FK"},
    // {"name": "Faroe Islands", "code": "FO"},
    // {"name": "Fiji", "code": "FJ"},
    // {"name": "Finland", "code": "FI"},
    // {"name": "France", "code": "FR"},
    // {"name": "French Guiana", "code": "GF"},
    // {"name": "French Polynesia", "code": "PF"},
    // {"name": "French Southern Territories", "code": "TF"},
    // {"name": "Gabon", "code": "GA"},
    // {"name": "Gambia", "code": "GM"},
    // {"name": "Georgia", "code": "GE"},
    // {"name": "Germany", "code": "DE"},
    // {"name": "Ghana", "code": "GH"},
    // {"name": "Gibraltar", "code": "GI"},
    // {"name": "Greece", "code": "GR"},
    // {"name": "Greenland", "code": "GL"},
    // {"name": "Grenada", "code": "GD"},
    // {"name": "Guadeloupe", "code": "GP"},
    // {"name": "Guam", "code": "GU"},
    // {"name": "Guatemala", "code": "GT"},
    // {"name": "Guernsey", "code": "GG"},
    // {"name": "Guinea", "code": "GN"},
    // {"name": "Guinea-Bissau", "code": "GW"},
    // {"name": "Guyana", "code": "GY"},
    // {"name": "Haiti", "code": "HT"},
    // {"name": "Heard Island and Mcdonald Islands", "code": "HM"},
    // {"name": "Holy See (Vatican City State)", "code": "VA"},
    // {"name": "Honduras", "code": "HN"},
    // {"name": "Hong Kong", "code": "HK"},
    // {"name": "Hungary", "code": "HU"},
    // {"name": "Iceland", "code": "IS"},
    {"name": "India", "code": "IN"},
    // {"name": "Indonesia", "code": "ID"},
    // {"name": "Iran, Islamic Republic Of", "code": "IR"},
    // {"name": "Iraq", "code": "IQ"},
    // {"name": "Ireland", "code": "IE"},
    // {"name": "Isle of Man", "code": "IM"},
    // {"name": "Israel", "code": "IL"},
    // {"name": "Italy", "code": "IT"},
    // {"name": "Jamaica", "code": "JM"},
    // {"name": "Japan", "code": "JP"},
    // {"name": "Jersey", "code": "JE"},
    // {"name": "Jordan", "code": "JO"},
    // {"name": "Kazakhstan", "code": "KZ"},
    // {"name": "Kenya", "code": "KE"},
    // {"name": "Kiribati", "code": "KI"},
    // {"name": "Korea, Democratic People S Republic of", "code": "KP"},
    // {"name": "Korea, Republic of", "code": "KR"},
    // {"name": "Kuwait", "code": "KW"},
    // {"name": "Kyrgyzstan", "code": "KG"},
    // {"name": "Lao People S Democratic Republic", "code": "LA"},
    // {"name": "Latvia", "code": "LV"},
    // {"name": "Lebanon", "code": "LB"},
    // {"name": "Lesotho", "code": "LS"},
    // {"name": "Liberia", "code": "LR"},
    // {"name": "Libyan Arab Jamahiriya", "code": "LY"},
    // {"name": "Liechtenstein", "code": "LI"},
    // {"name": "Lithuania", "code": "LT"},
    // {"name": "Luxembourg", "code": "LU"},
    // {"name": "Macao", "code": "MO"},
    // {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
    // {"name": "Madagascar", "code": "MG"},
    // {"name": "Malawi", "code": "MW"},
    // {"name": "Malaysia", "code": "MY"},
    // {"name": "Maldives", "code": "MV"},
    // {"name": "Mali", "code": "ML"},
    // {"name": "Malta", "code": "MT"},
    // {"name": "Marshall Islands", "code": "MH"},
    // {"name": "Martinique", "code": "MQ"},
    // {"name": "Mauritania", "code": "MR"},
    // {"name": "Mauritius", "code": "MU"},
    // {"name": "Mayotte", "code": "YT"},
    // {"name": "Mexico", "code": "MX"},
    // {"name": "Micronesia, Federated States of", "code": "FM"},
    // {"name": "Moldova, Republic of", "code": "MD"},
    // {"name": "Monaco", "code": "MC"},
    // {"name": "Mongolia", "code": "MN"},
    // {"name": "Montserrat", "code": "MS"},
    // {"name": "Morocco", "code": "MA"},
    // {"name": "Mozambique", "code": "MZ"},
    // {"name": "Myanmar", "code": "MM"},
    // {"name": "Namibia", "code": "NA"},
    // {"name": "Nauru", "code": "NR"},
    // {"name": "Nepal", "code": "NP"},
    // {"name": "Netherlands", "code": "NL"},
    // {"name": "Netherlands Antilles", "code": "AN"},
    // {"name": "New Caledonia", "code": "NC"},
    // {"name": "New Zealand", "code": "NZ"},
    // {"name": "Nicaragua", "code": "NI"},
    // {"name": "Niger", "code": "NE"},
    // {"name": "Nigeria", "code": "NG"},
    // {"name": "Niue", "code": "NU"},
    // {"name": "Norfolk Island", "code": "NF"},
    // {"name": "Northern Mariana Islands", "code": "MP"},
    // {"name": "Norway", "code": "NO"},
    // {"name": "Oman", "code": "OM"},
    // {"name": "Pakistan", "code": "PK"},
    // {"name": "Palau", "code": "PW"},
    // {"name": "Palestinian Territory, Occupied", "code": "PS"},
    // {"name": "Panama", "code": "PA"},
    // {"name": "Papua New Guinea", "code": "PG"},
    // {"name": "Paraguay", "code": "PY"},
    // {"name": "Peru", "code": "PE"},
    // {"name": "Philippines", "code": "PH"},
    // {"name": "Pitcairn", "code": "PN"},
    // {"name": "Poland", "code": "PL"},
    // {"name": "Portugal", "code": "PT"},
    // {"name": "Puerto Rico", "code": "PR"},
    // {"name": "Qatar", "code": "QA"},
    // {"name": "Reunion", "code": "RE"},
    // {"name": "Romania", "code": "RO"},
    // {"name": "Russian Federation", "code": "RU"},
    // {"name": "RWANDA", "code": "RW"},
    // {"name": "Saint Helena", "code": "SH"},
    // {"name": "Saint Kitts and Nevis", "code": "KN"},
    // {"name": "Saint Lucia", "code": "LC"},
    // {"name": "Saint Pierre and Miquelon", "code": "PM"},
    // {"name": "Saint Vincent and the Grenadines", "code": "VC"},
    // {"name": "Samoa", "code": "WS"},
    // {"name": "San Marino", "code": "SM"},
    // {"name": "Sao Tome and Principe", "code": "ST"},
    // {"name": "Saudi Arabia", "code": "SA"},
    // {"name": "Senegal", "code": "SN"},
    // {"name": "Serbia and Montenegro", "code": "CS"},
    // {"name": "Seychelles", "code": "SC"},
    // {"name": "Sierra Leone", "code": "SL"},
    // {"name": "Singapore", "code": "SG"},
    // {"name": "Slovakia", "code": "SK"},
    // {"name": "Slovenia", "code": "SI"},
    // {"name": "Solomon Islands", "code": "SB"},
    // {"name": "Somalia", "code": "SO"},
    // {"name": "South Africa", "code": "ZA"},
    // {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
    {"name": "Spain", "code": "ES"},
    // {"name": "Sri Lanka", "code": "LK"},
    // {"name": "Sudan", "code": "SD"},
    // {"name": "Suriname", "code": "SR"},
    // {"name": "Svalbard and Jan Mayen", "code": "SJ"},
    // {"name": "Swaziland", "code": "SZ"},
    // {"name": "Sweden", "code": "SE"},
    // {"name": "Switzerland", "code": "CH"},
    // {"name": "Syrian Arab Republic", "code": "SY"},
    // {"name": "Taiwan, Province of China", "code": "TW"},
    // {"name": "Tajikistan", "code": "TJ"},
    // {"name": "Tanzania, United Republic of", "code": "TZ"},
    // {"name": "Thailand", "code": "TH"},
    // {"name": "Timor-Leste", "code": "TL"},
    // {"name": "Togo", "code": "TG"},
    // {"name": "Tokelau", "code": "TK"},
    // {"name": "Tonga", "code": "TO"},
    // {"name": "Trinidad and Tobago", "code": "TT"},
    // {"name": "Tunisia", "code": "TN"},
    // {"name": "Turkey", "code": "TR"},
    // {"name": "Turkmenistan", "code": "TM"},
    // {"name": "Turks and Caicos Islands", "code": "TC"},
    // {"name": "Tuvalu", "code": "TV"},
    // {"name": "Uganda", "code": "UG"},
    // {"name": "Ukraine", "code": "UA"},
    // {"name": "United Arab Emirates", "code": "AE"},
    // {"name": "United Kingdom", "code": "GB"},
    // {"name": "United States", "code": "US"},
    // {"name": "United States Minor Outlying Islands", "code": "UM"},
    // {"name": "Uruguay", "code": "UY"},
    // {"name": "Uzbekistan", "code": "UZ"},
    // {"name": "Vanuatu", "code": "VU"},
    // {"name": "Venezuela", "code": "VE"},
    // {"name": "Viet Nam", "code": "VN"},
    // {"name": "Virgin Islands, British", "code": "VG"},
    // {"name": "Virgin Islands, U.S.", "code": "VI"},
    // {"name": "Wallis and Futuna", "code": "WF"},
    // {"name": "Western Sahara", "code": "EH"},
    // {"name": "Yemen", "code": "YE"},
    // {"name": "Zambia", "code": "ZM"},
    // {"name": "Zimbabwe", "code": "ZW"}
    ]
}


