import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { catchError, map, retry } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  rawdata: any;
  allalerts: any;
  notificationdataarray: any;
  notificationdata: any;
  notificationPanel: any;
  hasUnreadAlert: boolean;
  totalAlerts: number;
  readAlerts: number;
  unReadAlerts: number;
  lastval: any;
  rawnewdata: any;
  newalerts: any;
  ws: WebSocket;
  todaydate: any;
  newuseradded = false;
  data: any;
  checker: any;
  private userListAPI = environment.cueSocketUrl + 'metadata/user/patients';
  public registerToken : any = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQzMjgsImlhdCI6MTU2Mjc0OTc1NX0.QgIXPnmtuzkrpIswdSUKpULdwNYB1Q4UAK4G2eXXln8';
  
  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  apiUrl: string = environment.serverUrl;
  public systemHaveAlerts: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public docSystemHaveAlerts: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public socketConnected: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  private starunstalUrl = this.apiUrl + 'doctor/notifications/starPatient';
  private prodUrl = this.apiUrl + 'doctor/';
  private markreadurl = this.apiUrl + 'doctor/notifications/read/';
  constructor(
    private http: HttpClient,private systemService : SystemService
  ) {}

  /**
   * @name getAlerts
   * @description gets all notifications
   * @param counter
   * @returns get response from API
   */


  // public getAllNotifications(typetext, page, limit, status, types, filters): Observable<any> {
  //   var sessiontoken = localStorage.getItem('scarlettoken');
  //   var uid = localStorage.getItem('scarletuserid');
  //   let options = new HttpHeaders().set('x-access-token', sessiontoken);
  //   let bodydata = {
  //     q: typetext,
  //     page: page,
  //     limit: limit,
  //     status: status,
  //     types: types,
  //     filters : filters

  //   };
  //   return this.http
  //     .post<any>(this.prodUrl+ 'notifications/search/filter/' + uid, bodydata, {
  //       headers: options
  //     })
  //     .pipe(
  //       retry(3),
  //       map(data => {
  //         let dataToCache = data.data.map(item => {
  //           return item;
  //         });
  //         return dataToCache;
  //       }),
  //       catchError(this.handleError('getAllNotifications'))
  //     ) as Observable<any>;
  // }

  // getAlerts(counter): Observable<string> {
  //   var SessionID = localStorage.getItem('scarlettoken');
  //   if (SessionID || SessionID != '') {
  //     const endPoint = 'alerts';
  //     let headers = new Headers();
  //     headers.append('sessionID', SessionID);
  //     headers.append('count', counter);
  //     let options = new RequestOptions({ headers: headers });
  //     // Return response
  //     return this._http
  //       .get(endPoint, options)
  //       .map(response => <string>response.json());
  //   }
  // }


  // public starunstar(id,value): Observable<any> {
  //   var sessiontoken = localStorage.getItem('scarlettoken');
  //   let options = new HttpHeaders().set('x-access-token', sessiontoken);
  //   let bodydata = {
  //     "patient_list":'['+id+']',
  //     "action" : value
  //   }
  //   return this.http
  //     .post<any>(this.starunstalUrl, bodydata, {
  //       headers: options
  //     })
  //     .pipe(
  //       retry(3),
  //       map(data => {
  //         return data;
  //       }),
  //       catchError(this.handleError('starunstar'))
  //     ) as Observable<any>;
  // }

  // public markallread(id): Observable<any> {
  //   var sessiontoken = localStorage.getItem('scarlettoken');
  //   let options = new HttpHeaders().set('x-access-token', sessiontoken);
  //   let bodydata ={
  //     "patient_id" : id
  //   }
  //   return this.http
  //     .patch<any>(this.markreadurl + localStorage.getItem('scarletuserid'), bodydata, {
  //       headers: options
  //     })
  //     .pipe(
  //       retry(3),
  //       map(data => {
  //         return data;
  //       }),
  //       catchError(this.handleError('markallread'))
  //     ) as Observable<any>;
  // }

  /**
   * @name markAsRead
   * @description Marking single notification as read
   * @param ide
   * @returns put response from API
   */
  // markAsRead(ide): Observable<string> {
  //   var SessionID = localStorage.getItem('scarlettoken');
  //   if (SessionID || SessionID != '') {
  //     const endPoint = 'alerts';
  //     let headers = new Headers();
  //     headers.append('alert_id', ide);
  //     headers.append('sessionID', SessionID);
  //     let options = new RequestOptions({ headers: headers });
  //     return this._http
  //       .put(this.prodUrl + endPoint, {}, options)
  //       .map(response => <string>response.json());
  //   }
  // }

  /**
   * @name markAllAsRead
   * @description Marking all notification as read
   * @param No_param
   * @returns put response from API
   */
  // markAllAsRead(): Observable<string> {
  //   var SessionID = localStorage.getItem('scarlettoken');
  //   if (SessionID || SessionID != '') {
  //     const endPoint = 'alerts';
  //     let headers = new Headers();
  //     headers.append('sessionID', SessionID);
  //     let options = new RequestOptions({ headers: headers });
  //     return this._http
  //       .put(this.prodUrl + endPoint, {}, options)
  //       .map(response => <string>response.json());
  //   }
  // }


  reconnnectToSocket() {
    if (this.ws.OPEN != 1) {
      // TODO: put a timer and try to connect again and again untill it reconnects
    }
  }
  public getUserList(id): Observable<any> {
    let bodydata = {
      "filters" : [{
        "filter_type": "patientIdFilter",
        "value": id
      }],
      "pageNumber": 1,
      "userId" : +JSON.parse(localStorage.getItem('scarletuserdata')).id,
      "query_string": ""
    }
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.http.post<any>(this.userListAPI, bodydata, { headers: headers })
      .pipe(
        retry(3),
        map(
          (data) => {
            let dataToCache = data;
            return dataToCache;
          }),
        catchError(this.handleError('getUserList'))
      ) as Observable<any>;
  }

  private handleError(operation: string = 'operation') {
    return (error: any) => {
      error.operation = operation;
      this.systemService.handleErrorResponse(error);
      return throwError(error);
    };
  }
}
