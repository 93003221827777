<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/therapyhome/therapyedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                Design Therapy
            </h3>
            <div class="clearfix"><br /><br /></div>
        </div>
        <div class="col-md-12">
            <div class="content-holder">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield"
                           >
                            <mat-label>Therapy Name</mat-label>
                            <input matInput placeholder="Enter the name" formControlName="title"    required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Alias Name (Display to user)</mat-label>
                            <input matInput placeholder="Enter the alias name" formControlName="alias_name" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Therapy Code</mat-label>
                            <input [pattern]="'^[A-Z0-9]+$'" maxlength="20" minlength="4" matInput
                                placeholder="Length: 4-20, Type: Capitalized alphabets, numbers or both"
                                formControlName="code" required>
                            <mat-error>
                                Please Enter Valid Code
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Primary Condition</mat-label>
                            <mat-select formControlName="master_condition"
                                (selectionChange)="primaryCondition($event.value)" required>
                                <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                    [value]="condition.id">
                                    {{condition.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 zeropadleft" *ngIf="primarycondition">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Secondary Condition</mat-label>
                            <mat-select (selectionChange)="secondaryConditions($event.value)"
                                formControlName="comorbilities" required multiple>
                                <mat-option [disabled]='secondaryoption.length > 0 && secondaryoption!= "none"'
                                    value="none">No Secondary Condition</mat-option>
                                <mat-option class="camelcased" *ngFor="let sc of secondarycondition"
                                    [disabled]='secondaryoption=="none"' [value]="sc.id">
                                    {{sc.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Total Levels</mat-label>
                            <input type="number" max="30" min="1" matInput placeholder="Total Levels"
                                formControlName="level_count" required>
                            <mat-error>
                                Cannot be greater than 30
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Knowledge levels</mat-label>
                            <mat-select formControlName="knowledge_levels" multiple required>
                                <mat-option value="amateur">
                                    Amateur
                                </mat-option>
                                <mat-option value="average">
                                    Average
                                </mat-option>
                                <mat-option value="proficient">
                                    Proficient
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->

                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Languages</mat-label>
                            <mat-select formControlName="languages" multiple required>
                                <mat-option class="camelcased" *ngFor="let language of alllanguages"
                                    [value]="language.id">
                                    {{language.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Complexity Level</mat-label>
                            <mat-select formControlName="complexity_level" required>
                                <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Tags</mat-label>
                            <mat-select formControlName="tags" multiple #singleSelectTag>
                                <mat-option>
                                    <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                        [placeholderLabel]="'Search Item'"
                                        [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmattag"
                                        ngModel (ngModelChange)="modelChangedTags()" ngDefaultControl>
                                        <div class="mat-select-search-custom-header-content notranslate">
                                            <i class="material-icons">
                                                search
                                            </i>
                                        </div>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option class="camelcased select-tags-active"
                                    *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                    {{tag.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield" >
                            <textarea rows='2' matInput placeholder="Enter the therapy description" 
                                formControlName="detailed_text" ></textarea>
                        </mat-form-field>
                    </div>

                    <div class="clearfix"><br /></div>
                    <div>
                        <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                            [disabled]="!dataForm.valid || ActiveTherapyValidate=='Active'">{{buttontext}}</button>
                        <p style="color: red;" *ngIf="ActiveTherapyValidate=='Active'">(Update Cannot be Allowed For Active
                            Therapy)</p>
                    </div>
                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>