import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterApiService } from 'src/app/shared/services';

declare var $: any;

@Component({
  selector: 'app-adminsectionleftnav',
  templateUrl: './adminsectionleftnav.component.html',
  styleUrls: ['./adminsectionleftnav.component.scss']
})

export class AdminsectionleftnavComponent implements OnInit, OnDestroy {
  showingnav: boolean = true;
  public userData: any;
  public localStoredData: any;
  public hasPhoneNumberRequest: boolean = false;

  private observer = null;

  constructor(
    private routerApiService: RouterApiService,
    private router: Router
  ) { 

    this.observer = this.router.events.subscribe((val) => {

      if ( val instanceof NavigationEnd){

        this.routerApiService.anyPhoneNumberRequests()
          .subscribe((data) => {
            if (data.data.length > 0){
              this.hasPhoneNumberRequest = true;
            }
          })
      }
    })
  }

  ngOnInit() {
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.localStoredData.user_acl == 'superadmin' ? true : false);
  }

  togglenav() {
    if (this.showingnav) {
      $(".userlistnavcontent").css('width', '0px');
      $(".mainrightcontainersecondary").css('marginLeft', '0px');
      $(".slidetogglecss").css('marginLeft', '-15px');

    }
    else {
      $(".userlistnavcontent").css('width', '200px');
      $(".mainrightcontainersecondary").css('marginLeft', '210px');
      $(".slidetogglecss").css('marginLeft', '185px');
    }
    this.showingnav = !this.showingnav;
  }
  
  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }
}
