<div class="col-md-12 clearfix">
    <h2 class="pull-left title-modal"> {{newitem ? 'New' : 'Update'}} Hospital
    </h2>
    <div class="pull-right">
      <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
    </div>
  </div>
  <mat-dialog-content>
    <div class="col-md-12">
      <div class="form-wrapper">
        <form [formGroup]="dataForm" validate>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12" style="padding:0">
                  <mat-form-field appearance="outline" class="col-md-12">
                    <mat-label>Hospital Name</mat-label>
                    <input matInput placeholder="Hospital name" formControlName="hospital_name" required>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="padding: 0;">
                  <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Type</mat-label>
                    <input matInput  placeholder="Enter Type of Hospital" type="text"
                       formControlName="type" required>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-md-6" >
                    <mat-label>Contact Info</mat-label>
                    <input matInput placeholder="Enter Contact Info"  type="text"
                       formControlName="contact_info" required>
                  </mat-form-field>
                </div>
                </div>
              <div class="row">
                <mat-form-field appearance="outline" class="col-md-12">
                  <mat-label>Address</mat-label>
                  <textarea matInput placeholder="Address" rows="6" formControlName="address" required></textarea>
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="col-md-12" style="padding: 0;">
                    <mat-form-field appearance="outline" class="col-md-3">
                      <mat-label>City</mat-label>
                      <input matInput placeholder="City" formControlName="city" required>
                    </mat-form-field>            
                    <mat-form-field appearance="outline" class="col-md-3">
                      <mat-label>State</mat-label>
                      <input matInput placeholder="Enter your Address" type="text"
                         formControlName="state" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-3" >
                      <mat-label>Postal Code</mat-label>
                      <input matInput placeholder="Enter your Address" type="text"
                         formControlName="postalcode" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-3" >
                      <mat-label>Country</mat-label>
                      <input matInput  placeholder="Enter your Address" type="text"
                         formControlName="country" required>
                    </mat-form-field>
                  </div>
                </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions align="center" class="col-md-12">
      <button mat-flat-button color="primary" class="btn btn-filled" (click)="dataObject()"
      [disabled]="!dataForm.valid">{{newitem ? 'Create' : 'Update'}} Hospital</button>
  </mat-dialog-actions>