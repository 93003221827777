<mat-drawer-container class="sidenav-container" autosize>

  <app-contentleftnav></app-contentleftnav>
  <div class="sidenav-content">
    <app-contentnavbar></app-contentnavbar>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="content/therapyhome"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="create-btn" [routerLink]="['/content/therapy/edit/', 'new']">
            Create New Therapy
          </button>
        </h3>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2">
            <mat-select [(ngModel)]="conditionFilters" placeholder="Filter Conditions" multiple class="sortcommonwhite" (selectionChange)="conditionfilter($event.value)">
              <mat-option *ngFor="let condition of allconditions" [value]="condition.id">{{condition.title}}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-md-10">
            <form>
              <div class="form-group has-feedback has-search">

                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''">
            <a (click)="tableSort('all')" class="all">
              All
            </a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">
              Active
            </a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">
              Inactive
            </a>
          </li>
        </ul>
      </div>

      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="widthfluid bordergrey list-table">

            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell class="camelcased" *matCellDef="let element" data-toggle="tooltip" title="{{element.title}}">
                <span [class]="element.is_active === true ? 'circle green' : 'circle red'"></span>
                {{element.title | truncate:[40, '...']}}
              </td>
            </ng-container>

            <!-- updatedat Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type </th>
              <td mat-cell class="camelcased" *matCellDef="let element">{{element.alias_name}}
              </td>
            </ng-container>

            <!-- updatedat Column -->
            <ng-container matColumnDef="updatedat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated </th>
              <td mat-cell class="camelcased" *matCellDef="let element">{{element.updated_at |date:'dd-MMM-yyy'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-capitalize" mat-cell *matCellDef="let element">
                <div class="action-button-row">

                  <button mat-button [routerLink]="['/content/therapy/editlevels/', element.id]" [disabled]="element.is_active==true" [ngClass]="{disabled:element.is_active == true }">
                    <img src="assets\img\therapy\therapy\editTherapy.svg"  tooltip="Update Level" alt="">
                  </button>
                 
                  <button  [disabled]="element.is_active==true" [ngClass]="{disabled:element.is_active == true }" trigger="click mouseenter" mat-button disableRipple [matMenuTriggerFor]="menu3"
                  class="no-hover-effect" [routerLink]="['/content/therapy/edit', element.id]">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit Therapy" alt="">
                  </button>

                  <button [disabled]="element.is_active == false" [ngClass]="{disabled:element.is_active == false }" mat-button (click)="viewTherapy()" tooltip="View Therapy"
                    class="hover-btn-image" [routerLink]="['/content/therapy/edit', element.id]" >
                    <div class="button-bg-image view-btn"></div>
                  </button>

                  <button *ngIf="userData == true"   mat-button [routerLink]="['/content/therapy/custombranding', element.id]"   >
                    <img src="assets\img\therapy\therapy\Edit_branding.svg" tooltip="Edit Branding" alt="">
                  </button>

                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon [ngStyle]="{'color':'#FB7A20'}">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu3="matMenu">
                    <button mat-menu-item *ngFor="let language of alllanguages"
                      [routerLink]="['/content/level/edit', element.id,  language.id, getChildID(element, language.id)]"><span
                        *ngIf="getChildID(element, language.id) || language.id == 1">
                        <mat-icon>done</mat-icon>
                      </span>{{language.name}}</button>
                  </mat-menu>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item disableRipple class="no-hover-effect" (click)="openDialog(element.id)"  tooltip="Delete" [disabled]="element.is_active === true" [ngClass]="{disabled:element.is_active == true }">
                      <img src="assets/img/dls/admin-table/Icon_Delete.svg" [disabled]="element.is_active === true" [ngClass]="{disabled:element.is_active == true }" alt="">
                     Delete
                    </button>

                    <button mat-menu-item disableRipple class="no-hover-effect active-green"
                      [ngClass]="{'my-class': element.is_active == true}" *ngIf="element.is_active == false "
                      (click)="isActive(element.is_active,element.id)">
                      <!-- <span *ngIf="element.is_active == true">
                        <img src="assets/img/dls/admin-table/Icon_Deactivate.svg" alt="">
                      </span> -->
                      <span *ngIf="element.is_active == false">
                        <img src="assets/img/dls/admin-table/Icon_Activate.svg" alt="">
                      </span>
                      Activate</button>                   
                    <!-- <button mat-menu-item (click)="switchSession(element.id,element.name)"
                      class="no-hover-effect">Access
                      Dashboard</button>
                    <button mat-menu-item (click)="changepasswordHistory(element)" disableRipple
                      class="no-hover-effect">Change Password History
                    </button>
                    <button mat-menu-item (click)="forceLogout(element)" disableRipple class="no-hover-effect">Force
                      Logout
                    </button>
                    <button mat-menu-item *ngIf="element.is_active" [routerLink]="['/admin/hcstatus',element.id]">Change
                      Status</button>
                    <button mat-menu-item *ngIf="element.is_active" [routerLink]="['/admin/hctimelog',element.id]">View
                      Time Logs</button>
                    <button mat-menu-item (click)="downloadReport(element)">Download Log Reports</button>
                    <button mat-menu-item disableRipple class="no-hover-effect active-green"
                      [ngClass]="{'my-class': element.is_active == true}"
                      (click)="isActive(element.is_active,element.id)"
                      *ngIf="!element.is_on_leave && userData">{{element.is_active? "Deactivate" :
                      "Activate"}}</button> -->
                  </mat-menu>
                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row">

          </table>
        </div>
      </div>


    </div>
  </div>

</mat-drawer-container>