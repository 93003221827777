<div class="row">
    <div class="col-md-12">
        <div class="row">
    
            <div class="col-md-8">
                <p class="title-cont">Edit Note</p>
            </div>
    
            <div class="col-md-4">
                <div class="copy-close-wrapper">
                    <div class="close-icon" (click)="close()"></div>
                </div>
            </div>
    
        </div>

        <div class="row">
        
            <form>

                <mat-form-field appearance="outline" class="col-md-12">
                    <textarea name="edit" matInput [(ngModel)]="editNotes" class="edit-text-area"></textarea>
                </mat-form-field>
                

                <div class="col-md-12">
                    <button class="btn btn-medium btn-filled" (click)="saveEditNote()" [disabled]="editNotes.length === 0 || oldString === editNotes"> Save </button>
                </div>
            </form>
            

        </div>
    </div>     
</div>