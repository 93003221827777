<div class="status-wrapper">
  <div class="row d-flex-row">
    <div class="col-md-12 left">
      <div class="media">
        <div class="media-body media-middle">
          <h4 class="media-heading">{{userDetails?.name}}</h4>
          <p>{{userDetails?.master_condition_data?.name}}</p>
          <div class="close-icon">
            <i class="material-icons mat-dialog-close m-top notranslate" (click)="onNoClick()">
              close
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="set-status">
  <form [formGroup]="dataForm" validate>
    <div class="row">
      <div class="col-md-12">
        <div class="current-healthcoach">
          <h4>Current Healthcoach</h4>
          <h5 class="text-capitalize" *ngIf="userDetails.hc_data">{{userDetails?.hc_data.name}}</h5>
          <h5 *ngIf="!userDetails.hc_data">-</h5>
        </div>
      </div>

      <mat-form-field appearance="outline" class="full-width-formfield col-md-12">
        <mat-label>Select Healthcoach</mat-label>
        <mat-select formControlName="new_coach_id" #singleSelectHC #singleSelect>
          <mat-option>
            <ngx-mat-select-search class="tagmat" [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
              [noEntriesFoundLabel]="'No matching records found'" [formControl]="searchmathc" ngModel
              (ngModelChange)="modelChangedHC()" ngDefaultControl>
              <div class="mat-select-search-custom-header-content">
                <i class="material-icons" style="margin-left:-598px;margin-top: -60px;">
                  search
                </i>
              </div>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let hc of filteredHC | async" [value]="hc.id">
            {{hc.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="btn-holder">
          <button mat-flat-button color="primary" class="btn-common" (click)="dataObject()">Update</button>
          <button mat-raised-button class="btn-common" (click)="closeDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</div>