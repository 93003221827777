import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../../shared/guards';
import { CampaignHistoryComponent } from './campaign-history/campaign-history.component';
import { CampaignComponent } from './campaign.component';
import { CampaignqrComponent } from './campaignqr/campaignqr.component';
import { CampaignviewComponent } from './campaignview/campaignview.component';
import { CreatecampaignComponent } from './createcampaign/createcampaign.component';

const routes: Routes = [
  { path: '', component: CampaignComponent, canActivate: [AdminGuardGuard] },
  { path: 'createcampaign/edit/:id', component: CreatecampaignComponent, canActivate: [AdminGuardGuard] },
  { path: 'campaign/qr', component: CampaignqrComponent, canActivate: [AdminGuardGuard] },    
  { path: 'campaign/campaignHistory/:id', component: CampaignHistoryComponent, canActivate: [AdminGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule {}