import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { HttpService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertsService extends HttpService{
  rawdata: any;
  allalerts: any;
  notificationdataarray: any;
  notificationdata: any;
  notificationPanel: any;
  hasUnreadAlert: boolean;
  totalAlerts: number;
  readAlerts: number;
  unReadAlerts: number;
  lastval: any;
  rawnewdata: any;
  newalerts: any;
  ws: WebSocket;
  todaydate: any;
  newuseradded = false;
  data: any;
  checker: any;
  public newalertData: EventEmitter<any> = new EventEmitter<any>();
  public registerToken: any = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQzMjgsImlhdCI6MTU2Mjc0OTc1NX0.QgIXPnmtuzkrpIswdSUKpULdwNYB1Q4UAK4G2eXXln8';
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  apiUrl: string = environment.serverUrl;


  private prodUrl = this.apiUrl + 'notifications/search/filter/';
  private starunstalUrl = this.apiUrl + 'notifications/starPatient';
  private activityurl = this.apiUrl + 'notifications/patient/activity';
  private markreadurl = this.apiUrl + 'notifications/read/';
  private userListAPI = environment.cueSocketUrl + 'metadata/user/patients';

  constructor(private http: HttpClient) { 
      super(http)
  }


  public getAllNotifications(typetext, page, limit, status, types, filters): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      types: types,
      filters: filters

    };
    return this.post(this.prodUrl + localStorage.getItem('scarletuserid'), bodydata)
  }
  public starunstar(id, value): Observable<any> {
    let bodydata = {
      "patient_list": '[' + id + ']',
      "action": value
    }
    return this.post(this.starunstalUrl, bodydata)
  }

  public markallread(id): Observable<any> {
    return this.patch(this.markreadurl + localStorage.getItem('scarletuserid'), {"patient_id": id})
  }

  public getAllActivity(typetext, page, limit, status, types, patient_id): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      types: types,
      patient_id: patient_id.toString()

    };
    return this.post(this.activityurl, bodydata)
  }

  public getUserList(id): Observable<any> {
    // "userId": 'H' + JSON.parse(localStorage.getItem('scarletuserdata')).id,

    let bodydata = {
      "filters": [{
        "filter_type": "patientIdFilter",
        "value": id
      }],
      "pageNumber": 1,
      "userId": 'H' + JSON.parse(localStorage.getItem('scarletuserid')),
      "query_string": ""
    }
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.userListAPI, bodydata, { headers: headers })
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
