import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

@Component({
  selector: 'app-editcareycard',
  templateUrl: './editcareycard.component.html',
  styleUrls: ['./editcareycard.component.scss']
})

export class EditcareycardComponent implements OnInit {

  complexityenum: any = complexityenum;
  public showButtonLoader: boolean = false;
  alltags: any;
  colorval: any;
  public selfparentid: any;
  public newitem: boolean = false;
  chooseMedia: any;
  public childid: any;
  s3url: any = environment.s3url;
  public langid: any;
  careydata: any;
  type: any;
  optioncount: any;
  allintents: any;
  careycarddata: any;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    tag_title: [''],
    detailed_text: [''],
    complexity_level: ['', Validators.required],
    image_media: [''],
    type: ['', Validators.required],
    sub_type: ['none'],
    intent: [''],
    intent_button_text: [''],
    add_select_option: [''],
    text_option_count: ['', Validators.required],
    options: this.fb.array([], Validators.required),
    options_media: this.fb.array([]),
    tags: [[], Validators.required],
    color: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
  });
  private _onDestroy = new Subject<void>();

  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;


  initTextOptions() {
    return this.fb.group({
      txtoptn: []
    });
  }

  initaudioOptions() {
    return this.fb.group({
      imgoptn: []
    });
  }

  setBtnStyles() {
    let defaultBtnStyles = {
      'border-color': this.dataForm.value.color,
      'color': this.dataForm.value.color,
    };
    return defaultBtnStyles;
  }

  constructor(private browserTitle: Title, private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Carey Card');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getAllCareyCardData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getAllCareyCardData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getAllCareyCardData(params.chid);
        }
      }
      this.getTags();
      this.getIntents();
    });
  }


  modelChangedTags() {
    this.filterTags();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  contentType(e) {
    this.type = e;
    if (this.type == 'content' && !this.dataForm.get('text_option_count') && !this.dataForm.get('options')) {
      this.dataForm.addControl('text_option_count', new FormControl('', Validators.required));
      this.dataForm.addControl('options', this.fb.array([], Validators.required));
      this.changeOptions(0);
      const txtoptnformcontrol = <FormControl>(this.dataForm.controls['text_option_count']);
      txtoptnformcontrol.reset();
    }
    if (this.type == 'intent' && this.dataForm.get('text_option_count') && this.dataForm.get('options')) {
      this.dataForm.removeControl('text_option_count');
      this.dataForm.removeControl('options');
    }
  }

  subType(e) {
    this.changeOptions(0);
    (<FormControl>(this.dataForm.controls['text_option_count'])).reset();
  }

  changeOptions(e) {
    this.optioncount = e;
    var control1 = <FormArray>this.dataForm.controls['options'];
    var control2 = <FormArray>this.dataForm.controls['options_media'];
    if (control1.length !== 0) {
      if (control1.length < e) {
        for (let l = control1.length; l < e; l++) {
          control1.push(this.initTextOptions());
        }
      }
      if (control1.length > e) {
        for (let l = control1.length; l > e; l--) {
          control1.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < this.optioncount; k++) {
        control1.push(this.initTextOptions());
      }
    }

    if (control2.length !== 0) {
      if (control2.length < e) {
        for (let l = control2.length; l < e; l++) {
          control2.push(this.initaudioOptions());
        }
      }
      if (control2.length > e) {
        for (let l = control2.length; l > e; l--) {
          control2.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < this.optioncount; k++) {
        control2.push(this.initaudioOptions());
      }
    }

  }


  // To Get Media
  // ----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe(
        (res: any) => this.getMediaSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getMediaSuccess(data) {
    data = (data as any).data;
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // ----------------------------


  // To Get All CareyCard
  // ----------------------------

  getAllCareyCardData(ide) {
    this.contentService.getOneDataById(ide, 'careycard')
      .subscribe(
        (res: any) => this.getAllCareyCardSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getAllCareyCardSuccess(data) {
    data = (data as any).data;
    this.careydata = data;
    this.type = this.careydata.type;
    if (this.careydata.type == 'content') {
      this.optioncount = this.careydata.text_option_count;
      this.careydata.sub_type = ((this.careydata.sub_type == null) || (this.careydata.sub_type == '')) ? 'none' : this.careydata.sub_type;
      this.changeOptions(this.optioncount);
      for (let k = 0; k < this.optioncount; k++) {
        let txtformelement = (<FormArray>(this.dataForm.controls['options'])).at(k);
        txtformelement['controls'].txtoptn.setValue(this.careydata.options[k]);
        let imgformelement = (<FormArray>(this.dataForm.controls['options_media'])).at(k);
        imgformelement['controls'].imgoptn.patchValue(this.careydata.options_media[k]);
      }
      this.careydata.text_option_count = this.careydata.text_option_count.toString();
      const formtxtoptncount = <FormControl>this.dataForm.controls['text_option_count'];
      formtxtoptncount.setValue(this.careydata.text_option_count);
    }
    else if (this.careydata.type == 'intent') {
      this.careydata.intent = this.careydata.intent.toString();
    }
    this.dataForm.patchValue(this.careydata);
    if (this.careydata.image_media != null) {
      this.getMedia(this.careydata.image_media);

    }
    if (this.type == 'intent') {
      this.dataForm.removeControl('text_option_count');
      this.dataForm.removeControl('options');
    }
    this.selfparentide.setValue(this.selfparentid)
    this.language.setValue(this.langid);
  }

  // ----------------------------


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // Get All Intents
  // -----------------------------

  getIntents() {
    this.contentService
      .getGraphQlData('{getParentIntents(status:true){id,title,url}}', false)
      .subscribe((res: any) => this.ongetIntentsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )

  }

  ongetIntentsSuccess(data) {
    this.allintents = data.getParentIntents;
  }
  // -----------------------------


  // Submitting Carey Cards
  // -----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    var optiontext = [];
    var optionimg = [];
    var duptxtoptn = [];
    if (this.type == 'content') {
      postdata.options.forEach(el => {
        optiontext.push(el.txtoptn);
      });
      postdata.options_media.forEach(el => {
        optionimg.push(el.imgoptn);
      });
    }
    duptxtoptn = optiontext.map(v => v.toLowerCase());
    if (!duptxtoptn.every((e, i, a) => a.indexOf(e) === i)) {
      this.systemService.showSnackBar("Please enter the unique texts to create the carey card.", "OK");
      return;
    }
    if (optionimg.length > 0 && optiontext.length != optionimg.length) {
      this.systemService.showSnackBar("option images length doesn't match with text", "OK");
      return;
    }
    postdata.title = this.removeHTMLtags(postdata.title).toString();
    postdata.tag_title = this.removeHTMLtags(postdata.title).toString()
    postdata.detailed_text =this.removeHTMLtags(postdata.title).toString();
    postdata.complexity_level = postdata.complexity_level.toString();
    postdata.image_media = (postdata.image_media === '' || postdata.image_media === null) ? '' : postdata.image_media.toString();
    postdata.intent = (postdata.intent === '' || postdata.intent === null) ? '' : postdata.intent.toString();
    postdata.intent_button_text = (postdata.intent_button_text === '' || postdata.intent_button_text === null) ? '' : this.removeHTMLtags(postdata.intent_button_text).toString();;
    postdata.type == 'content' ? postdata.add_select_option = true : postdata.add_select_option = false;
    postdata.add_select_option = postdata.add_select_option.toString();
    postdata.type = postdata.type.toString();
    if (this.type == 'content') {
      postdata.text_option_count = postdata.text_option_count.toString();
    }
    postdata.sub_type = (postdata.sub_type == 'none') ? '' : postdata.sub_type;
    postdata.options = JSON.stringify(optiontext);
    postdata.options_media = JSON.stringify(optionimg);
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.color = postdata.color.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();

    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'careycard')
        .subscribe(
          (res: any) => this.onCreateSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'careycard')
        .subscribe(
          (res: any) => this.onUpdateSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

  private onCreateSuccess(data) {
    data = (data as any).data;
    this.careycarddata = data;
    this.systemService.showSuccessSnackBar(("CareyCard has been added"), "OK");
    this.router.navigate(['content/careycard']);
  }

  private onUpdateSuccess(data) {
    data = (data as any).data;
    this.systemService.showSuccessSnackBar(("CareyCard has been updated"), "OK");
    this.router.navigate(['content/careycard']);
  }

  // ----------------------------


  openmediabox(type, val?) {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image'
      },
      panelClass: 'mediaDailog'
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      if (type == 'imagemedia') {
        const formelement = <FormControl>this.dataForm.controls['image_media'];
        formelement.setValue(data.id);
        this.allmedia = data.path;
      }
      else {
        const formelement = <FormControl>this.dataForm.controls['options_media'];
        
        formelement['controls'][val].controls.imgoptn.setValue(data.id);
      }
    });
  }

  ​ removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}

export enum complexityenum {
  High,
  Medium,
  Low
}