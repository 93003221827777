import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-changehealthcoach',
  templateUrl: './changehealthcoach.component.html',
  styleUrls: ['./changehealthcoach.component.scss'],

})

export class ChangehealthcoachComponent implements OnInit {

  userDetails: any;
  public hcdata: any;
  selectedOption: any;
  public allhc: any = [];
  public showButtonLoader: boolean = false;
  public filteredHC: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmathc: FormControl = new FormControl();
  public dataForm = this.fb.group({
    new_coach_id: ['']
  });
  hclist: any;
  public firsthcid: any;

  constructor(
    private dialogRef: MatDialogRef<ChangehealthcoachComponent>, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService,
    private systemService: SystemService, private commonService: CommonserviceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private browserTitle: Title
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Change Health Coach');
  }

  ngOnInit() {
    this.setSEOTags();
    this.userDetails = this.data.element;
    this.getHC();
  }

  modelChangedHC() {
    this.filterHC();
  }

  private filterHC() {
    if (!this.allhc) {
      return;
    }
    // get the search keyword
    let search = this.searchmathc.value;
    if (!search) {
      this.filteredHC.next(this.allhc.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredHC.next(
      this.allhc.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  // To Get Health Coach List 
  // ----------------------------

  getHC() {
    this.contentService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name,display_name}}')
      .subscribe(
        (res: any) => this.onHCDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCDataSuccess(data) {
    data = (data as any).data;
    this.hclist = data.getAllHealthcoaches;
    for (var j = 0; j < this.hclist.length; j++) {
      if (this.userDetails.hc_data) {
        if (this.hclist[j].id != this.userDetails.hc_data.id) {
          this.allhc.push(this.hclist[j]);
        }
      }
      else {
        this.allhc.push(this.hclist[j]);
        this.firsthcid = this.hclist[0].id;
      }
    }
    this.filteredHC.next(this.allhc.slice());
  }

  // ----------------------------

  closeDialog() {
    this.dialogRef.close();
  }


  // Submitting HC Data 
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    let bodydata;
    if (this.userDetails.hc_data != null) {
      bodydata = { 'patientId': this.userDetails.id.toString(), 'oldHcId': this.userDetails.hc_data ? this.userDetails.hc_data.id.toString() : "", 'newHcId': postdata.new_coach_id.toString() };
    }
    else if (this.userDetails.hc_data == null) {
      bodydata = { 'patientId': this.userDetails.id.toString(), 'newHcId': postdata.new_coach_id.toString() };
    }
    postdata.new_coach_id = postdata.new_coach_id;
    this.commonService.hcTransfer(bodydata, 'hctransfer')
      .subscribe(
        (res: any) => this.onHCTransferSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCTransferSuccess() {
    this.dialog.closeAll();
    this.systemService.showSuccessSnackBar(("Healthcoach has been updated"), "OK");
  }

  // ----------------------------


}