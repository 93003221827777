<div class="row">
  <div class="col-md-12">
    <div class="clearfix">
      <h2 class="pull-left title-modal">Edit healthcoach</h2>
      <div class="pull-right">
        <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal" alt="">
      </div>
    </div>
    <mat-form-field appearance="outline" class="col-md-12 p-0">
      <mat-label>Health Coach</mat-label>
      <mat-select [(ngModel)]="selectedOption" multiple>
        <mat-option *ngFor="let hc of allhc" [value]="hc.id">{{hc.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button color="primary" class="submit-btn" (click)="dataObject()">Done</button>
  </div>
</div>