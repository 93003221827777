import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { Userdetailsinterface } from 'src/app/healthcoach/models/userdetailsinterface';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';


@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss']
})

export class TargetsComponent implements OnInit {
  public mealTargetFormGroup: FormGroup;
  public activityTargetFormGroup: FormGroup;
  public programDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public userTargetSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public mealCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);/*true ti show submit button */
  public activityCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);/*true ti show submit button */
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  private tabID: number = 6;
  public subscribeObj: any;
  public showtargets: boolean = true;

  constructor(private formBuilder: FormBuilder, private sysService: SystemService,
    private userDetailService: UserdetailsService, private appContext: ContextService) { }

  ngOnInit() {
    /**Build meal form */
    this.buildMealForm();
    /**Build activity form */
    this.buildActivityForm();
  }

  buildMealForm(): void {
    this.mealTargetFormGroup = this.formBuilder.group({
      weight: [null],
      carbs: [null, Validators.compose([Validators.required, Validators.max(100)])],
      protein: [null, Validators.compose([Validators.required, Validators.max(100)])],
      fats: [null, Validators.compose([Validators.required, Validators.max(100)])],
      calories: [null, Validators.required]
    });
  }

  buildActivityForm(): void {
    this.activityTargetFormGroup = this.formBuilder.group({
      activitystatus: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID)) {
        this.initProfileCreate(this.appContext.selectedChatUser.getValue());
        this.initComponentDraw(this.appContext.selectedChatUser.getValue());
        this.getActivityAnalysis(this.appContext.selectedChatUser.getValue());
      }
    });
  }

  initProfileCreate(selectedUserID: string) {
    this.loadingSubject$.next(true);
    /**Reinitiliase program detail subject */
    this.programDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**API Call */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      data = data == null ? [] : data;
      this.programDetailsSubject$.next(data);
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
    });
  }

  initComponentDraw(selectedUserID: string) {
    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    this.userTargetSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**Call API */
    this.userDetailService.getMealTargets(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false) })
    ).subscribe(data => {
      data = (data as any).data;
      this.userTargetSubject$.next(data);
      /**Assign to form group */
      if (!data.meal_targets) {
        this.showtargets = false;
      }
      else {
        this.showtargets = true;
      }
      data.meal_targets.overall.weight = data.weight;
      if (data.meal_targets.overall)
        this.mealTargetFormGroup.patchValue(data.meal_targets.overall);
      else
        this.sysService.showSnackBar("No meal targets found.", "Ok");
    });
  }

  getActivityAnalysis(selectedUserID: string) {
    this.loadingSubject$.next(true);
    /**Reinitilise user activity component */
    this.userTargetSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**Call API */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false) })
    ).subscribe(data => {
      data = (data as any).data;
      this.userTargetSubject$.next(data);
      if (data.activity_analysis)
        this.activityTargetFormGroup.controls.activitystatus.patchValue(data.activity_analysis);
      else
        this.sysService.showSnackBar("No activity analysis found.", "Ok");
    });
  }

  getweightval(b) {
    var k = b;
    k = k.replace("_", " ");
    return k
  }
  
  saveMealTarget(): void {
    let data: any = this.mealTargetFormGroup.value;
    delete data.weight;
    /**Check for 100 sum */
    if ((data.carbs + data.protein + data.fats) > 100) {
      this.sysService.showSnackBar("The sum of carbs, protein, and fats should not exceed 100.", "Ok");
      return;
    }
    data["total"] = data.carbs + data.protein + data.fats;
    /**Call Save API */
    this.mealCreationSubject$.next(false);
    this.userDetailService.saveMealTargets(this.appContext.selectedChatUser.getValue(), data).pipe(
      delay(100),
      catchError((error: any) => {
        this.mealCreationSubject$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.mealCreationSubject$.next(true) })
    ).subscribe(data => {
      this.mealCreationSubject$.next(true);
      if (data.code == 200) {
        this.sysService.showSnackBar(data.message, "OK");
      } else {
        this.sysService.showSnackBar(data.message, "OK");
      }
    });
  }

  saveActivityTarget(): void {
    let data: any = this.activityTargetFormGroup.value;
    /**Call Save API */
    this.activityCreationSubject$.next(false);
    this.userDetailService.saveActivityAnalysis(this.appContext.selectedChatUser.getValue(), data.activitystatus).pipe(
      delay(100),
      catchError((error: any) => {
        this.activityCreationSubject$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.activityCreationSubject$.next(true) })
    ).subscribe(data => {
      this.activityCreationSubject$.next(true);
      /**Check for status code */
      if (data.code == 200) {
        this.sysService.showSnackBar(data.message, "OK");
      } else {
        this.sysService.showSnackBar(data.message, "OK");
      }
    });
  }

  ngOnDestroy(): void {
    this.subscribeObj.unsubscribe();
    // this.userTargetSubject$.unsubscribe();
    // this.loadingError$.unsubscribe();
    // this.loadingSubject$.unsubscribe();
    // this.mealCreationSubject$.unsubscribe();
    // this.activityCreationSubject$.unsubscribe();
  }

}
