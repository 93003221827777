<form [formGroup]="requestForm" validate *ngIf="showerror == null">
    <div class="col-md-12">
        <h3>{{statustitle}} Request</h3>
    </div>
    <mat-form-field class="col-md-12" appearance="outline">
        <textarea matInput placeholder="Describe all your changes" formControlName="comment"></textarea>
    </mat-form-field>
    <div class="col-md-12">
        <button mat-flat-button color="primary" [disabled]="!requestForm.valid" class="centerbtn mt-15"
            (click)="approval()">
            Submit</button>
    </div>
</form>
<div *ngIf="showerror != null">
    <div class="warningsec">
        <h4 style="color: #e74e06;">Errors</h4>
        <div *ngFor="let pi of showerror?.error">
            <span><strong style="text-transform: capitalize;">{{pi.type}} | {{pi?.id}} </strong>
                {{pi.title}}</span><br />
            <p class="pmessage">{{pi.message}}</p>
        </div>
        <div class="clearfix"><br /></div>
        <h4 style="color: #ffa600;">Warning</h4>
        <div *ngFor="let pi of showerror?.warning">
            <span><strong style="text-transform: capitalize;">{{pi.type}} | {{pi?.id}} </strong>
                {{pi.title}}</span><br />
            <p class="pmessage">{{pi.message}}</p>
        </div>
    </div>
</div>