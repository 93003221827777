import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Component({
  selector: 'app-loadorerror',
  templateUrl: './loadorerror.component.html',
  styleUrls: ['./loadorerror.component.scss']
})
export class LoadorerrorComponent implements OnInit {
  @Input() loadError: boolean;
  @Input() loadSubject: boolean;
  @Input() noUserSelectCard?: boolean;

  public emptySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();

  private showUserSelectCard: boolean = true;

  constructor() { }

  ngOnInit() {
    /**No card if  noUserSelectCard is true otherwise show*/
    if(this.noUserSelectCard){
      this.showUserSelectCard = false;
    }else{
      this.showUserSelectCard = true;
    }
  }

  ngAfterViewInit(){
    this.loadingError$.next(this.loadError);
    this.loadingSubject$.next(this.loadSubject);
    if(this.showUserSelectCard){
      this.handleEmptySubject();
    }
  }

  ngOnChanges(){
    if(this.showUserSelectCard){
      this.handleEmptySubject();
    }
    if(this.loadError){
      this.loadingError$.next(this.loadError);
      this.loadingSubject$.next(false);
    }
    if(this.loadSubject){
      this.loadingSubject$.next(this.loadSubject);
      this.loadingError$.next(false);
    }
    
    
  }

  handleEmptySubject(){
    let scope = this;
    setTimeout(function(){
      if(!scope.loadSubject && scope.loadError == null){
        scope.emptySubject$.next(true);
      }else{
        scope.emptySubject$.next(false);
      }
    }, 1000);
  }
    

  ngOnDestroy(){
   //this.emptySubject$.unsubscribe();
   //this.loadingError$.unsubscribe();
   //this.loadingSubject$.unsubscribe();
  }

}
