<div class="generaldesc">
    <div class="row">
        <div class="col-md-8">
            <h4>{{mealdata.type}}</h4>
            <p>on {{getTheTime(mealdata.on_date)}}</p>
        </div>
        <div class="col-md-4">
            <p>Calories (<span
                    [class]="mealdata.nutritional_analysis.calories == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.calories}}</span>)
            </p>
            <h4>{{mealdata.total_calories | number}}</h4>
        </div>
    </div>
</div>
<div class="clearfix"><br /></div>
<div class="panel panel-default panel-table">
    <div class="panel-heading">
        <div class="tr">
            <div class="td">Food name</div>
            <div class="td">Count</div>
            <div class="td">Consumed in grams</div>
            <div class="td">Calories</div>
            <div class="td">Carbs</div>
            <div class="td">Protein</div>
            <div class="td">Fiber</div>
            <div class="td">Fats</div>
        </div>
    </div>
    <div class="panel-body">
        <div class="tr" *ngFor="let f of mealdata.food">
            <div class="td">{{f.food_name}}</div>
            <div class="td">{{f.count}} {{f.unit}}</div>
            <div class="td">{{f.consumed_in_grams}}</div>
            <div class="td">{{f.calories}}</div>
            <div class="td">{{f.carbs}}</div>
            <div class="td">{{f.protein}}</div>
            <div class="td">{{f.fiber}}</div>
            <div class="td">{{f.fats}}</div>
        </div>
    </div>
</div>
<table class="totaldatacss">
    <tr>
        <td>
            <p>Carbs (<span
                    [class]="mealdata.nutritional_analysis.carbs == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.carbs}}</span>)
            </p>
            <h4>{{mealdata.total_carbs | number}}</h4>
        </td>
        <td>
            <p>Fats (<span
                    [class]="mealdata.nutritional_analysis.fats == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.fats}}</span>)
            </p>
            <h4>{{mealdata.total_fats | number}}</h4>
        </td>
        <td>
            <p>Protein (<span
                    [class]="mealdata.nutritional_analysis.protein == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.protein}}</span>)
            </p>
            <h4>{{mealdata.total_protein | number}}</h4>
        </td>
        <td>
            <p>Fiber (<span
                    [class]="mealdata.nutritional_analysis.fiber == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.fiber}}</span>)
            </p>
            <h4>{{mealdata.total_fiber | number}}</h4>
        </td>
        <td>
            <p>Potassium (<span
                    [class]="mealdata.nutritional_analysis.potassium == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.potassium}}</span>)
            </p>
            <h4>{{mealdata.total_potassium | number}}</h4>
        </td>
        <td>
            <p>Sodium (<span
                    [class]="mealdata.nutritional_analysis.sodium == 'high' ? 'red' : 'normal'">{{mealdata.nutritional_analysis.sodium}}</span>)
            </p>
            <h4>{{mealdata.total_sodium | number}}</h4>
        </td>
    </tr>
</table>