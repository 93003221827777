<span class="slidetogglecss" (click)="togglenav()"><i [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">{{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}</i></span>
<div class="userlistnavcontent">
 <div class="row">
  <div class="col-md-12 submenubar">
   <h3 class="userlisttitle">Miscellaneous</h3> 
   <a [routerLink]="['/content/faq/category']" routerLinkActive="active">FAQ Category</a>
   <a [routerLink]="['/content/faq/question_answers']" routerLinkActive="active">FAQ Q & A</a>

   <a [routerLink]="['/content/questionnair']" routerLinkActive="active">Questionnaire</a>
   <a [routerLink]="['/content/questionnairmapping']" routerLinkActive="active">Questionnaire Mapping</a>
   <!-- <a [routerLink]="['/content/logging']" routerLinkActive="active">Logging</a> -->

   <a [routerLink]="['/content/logging/mapping']" routerLinkActive="active">Logging Mapping</a>
   <a [routerLink]="['/content/pump']" routerLinkActive="active">Pump</a>
   <a [routerLink]="['/content/symptom/mapping']" routerLinkActive="active">Symptom Mapping</a>
   <a [routerLink]="['/content/triggers']" routerLinkActive="active">Triggers</a>
   <a [routerLink]="['/content/trigger/mapping']" routerLinkActive="active">Triggers Mapping</a>
   <a [routerLink]="['/content/medicine']" routerLinkActive="active">Medicine</a>
   <a [routerLink]="['/content/medication']" routerLinkActive="active">Medication</a>
   <a [routerLink]="['/content/meal']" routerLinkActive="active">Meal</a>
   <a [routerLink]="['/content/conditionlist']" routerLinkActive="active">Conditions</a>
   <a [routerLink]="['/content/healthgoal']" routerLinkActive="active">Health Goals</a>

  </div>

 </div>
</div>
