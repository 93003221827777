<head>
    <meta charset="UTF-8">
</head>
<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="healthcoach/hctasklist/taskID"></app-breadcrumb>
        <div class="main-wrapper mt-30">

            <div *ngIf="taskDetails" class="hc-name mt-10 mb-40" style="font-size: 20px; margin-left: 40px;">
                ID&nbsp;<span style="color:var(--primary-orange);">{{taskDetails.task_display_id}}</span>
            </div>
            <!-- <div class="title-bar"> <img src="assets/img/dls/icons/Tick_solid.svg"
                                    class="img-responsive" alt="Wellthy">
                                ID<span>WTM000{{hctaskdata?.task_library_id}}</span> </div> -->
            <form [formGroup]="executionform" validate>
                <div class="task-wrapper">
                    <div class="text-over">
                        <h3 class="">Task details</h3>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="title-section">
                                <h5>Task type</h5>
                                <h4>{{taskDetails?.task_summary}}</h4>
                            </div>
                            <div class="task-details">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="title-section">
                                            <h5>Priority</h5>
                                            <h4 class="preview-sub text-capitalize">
                                                <span *ngIf="taskDetails?.priority == '2'" class="priority high"></span>
                                                <span *ngIf="taskDetails?.priority == '0'" class="priority low"></span>
                                                <span *ngIf="taskDetails?.priority == '1'"
                                                    class="priority medium"></span>
                                                {{priorityInList(taskDetails?.priority)}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="title-section">
                                            <h5>Scope</h5>
                                            <h4 class="preview-sub text-capitalize">{{taskDetails?.scope}}</h4>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="title-section">
                                            <h5>Expiry</h5>
                                            <h4 class="preview-sub">{{convertDate(taskDetails?.expiry_date)}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="configure-conditions">
                                            <h5>Conditions</h5>
                                            <mat-vertical-stepper fxLayout="column" [linear]="true" #stepper>
                                                <mat-step editable="false">
                                                    <!--*ngFor="let condition of conditions"-->
                                                    <ng-template matStepLabel>Who has clients
                                                        &nbsp;<span
                                                            *ngIf="taskDetails">{{taskDetails.filter_conditions_summary}}</span>
                                                    </ng-template>
                                                </mat-step>
                                            </mat-vertical-stepper>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                  
                                    <!-- <form [formGroup]="thirdFormGroup" autocomplete="off">
                                        <ng-template matStepLabel>Configure template</ng-template>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="language-tab mb-30">
                                                    <div>
                                                        <mat-menu #menu="matMenu" style="margin-left: 0%;">
                                                            <button *ngFor="let data of templateData"
                                                                mat-menu-item>{{data.id}}</button>
                                                        </mat-menu>
                                                        <mat-tab-group [selectedIndex]="0" style="margin-left: 0%;">
                                                            <mat-tab *ngFor="let data of templateData; "
                                                                [label]="data.id" style="margin-left: 0%;">
                                                                <ng-template mat-tab-label>
                                                                    <div>
                                                                        {{changeIdTolang(data.id)}}
                                                                    </div>
                                                                </ng-template>
                                                                <textarea
                                                                    class="tab-text-area"> {{data.description}}</textarea>
                                                            </mat-tab>
                                                        </mat-tab-group>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form> -->
                                    <form [formGroup]="thirdFormGroup" autocomplete="off">
                                        <ng-template matStepLabel>Configure template</ng-template>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="language-tab">
                                                    <div *ngIf="tempformData.controls.length>0 && openEditTemplate"
                                                        formArrayName="template">

                                                        <mat-tab-group [(selectedIndex)]="selectedIndex"
                                                            (selectedIndexChange)="changeindex1($event)">
                                                            <div
                                                                *ngFor="let tab of tempformData.controls; let j = index;">
                                                                <mat-tab [disabled]="!checklang(tab.value.id)"
                                                                    [formGroupName]="j" [label]="tab.value.name">
                                                                    <!-- [label]="tab.value.name" -->
                                                                    <ng-template mat-tab-label>
                                                                        <div *ngIf="tab">
                                                                            {{getLanguageName(tab.value.id)}}
                                                                        </div>
                                                                        <!-- <button mat-icon-button (click)="closeTab(j,tab)"
                                                                        class="close-btn">
                                                                        <mat-icon style="color: white;float: right;">close
                                                                        </mat-icon>
                                                                    </button> -->
                                                                    </ng-template>
                                                                    <!-- Contents for {{tab.value.data}} tab -->
                                                                    <!-- <mat-form-field class="example-full-width"> -->
                                                                    <div class="textareaDiv" *ngIf="is_Next">
                                                                        <textarea cols="40" wrap="hard"
                                                                            class="tab-text-area" (focus)="onFocus()"
                                                                            style="white-space: pre-wrap;"
                                                                            *ngIf="is_Next" [(ngModel)]="textbox"
                                                                            formControlName="description">
                                                                    </textarea>
                                                                        <!-- <button class="textareabtn" *ngIf="is_Next"
                                                                            (click)="toggleEmojiPicker()">😀</button>
                                                                        <emoji-mart class="emoji-mart" set="{{set}}"
                                                                            *ngIf="showEmojiPicker"
                                                                            (emojiSelect)="addEmoji($event)"
                                                                            title="Pick your emoji…"></emoji-mart> -->
                                                                    </div>
                                                                    <textarea cols="40" wrap="hard"
                                                                        class="tab-text-area"
                                                                        style="white-space: pre-wrap;" *ngIf="!is_Next"
                                                                        formControlName="description"
                                                                        [(ngModel)]="textbox" readonly></textarea>
                                                                </mat-tab>

                                                            </div>
                                                            <mat-tab disabled
                                                                *ngIf="alllanguages && alllanguages.length >= 0"
                                                                style="display: none;">
                                                                <ng-template mat-tab-label>
                                                                    <span style="display: none;"
                                                                        [ngClass]="alllanguages && alllanguages.length == 0?'hideclass':'showclass'">
                                                                        <button mat-button [matMenuTriggerFor]="menu"
                                                                            class="add-">
                                                                            <mat-icon aria-hidden="false"
                                                                                aria-label="Add">add
                                                                            </mat-icon>
                                                                            Add Language
                                                                        </button></span>
                                                                </ng-template>
                                                            </mat-tab>
                                                            <mat-menu #menu="matMenu" style="display: none;">
                                                                <button *ngFor="let lang of alllanguages" mat-menu-item
                                                                    style="display: none;"
                                                                    (click)="addTab(lang)">{{lang.name}}</button>
                                                            </mat-menu>
                                                        </mat-tab-group>
                                                        <!-- <div class="richmedia_dropdown">
                                                            <mat-select [(value)]="matRichmedia" id="richMediaSelect" required
                                                            (selectionChange)="changeRichMediaType($event);"
                                                                placeholder="Media" class="richmedia">
                                                                <mat-option *ngFor="let media of mediaType"
                                                                style="font-weight: bold;"
                                                                [disabled]="media.isDisabled === true"
                                                                [Selected]="media.isSelected === true"
                                                                [value]="media.name"> {{ media.name | titlecase }}</mat-option>
                                                            </mat-select>
                                                            </div> -->
                                                        <!-- <div *ngIf="is_Next">
                                                            <span>Select Rich Media </span>
                                                            <select id="richMediaSelect" required
                                                                (change)="changeRichMediaType($event.target.value);">
                                                                <option value="" disabled selected>Select one</option>
                                                                <option *ngFor="let media of mediaType"
                                                                    [disabled]="media.isDisabled === true"
                                                                    [selected]="media.isSelected === true"
                                                                    [value]="media.name">
                                                                    {{ media.name | titlecase }}</option>
                                                            </select><br>
                                                            <div>
                                                                <font style="color: #237EE9;font-size: 12px;">Supports :
                                                                </font>
                                                                <font style="color: #fa0035;font-size: 12px;">Image
                                                                    (.png,.jpg) ~8MB</font>
                                                            </div> -->
                                                            <!-- <input type="checkbox" #richmedia
                                                                (click)="richMediaCheckbox(richmedia.checked)"> Upload
                                                            Media
                                                            <select *ngIf="richmedia.checked === true"
                                                                id="richMediaSelect" required
                                                                (change)="changeRichMediaType($event.target.value);">
                                                                <option value="" disabled selected>Select one</option>
                                                                <option *ngFor="let media of mediaType"
                                                                    [disabled]="media.isDisabled === true"
                                                                    [value]="media.name">
                                                                    {{ media.name | titlecase }}</option>
                                                            </select><br>
                                                            <div class="tooltip_richmedia">
                                                                <font style="color:#5A5C75;">Supports</font>
                                                                <font style="color:#237EE9;"> Rich media</font>
                                                                <span class="tooltiptext">Image (.jpeg,.png,.jpg) ~5 MB
                                                                    Audio (.mp3,.wav,.m4a) ~10 MB
                                                                    Video (.mp4,.mov,.avi) ~20 MB. </span>
                                                            </div> -->
                                                        <!-- </div> -->

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row" style="box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;margin-right: 0; margin-left: 0;"  *ngIf="is_Next">
                                        <div class="col-md-12"
                                            style="background: var(--text-area-bg);border-radius: 8px;overflow: hidden;padding: 0px;">
                                            <mat-tab-group #richmediatabGrp [selectedIndex]="matTabIndex" class="rich-media"
                                            mat-stretch-tabs>
                                            <mat-tab label="Repository">
                                                <div class="col-md-12" style="overflow: hidden;padding: 0px;">
                                                    <!-- <div class="container-fluid"
                                                    style="margin-top: 10px;text-align: center;box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;margin-bottom: 10px;">
                                                    <div class="row" style="margin-top: 10px;">
                                                        <div class="col-md-12">
                                                            <span
                                                                style="text-align: center;font-size: 14px;font-weight: 400;">Choose
                                                                Rich Media From Library</span>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="row mt-10">
                                                        <div class="col-md-12">
                                                            <span
                                                                style="text-align: center;font-size: 14px;font-weight: 200;">(OR)</span>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="row">
                                                        <div class="col-md-12" style="height: 200px;">
                                                            <div class="clearfix"><br /></div>
                                                            <div class="upload-wrapper">
                                                                <form  [formGroup]="localMediaUpload"
                                                                (ngSubmit)="onFileSelect($event)" validate #demoForm="ngForm">
                                                                    <file-upload  formControlName="files"
                                                                    fileslimit="0"
                                                                    [(ngModel)]="uploadedFiles"></file-upload>
                                                                    <div class="row" style="margin-top: -20px;" *ngIf="uploadedFiles != '' ">
                                                                        <div class="col-md-12">
                                                                            <button mat-flat-button
                                                                                color="primary"
                                                                                class="centerbtn"
                                                                                style="margin-top:15px;"
                                                                                (click)="onFileSelect($event)"
                                                                                [disabled]="!disableUploadbtn">Upload
                                                                                File</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                               
                                                            </div>
                                    
                                                        </div>
                                                    </div> -->
                                                    <!-- </div> -->
                                                    <!-- this.localMediaUpload.value.files != null -->
                                                    <div class="container-fluid"
                                                        *ngIf="choose_library_media">
                                                        <div class="row">
                                                            <div class="col-md-12" style="margin-top: 10px;">
                                                                <div class="row">
                                                                    <form>
                                                                        <div class="form-group has-feedback has-search" style="margin-left:25px">
                                                                            <input [(ngModel)]="searchcriteria"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                class="form-control"
                                                                                placeholder="Search here"
                                                                                autocomplete="new-password">
                                                                            <button mat-fab class="searchbtn"
                                                                                style="display: none;"
                                                                                (click)="richMedia_livesearch()">
                                                                                <mat-icon matSuffix>search</mat-icon>
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="col-md-12 example-container richselectmedia"
                                                                infiniteScroll [infiniteScrollDistance]="1"
                                                                [infiniteScrollThrottle]="50" (scrolled)="getNextSet()"
                                                                [scrollWindow]="false">
                                                                <mat-button-toggle-group
                                                                    class="col-md-12 zeropadleft zeropadright"
                                                                    #group="matButtonToggleGroup">
                                                                    <mat-button-toggle
                                                                        *ngFor="let data of dataSource;let i = index"
                                                                        value="{{data.id}}" class="col-md-4 chooserichmediabtn"
                                                                        (click)="mediaselect(group)">
                                                                        <img style="width:100%;height: 100px;transition: .5s ease;backface-visibility: hidden; opacity: 1;"
                                                                            [src]="richMedaiS3url + data.thumbnail_path" />
                                                                            <div class="middle">
                                                                                <div class="text">{{data.title}}</div>
                                                                              </div>
                                                                        <!-- <p >{{data.title}}</p> -->
                                    
                                                                        <div *ngIf="data.id == grpValue" class="centered">
                                                                            <mat-icon
                                                                                [ngStyle]="{'color':'green','font-size': '90px','width':'auto'}">
                                                                                check</mat-icon>
                                                                        </div>
                                                                    </mat-button-toggle>
                                                                </mat-button-toggle-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container-fluid" style="height: 415px;" *ngIf="choose_local_media">
                                                        <div class="row"
                                                            style="margin-right: 15px;margin-left: 15px;margin-top: 20px;margin-bottom: 5px;">
                                                            <div class="image-placeholder" style="width: -webkit-fill-available; height: auto;">
                                                                <mat-spinner class="upload-spinner" *ngIf="loader"  ></mat-spinner>
                                                                <div class="profile-image">
                                                                <div class="profile-dummy-img">
                                                                  <img _ngcontent-nwm-c37="" class="" src="assets/img/hcassignment/upload-icon.svg">
                                                                  <figcaption>Supported formats: .jpeg, .png, .jpg
                                                                    <br>
                                                                    Max size: ~5 MB
                                                                  </figcaption>
                                                                </div>
                                                                <!-- <img *ngIf="base64File" class="image-data img-responsive" style="margin: 0px;"
                                                                  [src]="base64File"> -->
                                                                </div>
                                                              
                                                              <form [formGroup]="demoForm" (change)="onFileSelect($event)" validate
                                                                [class]="isinvalid ? 'invalidcls' : ''">
                                                                <file-upload formControlName="files" simple accept=".jpeg,.png,.jpg"  [(ngModel)]="uploadedFiles" class="file-uplaod-html">
                                                                  <ng-template let-file #placeholder>
                                                        
                                                                    <ng-container *ngIf="base64File">
                                                                      {{ filename }}
                                                                    </ng-container>
                                                                    <ng-container *ngIf="!base64File">
                                                                      Choose a file...
                                                                    </ng-container>
                                                                    
                                                                  </ng-template>
                                                        
                                                                  <ng-template let-control="control" #button>
                                                                    <i class="material-icons">
                                                                      cloud_upload
                                                                    </i>
                                                                  </ng-template>
                                                                </file-upload>
                                                              </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div class="col-md-12" style="padding: 5px 0px 0px 0px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
                                                            <!-- <div class="row" style="height: 50px;"> <h5>Upload Local Media</h5></div> -->
                                                            <!-- One click away to upload media from local -->
                                                            <span *ngIf="choose_library_media">Upload local media<button class="here" (click)="ChooseMediaSelectionType('local')">here</button></span>
                                                            <span *ngIf="choose_local_media">Upload library media<button class="here" (click)="ChooseMediaSelectionType('repo')">here</button></span>
                                                            </div>
                                                    <!-- old local file upload UI -->
                                                    <!-- <div class="row">
                                                        <div class="col-md-12" style="height: 200px;">
                                                            <div class="clearfix"><br /></div>
                                                            <div class="upload-wrapper">
                                                                <form [formGroup]="localMediaUpload"
                                                                    (ngSubmit)="onFileSelect($event)" validate
                                                                    #demoForm="ngForm">
                                                                    <file-upload formControlName="files" fileslimit="0"
                                                                        [(ngModel)]="uploadedFiles"></file-upload>
                                                                    <div class="row" style="margin-top: -20px;"
                                                                        *ngIf="uploadedFiles != '' ">
                                                                        <div class="col-md-12">
                                                                            <button mat-flat-button color="primary"
                                                                                class="centerbtn" style="margin-top:15px;"
                                                                                (click)="onFileSelect($event)"
                                                                                [disabled]="!disableUploadbtn">Upload
                                                                                File</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                    
                                                            </div>
                                    
                                                        </div>
                                                    </div> -->
                                                <!-- old local file upload UI -->
                                                </div>
                                            </mat-tab>
                                            <mat-tab label="Preview">
                                                    <!-- <div class="col-md-6" style="text-align: left;padding-left: 15px;">
                                                        <mat-select [(value)]="matRichmedia" id="richMediaSelect" required
                                                                (selectionChange)="selectNotifyType($event);"
                                                                    placeholder="Previewtype" class="richmedia">
                                                                    <mat-option *ngFor="let notify of notification"
                                                                    style="font-weight: bold;"
                                                                    [disabled]="notify.isDisabled === true"
                                                                    [Selected]="notify.isSelected === true"
                                                                    [value]="notify.name"> {{ notify.name | titlecase }}</mat-option>
                                                                </mat-select>
                                                    </div> -->
                                                    <div class="col-md-12 mt-1" *ngIf="!openEditTemplate" style="text-align: right;margin-top: 5px;">
                                                        <button mat-button  class="btn btn-filled" (click)="editTemplate();">Edit</button>
                                                    </div>
                                                <div class="col-md-12 iphone-holder-height-rich-media ">
                                                    <div class="iphonex-rich-media-task-execute">
                                                        <div id="iphonebox">
                                                            <div class="content-inside">
                                                                <mat-card class="card">
                                    
                                                                    <!-- Style 1 for notification -->
                                                                    <!-- <div class="row" style="background: #b4b4b442;margin-top: -8px;">
                                                                    <div class="text-right col-md-12 offset-md-12"
                                                                        style="padding-right: 21px;font-size: 8px;padding-top: 10px;">
                                                                        1min ago
                                                                    </div>
                                                                </div>
                                                                <div class="row" style="background: #b4b4b442;">
                                                                    <div class="col-md-12" style="margin-top: -25px;"><img
                                                                            src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                            style="width: 40px;margin-top: 10px; margin-left: 0px;margin-bottom: 5px;border-radius: 50%;">
                                                                        <span style="margin-top: 25px;"
                                                                            class="aligned-with-icon"> Wellthy Notification !!</span>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                                <audio controls
                                                                    *ngIf="path && mediatype === 'audio'">
                                                                    <source [src]="richMedaiS3url + this.path"
                                                                        type="{{mimeType}}">
                                                                </audio>
                                                                <video class="video-js vjs-big-play-centered"
                                                                    style="width:100%;height: 250px;" controls
                                                                    preload="auto"
                                                                    data-setup='{"techOrder": ["flash"]}'
                                                                    *ngIf="path && mediatype === 'video'">
                                                                    <source [src]="richMedaiS3url + this.path"
                                                                        type="{{mimeType}}">
                                                                </video>
                                    
                                                                <img *ngIf="path && mediatype === 'image'"
                                                                    class="mat-card-image1"
                                                                    [src]="richMedaiS3url + this.path">
                                                                <img *ngIf="base64File" class="mat-card-image1"
                                                                    [src]="base64File">
                                                                <img *ngIf="previewRichMediaLink && (path === '') "
                                                                    class="mat-card-image1"
                                                                    [src]="richMedaiS3url + previewRichMediaLink">
                                    
                                                                <hr>
                                                                <mat-card-content>
                                                                    <p>
                                                                        {{textbox}}
                                                                    </p>
                                                                </mat-card-content> -->
                                                                    <!-- Style 1 for notification -->
                                                                    <div class="row" style="display: flex; ">
                                                                        <div class="col-md-6">
                                                                            <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                            style="width: 10px;
                                                                            margin-top: 0px;
                                                                            margin-left: 6px;
                                                                            margin-bottom: 2px;">
                                                                            <span style="font-size: 10px;margin-left: 2px;color: grey;">Wellthy &#x2022; Now</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row" style="display: flex;margin-right: -15px; ">
                                                                     <div class="col-md-8">
                                                                        <span style="font-size: 12px;">{{textbox}}</span><br>
                                                                        <span style="font-size: 10px;color: grey;">Sent an attachment</span>
                                                                     </div>
                                                                     <div class="col-md-4" style="margin-left: 20px;">
                                                                        <img *ngIf="path && mediatype === 'image'"
                                                                        [src]="richMedaiS3url + this.path" style="width: 40px;text-align: -webkit-right;height: 35px;margin-left: 20px;">
                                                                    <img *ngIf="base64File" 
                                                                        [src]="base64File" style="width: 40px;text-align: -webkit-right;height: 35px;margin-left: 20px;">
                                                                    <img *ngIf="previewRichMediaLink && (path === '') "
                                                                        [src]="richMedaiS3url + previewRichMediaLink" style="width: 40px;text-align: -webkit-right;height: 35px;margin-left: 20px;">
                                                                     </div>
                                                                    </div>
                                                                </mat-card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-tab>
                                        </mat-tab-group>
                                        </div>
                                    </div>
                                    <!-- Rich media content for fully automated and preview class="col-md-12 iphone-holder-height-rich-media "  -->
                                </div>
                                <div class="row" style="margin-top:-35px;">
                                    <div *ngIf="!is_Next" class="col-md-12 iphone-holder-height-rich-media" style="margin-top:-35px;">
                                        <div class="iphonex-rich-media-task-execute">
                                            <div id="iphonebox" >
                                                <div class="content-inside">
                                                    <mat-card class="card">
            
                                                        <!-- Style 1 for notification -->
                                                        <!-- <div class="row" style="background: #b4b4b442;margin-top: -8px;">
                                                            <div class="text-right col-md-12 offset-md-12"
                                                                style="padding-right: 21px;font-size: 8px;padding-top: 10px;">
                                                                1min ago
                                                            </div>
                                                        </div>
                                                        <div class="row" style="background: #b4b4b442;">
                                                            <div class="col-md-12" style="margin-top: -25px;"><img
                                                                    src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                    style="width: 40px;margin-top: 10px; margin-left: 0px;margin-bottom: 5px;border-radius: 50%;">
                                                                <span style="margin-top: 25px;"
                                                                    class="aligned-with-icon"> Wellthy Notification !!</span>
                                                            </div>
                                                        </div>
                                                        <hr>
                                                        <audio controls
                                                            *ngIf="path && mediatype === 'audio'">
                                                            <source [src]="richMedaiS3url + this.path"
                                                                type="{{mimeType}}">
                                                        </audio>
                                                        <video class="video-js vjs-big-play-centered"
                                                            style="width:100%;height: 250px;" controls
                                                            preload="auto"
                                                            data-setup='{"techOrder": ["flash"]}'
                                                            *ngIf="path && mediatype === 'video'">
                                                            <source [src]="richMedaiS3url + this.path"
                                                                type="{{mimeType}}">
                                                        </video>
            
                                                        <img *ngIf="path && mediatype === 'image'"
                                                            class="mat-card-image1"
                                                            [src]="richMedaiS3url + this.path">
                                                        <img *ngIf="base64File" class="mat-card-image1"
                                                            [src]="base64File">
                                                        <img *ngIf="previewRichMediaLink && (path === '') "
                                                            class="mat-card-image1"
                                                            [src]="richMedaiS3url + previewRichMediaLink">
            
                                                        <hr>
                                                        <mat-card-content>
                                                            <p>
                                                                {{textbox}}
                                                            </p>
                                                        </mat-card-content> -->
                                                        <!-- Style 1 for notification -->
                                                        <div class="row"
                                                            style="background: #b4b4b442;overflow-x: hidden;">
                                                            <div class="col-md-2">
                                                                <!-- image logo -->
                                                                <img src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                    style="width: 40px;margin-top: 5px;margin-left: -5px;margin-bottom: 7px;">
                                                            </div>
                                                            <div class="col-md-6" style="height: auto;">
                                                                <!-- Notification name -->
                                                                <span style="font-size: 12px;"
                                                                    class="aligned-with-icon">
                                                                    Wellthy Notification</span>
                                                                <div class="row" style="height: auto;">
                                                                    <div class="col-md-12"
                                                                        style="margin-top: 15px;">
                                                                        <span style="font-size: 10px;"
                                                                            class="aligned-with-icon">{{textbox}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 text-right"
                                                                style="font-size: 8px;">
                                                                <!-- time stamp -->
                                                                1min ago
                                                                <div class="row text-right">
                                                                    <div class="col-md-12 text-right">
                                                                        <!-- Notification image -->
                                                                        <img *ngIf="path && mediatype === 'image'"
                                                                            class="mat-card-image2"
                                                                            [src]="richMedaiS3url + this.path">
                                                                        <img *ngIf="base64File"
                                                                            class="mat-card-image2"
                                                                            [src]="base64File">
                                                                        <img *ngIf="previewRichMediaLink && (path === '') "
                                                                            class="mat-card-image2"
                                                                            [src]="richMedaiS3url + previewRichMediaLink">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
            
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="all-patients">
                                <div class="title-holder">
                                    <h4  *ngIf="totalPatientCountForTask > 1">All patients({{totalPatientCountForTask}})</h4>
                                    <h4 *ngIf="totalPatientCountForTask === 1"> All patients({{totalPatientCountForTask}})</h4>
                                    <h4 *ngIf="totalPatientCountForTask === 0"> All patients(0)</h4>
                                    <!-- <form class="search-form"> -->
                                    <div class="form-group has-feedback has-search">
                                        <input class="form-control" placeholder="Search patient"
                                            (input)="onSearchChange($event.target.value)">
                                        <button mat-fab class="searchbtn" style="display: none;">
                                            <mat-icon matSuffix>search</mat-icon>
                                        </button>
                                    </div>
                                    <!-- </form> -->
                                </div>
                                
                                <div class="row">
                                    
                                    <div class="col-md-12">
                                        <div class="container-table" *ngIf="patientList?.length" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="5"
                                        (scrolled)="getNextSet()" [scrollWindow]="false">
                                            <mat-selection-list  formControlName="number_list" >
                                                <mat-list-option checkboxPosition="before" (click)="someMethod(patient)"
                                                    *ngFor="let patient of patientList" [value]="patient"  multiple [disabled]="checkprogress(patient.id)" [selected]="checkchecked(patient.id)" >
                                                    <div  class="clearfix">
                                                        <div class="pull-left">
                                                            {{patient.name}}
                                                        </div>
                                                    </div>
                                                </mat-list-option>
                                            </mat-selection-list>
                                        </div>
                                        <!--  (click)="executionform.reset()" -->
                                        <div class="reset-btn mt-30 mr-30 pull-right" *ngIf="patientList?.length">
                                            <button mat-button class="btn "
                                                (click)="reset()" [disabled]=" selectedData1.length==0">
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                    <div style="text-align: center;" *ngIf="!patientList?.length">
                                        No patients matched with the task creation
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                       
                        <!-- Rich meida content for semi automated  -->
                        <!-- <div class="col-md-6 mt-10" *ngIf="is_Next" style="background: var(--text-area-bg);">
                            <mat-tab-group class="rich-media" style="border: 2px solid #e5e0e0;border-radius: 5px;"
                                mat-stretch-tabs>
                                <mat-tab label="Repository">
                                    <div class="col-md-12">
                                        <div class="container-fluid" style="margin-top: 6px;text-align: center;">
                                            <mat-radio-group aria-label="Select an option"
                                                (change)="chooseRadiobutton($event)">
                                                <mat-radio-button value="0" checked>Choose Media From Library
                                                </mat-radio-button>
                                                <span>&nbsp;&nbsp;(or)</span>
                                                <mat-radio-button value="1" style="margin-left: 10px;">Choose Media From
                                                    Local
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <div class="row">
                                                <div *ngIf="path || base64File " class="col-md-12"
                                                    style="text-align:center; margin-top: 10px;">
                                                    <button mat-button class="btn mr-5 btn-filled" (click)="attachRichMedia()">Attach Media</button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="container-fluid" *ngIf="choose_library_media">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <form>
                                                            <div class="form-group has-feedback has-search">
                                                                <input [(ngModel)]="searchcriteria"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    class="form-control" placeholder="Search here"
                                                                    autocomplete="new-password">
                                                                <button mat-fab class="searchbtn" style="display: none;"
                                                                    (click)="richMedia_livesearch()">
                                                                    <mat-icon matSuffix>search</mat-icon>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="col-md-12 example-container selectmedia" infiniteScroll
                                                    [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                                                    (scrolled)="getNextSetOFmedia()" [scrollWindow]="false">
                                                    <mat-button-toggle-group class="col-md-12 zeropadleft zeropadright"
                                                        #group="matButtonToggleGroup">
                                                        <mat-button-toggle *ngFor="let data of dataSource;let i = index"
                                                            value="{{data.id}}" class="col-md-4 choosemediabtn"
                                                            (click)="mediaselect(group)">
                                                            <img style="width:100%;max-height:140px;"
                                                                [src]="s3url + data.thumbnail_path" />
                                                            <p>{{data.title}}</p>
                                                        </mat-button-toggle>
                                                    </mat-button-toggle-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-fluid" *ngIf="choose_local_media">
                                            <div class="row" style="margin-right: 15px;margin-left: 15px;">
                                                <div class="form-group">
                                                    <div class="col-md-12">
                                                        <mat-form-field style="width: 100%;" floatPlaceholder="never">
                                                            <input [hidden]="true" (change)="onFileSelect($event)"
                                                                type="file" accept="all/*" #fileSelect>
                                                            <input readonly matInput [(ngModel)]="filename"
                                                                placeholder="Select file..." />
                                                            <button *ngIf="this.base64File"
                                                                (click)="this.base64File = null; this.filename = null"
                                                                matSuffix matTooltip="Remove selected file"
                                                                [matTooltipPosition]="'above'" type="button"
                                                                color="warn" mat-icon-button>
                                                                <mat-icon>close</mat-icon>
                                                            </button>
                                                            <button matSuffix mat-icon-button
                                                                (click)="fileSelect.click();" matTooltip="Select a file"
                                                                [matTooltipPosition]="'above'">
                                                                <mat-icon>file_upload</mat-icon>
                                                            </button>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 m-t" *ngIf="base64File">
                                                    <table class="table table-bordered table-stripped">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    preview
                                                                </th>
                                                                <th>
                                                                    file type
                                                                </th>
                                                                <th>
                                                                    file size
                                                                </th>
                                                                <th>
                                                                    action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <img style="width: 100px" [src]="base64File">
                                                                </td>
                                                                <td>
                                                                    {{fileType}}
                                                                <td dir='ltr'>

                                                                    {{(filesize/1000)}} KB
                                                                </td>
                                                                <td>
                                                                    <button class="btn btn-danger"
                                                                        (click)="this.base64File = null; this.filename = null"><i
                                                                            class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Preview">
                                    <div class="wrapper">
                                        <h1>{{richMediaType | uppercase}} Push Notification </h1>
                                    </div>
                                    <div class="col-md-12 iphone-holder-height-rich-media ">
                                        <div class="iphonex-rich-media">
                                            <div id="iphonebox">
                                                <div class="content-inside">
                                                    <mat-card class="card" *ngIf="textbox || path">
                                                        <div class="row">
                                                            <div class="col-md-10"><img
                                                                    src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                    style="width: 40px;margin-top: 20px;margin-left: 10px;">
                                                                <span style="margin-top: 30px;"
                                                                    class="aligned-with-icon">
                                                                    Wellthy Notification
                                                                    !!</span>
                                                            </div>
                                                            <div class="text-right col-md-2 offset-md-2"
                                                                style="padding-right: 15px;">
                                                                <mat-icon>close</mat-icon>
                                                            </div>
                                                        </div>
                                                        <hr>
                                                        <audio controls
                                                            *ngIf="path && mediatype === 'audio'">
                                                            <source [src]="s3url + this.path"
                                                                type="{{mimeType}}">
                                                        </audio>
                                                        <video
                                                            class="video-js vjs-big-play-centered"
                                                            style="width:100%;height: 250px;"
                                                            controls preload="auto"
                                                            data-setup='{"techOrder": ["flash"]}'
                                                            *ngIf="path && mediatype === 'video'">
                                                            <source [src]="s3url + this.path"
                                                                type="{{mimeType}}">
                                                        </video>
                                                        <img *ngIf="path && mediatype === 'image'"
                                                            class="mat-card-image1"
                                                            [src]="s3url + this.path">
                                                        <img *ngIf="base64File"
                                                            class="mat-card-image1"
                                                            [src]="base64File">
                                                        <hr *ngIf="textbox">
                                                        <mat-card-content *ngIf="textbox" >
                                                            <p style="padding: 10px;">
                                                                {{textbox}}
                                                            </p>
                                                        </mat-card-content>
                                                        <hr>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div> -->
                        <!-- Rich media content for fully automated and preview  -->
                        <!-- <div  class="col-md-6 mt-10" *ngIf="!is_Next" >
                            <div class="wrapper">
                                <h1>Push Notification </h1>
                            </div>
                            <div class="col-md-12 iphone-holder-height-rich-media ">
                                <div class="iphonex-rich-media">
                                    <div id="iphonebox">
                                        <div class="content-inside">
                                            <mat-card class="card">
                                                <div class="row">
                                                    <div class="col-md-10"><img
                                                            src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                            style="width: 40px;margin-top: 20px;margin-left: 10px;">
                                                        <span style="margin-top: 30px;"
                                                            class="aligned-with-icon">
                                                            Wellthy Notification
                                                            !!</span>
                                                    </div>
                                                    <div class="text-right col-md-2 offset-md-2"
                                                        style="padding-right: 15px;">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                </div>
                                                <hr>
                                                <audio controls
                                                    *ngIf="path && mediatype === 'audio'">
                                                    <source [src]="s3url + this.path"
                                                        type="{{mimeType}}">
                                                </audio>
                                                <video
                                                    class="video-js vjs-big-play-centered"
                                                    style="width:100%;height: 250px;"
                                                    controls preload="auto"
                                                    data-setup='{"techOrder": ["flash"]}'
                                                    *ngIf="path && mediatype === 'video'">
                                                    <source [src]="s3url + this.path"
                                                        type="{{mimeType}}">
                                                </video>
                                                <img *ngIf="path && mediatype === 'image'"
                                                    class="mat-card-image1"
                                                    [src]="s3url + this.path">
                                                <img *ngIf="base64File"
                                                    class="mat-card-image1"
                                                    [src]="base64File">
                                                <hr>
                                                <mat-card-content>
                                                    <p style="padding: 10px;">
                                                        {{textbox}}
                                                    </p>
                                                </mat-card-content>
                                                <hr>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="button-holder mt-40" id="bottom">
                    <!-- <a mat-button class="btn btn-medium btn-filled mr-10" [disabled]="(!executionform.valid) || ( taskDetails.expiry_date < getToday)"
                        (click)="dataObject()" *ngIf="!is_Next">
                        Send Broadcast
                    </a> -->
                    <a mat-button class="btn btn-medium btn-filled mr-10" [disabled]="checknextbutton()"
                        (click)="!checknextbutton() && dataObject()"
                        *ngIf="!is_Next && (progresspatendId.length!=patientList.length || patientList.length==0)">
                        Send Broadcast
                    </a>
                    <a mat-button class="btn btn-medium btn-filled mr-10" (click)="goToCompletepage()"
                        *ngIf="(!is_Next || is_Next || taskComplete) && (patientList.length!=0 && progresspatendId.length==patientList.length)">
                        Next Page
                    </a>
                    <a mat-button id="bottom" class="btn btn-medium btn-filled mr-10"
                        *ngIf="is_Next && (progresspatendId.length!=patientList.length || patientList.length==0)"
                        [disabled]="checknextbutton()" (click)="!checknextbutton() && NextBtn()">
                        Next
                    </a>
                    <a mat-button id="bottom" class="btn btn-medium btn-filled mr-10"
                        *ngIf="taskComplete  && (progresspatendId.length!=patientList.length || patientList.length==0)"
                        (click)="taskCompleteButton()">
                        Task Completed
                    </a>
                </div>
            </form>

        </div>
    </div>
</mat-drawer-container>