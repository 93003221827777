import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pump',
  templateUrl: './pump.component.html',
  styleUrls: ['./pump.component.scss']
})


export class PumpComponent implements OnInit {
  public dataVal: any;
  pumpDetails:any;
  public image_path: any;
  public allpumpdata: any;
  private myCathash: any = {};
  s3url: any = environment.s3url;
  constructor(private userdetailsService: UserdetailsService, 
    public dialogRef: MatDialogRef<PumpComponent>,
    private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any, public systemService: SystemService,private contentService: ContentserviceService) { }

  ngOnInit(): void {
    
    this.dataVal = this.data;


    this.getPumpList();
    this.getPumpData();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getPumpData() {
    this.contentService.getGraphQlData('{getAllPump(status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.onPumpDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onPumpDataSuccess(data) {
    data = (data as any).data;
    this.allpumpdata = data.getAllPump;
    this.allpumpdata.forEach(element => {
      this.myCathash[element.id] = element.title;
    });
  }

  getPumpName(category) {
    category = this.myCathash[category];
    return category;

  }

  getPumpList() {
    this.userdetailsService.getOnePump(this.dataVal)
      .subscribe(
        (res: any) => this.getTherapyData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyData(res) {
    
    this.pumpDetails = res.data;
    this.image_path = this.pumpDetails.media_joined_data;
  }

  // getPumpList() {
  //   this.userdetailsService.getOnePump(this.dataVal)
  //     .subscribe(
  //       (res: any) => 
  //       this.getPumpData(res)),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)

  // }
}
