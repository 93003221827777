import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthcoachGuardGuard } from '../shared/guards';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'broadcast', loadChildren: () => import('./broadcast/broadcast.module').then(m => m.BroadcastModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'hcalerts', loadChildren: () => import('./hcalerts/hcalerts.module').then(m => m.HcalertsModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'hcprofile', loadChildren: () => import('./hcprofile/hcprofile.module').then(m => m.HcprofileModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'tags', loadChildren: () => import('./hctags/hctags.module').then(m => m.HctagsModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'hcsettings', loadChildren: () => import('./hcsettings/hcsettings.module').then(m => m.HcsettingsModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'prescriptionalupload', loadChildren: () => import('./components/uploadprescriptionaldialog/uploadprescriptionaldialog.module').then(m => m.UploadprescriptionaldialogModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'hctasks', loadChildren: () => import('./hctasklist/hctasklist.module').then(m => m.HctasklistModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'policies', loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule), canActivate: [HealthcoachGuardGuard] },
  { path: 'dietplanpdfreport', loadChildren: () => import('./components/tabpartials/userdetail/dietplanpdfreport/dietplanpdfreport.module').then(m => m.DietPlanpdfreportModule), canActivate: [HealthcoachGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class HealthcoachRoutingModule { }
