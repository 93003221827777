<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb  *ngIf="!newitem"  route="content/healthgoalhome/healthgoaledit"></app-breadcrumb>
        <app-breadcrumb  *ngIf="newitem"  route="content/healthgoalhome/healthgoalCreate"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Health Goal
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>
                           
                            <div class="clearfix"><br /></div>                          
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button mat-flat-button color="primary" class="centerbtn btn-left"
                            [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Health Goal</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>