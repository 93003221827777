<div class="datechatui" *ngIf="showdate">
   <div class="dashliner"></div>
   <div class="timestampchat" *ngIf="(chat.body !== '')">{{date}}</div>
</div>

<div class="datechatui" *ngIf="chat?.mimeType == 'patient_transfer_event'">
   <div class="dashliner"></div>
   <div class="transferHC">{{chat?.body}}</div>
</div>

<div *ngIf="(chat?.mimeType != 'patient_transfer_event') && (chat.attachment?.value!='is_CallDuration')">
   <div [ngClass]="[this.chatrectype == 'other' ? (chat.is_deleted==true?'recieverbox1':'recieverbox') : (deeplink?('senderbox1'):'senderbox'), whatsapp ? 'abc':'']"
      id="{{chat.id}}" [style.background-color]="deeplink?color:''"
      *ngIf="!careychat &&  chat?.attachment?.value !== 'Caregiver' && chat?.attachment?.value !== 'deleteCursor'">
      <span *ngIf="chat.is_deleted==true" class="tooltiptext">This message is deleted by the patient.</span>
      <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" (click)="getSaveAsDocumentType()"
         aria-label="Options" class="optioncss"
         *ngIf="!payment_link && (chat?.attachment?.value != 'Resync Health Kit' && chat?.attachment?.value !='Resync Google Kit') && !chat?.is_offline_msg">
         <mat-icon>more_vert</mat-icon>
      </button>

      <button mat-icon-button class="optioncss" tooltip="No internet, message not sent" *ngIf="chat.is_offline_msg">
         <mat-icon>wifi_off</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
         <div (click)="$event.stopPropagation()" *ngIf="chat?.attachment?.link">
            <mat-accordion multi>
               <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                     <mat-panel-description>
                        <button mat-menu-item style="padding: 3px 15px;" [disabled]="chat.is_deleted==true">
                           <mat-icon>save</mat-icon>
                           <span>Save As</span>
                        </button>
                     </mat-panel-description>
                  </mat-expansion-panel-header>
                  <button *ngFor="let doc of saveAsDocType" mat-menu-item [disabled]="chat.is_deleted==true"
                     (click)="startSaveAs(chat,doc.title)">{{doc.title}}</button>
               </mat-expansion-panel>
            </mat-accordion>
         </div>
         <button mat-menu-item (click)="startReply(chat.id,chattext)" [disabled]="chat.is_deleted==true">
            <img src="/assets/img/healthcoach/vectors/reply.svg" alt="" class="img-responsive img-holder"
               [disabled]="chat.is_deleted==true">
            <span>Reply</span>
         </button>
         <button mat-menu-item (click)="deleteMessage(chat)" *ngIf="chatrectype != 'other'"
            [disabled]="chat.is_deleted==true">
            <img src="/assets/img/healthcoach/vectors/bin.svg" alt="" class="img-responsive img-holder"
               [disabled]="chat.is_deleted==true">
            <span>Delete</span>
         </button>
      </mat-menu>

      <div [class]="(chat?.replyTo?.sender == 'U'+usId) ? 'replytocss' : 'replytocssot'" *ngIf="chat?.replyTo != null"
         id="{{chat?.replyTo?.id}}">
         <span [innerHtml]="(chat?.replyTo?.sender == 'U'+usId) ? usName : 'You'"></span>
         <div [hidden]="chat?.replyTo == null" style="margin-top: 5px;display: inline-block;">
            <ng-container *ngIf="chat?.replyTo?.rich_text === null ">
               <div *ngIf="chat?.replyTo?.attachment == null && chat?.replyTo?.body != '**notxt**'"
                  (click)="scrollToOrginalMsg(chat?.replyTo?.id)">
                  <p>{{chat?.replyTo?.body}}</p>
               </div>
               <div
                  *ngIf="chat?.replyTo?.attachment?.type == 'image/jpeg' || chat?.replyTo?.attachment?.type == 'image/png'"
                  (click)="scrollToOrginalMsg(chat?.replyTo?.id)">
                  <img [src]="chat.replyTo.attachment.url" class="imageboxcss" alt="" />
               </div>
               <div *ngIf="chat?.replyTo?.attachment?.type == 'video'" (click)="scrollToOrginalMsg(chat?.replyTo?.id)">
                  <video [src]="chat.replyTo.attachment.url" style="width:250px;height: 150px;max-height: 140px;" controls
                     controlsList="nodownload noremoteplayback" disablepictureinpicture></video>
               </div>
               <div *ngIf="chat?.replyTo?.attachment?.type == 'audio'" (click)="scrollToOrginalMsg(chat?.replyTo?.id)">
                  <audio [src]="chat.replyTo.attachment.url" style="width:250px;" controls
                     controlsList="nodownload noremoteplayback" disablepictureinpicture></audio>
               </div>
               <div *ngIf="chat?.replyTo?.attachment?.type == 'application/pdf'"
                  (click)="scrollToOrginalMsg(chat?.replyTo?.id)">
                  <img [src]="documentPlaceHolder" class="pdfboxcss" alt="" />
               </div>
            </ng-container>
            <ng-container *ngIf="chat?.replyTo?.rich_text !== null ">
               <div [innerHTML]="previewText" (click)="scrollToOrginalMsg(chat?.replyTo?.id)">
               </div>
            </ng-container>
            
         </div>
      </div>

      <div *ngIf="(!showGallery) && (chattext != '**notxt**') && !payment_link && chat?.is_offline_msg !== true; else offline_msgs"
      [ngClass]="chat.rich_text === null ? 'chattext' : 'Richchattext'" [innerHtml]="chattext">
         <a (click)="chatlength = chattext.length"
            style="position: relative; width: 75px;cursor: pointer;display:none;">Read more</a>
      </div>

      <ng-template #offline_msgs>
         <p *ngIf="chat.is_offline_msg" class="chattext" [innerHtml]="chattext">
      </ng-template>

      <ng-container *ngIf="chat.rich_text === null">
         <div [hidden]="isattachment == false">
            <div *ngIf="imgurl != null && imgurl != '' && videourl == null">
               <img [src]="imgurl" class="imageboxcss" (click)="openDialog(imgurl,'image')" alt="" />
            </div>
            <div *ngIf="video != null">
               <video [src]="videosource" style="width:250px;height: 150px;max-height: 140px;" controls
                  controlsList="nodownload noremoteplayback" disablepictureinpicture></video>
            </div>
            <div *ngIf="audio != null">
               <audio [src]="audiosource" style="width:250px;" controls controlsList="nodownload noremoteplayback"
                  disablepictureinpicture></audio>
            </div>

            <div *ngIf="isDocument">
               <a [href]="docurl" target="_blank"> 
                  <img [src]="documentPlaceHolder" class="pdfboxcss"  alt="" /> 
               </a>
            </div>

            <div *ngIf="whatsapp" class="deeplink whatsap-deeplink">

               <div class="media">

                  <div class="media-left">
                     <img class="media-object" src="assets/img/dls/icons/hcicons/Whatsapp.svg" alt="...">
                  </div>
                  <div class="media-body">
                     <div class="clearfix">
                        <div class="pull-left">
                           <h4 class="media-heading">Whatsapp chat</h4>
                           <p>tap to open</p>
                        </div>
                        <div class="pull-right">
                           <span class="material-icons">
                              chevron_right
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div *ngIf="deeplink && chat?.attachment?.value != 'Resync Health Kit' && chat?.attachment?.value !='Resync Google Kit'"
               class="deeplink1">
               <div *ngIf="chat?.attachment?.value=='Meal'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px"
                        src="../../../../assets/img/Meal.svg" alt="Meal Icon" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat?.attachment?.value=='Water'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px"
                        src="../../../../assets/img/Water.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat?.attachment?.value=='Weight'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px"
                        src="../../../../assets/img/Weight.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat?.attachment?.value=='Profile Progress'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Shape 3.png" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat?.attachment?.value=='Log'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/log.png" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat?.attachment?.value=='Lab report'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/lab.png" />
                  </div>
                  <span style="display: inline-block;  margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat?.attachment?.value=='Activity'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Activity.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>

               <div *ngIf="chat?.attachment?.value=='Blood sugar'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Blood Sugar.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>

               <div *ngIf="chat?.attachment?.value=='Symptom'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Symptoms.png" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>

               <div *ngIf="chat?.attachment?.value=='Blood pressure'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Blood Presure.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>

               <div *ngIf="chat?.attachment?.value=='Peak Flow'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Peak Flow.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>

               <div
                  *ngIf="chat?.attachment?.additionalData == 'magazine' || (getadditionalData(chat?.attachment?.additionalData)) == 'magazine'">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/magzinee.svg" />
                  </div>
                  <span
                     style="display: inline-block;margin: 5px;">{{getadditionalData(chat?.attachment?.additionalData)}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div
                  *ngIf="chat?.attachment?.additionalData == 'lesson' || (getadditionalData(chat?.attachment?.additionalData)) == 'lesson'">
                  <div class="sen1">
                     <img style="border-radius: 10px;height: 22px; width: 21px;margin: 2px;"
                        src="../../../../assets/img/Quiz.png" />
                  </div>
                  <span
                     style="display: inline-block;margin: 5px;">{{getadditionalData(chat?.attachment?.additionalData)}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
               <div *ngIf="chat.attachment.additionalData != 'lesson' && 
                  chat.attachment.additionalData != 'magazine' && 
                  chat.attachment.value !='Peak Flow' && 
                  chat.attachment.value !='Water' && 
                  chat.attachment.value !='Blood pressure' && 
                  chat.attachment.value !='Symptom' &&    
                  chat.attachment.value !='Blood sugar'&&  
                  chat.attachment.value !='Weight' && 
                  chat.attachment.value !='Meal' && 
                  chat.attachment.value !='Profile Progress' &&  
                  chat.attachment.value !='Log' && 
                  chat.attachment.value !='Profile Progress' &&    
                  chat.attachment.value !='Lab report' &&   
                  chat.attachment.value !='Activity' && 
                  (getadditionalData(chat?.attachment?.additionalData) )!= 'lesson' &&
                  (getadditionalData(chat?.attachment?.additionalData)) != 'magazine' 
                  ">
                  <div class="sen">
                     <img style="background: white;border-radius: 10px;height: 22px; width: 21px;margin: 2px"
                        src="../../../../assets/img/notification_coloured_icons/default_icon.svg" />
                  </div>
                  <span style="display: inline-block;margin: 5px;">{{chat?.attachment?.value}}</span>
                  <div style="float:right;margin: 5px;">
                     <img src="../../../../assets/img/Line.png" alt="Photo of a Shiba Inu" />
                  </div>
               </div>
            </div>

            <div *ngIf="payment_link" class="paymentlink">
               <div class="firstpaymentsection">
                  <p style="font-weight: 500;">{{getjson(chat?.attachment?.value)?.title}}</p>
                  <span class="subscriptionbottom">{{getjson(chat?.attachment?.value)?.payment_type}}</span>
               </div>
               <div id="{{'paycard'+ chat?.id}}" class="secondpaymentsection"
                  [class]="(chat?.body?.includes('failed')) ? 'secondpaymentsection paymentred' : (chat?.body?.includes('successful')) ? 'secondpaymentsection paymentgreen' : 'secondpaymentsection paymentgray'">
                  {{getjson(chat?.attachment?.value)?.price}} <mat-icon id="{{'payicon'+ chat?.id}}" class="paymenticon">
                     {{(chat?.body?.includes('failed')) ? 'priority_high' : (chat?.body?.includes('successful')) ?
                     'check' :
                     'access_time'}}
                  </mat-icon>
               </div>
            </div>
         </div>
         <div [hidden]="isattachment == true" style="height:40px;width:100px;clear:both;float:left;">Loading...</div>
      </ng-container>
   </div>

   <div class="careycss" id="{{chat.id}}" *ngIf="careychat" (click)="navigateTobotChat(chat?.attachment?.value)">
      <img src="assets/img/healthcoach/careychat/carey.png" class="careyico" />
      <div class="chattopsec">
         <div class="col-md-4">
            <img [src]="getImg(chat?.attachment?.value)" />
         </div>
         <div class="col-md-8 logrightsec">
            <h6 [innerHTML]="logtype(chat?.attachment?.value)"></h6>
            <h4 [innerHTML]="logvalue(chat?.attachment?.value)"></h4>
         </div>
      </div>
      <div [class]="csstype(chat?.attachment?.value)+' chatbottomsec'">
         View Chat <span class="material-icons">
            keyboard_arrow_right
         </span>
      </div>
   </div>

   <p *ngIf="(chat.body !== '') || (last == 'yes')" class="chatdate"> 
      <span *ngIf="(chatrectype != 'other') && (last == 'yes') && (chat.body !== '') " style="font-weight: bold;">
         {{recStatus}}
      </span> 
      <span *ngIf="lasttime && (chat.body !== '')" class="timecss">
         {{time}}
      </span>
   </p>
</div>

<div class="clearfix"></div>