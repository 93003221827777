<div class="disclaimer">
  <h2 mat-dialog-title class="text-center">Service Level Agreement</h2>
  <div class="mat-typography content">

    <p>This Agreement is made at Mumbai on
      <span>{{today | date:'dd'}}</span>th day of
      <span>{{today | date:'LLLL'}}</span> 2020, hereinafter referred to as the “Effective Date” between:
    </p>
    <p>{{doctorData.name}}, resident of {{doctorData.address}} (hereinafter referred to as the “First Party”, which expression
      shall unless repugnant to the context, include its Agents, Assigns, Executors and Administrators)</p>
    <p class="text-center">AND</p>
    <p>Wellthy Therapeutics Pvt Ltd, a company incorporated under the Companies Act, 1956, with its registered office situated
      at 20 Nagin Mahal, 82 Veer Nariman Road, Churchgate, Mumbai – 400020, India (hereinafter referred to as “Second Party”,
      which expression shall unless repugnant to the context, include its Agents, Assigns, Executors and Administrators)
    </p>
    <p>
      WHEREAS the Second Party is a Digital Therapeutics company which provides Lifestyle Modification and Patient Adherence Programs
      through a proprietary digital platform, to patients prescribed/referred by the First Party

    </p>
    <p>
      AND WHEREAS the First Party is a Licensed Health Care Professional / Clinic that is desirous of prescribing / referring its
      patients to the Second Party

    </p>
    <p> AND WHEREAS all parties have agreed to set out the Terms and Conditions and their respective obligations in writing and
      thus this Agreement.
    </p>
    <h4>NOW THIS AGREEMENT WITNESSETH AS UNDER
    </h4>
    <h4>1. TERM </h4>


    <p>1.1 That this Agreement will come into force with effect from the effective date and will be valid until terminated in
      accordance with Clause 6 of this Agreement. </p>
    <h4>2. SCOPE OF WORK</h4>
    <p>2.1 That the scope of work for each party is defined under their respective obligations clause.</p>
    <p>2.2 That each party to this Agreement will comply with the obligations under this Agreement in order to implement this
      Agreement in its true spirit.</p>
    <p>2.3 That in case of increase or variation of any scope of work/obligation and responsibility of any party hereto the
      same will come into effect only after such obligation has been agreed between all parties mutually and such alteration
      had been made in writing.
    </p>
    <h4>3. OBLIGATIONS OF THE FIRST PARTY</h4>
    <p>3.1 That the first party shall understand the program/service sufficiently, and undergo training if required, to prescribe/recommend
      the same to eligible patients</p>
    <p>3.2 That the First Party will prescribe/recommend to the eligible patients the Lifestyle Modification Program of the
      Second Party after complete assessment of the patient regarding suitability/eligibility of the patient for the Lifestyle
      Modification program of the Second Party
    </p>
    <p>3.3 That the First Party will adhere to Exclusion and Inclusion Criteria as explained by the representatives of the partners of the second party which it shall adhere
      to while referring /prescribing the patient to the Second Party. The same may change from time to time and will be
      communicated in writing to the First party
    </p>
    <p>3.4 That the First Party is free to enter into any agreement similar in substance or spirit to the present agreement
      with any other company/organization, subject to the confidentiality of data, work product, intellectual property and
      program design and implementation as provided in Clause 8 of the present agreement
    </p>
    <p>3.5 That the approach of the First party towards the patient should be that of intent and duty to care and treat the
      patient
    </p>
    <p>3.6 That the First Party agrees to prescribe/recommend the aforesaid lifestyle modification program(s) in accordance
      with the roles, responsibilities and obligations mentioned.</p>

    <h4>4. OBLIGATIONS OF THE SECOND PARTY
    </h4>
    <p>4.1 That the Second Party will provide Lifestyle and patient adherence Modification programs to patients prescribed/referred
      by the First Party.</p>
    <p>4.2 That the Second Party will provide support and services through their team and channel to the First Party.
    </p>
    <p>4.3 That the Second Party will share the details of Lifestyle Modification program through a proprietary mobile app to
      the First Party.</p>
    <p>4.4 That the Second Party shall ensure that the patient(s) prescribed / referred by the First Party shall be given information
      regarding the said Lifestyle Modification Program.</p>
    <p>4.5 That during the term of this agreement, the Second Party shall not refer Patient(s) prescribed/recommended by the
      First Party to any other Doctor or Physician without the express consent of the first party.
    </p>
    <p>4.6 That the Second Party shall reasonably follow all relevant global and local medical guidelines on care of the patient
      and inform the first party if there is any need for follow ups during the time the patient is on the program. Any changes
      in the service shall not conflict with relevant local or global medical guidelines of care, without informing the first
      party.
    </p>

    <p>4.7 That the Second Party shall not change dosage or type of medications without the written consent of the First Party.</p>
    <p>4.8 That the Second party shall provide the services related to the program to the patient for the duration of the program
      that the patient is prescribed/recommended.</p>
    <p>4.9 That the Second Party shall ensure that every patient agrees to the terms and conditions of its program/service
      before accepting the patient onto the program/service.
    </p>

    <p>4.10 That in the case of a patient not giving consent or not accepting or not signing the Terms and conditions of the
      Second Party, then the Second Party, may at its sole discretion deny the patient from participating in the program.
    </p>
    <h4>5. TERMINATION</h4>
    <p>5.1 That either Party may terminate this Agreement, without assigning any reason whatsoever, by giving thirty (30) days’
      notice in writing to the other Party. </p>
    <h4>6. WARRANTY</h4>
    <p>6.1 That the Authorised Signatories mentioned below are authorised to sign, execute and deliver this Agreement for and
      on behalf of their respective Parties and the covenants, representations and warranties contained in this Agreement
      and the same shall be binding on the Parties, its Associates and other employees working with the Parties both severally
      and jointly.
    </p>
    <h4>7. CONFIDENTIALITY AND OWNERSHIP OF DATA
    </h4>
    <p>7.1 That the First and Second Parties understand that all information, data, communication and any information in relation
      to the aforementioned activities as envisaged in the agreement, including but not limited to the affairs of business
      or the methods of carrying out the business/ platform / strategy / pricing / terms / documents, during the period of
      the said agreement. For the purpose of carrying out the services under this Agreement is the proprietary right of the
      Second party and shall be considered as Confidential in all forms and usage of the word –Confidential</p>
    <p>7.2 That the First Party agrees not to disclose and take all reasonable measures to protect any information in relation
      to the aforementioned activities as envisaged in the agreement, including but not limited to the affairs of business
      or the methods of carrying out the business/ platform / strategy / pricing / terms / documents / products / algorithms/
      designs / workflows / content that it might come in possession of, during the period of the said agreement
    </p>
    <p>7.3 That the First Party agrees to prevent all information in relation to the aforementioned activities from falling
      into public domain or possession of such persons other than the agents of the Company.</p>

    <p>7.4 That the First Party agrees that if required by the Second Party, to sign a separate Non-Disclosure Agreement with
      regards to the aforementioned information and data, the execution of which the First Party will not unduly withhold.
      Any Non-Disclosure Agreement signed separately by First and Second Parties will be read in conjunction with this Agreement.
    </p>
    <p>7.5 That the First Party will ensure that it keeps any and all information shared with the first party in the course
      of this Agreement and thereafter, and also during the scope of work being administered by the first party as confidential.
      In case the First Party divulges any such information, the same will be treated as a ground for termination of the
      present Agreement and the First Party will be held liable for any loss incurred by the Second Party due to the said
      actions of the First Party.</p>

    <p>7.6 That the First Party will have no objection to the Second party from being the custodian of, and using of the patient
      information and data shared by the first party and generated on the platform by the patient. The patient owns the data
      and the second party will be obligated to delete the patients’ data in part of in full once they receive a written
      request from the patient to do it which is verified by the second party to be genuine.</p>
    <p>7.7 That the Second Party has exclusive rights over the Intellectual property, data, and work product over the said program(s)
    </p>
    <p>7.8 That the “sensitive personal information (SPI) and personal information (PI)” of the patient is owned by the patient
      himself / herself, and is held in trust by Second Party on behalf of the patient. The physical or electronic records,
      which are generated by Second Party, are held in trust by Second Party on behalf of the patient. All analytics/ derivations/insights
      derived from this data by Second Party, belong to Second Party.</p>
    <h4>8. ANTI BRIBERY CLAUSE
    </h4>
    <p>8.1 That neither this Agreement, nor any payment hereunder, is in exchange for any explicit or implicit agreement or
      understanding between the Parties for recommendation or otherwise arrangement for, or provision of any business for
      Second Party's products. </p>
    <p>8.2 That the Second Party shall not offer or give a sponsorship, gift, meal, entertainment, or anything else of value
      in exchange for an agreement or understanding that Second Party’s products or services will be used, purchased, ordered,
      recommended, or prescribed, or that Second Party will receive any favourable treatment.</p>
    <h4>9. MISCELLANEOUS</h4>
    <p>9.1 That both the parties to this Agreement acknowledge and confirm that they are not aware of any inappropriate inducement,
      bribery, and payment of anything valuable to government official, political party for illegal retention or obtaining
      business in contravention of any law in India.</p>
    <p>9.2 That both the parties to this Agreement acknowledge and confirm that the present agreement includes any schedules
      hereto and has the effect of superseding any or all oral negotiations, discussions, communications-electronic or otherwise
      and it constitutes the sole agreement between the parties with respect to the subject matter thereof.
    </p>
    <h4>10. LIMITATION OF LIABILITY
    </h4>
    <p>10.1 That the first party agrees to indemnify and shall at all times hold the second party, its directors, officers,
      employees, (each a “Client Indemnified Person”), harmless and indemnified at all times hereafter, from and against
      any adverse events, effects or side effects arising out of the pharmacological action of any drugs/medication that
      the patient is prescribed and/or is consuming.</p>
    <p>10.2 That it is hereby assumed that the patient selected by the first party for the program has, prior to selection,
      been accurately diagnosed by the first party relevant to the condition and all associated co-morbidities and complications
      thereof. Also, the first party has full patient diagnostic, medical and medication history, and deems the patient fit
      for the program, and deems the program safe for use by the patient. Second Party shall in no way be liable for any
      adverse event resulting from treatment prescribed by the First Party.
    </p>
    <h4>11. FORCE MAJEURE</h4>
    <p>11.1 No Liability. Neither party will be liable for performance delays or for non-performance due to causes beyond its
      reasonable control.</p>
    <p>11.2 Best Efforts to Cure. In the event of a threatened default or default as a result of any cause beyond its reasonable
      control, the defaulting party shall nonetheless exercise its best efforts to avoid and cure such default.</p>

    <p>11.3 Right to Terminate. In the event such an event prevents performance thereunder for a period in excess of ninety
      (90) days, then the non-defaulting party may elect to terminate this Agreement and/or cancel or suspend any Purchase
      Orders thereunder by a written notice to the defaulting party.
    </p>
    <h4>12. DISPUTE RESOLUTION</h4>
    <p>12.1 That in case of any dispute or difference whatsoever arising between the parties out of or relating to the construction,
      meaning, scope, operation or effect of this Agreement or the validity or the breach thereof will be settled through
      Arbitration presided over by a sole arbitrator chosen mutually by the Parties. The seat of Arbitration will be Mumbai
      and the provisions of the [Indian] Arbitration and Conciliation Act, 1996 and any amendments thereof will apply to
      the said arbitration proceedings.
    </p>
    <p>12.2 That the Courts Mumbai will have jurisdiction with regard to any issues pertaining to the arbitration proceedings
      mentioned above.</p>

    <div class="inwitnes">
      <p>IN WITNESS WHEREOF, the parties hereto, have set their respective hands on the day and year first hereinabove written.
      </p>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>
              SIGNED AND DELIVERED(FIRST PARTY)
            </th>
            <th>
              SIGNED AND DELIVERED(SECOND PARTY)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{doctorData.name}}</td>
            <td>Wellthy Therapeutics Pvt. Ltd.
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>


<div align="center" class="accept-button text-center">
  <p>
    By accepting this that you have read and agree to the terms presented in the Service Level Agreement
  </p>
  <button mat-raised-button class="accept-btn" color="accent" (click)="serviceAgreement()">I Agree</button>
</div>