<mat-dialog-content class="mat-typography pump-add">
    <h3>Adverse drug reaction form</h3>
    <!-- <p>Pump changes</p> -->
    <form [formGroup]="addAdverseform" validate>
        <div class="row">
            <div class="col-md-12">
                <h4>Primary source</h4>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Select Qualification</mat-label>
                            <mat-select formControlName="qualification">
                                <mat-option value="Physician"> Physician</mat-option>
                                <mat-option value="Pharmacist"> Pharmacist</mat-option>
                                <mat-option value="Other"> Other Healthcare Professionals</mat-option>
                                <mat-option value="Patient"> Patient/Consumer</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Select Country</mat-label>
                            <mat-select formControlName="country">
                                <mat-option *ngFor="let list of countyList" [value]="list.name">{{list.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="col-md-12" formArrayName="medicinal_product" *ngFor="let pl of formData.controls; let i = index;">
                <h4>Medicinal product</h4>
                <div class="bg-color" [formGroupName]="i">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Name</mat-label>
                                <mat-select formControlName="name">
                                    <mat-option class="camelcased" *ngFor="let medication of allMedications"
                                        [value]="medication.title" (click)="getROAList(medication.id)">
                                        {{medication.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Daily dose</mat-label>
                                <input type="text" matInput placeholder="Daily Dose" formControlName="daily_dose">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Indication</mat-label>
                                <input type="text" matInput placeholder="Indication" formControlName="indication">
                            </mat-form-field>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Administration route</mat-label>
                                <mat-select formControlName="administration_route">
                                    <mat-option class="camelcased" *ngFor="let ROA of ROAList" [value]="ROA">
                                        {{ROA}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Start date of treatment</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="start_date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>End date of treatment</mat-label>
                                <input matInput [matDatepicker]="picker1" formControlName="end_date">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <a class="pull-right removecircle" href="javascript:;" (click)="removeBrodcast(i)"><i *ngIf="i > 0"
                        matTooltip="Remove this row" class="material-icons">remove_circle</i></a>
            </div>
            <div class="col-md-12">
                <button mat-flat-button color="primary" class="add-btn" (click)="addMoreMedicine()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="col-md-12">
                <h4>Adverse reaction </h4>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Describe adverse reaction</mat-label>
                            <textarea type="text" matInput placeholder="Describe adverse reaction"
                                formControlName="adverse_reaction"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Start date</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="adverse_start_date">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>End date</mat-label>
                            <input matInput [matDatepicker]="picker3" formControlName="adverse_end_date">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Outcome</mat-label>
                            <mat-select formControlName="adverse_outcome">
                                <mat-option value="Recovered">Recovered</mat-option>
                                <mat-option value="Recovering">Recovering</mat-option>
                                <mat-option value="Recovered with sequelae">Recovered with sequelae</mat-option>
                                <mat-option value="Not recovered">Not recovered</mat-option>
                                <mat-option value="N/A">N/A</mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <h4>Others </h4>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Seriousness criteria</mat-label>
                            <mat-select formControlName="situtation_to_patient" multiple>
                                <mat-option value="Patient Died">Patient Died</mat-option>
                                <mat-option value="Involved patient hospitalisation">Involved patient hospitalisation
                                    (24 h minimum)</mat-option>
                                <mat-option value="Life-threatening situation">Life-threatening situation</mat-option>
                                <mat-option value="Prolonged patient hospitalisation">Prolonged patient hospitalisation
                                </mat-option>
                                <mat-option value="Involved persistence or significant disability or incapacity">
                                    Involved persistence or significant disability or incapacity</mat-option>
                                <mat-option value="Other medically important condition">Other medically important
                                    condition</mat-option>
                                <mat-option value="None of the previous situations">None of the previous situations
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Action taken with the medicinal product</mat-label>
                            <mat-select formControlName="action_taken">
                                <mat-option value="Drug withdrawn">Drug withdrawn</mat-option>
                                <mat-option value="Dose reduced">Dose reduced</mat-option>
                                <mat-option value="Dose increased"> Dose increased</mat-option>
                                <mat-option value="Dose not changed">Dose not changed</mat-option>
                                <mat-option value="Unknown">Unknown</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Causality relatedness between the adverse reaction and the medicinal product
                            </mat-label>
                            <mat-select formControlName="causality_relatedness">
                                <mat-option value="Related"> Related</mat-option>
                                <mat-option value="Not related"> Not related</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Adverse reaction abated after stopping the treatment</mat-label>
                            <mat-select formControlName="adverse_reaction_abated">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                                <mat-option value="N/A">N/A</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Adverse reaction disappeared or reduced when the treatment dose was decreased
                            </mat-label>
                            <mat-select formControlName="adverse_reaction_disappeared">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                                <mat-option value="N/A">N/A</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Adverse reaction recurred after readministration</mat-label>
                            <mat-select formControlName="adverse_reaction_recurred">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                                <mat-option value="N/A">N/A</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Log Type</mat-label>
                            <mat-select formControlName="log_type">
                                <mat-option value="Symptom Log">Symptom</mat-option>
                                <mat-option value="Infusion Site Change Log">Infusion Site Change</mat-option>
                                <mat-option value="Hospitalisation">Hospitalisation</mat-option>
                                <mat-option value="Route of Administration">Route of Administration</mat-option>
                                <mat-option value="Pump changes">Pump changes</mat-option>
                                <mat-option value="Patient Event">Patient Event</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>MRD Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="mrd_date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <!-- <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Patient Activation Code</mat-label>
                            <input type="text" matInput placeholder="Activation Code" formControlName="activation_code">
                        </mat-form-field>
                    </div> -->
                    <!-- <div class="col-md-12">
                        <div class="gender-wrapper">
                          <mat-radio-group aria-label="Select an option" formControlName="remodulin" required>
                            <mat-radio-button value="is_remodulin">Remodulin</mat-radio-button>
                            <mat-radio-button value="is_nonremodulin">Non Remodulin</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div> -->
                </div>
            </div>
            <div class="col-md-12">
                <h4>Additional Information </h4>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Describe Patient Medical History</mat-label>
                            <textarea type="text" matInput placeholder="Describe adverse reaction"
                                formControlName="AdittionalInfo"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-flat-button color="primary" *ngIf="!IsUpdate"  [disabled]="!addAdverseform.valid" (click)="dataObject()"
        class="submit-btn">Submit AE Form</button>
</mat-dialog-actions>