import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-editmodule',
  templateUrl: './editmodule.component.html',
  styleUrls: ['./editmodule.component.scss']
})

export class EditmoduleComponent implements OnInit {
  complexityenum: any = complexityenum;
  public showButtonLoader: boolean = false;
  alltags: any;
  public selfparentid: any;
  public newitem: boolean = false;
  chooseMedia: any;
  public childid: any;
  public langid: any;
  allchapters: any;
  allcareyCards: any;
  moduledata: any;
  modulepatchdata: any;
  chaptercount: any;
  careycardcount: any;
  public selfparentide: any;
  public language: any;
  public allmagazineCards: any;
  public allconditions: any;
  public allmedia: any;
  public selectedoptn: any = [];
  public control1: any;
  public control2: any;
  public control3: any;
  public numDays:any = [];
  public dataForm = this.fb.group({
    title: ['', Validators.required],
    detailed_text: [''],
    complexity_level: ['', Validators.required],
    days: ['', Validators.required],
    hours: ['', Validators.required],
    minutes: ['', Validators.required],
    chapter_count: ['', Validators.required],
    chapter_data: this.fb.array([]),
    tags: ['', Validators.required],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    conditions: ['', Validators.required]
  });

  initChapterOptions() {
    return this.fb.group({
      chapterdata: []
    });
  }

  private _onDestroy = new Subject<void>();
  public searchmat: FormControl = new FormControl();
  public searchmattag = new FormControl();
  public filteredChapters: Array<ReplaySubject<any[]>> = [];
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


  @ViewChild('singleSelectChapter', { static: false }) singleSelectChapter: MatSelect;
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;


  constructor(private browserTitle: Title, private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Module');
  }
  ngOnInit() {
    this.getConditions();
    this.setSEOTags();
    this.getDays();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  initializeAllmoduleData() {
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null)
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getAllModuleData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getAllModuleData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getAllModuleData(params.chid);
        }
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChangedChapters(event, index) {
    this.filterBanksMulti(index);
  }
  modelChangedTags() {
    this.filterTags();
  }

  private filterBanksMulti(index) {
    if (!this.allchapters) {
      return;
    }
    // get the search keyword
    let search = this.searchmat.value;
    if (!search) {
      this.filteredChapters[index].next(this.allchapters.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredChapters[index].next(
      this.allchapters.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  selectChapterOptions(op) {
    this.selectedoptn = [];
    this.selectedoptn[0] = [];
    if (this.control1 && this.control1.value) {
      for (let l = 0; l < this.allchapters.length; l++) {
        for (let k = 0; k < this.control1.value.length; k++) {
          if (this.allchapters[l].id == this.control1.value[k].chapterdata) {
            this.selectedoptn[op][k] = this.allchapters[l];
          }
        }
      }
    }
  }


  selectedOptions(e, j, option) {
    var type;
    if (option == 0) {
      type = this.allchapters;
    }
    if (!this.selectedoptn[option]) {
      this.selectedoptn[option] = [];
    }
    for (let k = 0; k < type.length; k++) {
      if (e == type[k].id) {
        this.selectedoptn[option][j] = type[k];
      }
    }
  }

  disableOptions(option, e) {
    if (this.selectedoptn[option]) {
      for (let k = 0; k < this.selectedoptn[option].length; k++) {
        if (this.selectedoptn[option][k] && this.selectedoptn[option][k].id && e == this.selectedoptn[option][k].id) {
          return true;
        }
      }
    }
  }

  createChapterOptions(e) {
    this.chaptercount = e;
    this.control1 = <FormArray>this.dataForm.controls['chapter_data'];
    if (this.control1.length !== 0) {
      if (this.control1.length < e) {
        for (let j = 0; j < e; j++) {
          this.filteredChapters[j] = new ReplaySubject<any[]>(1);
          this.filteredTags[j] = new ReplaySubject<any[]>(1);
        }
        for (let l = this.control1.length; l < e; l++) {
          this.control1.push(this.initChapterOptions());
        }
        this.selectChapterOptions(0);
      }
      if (this.control1.length > e) {
        for (let j = this.control1.value.length; j > e; j--) {
          this.filteredChapters[j] = new ReplaySubject<any[]>(1);
          this.filteredTags[j] = new ReplaySubject<any[]>(1);
        }
        for (let l = this.control1.length; l > e; l--) {
          this.control1.removeAt(l - 1);
        }
        this.selectChapterOptions(0);
      }
    }
    else {
      for (let j = 0; j < e; j++) {
        this.filteredChapters[j] = new ReplaySubject<any[]>(1);
        this.filteredTags[j] = new ReplaySubject<any[]>(1);
      }

      for (var k = 0; k < e; k++) {
        this.control1.push(this.initChapterOptions());
      }
    }

  }


  // Get all Conditions
  // ----------------------------

  getConditions() {
    this.contentService
      .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetConditionsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetConditionsSuccess(data) {
    this.getTags();
    this.allconditions = data.getAllConditions;
  }



  getAllModuleData(ide) {
    this.contentService
      .getOneDataById(ide, 'module')
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.modulepatchdata = data;
        this.createChapterOptions(this.modulepatchdata.chapter_count);
        for (let k = 0; k < this.modulepatchdata.chapter_count; k++) {
          let chapterformelement = (<FormArray>(this.dataForm.controls['chapter_data'])).at(k);
          chapterformelement['controls'].chapterdata.patchValue(this.modulepatchdata.chapter_data[k]);
          this.selectChapterOptions(0);
        }
        this.modulepatchdata.days = Math.floor(this.modulepatchdata.unlock_time / (60 * 60 * 24)).toString();
        this.modulepatchdata.hours = Math.floor((this.modulepatchdata.unlock_time % 86400) / 3600);
        this.modulepatchdata.minutes = Math.floor((this.modulepatchdata.unlock_time % 3600) / 60);

        this.modulepatchdata.chapter_count = this.modulepatchdata.chapter_count.toString();
        this.dataForm.patchValue(this.modulepatchdata);
        this.selfparentide.setValue(this.selfparentid)
        this.language.setValue(this.langid);
      });
  }

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
    this.getChapters();
  }

  // Get all Chapters
  // ----------------------------

  getChapters() {
    this.contentService
      .getGraphQlData('{getParentChapters(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetChaptersSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetChaptersSuccess(data) {
    this.allchapters = data.getParentChapters;
    this.initializeAllmoduleData();
    for (let j = 0; j < this.chaptercount; j++) {
      this.filteredChapters[j].next(this.allchapters.slice());
    }
  }


  dataObject() {
    var postdata = this.dataForm.value;
    postdata.title = this.removeHTMLtags(postdata.title).toString();
    postdata.detailed_text = postdata.detailed_text.toString();
    postdata.complexity_level = postdata.complexity_level.toString();
    var hour, day, min, time;
    day = this.dataForm.value.days * 86400;
    hour = this.dataForm.value.hours * 3600;
    min = this.dataForm.value.minutes * 60;
    // postdata.image_media = (postdata.image_media === '' || postdata.image_media === null || postdata.image_media === undefined) ? '' : postdata.image_media.toString();
    time = (day + hour + min);

    postdata.unlock_time = time.toString();
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.conditions = JSON.stringify(postdata.conditions);
    postdata.chapter_count = postdata.chapter_count.toString();
    delete postdata.days;
    delete postdata.hours;
    delete postdata.minutes;
    var chapterarray = [];
    postdata.chapter_data.forEach(el => {
      chapterarray.push(el.chapterdata);
    });
    postdata.chapter_data = JSON.stringify(chapterarray);
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'module')
        .pipe(
          catchError((error: any) => {
            this.showButtonLoader = false;
            this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
            this.showButtonLoader = false;
          })
        )
        .subscribe(data => {
          data = (data as any).data;
          this.moduledata = data;
          this.systemService.showSuccessSnackBar(("Module has been added"), "OK");
          this.router.navigate(['content/module']);
        });
    }
    else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'module')
        .pipe(
          catchError((error: any) => {
            this.showButtonLoader = false;
            this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
            return throwError(error);
          }),
          finalize(() => {
            this.showButtonLoader = false;
          })
        )
        .subscribe(data => {
          this.systemService.showSuccessSnackBar(("Module has been updated"), "OK");
          this.router.navigate(['content/module']);
        });
    }
  }

  getDays(){
    for (var input = 0; input <= 365; input++) {
      this.numDays.push(input.toString());
     }
     
  }
  ​ removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

}

export enum complexityenum {
  High,
  Medium,
  Low
}

