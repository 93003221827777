import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';

@Component({
  selector: 'app-uploadprescriptionaldialog',
  templateUrl: './uploadprescriptionaldialog.component.html',
  styleUrls: ['./uploadprescriptionaldialog.component.scss']
})
export class UploadprescriptionaldialogComponent implements OnInit {
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loadingError$ = new Subject<boolean>();
  public resdata: any;
  public image_path: any;
  public maxDate = new Date();

  constructor(private UserdetailsService: UserdetailsService, public fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private systemService: SystemService) { }

  ngOnInit() {
  }

  public uploadPrescriptionForm = this.fb.group({
    patient_id_fk: [""],
    name: [""],
    test_date: [""],
    prescription_ids: [""]
  });


  onsubmit() {
    // this.uploadprescription(916);
    this.saveIttoDB(this.data.path, '100', this.uploadPrescriptionForm.value.name, this.uploadPrescriptionForm.value.name, this.data.type, 'prescription_image', 'gallery', datap => {
      this.uploadprescription(datap.id);
    })
  }
  saveIttoDB(path, size, title, desc, type, uptype, upfrom, cb) {
    var scope = this;
    this.UserdetailsService.postImageToBlade(path, size, title, desc, type, uptype, upfrom)
      .subscribe(
        (data) => {
          scope.resdata = data;
          scope.image_path = scope.resdata.data.path;
          if (scope.resdata.status == 201) {
          }
          else {
            scope.systemService.showSnackBar("Unknown error occured", "Ok");
          }
          cb(scope.resdata);
        },
        error => {
          if (error.status == 422) {
          }
        }
      )
  }

  uploadprescription(a) {
    var dd = new Date(this.uploadPrescriptionForm.value.test_date);
    var month = '' + (dd.getMonth() + 1);
    month = month.length < 2 ? '0' + month : month;
    var day = '' + dd.getDay();
    day = day.length < 2 ? '0' + day : day;
    var dmy = dd.getFullYear() + "-" + month + "-" + day;
    let data = {
      patient_id_fk: this.data.id.toString(),
      name: this.uploadPrescriptionForm.value.name,
      prescription_ids: [a],
      test_date: dmy
    }
    this.UserdetailsService.postPrescription(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(data => {
    });

  }


}










