<mat-drawer-container class="sidenav-container" autosize>
    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <div>
            <div class="col-md-10">
                <h3 class="commontitleprimary">
                    <button mat-icon-button class="create-btn" (click)="createDoctor('newsection')">
                        Create Doctor
                    </button>
                </h3>
            </div>
            <div class="col-md-2">
            </div>
            <div class="clearfix"><br /></div>
            <div class="col-md-2">
                <mat-select [(ngModel)]="typeFilters" placeholder="Filter" multiple class="sortcommonwhite"
                    (selectionChange)="typeFilter($event.value)" style="width: 100%;">
                    <mat-option value="Freemium">Freemium</mat-option>
                    <mat-option value="Regular">Regular</mat-option>
                </mat-select>
            </div>
            <div class="col-md-10">
                <form>
                    <div class="form-group has-feedback has-search">
                        <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                            placeholder="Search User here">
                        <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                            <mat-icon matSuffix>search</mat-icon>
                        </button>
                    </div>
                </form>
            </div>

            <div class="col-md-12">
                <ul class="list-inline table-filter-btn">
                    <li [class]="activecls == 'all' ? 'active' : ''">
                        <a (click)="tableSort('all')" class="all">
                            All
                        </a>
                    </li>
                    <li [class]="activecls == 'active' ? 'active' : ''">
                        <a (click)="tableSort('active')" class="active-filter">
                            Active
                        </a>
                    </li>
                    <li [class]="activecls == 'inactive' ? 'active' : ''">
                        <a (click)="tableSort('inactive')" class="inactive-filter">
                            Inactive
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-md-12">
                <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                    (scrolled)="getNextSet()" [scrollWindow]="false">
                    <div class="spinner-container" *ngIf="showloader">
                        <app-loader showLoader="{{true}}"></app-loader>
                    </div>
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows
                        class="widthfluid bordergrey list-table">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell class="camelcased" *matCellDef="let element">
                                <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
                                {{element.name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Code </th>
                            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>

                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef> Phone Number </th>
                            <td mat-cell *matCellDef="let element">+{{element.std_code}}-{{element.phone}} </td>
                        </ng-container>

                        <ng-container matColumnDef="doctor_type">
                            <th mat-header-cell *matHeaderCellDef> Doctor Type </th>
                            <td mat-cell class="camelcased" *matCellDef="let element"> {{element.doctor_type}} </td>
                        </ng-container>


                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="min-width:300px;">Action </th>
                            <td mat-cell class="text-capitalize" *matCellDef="let element">
                                <div class="action-button-row">

                                    <button mat-button (click)="createDoctor(element.id)">
                                        <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" tooltip="Edit"
                                            alt="Wellthy">

                                    </button>
                                    <button mat-button [matMenuTriggerFor]="menu3"
                                        *ngIf="!localStoredData?.organization_data?.scarlet_acl_restriction?.includes('activation')"
                                        [ngClass]="{disabled:!element.is_active}">
                                        <img src="assets/img/dls/admin-table/Icon_Activation.svg" tooltip="Activation"
                                            alt="Wellthy">

                                    </button>
                                    <button mat-button (click)="viewProfile(element)">
                                        <img src="assets/img/dls/admin-table/Icon_View profile.svg"
                                            tooltip="View Profile" alt="Wellthy">
                                    </button>


                                    <button mat-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>

                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="changepasswordHistory(element)"
                                            *ngIf="element.show_history === true">
                                            <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt=""
                                                alt="" *ngIf="element.show_history === true">
                                            <span>Change password History </span>
                                        </button>

                                        <button mat-menu-item *ngIf="element.show_history === false"
                                            matTooltip="This doctor has no password change history"
                                            style="opacity:0.5;cursor: not-allowed;">
                                            <img src="assets/img/dls/admin-table/Icon_View transfer history.svg" alt=""
                                                disabled>
                                            <span>Change password History </span>
                                        </button>


                                        <button mat-menu-item (click)="changepassword(element)">
                                            <img src="assets/img/dls/admin-table/Changepassword.svg" alt="">
                                            <span>Change password</span>
                                        </button>
                                        <button mat-menu-item class="active-green last-btn"
                                            [ngClass]="{'my-class': element.is_active == true}"
                                            (click)="isActive(element.is_active,element.id)">
                                            <span *ngIf="element.is_active == true">
                                                <img src="assets/img/dls/admin-table/Icon_Deactivate.svg" alt="">
                                            </span>
                                            <span *ngIf="element.is_active == false">
                                                <img src="assets/img/dls/admin-table/Icon_Activate.svg" alt="">
                                            </span>
                                            {{element.is_active? "Deactivate" : "Activate"}}</button>

                                        <button mat-menu-item (click)="forceLogout(element.id)">
                                            <img src="assets/img/dls/admin-table/Icon_Force_Logout.svg" alt="">
                                            <span class="drop-menu-items">Force logout</span>
                                        </button>
                                    </mat-menu>

                                    <mat-menu #menu3="matMenu">
                                        <button mat-menu-item (click)="openPreActive(element)">Pre-activate
                                            patient</button>
                                        <button mat-menu-item (click)="openDeeplink(element)">Deeplink for
                                            patient</button>
                                        <button mat-menu-item (click)="openGenericDeeplink(element)">Generic
                                            deeplink</button>
                                        <button mat-menu-item (click)="openpatientQR(element)">Unique QR</button>
                                        <button mat-menu-item (click)="openGeneralQR(element)">Generic QR code</button>
                                    </mat-menu>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>


        </div>
    </div>
</mat-drawer-container>