import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from '../../../shared/guards';
import { AdmindeletemodalComponent } from './admindeletemodal.component';

const routes: Routes = [
    { path: '', component: AdmindeletemodalComponent, canActivate: [AdminGuardGuard] },
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmindeletemodalRoutingModule {}