import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';


@Component({
  selector: 'app-viewcomment',
  templateUrl: './viewcomment.component.html',
  styleUrls: ['./viewcomment.component.scss']
})
export class ViewcommentComponent implements OnInit {
  showcomment: any;
  constructor(public dialogRef: MatDialogRef<ViewcommentComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private browserTitle: Title, private CommonserviceService: CommonserviceService, private systemService: SystemService) { }
  public ide: any;

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | View Comment');
  }

  ngOnInit(): void {
    this.setSEOTags();
    this.ide = this.data.element;
    this.showcomment = this.data.element;
    this.getAllLevelID();
  }

  getAllLevelID() {
    this.CommonserviceService
      .getRequestById(this.ide)
      .subscribe((res: any) => this.ongetAllLevelDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetAllLevelDataSuccess(data) {
    
    this.showcomment = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
