<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">
    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/userhome"></app-breadcrumb>

      <div class="col-md-12">
        <h3 class="commontitleprimary">
          <button mat-icon-button class="create-btn notranslate" (click)="editScarlet('newsection')">
            {{'Admin.AdminCreateTitle' | translate}}
          </button>
        </h3>
      </div>

      <div class="filter-search">
        <div class="col-md-12">
          <form>
            <div class="form-group has-feedback has-search">
              <input class="form-control" [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}"
                placeholder="Search">
              <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                <mat-icon matSuffix>search</mat-icon>
              </button>
            </div>
          </form>

        </div>
      </div>




      <div class="col-md-12">
        <ul class="list-inline table-filter-btn">
          <li [class]="activecls == 'all' ? 'active' : ''">
            <a (click)="tableSort('all')" class="all">
              All
            </a>
          </li>
          <li [class]="activecls == 'active' ? 'active' : ''">
            <a (click)="tableSort('active')" class="active-filter">
              Active
            </a>
          </li>
          <li [class]="activecls == 'inactive' ? 'active' : ''">
            <a (click)="tableSort('inactive')" class="inactive-filter">
              Inactive
            </a>
          </li>
        </ul>
      </div>

      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">

          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
                {{element.name}}
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="user_type">
              <th mat-header-cell *matHeaderCellDef> Role </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element"> {{element.user_type}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="min-width: 300px;">Action </th>
              <td mat-cell class="text-capitalize" *matCellDef="let element">
                <div class="action-button-row">

                  <button mat-button (click)="showdata(element)" *ngIf="userData">
                    <img src="assets/img/dls/admin-table/Icon_Change_role.svg" tooltip="Change role" alt="Wellthy">
                  </button>

                  <button mat-button (click)="editScarlet(element.id)" tooltip="Edit">
                    <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" alt="Wellthy">
                  </button>
                  <button mat-button (click)="viewProfile(element)" tooltip="View profile">
                    <img src="assets/img/dls/admin-table/Icon_View profile.svg" alt="Wellthy">
                  </button>
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="changepasswordHistory(element)"
                      *ngIf="element.show_history === true">
                      <img src="assets/img/dls/admin-table/Changepassword.svg" alt="">
                      <span class="drop-menu-items">Change password History</span>
                    </button>

                    <button mat-menu-item *ngIf="element.show_history === false"
                      matTooltip="This user has no password change history" style="opacity:0.5;cursor: not-allowed;">
                      <img src="assets/img/dls/admin-table/Changepassword.svg" alt="" disabled>
                      <span class="drop-menu-items">Change password History</span>
                    </button>




                    <button mat-menu-item (click)="changepassword(element)" *ngIf="userData">
                      <img src="assets/img/dls/admin-table/Changepassword.svg" alt="">
                      <span class="drop-menu-items">Change password</span>
                    </button>
                    <button mat-menu-item (click)="forceLogout(element.id)">
                      <img src="assets/img/dls/admin-table/Icon_Force_Logout.svg" alt="">
                      <span class="drop-menu-items">Force logout</span>
                    </button>
                    <button mat-menu-item class="no-hover-effect active-green last-btn"
                      [ngClass]="{'my-class': element.is_active == true}"
                      (click)="isActive(element.is_active,element.id)">
                      <span *ngIf="element.is_active == true">
                        <img src="assets/img/dls/admin-table/Icon_Deactivate.svg" alt="">
                      </span>
                      <span *ngIf="element.is_active == false">
                        <img src="assets/img/dls/admin-table/Icon_Activate.svg" alt="">
                      </span>
                      {{element.is_active? "Deactivate" :
                      "Activate"}}
                    </button>
                  </mat-menu>

                  <mat-menu #menu3="matMenu">
                    <button mat-menu-item *ngIf="element.user_type != 'scarlet'"
                      (click)="changeRole(element,'scarlet')">Scarlet</button>
                    <button mat-menu-item *ngIf="element.user_type != 'admin'"
                      (click)="changeRole(element,'admin')">Admin</button>
                    <button mat-menu-item *ngIf="element.user_type != 'content'"
                      (click)="changeRole(element,'content')">Content</button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>


            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button [matMenuTriggerFor]="menu3" disableRipple class="change-role no-hover-effect"
                        *ngIf="userData">Change Role
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <button mat-button disableRipple class="no-hover-effect edit-button"
                        [routerLink]="['/admin/users/edit',element.id]">Edit
                      </button>
                      <button mat-button (click)="viewProfile(element)" disableRipple
                        class="no-hover-effect view-btn">View Profile
                      </button>
                      <button mat-button (click)="changepassword(element)" disableRipple class="no-hover-effect"
                        *ngIf="userData">Change Password
                      </button>
                      <button mat-button (click)="forceLogout(element.id)" disableRipple class="no-hover-effect">Force
                        Logout
                      </button>
                      <button mat-button disableRipple class="no-hover-effect active-green last-btn"
                        [ngClass]="{'my-class': element.is_active == true}"
                        (click)="isActive(element.is_active,element.id)">{{element.is_active? "Deactivate" :
                        "Activate"}}</button>
                      <mat-menu #menu3="matMenu">
                        <button mat-menu-item *ngIf="element.user_type != 'scarlet'"
                          (click)="changeRole(element,'scarlet')">Scarlet</button>
                        <button mat-menu-item *ngIf="element.user_type != 'admin'"
                          (click)="changeRole(element,'admin')">Admin</button>
                        <button mat-menu-item *ngIf="element.user_type != 'content'"
                          (click)="changeRole(element,'content')">Content</button>
                        <button mat-menu-item [routerLink]="['/admin/hctimelog']">Download Log Reports</button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>
      </div>


    </div>
  </div>

</mat-drawer-container>