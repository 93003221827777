import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from 'src/app/shared/guards';
import { APILogsComponent } from './api-logs.component';


const routes: Routes = [
  { path: '', component: APILogsComponent, canActivate: [AdminGuardGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class APILogsRoutingModule { }
