import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdminleftnavModule } from '../../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../../shared/adminsectionleftnav/adminsectionleftnav.module';
import { HcassignleftnavModule } from '../../shared/hcassignleftnav/hcassignleftnav.module';
import { SharedModule } from './../../../shared/shared.module';
import { CreatehcComponent } from './createhc/createhc.component';
import { DownloadreportComponent } from './downloadreport/downloadreport.component';
import { HcassignhomeComponent } from './hcassignhome.component';
import { HcassignhomeRoutingModule } from './hcassignhome.routing.module';
import { HcstatusComponent } from './hcstatus/hcstatus.component';
import { HctimelogComponent } from './hctimelog/hctimelog.component';


@NgModule({
  declarations: [
    
    HcassignhomeComponent,
    CreatehcComponent,
    DownloadreportComponent,
    HcstatusComponent,
    HctimelogComponent
    
  ],
  imports: [
    CommonModule,
    HcassignhomeRoutingModule,
    ImageCropperModule,
    HcassignleftnavModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class HcassignhomeModule {}