import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { Moment } from 'moment';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import moment = require('moment');
import { UserlistService } from '../services/userlist/userlist.service';

export interface therapyDataFilter {
  therapyName: string;
  therapyid: number;
}
export interface clientDataFilter {
  clientName: string;
  clientid: number;
}
export interface ownerDataFilter {
  ownerName: string;
  ownerid: number;
}
export interface priorityDataFilter {
  title: string;
  id: number;
}
export interface expiryDataFilter {
  title: string;
  id: number;
}

@Component({
  selector: 'app-hctasklist',
  templateUrl: './hctasklist.component.html',
  styleUrls: ['./hctasklist.component.scss']
})
export class HctasklistComponent implements OnInit {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  sortedObj: any = [];
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns = ['task_id', 'task_summary', 'priority', 'expiry_date', 'status', 'action'];
  public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  isLoading: boolean = false;
  filters: any;
  sort_by: any;
  sort_direction: any
  public tagFilters: any;
  therapyData: any;
  therapyName: any;
  clientData: any;
  therapyid: any;
  clientid: any;
  ownerList: any;
  ownerid: any;
  startDate: any;
  endDate: any;
  priorityid: any = [];
  public status = 'active';
  public filterModel: any = { "low": false, "high": false, "medium": false }
  selected: { startDate: Moment, endDate: Moment };
  public failed: boolean = false;
  public pending: boolean = false;
  public getToday: any;
  highPriority: any = undefined;
  mediumPriority: any = undefined;
  lowPriority: any = undefined;
  priority: any = [];
  dataList: any = [];
  therapyDataFilter: any = [];
  clientDataFilter: any = [];
  ownerDataFilter: any = [];
  priorityDataFilter: any = [];
  expiryDataFilter: any = [];
  startDate1: any;
  endDate1: any;
  filterEvent: any;
  clientName: any;
  ownerName: any;
  taskdatalist: any = [];
  chosenTherapy: any;
  chosenClient: any;
  chosenOwner: any;
  Chosendate: any;
  taskLibraryId: any;
  patientProgressCount: any;
  constructor(public commonService: CommonserviceService,
    private systemService: SystemService, private router: Router, private route: ActivatedRoute, private userListService: UserlistService) {
    localStorage.removeItem("taskDisplay");
    localStorage.removeItem("selectedTask");
    localStorage.removeItem("selectedPatientId");
    localStorage.removeItem('hcSelectedType');
    localStorage.removeItem("notifyPatientName");
    localStorage.removeItem("selectedNotification");
    localStorage.removeItem('taskcompleteflag');
    localStorage.removeItem('progressPatients');
  }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;

  ngOnInit(): void {
    localStorage.removeItem("taskDisplay");
    localStorage.removeItem('hcSelectedType');
    localStorage.removeItem('disableTask');
    this.search();
    // this.getTherapyList();
    // this.getClientList();
    this.getFilteredList();
    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1)
    this.getToday = todayDate.toISOString()
    localStorage.removeItem('totalPatientCount');
  }


  //clear btn 
  clear(myCheckBox, myCheckBox1, myCheckBox2) {
    myCheckBox.checked = false;
    myCheckBox1.checked = false;
    myCheckBox2.checked = false;
    this.priorityDataFilter = [];
    this.lowPriority = false;
    this.mediumPriority = false;
    this.highPriority = false;
    this.priorityid = []
  }

  //mat chips for therapy
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.therapyDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }
  //remove mat chip for therapy
  async remove(filter: therapyDataFilter): Promise<void> {
    const index = this.therapyDataFilter.indexOf(filter);
    if (index >= 0) {
      this.therapyDataFilter.splice(index, 1);
      this.therapyid = false
      if (this.therapyid == false) {
        this.therapyid = false;
        this.therapyName = false;
        this.chosenTherapy = this.therapyData.title == false;
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      // 
    }
  }
  //mat chip for client
  add1(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.clientDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove1(filter: clientDataFilter): Promise<void> {
    const index = this.clientDataFilter.indexOf(filter);
    if (index >= 0) {
      this.clientDataFilter.splice(index, 1);
      this.clientid = false;
      if (this.clientid == false) {
        this.clientid = false;
        this.clientName = false;
        this.chosenClient = this.clientData.title == false;
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      //
    }
  }

  //mat chip for owner
  add2(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.ownerDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove2(filter: ownerDataFilter): Promise<void> {
    const index = this.ownerDataFilter.indexOf(filter);
    if (index >= 0) {
      this.ownerDataFilter.splice(index, 1);
      this.ownerid = false;
      if (this.ownerid == false) {
        this.ownerid = false;
        this.ownerName = false;
        this.chosenOwner = this.ownerList.title == false;
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      // 
    }
  }

  //mat chip for priority
  add3(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.priorityDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove3(filter: priorityDataFilter): Promise<void> {
    // const index = this.priorityDataFilter.indexOf(filter);    
    if (filter.id == 0) {
      const index = this.priorityDataFilter.indexOf(filter);
      this.priorityDataFilter.splice(index, 1);
      if ((this.priorityDataFilter.some(priority => priority.id == 1)) && this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [1, 2]
        this.lowPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 1)) {
        this.priorityid = [1]
        this.lowPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [2]
        this.lowPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.length == 0) {
        this.priorityid = []
        this.lowPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
    }
    if (filter.id == 1) {
      const index = this.priorityDataFilter.indexOf(filter);
      this.priorityDataFilter.splice(index, 1);
      if ((this.priorityDataFilter.some(priority => priority.id == 0)) && this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [0, 2]
        this.mediumPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 0)) {
        this.priorityid = [0]
        this.mediumPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [2]
        this.mediumPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.length == 0) {
        this.priorityid = []
        this.mediumPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
    }
    if (filter.id == 2) {
      const index = this.priorityDataFilter.indexOf(filter);
      this.priorityDataFilter.splice(index, 1);
      if ((this.priorityDataFilter.some(priority => priority.id == 0)) && this.priorityDataFilter.some(priority => priority.id == 1)) {
        this.priorityid = [0, 1]
        this.highPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 0)) {
        this.priorityid = [0]
        this.highPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 1)) {
        this.priorityid = [1]
        this.highPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.length == 0) {
        this.priorityid = []
        this.highPriority = false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
    }
  }

  //mat chip for expiry
  add4(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.expiryDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove4(filter: expiryDataFilter): Promise<void> {
    const index = this.expiryDataFilter.indexOf(filter);
    if (index >= 0) {
      this.expiryDataFilter.splice(index, 1);
      this.startDate = false;
      this.endDate = false;
      if (this.startDate == false && this.endDate == false) {
        this.Chosendate = false;
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      //  
    }
  }

  async clearChips() {
    this.therapyDataFilter = [];
    this.clientDataFilter = [];
    this.ownerDataFilter = [];
    this.priorityDataFilter = [];
    this.expiryDataFilter = [];
    this.therapyid = ""
    this.clientid = ""
    this.ownerid = ""
    this.lowPriority = false;
    this.mediumPriority = false;
    this.highPriority = false;
    this.priorityid = []
    this.startDate = ""
    this.endDate = ""
    this.chosenTherapy = this.therapyData.title == false;
    this.chosenClient = this.clientData.title == false;
    this.chosenOwner = this.ownerList.title == false;
    this.Chosendate = false;
    this.onit = true;
    this.search();
    await this.sleep(1000)
  }
  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  openCalendar(e) {
    this.pickerDirective.open(e)

  }


  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);

    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = '';
    this.search();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  //get therapy
  getTherapyList() {
    if (!this.searchcriteria) {
      this.searchcriteria = ""
    }
    this.commonService.getTherapyList(this.searchcriteria, "10", "therapy")
      .subscribe(
        (res: any) => this.getTherapyListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyListSuccess(data) {
    data = (data as any).data;
    this.therapyData = data;
    this.therapyName = this.therapyData;
    // this.therapyName.forEach(element => {
    //   this.therapyNamebyId[element.id] = element.title;
    // });
  }

  // get clients
  getClientList() {
    this.commonService.getTherapyList(this.searchcriteria, "10", "client")
      .subscribe(
        (res: any) => this.getClientListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getClientListSuccess(data) {
    data = (data as any).data;
    this.clientData = data;
  }

  //get filter data
  getFilteredList() {

    this.commonService.getFilterList()
      .subscribe(
        (res: any) => this.getFilterListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getFilterListSuccess(data) {
    data = (data as any).data;
    this.therapyData = data.filter_therapy_list;
    this.therapyName = this.therapyData;
    this.clientData = data.filter_client_list;
    this.ownerList = data.filter_owner_list;
    // this.therapyName.forEach(element => {
    //   this.therapyNamebyId[element.id] = element.title;
    // });
  }
  selectedTherapy(event, name) {
    if (event) {
      this.therapyid = event;
      this.therapyName = name;
      this.onit = true;
    } else {
      this.therapyid = ""
    }
    this.search();
  }

  selectedClient(event, name) {
    if (event) {
      this.clientid = event;
      this.clientName = name;
      this.onit = true;
    }
    else {
      this.clientid = ""
    }
    this.search();
  }

  selectedOwner(event, name) {
    if (event) {
      this.ownerid = event;
      this.ownerName = name;
      this.onit = true;
    }
    else {
      this.clientid = ""
    }
    this.search();
  }

  selectedPriority() {
    if (this.highPriority) { this.priority.push(2) };
    if (this.lowPriority) { this.priority.push(0) };
    if (this.mediumPriority) { this.priority.push(1) };
    // 
    let event = this.priority;
    if (event) {
      this.priorityid = event;
      this.onit = true;
    }
    else {
      this.clientid = ""
    }
    this.search();
  }


  tagfilter(event) {
    this.filterEvent = event;
    if (event == "Today") {
      this.startDate = moment().startOf('day').toISOString();
      this.endDate = moment().endOf('day').toISOString();
    }

    if (event == "Thisweek") {
      this.startDate = moment().startOf('week').toDate();
      this.endDate = moment().endOf('week').toDate();
      this.startDate = moment(this.startDate).toISOString();
      this.endDate = moment(this.endDate).toISOString();
    }
    this.onit = true;
    this.search();
    this.tagFilters = event;
  }

  selectDateRange() {
    this.filterEvent = "customDate";
    let d1 = this.selected.startDate.toDate();
    let d2 = this.selected.endDate.toDate();
    this.startDate = moment(d1).toISOString();
    this.endDate = moment(d2).toISOString();
    this.onit = true;
    this.search();
    this.tagFilters = event;
  }

  //  Task List
  // ----------------------------

  search() {
    if (this.searchcriteria) {
      this.searchcriteria = this.searchcriteria
    } else {
      this.searchcriteria = ''
    }

    this.lastSearch = this.searchcriteria;
    this.lastpage = this.page;
    this.isLoading = true;
    // this.searchcriteria = ''
    this.sort_by = ""
    this.sort_direction = ""
    this.therapyDataFilter = [];
    this.clientDataFilter = [];
    this.ownerDataFilter = [];
    this.priorityDataFilter = [];
    this.expiryDataFilter = [];
    this.filters = { "therapy_id": this.therapyid, "client_id": this.clientid, "owner_id": this.ownerid, "priority": this.priorityid, "expiry_start_date": this.startDate, "expiry_end_date": this.endDate }
    this.commonService.getHCTaskList(this.searchcriteria, this.page.toString(), '20', this.sort_by, this.sort_direction, this.filters, localStorage.getItem('scarletuserid').toString())
      .subscribe((res: any) => {
        this.onTaskListSuccess(res),
          this.dataList = res.data;

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onTaskListSuccess(data) {
    let date1 = new Date(this.startDate);
    let date2 = new Date(this.endDate);
    this.startDate1 = moment(date1).format('D MMM, YYYY');
    this.endDate1 = moment(date2).format('D MMM, YYYY');
    //
    data = data.data.sort((a, b) => (a.status > b.status) ? 1 : -1).reverse();

    var order = [2, 0, 3, 4]
    var newArray = [];
    order.forEach(function (item) {
      return data.filter(function (statusData) {

        if (statusData.status === item) {
          newArray.push(statusData)
        }
      })
    });

    var order1 = [2];
    var newArray1 = [];
    order1.forEach(function (item) {
      return newArray.filter(function (statusData) {

        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate() - 1)
        let today = todayDate.toISOString()
        if (statusData.status === item && statusData.expiry_date > today) {
          newArray1.push(statusData)
        }
      })
    });

    var sentAprroval = [3];
    sentAprroval.forEach(function (item) {
      return newArray.filter(function (statusData) {
        if (statusData.status === item) {
          newArray1.push(statusData)
        }
      })
    });

    var complete = [4];
    complete.forEach(function (item) {
      return newArray.filter(function (statusData) {
        if (statusData.status === item) {
          newArray1.push(statusData)
        }
      })
    });

    order1.forEach(function (item) {
      return newArray.filter(function (statusData) {
        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate() - 1)
        let today = todayDate.toISOString()
        if (statusData.status === item && statusData.expiry_date < today) {
          newArray1.push(statusData)
        }
      })
    });

    data = newArray1;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);

        this.isLoading = false;
        if (this.filters) {
          let therapy, client, owner, priority;
          if (this.filters.therapy_id) {
            therapy = { therapyName: this.therapyName, therapyid: this.therapyid }
            this.therapyDataFilter.push(therapy)
          }
          if (this.filters.client_id) {
            client = { clientName: this.clientName, clientid: this.clientid }
            this.clientDataFilter.push(client)
          }
          if (this.filters.owner_id) {
            owner = { ownerName: this.ownerName, ownerid: this.ownerid }
            this.ownerDataFilter.push(owner)
          }
          if (this.filters.priority.length > 0) {
            let prioritytitle, priorityId;
            if (this.filters.priority.includes(0)) {
              prioritytitle = "Low", priorityId = 0
              let priority1 = { title: prioritytitle, id: priorityId }
              this.priorityDataFilter.push(priority1)
            }
            if (this.filters.priority.includes(1)) {
              prioritytitle = "Medium", priorityId = 1
              let priority2 = { title: prioritytitle, id: priorityId }
              this.priorityDataFilter.push(priority2)
            }
            if (this.filters.priority.includes(2)) {
              prioritytitle = "High", priorityId = 2
              let priority3 = { title: prioritytitle, id: priorityId }
              this.priorityDataFilter.push(priority3)
            }
          }
          if ((this.filterEvent == "Today") && (this.filters.expiry_start_date && this.filters.expiry_end_date)) {
            let Date = { title: this.startDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          }
          if ((this.filterEvent == "Thisweek") && (this.filters.expiry_start_date && this.filters.expiry_end_date)) {
            let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          }
          if ((this.filterEvent == "customDate") && (this.filters.expiry_start_date && this.filters.expiry_end_date)) {
            let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          }
          //  

        }
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;

      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      // 

      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);

      this.dataSource.sort = this.sort;
      this.isLoading = false;
      if (this.filters) {
        let therapy, client, owner, priority;
        if (this.filters.therapy_id) {
          therapy = { therapyName: this.therapyName, therapyid: this.therapyid }
          this.therapyDataFilter.push(therapy)
        }
        if (this.filters.client_id) {
          client = { clientName: this.clientName, clientid: this.clientid }
          this.clientDataFilter.push(client)
        }
        if (this.filters.owner_id) {
          owner = { ownerName: this.ownerName, ownerid: this.ownerid }
          this.ownerDataFilter.push(owner)
        }
        if (this.filters.priority.length > 0) {
          let prioritytitle, priorityId;
          if (this.filters.priority.includes(0)) {
            prioritytitle = "Low", priorityId = 0
            let priority1 = { title: prioritytitle, id: priorityId }
            this.priorityDataFilter.push(priority1)
          }
          if (this.filters.priority.includes(1)) {
            prioritytitle = "Medium", priorityId = 1
            let priority2 = { title: prioritytitle, id: priorityId }
            this.priorityDataFilter.push(priority2)
          }
          if (this.filters.priority.includes(2)) {
            prioritytitle = "High", priorityId = 2
            let priority3 = { title: prioritytitle, id: priorityId }
            this.priorityDataFilter.push(priority3)
          }
        }
        if ((this.filterEvent == "Today") && (this.filters.expiry_start_date && this.filters.expiry_end_date)) {
          let Date = { title: this.startDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        }
        if ((this.filterEvent == "Thisweek") && (this.filters.expiry_start_date && this.filters.expiry_end_date)) {
          let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        }
        if ((this.filterEvent == "customDate") && (this.filters.expiry_start_date && this.filters.expiry_end_date)) {
          let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        }


      }
    }
  }


  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }

  statusInList(type) {
    var today = new Date();
    if (type.status == 0) {
      return "Failed"
    }
    if (type.status == 4) {
      return 'Completed'
    }
    if (type.status == 3) {
      this.pending = true;
      return 'Sent for approval'
    }
    if (type.status == 2 && type.expiry_date > this.getToday) {
      this.pending = true;
      return 'Pending'
    }
    if (type.status == 2 && type.expiry_date < this.getToday) {
      this.failed = true;
      return 'Failed'
    }
    else {
      return ""
    }
  }
  checkcolor(element) {
    if (element.status == '4') {
      return 'circle green'
    } else if (element.status == '2' && element.expiry_date < this.getToday) {
      return 'circle red'
    } else if (element.status == '2' && element.expiry_date > this.getToday) {
      return 'circle orange'
    } else if (element.status == '3') {
      return 'circle orange'
    }
  }




  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ',' + ' ' + year;
    }
  }
  cancelSearch() {
    this.searchcriteria = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();

  }

  routeChange(e) {
    this.taskLibraryId = e.task_library_id;
    let prevprogress: any = [];
    let progressPatients: any = [];
    var postData = {
      task_library_id: (e.task_library_id).toString(),
      hc_id: localStorage.getItem('scarletuserid'),
    }
    this.commonService.getProgress(postData, 'getTaskProgress')
      .subscribe(
        (res: any) => {
          if (res.data[0].progress.length > 0) {
            prevprogress = res.data[0].progress[0].patient_completed;
            // progressPatients = this.getUnique(prevprogress);
            progressPatients = [...new Set(prevprogress)];
            // progressPatients = res.data[0].progress[0].patient_completed;
          }
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )

    this.commonService.getOneTaskData(e.task_library_id)
      .subscribe(
        (res: any) => {

          localStorage.setItem("taskDetails", JSON.stringify(res.data));
          if (e.status == '0' || e.status == '2' && e.expiry_date < this.getToday) {

          } else {
            let displayTask: any = true;
            this.userListService.change___selectedpatient(e.filter_conditions);
            // localStorage.setItem('selectedTask', JSON.stringify(e.filter_conditions))
            localStorage.setItem('taskDisplay', displayTask)
            localStorage.setItem('newTaskID', e.task_library_id)
            this.getpetientList(e.task_library_id);
            localStorage.setItem("hcSelectedType", e.task[0].type);
            localStorage.setItem('hctaskdata', JSON.stringify(e));
            localStorage.setItem('task_display_id', JSON.stringify(e.task_display_id));

            if (e.task[0].type == 'broadcast') {//fully automated
              let patentId = []
              let patentName = []
              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {
                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };
                    patentId.push(patientData);
                  })
                  if (patentId.length == res.data.length) {
                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    // let finalCount = 
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == this.patientProgressCount && ((progressPatients.length && this.patientProgressCount) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      this.router.navigate(['healthcoach/hctasks/hctaskexecute', e.task_library_id]);
                    }
                  }
                })
            }
            else if (e.task[0].type == 'call') {//manual
              let patentId = []
              let patentName = []
              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {
                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };
                    patentId.push(patientData);
                  })
                  if (patentId.length == res.data.length) {
                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    // let finalCount = 
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == this.patientProgressCount && ((progressPatients.length && this.patientProgressCount) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      localStorage.setItem("selectedpatentdata", JSON.stringify(data));
                      this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
                    }
                  }
                })

            } else if (e.task[0].type == 'chat') {//semi automated
              let patentId = []
              let patentName = []
              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {
                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };
                    patentId.push(patientData);
                  })
                  if (patentId.length == res.data.length) {
                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    // let finalCount = 
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == this.patientProgressCount && ((progressPatients.length && this.patientProgressCount) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      this.router.navigate(['healthcoach/hctasks/hctaskexecute', e.task_library_id]);
                    }
                  }
                })
            } else if (e.task[0].type == 'patient_age') {//manual
              let data = { "selectedpatent": '', "selectedpatentflag": false }
              localStorage.setItem("selectedpatentdata", JSON.stringify(data));
              this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
            } else if (e.task[0].type == 'activity') {//manual
              let patentId = []
              let patentName = []

              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {

                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };

                    patentId.push(patientData);


                  })

                  if (patentId.length == res.data.length) {

                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == patentId.length && ((progressPatients.length && this.patientProgressCount) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      localStorage.setItem("selectedpatentdata", JSON.stringify(data));
                      this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
                    }

                  }
                })
              // let data = { "selectedpatent": '', "selectedpatentflag": false }
              // localStorage.setItem("selectedpatentdata", JSON.stringify(data));
              // this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
            } else if (e.task[0].type == 'custom_broadcast') {//semi automated
              let patentId = []
              let patentName = []
              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {
                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };
                    patentId.push(patientData);
                  })
                  if (patentId.length == res.data.length) {
                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    // let finalCount = 
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == this.patientProgressCount && ((progressPatients.length && this.patientProgressCount) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      this.router.navigate(['healthcoach/hctasks/hctaskexecute', e.task_library_id]);
                    }
                  }
                })
            } else if (e.task[0].type == 'whatsapp_chat') {//semi automated
              let patentId = []
              let patentName = []
              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {
                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };
                    patentId.push(patientData);
                  })
                  if (patentId.length == res.data.length) {
                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    // let finalCount = 
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == this.patientProgressCount && ((progressPatients.length && this.patientProgressCount) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      this.router.navigate(['healthcoach/hctasks/hctaskexecute', e.task_library_id]);
                    }
                  }
                })
            } else if (e.task[0].type == 'whatsapp_call') {  //manual
              let patentId = []
              let patentName = []
              var postdata = {
                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {

                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };

                    patentId.push(patientData);


                  })

                  if (patentId.length == res.data.length) {

                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == patentId.length && ((progressPatients.length && patentId.length) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      localStorage.setItem("selectedpatentdata", JSON.stringify(data));
                      this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
                    }
                  }
                })
            } else if (e.task[0].type == 'clinical_parameter') {//manual
              let patentId = []
              let patentName = []

              var postdata = {

                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {

                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };

                    patentId.push(patientData);


                  })

                  if (patentId.length == res.data.length) {

                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == patentId.length && ((progressPatients.length && patentId.length) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      localStorage.setItem("selectedpatentdata", JSON.stringify(data));
                      this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
                    }
                  }
                })

            } else if (e.task[0].type == 'data_parameters') {//manual 
              let patentId = []
              let patentName = []

              var postdata = {

                task_id_fk: (e.task_library_id).toString(),
                user_id_fk: localStorage.getItem('scarletuserid'),
                page: this.page.toString(),
                limit: "20",
              }
              this.commonService.TaskPost(postdata, 'getPatientList')
                .subscribe(async (res: any) => {
                  res.data.forEach(d => {
                    let patientData: any = {
                      id: d.id,
                      name: d.name
                    };
                    patentId.push(patientData);
                  })

                  if (patentId.length == res.data.length) {
                    let data = {
                      "selectedpatent": patentId,
                      "selectedpatentflag": true
                    }
                    this.getTotalPatientCount(patentId, e);
                    await this.sleep(1000)
                    if (progressPatients.length == patentId.length && ((progressPatients.length && patentId.length) !== 0)) {
                      var task_library_id = localStorage.getItem('newTaskID');
                      this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
                    } else {
                      localStorage.setItem("selectedpatentdata", JSON.stringify(data));
                      this.router.navigate(['healthcoach/hctasks/hctaskmanual']);
                    }
                  }
                })

            }


          }
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )

  }
  // check(){
  //   return 'circle green';
  // }

  getUnique(arr) {

    let uniqueArr = [];

    // loop through array
    for (let i of arr) {
      if (uniqueArr.indexOf(i) === -1) {
        uniqueArr.push(i);
      }
    }

    return uniqueArr;
  }



  getpetientList(taskId) {
    let postData = {
      task_id_fk: taskId.toString(),
      user_id_fk: localStorage.getItem('scarletuserid'),
      page: this.page.toString(),
      limit: "20"
    }
    this.commonService.TaskPost(postData, 'getPatientList')
      .subscribe(
        (res: any) => this.patientList(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  patientList(data) {
    data = data.data;
    this.userListService.change_patientData(data);
  }

  async getTotalPatientCount(patentId, e) {
    if (patentId.length !== 0 && patentId.length == 20) {
      let totalPatientCount = [];
      totalPatientCount.push(patentId); // 20
      let incremenentPageCount = 2
      for (let i = incremenentPageCount; i == incremenentPageCount; i++) {
        var postdata = {
          task_id_fk: (e.task_library_id).toString(),
          user_id_fk: localStorage.getItem('scarletuserid'),
          page: incremenentPageCount.toString(),
          limit: "20",
        }

        this.commonService.TaskPost(postdata, 'getPatientList')
          .subscribe(async (res: any) => {
            if (res.data.length !== 0) {

              res.data.forEach(d => {
                let newData: any = {
                  id: d.id,
                  name: d.name
                };
                totalPatientCount[0].push(newData); // adding 5
              })
              await this.sleep(1000)
              localStorage.setItem('totalPatientCount', JSON.stringify(totalPatientCount[0].length));
              incremenentPageCount++
            }

          })
      }
      await this.sleep(1000)
      this.patientProgressCount = totalPatientCount[0].length;
    }
    else {
      this.patientProgressCount = patentId.length;
      localStorage.setItem('totalPatientCount', JSON.stringify(patentId.length));
    }
  }
}
