import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { PatientRoutingModule } from './patient.routing.module';
import { PatientComponent } from './patient.component';
import { PatientuserdetailsComponent } from './patientuserdetails/patientuserdetails.component';
import { PatientpaymentinfoComponent } from './patientpaymentinfo/patientpaymentinfo.component';
import { AdminleftnavModule } from '../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../shared/adminsectionleftnav/adminsectionleftnav.module';
import { PatientonsiteComponent } from './patientonsite/patientonsite.component';
import { PatientInformationComponent } from './patient-information/patient-information.component';
import { EditPatientInformationComponent } from './edit-patient-information/edit-patient-information.component';
import { LoginhistoryComponent } from './loginhistory/loginhistory.component';
import { PatientPhoneChangeLogComponent } from './patient-phone-change-log/patient-phone-change-log.component';
import { PatientEventsComponent } from './patient-events/patient-events.component';

@NgModule({
  declarations: [
    
    PatientComponent,
    PatientuserdetailsComponent,
    PatientpaymentinfoComponent,
    PatientonsiteComponent,
    PatientInformationComponent,
    EditPatientInformationComponent,
    LoginhistoryComponent,
    PatientPhoneChangeLogComponent,
    PatientEventsComponent

],
  imports: [
    CommonModule,
    PatientRoutingModule,
    ImageCropperModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    SharedModule.forRoot(),
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PatientModule {}