import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-downloadreport',
  templateUrl: './downloadreport.component.html',
  styleUrls: ['./downloadreport.component.scss']
})

export class DownloadreportComponent implements OnInit {
  private userData: any;
  public currentdate: any;
  public newDate: any;
  public morrow: any = new Date();
  public completedata: any;
  public total_logs: number = 0;
  public total_breaks: number = 0;
  public datediff: any;
  public showdownloadoption: boolean = false;
  public iscumulative: boolean = false;

  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<DownloadreportComponent>,
    private systemService: SystemService, public fb: FormBuilder, private commonService: CommonserviceService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.data.type == 'solo') {
      this.userData = this.data.element;
      this.iscumulative = false;
    }
    else {
      this.iscumulative = true;
    }
  }

  downloadReport() {
    var data = {
      "name": this.userData.name,
      "total_logs": this.total_logs,
      "total_breaks": this.total_breaks,
      "datediff": this.datediff,
      "alldata": this.completedata
    }
    this.dialogRef.close({ data: data });
  }


  // To Get Time Logs
  // ----------------------------

  timelogs() {
    var scope = this;
    setTimeout(() => {
      scope.showdownloadoption = true
    }, 1500);
    var postdata = this.dataForm.value;
    var startd = postdata.startdate;
    var endd = postdata.enddate;
    startd.setHours(0, 0, 0, 0);
    endd.setHours(23, 59, 59, 99);
    var dep = moment(startd).format('YYYY-MM-DD')
    var pep = moment(endd).format('YYYY-MM-DD')
    this.datediff = moment(endd).diff(moment(startd), 'days')
    if (this.iscumulative) {
      this.commonService.getReportData(this.userData.id, dep, pep)
      .subscribe(
        (res: any) => this.onSuccessCumulativeReport(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
    else {
      this.commonService.getReportData(this.userData.id, dep, pep)
      .subscribe(
        (res: any) => this.onSuccessReport(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onSuccessCumulativeReport(data) {
    this.completedata = data;
    this.completedata = this.completedata.data;
    this.completedata.forEach(el => {
      this.total_logs += parseInt(el.total_logs);
      this.total_breaks += el.total_breaks;
    });
  }

  private onSuccessReport(data) {
    this.completedata = data;
    this.completedata = this.completedata.data;
    this.completedata.forEach(el => {
      this.total_logs += parseInt(el.total_logs);
      this.total_breaks += el.total_breaks;
    });
  }

  // ----------------------------


  convertDate(d) {
    var date = new Date(d);
    return date.toLocaleString();
  }

  convertTime(datetime) {
    var d = new Date(datetime);
    return d.toLocaleTimeString();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataForm.get('enddate').reset();
    // 
    this.currentdate = event.target.value;
    this.newDate = new Date(event.target.value);
    this.newDate.setDate(this.newDate.getDate() + 29)
  }

}
