import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-startstaging',
  templateUrl: './startstaging.component.html',
  styleUrls: ['./startstaging.component.scss']
})

export class StartstagingComponent implements OnInit {
  showloader: boolean = false;
  public requestForm = this.fb.group({
    phone: ["", Validators.required]
  })

  constructor(public dialogRef: MatDialogRef<StartstagingComponent>,private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, public fb: FormBuilder, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Start Staging');
  }

  ngOnInit(): void {
    this.setSEOTags();
  }

  
  // Staging Request
  // ----------------------------

  staging() {
    this.commonService.stagingRequest(this.data.element, this.requestForm.value.phone)
    .subscribe(
      (res : any) => this.onStagingSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    ) 
  }

  private onStagingSuccess() {
    this.dialog.closeAll();
    this.router.navigate(['admin/publishrequests']);
    this.systemService.showSnackBar("Changes are live on Stagin Therapy", "OK");
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  // ----------------------------

}
