<div class="doctor-graph-wrapper">
  <div class="chartheader">
    <form [formGroup]="dataForm" validate class="filter-form">
      <div class="row">

        <mat-form-field appearance="outline"
          [ngClass]="((mainoption == 'Activity') || (mainoption === 'Weight') || (mainoption === 'Water') || (mainoption === 'Peak Flow') || (mainoption === 'Meal') || (mainoption === 'Blood Pressure') || (mainoption === 'Blood Sugar')) ? 'col-md-12 bsugar' : 'col-md-6 pr-0'"
          class="col-md-3 col-sm-12 col-xs-12 doctor-filter-selection">
          <mat-label>Select option</mat-label>
          <mat-select formControlName="selectedOption" (selectionChange)="selectVal($event.value)">
            <mat-option class="text-capitalize" *ngFor="let tag of eligibleLogData" [value]="tag.title">
              {{tag.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>



        <mat-form-field appearance="outline" class="col-md-3 doctor-filter-selection"
          *ngIf="mainoption == 'Body Parameter'">
          <mat-label>Measurement Type</mat-label>
          <mat-select class="text-capitalize" (selectionChange)="selectMeasurementVal($event.value)">
            <mat-option class="text-capitalize" *ngFor="let measurement of MeasurementOptions"
              [value]="measurement.title">
              {{measurement.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div
          *ngIf="(mainoption != 'Activity' && mainoption != 'Water' && mainoption != 'Weight' && mainoption != 'Peak Flow' && mainoption != 'Meal' && mainoption != 'Blood Pressure' && mainoption != 'Blood Sugar'); else standardApplybutton">
          <mat-form-field appearance="outline" class="col-md-3 col-sm-6 col-xs-6 doc-date-picker-white-bg"
            (click)="$event.stopPropagation();">
            <input matInput formControlName="startdate" [matDatepicker]="picker1" [max]="morrow"
              placeholder="Start date">
            <mat-datepicker-toggle matSuffix [for]="picker1" [disableRipple]="true">
              <mat-icon matDatepickerToggleIcon>
                <img src="assets/img/dls/icons/Icon_Calendar.svg" alt="calender-icon" class="calendar-icon">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-md-3 col-sm-6 col-xs-6 doc-date-picker-white-bg"
            (click)="$event.stopPropagation();" [ngStyle]="{'margin-top':(mainoption == 'Meal') ? '20px' : '0' }">
            <input matInput formControlName="enddate" [matDatepicker]="picker2" [max]="morrow" placeholder="End date">
            <mat-datepicker-toggle matSuffix [for]="picker2" [disableRipple]="true">
              <mat-icon matDatepickerToggleIcon>
                <img src="assets/img/dls/icons/Icon_Calendar.svg" alt="calender-icon" class="calendar-icon">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <div class="col-md-3 col-xs-12">
            <button mat-flat-button color="primary" class="primary apply-btn doctor-apply-btn"
              (click)="applymainfilter()">Apply</button>
          </div>
        </div>
        <ng-template #standardApplybutton>
          <div class="col-md-3" *ngIf="mainoption == 'Blood Sugar'">
            <mat-form-field class="floating-bloodsugar-options" appearance="outline" *ngIf="mainoption == 'Blood Sugar'"
              style="display: block;margin-top: -5px;" class="blood-sugar-sub-type-select">
              <mat-label>Sub Type</mat-label>
              <mat-select class="text-capitalize" (selectionChange)="bloodSugarSubMenu($event.value)">
                <mat-option class="text-capitalize" value="fasting blood sugar">Fasting</mat-option>
                <mat-option class="text-capitalize" value="postmeal breakfast">Post Breakfast</mat-option>
                <mat-option class="text-capitalize" value="postmeal lunch">Post Lunch</mat-option>
                <mat-option class="text-capitalize" value="postmeal dinner">Post Dinner</mat-option>
                <mat-option class="text-capitalize" value="post snacks">Post Snacks</mat-option>
                <mat-option class="text-capitalize" value="premeal breakfast">Pre Breakfast</mat-option>
                <mat-option class="text-capitalize" value="premeal lunch">Pre Lunch</mat-option>
                <mat-option class="text-capitalize" value="premeal dinner">Pre Dinner</mat-option>
                <mat-option class="text-capitalize" value="pre snacks">Pre Snacks</mat-option>
                <mat-option class="text-capitalize" value="random">Random</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <button mat-flat-button color="primary" class="primary apply-btn doctor-apply-btn"
              (click)="applymainfilter()">Apply</button>
          </div>
        </ng-template>
      </div>
    </form>
  </div>

  <div class="logging-chart" *ngIf="defaultleftoption !== 'infusionsite' && defaultleftoption !== 'body'">
    <div class="row" *ngIf="!showNodatafound">
      <div *ngIf="enableGraph && mainoption=='Meal' || mainoption=='meal'" class="col-md-12" class="meal-data"
        style="padding: 15px;">
        Daily calorie goal:
        <span *ngIf="targetPatientData.target && userData.show_targets==true"
          class="meal-data-sub-text">{{convertDecimal(targetPatientData.target)}} cal</span>
        <span *ngIf="mainoption=='Meal' && userData.show_targets==false"
          class="meal-data-sub-text">NA</span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' &&  userData.show_targets==true" class="meal-data"> Breakfast:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.breakfast)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' && userData.show_targets==true" class="meal-data">Lunch:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.lunch)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' && userData.show_targets==true" class="meal-data"> Dinner:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.dinner)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;

        <span *ngIf="mainoption=='Meal' && userData.show_targets==true" class="meal-data"> Snacks:
          <span class="meal-data-sub-text"> {{convertDecimal(targetPatientData.daily.snacks)}} kcal</span>
        </span>&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <ng-container>
        <div class="col-md-12" style="padding-top: 10px;">
          <span *ngIf="mainoption === 'Activity' || mainoption === 'activity'" class="meal-data">
            Daily average:
            <span class="meal-data-sub-text">{{ activityAverage }}</span>
          </span>
          <!-- water average -->
          <span *ngIf="mainoption === 'Water' || mainoption === 'water'" class="meal-data">
            Daily average:
            <span class="meal-data-sub-text">{{ waterAverage }}</span>
          </span>
          <!-- Meal average -->
          <span *ngIf="mainoption === 'Meal' || mainoption === 'meal'" class="meal-data">
            Daily average:
            <span class="meal-data-sub-text">{{ mealAverage }}</span>
          </span>
          <!-- latest weight -->
          <span *ngIf="mainoption === 'Weight' || mainoption === 'weight'" class="meal-data">
            Latest Weight:
            <span class="meal-data-sub-text">{{ latestWeight }}</span>
          </span>
        </div>
      </ng-container>
    </div>
    <ng-container
      *ngIf="enableGraph && defaultleftoption === 'activity' || defaultleftoption === 'weight' || defaultleftoption === 'water' || defaultleftoption === 'peak_flow' || defaultleftoption === 'meal' || defaultleftoption === 'blood_pressure' || defaultleftoption === 'blood_sugar'">
      <div id="dashboard_div" *ngIf="!showNodatafound;else nographdatafound">
        <div id="chart_div"></div>
        <div id="slider_div"></div>
      </div>
    </ng-container>

    <div [hidden]="!(defaultleftoption == 'blood_pressure')" *ngIf="!showNodatafound" class="labels text-center">
      <div>
        <div class="systolic-bar"></div> Systolic-Diastolic (mmHg) &nbsp;<div class="pulse-bar"></div> Pulse
        Rate&nbsp;(BPM)
      </div>
    </div>
  </div>

  <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextSet()"
    [scrollWindow]="false" class="example-container diarycss" *ngIf="!showNodatafound;else nodatafound">

    <table mat-table [dataSource]="dataSource" matSort class="reports-table">

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef>Weight<span>(Kg)</span></mat-header-cell>
        <mat-cell *matCellDef="let showGenericData">{{showGenericData?.unit == 'lbs' ? (showGenericData.quantity *
          0.453592 | number: '1.0-0'): (showGenericData.quantity | number: '1.0-0')}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="water_quantity">
        <mat-header-cell *matHeaderCellDef>Quanity <span>(ml)</span></mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"> {{showGenericData.volume}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="systolic">
        <mat-header-cell *matHeaderCellDef> Systolic </mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"> {{showGenericData?.systolic}} {{showGenericData?.systolic_unit}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="diastolic">
        <mat-header-cell *matHeaderCellDef> Systolic-Diastolic &nbsp;<span>(mmHg)</span> </mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          {{showGenericData.systolic}}-{{showGenericData.diastolic}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activity_type">
        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.activity_type}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activity_value">
        <mat-header-cell *matHeaderCellDef> Activity<span>(min)</span></mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.quantity |
          number:'1.0-0'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="heart_rate">
        <mat-header-cell *matHeaderCellDef>Heart Rate</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData">
          <span *ngIf="showGenericData.heart_rate !== null">
            {{showGenericData.heart_rate}}
            {{showGenericData.heart_rate_unit}}
          </span>
          <span *ngIf="showGenericData.heart_rate === null">
            NA
          </span>

        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="blood_sugar_log_type">
        <mat-header-cell *matHeaderCellDef>Log Type</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData">{{showGenericData.meal_type}}
          <br />
          <span *ngIf="showGenericData.meal_type !== 'Fasting'">
            &nbsp; - {{ showGenericData.blood_sugar_log_type}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="blood_sugar_meal_type">
        <mat-header-cell *matHeaderCellDef>Meal Type</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.meal_type}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="calories_value">
        <mat-header-cell *matHeaderCellDef class="notranslate">kcal</mat-header-cell>
        <mat-cell class="camelcased" *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          <div style="display: flex;flex-direction:column;">
            <div *ngFor="let d of showGenericData.meal_data" >
              <span *ngFor="let meal of d.food_data;let j = index">
                <span>
                {{calculateKcal(meal.default_kcal,meal.quantity) | number : '1.1-1'}} <br></span><br>
                </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="blood_sugar_val">
        <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.value}}
          {{showGenericData.unit}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.value}}
          {{showGenericData.unit}}</mat-cell>
      </ng-container>


      <ng-container matColumnDef="Quantity">
        <mat-header-cell *matHeaderCellDef> Quantity</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.value}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hip">
        <mat-header-cell *matHeaderCellDef> Hip</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.hip}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hip_unit">
        <mat-header-cell *matHeaderCellDef> Hip Unit</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.hip_unit}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waist">
        <mat-header-cell *matHeaderCellDef> Waist</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.waist}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waist_unit">
        <mat-header-cell *matHeaderCellDef> Waist Unit</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.waist_unit}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ratio">
        <mat-header-cell *matHeaderCellDef> Aspect Ratio</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.ratio}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit">
        <mat-header-cell *matHeaderCellDef> Unit</mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"> {{convertToFirstletterCaps(showGenericData.unit)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="log_date" *ngIf="defaultleftoption !== 'meal'" >
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          {{convertIsoDate(showGenericData.log_date)}} <br> <br>  </mat-cell>
      </ng-container>

      <ng-container matColumnDef="log_date" *ngIf="defaultleftoption === 'meal'" >
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          {{convertIsoDate(showGenericData?.meal_data[0]?.log_date)}} <br> <br> <img *ngIf="defaultleftoption === 'meal'" style="height: 20px;" alt="macro-nutritient" src="assets/img/nutrition.png" (click)="showMacroData(showGenericData.meal_data);"> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="meal_type">
        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{showGenericData.meal_type}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="food_item">
        <mat-header-cell *matHeaderCellDef> Item</mat-header-cell>
        <mat-cell class="camelcased" *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          <div style="display: flex;flex-direction:column;">
            <div *ngFor="let d of showGenericData.meal_data" >
              <span *ngFor="let meal of d.food_data;let j = index">
                <span
                  [ngStyle]="(d.is_deleted || meal.is_deleted ) ? {'color': 'red','opacity': '0.5','cursor':'not-allowed','pointer-events': 'none'}:{'background-color': '','pointer-events': 'all'}">
                  {{meal.food_item}}({{meal.quantity}})<br></span><br>
                </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="measure">
        <mat-header-cell *matHeaderCellDef>Measure </mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"> {{showGenericData.measure}} {{showGenericData?.unit}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="log_type">
        <mat-header-cell *matHeaderCellDef> Log Type</mat-header-cell>
        <mat-cell class="text-capitalize" *matCellDef="let showGenericData"> {{ConvertData(showGenericData.log_type)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="location_name">
        <mat-header-cell *matHeaderCellDef> Location Name</mat-header-cell>
        <mat-cell class="text-capitalize text-left" *matCellDef="let showGenericData">
          {{showGenericData.location_name}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="infusion_site">
        <mat-header-cell *matHeaderCellDef>Infusion Site</mat-header-cell>
        <mat-cell class="text-capitalize text-left" *matCellDef="let showGenericData">
          {{ ConvertData(showGenericData.infusion_site)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="site_change_reason">
        <mat-header-cell *matHeaderCellDef>Change Reason</mat-header-cell>
        <mat-cell class="text-capitalize text-left" *matCellDef="let showGenericData">
          {{showGenericData.site_change_reason}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="reminder_days">
        <mat-header-cell *matHeaderCellDef>Reminder Days</mat-header-cell>
        <mat-cell class="text-capitalize text-left" *matCellDef="let showGenericData">
          {{showGenericData.reminder_days}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef>Weight &nbsp;<span>(Kg)</span></mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          {{showGenericData?.unit == 'lbs' ? (showGenericData.quantity * 0.453592 | number: '1.0-0') :
          (showGenericData.quantity| number: '1.0-0')}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tag">
        <mat-header-cell *matHeaderCellDef> Tags</mat-header-cell>
        <mat-cell *matCellDef="let showGenericData"
          [ngClass]="{ 'blink-bg1': createdIdnotificationId  == showGenericData.id }">
          {{!showGenericData.tag?'-':showGenericData.tag}}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row [ngStyle]="showGenericData.is_deleted?{'background-color':'#FFECEF'} : {'background-color': ''}"
        *matRowDef="let showGenericData; columns: displayedColumns;"></mat-row>
    </table>
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" #paginator showFirstLastButtons class="doctor-report">
    </mat-paginator> -->
  </div>
  <ng-template #nodatafound>
    <div class="empty-holder">
      No Table Data Available
    </div>
  </ng-template>
  <ng-template #nographdatafound>
    <div class="empty-holder">
      No Graph Data Available
    </div>
  </ng-template>
</div>