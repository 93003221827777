<div class="view-task">
    <div class="pull-right" style="display: inline-block;">
        <img src="assets/img/dls/icons/cross.svg" [mat-dialog-close]="true" class="img-responsive" alt="">
    </div>
    <br />
    <h4>Publish Request Details</h4>
    <mat-dialog-content style="margin-top: 0%;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Request Title</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr style="background:#F1F1F1">
                    <td width="50%">{{detailView?.title}}</td>
                    <td width="50%">{{detailView?.description}}</td>
                </tr>
            </tbody>
        </table>
    </mat-dialog-content>
</div>