<div class="col-md-12 charts-container">
  <div class="col-md-3 progress-chart-container">
    <div class="progress-heading">Chapters Rated</div>
    <div class="rating-value">52/70</div>
  </div>
  <div class="col-md-3 progress-chart-container">
    <div class="progress-heading">Avg Rating</div>
    <div class="rating-value">4.2</div>
  </div>
  <div class="col-md-3 progress-chart-container">
    <div class="progress-heading">Total Ratings Received</div>
    <div class="rating-value">968</div>
  </div>
  <div class="col-md-3 progress-chart-container">
    <div class="progress-heading">Avg Bounce Rate</div>
    <div class="rating-value">4.76 %</div>
  </div>
</div>
<div class="col-md-12 charts-container">
  <div class="col-md-3 pie-chart-container">
    <div class="chart-heading">Overall Rating</div>
    <div class="pie-container" id="pie-container"></div>
  </div>
  <div class="col-md-9 right-chart-container">
    <div class="chart-heading">Average Daily Rating</div>
    <div class="col-md-8 selection-wrapper">
      <div class="selection-text col-md-2 compare-text" (click)="openComparisonDropdown()">Compare with
        <div class="dropdown-wrapper" *ngIf="showComparisonDropdown && !allSelected">
          <div class="comparison-dropdown-value comparison-dropdown-five" (click)="addCompareVal(5)">5 star</div>
          <div class="comparison-dropdown-value comparison-dropdown-four" (click)="addCompareVal(4)">4 star</div>
          <div class="comparison-dropdown-value comparison-dropdown-three" (click)="addCompareVal(3)">3 star</div>
          <div class="comparison-dropdown-value comparison-dropdown-two" (click)="addCompareVal(2)">2 star</div>
          <div class="comparison-dropdown-value comparison-dropdown-one" (click)="addCompareVal(1)">1 star</div>
        </div>
      </div>
      <div class="selection-text col-md-1 five-star-selection star-selection" *ngIf="comparisonSelected.indexOf(5) > -1"
        (click)="removeCompareVal(5)">5 star</div>
      <div class="selection-text col-md-1 four-star-selection star-selection" *ngIf="comparisonSelected.indexOf(4) > -1"
        (click)="removeCompareVal(4)">4 star</div>
      <div class="selection-text col-md-1 three-star-selection star-selection"
        *ngIf="comparisonSelected.indexOf(3) > -1" (click)="removeCompareVal(3)">3 star</div>
      <div class="selection-text col-md-1 two-star-selection star-selection" *ngIf="comparisonSelected.indexOf(2) > -1"
        (click)="removeCompareVal(2)">2 star</div>
      <div class="selection-text col-md-1 one-star-selection star-selection" *ngIf="comparisonSelected.indexOf(1) > -1"
        (click)="removeCompareVal(1)">1 star</div>
    </div>
    <div class="col-md-4 selection-wrapper">
      <div class="col-md-3"></div>
      <div class="selection-text col-md-5 duration-text">Select Duration</div>
      <div class="duration-dropdown col-md-4" (click)="openDurationDropdown()">Last week</div>
      <div class="duration-dropdown-wrapper" *ngIf="showDurationDropdown && !allSelected" tabindex="-1"
        onblur="'showDurationDropdown == false'">
        <div class="comparison-dropdown-value " (click)="addCompareVal(5)">Last week</div>
        <div class="comparison-dropdown-value " (click)="addCompareVal(5)">Last 1 month</div>
        <div class="comparison-dropdown-value " (click)="addCompareVal(5)">Last 3 months</div>
        <div class="comparison-dropdown-value " (click)="addCompareVal(5)">Last 6 months</div>
        <div class="comparison-dropdown-value " (click)="addCompareVal(5)">Last year</div>
        <div class="comparison-dropdown-value " (click)="addCompareVal(5)">All time</div>
      </div>
    </div>
    <div class="col-md-12 avg-chart-container" id="avg-chart-container"></div>
    <div class="col-md-12 area-chart-container" id="area-chart-container"></div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="col-md-8 selection-wrapper">
      <div class="chart-heading language-wraper">Feedback Details</div>
    </div>
    <div class="col-md-4 selection-wrapper">
      <div class="col-md-3"></div>
      <div class="selection-text col-md-5 duration-text">Select Language</div>
      <div class="duration-dropdown col-md-4" (click)="toggleLanguageDropdown()">English</div>
      <div class="duration-dropdown-wrapper" *ngIf="showLanguageDropdown && !allSelected">
        <div class="comparison-dropdown-value" (click)="addCompareVal(5)">Last week</div>
        <div class="comparison-dropdown-value" (click)="addCompareVal(5)">Last 1 month</div>
        <div class="comparison-dropdown-value" (click)="addCompareVal(5)">Last 3 months</div>
        <div class="comparison-dropdown-value" (click)="addCompareVal(5)">Last 6 months</div>
        <div class="comparison-dropdown-value" (click)="addCompareVal(5)">Last year</div>
        <div class="comparison-dropdown-value" (click)="addCompareVal(5)">All time</div>
      </div>
    </div>
  </div>
</div>
<table mat-table [dataSource]="dataSource" class="col-md-12 bordergrey" matSort>
  <!-- Id Column -->
  <ng-container matColumnDef="Id" class="col-md-2">
    <th mat-header-cell *matHeaderCellDef> Id </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
  </ng-container>

  <!-- Rating Column -->
  <ng-container matColumnDef="Rating">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="rating"> Avg. Rating </th>
    <td mat-cell *matCellDef="let element"> {{ element.rating }}
    </td>
  </ng-container>

  <!-- Number Of Rating Column -->
  <ng-container matColumnDef="NumRating">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="numRating"> # Ratings </th>
    <td mat-cell *matCellDef="let element"> {{element.numRating}} </td>
  </ng-container>

  <!-- Date Rated Column -->
  <ng-container matColumnDef="LastRatedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="lastRatedAt"> Last Rated </th>
    <td mat-cell *matCellDef="let element"> {{element.lastRatedAt}} days ago </td>
  </ng-container>

  <!-- Date Updated Column -->
  <ng-container matColumnDef="LastUpdatedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="lastUpdatedAt"> Last Updated </th>
    <td mat-cell *matCellDef="let element"> {{element.lastUpdatedAt}} hours ago</td>
  </ng-container>

  <!-- Bounce Rate Column -->
  <ng-container matColumnDef="BounceRate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="bounceRate"> Bounce Rate </th>
    <td mat-cell *matCellDef="let element"> {{element.bounceRate}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="onhovercls"
    [routerLink]="['/central/doctor/view',element.id]"></tr>
</table>