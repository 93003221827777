import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { HttpService, SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable()
export class DoctorDataService extends HttpService {
  public apiUrl: string = environment.serverUrl;
  private getallcampaingurl = this.apiUrl + 'campaign/user/';
  private preactivateUrl = this.apiUrl + 'patient/register/bydoctor';
  private deeplinkUrl = this.apiUrl + 'patient/register/qrdeeplink';
  private docUrl = this.apiUrl + 'doctor/';
  private prodUrl = this.apiUrl + 'doctor/user/diary';
  private presUrl = this.apiUrl + 'utilities/prescriptions';
  private targetUrl = this.apiUrl + 'utilities/targets';
  private eligibleLog = this.apiUrl + 'users/logs';
  private profileUrl = this.apiUrl + 'doctor/user';
  private starunstalUrl = this.apiUrl + 'doctor/notifications/starPatient';
  private prodDocUrl = this.apiUrl + 'doctor/';
  private markreadurl = this.apiUrl + 'doctor/notifications/read/';
  private userListAPI = environment.cueSocketUrl + 'metadata/user/patients';
  public registerToken: any = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQzMjgsImlhdCI6MTU2Mjc0OTc1NX0.QgIXPnmtuzkrpIswdSUKpULdwNYB1Q4UAK4G2eXXln8';

  //   private prodUrl = this.apiUrl + 'doctor/user/diary';
  //   private presUrl = this.apiUrl + 'utilities/prescriptions';
  //   private targetUrl = this.apiUrl + 'utilities/targets';
  constructor(private http: HttpClient, private systemService: SystemService) {
    super(http)
  }

  /**v3.0 API for the graph */

  updatePassword(id, data): Observable<string> {
    return this.patch(this.apiUrl + 'users/update/passsword/normal/' + id, data)
  }

  slaAgreement(id): Observable<string> {
    let bodydata = {
      user_data_fk: id,
      tc_accepted: 'true',
      user_id_data_fk:id
    }
    return this.patch(this.apiUrl + 'users/accept/tandc', bodydata)
  }

  public followupPatient(patientid, doctorid, followup) {
    let bodydata = {
      patient_data_fk: patientid,
      user_data_fk: doctorid,
      do_follow_up: followup,
    };
    return this.post(this.apiUrl + 'patient/mapping/followup', bodydata)
  }

  getDoctorReport(start_date, end_date, patient_id): Observable<string> {
    let bodydata = {
      start_date: start_date,
      end_date: end_date,
      patient_id_data_fk: patient_id,
    }
    return this.post(this.apiUrl + 'user/report', bodydata)
  }
///ferrer report
getDoctorFerrerReport(start_date, end_date, patient_id): Observable<string> {
  let bodydata = {
    start_date: start_date,
    end_date: end_date,
    patient_id_data_fk: patient_id,
  }
  return this.post(this.apiUrl + 'user/ferrer/report', bodydata)
}

getDoctorAbbottReport(start_date, end_date, patient_id): Observable<string> {
  let bodydata = {
    start_date: start_date,
    end_date: end_date,
    patient_id_data_fk: patient_id,
  }
  return this.post(this.apiUrl + 'user/abbott/report', bodydata)
}



  public getPatientFilteredDiaryData(type, startdate, enddate, range, patientid, sub_type, blood_sugar_log_type): Observable<string> {
    let bodydata = {
      start_date: startdate,
      end_date: enddate,
      range_type: range,
      log_type: type,
      patient_id_data_fk: patientid,
      sub_type: sub_type,
      blood_sugar_log_type: blood_sugar_log_type
    };
    return this.post(this.apiUrl + 'diary/getGraphs', bodydata);
  }

  public getPatientProfile(patientid) {
    let bodydata = {};
    return this.post(this.apiUrl + 'patient/complete/' + patientid, bodydata);
  }

  public getPatientHCNotes(userID): Observable<string> {
    let body = {
      "q": "",
      "patient_data_fk": userID.toString(),
      "limit": "20",
      "page": "1",
      "type": "doctor"
    }
    return this.post(this.apiUrl + 'patient/note/search', body);
  }
  getPatientDiaryData(userid): Observable<string> {
    return this.get(this.apiUrl + 'doctor/user/diary');
  }

  public getEligibleLogs(userID: string): Observable<any> {
    return this.get(this.apiUrl + 'priority/mapping/log/byPatient/' + userID);
  }

  public getUserProfileDetails(userID: string): Observable<any> {
    return this.post(this.apiUrl + 'patient/complete/' + userID, {});
  }

  public getPatientDiary(id, selected_option, start_d, end_d): Observable<string> {

    let bodydata = {
      start_date: start_d,
      end_date: end_d,
      patient_id_data_fk: id
    };
    return this.post(this.apiUrl + 'diary/data/' + selected_option, bodydata);
  }

  getAllCampaign(): Observable<string> {
    var doctorID = localStorage.getItem('scarletuserid');
    const endPoint = doctorID;
    return this.get(this.getallcampaingurl + endPoint);
  }

  deeplinkAndQr(data, type): Observable<string> {
    var datap = {
      "activated_by": type,
      "user_code": JSON.parse(localStorage.getItem('scarletuserdata')).code,
      "phone": data.phone,
      "country_code": data.country_code,
      "campaign_id_fk": data.condition,
      "language_data_fk":data.language_data_fk
    }
    return this.post(this.deeplinkUrl, datap)
  }

  preActiveUser(data): Observable<string> {
    var body = {
      "campaign_id_fk": data.condition,
      "user_code": JSON.parse(localStorage.getItem('scarletuserdata')).code,
      "language_data_fk": data.language_data_fk
    }
    const options = {headers: {'phone': data.phone,'country-code':data.country_code}};
    // let headers = new Headers();
    // headers.append('phone', data.phone);
    // headers.append('country-code', data.country_code);
    // let options = new RequestOptions({ headers: headers });
    return this.post(this.preactivateUrl, body, options)
  }

  getUserData(userid): Observable<string> {
    let headers = new Headers();
    headers.append('userID', userid);
    let options = new RequestOptions({ headers: headers });
    return this.get(this.prodUrl, options)

  }

  getPrescriptionData(userid): Observable<string> {
    let headers = new Headers();
    headers.append('userID', userid);
    let options = new RequestOptions({ headers: headers });
    return this.get(this.presUrl, options)
  }

  getUserTargetData(userid): Observable<string> {
    let headers = new Headers();
    headers.append('userID', userid);
    let options = new RequestOptions({ headers: headers });
    return this.get(this.targetUrl, options)
  }
  getPeakFlowGraphData(start_date, end_date, patient_id_data_fk): Observable<string> {

    let bodydata = {
      start_date: start_date,
      end_date: end_date,
      patient_id_data_fk: patient_id_data_fk.toString()
    };
    return this.post(this.apiUrl + 'diary/peakFlow/graph', bodydata);
  }

  updateToBladePassword(id, data, service): Observable<string> {
    return this.patch(this.apiUrl + 'doctor/' + id, data)
  }

  getAllUsers(): Observable<string> {
    var doctorID = localStorage.getItem('scarletparentuserid');
    const endPoint = 'user/getAll';
    let headers = new Headers();
    headers.append('user_id', doctorID);
    let options = new RequestOptions({ headers: headers });
    return this.get(this.prodUrl + endPoint, options)

  }

  public getPatientsByDoctor(query, status, page, limit): Observable<any> {
    query = this.removeHTMLtags(query);
    var doctorID = localStorage.getItem('scarletuserid');
    const bodydata: any = {
      doctor_id: doctorID,
      q: query,
      status: status,
      page: page,
      limit: limit
    };
    if(environment.org)
    {
        bodydata['organization_data'] = this.systemService.getorg()
    }

    const endPoint = 'patient/mapping/search';
    return this.post(this.apiUrl + endPoint, bodydata);

  }
  getEligibalLogs(userid): Observable<string> {
    let headers = new Headers();
    headers.append('userID', userid);
    let options = new RequestOptions({ headers: headers });
    return this.get(this.eligibleLog, options);
  }

  getOneUserData(userid): Observable<string> {
    var SessionID = localStorage.getItem('scarlettoken');
    if (SessionID || SessionID !== '') {
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      // headers.append('id', doctorID);
      headers.append('userID', userid);

      let options = new RequestOptions({ headers: headers });
      return this.get(this.profileUrl, options);

    }
  }

  updateAgreement(): Observable<[string]> {
    var doctorID = localStorage.getItem('scarletparentuserid');

    const endPoint = 'updateagreementstatus';
    let headers = new Headers();
    headers.append('id', doctorID);
    let options = new RequestOptions({ headers: headers });
    return this.put(this.prodUrl + endPoint, {}, options);

  }

  updateDocPassword(newpassword): Observable<[string]> {
    var doctorID = localStorage.getItem('scarletuserid');
    const endPoint = 'account/password/reset';
    let headers = new Headers();
    headers.append('user_id', doctorID);
    headers.append('new_password', newpassword);
    let options = new RequestOptions({ headers: headers });
    return this.put(this.apiUrl + endPoint, {}, options)
  }

  followUp(user_ids: any, needs_followup: boolean): Observable<[string]> {
    const endPoint = 'user/followup';
    return this.put(this.prodUrl + endPoint, { user_ids: user_ids, needs_followup: needs_followup })

  }

  public getAllNotifications(typetext, page, limit, status, types, filters): Observable<any> {
    var uid = localStorage.getItem('scarletuserid');
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      types: types,
      filters: filters
    };
    if(environment.org)
    {
        bodydata['organization_data'] = this.systemService.getorg()
    }
    return this.post(this.docUrl + 'notifications/search/filter/' + uid, bodydata);
  }
  getAlerts(counter): Observable<string> {
    const endPoint = 'alerts';
    let headers = new Headers();
    headers.append('count', counter);
    let options = new RequestOptions({ headers: headers });
    // Return response
    return this.get(endPoint, options)
  }

  public starunstar(id, value): Observable<any> {
    let bodydata = {
      "patient_list": '[' + id + ']',
      "action": value
    }
    return this.http.post<any>(this.starunstalUrl, bodydata);
  }

  public markallread(id): Observable<any> {
    let bodydata = {
      "patient_id": id
    }
    return this.http
      .patch<any>(this.markreadurl + localStorage.getItem('scarletuserid'), bodydata);
  }

  markAsRead(ide): Observable<string> {
    const endPoint = 'alerts';
    let headers = new Headers();
    headers.append('alert_id', ide);
    let options = new RequestOptions({ headers: headers });
    return this.put(this.prodUrl + endPoint, {}, options)
  }

  markAllAsRead(): Observable<string> {
    const endPoint = 'alerts';
    let headers = new Headers();
    let options = new RequestOptions({ headers: headers });
    return this.put(this.prodUrl + endPoint, {}, options)
  }

  public getUserList(id): Observable<any> {
    let bodydata = {
      "filters": [{
        "filter_type": "patientIdFilter",
        "value": id
      }],
      "pageNumber": 1,
      "userId": +JSON.parse(localStorage.getItem('scarletuserdata')).id,
      "query_string": ""
    }
    let headers = new HttpHeaders().set('token', this.registerToken);
    return this.post(this.userListAPI, bodydata, { headers: headers });
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
  
  getAge(dateString) {
    let fulldate = dateString
    dateString = formatDate(dateString, 'dd/MM/yyyy', 'en-US');
    let  now = new Date();
    let  yearNow = now.getFullYear();
    let monthNow = now.getMonth() + 1;
    let dateNow = now.getDate();
  
    var dob = new Date(fulldate);
  
    let  yearDob = dob.getFullYear();
    let  monthDob = dob.getMonth()+ 1 ;
    let  dateDob = dob.getDate();
    let  age;
    let  ageString = "";
    let  yearString = "";
    let  monthString = "";
    let  dayString = "";
    let monthAge ;
  
    let yearAge = yearNow - yearDob;
  
    if (monthNow >= monthDob)
    monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }
  
    if (dateNow >= dateDob)
      var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;
  
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
  
     age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
        };
  
    if ( age.years > 1 ) yearString = " years";
    else yearString = " year";
    if ( age.months> 1 ) monthString = "m";
    else monthString = "m";
    if ( age.days > 1 ) dayString = " d";
    else dayString = " d";
  
  
    if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
      ageString = age.years + yearString +" "+ age.months + monthString;
    else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
      ageString = "Only " + age.days + dayString;
    else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
      ageString = age.years + yearString;
    else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
      ageString = age.years + yearString +" "+ age.months + monthString ;
    else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
      ageString = age.months + monthString + age.days + dayString;
    else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
      ageString = age.years + yearString;
    else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
      ageString = age.months + monthString;
    else ageString = "";
  
    return ageString;
  }
  
  public getPatientListbyHospitalId(id,page): Observable<any> {
    let body = new HttpParams()
    .set('hospital_id', id)
    .set('page',page)
    .set("limit","20")
    .set("status",'all')
    .set("q","")
    return this.post(this.apiUrl + 'patient/mapping/hospital/search',body)
  }

}
