import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-change-password-history',
  templateUrl: './change-password-history.component.html',
  styleUrls: ['./change-password-history.component.scss']
})
export class ChangePasswordHistoryComponent implements OnInit {
  userid: any;
  public searchcriteria: any;
  public showloader: any;
  lastSearch: any;
  status: any = status;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any = [];
  filterdata: any = [];
  public emptyplaceholder: boolean = false;
  dataSource: any;
  tabledata: any;
  displayedColumns: string[] = ['userId', 'changedVia', 'changedBy', 'Date'];
  emaildisplayedColumns: string[] = ['userId', 'uuid', 'is_used', 'CreatedDate','validtill'];

  public userName: string;
  UserType: any;
  sort: MatSort = new MatSort;
  historyType: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePasswordHistoryComponent>, private commonService: CommonserviceService, private systemService: SystemService) { }


  ngOnInit(): void {

    this.userid = this.data.element.id;
    this.userName = this.data.element.name;
    this.searchcriteria = "";
    this.page = 1;
    this.UserType = localStorage.getItem('userType');
    this.historyType = this.data.history;
    if(this.historyType === 'password'){
      this.getPasswordHistory(this.userid);
    }else if(this.historyType === 'email'){
      this.getEmailHistory(this.userid);
    }
  }

  getEmailHistory(userid: any) {
    this.commonService.GetPreviousEmailsSentToDoctor(userid)
      .subscribe(
        (res: any) => this.onPasswordChangeHistorySuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  convertDate(date) {
    var d = new Date(date);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()  
    return currentdate + ' ' + month + ' ' + year + ',' + d.toLocaleTimeString();;

  }

  getPasswordHistory(id) {

    let data1: any = {}
    data1.user_id_fk = this.data.element.id;
    this.commonService.getPasswordHistory(data1)
      .subscribe(
        (res: any) => this.onPasswordChangeHistorySuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )

  }

  private onPasswordChangeHistorySuccess(data) {
    this.tabledata = data;
    if (this.tabledata.data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
        this.dataSource.sort = this.sort;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = this.tabledata.data;
      }
      else {
        for (var k = 0; k < this.tabledata.data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }
}