import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  public systemHaveAlerts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public docSystemHaveAlerts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showSelectedNotificationUser: BehaviorSubject<any> = new BehaviorSubject<string>(null);
  constructor() { }
}
