import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from '../../../shared/shared.module';

import { ContextService as HealthCoachContextService } from '../../services/context.service';
import { ActivityComponent } from '../tabpartials/activity/activity.component';
import { ActivityModule } from '../tabpartials/activity/activity.module';
import { dairyModule } from '../tabpartials/diary/dairy.module';
import { DiaryComponent } from '../tabpartials/diary/diary.component';
import { knowUserModule } from '../tabpartials/knowuser/knowuser.module';
import { LabReportModule } from '../tabpartials/labreports/labreports.module';
import { prescriptionsModule } from '../tabpartials/prescriptions/prescriptions.module';
import { ProgramDetailModule } from '../tabpartials/programdetail/programdetail.module';
import { ProgramProgressModule } from '../tabpartials/programprogress/programprogress.module';
import { TargetsModule } from '../tabpartials/targets/targets.module';
import { TasksModule } from '../tabpartials/tasks/tasks.module';
import { UserDetailModule } from '../tabpartials/userdetail/userdetail.module';
import { UserdetailsectionComponent } from './userdetailsection.component';

@NgModule({
  declarations: [
    UserdetailsectionComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    
    SharedModule.forRoot(),
    UserDetailModule,
    ActivityModule,
    knowUserModule,
    ProgramDetailModule,
    ProgramProgressModule,
    dairyModule,
    LabReportModule,
    prescriptionsModule,
    TasksModule

  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    UserdetailsectionComponent
  ]
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class userdetailsectionModule { }
