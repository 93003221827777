<mat-drawer-container class="sidenav-container" autosize>

  <app-docleftnav></app-docleftnav>

  <div class="sidenav-content">
    <div class="col-md-12 topalertbar">
      <div class="col-md-12">
        <div class="clearfix">
          <h3 class="alerttitle pull-left">
            <span class="notranslate">
              {{'Doctor.Notifications' | translate}}
            </span>
            <!-- Notifications -->
          </h3>
        </div>

        <!-- <div class="show-notification-count" *ngIf="notificationdataarray.length > 0" style="float:left;font-size: 13px;font-weight: 200;color: #1F2B36;margin-top: 5px;">Showing {{notificationdataarray.length}} of {{getCount()}}</div> -->
      </div>
      <div class="clearfix hidden-xs"><br /><br /><br /><br /></div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2 col-xs-12 col-sm-12">
            <mat-select [(ngModel)]="typeFilters" placeholder="Filter" multiple class="sortcommonwhite"
              (selectionChange)="typeFilter($event.value)">
              <mat-option *ngFor="let types of notificationtypes" value="{{types[0]}}">{{types[1]}}</mat-option>
            </mat-select>
          </div>
          <div class="col-md-10 col-xs-12 col-sm-12">

            <form>
              <div class="form-group has-feedback has-search">
                
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12">
            <div class="list-filter table-filter-btn">
              <ul class="list-inline table-filter-holder">
                <li>
                  <a (click)="tableSort(null,'all')" [class]="notiftype == 'all' ? 'bold all': 'all'">
                    <span class="notranslate">
                      {{'Doctor.All' | translate}}
                    </span>
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('has_seen=false','unread')" [class]="notiftype == 'unread'? 'bold all': 'all'">
                    <span class="notranslate">
                      {{'Doctor.Unread' | translate}}
                    </span>
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('has_seen=true','read')" [class]="notiftype == 'read'? 'bold all': 'all'">
                    <span class="notranslate">
                      {{'Doctor.Read' | translate}}
                    </span>
                    <!-- Read -->
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('patient_starred=true','star')"
                    [class]="notiftype == 'star'? 'bold all': 'all'">
                    <span class="notranslate">
                      {{'Doctor.Starred' | translate}}
                    </span>
                    <!-- Starred -->
                  </a>
                </li>
                <li>
                  <a (click)="tableSort('patient_starred=false','unstar')"
                    [class]="notiftype == 'unstar'? 'bold all': 'all'">
                    <span class="notranslate">
                      {{'Doctor.Unstarred' | translate}}
                    </span>
                    <!-- Unstarred -->
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-12 scroll-panel" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
        (scrolled)="getNextPage()" [scrollWindow]="false">
        <div class="notification-panel">
          <mat-card *ngFor="let data of tabulardata;let i = index;" style="margin-bottom: 10px;"
            id="{{data?.patient_id_data_fk}}">
            <div class="clearfix">
              <mat-card-title class="pull-left">{{data?.name}}</mat-card-title>
              <div class="pull-right">
                <ul class="list-inline">
                  <li>
                    <i (click)="starfunc(data?.patient_id_data_fk, i, data?.patient_starred)"
                      class="material-icons more-vert">{{data?.patient_starred ? 'star' : 'star_outline'}}</i>
                  </li>
                  <li>
                    <div class="more-vert" [matMenuTriggerFor]="menu"><i class="material-icons">more_vert</i></div>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="markallread(data?.patient_id_data_fk)">Mark All Read</button>

                    </mat-menu>
                  </li>
                </ul>
              </div>
            </div>
            <div class="snack-wrapper">
              <div class="snack-holder" *ngFor="let notif of data?.notifications.slice(0, 3);">
                <div class="content">
                  <img [src]="'assets/img/notification_coloured_icons/'+(notif?.type)+'.svg'" alt=""
                    [class]="notif?.has_seen ? 'img-responsive hasseencss' : 'img-responsive'">
                  <h6 (click)="notificationVerify(notif?.type,data?.name,notif?.value,notif?.created_at,notif?.value,notif?.patient_id_data_fk)">{{setstatus(notif?.type, notif?.value)}}</h6>
                </div>
                <div class="content content-second">
                  <p>{{covertTime(notif?.created_at)| date:'MMM d, h:mm a'}}</p>
                </div>
              </div>
              <div class="snack-holder" *ngIf="data?.notifications[3]">
                <button mat-stroked-button (click)="openChatOption(data?.patient_id_data_fk)">View All</button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <ng-template #pageLoadingLoader>
        <app-loader showLoader="{{true}}"></app-loader>
      </ng-template>
    </div>
  </div>
</mat-drawer-container>