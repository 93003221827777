<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/magazinehome/magazineedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Magazine
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Sub Title</mat-label>
                                    <input matInput placeholder="Enter the sub title" formControlName="subtitle">
                                </mat-form-field>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield ">
                                <mat-label>Select Category</mat-label>
                                <mat-select formControlName="type_data_fk" #singleSelect>
                                    <!-- <mat-option>
                                        <ngx-mat-select-search class="tagcat" [clearSearchInput]="false"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmatcat" ngModel (ngModelChange)="modelChangedCat()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i>
                                            </div>
                                        </ngx-mat-select-search>
                                    </mat-option> -->
                                    <mat-option *ngFor="let cat of filteredCatData | async" [value]="cat.id">
                                        {{cat.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Primary Condition</mat-label>
                                    <mat-select formControlName="comorbilities" required multiple #singleSelectTag>
                                        <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                            [value]="condition.id">
                                            {{condition.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="clearfix"><br /></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <p (click)="openinstructions()" class="instruction-p">
                                        Instructions for editor
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-md-12">
                                            <mat-label>Text</mat-label>
                                            <textarea matInput placeholder="Enter the text"
                                                formControlName="raw_content"
                                                (ngModelChange)="additionalTextChange($event)" rows="25"
                                                (keyup)="uirender()" required></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div id="markdown-editor">

                                        <fieldset [disabled]="disabled">
                                            <md-editor formControlName="additionaltext" name="content"
                                                [upload]="doUpload" [preRender]="preRenderFunc" [height]="'410px'"
                                                mode="editor" [options]="options" required class="mdeditorcss">
                                            </md-editor>
                                        </fieldset>

                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                    <mat-label>Select Featured Image</mat-label>
                                    <input matInput placeholder="Select Featured Image" (click)="openmediabox()"
                                        formControlName="image_media" required readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Tags</mat-label>
                                    <mat-select formControlName="tags" required multiple #singleSelectTag>
                                        <mat-option>
                                            <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                                [placeholderLabel]="'Search Item'"
                                                [noEntriesFoundLabel]="'No matching records found'"
                                                [formControl]="searchmattag" ngModel
                                                (ngModelChange)="modelChangedTags()" ngDefaultControl>
                                                <div class="mat-select-search-custom-header-content notranslate">
                                                    <i class="material-icons">
                                                        search
                                                    </i></div>
                                            </ngx-mat-select-search>
                                        </mat-option>

                                        <mat-option class="camelcased select-tags-active"
                                            *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                            {{tag.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Time to Finish(In seconds)</mat-label>
                                <input type="number" matInput placeholder="Time to Finish(In seconds)"
                                    formControlName="time_to_finish" min="0" oninput="validity.valid||(value='');"
                                    required>
                                    <mat-error>
                                        Cannot be greater than 3600 sec
                                    </mat-error>
                            </mat-form-field>

                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>OG:Title</mat-label>
                                    <input matInput placeholder="Enter a title for SEO" formControlName="og_title">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>OG:Description</mat-label>
                                    <input matInput placeholder="Enter a description for SEO"
                                        formControlName="og_description">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>OG: Keywords</mat-label>
                                    <input matInput placeholder="Enter a keywords for SEO"
                                        formControlName="og_keywords">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>OG: Author</mat-label>
                                    <input matInput placeholder="Enter a author for SEO" formControlName="og_author">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Author</mat-label>
                                    <mat-select formControlName="author_data" required>
                                        <mat-option class="text-capitalize" *ngFor="let user of allusers"
                                            [value]="user.id">
                                            {{user.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button mat-flat-button color="primary" class="centerbtn btn-left"
                            [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Magazine</button>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <img class="img-responsive" *ngIf="allmedia" [src]="s3url + allmedia" />
                        <div class="content-inside">
                            <div class="iphonetitle">{{dataForm?.value?.title}}</div>
                            <div class="iphonesubtitle">{{dataForm?.value?.subtitle}}</div>
                            <div [innerHTML]="renderhtml" class="maincontent maincontent-magazine"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>