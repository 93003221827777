<mat-drawer #drawer class="sidenav" mode="side" opened>
    <div class="smallnavigator">
        <div class="logo-holder">
            <a [routerLink]="['/admin/settings']" routerLinkActive="active" class="hcprofile"><img
                    [src]="s3url + localStoredData.profile_image.path" class="hclogo center-block" /></a>
        </div>


        <ul class="alloption" *ngIf="adminClient === null">
            <li>
                <a [routerLink]="['/admin/hchome']" routerLinkActive="active" [ngClass]="activeRouteConditions()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a hcassign"></div>
                    <div class="name">HC Assign</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/admin/users']" routerLinkActive="active" [ngClass]="activeRouteAdmin()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a hcadmin"></div>
                    <div class="name">Admin</div>
                    <div class="notification-icon-wrapper" *ngIf="hasPhoneNumberRequest">
                        <i class="material-icons whiteicon">
                            fiber_manual_record
                        </i>
                    </div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/admin/payment']" routerLinkActive="active" [ngClass]="activeRoutePayment()"
                    [routerLinkActiveOptions]="{exact:false}"
                    *ngIf="!localStoredData?.organization_data?.scarlet_acl_restriction?.includes('payment')">
                    <div class="container-a payment"></div>
                    <div class="name">Payment</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/admin/taskrequest']" routerLinkActive="active" [ngClass]="activeRouteTaskRequest()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a request"></div>
                    <div class="name">Requests</div>
                </a>
            </li>
            <li>
                <a [routerLink]="['/admin/tasklibrary']" routerLinkActive="active" [ngClass]="activeRouteTask()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a tasklibrary"></div>
                    <div class="name">Library</div>
                </a>
            </li>
        </ul>
        <!-- ferrer and ciple doctor admin icons -->
        <ul class="alloption" *ngIf="adminClient === 9">
            <li>
                <a [routerLink]="['/admin/client-doctors']" routerLinkActive="active" [ngClass]="activeRouteTaskRequest()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a doctor"></div>
                    <div class="name">Doctor list</div>
                </a>
            </li>
            <!-- <li>
                <a [routerLink]="['/admin/hospital/list']" routerLinkActive="active" [ngClass]="activeRouteTaskRequest()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a hospital"></div>
                    <div class="name">Hospital List</div>
                </a>
            </li> -->
            <!-- <li>
                <a [routerLink]="['/admin/tasklibrary']" routerLinkActive="active" [ngClass]="activeRouteTask()"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="container-a tasklibrary"></div>
                    <div class="name">Library</div>
                </a>
            </li> -->
        </ul>

        <div class="bottom-menu">
            <a [routerLink]="['/application']" routerLinkActive="router-link-active">
                <img src="assets/img/dls/icons/switch-dashboard.svg" alt="">
                <!-- <div>Switch</div> -->
            </a>
        </div>
    </div>
</mat-drawer>