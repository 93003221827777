import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { Userdetailsinterface } from '../../../models/userdetailsinterface';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';

@Component({
  selector: 'app-programdetail',
  templateUrl: './programdetail.component.html',
  styleUrls: ['./programdetail.component.scss']
})

export class ProgramdetailComponent implements OnInit {

  public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public programDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public loadingSubject = new BehaviorSubject<boolean>(false);
  private tabID: number = 3;
  public subscribeObj: any;
  public subscribeObj1: any;
  public tagData: any = {};
  public userTags: any;
  public searchcriteria: any;
  lastSearch: any;
  public page: number = 1;
  public lastpage: number = 0;
  public onit: boolean = true;
  public endoflist: boolean = false;
  public showloader: any;
  public doctorForm = this.fb.group({
    doctorCode: ["", Validators.required]
  });
  public isactive = 'active';
  public status = 'active';
  PrimaryCondition: any;
  editableEdd: boolean = false;
  editableLmp: boolean = false;
  editableDeliveryOutCome: boolean = false;
  editableDeliveryDate: boolean = false;

  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
  });

  public lmpdataForm = this.fb.group({
    startdate: ['', Validators.required],
  });
  public deliverydateForm = this.fb.group({
    startdate: ['', Validators.required],
  });
  public deliveryForm = this.fb.group({
    deliveryOutCome: ['', Validators.required],
  });
  public todaydate: any = new Date();
  womens_health_data: any;
  eddValue: any;
  lmpValue: any;
  deliveryOutputValue: any;
  deliveryDateValue: any;
  gaValue: any;
  currentTime = new Date();

  EddminDate = new Date();
  EddmaxDate = new Date(this.EddminDate.getFullYear(), this.currentTime.getMonth() +9, 1);

  LmpmaxDate = new Date();
  LmpminDate = new Date(this.LmpmaxDate.getFullYear(), this.currentTime.getMonth(), 11, 1);
  profile_flags: boolean=false;



  constructor(
    private systemService: SystemService, public commonService: CommonserviceService, private appContext: ContextService, private userDetailService: UserdetailsService, private fb: FormBuilder) { }

  ngOnInit() {
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.initComponentDraw(this.appContext.selectedChatUser.getValue());
      }
    });
    this.subscribeObj1 = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.initComponentDraw1(this.appContext.selectedChatUser.getValue());
      }
    });
  }

  initComponentDraw(selectedUserID: string) {
    this.loadingSubject$.next(true);
    /**Reinitiliase program detail subject */
    this.programDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**API Call */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      data = data == null ? [] : data;
      this.profile_flags = data.profile_flags.includes("WHM");
      this.programDetailsSubject$.next(data);
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
    });
  }

  initComponentDraw1(selectedUserID: string) {
    this.tagData = {};
    this.userTags = [];
    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**Call API */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      this.womens_health_data = data.womens_health_data;
      this.eddValue = this.womens_health_data?.edd ? this.womens_health_data?.edd : 'NA'
      this.lmpValue = this.womens_health_data?.lmp ? this.womens_health_data?.lmp : 'NA'
      this.deliveryOutputValue = this.womens_health_data?.delivery_output ? this.womens_health_data?.delivery_output : 'NA'
      this.deliveryDateValue = this.womens_health_data?.delivery_date ? this.womens_health_data?.delivery_date : 'NA'
      this.gaValue = (this.womens_health_data?.ga !== ' weeks days' && this.womens_health_data?.ga ) ? this.womens_health_data?.ga : 'NA'
      this.appContext.gestationalAge.next(this.gaValue);
      this.searchcriteria = data.phone;
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllPatients(this.searchcriteria, this.page.toString(), '20', this.status, 'hcassignment')
        .subscribe(
          (res: any) => this.onPatientListSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )


    });
  }
  private onPatientListSuccess(data) {
    // 
    data = data.data;

    this.PrimaryCondition = data[0].master_condition_data.name;

  }
  getstyle(a, b) {

    if (a != null) {
      var k = a.split(',');
      if (k.includes(b.toString())) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }
  ngOnDestroy(): void {
    this.subscribeObj.unsubscribe();
  }
  editEdd(type: any) {
    if (type === 'edd') {
      this.editableEdd = true;
      this.editableLmp = false;
      this.editableDeliveryOutCome = false;
      this.editableDeliveryDate = false;
    
    }
    if (type === 'lmp') {
      this.editableLmp = true;
      this.editableEdd = false;
      this.editableDeliveryOutCome = false;
      this.editableDeliveryDate = false;
   
    }
    if (type === 'delivery_outcome') {
      this.editableDeliveryOutCome = true;
      this.editableLmp = false;
      this.editableEdd = false;
      this.editableDeliveryDate = false;
     
    }
    if (type === 'delivery_date') {
      this.editableDeliveryDate = true;
      this.editableDeliveryOutCome = false;
      this.editableLmp = false;
      this.editableEdd = false;
      
    }
  }
  cancelEdit(type: any) {
    if (type === 'edd') {
      this.editableEdd = false;
      this.dataForm.reset();
    }
    if (type === 'lmp') {
      this.editableLmp = false;
      this.lmpdataForm.reset();
    }
    if (type === 'delivery_outcome') {
      this.editableDeliveryOutCome = false;
      this.deliveryForm.reset();
    }
    if (type === 'delivery_date') {
      this.editableDeliveryDate = false;
      this.deliverydateForm.reset();
    }
  }
  submitEdd(value: any) {
    this.editableEdd = false;
    this.updateGestationalDetails('edd',value.startdate)
  }
  submitLmp(value: any) {
    this.editableLmp = false;
    this.updateGestationalDetails('lmp', value.startdate)
  }
  submitDeliveryOutcome() {
    this.editableDeliveryOutCome = false;
    this.updateGestationalDetails('delivery_outcome', this.deliveryForm.value.deliveryOutCome)
  }
  submitDeliveryDate(value: any) {
    this.editableDeliveryDate = false;
    this.updateGestationalDetails('delivery_date',  value.startdate)
  }
  updateGestationalDetails(type:any,value:any) {
    let patient_id_fk = this.appContext.selectedChatUser.getValue().toString();
    let scarletId = localStorage.getItem('scarletuserid');
    let edd;
    let lmp;
    let delivery_date;
    let delivery_output
    if (type === 'edd') {
      edd = value
    }
    if (type === 'lmp') {
      lmp = value
    }
    if (type === 'delivery_outcome') {
      delivery_output = value
    }
    if (type === 'delivery_date') {
      delivery_date = value
    }
    this.userDetailService.updateGestationalDetails(patient_id_fk, scarletId, edd, lmp, delivery_date, delivery_output).subscribe(data => {
    let object = (data.data);
      this.eddValue = object.edd ?object?.edd : 'NA'
      this.lmpValue = object?.lmp ?object?.lmp : 'NA'
      this.deliveryOutputValue = object?.delivery_output ? object?.delivery_output : 'NA'
      this.deliveryDateValue =object?.delivery_date ? object?.delivery_date : 'NA'
      this.gaValue = (object?.ga !== ' weeks days') ? object?.ga : 'NA'
      if(this.gaValue === 'NA'){
        this.appContext.gestationalAge.next(null);
      }else{
        this.appContext.gestationalAge.next(this.gaValue);
      }
      this.dataForm.reset();
      
    });
  }

}
