import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-approve-dialog',
  templateUrl: './approve-dialog.component.html',
  styleUrls: ['./approve-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,

})

export class ApproveDialogComponent implements OnInit {
  statustitle: any = "Approve";
  showerror: any = null;
  public requestForm = this.fb.group({
    comment: ["", Validators.required]
  })

  constructor(private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, public fb: FormBuilder, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Publish Request Approve');
  }

  ngOnInit(): void {
    this.setSEOTags();
    this.statustitle = this.data.approved ? 'Reject' : 'Approve';
  }


  // To Approve Request
  // ----------------------------

  approval() {
    let comment = this.commonService.isHTML(this.requestForm.value.comment);
    if(comment  === true){
      this.requestForm.value.comment.patchValue('')
      return;
    }
    this.commonService.approveRequest(this.data.approved, this.data.id, localStorage.getItem('scarletuserid').toString(), this.requestForm.value.comment)
      .subscribe(
        (res: any) => this.onSuccessApprove(),
        (err: HttpErrorResponse) => this.onErrorResponse(err),
        // (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  onErrorResponse(err) {
    
    if(err.error.message == "No matching record found for level."){
      this.systemService.showSnackBar(("No matching record found for level."), "OK");
    }else{
      this.showerror = JSON.parse(err.error.message);
    }

  }

  private onSuccessApprove() {
    this.dialog.closeAll();
    this.router.navigate(['admin/publishrequests']);
    var stat = "Approved";
    if (this.data.approved == true) {
      stat = 'Rejected';
    }
    this.systemService.showSnackBar("Publish Request has been " + stat, "OK");
  }

  // ----------------------------

}
