import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppMasterComponent } from './app-master.component';
import { UpdateValComponent } from './update-val/update-val.component';
import { AdminGuardGuard } from './../../shared/guards';

const routes: Routes = [
  { path: '', component: AppMasterComponent, canActivate: [AdminGuardGuard] },
  { path: 'updateappmasterval', component: UpdateValComponent, canActivate: [AdminGuardGuard] },
  
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppMasterRoutingModule {}