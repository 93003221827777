import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../services/commonservice/commonservice.service';
import { UpdateValComponent } from './update-val/update-val.component';

@Component({
  selector: 'app-app-master',
  templateUrl: './app-master.component.html',
  styleUrls: ['./app-master.component.scss']
})

export class AppMasterComponent implements OnInit {
  public showButtonLoader: boolean = false;
  public showloader: any;
  appMasterData: any;
  serverdata: any;
  serverDowndata: any;
  wellthyPhoneNumberdata: any;
  wellthyEmaildata: any;
  iosminapp: any;
  ioscurrentapp: any;
  androidminapp: any;
  androidcurrentapp: any;

  constructor(private browserTitle: Title, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog) { }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | App Master');
  }

  ngOnInit(): void {
    this.setSEOTags();
    this.search();
  }

  OpenDialog(element): void {
    const profile = this.dialog.open(UpdateValComponent, {
      width: '400px',
      data: {
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
      this.search()
    });
  }


  // APP Master List 
  // ----------------------------

  search() {
    this.commonService.getAppMasterData()
    .subscribe(
      (res: any) => this.getAppMasterSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getAppMasterSuccess(data) {
    this.appMasterData = data;
    for (var i = 0; i <= this.appMasterData.length; i++) {
      if (this.appMasterData[i] && this.appMasterData[i].key == 'SERVER_VERSION') {
        this.serverdata = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'IS_SERVER_DOWN') {
        this.serverDowndata = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'WELLTHY_DEFAULT_PHONE_NUMBER') {
        this.wellthyPhoneNumberdata = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'WELLTHY_DEFAULT_EMAIL_ID') {
        this.wellthyEmaildata = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'IOS_MIN_APP_VERSION') {
        this.iosminapp = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'IOS_CURRENT_APP_VERSION') {
        this.ioscurrentapp = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'ANDROID_MIN_APP_VERSION') {
        this.androidminapp = this.appMasterData[i];
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'ANDROID_CURRENT_APP_VERSION') {
        this.androidcurrentapp = this.appMasterData[i];
      }
    }
  }

  // ----------------------------

  convertData(d) {
    return d.replace(/_/g, ' ').toLowerCase();
  }

  covertString(data) {
    if (data == 'true') {
      return 'Yes';
    }
    else if (data == 'false') {
      return 'No';
    }
    else {
      return data;
    }
  }

  get(e) {
    var k = this.appMasterData.filter(a => a.key == e)[0].value;
    return k ? k : '-'
  }

}
