import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HealthcoachGuardGuard } from 'src/app/shared/guards';
import { CompletehctaskComponent } from './completehctask/completehctask.component';
import { ExecutehctaskComponent } from './executehctask/executehctask.component';
import { HctasklistComponent } from './hctasklist.component';
import { HctaskmanualComponent } from './hctaskmanual/hctaskmanual.component';
import { HctasksemiautoComponent } from './hctasksemiauto/hctasksemiauto.component';
import { HctasksemiautoperformComponent } from './hctasksemiauto/hctasksemiautoperform/hctasksemiautoperform.component';


const routes: Routes = [
  { path: '', component: HctasklistComponent, canActivate: [HealthcoachGuardGuard] },
  { path: 'hctaskexecute/:id', component: ExecutehctaskComponent, canActivate: [HealthcoachGuardGuard] },
  { path: 'hctaskcomplete/:id', component: CompletehctaskComponent, canActivate: [HealthcoachGuardGuard] },
  { path: 'hctasks/hctaskmanual', component: HctaskmanualComponent, canActivate: [HealthcoachGuardGuard] },
  { path: 'hctassemiauto', component: HctasksemiautoComponent, canActivate: [HealthcoachGuardGuard] },
  { path: 'hctassemiautoperform', component: HctasksemiautoperformComponent, canActivate: [HealthcoachGuardGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HctasklistRoutingModule { }
