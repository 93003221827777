import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment = require('moment');
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../services/commonservice/commonservice.service';

export interface expiryDataFilter {
  title: string;
  id: number;
}
export interface performanceDataFilter {
  // performance: string;
  // performanceid: number;
  title: string;
  id: number;
}

@Component({
  selector: 'app-taskadmin',
  templateUrl: './taskadmin.component.html',
  styleUrls: ['./taskadmin.component.scss']
})

export class TaskadminComponent implements OnInit {
  selected: any
  displayedColumns = ['name', 'total', 'completed', 'pending', 'failed', 'action'];
  public tagFilters: any;
  stDate: any;
  enDate: any;
  perf: any;
  overView: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public searchcriteria: any;
  public showloader: any;
  lastSearch: any;
  isLoading: boolean = false;
  filters: any;
  sort_by: any;
  sort_direction: any
  public isactive = 'active';
  public filterChips: any;
  removable = true;
  expiryDataFilter: any = [];
  performanceDataFilter: any = [];
  startDate1: any;
  endDate1: any;
  postData: any = {};
  dataList: any = [];
  filterEvent: any;
  Chosendate: any;
  filterPerformance: any;
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;

  constructor(private dialog: MatDialog, public commonService: CommonserviceService,
    private systemService: SystemService) { }
  @ViewChild(MatSort, { static: false })
  sort: MatSort = new MatSort;
  ngOnInit(): void {
    // this.search()
    this.tagfilter(event);

  }

  //mat chip for expiry
  add4(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.expiryDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove4(filter: expiryDataFilter): Promise<void> {

    const index = this.expiryDataFilter.indexOf(filter);
    if (index >= 0) {
      this.expiryDataFilter.splice(index, 1);
      this.stDate = false;
      this.enDate = false;
      if (this.stDate == false && this.enDate == false) {
        this.Chosendate = false;
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
    }
  }

  //mat chip for performance
  // add3(event: MatChipInputEvent): void {
  //   
  //   const value = (event.value || '').trim();
  //   if (value) {
  //     
  //     this.performanceDataFilter.push({ name: value });
  //   }
  //   if (event.input) {
  //     event.input.value = '';
  //   }
  // }
  add3(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.performanceDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }


  // async remove3(filter: performanceDataFilter): Promise<void> {
  //   const index = this.performanceDataFilter.indexOf(filter);
  //   if (index >= 0) {
  //     this.performanceDataFilter.splice(index, 1);
  //     this.perf = false;
  //     //this.filterPerformance = undefined;
  //     this.onit = true;
  //     this.search();
  //     await this.sleep(1000)
  //   }
  // }
  async remove3(filter: performanceDataFilter): Promise<void> {

    const index = this.performanceDataFilter.indexOf(filter);
    if (index >= 0) {
      this.performanceDataFilter.splice(index, 1);
      this.perf = false;
      this.onit = true;
      this.search();
      await this.sleep(1000)
    }
  }

  async clearChips() {
    this.expiryDataFilter = [];
    this.performanceDataFilter = [];
    //this.filterPerformance = "";
    this.perf = false;
    this.perf = "";
    this.stDate = "";
    this.enDate = "";
    this.onit = true;
    this.applyFilters();
    this.search();
    await this.sleep(1000)
  }

  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  openCalendar(e) {
    this.pickerDirective.open(e);
  }

  toppingsControl = new FormControl([]);
  toppingList: string[] = ['Select Date', 'Performance'];

  onToppingRemoved(topping: string) {
    const toppings = this.toppingsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.toppingsControl.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  // viewTask(): void {
  //   this.dialog.open(ViewtaskComponent, {
  //     width: '900px',
  //     data: {
  //       element: 'Hi'
  //     }
  //   });
  // }
  // assignTask(): void {
  //   this.dialog.open(AssigntaskComponent, {
  //     width: '500px',
  //     data: {
  //       element: 'Hi'
  //     }
  //   });
  // }

  //filter

  tagfilter(event) {

    this.filterEvent = event
    if (event == "Today") {
      // var today = new Date();
      this.stDate = moment().startOf('day').toISOString();
      this.enDate = moment().endOf('day').toISOString();

    }

    if (event == "Thisweek") {
      // var curr = new Date; // get current date
      // var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      // var last = first + 6; // last day is the first day + 6

      // var firstday = new Date(curr.setDate(first)).toUTCString();
      // var lastday = new Date(curr.setDate(last)).toUTCString();
      // this.startDate = firstday.toString()
      // this.endDate = lastday.toString()
      this.stDate = moment().startOf('week').toDate();
      this.enDate = moment().endOf('week').toDate();
      this.stDate = moment(this.stDate).toISOString();
      this.enDate = moment(this.enDate).toISOString();
    }

    this.onit = true;
    this.applyFilters();

    this.tagFilters = event;
  }

  selectDateRange() {
    this.filterEvent = "customeDate"
    let d1 = this.selected.startDate.toDate();
    let d2 = this.selected.endDate.toDate();
    this.stDate = moment(d1).toISOString();
    this.enDate = moment(d2).toISOString();
    this.onit = true;
    this.applyFilters();
    this.tagFilters = event;
  }

  performanceFilter(e) {
    this.perf = e;
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  //cancel search
  cancelSearch() {
    this.searchcriteria = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();

  }
  removeOption(option) {
    // const index = this.tagFilters.indexOf(option);

    // if (index >= 0) {
    //   this.tagFilters.splice(index, 1);
    // }

    this.applyFilters()
    delete (this.tagFilters)
  }

  applyFilters() {
    if (this.stDate == undefined && this.enDate == undefined) {
      this.stDate = ''
      this.enDate = ''
    }
    var filterdata = {
      "performance": this.perf,
      "create_start_date": this.stDate,
      "create_end_date": this.enDate
    }

    var bodyData = {
      mode: "admin",
      hc_id: "",
      user_id_fk: "",
      q: "",
      filters: JSON.stringify(filterdata)
    }
    this.commonService.TaskPost(bodyData, 'filterOverview')
      .subscribe(
        (res: any) => this.onFilterSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onFilterSuccess(data) {
    data = data.data;
    this.overView = data
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();

  }




  //  Task List
  // ----------------------------

  search() {
    
    if (this.searchcriteria) {
      this.searchcriteria = this.searchcriteria
    } else {
      this.searchcriteria = ''
    }
    // if (this.perf) {
    //   this.filterPerformance = "add";
    //   // this.perf = ""
    // }
    // if (!this.perf) {
    //   this.perf = "htl"
    //   // this.perf = ""
    // }

    this.lastSearch = this.searchcriteria;
    this.lastpage = this.page;
    this.isLoading = true;
    this.expiryDataFilter = [];
    this.performanceDataFilter = [];
    this.filters = { "performance": this.perf, "create_start_date": this.stDate, "create_end_date": this.enDate }
    this.searchcriteria = this.removeHTMLtags(this.searchcriteria);
    this.postData = {
      q: this.searchcriteria,
      sort_by: "",
      sort_direction: "",
      filters: JSON.stringify({ "performance": this.perf, "create_start_date": this.stDate, "create_end_date": this.enDate }),
      page: this.page.toString(),
      limit: "20",
      user_id_fk: ""
    }
    this.commonService.TaskPost(this.postData, 'getFilteredDataAdmin')
      .subscribe((res: any) => {
        
        this.onTaskListSuccess(res),
          this.dataList = res.data;
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  private onTaskListSuccess(data) {
    
    let date1 = new Date(this.stDate);
    let date2 = new Date(this.enDate);
    this.startDate1 = moment(date1).format('D MMM, YYYY');
    this.endDate1 = moment(date2).format('D MMM, YYYY');
    
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
        this.isLoading = false;
        if (this.postData) {
          if ((this.filterEvent == "Today") && (this.filters.create_start_date && this.filters.create_end_date)) {
            let Date = { title: this.startDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          }
          if ((this.filterEvent == "Thisweek") && (this.filters.create_start_date && this.filters.create_end_date)) {
            let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          }
          if ((this.filterEvent == "customeDate") && (this.filters.create_start_date && this.filters.create_end_date)) {
            let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          }
          if (this.filters.performance) {
            // 
            // let performanceName;
            // if (this.perf == "htl") {
            //   performanceName = "High to low"
            // }
            // if (this.perf == "lth") {
            //   performanceName = "Low to high"
            // }
            // let performance = { performance: performanceName, performanceid: 0 }
            // this.performanceDataFilter.push(performance)
            // 
            if (this.perf == "htl") {
              let performanceName = "High to low"
              let performanceVal = { title: performanceName, id: 0 }
              this.performanceDataFilter.push(performanceVal)
            }
            if (this.perf == "lth") {
              let performanceName1 = "Low to high"
              let performanceVal1 = { title: performanceName1, id: 0 }
              this.performanceDataFilter.push(performanceVal1)
            }
            
          }
        }
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;

      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      
      this.isLoading = false;
      if (this.postData) {
        if ((this.filterEvent == "Today") && (this.filters.create_start_date && this.filters.create_end_date)) {
          let Date = { title: this.startDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        }
        if ((this.filterEvent == "Thisweek") && (this.filters.create_start_date && this.filters.create_end_date)) {
          let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        }
        if ((this.filterEvent == "customeDate") && (this.filters.create_start_date && this.filters.create_end_date)) {
          let Date = { title: this.startDate1 + "-" + this.endDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        }
        if (this.filters.performance) {
          
          // if (this.perf == "htl") {
          //   let performanceName = "High to low"
          //   let performance = { performance: performanceName, performanceid: 0 }
          //   this.performanceDataFilter.push(performance)
          // }
          // if (this.perf == "lth") {
          //   let performanceName1 = "Low to high"
          //   let performance = { performance: performanceName1, performanceid: 0 }
          //   this.performanceDataFilter.push(performance)
          // }
          if (this.perf == "htl") {
            let performanceName = "High to low"
            let performanceVal = { title: performanceName, id: 0 }
            this.performanceDataFilter.push(performanceVal)
          }
          if (this.perf == "lth") {
            let performanceName1 = "Low to high"
            let performanceVal1 = { title: performanceName1, id: 0 }
            this.performanceDataFilter.push(performanceVal1)
          }
          
        }
      }
    }
  }
removeHTMLtags(value:string){
  return value.replace( /(<([^>]+)>)/ig, '');
}
  // changeFilter(filterModel, isClient?: boolean): void {
  //  /**Get selected filters */
  //  this.filterChips = [];

  //  for (let chip in this.filterModel) {
  //    if (this.filterModel.hasOwnProperty(chip) && this.filterModel[chip]) {
  //      this.filterChips.push(chip);
  //    }
  //  }
  // }
}

