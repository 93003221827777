import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../shared/shared.module';
import { MediainformationComponent } from './../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

import { EditlessonComponent } from './editlesson/editlesson.component';
import { LessonRoutingModule } from './lesson.routing.module';
import { LessonComponent } from './lesson.component';
import { ContentnavbarModule } from '../shared/contentnavbar/contentnavbar.module';
import { ContentleftnavModule } from '../shared/contentleftnav/contentleftnav.module';
import { TherapysetupleftnavModule } from '../shared/therapysetupleftnav/therapysetupleftnav.module';
import { SharedTranslateModule } from './../../shared/shared-translate.module';

@NgModule({
  declarations: [

    LessonComponent,
    EditlessonComponent
  ],
  imports: [
    CommonModule,
    LessonRoutingModule,
    ContentnavbarModule,
    ContentleftnavModule,
    TherapysetupleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot(),
    SharedTranslateModule
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LessonModule { }
