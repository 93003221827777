<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/lessonhome/lessonedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Lesson
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">

                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Lesson Name</mat-label>
                                <input matInput placeholder="Enter the name" formControlName="title" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Lesson Subtitle</mat-label>
                                <input matInput placeholder="Enter the subtitle" formControlName="sub_title" required>
                            </mat-form-field>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Complexity Level</mat-label>
                                        <mat-select formControlName="complexity_level" required>
                                            <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                            <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                            <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Content Type</mat-label>
                                        <mat-select formControlName="type"
                                            (selectionChange)="changeOptions($event.value)" required>
                                            <mat-option value="audio">
                                                Audio
                                            </mat-option>
                                            <mat-option value="image">
                                                Image
                                            </mat-option>
                                            <mat-option value="video">
                                                Video
                                            </mat-option>
                                            <mat-option value="text">
                                                Text
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-12"
                                [ngClass]="!(optiontype =='audio' || optiontype=='video') ? 'hidefield' : ''">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Media Orientation</mat-label>
                                        <mat-select formControlName="media_orientation"
                                            (selectionChange)="morientation($event.value)"
                                            [required]="optiontype =='audio' || optiontype=='video'">
                                            <mat-option value="portrait">Portrait</mat-option>
                                            <mat-option value="landscape">Landscape</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-12"
                                [ngClass]='!(optiontype=="image" || optiontype=="text" || (optiontype=="video"&& mediaorientation=="landscape")||(optiontype=="audio"&& mediaorientation=="landscape")) ? "hidefield" : ""'>
                                <div class="row">
                                    <p (click)="openinstructions()" class="instruction-p">Instructions for editor</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6"
                                    [ngClass]='!(optiontype=="image" || optiontype=="text" || (optiontype=="video"&& mediaorientation=="landscape")||(optiontype=="audio"&& mediaorientation=="landscape")) ? "hidefield" : ""'>
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-md-12">
                                            <mat-label>Text</mat-label>
                                            <textarea matInput placeholder="Enter the text"
                                                formControlName="raw_content"
                                                (ngModelChange)="additionalTextChange($event)" rows="25"
                                                (keydown)="uirender()"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-6"
                                    [ngClass]='!(optiontype=="image" || optiontype=="text" || (optiontype=="video"&& mediaorientation=="landscape")||(optiontype=="audio"&& mediaorientation=="landscape")) ? "hidefield" : ""'>
                                    <div class="row" id="markdown-editor">
                                        <div class="col-md-12">
                                            <fieldset [disabled]="disabled">
                                                <md-editor formControlName="additionaltext" name="content"
                                                    [upload]="doUpload" [preRender]="preRenderFunc" [height]="'410px'"
                                                    mode="editor" [options]="options" 
                                                    [required]="(optiontype=='image' || optiontype=='text' || (optiontype=='video' && mediaorientation=='landscape')||(optiontype=='audio'&& mediaorientation=='landscape'))"
                                                    class="mdeditorcss notranslate">
                                                </md-editor>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="optiontype=='image'">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Option Count</mat-label>
                                        <mat-select formControlName="imagecount"
                                            (selectionChange)="generateOptions($event.value)">
                                            <mat-option [value]="1">1</mat-option>
                                            <mat-option [value]="2">2</mat-option>
                                            <mat-option [value]="3">3</mat-option>
                                            <mat-option [value]="4">4 </mat-option>
                                            <mat-option [value]="5">5 </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" *ngIf="noofoptions && optiontype =='image'">
                                <div class="col-md-12">
                                    <div formArrayName="media" [class]="addingClass">
                                        <h4 class="mb-20">Choose Images</h4>
                                        <div *ngFor="let option of dataForm.get('media')['controls']; let j=index"
                                            [formGroupName]="j" class="boxthekeyvalue">

                                            <div class="uploader-parent">
                                                <div class="upload-btn-wrapper" *ngIf="option && optiontype == 'image'"
                                                    [ngClass]="{'upload-btn-wrapper-active': option?.controls?.audiovideomedia?.value != null}">
                                                    <div class="holder">
                                                        <div class="success-wraper"
                                                            *ngIf="option && option?.controls?.audiovideomedia?.value != null">
                                                            <i class="material-icons">
                                                                done
                                                            </i>
                                                            <p class="customlabel">{{allmediatitle[j]}}</p>
                                                        </div>

                                                        <div class="initial"
                                                            *ngIf="option && option?.controls?.audiovideomedia?.value == null">
                                                            <img src="assets/img/therapy/lesson/image.svg"
                                                                class="img-responsive">
                                                            <p class="customlabel">Choose image
                                                                {{j+1}}
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <input matInput (click)="openmediabox(j,'media')"
                                                        formControlName="audiovideomedia" readonly required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" [ngClass]="!(optiontype =='audio'||optiontype=='video') ? 'hidefield' : ''"
                                *ngIf="optiontype != 'text'">
                                <div class="col-md-12">
                                    <div formArrayName="media" [class]="addingClass">
                                        <h4 class="mb-20">Choose {{optiontype}}</h4>
                                        <div *ngFor="let option of dataForm.get('media')['controls']; let j=index"
                                            [formGroupName]="j" class="boxthekeyvalue">

                                            <div class="uploader-parent">
                                                <div class="upload-btn-wrapper"
                                                    [ngClass]="{'upload-btn-wrapper-active': option?.controls?.audiovideomedia?.value != null}">
                                                    <div class="holder">
                                                        <div class="success-wraper"
                                                            *ngIf="option && option?.controls?.audiovideomedia?.value != null">
                                                            <i class="material-icons">
                                                                done
                                                            </i>
                                                            <p class="customlabel">{{allmediatitle[j]}}</p>
                                                        </div>

                                                        <div class="initial"
                                                            *ngIf="option && option?.controls?.audiovideomedia?.value == null">
                                                            <img src="assets/img/therapy/lesson/{{optiontype}}.svg"
                                                                class="img-responsive">
                                                            <p class="customlabel">Choose {{optiontype}}</p>
                                                        </div>

                                                    </div>
                                                    <input matInput (click)="openmediabox(j,'media')"
                                                        formControlName="audiovideomedia" readonly
                                                        [required]="optiontype =='audio'||optiontype=='video'" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row" [ngClass]="!(optiontype =='audio') ? 'hidefield' : ''">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                        <mat-label>Select Background Image</mat-label>
                                        <input matInput placeholder="Select Background Image"
                                            (click)="openmediabox(0,'background')"
                                            formControlName="background_image_media" [required]="optiontype =='audio'"
                                            readonly>
                                    </mat-form-field>
                                </div>
                            </div> -->

                            <!-- <div class="row" *ngIf="optiontype =='video'">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                        <mat-label>Select Background Image</mat-label>
                                        <input matInput placeholder="Select Background Image"
                                            (click)="openmediabox(0,'background')"
                                            formControlName="background_image_media" readonly>
                                    </mat-form-field>
                                </div>
                            </div> -->

                            <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                                <mat-checkbox value="checked" formControlName="allow_feedback"
                                    (click)="changeValue(checked)">
                                    Allow Feedback
                                </mat-checkbox>
                                <br />
                            </div>

                            <div [ngClass]="!checked ? 'hidefield' : ''">
                                <div class="col-md-12 zeropadleft zeropadright">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Feedback</mat-label>
                                        <mat-select formControlName="feedback" #singleSelectFeedback
                                            [required]="checked">
                                            <mat-option>
                                                <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                                    [placeholderLabel]="'Search Item'"
                                                    [noEntriesFoundLabel]="'No matching records found'"
                                                    [formControl]="searchmatfeedback" ngModel
                                                    (ngModelChange)="modelChangedFeedback()" ngDefaultControl>
                                                    <div class="mat-select-search-custom-header-content notranslate">
                                                        <i class="material-icons">
                                                            search
                                                        </i>
                                                    </div>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option class="camelcased" *ngFor="let fb of filteredFeedback | async"
                                                [value]="fb.id">
                                                {{fb.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Time to Finish (In seconds)</mat-label>
                                <input type="number" min="1" max="3600" placeholder="Time to Finish (In seconds)" matInput
                                    formControlName="time_to_finish" required>
                                <mat-error>
                                    Cannot be greater than 3600 sec
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Tags</mat-label>
                                <mat-select formControlName="tags" required multiple #singleSelectTag>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content notranslate">
                                                <i class="material-icons">
                                                    search
                                                </i>
                                            </div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option class="camelcased select-tags-active"
                                        *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                        {{tag.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button type="submit" color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm?.valid">{{newitem ? 'New' : 'Update'}}
                                Lesson</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox"
                        [ngClass]="{'portrait-class': mediaorientation=='portrait','audio-class': optiontype == 'audio'}">
                        <div style="position: relative;" *ngIf="optiontype == 'image'">
                            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                                <!-- Indicators -->
                                <ol class="carousel-indicators" *ngIf="allmedia.length > 1">
                                    <li data-target="#myCarousel" *ngFor="let x of allmedia;let i = index"
                                        [attr.data-slide-to]="i" ngClass="i == 0 ? 'active' : ''"></li>
                                </ol>
                                <!-- Wrapper for slides -->
                                <div class="carousel-inner" role="listbox">
                                    <div *ngFor="let option of allmedia; let k = index"
                                        [ngClass]="k == 0 ? 'item active' : 'item'">
                                        <img [src]="s3url + option" alt="Wellthy Care" class="img-responsive img-fit">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="optiontype == 'video'">
                            <div *ngFor="let option of allmedia">
                                <video style="max-width: 100%;" [src]="s3url + option" controls></video>
                            </div>
                        </div>

                        <div *ngIf="optiontype == 'audio'">
                            <div class="audioimage-holder">
                                <img *ngIf="thumbnailMedia" [src]="s3url + thumbnailMedia" class="img-responsive" />
                            </div>
                            <div *ngFor="let option of allmedia">
                                <div class="audio-container">
                                    <audio controls class="embed-responsive-item">
                                        <source [src]="s3url + option">
                                    </audio>
                                </div>

                            </div>
                        </div>

                        <div class="content-inside"
                            [ngClass]="{'textheight': optiontype=='text','videoheight' : optiontype == 'video'}">


                            <div class="title-holder">
                                <div *ngIf="!(dataForm?.value?.time_to_finish == '')" class="time-complete">
                                    {{dataForm?.value?.time_to_finish}}s read
                                </div>
                                <div class="iphonetitle">
                                    {{dataForm?.value?.title}}
                                </div>

                            </div>
                            <div class="iphonesubtitle" style="display: block">
                                {{dataForm?.value?.sub_title}}
                            </div>
                            <!-- <div class="maincontent" *ngIf="mediaorientation== 'landscape'" [innerHTML]="dataForm?.value?.content"></div> -->
                            <div class="maincontent notranslate" [innerHTML]="renderhtml">
                            </div>
                        </div>
                        <div class="start-quiz-btn text-center" *ngIf="!(dataForm?.value.time_to_finish == '')">
                            <button mat-flat-button color="primary" class="btn-block">Finish lesson</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>