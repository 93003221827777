<div *ngIf="environmentData?.org == true" class="orgnavigator">
    <mat-drawer #drawer class="sidenav" mode="side" opened>
        <div class="smallnavigator">
            <a class="hcprofile switchcss">
                <img *ngIf="localStoredHCData?.profile_image?.path"
                    [src]="s3url + localStoredHCData?.profile_image?.path" class="hclogo center-block" />
                <img *ngIf="!localStoredHCData?.profile_image?.path"
                    src="https://image.flaticon.com/icons/svg/149/149071.svg" class="hclogo center-block" />

            </a>
            <ul class="alloption hcleftnav">
                <li *ngFor="let d of orgData" (click)="switchOrg(d)">
                    <div [class]="d?.organizationDataId == orgid ? 'active' : 'unselect'">
                        <img [src]="s3url + d?.organization_data?.logo_media?.path" />
                    </div>
                </li>
            </ul>
            <div class="bottom-menu">
                <a (click)="logoutAccount()">
                    <img src="assets/img/logoutico.png" width="22px" />
                </a>
            </div>
        </div>
    </mat-drawer>
    <div class="container" style="margin-top:25vmin;float: left;width: calc(100% - 64px);">
        <div class="row">
            <!-- team-block -->
            <div class="col-md-8 col-md-offset-2 ml20p">
                <h4 class="navigatorheading">{{localStoredHCData?.organization_data?.title}}</h4>
                <div *ngIf="!userOptions.length">
                    <h6 style="text-align: center;">Sorry, You don't have access to any module. Please contact IT support.</h6>
                    <hr />
                    <div class="clearfix"><br /></div>
                    <div>
                        <h4 style="text-align: center;">Or just</h4>
                        <br />
                        <!-- <button mat-flat-button color="primary" class="centerbtn pull-left"
                            (click)="logoutAccount();">Logout</button> -->
                    </div>
                </div>


                <a [routerLink]="item.router" *ngFor="let item of userOptions; let i = index">
                    <div class="col-md-5 mb30 navigatorcontainercss">
                        <div class="row">
                            <div class="prod-img col-md-2 col-xs-3 pdl0 pdt10">
                                <img src="{{item.image_url}}" alt="" class="prodimgcss">
                            </div>
                            <div class="col-md-10 col-xs-9">
                                <h5 class="label-title">{{item.label}}</h5>
                                <p style="color:#000;" class="label-description">
                                    {{item.description}}</p>
                            </div>
                        </div>
                    </div>
                </a>


            </div>
        </div>
    </div>
</div>
<div *ngIf="environmentData?.org == false" class="defaultnavigator">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <!-- team-block -->
            <div class="col-md-12">
                <div class="header text-center">
                    <h5 class="navigatorheading notranslate">
                        {{'Doctor.AllProducts' | translate}}
                    </h5>
                    <p>all dashboards accessible to you are available below</p>
                </div>
                <div *ngIf="!userOptions.length">
                    <h6 style="text-align: center;">Sorry, You don't have access to any module. Please contact IT support.</h6>
                    <hr />
                    <div class="clearfix"><br /></div>
                    <div>
                        <h4 style="text-align: center;">Or just</h4>
                        <br />
                        <!-- <button mat-flat-button color="primary" class="centerbtn pull-left"
                            (click)="logoutAccount();">Logout</button> -->
                    </div>
                </div>

                <div class="row d-flex align-items-center justify-content-center">
                    <a [routerLink]="item.router" *ngFor="let item of userOptions; let i = index" class="col-md-4">
                        <div class="mb30 navigatorcontainercss">
                            <div class="media">
                                <div class="media-left media-middle">
                                    <img class="media-object" src="{{item.image_url}}" alt="Wellthy">
                                </div>
                                <div class="media-body">
                                    <h5 class="label-title">{{item.label}}</h5>
                                    <p class="label-description">{{item.description}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <button *ngIf="userOptions.length" mat-raised-button class="centerbtn pull-left notranslate" (click)="logoutAccount()">
                <img src="assets/img/dls/admin-table/Icon_Deactivate.svg" alt="">
                {{'Doctor.TroubleShoot' | translate}}
            </button>
             <button *ngIf="!userOptions.length" mat-flat-button color="primary"  mat-raised-button class="centerbtn pull-left notranslate" (click)="logoutAccount();">Logout</button>
        </div>
    </div>

    

</div>
