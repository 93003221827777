import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PolicyApiService {

  public apiUrl: string = environment.serverUrl;

  constructor(
    public httpService: HttpService
  ) { }

  getPolicies(policyNumber: string){

    let url = this.apiUrl + 'patient/policy/api/allpolicies';

    let body = {
      q: policyNumber
    }
    return this.httpService.post(url, body);
  }

  sendOtp(data: any){

    let url = this.apiUrl + 'otp/login/send';

    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set('customer-id',data.customer_id)
      .set('policy-number', data.policy_number)
      .set('client-id', "17");

    let options = {
      headers : headers
    }

    let body = new HttpParams()
      .set("phone", data.mobileNumber)
      .set("country_code", "91");

    return this.httpService.post(url, body, options);
  }

  verifyOTP(data: any){

    let url = this.apiUrl + 'otp/login/verify';

    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set('customer-id',data.customer_id)
      .set('policy-number', data.policy_number)
      .set('client-id', "17");

    let options = {
      headers : headers
    }

    let body = new HttpParams()
      .set("phone", data.mobileNumber)
      .set("country_code", "91")
      .set("otp", data.otp);

    return this.httpService.post(url, body, options);
  }
}
