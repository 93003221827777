import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment';
import moment = require('moment');
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { PrefillComponent } from '../prefill/prefill.component';
import * as _ from 'lodash';
import { MatTabGroup } from '@angular/material/tabs';
import { AleartmodelComponent } from 'src/app/content/shared/aleartmodel/aleartmodel.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadValidators } from '@iplab/ngx-file-upload';

@Component({
  selector: 'app-edittask',
  templateUrl: './edittask.component.html',
  styleUrls: ['./edittask.component.scss']
})

export class EdittaskComponent implements OnInit {
  subject = new Subject<boolean>();
  @ViewChild('stepper') stepper: MatStepper;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  showTab1: boolean = true;
  showTab2: boolean = false;
  showsummaryTab: boolean = true;
  selectedCondition: any;
  selectedTask: any;
  taskPriority: any;
  taskScope: any;
  expiry_date: any;
  therapyData: any;
  selectedIndex: any = 0;
  searchcriteria: any;
  clientData: any;
  levelData: any;
  lessonData: any;
  moduleData: any;
  chapterData: any;
  langData: any;
  condData: any;
  clientaccess: boolean = false;
  levelaccess: boolean = false;
  moduleacess: boolean = false;
  operatorAccess: boolean = false;
  threeOptions: boolean = false;
  showUnits: boolean = false;
  lessonAccess: boolean = false;
  chapterAccess: boolean = false;
  languageAccess: boolean = false;
  genderaccess: boolean = false;
  showYears: boolean = false;
  conditionsAccess: boolean = false;
  therapyAccess: boolean = false;
  unitOptions: boolean = false;
  dataAccess: boolean = false;
  selectedType: any;
  langText: any;
  clientValue: any;
  getGender: any;
  editpageopen: boolean = true;
  public childid: any;
  public selfparentid: any;
  public alllanguages: any;
  public primarylanguage: any;
  public newitem: boolean = false;
  public filter_conditions: FormArray;
  public task: FormArray;
  public template: FormArray;
  private myCathash: any = {};
  public therapyName: any;
  private therapyNamebyId: any = {};
  private conditionNamebyId: any = {};
  private clientNamebyId: any = {};
  private levelNamebyId: any = {};
  private chapterNamebyId: any = {};
  private moduleNamebyId: any = {};
  private lessonNamebyId: any = {};
  newTaskID: any;
  prefill: boolean = false;
  public taskType: any;
  public todaydate: any = new Date();
  public startDate: any;
  unSaved: boolean = true;
  conditionValue: boolean = false;
  isclientValue: boolean = false;
  islevel: boolean = false;
  isModule: boolean = false;
  ischapter: boolean = false;
  isLesson: boolean = false;
  islanguage = false
  languaggeName: any;
  diseaseName: any;
  subTypeValue: any;
  endDate: any;
  sendbroadcastflag: boolean = false;
  public selectedValue: any;
  public closedTabs = [];
  changeDetact: any;
  diseaseVal = [];
  clientVal = [];
  description: any;
  expirySelection: any;
  selected1: { startDate: Moment, endDate: Moment };
  public searchmattag: FormControl = new FormControl();
  public searchmattag1: FormControl = new FormControl();
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;
  @ViewChild('filterInout', { static: true }) filterInout: NgModel;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  @ViewChild(DaterangepickerDirective, { static: false }) picker: DaterangepickerDirective;
  levelName: any;
  moduleName: any;
  chapterName: any;
  lessonName: any;
  clientName: any;
  conditionData: any = [];
  operation: any;
  sub_type: any;
  unit: any;
  value: any;
  ontaskChange: string;
  sub_typeClear: any;
  operationClear: any;
  valueClear: any;
  unitClear: any;
  SubtypeChange: any;
  diseaseType: any = [];
  @ViewChild('button') button: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  lang_data: any;
  mediaType = [{
    id: 1, name: 'image', isSelected: false, isDisabled: false
  },
    // {
    //   id: 2, name: 'audio', isSelected: false, isDisabled: false
    // },
    // {
    //   id: 3, name: 'video', isDisabled: false,
    // },
    // {
    //   id: 4, name: 'carousel', isDisabled: true,
    // }
  ];
  notification = [{
    id: 1, name: 'collapsed', isSelected: false, isDisabled: false,
  }, { id: 2, name: 'expanded', isSelected: false, isDisabled: false }]
  enableRichMedia: boolean = false;
  choose_local_media: boolean = false;
  choose_library_media: boolean = true;
  chooseMedia: any;
  public dataForm = this.fb.group({
    image_media: ['', Validators.required],
  });
  allmedia: any;
  filename: any;
  base64File: any;
  public page: number = 1;
  public lastpage: number = 0;
  data: any;
  lastSearch: any;
  public onit: boolean = true;
  tabulardata: any[];
  emptyplaceholder: boolean;
  dataSource: any;
  public endoflist: boolean = false;
  richMediaType: any;
  mediaselection: boolean;
  s3url: any = environment.s3url;
  richMedaiS3url: any = environment.richMedia;
  path = '';
  selectedFile: any;
  imageData: any;
  video: any;
  audio: any;
  videomimeType: any;
  videosource: any;
  audiomimeType: any;
  audiosource: any;
  filesize: any;
  fileType: any;
  mediatype: any;
  mimeType: any;
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  radioEnable: any;
  uploadMediaType: string;
  responseUrl: any;
  upload_doc_type: string;
  resdata: any;
  final_media_path: any;
  mediaSize: any;
  title: any;
  detailedText: any;
  thumbnail_path: any;
  createdby: any;
  textbox = '';
  previewRichMediaLink: any;
  matTabIndex = '0';
  previewRichMediaType: any;
  // private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  grpValue: any;
  edit_mode: any = 0;
  uploadedFile: any;
  disableUploadbtn: boolean = true;
  @ViewChild("richmediatabGrp", { static: false }) richmediatabGrp: MatTabGroup;
  public uploadedFiles: Array<any> = [];
  private filesControl = new FormControl(
    null,
    FileUploadValidators.filesLimit(2)
  );
  public localMediaUpload = new FormGroup({
    files: this.filesControl
  });
  public demoForm = new FormGroup({
    files: this.filesControl
  });
  matRichmedia: string;
  public loader: boolean = false;

  constructor(private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private contentService: ContentserviceService,
    public fb: FormBuilder, public commonService: CommonserviceService, private systemService: SystemService,
    private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar, private sanitizer: DomSanitizer) {

  }


  ngOnInit() {
    // if (window.localStorage) {
    //   if (!localStorage.getItem('firstLoad')) {
    //     localStorage['firstLoad'] = true;
    //     window.location.reload();
    //   }
    //   else
    //     localStorage.removeItem('firstLoad');
    // }
    this.route.params.subscribe(async params => {
      this.getLanguages();
      this.selectedValue = [{ 'id': '1', 'description': '', 'name': 'English' }]
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        const name = <FormArray>(this.firstFormGroup.controls['filter_conditions']);
        name.controls = [];
      } else {
        this.selectedIndex = 0;
        this.showTab2 = true
        //         this.enableRichMedia = true;
        //         this.mediaType = [{
        //           id: 1, name: 'image', isSelected: true, isDisabled: false
        //         }];
        //         this.matRichmedia = 'image';
        this.matTabIndex = '1';
        this.richMediaType = 'image';
        this.searchcriteria = "";
        this.getAllTaskData(params.id);
        this.childid = params.id;
        this.newitem = false;
        this.loadLibraryAssets();
      }
    });
    //formgroup

    this.firstFormGroup = this._formBuilder.group({
      filter_conditions: this.fb.array([
        this.buildSelection()
      ], Validators.required),
      filter_therapies: ['', Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({
      task: this.fb.array([
        this.buildTaskSelection(),
      ]),
      scope: ['', Validators.required],
      priority: ['', Validators.required],
      has_expiry: [],
      expiry_date: ['', Validators.required]
    });

    this.thirdFormGroup = this._formBuilder.group({
      template: this.fb.array([
        // this.templateFormSelection(this.language),
      ]),
      category: ['', Validators.required],
      show_library: ['', Validators.required]
    });


    // var stepIndex = this.stepper.selectedIndex;
    (this.firstFormGroup.get('filter_conditions') as FormArray).valueChanges.subscribe(values => {
      this.processData(values);
      this.selectedType = this.firstFormGroup.controls.filter_conditions.value;
      if (this.selectedType[0].field_value) {
      }
    });



    this.formData.get('type')?.valueChanges.subscribe(val => {

      // if (val == "activity_type" || val == "number_of_days_completed" || val == "patient_age") {
      //   this.firstFormGroup.controls['unit'].setValidators([Validators.required]);
      // } else {
      //   this.firstFormGroup.controls['unit'].clearValidators();
      // }
      this.firstFormGroup.controls['unit'].updateValueAndValidity();
    });
    localStorage.setItem("editTask", "editTask");

  }

  public form2 = new FormGroup({
    drop: new FormControl(this.mediaType[0].id,
      Validators.required,
    ),
  });
  // check123(data){
  //   if(data.)
  //   selectedType[0].field_value!=null
  // }
  checkcond(value, v) {
    // 
    let d = v.includes(value)
    return d;
    // 
    // if(this.selectedType[0].sub_type=='diary_blood_pressure' && this.selectedType[0].field_value!=null ){
    //   let d = this.selectedType[0].field_value.includes(value)
    //   return d;
    // }else{
    //   return false;
    // }



  }
  processData(data) {
    this.startDate = data.startDate;
    this.endDate = data.endDate;
  }
  changeindex() {
    this.selectedIndex = 0;
  }



  selectDateRange(event) {
    let d1 = this.selected1.startDate.toDate();
    let d2 = this.selected1.endDate.toDate();
    this.startDate = moment(d1).format('YYYY-MM-DD HH:m:s');
    this.endDate = moment(d2).format('YYYY-MM-DD HH:m:s');

  }

  // canDeactivate User
  // canDeactivate(){
  //   
  //     if(!this.editpageopen){
  //       let d:boolean=true;
  //        
  //       this.subject.next(d);
  //     }
  //     if(this.editpageopen){
  //       
  //       const dialogRef = this.dialog.open(AleartmodelComponent);

  //       dialogRef.afterClosed().subscribe((result:any) => {
  //         
  //         this.subject.next(result)
  //       });
  //     }
  // }

  // canDeactivate(): Observable<boolean> | boolean {
  //   
  //   if(this.editpageopen)
  //    {
  //     const dialogRef = this.dialog.open(AleartmodelComponent);

  //     dialogRef.afterClosed().subscribe((result:any) => {
  //       
  //       return Observable.of(result);
  //     });
  //   }
  //   return true;
  // }
  confirmDialog(): Observable<boolean> {
    if (this.editpageopen) {
      const dialogRef = this.dialog.open(AleartmodelComponent);

      return dialogRef.afterClosed();
    }
  }
  //dynamic summery tab selection
  getStepper() {
    if (this.stepper.selectedIndex == 0) {
      this.showTab1 = true;
      this.showTab2 = false;
      this.showsummaryTab = true;
    } else if (this.stepper.selectedIndex == 2) {
      this.showsummaryTab = false;
    }
    else {
      this.showTab1 = false;
      this.showTab2 = true
      this.showsummaryTab = true;
    }
  }

  //////

  convertDate(d) {

    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ',' + ' ' + year;
    }
  }

  // Add or remove Input field 
  buildSelection(): FormGroup {
    return this.fb.group({
      type: [null, Validators.required],
      sub_type: [null],
      operation: [null],
      Systolicoperation: [null],
      Diastolicoperation: [null],
      operation_type: [null],
      value: [null],
      Systolicvalue: [null],
      Diastolicvalue: [null],
      unit: [null],
      start_date: [null],
      end_date: [null],
      field_value: [null]
    })
  }


  get formData(): FormArray {
    // 
    return <FormArray>this.firstFormGroup.get('filter_conditions');
  }

  addMore(): void {
    this.filter_conditions = this.firstFormGroup.get('filter_conditions') as FormArray;
    this.filter_conditions.push(this.buildSelection());

  }
  removeBrodcast(index: number) {
    this.filter_conditions = this.firstFormGroup.get('filter_conditions') as FormArray;
    this.filter_conditions.removeAt(index);
  }

  // Task selection
  buildTaskSelection(): FormGroup {
    return this.fb.group({
      type: [null],
      sub_type: [null],
      operation: [null],
      operation_type: [null],
      value: [null],
      unit: [null],
      DateRange: [null]

    })
  }
  ValidateData() {
    this.filter_conditions = this.firstFormGroup.get('filter_conditions') as FormArray;

    const formControl = (this.firstFormGroup.controls.filter_conditions as FormArray);

  }

  get newformData(): FormArray { return <FormArray>this.secondFormGroup.get('task'); }

  addMoreTask(): void {
    this.task = this.secondFormGroup.get('task') as FormArray;
    this.task.push(this.buildTaskSelection());
  }


  async removeTask(index: number) {
    this.task = this.secondFormGroup.get('task') as FormArray;
    this.task.removeAt(index);
    this.selectedTask = this.secondFormGroup.controls.task.value;
    await this.sleep(200)
  }



  // Task selection
  templateFormSelection(lang): FormGroup {
    return this.fb.group({
      "id": [lang.id],
      "description": [''],
      "name": [lang.name]
    })
  }

  // To get All Languages
  // ----------------------------

  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name,short_code}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;

    this.primarylanguage = this.alllanguages;
    // this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
    this.primarylanguage.forEach(element => {
      this.myCathash[element.id] = element.name;
    });
    if (this.newitem == true) {
      this.addTab(this.alllanguages[0])
    } else {
      // this.selectedIndex=0;
      this.addTab(this.alllanguages[0]);
      this.alllanguages = this.alllanguages;

    }

  }

  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  addTab(lang) {
    // this.tabs.push(lang);
    // 
    this.selectedIndex = 0;
    this.template = this.thirdFormGroup.get('template') as FormArray;

    //   if(this.template.value.indexOf(lang) === -1) {

    // }
    let check = _.findIndex(this.template.value, function (o) { return o.id == lang.id; });

    if (check == -1) {
      this.template.push(this.templateFormSelection(lang));
    }

    if (lang) {
      this.selected.setValue(this.alllanguages.length - 1);
    }
    this.removeSelectedLang(lang.id)
  }


  removeSelectedLang(id) {
    this.alllanguages = this.alllanguages.filter((item) => item.id !== id)
  }


  addLang(id) {
    this.alllanguages = this.alllanguages.push((item) => item.id == id)
  }

  // closeTab(index: number) {
  //   
  //   this.tabs.splice(index, 1);
  //   event.stopPropagation();
  // this.closedTabs.push(index);

  // }

  closeTab(index: number, data: any) {

    // this.tabs.splice(index, 1);
    this.template.removeAt(index);
    this.alllanguages.push(data.value);

  }


  getLanguageName(category) {
    category = this.myCathash[category];
    return category;
  }

  get tempformData() {
    let arr = this.thirdFormGroup.get('template') as FormArray;
    let newArr = arr.controls;
    return this.thirdFormGroup.get('template') as FormArray;
  }

  // removeTab(index: number) {
  //   this.tabs.splice(index, 1);
  // }

  ////

  prefillFromLibrary(): void {
    const prefilldialog = this.dialog.open(PrefillComponent, {
      width: '900px',
      data: {
        element: 'Hi'
      }
    });
    prefilldialog.afterClosed().subscribe(result => {

      // this.firstFormGroup.patchValue(result.data);
      if (result) {
        this.template.clear()
        this.getAllTaskData(result.data.id)
      }
    });
  }

  getdata() {
    this.firstFormGroup = this.firstFormGroup.value;
  }

  async onTextChange(index) {
    const formControl = (this.firstFormGroup.controls.filter_conditions as FormArray).at(index);
    this.conditionValue = false;
    this.isclientValue = false;
    this.islanguage = false;
    this.islevel = false;
    this.isModule = false;
    this.ischapter = false;
    this.isLesson = false;
    this.selected1 = undefined;
    formControl['controls'].sub_type.reset();
    formControl['controls'].operation.reset();
    formControl['controls'].value.reset();
    formControl['controls'].unit.reset();
    // formControl['controls'].end_date.reset();
    formControl['controls'].operation_type.reset();
    formControl['controls'].start_date.reset();
  }

  onTextChangeValue(index) {

    const formControl = (this.firstFormGroup.controls.filter_conditions as FormArray).at(index);
    formControl['controls'].value.reset();
    formControl['controls'].unit.reset();
    this.conditionValue = false;
    this.isclientValue = false;
    this.islanguage = false;
    this.islevel = false;
    this.isModule = false;
    this.ischapter = false;
    this.isLesson = false;
  }

  async onTaskChange() {
    this.selectedTask = this.secondFormGroup.controls.task.value;
    await this.sleep(200)

    if (this.selectedTask[0].type == 'broadcast') {

      this.sendbroadcastflag = true;
    } else {

      this.sendbroadcastflag = false;
    }
  }
  onPriority(val) {
    this.taskPriority = val;
  }

  onScope(val) {
    this.taskScope = val;
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.expirySelection = moment(event.value).format('D MMM, YYYY');
  }

  addEvent1(event) {
    this.expirySelection = moment(event).format('D MMM, YYYY');
  }


  ScopeList(param: string): string {
    let returnValue = "";
    if (param === "global") {
      returnValue =  "Global";
    } 
    
    if (param === "patient") {
      returnValue = "Patient";
    }
    return returnValue;
  }

  checkTherapy(category) {
    category = this.therapyNamebyId[category];
    return category;
  }

  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }

  //get therapy
  getTherapyList(therapy_id?) {
    if (therapy_id !== undefined) {
      therapy_id = therapy_id.toString();
    }
    if (!this.searchcriteria) {
      this.searchcriteria = ""
    } else {
      this.searchcriteria = this.searchmattag.value;
    }
    this.commonService.getTherapyList(this.searchcriteria, "20", "therapy", therapy_id)
      .subscribe(
        (res: any) => this.getTherapyListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyListSuccess(data) {
    data = (data as any).data;
    this.therapyData = data;
    this.therapyName = this.therapyData;
    this.therapyName.forEach(element => {
      this.therapyNamebyId[element.id] = element.title;
    });

  }

  // get clients
  getClientList(client_id?) {
    this.commonService.getTherapyList(this.searchcriteria, "10", "client", client_id)
      .subscribe(
        (res: any) => this.getClientListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getClientListSuccess(data) {
    data = (data as any).data;
    this.clientData = data;
    this.clientData.forEach(element => {
      this.clientNamebyId[element.id] = element.title;
    });

  }

  // get levels
  getLevelsList(level_id?) {
    if (level_id !== undefined) {
      level_id = level_id.toString();
    }
    this.commonService.getTherapyList(this.searchcriteria, "10", "level", level_id)
      .subscribe(
        (res: any) => this.getlevelListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getlevelListSuccess(data) {
    data = (data as any).data;
    this.levelData = data;
    this.levelData.forEach(element => {
      this.levelNamebyId[element.id] = element.title;
    });
  }

  // get lessons
  getLessionsList(lesson_id?) {
    if (lesson_id !== undefined) {
      lesson_id = lesson_id.toString();
    }
    this.commonService.getTherapyList(this.searchcriteria, "10", "lesson", lesson_id)
      .subscribe(
        (res: any) => this.getlessonListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getlessonListSuccess(data) {
    data = (data as any).data;
    this.lessonData = data;
    this.lessonData.forEach(element => {
      this.lessonNamebyId[element.id] = element.title;
    });
  }

  // get module
  getModulesList(module_id?) {
    if (module_id !== undefined) {
      module_id = module_id.toString();
    }
    this.commonService.getTherapyList(this.searchcriteria, "10", "module", module_id)
      .subscribe(
        (res: any) => this.getModuleListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getModuleListSuccess(data) {
    data = (data as any).data;
    this.moduleData = data;
    this.moduleData.forEach(element => {
      this.moduleNamebyId[element.id] = element.title;
    });
  }

  // get chapter
  getChaptersList(chapter_id?) {
    if (chapter_id !== undefined) {
      chapter_id = chapter_id.toString();
    }
    this.commonService.getTherapyList(this.searchcriteria, "10", "chapter", chapter_id)
      .subscribe(
        (res: any) => this.getChapterSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getChapterSuccess(data) {
    data = (data as any).data;
    this.chapterData = data;
    this.chapterData.forEach(element => {
      this.chapterNamebyId[element.id] = element.title;
    });
  }

  // get language
  getLanguageList() {
    this.commonService.getTherapyList(this.searchcriteria, "10", "language")
      .subscribe(
        (res: any) => this.getLangSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLangSuccess(data) {
    data = (data as any).data;
    this.langData = data;
  }

  // get conditions
  getConditionList() {
    this.commonService.getTherapyList(this.searchcriteria, "10", "condition")
      .subscribe((res: any) => {
        this.getCondSuccess(res)

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCondSuccess(data) {
    data = (data as any).data;
    this.condData = data;

    this.condData.forEach(element => {
      this.conditionNamebyId[element.id] = element.title;
    });
  }

  //////

  livesearch() {
    let search = this.searchmattag.value;

    if (search.length > 2) {
      this.searchcriteria = search;
      this.getTherapyList()
    }
    // else if (search.length <= 0) {
    //   this.searchcriteria = '';
    //   this.getTherapyList()
    // }
  }

  onclicktheropy() {

    this.getTherapyList();
  }
  livesearch1() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getConditionList()
    }
    else if (search.length <= 0) {
      this.searchcriteria = '';
      this.getConditionList()
    }
  }
  livelangsearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getLanguageList()
    }
    else if (search.length <= 0) {
      this.searchcriteria = '';
      this.getLanguageList()
    }
  }
  livechaptersearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getChaptersList()
    }
    // else if (search.length <= 0) {
    //   this.searchcriteria = '';
    //   this.getChaptersList()
    // }
  }
  onclicklivechaptersearch() {

    this.getChaptersList();
  }
  livelessonsearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getLessionsList()
    }
    // else if (search.length <= 0) {
    //   this.searchcriteria = '';
    //   this.getLessionsList()
    // }
  }
  onclicklivelessonsearch() {

    this.getLessionsList();
  }
  livemodulesearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getModulesList()
    }
    // else if (search.length <= 0) {
    //   this.searchcriteria = '';
    //   this.getModulesList()
    // }
  }
  onclicklivemodulesearch() {

    this.getModulesList();
  }
  liveclientsearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getClientList()
    }
    // else if (search.length <= 0) {
    //   this.searchcriteria = '';
    //   this.getClientList()
    // }
  }
  onclickliveclientsearch() {

    this.getClientList();
  }
  livelevelsearch() {
    let search = this.searchmattag1.value;
    if (search.length > 2) {
      this.searchcriteria = search;
      this.getLevelsList()
    }
    // else if (search.length <= 0) {
    //   this.searchcriteria = '';
    //   this.getLevelsList()
    // }
  }
  onclicklivelevelsearch() {

    this.getLevelsList();
  }

  // To get one task details

  getAllTaskData(id) {
    this.commonService.getOneTaskData(id)
      .subscribe(async (res: any) => {

        this.getOneTaskSuccess(res)
        await this.sleep(200)
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private async getOneTaskSuccess(data) {
    data = data.data;
    let template_data = data.template;
    if (template_data !== null) {
      this.edit_mode = 1;
      if (template_data[0].link) {
        this.enableRichMedia = true;
        this.matRichmedia = 'image';
        this.mediaType = [{
          id: 1, name: 'image', isSelected: true, isDisabled: false
        }];
      }
    } else {
      this.edit_mode = 0;
    }

    this.firstFormGroup.patchValue(data);
    this.secondFormGroup.patchValue(data);
    // template_data = data.template.pop();
    for (let i = 0; i < template_data.length; i++) {
      if (template_data[i].link !== undefined) {
        this.matTabIndex = '1';
        this.previewRichMediaLink = template_data[i].link;
        this.previewRichMediaType = template_data[i].type;
      }
    }
    this.thirdFormGroup.patchValue(data);
    this.thirdFormGroup.controls.template.patchValue(template_data);
    this.firstFormGroup.controls.filter_therapies.patchValue(parseInt(data.filter_therapies[0]));
    if (data.filter_conditions[0].end_date != null) {
      let d1 = data.filter_conditions[0].end_date.startDate;
      let d2 = data.filter_conditions[0].end_date.endDate;
      this.startDate = moment(d1).format('D MMM, YYYY');
      this.endDate = moment(d2).format('D MMM, YYYY');
      this.selected1 = { startDate: this.startDate, endDate: this.endDate }
    }
    this.firstFormGroup.controls['filter_therapies'].patchValue(parseInt(data.filter_therapies[0]));
    this.secondFormGroup.controls.priority.patchValue(data.priority.toString())
    if (this.secondFormGroup.controls.priority.value) {
      this.onPriority(this.secondFormGroup.controls.priority.value);

    }
    if (this.secondFormGroup.controls.scope) {
      this.onScope(this.secondFormGroup.controls.scope.value);

    }
    if (this.secondFormGroup.controls.scope) {
      this.onScope(this.secondFormGroup.controls.scope.value);

    }
    if (this.secondFormGroup.controls.expiry_date) {
      this.addEvent1(this.secondFormGroup.controls.expiry_date.value);

    }


    this.showTab1 = true;
    this.showTab2 = true;
    const name = <FormArray>(this.firstFormGroup.controls['filter_conditions']);
    if (data.filter_therapies.length != 0) {
      for (let i = 0; i < data.filter_therapies.length; i++) {
        this.getTherapyList(data.filter_therapies[i]);
      }
    }
    if (data.filter_conditions.length != 0) {
      name.controls.shift();
      for (let i = 0; i < data.filter_conditions.length; i++) {
        if (data.filter_conditions[i].type === 'patient_chapter') {
          this.getChaptersList(data.filter_conditions[i].value);
        }
        if (data.filter_conditions[i].type === 'patient_lesson') {
          this.getLessionsList(data.filter_conditions[i].value);
        }
        if (data.filter_conditions[i].type === 'patient_module') {
          this.getModulesList(data.filter_conditions[i].value);
        }
        if (data.filter_conditions[i].type === 'patient_level') {
          this.getLevelsList(data.filter_conditions[i].value);
        }
        name.push(this.initTextOptionswithdata(data.filter_conditions[i]));
        // this.onTypeChange(data.filter_conditions[i].type);
      }
    }
    const name1 = <FormArray>(this.secondFormGroup.controls['task']);
    if (data.task.length != 0) {
      name1.controls.shift();
      for (let i = 0; i < data.task.length; i++) {
        name1.push(this.initTextOptionswithdata1(data.task[i]));
        this.typeChecking(data.task[i].type);
      }
    }

    const name2 = <FormArray>(this.thirdFormGroup.controls['template']);
    if (data.template.length != 0) {
      // name2.controls.shift();
      for (let i = 0; i < data.template.length; i++) {

        let check = _.findIndex(name2.value, function (o) { return o.id == data.template[i].id });
        if (check == -1) {
          name2.push(this.initTextOptions(data.template[i]));
          this.removeSelectedLang(data.template[i].id);
        }

      }
    } else {
      this.initialAPicalls();
    }
    // this.expiry_date = this.secondFormGroup.controls.expiry_date.value;


    await this.sleep(200)
    this.onTaskChange()

  }
  initialAPicalls() {
    this.getTherapyList();
    this.getClientList();
    this.getLevelsList();
    this.getLessionsList();
    this.getModulesList();
    this.getChaptersList();
    this.getLanguageList();
    this.getConditionList();
  }

  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }


  initTextOptionswithdata(data) {

    if (data.value && data.value.length > 0) {
      if (data.value && data.value.length == 1) {
        return this.fb.group({
          type: [data.type],
          sub_type: [data.sub_type],
          operation: [data.operation],
          operation_type: [data.operation_type],
          value: [data.value],
          unit: [data.value[0].unit],
          Systolicoperation: [data.value[0].operation],
          Diastolicoperation: [data.value[0].operation],
          Systolicvalue: [data.value[0].value],
          Diastolicvalue: [data.value[0].value],
          start_date: [data.start_date],
          end_date: [data.end_date],
          field_value: [data.value[0].field_type]
        })
      } else if (data.value && data.value.length == 2) {
        let fieldValue = [];
        for (let i = 0; i < data.value.length; i++) {
          fieldValue.push(data.value[i].field_type)
        }
       
        return this.fb.group({
          type: [data.type],
          sub_type: [data.sub_type],
          operation: [data.operation],
          operation_type: [data.operation_type],
          value: [data.value],
          unit: [data.value[0].unit],
          Systolicoperation: [data.value[0].operation],
          Diastolicoperation: [data.value[0].operation],
          Systolicvalue: [data.value[0].value],
          Diastolicvalue: [data.value[0].value],
          start_date: [data.start_date],
          end_date: [data.end_date],
          field_value: [fieldValue]
        })
      }

    } else {
      return this.fb.group({
        type: [data.type],
        sub_type: [data.sub_type],
        operation: [data.operation],
        operation_type: [data.operation_type],
        value: [data.value],
        unit: [data.unit],
        Systolicoperation: '',
        Diastolicoperation: '',
        Systolicvalue: '',
        Diastolicvalue: '',
        start_date: [data.start_date],
        end_date: [data.end_date],
        field_value: ''
      })
    }
  }

  initTextOptionswithdata1(data) {
    return this.fb.group({
      type: [data.type]
    })
  }
  initTextOptions(data) {
    return this.fb.group({
      id: [data.id],
      description: [data.description],
      name: [this.getLanguageName(data.id)]
    })
  }

  // createTask() {
  //   var body = {
  //     created_by_fk: localStorage.getItem('scarletuserid').toString()
  //   }
  //   this.commonService.TaskPost(body, 'createTaskbyAdmin')
  //     .subscribe(
  //       (res: any) => this.onCreateTaskSuccess(res),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  // }

  // private async onCreateTaskSuccess(data) {
  //   data = data.data;
  //   //
  //   localStorage.setItem("task_display_id", data.task_display_id);

  // }



  typeCheck(item) {
    if (item == 'call') {
      this.taskType = 'Make phone calls'
    } else if (item == 'broadcast') {
      this.taskType = 'Send broadcast'
    } else if (item == 'chat') {
      this.taskType = 'Send chat'
    } else if (item == 'sms') {
      this.taskType = 'Send SMS'
    } else if (item == 'activity') {
      this.taskType = 'Activity Compilation'
    }
  }

  typeChecking(item) {

    if (item == 'call') {
      return 'Make phone calls'
    } else if (item == 'broadcast') {
      return 'Send broadcast'
    } else if (item == 'chat') {
      return 'Send custom chat'
    } else if (item == 'sms') {
      return 'Send SMS'
    } else if (item == 'activity') {
      return 'Activity compilation'
    } else if (item == 'clinical_parameter') {
      return 'Get clinical parameter'
    } else if (item == 'custom_broadcast') {
      return 'Send custom broadcast'
    } else if (item == 'whatsapp_chat') {
      return 'Whatsapp chat'
    } else if (item == 'whatsapp_call') {
      return 'Whatsapp call'
    } else if (item == 'data_parameters') {
      return 'Get Data Parameters'
    }
  }

  //date uploading
  async dataObject() {
    this.editpageopen = false;
    var post1data = this.firstFormGroup.value;
    var post2data = this.secondFormGroup.value;
    var post3data = this.thirdFormGroup.value;
    var language = this.languaggeName;
    var level = this.levelName;
    var module = this.moduleName;
    var chapter = this.chapterName;
    var lesson = this.lessonName;
    var subTypeValue = this.subTypeValue;
    let filterdPrimaryCondition1: any = [];

    let filterdPatientCondition1: any = [];
    let finalDiseasedata1: any = [];



    for (let i = 0; i < post1data.filter_conditions.length; i++) {
      let diseasedt = post1data.filter_conditions[i].value;
      if (post1data.filter_conditions[i].value) {
        for (let i = 0; i < diseasedt.length; i++) {

          finalDiseasedata1.push(this.conditionNamebyId[diseasedt[i]])

        }


      }
    }

    let primaryConditionType;
    let filterdPrimaryCondition = post1data.filter_conditions.filter(d => {
      if (d.type == "primary_condition") {
        primaryConditionType = "primary_condition";
        return d.type = "primary_condition";
      }
    }
    )

    if (filterdPrimaryCondition && primaryConditionType == "primary_condition") {
      let diseasedt = filterdPrimaryCondition[0].value;
      for (let i = 0; i < diseasedt.length; i++) {

        filterdPrimaryCondition1.push(this.conditionNamebyId[diseasedt[i]])
      }

    }


    let patientConditionType;
    let filterdpatientCondition = post1data.filter_conditions.filter(d => {
      if (d.type == "patient_condition") {
        patientConditionType = "patient_condition";
        return d.type = "patient_condition";
      }

    }
    )

    if (filterdpatientCondition && patientConditionType == "patient_condition") {
      let diseasedt = filterdpatientCondition[0].value;
      for (let i = 0; i < diseasedt.length; i++) {

        filterdPatientCondition1.push(this.conditionNamebyId[diseasedt[i]])
      }

    }

    var unique = this.diseaseVal.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b); return a;
    }, []);

    var disease = unique.filter(function (element) {
      return element !== undefined;
    });

    var uniqueClient = this.clientVal.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b); return a;
    }, []);
    var client = uniqueClient.filter(function (element) {
      return element !== undefined;
    });
    var newdate = new Date(post2data.expiry_date);
    var task_summary = []
    var filter_conditions_summary = []
    var filter_clients = []
    var taskData: any = [];

    post1data.filter_conditions.filter(function (item) {
      if (item.type == 'number_of_days_completed') {
        filter_conditions_summary.push('who has completed ' + item.value + " " + item.unit);
      } else if (item.type == 'activity_type') {
        filter_conditions_summary.push('App ' + subTypeValue + " " + item.operation + " " + item.value + " " + item.unit);
      } else if (item.type == 'data_parameters' && item.unit == null) {
        filter_conditions_summary.push('Data parameter for ' + item.sub_type + " is 0 ");
      } else if (item.type == 'data_parameters' && item.unit != null) {
        filter_conditions_summary.push('Data parameter for  ' + item.sub_type + " is " + item.value + " " + item.unit);
      } else if (item.type == 'patient_age') {
        filter_conditions_summary.push('patient age ' + item.value + " " + item.unit);
      } else if (item.type == 'primary_condition') {
        filter_conditions_summary.push('primary condition ' + disease);
      } else if (item.type == 'patient_condition') {
        filter_conditions_summary.push('Patient condition ' + disease);
      } else if (item.type == 'patient_gender') {
        filter_conditions_summary.push('Patient gender ' + item.value);
      } else if (item.type == 'patient_language') {
        filter_conditions_summary.push('Language preference ' + language);
      } else if (item.type == 'clinical_parameter') {
        let data;
        if (item.operation == 'equal_to') { data = "equals to " }
        if (item.operation == 'greater_than') { data = "more than " }
        if (item.operation == 'less_than') { data = "less to " }
        filter_conditions_summary.push('clinical_parameter ' + item.sub_type + " " + data + " " + item.value + " " + item.unit);
      } else if (item.type == 'patient_chapter') {
        filter_conditions_summary.push('Patient Chapter ' + chapter);
      } else if (item.type == 'patient_lesson') {
        filter_conditions_summary.push('Patient Lesson ' + lesson);
      } else if (item.type == 'patient_module') {

        filter_conditions_summary.push('Patient Module ' + module);
      } else if (item.type == 'patient_level') {
        filter_conditions_summary.push('Patient Level ' + level);
      } else if (item.type == 'patient_client') {
        filter_conditions_summary.push('Patient Client ' + client);
      }
    })

    // post1data.filter_conditions.filter(function (item) {
    //   if (item.type == 'patient_client') {
    //     filter_clients.push(item.value);
    //   } else {
    //     filter_clients = [];
    //   }
    // })    
    if (post2data.task.length == 1 && post1data.filter_conditions.length == 1) {

      post2data.task.filter(function (item1) {
        post1data.filter_conditions.filter(function (item2) {

          let data;
          let data1;
          let clinicalValue;

          if (subTypeValue == null || subTypeValue == undefined) { subTypeValue = "" }
          if (item2.value == null || item2.value == undefined) { item2.value = "" }
          if (item2.unit == null || item2.unit == undefined) { item2.unit = "" }
          if (item2.operation == null || item2.operation == undefined) { item2.operation = "" }
          let unitValue = item2.unit;

          if (item2.value == 1 && unitValue == "time") { unitValue = "time" }
          if (item2.value > 1 && unitValue == "time") { unitValue = "times" }
          if (item2.value == 1 && unitValue == "month") { unitValue = "month" }
          if (item2.value > 1 && unitValue == "month") { unitValue = "months" }
          if (item2.value == 1 && unitValue == "day") { unitValue = "day" }
          if (item2.value > 1 && unitValue == "day") { unitValue = "days" }
          if (item2.value == 1 && unitValue == "minute") { unitValue = "minute" }
          if (item2.value > 1 && unitValue == "minute") { unitValue = "minutes" }
          if (item2.type == 'clinical_parameter') {
            clinicalValue = 'clinical parameter'
          }

          if (item2.operation == 'equal_to') { data = "equals to" }
          if (item2.operation == 'greater_than') { data = "more than" }
          if (item2.operation == 'less_than') { data = "less than" }
          if (item2.operation == 'no') { data = "Not Logged" }
          if (item2.operation == 'yes') { data = "" }
          if (item2.operation == 'since') { data = "Since" }
          if (item2.operation == 'last') { data = "" }

          if (item2.Systolicoperation == 'equal_to') { data = "" }
          if (item2.Systolicoperation == 'greater_than') { data = "more than" }
          if (item2.Systolicoperation == 'less_than') { data = "less than" }

          if (item2.Diastolicoperation == 'equal_to') { data1 = "" }
          if (item2.Diastolicoperation == 'greater_than') { data1 = "more than" }
          if (item2.Diastolicoperation == 'less_than') { data1 = "less than" }

          //// to all types with number of days
          if (item1.type == 'call' && item2.type == 'number_of_days_completed') {
            task_summary.push("Make phone calls to people who completed " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'number_of_days_completed') {
            task_summary.push("Send broadcast message to people who completed " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'number_of_days_completed') {
            task_summary.push("Custom chat with people who completed " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'number_of_days_completed') {
            task_summary.push("Send custom broadcast to people who completed " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'number_of_days_completed') {
            task_summary.push("Whatsapp chat with people who completed " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'number_of_days_completed') {
            task_summary.push("Make whatsapp call to people who completed " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'number_of_days_completed') {
            task_summary.push("Collect  patient who have completed " + data + " " + item2.value + "  " + unitValue + " in program");
          } else if (item1.type == 'data_parameters' && item2.type == 'number_of_days_completed') {
            task_summary.push("Collect patient who have completed " + data + " " + item2.value + " " + unitValue + " in program");
          } else if (item1.type == 'activity' && item2.type == 'number_of_days_completed') {
            task_summary.push("Check activity for patient who have completed " + data + " " + item2.value + " " + unitValue);
          }

          ////   //// to all types with Activity
          else if (item1.type == 'call' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Make a phone call to the user who logged  " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Send broadcast message to user who completed  " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Custom chat with user who completed " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Send custom broadcast to user who completed " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Whatsapp chat with user who completed " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Make whatsapp call to user who completed " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Collect  patient who have completed " + data + item2.value + " " + unitValue + " in program");
          } else if (item1.type == 'data_parameters' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Collect patient who have completed " + data + item2.value + " " + unitValue + " in program");
          } else if (item1.type == 'activity' && item2.type == 'activity_type' && subTypeValue != "App Launch" && item2.operation != 'no') {
            task_summary.push("Check activity for patient who have completed " + data + item2.value + " " + unitValue);
          }


          else if (item1.type == 'call' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Make a phone call to the user who launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'broadcast' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Send broadcast message to user who launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'chat' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Custom chat with user who  launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'custom_broadcast' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Send custom broadcast to userwho launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Whatsapp chat with user who launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'whatsapp_call' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Make whatsapp call to user who who launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'clinical_parameter' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Collect  patient who launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'data_parameters' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Collect patient who launched their app " + item2.value + " " + unitValue + " ago");
          } else if (item1.type == 'activity' && item2.type == 'activity_type' && subTypeValue == "App Launch") {
            task_summary.push("Check activity for patient who launched their app " + item2.value + " " + unitValue + " ago");
          }


          else if (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Make a phone call to the user Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Send broadcast message to user Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Custom chat with user Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Send custom broadcast to user Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Whatsapp chat with user Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Make whatsapp call to user Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Collect  patient Who has " + data + " " + subTypeValue + " for " + item2.value + "  " + unitValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Collect patient Who has " + data + " " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'activity' && item2.type == 'activity_type' && item2.operation == 'no') {
            task_summary.push("Check activity for patient  Who has " + data + " " + subTypeValue + " for" + item2.value + " " + unitValue);
          }

          //// to all types with data parameters without value and unit     /*** item2.value == ""  || item2.operation == "no") */
          else if (item1.type == 'call' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Make a phone call to the Users who have " + subTypeValue);
          } else if (item1.type == 'broadcast' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Send broadcast message to Users who have " + subTypeValue);
          } else if (item1.type == 'chat' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Custom chat with Users who have " + subTypeValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Custom broadcast with Users who have " + subTypeValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Whatsapp chat with Users who have " + subTypeValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Whatsapp call with Users who have " + subTypeValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Collect Users who have " + subTypeValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Collect Users who have " + subTypeValue);
          } else if (item1.type == 'activity' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "yes") {
            task_summary.push("Check activity for Users who have " + subTypeValue);
          }


          //// to all types with data parameters without value and unit     /*** item2.value == ""  || item2.operation == "no") */
          else if (item1.type == 'call' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Make a phone call to the Users who have no " + subTypeValue);
          } else if (item1.type == 'broadcast' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Send broadcast message to Users who have no " + subTypeValue);
          } else if (item1.type == 'chat' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Custom chat with Users who have no " + subTypeValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Custom broadcast with Users who have no " + subTypeValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Whatsapp chat with Users who have no " + subTypeValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Whatsapp call with Users who have no " + subTypeValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Collect Users who have no " + subTypeValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Collect Users who have no " + subTypeValue);
          } else if (item1.type == 'activity' && item2.type == 'data_parameters' && item2.value == "" && item2.operation == "no") {
            task_summary.push("Check activity for Users who have no " + subTypeValue);
          }

          //// to all types with data parameters with value and unit
          else if (item1.type == 'call' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Make a phone call to the Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Send broadcast message to Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Custom chat with Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Custom broadcast with Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Whatsapp chat with Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Whatsapp call with  Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Collect Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Collect Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          } else if (item1.type == 'activity' && item2.type == 'data_parameters' && item2.value != "") {
            task_summary.push("Check activity for Users who logged " + subTypeValue + " for " + item2.value + " " + unitValue);
          }

          //// to all types with age
          else if (item1.type == 'call' && item2.type == 'patient_age') {
            task_summary.push("Make phone calls to user who is " + item2.value + " " + unitValue + " old");
          } else if (item1.type == 'broadcast' && item2.type == 'patient_age') {
            task_summary.push("Send broadcast message to user who is  " + item2.value + " " + unitValue + " old");
          } else if (item1.type == 'chat' && item2.type == 'patient_age') {
            task_summary.push("Custom chat with user who is " + item2.value + " " + unitValue + " old");
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_age') {
            task_summary.push("Custom broadcast with user who is " + item2.value + " " + unitValue + " old");
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_age') {
            task_summary.push("Whatsapp chat with user who is " + item2.value + " " + unitValue + " old");
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_age') {
            task_summary.push("Whatsapp call with user who is " + item2.value + " " + unitValue + " old");
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_age') {
            task_summary.push("Collect  patient who have completed " + item2.value + "  " + unitValue + " in program");
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_age') {
            task_summary.push("Collect patient who have completed " + item2.value + " " + unitValue + " in program");
          } else if (item1.type == 'activity' && item2.type == 'patient_age') {
            task_summary.push("Check activity for patient who have completed " + item2.value + " " + unitValue);
          }

          /// to all type with disease
          else if (item1.type == 'call' && item2.type == 'primary_condition') {
            task_summary.push("Make phone calls to user with primary condition " + finalDiseasedata1);
          } else if (item1.type == 'broadcast' && item2.type == 'primary_condition') {
            task_summary.push("Send broadcast message to user with primary condition " + finalDiseasedata1);
          } else if (item1.type == 'chat' && item2.type == 'primary_condition') {
            task_summary.push("Custom chat with user with primary condition " + finalDiseasedata1);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'primary_condition') {
            task_summary.push("Custom broadcast with user with primary condition " + finalDiseasedata1);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'primary_condition') {
            task_summary.push("Whatsapp chat with user with primary condition " + finalDiseasedata1);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'primary_condition') {
            task_summary.push("Whatsapp call with user with primary condition " + finalDiseasedata1);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'primary_condition') {
            task_summary.push("Collect patient who is having clinical parameter as " + finalDiseasedata1);
          } else if (item1.type == 'data_parameters' && item2.type == 'primary_condition') {
            task_summary.push("Collect  patient who is having data parameter as " + finalDiseasedata1);
          } else if (item1.type == 'activity' && item2.type == 'primary_condition') {
            task_summary.push("Check activity for patient who is having " + finalDiseasedata1);
          }

          /// to all type with patient condition
          else if (item1.type == 'call' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'chat' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          } else if (item1.type == 'activity' && item2.type == 'patient_condition') {
            task_summary.push("Users who logged codition " + finalDiseasedata1);
          }
          ////  Gender
          else if (item1.type == 'call' && item2.type == 'patient_gender') {
            task_summary.push("Make phone calls to " + item2.value + " patients");
          } else if (item1.type == 'broadcast' && item2.type == 'patient_gender') {
            task_summary.push("Send Broadcast to " + item2.value + " patients");
          } else if (item1.type == 'chat' && item2.type == 'patient_gender') {
            task_summary.push("Chat with " + item2.value + " patients");
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_gender') {
            task_summary.push("Send custom broadcast to " + item2.value + " patients");
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_gender') {
            task_summary.push("Whatsapp chat with " + item2.value + " patients");
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_gender') {
            task_summary.push("Make Whatsapp call with " + item2.value + " patients");
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_gender') {
            task_summary.push("Collect  patient who have completed " + item2.value + "  " + unitValue + " in program");
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_gender') {
            task_summary.push("Collect patient who have completed " + item2.value + " " + unitValue + " in program");
          } else if (item1.type == 'activity' && item2.type == 'patient_gender') {
            task_summary.push("Check activity for patient who have completed " + item2.value + " " + unitValue);
          }

          ////  language
          else if (item1.type == 'call' && item2.type == 'patient_language') {
            task_summary.push("Make phone call to the Patients With Onboarding Language As  " + language);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_language') {
            task_summary.push("Send broadcast message to patients with onboarding language as " + language);
          } else if (item1.type == 'chat' && item2.type == 'patient_language') {
            task_summary.push("Custom chat with patients with onboarding language as " + language);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_language') {
            task_summary.push("Send custom broadcast to patients with onboarding language as " + language);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_language') {
            task_summary.push("Whatsapp chat with patients with onboarding language as " + language);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_language') {
            task_summary.push("Make whatsapp call to patients with onboarding language as " + language);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_language') {
            task_summary.push("Collect  patient who have completed " + item2.value + "  " + unitValue + " in program");
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_language') {
            task_summary.push("Collect patient who have completed " + item2.value + " " + unitValue + " in program");
          } else if (item1.type == 'activity' && item2.type == 'patient_language') {
            task_summary.push("Check activity for patient who have completed " + item2.value + " " + unitValue);
          }


          //clinical_parameters
          else if (item1.type == 'call' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Make phone calls to patient who logged " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Send broadcast message to  patient who logged " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Custom chat with patient who logged " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Send custom broadcast to patient who logged " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Whatsapp chat with patient who logged " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Make whatsapp call to patient who logged " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Collect  patient who have completed " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Collect patient who have completed " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          } else if (item1.type == 'activity' && item2.type == 'clinical_parameter' && item2.sub_type != "diary_blood_pressure") {
            task_summary.push("Check activity for patient who have completed  " + clinicalValue + " " + subTypeValue + " " + data + " " + item2.value + " " + unitValue);
          }


          //clinical_parameters[systolic]
          else if (item1.type == 'call' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Make phone calls to patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Send broadcast message to  patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Custom chat with patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Send custom broadcast to patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Whatsapp chat with patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Make whatsapp call to patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Collect  patient who have completed " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Collect patient who have completed " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + " " + item2.Systolicvalue + " " + unitValue);
          } else if (item1.type == 'activity' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("systolic") && item2.field_value.length == 1) {
            task_summary.push("Check activity for patient who have completed " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data + " " + item2.Systolicvalue + " " + unitValue);
          }
          //clinical_parameters[diastolic]
          else if (item1.type == 'call' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Make phone calls to patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Send broadcast message to  patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Custom chat with patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Send custom broadcast to patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Whatsapp chat with patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Make whatsapp call to patient who logged " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Collect  patient who have completed " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Collect patient who have completed " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'activity' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.includes("diastolic") && item2.field_value.length == 1) {
            task_summary.push("Check activity for patient who have completed " + clinicalValue + " " + subTypeValue + " " + item2.field_value[0] + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          }


          //clinical_parameters[diastolic,systolic]
          else if (item1.type == 'call' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Make phone calls to patient who logged " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'broadcast' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Send broadcast message to  patient who logged " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'chat' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Custom chat with patient who logged " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Send custom broadcast to patient who logged " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Whatsapp chat with patient who logged " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Make whatsapp call to patient who logged " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Collect  patient who have completed " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'data_parameters' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Collect patient who have completed " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          } else if (item1.type == 'activity' && item2.type == 'clinical_parameter' && item2.sub_type == "diary_blood_pressure" && item2.field_value.length == 2) {
            task_summary.push("Check activity for patient who have completed " + clinicalValue + " " + subTypeValue + " systolic value" + " " + data + " " + item2.Systolicvalue + " " + unitValue + " and diastolic value " + " " + data1 + " " + item2.Diastolicvalue + " " + unitValue);
          }

          ////  chapter
          else if (item1.type == 'call' && item2.type == 'patient_chapter') {
            task_summary.push("Make phone calls to user who completed chapter " + chapter);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_chapter') {
            task_summary.push("Send broadcast to user who completed chapter " + chapter);
          } else if (item1.type == 'chat' && item2.type == 'patient_chapter') {
            task_summary.push("Chat with user who completed chapter " + chapter);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_chapter') {
            task_summary.push("Send Custom broadcast to user who completed chapter " + chapter);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_chapter') {
            task_summary.push("Whatsapp chat to user who completed chapter " + chapter);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_chapter') {
            task_summary.push("Make whatsapp call to user who completed chapter " + chapter);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_chapter') {
            task_summary.push("Collect patient who is having clinical parameter as  " + chapter);
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_chapter') {
            task_summary.push("Collect  patient who is having data parameter as " + chapter);
          } else if (item1.type == 'activity' && item2.type == 'patient_chapter') {
            task_summary.push("Check activity for patient who is having " + chapter);
          }
          ////  lesson
          else if (item1.type == 'call' && item2.type == 'patient_lesson') {
            task_summary.push("Make phone calls to user who completed lesson " + lesson);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_lesson') {
            task_summary.push("Send broadcast to user who completed lesson " + lesson);
          } else if (item1.type == 'chat' && item2.type == 'patient_lesson') {
            task_summary.push("Chat with user who completed lesson " + lesson);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_lesson') {
            task_summary.push("Send Custom broadcast to user who completed lesson " + lesson);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_lesson') {
            task_summary.push("Whatsapp chat to user who completed lesson " + lesson);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_lesson') {
            task_summary.push("Make whatsapp call to user who completed lesson " + lesson);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_lesson') {
            task_summary.push("Collect patient who is having clinical parameter as" + lesson);
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_lesson') {
            task_summary.push("Collect patient who is having data parameter as" + lesson);
          } else if (item1.type == 'activity' && item2.type == 'patient_lesson') {
            task_summary.push("Check activity for patient who is having " + lesson);
          }

          ////  module
          else if (item1.type == 'call' && item2.type == 'patient_module') {
            task_summary.push("Make phone calls to user who completed module " + module);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_module') {
            task_summary.push("Send broadcast to user who completed module " + module);
          } else if (item1.type == 'chat' && item2.type == 'patient_module') {
            task_summary.push("Chat with user who completed module " + module);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_module') {
            task_summary.push("Send Custom broadcast to user who completed module " + module);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_module') {
            task_summary.push("Whatsapp chat to user who completed module " + module);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_module') {
            task_summary.push("Make whatsapp call to user who completed module " + module);
          } else if (item1.type == 'clinical_parameter' && item2.type == 'patient_module') {
            task_summary.push("Collect patient who is having clinical parameter as" + module);
          } else if (item1.type == 'data_parameters' && item2.type == 'patient_module') {
            task_summary.push("Collect  patient who is having data parameter as" + module);
          } else if (item1.type == 'activity' && item2.type == 'patient_module') {
            task_summary.push("Check activity for patient who is having " + module);
          }
          ////  level
          else if (item1.type == 'call' && item2.type == 'patient_level') {
            task_summary.push("Make phone calls to user who completed level " + level);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_level') {
            task_summary.push("Send broadcast to user who completed level " + level);
          } else if (item1.type == 'chat' && item2.type == 'patient_level') {
            task_summary.push("Chat with user who completed level " + level);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_level') {
            task_summary.push("Send Custom broadcast to user who completed level " + level);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_level') {
            task_summary.push("Whatsapp chat to user who completed level " + level);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_level') {
            task_summary.push("Make whatsapp call to user who completed level " + level);
          }
          // else if(item1.type == 'clinical_parameter' && item2.type == 'patient_level'){
          //   task_summary.push("Collect "+ item2.value +" from patient who is "+ unitValue + "in program");
          // }else if(item1.type == 'data_parameters' && item2.type == 'patient_level'){
          //   task_summary.push("Collect "+ item2.value +" from patient who is "+ unitValue + "in program");
          // }else if(item1.type == 'activity' && item2.type == 'patient_level'){
          //   task_summary.push("Check activity for patient who is "+ item2.value +" "+unitValue + " old"); 
          // }

          ////  client
          else if (item1.type == 'call' && item2.type == 'patient_client') {
            task_summary.push("Make phone calls to user with therapy client " + client);
          } else if (item1.type == 'broadcast' && item2.type == 'patient_client') {
            task_summary.push("Send broadcast to user with therapy client " + client);
          } else if (item1.type == 'chat' && item2.type == 'patient_client') {
            task_summary.push("Chat with user with therapy client " + client);
          } else if (item1.type == 'custom_broadcast' && item2.type == 'patient_client') {
            task_summary.push("Send Custom broadcast to user with therapy client " + client);
          } else if (item1.type == 'whatsapp_chat' && item2.type == 'patient_client') {
            task_summary.push("Whatsapp chat to user with therapy client " + client);
          } else if (item1.type == 'whatsapp_call' && item2.type == 'patient_client') {
            task_summary.push("Make whatsapp call to user with therapy client " + client);
          }
          //  else if(item1.type == 'clinical_parameter' && item2.type == 'patient_client'){
          //   task_summary.push("Collect "+itemValue +" from patient who is "+ unitValue + "in program");
          // }else if(item1.type == 'data_parameters' && item2.type == 'patient_client'){
          //   task_summary.push("Collect "+ itemValue +" from patient who is "+ unitValue + "in program");
          // }else if(item1.type == 'activity' && item2.type == 'patient_client'){
          //   task_summary.push("Check activity for patient who is "+ itemValue +" "+unitValue + " old"); 
          // }

        })
      })
    }
    else if (post2data.task.length >= 2 && post1data.filter_conditions.length == 1) {
      var itemVal, itemUnit, filter, subTypeValue1, itemOpearationData, taskType;;
      var taskDataCompleted;
      var itemType;
      var data;
      var dataParamVal;
      var dataParamOperation;
      post2data.task.filter(function (item1) {
        post1data.filter_conditions.filter(function (item2) {
          if (subTypeValue == null || subTypeValue == undefined) { subTypeValue = "" }
          if (item2.value == null || item2.value == undefined) { item2.value = "" }
          if (item2.value == null || item2.value == undefined) { dataParamVal = "" }
          if (item2.unit == null || item2.unit == undefined) { item2.unit = "" }
          if (item2.operation == null || item2.operation == undefined) { item2.operation = "" }

          itemVal = item2.value;
          itemUnit = item2.unit;
          itemOpearationData = item2.operation;
          dataParamOperation = item2.operation;
          subTypeValue1 = subTypeValue;
          itemType = item2.type;
          taskType = item2.type;

          if (item2.operation == 'equal_to') { data = "equals to" }
          if (item2.operation == 'greater_than') { data = "more than" }
          if (item2.operation == 'less_than') { data = "less than" }
          if (item2.operation == 'no' && item2.type == 'activity_type') { data = "Not Logged" }
          if (item2.operation == 'yes' && item2.type == 'activity_type') { data = "" }
          if (item2.operation == 'since' && item2.type == 'activity_type') { data = "Since" }
          if (item2.operation == 'last' && item2.type == 'activity_type') { data = "" }



          if (item2.type == 'number_of_days_completed') {
            taskDataCompleted = " to people who completed ";
            var data1 = (item1.type == 'call' && item2.type == 'number_of_days_completed') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'number_of_days_completed') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'number_of_days_completed') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'number_of_days_completed') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'number_of_days_completed') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'number_of_days_completed') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'number_of_days_completed') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'number_of_days_completed') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'number_of_days_completed') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'activity_type') {
            taskDataCompleted = " to people who logged ";
            var data1 = (item1.type == 'call' && item2.type == 'activity_type') ? " Make a phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'activity_type') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'activity_type') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'activity_type') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'activity_type') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'activity_type') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'activity_type') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'activity_type') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'activity_type') ? " Check activity" : ""

            taskData.push(data);
          }
          if (item2.type == 'activity_type' && item2.operation == 'no') {
            taskDataCompleted = " to people who has ";
            var data1 = (item1.type == 'call' && item2.type == 'activity_type') ? " Make a phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'activity_type') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'activity_type') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'activity_type') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'activity_type') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'activity_type') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'activity_type') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'activity_type') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'activity_type') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'data_parameters') {
            if (itemVal && itemUnit) {
              taskDataCompleted = " for ";
            } else (!itemVal && !itemUnit)
            {
              taskDataCompleted = "to Users who logged";
            }
            var data1 = (item1.type == 'call' && item2.type == 'data_parameters') ? " Make a phone calls " : ""
              || (item1.type == 'broadcast' && item2.type == 'data_parameters') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'data_parameters') ? "  Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'data_parameters') ? "Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'data_parameters') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'data_parameters') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'data_parameters') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'data_parameters') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_age') {
            taskDataCompleted = " to people who completed ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_age') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_age') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_age') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_age') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_age') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_age') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_age') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_age') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_age') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'primary_condition') {
            taskDataCompleted = " to user with primary condition ";
            var data1 = (item1.type == 'call' && item2.type == 'primary_condition') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'primary_condition') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'primary_condition') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'primary_condition') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'primary_condition') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'primary_condition') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'primary_condition') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'primary_condition') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'primary_condition') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_condition') {
            var data1 = (item1.type == 'call' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                || (item1.type == 'chat' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_condition') ? " Users who logged codition " : ""
                            || (item1.type == 'activity' && item2.type == 'patient_condition') ? " Users who logged codition " : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_gender') {
            taskDataCompleted = " patients ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_gender') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_gender') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_gender') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == ' patient_gender') ? "Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == ' patient_gender') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_gender') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_gender') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_gender') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_gender') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_language') {
            taskDataCompleted = "  to the Patients With Onboarding Language As ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_language') ? " Make phone calls  " : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_language') ? " send Broadcast Message " : ""
                || (item1.type == 'chat' && item2.type == 'patient_language') ? " Custom chat " : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_language') ? " Send custom broadcast  " : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_language') ? " Whatsapp chat  " : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_language') ? " Make whatsapp call  " : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_language') ? " Add clinical parameter  " : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_language') ? " Add Data parameter " : ""
                            || (item1.type == 'activity' && item2.type == 'patient_language') ? " Check activity " : ""

            taskData.push(data1);
          }
          if (item2.type == 'clinical_parameter') {
            var data1 = (item1.type == 'call' && item2.type == ' clinical_parameter') ? "Users who logged " : ""
              || (item1.type == 'broadcast' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                || (item1.type == 'chat' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                          || (item1.type == 'data_parameters' && item2.type == 'clinical_parameter') ? "Users who logged " : ""
                            || (item1.type == 'activity' && item2.type == 'clinical_parameter') ? "Users who logged " : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_chapter') {
            taskDataCompleted = " to user who completed chapter ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_chapter') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_chapter') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_chapter') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_chapter') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_chapter') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_chapter') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_chapter') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_chapter') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_chapter') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_lesson') {
            taskDataCompleted = " to user who completed lesson ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_lesson') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_lesson') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_lesson') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_lesson') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_lesson') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_lesson') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_lesson') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_lesson') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_lesson') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_module') {
            taskDataCompleted = " to user who completed module ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_module') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_module') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_module') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_module') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_module') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_module') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_module') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_module') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_module') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_level') {
            taskDataCompleted = " to user who completed level";
            var data1 = (item1.type == 'call' && item2.type == 'patient_level') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_level') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_level') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_level') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_level') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_level') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_level') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_level') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_level') ? " Check activity" : ""

            taskData.push(data1);
          }
          if (item2.type == 'patient_client') {
            taskDataCompleted = " to user with therapy client ";
            var data1 = (item1.type == 'call' && item2.type == 'patient_client') ? " Make phone calls" : ""
              || (item1.type == 'broadcast' && item2.type == 'patient_client') ? " send Broadcast Message" : ""
                || (item1.type == 'chat' && item2.type == 'patient_client') ? " Custom chat" : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'patient_client') ? " Send custom broadcast" : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_client') ? " Whatsapp chat" : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_client') ? " Make whatsapp call" : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_client') ? " Add clinical parameter" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'patient_client') ? " Add Data parameter" : ""
                            || (item1.type == 'activity' && item2.type == 'patient_client') ? " Check activity" : ""

            taskData.push(data1);
          }

        })


      })
      var uniqueyask = taskData.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b); return a;
      }, []);
      filter = uniqueyask.filter(function (element) {
        return element !== undefined;
      });
      let unitValue = itemUnit;


      if (itemVal == 1 && unitValue == "time") { unitValue = "time" }
      if (itemVal > 1 && unitValue == "time") { unitValue = "times" }
      if (itemVal == 1 && unitValue == "month") { unitValue = "month" }
      if (itemVal > 1 && unitValue == "month") { unitValue = "months" }
      if (itemVal == 1 && unitValue == "day") { unitValue = "day" }
      if (itemVal > 1 && unitValue == "day") { unitValue = "days" }
      if (itemVal == 1 && unitValue == "minute") { unitValue = "minute" }
      if (itemVal > 1 && unitValue == "minute") { unitValue = "minutes" }
      if (itemOpearationData == 'no' && taskType != 'activity_type') { dataParamOperation = "no" }
      if (itemOpearationData == 'yes' && taskType != 'activity_type') { dataParamOperation = "yes" }



      if (taskDataCompleted == null || taskDataCompleted == undefined) { taskDataCompleted = "" }
      if ((itemType == 'number_of_days_completed') && post2data.task.length >= 2) {
        task_summary.push(filter + ' ' + taskDataCompleted + " " + subTypeValue1 + " " + data + " " + itemVal + " " + unitValue);
      }
      if ((itemType == 'patient_age') && post2data.task.length >= 2) {
        task_summary.push(filter + ' ' + taskDataCompleted + " " + subTypeValue1 + " " + itemVal + " " + unitValue);
      }
      if ((itemType == 'activity_type' && subTypeValue1 != "App Launch" && itemOpearationData != 'no') && post2data.task.length >= 2) {
        task_summary.push(filter + ' ' + taskDataCompleted + " " + subTypeValue1 + " " + itemVal + " " + unitValue);
      }
      if ((itemType == 'activity_type' && subTypeValue1 != "App Launch" && itemOpearationData == 'no') && post2data.task.length >= 2) {
        task_summary.push(filter + ' ' + taskDataCompleted + " " + data + " " + subTypeValue1 + " for  " + itemVal + " " + unitValue);
      }
      if ((itemType == 'activity_type' && subTypeValue1 == "App Launch" && itemOpearationData != 'no') && post2data.task.length >= 2) {
        let taskDataCompleted1 = "to user who launched their app";
        task_summary.push(filter + ' ' + taskDataCompleted1 + ' ' + itemVal + " " + unitValue + " ago");
      }
      if ((itemType == 'data_parameters') && post2data.task.length >= 2 && itemVal == "" && dataParamOperation == "yes") {
        task_summary.push(filter + " to Users who have " + subTypeValue1);
      }
      if ((itemType == 'data_parameters') && post2data.task.length >= 2 && itemVal == "" && dataParamOperation == "no") {
        task_summary.push(filter + " to Users who have no " + subTypeValue1);
      }
      if ((itemType == 'data_parameters') && post2data.task.length >= 2 && itemVal != "") {
        task_summary.push(filter + " " + subTypeValue1 + ' ' + taskDataCompleted + " " + itemVal + " " + unitValue);
      }
      if ((itemType == "primary_condition" || itemType == "patient_condition") && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + finalDiseasedata1 + " " + unitValue);
      }
      if ((itemType == "patient_gender") && post2data.task.length >= 2) {
        task_summary.push(filter + " to " + itemVal + " " + taskDataCompleted);
      }
      if ((itemType == "patient_language") && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + language + " " + unitValue);
      }
      if ((itemType == 'clinical_parameter') && post2data.task.length >= 2) {
        let clinicalValue;
        if (itemType == 'clinical_parameter') {
          clinicalValue = 'clinical parameter'
        }
        task_summary.push(filter + clinicalValue + " " + itemOpearationData + " " + itemVal + " " + unitValue);
      }
      if ((itemType == 'patient_chapter') && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + " " + chapter);
      }
      if ((itemType == 'patient_lesson') && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + " " + lesson);
      }
      if ((itemType == 'patient_module') && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + " " + module);
      }
      if ((itemType == 'patient_level') && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + " " + level);
      }
      if ((itemType == 'patient_client') && post2data.task.length >= 2) {
        task_summary.push(filter + subTypeValue1 + taskDataCompleted + " " + client);
      }
    }
    else if (post1data.filter_conditions.length >= 2 && post2data.task.length == 1) {


      var itemVal, itemUnit, filter, subTypeValue1, itemOpearationData;
      var taskDataCompleted;
      var itemType;
      var taskType;
      var dataParamOperation;
      post2data.task.filter(function (item1) {
        post1data.filter_conditions.filter(function (item2) {

          let dataOperation;
          if (subTypeValue == null || subTypeValue == undefined) { subTypeValue = "" }
          if (item2.value == null || item2.value == undefined) { item2.value = "" }
          if (item2.unit == null || item2.unit == undefined) { item2.unit = "" }
          if (item2.value == null || item2.value == undefined) { dataParamVal = "" }
          if (item2.operation == null || item2.operation == undefined) { item2.operation = "" }


          if (item2.operation == 'equal_to') { dataOperation = "equals to" }
          if (item2.operation == 'greater_than') { dataOperation = "more than" }
          if (item2.operation == 'less_than') { dataOperation = "less than" }
          if (item2.operation == 'no' && item2.type == 'activity_type') { dataOperation = "Not Logged" }
          if (item2.operation == 'yes' && item2.type == 'activity_type') { dataOperation = "" }
          if (item2.operation == 'since' && item2.type == 'activity_type') { dataOperation = "Since" }
          if (item2.operation == 'last' && item2.type == 'activity_type') { dataOperation = "" }
          if (item2.operation == 'no' && item2.type != 'activity_type') { dataParamOperation = 'no' }
          if (item2.operation == 'yes' && item2.type != 'activity_type') { dataParamOperation = 'yes' }


          itemVal = item2.value;
          itemUnit = item2.unit;
          dataParamOperation = item2.operation;
          let unitValue = itemUnit;


          if (itemVal == 1 && unitValue == "time") { unitValue = "time" }
          if (itemVal > 1 && unitValue == "time") { unitValue = "times" }
          if (itemVal == 1 && unitValue == "month") { unitValue = "month" }
          if (itemVal > 1 && unitValue == "month") { unitValue = "months" }
          if (itemVal == 1 && unitValue == "day") { unitValue = "day" }
          if (itemVal > 1 && unitValue == "day") { unitValue = "days" }
          if (itemVal == 1 && unitValue == "minute") { unitValue = "minute" }
          if (itemVal > 1 && unitValue == "minute") { unitValue = "minutes" }
          itemOpearationData = dataOperation;
          subTypeValue1 = subTypeValue;
          itemType = item2.type;
          taskType = item1.type;
          if (itemType == 'clinical_parameter') {
            itemType = 'clinical parameter';
          }

          if (item1.type == 'call') {

            taskDataCompleted = "Make phone calls to patient";
            var data = (item1.type == 'call' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'call' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'call' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no" + subTypeValue1 : ""
                      || (item1.type == 'call' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'call' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'call' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'call' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'call' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'call' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'call' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'call' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'call' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'call' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'call' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'call' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'broadcast') {
            taskDataCompleted = " Send broadcast to user";
            var data = (item1.type == 'broadcast' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'broadcast' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'broadcast' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'broadcast' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'broadcast' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'broadcast' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'broadcast' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'broadcast' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'broadcast' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'broadcast' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'broadcast' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'broadcast' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'broadcast' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'broadcast' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'broadcast' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'broadcast' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'chat') {
            taskDataCompleted = " Chat with user";
            var data = (item1.type == 'chat' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'chat' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'chat' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'chat' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'chat' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'chat' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'chat' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'chat' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'chat' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'chat' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'chat' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'chat' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'chat' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'chat' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'chat' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'chat' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'custom_broadcast') {
            taskDataCompleted = " Send Custom broadcast to user ";
            var data = (item1.type == 'custom_broadcast' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'custom_broadcast' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'custom_broadcast' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'custom_broadcast' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'custom_broadcast' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'custom_broadcast' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'custom_broadcast' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'custom_broadcast' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'custom_broadcast' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'custom_broadcast' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'custom_broadcast' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'custom_broadcast' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'custom_broadcast' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'custom_broadcast' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'custom_broadcast' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'custom_broadcast' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'whatsapp_chat') {
            taskDataCompleted = " Whatsapp chat to user";
            var data = (item1.type == 'whatsapp_chat' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'whatsapp_chat' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'whatsapp_chat' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'whatsapp_chat' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'whatsapp_chat' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'whatsapp_chat' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'whatsapp_chat' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'whatsapp_chat' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'whatsapp_chat' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'whatsapp_chat' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'whatsapp_chat' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'whatsapp_chat' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'whatsapp_chat' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'whatsapp_chat' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'whatsapp_call') {
            taskDataCompleted = " Make whatsapp call to user";
            var data = (item1.type == 'whatsapp_call' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'whatsapp_call' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'whatsapp_call' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'whatsapp_call' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'whatsapp_call' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'whatsapp_call' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'whatsapp_call' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'whatsapp_call' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'whatsapp_call' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'whatsapp_call' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'whatsapp_call' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'whatsapp_call' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'whatsapp_call' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'whatsapp_call' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'whatsapp_call' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'whatsapp_call' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'clinical_parameter') {
            taskDataCompleted = " Collect patient";
            var data = (item1.type == 'clinical_parameter' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'clinical_parameter' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'clinical_parameter' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'clinical_parameter' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'clinical_parameter' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'clinical_parameter' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'clinical_parameter' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'clinical_parameter' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'clinical_parameter' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'clinical_parameter' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal + " " + unitValue : ""
                                    || (item1.type == 'clinical_parameter' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'clinical_parameter' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'clinical_parameter' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'clinical_parameter' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'clinical_parameter' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'data_parameters') {
            taskDataCompleted = " Collect  patient";
            var data = (item1.type == 'data_parameters' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'data_parameters' && item2.type == 'activity_type' && item2.operation != 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'data_parameters' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'data_parameters' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no" + subTypeValue1 : ""
                      || (item1.type == 'data_parameters' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'data_parameters' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'data_parameters' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'data_parameters' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'data_parameters' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'data_parameters' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'data_parameters' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'data_parameters' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'data_parameters' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'data_parameters' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'data_parameters' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'data_parameters' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
          if (item1.type == 'activity') {
            taskDataCompleted = " Check activity for patient";
            var data = (item1.type == 'activity' && item2.type == 'number_of_days_completed') ? " who completed number of days " + itemOpearationData + " " + itemVal + " " + unitValue : ""
              || (item1.type == 'activity' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who Logged " + itemOpearationData + " " + itemVal + " " + unitValue : ""
                || (item1.type == 'call' && item2.type == 'activity_type' && item2.operation == 'no') ? " Who has " + dataOperation + " " + subTypeValue1 + " For " + itemVal + " " + unitValue : ""
                  || (item1.type == 'activity' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "yes") ? " Who Logged " + subTypeValue1 : ""
                    || (item1.type == 'activity' && item2.type == 'data_parameters' && unitValue == "" && dataParamOperation == "no") ? " Who Logged no " + subTypeValue1 : ""
                      || (item1.type == 'activity' && item2.type == 'data_parameters' && unitValue != "") ? " Who Logged " + subTypeValue1 + " for " + itemVal + " " + unitValue : ""
                        || (item1.type == 'activity' && item2.type == 'patient_age') ? " who is " + itemVal + " " + unitValue + " old" : ""
                          || (item1.type == 'activity' && item2.type == 'primary_condition') ? "  with primary condition " + filterdPrimaryCondition1 : ""
                            || (item1.type == 'activity' && item2.type == 'patient_condition') ? "  who logged codition " + filterdPatientCondition1 : ""
                              || (item1.type == 'activity' && item2.type == 'patient_gender') ? " having patient gender " + itemVal : ""
                                || (item1.type == 'activity' && item2.type == 'patient_language') ? " having Onboarding Language As " + language : ""
                                  || (item1.type == 'activity' && item2.type == 'clinical_parameter') ? " who logged " + itemType + " " + subTypeValue + " " + dataOperation + " " + itemVal : ""
                                    || (item1.type == 'activity' && item2.type == 'patient_chapter') ? " who completed chapter " + chapter : ""
                                      || (item1.type == 'activity' && item2.type == 'patient_lesson') ? " who completed lesson " + lesson : ""
                                        || (item1.type == 'activity' && item2.type == 'patient_module') ? " who completed module " + module : ""
                                          || (item1.type == 'activity' && item2.type == 'patient_level') ? "  who completed level " + level : ""
                                            || (item1.type == 'activity' && item2.type == 'patient_client') ? "with therapy client " + client : ""


            taskData.push(data);
          }
        })
      })

      if (taskDataCompleted == null || taskDataCompleted == undefined) { taskDataCompleted = "" }
      if (taskType == 'call' || taskType == 'broadcast' || taskType == 'chat' || taskType == 'custom_broadcast' || taskType == 'whatsapp_chat'
        || taskType == 'whatsapp_call' || taskType == 'clinical_parameter' || taskType == 'data_parameters' || taskType == 'activity') {
        task_summary.push(taskDataCompleted + taskData);
      }
    }
    const lang_data = JSON.parse(JSON.stringify(post3data.template));

    lang_data.filter(function (item) {
      delete item.name;
    });
    let attachment;
    let finalLink;
    let finalmimeType;
    if (this.radioEnable === 0 || this.radioEnable === undefined && this.previewRichMediaLink === '') { // media is selected from Library 
      attachment = {
        link: this.path,
        type: this.mimeType
      }
      finalLink = this.path;
      finalmimeType = this.mimeType
    } else if (this.radioEnable === 1 && this.previewRichMediaLink === '') {
      attachment = {
        link: this.final_media_path,
        type: this.mimeType
      }
      finalLink = this.final_media_path;
      finalmimeType = this.mimeType
    } else {
      attachment = {
        link: this.previewRichMediaLink,
        type: this.previewRichMediaType
      }
      finalLink = this.previewRichMediaLink;
      finalmimeType = this.previewRichMediaType
    }
    if (attachment.link !== undefined || attachment.link !== '') {
      lang_data.forEach(object => {
        object.link = finalLink
        object.type = finalmimeType;

      });
    }
    let postdata;
    if (attachment.link !== undefined || attachment.link !== '') {
      let jsonObject = {
        type: post1data.filter_conditions.type, operation_type: 'array/string/operator', operation: 'equal_to', value: 20, unit: 'day'

      }
      postdata = {
        filter_conditions: JSON.stringify([this.buildcustomJSON(post1data.filter_conditions[0])]), //JSON.stringify(post1data.filter_conditions),//,
        task: JSON.stringify(post2data.task),
        //  [{ "type":"chat", "sub_type": null, "operation": "less than", "operation_type": "less than", "value": "20", "unit":"days"}]
        priority: post2data.priority.toString(),
        scope: post2data.scope.toString(),
        has_expiry: post2data.has_expiry.toString(),
        expiry_date: newdate.toISOString(),
        template: JSON.stringify(lang_data),
        show_library: post3data.show_library.toString(),
        category: post3data.category.toString(),
        status: 'created',
        created_by_fk: localStorage.getItem('scarletuserid').toString(),
        filter_conditions_summary: JSON.stringify(filter_conditions_summary),
        task_summary: JSON.stringify(task_summary),
        filter_therapies: JSON.stringify([post1data.filter_therapies]),
        filter_clients: JSON.stringify(filter_clients),
        // attachment:attachment
      }
    } else {
      postdata = {
        filter_conditions: JSON.stringify([this.buildcustomJSON(post1data.filter_conditions[0])]), //JSON.stringify(post1data.filter_conditions),//
        task: JSON.stringify(post2data.task),
        //  [{ "type":"chat", "sub_type": null, "operation": "less than", "operation_type": "less than", "value": "20", "unit":"days"}]
        priority: post2data.priority.toString(),
        scope: post2data.scope.toString(),
        has_expiry: post2data.has_expiry.toString(),
        expiry_date: newdate.toISOString(),
        template: JSON.stringify(lang_data),
        show_library: post3data.show_library.toString(),
        category: post3data.category.toString(),
        status: 'created',
        created_by_fk: localStorage.getItem('scarletuserid').toString(),
        filter_conditions_summary: JSON.stringify(filter_conditions_summary),
        task_summary: JSON.stringify(task_summary),
        filter_therapies: JSON.stringify([post1data.filter_therapies]),
        filter_clients: JSON.stringify(filter_clients),
      }
    }

    //   postdata.has_coach_access = postdata.has_coach_access.toString();
    //   postdata.is_test = postdata.is_test.toString();
    //   postdata.has_client = postdata.has_client.toString();
    //   postdata.has_end_date = postdata.has_end_date.toString();
    //   postdata.has_doctor_access = postdata.has_doctor_access.toString();
    //   if (postdata.doctor_data) {
    //     postdata.doctor_data = postdata.doctor_data.toString();
    //   } 
    //   postdata.therapy_data_fk = (postdata.therapy_data_fk as string).toString();
    if (this.newitem == true) {
      var body = {
        created_by_fk: localStorage.getItem('scarletuserid').toString()
      }
      this.newTaskID = localStorage.getItem('newTaskID');
      this.commonService.updateTaskData(this.newTaskID, postdata)
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.commonService.updateTaskData(this.childid, postdata)
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    await this.sleep(1000)
  }


  buildcustomJSON(filter_condition) {
    let obj;
    if (filter_condition.type == "number_of_days_completed") {
      obj =
      {
        type: filter_condition.type,
        operation_type: null,
        operation: filter_condition.operation,
        value: filter_condition.value,
        unit: filter_condition.unit
      }
      return (obj);
    }
    else if (filter_condition.type == "activity_type") {
      if (filter_condition.type == "activity_type" && filter_condition.sub_type == "diary_activity" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "diary_activity" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "diary_meal" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "diary_meal" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "app_launch" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      } else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "app_launch" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_chapter_completed" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_chapter_completed" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_module_completed" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_module_completed" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,

        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_lesson_completed" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_lesson_completed" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_level_completed" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && filter_condition.sub_type == "homefeed_level_completed" && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && (filter_condition.sub_type == "diary_blood_pressure" || filter_condition.sub_type == "diary_blood_sugar" || filter_condition.sub_type == "prescription" ||
        filter_condition.sub_type == "lab_report" || filter_condition.sub_type == "medicine" || filter_condition.sub_type == "diary_weight" ||
        filter_condition.sub_type == "coach_message" || filter_condition.sub_type == "coach_call" || filter_condition.sub_type == "coach_call_completed")
        && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
      else if (filter_condition.type == "activity_type" && (filter_condition.sub_type == "diary_blood_pressure" || filter_condition.sub_type == "diary_blood_sugar" || filter_condition.sub_type == "prescription" ||
        filter_condition.sub_type == "lab_report" || filter_condition.sub_type == "medicine" || filter_condition.sub_type == "diary_weight" ||
        filter_condition.sub_type == "coach_message" || filter_condition.sub_type == "coach_call" || filter_condition.sub_type == "coach_call_completed") && filter_condition.end_date.endDate == null && filter_condition.end_date.startDate == null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
        }
        return (obj);
      }
    }
    else if (filter_condition.type == "data_parameters") {
      if (filter_condition.type == "data_parameters" && (filter_condition.operation == "yes" || filter_condition.operation == "no")) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation
        }
        return (obj);
      }
      else if (filter_condition.type == "data_parameters" && filter_condition.operation == "last" && filter_condition.end_date.endDate != null && filter_condition.end_date.startDate != null) {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit,
          start_date: filter_condition.end_date.startDate,
          end_date: filter_condition.end_date.endDate
        }
        return (obj);
      }
    }
    else if (filter_condition.type == "patient_age") {
      obj = {
        type: filter_condition.type,
        operation_type: null,
        operation: filter_condition.operation,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "primary_condition") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_condition") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_gender") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_language") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "clinical_parameter") {
      if (filter_condition.type == "clinical_parameter" && filter_condition.sub_type == "diary_blood_pressure" && filter_condition.field_value.includes("systolic") && filter_condition.field_value.length == 1) {
        let Value = [];
        let fieldVAl;

        fieldVAl = {
          field_type: filter_condition.field_value,
          value: filter_condition.Systolicvalue,
          unit: filter_condition.unit,
          operation_type: null,
          operation: filter_condition.Systolicoperation
        }
        Value.push(fieldVAl);
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          value: Value
        }
        return (obj);
      }
      else if (filter_condition.type == "clinical_parameter" && filter_condition.sub_type == "diary_blood_pressure" && filter_condition.field_value.includes("diastolic") && filter_condition.field_value.length == 1) {
        let Value = [];
        let fieldVAl;
        fieldVAl = {
          field_type: filter_condition.field_value,
          value: filter_condition.Diastolicvalue,
          unit: filter_condition.unit,
          operation_type: null,
          operation: filter_condition.Diastolicoperation
        }
        Value.push(fieldVAl);
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          value: Value
        }
        return (obj);
      }
      else if (filter_condition.type == "clinical_parameter" && filter_condition.sub_type == "diary_blood_pressure" && filter_condition.field_value.length == 2) {
        let Value = [];
        let systolicfieldVAl;
        let diastolicfieldVAl;
        systolicfieldVAl = {
          field_type: "systolic",
          value: filter_condition.Systolicvalue,
          unit: filter_condition.unit,
          operation_type: null,
          operation: filter_condition.Systolicoperation
        }
        diastolicfieldVAl = {
          field_type: "diastolic",
          value: filter_condition.Diastolicvalue,
          unit: filter_condition.unit,
          operation_type: null,
          operation: filter_condition.Diastolicoperation
        }
        Value.push(systolicfieldVAl);
        Value.push(diastolicfieldVAl);
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          value: Value
        }
        return (obj);
      }
      else if (filter_condition.type == "clinical_parameter" && filter_condition.sub_type == "diary_blood_sugar") {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
      else if (filter_condition.type == "clinical_parameter" && filter_condition.sub_type == "diary_weight") {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
      else if (filter_condition.type == "clinical_parameter" && filter_condition.sub_type == "diary_hba1c") {
        obj = {
          type: filter_condition.type,
          sub_type: filter_condition.sub_type,
          operation_type: null,
          operation: filter_condition.operation,
          value: filter_condition.value,
          unit: filter_condition.unit
        }
        return (obj);
      }
    }
    else if (filter_condition.type == "patient_chapter") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_lesson") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_module") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_level") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
    else if (filter_condition.type == "patient_client") {
      obj = {
        type: filter_condition.type,
        value: filter_condition.value
      }
      return (obj);
    }
  }


  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Task has been created"), "OK");
    this.subject.next(true);
    // this.redirectTo('admin/tasklibrary')
    this.router.navigate(['admin/tasklibrary'])
      .then(() => {
        // window.location.reload();
      });
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Task has been updated"), "OK");
    this.subject.next(true);
    // this.redirectTo('admin/tasklibrary')
    this.router.navigate(['admin/tasklibrary'])
      .then(() => {
        // window.location.reload();
      });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
    // this.onRefresh();
  }
  onRefresh() {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () { return true; };

    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }

  deleteTask(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
      }
    });
  }
  delete(id) {
    this.commonService.DeleteTaskbyAdmin(id)
      .subscribe(
        (res: any) => this.onSuccessDelete(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onSuccessDelete() {

  }

  // preview for conditions dropdown
  // checkoperation(type){

  // }
  // checkUnit(type){}
  conditionChecking(type) {
    // if(type == "primary_condition"){
    //   this.checkforValue()
    // }

    switch (type) {
      case "patient_client":
        this.isclientValue = true;
        this.conditionValue = false;
        this.islanguage = false;
        this.islevel = false;
        this.isModule = false;
        return 'Patients who is associated with';
      case "patient_gender":
        return 'Whose gender is';
      case "activity_type":
        return 'Patients who logged';
      case "data_parameters":
        return 'Patients who';
      case "patient_age":
        return 'Patients age';
      case "primary_condition":
        this.conditionValue = true;
        this.islanguage = false;
        this.isclientValue = false;
        this.islevel = false;
        this.isModule = false;
        return 'Patients who has';
      case "patient_condition":
        this.conditionValue = true;
        this.islanguage = false;
        this.isclientValue = false;
        this.islevel = false;
        this.isModule = false;
        return 'Patients condition is';
      case "patient_language":
        this.islanguage = true;
        this.isclientValue = false;
        this.conditionValue = false;
        this.islevel = false;
        this.isModule = false;
        return 'Patients language';
      case "clinical_parameter":
        return 'Patients who logged the';
      case "patient_chapter":
        this.ischapter = true;
        return 'Patients who completed the chapter';
      case "patient_lesson":
        this.isLesson = true;
        return 'Patients who completed the lesson';
      case "patient_module":
        this.isModule = true;
        this.islevel = false;
        this.islanguage = false;
        this.isclientValue = false;
        this.conditionValue = false;
        return 'Patients who completed the module';
      case "patient_level":
        this.islevel = true;
        this.islanguage = false;
        this.isclientValue = false;
        this.conditionValue = false;
        this.isModule = false;
        return 'Patients who completed the level';
      case "number_of_days_completed":
        return 'Patients who completed';
      default: return ""
    }
  }

  appActivitySubType(type) {

    if (type == null || type === true || type === false || type == undefined) {
      return "";
    } else
      switch (type) {
        case 'diary_activity':
          this.subTypeValue = 'Activity';
          return 'Activity';
        case 'diary_meal':
          this.subTypeValue = 'Meal';
          return 'Meal';
        case 'app_launch':
          this.subTypeValue = 'App Launch';
          return 'App Launch';
        case 'diary_blood_pressure':
          this.subTypeValue = 'Blood Pressure';
          return 'Blood Pressure';
        case 'diary_blood_sugar':
          this.subTypeValue = 'Blood Sugar';
          return 'Blood Sugar';
        case 'prescription':
          this.subTypeValue = 'Prescription';
          return 'Prescription';
        case 'lab_report':
          this.subTypeValue = 'Lab Report';
          return 'Lab Report';
        case 'medicine':
          this.subTypeValue = 'Medicine';
          return 'Medicine';
        case 'diary_weight':
          this.subTypeValue = 'Weight';
          return 'Weight';
        case "coach_message":
          this.subTypeValue = 'Healthcoach chat';
          return 'Healthcoach chat';
        case "coach_call":
          this.subTypeValue = 'Healthcoach call attempted';
          return 'Healthcoach call attempted';
        case "coach_call_completed":
          this.subTypeValue = 'Healthcoach call completed';
          return 'Healthcoach call completed';
        case "homefeed_chapter_completed":
          this.subTypeValue = 'Chapter completed';
          return 'Chapter completed';
        case "homefeed_module_completed":
          this.subTypeValue = 'Module completed';
          return 'Module completed';
        case "homefeed_lesson_completed":
          this.subTypeValue = 'Lesson completed';
          return 'Lesson completed ';
        case "homefeed_level_completed":
          this.subTypeValue = 'Level completed';
          return 'Level completed ';
        case 'diary_hba1c':
          this.subTypeValue = 'HBA1C';
          return 'HBA1C';
        case "meal_reminder":
          this.subTypeValue = 'Meal reminder';
          return 'Meal reminder';
        case "activity_reminder":
          this.subTypeValue = 'Activity reminder';
          return 'Activity reminder';
        case "water_reminder":
          this.subTypeValue = 'Water reminder';
          return 'Water reminder';
        case "symptom_reminder":
          this.subTypeValue = 'Symptom Reminder';
          return 'Symptom Reminder';
        default: return ""
      }
  }



  checkforValue(value) {
    // if (type !== undefined) {
    //   switch (type) {
    //     case 'patient_conditionValue':
    //       this.conditionValue = false;
    //       break;
    //     case 'patient_client':
    //       this.isclientValue = false;
    //       break;
    //     case 'patient_client':
    //       this.isclientValue = false;
    //       break;
    //     case 'patient_language':
    //       this.islanguage = false;
    //       break;
    //     case 'patient_level':
    //       this.islevel = false;
    //       break;
    //     case 'patient_module':
    //       this.isModule = false;
    //       break;
    //     case 'patient_chapter':
    //       this.ischapter = false;
    //       break;
    //     case 'patient_lesson':
    //       this.isLesson = false;
    //       break;
    //   }
    // }
    if (this.conditionValue) {
      this.diseaseName = this.conditionNamebyId[value];
      this.diseaseVal.push(this.diseaseName);
      return this.diseaseName;
    } else if (this.isclientValue) {
      this.clientName = this.clientNamebyId[value]
      this.clientVal.push(this.clientName);
      return this.clientName;
    } else if (this.islanguage) {
      this.languaggeName = this.myCathash[value];
      return this.myCathash[value]
    } else if (this.islevel) {
      this.levelName = this.levelNamebyId[value];
      return this.levelName
    } else if (this.isModule) {
      this.moduleName = this.moduleNamebyId[value];
      return this.moduleName;
    }
    else if (this.ischapter) {
      this.chapterName = this.chapterNamebyId[value];
      return this.chapterName;
    }
    else if (this.isLesson) {
      this.lessonName = this.lessonNamebyId[value];
      return this.lessonName;
    }
    else {
      return value;
    }

  }
  checkoperation(operation) {
    if (operation == null || operation === true || operation === false) {
      return "";
    }
    else if (operation == 'equal_to') {
      return "equals to ";
    }
    else if (operation == 'greater_than') {
      return "more than ";
    }
    else if (operation == 'less_than') {
      return "less than ";
    }
    else if (operation == 'yes') {
      return "Yes ";
    }
    else if (operation == 'no') {
      return "Not Logged ";
    }
    else if (operation == 'last') {
      return "days ago ";
    }
  }

  checkUnit(unit) {
    if (unit == null || unit === true || unit === false) {
      return "";
    }
    else {
      return unit;
    }
  }

  typeof(val) {
    return typeof (val)
  }

  clientValueChange(id) {
    this.clientValue = id
  }
  ChangeGender(gender) {
    this.getGender = gender
  }

  changeEndDate(date) {
    //this.fromdate = new Date(date);
    this.startDate = new Date(date);
  }

  modelChangedTags() {
    this.filterTags();
  }
  private filterTags() {
    if (!this.therapyData) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTherapy.next(this.therapyData.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.therapyData.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }
  getCompleteString(item1, item2) {
    if (item1.type == 'call' && item2.type == 'number_of_days_completed') {
      return "Make phone calls to people who completed" + item2.value, item2.unit;
    }
  }
  handleNoOfDays(input) {
    if (input.value < 0) input.value = 0;
    if (input.value > 365) input.value = 365;
  }
  // post2data.task.filter(function (item) {
  //   if (item.type == 'call') {
  //     task_summary.push('Make phone calls');
  //   } else if (item.type == 'broadcast') {
  //     task_summary.push('Send broadcast');
  //   } else if (item.type == 'chat') {
  //     task_summary.push('Send chat');
  //   } else if (item.type == 'sms') {
  //     task_summary.push('Send sms');
  //   } else if(item.type == 'clinical_parameter'){
  //     task_summary.push('Get clinical parameter')
  //   }else if (item.type == 'custom_broadcast'){
  //     task_summary.push('Send Custom Broadcast')
  //   }
  // })

  //   post1data.filter_conditions.filter(function (item) {
  //     if (item.type == 'number_of_days_completed') {
  //       filter_conditions_summary.push('who has completed Days');
  //     } else if (item.type == 'activity_type') {
  //       filter_conditions_summary.push('App activity');
  //     } else if (item.type == 'data_parameters') {
  //       filter_conditions_summary.push('Data parameter');
  //       filter_conditions_summary.push('patient age');
  //     } else if (item.type == 'activity') {
  //       filter_conditions_summary.push('Activity compilation');
  //     }else if(item.type == 'patient_language'){
  //       filter_conditions_summary.push('Language preference');
  //     }
  // }
  foropen() {
    this.cdkVirtualScrollViewPort.scrollToIndex(5);
  }
  openChange($event: boolean) {
    if ($event) {
      this.foropen();
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }
  openDatepicker() {
    this.picker.open();
  }
  richMediaCheckbox(checked) {
    if (checked === true) {
      this.enableRichMedia = true;
      this.choose_library_media = true;
      this.choose_local_media = false;
      this.path = '';
      this.base64File = '';
      this.searchcriteria = "";
      this.page = 1;
    } else {
      this.enableRichMedia = false;
      this.path = '';
      this.base64File = '';
      this.choose_library_media = false;
      this.choose_local_media = true;
      this.richMediaType = '';

    }
  }
  chooseRadiobutton(event) {
    this.radioEnable = event.value;
    if (this.radioEnable === '0') {
      this.choose_library_media = true;
      this.choose_local_media = false;
      this.path = '';
      this.base64File = '';
      // To get all the Assets from Library 
      this.searchcriteria = "";
      this.page = 1;
      this.loadLibraryAssets();
    } else {
      this.path = '';
      this.base64File = '';
      this.choose_library_media = false;
      this.choose_local_media = true;
    }
  }
  onFileSelect(event): void {
    let scope = this;
    // var input = event.target;
    let input = this.uploadedFiles[0];
    if (input.size > 25000000) {
      this.imageData = null;
      this.video = null;
      this.audio = null;
      this.snackBar.open("File size exceed 25mb", "OK", {
        duration: 2000,
      });
    }
    else {
      if (input && (input.type == 'image/jpeg' || input.type == 'image/png')) {
        try {
          this.loader = true;
          this.choose_library_media = false;
          // const file = event.target.files[0];
          const file = input;
          const fReader = new FileReader()
          fReader.readAsDataURL(file)
          fReader.onloadend = (_event: any) => {
            let tempImg = new Image();
            scope.filename = file.name;
            scope.filesize = input.size
            scope.fileType = input.type;
            scope.previewRichMediaLink = '';
            scope.base64File = _event.target.result;
            tempImg.src = fReader.result as string;
            tempImg.onload = function () {
              var MAX_WIDTH = 400;
              var MAX_HEIGHT = 300;
              var tempW = tempImg.width;
              var tempH = tempImg.height;
              if (tempW > tempH) {
                if (tempW > MAX_WIDTH) {
                  tempH *= MAX_WIDTH / tempW;
                  tempW = MAX_WIDTH;
                }
              } else {
                if (tempH > MAX_HEIGHT) {
                  tempW *= MAX_HEIGHT / tempH;
                  tempH = MAX_HEIGHT;
                }
              }
              var canvas = document.createElement('canvas');
              canvas.width = tempW;
              canvas.height = tempH;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(tempImg, 0, 0, tempW, tempH);
              var thtype = "image/jpeg";
              var dataURL = canvas.toDataURL("image/jpeg");
              var myFile: Blob = scope.dataURItoBlob(dataURL);
              scope.selectedFile = myFile;
              scope.disableUploadbtn = true;
              scope.processfiles(scope.fileType, scope.filesize, scope.filename, scope.selectedFile)
            }

          }
        } catch (error) {
          this.filename = null;
          this.base64File = null;

        }
      } else if (input.files && input.files[0] && (input.files[0].type == 'video/mp4' || input.files[0].type == 'video/webm' || input.files[0].type == 'video/quicktime')) {

        var path = URL.createObjectURL(input.files[0]);
        this.video = {
          sources: [{
            src: path,
            type: input.files[0].type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.videomimeType = input.files[0].type;
        this.videosource = path;
        this.videosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.videosource);
      } else if (input.files && input.files[0] && (input.files[0].type == 'audio/mp3' || input.files[0].type == 'audio/aac' || input.files[0].type == 'audio/wav' || input.files[0].type == 'audio/flac' || input.files[0].type == 'audio/mpeg')) {
        var audiopath = URL.createObjectURL(input.files[0]);
        this.audio = {
          sources: [{
            src: path,
            type: input.files[0].type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.audiomimeType = input.files[0].type;
        this.audiosource = audiopath;

        this.audiosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.audiosource);
        // this.showprev = true;
        // } else if (input.files && input.files[0] && input.files[0].type == 'application/pdf') {
        //     this.isPDF = true;
        //     this.showprev = true;
        // } 
      } else {
        // this.showprev = false;
        this.snackBar.open("Unsuported file format", "OK", {
          duration: 2000,
        });
      }
    }

  }

  processfiles(filetype, fileSize, fileName, file) {
    let path = 'images'
    this.createdby = localStorage.getItem('scarletuserid');
    if (filetype.includes('pdf')) {
      this.uploadMediaType = 'richmedia_pdf';
      this.upload_doc_type = 'document';
    }
    if (filetype.includes('image')) {
      this.uploadMediaType = 'richmedia';
      this.upload_doc_type = 'gallary';
    }
    this.contentService.getRichMediaS3URL(path, filetype, fileSize, fileName)
      .subscribe(
        (data) => {
          this.responseUrl = data;
          this.thumbnail_path = this.responseUrl.data.path;
          this.commonService.uploadToS3(file, this.responseUrl.data.signed_request).subscribe(
            (data) => {
              this.createRichMedia(this.responseUrl.data.path, fileSize.toString(), fileName, fileName, filetype, this.thumbnail_path, this.createdby)
            }, (error) => {
              // scope.isloadingpres = false;
              this.loader = false;
              this.systemService.showSnackBar("Unable to upload file.", "Ok");
            })
        }, (error) => {
          // scope.isloadingpres = false;
          this.loader = false;
          this.systemService.showSnackBar("Unable to upload file.", "Ok");
        });
  }

  loadLibraryAssets() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.contentService.getAllRichMedia(this.searchcriteria, this.page.toString(), "20", "all", this.richMediaType.toString(), "", "")
        .subscribe(
          (res: any) => this.onDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }
  onDataSuccess(data) {
    var mediatype;
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = this.tabulardata;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      if (mediatype == true) {
        data = data.filter(dt => dt.type == this.data.type)
      }
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = this.tabulardata;
    }
  }
  mediaselect(grp) {
    this.mediaselection = true;
    this.previewRichMediaLink = '';
    this.previewRichMediaType = '';
    this.base64File = '';
    this.grpValue = grp.value;
    this.richmediatabGrp.selectedIndex = 1;
    if (grp && grp.value) {
      this.path = this.tabulardata.filter(d => d.id == grp.value)[0].path;
      this.mediatype = this.tabulardata.filter(d => d.id == grp.value)[0].type;
      this.mimeType = this.tabulardata.filter(d => d.id == grp.value)[0].mime;
      this.mediaSize = this.tabulardata.filter(d => d.id == grp.value)[0].size;
      this.title = this.tabulardata.filter(d => d.id == grp.value)[0].title;
      this.detailedText = this.tabulardata.filter(d => d.id == grp.value)[0].detailed_text;
      this.thumbnail_path = this.tabulardata.filter(d => d.id == grp.value)[0].thumbnail_path;
      this.createdby = this.tabulardata.filter(d => d.id == grp.value)[0].created_by_data_fk;
    }
    else {
      this.systemService.showSnackBar('Please select media', 'OK');
    }
  }
  richMedia_livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.loadLibraryAssets();
    }
  }
  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.loadLibraryAssets();
    }
  }
  changeRichMediaType(data) {
    this.richMediaType = data;
    this.searchcriteria = "";
    this.lastSearch = "";
    this.lastpage = 0;
    this.page = 1;
    this.onit = true;
    this.matTabIndex = '0';
    this.enableRichMedia = true;
    this.loadLibraryAssets();
  }

  cancelRichMediaSelection() {
    this.richMediaType = false;
    this.searchcriteria = "";
    this.lastSearch = "";
    this.lastpage = 0;
    this.page = 0;
    this.onit = true;
    this.matTabIndex = '0';
    this.enableRichMedia = false;
    this.previewRichMediaLink = undefined;
    this.previewRichMediaType = undefined;
  }




  toggleEmojiPicker() {

    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const { textbox } = this;
    const text = `${textbox}${event.emoji.native}`;
    this.textbox = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
  attachRichMedia() {
    if (this.radioEnable === 0 || this.radioEnable === undefined) { // media is selected from Library 
      if (this.mediatype.includes('pdf')) {
        this.uploadMediaType = 'richmedia_pdf';
        this.upload_doc_type = 'document';
      }
      if (this.mediatype.includes('image')) {
        this.uploadMediaType = 'richmedia';
        this.upload_doc_type = 'gallary';
      }
      // media is uploaded by Repository 
      // this.createRichMedia(this.path,this.mediaSize,this.title,this.detailedText,this.mediatype,this.thumbnail_path,this.createdby);
    } else {
      // media is uploaded by local system
      this.processfiles(this.fileType, this.filesize, this.filename, this.selectedFile);
    }
  }
  createRichMedia(path, fileSize, fileName, fileDesc, filetype, thumbnail_path, createdby) {
    this.commonService.CreateRichMedia(path, fileSize.toString(), fileName, fileDesc, filetype, thumbnail_path, createdby)
      .subscribe(
        (data) => {
          this.resdata = data;
          this.final_media_path = this.resdata.data.path;
          this.mimeType = this.resdata.data.mime;
          this.systemService.showSnackBar("Media Uploaded Successfully", "Ok");
          this.disableUploadbtn = false;
          this.path = '';
          this.previewRichMediaLink = '';
          this.richmediatabGrp.selectedIndex = 1;
          this.loader = false;

        })
    this.loader = false;
  }
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }
  public clear(): void {
    this.uploadedFiles = [];
  }
  ngOnDestroy() {
    this.route.params.subscribe(async params => {
      this.getLanguages();
      this.selectedValue = [{ 'id': '1', 'description': '', 'name': 'English' }]
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        const name = <FormArray>(this.firstFormGroup.controls['filter_conditions']);
        name.controls = [];
      } else {
        this.selectedIndex = 0;
        this.edit_mode = 1;
        this.enableRichMedia = true;
        this.mediaType = [{
          id: 1, name: 'image', isSelected: true, isDisabled: false
        }];
        this.matRichmedia = 'image';
        this.matTabIndex = '1';
        this.richMediaType = 'image';
        this.searchcriteria = "";
        this.getAllTaskData(params.id);
        this.childid = params.id;
        this.newitem = false;
      }
    });
  }
  ChooseMediaSelectionType(type) {
    let selectionType = type;
    if (selectionType === 'repo') {
      this.choose_library_media = true;
      this.choose_local_media = false;
      this.path = '';
      this.base64File = '';
      // To get all the Assets from Library 
      this.searchcriteria = "";
      this.page = 1;
      this.loadLibraryAssets();
    } else {
      this.path = '';
      this.base64File = '';
      this.choose_library_media = false;
      this.choose_local_media = true;
    }
  }
  selectNotifyType(event) {
  }
}
