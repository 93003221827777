import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from 'src/app/shared/shared.module';

import { ContextService as HealthCoachContextService  } from 'src/app/healthcoach/services/context.service';
import { UserdetailComponent } from './userdetail.component';
import { UpdateMobileModalComponent } from './update-mobile-modal/update-mobile-modal.component';
import { PhoneNumberChangeLogComponent } from './phone-number-change-log/phone-number-change-log.component';
import { CreateDietPlanModalComponent } from './create-diet-plan-modal/create-diet-plan-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    UserdetailComponent,
    UpdateMobileModalComponent,
    PhoneNumberChangeLogComponent,
    CreateDietPlanModalComponent,
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    MatSlideToggleModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    UserdetailComponent
  ],
  entryComponents: [
    UpdateMobileModalComponent,
    CreateDietPlanModalComponent,
  ]
})
export class UserDetailModule { }
