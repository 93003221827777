import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ContentserviceService } from '../../../services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-createonboardingflow',
  templateUrl: './createonboardingflow.component.html',
  styleUrls: ['./createonboardingflow.component.scss']
})

export class CreateonboardingflowComponent implements OnInit {
  title: any = "Create Onboarding Flow";
  public newitem: boolean = true;
  public showButtonLoader: any;
  public ide: any;
  public allconditions: any;
  public alltherapies: any;
  public comorb: any;
  public showAllcondition: boolean = false;
  public allwidgets: any;
  public allclients: any;
  public widgetlist: any;
  public clientid: any;
  public obdata: any;
  private myhash: any = {};
  private myidhash: any = {};
  public indexHealth: any;
  public indexBMI: any;
  public removedwidgetnames: any = ['MOBILE_VERIFICATION', 'CAREY_IMAGE', 'T_AND_C', 'CAREY_INTRO', 'ACTIVATION_CODE', 'OTP_VERIFICATION', 'ONBOARDING_LANGUAGE'];
  public mandatory_field_ids = ["SECONDARY_CONDITIONS", "USER_HEALTH_DETAILS", "THERAPY_LANGUAGE", "USERNAME"];
  public missingmandatory: any = [];
  public bmi: any = [];
  public bmielement: any = [];
  public widgetid: any = [];
  todo = [];
  done = [];

  public dataForm = this.fb.group({
    client_id: ["", Validators.required],
    widgets: [[]],
    created_by_data_fk: ["5", Validators.required]
  });


  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Onboarding Flow');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getClients();
    this.getAllWidgets(res => {
      this.route.params.subscribe(params => {
        this.clientid = params.id;
        if (params.id == 'new') {
          this.newitem = true;
        }
        else {
          this.newitem = false;
          if (!<FormControl>this.dataForm.controls['client_id'].value && !this.newitem) {
            this.dataForm.get('client_id').clearValidators();
          }
          this.getonboardingFlow(params.id);
        }
      });
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  // To get Onboarding Flow
  // ----------------------------

  getonboardingFlow(id) {
    this.contentService.getOneDataById(id, "obflowcreate")
      .subscribe(
        (res: any) => this.getOnboardingSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getOnboardingSuccess(data) {
    this.obdata = data;
    this.dataForm.patchValue(this.obdata);
    for (var k = 0; k < this.obdata.widgets.length; k++) {
      this.done.push({
        id: this.obdata.widgets[k],
        title: this.myhash[this.obdata.widgets[k]]
      })
      this.allwidgets = this.allwidgets.filter(a => a.id != this.obdata.widgets[k]);
      this.todo = this.allwidgets;
      this.removeWidgets(this.removedwidgetnames, this.todo);
    }
  }

  // ----------------------------

  removeWidgets(remarr, haystack) {
    for (let j = 0; j < remarr.length; j++) {
      for (let i = 0; i < haystack.length; i++) {
        if (haystack[i].title == remarr[j]) {
          haystack.splice(i, 1);
        }
      }
    }
  }


  // Get all Clients
  // ----------------------------

  getClients() {
    this.contentService.getGraphQlData('{getAllClients(appMode:false, status:true){id,title}}')
      .subscribe((res: any) => this.ongetClientsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetClientsSuccess(data) {
    this.allclients = data.getAllClients;
  }

  // ----------------------------


  // To get All Widgets
  // ----------------------------

  getAllWidgets(cb) {
    this.contentService.getGeneralRequest("obflow")
      .subscribe((res: any) => this.getAllWidgetsSuccess(cb, (res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllWidgetsSuccess(cb, data) {
    this.allwidgets = data;
    this.todo = this.allwidgets;
    this.allwidgets.forEach(element => {
      this.myhash[element.id] = element.title;
      this.myidhash[element.title] = element.id;
    });
    this.removeWidgets(this.removedwidgetnames, this.todo);
    cb(true);
  }

  // ----------------------------

  getWidgetsName(widgetarr) {
    widgetarr = widgetarr.map(t => this.myhash[t]);
    return widgetarr;
  }

  notFoundMandatory(a, b) {
    for (var i = 0; i < a.length; i++) {
      if (b.indexOf(a[i]) > -1) {
      }
      else {
        this.missingmandatory.push(a[i]);
      }
    }
    this.missingmandatory = Array.from(new Set(this.missingmandatory));
  }


  // Submitting Onboarding Flow
  // ----------------------------

  dataObject() {
    this.widgetid = [];
    this.missingmandatory = [];
    var postdata = this.dataForm.value;
    if (postdata.client_id == "noclient" || postdata.client_id === null) {
      postdata.client_id = "";
    }
    else {
      postdata.client_id = postdata.client_id == null ? null : postdata.client_id.toString();
    }
    var widgetlist = [];
    this.done.forEach(el => {
      widgetlist.push(el.title);
    });
    for (var i = 0; i < widgetlist.length; i++) {
      if (widgetlist[i] == "USER_HEALTH_DETAILS") {
        this.indexHealth = i;
      }
      if (widgetlist[i] == "BMI") {
        this.indexBMI = i;
      }
    }
    this.bmi = ["USER_HEALTH_DETAILS", "BMI"];
    var bmicheck = this.bmi.every(i => widgetlist.includes(i));
    this.bmielement = ["BMI"];
    var bmielementpresent = this.bmielement.every(i => widgetlist.includes(i));
    this.notFoundMandatory(this.mandatory_field_ids, widgetlist);
    widgetlist.forEach(el => {
      this.widgetid.push(this.myidhash[el]);
    })
    if (((!bmielementpresent) || (bmicheck && this.indexHealth < this.indexBMI)) && this.missingmandatory.length == 0 && widgetlist[widgetlist.length - 1] == "THERAPY_LANGUAGE") {
      postdata.widgets = JSON.stringify(this.widgetid);
      if (this.newitem == true) {
        this.contentService.postToBlade(postdata, 'obflowcreate', true)
          .subscribe((res: any) => this.onCreateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
      else {
        postdata.created_by_data_fk = (postdata.created_by_data_fk as string).toString();
        this.contentService.updateToBlade(this.clientid, this.clientid, postdata, 'obflowcreate', true)
          .subscribe((res: any) => this.onUpdateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
    }
    else {
      if (bmicheck && this.indexHealth > this.indexBMI) {
        this.systemService.showSnackBar(("BMI must be below USER_HEALTH_DETAILS"), "OK");
      }
      else if (widgetlist[widgetlist.length - 1] !== "THERAPY_LANGUAGE") {
        this.systemService.showSnackBar(("THERAPY_LANGUAGE is mandatory and must be included at the end"), "OK");
      }
      else if (this.missingmandatory.length > 0) {
        this.systemService.showOnboardingSnackbar(("Please include " + this.missingmandatory), 'OK');
      }
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Onboarding flow has been prepared"), "OK");
    this.router.navigate(['content/onboarding']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Onboarding has been updated"), "OK");
    this.router.navigate(['content/onboarding']);
  }

  // ----------------------------

}
