import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from '../shared/guards';
import { EditorinstructionsComponent } from './shared/editorinstructions/editorinstructions.component';


const routes: Routes = [
  { path: 'profile', loadChildren: () => import('./cprofile/cprofile.module').then(m => m.CprofileModule), canActivate: [ContentGuardGuard] },
  { path: 'home', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [ContentGuardGuard] },
  { path: 'therapy', loadChildren: () => import('./therapydesign/therapydesign.module').then(m => m.TherapydesignModule), canActivate: [ContentGuardGuard] },
  { path: 'level', loadChildren: () => import('./level/level.module').then(m => m.LevelModule), canActivate: [ContentGuardGuard] },
  { path: 'module', loadChildren: () => import('./module/module.module').then(m => m.ModuleModule), canActivate: [ContentGuardGuard] },
  { path: 'chapter', loadChildren: () => import('./chapter/chapter.module').then(m => m.ChapterModule), canActivate: [ContentGuardGuard] },
  { path: 'lesson', loadChildren: () => import('./lesson/lesson.module').then(m => m.LessonModule), canActivate: [ContentGuardGuard] },
  { path: 'quiz', loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule), canActivate: [ContentGuardGuard] },
  { path: 'question', loadChildren: () => import('./question/question.module').then(m => m.QuestionModule), canActivate: [ContentGuardGuard] },
  { path: 'careycard', loadChildren: () => import('./careycard/careycard.module').then(m => m.CareycardModule), canActivate: [ContentGuardGuard] },
  { path: 'magazinecollection', loadChildren: () => import('./magazinecollection/magazinecollection.module').then(m => m.MagazinecollectionModule), canActivate: [ContentGuardGuard] },
  { path: 'openinstructions', component: EditorinstructionsComponent, canActivate: [ContentGuardGuard] },

  { path: 'magazinecategory', loadChildren: () => import('./magazinecategory/magazinecategory.module').then(m => m.MagazinecategoryModule), canActivate: [ContentGuardGuard] },

  { path: 'magazine', loadChildren: () => import('./magazine/magazine.module').then(m => m.MagazineModule), canActivate: [ContentGuardGuard] },

  { path: 'feedback', loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule), canActivate: [ContentGuardGuard] },
  { path: 'tag', loadChildren: () => import('./tag/tag.module').then(m => m.TagModule), canActivate: [ContentGuardGuard] },
  { path: 'intent', loadChildren: () => import('./intent/intent.module').then(m => m.IntentModule), canActivate: [ContentGuardGuard] },

  { path: 'media', loadChildren: () => import('./mediamanager/mediamanager.module').then(m => m.MediamanagerModule), canActivate: [ContentGuardGuard] },
  { path: 'onboarding', loadChildren: () => import('./therapysetup/onboardingflow/onboardingflow.module').then(m => m.OnboardingflowModule), canActivate: [ContentGuardGuard] },

  { path: 'therapy', loadChildren: () => import('./therapydesign/therapydesign.module').then(m => m.TherapydesignModule), canActivate: [ContentGuardGuard] },
  { path: 'onboardingcontent', loadChildren: () => import('./therapysetup/onboardingcontent/onboardingcontent.module').then(m => m.OnboardingcontentModule), canActivate: [ContentGuardGuard] },

  { path: 'activationcode/view/:id', loadChildren: () => import('./therapysetup/activationcode/viewactivationcode/viewactivationcode.module').then(m => m.ViewactivationcodeModule), canActivate: [ContentGuardGuard] },
  { path: 'faq/category', loadChildren: () => import('./faq/category/category.module').then(m => m.CategoryModule), canActivate: [ContentGuardGuard] },
  { path: 'faq/question_answers', loadChildren: () => import('./faq/questionanswers/questionanswers.module').then(m => m.QuestionanswerModule), canActivate: [ContentGuardGuard] },

  { path: 'questionnair', loadChildren: () => import('./questionnair/questionnair/questionnair.module').then(m => m.QuestionnairModule), canActivate: [ContentGuardGuard] },
  { path: 'questionnair/create/:id/:langid/:chid', loadChildren: () => import('./questionnair/createquestionnair/createquestionnair.module').then(m => m.CreatequestionnairModule), canActivate: [ContentGuardGuard] },
  { path: 'questionnair/edit/:id', loadChildren: () => import('./questionnair/createquestionnairmapping/createquestionnairmapping.module').then(m => m.CreatequestionnairmappingModule), canActivate: [ContentGuardGuard] },
  { path: 'questionnairmapping', loadChildren: () => import('./questionnair/questionnairmapping/questionnairmapping.module').then(m => m.QuestionnairmappingModule), canActivate: [ContentGuardGuard] },
  { path: 'questionnairmapping/edit/:id', loadChildren: () => import('./questionnair/createquestionnairmapping/createquestionnairmapping.module').then(m => m.CreatequestionnairmappingModule), canActivate: [ContentGuardGuard] },
  { path: 'logging', loadChildren: () => import('./logging/viewlogging/viewlogging.module').then(m => m.ViewloggingModule), canActivate: [ContentGuardGuard] },
  { path: 'logging/mapping/create/:condid/:mappingid', loadChildren: () => import('./logging/createloggingmap/createloggingmap.module').then(m => m.CreateloggingmapModule), canActivate: [ContentGuardGuard] },
  { path: 'logging/mapping', loadChildren: () => import('./logging/viewloggingmap/viewloggingmap.module').then(m => m.ViewloggingmapModule), canActivate: [ContentGuardGuard] },
  { path: 'symptom/mapping', loadChildren: () => import('./symptoms/symptoms.module').then(m => m.SymptomsModule), canActivate: [ContentGuardGuard] },


  { path: 'triggers', loadChildren: () => import('./triggers/triggers.module').then(m => m.TriggersModule), canActivate: [ContentGuardGuard] },
  { path: 'medicine', loadChildren: () => import('./medicine/medicine.module').then(m => m.MedicineModule), canActivate: [ContentGuardGuard] },
  { path: 'meal', loadChildren: () => import('./meal/meal.module').then(m => m.MealModule), canActivate: [ContentGuardGuard] },
  { path: 'dynamicuuxid', loadChildren: () => import('./uuxid/uuxid.module').then(m => m.UuxidModule), canActivate: [ContentGuardGuard] },
  { path: 'feedbackviewer', loadChildren: () => import('./feedbackviewer/feedbackviewer.module').then(m => m.FeedbackviewerModule), canActivate: [ContentGuardGuard] },
  { path: 'staticuuxid', loadChildren: () => import('./static-uuxid/static-uuxid.module').then(m => m.StaticUuxidModule), canActivate: [ContentGuardGuard] },
  { path: 'conditionlist', loadChildren: () => import('./condition/condition.module').then(m => m.ConditionModule), canActivate: [ContentGuardGuard] },
  { path: 'medication', loadChildren: () => import('./medication/medication.module').then(m => m.MedicationModule), canActivate: [ContentGuardGuard] },
  { path: 'pump', loadChildren: () => import('./pump/pump.module').then(m => m.PumpModule), canActivate: [ContentGuardGuard] },
  { path: 'healthgoal', loadChildren: () => import('./healthgoal/healthgoal.module').then(m => m.HealthgoalModule), canActivate: [ContentGuardGuard] }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
