import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Title } from '@angular/platform-browser';
import { ActivatebottomsheetComponent } from './activatebottomsheet/activatebottomsheet.component';
import { DeeplinkbottomsheetComponent } from './deeplinkbottomsheet/deeplinkbottomsheet.component';
import { GeneralqrbottomsheetComponent } from './generalqrbottomsheet/generalqrbottomsheet.component';
import { GenericDeeplinkComponent } from './genericdeeplink/genericdeeplink.component';
import { QrbottomsheetComponent } from './qrbottomsheet/qrbottomsheet.component';


@Component({
  selector: 'app-activationhome',
  templateUrl: './activationhome.component.html',
  styleUrls: ['./activationhome.component.scss']
})
export class ActivationhomeComponent implements OnInit {

  constructor(private _bottomSheet: MatBottomSheet, private browserTitle: Title) { }

  openPreActive(): void {
    this._bottomSheet.open(ActivatebottomsheetComponent);
  }
  openDeeplink(): void {
    this._bottomSheet.open(DeeplinkbottomsheetComponent, {
      panelClass: 'custom-bottom-css'
    });
  }
  openGenericDeeplink(): void {
    this._bottomSheet.open(GenericDeeplinkComponent, {
      panelClass: 'custom-bottom-css'
    });
  }
  openpatientQR(): void {
    this._bottomSheet.open(QrbottomsheetComponent, {
      panelClass: 'custom-link-css'
    });
  }
  openGeneralQR(): void {
    this._bottomSheet.open(GeneralqrbottomsheetComponent, {
      panelClass: 'custom-link-bottom-css'
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Doctor | Activation');
  }


  ngOnInit() {
    this.setSEOTags();
  }

}
