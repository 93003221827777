import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from './../shared/shared.module';
import { ActivationsearchRoutingModule } from './activationsearch/activationsearch.routing.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminusersRoutingModule } from './adminsection/adminusers.routing.module';
import { AppMasterRoutingModule } from './app-master/app-master.routing.module';
import { ActivatebottomsheetComponent } from './doctor/activatebottomsheet/activatebottomsheet.component';
import { DeeplinkbottomsheetComponent } from './doctor/deeplinkbottomsheet/deeplinkbottomsheet.component';
import { DoctorRoutingModule } from './doctor/doctor.routing.module';
import { GeneralqrbottomsheetComponent } from './doctor/generalqrbottomsheet/generalqrbottomsheet.component';
import { GenericDeeplinkComponent } from './doctor/genericdeeplink/genericdeeplink.component';
import { QrbottomsheetComponent } from './doctor/qrbottomsheet/qrbottomsheet.component';
import { HardsetotpRoutingModule } from './hardsetotp/hardsetotp.routing.module';
import { ActivationRoutingModule } from './hcassign/activation/activation.routing.module';
import { EdithclistComponent } from './hcassign/activation/edithclist/edithclist.component';
import { GeneratecodebyrequestComponent } from './hcassign/activation/generatecodebyrequest/generatecodebyrequest.component';
import { CampaignRoutingModule } from './hcassign/campaign/campaign.routing.module';
import { CampaignviewComponent } from './hcassign/campaign/campaignview/campaignview.component';
import { ClientRoutingModule } from './hcassign/client/client.routing.module';
import { CropImageComponent } from './hcassign/hcassignhome/createhc/crop-image/crop-image.component';
import { HcassignhomeRoutingModule } from './hcassign/hcassignhome/hcassignhome.routing.module';
import { HcuserassignRoutingModule } from './hcassign/hcuserassign/hcuserassign.routing.module';
import { ViewuserassignComponent } from './hcassign/hcuserassign/viewuserassign/viewuserassign.component';
import { PatientRoutingModule } from './patient/patient.routing.module';
import { PaymentmappingRoutingModule } from './paymentmapping/paymentmapping.routing.module';
import { PaymentplanRoutingModule } from './paymentplan/paymentplan.routing.module';
import { ProfileimageRoutingModule } from './profileimage/profileimage.routing.module';
import { ApproveDialogComponent } from './publish-request/approve-dialog/approve-dialog.component';
import { PublishRequestRoutingModule } from './publish-request/publish-request.routing.module';
import { ViewcommentComponent } from './publish-request/viewcomment/viewcomment.component';
import { AdmindeletemodalComponent } from './shared/admindeletemodal/admindeletemodal.component';
import { AdmindeletemodalRoutingModule } from './shared/admindeletemodal/admindeletemodal.routing.module';
import { AdminleftnavModule } from './shared/adminleftnav/adminleftnav.module';
import { AdminprofileComponent } from './shared/adminprofile/adminprofile.component';
import { AdminsectionleftnavModule } from './shared/adminsectionleftnav/adminsectionleftnav.module';
import { ChangeEmailComponent } from './shared/change-email/change-email.component';
import { ChangepasswordRoutingModule } from './shared/changepassword/changepassword.routing.module';
import { HcassignleftnavModule } from './shared/hcassignleftnav/hcassignleftnav.module';
import { PaymentleftnavModule } from './shared/paymentleftnav/paymentleftnav.module';
import { UserprofileviewRoutingModule } from './shared/userprofileview/userprofileview.routing.module';
import { HcassigntaskComponent } from './taskadmin/hcassigntask/hcassigntask.component';
import { ReassignComponent } from './taskadmin/hcassigntask/reassign/reassign.component';
import { TaskviewhistoryComponent } from './taskadmin/hcassigntask/taskviewhistory/taskviewhistory.component';
import { TaskadminComponent } from './taskadmin/taskadmin.component';
import { AssigntaskComponent } from './tasklibrary/assigntask/assigntask.component';
import { EdittaskComponent } from './tasklibrary/edittask/edittask.component';
import { PrefillComponent } from './tasklibrary/prefill/prefill.component';
import { TasklibraryComponent } from './tasklibrary/tasklibrary.component';
import { TasklibraryModule } from './tasklibrary/tasklibrary.module';
import { ViewtaskComponent } from './tasklibrary/viewtask/viewtask.component';
import { RequestdetailsComponent } from './taskrequest/requestdetails/requestdetails.component';
import { TaskrequestComponent } from './taskrequest/taskrequest.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TasklibraryRoutingModule } from './tasklibrary/tasklibrary-routing.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoogleAppleRawdataComponent } from './shared/googleapplerawdata/googleapplerawdata.component';


@NgModule({
  declarations: [
    AdminprofileComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
    GenericDeeplinkComponent,
    CropImageComponent,
    ChangeEmailComponent,
    TasklibraryComponent,
    EdittaskComponent,
    PrefillComponent,
    ViewtaskComponent,
    AssigntaskComponent,
    TaskadminComponent,
    HcassigntaskComponent,
    RequestdetailsComponent,
    ReassignComponent,
    TaskviewhistoryComponent,
    TaskrequestComponent,
    GoogleAppleRawdataComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ActivationsearchRoutingModule,
    AdminusersRoutingModule,
    AppMasterRoutingModule,
    DoctorRoutingModule,
    HardsetotpRoutingModule,
    PatientRoutingModule,
    PaymentmappingRoutingModule,
    PaymentplanRoutingModule,
    ProfileimageRoutingModule,
    ActivationRoutingModule,
    CampaignRoutingModule,
    ClientRoutingModule,
    HcassignhomeRoutingModule,
    HcuserassignRoutingModule,
    PublishRequestRoutingModule,
    AdmindeletemodalRoutingModule,
    ChangepasswordRoutingModule,
    UserprofileviewRoutingModule,
    ImageCropperModule,
    PaymentleftnavModule,
    HcassignleftnavModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    TasklibraryModule,
    TasklibraryRoutingModule,
    SharedModule.forRoot(),
    MatSelectInfiniteScrollModule,
    MatSelectModule,
    PickerModule,
    MatTooltipModule
  ],
  entryComponents: [
    AdmindeletemodalComponent,
    CampaignviewComponent,
    GeneratecodebyrequestComponent,
    EdithclistComponent,
    ViewuserassignComponent,
    ApproveDialogComponent,
    ViewcommentComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
    ViewtaskComponent,
    AssigntaskComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }