import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from 'src/app/healthcoach/services/socket/socket.service';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { BroadcastService } from '../../services/broadcast/broadcast.service';


@Component({
  selector: 'app-createbroadcast',
  templateUrl: './createbroadcast.component.html',
  styleUrls: ['./createbroadcast.component.scss']
})

export class CreatebroadcastComponent implements OnInit {
  public broadcastForm: FormGroup;
  public brodcastCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);/*true ti show submit button */
  public categories: Array<{ value: string, text: string }> = [{
    value: 'Greeting',
    text: 'Greeting'
  },
  {
    value: 'Meal',
    text: 'Meal'
  },
  {
    value: 'Blood Sugar',
    text: 'Blood Sugar'
  },
  {
    value: 'Weight',
    text: 'Weight'
  },
  {
    value: 'Activity',
    text: 'Activity'
  },
  {
    value: 'Protip',
    text: 'Protip'
  },
  {
    value: 'Explanatory',
    text: 'Explanatory'
  },
  {
    value: 'Medication',
    text: 'Medication'
  },
  {
    value: 'TextSMS',
    text: 'TextSMS'
  },
  {
    value: 'Others',
    text: 'Others'
  },
  ];
  public uploadedfile: any;
  public responseUrl: any;
  public isFileUploaded: boolean = false;
  public postuploads3response: any;
  public imageData: any;
  public video: any;
  public videomimeType: string;
  public videosource: any;
  public audio: any;
  public audiomimeType: string;
  public audiosource: any;
  public isPDF: boolean = false;
  public showprev: boolean = false;
  public isloading: boolean = false;

  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;


  constructor(public formBuilder: FormBuilder, private broadcastService: BroadcastService,
    private browserTitle: Title,
    private sanitizer: DomSanitizer, private systemService: SystemService, private socketService: SocketService,
    public dialogRef: MatDialogRef<CreatebroadcastComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Update Broadcast');
  }

  ngOnInit() {
    this.setSEOTags();
    this.buildBrodcastForm();
  }

  buildBrodcastForm(): void {
    this.broadcastForm = this.formBuilder.group({
      hc_id_data_fk: [localStorage.getItem('scarletuserid')],
      name: ["", [Validators.required, whiteSpaceValidation]],
      type: ["", [Validators.required, whiteSpaceValidation]],
      message: ["", [Validators.required, whiteSpaceValidation]],
      media_url: [""],
      mime_type: [""]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }


  // Uploading file to S3
  // ----------------------------

  broadcastObject(): void {
    this.isloading = true;
    var file = null;
    if (this.myInputVariable.nativeElement.files[0]) {
      file = this.myInputVariable.nativeElement.files[0];
      this.brodcastCreationSubject$.next(false);
      this.socketService.fileEvent(file, parseInt(localStorage.getItem('scarletuserid')), data => {
        if (data != false) {
          this.createBroadCast(data, file.type);
        }
        else {
          this.isloading = false;
          this.systemService.showSnackBar("Unable to upload files.", "Ok");
          this.brodcastCreationSubject$.next(true);
        }
      });
    } else {
      this.createBroadCast(null, null);
    }
  }

  // ----------------------------


  // Creating a broadcast 
  // ----------------------------

  createBroadCast(path, type): void {
    if ((type != null) && (path != null)) {
      this.broadcastForm.value.media_url = path;
      this.broadcastForm.value.mime_type = type;
    }
    this.broadcastForm.value.name = this.removeHTMLtags(this.broadcastForm.value.name)
    this.broadcastForm.value.message = this.removeHTMLtags(this.broadcastForm.value.message)
    this.broadcastService.createBrodcast(this.broadcastForm.value)
    .subscribe(
      (res: any) => this.createBroadCastSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private createBroadCastSuccess (data) {
    this.brodcastCreationSubject$.next(true);
    if (data.status == 200) {
      this.systemService.showSnackBar("Successfully created broadcast", "OK");
      this.isloading = false;
      this.closeDialog();
    } else {
      this.systemService.showSnackBar(data.message, "OK");
    }
  }

  // ----------------------------


  // Generating preview of the uploaded file 
  // ----------------------------

  previewFile(event): void {
    var input = event.target;
    if (input.files[0].size > 25000000) {
      this.imageData = null;
      this.video = null;
      this.audio = null;
      this.isPDF = null;
      this.myInputVariable.nativeElement.value = "";
      this.systemService.showSnackBar("File size exceed 25mb", "OK");
    }
    else {
      if (input.files && input.files[0] && (input.files[0].type == 'image/jpeg' || input.files[0].type == 'image/png')) {
        this.video = null;
        this.audio = null;
        this.isPDF = null;
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target;
          this.imageData = this.imageData.result;
        }
        reader.readAsDataURL(input.files[0]);
      } else if (input.files && input.files[0] && (input.files[0].type == 'video/mp4' || input.files[0].type == 'video/webm' || input.files[0].type == 'video/avi' || input.files[0].type == 'video/mov')) {
        this.imageData = null;
        this.isPDF = null;
        this.audio = null;
        var path = URL.createObjectURL(input.files[0]);
        this.video = {
          sources: [{
            src: path,
            type: input.files[0].type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.videomimeType = input.files[0].type;
        this.videosource = path;
        this.videosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.videosource);

      } else if (input.files && input.files[0] && (input.files[0].type == 'audio/mp3' || input.files[0].type == 'audio/webm' || input.files[0].type == 'audio/avi' || input.files[0].type == 'audio/mov')) {
        this.imageData = null;
        this.isPDF = null;
        this.video = null;
        var audiopath = URL.createObjectURL(input.files[0]);
        this.audio = {
          sources: [{
            src: path,
            type: input.files[0].type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.audiomimeType = input.files[0].type;
        this.audiosource = audiopath;
        this.audiosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.audiosource);
      } else if (input.files && input.files[0] && input.files[0].type == 'application/pdf') {
        this.imageData = null;
        this.video = null;
        this.audio = null;
        this.isPDF = true;
      }
      this.showprev = true;
    }
  }

  // ----------------------------
  
  closeCreateBroadcast() {
    this.dialogRef.close();
  }

  close(): void {
    this.imageData = null;
    this.video = null;
    this.audio = null;
    this.isPDF = null;
    this.myInputVariable.nativeElement.value = "";
  }
  trigger(e) {

    if (e == 'image') {
        $('#inputFile').attr("accept", "image/*")
        $('#inputFile').trigger('click');
    }
    else if (e == 'video') {
        $('#inputFile').attr("accept", "video/*")
        $('#inputFile').trigger('click');
    }
    else if (e == 'audio') {
        $('#inputFile').attr("accept", "audio/*")
        $('#inputFile').trigger('click');
    }
    else if (e == 'other') {
        $('#inputFile').attr("accept", "application/pdf")
        $('#inputFile').trigger('click');
    }
}
removeHTMLtags(value:string){
  return value.replace( /(<([^>]+)>)/ig, '');
}
}
