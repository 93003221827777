import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocleftnavModule } from '../../shared/docleftnav/docleftnav.module';
import { DocLabReportsModule } from '../doclabreports/doclabreports.module';
import { DoctorGraphModule } from '../doctorgraph/doctorgraph.modules';
import { DocuserlistnavbarModule } from '../docuserlistnavbar/docuserlistnavbar.module';
import { knowUserModule } from '../doctor-notes/doctornotes.module';
import { OtherdetailsModule } from '../otherdetails/otherdetails.module';
import { SharedModule } from './../../../shared/shared.module';
//import { DeeplinkbottomsheetComponent } from './deeplinkbottomsheet/deeplinkbottomsheet.component';
//import { GeneralqrbottomsheetComponent } from './generalqrbottomsheet/generalqrbottomsheet.component';
//import { GenericDeeplinkComponent } from './genericdeeplink/genericdeeplink.component';
//import { QrbottomsheetComponent } from './qrbottomsheet/qrbottomsheet.component';
//import { MealbreakupComponent as DocMealBreakupComponent } from './../components/mealbreakup/mealbreakup.component';
import { AlertsService } from './../../services/alerts/alerts.service';
import { DoctorDataService } from './../../services/doctor/doctor.service';
import { PatientDataService } from './../../services/patient/patient.service';
import { UserListService } from './../../services/userlist/user-list.service';
import { GeneraldataComponent } from './generaldata.component';
import { GeneraldataRoutingModule } from './generaldata.routing.module';

//import { DoctorgraphModule } from './../doctorgraph/doctorgraph.module';

@NgModule({
  declarations: [
    //DocMealBreakupComponent,
    GeneraldataComponent,
    //ActivatebottomsheetComponent,
    //DeeplinkbottomsheetComponent,
    //QrbottomsheetComponent,
   // GeneralqrbottomsheetComponent,
   // GenericDeeplinkComponent,
  ],
  imports: [
    CommonModule,
    GeneraldataRoutingModule,
    DoctorGraphModule,
    DocLabReportsModule,
    OtherdetailsModule,
    DocleftnavModule,
    DocuserlistnavbarModule,
    knowUserModule,
    SharedModule.forRoot(),
    
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService,
  ],
  /*entryComponents: [
    DocMealBreakupComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
  ]*/
})
export class GeneraldataModule { }
