import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from '../../healthcoach/services/socket/socket.service';

@Injectable({
  providedIn: 'root'
})

export class OfflineMessagesService {

  public messages = new BehaviorSubject([]);
  public messagesForRendering = new BehaviorSubject([]);

  constructor(
    private socketService: SocketService,
  ) { }

  fetchOfflineChats() {

    let offlineMessagesStr = localStorage.getItem('offlineMessages');

    if (offlineMessagesStr !== null) {
      let offlineMessages = JSON.parse(offlineMessagesStr);

      this.setLocalMessages(offlineMessages);
    }
  }

  deleteSentChat() {

    let currentOfflineMessages = this.messages.getValue();
    let currentRenderedMessages = this.messagesForRendering.getValue();

    currentOfflineMessages.splice(0, 1);
    currentRenderedMessages.splice(0, 1);

    this.messages.next(currentOfflineMessages);
    this.messagesForRendering.next(currentRenderedMessages);

    localStorage.setItem('offlineMessages', JSON.stringify(currentOfflineMessages));

  }

  startSendingMessages() {

    let offlineChats = this.messages.getValue();

    if (offlineChats.length > 0) {

      this.socketService.emitNewMessage(offlineChats[0]).subscribe((data) => {
        if (data) {
          this.deleteSentChat();
          this.startSendingMessages();
        }
      });

    } else {
      window.location.reload();
    }
  }

  storeOfflineChats(chatObj) {

    let currentOfflineMessages = this.messages.getValue();

    currentOfflineMessages.push(chatObj);

    this.messages.next(currentOfflineMessages);

    let obj = {
      attachment: null,
      id: chatObj.conversationId,
      trackId: chatObj.trackId,
      sender: chatObj.sender,
      mimeType: "text/plain",
      timestamp: new Date().getTime(),
      body: chatObj.body,
      replyTo: null,
      clientId: chatObj.clientId,
      is_deleted: null,
      is_user_liked: null,
      is_hc_liked: null,
      updated_at: null,
      showdate: true,
      is_offline_msg: true
    }

    this.messagesForRendering.next([...this.messagesForRendering.getValue(), obj]);

    localStorage.setItem('offlineMessages', JSON.stringify(currentOfflineMessages));

  }

  setLocalMessages(offlineMessages) {
    this.messages.next(offlineMessages);

    let chatsForRendering = [];

    for (let message of offlineMessages) {
      let obj = {
        attachment: null,
        id: message.conversationId,
        trackId: message.trackId,
        sender: message.sender,
        mimeType: "text/plain",
        timestamp: new Date().getTime(),
        body: message.body,
        replyTo: null,
        clientId: message.clientId,
        is_deleted: null,
        is_user_liked: null,
        is_hc_liked: null,
        updated_at: null,
        showdate: true,
        is_offline_msg: true
      }

      chatsForRendering.push(obj);
    }

    this.messagesForRendering.next(chatsForRendering);
  }

}
