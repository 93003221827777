import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NavigatorComponent } from './navigator/navigator.component';
// Healthcoach Dashboard
// Therapy Dashboard
// import { LanguageComponent } from './content/language/language.component';
// import { EditlanguageComponent } from './content/language/editlanguage/editlanguage.component';
// import { ConditionsComponent } from './content/therapysetup/conditions/conditions.component';
// import { EditconditionsComponent } from './content/therapysetup/conditions/editconditions/editconditions.component';
// import { HclabreportComponent as HealthCoachHclabreportComponent } from './healthcoach/modals/hclabreport/hclabreport.component';
// HC assignment in Admin Dashboard
import { RelesenoteComponent } from './relesenote/relesenote.component';
import { EnsureAuthenticatedService, LoginRedirectService } from './shared/services';
import { SetdoctorpasswordComponent } from './set-doctor-password/setdoctorpassword/setdoctorpassword.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginRedirectService] },
  { path: 'forgotPassword', component: ForgotPasswordComponent},
  { path: '', redirectTo: 'login', pathMatch: 'full', canActivate: [EnsureAuthenticatedService] },
  { path: 'setDoctorPassword', component: SetdoctorpasswordComponent},
  { path: 'application', component: NavigatorComponent, canActivate: [EnsureAuthenticatedService] },

  {
    path: 'doctor',
    loadChildren: () => import('./doctor/doctor.module').then(m => m.DoctorModule), canActivate: [EnsureAuthenticatedService]
  },
  { path: 'healthcoach', loadChildren: () => import('./healthcoach/healthcoach.module').then(m => m.HealthcoachModule), canActivate: [EnsureAuthenticatedService] },
  { path: 'content', loadChildren: () => import('./content/content.module').then(m => m.ContentModule), canActivate: [EnsureAuthenticatedService] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [EnsureAuthenticatedService] },
  { path: 'release', component: RelesenoteComponent },

];
// const routerOptions: ExtraOptions = {
//   useHash: true,
//   anchorScrolling: "enabled",
//   onSameUrlNavigation: "reload",
//   enableTracing: false,
//   scrollPositionRestoration: "enabled"
// };

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }