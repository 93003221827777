<div class="profile-wrapper">
  <div class="profile-info clearfix">
      <div class="media">
        <div class="media-body media-middle">
          <h4 class="media-heading">Akash Kumar</h4>
          <div class="profile-content">Male | 28Yrs | 8732123456</div>
        </div>
    </div>
      <div class="close-icon">
        <i class="material-icons mat-dialog-close">
          close
        </i>
      
    </div>

  </div>
  <div class="hc-details">
    <div class="text-over">User Details</div>
    <div class="row">
      <div class="col-md-3">
        <div class="email">
          <h5 class="text-uppercase">BMI</h5>
          <p>24</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="location">
          <h5 class="text-uppercase">Weight</h5>
          <p>65Kg</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="location">
          <h5 class="text-uppercase">Height</h5>
          <p>173</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="location">
          <h5 class="text-uppercase">Diet</h5>
          <p>Non Veg</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="about-me mt-30">
          <h5 class="text-uppercase">Preferred Language</h5>
          <p>English</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="about-me mt-30">
          <h5 class="text-uppercase">Diabetes Since</h5>
          <p>18 Feb 1999</p>
        </div>
      </div>
    </div>
  </div>

  <div class="hc-details assignment-parameters">
    <div class="text-over">Program Details</div>
    <div class="row">
      <div class="col-md-3">
        <div class="program">
          <h5 class="text-uppercase">Program name</h5>
          <p>Diabetes Oral</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program">
          <h5 class="text-uppercase">Start Date</h5>
          <p>08 Nov 2019</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program">
          <h5 class="text-uppercase">End Date</h5>
          <p>08 Nov 2020</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program">
          <h5 class="text-uppercase">Days Remaining</h5>
          <p>127 Days</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program  mt-30">
          <h5 class="text-uppercase">Sub Types</h5>
          <p>Hyper Tension</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program  mt-30">
          <h5 class="text-uppercase">Client Name</h5>
          <p>Novartis</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program  mt-30">
          <h5 class="text-uppercase">Campaign Name</h5>
          <p>B2C</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="program  mt-30">
          <h5 class="text-uppercase">Activation Code</h5>
          <p>A823F0</p>
        </div>
      </div>
    </div>
  </div>
</div>


