<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
      <app-adminsectionleftnav></app-adminsectionleftnav>
      <div class="mainrightcontainersecondary">
        <app-breadcrumb route="admin/mobilechange"></app-breadcrumb>

        <div class="row">
          <div class="col-md-12">
            <form class="search-form">
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="full-width-formfield">
                    <mat-label>Search</mat-label>
                    <input matInput [(ngModel)]="searchTerm" name="search-term" placeholder="Search Mobile Number, HC name">
                </mat-form-field>
              </div>
              
              <div class="col-md-3">
                <button class="btn btn-primary search-btn" (click)="textSearch()">Search</button>
            </div>
            </form>
          </div>

          <div class="col-md-12">
            <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
            (scrolled)="getNextSet()" [scrollWindow]="false">
    
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">

                <ng-container matColumnDef="old_number">
                  <th mat-header-cell *matHeaderCellDef> Old Number </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.current_country_code}} - {{element.current_phone}}
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="new_number">
                  <th mat-header-cell *matHeaderCellDef> New Number </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.new_country_code}} - {{ element.new_phone}}
                  </td>
                </ng-container>
    
                
                <ng-container matColumnDef="reason">
                  <th mat-header-cell *matHeaderCellDef>Change Request Reason</th>
                  <td mat-cell *matCellDef="let element"> 
                   {{ element.reason }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef>Request Date</th>
                  <td mat-cell *matCellDef="let element"> 
                   {{ element.created_at | date:'dd/MM/yyyy,h:mm a'}}
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="request_by">
                  <th mat-header-cell *matHeaderCellDef>Change Request By</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.requester_user_name}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.request_status === null">
                      Action Pending
                    </ng-container>
                    <ng-container *ngIf="element.request_status === 1">
                      Approved
                    </ng-container>
                    <ng-container *ngIf="element.request_status === 2">
                      Rejected
                    </ng-container>
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="action-button-row">

                      <ng-container *ngIf="element.request_status === null">
                        <button mat-button (click)="requestAction(element.id, 1)">
                          <img src="assets/img/dls/icons/actiontable/Icon_Approve.svg" tooltip="Approve" alt="Approve Icon">
                        </button>
  
                        <button mat-button (click)="requestAction(element.id, 2)">
                          <img src="assets/img/dls/icons/actiontable/Icon_Reject.svg" tooltip="Reject" alt="Reject Icon">
                        </button>
                      </ng-container>
                      
                      <ng-container *ngIf="element.request_status !== null">
                        <button mat-button style="opacity:0.5;cursor: not-allowed;">
                          <img src="assets/img/dls/icons/actiontable/Icon_Approve.svg" tooltip="Approve" alt="Approve Icon">
                        </button>
  
                        <button mat-button style="opacity:0.5;cursor: not-allowed;">
                          <img src="assets/img/dls/icons/actiontable/Icon_Reject.svg" tooltip="Reject" alt="Reject Icon">
                        </button>
                      </ng-container>

                    </div>
                  </td>
                </ng-container>
               
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                </tr>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</mat-drawer-container>
