<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/levelhome/leveledit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary mb-20">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Level
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="row">
                        <div class="col-md-12 leftformsection">

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Level Name</mat-label>
                                <input matInput placeholder="Enter the name" formControlName="title" required>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Description</mat-label>
                                <input matInput placeholder="Enter the description" formControlName="detailed_text">
                            </mat-form-field> -->

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Complexity Level</mat-label>
                                        <mat-select formControlName="complexity_level" required>
                                            <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                            <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                            <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Image</mat-label>
                                <input matInput placeholder="Select Image" 
                                    formControlName="image_media" required readonly >
                                    <button type="button" matSuffix mat-flat-button  color="primary"  (click)="openmediabox()" style="width: 100px;bottom: 7px;">{{allmedia ? 'Edit' : 'Choose'}}</button>
                            </mat-form-field>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Modules Count</mat-label>
                                        <mat-select formControlName="module_count"
                                            (selectionChange)="createModuleOptions($event.value)" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                            <mat-option value="9">9</mat-option>
                                            <mat-option value="10">10</mat-option>
                                            <mat-option value="11">11</mat-option>
                                            <mat-option value="12">12</mat-option>
                                            <mat-option value="13">13</mat-option>
                                            <mat-option value="14">14</mat-option>
                                            <mat-option value="15">15</mat-option>
                                            <mat-option value="16">16</mat-option>
                                            <mat-option value="17">17</mat-option>
                                            <mat-option value="18">18</mat-option>
                                            <mat-option value="19">19</mat-option>
                                            <mat-option value="20">20</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12" *ngIf="modulecount">
                                    <div class="count-box">
                                        <p class="customlabel">Module</p>
                                        <div formArrayName="module_data">
                                            <div *ngFor="let moduledata of dataForm.controls.module_data.controls; let j=index;"
                                                [formGroupName]="j" class="boxthekeyvalue">
                                                <mat-form-field appearance="outline"
                                                    class="full-width-formfield featureimage">
                                                    <mat-label>Select the module</mat-label>
                                                    <mat-select formControlName="moduledata"
                                                        (selectionChange)="selectedOptions($event.value, j)" required
                                                        #singleSelect>
                                                        <mat-option>
                                                            <ngx-mat-select-search [clearSearchInput]="false"
                                                                [placeholderLabel]="'Search Item'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                [formControl]="searchmat" ngModel
                                                                (ngModelChange)="modelChanged($event, j)"
                                                                ngDefaultControl>
                                                                <div class="mat-select-search-custom-header-content notranslate">
                                                                    <i class="material-icons">
                                                                        search
                                                                    </i></div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option class="camelcased"
                                                            *ngFor="let module of filteredModules[j] | async; let i=index;"
                                                            [value]="module.id" [disabled]="disableOptions(module.id)">
                                                            {{module.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Tags</mat-label>

                                <mat-select formControlName="tags" required multiple #singleSelectTag>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i></div>
                                        </ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option class="camelcased select-tags-active"
                                        *ngFor="let tag of filteredTags | async" [value]="tag.id">

                                        {{tag.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Level</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="card">
                            <img *ngIf="allmedia" [src]="s3url + allmedia" class="img-responsive" />
                            <!-- <div *ngIf="!(dataForm?.value.title == '')">
                                <div *ngIf="!allmedia" class="no-media"></div>
                            </div> -->
                        </div>
                        <div class="content-holder">
                            <div class="content-text">
                                <div class="iphonetitle">
                                    {{dataForm?.value?.title}}
                                </div>
                                <div class="iphonesubtitle">
                                    {{dataForm?.value?.detailed_text}}
                                </div>
                            </div>
                            <div *ngFor="let sel of selectedoptn" class="text-card">
                                {{sel.title}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>