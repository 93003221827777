<mat-drawer-container class="sidenav-container" autosize>
    <app-adminleftnav></app-adminleftnav>

    <div class="sidenav-content">
        <div class="breadcrumbholder">
            <app-breadcrumb route="admin/payment/paymentedit"></app-breadcrumb>
        </div>

        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}}
                Payment Plan
            </h3>
        </div>

        <div class="col-md-12">
            <div class="form-wrapper">
                <form [formGroup]="dataForm" class="leftformsection" validate>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="image-placeholder" style="width:unset">
                                <mat-spinner class="upload-spinner" *ngIf="isloading"></mat-spinner>
                                <div class="profile-image" *ngIf="!isloading">
                                    <div class="profile-dummy-img" *ngIf="!uploadedparam">
                                        <img _ngcontent-nwm-c37="" class="" src="assets/img/icon-gallery.svg">
                                        <figcaption>Supported formats: .jpeg, .png, .jpg
                                            <br>
                                            Max size: ~5 MB
                                        </figcaption>
                                    </div>
                                    <img *ngIf="uploadedparam" class="image-data img-responsive"
                                        [src]="s3url + image_path">
                                </div>
                            </div>
                            <form [formGroup]="demoForm" (change)="uploadImage()" validate
                                [class]="isinvalid ? 'invalidcls' : ''">
                                <file-upload formControlName="files" simple accept=".jpeg,.png,.jpg"
                                    class="file-uplaod-html">
                                    <ng-template let-file #placeholder>
                                        <ng-container *ngIf="file; else emptyList">
                                            {{ img_file_name }}
                                        </ng-container>
                                        <ng-container *ngIf="!file">
                                            Choose a file...
                                        </ng-container>

                                    </ng-template>

                                    <ng-template let-control="control" #button>
                                        <i class="material-icons">
                                            cloud_upload
                                        </i>
                                    </ng-template>
                                </file-upload>

                            </form>
                        </div>

                        <div class="col-md-9">
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-12">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Title" formControlName="title" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-md-12">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput placeholder="Description" rows="10"
                                        formControlName="detailed_text" required></textarea>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-15 fbcheckbox checkbox-margin top-margin-sm">
                            <mat-checkbox value="checkedtriggers" formControlName="is_most_popular">
                                Is Most Popular
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Plan Type</mat-label>
                            <mat-select placeholder="Select Plan Type" class="select-role" formControlName="plan_type"
                                required>
                                <mat-option value="therapy">Therapy</mat-option>
                                <mat-option value="doctor">Doctor</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Payment Type</mat-label>
                            <mat-select (selectionChange)="subscriptionType($event.value)"
                                placeholder="Select Payment Type" class="select-role" formControlName="payment_type"
                                required>
                                <mat-option value="onetime">Onetime</mat-option>
                                <mat-option value="subscription">Subscription</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Price</mat-label>
                            <input type="number" matInput placeholder="price" formControlName="price" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-12">
                            <mat-label>Payment Duration</mat-label>
                            <mat-select placeholder="Select Payment Duration" class="select-role"
                                formControlName="payment_duration" required>
                                <mat-option value="7">1 week</mat-option>
                                <mat-option value="14">2 week</mat-option>
                                <mat-option value="21">3 week</mat-option>
                                <mat-option value="30">1 Month</mat-option>
                                <mat-option value="90">3 Month</mat-option>
                                <mat-option value="120">4 Month</mat-option>
                                <mat-option value="150">5 Month</mat-option>
                                <mat-option value="180">6 Month</mat-option>
                                <mat-option value="270">9 Month</mat-option>
                                <mat-option value="365">1 Year</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-12" *ngIf='showPaymentduration'>
                            <mat-label>Payment Frequency</mat-label>
                            <mat-select placeholder="Select Payment Frequency " class="select-role"
                                formControlName="payment_frequency" [required]='showPaymentduration'>
                                <mat-option value="1">1 times</mat-option>
                                <mat-option value="2">2 times</mat-option>
                                <mat-option value="3">3 times</mat-option>
                                <mat-option value="4">4 times</mat-option>
                                <mat-option value="5">5 times</mat-option>
                                <mat-option value="6">6 times</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Feature Count</mat-label>
                                        <mat-select formControlName="featurecount"
                                            (selectionChange)="generateOptions($event.value)">
                                            <mat-option value="0">0</mat-option>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4 </mat-option>
                                            <mat-option value="5">5 </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div formArrayName="features" class="col-md-12">
                                    <div *ngFor="let feature of dataForm.get('features')['controls']; let j=index;"
                                        [formGroupName]="j">
                                        <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                            <mat-label>Enter the feature</mat-label>
                                            <input matInput formControlName="feature" placeholder="Enter the feature">
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-12">
                        <button mat-flat-button color="primary" class="submit-btn mt-15" (click)="dataObject()"
                            [disabled]="!dataForm.valid || !uploadedparam">{{newitem ? 'Create' : 'Update'}}
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>

</mat-drawer-container>