
import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPersistenceContainer, PersistenceService, StorageType } from 'angular-persistence';
import { debug } from 'console';
import { post } from 'jquery';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { UserlistService } from 'src/app/healthcoach/services/userlist/userlist.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { ContextService } from '../../../services/context.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  searchcriteria: any;
  showloader: any;
  public page: number = 1;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: Section[];
  private tabID: number = 8;
  public emptyplaceholder: boolean = false;
  public status: any = 'all';
  public filtercategory: any = null;
  public filterval: any = null;
  public notiftype: any = 'all';
  public selectedUserID: any;
  public subscribeObj: any;
  private container: IPersistenceContainer;
  taskDetails: any;
  conditions: any;
  data: any = [];
  patentlanguageId = [1];
  public notificationtypes = [
    ['reminder_added_event,reminder_updated_event', 'Reminder', 7],
    ['medicine_added_event,medicine_updated_event', 'Medicine', 7],
    ['low_bp_event,high_bp_event', 'Blood Pressure', 5],
    ['low_blood_sugar_event,high_blood_sugar_event', 'Blood Sugar', 5],
    ['onboarding_event', 'Onboarded', null],
    ['prescription_upload,prescription_update', 'Prescription', 7],
    ['caregiver_added,caregiver_updated', 'Care Giver', 7],
    ['lab_report_event', 'Lab Report', 6],
    ['doctor_assignment_event,doctor_reassignment_event', 'Doctor Assigned', 7],
    ['patient_transfer_event', 'Patient Transferred', null],
    ['symptom_event', 'Symptom Event', 7],
    ['trigger_event', 'Trigger', 7],
    ['questionnaire_event', 'Questionnaire', 7],
    ['reminder_added_event', 'Reminder', 7],
    ['patient_note_event', 'Patient Note', 1],
    ['weight_event', 'Patient Note', 5],
    ['activity_event', 'Activity', 5],
    ['adverse_reaction_event', 'Adverse Event', 7],
    ['medication_added_event', 'medication_updated_event', 'Medication', 7],
    ['pump_added_event', 'pump_updated_event', 'Pump', 7],

  ]
  newTaskID: any
  hideTask: boolean = false;
  iscomplete: boolean = false;
  progressPatientLength: any;
  patientLength: any;
  enableTaskBtn: boolean = false;
  taskcompleteflag: any;
  selectedUserIDs: any = [];
  displayBtn: any;
  taskDetailsData: any;
  constructor(private route: ActivatedRoute,
    private AlertsService: AlertsService,
    private SystemService: SystemService,
    private appContext: ContextService,
    private persistenceService: PersistenceService,
    public commonService: CommonserviceService,
    private systemService: SystemService,
    private userListService: UserlistService, private router: Router) {
    this.container = this.persistenceService.createContainer(
      'com.scarlet.hc',
      { type: StorageType.LOCAL, oneUse: false }
    );
  }

  ngOnInit() {

    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.getAllTaskData();
        this.getProgress();
      }
    });
    setInterval(() => {

      let task = localStorage.getItem("taskcompleteflag");
      if (task != null || task != undefined) {
        this.taskcompleteflag = JSON.parse(localStorage.getItem("taskcompleteflag")).data;
      }

    }, 1000)

    this.displayBtn = localStorage.getItem('hcSelectedType');
    if ((this.displayBtn == 'activity' || this.displayBtn == 'clinical_parameter' || this.displayBtn == ' data_parameters')) {
      this.taskDetailsData = JSON.parse(localStorage.getItem("taskDetails"));
      this.appContext.multiUserSelectionUsers1.subscribe(data => {
        if (data != null) {
          this.selectedUserIDs = data;
          this.patentlanguageId = [1];
          for (let i = 0; i < this.selectedUserIDs.length; i++) {
            this.patentlanguageId.push(this.selectedUserIDs[i].language_data_fk);
          }
        }
      });
    }

  }


  getProgress() {
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      hc_id: localStorage.getItem('scarletuserid'),
    }
    this.commonService.getProgress(postData, 'getTaskProgress')
      .subscribe(
        (res: any) => {
          this.checkProgress(res),
            this.progressPatientLength = res.data.length
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  checkProgress(res) {
    var patientId = localStorage.getItem('selectedPatientId');
    var data = res.data.find(element => element.progress[0].patient_completed[0] == patientId);
    if (data) {
      let data1 = data.progress[0].patient_completed[0];
      if (patientId == data1) {
        this.iscomplete = true
      }
    }
    else {
      this.iscomplete = false
    }
    // this.patientLength = localStorage.getItem("patientLength");
    // let patientLength = parseInt(this.patientLength);
    // this.progressPatientLength = parseInt(this.progressPatientLength);
    // if (this.progressPatientLength == patientLength) {
    //   this.checkTaskComplete()
    // }
  }

  // checkTaskComplete() {
  //   
  //   this.enableTaskBtn = true;

  // }
  changeTab(tb) {
    for (var k = 0; k < this.notificationtypes.length; k++) {
      if ((this.notificationtypes[k][0] as string).includes(tb)) {
        if (this.notificationtypes[k][2] != null) {
          this.appContext.selectedTabActivity.next(this.notificationtypes[k][2] as number);
          this.appContext.changeWatcherp.next(true);
        }
      }
    }
  }
  // convertData(d) {
  //   return d.replace(/_/g, ' ');
  // }

  setstatus(type, value) {
    if (type == 'reminder_added_event' || type == 'reminder_updated_event') {
      if (value != null) {
        return JSON.parse(value).category ? JSON.parse(value).category : '' + " Reminder"
      }
      else {
        return "New Reminder"
      }
    }
    else if (type == 'medicine_added_event') {
      return "Medicine Added"
    }
    else if (type == 'medicine_updated_event') {
      return "Medicine Updated"
    }
    else if (type == 'low_bp_event') {
      return "Low Blood Pressure"
    }
    else if (type == 'high_bp_event') {
      return "High Blood Pressure"
    }
    else if (type == 'low_blood_sugar_event') {
      return "Low Blood Sugar"
    }
    else if (type == 'high_blood_sugar_event') {
      return "High Blood Sugar"
    }
    else if (type == 'onboarding_event') {
      return "On Boarded"
    }
    else if (type == 'prescription_upload') {
      return "Prescription Added"
    }
    else if (type == 'prescription_update') {
      return "Prescription Updated"
    }
    else if (type == 'caregiver_added') {
      return "Caregiver Added"
    }
    else if (type == 'caregiver_updated') {
      return "Caregiver Updated"
    }
    else if (type == 'lab_report_event') {
      return "Lab Report Added"
    }
    else if (type == 'doctor_assignment_event') {
      return "Doctor Assigned"
    }
    else if (type == 'doctor_reassignment_event') {
      return "Doctor Reassigned"
    }
    else if (type == 'patient_transfer_event') {
      return "Transferred to you"
    } else if (type == 'trigger_event') {
      return "Trigger Values Added"
    }
    else if (type == 'questionnaire_event') {
      return "Questionnaire Logged"
    }
    else if (type == 'symptom_event') {
      return "Symptom Added"
    }
    else if (type == 'daily_goals') {
      return "Daily Goals Added"
    }
    else if (type == 'peak_flow') {
      return "Peak Flow Added"
    }
    else if (type == 'patient_note_event') {
      return "Patient Note"
    }
    else if (type == 'activity_event') {
      return "Activity Event"
    }
    else if (type == 'blood_sugar_event') {
      return "Blood Sugar Added"
    }
    else if (type == 'weight_event') {
      return "Weight Added"
    }
    else if (type == 'adverse_reaction_event') {
      return "Adverse Reaction|Remodulin"
    }
    else if (type == 'medication_added_event') {
      return "Medication Added"
    }
    else if (type == 'medication_updated_event') {
      return "Medication Updated"
    }
    else if (type == 'pump_added_event') {
      return "Pump Added"
    }
    else if (type == 'pump_updated_event') {
      return "Pump Updated"
    }
    else {
      return type
    }
  }
  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }
  tableSort(e, typer) {
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
    this.notiftype = typer;
  }
  typeFilter(e) {
    var pru = JSON.stringify(e).replace('["', '').replace('"]', '').replace(/\"/g, '');
    this.filtercategory = (e != '' ? pru : null);
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
  }
  getNextPage() {

    this.page++;
    this.search();
  }


  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------

  // List Activity 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.AlertsService.getAllActivity(this.searchcriteria, this.page.toString(), "20", this.status, this.filtercategory, this.selectedUserID)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.SystemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = (data as any).data.notifications;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
    }

  }

  // ----------------------------

  // To get one task details

  getAllTaskData() {
    this.newTaskID = localStorage.getItem('newTaskID')
    if (this.newTaskID != null) {
      this.commonService.getOneTaskData(this.newTaskID)
        .subscribe(
          (res: any) => this.getOneTaskSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }

  }

  private getOneTaskSuccess(data) {
    data = data.data;
    this.taskDetails = data;

    this.conditions = data.filter_conditions_summary

  }

  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ',' + ' ' + year;
    }
  }


  dataObject() {
    this.userListService.current_____selectedpatient.subscribe(
      (response: any) => {

        this.data = response;
      },
      (error: any) => {

      },
      () => { }
    )
    let patientId = localStorage.getItem('selectedPatientId');
    var patientData = [];
    patientData.push(patientId);


    let type = this.data[0].type;
    let sub_type = this.data[0].sub_type;
    let operation = this.data[0].operation;
    let operation_type = this.data[0].operation_type;
    let value = this.data[0].value;
    let unit = this.data[0].unit;
    var progress = [{
      "type": type,
      "sub_type": sub_type,
      "operation": operation,
      "operation_type": operation_type,
      "value": value,
      "unit": unit,
      "patient_completed": patientData,
      "patient_ongoing": [],
      "patient_failed": []
    }]
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      created_by_fk: localStorage.getItem('scarletuserid').toString(),
      hc_id: localStorage.getItem('scarletuserid'),
      progress: JSON.stringify(progress)

    }
    this.commonService.TaskProgress(postData, 'createTaskProgress')
      .subscribe(
        (res: any) => this.onSendSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onSendSuccess() {
    this.systemService.showSuccessSnackBar(("The Task has been completed for this patient"), "OK");
    this.getProgress();
    // this.patientLength = localStorage.getItem("patientLength");
    // let patientLength = parseInt(this.patientLength);
    // this.progressPatientLength = parseInt(this.progressPatientLength)+1;
    // if (this.progressPatientLength == patientLength) {
    //   this.checkTaskComplete()
    // }

  }

  async submitTask() {
    localStorage.removeItem('taskcompleteflag');
    if ((this.displayBtn == 'activity' || this.displayBtn == 'clinical_parameter' || this.displayBtn == 'data_parameters')) {
      let userid: any;
      let taskProgressUserId: any = [];
      this.appContext.multiUserSelectionUsers1.subscribe(data => {
        if (data != null) {
          this.selectedUserIDs = data;
          this.patentlanguageId = [1];
          for (let i = 0; i < this.selectedUserIDs.length; i++) {
            this.patentlanguageId.push(this.selectedUserIDs[i].language_data_fk);
          }
        }
      });
      for (let i = 0; i < this.selectedUserIDs.length; i++) {
        userid = this.selectedUserIDs[i].user_id;
        taskProgressUserId.push(userid)
      }
      let data1: any=[];
      let progressVal:any=localStorage.getItem('progressPatients');
      data1 = JSON.parse(localStorage.getItem('progressPatients'));
   
      let taskProgress: any = []
      if (progressVal) {
        taskProgress = taskProgressUserId.concat(data1);
        data1 = [];
        progressVal=false;
      }
      else{
        taskProgress=taskProgressUserId
      }
      this.taskDetailsData = JSON.parse(localStorage.getItem("taskDetails"));
      let type: any = this.taskDetailsData.task[0].type ?? "";
      let sub_type: any = this.taskDetailsData.task[0].sub_type ?? "";
      let operation: any = this.taskDetailsData.task[0].operation ?? "";
      let operation_type: any = this.taskDetailsData.task[0].operation_type ?? "";
      let value: any = this.taskDetailsData.task[0].value ?? "";
      let unit: any = this.taskDetailsData.task[0].unit ?? "";
      var progress = [{
        "type": type,
        "sub_type": sub_type,
        "operation": operation,
        "operation_type": operation_type,
        "value": value,
        "unit": unit,
        "patient_completed": taskProgress,
        "patient_ongoing": [],
        "patient_failed": []
      }]
      await this.postProgressData(progress);
    }
    var task_library_id = localStorage.getItem('newTaskID');
    localStorage.removeItem('taskcompleteflag');
    this.router.navigate(['healthcoach/hctasks/hctaskcomplete/' + task_library_id])
  }


  async postProgressData(progress) {
    var postData = {
      task_library_id: localStorage.getItem('newTaskID'),
      created_by_fk: localStorage.getItem('scarletuserid').toString(),
      hc_id: localStorage.getItem('scarletuserid'),
      progress: JSON.stringify(progress)
    }
    await this.commonService.TaskProgress(postData, 'createTaskProgress')
      .subscribe((res: any) => {
        localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
}





export interface Section {
  name: string;
  updated: Date;
}