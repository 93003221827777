import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import {ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-campaigndeeplink',
  templateUrl: './campaigndeeplink.component.html',
  styleUrls: ['./campaigndeeplink.component.scss']
})
export class CampaigndeeplinkComponent implements OnInit {
  public image: any;
  cc: any;
  canvas: any;
  img: any = null;
  qrcode: any = null;
  gctx: any;
  imgurl: any;
  downloadOn: boolean = false;
  public alldata: any;
  public dataVal: any;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private browserTitle: Title,private commonService: CommonserviceService,private systemService: SystemService,private _bottomSheetRef: MatBottomSheetRef<CampaigndeeplinkComponent>,private cd: ChangeDetectorRef) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Campaign QR');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.dataObject();
  }


  //  Submitting User Data 
  // ----------------------------

  dataObject() {
    var postpredata = {
      "query" : "?campaign_id="+this.data.id+"&intent=campaign_activation",
      "type":"general"
    }
    this.commonService.campaignDeelink(postpredata)
    .subscribe(
      (res : any) => this.onSuccessData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  private onSuccessData(data) {
    var scope = this;
    if ((data as any).status == 200) {
      setInterval(() => {
        scope.alldata = (data as any).data;
        scope.cd.markForCheck();
      }, 1000);
    }
    else {
      this.systemService.showSnackBar((data as any).message, "OK");
      this._bottomSheetRef.dismiss();
    }
  }

  // ----------------------------

  //  To Copy URL
  // ----------------------------

  copyURL() {
    var abc = this.alldata.shortLink;
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = abc;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }

}



