import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { BehaviorSubject, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { catchError, map, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs/Observable';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UserListService {
  public apiUrl: string = environment.serverUrl;
  private prodUrl = this.apiUrl + 'doctor/';
  private profileUrl = this.apiUrl + 'doctor/user';
  private eligibleLog = this.apiUrl + 'users/logs';
  public cSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private _http: Http, private systemService: SystemService) { }

  /**
   * @name getAllUsers
   * @description Getting all patients under the doctor
   * @param No_param
   * @returns get response from API
   */
  getAllUsers(): Observable<string> {
    var SessionID = localStorage.getItem('scarlettoken');
    var doctorID = localStorage.getItem('scarletparentuserid');
    if (SessionID || SessionID !== '') {
      const endPoint = 'user/getAll';
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      headers.append('user_id', doctorID);
      let options = new RequestOptions({ headers: headers });
      return this._http
        .get(this.prodUrl + endPoint, options)
        .map(response => <string>response.json());
    }
  }

  public getPatientsByDoctor(query, status, page, limit): Observable<any> {
    
    var SessionID = localStorage.getItem('scarlettoken');
    var doctorID = localStorage.getItem('scarletuserid');
    query = this.removeHTMLtags(query);
    const params: any = {
      doctor_id: doctorID,
      q: query,
      status: status,
      page: page,
      limit: limit
    };
    let headers = new HttpHeaders().set('x-access-token', SessionID);
    const endPoint = 'patient/mapping/search';
    return this.http
      .post<any>(this.apiUrl + endPoint, params, { headers: headers })
      .pipe(
        retry(1),
        map(data => {
          let dataToCache = data.data;
          return dataToCache;
        }),
        catchError(this.handleError('getPatientsByDoctor'))
      ) as Observable<any>;
  }

  /**
   * @name getEligibalLogs
   * @description Getting all eligible mapping data from server for lab reports
   * @param userid
   * @returns get response from API
   */
  getEligibalLogs(userid): Observable<string> {
    var SessionID = localStorage.getItem('scarlettoken');
    if (SessionID || SessionID !== '') {
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      headers.append('userID', userid);
      let options = new RequestOptions({ headers: headers });
      return this._http
        .get(this.eligibleLog, options)
        .map(response => <string>response.json());
    }
  }

  /**
   * @name getOneUserData
   * @description Getting the particular selected patient data
   * @param userid
   * @returns get response from API
   */
  getOneUserData(userid): Observable<string> {
    var SessionID = localStorage.getItem('scarlettoken');
    if (SessionID || SessionID !== '') {
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      // headers.append('id', doctorID);
      headers.append('userID', userid);

      let options = new RequestOptions({ headers: headers });
      return this._http
        .get(this.profileUrl, options)
        .map(response => <string>response.json());
    }
  }

  /**
   * @name updateAgreement
   * @description Updating the parameter for Service level agreement
   * @param No_param
   * @returns put response from API
   */
  updateAgreement(): Observable<[string]> {
    var doctorID = localStorage.getItem('scarletparentuserid');
    var SessionID = localStorage.getItem('scarlettoken');
    if (SessionID || SessionID !== '') {
      const endPoint = 'updateagreementstatus';
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      headers.append('id', doctorID);
      let options = new RequestOptions({ headers: headers });
      return this._http
        .put(this.prodUrl + endPoint, {}, options)
        .map(response => <[string]>response.json());
    }
  }

  updatePassword(newpassword): Observable<[string]> {
    var doctorID = localStorage.getItem('scarletuserid');
    var SessionID = localStorage.getItem('scarlettoken');
    newpassword = this.removeHTMLtags(newpassword);
    if (SessionID || SessionID !== '') {
      const endPoint = 'account/password/reset';
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      headers.append('user_id', doctorID);
      headers.append('new_password', newpassword);
      let options = new RequestOptions({ headers: headers });
      return this._http
        .put(this.apiUrl + endPoint, {}, options)
        .map(response => <[string]>response.json());
    }
  }

  /**
   * @name followUp
   * @description Updating the parameter for followup
   * @params user_ids,needs_followup
   * @returns put response from API
   */
  followUp(user_ids: any, needs_followup: boolean): Observable<[string]> {
    var SessionID = localStorage.getItem('scarlettoken');
    if (SessionID || SessionID !== '') {
      const endPoint = 'user/followup';
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      // headers.append('id', doctorID);
      let options = new RequestOptions({ headers: headers });
      return this._http
        .put(
          this.prodUrl + endPoint,
          { user_ids: user_ids, needs_followup: needs_followup },
          options
        )
        .map(response => <[string]>response.json());
    }
  }

  private handleError(operation: string = 'operation') {
    return (error: any) => {
      error.operation = operation;
      this.systemService.handleErrorResponse(error);
      return throwError(error);
    };
  }
  
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

  getAge(dateString) {
    let fulldate = dateString
    dateString = formatDate(dateString, 'dd/MM/yyyy', 'en-US');
    let  now = new Date();
    let  yearNow = now.getFullYear();
    let monthNow = now.getMonth() + 1;
    let dateNow = now.getDate();
  
    var dob = new Date(fulldate);
  
    let  yearDob = dob.getFullYear();
    let  monthDob = dob.getMonth()+ 1 ;
    let  dateDob = dob.getDate();
    let  age;
    let  ageString = "";
    let  yearString = "";
    let  monthString = "";
    let  dayString = "";
    let monthAge ;
  
    let yearAge = yearNow - yearDob;
  
    if (monthNow >= monthDob)
    monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }
  
    if (dateNow >= dateDob)
      var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;
  
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
  
     age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
        };
  
    if ( age.years > 1 ) yearString = " years";
    else yearString = " year";
    if ( age.months> 1 ) monthString = "m";
    else monthString = "m";
    if ( age.days > 1 ) dayString = " d";
    else dayString = " d";
  
  
    if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
      ageString = age.years + yearString +" "+ age.months + monthString;
    else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
      ageString = "Only " + age.days + dayString;
    else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
      ageString = age.years + yearString;
    else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
      ageString = age.years + yearString +" "+ age.months + monthString ;
    else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
      ageString = age.months + monthString + age.days + dayString;
    else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
      ageString = age.years + yearString;
    else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
      ageString = age.months + monthString;
    else ageString = "";
  
    return ageString;
  }
}
