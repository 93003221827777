import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ProfileimageRoutingModule } from './profileimage.routing.module';
import { ProfileimageComponent } from './profileimage.component';

@NgModule({
  declarations: [
    
    ProfileimageComponent
    
  ],
  imports: [
    CommonModule,
    ProfileimageRoutingModule,
    ImageCropperModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class ProfileimageModule {}