import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, delay, finalize } from 'rxjs/internal/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from '../../../shared/services';
import { Userdetailsinterface } from '../../models/userdetailsinterface';
import { ContextService } from '../../services/context.service';
import { SocketService } from '../../services/socket/socket.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';
import { UserlistService } from '../../services/userlist/userlist.service';
import { DeeplinkComponent } from '../deeplink/deeplink.component';
import { PaymentlinkComponent } from '../paymentlink/paymentlink.component';
import { HttpErrorResponse } from '@angular/common/http';

import { ConnectionService } from 'ng-connection-service';
import { OfflineMessagesService } from 'src/app/shared/services/offline-messages.service';

declare var $: any;

@Component({
    selector: 'app-chatsection',
    templateUrl: './chatsection.component.html',
    styleUrls: ['./chatsection.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})

export class ChatsectionComponent implements OnInit {

    public userName$: Subject<string> = this.appContext.userName;
    public gestationalAge: Subject<string> = this.appContext.gestationalAge;
    public userCurrentStatus$: Subject<string> = this.appContext.userCurrentStatus;
    public userDetailsSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadingError$: Subject<boolean> = new Subject<boolean>();
    public chatForm = this.fb.group({
        messagetext: [null, Validators.required]
    });
    deletedId: any;
    patentlanguageId = [1];
    isGallery: boolean = false;
    onlinestatus: any;
    allchats = [];
    term: any;
    userLastSeen: any;
    timestamp: any;
    chattext: any;
    responseUrl: any;
    file: any;
    imageData: any;
    isPDF = false;
    video: any;
    videomimeType: any;
    videosource: any;
    audio: any;
    audiomimeType: any;
    audiosource: any;
    showprev = false;
    selectedFile: any;
    postuploads3response: any;
    uploaderror = false;
    uid: any;
    showTyper = false;
    layer: any;
    alluserset: any;
    recStatusval: any;
    lastID = null;
    chatlastID = null;
    isvisible: boolean = false;
    socket: any;
    convoid: any;
    initialdeliverystatus: any;
    initialseenstatus: any;
    convid: any;
    allconversations: any;
    lastMessageId: any;
    userid: any;
    showChatLoader = false;
    addedattachment: any;
    getthenewmessage: any;
    getallmessages: any;
    public changeDetector: any = null;
    checkconversationfetched: boolean = false;
    isnextpageclick: boolean = false;
    scrollDirection: string = "bottom";
    settonull = false;
    usermigrate: boolean;
    allmessageloaded: boolean = false;
    expiredvar: any;
    isexpired: boolean = false;
    showButtonLoader: boolean;
    public noappSelectedUserFound: boolean = true;
    isdownloadingreport: boolean = false;
    olduserid: any = null;
    isLoading: boolean = false;
    getreplyid: any;
    taskcompleteflag: boolean = false;
    public replyOn: any = null;
    public replydata: any;
    public startcallloader: boolean = false;
    public calllisting: any;
    public optiontype: any = null;
    private deeplinkdailog: any;
    private paymentlinkdailog: any;
    private optionlink: any = null;
    private optionvalue: any = null;
    isTemplate!: boolean;
    urlDetect!: boolean;
    id: any;
    description: any;
    //disableSubmit: boolean = false;
    deeplink: any;
    typeValue: any;
    displayBtn: string;
    isCall: boolean;
    isChat: boolean;
    filteredIcons: any = ["edit"]
    isNormalChat!: boolean;
    public userClientDetails; any;
    getPermissionLink: any;
    templateData: any = [];
    private myCathash: any = {};
    thirdFormGroup: FormGroup;
    primarylanguage: any;
    alllanguages: any;
    selectedImage: any
    width: any = null;
    height: any = null;
    @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
    @ViewChild('scrollToBottom', { static: false }) scrollToBottomContainer: ElementRef;
    @ViewChild('virtualScroller', { static: false }) virtualScroller: ElementRef;
    @ViewChild('RenderchatsComponent', { static: false }) childdata: ElementRef;
    @Input() isFullWidth: any;
    userIds: any = [];
    public selectedUserID: any = [];
    public subscribeObj: any;
    messageData: any;
    tabEventData: any;
    disableLang: any = [];
    callDurationhr: any;
    callDurationmin: any;
    taskDetails: any;
    callDurationsec: any;
    routeUrl: any;
    taskProgressUserId: any = [];
    attachment: any;

    isConnected = true;
    resync_start_date: any;
    resync_end_date: any;

    constructor(
        public commonService: CommonserviceService,
        public snackBar: MatSnackBar,
        private UserdataService: UserdetailsService,
        private appContext: ContextService,
        private socketService: SocketService,
        private _formBuilder: FormBuilder,
        private contentService: ContentserviceService,
        private systemService: SystemService,
        private router: Router,
        public fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private cdRef: ChangeDetectorRef,
        private UserlistService: UserlistService,
        private dialog: MatDialog,
        private connectionService: ConnectionService,
        private offlineMessageService: OfflineMessagesService
    ) {

        this.routeUrl = this.router.url;
    }

    ngOnInit() {

        this.connectionService.monitor().subscribe(connectionStatus => {
            this.isConnected = connectionStatus;
        });

        this.getLanguages();
        this.taskDetails = JSON.parse(localStorage.getItem("taskDetails"));
        this.displayBtn = localStorage.getItem('hcSelectedType');

        if (this.displayBtn == 'call' || this.displayBtn == 'patient_age' || this.displayBtn == 'whatsapp_call') {
            this.isCall = true;
            this.isChat = false;
            this.isNormalChat = false;
            this.appContext.multiUserSelectionUsers1.subscribe(data => {
                if (data != null) {
                    this.selectedUserID = data;

                    this.patentlanguageId = [1];
                    for (let i = 0; i < this.selectedUserID.length; i++) {
                        this.patentlanguageId.push(this.selectedUserID[i].language_data_fk);
                    }
                }
            });
        } else if ((this.displayBtn == 'chat' || this.displayBtn == 'custom_broadcast' || this.displayBtn == 'whatsapp_chat')) {
            this.isChat = true
            this.isCall = false;
            this.isNormalChat = false;
            this._languageData();
            this.appContext.multiUserSelectionUsers1.subscribe(data => {
                if (data != null) {
                    this.selectedUserID = data;

                    this.patentlanguageId = [1];
                    for (let i = 0; i < this.selectedUserID.length; i++) {
                        this.patentlanguageId.push(this.selectedUserID[i].language_data_fk);
                    }
                }
            });
        } else if ((this.displayBtn == 'activity' || this.displayBtn == 'clinical_parameter' || this.displayBtn == 'data_parameters')) {
            this.isNormalChat = true;
            this.isChat = false;
            this.isCall = false;
        } else {
            this.isNormalChat = true;
            this.isChat = false;
            this.isCall = false;
        }

        this.allchats = [];
        this.callList();

        if (localStorage.getItem('switchId')) {
            this.chatForm.disable();
        }

        this.getreplyid = this.socketService.replytoid.subscribe(data => {
            this.replydata = data;
        })

        this.getthenewmessage = this.socketService.newMessages.subscribe(data => {
            this.chatlastID = data.id;
            if (this.appContext.selectedChatUserRowData.getValue() != null) {
                if (data.type === 'conversation' || data.type === 'payment' || data.type === 'deeplink' || data.type === 'insightChat'){
                 this.CueChatLogic();
                }
                this.onNewMessageArrival(data);
            }
        });

        let scope = this;
        this.uid = localStorage.getItem('scarletuserid');

        this.appContext.selectedChatUser.subscribe(data => {

            scope.isexpired = false;
            if (data != null) {
                this.getotherdetails(data, response => {
                    if (response != null) {
                        scope.isexpired = response.is_expired
                    }
                })
            }
            this.imageData = null;
            this.video = null;
            this.audio = null;
            this.isPDF = null;
            this.optiontype = null;

            if (data == null || data == "") {
                /**No selected user found */
                this.noappSelectedUserFound = true;
                return;
            } else {
                this.noappSelectedUserFound = false;
            }
            if (data) {
                /**Detect local changes first */
                scope.allchats = [];
                scope.isvisible = false;
                this.changeDetector = data; //this.appContext.selectedChatUser.getValue();
                this.userid = data; //this.appContext.selectedChatUser.getValue();
                scope.convid = (scope.appContext.selectedChatUserRowData.getValue() as any).chatMetadata[0];;
                scope.CueChatLogic();
            }
            this.initComponentDrawDetails(this.appContext.selectedChatUser.getValue());

        });


        this.getPermissionLink = this.appContext.messageSource.subscribe(data => {
            // this.chatForm.controls.messagetext.patchValue(data);
            this.addedattachment = "deeplink"
            this.optiontype = "deeplink"
            if (data.link) {
                this.optionlink = data.link
            } else {
                this.optionlink = ""
            }
            if(data.hk_start_date){
                this.resync_start_date = data.hk_start_date
            }
            if(data.hk_end_date){
                this.resync_end_date = data.hk_end_date
            }
            this.optionvalue = data.type,
            this.typeValue = data.type,

            this.sendMessage();
        });

        this.thirdFormGroup = this._formBuilder.group({
            template: this.fb.array([
                // this.templateFormSelection(this.language),
            ]),
        });

        this.offlineMessageService.messagesForRendering.subscribe((data) => {
            if(this.allchats.length > 0){
                let allChats = this.allchats.filter((chat) => {
                    return chat.hasOwnProperty("is_offline_msg") ? false : true;
                });
    
                this.allchats = [...allChats, ...data];
    
                if (data.length > 0) {
                    this.recStatusval = "";
                }
            }
          
        });

    }


    checkData(data) {
        for (let i = 0; i <= this.selectedUserID.length; i++) {
            if (data.id1 != this.selectedUserID[i].language_data_fk) {
                return true;
            } else {
                return false;
            }
        }

    }
    _languageData() {
        this.commonService.current____languageData.subscribe(
            (response: any) => {
                if (response == null) {
                    return;
                } else {
                    this.templateData = response;
                    console.log("this.templateData...." + JSON.stringify(this.templateData));
                }
            },
            (error: any) => {

            },
            () => { }
        )
    }

    getLanguages() {
        this.contentService
            .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name,short_code}}')
            .subscribe((res: any) => {
                this.getAllLanguageSuccess((res as any).data)
            },
                (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
            )
    }

    private getAllLanguageSuccess(data) {
        this.alllanguages = data.getAllLanguages;
        this.primarylanguage = this.alllanguages;
        // this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
        this.primarylanguage.forEach(element => {
            this.myCathash[element.id] = element.name;
        });
    }

    templateData1() {

        for (var i = 0; i < this.templateData.length; i++) {
            if (this.templateData[i].id == '1') {
                let objIndex = this.templateData.findIndex((obj => obj.id == 1));
                this.templateData[objIndex].id = "English";
            }
            if (this.templateData[i].id == '2') {
                let objIndex1 = this.templateData.findIndex((obj => obj.id == 2));
                this.templateData[objIndex1].id = "हिन्दी";
            }
            if (this.templateData[i].id == '3') {
                let objIndex2 = this.templateData.findIndex((obj => obj.id == 3));
                this.templateData[objIndex2].id = 'ಕನ್ನಡ';
            }
            if (this.templateData[i].id == '4') {
                let objIndex3 = this.templateData.findIndex((obj => obj.id == 4));
                this.templateData[objIndex3].id = 'বাংলা';
            }
            if (this.templateData[i].id == '5') {
                let objIndex4 = this.templateData.findIndex((obj => obj.id == 5));
                this.templateData[objIndex4].id = 'Español';
            }
        }
    }

    initComponentDrawDetails(selectedUserID: string) {

        this.loadingSubject$.next(true);
        /**Reinitilise user detail component */
        this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
        /**Call API */
        this.UserdataService.getUserProfileDetails(selectedUserID).pipe(
            delay(100),
            catchError((error: any) => {
                this.loadingError$.next(true);
                return throwError(error);
            }),
            finalize(() => { this.loadingSubject$.next(false); })
        ).subscribe(data => {
            data = (data as any).data;
            data = (data == null) ? [] : data;
            localStorage.setItem('campaign_id',data.campaign_data_id)
            this.userDetailsSubject$.next(data);
            if (data.patient_client_data) {
                this.userClientDetails = data.patient_client_data.title;
            }
            /**Change subject also */
            //this.appContext.isSelectedUserHasCKD.next((data.has_ckd ? data.has_ckd : false));
            /**false other subjects */
            this.loadingSubject$.next(false);
            this.loadingError$.next(false);
        });
    }

    calltoaction(knum) {
        this.getUserPhonenumber(this.userid, data => {
            this.UserdataService.makeAcall(knum, '+' + JSON.parse(localStorage.getItem('scarletuserdata')).std_code + JSON.parse(localStorage.getItem('scarletuserdata')).phone, '+' + data.country_code + data.phone, '+912248770333')
                .subscribe(data => {
                    if (data.success.status != 'success') {
                        this.systemService.showSnackBar('Something went wrong', 'ok')
                    }
                    else {
                        this.startcallloader = true;
                        setTimeout(() => {
                            this.startcallloader = false;
                        }, 5000);
                    }
                })
        })
    }
    callList() {
        this.UserdataService.callList()
            .subscribe(data => {
                this.calllisting = data.objects;
            })
    }
    getUserPhonenumber(id, cb) {
        this.UserlistService
            .getGraphQlData('{getOnePatient(appMode:false,patientId:' + id + '){country_code,phone}}')
            .pipe(
                catchError((error: any) => {
                    this.showButtonLoader = false;
                    this.systemService.showSnackBar(
                        error.error && error.error.error_message
                            ? error.error.error_message
                            : 'Sorry something went wrong.',
                        'OK'
                    );
                    return throwError(error);
                }),
                finalize(() => {
                    this.showButtonLoader = false;
                })
            )
            .subscribe(data => {
                data = (data as any).data;
                cb(data.getOnePatient)
            })
    }
    getotherdetails(id, cb) {
        this.UserlistService
            .getGraphQlData('{getOnePatient(appMode:false,patientId:' + id + '){is_expired}}')
            .pipe(
                catchError((error: any) => {
                    this.showButtonLoader = false;
                    this.systemService.showSnackBar(
                        error.error && error.error.error_message
                            ? error.error.error_message
                            : 'Sorry something went wrong.',
                        'OK'
                    );
                    return throwError(error);
                }),
                finalize(() => {
                    this.showButtonLoader = false;
                })
            )
            .subscribe(data => {
                data = (data as any).data;
                cb(data.getOnePatient)
            })
    }
    ngAfterViewInit() {
        if (!this.noappSelectedUserFound) {
            this.registerVirtualScrollSubject();
        }

    }

    registerVirtualScrollSubject() {
        let vScroller: any = this.virtualScroller;
        if (vScroller && vScroller.scrollDispatcher) {
            vScroller.scrollDispatcher._scrolled.subscribe(data => {
            });
        }
    }
    closereply() {
        this.replydata = null;
    }
    scrollToBottom(scope): void {
        if (!scope.virtualScroller)
            return;
        try {
            if (scope.scrollDirection == "bottom") {
                scope.virtualScroller._contentWrapper.nativeElement.scrollIntoView(false);
            } else {
                //scope.virtualScroller._contentWrapper.nativeElement.scrollTop = 500;
                scope.scrollDirection = "bottom";
            }
        } catch (err) {
        }
    }
    ngOnDestroy() {
        this.allchats = [];
        if (this.olduserid != null) {
            this.socketService.unWatchUser(this.userid).subscribe();
        }
    }

    CueChatLogic() {
        let scope = this;
        scope.allchats = [];
        if (localStorage.getItem('crtime')) {
            localStorage.removeItem('crtime');
        }

        if (scope.convid && (scope.convid.status == 'offline')) {
            this.onlinestatus = false;
            this.userLastSeen = scope.convid.last_seen == null ? 'user last seen data not available' : ('Last seen on ' + this.getTheTime(scope.convid['last_seen']));
        }

        if (scope.convid && scope.convid.status == 'online') {
            this.onlinestatus = true;
        }

        if (scope.convid) {
            scope.convoid = scope.convid.conv_id;
            scope.initialdeliverystatus = scope.convid.delivery_cursor;
            scope.initialseenstatus = scope.convid.read_cursor;
            let mjson = { "conversationId": scope.convoid, "type": "latestWithConversationId", "count": 20 };
            this.socketService.fetchAllMessages(mjson)
                .subscribe(data => {
                    this.ongettingallmessages(data, 'new');
                })
            if (this.olduserid != null) {
                this.socketService.unWatchUser(scope.userid).subscribe();
            }
            this.olduserid = this.userid;
            this.socketService.watchUser(scope.userid).subscribe();
        }
        else {
            scope.showChatLoader = false;
        }
    }

    close() {
        this.imageData = null;
        this.video = null;
        this.audio = null;
        this.isPDF = null;
        this.optiontype = null;
        this.myInputVariable.nativeElement.value = null;
    }

    ongettingallmessages(data, mtype) {


        let scope = this;
        let lastmessagetimestamp = "";
        let renderedchat = [];

        this.recStatusval = "Sent";
        this.replydata = null;

        if (!this.isnextpageclick) {
            this.allchats = [];
        }

        this.isvisible = (data.messages.length < 20) ? false : true;
        renderedchat = data.messages;

        let startdateset = false;
        let firstdt;
        let seconddt;

        for (var r = 0; r < renderedchat.length; r++) {
            if (r < renderedchat.length - 1) {
                if (startdateset == false) {
                    renderedchat[r]['showdate'] = true;
                    startdateset = true;
                }
                else {
                    if (renderedchat[r].sender == renderedchat[r + 1].sender) {
                        firstdt = new Date(renderedchat[r].timestamp);
                        seconddt = new Date(renderedchat[r + 1].timestamp);
                        if ((firstdt.getHours() * 60 + firstdt.getMinutes()) >= (seconddt.getHours() * 60 + seconddt.getMinutes() + 1)) {
                            renderedchat[r]['showdate'] = false;
                            startdateset = false;
                        }
                        else {
                            renderedchat[r]['showdate'] = false;
                            startdateset = true;
                        }
                        if (firstdt.getDate() != seconddt.getDate()) {
                            renderedchat[r]['showdate'] = false;
                            startdateset = false;
                        }
                    }
                    else {
                        renderedchat[r]['showdate'] = false;
                        startdateset = false;
                    }
                }
            }
            else {
                if (renderedchat[r - 1]) {
                    if (renderedchat[r - 1].sender != renderedchat[r].sender) {
                        renderedchat[r]['showdate'] = true;
                    }
                    else {
                        renderedchat[r]['showdate'] = false;
                    }
                }
            }
        }

        for (let q = 0; q < renderedchat.length; q++) {
            if (mtype == 'new') {
                scope.allchats.push(renderedchat[q]);
            }
            else {
                scope.allchats.unshift(renderedchat[q]);
            }
        }

        if (scope.allchats.length > 0) {
            scope.lastID = scope.allchats[0].id;
            scope.chatlastID = scope.allchats[this.allchats.length - 1].id;
        }

        let lastMessageIndex = data.messages.length - 1;

        if (data.messages[lastMessageIndex] && data.messages[lastMessageIndex].sender && data.messages[lastMessageIndex].sender == ('H' + scope.uid) && mtype == "new") {
            if (scope.convid['delivery_cursor'] == data.messages[lastMessageIndex].id) {
                scope.recStatusval = "Delivered";
            }
            if (scope.convid['read_cursor'] == data.messages[lastMessageIndex].id) {
                scope.recStatusval = "Read";
            }
        }

        if (data.messages[lastMessageIndex] && data.messages[lastMessageIndex].sender && data.messages[lastMessageIndex].sender != 'H' + scope.uid && mtype == "new") {
            let swithchId = sessionStorage.getItem('switchId');

            if (!swithchId) {
                let deliveryrec = { type: 'deliveryCursor', conversationId: scope.convoid, userId: 'H' + scope.uid, value: data.messages[data.messages.length - 1].id, participants: ['H' + scope.uid, data.messages[data.messages.length - 1].sender] };
                let readrec = { type: 'readCursor', conversationId: scope.convoid, userId: 'H' + scope.uid, value: data.messages[data.messages.length - 1].id, participants: ['H' + scope.uid, data.messages[data.messages.length - 1].sender] };
                this.socketService.setCursor(deliveryrec).subscribe();
                this.socketService.setCursor(readrec).subscribe();
            }

        }
        scope.showChatLoader = false;
        setTimeout(function () {
            scope.scrollToBottom(scope);
        }, 100);
    }

    onNewMessageArrival(data) {
        // this.CueChatLogic();
        var scope = this;
        // If the message delivered is a type of conversation only then emit a delivered message ...
        if (data.type === 'conversation' || data.type === 'payment' || data.type === 'deeplink' || data.type === 'insightChat') {
            scope.showChatLoader = false;

            if (data.sender != 'H' + scope.uid && data.conversationId) {
                let deliveryrec = { type: 'deliveryCursor', conversationId: scope.convoid, userId: 'H' + scope.uid, value: data.id, participants: ['H' + scope.uid, data.sender] };
                this.socketService.setCursor(deliveryrec).subscribe();
                this.cdRef.detectChanges();
            }

            var couple1 = data.participants.filter(part => part == 'H' + scope.uid);
            var couple2 = data.participants.filter(part => part == 'U' + scope.userid);

            var oldchat;

            var ispopped = false;
            if (couple1.length == 1 && couple2.length == 1) {
                if (scope.allchats.length > 1) {
                    scope.allchats[scope.allchats.length - 1]['showdate'] = false;
                    oldchat = scope.allchats[scope.allchats.length - 1];
                    scope.allchats.pop();
                    ispopped = true;
                }
                data['showdate'] = true;
                if (this.settonull == false) {
                    localStorage.setItem('crdate', null);
                    this.settonull = true;
                }
                setTimeout(() => {
                    if (ispopped) {
                        scope.allchats.push(oldchat);

                        ispopped = false;
                    }
                    scope.allchats.push(data);
                    
                    setTimeout(function () {
                        scope.scrollToBottom(scope);
                    }, 100)
                }, 1);
                this.lastMessageId = data.id;
                scope.recStatusval = 'Sent';
                if (scope.convoid && data.sender != 'H' + scope.uid && data.sender == 'U' + this.userid) {
                    let readrec = { type: 'readCursor', conversationId: scope.convoid, userId: 'H' + scope.uid, value: data.id, participants: ['H' + scope.uid, data.sender] };
                    this.socketService.setCursor(readrec).subscribe()
                    //scope.socket.emit('readReceipt',readrec );
                }
            }
            /*  else{
               if(data.sender != scope.uid)
               {
                   for (var r = 0; r < scope.alluserset.length; r++) {
                       if (scope.alluserset[r].id == data.sender) {
                           scope.alluserset[r].newmes = true;
                       } else {
                           scope.alluserset[r].newmes = false;
                       }
                   }
               }
             } */
            /*$('.chatarea').animate({
                scrollBottom: 99999
            });*/
            this.cdRef.detectChanges();
        }

        if (data.type == "typingCursor") {
            if (data.userId == 'U' + this.userid) {
                this.cdRef.detectChanges();
                scope.showTyper = true;
                setTimeout(function () {
                    scope.showTyper = false;
                }, 1000);
            }
        }

        if (data.type == "paymentCursor") {
            this.cdRef.detectChanges();
            if (data.value == 1) {
                if ($('#payicon' + data.messageId) && $('#payicon' + data.messageId)[0]) {
                    $('#payicon' + data.messageId)[0].innerHTML = "check"
                    $('#paycard' + data.messageId).attr("class", "secondpaymentsection paymentgreen");
                }
            }
            if (data.value == 0) {
                if ($('#payicon' + data.messageId) && $('#payicon' + data.messageId)[0]) {
                    $('#payicon' + data.messageId)[0].innerHTML = "priority_high"
                    $('#paycard' + data.messageId).attr("class", "secondpaymentsection paymentred");
                }
            }
        }

        if (data.type == "statusCursor" && data.userId == 'U' + this.userid) {
            this.cdRef.detectChanges();
            if (data.value == 1 && data.userId == 'U' + scope.userid) {
                scope.onlinestatus = true;
            }
            else {
                scope.onlinestatus = false;
            }
        }

        if (data.userId == 'U' + this.userid) {
            if (data.type == 'deliveryCursor') {
                if (data.value == this.lastMessageId) {
                    scope.recStatusval = "Delivered";
                    this.cdRef.detectChanges();
                }
            }
            if (data.type == 'readCursor') {
                if (data.value == this.lastMessageId) {
                    scope.recStatusval = "Read";
                    this.cdRef.detectChanges();
                }
            }
        }

        setTimeout(function () {
            scope.cdRef.detectChanges();
        }, 3);
    }

    getNextPage() {
        var scope = this;
        this.isnextpageclick = true;
        if (scope.allchats.length >= 20) {
            if (scope.lastID == null) {
                scope.lastID = scope.allchats[0]._id;
                // scope.chatlastID=scope.allchats[this.allchats.length-1]._id;
            }
            this.scrollDirection = "top";
            let mjson = { "conversationId": scope.convoid, "fromId": this.lastID, "type": "withConvAndMessageId", "direction": "lesserThan", "count": 20 };
            this.socketService.fetchAllMessages(mjson)
                .subscribe(data => {
                    data.messages = data.messages.reverse();
                    this.ongettingallmessages(data, 'old');
                })

            //this.cdRef.detectChanges();
        }

    }
    // sentCustomChat() {
    //     
    //     var message = this.templateData[0].description;
    //     
    //     //this.chatForm.value.patch;
    //     this.chatForm.controls.messagetext.patchValue(message)
    //     this.sendMessage();
    // }

    TextChange(event: any) {

        if (event.target.value.includes("wellthy.page.link")) {
            this.urlDetect = true
            this.systemService.showSnackBar("deeplink is allowed to sent only via selection", "Ok");
        } else {
            this.urlDetect = false
        }
    }

    sendMessage() {
        // let trustedData = this.domSanitizer.bypassSecurityTrustHtml(this.chatForm.value.messagetext)
        // console.log(trustedData);
        if (this.urlDetect == true) {
            return;
        } else {
            // $('#txtmessage').attr('rows', 1);

            this.CallSendMessagefn();
        }
        // $('#txtmessage').attr('rows', 1);
        // this.CallSendMessagefn();
    }

    keyDownFunction(evt) {
        var heightcalc = $('#txtmessage').val().length / ($('#txtmessage').width() / 6.3);
        heightcalc = Math.ceil(heightcalc);
        heightcalc = heightcalc < 1 ? 1 : heightcalc;
        if (heightcalc < 10) {
            $('#txtmessage').attr('rows', heightcalc);
        } else {
            $('#txtmessage').attr('rows', 10);
        }
        if (evt.keyCode == 13 && !evt.shiftKey) {
            $('#txtmessage').attr('rows', 1);
            evt.preventDefault();
            // this.CallSendMessagefn();
        }
        var scope = this;
        let typingdata = { type: "typingCursor", conversationId: scope.convoid, userId: 'H' + scope.uid };
        scope.socketService.publishTyping(typingdata, scope.userid);
    }


    CallSendMessagefn() {

        if ((/\S/.test(this.chatForm.value.messagetext)) && (!this.addedattachment) && (this.chatForm.value.messagetext != null)) {
            this.deletedId = localStorage.getItem("deletedId");
            this.sendLayerMessage(this.chatForm.value.messagetext);
        } else {
            if (this.addedattachment && this.addedattachment.files && this.addedattachment.files[0]) {
                this.isLoading = true;
                if ((/\S/.test(this.chatForm.value.messagetext)) && (this.chatForm.value.messagetext != null)) {
                    this.sendLayerMessage(this.chatForm.value.messagetext);
                } else {
                    this.sendLayerMessage('');
                }
            }
            else if (this.addedattachment == "payment" && this.optiontype) {
                this.isLoading = true;
                this.sendLayerMessage('Payment Pending.')
            }
            else if (this.addedattachment == "deeplink" && this.optiontype) {
                this.isLoading = true;
                this.sendLayerMessage(this.chatForm.value.messagetext ? this.chatForm.value.messagetext : '')
            }
            else {
                if ((/\S/.test(this.chatForm.value.messagetext)) && (this.chatForm.value.messagetext != null)) {
                    this.sendLayerMessage(this.chatForm.value.messagetext);
                }
            }

        }
    }

    checklang(id) {
        let d = this.patentlanguageId.includes(parseInt(id))
        return d;
    }

    checkdeletemsg(chat) {
        if (chat.sender.charAt(0) == 'H') {
            return !chat.is_deleted;
        } else if (chat.sender.charAt(0) == 'U') {
            return true;
        }
    }

    trigger(e) {

        this.optiontype = e;
        if (e == 'image') {
            $('#file-upload').attr("accept", "image/*")
            $('#file-upload').trigger('click');
        }
        else if (e == 'video') {
            $('#file-upload').attr("accept", "video/*")
            $('#file-upload').trigger('click');
        }
        else if (e == 'audio') {
            $('#file-upload').attr("accept", "audio/*")
            $('#file-upload').trigger('click');
        }
        else if (e == 'other') {
            $('#file-upload').attr("accept", "application/pdf")
            $('#file-upload').trigger('click');
        }
        else if (e == 'payment') {
            this.openPaymentLink()
        }
        else {
            this.openDeeplink();
        }
    }
    openDeeplink() {
        var scope = this;
        this.deeplinkdailog = this.dialog.open(DeeplinkComponent, {
            width: '700px',
            height: 'auto',
        });
        this.addedattachment = "deeplink";
        this.deeplinkdailog.afterClosed().subscribe((data) => {
            if (data) {
                if (data.type == "Dynamiclink") {
                    this.optionlink = data.shortlink.shortLink;
                    this.optionvalue = data.title;
                    this.typeValue = data.typeOfItem;
                }
                else if (data.type == "staticlink") {
                    this.optionlink = data.staticshortlink;
                    this.optionvalue = data.title;
                    this.typeValue = data.typeOfItem;

                }
            }
            else {
                this.optiontype = null;
            }
        });
    }
    openPaymentLink() {
        this.optiontype = "";
        this.addedattachment = "payment";
        var scope = this;
        this.paymentlinkdailog = this.dialog.open(PaymentlinkComponent, {
            width: '500px',
            height: 'auto',
        });
        this.paymentlinkdailog.afterClosed().subscribe((data) => {
            this.optiontype = "payment";
            if (data) {
                this.optionlink = data.link;
                this.optionvalue = JSON.stringify(data.order_id)
            }
            else {
                this.optiontype = null;
            }
        });
    }

    previewFile(event) {
        this.selectedFile = event.target.files[0].name;
        var input = event.target;
        if (input.files[0].size > 25000000) {
            this.imageData = null;
            this.video = null;
            this.audio = null;
            this.isPDF = null;
            this.myInputVariable.nativeElement.value = "";
            this.snackBar.open("File size exceed 25mb", "OK", {
                duration: 2000,
            });
        }
        else {
            this.addedattachment = input;
            if (input.files && input.files[0] && (input.files[0].type == 'image/jpeg' || input.files[0].type == 'image/png')) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imageData = e.target;
                    this.imageData = this.imageData.result;
                }
                reader.readAsDataURL(input.files[0]);
                this.showprev = true;
            } else if (input.files && input.files[0] && (input.files[0].type == 'video/mp4' || input.files[0].type == 'video/webm' || input.files[0].type == 'video/quicktime')) {

                var path = URL.createObjectURL(input.files[0]);
                this.video = {
                    sources: [{
                        src: path,
                        type: input.files[0].type
                    }],
                    options: {
                        autoplay: false,
                        volume: 0.6
                    }
                }
                this.videomimeType = input.files[0].type;
                this.videosource = path;
                this.videosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.videosource);
                this.showprev = true;
            } else if (input.files && input.files[0] && (input.files[0].type == 'audio/mp3' || input.files[0].type == 'audio/aac' || input.files[0].type == 'audio/wav' || input.files[0].type == 'audio/flac' || input.files[0].type == 'audio/mpeg')) {
                var audiopath = URL.createObjectURL(input.files[0]);
                this.audio = {
                    sources: [{
                        src: path,
                        type: input.files[0].type
                    }],
                    options: {
                        autoplay: false,
                        volume: 0.6
                    }
                }
                this.audiomimeType = input.files[0].type;
                this.audiosource = audiopath;

                this.audiosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.audiosource);
                this.showprev = true;
            } else if (input.files && input.files[0] && input.files[0].type == 'application/pdf') {
                this.isPDF = true;
                this.showprev = true;
            } else {
                this.showprev = false;
                this.snackBar.open("Unsuported file format", "OK", {
                    duration: 2000,
                });
            }
        }
    }

    sendLayerMessage(chatmessage) {

        let checkHTML = this.commonService.isHTML(chatmessage);
        if (checkHTML !== true) {
            var file = null;
            var convID = "";
            var datenew;
            if (this.convid) {
                convID = this.convid.conv_id;
            }
            else {
                convID = "";
            }
            datenew = new Date();
            datenew = datenew * 1;
            var scope = this;
            setTimeout(() => {
                scope.isLoading = false;
            }, 1500);
            file = this.myInputVariable.nativeElement.files[0];
            // file = undefined;
            var scope = this;
            var width = null;
            var height = null;
            var message = null;
            var hccode = 'H' + (this.uid).toString();
            var ucode = 'U' + (this.userid).toString();

            if (file && file != '') {
                this.socketService.fileEvent(file, scope.userid, data => {
                    if (data != false) {
                        this.imageData = null;
                        this.video = null;
                        this.audio = null;
                        this.isPDF = null;
                        let newmessagedata;
                        if ((file.type).includes('image')) {

                            width = $('.image-preview .preview')[0].naturalWidth;
                            height = $('.image-preview .preview')[0].naturalHeight;

                            newmessagedata = {
                                conversationId: convID,
                                clientId: this.socketService.clientid,
                                type: 'conversation',
                                sender: hccode,
                                trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                                participants: [hccode, ucode],
                                attachment: {
                                    link: data,
                                    type: file.type,
                                    width: width,
                                    height: height,
                                },
                                body: chatmessage,
                                mimeType: 'text',
                                replyTo: this.replydata != null ? this.replydata.id : null
                            };

                        } else if ((file.type).includes('video')) {
                            width = $('.video-preview .videoprev')[0].videoWidth;
                            height = $('.video-preview .videoprev')[0].videoHeight;
                            newmessagedata = {
                                conversationId: convID,
                                clientId: this.socketService.clientid,
                                type: 'conversation',
                                sender: hccode,
                                participants: [hccode, ucode],
                                trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                                attachment: {
                                    link: data,
                                    type: file.type,
                                    width: width,
                                    height: height
                                },
                                body: chatmessage,
                                mimeType: 'text',
                                replyTo: this.replydata != null ? this.replydata.id : null
                            };
                        } else if ((file.type).includes('audio')) {

                            width = $('.audio-preview .audioprev')[0].audioWidth;
                            height = $('.audio-preview .audioprev')[0].audioHeight;
                            newmessagedata = {
                                conversationId: convID,
                                clientId: this.socketService.clientid,
                                type: 'conversation',
                                sender: hccode,
                                participants: [hccode, ucode],
                                trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                                attachment: {
                                    link: data,
                                    type: file.type
                                },
                                body: chatmessage,
                                mimeType: 'text',
                                replyTo: this.replydata != null ? this.replydata.id : null
                            };
                        } else {

                            newmessagedata = {
                                conversationId: convID,
                                sender: hccode,
                                clientId: this.socketService.clientid,
                                trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                                participants: [hccode, ucode],
                                attachment: {
                                    link: data,
                                    type: file.type
                                },
                                body: chatmessage,
                                mimeType: 'text',
                                type: "conversation",
                                replyTo: this.replydata != null ? this.replydata.id : null
                            };
                        }

                        if (this.isConnected) {
                            this.socketService.emitNewMessage(newmessagedata)
                                .subscribe(data => {
                                    this.replydata = null;
                                    this.optiontype = null;
                                    localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
                                    if(this.taskDetails?.task[0]){
                                    let type: any = this.taskDetails?.task[0].type ?? "";
                                    let sub_type: any = this.taskDetails?.task[0].sub_type ?? "";
                                    let operation: any = this.taskDetails?.task[0].operation ?? "";
                                    let operation_type: any = this.taskDetails?.task[0].operation_type ?? "";
                                    let value: any = this.taskDetails?.task[0].value ?? "";
                                    let unit: any = this.taskDetails?.task[0].unit ?? "";
                                    var progress = [{
                                        "type": type,
                                        "sub_type": sub_type,
                                        "operation": operation,
                                        "operation_type": operation_type,
                                        "value": value,
                                        "unit": unit,
                                        "patient_completed": [this.userid],
                                        "patient_ongoing": [],
                                        "patient_failed": []
                                    }]
                                    var postData = {
                                        task_library_id: localStorage.getItem('newTaskID'),
                                        created_by_fk: localStorage.getItem('scarletuserid').toString(),
                                        hc_id: localStorage.getItem('scarletuserid'),
                                        progress: JSON.stringify(progress)
                                    }
                                    this.commonService.TaskProgress(postData, 'createTaskProgress')
                                        .subscribe((res: any) => {
                                            // this.systemService.showSnackBar("Message send to patients successfully.", "Ok");
                                            localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
                                            this.taskcompleteflag = true;
                                        },
                                            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
                                        )
                                    }
                                });
                            
                        } else {
                            this.offlineMessageService.storeOfflineChats(newmessagedata);
                        }


                        this.chatForm.reset();
                        this.myInputVariable.nativeElement.value = "";
                        file = null;
                        message = null;
                        this.addedattachment = null;

                        if (this.callDurationhr) {
                            this.systemService.showSnackBar("Data is saved", "Ok");
                            this.callDurationhr = "";
                            this.callDurationmin = "";
                            this.callDurationsec = "";
                            this.selectedImage = "";
                            this.selectedImage = undefined;
                            this.selectedImage = false;
                            this.selectedFile = false;
                        }
                    } else {
                        this.uploaderror = true;
                    }
                });
            } else {

                if (this.uploaderror != true) {
                    let newmessagedata;
                    if (this.optiontype == "deeplink") {

                        this.isLoading = false;
                        var typeOfmessage: any;
                        var bodymessage: any;
                        if (this.optionvalue == "App Rating") {
                            typeOfmessage = "conversation",
                                bodymessage = "Please provide us with a rating."
                        }
                        else if (this.optionvalue == "Coach Mark") {
                            typeOfmessage = "conversation",
                                bodymessage = chatmessage
                        }
                        else {
                            typeOfmessage = "deeplink",
                                bodymessage = chatmessage
                        }
                        //     let deeplinkName=this.typeValue;
                        //     let typeVal:any = {};
                        //    typeVal.deeplinkName=deeplinkName;
                        // let typeVal={};

                        newmessagedata = {
                            conversationId: convID,
                            //title: this.uid + this.userid,
                            sender: hccode,
                            clientId: this.socketService.clientid,
                            //receiver: this.userid,
                            participants: [hccode, ucode],
                            body: bodymessage,
                            attachment: {
                                link: this.optionlink,
                                type: "deeplink",
                                value: this.optionvalue,
                                additionalData: JSON.stringify(this.typeValue),
                            },
                            trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                            mimeType: 'text/plain',
                            type: typeOfmessage,
                            replyTo: this.replydata != null ? this.replydata.id : null,
                            hk_start_date:this.resync_start_date,
                            hk_end_date:this.resync_end_date
                        };
                    }
                    else if (this.optiontype == "payment") {
                        newmessagedata = {
                            conversationId: convID,
                            //title: this.uid + this.userid,
                            sender: hccode,
                            clientId: this.socketService.clientid,
                            //receiver: this.userid,
                            participants: [hccode, ucode],
                            body: chatmessage,
                            attachment: {
                                link: this.optionlink,
                                type: "payment_link",
                                value: this.optionvalue
                            },
                            trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                            mimeType: 'text/plain',
                            type: "payment",
                            replyTo: this.replydata != null ? this.replydata.id : null
                        };
                    }
                    else {
                        newmessagedata = {
                            conversationId: convID,
                            //title: this.uid + this.userid,
                            sender: hccode,
                            clientId: this.socketService.clientid,
                            //receiver: this.userid,
                            participants: [hccode, ucode],
                            body: chatmessage,
                            trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                            mimeType: 'text/plain',
                            type: "conversation",
                            replyTo: this.replydata != null ? this.replydata.id : null
                        };
                    }

                    if (this.isConnected) {
                        this.socketService.emitNewMessage(newmessagedata).subscribe(data => {
                            if (this.callDurationhr) {
                                this.systemService.showSnackBar("Data is saved", "Ok");
                                this.callDurationhr = "";
                                this.callDurationmin = "";
                                this.callDurationsec = "";
                                this.selectedImage = "";
                                this.selectedImage = undefined;
                                this.selectedImage = false;
                                this.selectedFile = false;
                            }
                            this.replydata = null;
                            this.optiontype = null;
                            localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
                            if(this.taskDetails?.task[0]){
                                let type: any = this.taskDetails?.task[0].type ?? "";
                                let sub_type: any = this.taskDetails?.task[0].sub_type ?? "";
                                let operation: any = this.taskDetails?.task[0].operation ?? "";
                                let operation_type: any = this.taskDetails?.task[0].operation_type ?? "";
                                let value: any = this.taskDetails?.task[0].value ?? "";
                                let unit: any = this.taskDetails?.task[0].unit ?? "";
                                var progress = [{
                                    "type": type,
                                    "sub_type": sub_type,
                                    "operation": operation,
                                    "operation_type": operation_type,
                                    "value": value,
                                    "unit": unit,
                                    "patient_completed": [this.userid],
                                    "patient_ongoing": [],
                                    "patient_failed": []
                                }]
                                var postData = {
                                    task_library_id: localStorage.getItem('newTaskID'),
                                    created_by_fk: localStorage.getItem('scarletuserid').toString(),
                                    hc_id: localStorage.getItem('scarletuserid'),
                                    progress: JSON.stringify(progress)
                                }
    
                                this.commonService.TaskProgress(postData, 'createTaskProgress')
                                    .subscribe((res: any) => {
                                        localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
                                        this.taskcompleteflag = true;
                                    },
                                        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
                                    )
                            }
                                
                        });
                    } else {
                        this.offlineMessageService.storeOfflineChats(newmessagedata);
                    }

                    this.chatForm.reset();
                    message = null;
                }
            }

        } else {
            this.chatForm.controls.messagetext.patchValue('')
            return;
        }
    }

    createSupportTicket(): void {
        /**Check for empty or null value */
        if (this.appContext.selectedChatUser.getValue() != null) {
            /**Assign value */
            this.appContext.supportTicketObjectHandler.next(this.appContext.selectedChatUserRowData.getValue());
            /**Navigate to router */
            this.router.navigate(['healthcoach/hcsupport']);
        } else {
            this.systemService.showSnackBar("Please select user first to create support ticket.", "Ok");
        }
    }

    getTheTime(dateStamp) {
        if (dateStamp == undefined) {
            return "Not Available";
        } else {
            var datep = new Date(dateStamp);
            var minval = ('0' + datep.getMinutes()).slice(-2)
            return (datep.getHours() + ":" + minval + " On " + datep.getDate() + " " + SHORT_MONTHS[datep.getMonth()] + " " + datep.getFullYear());
        }
    }

    // addTab(lang) {
    //     // this.tabs.push(lang);
    //     // 
    //     this.template = this.thirdFormGroup.get('template') as FormArray;
    //     this.template.push(this.templateFormSelection(lang));

    //     if (lang) {
    //         this.selected.setValue(this.alllanguages.length - 1);
    //     }
    //     this.removeSelectedLang(lang.id)
    // }


    // removeSelectedLang(id) {
    //     this.alllanguages = this.alllanguages.filter((item) => item.id !== id)
    // }

    getLanguageName(category) {
        category = this.myCathash[category];
        return category;
    }

    get tempformData(): FormArray {
        return <FormArray>this.thirdFormGroup.get('template');
    }

    changeindex(tabChangeEvent: MatTabChangeEvent): void {
        this.tabEventData = tabChangeEvent;
    }

    sentCustomChat() {
        if (this.tabEventData == undefined) {
            this.messageData = this.templateData[0].description;
        }
        if (this.tabEventData == 0) {
            this.messageData = this.templateData[0].description;
        }
        if (this.tabEventData == 1) {
            this.messageData = this.templateData[1].description;
        }
        if (this.tabEventData == 2) {
            this.messageData = this.templateData[2].description;
        }
        if (this.tabEventData == 3) {
            this.messageData = this.templateData[3].description;
        }
        if (this.tabEventData == 4) {
            this.messageData = this.templateData[4].description;
        }
        this.attachment = {
            link: this.templateData[0].link,
            type: this.templateData[0].type
        }

        //this.chatForm.value.patch;
        this.chatForm.controls.messagetext.patchValue(this.messageData)
        this.sendMessage1();
    }

    sendMessage1() {
        $('#txtmessage').attr('rows', 1);
        if ((/\S/.test(this.chatForm.value.messagetext)) && (!this.addedattachment) && (this.chatForm.value.messagetext != null)) {
            this.sendLayerMessage1();
        }
    }

    sendLayerMessage1() {
        var scope = this;
        this.uid = localStorage.getItem('scarletuserid');

        if (this.selectedUserID == null || this.selectedUserID == "") {
            this.noappSelectedUserFound = true;
            return;
        } else {
            this.noappSelectedUserFound = false;
        }

        for (let i = 0; i <= this.selectedUserID.length; i++) {
            scope.isvisible = false;
            this.changeDetector = this.selectedUserID[i];//this.appContext.selectedChatUser.getValue();

            this.userid = this.selectedUserID[i].user_id;//this.appContext.selectedChatUser.getValue();

            this.convid = this.selectedUserID[i].chatMetadata[0].conv_id;
            this.taskProgressUserId.push(this.userid)
            var file = null;
            var convID = "";
            var datenew;
            if (this.convid) {
                convID = this.convid;
            }
            else {
                convID = "";
            }
            datenew = new Date();
            datenew = datenew * 1;

            setTimeout(() => {
                scope.isLoading = false;
            }, 1500);
            // file = this.myInputVariable.nativeElement.files[0];
            file = undefined;

            var message = null;
            var hccode = 'H' + (this.uid).toString();
            var ucode = 'U' + (this.userid).toString();
            let newmessagedata;
            let msg = this.messageData;
            let langid = 1;
            if (this.selectedUserID[i].language_data_fk) {
                langid = this.selectedUserID[i].language_data_fk
            } else {
                langid = 1;
            }

            let index = this.templateData.map(function (e) { return e.id }).indexOf((langid).toString());

            if (index != -1) {
                msg = this.templateData[index].description;
            } else {
                msg = this.templateData[0].description;
            }
            newmessagedata = {
                conversationId: convID,
                //title: this.uid + this.userid,
                sender: hccode,
                clientId: this.socketService.clientid,
                //receiver: this.userid,
                participants: [hccode, ucode],
                body: msg,
                trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                mimeType: 'text/plain',
                type: "conversation",
                replyTo: this.replydata != null ? this.replydata.id : null,
                attachment: this.attachment
            }
            this.socketService.emitNewMessage(newmessagedata)
                .subscribe(data => {
                    this.replydata = null;
                    this.optiontype = null;
                    localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
                    let data1: any = JSON.parse(localStorage.getItem('progressPatients'));
                    let taskProgress: any = []
                    if (data1 != null) {
                        taskProgress = this.taskProgressUserId.concat(data1);
                        data1 = [];
                    } else {
                        taskProgress = this.taskProgressUserId;
                    }

                    let type: any = this.taskDetails.task[0].type ?? "";
                    let sub_type: any = this.taskDetails.task[0].sub_type ?? "";
                    let operation: any = this.taskDetails.task[0].operation ?? "";
                    let operation_type: any = this.taskDetails.task[0].operation_type ?? "";
                    let value: any = this.taskDetails.task[0].value ?? "";
                    let unit: any = this.taskDetails.task[0].unit ?? "";
                    var progress = [{
                        "type": type,
                        "sub_type": sub_type,
                        "operation": operation,
                        "operation_type": operation_type,
                        "value": value,
                        "unit": unit,
                        "patient_completed": taskProgress,
                        "patient_ongoing": [],
                        "patient_failed": []
                    }]
                    var postData = {
                        task_library_id: localStorage.getItem('newTaskID'),
                        created_by_fk: localStorage.getItem('scarletuserid').toString(),
                        hc_id: localStorage.getItem('scarletuserid'),
                        progress: JSON.stringify(progress)
                    }
                    this.commonService.TaskProgress(postData, 'createTaskProgress')
                        .subscribe((res: any) => {
                            this.systemService.showSnackBar("Message send to patients successfully.", "Ok");

                            localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
                            this.taskcompleteflag = true;

                        },
                            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
                        )
                });
            this.chatForm.reset();
            message = null;
        }
    }

    async sendCallMessage() {
        var scope = this;
        this.uid = localStorage.getItem('scarletuserid');
        if (this.selectedUserID.length != 0) {
            for (let i = 0; i <= this.selectedUserID.length; i++) {
                scope.isvisible = false;
                this.userid = this.selectedUserID[i].user_id;
                this.convid = this.selectedUserID[i].chatMetadata[0].conv_id;
                this.taskProgressUserId.push(this.userid)
                var file;
                var convID = "";
                var datenew;
                if (this.convid) {
                    convID = this.convid;
                }
                else {
                    convID = "";
                }
                datenew = new Date();
                datenew = datenew * 1;
                var scope = this;
                setTimeout(() => {
                    scope.isLoading = false;
                }, 1500);
                file = this.myInputVariable.nativeElement.files[0];
                var scope = this;
                var width = null;
                var height = null;
                var message = null;
                var hccode = 'H' + (this.uid).toString();
                var ucode = 'U' + (this.userid).toString();
                let newmessagedata;
                if (file && this.callDurationhr && this.callDurationmin && this.callDurationsec) {
                    await this.ScreenshotAttachment(file, scope.userid, convID, hccode, ucode, datenew)
                }
                else if (!file && this.callDurationhr && this.callDurationmin && this.callDurationsec) {
                    let callDuration: any = {};
                    callDuration.callDurationhr = this.callDurationhr;
                    callDuration.callDurationmin = this.callDurationmin;
                    callDuration.callDurationsec = this.callDurationsec;
                    newmessagedata = {
                        conversationId: convID,
                        clientId: this.socketService.clientid,
                        type: 'conversation',
                        sender: hccode,
                        trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                        participants: [hccode, ucode],
                        attachment: {
                            link: "",
                            type: "",
                            width: "",
                            height: "",
                            value: "is_CallDuration"
                        },
                        body: JSON.stringify(callDuration),
                        mimeType: 'text',
                        replyTo: this.replydata != null ? this.replydata.id : null
                    };
                }
                if (newmessagedata != null) {
                    await this.emitNewmessage(newmessagedata);
                }
            }
        }
    }

    sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async ScreenshotAttachment(file, userid, convID, hccode, ucode, datenew) {
        // await this.sleep(1000)
        let newmessagedata;
        this.socketService.fileEvent(file, userid, async data => {
            if (data != false) {
                this.imageData = null;
                this.video = null;
                this.audio = null;
                this.isPDF = null;
                if ((file.type).includes('image')) {
                    file.onload = function () {
                        this.width = $('.image-preview .preview')[0].naturalWidth;
                        this.height = $('.image-preview .preview')[0].naturalHeight;
                    }
                    let callDuration: any = {};
                    callDuration.callDurationhr = this.callDurationhr;
                    callDuration.callDurationmin = this.callDurationmin;
                    callDuration.callDurationsec = this.callDurationsec;
                    newmessagedata = {
                        conversationId: convID,
                        clientId: this.socketService.clientid,
                        type: 'conversation',
                        sender: hccode,
                        trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                        participants: [hccode, ucode],
                        attachment: {
                            link: data,
                            type: file.type,
                            width: this.width,
                            height: this.height,
                            value: "is_CallDuration"
                        },
                        body: JSON.stringify(callDuration),
                        mimeType: 'text',
                        replyTo: this.replydata != null ? this.replydata.id : null
                    };
                    if (newmessagedata != null) {
                        await this.emitNewmessage(newmessagedata);
                    }
                }
            }
        })
    }

    async emitNewmessage(newmessagedata) {

        // let progreeofPreviousPatient: any = [];
        // let data = parseInt(localStorage.getItem('progressPatients'));
        // progreeofPreviousPatient.push(data)
        // const taskProgress = this.taskProgressUserId.concat(progreeofPreviousPatient);
        this.socketService.emitNewMessage(newmessagedata).subscribe(data => {
            let data1: any = JSON.parse(localStorage.getItem('progressPatients'));
            let taskProgress: any = []
            if (data1 != null) {
                taskProgress = this.taskProgressUserId.concat(data1);
                data1 = [];
            }
            else {
                taskProgress = this.taskProgressUserId;
            }

            // var tmp = [];
            // for (var i = 0; i < taskProgress.length; i++) {
            //     if (tmp.indexOf(taskProgress[i]) == -1) {
            //         tmp.push(taskProgress[i]);
            //     }
            // }


            this.callDurationhr = '';
            this.callDurationmin = '';
            this.callDurationsec = '';
            this.selectedFile = '';
            this.systemService.showSnackBar("Data is saved", "Ok");
            localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
            let type: any = this.taskDetails.task[0].type ?? "";
            let sub_type: any = this.taskDetails.task[0].sub_type ?? "";
            let operation: any = this.taskDetails.task[0].operation ?? "";
            let operation_type: any = this.taskDetails.task[0].operation_type ?? "";
            let value: any = this.taskDetails.task[0].value ?? "";
            let unit: any = this.taskDetails.task[0].unit ?? "";
            var progress = [{
                "type": type,
                "sub_type": sub_type,
                "operation": operation,
                "operation_type": operation_type,
                "value": value,
                "unit": unit,
                "patient_completed": taskProgress,
                "patient_ongoing": [],
                "patient_failed": []
            }]
            var postData = {
                task_library_id: localStorage.getItem('newTaskID'),
                created_by_fk: localStorage.getItem('scarletuserid').toString(),
                hc_id: localStorage.getItem('scarletuserid'),
                progress: JSON.stringify(progress)
            }
            this.commonService.TaskProgress(postData, 'createTaskProgress')
                .subscribe((res: any) => {
                    localStorage.setItem("taskcompleteflag", JSON.stringify({ 'data': true }));
                    this.taskcompleteflag = true;
                },
                    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
                )
        })
    }
}
