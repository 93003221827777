<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
      <app-adminsectionleftnav></app-adminsectionleftnav>
      <div class="mainrightcontainersecondary">
        <app-breadcrumb route="admin/apilogs"></app-breadcrumb>
        <div class="clearfix"><br /></div>
        <div class="col-md-2">
          <mat-select [(ngModel)]="statusFilters" placeholder="Status" class="sortcommonwhite"
            (selectionChange)="statusFilter()" style="width: 100%;">
            <mat-option value="">Select</mat-option>
            <mat-option value="success">Success</mat-option>
            <mat-option value="error">Error</mat-option>
          </mat-select>
        </div>
        <div class="col-md-2">
          <mat-select [(ngModel)]="providerFilters" placeholder="Provider" multiple class="sortcommonwhite"
            (selectionChange)="providerFilter()" style="width: 100%;">
            <mat-option value="freshsales">Freshsales</mat-option>
            <mat-option value="onehash">Onehash</mat-option>
            <mat-option value="kaleyra">Kaleyra</mat-option>
            <mat-option value="gupshup">Gupshup</mat-option>
          </mat-select>
        </div>
        <div class="col-md-8">
          <form>
            <div class="form-group has-feedback has-search">
              <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                placeholder="Search request">
              <button mat-fab class="searchbtn" style="display: none;" (click)="textSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div class="row">

          <div class="col-md-12">
            <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
            (scrolled)="getNextSet()" [scrollWindow]="false">
    
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">

                <ng-container matColumnDef="Id">
                  <th mat-header-cell *matHeaderCellDef> # </th>
                  <td mat-cell *matCellDef="let element" style="padding-right:10px;" >
                    {{ element?.id}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef> Provider </th>
                  <td mat-cell *matCellDef="let element" style="padding-right:10px;" >
                    {{ element?.provider}}
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="URL">
                  <th mat-header-cell *matHeaderCellDef> API Info </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.request?.url?.substring(0,50) || element?.request?.to}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.response?.statusCode || element?.response?.status}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Request">
                  <th mat-header-cell *matHeaderCellDef> Request </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      <canvas [hidden]="true" #canvas></canvas>
                      <button mat-button (click)="clickToCopy(element?.request)" tooltip="Copy Request">
                        <img src="assets/img/dls/icons/actiontable/copy-icon.svg" style="height: 21px;" alt="">
                      </button>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Response">
                  <th mat-header-cell *matHeaderCellDef> Response </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      <canvas [hidden]="true" #canvas></canvas>
                      <button mat-button (click)="clickToCopy(element?.response)" tooltip="Copy Response">
                        <img src="assets/img/dls/icons/actiontable/copy-icon.svg" style="height: 21px;" alt="">
                      </button>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef>Date Time</th>
                  <td mat-cell *matCellDef="let element"> 
                   {{ element.created_at | date:'dd/MM/yyyy,h:mm:ss a'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="action-button-row">

                      <ng-container *ngIf="(element.response.statusCode && element.response.statusCode == 200) || (element.response.status && element.response.status == 'accepted')">
                        <button mat-button style="opacity:0.5;cursor: not-allowed;">
                          <img src="assets/img/dls/icons/actiontable/Icon_Approve.svg" tooltip="Success" alt="Success">
                        </button>
                      </ng-container>

                      <ng-container *ngIf="(element.response.statusCode && element.response.statusCode != 200) || (element.response.status && element.response.status != 'accepted')">
                        <button mat-button (click)="retry(element.id, element.provider)">
                          <img src="assets/img/dls/icons/actiontable/Icon_Reject.svg" tooltip="Retry" alt="Retry">
                        </button>
                      </ng-container>
                    </div>
                  </td>
                </ng-container>
               
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                </tr>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</mat-drawer-container>
