import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from '../../../../shared/shared.module';

import { ContextService as HealthCoachContextService } from '../../../services/context.service';
import { SenduserbrodcastComponent } from './senduserbrodcast.component';

@NgModule({
  declarations: [
    SenduserbrodcastComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    SenduserbrodcastComponent
  ]
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class sendUserBroadcastModule { }
