
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../../content/services/contentservice/contentservice.service';

@Component({
  selector: 'app-campaignview',
  templateUrl: './campaignview.component.html',
  styleUrls: ['./campaignview.component.scss']
})

export class CampaignviewComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CampaignviewComponent>, private contentService: ContentserviceService, private systemService: SystemService,
    private browserTitle: Title
  ) { }

  public showButtonLoader: any;
  public userView: any;
  public allclients: any;
  public myhash = {};
  public myhashhc = {};
  public myhashtherapy = {};
  public allhc: any;
  public alltherapies: any;
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Campaign View');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getallclient();
    this.getHc();
    this.getTherapy();
    this.userView = this.data.element;
  }


  getClientName(clients) {
    clients = this.myhash[clients];
    return clients;
  }

  getHCName(allhc) {
    allhc = allhc.map(i => this.myhashhc[i]);
    return allhc;
  }

  getTherapyName(therapy) {
    therapy = this.myhashtherapy[therapy];
    return therapy;
  }


  // To Get HealthCoach Data
  // ----------------------------

  getHc() {
    this.contentService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name}}')
    .subscribe(
      (res: any) => this.getHCData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    ) 
  }

  private getHCData(data) {
    this.allhc = data.data.getAllHealthcoaches;
    this.allhc.forEach(element => {
      this.myhashhc[element.id] = element.name;
    });
  }

  // ----------------------------


  // To Get All Client Data
  // ----------------------------

  getallclient() {
    this.contentService.getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
    .subscribe(
      (res: any) => this.getClientData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    ) 
  }

  private getClientData(data) {
    this.allclients = data.data.getAllClients;
    this.allclients.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  // ----------------------------


  // To Get Therapy Data
  // ----------------------------

  getTherapy() {
    this.contentService.getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
    .subscribe(
      (res: any) => this.getTherapyData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    ) 
  }
  
  private getTherapyData(data) {
    this.alltherapies = data.data.getAllTherapies;
    this.alltherapies.forEach(element => {
      this.myhashtherapy[element.id] = element.title;
    });
  }

  // ----------------------------

  // Close Modal

  onNoClick(): void {
    this.dialogRef.close();
  }

}
