
import { HttpErrorResponse } from '@angular/common/http';
import { unsupported } from '@angular/compiler/src/render3/view/util';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../../utility/validation';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-createcampaign',
  templateUrl: './createcampaign.component.html',
  styleUrls: ['./createcampaign.component.scss']
})

export class CreatecampaignComponent implements OnInit {
  public newitem: boolean = false;
  public showButtonLoader: any;

  public ide: any;
  public childid: any;
  public selfparentid: any;
  public selfparentide: any;
  public allconditions: any;
  public alltherapies: any;
  public showAllcondition: boolean = false;
  public allhc: any;
  public allclients: any;
  public campaigndata: any;
  public campaignEditdata: any;
  public searchmatclient: FormControl = new FormControl();
  public checkedpaid: boolean = false;
  public coachaccess: any;
  public docaccess: any;
  public clientaccessp: any;
  public doctorData: any;
  public showFerrerFields: boolean = false;

  public showPrescription: boolean = false;
  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    detailed_text: [''],
    is_paid: [false],
    type: [Validators.required],
    has_coach_access: ['', Validators.required],
    coach_ids: [[]],
    is_test: [false],
    has_client: ['', Validators.required],
    client_data: [""],
    start_date: [Validators.required],
    has_end_date: [false],
    end_date: [""],
    has_doctor_access: [''],
    doctor_data: [''],
    therapy_data_fk: ["", Validators.required],
    created_by_data_fk: ["5", Validators.required],
    show_support_phone_number: [true],
    show_support_email_id: [true],
    show_questionnaire: [false],
    show_triggers: [false],
    show_aqi: [false],
    show_bmi: [false],
    pump_access: [1],
    medication_access: [1],
    lab_report_access: [1],
    show_history: [false],
    show_utilities: [false],
    show_targets: [false],
    remodulin: ['none', Validators.required],
    is_remodulin: [''],
    is_nonremodulin: [''],
    use_geolocation: [false],
    show_manufacturer: [false],
    default_weight_unit: ['kg'],
    default_height_unit: ['ft'],
    profile_flags: [[]],
    chat_flags: [[]],
    dairy_flags: [[]],
    health_flags: [[]],
    pro_flags: [[]],
    show_medsurvey: [false],
    max_upload_limit: [],
    duration: ['', Validators.required]
  });
  public searchmattag: FormControl = new FormControl();
  public searchmathc: FormControl = new FormControl();
  public searchmatdoc: FormControl = new FormControl();
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;
  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;
  @ViewChild('singleSelectDOC', { static: false }) singleSelectDOC: MatSelect;

  private _onDestroy = new Subject<void>();
  public filteredDoc: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredHC: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public clientdata: any;
  public hcdata: any;
  public docdata: any;
  public checkedtriggers: boolean = false;
  public isNewItem: any;
  public showLoader: boolean = true;

  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private commonService: CommonserviceService,
    private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public dataModal: any, public dialogRef: MatDialogRef<CreatecampaignComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Campaign');
  }

  ngOnInit() {
        this.getHc();
    // this.getDoctList(data => {
      // if (data) {
        this.getallclient();
        this.getTherapy();
        this.setSEOTags();
        this.getDoctList('');
        this.isNewItem = this.dataModal.element;

        if (this.isNewItem == 'newsection') {
          this.newitem = true;
          this.showLoader = false
        }
        else {
          this.newitem = false;
          this.childid = this.isNewItem;
          this.getAllCampaignData(this.isNewItem);
        }

        // this.route.params.subscribe(params => {
        //   // this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
        //   if (params.id == 'new') {
        //     this.selfparentid = null;
        //     this.newitem = true;
        //     // this.selfparentide.setValue(null);
        //   } else {
        //     this.getAllCampaignData(params.id);
        //     this.childid = params.id;
        //     this.newitem = false;
        //   }
        // });
      // }
    // });
  }

  checkClient(client) {

    if (client == '9') {
      this.showFerrerFields = true;
    }
    else {
      this.showFerrerFields = false;
    }

  }

  // To Get Doctor List
  // ----------------------------

  getDoctList(cb) {
    this.commonService.getGraphQlData('{getAllDoctorUsers(appMode:false,status:true){code,name,id}}')
      .subscribe(
        (res: any) => this.getDocListSuccess(cb, res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getDocListSuccess(cb, data) {
    data = (data as any).data;
    this.doctorData = data.getAllDoctorUsers;
    this.showLoader = false;
  }

  // ----------------------------

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChangedTags() {
    this.filterTags();
  }

  modelChangedDOC() {
    this.filterDOC();
  }

  modelChangedHC() {
    this.filterHC();
  }
  modelChangedClient() {
    this.filterClients();
  }

  private filterDOC() {
    if (!this.doctorData) {
      return;
    }

    let search = this.searchmatdoc.value;
    if (!search) {
      this.filteredDoc.next(this.doctorData.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredDoc.next(
      this.doctorData.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterTags() {
    if (!this.alltherapies) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTherapy.next(this.alltherapies.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.alltherapies.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterHC() {
    if (!this.allhc) {
      return;
    }
    // get the search keyword
    let search = this.searchmathc.value;
    if (!search) {
      this.filteredHC.next(this.allhc.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredHC.next(
      this.allhc.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


  // To Get Therapy List
  // ----------------------------

  getTherapy() {
    this.commonService.getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.getTherapyData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyData(data) {
    data = (data as any).data;
    this.alltherapies = data.getAllTherapies;
    this.filteredTherapy.next(this.alltherapies.slice());
  }

  // ----------------------------


  // To Get All Campaign List
  // ----------------------------

  getAllCampaignData(ide) {
    this.commonService.getOneDataById(ide, 'campaigndata')
      .subscribe(
        (res: any) => this.onCampaignSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onCampaignSuccess(data) {
    data = (data as any).data;
    this.campaigndata = data;
    this.campaigndata.therapy_data_fk = this.campaigndata.therapy_data_fk.toString();
    if (this.campaigndata.has_coach_access) {
      this.HCAccess("true");
    }
    if (this.campaigndata.has_client) {
      this.clientAccess("true");
    }
    if (this.campaigndata.has_doctor_access) {
      this.doctorAccess("true");

    }
    if (this.campaigndata.client_data) {
      this.checkClient(this.campaigndata.client_data.toString());

    }
    this.campaigndata.client_data = this.campaigndata.client_data ? this.campaigndata.client_data.toString() : this.campaigndata.client_data;
    this.dataForm.patchValue(this.campaigndata);
    this.campaigndata.therapy_data_fk = this.campaigndata.therapy_data_fk.toString();
    if (this.campaigndata.pump_access == "add") {
      this.dataForm.controls.pump_access.patchValue(2);
    } else if (this.campaigndata.pump_access == "view") {
      this.dataForm.controls.pump_access.patchValue(3);
    } else {
      this.dataForm.controls.pump_access.patchValue(1);
    }

    if (this.campaigndata.medication_access == "add") {
      this.dataForm.controls.medication_access.patchValue(2);
    } else if (this.campaigndata.medication_access == "view") {
      this.dataForm.controls.medication_access.patchValue(3);
    } else {
      this.dataForm.controls.medication_access.patchValue(1);
    }

    if (this.campaigndata.lab_report_access == "add") {
      this.dataForm.controls.lab_report_access.patchValue(2);
    } else if (this.campaigndata.lab_report_access == "view") {
      this.dataForm.controls.lab_report_access.patchValue(3);
    } else {
      this.dataForm.controls.lab_report_access.patchValue(1);
    }

    if (this.campaigndata.is_remodulin) {
      this.dataForm.controls.remodulin.patchValue('is_remodulin');
    } else if (this.campaigndata.is_nonremodulin) {
      this.dataForm.controls.remodulin.patchValue('is_nonremodulin');
    } else {
      this.dataForm.controls.remodulin.patchValue('none');
    }

    // let array3 = this.campaigndata.profile_flags.filter(x => this.showChatList[x.code].includes(x));
    var newArray1 = []
    var newArray2 = []
    var newArray3 = []
    var newArray4 = []

    this.campaigndata.profile_flags.map(item => {
      let array3 = this.showChatList.find(i2 => i2.code === item);
      if (array3 != undefined) {
        newArray1.push(array3.code);
      }
    });
    this.dataForm.controls.chat_flags.patchValue(newArray1);

    this.campaigndata.profile_flags.map(item => {
      let array3 = this.showDairyList.find(i2 => i2.code === item);
      if (array3 != undefined) {
        newArray2.push(array3.code);
      }
    });
    this.dataForm.controls.dairy_flags.patchValue(newArray2);

    this.campaigndata.profile_flags.map(item => {
      let array3 = this.showProfileList.find(i2 => i2.code === item);
      if (array3 != undefined) {
        newArray3.push(array3.code);
      }
    });
    for (let i = 0; i < this.campaigndata.profile_flags.length; i++) {
      if (this.campaigndata.profile_flags[i] === "SMS") {
        this.dataForm.controls['show_medsurvey'].setValue(true);
      } else {
        this.dataForm.controls['show_medsurvey'].setValue(false);
      }
    }
    this.dataForm.controls.pro_flags.patchValue(newArray3);

    this.campaigndata.profile_flags.map(item => {
      let array3 = this.showHealthList.find(i2 => i2.code === item);
      if (array3 != undefined) {
        newArray4.push(array3.code);
      }
    });
    this.dataForm.controls.health_flags.patchValue(newArray4);
  }

  // ----------------------------


  // To Get Health Coach List
  // ----------------------------

  getHc() {
    this.commonService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.onHCSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCSuccess(data) {
    data = (data as any).data;
    this.allhc = data.getAllHealthcoaches;
    this.allhc.forEach(element => {
      element.id = element.id.toString();
    });
    this.filteredHC.next(this.allhc.slice());
  }

  // ----------------------------


  // To Get Health Coach List
  // ----------------------------

  getallclient() {
    this.commonService.getGraphQlData('{getAllClients(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.onClientSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onClientSuccess(data) {
    data = (data as any).data;
    this.allclients = data.getAllClients;
  }

  // ----------------------------

  changeValue(value) {
    this.checkedpaid = !value;
  }

  clientAccess(e) {
    this.clientdata = e;
    if (this.clientdata) {
      this.clientaccessp = true;
      this.dataForm.addControl('client_data', new FormControl('', Validators.required));
    }
    else {
      this.clientaccessp = false;
      this.showFerrerFields = false;
      this.dataForm.removeControl('client_data');
    }
  }

  HCAccess(e) {
    this.hcdata = e;
    if (this.hcdata) {
      this.coachaccess = true;
      this.dataForm.addControl('coach_ids', new FormControl([], Validators.required));
    }
    else {
      this.coachaccess = false;
      this.dataForm.removeControl('coach_ids');
    }
  }

  doctorAccess(e) {
    this.docdata = e;
    if (this.docdata) {
      this.docaccess = true;
      this.dataForm.addControl('doctor_data', new FormControl([], Validators.required));
    }
    else {
      this.docaccess = false;
      this.dataForm.removeControl('doctor_data');
    }
  }

  onChangeProfile(event) {
    if (event.value.includes('SPR')) {
      this.showPrescription = true;
    }
    else {
      this.showPrescription = false;
    }
  }

  // Submitting Campaign Data
  // ----------------------------

  dataObject() {

    var newarray = [];
    var postdata = this.dataForm.value;
    var postdata1 = this.dataForm.value;
    postdata.is_paid = this.checkedpaid.toString();
    postdata.title = this.removeHTMLtags(postdata.title);
    postdata.detailed_text = this.removeHTMLtags(postdata.detailed_text);
    postdata.has_coach_access = postdata.has_coach_access.toString();
    postdata.is_test = postdata.is_test.toString();
    postdata.has_client = postdata.has_client.toString();
    postdata.has_end_date = postdata.has_end_date.toString();
    postdata.has_doctor_access = postdata.has_doctor_access.toString();

    postdata.show_medsurvey = postdata.show_medsurvey.toString();

    if (postdata.pro_flags.includes('SPR')) {
      postdata.max_upload_limit = postdata.max_upload_limit.toString();
    }

    if (postdata.doctor_data) {
      postdata.doctor_data = postdata.doctor_data.toString();
    }
    postdata.created_by_data_fk = postdata.created_by_data_fk.toString();
    postdata.start_date = moment(postdata.start_date).format('YYYY-MM-DD');
    if (postdata.end_date) {
      postdata.end_date = moment(postdata.end_date).format('YYYY-MM-DD');
    } else {
      postdata.end_date = null
    }

    postdata.therapy_data_fk = (postdata.therapy_data_fk as string).toString();
    postdata.coach_ids = JSON.stringify(postdata.coach_ids);
    postdata.show_support_phone_number = postdata.show_support_phone_number.toString();
    postdata.show_support_email_id = postdata.show_support_email_id.toString();
    postdata.show_questionnaire = postdata.show_questionnaire.toString();
    postdata.show_triggers = postdata.show_triggers.toString();
    postdata.show_aqi = postdata.show_aqi.toString();
    postdata.show_bmi = postdata.show_bmi.toString();
    postdata.use_geolocation = postdata.use_geolocation.toString();
    postdata.show_manufacturer = postdata.show_manufacturer.toString();
    postdata.default_weight_unit = postdata.default_weight_unit.toString();
    postdata.default_height_unit = postdata.default_height_unit.toString();
    postdata.duration = postdata.duration.toString();

    if (postdata.pump_access == 2) {
      postdata.pump_access = "add"
    } else if (postdata.pump_access == 3) {
      postdata.pump_access = "view"
    } else {
      postdata.pump_access = null
    }

    if (postdata.medication_access == 2) {
      postdata.medication_access = "add"
    } else if (postdata.medication_access == 3) {
      postdata.medication_access = "view"
    } else {
      postdata.medication_access = null
    }

    if (postdata.lab_report_access == 2) {
      postdata.lab_report_access = "add"
    } else if (postdata.lab_report_access == 3) {
      postdata.lab_report_access = "view"
    } else {
      postdata.lab_report_access = null
    }
    if (postdata.remodulin == 'is_remodulin') {
      postdata.is_remodulin = 'true';
    } else {
      postdata.is_remodulin = 'false';
    }
    if (postdata.remodulin == 'is_nonremodulin') {
      postdata.is_nonremodulin = 'true';
    } else {
      postdata.is_nonremodulin = 'false';
    }

    postdata.show_history = postdata.show_history.toString(),
      postdata.show_utilities = postdata.show_utilities.toString(),
      postdata.show_targets = postdata.show_targets.toString();
    if (postdata.has_coach_access == "false") {
      postdata['coach_ids'] = "[]";
    }
    postdata.profile_flags = [];
    if (postdata.show_questionnaire == "true") {
      postdata.profile_flags.push('SQR')

    }
    if (postdata.show_triggers == "true") {
      postdata.profile_flags.push('STR')

    }
    if (postdata.show_aqi == "true") {
      postdata.profile_flags.push('SAQ')

    }
    if (postdata.show_history == "true") {
      postdata.profile_flags.push('SHS')
    }
    if (postdata.show_utilities == "true") {
      postdata.profile_flags.push('SUT')
    }
    if (postdata.show_targets == "true") {
      postdata.profile_flags.push('STG')
    }
    if (postdata.is_remodulin == "true") {
      postdata.profile_flags.push('IRM')
    }
    if (postdata.is_nonremodulin == "true") {
      postdata.profile_flags.push('INR')
    }
    if (postdata.use_geolocation == "true") {
      postdata.profile_flags.push('UGL')
    }
    if (postdata.show_manufacturer == "true") {
      postdata.profile_flags.push('SMF')
    };

    if (postdata.chat_flags) {
      for (var i in postdata.chat_flags) {
        postdata.profile_flags.push(postdata.chat_flags[i])
      }
    }

    if (postdata.dairy_flags) {
      for (var i in postdata.dairy_flags) {
        postdata.profile_flags.push(postdata.dairy_flags[i])
      }
    }
    if (postdata.pro_flags) {
      for (var i in postdata.pro_flags) {
        postdata.profile_flags.push(postdata.pro_flags[i])
      }
    }

    if (postdata.health_flags) {
      for (var i in postdata.health_flags) {
        postdata.profile_flags.push(postdata.health_flags[i])
      }
    }
    if (postdata.show_medsurvey == "false") {
      let index;
      index = postdata.profile_flags.indexOf('SMS');
      if (index !== -1) {
        postdata.profile_flags.splice(index, 1); // 
      }
    }

    if (postdata.show_medsurvey == "true") {
      postdata.profile_flags.push('SMS')
    }

    newarray = [...new Set(postdata.profile_flags)]
    postdata.profile_flags = JSON.stringify(newarray);
    delete postdata.remodulin;
    delete postdata.chat_flags;
    delete postdata.dairy_flags;
    delete postdata.health_flags;
    delete postdata.pro_flags;
    delete postdata.show_medsurvey;


    // console.table(postdata.profile_flags);
    // this.campaignEditdata = this.campaigndata;
    // // postdata1.profile_flags=postdata1.profile_flags.replaceAll(/["']/g, "")
    // this.campaignEditdata.profile_flags = JSON.stringify(this.campaignEditdata.profile_flags);

    // this.campaignEditdata.coach_ids = JSON.stringify(this.campaignEditdata.coach_ids);
    // //   return;


    // if (this.campaignEditdata.clientDataId) {
    //   this.campaignEditdata.clientDataId = this.campaignEditdata.clientDataId.toString();
    // }
    // this.campaignEditdata.created_by_data_fk = this.campaignEditdata.created_by_data_fk.toString();
    // if (this.campaignEditdata.doctor_data) { this.campaignEditdata.doctor_data = this.campaignEditdata.doctor_data.toString(); }
    // if (this.campaignEditdata.end_date) { this.campaignEditdata.end_date = this.campaignEditdata.end_date.toString(); }
    // // this.campaignEditdata.profile_flags = this.campaignEditdata.pro_flags.toString();
    // this.campaignEditdata.has_client = this.campaignEditdata.has_client.toString();
    // this.campaignEditdata.has_coach_access = this.campaignEditdata.has_coach_access.toString();
    // this.campaignEditdata.has_doctor_access = this.campaignEditdata.has_doctor_access.toString();
    // this.campaignEditdata.has_end_date = this.campaignEditdata.has_end_date.toString();
    // this.campaignEditdata.is_nonremodulin = this.campaignEditdata.is_nonremodulin.toString();
    // this.campaignEditdata.is_paid = this.campaignEditdata.is_paid.toString();
    // this.campaignEditdata.is_remodulin = this.campaignEditdata.is_remodulin.toString();
    // this.campaignEditdata.is_test = this.campaignEditdata.is_test.toString();
    // if (this.campaignEditdata.lab_report_access) {
    //   this.campaignEditdata.lab_report_access = this.campaignEditdata.lab_report_access.toString();
    // }
    // this.campaignEditdata.max_upload_limit = this.campaignEditdata.max_upload_limit.toString();
    // if (this.campaignEditdata.medication_access) {
    //   this.campaignEditdata.medication_access = this.campaignEditdata.medication_access.toString();
    // }
    // this.campaignEditdata.profile_flags = this.campaignEditdata.profile_flags.toString();
    // if (this.campaignEditdata.pump_access) {
    //   this.campaignEditdata.pump_access = this.campaignEditdata.pump_access.toString();
    // }
    // this.campaignEditdata.show_aqi = this.campaignEditdata.show_aqi.toString();
    // this.campaignEditdata.show_bmi = this.campaignEditdata.show_bmi.toString();
    // this.campaignEditdata.show_history = this.campaignEditdata.show_history.toString();
    // this.campaignEditdata.show_manufacturer = this.campaignEditdata.show_manufacturer.toString();
    // this.campaignEditdata.show_questionnaire = this.campaignEditdata.show_questionnaire.toString();
    // this.campaignEditdata.show_support_email_id = this.campaignEditdata.show_support_email_id.toString();
    // this.campaignEditdata.show_support_phone_number = this.campaignEditdata.show_support_phone_number.toString();
    // this.campaignEditdata.show_targets = this.campaignEditdata.show_targets.toString();
    // this.campaignEditdata.show_triggers = this.campaignEditdata.show_triggers.toString();
    // this.campaignEditdata.show_utilities = this.campaignEditdata.show_utilities.toString();
    // this.campaignEditdata.use_geolocation = this.campaignEditdata.use_geolocation.toString();


    // // delete postdata1['coach_ids'];
    // delete this.campaignEditdata['clientDataId'];
    // // delete this.campaignEditdata['coach_ids'];
    // delete this.campaignEditdata['created_at'];
    // delete this.campaignEditdata['deletedByFkId'];
    // delete this.campaignEditdata['deleted_at'];
    // delete this.campaignEditdata['deleted_by_fk'];
    // delete this.campaignEditdata['doctorDataId'];
    // delete this.campaignEditdata['migration_id'];
    // delete this.campaignEditdata['id'];
    // delete this.campaignEditdata['is_active'];
    // delete this.campaignEditdata['is_deleted'];
    // delete this.campaignEditdata['softDeletedByFkId'];
    // delete this.campaignEditdata['soft_deleted_at'];
    // delete this.campaignEditdata['soft_deleted_by_fk'];
    // delete this.campaignEditdata['statusActivatedByFkId'];
    // delete this.campaignEditdata['status_activated_at'];
    // delete this.campaignEditdata['status_activated_by_fk'];
    // delete this.campaignEditdata['updated_at'];




    // var compareJSON = function (obj1, obj2) {
    //   var ret = {};
    //   for (var i in obj2) {
    //     if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
    //       ret[i] = obj2[i];
    //     }
    //   }
    //   return ret;
    // };

    // var updated_value = ((compareJSON(this.campaignEditdata, postdata1)));
    // var prev = ((compareJSON(postdata1, this.campaignEditdata)));
    // let campaignEditVal = [];
    // for (let key in prev) {
    //   for (let key in updated_value) {
    //     let oldvalue;
    //     let oldKey;
    //     let currentVal;
    //     if (prev.hasOwnProperty(key) == updated_value.hasOwnProperty(key) &&
    //       (prev.hasOwnProperty(key) && updated_value.hasOwnProperty(key) != undefined)
    //     ) {
    //       oldvalue = prev[key];
    //       oldKey = key;
    //       currentVal = updated_value[key];
    //       let sentence = oldKey + " value " + oldvalue + " is updated to " + currentVal;
    //       let data1: any = {}
    //       data1.title = sentence,
    //         data1.key = oldKey,
    //         data1.user_id_data_fk = localStorage.getItem('scarletuserid'),
    //         data1.source_of_password_change = localStorage.getItem('app'),
    //         data1.password_changed_via = 'Dashboard'
    //       campaignEditVal.push(data1);
    //     }
    //   }
    // }

    // let uniqObj = this.getUniqueListByKey(campaignEditVal, 'key')
    // postdata['campaign_history'] = uniqObj;
    // postdata['user_id_data_fk'] = localStorage.getItem('scarletuserid');
    if (this.newitem == true) {
      this.commonService.postToBlade(postdata, 'campaigndata')
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.commonService.updatedataToBlade(this.childid, postdata, 'campaigndata')
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Campaign has been created"), "OK");
    this.closeModal();
    // this.router.routeReuseStrategy.shouldReuseRoute = function () { return true; };
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
    //     this.router.navigate(['admin/campaign'])
  }

  onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Campaign has been updated"), "OK");
    this.closeModal();
    // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
    //this.router.navigate(['admin/campaign']);
  }

  // toString(myObj:any){
  //   Object.keys(myObj).forEach(function(key){
  //     typeof myObj[key] == 'object' ? toString(myObj[key]) : myObj[key]= String(myObj[key]);
  //   });
  // }
  // ----------------------------


  closeModal(): void {
    this.dialogRef.close();
  }


  showChatList = [
    { "name": "show carey", "code": "SCA" },
    { "name": "show coach", "code": "SCO" }
  ]
  showDairyList = [
    { "name": "insight", "code": "ISG" },
    { "name": "streaks", "code": "STK" }
  ]
  showProfileList = [
    { "name": "AppDetail page title", "code": "TIT" },
    { "name": "Mother tongue", "code": "LGL" },
    { "name": "show reminders", "code": "SRM" },
    { "name": "show prescription", "code": "SPR" },
    { "name": "show FAQ", "code": "SFQ" },
    { "name": "show YOU tab", "code": "YOU" },
    { "name": "show Setting tab", "code": "SET" },
    { "name": "Health detail", "code": "SHD" },
    { "name": "show caregiver", "code": "SCG" },
    { "name": "show doctor", "code": "DOC" },
    { "name": "show Subscription", "code": "SUB" },
    { "name": "show theme", "code": "THM" },
    { "name": "show language", "code": "LNG" },
    { "name": "show t&C", "code": "TAC" },
    { "name": "show privacy policy", "code": "PPY" },
    { "name": "show app detail", "code": "DET" },
    { "name": "customer support", "code": "CSU" },
    { "name": "Skip preview screen", "code": "SPS" },
    { "name": "Show Policy Progress data", "code": "SPD" },
    { "name": "Add policy members", "code": "APM" },
    { "name": "Steps to be shown", "code": "SST" },
    { "name": "Show Reward Section", "code": "SRW" },
    { "name": "coach mark", "code": "ECM" },
    { "name": "Report AE", "code": "RAE" },
    { "name": "Resync_GoogleFit", "code": "RGH" },
    { "name": "Show Google/Health Kit Rewards", "code": "SGR" },
    { "name": "In-App community", "code": "ACA" },
    { "name": "Womens Health", "code": "WHM" },
    { "name": "Partner Notes", "code": "PPN" },
    { "name": "Doctor Visit", "code": "SDV" },

  ]

  showHealthList = [
    { "name": "show Blood Sugar Monitor", "code": "BSM" },
    { "name": "show Eye Problem", "code": "EYP" },
    { "name": "show Gym Access", "code": "GYM" },
    { "name": "show Working Professional", "code": "WOP" },
    { "name": "Location Access", "code": "LOA" },
    { "name": "show diabietic", "code": "DBS" },
    { "name": "show high blood pressure", "code": "HBP" }
  ]
  
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
  getUniqueListByKey(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
}
