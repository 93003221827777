import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { PersistenceService, StorageType, IPersistenceContainer } from 'angular-persistence';
import { ContextService } from '../../services/context.service';
import { environment } from '../../../../environments/environment';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { HttpService } from 'src/app/shared/services';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})

export class UserdetailsService extends HttpService {

  public s3tokenurl: any = environment.serverUrl;
  public graphql: any = environment.gqlUrl + 'gql?query=query';
  public apiUrl: string = environment.serverUrl;
  private profileUrl = this.apiUrl + 'patient/complete/';
  private profilesettingUrl = this.apiUrl + 'setting/data/';
  private presprogressUrl = this.apiUrl + 'homefeed/level/data';
  private prescriptionUrl = this.apiUrl + 'utilities/prescriptions';
  private userDiaryUrl = this.apiUrl + 'healthcoach/users/diary';
  private userMealGetTargetUrl = this.apiUrl + 'utilities/targets';
  private userMealSaveTargetUrl = this.apiUrl + 'healthcoach/users/targets';
  private userActivitySaveUrl = this.apiUrl + 'healthcoach/users/healthstatus';
  private getLocalFoodDatabase = this.apiUrl + 'diary/recipes';
  private prescriptionupload = this.apiUrl + 'prescription';
  private remsettingUrl = this.apiUrl + 'setting/v2/data/';
  private nutritionixUrl = 'https://trackapi.nutritionix.com/v2/search/instant';
  private nutitionixBreakupUtl = '  https://trackapi.nutritionix.com/v2/natural/nutrients';
  private caregiverurl = this.apiUrl + 'healthcoach/users/caregiver';
  public microserviceUrl: any = environment.microserviceUrl;
  private alldata: any;
  public allextension: any = {
    caregiver: 'caregiver',
    doc_code: 'patient/mapping/doctor',
    symptoms: 'diary/symptoms/getall',
    triggers: 'diary/data/bypatient/trigger',
    questionnaire: 'questionnaire/bypatient',
    mealsearch: 'meal/search/app',
    transactions: 'patient/payment/data'
  };
  constructor(private http: HttpClient, private appContext: ContextService, private authService: AuthenticateService, private persistenceService: PersistenceService, private authenticateService: AuthenticateService, private systemService: SystemService) {
    super(http)
  }


  public getUserProfileDetails(userID: string): Observable<any> {
    return this.post(this.profileUrl + userID, {})
  }
  public getUserSettingDetails(userID: string): Observable<any> {
    return this.http.get<any>(this.profilesettingUrl + userID)
  }
  public getUserRemSettingDetails(userID: string): Observable<any> {
    return this.http.get<any>(this.remsettingUrl + userID)
  }
  public getUserProgramProgress(data: string): Observable<any> {
    return this.post(this.presprogressUrl, data)
  }

  public getUserPrescription(userID: string): Observable<any> {
    return this.get(this.prescriptionUrl)
  }

  public getUserDiary(userID: string): Observable<any> {
    return this.get(this.userDiaryUrl)
  }

  public getEligibleLogs(userID: string): Observable<any> {
    return this.get(this.s3tokenurl + 'priority/mapping/log/byPatient/' + userID)
  }

  public getMealTargets(userID: string): Observable<any> {
    return this.get(this.userMealGetTargetUrl)
  }

  public saveMealTargets(userID: string, data: Object): Observable<any> {
    this.alldata = data;
    var addedweight = this.alldata.weight;
    delete this.alldata.weight;
    const body: any = {
      "weight": addedweight,
      "meal_targets": {
        "overall": {
          data
        },
      }
    };
    return this.put(this.userMealSaveTargetUrl, body)
  }

  public saveActivityAnalysis(userID: string, activityAnalysis: string): Observable<any> {
    return this.put(this.userActivitySaveUrl, {})
  }



  public getLocalFoodData(): Observable<any> {
    return this.get(this.getLocalFoodDatabase)
  }

  public getNutritionixbreakup(name): Observable<any> {
    let headers = new HttpHeaders().set('x-app-id', 'a408daa7').append("x-app-key", '47ae361e0fe096694a32bdac4290aa18');
    return this.post(this.nutitionixBreakupUtl, { "query": name }, { headers: headers })
  }

  public makeAcall(a, b, c, d): Observable<any> {
    const params: any = {
      "k_number": a,
      "agent_number": b,
      "customer_number": c,
      "caller_id": d
    };
    let headers = new HttpHeaders().set('Authorization', '7714b59a-7ba1-4ec8-bd94-af138beb286d').append("x-api-key", 'MuPhwXRXB05hlIS793aRo51Pr03iIqei2NtzGMXm').append(InterceptorSkipHeader, '');
    return this.post('https://kpi.knowlarity.com/Basic/v1/account/call/makecall', params, { headers: headers })
  }

  public callList(): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', '7714b59a-7ba1-4ec8-bd94-af138beb286d').append("x-api-key", 'MuPhwXRXB05hlIS793aRo51Pr03iIqei2NtzGMXm').append(InterceptorSkipHeader, '');
    return this.get('https://kpi.knowlarity.com/Basic/v1/account/numbers', { headers: headers })
  }
  public getcalllogs(st, en, hc, cust): Observable<any> {
    var url;
    if (cust == null) {
      url = 'https://kpi.knowlarity.com/Basic/v1/account/calllog?start_time=' + encodeURIComponent(en) + '&end_time=' + encodeURIComponent(st) + '&agent_number=' + encodeURIComponent("+91" + hc) + '&limit=' + encodeURIComponent("40")
    }
    else {
      url = 'https://kpi.knowlarity.com/Basic/v1/account/calllog?start_time=' + encodeURIComponent(en) + '&end_time=' + encodeURIComponent(st) + '&agent_number=' + encodeURIComponent("+91" + hc) + '&limit=' + encodeURIComponent("40") + '&customer_number=' + encodeURIComponent(cust)
    }
    let headers = new HttpHeaders().set('Authorization', '7714b59a-7ba1-4ec8-bd94-af138beb286d').append("x-api-key", 'MuPhwXRXB05hlIS793aRo51Pr03iIqei2NtzGMXm').append(InterceptorSkipHeader, '');
    return this.http.get<any>(url, { headers: headers })
  }

  public getNutritionix(pmm): Observable<any> {
    const params: any = {};
    var nutritionixurl = this.nutritionixUrl + "?query=" + pmm + "&branded=false";
    let headers = new HttpHeaders().set('x-app-id', 'a408daa7').append("x-app-key", '47ae361e0fe096694a32bdac4290aa18');
    return this.get(nutritionixurl, { headers: headers, params: params })
  }


  public getMisc(body, service): Observable<any> {
    return this.post(this.s3tokenurl + this.allextension[service], body)
  }

  public getAlltriggers(patientid): Observable<any> {
    let body = {
      "patient_id_data_fk": patientid,
      "language_data_fk": "1"
    };
    return this.post(this.s3tokenurl + 'trigger/all', body)
  }

  public postLabReport(type, report_data, id): Observable<any> {
    return this.post(this.s3tokenurl + 'diary/' + type, report_data)
  }

  public UpdateMealLog(report_data,group_id): Observable<any> {
    return this.patch(this.s3tokenurl + 'diary/' + 'meal/' + group_id, report_data)
  }

  public deleteReport(type, id): Observable<any> {
    return this.delete(this.s3tokenurl + 'diary/' + type + '/' + id)
  }


  public postPrescription(data: any): Observable<any> {
    return this.post(this.prescriptionupload, data)
  }

  postImageToBlade(path, size, title, description, mime, upload_type, uploaded_from): Observable<string> {
    title = this.removeHTMLtags(title);
    description = this.removeHTMLtags(description);
    var imagedata = {
      "path": path,
      "size": size,
      "title": title,
      "detailed_text": description,
      "mime": mime,
      "upload_type": upload_type,
      "uploaded_from": uploaded_from
    }
    return this.post(this.s3tokenurl + 'document/create', imagedata)
  }


  postToBlade(data, service): Observable<string> {
    return this.post(this.s3tokenurl + this.allextension[service], data)
  }

  public postCareGiver(userID: string, data: any): Observable<any> {
    return this.put(this.caregiverurl, data)
  }


  public saveUserNotes(userID: string, healthcoachID: string, note: string, type: string): Observable<any> {
    note = this.removeHTMLtags(note);
    let bodydata = {
      patient_data_fk: userID,
      created_by_data_fk: healthcoachID,
      raw_content: note,
      content: note,
      type: type
    };
    return this.post(this.s3tokenurl + 'patient/note', bodydata)
  }

  public saveEditNotes(patientId, hcId, notesId, newContent, oldContent){
    let body = {
      patient_data_fk: patientId.toString(),
      created_by_data_fk: hcId.toString(),
      notes_data_fk: notesId.toString(),
      new_content: newContent,
      old_content: oldContent
    }

    return this.post(this.s3tokenurl + "patient/note/edit", body);
  }


  public getPatienInfo(q, count, type, userID, archiveStatus): Observable<string> {
    q = this.removeHTMLtags(q);
    let is_archive = "active";

    if (archiveStatus) {
      is_archive = "archive"
    }

    let body = {
      "q": q,
      "patient_data_fk": userID.toString(),
      "limit": "20",
      "page": count.toString(),
      "type": type,
      "status": is_archive
    }
    return this.post(this.s3tokenurl + 'patient/note/search', body)
  }

  public markNoteAsArchive(noteId, edited_by_data_fk) {

    let url = `${this.apiUrl}patient/note/archive/${noteId}?edited_by_data_fk=${edited_by_data_fk}`;

    return this.patch(url, {});

  }


  public addDetails(data, patient_id_fk, service): Observable<string> {
    data.name = this.removeHTMLtags(data.name);
    data.phone = this.removeHTMLtags(data.phone);
    data.email = this.removeHTMLtags(data.email);
    let bodydata = {
      name: data.name,
      gender: data.gender,
      phone: data.phone,
      relation: data.relation,
      patient_id_fk: patient_id_fk,
      country_code: data.country_code,
      email: data.email
    };
    return this.post(this.s3tokenurl + this.allextension[service], bodydata)
  }

  public EditDetails(caregiverId,data, patient_id_fk, service): Observable<string> {
    data.name = this.removeHTMLtags(data.name);
    data.phone = this.removeHTMLtags(data.phone);
    data.email = this.removeHTMLtags(data.email);
    let bodydata = {
      name: data.name,
      gender: data.gender,
      phone: data.phone,
      relation: data.relation,
      patient_id_fk: patient_id_fk,
      country_code: data.country_code,
      email: data.email,
      updated_by_fk: data.updated_by_fk
      // ,
      // user_id_data_fk:data.user_id_data_fk
    };
    return this.patch(this.s3tokenurl + this.allextension[service] + "/" + caregiverId, bodydata)
  }




  public addDocDetails(data, patient_id_fk, service): Observable<string> {
    let bodydata = {
      patient_data_fk: patient_id_fk,
      user_code: data.doctorCode,
    };
    return this.post(this.s3tokenurl + this.allextension[service], bodydata)
  }

  patietDocMapping(data): Observable<string> {
    return this.post(this.s3tokenurl + 'patient/mapping/doctor/forscarlet', data)
  }
  public updateDocDetails(data, patient_id_fk, service, user_data_fk): Observable<string> {
    let bodydata = {
      patient_data_fk: patient_id_fk,
      user_code: data.doctorCode,
      user_data_fk: user_data_fk
    };
    return this.patch(this.s3tokenurl + this.allextension[service], bodydata)
  }

  public getPatientDiary(id, selected_option, start_d, end_d,page?,limit?): Observable<string> {
      let bodydata;
      if(selected_option === 'activity'){
        bodydata = {
          start_date: start_d,
          end_date: end_d,
          patient_id_data_fk: id.toString(),
          page:page,
          limit:'20',
        };
      }else {
        bodydata = {
          start_date: start_d,
          end_date: end_d,
          patient_id_data_fk: id.toString(),
          mode:'aggregate',
          page:page,
          limit:'20',
        };
      }
      if(selected_option === 'meal') {
        let timezoneFields = this.getTimezoneFields();

        bodydata = {
          ...bodydata,
          ...timezoneFields
        }
      }
    
    return this.post(this.s3tokenurl + 'diary/data/' + selected_option, bodydata)
  }
  public addMedicine(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'medicine', postdata)
  }
  public addReminder(postdata): Observable<string> {
    return this.http.post<any>(this.s3tokenurl + 'reminder/v2', postdata)
  }

  public addSymptoms(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'diary/symptoms', postdata)
  }
  public addPump(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'pump/log', postdata)
  }

  public getPumps(postdata): Observable<any> {
    return this.post(this.s3tokenurl + 'pump/log/data/all', postdata)
  }
  public deletePump(id): Observable<any> {
    return this.delete(this.s3tokenurl + 'pump/log/delete/' + id)
  }

  public deleteMedication(id): Observable<any> {
    return this.delete(this.s3tokenurl + 'medication/log/' + id)
  }

  public getOnePump(id): Observable<any> {
    return this.get(this.s3tokenurl + 'pump/log/get/' + id)
  }

  public addMedication(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'medication/log', postdata)
  }

  public updateMedication(id, postdata): Observable<string> {
    return this.patch(this.s3tokenurl + 'medication/log/' + id, postdata)
  }

  public getOneMedicationById(id): Observable<any> {
    return this.get(this.s3tokenurl + 'medication/log/get/' + id)
  }

  public getMedication(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'medication/log/data/app', postdata)
  }

  public getSymptoms(condition, language): Observable<string> {
    return this.get(this.s3tokenurl + 'priority/mapping/symptom/app?condition_data_fk=' + condition + '&language_data_fk=' + language)
  }


  public getPatientFilteredDiaryData(type, startdate, enddate, range, patientid, sub_type, blood_sugar_log_type, activityUnits): Observable<string> {
    let timezoneFields = this.getTimezoneFields();
    let bodydata = {
      start_date: startdate,
      end_date: enddate,
      range_type: range,
      log_type: type,
      patient_id_data_fk: patientid,
      sub_type: sub_type,
      blood_sugar_log_type: blood_sugar_log_type,
      ...timezoneFields
    };

    if (typeof activityUnits !== "undefined"){
      bodydata["unit_type"] = activityUnits;
    }
    
    return this.post(this.s3tokenurl + 'diary/getGraphs', bodydata)
  }

  getPeakFlowGraphData(start_date, end_date, patient_id_data_fk): Observable<string> {
    let bodydata = {
      start_date: start_date,
      end_date: end_date,
      patient_id_data_fk: patient_id_data_fk.toString(),
      offset_hours: '5',
      offset_minutes: '30',
      utc_relation: 'ahead',
    };
    return this.post(this.s3tokenurl + 'diary/peakFlow/graph', bodydata)
  }


  public uploadPres(bodydata): Observable<string> {
    return this.post(this.s3tokenurl + 'prescription', bodydata)
  }

  public createDynamicLink(data): Observable<string> {
    return this.http.post<any>(this.s3tokenurl + 'deeplink', { "query": data })
  }


  public getUserPaymentPlans(uid): Observable<string> {
    let body = {
      "patient_data_fk": uid.toString(),
      "language_id": "1"
    }
    return this.post(this.s3tokenurl + 'product/mapping/bypatient', body)
  }

  public createPaymentLink(data): Observable<string> {
    return this.post(this.s3tokenurl + 'patient/payment/link', data)
  }
  public getInfusionSitedata(data): Observable<string> {
    return this.post(this.s3tokenurl + 'diary/data/infusionsite', data)
  }
  public addInfusionSite(data): Observable<string> {
    return this.post(this.s3tokenurl + 'diary/infusionsite', data)
  }

  public hardDelete(id): Observable<any> {
    return this.delete(this.s3tokenurl + 'diary/infusionsite' + '/' + id)
  }

  public getMedicationdata(data): Observable<string> {
    return this.post(this.s3tokenurl + 'medication/log/data/app', data)
  }
  public getPumpdetails(data): Observable<string> {
    return this.post(this.s3tokenurl + 'pump/log/data/app', data)
  }
  public getUserMedicalHistory(id): Observable<string> {
    return this.get(this.s3tokenurl + 'medicalhistory/' + id)
  }
  public addUserMedicalHistory(data): Observable<string> {
    return this.post(this.s3tokenurl + 'medicalhistory', data)
  }

  public getGraphQlData(parameter: string, org?): Observable<any> {
    if (org != false && environment.org) {
      var orgdata = 'status:true,organization_data:"' + this.authService.getorg() + '"';
      parameter = parameter.replace('status:true', orgdata)
    }
    return this.get(encodeURI(this.graphql + parameter))
  }

  public getROAData(id): Observable<string> {
    return this.get(this.s3tokenurl + 'routeofadministration/log/' + id)
  }

  public addROAData(data): Observable<string> {
    return this.post(this.s3tokenurl + 'routeofadministration/log', data)
  }
  public roaDelete(id): Observable<any> {
    return this.delete(this.s3tokenurl + 'routeofadministration/log' + '/' + id)
  }
  public getOneRoute(id): Observable<any> {
    return this.get(this.s3tokenurl + 'routeofadministration/log/get/' + id)
  }
  public updateROAData(id, data): Observable<string> {
    return this.patch(this.s3tokenurl + 'routeofadministration/log/' + id, data)
  }
  public getOnePumpData(id): Observable<string> {
    return this.get(this.s3tokenurl + 'pump/log/get/' + id)
  }
  public updatePumpData(id, data): Observable<string> {
    return this.patch(this.s3tokenurl + 'pump/log/' + id, data)
  }

  getAverseEffectByPatient(bodydata): Observable<string> {

    return this.post(this.s3tokenurl + 'adverse/effect', bodydata)
  }
  public gethistoricalPumps(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'pump/log/history', postdata)
  }

  public addAdverseEffect(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'adverse/effect', postdata)
  }
  public getAdversedata(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'adverse/effect/get', postdata)
  }
  public getOneAdversedata(id): Observable<string> {
    return this.get(this.s3tokenurl + 'adverse/effect/' + id)
  }
  public updateAdversedata(id, postdata): Observable<string> {
    return this.patch(this.s3tokenurl + 'adverse/effect/' + id, postdata)
  }
  public sendAEMail(postdata): Observable<string> {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    const mailbody = new HttpParams()
      .set('action_taken', postdata.action_taken)
      .set('adverse_end_date', postdata.adverse_end_date.toString())
      .set('adverse_outcome', postdata.adverse_outcome.toString())
      .set('adverse_reaction', postdata.adverse_reaction.toString())
      .set('adverse_reaction_abated', postdata.adverse_reaction_abated)
      .set('adverse_reaction_disappeared', postdata.adverse_reaction_disappeared)
      .set('adverse_reaction_recurred', postdata.adverse_reaction_recurred)
      .set('adverse_start_date', postdata.adverse_start_date.toString())
      .set('age', postdata.age.toString())
      .set('causality_relatedness', postdata.causality_relatedness)
      .set('country', postdata.country.toString())
      .set('dob', postdata.dob.toString())
      .set('gender', postdata.gender.toString())
      .set('height', postdata.height.toString())
      .set('height_unit', postdata.height_unit.toString())
      .set('medicinal_product', postdata.medicinal_product)
      .set('patient_data_fk', postdata.patient_data_fk.toString())
      .set('patient_medical_history', postdata.patient_medical_history)
      .set('qualification', postdata.qualification.toString())
      .set('situtation_to_patient', postdata.situtation_to_patient)
      .set('subject', postdata.subject.toString())
      .set('to', postdata.to)
      .set('weight', postdata.weight.toString())
      .set('weight_unit', postdata.weight_unit.toString())

    return this.http.post<any>(this.microserviceUrl + 'sesemail/send', mailbody, { headers: headers })
  }

  public deleteAEEvent(id, postdata): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: postdata
    }
    return this.delete(this.s3tokenurl + 'adverse/effect/' + id, options)
  }

  public deletePresc(id, postdata): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: postdata
    }
    return this.delete(this.s3tokenurl + 'prescription/delete/' + id, options)
  }

  public cowinPreference(postdata): Observable<string> {
    return this.post(this.microserviceUrl + 'cowin/notification/preference', postdata)
  }
  public cowinPatientData(postdata): Observable<string> {
    return this.post(this.microserviceUrl + 'cowin/notification/preference/get', postdata)
  }
  public cowinSlotData(postdata): Observable<string> {
    return this.post(this.microserviceUrl + 'cowin/slot', postdata)
  }
  public postPermissionData(postdata): Observable<string> {
    return this.post(this.microserviceUrl + 'taskmanagement/permissions', postdata)
  }
  public getpatientPermissiondata(id): Observable<string> {
    return this.get(this.microserviceUrl + 'taskmanagement/permissions?patient_id_fk=' + id)
  }

  public getCoachmarkProgressdata(id): Observable<string> {
    return this.get(this.s3tokenurl + 'coachmarksscreen/progress/?patient_id_fk=' + id)
  }

  public getCoachMarkProgress(patient_id_fk, screen): Observable<string> {
    //  
    let postData: any = {};
    postData.patient_id_fk = patient_id_fk;
    postData.screen = screen;
    return this.post(this.s3tokenurl + 'coachmarksscreen/progress', postData);
  }
  // public getMedicationSurveyData(id): Observable<string> {
  //   return this.get(this.s3tokenurl + 'medicationsurvey/unfilled/' + id)
  // }

  public getMedicationSurveyData(postdata): Observable<string> {
    return this.post(this.s3tokenurl + 'medicationsurvey/data', postdata)
  }
  public getMedicationDatabyID(id, postdata): Observable<string> {
    return this.patch(this.s3tokenurl + 'medicationsurvey/' + id, postdata)
  }

  public getMCHITags(): Observable<string> {
    return this.get(this.s3tokenurl + 'app/master/data')
  }

  public getPatientTargetData(id): Observable<string> {
    return this.get(this.s3tokenurl + 'patient/targets/' + id)
  }

  private infusionData = new BehaviorSubject<any>(null);
  current__infusionData = this.infusionData.asObservable();

  change_infusionData(applicationData: any) {
    this.infusionData.next(applicationData)
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

  updateGestationalDetails (patient_id_fk,Updated_by_user_fk,edd?,lmp?,delivery_date?,delivery_output?): Observable<any>{
    let postData: any = {};
    postData.patient_id_data_fk = patient_id_fk;
    postData.edd = edd;
    postData.lmp = lmp;
    postData.delivery_date = delivery_date;
    postData.delivery_output = delivery_output;
    postData.updated_by_user_fk = Updated_by_user_fk;

    return this.post(this.s3tokenurl + 'womens/health', postData);
  }
  
  getTimezoneFields () {
    let fields:any = {};
    try {
      let offset = new Date().getTimezoneOffset()
      let minutes = Math.abs(offset);
      if(offset >= 0) {
        fields.utc_relation = "behind";
      } else {
        fields.utc_relation = "ahead";
      }
      fields.offset_hours = '' + Math.floor(minutes / 60);
      fields.offset_minutes = '' + minutes % 60;
    } catch(error) {
      console.log(`getTimezoneFields`, error);
      fields = {};
    }
    return fields;
  }
}

