<mat-drawer-container class="docsidenav-container">

  <!--Left Navbar-->
  <div class="sharednav">
    <app-docleftnav></app-docleftnav>
  </div>

  <div class="sidenav-content">

    <app-breadcrumb route="doctor/user"></app-breadcrumb>

    <!--Userlist Table-->
    <div class="leftuserlistnav">
      <app-docuserlistnavbar></app-docuserlistnavbar>
    </div>

    <div id="mainrightcontainersecondary">
      <div class="docuserdetails">

        <!--Fixed top-bar Section (Web View) -->
        <div class="fixedtoolbar">
          <div class="row fixedtoolbar-cont">
            <div class="col-md-4 col-sm-12 docuserbasicdetail">
              <!-- <mat-icon class="backarrow" [routerLink]="['/doctor/home']">keyboard_arrow_left</mat-icon> -->
              
              <div class="row">
                <div class="col-md-8">
                  <h4 class="title notranslate">
                    {{patientdata?.name| titlecase}}
                  </h4>
      
                  <p class="subprofiletitle notranslate">
                    {{patientdata?.gender=="m"?"Male": (patientdata?.gender=="o"?"Other" : "Female")}} 
                    <span>|</span>
                    {{getWholeNumberAge(patientdata?.dob)}}
                  </p>
                </div>
                <div class="col-md-4">

                  <button class="followbtn" (click)="followup(patientfollowup);" mat-flat-button color="primary">
                    <mat-progress-spinner *ngIf="spinnerButton" color="primary" mode="indeterminate" value="50" diameter="15" class="pull-left notranslate" style="margin-left: 0;margin-top: 8px;">
                    </mat-progress-spinner>

                    <span *ngIf="patientfollowup == 'true'">Unfollow</span>
                    <span *ngIf="patientfollowup == 'false'">Follow-up</span>
                  </button>
      
                  <div *ngIf="patientdata?.show_aqi notranslate">
                    <span class="buttonrectangle">{{patientdata?.show_aqi ? 'AQI' : 'Inactive'}}</span>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- <div class="col-md-2 col-sm-12  secondarycondition">
              <div *ngIf="patientdata?.show_aqi notranslate">
                <span class="buttonrectangle">{{patientdata?.show_aqi ? 'AQI' : 'Inactive'}}</span>
              </div>
            </div> -->
  
            <div class="col-md-8 col-sm-12 docuserdetailconditions text-right" *ngIf="clientDetailsPDFDownload != 'JLI'">
              <form [formGroup]="dataForm" validate class="filter-form">

                <mat-form-field appearance="outline" (click)="$event.stopPropagation();"  class="col-md-4 doc-date-picker">
                  <input matInput formControlName="startdate" [matDatepicker]="picker1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [max]="morrow" placeholder="Start date">
                  <mat-datepicker-toggle matSuffix [for]="picker1" [disableRipple]="true">
                    <mat-icon matDatepickerToggleIcon>
                      <img src="assets/img/dls/icons/Icon_Calendar.svg" alt="calender-icon" width="16px" height="16px" class="calendar-icon">
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" (click)="$event.stopPropagation();" class="col-md-4 doc-date-picker">
                  <input matInput formControlName="enddate" [matDatepicker]="picker2" [min]="currentdate" [max]="newDate" placeholder="End date">
                  <mat-datepicker-toggle matSuffix [for]="picker2" [disableRipple]="true">
                    <mat-icon matDatepickerToggleIcon>
                      <img src="assets/img/dls/icons/Icon_Calendar.svg" alt="calender-icon" width="16px" height="16px" class="calendar-icon">
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

                <div class="col-md-4">
                  <button mat-flat-button color="primary" class="primary apply-btn btn-block doctor-apply-btn" (click)="downloadReport()" [disabled]="!dataForm.valid">Download report</button>
                </div>
              </form>
              <!-- <button class="downloadbtn" (click)="downloadReport();" mat-stroked-button color="primary">
                <mat-progress-spinner *ngIf="isdownloadingreport" color="primary" mode="indeterminate"
                  value="50" diameter="20" class="pull-left" style="margin-top: 7px;margin-right: 10px;">
                </mat-progress-spinner> {{ isdownloadingreport ? 'Downloading' : 'Download Report' }}
              </button>
  
              <button class="followbtn" (click)="followup(patientfollowup);" mat-stroked-button color="primary">
                <mat-progress-spinner *ngIf=spinnerButton color="primary" mode="indeterminate" value="50" diameter="20"
                  class="pull-left" style="margin-left: 0;margin-top: 8px;">
                </mat-progress-spinner>
  
                <span *ngIf="patientfollowup == 'true'">Unfollow</span>
                <span *ngIf="patientfollowup == 'false'">Follow-up</span>
  
  
              </button> -->
  
              <!-- <div class="row">              
                old code not using in v3
                <div class="col-md-7 secondarycondition">
                  <span class="buttonrectangle">{{primary_condition}}</span>
                  <span class="buttonrectangle" *ngFor="let sc of secondary_condition">{{sc}}</span>
                </div>
                old code not using in v3
                <div class="col-md-5">
                  <div class="hidden-xs row">
                    <div class="col-md-12">
                      <div class="row buttonsrow">
                        <div class="col-md-5">
                          <button class="followbtn" (click)="followup();" mat-stroked-button color="primary">
                            <mat-progress-spinner *ngIf=spinnerButton color="primary" mode="indeterminate" value="50"
                              diameter="20" class="pull-left" style="margin-top: 6px;margin-right: 5px;">
                            </mat-progress-spinner>
                            {{patientfollowup ? 'Unfollow' : 'Follow-up'}}                        
                          </button>
                        </div>
                        <div class="col-md-5">
                          <button class="downloadbtn" (click)="downloadReport();" mat-stroked-button color="primary">
                            <mat-progress-spinner *ngIf="isdownloadingreport" color="primary" mode="indeterminate"
                              value="50" diameter="20" class="pull-left" style="margin-top: 7px;margin-right: 10px;">
                            </mat-progress-spinner> {{ isdownloadingreport ? 'Downloading' : 'Download Report' }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="logs-wrapper">
                <!---Toggle Buttons--->
                <mat-button-toggle-group appearance="standard" [value]="currentLogSelection" name="reportSelection" aria-label="Report Selection">
                  <mat-button-toggle value="diary" [checked]="true" class=" btn-outline notranslate" (change)="currentLogSelection = 'diary';">
                    Diary
                  </mat-button-toggle>
  
                  <mat-button-toggle value="reports"  class=" btn-outline notranslate" (change)="currentLogSelection = 'reports';">
                    Lab reports 
                  </mat-button-toggle>
  
                  <mat-button-toggle value="others"  class=" btn-outline notranslate" (change)="currentLogSelection = 'others';">
                    Others
                  </mat-button-toggle>
                  <mat-button-toggle value="notes"  class=" btn-outline notranslate" (change)="currentLogSelection = 'notes';">
                    Notes
                  </mat-button-toggle>
  
                </mat-button-toggle-group>
  
              </div>
            </div>
          </div>

        </div>

        <!--Fixed top-bar Section (Mobile View) -->
        <div class="fixedtoolbar mobileProfileInfo" style="display:none;">
          <div class="">

            <div class="col-xs-9 p-0">
              <div class="docuserbasicdetail">
                <div class="media">
                  <div class="media-left">
                    <mat-icon class="backarrow" [routerLink]="['/doctor/home']">keyboard_arrow_left</mat-icon>
                  </div>
                  <div class="media-body">
                    <h4 class="title">
                      {{patientdata?.name| titlecase}}
                    </h4>
                    <p class="subprofiletitle">
                      {{patientdata?.gender=="m"?"Male": (patientdata?.gender=="o"?"Other" : "Female")}} |
                      {{patientdata?.age}} Years
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-3">
              <div class="text-right menuMobileMore">
                <button mat-icon-button [matMenuTriggerFor]="menu" class="menuMoreMobile">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <!-- <button mat-menu-item (click)="downloadReport();">
                    <mat-progress-spinner *ngIf="isdownloadingreport" color="primary" mode="indeterminate" value="50"
                      diameter="20" class="pull-left" style="margin-top: 15px;margin-right: 12px;">
                    </mat-progress-spinner>
                    {{ isdownloadingreport ? 'Downloading' : 'Download Report' }}
                  </button> -->
                  <button mat-menu-item (click)="followup(patientfollowup);">
                    <mat-progress-spinner *ngIf=spinnerButton color="primary" mode="indeterminate" value="50"
                      diameter="20" class="pull-left" style="margin-top: 15px;margin-right: 12px;">
                    </mat-progress-spinner>
                    {{patientfollowup? 'Unfollow' : 'Follow-up'}}
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>

        <div class="userConditionsMobile visible-xs d-none">
          <div class="row">
            <div class="col-xs-12">
              <div class="userConditionsMobileHolder">
                <span class="buttonrectangle">{{primary_condition}}</span>
                <span class="buttonrectangle" *ngFor="let sc of secondary_condition">{{sc}}</span>
              </div>
            </div>
          </div>
        </div>

        <!--Overview Section-->
        <!-- <h3 class="col-lg-12 headings overview-h p-0">Overview</h3>
        <div class="col-lg-12">
          <ul class="overviewcontent">
            <div class="row">
              <li class="col-lg-4"
                *ngIf="(diabetic_since!==''&&diabetic_since!==0&&diabetic_since!==-1)&&(diabetic_primary_condition||diabetic_secondary_condition)">
                <span class="listpadding">&#9679;</span>Diabetic {{diabetic_sincevalue}}</li>
              <li class="col-lg-5" *ngIf="this.program_start!==0 && this.program_start!==''">
                <span class="listpadding">&#9679;</span>On lifestyle intervention program since {{this.program_start}}
              </li>
              <li class="col-lg-3" *ngIf="this.overviewdata?.overview?.last_consultation_on!==0">
                <span class="listpadding">&#9679;</span>Last consultation on
                <span>{{this.overviewdata?.overview?.last_consultation_on}}</span>
              </li>
            </div>
            <div class="row" *ngIf="diabetic_primary_condition||diabetic_secondary_condition">
              <li class="col-lg-4"
                *ngIf="this.overviewdata?.overview?.initial_hba1c !== 0 && this.overviewdata?.overview?.initial_hba1c !== ''">
                <span class="listpadding">&#9679;</span>Program start
                HbA1C: {{this.overviewdata?.overview?.initial_hba1c}} </li>
              <li class="col-lg-5"
                *ngIf="this.overviewdata?.overview?.target_hba1c !== 0 && this.overviewdata?.overview?.target_hba1c!==''">
                <span class="listpadding">&#9679;</span>Target HbA1C: {{this.overviewdata?.overview?.target_hba1c}}</li>
            </div>
          </ul>
        </div> -->

        <!--HealthData Tabulation-->
        <!-- <div class="col-lg-12">
          <div class="row">
            <app-healthdata></app-healthdata>
          </div>
        </div> -->

        <!--Healthcoach Notes Section-->
        <!-- <div *ngIf="hcnotes?.length>0">
          <div class="row">
            <div class="col-md-12">
              <h3 class="headings top-header">Notes from Healthcoach</h3>
              <mat-card class="matCard mat-card-note">
                <p class="hcnotes">{{hcnotes[hcnotes.length-1].content}}</p>
                <div class="hcInfo">
                  <h4 class="hcname">{{patientdata?.healthcoach_data.name}}</h4>
                  <p class="hcdate">{{hcnotesdate}}</p>
                </div>
              </mat-card>
            </div>
            <div class="col-md-12">
              <hr class="hr-margin">
            </div>
          </div>
        </div> -->


        <div class="graphcard">
          <div class="row">
            <div class="col-md-12">
              <ng-container *ngIf="currentLogSelection === 'diary'">
                <app-doctorgraph></app-doctorgraph>
              </ng-container>
              <ng-container *ngIf="currentLogSelection === 'reports'">
                <app-doclabreports></app-doclabreports>
              </ng-container>
              <ng-container *ngIf="currentLogSelection === 'others'">
                <app-otherdetails></app-otherdetails>
              </ng-container>
              <ng-container *ngIf="currentLogSelection === 'notes'">
                <doc-knowuser></doc-knowuser>
              </ng-container>
              
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</mat-drawer-container>

<ng-template #stillLoading>
  <div style="position: fixed;
  width: 130%;
  z-index: 1;
  height: 100%;
  background: #ffffff00;
  margin-left: -30%;
  top: 0%;
  padding-top: 24%;">
    <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
</ng-template>