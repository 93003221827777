import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from './../../shared/shared.module';
import { MediainformationComponent } from './../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from './../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from './../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

import { MediamanagerComponent } from './mediamanager.component';
import { ViewmediaComponent } from './viewmedia/viewmedia.component';
import { UploadmediaComponent } from './uploadmedia/uploadmedia.component';
import { MediamanagerRoutingModule } from './mediamanager.routing.module';
import { ContentnavbarModule } from '../shared/contentnavbar/contentnavbar.module';
import { ContentleftnavModule } from '../shared/contentleftnav/contentleftnav.module';
import { FaqleftnavModule } from '../shared/faqleftnav/faqleftnav.module';
import { TherapysetupleftnavModule } from '../shared/therapysetupleftnav/therapysetupleftnav.module';
import { MediaSetupleftNavModule } from '../shared/media-setupleft-nav/media-setupleft-nav.module';
import { NotificationUploadMediaComponent } from './notification-upload-media/notification-upload-media.component';
import { UploadMediaDialogComponent } from './upload-media-dialog/upload-media-dialog.component';
import { MediaDetailsDialogComponent } from './media-details-dialog/media-details-dialog.component';

@NgModule({
  declarations: [
    MediainformationComponent,
    MediamanagerComponent,
    ViewmediaComponent,
    UploadmediaComponent,
    NotificationUploadMediaComponent,
    UploadMediaDialogComponent,
    MediaDetailsDialogComponent
  ],
  imports: [
    CommonModule,
    MediamanagerRoutingModule,
    ContentnavbarModule,
    ContentleftnavModule,
    FaqleftnavModule,
    MediaSetupleftNavModule,
    TherapysetupleftnavModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent,
    UploadMediaDialogComponent,
    MediaDetailsDialogComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class MediamanagerModule { }
