import { SelectedUserinterface } from './../../../healthcoach/models/selecteduserinterface';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from '@angular/router';
import * as Chartist from "chartist";
import 'chartist-plugin-legend/chartist-plugin-legend.js';
import 'chartist-plugin-tooltips/dist/chartist-plugin-tooltip.min.js';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { SHORT_MONTHS } from '../../../shared/data';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticateService } from 'src/app/shared/services';
import { UserdetailsService } from '../../../healthcoach/services/userdetails/userdetails.service';
import { MatSort } from '@angular/material/sort';
import { Userdetailsinterface } from 'src/app/healthcoach/models/userdetailsinterface';
import { DiaryLogDialogComponent } from 'src/app/healthcoach/components/diarylog/diarylog/diarylogdialog.component';
import { ContextService } from 'src/app/healthcoach/services/context.service';
declare var google: any;

@Component({
  selector: 'app-doctorgraph',
  templateUrl: './doctorgraph.component.html',
  styleUrls: ['./doctorgraph.component.scss']
})

export class DoctorGraphComponent implements OnInit {
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;

  // public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public userHasNix: boolean = false;
  public selectedActivity: string = "Activity";
  public selectedRange: string = "daily";
  public subscribeObj: any;
  public morrow: any = new Date();

  /**Old Diary Variables */
  public emailChartData: any;
  public hoursChartData: any;
  public hoursChartOptions: any;
  public hoursChartResponsive: any[];
  public activityChartData: any;
  public activityChartOptions: any;
  public activityChartResponsive: any[];
  public allusersrawdata: any;
  public rawdata: any;
  public userDataQ: any;
  tabulardata: any = [];

  public symptomnames = {
    "tirednessConfusion": "Tiredness / Confusion",
    "difficultyInBreathing": "Difficulty in Breathing",
    "poundingInChest": "Pounding in Chest, Neck or Ears",
    "sleepProblems": "Sleep problems and daytime exhaustion",
    "painTightnessPressure": "Pain, tightness and pressure in the neck, jaws, shoulder and back",
    "heartPalpitation": "Heart palpitation",
    "swellingInLegs": "Swelling in legs, ankle, feet, stomach and veins of the neck",
    "feelingOfFaintness": "Feeling of faintness",
    "bloating": "Bloating",
    "constipation": "Constipation",
    "diarrhoea": "Diarrhoea",
    "feelingOfNervousness": "Feeling of nervousness or anxiety",
    "nightmares": "Nightmares",
    "dryCough": "Dry Cough",
    "chestPain": "Chest Pain",
    "frequentUrination": "Frequent Urination",
    "dizziness": "Dizziness",
    "headache": "Headache"
  }

  user: any;
  sub: any;
  userid: any;
  AverageActivity: any;
  HighestActivity: any;
  weightpercentage: any;
  newar = [];
  bloodflow: any;
  bloodpercentage: any;
  totalMeal: any;
  defaultleftoption = "activity";
  displayedColumns = [];
  dataSource: any;
  onlinestatus: any;
  allchats = [];
  timestamp: any;
  chattext: any;
  recipientStatus: any;
  chatrectype: any;
  responseUrl: any;
  file: any;
  imageData: any;
  isPDF = false;
  video: any;
  videomimeType: any;
  videosource: any;
  showprev = false;
  selectedFile: any;
  postuploads3response: any;
  uploaderror = false;
  uid: any;
  showTyper = false;
  layer: any;
  useractivename: any;
  useractivenameshort: any;
  showChatLoader = false;
  rawprogressdata: any;
  alluserset: any;
  useractiveid: any;
  respondUserIds: any;
  filterdrespondinuser = [];
  userDetails = 'uprofile';
  uprogressweek = 1;
  usercurrentweek: any;
  nomealsdata = false;
  nobloodsugardata = false;
  nobloodpressuredata = false;
  bloodPressurePercentage: any;
  systolicLastRecorded: any;
  diastolicLastRecorded: any;
  nowaterdata = false;
  bpMessage: any;
  pressureData: any;
  waterData: any;
  HideFirstTime = true;
  ToggleHamburger: any;
  isSaved: boolean;
  isSavedself: boolean;
  presRawData: any;
  prescriptionurl = [];
  coachdata: any;
  coachname: any;
  countsec: any;
  term: any;
  labreport: any;
  isGallery = false;
  progid = false;
  AllAverage: any;
  addedattachment: any;
  prescriptionurlraw: any;
  tabulardatasource: any;
  usermigrate = false;
  recStatusval: any;
  chatroomid: any;
  lastID = null;
  isvisible: boolean = false;
  requestedbloodgraph = 'premeal';
  showtarget: boolean = false;
  activitystatus: any;
  mealnutritionaldata: any;
  documentPlaceHolder = '/assets/img/pdficon.jpg';
  socket: any;
  convoid: any;
  initialdeliverystatus: any;
  initialseenstatus: any;
  convid: any;
  allconvesations: any;
  readtimestamp: any;
  graph_data: any;
  checkconversationfetched: boolean = false;
  getthenewmessage: any;
  connectionchecker: any = true;
  connectivitysub: any;
  mainoption: any = 'all';
  mealsubmenu: any = 'breakfast';
  diffDays: any;
  diarylogopen: any;
  public selectedUserID: string;
  eligibleLogData: any;
  istabulardatapresent: boolean = false;
  public subtype = null;
  public mealsubmenuclicked: boolean = false;
  public bloodsugarsubmenu = 'all';
  public bloodsugarlogtype = '';
  filterargs = { type: 'dairy' };
  infusionNoData: boolean = false;
  MeasurementOptions: any = [];
  MeasurementType: any;
  activityAverage = "";
  waterAverage = "";
  mealAverage = "";
  activityUnits: string;
  targetPatientData: any = [];
  userData: any = {};
  enableGraph:boolean = false;
  public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);


  public dataForm = this.fb.group({
    startdate: [''],
    enddate: [''],
    selectedOption: ['', Validators.required],
  });

  @Input() ff: any;
  latestWeight: any;
  showNodatafound: boolean = true;	
  showNoGraphdatafound: boolean;
  endoflist: boolean = false;selectedOptionData: any;
  createdIdnotificationId: any;
;
  page: any = 0;

  constructor(private appContext: ContextService,private systemService: SystemService, public fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private doctorService: DoctorDataService, private authService: AuthenticateService, private userDetailService: UserdetailsService) { }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.dataForm.get('selectedOption').setValue("");
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && !this.appContext.isSelectedChatUserIsBot.getValue()) {

        this.selectedUserID = this.appContext.selectedChatUser.getValue();
        this.createdIdnotificationId = false;
        this.dataForm.reset();
        this.displayedColumns = [];
        this.dataSource = '';
        this.defaultleftoption = "";
        this.mainoption = "";
        this.getUserEligibleLogs(this.selectedUserID);
        this.getPatientTargets(this.selectedUserID);
        this.initComponentDraw1(this.selectedUserID);
        this.activityAverage = "";
      }

    });
    this.route.params.subscribe(params => {
      this.selectedUserID = params.id;
      this.getUserEligibleLogs(this.selectedUserID);
      this.getPatientTargets(this.selectedUserID);
      this.getUserDetails(this.selectedUserID);
      this.dataForm.reset();
      this.displayedColumns = [];
      this.dataSource = '';
      this.defaultleftoption = '';
      this.mainoption = "all";
      this.initComponentDraw1(this.selectedUserID);
      let notificationStoredValue = localStorage.getItem("selectedNotification");

      if (notificationStoredValue == 'low_bp_event' || notificationStoredValue == 'high_bp_event') {
        let val = 'Blood Pressure';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'low_blood_sugar_event' || notificationStoredValue == 'high_blood_sugar_event' || notificationStoredValue == 'blood_sugar_event') {
        let val = 'Blood Sugar';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'peak_flow') {
        let val = 'Peak Flow';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        var startd = "";
        var endd = "";
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'activity_event') {
        let val = 'Activity';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'weight_event') {
        let val = 'Weight';
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID,val);
      }
      else if (notificationStoredValue == 'water_alert_event') {
        let val = 'Water';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'meal_event') {
        let val = 'Meal';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);
      }
      else if (notificationStoredValue == 'infusion_site_event') {
        let val = 'Infusion Site';
        this.dataForm.get('selectedOption').setValue(val);
        this.selectVal(val);
        this.createdIdnotificationId = localStorage.getItem("createdId");
        this.initComponentDraw1(this.selectedUserID);      }
    });
   
  }

  //   openLabDialog() {
  //     var scope = this;
  //     this.diarylogopen = this.dialog.open(DiaryLogDialogComponent, {
  //         width: '700px',
  //         height: 'auto',
  //         data: {
  //             dataKey: scope.selectedUserID,
  //             hasnix : this.userHasNix,
  //             selectedOption : this.selectedActivity
  //         }
  //     });
  //     this.diarylogopen.afterClosed().subscribe(() => {
  //       this.initComponentDraw(this.appContext.selectedChatUser.getValue());
  //     });
  //  }

  convertIsoDate(d) {
    let date = new Date(d);
    let str;
    let options: any = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    str = (date).toString();
    if(str !== 'Invalid Date'){
      return date.toLocaleDateString("en-US", options);
    }else{
      return d;
    }
  }

  ConvertData(d) {
    return d.replace(/_/g, ' ');
  }

  changeRange(e) {
    this.selectedRange = e;
  }

  getTableData(UserID, selectedOption, startd, endd, page?,postdata?) {
    this.userDetailService.getPatientDiary(UserID, selectedOption, startd, endd, page).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false) })
    ).subscribe((data: any) => {
      data = (data as any).data;
      if (data.length > 0) {
        this.endoflist = false;
        this.showNodatafound = false;
        for (let i = 0; i < data.length; i++) {
          this.tabulardatasource.push(data[i]);
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].unit === 'kg') {
            this.latestWeight = Math.round(data[0].quantity) + " " + data[0].unit
          } else if (data[i].unit === 'lbs') {
            this.latestWeight = Math.round(data[0].quantity * 0.453592) + " " + 'kg'
          }
          if (data[i]?.meal_data) {
            for (let j = 0; j < data[i].meal_data.length; j++) {
              if (data[i].meal_data[j]?.is_deleted === false) {
                this.showNoGraphdatafound = false;
                break;
              }
            }
          } else {
            if (data[i]?.is_deleted === false) {
              this.showNoGraphdatafound = false;
            }
          }
        }
        this.dataSource = new MatTableDataSource<Element>(this.tabulardatasource);
        this.dataSource.paginator = this.paginator
        this.getGraphData(postdata,startd,endd)
        // setTimeout(() => this.dataSource.paginator = this.paginator);
      } else if (this.page === 1 || this.page === '1') {
        this.showNodatafound = true;
        this.showNoGraphdatafound = true;
        this.endoflist = true;
      }
    });
   
  }
getGraphData(postdata,startd,endd){

  if (this.mainoption == 'Peak Flow' && this.graph_data) {
    this.userDetailService.getPeakFlowGraphData(startd, endd, this.selectedUserID)
      .pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false) })
      ).subscribe((data:any)  => {
        data = (data as any).data;
       
        this.tabulardata = data;
        this.loadingSubject$.next(false);
        this.loadingError$.next(false);

        this.defaultleftoption = 'peak_flow';

        let dates = [];
        let values = [];
        let values1 = [];

        for (let p = 0; p < this.tabulardata.graph_data.length; p++) {
          if (this.tabulardata.graph_data[p].value != "0,0") {
            values.push(this.tabulardata.graph_data[p].value[0]);
            values1.push(this.tabulardata.graph_data[p].value[1]);
            
            let dateArray = this.tabulardata.graph_data[p].key.split("-");
            dates.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
          }
        }

        if ( values.length === 0){
          let dateArray = this.tabulardata.graph_data[0].key.split("-");
          dates.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));

          let maxGraphDataLength = this.tabulardata.graph_data.length - 1;
          let dateArr = this.tabulardata.graph_data[maxGraphDataLength].key.split("-");
          dates.push(new Date(dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0]));

          values.push(0);
          values.push(0);

          values1.push(0);
          values1.push(0);
        }
        this.showMultipleBarGraph(dates, values, values1);

        // setTimeout(() => {
        // }, 300);

      });

  }

  if (this.mainoption != 'Peak Flow' && this.graph_data) {

    this.userDetailService.getPatientFilteredDiaryData(this.graph_data, startd, endd, this.selectedRange, this.selectedUserID, this.subtype, this.bloodsugarlogtype, this.activityUnits)
      .pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          this.defaultleftoption = "";
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false) })
      ).subscribe((data:any) => {
        this.tabulardata = data;
        this.loadingSubject$.next(false);
        this.loadingError$.next(false);

        if (postdata.selectedOption == 'Activity') {
          this.defaultleftoption = 'activity';
          let bottomlabel = [];
          let serieslevel = [];

          for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {
              serieslevel.push(Number(this.tabulardata.data[this.selectedRange][p].value));

              let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");

              bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
          }
          this.showNewBarGraph(bottomlabel, serieslevel);
          // setTimeout(() => {
          //   this.showNewBarGraph(bottomlabel, serieslevel);
          // }, 300);

        }

        else if (postdata.selectedOption == 'Blood Sugar') {

          this.defaultleftoption = 'blood_sugar';

          let bottomlabel = [];
          let serieslevel = [];

          for (let r = 0; r < this.tabulardata.data[this.selectedRange].length; r++) {
            if (this.tabulardata.data[this.selectedRange][r].value != "0") {
              serieslevel.push(Number(this.tabulardata.data[this.selectedRange][r].value));

              let dateArray = this.tabulardata.data[this.selectedRange][r].key.split("-");
              bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
            }
          }

          if (serieslevel.length === 0){
            serieslevel.push(0);
            serieslevel.push(0);

            let lengthMax = this.tabulardata.data[this.selectedRange].length - 1;

            let dateArray = this.tabulardata.data[this.selectedRange][0].key.split("-");
            let dateArray1 = this.tabulardata.data[this.selectedRange][lengthMax].key.split("-");

            bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
            bottomlabel.push(new Date(dateArray1[2] + "-" + dateArray1[1] + "-" + dateArray1[0]));
          }
          this.showNewDottedGraph(bottomlabel, serieslevel);
          // setTimeout(() => {
           
          // }, 300);
        }

        else if (postdata.selectedOption == 'Blood Pressure') {
          this.defaultleftoption = 'blood_pressure'
          var pressureBottomLabel = [];
          var systolicValues = [];
          var diastolicValues = [];
          var heartRateValues = [];
          for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {

            if (this.tabulardata.data[this.selectedRange][p].value.blood_pressure != "0/0"){
              let values = this.tabulardata.data[this.selectedRange][p].value.blood_pressure.split("/");
              systolicValues.push(Number(values[0]));
              diastolicValues.push(Number(values[1]));

              let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");
              pressureBottomLabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
              if(this.tabulardata.data[this.selectedRange][p].value.heart_rate === null){
                heartRateValues.push(0);
              }else{
                heartRateValues.push(this.tabulardata.data[this.selectedRange][p].value.heart_rate);
              }
            }
          }

          if (systolicValues.length === 0){

            systolicValues.push(0);
            systolicValues.push(0);

            diastolicValues.push(0);
            diastolicValues.push(0);

            heartRateValues.push(0);
            heartRateValues.push(0);

            let lengthMax = this.tabulardata.data[this.selectedRange].length - 1;

            let dateArray = this.tabulardata.data[this.selectedRange][0].key.split("-");
            let dateArray1 = this.tabulardata.data[this.selectedRange][lengthMax].key.split("-");

            pressureBottomLabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
            pressureBottomLabel.push(new Date(dateArray1[2] + "-" + dateArray1[1] + "-" + dateArray1[0]));

          }
          this.showBloodPressureGraph(pressureBottomLabel, systolicValues, diastolicValues, heartRateValues);

          // setTimeout(() => {
          // }, 300);
        }

        else if (postdata.selectedOption == 'Water') {
          this.defaultleftoption = 'water';
          var bottomlabel = [];
          var serieslevel = [];

          for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {
            serieslevel.push(Number(this.tabulardata.data[this.selectedRange][p].value));

            let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");
            bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
          }

          this.showNewBarGraph(bottomlabel, serieslevel);
        }

        else if (postdata.selectedOption == 'Meal') {
          this.defaultleftoption = "meal";

          let mealBottomLabel = [];
          let mealSeriesValue = [];

          for (let t = 0; t < this.tabulardata.data[this.selectedRange].length; t++) {
            let dateArray = this.tabulardata.data[this.selectedRange][t].key.split("-");
            mealBottomLabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));

            mealSeriesValue.push(Number(this.tabulardata.data[this.selectedRange][t].value));
          }
          this.showNewBarGraph(mealBottomLabel, mealSeriesValue);

          // setTimeout(() => {
          // }, 300);
        }

        else if (postdata.selectedOption == 'Weight') {
          this.defaultleftoption = "weight";
          let bottomlabel = [];
          let serieslevel = [];
        
          for (let p = 0; p < this.tabulardata.data[this.selectedRange].length; p++) {
              
            if (Number(this.tabulardata.data[this.selectedRange][p].value) !== 0){
              serieslevel.push(Number(this.tabulardata.data[this.selectedRange][p].value));

              let dateArray = this.tabulardata.data[this.selectedRange][p].key.split("-");

              bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));
            }  

          }

          if (bottomlabel.length === 0){

            serieslevel.push(Number(this.tabulardata.data[this.selectedRange][0].value));

            let dateArray = this.tabulardata.data[this.selectedRange][0].key.split("-");

            bottomlabel.push(new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]));

            let lengthMax = this.tabulardata.data[this.selectedRange].length - 1;

            serieslevel.push(Number(this.tabulardata.data[this.selectedRange][lengthMax].value));

            let dateArray1 = this.tabulardata.data[this.selectedRange][lengthMax].key.split("-");

            bottomlabel.push(new Date(dateArray1[2] + "-" + dateArray1[1] + "-" + dateArray1[0]));

          }
          this.showNewDottedGraph(bottomlabel, serieslevel);

          // setTimeout(() => {
          // }, 300);
        }

      });
  }
}
  

  selectVal(e) {
    this.enableGraph = false;	
    this.mainoption = e;
    this.subtype = null;	
    this.mealsubmenuclicked = false;	
    this.latestWeight = '';
    
    // this.mealsubmenuclicked = false;
  }

  mealSubMenus(e) {
    this.mealsubmenuclicked = true;
    if (e == 'breakfast') {
      this.bloodsugarlogtype = '';
      this.subtype = 'breakfast';
    }
    else if (e == 'lunch') {
      this.bloodsugarlogtype = '';
      this.subtype = 'lunch';
    }
    else if (e == 'snacks') {
      this.bloodsugarlogtype = '';
      this.subtype = 'snacks';
    }
    else if (e == 'dinner') {
      this.bloodsugarlogtype = '';
      this.subtype = 'dinner';
    }
    // this.applymainfilter();
  }

  bloodSugarSubMenu(e) {
    if (e == 'fasting blood sugar') {
      this.bloodsugarlogtype = '';
      this.subtype = 'fasting';
    }
    else if (e == 'postmeal breakfast') {
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'breakfast';
    }
    else if (e == 'postmeal lunch') {
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'lunch';
    }
    else if (e == 'postmeal dinner') {
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'dinner';
    }
    else if(e == 'post snacks'){
      this.bloodsugarlogtype = 'post meal';
      this.subtype = 'snacks';
    }
    else if (e == 'premeal breakfast') {
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'breakfast';
    }
    else if (e == 'premeal lunch') {
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'lunch';
    }
    else if (e == 'premeal dinner') {
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'dinner';
    }
    else if (e == 'pre snacks'){
      this.bloodsugarlogtype = 'pre meal';
      this.subtype = 'snacks';
    }
    else if (e == 'random') {
      this.bloodsugarlogtype = '';
      this.subtype = 'random';
    }
  }

  showPeakFlowGraph(BottomLabel, firstValues, secondValues) {

    var LabelData = BottomLabel;
    var firstSeriesData = firstValues;
    var secondValue = secondValues;
    var largest = firstSeriesData[0];
    var average = 0;
    for (var i = 0; i < secondValue.length; i++) {
      if (largest < secondValue[i]) {
        largest = secondValue[i];
      }
      average = average + parseInt(secondValue[i]);
    }
    largest = parseInt(largest);
    const dataPressureChart: any = {
      labels: LabelData,
      series: [
        { value: firstSeriesData },
        { value: secondValue }
      ]
    };

    const optionsBloodSugarChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      axisX: {
        showGrid: false
      },
      low: 0,
      high: largest + 100,
      chartPadding: { top: 0, right: 0, bottom: 0, left: 20 },
      seriesBarDistance: 5,

      plugins: [
        Chartist.plugins.tooltip({
          transformTooltipTextFnc: function (tooltip) {
            return tooltip + ' l/min';
          }
        })
      ]
    }

    let bloodPressureChart = new Chartist.Bar('#PeakFlowChart', dataPressureChart, optionsBloodSugarChart);
    this.startAnimationForBarChart(bloodPressureChart);
  }

  selectMeasurementVal(event) {
    this.MeasurementType = false;
    this.MeasurementType = event;
  }

  applymainfilter() {
    // let infuusionData = localStorage.getItem("log_data");   
    var postdata = this.dataForm.value;
    var startd = null;
    var endd = null;
    this.page = "1";
    this.tabulardatasource = [];
    this.enableGraph = false;	
    if(postdata.selectedOption !== null){	
      const str2 = postdata.selectedOption.charAt(0).toUpperCase() + postdata.selectedOption.slice(1);	
      postdata.selectedOption = str2;	
    }
    if (postdata.startdate && postdata.enddate) {
      startd = postdata.startdate;
      endd = postdata.enddate;
      startd.setHours(0, 0, 0, 0);
      endd.setHours(23, 59, 59, 99);
    } else {
      startd = ""
      endd = ""
    }

    if(this.mainoption === "Activity" || this.mainoption === "Weight" || this.mainoption === "Water" || this.mainoption === "Peak Flow" || this.mainoption === "Meal" || this.mainoption === "Blood Pressure" || this.mainoption === "Blood Sugar"){
      startd = new Date(this.userData.therapy_start_date);
      startd.setHours(0, 0, 0, 0);
      endd = new Date();
      endd.setHours(23, 59, 59, 99);
    }

    if (endd >= startd) {
      
      startd = moment(startd).add(30, 'minutes').add(5, 'hours').toISOString();
      endd = moment(endd).add(30, 'minutes').add(5, 'hours').toISOString();

      this.loadingSubject$.next(true);

      if (postdata.selectedOption == 'Activity' || postdata.selectedOption == 'activity') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'activity_type', 'activity_value'];
      }
      else if (postdata.selectedOption == 'Blood Sugar') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'blood_sugar_log_type', 'blood_sugar_val', 'unit'];
      }
      else if (postdata.selectedOption == 'Blood Pressure') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'diastolic', 'heart_rate', 'tag'];
      }
      else if (postdata.selectedOption == 'Water') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'quantity', 'unit'];
      }
      else if (postdata.selectedOption == 'Meal') {
        this.displayedColumns = ['log_date', 'meal_type', 'food_item', 'calories_value'];
      }
      else if (postdata.selectedOption == 'Weight') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'weight', 'tag'];
      }
      else if (postdata.selectedOption == 'Peak Flow') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'measure', 'log_type'];
      }
      else if (postdata.selectedOption == 'Infusion Site') {
        this.enableGraph = true;
        this.displayedColumns = ['log_date', 'infusion_site', 'site_change_reason', 'showEdit'];
      }
      else if (postdata.selectedOption == 'Body Parameters: Temperature') {
        this.displayedColumns = ['log_date', 'unit', 'Quantity'];
      }
      else if (postdata.selectedOption == 'Body Parameters: Hip waist ratio') {
        this.displayedColumns = ['log_date', 'hip', 'hip_unit', 'waist', 'waist_unit', 'ratio'];
      }
      /**Reinitilise user detail component */
      this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
      if (postdata.selectedOption == 'Blood Sugar') {
        this.selectedOptionData = 'bloodSugar';
        this.graph_data = 'blood_sugar';
      }
      else if (postdata.selectedOption == 'Activity') {
        this.subtype = null;
        this.selectedOptionData = 'activity';
        this.graph_data = 'activity';
        this.selectedRange = "monthly"
      }
      else if (postdata.selectedOption == 'Weight') {
        this.subtype = null;
        this.selectedOptionData = 'weight';
        this.graph_data = 'weight';
      }
      else if (postdata.selectedOption == 'Meal') {
        this.subtype = null;
        this.selectedOptionData = 'meal';
        this.graph_data = 'meal';
      }
      else if (postdata.selectedOption == 'Water') {
        this.subtype = null;
        this.selectedOptionData = 'water';
        this.graph_data = 'water';
      }
      else if (postdata.selectedOption == 'Blood Pressure') {
        this.subtype = null;
        this.selectedOptionData = 'bloodPressure';
        this.graph_data = 'heart_rate';
      }
      else if (postdata.selectedOption == 'Peak Flow') {
        this.selectedOptionData = 'peakFlow'
        this.graph_data = 'peak_flow';
      }
      else if (postdata.selectedOption == 'Infusion Site') {
        this.selectedOptionData = 'infusionsite'
        this.graph_data = 'peak_flow';
      }
      else if (postdata.selectedOption == 'Body Parameters: Temperature') {
        this.subtype = null;
        this.selectedOptionData = 'bodytemperature';
        // this.graph_data = 'bodytemperature';
      }
      else if (postdata.selectedOption == 'Body Parameters: Hip waist ratio') {
        this.subtype = null;
        this.selectedOptionData = 'hipwaist';
        // this.graph_data = 'hipwaist';
      }
      this.getTableData(this.selectedUserID, this.selectedOptionData, startd, endd,this.page,postdata);


      //ss localStorage.removeItem("selectedNotification");
    }
    else {
      this.dataSource = '';
      this.systemService.showSnackBar("End date should be Greater than Start Date", "OK");
    }
  }

  convertDate(key) {
    if (this.selectedRange == 'daily') {
      var newdate = key.split("-").reverse().join("-");
      var d = new Date(newdate);
      var month = SHORT_MONTHS[d.getMonth()];
      var currentdate = d.getDate()
      var year = d.getFullYear()
      return currentdate + ' ' + month;
    }
    else if (this.selectedRange == 'weekly') {
      var weekno = parseInt(key.split("-"));
      var test = moment().day("Sunday").week(weekno).format('DD-MM');
      test = test.split("-")[0] + " " + SHORT_MONTHS[parseInt(test.split("-")[1]) - 1];
      var testp = moment().day("Saturday").week(weekno).format('DD-MM');
      testp = testp.split("-")[0] + " " + SHORT_MONTHS[parseInt(testp.split("-")[1]) - 1];
      return test + " - " + testp;
    }
    else if (this.selectedRange == 'monthly') {
      var newdate = key.split("-").reverse().join("-");
      var d = new Date(newdate);
      var month = SHORT_MONTHS[d.getMonth()];
      var currentdate = d.getDate()
      var year = d.getFullYear()

      return month;
    }
    if (this.mainoption == 'Peak Flow') {
      var newdate = key.split("-").reverse().join("-");
      var d = new Date(newdate);
      var month = SHORT_MONTHS[d.getMonth()];
      var currentdate = d.getDate()
      var year = d.getFullYear()

      return currentdate + ' ' + month;
    }

  }

  initComponentDraw(selectedUserID: string) {
    //this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    // this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    let datap;
    datap.push('id', selectedUserID);
    // this.userDetailsSubject$.next(datap);
    this.user = datap;
  }

  getUserEligibleLogs(UserID: string) {
    this.doctorService.getEligibleLogs(UserID).pipe(
      delay(100),
      catchError((error: any) => {
        return throwError(error);
      })
    ).subscribe(data => {
      this.eligibleLogData = data.data;
      for (var j = 0; j < this.eligibleLogData.length; j++) {
        if(this.eligibleLogData[j].title == 'Activity'){
          let index = j;
          if (j > -1) {
            this.eligibleLogData.splice(index, 1); // Remove array element
         }
        }
      }
      this.filteradmincontent(this.eligibleLogData);
    });
  }

  filteradmincontent(a) {
    this.eligibleLogData = [];
    for (var j = 0; j < a.length; j++) {
      if (a[j].type == 'diary' || a[j].type == 'body_parameters') {
        this.eligibleLogData.push(a[j]);
      }
    }
    
  }

  showMealGraph(mealbottomlabel, mealseriesvalue) {
    var meallabelData = mealbottomlabel;
    var mealseriesData = mealseriesvalue;
    var largest = 0;
    largest = mealseriesData[0];
    var average = 0;
    for (var i = 0; i < mealseriesData.length; i++) {
      if (largest < mealseriesData[i]) {
        largest = mealseriesData[i];
      }
      average = average + parseInt(mealseriesData[i]);
    }
    largest = largest;
    var mealIntakeGraph = {
      labels: meallabelData,
      series: [
        mealseriesData
      ]
    };
    var mealOptions = {
      axisX: {
        showGrid: false
      },
      low: 0,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 20
      },
      plugins: [
        Chartist.plugins.tooltip({
          transformTooltipTextFnc: function (tooltip) {
            return tooltip + ' Cal';
          }
        })
      ]
    };
    var responsiveOptions: any[] = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
    var mealChartCreation = new Chartist.Bar('#mealChart', mealIntakeGraph, mealOptions, responsiveOptions);

    this.startAnimationForBarChart(mealChartCreation);
  }

  getMonthString(num){
    return SHORT_MONTHS[num];
  }

  showNewBarGraph(dates, values){

    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;

    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));

      let combined = new Array();
      let maxValue = values[0];
      let sum = 0;
      let count = 0;

      combined[0] = ['range', 'value'];

      for(let i = 0; i<values.length;i++){
        combined[i+1] = [dates[i], values[i]];

        if (values[i] > maxValue){
          maxValue = values[i];
        }

        if (scope.mainoption === 'Activity' || scope.mainoption === 'Water' || scope.mainoption === 'Meal'){
          if (values[i] != 0){
            sum += values[i];
            ++count;
          }
        }
       
      }

      if (sum > 0){
        if (scope.mainoption === 'Activity'){
          scope.activityAverage = ` ${Math.round(sum / count)} ${scope.activityUnits}`;
        }

        if (scope.mainoption === 'Water'){
          scope.waterAverage = ` ${Math.round(sum / count)} ml`;
        }

        if (scope.mainoption === 'Meal'){
          scope.mealAverage = ` ${Math.round(sum / count)} kcal`;
        }
      } else {
        scope.activityAverage = "NA";
        scope.waterAverage = "NA";
        scope.mealAverage = "NA";
      }

      let ticks = [0];
      let tickDifference = 0;
      let vAxisTitle = "";

      if (scope.mainoption === "Activity"){
        if (scope.activityUnits === "mins"){
          tickDifference = 30;
          maxValue = maxValue + 50;
          vAxisTitle = "mins";
        } else if (scope.activityUnits === "steps"){
          tickDifference = 1500;
          maxValue = maxValue + 300;
          vAxisTitle = "steps";
        }
      } else if (scope.mainoption === 'Water'){
        tickDifference = 500;
        maxValue = maxValue + 500;
        vAxisTitle = "ml";
      } else if (scope.mainoption === 'Peak Flow'){
        tickDifference = 100;
        maxValue = maxValue + 100;
        vAxisTitle = "l/min";
      } else if (scope.mainoption === 'Meal'){
        tickDifference = 300;
        maxValue = maxValue + 300;
        vAxisTitle = "kcal";
      }

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(2, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> Value:${data.getValue(i, 1)}${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 2, tooltip);
      }

      // Create a Column chart passing some options
      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'ColumnChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": vAxisTitle,
            "format": "decimal",
            "minorGridlines": {
              "count": 0
            },
            "gridlines": {
              "count": 0
            },
            "ticks": ticks,
            "maxValue": maxValue
          }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });

      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
    }
  }
  showWeightGraph(weightbottomlabel, weightserieslevel) {
    var weightlabelData = weightbottomlabel;
    var weightseriesData = weightserieslevel;
    var largest = weightseriesData[0];
    var average = 0;
    for (var i = 0; i < weightseriesData.length; i++) {
      if (largest < weightseriesData[i]) {
        largest = weightseriesData[i];
      }
      average = average + parseInt(weightseriesData[i]);
    }
    this.weightpercentage = (parseInt(largest) - (average / parseInt(weightseriesData.length))) / 100;
    largest = parseInt(largest);

    const weightchartdata: any = {
      labels: weightlabelData,
      series: [
        weightseriesData
      ]
    };

    const weightchartconfig: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: 0,
      fullWidth: true,
      high: largest + 50, // we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 20
      },
      plugins: [
        Chartist.plugins.tooltip({
          transformTooltipTextFnc: function (tooltip) {
            return tooltip + ' Kg';
          }
        })
      ]
    }

    var weightchartval = new Chartist.Bar('#weightchartval', weightchartdata, weightchartconfig);

    this.startAnimationForLineChart(weightchartval);
  }

  showWaterGraph(waterBottomLabel, waterSeriesLabel) {
    var waterLabelData = waterBottomLabel;
    var waterSeriesData = waterSeriesLabel;

    var largest = waterSeriesData[0];
    var average = 0;
    for (var i = 0; i < waterSeriesData.length; i++) {
      if (largest < waterSeriesData[i]) {
        largest = waterSeriesData[i];
      }
      average = average + parseInt(waterSeriesData[i]);
    }
    const dataPressureChart: any = {
      labels: waterLabelData,
      series: [
        waterSeriesData
      ]
    };
    const optionsBloodSugarChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: 0,
      chartPadding: { top: 0, right: 0, bottom: 0, left: 20 },
      plugins: [
        Chartist.plugins.tooltip({
          transformTooltipTextFnc: function (tooltip) {
            return tooltip + ' ml';
          }
        })
      ]
    }
    var waterDataChart = new Chartist.Bar('#waterDataChart', dataPressureChart, optionsBloodSugarChart);
    this.startAnimationForLineChart(waterDataChart);
  }

  showBloodPressureGraph(pressureBottomLabel, systolicValues, diastolicValues, heartRateValues) {

    google.charts.load('current', { 'packages': ['corechart', 'controls'] });
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;
    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));
      let maxValue = 0;
      let pointSize = 3;

      let combined = new Array();
      
      if ( systolicValues[0] > diastolicValues[0]){
        maxValue = systolicValues[0];
      } else {
        maxValue = diastolicValues[0];
      }

      for(let i = 0;i<systolicValues.length;i++){

        if (systolicValues[i] > maxValue){
          maxValue = systolicValues[i];
        }

        if(diastolicValues[i] > maxValue){
          maxValue = diastolicValues[i];
        }
      }

      if (maxValue === 0){
        pointSize = 0;
      }

      maxValue += 50;

      let tickDifference = 50;
      let ticks = [0];

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      combined[0] = ['range', 'systolic', 'diastolic', 'systolic', 'diastolic', 'pulserate'];
      for (let i = 0; i < systolicValues.length; i++) {
        combined[i + 1] = [pressureBottomLabel[i], systolicValues[i], systolicValues[i], diastolicValues[i], diastolicValues[i], heartRateValues[i]];
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(4, { type: 'string', role: 'tooltip', p: { html: true } });

      for (let i = 0; i < data.getNumberOfRows(); i++) {
        let tooltip = `<div class="ggl-tooltip">
        <span style="font-size: 10px;">${data.getValue(i, 1)}-${data.getValue(i, 3)}mmHg</span>
        <br />
        <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
        </div>`;
        data.setValue(i, 4, tooltip);
      }

      data.insertColumn(7, { type: 'string', role: 'tooltip', p: { html: true } });
      for (let i = 0; i < data.getNumberOfRows(); i++) {
        if(data.getValue(i, 6) !== 0){	
          let tooltip = `<div class="ggl-tooltip">	
          <span style="font-size: 10px;"> Pulse:${data.getValue(i, 6)}bpm</span>	
          <br />	
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>	
          </div>`;	
        data.setValue(i, 7, tooltip);	
        }else{	
          let tooltip = `<div class="ggl-tooltip">	
          <span style="font-size: 10px;"> Pulse:NA</span>	
          <br />	
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>	
          </div>`;	
        data.setValue(i, 7, tooltip);	
        }
      }

      // Create a Column chart passing some options
      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'CandlestickChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": "mmHg",
            "format": "decimal",
            "gridlines": { "color": '#dfdfdf' },
            "minorGridlines": { "color": '#dfdfdf' },
            "ticks": ticks,
            "maxValue": maxValue
          },
          "bar": { "groupWidth": '4' },
          "candlestick": {
            "fallingColor": { "strokeWidth": 0, "fill": '#CB0083' }, // red
            "risingColor": { "strokeWidth": 0, "fill": '#CB0083' }   // green
          },
          "seriesType": 'candlesticks',
          "series": { 1: { "type": 'scatter', "color": '#691DD6', "pointSize": pointSize } }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });
      
      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
    }
  }

  showBloodGraph(bloodserieslevel, bloodbottomlabel) {
    var bloodlabelData = bloodbottomlabel;
    var bloodseriesData = bloodserieslevel;
    var largest = parseInt(bloodseriesData[0]);
    var average = 0;
    for (var i = 0; i < bloodseriesData.length; i++) {
      if (largest < parseInt(bloodseriesData[i])) {
        largest = parseInt(bloodseriesData[i]);
      }
      average = average + parseInt(bloodseriesData[i]);
    }

    this.bloodpercentage = ((largest) - (average / parseInt(bloodseriesData.length))) / 100;
    if (this.bloodpercentage < 0) {
      this.bloodflow = 'lesser';
    } else if (this.bloodpercentage > 0) {
      this.bloodflow = 'larger';
    } else {
      this.bloodflow = 'zero';
    }
    largest = (largest);

    var bloodsugarshowseries = [];
    var dataDailySalesChart;


    dataDailySalesChart = {
      labels: bloodlabelData,
      series: [
        bloodseriesData
      ]
    };

    if (bloodsugarshowseries.length == 0) {
      bloodlabelData = null;
    }

    const optionsDailySalesChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: 0,
      fullWidth: true,
      high: largest + 130, // we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 20
      },
      plugins: [
        Chartist.plugins.tooltip({
          transformTooltipTextFnc: function (tooltip) {
            return tooltip + ' l/min';
          }
        })
      ]
    }

    var dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

    this.startAnimationForLineChart(dailySalesChart);

  }

  showActivityGraph(bottomlabel, serieslevel) {
    var labelData = bottomlabel;
    var seriesData = serieslevel;
    var largest = 0;
    largest = seriesData[0];
    var average = 0;
    for (var i = 0; i < seriesData.length; i++) {
      if (largest < seriesData[i]) {
        largest = seriesData[i];
      }
      average = average + parseInt(seriesData[i]);
    }
    this.AverageActivity = average / parseInt(seriesData.length);
    this.AverageActivity = Math.round(this.AverageActivity * 100) / 100;
    largest = largest;
    this.HighestActivity = largest + " Min";
    var dataEmailsSubscriptionChart = {
      labels: labelData,
      series: [
        seriesData
      ]
    };
    var optionsEmailsSubscriptionChart = {
      axisX: {
        showGrid: false
      },
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 20
      },
      // fullWidth: true,
      plugins: [
        Chartist.plugins.tooltip({
          transformTooltipTextFnc: function (tooltip) {
            return tooltip + ' Kcal';
          }
        })
      ]
    };
    var responsiveOptions: any[] = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
    var emailsSubscriptionChart = new Chartist.Bar('#emailsSubscriptionChart', dataEmailsSubscriptionChart, optionsEmailsSubscriptionChart, responsiveOptions);
    this.startAnimationForBarChart(emailsSubscriptionChart);
  }

  startAnimationForBarChart(chart) {
    let seq2: any, delays2: any, durations2: any;

    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on('draw', function (data) {
      if (data.type === 'bar') {
        seq2++;
        data.element.animate({
          opacity: {
            begin: seq2 * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq2 = 0;
  };
  startAnimationForPieChart(chart) {
    chart.on('draw', function (data) {
      if (data.type === 'slice') {
        var pathLength = data.element._node.getTotalLength();
        data.element.attr({
          'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
        });
        var animationDefinition = {
          'stroke-dashoffset': {
            id: 'anim' + data.index,
            dur: 800,
            from: -pathLength + 'px',
            to: '0px',
            easing: Chartist.Svg.Easing.easeOutQuint,
            fill: 'freeze'
          }
        };

        data.element.attr({
          'stroke-dashoffset': -pathLength + 'px'
        });

        data.element.animate(animationDefinition, false);
      }
    });
  };
  startAnimationForLineChart(chart) {
    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on('draw', function (data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === 'point') {
        seq++;
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq = 0;
  };

  sortByKey(arrp, keyp) {
    return arrp.sort(function (a, b) {
      var x = a[keyp];
      var y = b[keyp];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }
  removeDuplicates(array, key) {
    var k;
    var newp;
    var oldarray;
    var newdate;
    var arraydate;
    this.newar = [];
    for (var i = 0; i < array.length; i++) {
      k = 0;
      for (var j = 0; j < this.newar.length; j++) {
        newdate = new Date(this.newar[j]['on_date'] * 1000);
        arraydate = new Date(array[i]['on_date'] * 1000);
        newdate = newdate.getDay() + '/' + newdate.getMonth() + 1 + '/' + newdate.getFullYear();
        arraydate = arraydate.getDay() + '/' + arraydate.getMonth() + 1 + '/' + arraydate.getFullYear();
        if (newdate == arraydate) {
          k = 1;
          if (key == 'duration') {
            oldarray = array[i][key];
            newp = this.newar[j][key] + oldarray;
            this.newar[j][key] = newp;
          }
          if (key == 'quantity') {
            oldarray = array[i][key];
            newp = this.newar[j][key] + oldarray;
            this.newar[j][key] = newp;
          }
        }
      }
      if (k == 0) {
        this.newar.push(array[i]);
      }
    }
    return this.newar;
  }

  /// All Graph Functions End //

  getTheTime(dateStamp) {
    if (dateStamp == undefined) {
      return "Not Available";
    } else {

      var datep = new Date(dateStamp * 1000);
      var minval = ('0' + datep.getMinutes()).slice(-2)
      return (datep.getHours() + ":" + minval + "  " + datep.getDate() + " " + SHORT_MONTHS[datep.getMonth()] + " " + datep.getFullYear());
    }
  }
  gettype(type) {

    type = this.symptomnames[type] ? this.symptomnames[type] : 'NF';
    return type
  }

  UpperCaseArray(input) {
    var result = input.replace(/([A-Z])/g, ' $1').trim();
    return result.split(",");
  }


  getUserDetails(selectedUserID: string) {

    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;

      data = (data == null) ? [] : data;
      this.userDataQ = data;
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
    });
  }
  highlight(event) {
    // localStorage.setItem('patientfollowup', row.doing_follow_up);
    let data = {
      "d": [
        {
          "identity": localStorage.getItem('scarletuserid'),
          "ts": (Math.floor(new Date().valueOf() / 1000)),
          "type": "event",
          "evtName": event,
          "evtData": {
            "Patient name": this.userDataQ.name,
            "Patient ID": this.userDataQ.id
          }
        }
      ]
    }
    this.authService
      .sendEventData(data)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {

      })
  }
  getPatientTargets(selectedPatientID) {

    this.userDetailService.getPatientTargetData(selectedPatientID)
      .subscribe((res: any) => {

        if (res.data[0]?.type == 'meal') {
          this.targetPatientData = res.data[0];
        } else if (res.data[1]?.type == 'meal') {
          this.targetPatientData = res.data[1];
        }

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  initComponentDraw1(selectedUserID: string,graphType?:any) {
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;

      this.userData = data;

      let profileFlags = this.userData.profile_flags.split(',');

      if (profileFlags.indexOf("SST") > -1){
        this.activityUnits = "steps";
      }else {
        this.activityUnits = "mins";
      }
      if(graphType){
       this.dataForm.get('selectedOption').setValue(graphType);
       this.mainoption = graphType;
      }
      this.applymainfilter();
    })
  }
  showNewDottedGraph(dates, values){

    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;
    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));

      let combined = new Array();
      let maxValue = values[0];

      combined[0] = ['range', 'value'];

      for(let i = 0; i<values.length;i++){
        combined[i+1] = [dates[i], values[i]];

        if (values[i] > maxValue){
          maxValue = values[i];
        }
       
      }

      let pointSize = 2;

      if (maxValue === 0){
        pointSize = 0;
      }

      let ticks = [0];
      let tickDifference;
      let vAxisTitle;

      if (scope.mainoption === "Weight"){
        tickDifference = 20;
        vAxisTitle = "Kg";
        maxValue = maxValue + 30;
      } else if (scope.mainoption === "Blood Sugar"){
        tickDifference = 50;
        vAxisTitle = "mg/dL"
        maxValue = maxValue + 50;
      }

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(2, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> Value:${data.getValue(i, 1)}${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 2, tooltip);
      }

      // Create a Column chart passing some options
      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'ScatterChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "pointSize": pointSize,
          "colors": ["#ff7131"],
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": vAxisTitle,
            "format": "decimal",
            "minorGridlines": {
              "count": 0
            },
            "gridlines": {
              "count": 0
            },
            "ticks": ticks,
            "maxValue": maxValue
          }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "height": 60,
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });

      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
    }

  }
  showMultipleBarGraph(dates, values, values1){

    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChart);

    let scope = this;
    function drawChart() {

      let dashboard = new google.visualization.Dashboard(document.getElementById('dashboard_div'));

      let combined = new Array();
      let maxValue = values[0];

      combined[0] = ['range', 'Before Medication', 'After Medication'];

      for(let i = 0; i< values.length;i++){
        combined[i+1] = [dates[i], values[i], values1[i]];

        if (values[i] > maxValue){
          maxValue = values[i];
        }

        if(values1[i] > maxValue){
          maxValue = values1[i];
        }
       
      }

      let ticks = [0];
      let tickDifference = 100;
      let vAxisTitle = "l/min";

      maxValue = maxValue + 100;

      for(let i = 0; i < maxValue;){
        i = i + tickDifference;
        ticks.push(i);
      }

      let data = new google.visualization.arrayToDataTable(combined, false);

      data.insertColumn(2, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> Before Medication: ${data.getValue(i, 1)} ${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 2, tooltip);
      }

      data.insertColumn(4, { type: 'string', role: 'tooltip', p: { html: true } });
      for (var i = 0; i < data.getNumberOfRows(); i++) {
        var tooltip = `<div class="ggl-tooltip">
          <span style="font-size: 10px;"> After Medication: ${data.getValue(i, 3)} ${vAxisTitle}</span> 
          <br />
          <span style="font-size: 10px;">${ scope.getMonthString(data.getValue(i, 0).getMonth()) }, ${data.getValue(i, 0).getDate()}, ${data.getValue(i, 0).getFullYear()}</span>
          </div>`;
        data.setValue(i, 4, tooltip);
      }

      let comboChart = new google.visualization.ChartWrapper({
        'chartType': 'ColumnChart',
        'containerId': 'chart_div',
        'options': {
          "legend": 'none',
          "fontName": 'AvenirNext-Regular',
          "tooltip": { isHtml: true },
          "hAxis": {
            "minorGridlines": {
              "count": 0
            }
          },
          "vAxis": {
            "title": vAxisTitle,
            "format": "decimal",
            "minorGridlines": {
              "count": 0
            },
            "gridlines": {
              "count": 0
            },
            "ticks": ticks,
            "maxValue": maxValue
          }
        }
      });

      // Create a range slider, passing some options
      let ChartRangeFilter = new google.visualization.ControlWrapper({
        'controlType': 'ChartRangeFilter',
        'containerId': 'slider_div',
        'options': {
          'filterColumnLabel': 'range',
          "fontName": 'AvenirNext-Regular',
          "legend": 'none',
        }
      });

      dashboard.bind(ChartRangeFilter, comboChart);
      dashboard.draw(data);
    }

  }
  convertDecimal(num) {	
    return Math.floor(num).toFixed(2);	
  }
  convertToFirstletterCaps(value){
    const string = value.charAt(0).toUpperCase() + value.slice(1);
    return string;
  }
  getNextSet() {
    var startd = null;
    var endd = null;
    startd = new Date(this.userData.therapy_start_date);
      startd.setHours(0, 0, 0, 0);
      endd = new Date();
      endd.setHours(23, 59, 59, 99);
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.page.toString();
      this.getTableData(this.selectedUserID, this.selectedOptionData, startd, endd,this.page);
    }
  }
  showMacroData(data: any) {
    var scope = this;
    let carb = 0;
    let protein = 0;
    let fat = 0;
    let fiber = 0;
    let calories_kcal = 0;
    for (let i = 0; i < data.length; i++) {
      for(let j = 0; j < data[i].food_data.length; j++){
        let foodLength = data[i].food_data.length;
        if(foodLength > 1){
          if(data[i].food_data[j].is_deleted !== true){
            calories_kcal += (data[i].food_data[j].default_kcal  *  data[i].food_data[j].quantity);
            carb += data[i].food_data[j].nutrition_data.carbohydrates;
            protein +=  data[i].food_data[j].nutrition_data.proteins;
            fat +=  data[i].food_data[j].nutrition_data.fats;
            fiber +=  data[i].food_data[j].nutrition_data.fibers;
          }
        }else{
          if(data[i].is_deleted !== true){
            calories_kcal += (data[i].food_data[j].default_kcal  *  data[i].food_data[j].quantity);
            carb += data[i].food_data[j].nutrition_data.carbohydrates;
            protein +=  data[i].food_data[j].nutrition_data.proteins;
            fat +=  data[i].food_data[j].nutrition_data.fats;
            fiber +=  data[i].food_data[j].nutrition_data.fibers;
          }
        }
        
      }
      }
      this.diarylogopen = this.dialog.open(DiaryLogDialogComponent, {
        width: '500px',
        height: 'auto',
        data: {
          enable: true,
          meal_type: data[0].meal_type,
          totalcalories: calories_kcal,
          carb: carb,
          protein: protein,
          fat: fat,
          fiber: fiber,
        }
      });
      this.diarylogopen.afterClosed().subscribe(() => {
        this.initComponentDraw(this.selectedUserID);
      });
  }
  calculateKcal(kcal:any,quantity:any) {
    return (kcal * quantity);
  }

}
export interface Element {
  duration: string;
  date: string;
  showGenericData: string;
  value: string;
  track: string;
  items: string;
  status: string;
  breakup: string;
  diastolic: string;
  systolic: string;
  heartrate: string;
  totalglass: string;
}
