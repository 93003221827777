

<mat-drawer-container class="sidenav-container" autosize>
    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb *ngIf="newitem" route="content/questionnairhome/questionnaircreate"></app-breadcrumb>
        <app-breadcrumb *ngIf="!newitem" route="content/questionnairhome/questionnairedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Questionnaire
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <mat-horizontal-stepper [linear]="isLinear" #stepper>
                    <mat-step [stepControl]="dataForm">
                        <ng-template matStepLabel>Create Questions</ng-template>
                        <form [formGroup]="dataForm" validate>
                            <div class="row" style="height: 60vmin;display: block;overflow-y: auto;">
                                <div class="col-md-12 leftformsection">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Title</mat-label>
                                        <input matInput placeholder="Enter the Title" formControlName="title" required>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="full-width-formfield featureimage"
                                        (click)="openmediabox()">
                                        <mat-label>Select Image</mat-label>
                                        <input matInput formControlName="image_media" required readonly>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Repeat On Day</mat-label>
                                        <input matInput placeholder="Enter the Title" formControlName="repeat_on_day"
                                            required pattern="^\d+(?:\.\d{0,1})?$"
                                            oninput="validity.valid||(value='');">
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Skip Repeat On Day</mat-label>
                                        <input matInput placeholder="Enter the Repeat Days" formControlName="skip_repeat_on_day"
                                            required pattern="^\d+(?:\.\d{0,1})?$"
                                            oninput="validity.valid||(value='');">
                                    </mat-form-field>


                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Tags</mat-label>
                                        <mat-select formControlName="tags" required multiple #singleSelectTag>
                                            <mat-option>
                                                <ngx-mat-select-search class="tagmat search-field"
                                                    [clearSearchInput]="true" [placeholderLabel]="'Search Item'"
                                                    [noEntriesFoundLabel]="'No matching records found'"
                                                    [formControl]="searchmattag" ngModel
                                                    (ngModelChange)="modelChangedTags()" ngDefaultControl>
                                                    <div class="mat-select-search-custom-header-content">
                                                        <i class="material-icons">
                                                            search
                                                        </i></div>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option class="camelcased select-tags-active"
                                                *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                                {{tag.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-select placeholder="Select type*" class="select-role"
                                            formControlName="type" required>
                                            <mat-option value="Quality Of Life">Quality Of Life</mat-option>
                                            <mat-option value="Diet">Diet</mat-option>
                                            <mat-option value="Med Adherence">Med Adherence</mat-option>
                                            <mat-option value="Symptom">Symptom</mat-option>
                                            <mat-option value="Asthma">Asthma</mat-option>

                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Total Questions</mat-label>
                                        <mat-select placeholder="Total Questions" formControlName="question_count"
                                            (selectionChange)="questionCount($event.value)" required>
                                            <mat-option *ngFor="let num of number" [value]="num">
                                               {{num}}
                                            </mat-option>
                                            <!-- <mat-option [value]="2">
                                                2
                                            </mat-option>
                                            <mat-option [value]="3">
                                                3
                                            </mat-option>
                                            <mat-option [value]="4">
                                                4
                                            </mat-option>
                                            <mat-option [value]="5">
                                                5
                                            </mat-option>
                                            <mat-option [value]="6">
                                                6
                                            </mat-option>
                                            <mat-option [value]="7">
                                                7
                                            </mat-option>
                                            <mat-option [value]="8">
                                                8
                                            </mat-option>
                                            <mat-option [value]="9">
                                                9
                                            </mat-option>
                                            <mat-option [value]="10">
                                                10
                                            </mat-option> -->
                                        </mat-select>
                                    </mat-form-field>


                                    <div class="col-md-12">
                                        <div formArrayName="questions">
                                            <div
                                                *ngFor="let tabGroup of dataForm['controls'].questions['controls']; let i=index;">
                                                <div formGroupName="{{i}}" class="retrysection">
                                                    <p>Question {{i + 1}}</p>
                                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                                        <mat-label>Question {{i + 1}}</mat-label>
                                                        <input matInput placeholder="Enter the question"
                                                            formControlName="question" required>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                                        <mat-label>Multi select</mat-label>
                                                        <mat-select placeholder="Multi select"
                                                            formControlName="is_multi_select">
                                                            <mat-option [value]="true">Yes</mat-option>
                                                            <mat-option [value]="false">No</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                                        <mat-label>Option Count</mat-label>
                                                        <mat-select placeholder="Option count"
                                                            formControlName="option_count"
                                                            (selectionChange)="questionOptionCount($event.value,i)">
                                                            <mat-option [value]="2">2</mat-option>
                                                            <mat-option [value]="3">3</mat-option>
                                                            <mat-option [value]="4">4</mat-option>
                                                            <mat-option [value]="5">5</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <!-- tabGroup.controls.options.controls -->
                                                    <div formArrayName="options">
                                                        <div
                                                            *ngFor="let text of tabGroup['controls'].options['controls']; let j=index;">
                                                            <div formGroupName="{{j}}"
                                                                class="retrysection retrysection2">
                                                                <p> Options {{j + 1}}</p>
                                                                <mat-form-field appearance="outline"
                                                                    class="full-width-formfield">
                                                                    <mat-label>Option</mat-label>
                                                                    <input matInput placeholder="Enter the option text"
                                                                        formControlName="text" required>
                                                                </mat-form-field>
                                                                <mat-form-field appearance="outline"
                                                                    class="full-width-formfield">
                                                                    <mat-label>Score</mat-label>
                                                                    <mat-select placeholder="Score"
                                                                        formControlName="score">
                                                                        <mat-option *ngFor="let text of getScore(i)"
                                                                            [value]="text">{{text}}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button mat-flat-button color="primary" class="centerbtn" (click)="setlowhigh()"
                                        [disabled]="!dataForm.valid " style="margin-top:15px;">Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="rangeForm">
                        <form [formGroup]="rangeForm" (ngSubmit)="dataObject()">
                            <div class="row" style="height: 60vmin;display: block;overflow-y: auto;">
                                <ng-template matStepLabel>Create Ranges</ng-template>
                                <div class="col-md-12 pad-40">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Range</mat-label>
                                        <mat-select placeholder="Select Range" formControlName="select_range"
                                            (selectionChange)="rangeCount($event.value)">
                                            <mat-option [value]="2">2</mat-option>
                                            <mat-option [value]="3">3</mat-option>
                                            <mat-option [value]="4">4</mat-option>
                                            <mat-option [value]="5">5</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <div formArrayName="range">
                                        <div *ngFor="let data of rangeForm['controls'].range['controls']; let i=index;">
                                            <div formGroupName="{{i}}"
                                                class="retrysection retrysection2 retrysection-inner">
                                                <p>Question {{i + 1}}</p>
                                                <!-- <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Color Hex Code (Ex : #f1f1f1)</mat-label>
                                                    <input matInput placeholder="Enter the Color Code"
                                                        formControlName="category_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" required>
                                                </mat-form-field> -->

                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Upper limit</mat-label>
                                                    <input matInput placeholder="Enter the upper_limit"
                                                        formControlName="upper_limit" required>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Lower limit</mat-label>
                                                    <input matInput placeholder="Enter the lower_limit"
                                                        formControlName="lower_limit" required>
                                                </mat-form-field>

                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Select Category</mat-label>
                                                    <mat-select placeholder="Select Category"
                                                        formControlName="category">
                                                        <mat-option value="poor">Poor</mat-option>
                                                        <mat-option value="fair">Fair</mat-option>
                                                        <mat-option value="moderate">Moderate</mat-option>
                                                        <mat-option value="adherent">Adherent</mat-option>
                                                        <mat-option value="good">Good</mat-option>
                                                        <mat-option value="verygood">Very Good</mat-option>
                                                        <mat-option value="excellent">Excellent</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Title</mat-label>
                                                    <input matInput placeholder="Enter the title"
                                                        formControlName="title" required>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Select Feedback list number</mat-label>
                                                    <mat-select placeholder="Select Range"
                                                        (selectionChange)="feedbackCount($event.value,i)"
                                                        formControlName="feedback_count">
                                                        <mat-option [value]="1">1</mat-option>
                                                        <mat-option [value]="2">2</mat-option>
                                                        <mat-option [value]="3">3</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <div formArrayName="feedback">
                                                    <div
                                                        *ngFor="let text of data['controls'].feedback['controls']; let j=index;">
                                                        <div formGroupName="{{j}}">
                                                            <mat-form-field appearance="outline"
                                                                class="full-width-formfield">
                                                                <mat-label>Feedback List</mat-label>
                                                                <input matInput placeholder="Enter the Feedback text"
                                                                    formControlName="list" required>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div formArrayName="feedback">
                                                    <div *ngFor="let feed of data.range['controls']; let p=index;">
                                                        <div formGroupName="{{p}}">
                                                            <input matInput placeholder="Enter the List item"
                                                            formControlName="list" required>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="clearfix"><br /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 pad-40">
                                    <button mat-flat-button matStepperPrevious>Back</button>
                                    <button mat-flat-button color="primary" class="centerbtn"
                                        [disabled]="!rangeForm.valid " style="margin-top:15px;"
                                        matStepperNext>Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside content-inside2">
                            <mat-horizontal-stepper [linear]="isLinear">
                                <ng-template matStepperIcon="edit">
                                    <mat-icon class="done">done</mat-icon>
                                </ng-template>
                                <div *ngFor="let option of dataForm?.value?.questions; let i=index; let last = last;">
                                    <mat-step [stepControl]="i" [editable]="true">
                                        <div class="question">{{option?.question}}</div>
                                        <div *ngIf="option.is_multi_select == true" class="">
                                            <div class="example-radio-button custom-radio"
                                                *ngFor="let op of dataForm.value.questions[i].options">
                                                {{op.text}}
                                            </div>
                                        </div>
                                        <div *ngIf="option.is_multi_select == false" class="">
                                            <mat-checkbox *ngFor="let op of  dataForm.value.questions[i].options"
                                                class="amateur"> {{op.text}}</mat-checkbox>
                                        </div>
                                    </mat-step>
                                </div>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>
