<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <app-adminsectionleftnav></app-adminsectionleftnav>
        <div class="mainrightcontainersecondary">
        <div class="col-md-12">
            <div class="breadcrumbsholder">
                <app-breadcrumb route="admin/hardsetotphome/hardsetotpedit"></app-breadcrumb>
            </div>
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Hardset OTP
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-12">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Country Code</mat-label>
                                    <input matInput placeholder="Enter the country code" formControlName="country_code"
                                        minlength="1" maxlength="3" pattern="^\d+(?:\.\d{0,1})?$"
                                        oninput="validity.valid||(value='');" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Phone</mat-label>
                                    <input *ngIf="newitem" matInput placeholder="Enter the phone number" formControlName="phone"
                                        maxlength="10" pattern="^\d+(?:\.\d{0,1})?$"
                                        oninput="validity.valid||(value='');" required>
                                        <input *ngIf="!newitem" matInput placeholder="Enter the phone number" formControlName="phone"
                                        maxlength="10" pattern="^\d+(?:\.\d{0,1})?$"
                                        oninput="validity.valid||(value='');" readonly required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Hard-set OTP</mat-label>
                                    <input matInput placeholder="Enter the otp" formControlName="otp" maxlength="6"
                                        pattern="^\d+(?:\.\d{0,1})?$" oninput="validity.valid||(value='');" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Requester info</mat-label>
                                    <input matInput placeholder="Enter the Requester info"
                                        formControlName="requester_info" minlength="1" 
                                        oninput="validity.valid||(value='');" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Beneficiary Name</mat-label>
                                    <input matInput placeholder="Enter the Beneficiary Name"
                                        formControlName="beneficiary_name" minlength="1" 
                                        oninput="validity.valid||(value='');" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Reason for Hard-Set</mat-label>
                                    <textarea matInput rows="4" placeholder="Enter the Reason for hardset OTP info"
                                        formControlName="reason_for_hardset_otp" minlength="1" 
                                        oninput="validity.valid||(value='');" required>
                                    </textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 zeropadleft">
                                <mat-form-field *ngIf="userData" appearance="outline" class="full-width-formfield">
                                    <mat-label>Expiry Date</mat-label>
                                    <input matInput [matDatepicker]="datepicker" datePickerFormat="YYYY-MM-DD"
                                        formControlName="expiry_date" [min]="morrow"  required>
                                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
					<mat-datepicker #datepicker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field *ngIf="!userData" appearance="outline" class="full-width-formfield">
                                    <mat-label>Expiry Date</mat-label>
                                    <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="datepicker" datePickerFormat="YYYY-MM-DD"
                                        formControlName="expiry_date" [min]="morrow"  required>
                                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
					<mat-datepicker #datepicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                           
                            
                            
                            <!-- <div class="col-md-12 zeropadleft">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Created By</mat-label>
                                    <input matInput placeholder="Enter the Created By" formControlName="created_by"
                                        minlength="1"  oninput="validity.valid||(value='');" required>
                                </mat-form-field>
                            </div> -->
                            
                            <section class="example-section">
                                <mat-checkbox

                                    class="example-margin" formControlName="is_dummy"

                                    >
                                    Deactivate account on Expiry
                                </mat-checkbox>
                              </section>
                        </div>
                        <div class="row">
                            <div class="col-md-12 zeropadleft zeromarginleft">
                                <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;" [disabled]="!dataForm.valid"
                                    >{{newitem ? 'New' : 'Update'}} Hardset otp</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
    </div>

</mat-drawer-container>
