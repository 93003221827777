<div class="container">
    <div class="row">
        <div class="col-md-12" id="mainCol">
            <div class="wrapper">
                <section id="section-a">
                    <div>
                        <br />
                        <h1 class="text">Version 21.14.1.1 Release Notes</h1>
                        <p class="created"> <span class="wrapper-list">Created:</span> <span
                            class="wrapper-content">21 January 2021</span> </p>
                    </div>

                </section>

                <section id="section-a">
                    <div>
                        <br />
                        <h1 class="text">Version 20.27.8.1 Release Notes</h1>
                        <p class="created"> <span class="wrapper-list">Created:</span> <span
                                class="wrapper-content">27-08-2020, 13:30</span> </p>
                        <p class="updated"> <span class="wrapper-list">Last updated:</span><span
                                class="wrapper-content"> 27-08-2020, 14:30</span></p>
                    </div>
                    <br />
                    <h2>Admin Dashboard</h2>
                    <br />
                    <h4>
                        What's New
                    </h4>
                    <ul>
                        <li>Admin Access HC Dashboard Session</li>
                        <li>Tracking Created Session and background logs</li>
                        <li>Access HC Dashboard with disable feature to send chat messages to patient</li>
                        <li>Admin Access HC Dashboard Session</li>
                        <li>API changes and performance improvement in Activation code Generation</li>
                        <li>Activation Code search with more faster information and error handling</li>
                        <li>View Time Log in HC Section updated with new APIs</li>

                    </ul>

                    <h4>
                        Bug Fixes
                    </h4>
                    <ul>
                        <li>User creation upload photo placeholder icon changes</li>
                        <li>Phone number Validation added inside doctor pre activation section inside Admin dashboard</li>
                        <li>Scarlet admin and doctor duplicate search issues</li>
                    </ul>

                </section>

                <section id="section-b">
                    <br /><br />
                    <h2>Healthcoach Dashboard</h2>
                    <br />
                    <h4>
                        What's New
                    </h4>
                    <ul>
                        <li>Time Log New API Integration</li>
                        <li>Paginated time log past data in profile section</li>
                        <li>Prescription pdf and image upload Changes and handled all error scenarios if upload is failed</li>
                        <li>Custom Deeplinks has been added</li>
                    </ul>
                    <h4>
                        Bug Fixes
                    </h4>
                    <ul>
                        <li>Blank user Coming while system going in sleep</li>
                        <li>Date validation in Diary and Lab Report</li>
                    </ul>
                </section>

                <section id="section-b">
                    <br /><br />
                    <h2>Therapy Dashboard</h2>
                    <br />
                    <h4>
                        What's New
                    </h4>
                    <ul>
                        <li>Custom Deeplinks has been added in UUXID section</li>
                    </ul>
                </section>

                <section id="section-b">
                    <br /><br />
                    <h2>FAQ App</h2>
                    <br />
                    <h4>
                        What's New
                    </h4>
                    <ul>
                        <li>Filters changes in FAQ QA to allow multi client and therapy questions in same category</li>
                    </ul>
                </section>
            </div>
        </div>
    </div>
</div>