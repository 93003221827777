import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticateService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class DoctorGuardGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticateService) {}
  
  canLoad(route: Route): boolean {
    const isDoc = this.authService.app === 'doctor';
    if (isDoc) {
      return true;
    }
    this.router.navigateByUrl(`/application`);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAdmin = this.authService.app === 'doctor';
    if (isAdmin) {
      return true;
    }
    this.router.navigateByUrl(`/application`);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
  
}

