import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { local } from 'd3-selection';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-devicedialog',
  templateUrl: './olddevice.component.html',
  styleUrls: ['./olddevice.component.scss']
})
export class DeviceDialogComponent implements OnInit {

  public deviceData: any;
  public name = 'sahitya';
  loginData: any;
  onit: boolean;
  tabulardata: any[];
  emptyplaceholder: boolean;
  dataSource: any;
  endoflist: boolean;
  sort: any;
  public page: number = 1;
  userid: any;
  deviceHistory: any;
  tabName:any='Login'

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,public dialogRef: MatDialogRef<DeviceDialogComponent>,public commonService:CommonserviceService
  ,public systemService:SystemService,private userDetailService: UserdetailsService) { }

  ngOnInit() {
    this.userid = this.data.devicedataSource;
    this.deviceData = this.data.devicedataSource;
    this.getLoginHistory();
    
  }
  getLoginHistory() {    
    let bodyData = {page:this.page.toString(),limit:'20',patient_data_fk:this.userid.toString()}
    this.commonService.getPtaientloginHistory(bodyData)
      .subscribe(
        (res: any) => {
          this.loginData = res.data;
          this.onSearchDataSuccess(res);
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
        
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;

      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  getDeviceHistory() {
    this.userDetailService.getpatientPermissiondata(this.userid.toString())
      .subscribe(
        (res: any) => {
          this.deviceHistory = res.data;
          this.successermisonData(res)
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private successermisonData(data) {

    data = (data as any).data;
     }
  tabClick(event){
    if(event.index == 0){
      this.getLoginHistory();
      this.tabName = 'Login';
    }else{
      this.tabName = 'Device';
      this.getDeviceHistory();
    }
   
  }
}
