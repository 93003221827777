/*==========================================
Title: Update password services
Author: Soundharya AM
Date:   17 April 2020
Last Change :  Added update password service
===========================================*/

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticateService, HttpService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CommonserviceService extends HttpService {

  public s3tokenurl: any = environment.serverUrl;
  public cueUrl: any = environment.cueSocketUrl;
  public graphql: any = environment.gqlUrl + 'gql?query=query';
  private getallcampaingurl = this.s3tokenurl + 'campaign/user/';
  private preactivateUrl = this.s3tokenurl + 'patient/register/bydoctor';
  private deeplinkUrl = this.s3tokenurl + 'patient/register/qrdeeplink';
  private campaignDeeplink = this.s3tokenurl + 'deeplink';
  public microserviceUrl: any = environment.microserviceUrl;
  public allusers: any;
  private enableCaptcha = environment.captcha;
  public allextension: any = {
    tag: 'tag',
    users: 'users',
    doctor: 'doctor',
    patient: 'patient',
    hcassignment: 'patient/hcassignment',
    campaign: 'campaign',
    resetpassword: 'users/update/passsword/normal',
    activationbyreq: 'activationcode/bygenerationrequest',
    hctimelog: 'timelog/userTimelogs',
    campaigndata: 'campaign',
    hcleaverequest: 'users/leave/request/',
    hctransfer: 'healthcoach/transfer',
    hardsetotp: 'otp/helper',
    hardsetotpcreate: 'otp/helper/create',
    client: 'client',
    request: 'request',
    payment: 'product',
    productplan: 'product',
    productmapping: 'product/mapping',
    activationdata: 'activationcode'
  };
  public taskExtension: any = {
    taskHistory: 'taskmanagement/taskhistory/get',
    deleteTaskbyAdmin: 'taskmanagement/task',
    duplicateTask: 'taskmanagement/tasklibrary/duplicate',
    taskApprove: 'taskmanagement/request',
    filterOverview: 'taskmanagement/task/globaloverview',
    getFilteredDataAdmin: 'taskmanagement/task/hcwiseoverview',
    getHCListForAssignment: 'taskmanagement/task/hclist',
    postHCForAssignment: 'taskmanagement/task',
    getPatientList: 'taskmanagement/task/patient/list',
    createTaskbyAdmin: 'taskmanagement/tasklibrary',
    sendForApproval: 'taskmanagement/request',
    createTaskProgress: 'taskmanagement/task/progress',
    getTaskProgress: 'taskmanagement/task/progress/get'
  }
  public refreshPage = new Subject<any>();

  captchaResponse: string | null;

  constructor(
    private http: HttpClient,
    private authService: AuthenticateService
  ) {
    super(http)
  }

  public getRequestById(id): Observable<any> {
    return this.get(this.s3tokenurl + 'request/get/' + id)
  }

  postToBlade(data, service, org?): Observable<string> {
    if (org != false && environment.org == true) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service], data)
  }

  hardDelete(service, id): Observable<string> {
    let bodydata = {};
    return this.delete(this.s3tokenurl + this.allextension[service] + '/delete/' + id, bodydata)
  }

  campaignDelete(id): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { user_id_fk: localStorage.getItem('scarletuserid') }
    }
    return this.delete(this.s3tokenurl + 'campaign/delete/' + id, options)
  }


  hardDeleteWithUserfk(service, id): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { user_id_fk: localStorage.getItem('scarletuserid') }
    }
    return this.delete(this.s3tokenurl + this.allextension[service] + '/delete/' + id, options)
  }

  changeHcleaveRequest(id, request_type, service): Observable<string> {
    let bodydata = {
      request_type: request_type,
      user_id_data_fk: localStorage.getItem('scarletuserid')
    }
    return this.patch(this.s3tokenurl + this.allextension[service] + id, bodydata)
  }


  logoutUser(id): Observable<string> {
    return this.delete(this.s3tokenurl + 'auth/logout/u/' + id)
  }


  logoutPatient(id): Observable<string> {
    return this.delete(this.s3tokenurl + 'auth/logout/p/' + id)
  }

  updatePassword(id, data): Observable<string> {
    return this.patch(this.s3tokenurl + 'users/update/passsword/normal/' + id, data)
  }

  hardsetotpedit(id, data): Observable<string> {
    return this.patch(this.s3tokenurl + 'otp/helper/edit/' + id, data)
  }

  hcTransfer(postdata, service): Observable<string> {
    let bodydata = {
      patientId: postdata.patientId,
      oldHcId: postdata.oldHcId,
      newHcId: postdata.newHcId,
    }
    return this.http.post<any>(this.s3tokenurl + this.allextension[service], bodydata)
  }

  changeHC(postdata): Observable<any> {
    return this.post(this.cueUrl + 'conversation/changeHealthCoachOnLeave', postdata)
  }


  setStatus(isactive, service, id, useridfk): Observable<string> {
    let bodydata = {
      user_id_data_fk: useridfk.toString()
    }
    if (isactive) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: bodydata
      }
      return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, options)
    }
    else {
      return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, bodydata)
    }
  }
  campaignStatus(isactive, service, id, useridfk): Observable<string> {
    let bodydata = {
      user_id_fk: useridfk
    }
    if (isactive) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: bodydata
      }
      return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, options)
    }
    else {
      return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, bodydata)
    }
  }

  // campaignStatus(isactive, service, id, useridfk): Observable<string> {
  //   let bodydata = {
  //     user_id_fk: useridfk
  //   }
  //   if (isactive) {
  //     const options = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json'
  //       }),
  //       body: bodydata
  //     }
  //     return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, options)
  //   }
  //   else {
  //     return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, bodydata)
  //   }
  // }
  approveRequest(isapprove, id, useridfk, comment): Observable<string> {
    comment = this.removeHTMLtags(comment);
    let bodydata = {
      is_approved_by: useridfk,
      is_approved: (!isapprove).toString(),
      comment: comment
    }
    return this.post(this.s3tokenurl + 'request/approve/' + id, bodydata)
  }
  stagingRequest(level, phoneno): Observable<string> {
    level = this.removeHTMLtags(level);
    phoneno = this.removeHTMLtags(phoneno);
    let bodydata = {
      level_data_fk: level.toString(),
      phone: phoneno.toString()
    }
    return this.post(this.s3tokenurl + 'therapy/update/staging', bodydata)
  }

  getAppMasterData(): Observable<string> {

    let headers = new HttpHeaders().set('x-access-token', this.authService.accessToken);
    return this.get(this.s3tokenurl + 'app/master/data/original?organization_data=' + this.authService.getorg(), { headers: headers })
  }

  getCount(start_date, end_date, hc_id): Observable<string> {
    let bodydata = {
      start_time: start_date.toString(),
      end_time: end_date.toString(),
      hc_id_data_fk: hc_id
    }
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.microserviceUrl + 'timelog/user/getcount', bodydata)
  }

  getTimelog(start_date, end_date, hc_id, page, limit): Observable<string> {
    let bodydata = {
      start_time: start_date.toString(),
      end_time: end_date.toString(),
      hc_id_data_fk: hc_id,
      page: page,
      limit: limit
    }
    return this.post(this.microserviceUrl + 'timelog/user/getall', bodydata)
  }

  getReportData(hc_id, start_date, end_date): Observable<string> {
    let bodydata = {
      start_date: start_date.toString(),
      end_date: end_date.toString(),
      hc_id_data_fk: hc_id.toString(),
    }
    return this.post(this.microserviceUrl + 'timelog/user/summary', bodydata)
  }

  getGraphData(start_date, end_date, hc_id, type): Observable<string> {
    let bodydata = {
      start_time: start_date.toString(),
      end_time: end_date.toString(),
      hc_id_data_fk: hc_id,
      date_filter: type
    }
    return this.post(this.microserviceUrl + 'timelog/user/graphs', bodydata)
  }


  updateAppMasterData(id, val): Observable<string> {
    return this.patch(this.s3tokenurl + 'app/master/data/' + id + '/' + val, {})
  }

  // setStatusCamp(isactive, service, id): Observable<string> {
  //     if (isactive) {
  //       return this.delete(this.s3tokenurl + this.allextension[service] + '/' + id, {})
  //     }
  //     else {
  //       return this.patch(this.s3tokenurl + this.allextension[service] + '/restore/' + id, {})
  //     }
  // }

  public gethctimelog(stime, etime, id, service): Observable<any> {
    let bodydata = {
      hc_id_data_fk: id,
      start_time: stime,
      end_time: etime
    };
    return this.post(this.microserviceUrl + this.allextension[service], bodydata)
  }

  updateToBlade(id, data, service, org?): Observable<string> {
    if (org != false && environment.org == true) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.patch(this.s3tokenurl + this.allextension[service] + '/update/' + id, data)
  }

  updatedataToBlade(id, data, service, org?): Observable<string> {
    if (org != false && environment.org == true) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.patch(this.s3tokenurl + this.allextension[service] + '/' + id, data)
  }

  patietDocMapping(data): Observable<string> {
    return this.post(this.s3tokenurl + 'patient/mapping/doctor/forscarlet', data)
  }


  public getAllActivationData(typetext, page, limit, status, therapyid, service): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      therapy_id: therapyid,
      status: status,
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }

  public getAllData(typetext, page, limit, status, service, user_type): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      user_type: user_type
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    if (JSON.parse(localStorage.getItem('scarletuserdata')).user_type == 'scarlet') {
      bodydata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }

  public getAllGeneralData(typetext, page, limit, status, service): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }

  public getAllPatients(typetext, page, limit, status, service, org?): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    if (org != false && environment.org == true) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }
  public getAllPatientsFilter(typetext, page, limit, status, service, startdate, enddate): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      start_date: startdate,
      end_date: enddate
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/search', bodydata)
  }


  public getAllPatientPayment(patient_data_fk, transaction_type, start_time, end_time, page, limit): Observable<any> {
    let bodydata = {
      patient_data_fk: patient_data_fk,
      transaction_type: transaction_type,
      start_time: start_time,
      end_time: end_time,
      page: page,
      limit: limit,
    };
    return this.post(this.s3tokenurl + 'patient/payment/data', bodydata)
  }


  public getOneDataById(id, service): Observable<any> {
    return this.get(this.s3tokenurl + this.allextension[service] + '/get/' + id)
  }


  public getAllActivationCodeData(page, limit, status, request_id): Observable<any> {
    return this.get(this.s3tokenurl + 'activationcode?page=' + page + '&limit=' + limit + '&status=' + status + '&generation_request_id=' + request_id)
  }

  public getSearchData(typetext, page, limit, status, service, org?): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      language_id: '1',
      group_mode: 'true'
    };
    if (org != false && environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/data', bodydata)
  }

  public getSearchMappingData(typetext, page, limit, status, service, org?): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    if (org != false && environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + this.allextension[service] + '/data', bodydata)
  }

  updateToBladePassword(id, data, service): Observable<string> {
    return this.patch(this.s3tokenurl + this.allextension[service] + '/' + id, data)
  }

  updateChildToBlade(chid, id, data, service): Observable<string> {
    return this.patch(this.s3tokenurl + this.allextension[service] + '/' + chid, data)
  }

  public getAllHelperOTP(typetext, page, limit, status): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.s3tokenurl + 'otp/helper/search', bodydata)
  }

  public getAllClients(page, status): Observable<any> {
    if (environment.org) {
      return this.get(this.s3tokenurl + 'client/?page=' + page + '&limit=10&status=' + status + '&organization_data=' + this.authService.getorg())
    }
    else {
      return this.get(this.s3tokenurl + 'client/?page=' + page + '&limit=10&status=' + status)
    }
  }
  public getOneClient(id): Observable<any> {
    return this.get(this.s3tokenurl + 'client/get/' + id)
  }
  public getOneHelperOTP(id): Observable<any> {
    return this.get(this.s3tokenurl + 'otp/helper/get/' + id)
  }

  updateClient(id, data, org?): Observable<string> {
    if (org != false && environment.org) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.patch(this.s3tokenurl + 'client/' + id, data)
  }

  customBranding(id, data, org?): Observable<string> {
    if (org != false && environment.org) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.patch(this.s3tokenurl + 'therapy/branding/' + id, data)
  }

  getAllActivationCodes(data): Observable<string> {
    return this.post(this.s3tokenurl + 'activationcode/getcodes', data)
  }

  gettransferlistdata(page, limit, id): Observable<string> {
    let bodydata = {
      page: page,
      limit: limit,
      patient_data_fk: id
    };
    return this.post(this.s3tokenurl + 'hcmapping/history', bodydata)
  }

  public getGraphQlData(parameter: string, org?): Observable<any> {
    if (org != false && environment.org) {
      var orgdata = 'status:true,organization_data:"' + this.authService.getorg() + '"';
      parameter = parameter.replace('status:true', orgdata)
    }
    return this.get(encodeURI(this.graphql + parameter))
  }

  getAllCampaign(id): Observable<string> {
    return this.get(this.getallcampaingurl + id)
  }
  preActiveUser(data, code): Observable<string> {
    var body = {
      "campaign_id_fk": data.condition,
      "user_code": code,
      "language_data_fk": data.language_data_fk
    }
    const options = { headers: { 'phone': data.phone, 'country-code': data.country_code } };
    // let headers = new Headers();
    // headers.append('phone', data.phone);
    // headers.append('country-code', data.country_code);
    // let options = new RequestOptions({ headers: headers });
    return this.post(this.preactivateUrl, body, options)
  }
  deeplinkAndQr(data, type, code): Observable<string> {
    var datap = {
      "activated_by": type,
      "user_code": code,
      "phone": data.phone,
      "country_code": data.country_code,
      "campaign_id_fk": data.condition,
      "language_data_fk": data.language_data_fk
    }
    return this.post(this.deeplinkUrl, datap)
  }

  removeBranding(id): Observable<string> {
    var body = {
      created_by_data_fk: localStorage.getItem('scarletuserid')
    }
    return this.patch(this.s3tokenurl + 'client/removebranding/' + id, body)
  }

  performSwitch(body): Observable<string> {
    return this.post(this.s3tokenurl + 'users/session/switch', body)
  }

  getS3URL(upload_type, type, size, filen): Observable<string> {
    var s3data = {
      "upload_type": upload_type,
      "mime": type,
      "size": size.toString(),
      "filename": filen
    }
    return this.post(this.s3tokenurl + 'document/s3/token', s3data)
  }

  uploadToS3(file, url): Observable<any> {
    return this.put(url, file)
  }
  //Rich media uploadToS3 URL 
  RichMediauploadToS3(file, url): Observable<any> {
    return this.put(url, file)
  }

  postImageToBlade(path, size, title, description, mime, upload_type, uploaded_from): Observable<string> {
    title = this.removeHTMLtags(title);
    description = this.removeHTMLtags(description);
    var imagedata = {
      "path": path,
      "size": size,
      "title": title,
      "detailed_text": description,
      "mime": mime,
      "upload_type": upload_type,
      "uploaded_from": uploaded_from
    }
    return this.post(this.s3tokenurl + 'document/create', imagedata)
  }

  CreateRichMedia(path, size, title, description, mime, thumbnail_path, created_by_fk_data): Observable<string> {
    title = this.removeHTMLtags(title);
    description = this.removeHTMLtags(description);
    var imagedata = {
      "path": path,
      "size": size,
      "title": title,
      "detailed_text": description,
      "mime": mime,
      "thumbnail_path": thumbnail_path,
      "created_by_data_fk": created_by_fk_data
      // "upload_type": upload_type,
      // "uploaded_from": uploaded_from
    }
    return this.post(this.microserviceUrl + 'taskmanagement/media/create', imagedata)
  }
  public getAllMedias(): Observable<any> {
    return this.get(this.s3tokenurl + 'media/get&page=1&limit=10&status=active')
  }

  public getMediaById(id): Observable<any> {
    return this.get(this.s3tokenurl + 'media' + '/get/' + id)
  }

  public getMediasSearch(typetext, page, limit, status, type, sdate, edate): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      media_type: type,
      date_start: sdate,
      date_end: edate
    }
    return this.post(this.s3tokenurl + 'media/search', bodydata)
  }
  //Activation Services

  getActivationCodeDetail(code): Observable<string> {
    code = this.removeHTMLtags(code);
    return this.post(this.s3tokenurl + 'activationcode/code/profile', { "code": code })
  }
  getactivationRequestData(id): Observable<string> {
    return this.get(this.s3tokenurl + 'activationcode/requestdata/' + id)
  }

  resetActivationcode(code): Observable<string> {
    return this.post(this.s3tokenurl + 'activationcode/code/reset', { "code": code, "user_id_data_fk": localStorage.getItem('scarletuserid') })
  }

  updateEmail(id, data): Observable<string> {
    return this.patch(this.s3tokenurl + 'users/update/email/' + id, data)
  }

  checkmultilingual(id): Observable<string> {
    return this.get(this.s3tokenurl + 'request/language/' + id)
  }

  campaignDeelink(data): Observable<string> {
    return this.post(this.campaignDeeplink, data)
  }

  CreateHardSetOtp(data, service, org?): Observable<string> {
    if (org != false && environment.org == true) {
      data['organization_data'] = this.authService.getorg()
    }
    return this.post(this.microserviceUrl + this.allextension[service], data)
  }

  onsiteEnroll(data): Observable<string> {
    return this.post(this.s3tokenurl + 'patient/register/onsite', data)
  }

  // CreateHardSetOtp(data, service, org?): Observable<string> {
  //   if (org != false && environment.org == true) {
  //     data['organization_data'] = this.authService.getorg()
  //   }
  //   return this.post(this.microserviceUrl + this.allextension[service], data)
  // }

  //Task management services
  public getTaskList(typetext, page, limit, status, sort_by, sort_direction, filters): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      sort_by: sort_by,
      sort_direction: sort_direction,
      filters: JSON.stringify(filters)
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.microserviceUrl + 'taskmanagement/tasklibrary/search', bodydata)
  }

  public getReqTaskList(typetext, page, limit, status, sort_by, sort_direction, filters): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      status: status,
      sort_by: sort_by,
      sort_direction: sort_direction,
      filters: JSON.stringify(filters)
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.microserviceUrl + 'taskmanagement/request/search', bodydata)
  }

  public getHCTaskList(typetext, page, limit, sort_by, sort_direction, filters, hc_id): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    let bodydata = {
      q: typetext,
      page: page,
      limit: limit,
      hc_id: hc_id,
      sort_by: sort_by,
      sort_direction: sort_direction,
      filters: JSON.stringify(filters)
    };
    if (environment.org) {
      bodydata['organization_data'] = this.authService.getorg()
    }
    return this.post(this.microserviceUrl + 'taskmanagement/task/hcviewhistory', bodydata)
  }
  getOneTaskData(id): Observable<string> {
    return this.get(this.microserviceUrl + 'taskmanagement/tasklibrary?id=' + id)
  }
  // public getTherapyList(typetext, page, limit, status,sort_by,sort_direction,filters): Observable<any> {
  //   let bodydata = {
  //     q: typetext,
  //     page: page,
  //     limit: limit,
  //     status: status,
  //     sort_by: sort_by,
  //     sort_direction: sort_direction,
  //     filters:JSON.stringify(filters)
  //   };
  //   if(environment.org)
  //   {
  //     bodydata['organization_data'] = this.authService.getorg()
  //   }
  //   return this.post(this.microserviceUrl +'taskmanagement/tasklibrary/search', bodydata)
  // }

  getTherapyList(search, limit, therapy, selected_id?): Observable<string> {
    search = this.removeHTMLtags(search);
    let bodydata = {
      q: search,
      limit: limit,
      entity_type: therapy,
      id: selected_id
    }
    return this.post(this.microserviceUrl + 'taskmanagement/tasklibrary/selectlist', bodydata)
  }

  TaskPost(data, service): Observable<string> {
    return this.post(this.microserviceUrl + this.taskExtension[service], data)
  }

  TaskProgress(data, service): Observable<string> {
    return this.post(this.microserviceUrl + this.taskExtension[service], data)
  }

  getProgress(data, service): Observable<string> {
    return this.post(this.microserviceUrl + this.taskExtension[service], data)
  }
  DeleteAssignedTaskbyAdmin(id): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        id: id,
        user_id_fk: localStorage.getItem('scarletuserid')
      }
    }
    return this.delete(this.microserviceUrl + 'taskmanagement/task', options)
  }

  DeleteTaskbyAdmin(id): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        id: id,
        user_id_fk: localStorage.getItem('scarletuserid')
      }
    }
    return this.delete(this.microserviceUrl + 'taskmanagement/tasklibrary', options)
  }

  updateTaskData(id, data): Observable<string> {
    return this.patch(this.microserviceUrl + 'taskmanagement/tasklibrary?id=' + id, data)
  }
  reassignTask(data): Observable<string> {
    return this.patch(this.microserviceUrl + 'taskmanagement/task', data)
  }

  approveTask(data): Observable<string> {
    return this.patch(this.microserviceUrl + 'taskmanagement/request', data)
  }

  getFilterList(): Observable<string> {
    var data = {}
    return this.post(this.microserviceUrl + 'taskmanagement/tasklibrary/filterdata', data)
  }

  createTask() {
    var body = {
      created_by_fk: localStorage.getItem('scarletuserid').toString()
    }
    return this.post(this.microserviceUrl + 'taskmanagement/tasklibrary', body)
      .pipe(
        tap((res: HttpResponse<any>) => {
          localStorage.setItem('createTaskID', JSON.stringify(res))
        })
      )
  }

  // Task ID
  public taskID: Subject<string> = new Subject<string>()
  currentMessage = this.taskID.asObservable();

  changeTaskID(id) {
    this.taskID.next(id)
  }

  //share languagee template data
  private __languageData = new BehaviorSubject<any>(null);
  current____languageData = this.__languageData.asObservable();

  change___languageData(languageData: any) {
    this.__languageData.next(languageData)
  }

  //share languages between excute and chat
  private __languageData1 = new BehaviorSubject<any>(null);
  current____languageData1 = this.__languageData1.asObservable();

  change___languageTemplate1(languageData1: any) {
    this.__languageData1.next(languageData1)
  }

  sendOTP(data): Observable<string> {
    return this.post(this.s3tokenurl + 'otp/doctor/forgot/password', data)
  }

  verifyOTP(data): Observable<string> {
    return this.post(this.s3tokenurl + 'otp/login/verify', data)
  }

  createPassword1(data): Observable<string> {

    let reqHeaders = new HttpHeaders();
    reqHeaders = reqHeaders.set('Content-Type', 'application/json');

    return this.post(this.s3tokenurl + 'otp/doctor/forgot/password/new', data, { headers: reqHeaders })
  }

  getPasswordHistory(data): Observable<string> {
    return this.post(this.s3tokenurl + 'password/change/history', data);
  }
  getHardsetotpHistory(data): Observable<string> {
    return this.post(this.s3tokenurl + 'otp/helper/history', data);
  }
  getPatientInfo(data): Observable<string> {
    return this.get(this.s3tokenurl + 'patient/program/change/' + data);
  }

  getPtaient(id): Observable<string> {
    let bodydata = {};
    return this.post(this.s3tokenurl + 'patient/complete/' + id, bodydata);
  }
  getPtaientloginHistory(data): Observable<string> {
    return this.post(this.s3tokenurl + 'patient/login/loginhistory', data);
  }

  EditPatient(data): Observable<string> {
    return this.patch(this.s3tokenurl + 'patient/program/change', data);
  }

  getTherapyCondition(data): Observable<string> {
    return this.get(this.s3tokenurl + 'therapy/get/' + data);
  }
  sendRefresh(message: string) { //the component that wants to update something, calls this fn
    this.refreshPage.next({ text: message }); //next() will feed the value in Subject
  }

  getRefresh(): Observable<any> { //the receiver component calls this function 
    return this.refreshPage.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
}
isHTML(str) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

removeHTMLtags(value:string){
  return value.replace( /(<([^>]+)>)/ig, '');
}

  getHealthKitRawData(patient_id) {
    return this.get(this.s3tokenurl + 'healthkit/data/patient/' + patient_id)
  }

  getUserPhoneChangeLog(customerId, pageNumber){
    let url = this.s3tokenurl + 'patient/data/change/list';

    let body = new HttpParams()
      .set('q', "")
      .set('limit', "20")
      .set('page', pageNumber.toString())
      .set("patient_id", customerId.toString());


    return this.post(url, body);
  }
  getPatientEventCount(patient_id:any){
    let body = new HttpParams()
    .set('patient_data_fk', patient_id)
    return this.post(this.s3tokenurl + 'app/list/events',body)
  }
  getPatientAllEvent(patient_id:any,page,filterName?:any,){
    let body = new HttpParams()
    .set('patient_data_fk', patient_id)
    .set('page',page)
    .set("limit","50")
    .set("events",JSON.stringify(filterName))
    return this.post(this.s3tokenurl + 'app/get/events',body)
  }
  createHospitalLocation(data): Observable<string> {
    return this.post(this.s3tokenurl + 'hospital/location', data)
  }

  updateHospitalLocation(data,id): Observable<string> {
    return this.patch(this.s3tokenurl + 'hospital/location/'+ id, data)
  }
  deleteHospitalLocation(id): Observable<string> {
    return this.delete(this.s3tokenurl + 'hospital/location/'+ id)
  }

  createHospital(data): Observable<string> {
    return this.post(this.s3tokenurl + 'hospital', data)
  }

  updateHospital(data,id): Observable<string> {
    return this.patch(this.s3tokenurl + 'hospital/'+ id, data)
  }

  deleteHospital(id): Observable<string> {
    return this.delete(this.s3tokenurl + 'hospital/'+ id)
  }
  getAllHospital(typetext?): Observable<any> {
    let bodydata = {
      q: typetext,
    };
    return this.post(this.s3tokenurl + 'hospital/search',bodydata)
  }
  public getHospitalDataById(id): Observable<any> {
    return this.patch(this.s3tokenurl + 'hospital/' + id,'')
  }
  public getHospitalLocationlDataById(id): Observable<any> {
    return this.patch(this.s3tokenurl + 'hospital/location/' + id,'')
  }
  validateDocPasswordLink(uuid){
    let bodydata = {
      uuid: uuid,
    };
    return this.post(this.s3tokenurl + 'users/update/password/checklink',bodydata)
  }
  GetPreviousEmailsSentToDoctor(doctor_id){
    let bodydata = {
      user_id_data_fk: doctor_id.toString(),
    };
    return this.post(this.s3tokenurl + 'users/update/password/pastlinks',bodydata)
  }
  ResendPasswordResetEmailtoDoctor(doctor_id,admin_id){
    let bodydata = {
      user_id_data_fk: doctor_id.toString(),
      sent_by_data_fk:admin_id.toString()
    };
    return this.post(this.s3tokenurl + 'users/update/password/sendlink',bodydata)
  }
}
