<mat-dialog-content class="mat-typography infusion-add">
    <h3>Infusion Site Changes</h3>

    <form [formGroup]="infoForm" validate>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Select Site</mat-label>
                    <mat-select formControlName="infusion_site">
                        <mat-option value="abdomen_left"> Abdomen Left</mat-option>
                        <mat-option value="thigh_front_right">Thigh Front Right</mat-option>
                        <mat-option value="thigh_front_left">Thigh Front Left</mat-option>
                        <mat-option value="thigh_rear_right">Thigh Rear Right</mat-option>
                        <mat-option value="thigh_rear_left">Thigh Rear Left</mat-option>
                        <mat-option value="upper_arm_rear_right">Upper Arm Rear Right</mat-option>
                        <mat-option value="upper_arm_rear_left">Upper Arm Rear Left</mat-option>
                        <mat-option value="lower_flank_left">Lower Flank Left</mat-option>
                        <mat-option value="upper_bottocks_right">Upper Buttocks Right</mat-option>
                        <mat-option value="upper_buttocks_left">Upper Buttocks Left</mat-option>                      
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Reason for change</mat-label>
                    <mat-select formControlName="site_change_reason"> 
                        <mat-option value="scheduled">Scheduled</mat-option>
                        <mat-option value="pain">Pain</mat-option>
                        <mat-option value="reaction">Reaction</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Reminder Days</mat-label>
                    <input matInput placeholder="Reminder Days" formControlName="reminder_days">
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Choose Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="log_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>


</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-flat-button color="primary" class="submit-btn" (click)= "addInfusionSite()">Add Infusion site Changes</button>
</mat-dialog-actions>