import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { TherapydesignComponent } from './therapydesign.component';
import { EdittherapydesignComponent } from './edittherapydesign/edittherapydesign.component';
import { EditlevelsComponent } from './editlevels/editlevels.component';
import { CustombrandingComponent } from './custombranding/custombranding.component';

const routes: Routes = [
  { path: '', component: TherapydesignComponent, canActivate: [ContentGuardGuard] },
  { path: 'therapy/edit/:id', component: EdittherapydesignComponent, canActivate: [ContentGuardGuard] },
  { path: 'therapy/editlevels/:id', component: EditlevelsComponent, canActivate: [ContentGuardGuard] },
  { path: 'therapy/custombranding/:id', component: CustombrandingComponent, canActivate: [ContentGuardGuard] }

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TherapydesignRoutingModule { }
