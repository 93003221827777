<div class="row">
    <div class="col-md-12">
        <div class="heading-wrapper">
        
            <div class="left-cont">
                <div class="back-btn-wrapper">
                    <div class="back-btn" (click)="closeDialog()"></div>
                </div>
                <p class="title">Add details</p>
            </div>
            
            <div class="close-icon-wrapper">
                <div class="close-icon" (click)="closeDialog()"></div>
            </div>
        
        </div>
    </div>    
</div>

<form [formGroup]="mediainfoForm" (ngSubmit)="onsubmit()">
    <div formArrayName="mediadatas" class="media-form-wrapper">
        <div *ngFor="let media of mediainfoForm.get('mediadatas')['controls']; let j=index" [formGroupName]="j" class="media-form-cont" [ngStyle]="{height : media.value.type.includes('audio') ? '136px' : '120px'}">

            <div class="preview-cont">
                <div class="media-preview" *ngIf="media.value.type == 'image'">
                    <img [src]="media.value.url" width="80px" height="80px">
                </div>

                <div class="media-preview" *ngIf="media.value.type == 'video'">
                    <div class="audio-video-preview">
                        <mat-icon>video_library</mat-icon>
                        <div>{{media.value.name}}</div>
                    </div>
                </div>

                <div class="media-preview" *ngIf="media.value.type.includes('audio')">
                    <div class="audio-video-preview">
                        <mat-icon>library_music</mat-icon>
                        <div>{{media.value.name}}</div>
                    </div>
                </div>
            </div>

            <div class="form-field-wrapper">

                <div class="form-field-cont">
                    <mat-form-field appearance="outline" class="form-field-elem">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" formControlName="title" type="text" value=""/>
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="form-field-elem">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="Description (optional)" formControlName="detailed_text"  type="text" value=""/>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="media.value.type.includes('audio')">
                    <div class="audio-cont">
                        <p>Choose Thumbnail</p>    
                        <file-upload formControlName="audio_thumb" class="file-wrapper" simple></file-upload>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
    <button mat-button type="submit" mat-flat-button color="primary" [disabled]="!mediainfoForm.valid" class="uploadmodalbtn">Save files</button>
</form>
