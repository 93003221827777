import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class APILogsAPIService {

  public apiUrl: string = environment.serverUrl;

  constructor(
    public httpService: HttpService
  ) { }

  search(data: any){

    let url = this.apiUrl + 'third_party/api/data';
    return this.httpService.post(url, data);
  }

  retry(id: any){

    let url = this.apiUrl + `third_party/api/retry/${id}`;

    return this.httpService.post(url, {});
  }

}
