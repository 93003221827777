import { SocketService } from 'src/app/healthcoach/services/socket/socket.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError } from 'rxjs/index';
import { catchError, finalize, map } from 'rxjs/internal/operators';
import { delay, startWith } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { I } from '@angular/cdk/keycodes';
import moment = require('moment');


@Component({
  selector: 'app-diarylogdialog',
  templateUrl: './diarylogdialog.component.html',
  styleUrls: ['./diarylogdialog.component.scss']
})
export class DiaryLogDialogComponent implements OnInit {
  maxDate = new Date();
  dt = new Date();
  minDate = new Date((this.dt.getFullYear() - 1), this.maxDate.getMonth(), this.maxDate.getDate());
  defaultleftoption: any;
  InfusionOption: any;
  showsuccess = false;
  rawdata: any;
  showerror = false;
  showdateerror = false;
  hasnix: boolean;
  hascholestrol: boolean;
  showButtonLoader: boolean;
  localfooddata: any;
  sortedfooddata: any
  nutritionixfooddata: any;
  selectedType: any;
  eligibleLogData: any;
  date: Date = new Date();
  isloading: boolean = false;
  showReason: boolean = false;
  infusionChange:  boolean = false;
  infusionSite: any;
  lastLogData: any;
  firstInfusion: boolean = true;
  infusionSiteData: any;
  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy hh:mm a',
    defaultOpen: false,
    closeOnSelect: true
  }
  selectedTag: any;
  public ImageId: any;
  InfusionImage: any = [];
  MCHITagData: any = [];
  logDateAsStartDate: any = {};
  private allfiles: any;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public demoForm = new FormGroup({
    files: this.filesControl,
  });
  private responsethumbUrl: any;
  upload_infusion_type: any;
  upload_type: any = upload_type;
  mediaSaveas: string;
  private resdata: any;
  public image_path: any;
  //public files: any = this.filesControl;
  upload_doc_type: any;
  responseUrl: any;
  Infusion_type: any;
  infusion_reason: any
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
  public uploadedfiletype: any;
  isloadingpres: boolean = false;
  TemperatureError: boolean = false;
  public isinvalid: boolean = false;
  public foodFilterCtrl: FormControl = new FormControl();
  public foodSubject$ = new BehaviorSubject<any>(null);
  public foodPreviewSubject$ = new BehaviorSubject<any>(null);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public foodFilterData: any = [];
  public filteredFood: ReplaySubject<any> = new ReplaySubject<any>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  public filteredOptions: Observable<string[]>;
  public newentity = [0.25, 0.5, 0.75, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50];
  public newunit = [];
  public bodydata: any = {};
  public selectedUserID: string;
  public endoflist: boolean = false;
  public page: number = 1;
  public file: any;
  public SearchedItem: any;
  public fileData: any;
  measurement_Value: any;
  hipcircumferenceVal: any;
  waistcircumferenceVal: any;
  aspectRatio: any;
  BodyParamUnitData: any;
  tempplaceholderData: any;
  public labreportForm = this.fb.group({
    labtype: [],
    on_date: [],
    log_date: [],
    duration: [],
    type: [],
    track_type: ["Manual"],
    name: [],
    val: [],
    hdl: [],
    ldl: [],
    score: [],
    symval: [],
    unit: ["kg"],
    triglyceride: [],
    sodium: [],
    tag: [''],
    potassium: [],
    chloride: [],
    bicarbonate: [],
    quantity: [''],
    systolic_blood_pressure: [],
    systolic_blood_pressure_unit: [],
    diastolic_blood_pressure: [],
    diastolic_blood_pressure_unit: [],
    heart_rate: [],
    weight_unit: [],
    bloodSugar_unit: [],
    water_unit: [''],
    oximetry_value: [],
    peak_flow_measure: [],
    peak_flow_unit: [],
    log_type: [],
    longitude: [],
    latitude: [],
    location_name: [],
    infusion_site: [''],
    site_change_reason: [''],
    reminder_days: [''],
    infusion_site_summary: [''],
    measurement_type: [''],
    temperature_unit: [''],
    value: [''],
    waist: [''],
    hip: [''],
    BodyParamUnit: [''],
    hr_duration: [''],
    activity_log_type:[''],
    min_duration: [''],
    kcal_duration: [''],
    food: this.fb.array([
    ])
  });
  enterActivityDuration: boolean = false
  showActivityDatePicker: boolean = false;
  showActivitykcal: boolean = false;
  @ViewChild("timepicker") timepicker: any;
  formControlItem: FormControl = new FormControl("");
  acivity_input_type: any;
  userWeight: any;
  macroNutritient:boolean=false;
  nutritient_data: any;
  mealType: any;
  totalcalories: any;
  carb: any;
  protein: any;
  fat: any;
  fiber: any;
  mediaUploadsize: any;
  reminder_days: any;

  get7dayolddate() {
    var date = new Date();
    var last = new Date(date.getTime() - (90 * 24 * 60 * 60 * 1000));
    return last;
  }
  initOptions(trackid, food_item, cal,id) {
    return this.fb.group({
      quantity: [Validators.required],
      track_id: [trackid, Validators.required],
      food_item: [food_item, Validators.required],
      unit: [Validators.required],
      value: [cal, Validators.required],
      id:[id,Validators.required]
    })
  }

  initCKDoptions(alldata) {
    return this.fb.group({
      consumed_in_grams: [alldata.consumed_in_grams],
      count: [Validators.required],
      food_id: [alldata.food_id, Validators.required],
      food_name: [alldata.food_name, Validators.required],
      calories: [alldata.calories, Validators.required],
      cholesterol: [alldata.cholesterol, Validators.required],
      fiber: [alldata.fiber, Validators.required],
      phosphorous: [alldata.phosphorous, Validators.required],
      potassium: [alldata.potassium, Validators.required],
      protein: [alldata.protein, Validators.required],
      saturated_fats: [alldata.saturated_fats, Validators.required],
      sodium: [alldata.sodium, Validators.required],
      carbs: [alldata.carbs, Validators.required],
      fats: [alldata.fats, Validators.required],
      serving_qty: [alldata.serving_qty, Validators.required],
      serving_unit: [alldata.serving_unit, Validators.required],
      serving_in_grams: [alldata.serving_in_grams, Validators.required],
      unit: [alldata.unit, Validators.required],
      allunit: [alldata.allunit]
    })
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userDetailService: UserdetailsService, public dialog: MatDialog, public fb: FormBuilder,
    private UserdataService: UserdetailsService, private commonService: CommonserviceService, private systemService: SystemService, private appContext: ContextService,private socketService:SocketService) {
    localStorage.removeItem("infusionStartDate");
    localStorage.removeItem("DiaryInfusion");
    if(data.enable){
      this.macroNutritient = true;
      this.carb = data.carb,
      this.protein = data.protein,
      this.fat = data.fat,
      this.fiber = data.fiber,
      this.mealType = data.meal_type.charAt(0).toUpperCase() + data.meal_type.slice(1);
      this.totalcalories = data.totalcalories
    }
  }

  ngOnInit() {
    this.showsuccess = false;
    this.hasnix = this.data.hasnix;
    this.hascholestrol = this.data.hascholestrol;
    this.selectedType = this.data.selectedOption;
    this.showReason = this.data.showSiteReason;
    this.infusionChange = this.data.infusionChange;
    this.reminder_days = this.data.reminder_days
    localStorage.removeItem('Infusion_ImageId');
    var selectedlogval;
    if (this.selectedType == 'Activity') { selectedlogval = 'activity' } if (this.selectedType == 'Blood Sugar') { selectedlogval = 'bloodSugar' } if (this.selectedType == 'Meal') { selectedlogval = 'meals' } if (this.selectedType == 'Weight') { selectedlogval = 'weight' } if (this.selectedType == 'Blood Pressure') { selectedlogval = 'bloodPressure' } if (this.selectedType == 'Water') { selectedlogval = 'water' } if (this.selectedType == 'Body Parameters') { selectedlogval = 'measurement' }
    this.defaultleftoption = selectedlogval;
    this.minDate = this.get7dayolddate();
    this.getLocalFoodData("");
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getUserEligibleLogs(this.selectedUserID);
      if(this.infusionChange){
        this.defaultleftoption = "Infusion Site";
        this.labreportForm.controls.labtype.patchValue("Infusion Site");
        this.labreportForm.controls.infusion_site.patchValue(this.data.selectedOption) 
        this.labreportForm.controls.on_date.patchValue(new Date());
        this.labreportForm.controls.reminder_days.patchValue(this.reminder_days.toString());
        this.lastLogData = this.data.lastLogData;
      }
    this.getMCHITags();
    this.getUserDetails();
    this.mediaUploadsize = JSON.parse(JSON.parse(localStorage.getItem('mediaUploadSize')));
  }

  getMCHITags() {
    this.userDetailService.getMCHITags().subscribe(
      (res: any) => this.MCHITags(res),
      (err: HttpErrorResponse) => {
        this.systemService.handleErrorResponse(err)
        //this.enableCoachMark = err.status;
      }
    );
  }


  private MCHITags(data) {
    data = (data as any).data;
    let MCHITags: any = data.MCHI_TAGS_FOR_MONTH_VERIFY;
    var myarray = MCHITags.split(',');
    for (var i = 0; i < myarray.length; i++) {
      this.MCHITagData.push(myarray[i]);
    }
  }

  changeRange(e) {
    Object.keys(this.labreportForm.controls).forEach(key => {
      this.labreportForm.get(key).setErrors(null);
    });
    this.selectedTag = this.labreportForm.value.labtype;

    if (e == 'Infusion Site') {
      this.getInfusionSite();
    } else {
      this.firstInfusion = true;
    }

  }

  getUserEligibleLogs(id: string) {
    this.userDetailService.getEligibleLogs(id).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      this.eligibleLogData = data;
      this.filteradmincontent(this.eligibleLogData);
      this.loadingError$.next(false);
    });
  }
  filteradmincontent(a) {
    // this.eligibleLogData = [];
    // for (var j = 0; j < a.length; j++) {
    //   if (a[j].type == 'diary') {
    //     this.eligibleLogData.push(a[j]);
    //   }
    // }
    this.eligibleLogData = [];
    for (var j = 0; j < a.length; j++) {
      if (a[j].type == 'diary' || a[j].type == 'body_parameters') {
        // if (a[j].title === 'Measurement') {
        //   a[j].title = 'Body Parameters';
        // }
        this.eligibleLogData.push(a[j]);
      }
    }
  }

  public _filter(value: any): any {
    const filterValue = value.toLowerCase();

    this.getLocalFoodData(filterValue);
  }

  showlabrepo(param) {
    if (this.eligibleLogData && this.eligibleLogData.filter(aa => aa.response_key == param).length > 0) {
      return true
    }
    else {
      return false
    }
  }
  getNextSet() {

    if (this.endoflist == false) {
      this.page = this.page + 1;

      this.search();
    }
  }

  search() {
    this.SearchedItem = this.removeHTMLtags(this.SearchedItem)
    var data = {
      "q": this.SearchedItem,
      "page": this.page.toString(),
      "limit": "10"
    }
    this.UserdataService.getMisc(data, 'mealsearch').pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
        return throwError(error);
      }),
      finalize(() => { this.showButtonLoader = false; })
    ).subscribe(
      (data) => {
        data = (data as any).data;
        this.localfooddata = data;
        this.sortedfooddata = data;
        // listen for search field value changes
        this.filteredOptions = this.foodFilterCtrl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      })
  }

  getLocalFoodData(e) {
  e = this.removeHTMLtags(e)
    var data = {
      "q": e,
      // "status": "active",
      "page": "1",
      "limit": "10"
    }
    this.UserdataService.getMisc(data, 'mealsearch').pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
        return throwError(error);
      }),
      finalize(() => { this.showButtonLoader = false; })
    ).subscribe(
      (data) => {
        data = (data as any).data;
        this.localfooddata = data;
        this.sortedfooddata = data;
        this.endoflist = false;
        // listen for search field value changes
        this.filteredOptions = this.foodFilterCtrl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      })
  }
  searchFood(e) {
    this.getLocalFoodData(e.target.value);
    this.SearchedItem = e.target.value;
  }
  
 addNewFood(data) {
    this.newunit = [];
    const control = <FormArray>this.labreportForm.controls['food'];
    this.newunit = ["plate", "cup", "piece", "portion", "ltr", "ml"];
    let id = data.id.toString();
    control.push(this.initOptions(id, data.food_name, data.nutrition_data.calories_kcal,id));
 }
  
  setServingGrams(position, val) {
    const control = <FormArray>this.labreportForm.controls['food'];
    if (val) {
      var k = control.controls[position].value.allunit.filter(unit => unit.measure == val);
      control.controls[position].value.consumed_in_grams = k[0].searvingweight;
    }

  }
  removeFoodItem(e) {
    const control = <FormArray>this.labreportForm.controls['food'];
    control.removeAt(e);
  }

  changeReason(value) {
    this.infusion_reason = value

  }

  async uploadthumbnail(path, ele, imageinfo, cb, logdate) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = async function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }
          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('infusion_image', thtype, myFile.size, 'image.png').toPromise().then(data => {
            scope.responsethumbUrl = data;
            if (scope.responsethumbUrl) {
              if (scope.mediaSaveas !== "1") {
                if (scope.allfiles.type.includes('pdf')) {
                  scope.upload_infusion_type = upload_type[0];
                  scope.upload_doc_type = 'document';
                }
                if (scope.allfiles.type.includes('image')) {
                  scope.upload_infusion_type = upload_type[1];
                  scope.upload_doc_type = 'gallary';
                }
              } else {
                if (scope.allfiles.type.includes('pdf')) {
                  scope.upload_infusion_type = upload_type[0];
                  scope.upload_doc_type = 'document';
                }
                if (scope.allfiles.type.includes('image')) {
                  scope.upload_infusion_type = upload_type[1];
                  scope.upload_doc_type = 'gallary';
                }
              }
              scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                .subscribe(
                  (data) => {

                  }, (error) => {
                    scope.isloadingpres = true;
                    scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                  })
              let image_id;
              scope.commonService.postImageToBlade(scope.responsethumbUrl.data.path, ele.size.toString(), imageinfo.title, imageinfo.detailed_text, ele.type, scope.upload_infusion_type, scope.upload_doc_type).toPromise().then(data => {
                scope.resdata = data;
                image_id = [scope.resdata.data.id];
                scope.getImageId(image_id);

                if (scope.resdata.status == 201) {
                  if (scope.InfusionImage.length === scope.file.length) {
                    let bodydata = {
                      "infusion_site": scope.labreportForm.value.infusion_site,
                      "site_change_reason": scope.labreportForm.value.site_change_reason,
                      "reminder_days": scope.labreportForm.value.reminder_days,
                      "patient_id_data_fk": scope.data.dataKey,
                      "log_date": logdate,
                      "logged_by": 'hc',
                      "start_date": logdate,
                      "infusion_site_summary": scope.labreportForm.value.infusion_site_summary,
                      "image_ids": JSON.stringify(scope.InfusionImage),
                    }
                    scope.UserdataService.addInfusionSite(bodydata).pipe(
                      catchError((error: any) => {
                        scope.loadingError$.next(true);
                        return throwError(error);
                      }),
                      finalize(() => { scope.loadingSubject$.next(false); })
                    ).subscribe(data => {
                      scope.dialog.closeAll();
                      scope.isloading = false;
                      scope.systemService.showSnackBar("Infusion site updated.", "OK");
                    });
                  }
                }
                else {


                }
              });
            }
          });
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else if (ele.type.match('pdf')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.setAttribute('crossorigin', 'anonymous');
        tempImg.src = '/assets/img/pdficon.jpg';
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('infusion_image', thtype, myFile.size, 'image.png')
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      if (data) {
                        saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                      }
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);

    }
    else {
      this.isloadingpres = true;
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }
    async function saveIttoDB(path, size, type, thumbpath, imageinfo) {
      if (scope.mediaSaveas !== "1") {
        if (scope.allfiles.type.includes('pdf')) {
          scope.upload_infusion_type = upload_type[0];
          scope.upload_doc_type = 'document';
        }
        if (scope.allfiles.type.includes('image')) {
          scope.upload_infusion_type = upload_type[1];
          scope.upload_doc_type = 'gallary';
        }
      } else {
        if (scope.allfiles.type.includes('pdf')) {
          scope.upload_infusion_type = upload_type[0];
          scope.upload_doc_type = 'document';
        }
        if (scope.allfiles.type.includes('image')) {
          scope.upload_infusion_type = upload_type[1];
          scope.upload_doc_type = 'gallary';
        }
      }
      let image_id;
      await scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, scope.upload_infusion_type, scope.upload_doc_type)
        .subscribe(
          (data) => {
            scope.resdata = data;
            image_id = [scope.resdata.data.id];
            scope.getImageId(image_id);

            let bodydata = {
              "infusion_site": this.labreportForm.value.infusion_site,
              "site_change_reason": this.labreportForm.value.site_change_reason,
              "reminder_days": this.labreportForm.value.reminder_days,
              "patient_id_data_fk": this.data.dataKey,
              "log_date": logdate,
              "logged_by": 'hc',
              "start_date": logdate,
              "infusion_site_summary": this.labreportForm.value.infusion_site_summary,
              "image_ids": this.InfusionImage,
            }
            if (scope.resdata.status == 201) {
              this.UserdataService.addInfusionSite(bodydata).pipe(
                catchError((error: any) => {
                  this.loadingError$.next(true);
                  return throwError(error);
                }),
                finalize(() => { this.loadingSubject$.next(false); })
              ).subscribe(data => {
                this.dialog.closeAll();
                this.isloading = false;
                this.systemService.showSnackBar("Infusion site updated.", "OK");
              });

            }
            else {


            }
          })
    }
  }

  getImageId(imageID) {
    this.ImageId = imageID[0];
    localStorage.setItem('Infusion_ImageId', this.ImageId);
    this.InfusionImage.push(this.ImageId);
    this.InfusionImage = [...new Set(this.InfusionImage)];
  }

  dataURItoBlobb(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }


  labreportObject() {
    this.isloading = true;
    var innerdata;
    var labjson;
    var scope = this;
    var isvalidvalue = true;
    var selected_Date = Date.parse(this.labreportForm.value.on_date);
    var datum = new Date(this.labreportForm.value.on_date);
    var date = new Date();
    // To offset the timezone deduction(-5:50 while converting using toISOString()) 
    datum.setHours(date.getHours(),date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    var maxDate = new Date().getTime();
    var logdate = null;
    scope.showdateerror = false;
    var timestamp = date.getTime();
    logdate = moment(datum).toISOString();
    timestamp = Math.floor(timestamp / 1000);


    if (this.demoForm.value.files) {
      this.demoForm.value.files.forEach(el => {
        if (((el.size / (1024 * 1024)) > this.mediaUploadsize.video) && (el.type.includes('video'))) {
          this.isinvalid = true;
        }
        else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
          this.isinvalid = true;
        }
        else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.image) && (el.type.includes('image'))) {
          this.isinvalid = true;
        }
        else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.document) && (el.type.includes('pdf'))) {
          this.isinvalid = true;
        }
        else {
          this.isinvalid = false;
          this.isloadingpres = false;
        }
      });
      if (this.isinvalid != true) {
        if (this.labreportForm.status == 'INVALID') {
          this.isinvalid = true;
          this.isloadingpres = false;
        }
        else {
          this.isinvalid = false;
          this.allfiles = this.demoForm.value.files;
          this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];

          if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
            this.isloadingpres = false;
            this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
          }
          else {
            var imageinfo = {
              detailed_text: "Infusion Image",
              title: "Infusion Image",
              type: this.uploadedfiletype,
            }
            this.isloadingpres = true;
            var scope = this;
            var last = false;
            var imagedetails;
            this.InfusionImage = [];
            processfiles(0);
            function processfiles(i) {
              if (imageinfo) {
                scope.isloadingpres = true;
                imagedetails = "";
                imagedetails = imageinfo;
                if (scope.allfiles[i].type.includes('pdf')) {
                  scope.Infusion_type = 'infusion_pdf';
                }
                if (scope.allfiles[i].type.includes('image')) {
                  scope.Infusion_type = 'infusion_image';
                }
                scope.commonService.getS3URL(scope.Infusion_type, scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                  .subscribe(
                    (data) => {
                      scope.responseUrl = data;
                      scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                        .subscribe(
                          (data) => {

                            scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, logdate, data => {

                            });
                          }, (error) => {
                            scope.isloadingpres = false;
                            scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                            return;
                          })
                    }, (error) => {
                      scope.isloadingpres = false;
                      scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                      return;
                    });
              }
            }
          }
        }
      }

      let infusion_image = localStorage.getItem("Infusion_ImageId")
      this.InfusionImage.push(infusion_image);
      this.InfusionImage = [...new Set(this.InfusionImage)];


    }

    if (this.defaultleftoption == 'Activity') {
      // if ((this.labreportForm.value.duration < 1) || (this.labreportForm.value.duration > 1440)) {
      //   isvalidvalue = false;
      // }
      if (this.acivity_input_type === 'mins') {
      //   if ((((this.labreportForm.value.hr_duration + this.labreportForm.value.min_duration)*60)< 1) || ((this.labreportForm.value.hr_duration + this.labreportForm.value.min_duration)*60) > 1440) {
      //   isvalidvalue = false;
      // }
      if(this.labreportForm.value.hr_duration === ''){
        this.labreportForm.value.hr_duration = 0;
      }
        innerdata = {
          "quantity": (this.labreportForm.value.hr_duration )*60 + this.labreportForm.value.min_duration,
          "value": this.convertMinToKcal(this.labreportForm.value.type),
          "unit": 'Mins',
          "type": this.labreportForm.value.type,
          "on_date": logdate,
          "track_id": timestamp
        }

      } else if (this.acivity_input_type === 'Kcal') {
        innerdata = {
          "quantity":this.convertKcalToMin(this.labreportForm.value.type),
          "value":  (this.labreportForm.value.kcal_duration),
          "type": this.labreportForm.value.type,
          "unit": 'Kcal',
          "on_date": logdate,
          "track_id": timestamp
        }
      }

      this.logDateAsStartDate = innerdata;
      labjson = {
        'activities': innerdata
      };
    }

    if (this.defaultleftoption == 'Body Parameters: Temperature') {
      let tempUnit = this.labreportForm.value.temperature_unit;
      let quantityValue = this.labreportForm.value.value;
      if (tempUnit === 'Celsius' && quantityValue <= 50) {
        innerdata = {
          "patient_id_data_fk": localStorage.getItem('selectedPatientId'),
          "value": this.labreportForm.value.value,
          "unit": this.labreportForm.value.temperature_unit,
          "log_date": logdate,
        }
        this.logDateAsStartDate = innerdata;
        labjson = {
          'bodytemperature': innerdata
        };
      }
      else if (tempUnit === 'Celsius' && quantityValue >= 50) {
        this.systemService.showSnackBar('Invalid Celsius Value Entered', 'OK');
        return;
      }
      else if (tempUnit === 'Fahrenheit' && (quantityValue > 49 && quantityValue <= 150)) {
        innerdata = {
          "patient_id_data_fk": localStorage.getItem('selectedPatientId'),
          "value": this.labreportForm.value.value,
          "unit": this.labreportForm.value.temperature_unit,
          "log_date": logdate,
        }
        this.logDateAsStartDate = innerdata;
        labjson = {
          'bodytemperature': innerdata
        };
      }
      else if (tempUnit === 'Fahrenheit'){
      if(quantityValue < 50 ) {
        this.systemService.showSnackBar('Invalid Fahrenheit Value Entered', 'OK');
        return;
      }else if(quantityValue > 150){
        this.systemService.showSnackBar('Invalid Fahrenheit Value Entered', 'OK');
        return;
      }
    }
    }

    // if (this.defaultleftoption == 'Body Parameters' && this.measurement_Value == 'Hip_waist_ratio') {
    //   
    //   if (this.labreportForm.value.BodyParamUnit == 'inch' && (this.labreportForm.value.hip < 14 || this.labreportForm.value.hip > 200) || (this.labreportForm.value.waist < 14 || this.labreportForm.value.waist > 200)) {
    //     this.systemService.showSnackBar('Invalid inch Value Entered', 'OK');
    //     return;
    //   }
    //   else if (this.labreportForm.value.BodyParamUnit == 'cm'  && (this.labreportForm.value.hip < 35 || this.labreportForm.value.hip > 500) || (this.labreportForm.value.waist < 35 || this.labreportForm.value.waist > 500)) {
    //     this.systemService.showSnackBar('Invalid cm Value Entered', 'OK');
    //     return;
    //   }
    //   else {
    //     innerdata = {
    //       "patient_id_data_fk": localStorage.getItem('selectedPatientId'),
    //       "hip": this.labreportForm.value.hip.toString(),
    //       "waist": this.labreportForm.value.waist.toString(),
    //       "hip_unit": this.labreportForm.value.BodyParamUnit,
    //       "waist_unit": this.labreportForm.value.BodyParamUnit,
    //       "ratio": this.aspectRatio.toString(),
    //       "log_date": logdate,
    //     }
    //     this.logDateAsStartDate = innerdata;
    //     labjson = {
    //       'hipwaist': innerdata
    //     };
    //   }

    // }
    if (this.defaultleftoption == 'Body Parameters: Hip waist ratio') {

      if (this.BodyParamUnitData === "inch") {
        if ((this.labreportForm.value.hip < 14 || this.labreportForm.value.hip > 200) || (this.labreportForm.value.waist < 14 || this.labreportForm.value.waist > 200)) {
          this.systemService.showSnackBar('Invalid inch Value Entered', 'OK');
          return;
        }
      }
      else if (this.BodyParamUnitData === "cm") {
        if ((this.labreportForm.value.hip < 35 || this.labreportForm.value.hip > 500) || (this.labreportForm.value.waist < 35 || this.labreportForm.value.waist > 500)) {
          this.systemService.showSnackBar('Invalid cm Value Entered', 'OK');
          return;
        }
      }
      innerdata = {
        "patient_id_data_fk": localStorage.getItem('selectedPatientId'),
        "hip": this.labreportForm.value.hip.toString(),
        "waist": this.labreportForm.value.waist.toString(),
        "hip_unit": this.labreportForm.value.BodyParamUnit,
        "waist_unit": this.labreportForm.value.BodyParamUnit,
        "ratio": this.aspectRatio.toString(),
        "log_date": logdate,
      }
      this.logDateAsStartDate = innerdata;
      labjson = {
        'hipwaist': innerdata
      };


    }

    if (this.defaultleftoption == 'Peak Flow') {
      if ((this.labreportForm.value.peak_flow_measure < 50) || (this.labreportForm.value.peak_flow_measure > 1000) || (this.labreportForm.value.peak_flow_measure == null)) {
        isvalidvalue = false;
      }
      innerdata = {
        "measure": this.labreportForm.value.peak_flow_measure,
        "unit": this.labreportForm.value.peak_flow_unit,
        "type": this.labreportForm.value.log_type,
        "longitude": this.labreportForm.value.longitude,
        "latitude": this.labreportForm.value.latitude,
        "on_date": logdate,
        "location_name": this.removeHTMLtags(this.labreportForm.value.location_name),
      }
      labjson = {
        'peakFlow': innerdata
      };
    }

    if (this.defaultleftoption == 'Blood Sugar') {
      // if ((this.labreportForm.value.duration < 10) || (this.labreportForm.value.duration > 650 || (this.labreportForm.value.duration == null))) {
      //   isvalidvalue = false;
      //   this.systemService.showSnackBar('Invalid Blood Sugar Value Entered', 'OK');
      //   return;
      // }
      innerdata = {
        "value": this.labreportForm.value.duration,
        "type": this.labreportForm.value.type,
        "track_id": timestamp,
        "bloodSugar_unit": this.labreportForm.value.bloodSugar_unit,
        "on_date": logdate
      }
      labjson = {
        'bloodSugar': innerdata
      };
    }
    if (this.defaultleftoption == 'Weight') {
      if (this.labreportForm.value.weight_unit === 'kg') {
        if ((this.labreportForm.value.duration < 20) || (this.labreportForm.value.duration > 400) || (this.labreportForm.value.duration == null)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Weight Value Entered', 'OK');
          return;
        }
      } else if (this.labreportForm.value.weight_unit === 'lbs') {
        if ((this.labreportForm.value.duration < 20) || (this.labreportForm.value.duration > 800) || (this.labreportForm.value.duration == null)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Weight Value Entered', 'OK');
          return;
        }
      }
      innerdata = {
        "value": this.labreportForm.value.duration,
        "unit": this.labreportForm.value.weight_unit,
        "track_id": timestamp,
        "on_date": logdate,
        "tag": this.labreportForm.value.tag
      }
      labjson = {
        'weight': innerdata
      };
    }

    if (this.defaultleftoption == 'Meal') {
      if (this.labreportForm.value.food.length == 0) {
        isvalidvalue = false;
      }
      var cal = 0;
      (this.labreportForm.value.food).forEach(el => {
        el.value = el.value * el.quantity;
        cal = el.value + cal;
      });
      innerdata = {
        "type": this.labreportForm.value.type,
        "on_date": logdate,
        "food_data": JSON.stringify(this.labreportForm.value.food),
        "calories": cal.toString()
      }
    }

    if (this.defaultleftoption == 'Water') {
      if (this.labreportForm.value.water_unit == 'glass') {
        if ((this.labreportForm.value.quantity < 1) || (this.labreportForm.value.quantity > 10) || (this.labreportForm.value.quantity == null)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Water Value Entered,valid range is [1-10glass]', 'OK');
          return;
        }
      }
      else if (this.labreportForm.value.water_unit == 'ml') {
        if ((this.labreportForm.value.quantity < 20) || (this.labreportForm.value.quantity > 5000) || (this.labreportForm.value.quantity == null)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Water Value Entered,valid range is [20-5000mL]', 'OK');
          return;
        }
      } else if (this.labreportForm.value.water_unit == 'bottle') {
        if ((this.labreportForm.value.quantity < 0.5) || (this.labreportForm.value.quantity > 20) || (this.labreportForm.value.quantity == null)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Water Value Entered,valid range is [0.5-20bottle]', 'OK');
          return;
        }
      }
      innerdata = {
        "quantity": this.labreportForm.value.quantity,
        "unit": this.labreportForm.value.water_unit,
        "track_id": timestamp,
        "on_date": logdate
      }
      labjson = {
        'water': innerdata
      };
    }

    if (this.defaultleftoption == 'Blood Pressure') {
      if (((this.labreportForm.value.systolic_blood_pressure < 30) || (this.labreportForm.value.systolic_blood_pressure > 300))) {
        this.systemService.showSnackBar('Invalid Systolic Value ,valid range is [30-300 mmHG]', 'OK');
        return;
      } if((this.labreportForm.value.diastolic_blood_pressure < 30) || (this.labreportForm.value.diastolic_blood_pressure > 300)){
        this.systemService.showSnackBar('Invalid Diastolic Value ,valid range is [30-300 mmHG]', 'OK');
        return;
      }if(((this.labreportForm.value.heart_rate < 20) || (this.labreportForm.value.heart_rate > 200)) &&(this.labreportForm.value.heart_rate)){
        this.systemService.showSnackBar('Invalid Heart Rate Value ,valid range is [20-200 bpm]', 'OK');
        return;
      } if (this.labreportForm.value.systolic_blood_pressure < this.labreportForm.value.diastolic_blood_pressure) {
            isvalidvalue = false;
            this.systemService.showSnackBar('Systolic Value must be greater than Diastolic Value', 'OK');
            return;
          } else {
            innerdata = {
              "systolic_blood_pressure": this.labreportForm.value.systolic_blood_pressure,
              "diastolic_blood_pressure": this.labreportForm.value.diastolic_blood_pressure,
              "systolic_blood_pressure_unit": this.labreportForm.value.systolic_blood_pressure_unit,
              "diastolic_blood_pressure_unit": this.labreportForm.value.diastolic_blood_pressure_unit,
              "heart_rate": this.labreportForm.value.heart_rate,
              "heart_rate_unit": "bpm",
              "on_date": logdate,
              "tag": this.labreportForm.value.tag
            }
            labjson = {
              'blood_pressure': innerdata
            };
          }
    }

    if (this.defaultleftoption == 'Infusion Site') {
      let infusion_site_summary = this.commonService.isHTML(this.labreportForm.value.infusion_site_summary);
      if(infusion_site_summary  === true){
        this.labreportForm.value.infusion_site_summary.patchValue('')
        return;
      }
      if (logdate == null) {
        isvalidvalue = false;
      }
      innerdata = {
        "infusion_site": this.labreportForm.value.infusion_site,
        "site_change_reason": this.labreportForm.value.site_change_reason,
        "reminder_days": this.labreportForm.value.reminder_days,
        "track_id": timestamp,
        "logged_by": 'HC',
        "on_date": logdate,
        "start_date": logdate,
        "infusion_site_summary": this.labreportForm.value.infusion_site_summary,
        "image_ids": this.InfusionImage,
      }
      labjson = {
        'infusionsite': innerdata
      };
    }

    var scope = this;
    if (isvalidvalue == false) {
      scope.showerror = true;
    } else {
      scope.showerror = false;
      this.showButtonLoader = true;
      if (this.defaultleftoption == 'Blood Pressure') {
        this.bodydata = {
          systolic: innerdata.systolic_blood_pressure,
          systolic_unit: innerdata.systolic_blood_pressure_unit,
          diastolic: innerdata.diastolic_blood_pressure,
          diastolic_unit: innerdata.diastolic_blood_pressure_unit,
          heart_rate: innerdata.heart_rate,
          heart_rate_unit: innerdata.heart_rate_unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey,
          tag: this.labreportForm.value.tag
        };
      }
      else if (this.defaultleftoption == "Weight") {
        this.bodydata = {

          quantity: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey,
          tag: this.labreportForm.value.tag
        };
      }
      else if (this.defaultleftoption == "Peak Flow") {
        this.bodydata = {
          measure: innerdata.measure,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          log_type: innerdata.type,
          longitude: innerdata.longitude,
          latitude: innerdata.latitude,
          location_name:this.removeHTMLtags(innerdata.location_name),
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Blood Sugar") {
        let ai = {
          type: null,
          subtype: null
        };
        if (innerdata.type == "pre_breakfast") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'breakfast';
              ai.subtype = 'pre meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'breakfast';
              ai.subtype = 'pre meal';
            }
          }
        }
        if (innerdata.type == "pre_lunch") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'lunch';
              ai.subtype = 'pre meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'lunch';
              ai.subtype = 'pre meal';
            }
          }

        }
        if (innerdata.type == "pre_dinner") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'dinner';
              ai.subtype = 'pre meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'dinner';
              ai.subtype = 'pre meal';
            }
          }

        }
        if (innerdata.type == "Breakfast") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'breakfast';
              ai.subtype = 'post meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'breakfast';
              ai.subtype = 'post meal';
            }
          }

        }
        if (innerdata.type == "pre_snacks") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'snacks';
              ai.subtype = 'pre meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'snacks';
              ai.subtype = 'pre meal';
            }
          }

        }
        if (innerdata.type == "post_snacks") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'snacks';
              ai.subtype = 'post meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'snacks';
              ai.subtype = 'post meal';
            }
          }

        }
        if (innerdata.type == "Lunch") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'lunch';
              ai.subtype = 'post meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'lunch';
              ai.subtype = 'post meal';
            }
          }

        }
        if (innerdata.type == "Dinner") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'dinner';
              ai.subtype = 'post meal';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'dinner';
              ai.subtype = 'post meal';
            }
          }

        }
        if (innerdata.type == "Fasting") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'Fasting';
              ai.subtype = '';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'Fasting';
              ai.subtype = '';
            }
          }

        }
        if (innerdata.type == "Random") {
          if (innerdata.bloodSugar_unit === 'mmol/L') {
            if (innerdata.value < 0.5 || innerdata.value > 35) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [0.5-35mmol/L]', 'OK');
              return;
            } else {
              ai.type = 'random';
              ai.subtype = '';
            }
          } else if (innerdata.bloodSugar_unit === 'mg/dL') {
            if (innerdata.value < 10 || innerdata.value > 650) {
              isvalidvalue = false;
              this.showButtonLoader = false;
              this.systemService.showSnackBar('Invalid Blood Sugar Value Entered, Valid range is [10-650mg/dL]', 'OK');
              return;
            } else {
              ai.type = 'random';
              ai.subtype = '';
            }
          }

        }

        this.bodydata = {

          value: innerdata.value,
          blood_sugar_log_type: ai.subtype,
          meal_type: ai.type,
          unit: innerdata.bloodSugar_unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Water") {
        this.bodydata = {
          quantity: innerdata.quantity,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Meal") {
        this.bodydata = {
          value: innerdata.calories,
          food_data: innerdata.food_data,
          log_date: innerdata.on_date,
          meal_type: innerdata.type,
          patient_id_data_fk: this.data.dataKey,
          is_suggestion: "false",
          image_ids: "[]"
        };
      }
      else if (this.defaultleftoption == "Activity") {
        this.bodydata = {
          value: innerdata.value,
          activity_type: innerdata.type,
          quantity: innerdata.quantity,
          unit: 'mins',
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Body Parameters: Temperature") {
        this.bodydata = {
          patient_id_data_fk: innerdata.patient_id_data_fk,
          value: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.log_date
        };
      }
      else if (this.defaultleftoption == "Body Parameters: Hip waist ratio") {
        this.bodydata = {
          patient_id_data_fk: innerdata.patient_id_data_fk,
          hip: innerdata.hip,
          waist: innerdata.waist,
          hip_unit: innerdata.hip_unit,
          waist_unit: innerdata.waist_unit,
          ratio: innerdata.ratio,
          log_date: innerdata.log_date
        };
      }
      else if (this.defaultleftoption == "Infusion Site") {
        this.bodydata = {
          infusion_site: innerdata.infusion_site,
          site_change_reason: innerdata.site_change_reason,
          reminder_days: innerdata.reminder_days,
          patient_id_data_fk: this.data.dataKey,
          log_date: innerdata.on_date,
          start_date: innerdata.on_date,
          infusion_site_summary: innerdata.infusion_site_summary,
          image_ids: this.InfusionImage,
        };

      }


      if (this.defaultleftoption == 'Blood Sugar') {
        this.defaultleftoption = 'bloodSugar';
      }
      else if (this.defaultleftoption == 'Blood Pressure') {
        this.defaultleftoption = 'bloodPressure';
      }
      else if (this.defaultleftoption == 'Peak Flow') {
        this.defaultleftoption = 'peakFlow'
      }
      else if (this.defaultleftoption == 'Infusion Site') {
        this.defaultleftoption = 'infusionsite'
      }
      else if (this.defaultleftoption == "Body Parameters: Temperature") {
        this.defaultleftoption = 'bodytemperature'
      }
      else if (this.defaultleftoption == "Body Parameters: Hip waist ratio") {
        this.defaultleftoption = 'hipwaist'
      }

      this.bodydata['logged_by'] = "hc";
      this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid')


      this.UserdataService.postLabReport(this.defaultleftoption, this.bodydata, this.data.dataKey).pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.isloading = false;
          this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      ).subscribe(
        (data) => {
          scope.dialog.closeAll();
          this.isloading = false;
          //  this.sendMqttRequest('Diary');
          this.systemService.showSnackBar("Diary Value added.", "OK");
          this.rawdata = data;
          this.showButtonLoader = false;
          this.sendMqttmsg();
        })
    }

  }

  onSelectFile(event) {
    this.file = event.target.files
  }

  getInfusionSite() {
    let data = {
      "start_date": "",
      "end_date": "",
      "patient_id_data_fk": this.selectedUserID.toString()
    }
    this.userDetailService.getInfusionSitedata(data)
      .subscribe(
        (res: any) => this.onSuccessInfusionDetails((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  onSuccessInfusionDetails(data) {
    this.infusionSiteData = data;
    if (data.length <= 0) {
      this.firstInfusion = true;
    }
   
    // if (this.pageno == 1 || this.pageno == undefined) {
    //   this.alltriggers = data;
    // }
    // else {
    // for (var k = 0; k < data.length; k++) {
    //   this.infusionSiteData.push(data[k]);
    // }
    // }
  }
  async updateInfusionSite() {
    var datum = this.labreportForm.value.on_date
    let arrayVal: any = [];
    this.InfusionImage = [];
    var date = new Date();
    var logdate = null;
    logdate = datum.toISOString()
    arrayVal = [];
    for (let i = 0; i < this.file.length; i++) {
      arrayVal.push(this.file[i])
    }
    if (arrayVal) {
      for (let i = 0; i < arrayVal.length; i++) {
        arrayVal.forEach(el => {
          if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.video) && (el.type.includes('video'))) {
            this.isinvalid = true;
          }
          else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
            this.isinvalid = true;
          }
          else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.image) && (el.type.includes('image'))) {
            this.isinvalid = true;
          }
          else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.document) && (el.type.includes('pdf'))) {
            this.isinvalid = true;
          }
          else {
            this.isinvalid = false;
            this.isloadingpres = false;
          }
        });
        if (this.isinvalid != true) {
          if (this.labreportForm.status == 'INVALID') {
            this.isinvalid = true;
            this.isloadingpres = false;
          }
          else {
            this.isinvalid = false;
            this.allfiles = arrayVal[i];
            this.uploadedfiletype = (this.allfiles.type).split('/')[1];

            if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
              this.isloadingpres = false;
              this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
            }
            else {
              var imageinfo = {
                detailed_text: "Infusion Image",
                title: "Infusion Image",
                type: this.uploadedfiletype,
              }
              this.isloadingpres = true;
              var scope = this;
              var last = false;
              var imagedetails;
              await this.processfiles(scope, imageinfo, imagedetails, logdate);
            }
          }
        }


      }

    }

  }
  async processfiles(scope, imageinfo, imagedetails, logdate) {
    if (imageinfo) {
      scope.isloadingpres = true;
      imagedetails = "";
      imagedetails = imageinfo;
      if (scope.allfiles.type.includes('pdf')) {
        scope.Infusion_type = 'infusion_pdf';
      }
      if (scope.allfiles.type.includes('image')) {
        scope.Infusion_type = 'infusion_image';
      }
      await this.uploadthumbnail('', scope.allfiles, imagedetails, '', logdate);
    }
  }

  // sendMqttRequest(type){
  //   var obj = {
  //     link : "https://wellthy.page.link/B421",
  //     // link : "This is for preview. it will be remove after screen refresh.",
  //     type : type
  //   }
  //   this.appContext.changeMessage(obj);
  // }

  submitInfusionSite() {
    var datum = this.labreportForm.value.on_date;
    var logdate = null;
    logdate = datum.toISOString()
    let bodydata = {
      "infusion_site": this.labreportForm.value.infusion_site,
      "site_change_reason": this.labreportForm.value.site_change_reason,
      "reminder_days": this.labreportForm.value.reminder_days,
      "patient_id_data_fk": this.data.dataKey,
      "log_date": logdate,
      "logged_by": 'hc',
      "start_date": logdate,
      "infusion_site_summary": this.labreportForm.value.infusion_site_summary,
    }
    this.UserdataService.addInfusionSite(bodydata).pipe(
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      this.dialog.closeAll();
      this.isloading = false;
      this.systemService.showSnackBar("Infusion site updated.", "OK");
    });
  }

  temperatureUnit(event: any) {
    if (event == 'Celsius') {
      this.tempplaceholderData = "Enter Celsius unit (0 - 50 degrees Celsius)"
    } else {
      this.tempplaceholderData = "Enter Fahrenheit unit (50 - 150 degrees)"
    }
  }

  hipCircumference(event: any) {

    this.aspectRatio = false;
    this.hipcircumferenceVal = event.target.value;
    if (this.BodyParamUnitData && (this.waistcircumferenceVal != null || this.waistcircumferenceVal != undefined) && (this.hipcircumferenceVal != null || this.hipcircumferenceVal != undefined)) {
      this.calculateAspectRatio(this.waistcircumferenceVal, this.hipcircumferenceVal)
    }
  }

  waistCircumference(event: any) {
    this.aspectRatio = false;
    this.waistcircumferenceVal = event.target.value;
    if (this.BodyParamUnitData && (this.waistcircumferenceVal != null || this.waistcircumferenceVal != undefined) && (this.hipcircumferenceVal != null || this.hipcircumferenceVal != undefined)) {
      this.calculateAspectRatio(this.waistcircumferenceVal, this.hipcircumferenceVal)
    }
  }



  BodyParamUnit(event: any) {
    this.BodyParamUnitData = event;
  }


  calculateAspectRatio(waist, hip) {
    var waist = waist;
    var hip = hip;
    return this.aspectRatio = (waist / hip).toFixed(2);
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
  acitivityInputType(value: any) {
    if (value === 'mins') {
      this.showActivityDatePicker = true
      this.showActivitykcal = false
      this.acivity_input_type = value;
    } else if (value === 'Kcal') {
      this.showActivitykcal = true
      this.showActivityDatePicker = false
      this.acivity_input_type = value;
    }

  }
  openFromIcon(timepicker: { open: () => void }) {
    if (!this.formControlItem.disabled) {
      timepicker.open();
    }
  }
  onValueChange(e) {
    console.log(e.target.value);
  }

  onClear($event: Event) {
    this.formControlItem.setValue(null);
  }

  checkHourLength(e, input) {
    const keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';
    const newValue = input.value + (isNaN(keyValue) ? '' : keyValue.toString());
    const match = newValue.match(numberOnlyPattern);

    if (+newValue > 11 || !match || newValue === '') {
      e.preventDefault();
    }
  }
  checkMinutesLength(e, input) {
    const keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';
    const newValue = input.value + (isNaN(keyValue) ? '' : keyValue.toString());
    const match = newValue.match(numberOnlyPattern);

    if (+newValue > 59 || !match || newValue === '') {
      e.preventDefault();
    }
  }
  checkKcalLength(e, input) {
    const keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';
    const newValue = input.value + (isNaN(keyValue) ? '' : keyValue.toString());
    const match = newValue.match(numberOnlyPattern);

    if (+newValue > 2000 || !match || newValue === '' || newValue === '0') {
      e.preventDefault();
    }
  }
  getMetCode(type: any) {
    switch (type) {
      case 'Walking': {
        return 4.5;
      }
      case 'Running': {
        return 7.0
      }
      case 'Cycling': {
        return 7.5;
      }
      case 'Swimming': {
        return 7.0;
      }
      case 'Gym': {
        return 5.0
      }
      case 'Yoga': {
        return 4.0;
      }
      case 'Aerobic': {
        return 7.3;
      }
      default: {
        return 0.0;
      }
    }
  }
  convertMinToKcal(type) {
    let kcalValue = this.getMetCode(type) * (this.userWeight * ((this.labreportForm.value.hr_duration * 60) + this.labreportForm.value.min_duration) / 60)
    return kcalValue;
  }
  convertKcalToMin(type) {
    let minValue = (this.labreportForm.value.kcal_duration * 60) / (this.userWeight * this.getMetCode(type))
    return minValue;
  }
  getUserDetails() {
    this.userDetailService.getUserProfileDetails(this.appContext.selectedChatUser.getValue()).pipe().subscribe(data => {
      data = (data as any).data;
      this.userWeight = data.weight;
    });
  }
  sendMqttmsg(){
    let convID = (this.appContext.selectedChatUserRowData.getValue() as any).chatMetadata[0].conv_id;
    let datenew;
    let healthCoachId = localStorage.getItem('scarletuserid');
    var hccode = 'H' + (healthCoachId).toString();
    var ucode = 'U' + (this.selectedUserID).toString();
    datenew = new Date();
    datenew = datenew * 1;
    let newmessagedata = {
      conversationId: convID,
      sender: hccode,
      clientId: this.socketService.clientid,
      participants: [hccode,  ucode],
      body: '',
      attachment: {
          link: '',
          type: "deeplink",
          value: 'Diary',
          additionalData: 'Diary Refresh',
      },
      trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
      mimeType: 'text/plain',
      type: 'deeplink',
      replyTo: '',
  };
  this.socketService.emitNewMessage(newmessagedata).subscribe(data => {
  });        
  }
}
export enum upload_type {
  infusion_pdf,
  infusion_image
}
