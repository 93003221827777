<form [formGroup]="dataForm" (ngSubmit)="changePassword()" autocomplete="off" validate>
    <h4 class="title">Change Password</h4>
    <div class="close-icon">
        <i class="material-icons mat-dialog-close" (click)="closeDialog()">
            <img src="assets/img/dls/icons/cross.svg" alt="">
        </i>
    </div>
    <div class="row">
        <!-- <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Old Password</mat-label>
            <input matInput type="password" autocomplete="new-password" formControlName="old_password" placeholder="Old Password">
        </mat-form-field> -->
        <br>
        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>New Password</mat-label>
            <input matInput minlength="4" type="password" autocomplete="new-password" formControlName="new_password"
                placeholder="New Password">
            <mat-error>New password</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Confirm Password</mat-label>
            <input matInput minlength="4" type="password" autocomplete="new-password" formControlName="confirmPassword"
                placeholder="Confirm Password">
        </mat-form-field>
        <p class="col-md-12 pswdnote"><span class="pswdnoteheading">Note:</span> Minimum length of new password should
            be 8, atleast one uppercase, one lowercase and one number.</p>
    </div>
    <div class="row">
        <div class="col-md-2">
            <button mat-flat-button color="primary" class="centerbtn" style="margin:15px 0;"
                [disabled]="!dataForm.valid">Done</button>
        </div>

    </div>

</form>