<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/modulehome/moduleedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Module
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-12">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Module Name</mat-label>
                                <input matInput placeholder="Enter the name" formControlName="title" required>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Description</mat-label>
                                <input matInput placeholder="Enter the description" formControlName="detailed_text">
                            </mat-form-field> -->

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Condition</mat-label>
                                <mat-select formControlName="conditions" required multiple>
                                    <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                        [value]="condition.id">
                                        {{condition.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Complexity Level</mat-label>
                                        <mat-select formControlName="complexity_level" required>
                                            <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                            <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                            <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Chapters Count</mat-label>
                                        <mat-select formControlName="chapter_count"
                                            (selectionChange)="createChapterOptions($event.value)" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="clearfix"></div>

                            <div class="col-md-12 retrysection" *ngIf="chaptercount">
                                <div class="row">
                                    <p class="customlabel">Chapters</p>
                                    <div formArrayName="chapter_data">
                                        <div *ngFor="let chapterdata of dataForm.controls.chapter_data.controls; let j=index;"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <mat-form-field appearance="outline"
                                                class="full-width-formfield featureimage">
                                                <mat-label>Chapter {{j+1}}</mat-label>
                                                <mat-select formControlName="chapterdata"
                                                    (selectionChange)="selectedOptions($event.value,j,0)" required
                                                    #singleSelectChapter>
                                                    <mat-option>
                                                        <ngx-mat-select-search [clearSearchInput]="false"
                                                            [placeholderLabel]="'Search Item'"
                                                            [noEntriesFoundLabel]="'No matching records found'"
                                                            [formControl]="searchmat" ngModel
                                                            (ngModelChange)="modelChangedChapters($event,j,0)"
                                                            ngDefaultControl>
                                                            <div
                                                                class="mat-select-search-custom-header-content notranslate">
                                                                <i class="material-icons">
                                                                    search
                                                                </i>
                                                            </div>
                                                        </ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option class="camelcased"
                                                        *ngFor="let chapter of filteredChapters[j] | async"
                                                        [value]="chapter.id" [disabled]="disableOptions(0,chapter.id)">
                                                        {{chapter.title}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <h3 class="commontitleprimary">Unlock Time</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Days</mat-label>
                                        <mat-select formControlName="days" required>
                                            <!-- (selectionChange)="createChapterOptions($event.value)" -->
                                            <mat-option  *ngFor="let num of numDays" [value]="num" class="notranslate">{{num}}</mat-option>
                                            <!-- <mat-option value="0">0</mat-option>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option> -->
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Hours</mat-label>
                                        <input type="number" matInput min="0" max="24" placeholder="Hours"
                                            formControlName="hours" required>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Minutes</mat-label>
                                        <input type="number" matInput matInput min="0" max="60" placeholder="Minutes"
                                            formControlName="minutes" required>
                                    </mat-form-field>
                                </div>
                            </div>




                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Tags</mat-label>
                                <mat-select formControlName="tags" required multiple #singleSelectTag>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i>
                                            </div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option class="camelcased select-tags-active"
                                        *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                        {{tag.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col-md-12 leftpad40">
                            <div class="clearfix"><br /></div>
                            <button mat-flat-button color="primary" class="centerbtn" style="margin-top:-22px;"
                                [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Module</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</mat-drawer-container>