<!-- <ng-template>
  
</ng-template> -->
<!-- <ng-template #loading>
  <div class="loading">
    <mat-spinner></mat-spinner>
  </div>
</ng-template> -->
<div class="empty-card" *ngIf="emptySubject$ | async;">
  <!-- <img src="../../../../../../assets/img/Crane.gif">
  <br />
  <span>Please select user first.</span> -->
</div>
<div class="nodata-card" *ngIf="loadingError$ | async;">
    <div style="position: absolute;top: 50%;transform: translateY(-50%);width: 100%;">  
      <span>Something went wrong</span>
    </div>
  </div>
<div class="loader-plate" *ngIf="loadingSubject$ | async;">
    <div style="position: absolute;top: 50%;transform: translateY(-50%);width: 100%;">  
        <mat-progress-spinner
        class="spinner-margin"
        color="accent"
        mode="indeterminate"
        diameter="50">
      </mat-progress-spinner>
    </div>
</div>