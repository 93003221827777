import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';


@Component({
  selector: 'app-alltriggers',
  templateUrl: './alltriggers.component.html',
  styleUrls: ['./alltriggers.component.scss']
})
export class AlltriggersComponent implements OnInit {
  langid: any;
  token: any;
  patientid: any;
  verifiedtrigger: any = [];
  allloggedtrigger: any = [];
  showdata: boolean = false;
  device: any;
  s3url: any = environment.s3url;
  alldata: any;

  constructor(private userdetailsService: UserdetailsService, public fb: FormBuilder) { }


  ngOnInit(): void {
    var scope = this;
    this.userdetailsService.getAlltriggers("146")
      .subscribe(data => {
        data = (data as any).data;
        scope.showdata = true;
        this.alldata = data;
        data.verified_triggers.forEach(el => {
          this.verifiedtrigger.push(el);
        });
        data.unverified_triggers.forEach(el => {
          this.allloggedtrigger.push(el);
        });
      })
  }
}
