<mat-drawer-container class="sidenav-container" autosize>

  <app-adminleftnav></app-adminleftnav>
  <div class="sidenav-content">

    <app-adminsectionleftnav></app-adminsectionleftnav>
    <div class="mainrightcontainersecondary">
      <app-breadcrumb route="admin/publishrequests"></app-breadcrumb>
      <div class="col-md-12">
        <h3 class="commontitleprimary">
          Request Approval
        </h3>
      </div>

      <div class="col-md-12">
        <form>
          <div class="form-group has-feedback has-search">
            <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
              placeholder="Search Requests here">
            <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
          </div>
        </form>
      </div>


      <div class="col-md-12">
        <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
          (scrolled)="getNextSet()" [scrollWindow]="false">
          <div class="spinner-container" *ngIf="showloader">
            <app-loader showLoader="{{true}}"></app-loader>
          </div>

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="widthfluid bordergrey list-table">
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Request Title </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                <span [class]="(element?.is_approved_by == null) ?'circle gray':element.is_approved ?'circle green' : 'circle red'"></span>
                {{!element.title?'-':element.title}}
              </td>

            </ng-container>

            <ng-container matColumnDef="level">
              <th mat-header-cell *matHeaderCellDef> Level </th>
              <td mat-cell *matCellDef="let element"> {{element?.level_data_fk?.title}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> Description </th>
              <td mat-cell *matCellDef="let element"> {{element?.description | truncate:[40, '...'] }} </td>
            </ng-container> -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                {{element?.is_approved_by == null ? 'Pending' : element.is_approved ? 'Approved' : 'Rejected'}}
                <div *ngIf="element?.approved_by">by {{element?.approved_by?.name}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell class="camelcased" *matCellDef="let element">
                <div class="action-button-row">
                  <button mat-button (click)="viewDetails(element.id)" disableRipple class="no-hover-effect" tooltip="View description" >
                    <!-- View Description -->
                    <img src="assets/img/dls/admin-table/Icon_View_description.svg" alt="Wellthy">

                  </button>
                  <button mat-button (click)="viewComment(element.id)" disableRipple class="no-hover-effect" tooltip="View comment" >
                    <!-- View Comment -->
                    <img src="assets/img/dls/admin-table/Icon_View_comment.svg" alt="Wellthy">
                  </button>

                  <!-- <button mat-button disableRipple class="no-hover-effect" [disabled]="element.is_approved == true"
                    [ngClass]="{'my-class': element.is_approved == true}"
                    (click)="openStaging(element.level_data_fk.id)">
                   Start Staging 
                    <img src="assets/img/dls/admin-table/Icon_Start_staging.svg" alt="Wellthy">

                  </button> -->

                  <button mat-button disableRipple class="no-hover-effect" *ngIf="element.is_approved ==false"
                    (click)="openStaging(element.level_data_fk.id)" tooltip="Start staging" >
                    <img src="assets/img/dls/admin-table/Icon_Start_staging.svg" alt="Wellthy">
                  </button>

                  <button mat-button disableRipple class="no-hover-effect" *ngIf="element.is_approved ==true"
                    [ngClass]="{'my-class': element.is_approved == true}">
                    <img src="assets/img/dls/admin-table/Icon_Start_staging.svg" alt="Wellthy">
                  </button>


                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button [disabled]="element.is_approved_by != null" mat-menu-item
                      (click)="openCheckMultilingialDialog(element.id)">
                      <img src="assets/img/dls/icons/actiontable/Icon_Check multilingual.svg" style="height: 28px;"
                        alt="">
                      <span>Check Multilingual</span></button>

                    <button [disabled]="element.is_approved_by != null" mat-menu-item
                      class="no-hover-effect active-green" [ngClass]="{'approve-btn': element.is_approved == true}"
                      (click)="openApproveDialog(false,element.id)">
                      <img src="assets/img/dls/icons/actiontable/Icon_Approve.svg" style="height: 28px;" alt="">
                      <span>{{"Approve"}}</span></button>

                    <button [disabled]="element.is_approved_by != null" mat-menu-item class="active-danger last-btn"
                      [ngClass]="{'my-class': element.is_approved == true}"
                      (click)="openApproveDialog(true,element.id)">
                      <img src="assets/img/dls/icons/actiontable/Icon_Reject.svg" style="height: 28px;" alt="">
                      <span>{{"Reject"}}</span></button>
                  </mat-menu>


                </div>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row" style="margin-left: -15px;">
                      <button mat-button (click)="viewDetails(element.id)" disableRipple class="no-hover-effect">View
                        Description
                      </button>
                      <button mat-button (click)="viewComment(element.id)" disableRipple
                        class="no-hover-effect">View Comment
                      </button>

                      <button mat-button disableRipple class="no-hover-effect"
                        [ngClass]="{'my-class': element.is_approved == true}" *ngIf="element.is_approved_by == null"
                        (click)="openStaging(element.level_data_fk.id)">Start Staging</button>

                      <button *ngIf="element.is_approved_by == null" mat-button disableRipple class="no-hover-effect"
                        [ngClass]="{'approve-btn': element.is_approved == true}"
                        (click)="openCheckMultilingialDialog(element.id)">Check Multilingial</button>

                      <button *ngIf="element.is_approved_by == null" mat-button disableRipple
                        class="no-hover-effect active-green" [ngClass]="{'approve-btn': element.is_approved == true}"
                        (click)="openApproveDialog(false,element.id)">{{"Approve"}}</button>

                      <button *ngIf="element.is_approved_by == null" mat-button disableRipple style="width: auto;"
                        class="no-hover-effect active-danger last-btn"
                        [ngClass]="{'my-class': element.is_approved == true}"
                        (click)="openApproveDialog(true,element.id)">{{"Reject"}}</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
          </table>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>