<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/chapterhome/chapteredit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Chapter
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Chapter Name</mat-label>
                                <input matInput placeholder="Enter the name" formControlName="title" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Description</mat-label>
                                <input matInput placeholder="Enter the description" formControlName="detailed_text"
                                    required>
                            </mat-form-field>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Complexity Level</mat-label>
                                        <mat-select formControlName="complexity_level" required>
                                            <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                            <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                            <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Total Subparts</mat-label>
                                        <mat-select (selectionChange)="changeOptions($event.value)"
                                            formControlName="subpart_count" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="clearfix">
                            </div>
                            <div class="content-box" *ngIf="optioncount">
                                <div formArrayName="subpart_data">
                                    <div *ngFor="let option of dataForm.controls.subpart_data.controls; let j=index;"
                                        [formGroupName]="j" class="boxthekeyvalue">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p class="custom-label">Select Type Lesson/Quiz - {{j + 1}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="full-width-formfield">
                                                    <mat-label>Select Type</mat-label>
                                                    <mat-select formControlName="type"
                                                        (selectionChange)="optionType(j,$event.value)" required>
                                                        <mat-option value="lesson">
                                                            Lesson
                                                        </mat-option>
                                                        <mat-option value="quiz">
                                                            Quiz
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="full-width-formfield"
                                                    *ngIf="chapoptntype[j]=='lesson'">
                                                    <mat-label>Select Option</mat-label>
                                                    <mat-select formControlName="id"
                                                        (selectionChange)="selectedOptions($event.value,j,0)" required
                                                        #singleSelect>
                                                        <mat-option>
                                                            <ngx-mat-select-search [clearSearchInput]="false"
                                                                [placeholderLabel]="'Search Item'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                [formControl]="searchmat" ngModel
                                                                (ngModelChange)="modelChanged($event, j,0)"
                                                                ngDefaultControl>
                                                                <div class="mat-select-search-custom-header-content notranslate">
                                                                    <i class="material-icons">
                                                                        search
                                                                    </i></div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option class="camelcased"
                                                            *ngFor="let lesson of filteredLessons[j] | async"
                                                            [value]="lesson.id"
                                                            [disabled]="disableOptions(0,lesson.id)">
                                                            {{lesson.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="full-width-formfield"
                                                    *ngIf="chapoptntype[j]=='quiz'">
                                                    <mat-label>Select Option</mat-label>
                                                    <mat-select formControlName="id"
                                                        (selectionChange)="selectedOptions($event.value,j,1)" required
                                                        #singleSelectQuiz>
                                                        <mat-option>
                                                            <ngx-mat-select-search [clearSearchInput]="false"
                                                                [placeholderLabel]="'Search Item'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                [formControl]="searchmatquiz" ngModel
                                                                (ngModelChange)="modelChangedQuiz($event, j,1)"
                                                                ngDefaultControl>
                                                                <div class="mat-select-search-custom-header-content">
                                                                    <i class="material-icons">
                                                                        search
                                                                    </i></div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option class="camelcased"
                                                            *ngFor="let quiz of filteredQuizzes[j] | async"
                                                            [value]="quiz.id" [disabled]="disableOptions(1,quiz.id)">
                                                            {{quiz.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Carey card</mat-label>
                                <mat-select formControlName="carey_card_data" #singleSelectCareyCard>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmatcareycard" ngModel
                                            (ngModelChange)="modelChangedCareycards()" ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i></div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option value="">---No Value Selected---</mat-option>
                                    <mat-option class="camelcased" *ngFor="let carey of filteredCareycards | async"
                                        [value]="carey.id">
                                        {{carey.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Magazine</mat-label>
                                <mat-select formControlName="magazine_data" #singleSelectMagazine>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmatmagazine" ngModel
                                            (ngModelChange)="modelChangedMagazines()" ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i></div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option value="">---No Value Selected---</mat-option>
                                    <mat-option class="camelcased" *ngFor="let magazine of filteredMagazines | async"
                                        [value]="magazine.id">
                                        {{magazine.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                <mat-label>Select Image</mat-label>
                                <input matInput  formControlName="image_media" required
                                    readonly>
                                    <button type="button" matSuffix mat-flat-button  color="primary" (click)="openmediabox()" style="width: 100px;">{{allmedia ? 'Edit' : 'Choose'}}</button>
                            </mat-form-field>

                            <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                                <mat-checkbox value="checked" formControlName="allow_feedback"
                                    (click)="changeValue(checked)">
                                    Allow Feedback
                                </mat-checkbox>
                                <br />
                            </div>

                            <div [ngClass]="!checked ? 'hidefield' : ''">
                                <div class="col-md-12 zeropadleft zeropadright">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Feedback</mat-label>
                                        <mat-select formControlName="feedback" #singleSelectFeedback
                                            [required]="checked">
                                            <mat-option>
                                                <ngx-mat-select-search class="tagmat" [clearSearchInput]="false"
                                                    [placeholderLabel]="'Search Item'"
                                                    [noEntriesFoundLabel]="'No matching records found'"
                                                    [formControl]="searchmatfeedback" ngModel
                                                    (ngModelChange)="modelChangedFeedback()" ngDefaultControl>
                                                    <div class="mat-select-search-custom-header-content">
                                                        <i class="material-icons">
                                                            search
                                                        </i></div>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option class="camelcased" *ngFor="let fb of filteredFeedback | async"
                                                [value]="fb.id">
                                                {{fb.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Time to Finish (In seconds)</mat-label>
                                <input type="number" min="1" max="3600" matInput placeholder="Time to Finish (In seconds)"
                                    formControlName="time_to_finish" required>
                                <mat-error>
                                    Cannot be greater than 3600 sec
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Tags</mat-label>
                                <mat-select formControlName="tags" required multiple #singleSelectTag>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content">
                                                <i class="material-icons">
                                                    search
                                                </i></div>
                                        </ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option class="camelcased select-tags-active"
                                        *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                        {{tag.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Chapter</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">

                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside">
                            <div class="title-holder">
                                <div class="card">
                                    <div class="options-image">
                                        <img *ngIf="allmedia" [src]="s3url + allmedia" class="img-responsive" />
                                        <div *ngIf="!(dataForm?.value.title == '')">
                                            <div *ngIf="!allmedia" class="no-media"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-text">
                                    <div class="iphonetitle">
                                        {{dataForm?.value?.title}}
                                    </div>
                                    <div class="iphonesubtitle sub-description">
                                        {{dataForm?.value?.detailed_text}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>