import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DiffResults } from 'ngx-text-diff/lib/ngx-text-diff.model';
import 'rxjs/add/operator/map';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-leveldifference',
  templateUrl: './leveldifference.component.html',
  styleUrls: ['./leveldifference.component.scss']
})
export class LeveldifferenceComponent implements OnInit {
  public left: any;
  public right: any;
  public showdiff: boolean = false;
  public levelid: any;
  
  constructor(private browserTitle: Title, private route: ActivatedRoute, private contentService: ContentserviceService) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Level Difference');
  }

  ngOnInit(): void {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.levelid = params.id;
      this.getLevelDifference(this.levelid);
    })
  }


  // To Delete Level
  // ----------------------------

  getLevelDifference(id) {
    this.contentService.getLevelDifference(id)
    .subscribe(
      (res : any) => this.getLevelDifferenceSuccess((res as any).data),
    );
  }
 
  private getLevelDifferenceSuccess(data) {
    this.right = JSON.stringify(data[0].new_data, null, "\t");
    this.left = JSON.stringify(data[0].old_data, null, "\t");
    this.showdiff = true;
  }

  // ----------------------------
  
  onCompareResults(diffResults: DiffResults) {
  }

}
