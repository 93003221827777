
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PersistenceService } from 'angular-persistence';
import { AuthenticateService, SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})

export class ChangepasswordComponent implements OnInit {
  public showButtonLoader: boolean = false;
  error: boolean = false;
  public message: any;

  public dataForm = this.fb.group({
    new_password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  },
    { validator: this.checkPasswords }
  );

  public usertype:any;
  public userView: any;

  constructor(private persistenceService: PersistenceService, private authService: AuthenticateService,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    private systemService: SystemService, public fb: FormBuilder, private router: Router, private commonService: CommonserviceService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnInit() {
    this.userView = this.data.element;
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


  // To change password 
  // ----------------------------

  changePassword() { 
    var postdata = this.dataForm.value;
    postdata['user_id_data_fk']= localStorage.getItem('scarletuserid');
   postdata['source_of_password_change']= localStorage.getItem('app')
   postdata['password_changed_via']= 'Dashboard';
    delete postdata['confirmPassword'];
    this.error = false;
    if (this.hasWhiteSpace(this.dataForm.value.new_password) == false) {
      this.commonService
      .updateToBladePassword( this.userView.id, postdata, 'resetpassword')
      .subscribe(
        (res: any) => this.onStatusSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
    else {
      this.error = true;
      if(this.hasWhiteSpace(this.dataForm.value.new_password) == true)
      {
        this.message = "Enter valid password, Your password should not be empty or contain spaces";
      }
      else if(this.dataForm.value.old_password === this.dataForm.value.new_password)
      {
        this.message = "New password must be different from old passsword"
      }
      this.systemService.showSnackBar((this.message), "OK");
    }
  }

  private onStatusSuccess() {
  var scope = this;
  scope.dialog.closeAll();
    this.systemService.showSuccessSnackBar(("The password has been updated"),"OK");
  }

  // ----------------------------

}
