<div class="disclaimer">
  <h2 mat-dialog-title class="text-center">Reset Your Password</h2>
  <div class="mat-typography content">
  <p class="passwordsubtext">Choose your new password at your convinience. <br/> Great passwords use upper and lower case characters, numbers and symbols. </p>
  <div class="mat-typography content">
      <div class="col-md-4 col-md-offset-4 eropadleft">
          <mat-form-field appearance="outline" class="full-width-formfield">
              <mat-label>New Password</mat-label>
              <input matInput placeholder="Enter your new password"
                  [(ngModel)]="newpassword">
          </mat-form-field>
          <p *ngIf="this.error" class="errorcls">{{this.message}}</p>
      </div>
  </div>

</div>
<div align="center" class="accept-button text-center">
    <button mat-raised-button class="accept-btn" color="accent" (click)="changePassword()">Set Password</button>
    <!-- &nbsp;&nbsp; -->
    <!-- <button mat-stroked-button color="accent" class="accept-btn2" (click)="skip()">Skip</button> -->
  </div>