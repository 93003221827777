import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { PersistenceService } from 'angular-persistence';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable} from 'rxjs/index';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { SystemService } from 'src/app/shared/services';
import { Brodcastlistinterface } from '../models/brodcastlistinterface';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { CreatebroadcastComponent } from './createbroadcast/createbroadcast.component';
import { ViewbroadcastComponent } from './viewbroadcast/viewbroadcast.component';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss']
})

export class BroadcastComponent implements OnInit {
  public dataSource: BrodcastListDataSource;
  public selection = new SelectionModel<Brodcastlistinterface>(true, []);
  public brodcastDeletionID: string = null;
  public displayedColumns = ['name', 'type', 'message', 'created_at', 'id'];
  public searchForm: FormGroup;
  public filterValue: string = "";
  public filterList: Array<string> = [];
  public filterStatusValue: string = "All";

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private brodcastService: BroadcastService,
    private browserTitle: Title,
    public dialog: MatDialog, private persistenceService: PersistenceService, private cdRef: ChangeDetectorRef,
    private systemService: SystemService, private formBuilder: FormBuilder,private snackBar: MatSnackBar) { 
      localStorage.removeItem("notifyPatientName");
      localStorage.removeItem("selectedNotification");
    }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Broadcast');
  }

  ngOnInit() {
    this.dataSource = new BrodcastListDataSource(this.brodcastService, this.persistenceService, this, this.systemService);
    this.buildSearchForm();
    this.setSEOTags();
  }

  ngAfterViewInit() {
    /**Load the view */
    let pageIndex = (this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0);
    let pageSize = (this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 10);
    this.dataSource.getBrodcastList(pageIndex, pageSize);
    /**Detect Changes */
    this.cdRef.detectChanges();
  }

  handleRowSelection(row: Brodcastlistinterface): void {
    /**View Broadcast */
    this.viewbroadcast(row);
  }

  paginateBrodcast($event): void {
    /**Register paginator */
    this.dataSource.paginateBrodcast();
    /**Detect Changes */
    this.cdRef.detectChanges();
  }

  applyFilter(searchText: string): void {

    this.dataSource.filter(searchText);
  }

  applyFilterType(filterType: string): void {
    this.filterStatusValue = filterType;
    this.dataSource.filterType(filterType);
  }

  buildSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      searchText: []
    });
  }


  // To Delete Broadcast
  // ----------------------------

  // deleteBrodcast(brodcastID: string, $event: any): void {
  //   $event.stopPropagation();
  //   var answer = confirm("Are you sure to delete the broadcast?");
  //   if (answer) {
  //     this.brodcastDeletionID = brodcastID;
  //     this.brodcastService.deleteBrodcast(brodcastID)
  //     .subscribe(
  //       (res: any) => this.deleteBrodcastSuccess(brodcastID),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  //   }
  // }

  // private deleteBrodcastSuccess (brodcastID) {
  //   this.brodcastDeletionID = null;
  //   this.dataSource.deleteBrodcastEntry(brodcastID);
  //   this.systemService.showSnackBar("Broadcast Deleted Successfully", "OK");
  // }

  // ----------------------------



   // To delete Broadcast 
  // ----------------------------

  delete(brodcastID) {
    this.brodcastService.deleteBrodcast(brodcastID)
    .subscribe(
      (res : any) => this.onDelete(brodcastID),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDelete(brodcastID) {
    this.brodcastDeletionID = null;
    this.dataSource.deleteBrodcastEntry(brodcastID);
  }

  // ----------------------------
  

  // To open dialog box 
  // ----------------------------

  deleteBrodcast(brodcastID: string, $event: any): void {
    $event.stopPropagation();
    let dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.brodcastDeletionID = brodcastID;
        this.delete(brodcastID);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Broadcast Deleted Successfully', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  // ----------------------------


  opencreatebroadcast(): void {
    let dialogRef = this.dialog.open(CreatebroadcastComponent, {
      width: '60%',
      height: '60%'
    });
    dialogRef.afterClosed().subscribe(result => {
      let pageIndex = 0;
      let pageSize = (this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 10);
      this.dataSource.reload(pageIndex, pageSize);
    });
  }

  viewbroadcast(selectedRow: Brodcastlistinterface): void {
    let dialogRef = this.dialog.open(ViewbroadcastComponent, {
      width: '60%',
      height: '60%',
      data: selectedRow
    });
    dialogRef.afterClosed().subscribe(result => {
      //Do Nothing
    });
  }


}

export class BrodcastListDataSource implements DataSource<Brodcastlistinterface> {
  public brodcastSubject$ = new BehaviorSubject<Brodcastlistinterface[]>(null);
  public brodcastFilterData: Brodcastlistinterface[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loadingError$ = new Subject<boolean>();
  public loading$ = this.loadingSubject.asObservable();
  public dataLength: number = 0;
  public filterValue: string = "All";

  constructor(private brodcastService: BroadcastService, private persistenceService: PersistenceService, public parentScope: BroadcastComponent, private systemService: SystemService) { }

  connect(collectionViewer: CollectionViewer): Observable<Brodcastlistinterface[]> {
    return this.brodcastSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.brodcastSubject$.complete();
    this.loadingSubject.complete();
  }

  reload(pageIndex, pageSize): void {
    this.getBrodcastList(pageIndex, pageSize);
  }
  length(): number {
    return this.brodcastSubject$.getValue().length;
  }

  filter(filterValue: string): void {

    let data = this.brodcastFilterData;
    let searchedData;
    if (filterValue == "") {
      searchedData = this.brodcastFilterData;
    } else {
      searchedData = data.filter((row) => {
        return (JSON.stringify(row).toLowerCase().includes(filterValue.toLowerCase()));
      });
    }
    this.paginateBrodcast(searchedData);
  }

  data(): Brodcastlistinterface[] {
    this.brodcastSubject$.subscribe(data => {
      return this.brodcastSubject$.getValue();
    });
    return this.brodcastSubject$.getValue();
  }

  filterType(filterType: string): void {
    this.filterValue = filterType;
    let data = (filterType == "All" ? this.brodcastFilterData : this.brodcastFilterData.filter(data => { return data.type == filterType; }));
    this.paginateBrodcast(data);
  }

  getCounts(inputKey): number {
    if (this.brodcastFilterData && this.brodcastFilterData.length > 0) {
      return (inputKey == "All" ? this.brodcastFilterData.length : this.brodcastFilterData.filter(user => user[inputKey]).length);
    } else {
      return 0;
    }
  }


  // To Get Broadcast ListS
  // ----------------------------

  getBrodcastList(pageIndex?: number, pageSize?: number): void {
    // this.loadingSubject.next(true);
    this.brodcastService.getAllBrodcast()
    .subscribe(
      (res: any) => this.getBroadcastSuccess(pageIndex, pageSize,(res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getBroadcastSuccess(pageIndex, pageSize, data) {
    data = data ? data : [];
      this.brodcastFilterData = data.reverse();
      /**Paginate Data */
      this.loadingSubject.next(false)
      var scope = this;
      setTimeout(() => {
        scope.paginateBrodcast(data, pageIndex, pageSize);
        /**Generate Filters */
        scope.generateFilters(data);
      }, 200);
  }

  // ----------------------------

  paginateBrodcast(data?: Brodcastlistinterface[], pageIndex?: number, pageSize?: number): void {
    data = (this.filterValue == "All" ? data : data.filter(datai => { return datai.type == this.filterValue; }));
    if (data) {
      data.sort(function (a, b) { return b.created_at - a.created_at; });
      if (!pageIndex) { pageIndex = 0 };
      if (!pageSize) { pageSize = 0 };
      pageIndex = ((pageIndex || pageIndex == 0) ? pageIndex : this.parentScope.paginator.pageIndex);
      pageSize = ((pageSize || pageSize == 0) ? pageSize : this.parentScope.paginator.pageSize);
      data = (data ? data : this.brodcastFilterData);
      var length = data.length;
      //data = data.slice(startIndex, endIndex);
      this.brodcastSubject$.next(data);
      /**Page Length */
      this.dataLength = length;
    } else {
      this.brodcastSubject$.next([]);
      /**Page Length */
      this.dataLength = 0;
    }

  }

  generateFilters(data: Array<Brodcastlistinterface>): void {
    let filterList = data.map(brodcast => {
      return brodcast.type
    });
    /**Remove duplicates form array */
    filterList = Array.from(new Set(filterList));
    filterList = filterList.filter(function (el) {
      return el != null;
    });
    this.parentScope.filterList = filterList;
  }

  deleteBrodcastEntry(brodcastID: string) {
    let data = this.brodcastFilterData.filter(brodcast => { return brodcast.id != brodcastID });
    /**Delete from subject and list */
    this.brodcastFilterData = data;
    this.paginateBrodcast(data);
    /**Change Filters */
    this.generateFilters(this.brodcastFilterData);
    /*Reset text value also */
    this.parentScope.searchForm.controls['searchText'].setValue("");
  }

}