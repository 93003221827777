<!-- <p>{{showcomment}}</p> -->
<div class="col-md-12 p-1">
    <div style="float: left">
    <h3 class="commontitleprimary hc-status-head">
       View comments
    </h3>
    </div>
    <div class="close-icon" style="float:right">
        <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()" class="img-responsive close-modal" alt="">
      </div>
</div>
<div class="dialog-content" style="margin-top: 35px;padding: 20px;">
<p *ngIf="showcomment?.comment == null ">No Comments Found</p>
<p *ngIf="showcomment?.comment">{{showcomment?.comment}}</p>
</div>