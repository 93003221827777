<div class="col-md-8 toptrasnferbar" style="left: 31%;position: fixed;">
    <div class="col-md-12">
        <h3 class="trasnfertitle">Transfer user</h3>
    </div>
    <div class="col-md-6">
        <div class="clearfix"><br /><br /><br /></div>
        <form [formGroup]="userTransferForm" (ngSubmit)="startUserTransfer()" novalidate>
            <div *ngIf="loadingSubject | async as hcData; else dropdownLoader">
                <mat-form-field appearance="outline" [hideRequiredMarker]="false" class="full-width-formfield">
                    <mat-label>Select Health Coaches</mat-label>
                    <mat-select placeholder="Select Health Coaches" formControlName="healthcoach">
                        <mat-option *ngFor="let hc of hcFilterData" value="{{hc.id}}" class="notranslate">
                            {{hc.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-template #dropdownLoader>
                <app-loader showLoader="{{true}}"></app-loader>
            </ng-template>
            <div class="clearfix"><br /></div>

            <button [disabled]="!userTransferForm.valid" mat-flat-button color="primary" class="centerbtn">Transfer
                user</button>
        </form>
    </div>
</div>