import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-generalqrbottomsheet',
  templateUrl: './generalqrbottomsheet.component.html',
  styleUrls: ['./generalqrbottomsheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GeneralqrbottomsheetComponent implements OnInit {
  public allCampaigns: any;
  public showqrlink: boolean = false;
  public imgurl: any;
  public campaignid: any;
  constructor(private browserTitle: Title, @Inject(MAT_DIALOG_DATA) public data: any, private cd: ChangeDetectorRef,
   private dialogRef: MatDialogRef<GeneralqrbottomsheetComponent>, public fb: FormBuilder, private commonService: CommonserviceService) { }
  
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  openLink(event: MouseEvent): void {
    this.dialogRef.close();
    event.preventDefault();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Doctor');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getCampaignData();
  }


  // To Get Campaign Data
  // ----------------------------

  getCampaignData() {
    this.commonService.getAllCampaign(this.data.id)
    .subscribe(data => {
      var scope = this;  
      setInterval(() => {
        scope.allCampaigns = (data as any).data;
        scope.cd.markForCheck();
      }, 100);
    })
  }

  // ----------------------------


  // To Get Campaign Data
  // ----------------------------

  dataObject(campaignid) {
    var scope = this;
    // setInterval(() => {
      this.campaignid = campaignid;
      var dataset = '\"campaign_id\":\"' + campaignid +'\"'+ encodeURIComponent('&') + '\"user_code\":\"' + scope.data.code + '\"';
      this.imgurl = "https://chart.googleapis.com/chart?cht=qr&chl=" + dataset + "&chs=232x232&choe=UTF-8&chld=H%7C2";
      scope.showqrlink = true;
      scope.cd.markForCheck();
    // }, 1000);
  }

  // ----------------------------


  //  To Download QR Code
  // ----------------------------

  downloadQR(data) {
    var canvas: HTMLCanvasElement = this.canvas.nativeElement;
    var context = canvas.getContext('2d');
    let pdfName = data;
    let scope = this;
    setTimeout(() => {
      let img1 = new Image();
      let img2 = new Image();
      let img3 = new Image();
      img1.crossOrigin = "Anonymous";
      img1.onload = function () {
        var cW = 300;
        var cH = 300;
        canvas.width = cW;
        canvas.height = cH
        var dataset = '\"campaign_id\":\"' + scope.campaignid +'\"'+ encodeURIComponent('&') + '\"user_code\":\"' + scope.data.code + '\"';
        img2.src = "https://chart.googleapis.com/chart?cht=qr&chl=" + dataset + "&chs=545x545&choe=UTF-8&chld=H%7C2";
        img2.crossOrigin = "Anonymous";
      };
      img2.onload = function () {
        img3.src = "assets/img/Wellthy_QR_Logo_2.png";
        img3.crossOrigin = "Anonymous";
        img3.onload = function () {
          context.drawImage(img2, 0, 0, 300, 300);
          context.drawImage(img3, 120, 120, 65, 65);
          let fileName = pdfName + '_' + scope.data.name+'_'+scope.data.code+ ".png";
          scope.download(canvas, fileName);
        }
      };
      img1.src = 'assets/img/Doctor_Stand.png';
    }, 2000);
  }
  
  download(canvas, filename) {
    var lnk = document.createElement('a'), e;
    lnk.download = filename;
    lnk.href = canvas.toDataURL("image/png;base64");
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent("click", true, true, window,
        0, 0, 0, 0, 0, false, false, false,
        false, 0, null);
      lnk.dispatchEvent(e);
    } else {
      lnk.click();
    }
  }

  // ----------------------------


  closeModal(): void {
    this.dialogRef.close();
  }

}

