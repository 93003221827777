import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';


@Component({
  selector: 'app-pumpadd',
  templateUrl: './pumpadd.component.html',
  styleUrls: ['./pumpadd.component.scss']
})
export class PumpaddComponent implements OnInit {
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedUserID: any;
  bodydata: any;
  public isinvalid: boolean = false;
  private allfiles: any;
  public uploadedfiletype: any;
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
  prescription_type: any;
  responseUrl: any;
  ROAType: any;
  public filestoUpload = [];
  public allpumpdata: any;
  private responsethumbUrl: any;
  upload_prescription_type: any;
  upload_type: any = upload_type;
  upload_doc_type: any;
  private resdata: any;
  public imageFileSize: any;
  public image_path: any;
  public loadingval: any = 5;
  public isloading: boolean = false;
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public morrow: any = new Date();
  pumpSelection: any;
  public dataForm = this.fb.group({
    patient_id_fk: [''],
    pump_id_fk: [null, Validators.required],
    serial_number: [null],
    media_ids: [[]],
    expiry_date: [null],
    created_by_fk: [''],
    pump_route_type: [null, Validators.required],
    custom_days: [null],
    time: [[]],
    is_other_pump: [false],
    other_pump_name: [''],
    manufacturer: [''],
    reason_for_change: [''],
    reason_for_addition: [''],
    label_for_remodulin: [''],
    date_of_change: [''],
    hourly_dose: [''],
    time_of_infusion: [''],
    temperature: [''],
    occulsions: [''],
    errors: [''],
    battery_status: [''],
    positive_pressure_maintained: [''],
    t: [null],
    pump_status: [null, Validators.required],
  });
  isloadingpres: boolean = false;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public demoForm = new FormGroup({
    files: this.filesControl,
  });
  fullPumpData: any;
  pumpStatus: any;
  selected_pump_status: any;

  max_reserve_pump_count: any;
  min_current_pump_count: any;
  min_reserve_pump_count: any;
  max_current_pump_count: any;
  newCurrentPump: any;
  temp_pump_id: any;
  selectedBrandTitle: any;
  selectedBrandID: any;
  can_be_reserve: boolean = true;
  add_pump_status: any[];
  selected_add_pump: string;
  selectNewCurrentPumpDropDown: any;
  alreadyCurrentPumpExists: boolean;
  mediaUploadsize: any;

  constructor(public router: Router, private SystemService: SystemService, public fb: FormBuilder, public dialog: MatDialog, private appContext: ContextService, private userDetailService: UserdetailsService, private systemService: SystemService,
    private contentService: ContentserviceService, private commonService: CommonserviceService) { }

  ngOnInit(): void {
    this.pumpSelection = null;
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    // this.getConditions();
    this.getPumpData();
    this.getUserPumps();
    this.getPumpFlags(this.appContext.selectedChatUser.getValue());
    this.mediaUploadsize = JSON.parse(localStorage.getItem('mediaUploadSize'));

  }

  onTextChange(pump) {
    this.pumpSelection = pump.value;
  }
  getPumpFlags(selectedUserID: string) {
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      data = (data == null) ? [] : data;

      this.max_reserve_pump_count = data.patient_client_data.max_reserve_pump_count;
      this.min_reserve_pump_count = data.patient_client_data.min_reserve_pump_count;

      this.max_current_pump_count = data.patient_client_data.max_current_pump_count;
      this.min_current_pump_count = data.patient_client_data.min_current_pump_count;
    });
  }
  onStatusChange(value) {
    this.pumpStatus = value.value;
  }

  getPumpData() {
    this.contentService.getGraphQlData('{getAllPump(status:true){id,title,route_type,add_pump_status,valid_pump_status}}', false)
      .subscribe(
        (res: any) => this.onPumpDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onPumpDataSuccess(data) {
    data = (data as any).data;
    this.allpumpdata = data.getAllPump;
    let alreadyCurrentPumpExists:any;
    this.fullPumpData.forEach(element => {
     if(element.pump_status === 1 || element.pump_status === '1'){
       alreadyCurrentPumpExists =  true;
     } 
   });
   if(alreadyCurrentPumpExists){
    this.alreadyCurrentPumpExists =  true;
   }else{
    this.alreadyCurrentPumpExists =  false;
   }
  }

  getROAType(allROA) {
    this.selectedBrandID = allROA.id
    this.allpumpdata.filter(x => x.id === this.selectedBrandID);
    this.allpumpdata.filter((x) => {
      if (x.id === this.selectedBrandID) {
        this.ROAType = x.route_type;
        this.can_be_reserve =  x.can_be_reserve
        this.selectedBrandTitle =  x.title;
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  uploadObject() {
   
    this.bodydata = this.dataForm.value;

    if (this.bodydata.pump_id_fk == null && this.bodydata.pump_route_type == null) {
      return;
    }
    if (this.bodydata.pump_id_fk == null && this.bodydata.pump_route_type == "subcutaneous" && this.bodydata.custom_days == null &&
      this.bodydata.expiry_date == null && this.bodydata.t == null) {
      return;
    }
    if (this.bodydata.pump_route_type == "subcutaneous" && (this.bodydata.serial_number == null || this.bodydata.custom_days == null ||
      this.bodydata.expiry_date == null || this.bodydata.t == null)) {
      return;
    }
    else {
      if (this.bodydata.is_other_pump) {
        delete this.bodydata.pump_id_fk;
      } else {
        this.bodydata.pump_id_fk = this.bodydata.pump_id_fk;
      }
      this.bodydata.patient_id_fk = this.selectedUserID.toString();

      if (this.bodydata.pump_id_fk != null && this.bodydata.pump_route_type == "subcutaneous") {
        this.bodydata.custom_days = this.bodydata.custom_days.toString();
        const momentDate = new Date(this.dataForm.value.expiry_date);
        this.bodydata.expiry_date = moment(momentDate).format("YYYY-MM-DD");
      }
      if (this.bodydata.pump_id_fk != null && (this.bodydata.pump_route_type == "implantable" || this.bodydata.pump_route_type == "intravenous") && this.bodydata.expiry_date != null) {
        const momentDate = new Date(this.dataForm.value.expiry_date);
        this.bodydata.expiry_date = moment(momentDate).format("YYYY-MM-DD");
      }
      this.bodydata.serial_number = this.bodydata.serial_number;
      this.bodydata.is_other_pump = this.bodydata.is_other_pump.toString();
      if (this.bodydata.time_of_infusion) {
        this.bodydata.time_of_infusion = this.bodydata.time_of_infusion;
      } else {
        delete this.bodydata.time_of_infusion;
      }
      if (this.bodydata.is_other_pump) {
        this.bodydata.other_pump_name = this.bodydata.other_pump_name;
      } else {
        delete this.bodydata.other_pump_name;
      }
      if (this.bodydata.manufacturer) {
        this.bodydata.manufacturer = this.bodydata.manufacturer;
      } else {
        delete this.bodydata.manufacturer;
      }
      if (this.bodydata.reason_for_change) {
        this.bodydata.reason_for_change = this.bodydata.reason_for_change;
      } else {
        delete this.bodydata.reason_for_change;
      }
      if (this.bodydata.reason_for_addition) {
        this.bodydata.reason_for_addition = this.bodydata.reason_for_addition;
      } else {
        delete this.bodydata.reason_for_addition;
      }
      if (this.bodydata.label_for_remodulin) {
        this.bodydata.label_for_remodulin = this.bodydata.label_for_remodulin;
      } else {
        delete this.bodydata.label_for_remodulin;
      }

      if (this.dataForm.value.date_of_change) {
        const momentDate1 = new Date(this.dataForm.value.date_of_change);
        this.bodydata.date_of_change = momentDate1.toISOString();
      } else {
        delete this.bodydata.date_of_change;
      }
      if (this.bodydata.hourly_dose) {
        this.bodydata.hourly_dose = this.bodydata.hourly_dose;
      } else {
        delete this.bodydata.hourly_dose;
      }
      if (this.dataForm.value.time_of_infusion) {
        const momentDate2 = new Date(this.dataForm.value.time_of_infusion);
        this.bodydata.time_of_infusion = momentDate2.toISOString();
      } else {
        delete this.bodydata.time_of_infusion;
      }
      if (this.bodydata.temperature) {
        this.bodydata.temperature = this.bodydata.temperature;
      } else {
        delete this.bodydata.temperature;
      }
      if (this.bodydata.occulsions) {
        this.bodydata.occulsions = this.bodydata.occulsions;
      } else {
        delete this.bodydata.occulsions;
      }
      if (this.bodydata.errors) {
        this.bodydata.errors = this.bodydata.errors;
      } else {
        delete this.bodydata.errors;
      }
      if (this.bodydata.positive_pressure_maintained) {
        this.bodydata.positive_pressure_maintained = this.bodydata.positive_pressure_maintained;
      } else {
        delete this.bodydata.positive_pressure_maintained;
      }
      if (this.bodydata.battery_status) {
        this.bodydata.battery_status = this.bodydata.battery_status;
      } else {
        delete this.bodydata.battery_status;
      }
      let alreadyCurrentPumpExists:any;
       this.fullPumpData.forEach(element => {
        if(element.pump_status === 1 || element.pump_status === '1'){
          alreadyCurrentPumpExists =  true;
        } 
      });
      if(!alreadyCurrentPumpExists){
        if (this.bodydata.pump_status) {
          this.bodydata.pump_status = '1';
        } else {
          delete this.bodydata.pump_status;
        }
      }else{
        if (this.bodydata.pump_status) {
          this.bodydata.pump_status = this.bodydata.pump_status;
        } else {
          delete this.bodydata.pump_status;
        }
      }
     
      if (this.bodydata.expiry_date == "Invalid date") {
        this.bodydata.expiry_date = null;
      }
      const momentDate = new Date(this.dataForm.value.expiry_date);
      this.bodydata.expiry_date = moment(momentDate).format("YYYY-MM-DD");
      if(this.bodydata.t!=null)
      {
        var time = this.convertIsoDatetime(this.bodydata.t);
      }
      if (time != "Invalid date") {
        var d = new Date(this.bodydata.t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        // Replace event.value with your date value
        this.bodydata.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
        if(this.bodydata.t==null)
        {
          this.bodydata.time=[];
        }
        delete this.bodydata.t;
      }
     
      delete this.bodydata.t;
      this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
      if (this.bodydata.custom_days == "") {
        this.bodydata.custom_days = null;
      }
      if (this.bodydata.time.length == 0) {
        this.bodydata.time = null;
      }
      if (this.demoForm.value.files != null) {
        this.demoForm.value.files.forEach(el => {
          if (((el.size / (1024 * 1024)) > this.mediaUploadsize.video) && (el.type.includes('video'))) {
            this.isinvalid = true;
          }
          else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
            this.isinvalid = true;
          }
          else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.image) && (el.type.includes('image'))) {
            this.isinvalid = true;
          }
          else if (((el.size / (1024 * 1024)) > this.mediaUploadsize.document) && (el.type.includes('pdf'))) {
            this.isinvalid = true;
          }
          else {
            this.isinvalid = false;
            // this.isloadingpres = false;
          }
        });
        if (this.isinvalid != true) {
          if (this.demoForm.status == 'INVALID') {
            this.isinvalid = true;
            //  this.isloadingpres = false;
          }
          else {
            let filename = this.demoForm.value.files[0].name;
            this.isinvalid = false;
            this.allfiles = this.demoForm.value.files;
            this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];

            if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
              // this.isloadingpres = false;
              this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
            }
            else {
              var imageinfo = {
                detailed_text: 'pump',//this.dataForm.value.name,
                title: 'pump',//this.dataForm.value.name,
                type: this.uploadedfiletype,
              }

              // this.isloadingpres = true;
              var scope = this;
              var last = false;
              var imagedetails;
              var loadingvalcalculation = (100 / (this.allfiles.length * 3));
              processfiles(0);
              function processfiles(i) {
                if (imageinfo && last == false) {
                  imagedetails = "";
                  imagedetails = imageinfo.title[i];

                  var path = 'healthcoach/media';

                  var newfile = null;
                  var newname = (+ new Date()) + localStorage.getItem('scarletuserid') + '.' + scope.allfiles[i].name.split('.').pop()
                  newfile = new File([scope.allfiles[i]], newname);


                  scope.contentService.getS3URL(path, scope.allfiles[i].type, scope.allfiles[i].size, newfile.name)
                    .subscribe(
                      (data) => {
                        scope.responseUrl = data;
                        scope.imageFileSize = scope.allfiles[i].size;
                        scope.loadingval = scope.loadingval + loadingvalcalculation;
                        scope.contentService.uploadToS3(newfile, scope.responseUrl.data.signed_request)
                          .subscribe(
                            (data) => {
                              scope.loadingval = scope.loadingval + loadingvalcalculation;

                              if ((i + 1) == scope.allfiles.length) {
                                last = true;
                              }

                              scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imageinfo, data => {
                                if (data) {
                                  scope.loadingval = scope.loadingval + loadingvalcalculation;
                                  if (i + 1 == scope.allfiles.length) {
                                    scope.bodydata.media_ids = JSON.stringify(scope.filestoUpload);
                                    scope.pumpStatusChangeType(scope.bodydata.pump_status);
                                    scope.isloading = false;
                                    scope.systemService.showSuccessSnackBar(filename + " is uploaded and saved", "Ok");
                                  }
                                  else {
                                    processfiles(i + 1)
                                  }
                                }
                                else {
                                  processfiles(i + 1)
                                }
                              });
                            }, (error) => {
                              scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                            })
                      })
                }
              }
              if (!imageinfo) {
                // this.isloading = false;
              }
            }
          }
        }
      } else {
        this.bodydata.media_ids = JSON.stringify(this.bodydata.media_ids);
        this.pumpStatusChangeType(this.bodydata.pump_status);
        
      }
    }
    // this.bodydata == null;
  }

  uploadthumbnail(path, ele, imageinfo, cb) {

    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('pump', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {

                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else if (ele.type.match('pdf')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.setAttribute('crossorigin', 'anonymous');
        tempImg.src = '/assets/img/pdficon.jpg';
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);

          scope.commonService.getS3URL('pump_image', thtype, myFile.size, 'image.png')
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);

    }
    else {
      this.isloadingpres = true;
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }


    function saveIttoDB(path, size, type, thumbpath, imageinfo) {
      if (scope.allfiles[0].type.includes('pdf')) {
        scope.upload_prescription_type = upload_type[0];
        scope.upload_doc_type = 'document';
      }
      if (scope.allfiles[0].type.includes('image')) {
        scope.upload_prescription_type = upload_type[1];
        scope.upload_doc_type = 'gallary';
      }
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, scope.upload_prescription_type, scope.upload_doc_type)
        .subscribe(
          (data) => {
            scope.resdata = data;
            scope.image_path = scope.resdata.data.path;
            scope.dataForm.reset();
            scope.demoForm.reset();
            scope.isloadingpres = false;
            scope.filestoUpload.push(scope.resdata.data.id);
            if (scope.resdata.status == 201) {
              // scope.userDetailService.addPump(scope.bodydata).pipe(
              //   delay(100),
              //   catchError((error: any) => {
              //     scope.loadingError$.next(true);
              //     scope.isloadingpres = true;
              //     return throwError(error);
              //   }),
              //   finalize(() => { scope.loadingSubject$.next(false); })
              // ).subscribe(data => {
              //   scope.closeDialog();
              //   scope.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");

              // })
            }
            else {
              scope.isloadingpres = true;
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          })
    }
  }

  dataURItoBlobb(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  sendMqttRequest(type) {
    var obj = {
      link: "https://wellthy.page.link/B421",
      type: type
    }
    this.appContext.changeMessage(obj);
  }

  convertIsoDatetime(d) {
    var date = new Date(d);

    // var UTCdate = date.toUTCString()
    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // var getHr = date.getUTCHours()
    // var getMin = date.getUTCMinutes()
    // var getTime = getHr +":"+getMin
    // 
    //  return getTime.toString();
    const number = moment(date).format("HH:mm");

    // return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return number;
  }
  getUserPumps() {
    let data = {
      "patient_id_fk": this.appContext.selectedChatUser.getValue().toString()
      // "patient_data_fk": (this.appContext.selectedChatUser.getValue()).toString()

    }
    this.userDetailService.getPumps(data).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);

        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {

      data = (data as any).data;
      this.fullPumpData = data;
      this.fullPumpData = this.fullPumpData.filter(a => a.is_deleted == false);
      this.selectNewCurrentPumpDropDown = this.fullPumpData.filter(pump => pump.pump_status !== 1)

    });

  }
  selectActivePump(event: any) {
    console.log('event' + JSON.stringify(event));
  }
  pumpStatusChangeType(user_selected_pump_status: any) {
    //  ******  Current  Status ********
    if (user_selected_pump_status === 1 || user_selected_pump_status === "1") {
      let currentPumpSize = this.fullPumpData.filter(x => x.pump_status === 1).length;
      if(currentPumpSize === 0 || currentPumpSize === '0'){
             // add current pump
             this.userDetailService.addPump(this.bodydata).pipe(
              delay(100),
              catchError((error: any) => {
                this.loadingError$.next(true);
                //  this.isloadingpres = true;
                return throwError(error);
              }),
              finalize(() => { this.loadingSubject$.next(false); })
            ).subscribe(data => {
              this.closeDialog();
              this.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");
            })
      }
      if (currentPumpSize >= this.min_current_pump_count && currentPumpSize < this.max_current_pump_count && currentPumpSize !== 0) {
        // add current pump
        this.userDetailService.addPump(this.bodydata).pipe(
          delay(100),
          catchError((error: any) => {
            this.loadingError$.next(true);
            //  this.isloadingpres = true;
            return throwError(error);
          }),
          finalize(() => { this.loadingSubject$.next(false); })
        ).subscribe(data => {
          this.closeDialog();
          this.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");
        })
      } else {
      // there is already current pump matching max count 
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let newCurrentpump: any;
      let formObject;
      let customDiablogHeader: any;
       // check for current and make it as disabled
       for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
          if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
            customDiablogHeader = 'Already have an current pump. Do you want ' +''+this.bodydata.other_pump_name +''+' to be current pump?';
          }else{
            customDiablogHeader = 'Already have an current pump. Do you want ' +''+ this.selectedBrandTitle +''+ ' to be current pump?';
          }
         
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              newCurrentpump = userfullpumpdata[i].id;
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.userDetailService.addPump(this.bodydata).pipe(
                    delay(100),
                    catchError((error: any) => {
                      this.loadingError$.next(true);
                      return throwError(error);
                    }),
                    finalize(() => { this.loadingSubject$.next(false); })
                  ).subscribe(data => {
                    this.closeDialog();
                    this.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");
                  })
                }));
              const a = document.createElement('a');
              a.click();
              a.remove();
            } else {
              this.dialog.closeAll();
            }
          });
        }
      }
      }
    }
    // ******  Reserve  Status ********
    else if (user_selected_pump_status === 2 || user_selected_pump_status === "2") {
      //check if there is reserve/Current and max and min count 
      let reservePumpSize = this.fullPumpData.filter(x => x.pump_status === 2).length;
      if (reservePumpSize >= this.min_reserve_pump_count && reservePumpSize < this.max_reserve_pump_count) {
      // add reserve pump
      this.userDetailService.addPump(this.bodydata).pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false); })
      ).subscribe(data => {
        this.closeDialog();
        this.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");
      })
      } else {
      // there is already reserve pump matching max count 
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let newCurrentpump: any;
      let formObject;
      let customDiablogHeader: any;
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 2 || userfullpumpdata[i].pump_status === "2") {
          if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
            customDiablogHeader = 'Already have an reserve pump. Do you want make ' +''+ this.bodydata.other_pump_name +''+ ' as reserve pump?';
          }else{
            customDiablogHeader = 'Already have an reserve pump. Do you want make '+''+ this.selectedBrandTitle + ' as reserve pump?';
          }
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              newCurrentpump = userfullpumpdata[i].id;
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.userDetailService.addPump(this.bodydata).pipe(
                    delay(100),
                    catchError((error: any) => {
                      this.loadingError$.next(true);
                      return throwError(error);
                    }),
                    finalize(() => { this.loadingSubject$.next(false); })
                  ).subscribe(data => {
                    this.closeDialog();
                    this.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");
                  })
                }));
              const a = document.createElement('a');
              a.click();
              a.remove();
            } else {
              this.dialog.closeAll();
            }
          });
        }
      } 
      }
    }
    // ****** Disable Sttus  ******
    else if (user_selected_pump_status === 3 || user_selected_pump_status === "3") {
      //add disable pump
      this.userDetailService.addPump(this.bodydata).pipe(
        delay(100),
        catchError((error: any) => {
          this.loadingError$.next(true);
          return throwError(error);
        }),
        finalize(() => { this.loadingSubject$.next(false); })
      ).subscribe(data => {
        this.closeDialog();
        this.systemService.showSuccessSnackBar("Pump has been uploaded", "Ok");
      })
    }

  }
  
  buildObject(data: any, pump_type: any, user_selected_pump_status: any) {
    let tempData: any;
    tempData = { ...data };
    if (pump_type === 'current') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.valid_pump_status;
      delete tempData.add_pump_status;


      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();

      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
      let t = parseInt(tempData?.time[0].t)
      var time = this.convertIsoDatetime(t);
      var d = new Date(t);
      var date = d.toISOString()
      var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      }else{
        tempData.time = tempData.time?.toString();
      }
      tempData.pump_status = '1';
    } else if (pump_type === 'reserve') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.valid_pump_status;
      delete tempData.add_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();

      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
        let t = parseInt(tempData.time[0].t)
        var time = this.convertIsoDatetime(t);
        var d = new Date(t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      } else {
        tempData.time = tempData.time?.toString();
      }

      tempData.pump_status = '2';
    } else if (pump_type === 'disabled') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.valid_pump_status;
      delete tempData.add_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();

      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = [tempData.media_ids?.toString()];
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
      let t = parseInt(tempData.time[0].t)
      var time = this.convertIsoDatetime(t);
      var d = new Date(t);
      var date = d.toISOString()
      var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
    }else{
      tempData.time = tempData.time?.toString();
    }
    tempData.pump_status = '3';
  }
    return tempData;
  }
  onBrandChange(event:any){
    var add_pump_status =  this.allpumpdata;
    this.add_pump_status = [];
    let alreadyCurrentPumpExists:any;
    this.fullPumpData.forEach(element => {
     if(element.pump_status === 1 || element.pump_status === '1'){
       alreadyCurrentPumpExists =  true;
     } 
   });
   if(!alreadyCurrentPumpExists){
    add_pump_status.find((value)=>{
      if(event.value === value.id){
        value.add_pump_status.find((value)=>{
          if(value===1 || value==="1"){
            this.add_pump_status.push({name:'Current',value:'1'})
          }
      })
      }
  }) 
   }else{
    add_pump_status.find((value)=>{
      if(event.value === value.id){
        value.add_pump_status.find((value)=>{
          if(value===1 || value==="1"){
            this.add_pump_status.push({name:'Current',value:'1'})
          }else if(value===2  || value==="2"){
            this.add_pump_status.push({name:'Reserve',value:'2'})
           
          }else if(value===3  || value==="3"){
            this.add_pump_status.push({name:'Disabled',value:'3'})
          }
      })
      }
  })
   }
 
  }
}

export enum upload_type {
  prescription_pdf,
  pump
}




