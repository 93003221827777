<div class="profile-wrapper">
  <div class="profile-info clearfix">
    <div class="media">
      <div class="media-left media-middle">
        <img src="https://media.glassdoor.com/sqll/1596461/wellthy-therapeutics-squarelogo-1556794579439.png">
      </div>
      <div class="media-body">
        <h4>{{!userView.name?'-':userView.name}}</h4>
        <div class="profile-content">
          <p>+{{userView.country_code}} {{userView.phone}}</p>
        </div>
      </div>
    </div>


    <div class="close-icon">
      <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()" class="img-responsive close-modal" alt="">
    </div>
  </div>

  <div class="hc-details">
    <div class="text-over">Patient Details</div>
    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5>Primary Condition</h5>
          <p *ngIf="therapy_data" class="value">-</p>
          <p class="notranslate" *ngIf="!therapy_data">{{therapy_name}}</p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="location">
          <h5>Status</h5>
          <p class="text-capitalize">{{patient_status}}</p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="about-me">
          <h5>Gender</h5>
          <p>{{gender(userView.gender)}}</p>
        </div>
      </div>
    </div>

    <div class="row">


      <div class="col-md-4">
        <div class="location">
          <h5>Device ID</h5>
          <p *ngIf="device_id==null">-</p>
          <p *ngIf="device_id!=null">{{device_id}}</p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="about-me">
          <h5>Device type</h5>
          <p *ngIf="device_type==null">-</p>
          <p *ngIf="device_type!=null">{{device_type}}</p>         
        </div>
      </div>

      <div class="col-md-4">
        <div class="email">
          <h5 class="text-uppercase">App Version</h5>
          <p *ngIf="app_version==null">-</p>
          <p *ngIf="app_version!=null">{{app_version}}</p>
          <!-- <p>Android Xiaomi Redmi Note 4/ iOS iPhone 13 Pro Max 15.2</p> -->
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-4">
        <div class="location">
          <h5>Patient ID</h5>
          <p>{{userView.id}}</p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="email">
          <h5>Comorbidities</h5>
          <p *ngIf="emptycondition" class="value">-</p>
          <p *ngIf="!emptycondition">{{getConditionName(userView.conditions)}}</p>
        </div>
      </div>



      <div class="col-md-4">
        <div class="about-me">
          <!-- <h5>Device type</h5>
          <p>{{userView.device_type}}</p> -->
        </div>
      </div>

    </div>

  </div>