import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  
  constructor(private httpClient: HttpClient) {}

  get(url: string, options?): Observable<any> {
    return this.httpClient.get(url, options);
  }
  post(url: string, body, options?): Observable<any> {
    return this.httpClient.post(url, body, options);
  }
  put(url: string, body, options?): Observable<any> {
    return this.httpClient.put(url, body, options);
  }
  patch(url: string, body, options?): Observable<any> {
    return this.httpClient.patch(url, body, options);
  }
  delete(url: string, options?): Observable<any> {
    return this.httpClient.delete(url, options);
  }
}