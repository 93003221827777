<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-uuxidleftnav></app-uuxidleftnav>
        <div class="mainrightcontainersecondary">

            <app-breadcrumb route="content/staticLinks"></app-breadcrumb>
            <div class="container-scroll">
                <div class="col-md-5 uxuid-data">
                    <h2 class="commontitleprimary">Profile Deeplinks</h2>
                    <ul class="list-group">
                        <li class="list-group-item">Clinical details
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('clinical_details')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">App details
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('appdetails')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Profile Progress
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('profileprogress')">Copy
                                Link</button>
                        </li>

                        <li class="list-group-item">Personal Details
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('personal_details')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Care Team
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('careteam')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Health kit
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('healthkit')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">tandc
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('tandc')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">privacy
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('privacy')">Copy
                                Link</button>
                        </li>
                    </ul>
                </div>

                <div class="col-md-5 uxuid-data">
                    <h2 class="commontitleprimary">Logging Deeplinks</h2>
                    <ul class="list-group">
                        <li class="list-group-item">Meal
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Meal')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Activity
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Activity')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Blood sugar
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Blood Sugar')">Copy
                                Link</button>
                        </li>

                        <li class="list-group-item">Blood pressure
                            <button class="copystaticux" mat-icon-button
                                (click)="copyDeeplink('log/Blood Pressure')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Water
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Water')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Peak Flow
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Peak Flow')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Weight
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Weight')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Symptom
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Symptoms')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Lab report
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log/Lab Reports')">Copy
                                Link</button>
                        </li>
                    </ul>
                </div>
               

                <div class="clearfix"></div>

                <div class="col-md-5 uxuid-data">
                    <h2 class="commontitleprimary">Quick Action Deeplinks</h2>
                    <ul class="list-group">
                        <li class="list-group-item">Healthcoach
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('healthcoach')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Log
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('log')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">profileprogress
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('profileprogress')">Copy
                                Link</button>
                        </li>

                        <li class="list-group-item">Reminder
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('reminder')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">Medicine
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('medicine')">Copy
                                Link</button>
                        </li>
                        <li class="list-group-item">FAQ
                            <button class="copystaticux" mat-icon-button (click)="copyDeeplink('faq')">Copy
                                Link</button>
                        </li>
                    </ul>
                </div>


            </div>
        </div>
    </div>
</mat-drawer-container>