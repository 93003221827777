import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../../shared/shared.module';
import { DoctorGraphComponent } from './doctorgraph.component';

import { AlertsService } from './../../services/alerts/alerts.service';
import { DoctorDataService } from './../../services/doctor/doctor.service';
import { PatientDataService } from './../../services/patient/patient.service';
import { UserListService } from './../../services/userlist/user-list.service';
//import { DoctorGraphRoutingModule } from './doctorgraph.routing.module';

@NgModule({
  declarations: [
    DoctorGraphComponent
  ],
  imports: [
    CommonModule,
    //DoctorGraphRoutingModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService
  ],
  exports:[
    DoctorGraphComponent
  ],
  /*entryComponents: [
    DocMealBreakupComponent,
    ActivatebottomsheetComponent,
    DeeplinkbottomsheetComponent,
    QrbottomsheetComponent,
    GeneralqrbottomsheetComponent,
  ]*/
})
export class DoctorGraphModule { }
