import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ChoosemediamodalComponent } from 'src/app/content/mediamanager/choosemediamodal/choosemediamodal.component';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { environment } from 'src/environments/environment';
// import { environment } from '../../../../../environments/environment';
// import { whiteSpaceValidation } from '../../../../utility/validation';

@Component({
  selector: 'app-editmagazinecategory',
  templateUrl: './editmagazinecategory.component.html',
  styleUrls: ['./editmagazinecategory.component.scss']
})
export class EditmagazinecategoryComponent implements OnInit {

  public newitem: boolean = false;
  public dataForm = this.fb.group({
    title: ['', Validators.required],
    detailed_text: [''],
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required]
  });

  public chooseMedia: any;
  public showButtonLoader: boolean = false;
  public selfparentid: any;
  public childid: any;
  s3url: any = environment.s3url;
  public selectedoptn: any = [];
  public langid: any;
  public checked: boolean = false;
  public selfparentide: any;
  public language: any;
  public allmedia: any;
  public imageData: any;
  public chapoptntype: any = [];
  public userdata: any;
  
  constructor(private browserTitle: Title, private router: Router, private route: ActivatedRoute, public dialog: MatDialog, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService,private commonService:CommonserviceService) { }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Category');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);
      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getCategorydata(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getCategorydata(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getCategorydata(params.chid);
        }
      }
    });
  }


  // Get Media
  // -----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe(
        (res: any) => this.onGetMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // -----------------------------

  openmediabox() {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: 'image',
      }
    });

    this.chooseMedia.afterClosed().subscribe(data => {
      const formelement = <FormControl>this.dataForm.controls['image_media'];
      formelement.setValue(data.id);
      this.allmedia = data.path;
    });
  }


  // To Get Category Data
  // -----------------------------

  getCategorydata(ide) {
    this.contentService.getOneDataById(ide, "magazinecategory")
    .subscribe(
      (res: any) => this.getCategoryDataSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getCategoryDataSuccess(data) {
    this.userdata = data;
    this.dataForm.patchValue(this.userdata);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
  }

  // -----------------------------


  // Submitting Category Magazine data
  // -----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    let detailed_text = this.commonService.isHTML( this.dataForm.value.detailed_text);
    let title = this.commonService.isHTML( this.dataForm.value.title);
   
    if(detailed_text  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    }
    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    postdata.title = postdata.title.toString();
    postdata.detailed_text = postdata.detailed_text;
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'magazinecategory', true)
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.contentService
        .updateToBlade(this.childid, this.selfparentid, postdata, 'magazinecategory', true)
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onCreateSuccess(){
    this.systemService.showSuccessSnackBar(("Magazine Category has been added"), "OK");
    this.router.navigate(['/content/magazinecategory']);
  }

  private onUpdateSuccess(){
    this.systemService.showSuccessSnackBar(("Magazine Category has been updated"), "OK");
    this.router.navigate(['content/magazinecategory']);
  }

  // -----------------------------

}
