import { CreateHospitalComponent } from './../create-hospital/create-hospital.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import { ChangepasswordComponent } from '../../shared/changepassword/changepassword.component';
import { UserprofileviewComponent } from '../../shared/userprofileview/userprofileview.component';
import { ActivatebottomsheetComponent } from '../activatebottomsheet/activatebottomsheet.component';
import { ChangePasswordHistoryComponent } from '../change-password-history/change-password-history.component';
import { DeeplinkbottomsheetComponent } from '../deeplinkbottomsheet/deeplinkbottomsheet.component';
import { EditdoctorComponent } from '../editdoctor/editdoctor.component';
import { GeneralqrbottomsheetComponent } from '../generalqrbottomsheet/generalqrbottomsheet.component';
import { GenericDeeplinkComponent } from '../genericdeeplink/genericdeeplink.component';
import { QrbottomsheetComponent } from '../qrbottomsheet/qrbottomsheet.component';


@Component({
  selector: 'app-client-doctor-admin',
  templateUrl: './client-doctor-admin.component.html',
  styleUrls: ['./client-doctor-admin.component.scss'],
  animations: [detailExpandTable],
})
export class ClientDoctorAdminComponent implements OnInit {
  public expandedElement: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  s3url: any = environment.s3url;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'phone', 'email', 'doctor_type', 'action'];
  public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public localStoredData: any;

  constructor(private browserTitle: Title, private _bottomSheet: MatBottomSheet, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  viewProfile(element): void {
    const profile = this.dialog.open(UserprofileviewComponent, {
      width: '750px',
      data: {
        element: element
      }
    });

    profile.afterClosed().subscribe(result => {
    });
  }

  changepassword(element): void {
    const password = this.dialog.open(ChangepasswordComponent, {
      width: '450px',
      data: {
        element: element
      }
    });

    password.afterClosed().subscribe(result => {
      this.search();
    });
  }


  changepasswordHistory(element): void {
    localStorage.setItem("userType",'doctor')
    const history = this.dialog.open(ChangePasswordHistoryComponent, {
      width: '900px',
      data: {
        element: element
      }
    });

    history.afterClosed().subscribe(result => {
    });
 }

  createDoctor(element): void {
    const createDr = this.dialog.open(CreateHospitalComponent, {
      width: '1024px',
      height:'100%',
      data: {
        element: element
      }
    });

    createDr.afterClosed().subscribe(result => {
      this.search();
    });
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Doctor');
  }

  ngOnInit() {
    this.setSEOTags();
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  openPreActive(el): void {
    this.dialog.open(ActivatebottomsheetComponent, {
      width: '700px',
      data: {
        id: el.id,
        code: el.code,
        name: el.name
      }
    });
  }

  openDeeplink(el): void {
    this.dialog.open(DeeplinkbottomsheetComponent, {
      width: '700px',

      data: {
        id: el.id,
        code: el.code,
        name: el.name
      },
      panelClass: 'custom-bottom-css'
    });
  }

  openGenericDeeplink(el): void {
    this.dialog.open(GenericDeeplinkComponent, {
      width: '700px',

      data: {
        id: el.id,
        code: el.code,
        name: el.name
      },
      panelClass: 'custom-bottom-css'
    });
  }

  openpatientQR(el): void {
    this.dialog.open(QrbottomsheetComponent, {
      width: '700px',

      data: {
        id: el.id,
        code: el.code,
        name: el.name
      },
      panelClass: 'custom-link-css'
    });
  }

  openGeneralQR(el): void {
    this.dialog.open(GeneralqrbottomsheetComponent, {
      width: '700px',
      data: {
        id: el.id,
        code: el.code,
        name: el.name
      },
      panelClass: 'custom-link-bottom-css'
    });
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  typeFilter(fln) {
    this.filteredtypes = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.doctor_type.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }


  // Active Deactive Scarlet User 
  // ----------------------------

  isActive(status, id) {
    this.commonService.setStatus(status, 'users', id, this.localStoredData.id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;

      this.search();
    }
    else {
      this.tabulardata = [];
      this.page = 1;
      this.lastpage = 0;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // List Scarlet User 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllData(this.searchcriteria, this.page.toString(), "20", this.status, "users", "doctor")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------


  // Force Logout From the Scarlet  
  // ----------------------------

  forceLogout(id) {
    this.commonService.logoutUser(id)
      .subscribe(
        (res: any) => this.onLogoutSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onLogoutSuccess() {
    this.systemService.showSuccessSnackBar(("Selected Doctor has been Logout"), "OK");
  }

  // ----------------------------

}


export interface PeriodicElement {
  name: string;
  email: string;
  phone: number;
  type: string;
}
