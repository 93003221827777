import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-magazinecategory',
  templateUrl: './magazinecategory.component.html',
  styleUrls: ['./magazinecategory.component.scss'],
  animations: [detailExpandTable]
})
export class MagazinecategoryComponent implements OnInit {

  displayedColumns: string[] = ['title', 'description', 'updated_at','action'];
   public isactive = 'all';
  public showloader: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  searchcriteria: any;
  public page: number = 1;
  lastSearch: any;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public alltags: any;
  private myhash: any = {};
  public filteredtabulardata: any;
  activecls = 'active';
  public tagFilters: any;
  public filteredtags: any = [];
  public status: any = 'active';

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = "";
    this.page = 1;
    this.getTags();
    this.getLanguages();
    this.search();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Category');
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtags = [];
    this.tagFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  tagfilter(fln) {
    this.filteredtags = fln;
    if (fln.length == 0) {
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
    else {
      this.filteredtabulardata = this.tabulardata.filter(checkTags);
      this.dataSource = new MatTableDataSource<Element>(this.filteredtabulardata);
    }
    function checkTags(t) {
      if (t.tags.split(',').filter(arr1Item => fln.includes(arr1Item)).length > 0) {
        return true
      }
    }
  }


  // Delete FAQ Category
  // ----------------------------
  
  delete(id) {
    this.contentService.hardDelete('magazinecategory', id)
    .subscribe(
      (res: any) => this.onDeleteSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected category has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  getUsername(id) {
    return this.contentService.allusers[id];
  }



  // To get All Languages
  // ----------------------------

  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data) {
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.alltags.forEach(element => {
      this.myhash[element.id] = element.title;
    });
  }

  getTagName(tags) {
    tags = tags.split(',').map(tags => this.myhash[tags]).join(',');
    return tags;
  }

  // ----------------------------


  // Active Deactive Magazine Category
  // ----------------------------

  isActive(status, id) {
    let value;
    if(status){
      value = "Deactivate"
    }else{
      value = "Activate"
    }
    let header = "Are you sure you want to "+""+ value +" "+ "Magazine Category?";
    localStorage.setItem('modal_header',header);
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        localStorage.removeItem("modal_header");
        this.contentService.setStatus(status, 'magazinecategory', id)
      .subscribe(
        (res: any) => this.onStatusSuccess(status, id,value),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Magazine Category has been '+ value+'d', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  private onStatusSuccess(status, id, value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }


  // ----------------------------

  getChildID(itemData: any, languageID) {
    if (!isObject(itemData) || !itemData.child_data || !isArray(itemData.child_data) || !(Number(languageID) > 0)) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------


  // List Magazine Category 
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getSearchData(this.searchcriteria,this.page.toString(), "20", this.status, "magazinecategory",false)
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'updatedat': return new Date(item.updated_at);
          default: return item[property];
        }
      };
    }
  }

  // ----------------------------

}
