import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { ViewsymptommapComponent } from './viewsymptommap.component';
import { CreatesymptommapComponent } from './createsymptommap/createsymptommap.component';
const routes: Routes = [
  { path: '', component: ViewsymptommapComponent, canActivate: [ContentGuardGuard] },
  { path: 'symptom/mapping/create/:condid/:mappingid', component: CreatesymptommapComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SymptomsRoutingModule { }
