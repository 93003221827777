import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContextService as HealthCoachContextService  } from 'src/app/healthcoach/services/context.service';
import { LoaderComponent } from './loader.component';





@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports:[
    LoaderComponent
  ]
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class LoaderModule { }
