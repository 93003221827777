import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as Chartist from 'chartist';


declare var $: any;

@Component({
  selector: 'app-feedbackviewertable',
  templateUrl: './feedbackviewertable.component.html',
  styleUrls: ['./feedbackviewertable.component.scss']
})
export class FeedbackviewertableComponent implements OnInit {

  @Input() tableData;
  @Input() tableIndex;

  dataSource: any;
  displayedColumns: Array<String> = [];
  showComparisonDropdown: Boolean = false;
  allSelected: Boolean = false;
  comparisonSelected: Array<Number> = [];
  showDurationDropdown: Boolean = false;
  showLanguageDropdown: Boolean = false;
  @ViewChild(MatSort, {static: false}) sort:MatSort;

  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.tableData[this.tableIndex]);
    this.displayedColumns = ['Id', 'Name', 'Rating', 'NumRating', 'LastRatedAt', 'LastUpdatedAt', 'BounceRate'];
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.generatePieChart(this.tableData[this.tableIndex]);
    this.generateAvgChart(this.tableIndex[this.tableIndex]);
    this.generateLineChart();
  }

  generateAvgChart(chartData) {
    setTimeout(() => {
      $('#avg-chart-container .ct-series-a .ct-line').css({ 'stroke': '#42a5f5', 'stroke-width': '2px' });
      $('#avg-chart-container .ct-series-a .ct-point').css({ 'stroke': '#42a5f5', 'stroke-width': '6px' });
      $('#avg-chart-container .ct-series-a .ct-area').css('fill', '#42a5f5');
    }, 50);
    new Chartist.Line('.avg-chart-container', {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      series: [
        [5, 4, 3.2, 2, 3.6, 2, 1.4, 4, 4, 4.2, 3.8, 1.2, 1, 0.8, 3, 2]
      ]
    }, {
      fullWidth: true,
      chartPadding: {
        right: 10
      },
      lineSmooth: Chartist.Interpolation.cardinal({
        fillHoles: true,
        tension: 0.1
      }),
      low: 0,
      high: 5,
      showArea: true,
      axisX: { showGrid: false, showLabel: false },
      axisY: { showGrid: false, showLabel: false }
    },
    );
  }
  // #42a5f5

  generateLineChart() {
    setTimeout(() => {
      $('#area-chart-container .ct-series-a .ct-line').css({ 'stroke': '#66BB6A', 'stroke-width': '2px' });
      $('#area-chart-container .ct-series-b .ct-line').css({ 'stroke': '#D4E157', 'stroke-width': '2px' });
      $('#area-chart-container .ct-series-c .ct-line').css({ 'stroke': '#FFEE58', 'stroke-width': '2px' });
      $('#area-chart-container .ct-series-d .ct-line').css({ 'stroke': '#FFA726', 'stroke-width': '2px' });
      $('#area-chart-container .ct-series-e .ct-line').css({ 'stroke': '#FF7043', 'stroke-width': '2px' });
      $('#area-chart-container .ct-series-a .ct-point').css({ 'stroke': '#66BB6A', 'stroke-width': '6px' });
      $('#area-chart-container .ct-series-b .ct-point').css({ 'stroke': '#D4E157', 'stroke-width': '6px' });
      $('#area-chart-container .ct-series-c .ct-point').css({ 'stroke': '#FFEE58', 'stroke-width': '6px' });
      $('#area-chart-container .ct-series-d .ct-point').css({ 'stroke': '#FFA726', 'stroke-width': '6px' });
      $('#area-chart-container .ct-series-e .ct-point').css({ 'stroke': '#FF7043', 'stroke-width': '6px' });
    }, 50);
    var series: any[] = [
      [5, 5, 3, 1, 2, 3.8, 4, null, null, null, 2, 1.6, 3, 4, 3, 4.5],
      [5, 3.2, null, 3, null, 5, 4, 2.3, null, null, 4, null, 1.4, null, null, null],
      [null, null, null, null, 3, 4, 1, 3, 4, 3, 3.5, 4.5, 2.5, null, null, null],
      [{ x: 3, y: 3 }, { x: 4, y: 3 }, { x: 5, y: undefined }, { x: 3, y: 4 }, { x: 3.5, y: null }, { x: 4, y: 4 }, { x: 4.5, y: 4 }],
      [2, 3, 4, 3.5, 1.3, 3.5, 1.2, 4, 3.5, 5, 2, null, null, null, null, 1.4]
    ];
    var chart = new Chartist.Line('#area-chart-container', {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      series: series,
    }, {
      fullWidth: true,
      chartPadding: {
        right: 10
      },
      lineSmooth: Chartist.Interpolation.cardinal({
        fillHoles: true,
        tension: 0.1
      }),
      low: 0,
      axisY: {
        onlyInteger: true,
      }
    });
  }

  generatePieChart(chartData) {
    var data = {
      labels: ['☆☆☆☆☆ 20', '☆☆☆☆ 12', '☆☆☆ 36', "☆☆ 14", "☆ 10"],
      series: [20, 12, 36, 14, 10]
    };

    var options = {
      labelInterpolationFnc: function (value) {
        return value[0]
      }
    };

    var responsiveOptions: any[] = [
      ['screen and (min-width: 640px)', {
        chartPadding: 10,
        labelOffset: 10,
        labelDirection: 'explode',
        labelInterpolationFnc: function (value) {
          return value;
        }
      }],
      ['screen and (min-width: 1024px)', {
        labelOffset: 10,
        chartPadding: 10,
        labelDirection: 'explode',
      }]
    ];
    setTimeout(() => {
      $('#pie-container .ct-series-a .ct-slice-pie').css('fill', '#66BB6A');
      $('#pie-container .ct-series-b .ct-slice-pie').css('fill', '#D4E157');
      $('#pie-container .ct-series-c .ct-slice-pie').css('fill', '#FFEE58');
      $('#pie-container .ct-series-d .ct-slice-pie').css('fill', '#FFA726');
      $('#pie-container .ct-series-e .ct-slice-pie').css('fill', '#FF7043');
    }, 50);

    new Chartist.Pie('#pie-container', data, options, responsiveOptions);
  }

  openComparisonDropdown() {
    let currentComparisonDropdownStatus = this.showComparisonDropdown;
    this.showComparisonDropdown = !currentComparisonDropdownStatus;
  }

  addCompareVal(val) {
    this.comparisonSelected.indexOf(val) === -1 && this.comparisonSelected.push(val);
  }

  removeCompareVal(val) {
    this.comparisonSelected.indexOf(val) > -1 && this.comparisonSelected.splice(this.comparisonSelected.indexOf(val), 1);
  }

  openDurationDropdown() {
    let currentDurationDropdownStatus = this.showDurationDropdown;
    this.showDurationDropdown = !currentDurationDropdownStatus
  }
  toggleLanguageDropdown() {
    let currentLanguageDropdownStatus = this.showLanguageDropdown;
    this.showLanguageDropdown = !currentLanguageDropdownStatus;
  }


}

export interface PeriodicElement {
  Id: string;
  Name: string;
  Rating: string;
  NumRating: number;
  LastRatedAt: string;
  LastUpdatedAt: string;
}
