import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { SharedModule } from '../../shared/shared.module';
import { MediainformationComponent } from '../mediamanager/uploadmedia/mediainformation/mediainformation.component';
import { ChoosemediamodalComponent } from '../mediamanager/choosemediamodal/choosemediamodal.component';
import { ViewlottieComponent } from '../feedback/viewlottie/viewlottie.component';
import { DeletemodalComponent } from '../shared/deletemodal/deletemodal.component';

import { ContentnavbarModule } from '../shared/contentnavbar/contentnavbar.module';
import { ContentleftnavModule } from '../shared/contentleftnav/contentleftnav.module';
import { TherapysetupleftnavModule } from '../shared/therapysetupleftnav/therapysetupleftnav.module';
import { StaticUuxidComponent } from './static-uuxid.component';
import { UuxidleftnavModule } from '../shared/uuxidleftnav/uuxidleftnav.module';
import { StaticUuxidRoutingModule } from './static-uuxid.routing.module';

@NgModule({
  declarations: [

    StaticUuxidComponent,
  ],
  imports: [
    CommonModule,
    ContentnavbarModule,
    ContentleftnavModule,
    TherapysetupleftnavModule,
    UuxidleftnavModule,
    StaticUuxidRoutingModule,
    LottieAnimationViewModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
  ],
  entryComponents: [
    MediainformationComponent,
    ChoosemediamodalComponent,
    ViewlottieComponent,
    DeletemodalComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class StaticUuxidModule { }
