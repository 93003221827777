<div class="row patient-event" >
    <div class="col-md-12">
        <div class="title-wrapper">
            <h2 class="commontitleprimary text-capitalize col-md-10">
                <span style="color:var(--primary-orange);text-align: center;">Patient Event Logs</span>
            </h2>
            <div class="col-md-2" style="text-align: right;">
                <button mat-icon-button class="mat-focus-indicator create-btn mat-icon-button mat-button-base col-md-4"
                    (click)="eventRefresh();"> Refresh </button>
            </div>
            <div class="close-icon" style="margin-top: 8px;">
                <img src="assets/img/dls/icons/cross.svg" (click)="close()">
            </div>
        </div>
    </div>
    <div class="col-md-12 client-profile" *ngIf="eventData.length > '0';else nodatafound ">
        <div class="row">
            <div class="col-md-4" >
                <section class="filter_section" id="filter" [formGroup]="toppings"(scroll)="scrollPos($event);">
                    <h5 style="text-align: center;">Filter by events</h5>
                    <hr />
                    <p infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                        (scrolled)="getNextSetFilter()" [scrollWindow]="false">
                        <mat-checkbox [checked]="item.checked" (change)="onEventFilterChange($event,item.event,i)" id="{{'filter'+i}}" *ngFor="let item of dataSource;; let i = index">
                            {{item.event}} (Count - {{item.count}} )</mat-checkbox>
                    </p>
                </section>
            </div>
            <div class="col-md-8">
                <section class="filter_section" [formGroup]="toppings" infiniteScroll [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="50" (scrolled)="getNextSetEvent()" [scrollWindow]="false">
                    <h5 style="text-align: center;">User Activity Details</h5>
                    <hr>
                    <div *ngFor="let item of eventData">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-icon>mobile_friendly</mat-icon>
                            </div>
                            <div class="col-md-8">
                                {{item.event_time | date:'mediumDate'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                {{item.event_time | date:'h:mm:ss a'}}
                            </div>
                            <div class="col-md-8">
                                <b>Event Name :</b> {{item.event}}
                                <div class="row">
                                    <div class="col-md-12"
                                        *ngFor="let prop of item.event_properties | keyvalue; let i = index">
                                        <b>{{prop.key}} : </b> <span style="word-break: break-all; ">{{prop.value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <ng-template #nodatafound>
        <div class="row" style="text-align:center;overflow:hidden">
            No Data Found
        </div>
    </ng-template>
</div>