
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { Userdetailsinterface } from '../../models/userdetailsinterface';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';

@Component({
  selector: 'app-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.scss']
})

export class DeeplinkComponent implements OnInit {
  public userProgressSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public selectedUserID: string;
  public udata: any = {};
  public currentWeek: number = 1;
  public expectedWeek: number = 1;
  public totalWeeks: number = 1;
  public diameterValue: number = 0;
  public currentWeekArray: any = [];
  private tabID: number = 4;
  public subscribeObj: any;
  public selectedWeek: any;
  deeplinkdata: any;
  public userData: any;
  activecls: any = 'therapy';
  deeplinkTitle: any = ''
  deeplinkurl: any;
  
  constructor(private appContext: ContextService, private userDetailService: UserdetailsService, private dialogRef: MatDialogRef<DeeplinkComponent>) { }

  ngOnInit(): void {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getprogdetails(this.selectedUserID);
  }

  getprogdetails(selectedUserID: string) {
    var scope = this;
    this.loadingSubject$.next(true);
    /**Reinitiliase program detail subject */
    /**API Call */
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      data = data == null ? [] : data;
      // var pi = data.therapy_data.therapy_levels + '_levels';
      this.currentWeek = data.therapy_data.therapy_levels.split(',');
      this.currentWeekArray = [];
      this.currentWeekArray = [this.currentWeek];
      setTimeout(() => {
        scope.initComponentDraw(this.currentWeekArray[0][0], 0);
      }, 100);
      // this.generateWeekList(this.currentWeek);
    });
  }

  filterData(data) {
    this.activecls = data;
    if (data == 'therapy') {

      if (status === this.activecls) {
        return;
      }

      this.activecls = data;
      //this.displayedColumns.splice(0);
      this.getprogdetails(this.selectedUserID);
    }
    else if (data == 'general') {

    }
  }

  initComponentDraw(level, number) {
    /**Assign week */

    this.loadingSubject$.next(true);
    /**Reinitialise progress subject */
    this.userProgressSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    this.udata = {
      "patient_id": this.selectedUserID.toString(),
      "level_id": level.toString(),
      "bypass_suitable_module_check": "false",
      "bypass_patient_progress": "false"
    };
    this.userDetailService.getUserProgramProgress(this.udata).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      if (data) {
        data = data[0] == null ? [] : data[0];
        this.selectedWeek = number + 1;
      }
      else {
        data = null
      }
      this.userProgressSubject$.next(data);
      this.loadingSubject$.next(false);
      this.loadingSubject$.next(false);
    });
  }

  copyDeeplink(id, title) {
    this.userDetailService.createDynamicLink(id).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      
      this.deeplinkurl = data;
      this.deeplinkdata = {
        title: title,
        type: 'staticlink',
        staticshortlink: this.deeplinkurl.shortLink
      }
      if (this.deeplinkdata.title) {
        this.dialogRef.close(this.deeplinkdata);
      }
      else {
        this.dialogRef.close(this.deeplinkdata = {
          title: '',
          type: '',
          staticshortlink: ''
        });
      }
    });
  }

  copyUXID(type, uxid, title) {
    
    var uxuidlink;
    if (type == 'lesson') {
      uxuidlink = 'lesson/' + uxid;
    }
    else if (type == 'quiz') {
      uxuidlink = 'quiz/' + uxid;
    }
    else if (type == 'level') {
      uxuidlink = 'level/' + uxid;
    }
    else if (type == 'magazine') {
      uxuidlink = 'article/' + uxid;
    }
    else if (type == 'chapter') {
      uxuidlink = 'chapter/' + uxid;
    }
    else {
      uxuidlink = uxid;
    }
    this.userDetailService.createDynamicLink(uxuidlink).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      
      this.deeplinkdata = {
        uxid: uxid,
        title: title,
        shortlink: data,
        type: 'Dynamiclink',
        typeOfItem : type
        
      }
      this.dialogRef.close(this.deeplinkdata);

    });
  }
}