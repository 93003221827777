import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-editmeal',
  templateUrl: './editmeal.component.html',
  styleUrls: ['./editmeal.component.scss']
})

export class EditmealComponent implements OnInit {
  public newitem: boolean = true;
  public showButtonLoader: any;
  public selfparentid: any;
  public childid: any;
  public langid: any;
  public languagedata: any;
  public ide: any;
  public noofoptions: any;
  public addingClass: any = '';
  mealdata: any;
  mealId: any;
  public dataForm = this.fb.group({
    common_names: ["", Validators.required],
    food_group: ["", Validators.required],
    food_name: ["", Validators.required],
    meal_time: ["", Validators.required],
    unit: ["", [Validators.required]],
    unit_serving: ["", Validators.required],
    unit_measure: ["", Validators.required],
    food_class: ["", Validators.required],
    cuisine: ["", [Validators.required]],
    course_type: ["", Validators.required],
    priority1: ["", Validators.required],
    priority2: ["", Validators.required],
    priority3: ["", [Validators.required]],
    cook_time: ["", Validators.required],
    prep_time: ["", Validators.required],
    is_packaged: ["", Validators.required],
    is_processed: ["", [Validators.required]],
    is_end_product: ["", Validators.required],
    is_searchable: ["", Validators.required],
    is_recommendable: ["", Validators.required],
    recipe_source_link: ["", [Validators.required]],
    num_servings: ["", Validators.required],
    description: ["", Validators.required],
    ndb_number: [null],
    calories_calculated_for: ["", [Validators.required]],
    calories_kcal: ["", Validators.required],
    carbohydrates: ["", Validators.required],
    proteins: ["", Validators.required],
    fats: ["", [Validators.required, whiteSpaceValidation]],
    fibers: ["", Validators.required],
    total_sugar: ["", Validators.required],
    sodium: ["", Validators.required],
    added_sugar: ["", Validators.required],
    fatty_acid_saturated: ["", Validators.required],
    fatty_acid_monounsaturated: ["", [Validators.required]],
    fatty_acid_polyunsaturated: ["", Validators.required],
    potassium: ["", Validators.required],
    cholesterol: ["", Validators.required],
    gi_est: ["", [Validators.required]],
    gi_der: ["", Validators.required],
    alias_name_options_count: ["", Validators.required],
    food_alias: this.fb.array([])
  });

  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Meal');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe((params) => {
      if (params.id == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        this.getMealData(params.id);
        this.mealId = params.id;
      }
    });
  }


  generateOptions(e) {
    this.noofoptions = e;
    this.textOptions(e);
    this.addingClass = 'retrysection';

  }

  textOptions(e) {
    var control1 = <FormArray>(
      this.dataForm.controls['food_alias']
    );
    if (control1.length !== 0) {
      if (control1.length < e) {
        for (let l = control1.length; l < e; l++) {
          control1.push(this.initFbTextOptions());
        }
      }
      if (control1.length > e) {
        for (let l = control1.length; l > e; l--) {
          control1.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        control1.push(this.initFbTextOptions());
      }
    }
  }

  initFbTextOptions() {
    return this.fb.group({
      alias: ['', Validators.required],
      is_primary: ['false', Validators.required]
    });
  }

  // Get Meal Data
  // ----------------------------

  getMealData(ide) {
    this.contentService.getOneDataById(ide, "meal")
      .subscribe((res: any) => this.ongetMealDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetMealDataSuccess(data) {
    this.mealdata = data;
    this.mealdata.calories_calculated_for = this.mealdata.nutrition_data.calories_calculated_for;
    this.mealdata.proteins = this.mealdata.nutrition_data.proteins;
    this.mealdata.calories_kcal = this.mealdata.nutrition_data.calories_kcal;
    this.mealdata.fats = this.mealdata.nutrition_data.fats;
    this.mealdata.fatty_acid_saturated = this.mealdata.nutrition_data.fatty_acid_saturated;
    this.mealdata.added_sugar = this.mealdata.nutrition_data.added_sugar;
    this.mealdata.fatty_acid_monounsaturated = this.mealdata.nutrition_data.fatty_acid_monounsaturated;
    this.mealdata.fatty_acid_polyunsaturated = this.mealdata.nutrition_data.fatty_acid_polyunsaturated;
    this.mealdata.potassium = this.mealdata.nutrition_data.potassium;
    this.mealdata.cholesterol = this.mealdata.nutrition_data.cholesterol;
    this.mealdata.gi_est = this.mealdata.nutrition_data.gi_est;
    this.mealdata.gi_der = this.mealdata.nutrition_data.gi_der;
    this.mealdata.carbohydrates = this.mealdata.nutrition_data.carbohydrates;
    this.mealdata.fibers = this.mealdata.nutrition_data.fibers;
    this.mealdata.total_sugar = this.mealdata.nutrition_data.total_sugar;
    this.mealdata.sodium = this.mealdata.nutrition_data.sodium;
    this.mealdata.is_packaged = this.mealdata.is_packaged.toString();
    this.mealdata.is_processed = this.mealdata.is_processed.toString();
    this.mealdata.is_end_product = this.mealdata.is_end_product.toString();
    this.mealdata.is_searchable = this.mealdata.is_searchable.toString();
    this.mealdata.is_recommendable = this.mealdata.is_recommendable.toString();
    this.dataForm.get('alias_name_options_count').setValue(this.mealdata.food_alias.length)
    this.textOptions(this.mealdata.food_alias.length)
    this.dataForm.patchValue(this.mealdata);
  }

  // ----------------------------


  // Submitting Meal Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    if (!this.newitem) {
      postdata.id = this.mealId;
    }
    postdata.food_name = postdata.food_name;
    postdata.common_names = postdata.common_names;
    postdata.food_group = postdata.food_group;
    postdata.food_name = postdata.food_name;

    postdata.meal_time = postdata.meal_time;
    postdata.unit = postdata.unit;
    postdata.unit_option_name = postdata.unit_option_name;
    postdata.unit_measure = postdata.unit_measure;
    postdata.food_class = postdata.food_class;
    postdata.cuisine = postdata.cuisine;
    postdata.food_type = postdata.food_type;
    postdata.priority1 = postdata.priority1;
    postdata.priority2 = postdata.priority2;
    postdata.priority3 = postdata.priority3;
    postdata.cook_time = postdata.cook_time;
    postdata.prep_time = postdata.prep_time;

    postdata.is_packaged = postdata.is_packaged;
    postdata.is_processed = postdata.is_processed;
    postdata.is_end_product = postdata.is_end_product;
    postdata.is_searchable = postdata.is_searchable;
    postdata.is_recommendable = postdata.is_recommendable;
    postdata.recipe_source_link = postdata.recipe_source_link;

    postdata.num_servings = postdata.num_servings;
    postdata.description = postdata.description;
    postdata.food_alias = postdata.food_alias;
    postdata.nutrition_data = {
      'proteins': this.dataForm.value.proteins,
      'calories_calculated_for': this.dataForm.value.calories_calculated_for,
      'calories_kcal': this.dataForm.value.calories_kcal,
      'fats': this.dataForm.value.fats,
      'carbohydrates': this.dataForm.value.carbohydrates,
      'fibers': this.dataForm.value.fibers,
      'total_sugar': this.dataForm.value.total_sugar,
      'sodium': this.dataForm.value.sodium,

      'fatty_acid_saturated': this.dataForm.value.fatty_acid_saturated,
      'added_sugar': this.dataForm.value.added_sugar,
      'fatty_acid_monounsaturated': this.dataForm.value.fatty_acid_monounsaturated,
      'fatty_acid_polyunsaturated': this.dataForm.value.fatty_acid_polyunsaturated,
      'potassium': this.dataForm.value.potassium,
      'cholesterol': this.dataForm.value.cholesterol,
      'gi_est': this.dataForm.value.gi_est,
      'gi_der': this.dataForm.value.gi_der,
      "fats_unit": "g",
      'carbohydrates_unit': 'g',
      'proteins_unit': 'g',
      'fibers_unit': 'g',
      'total_sugar_unit': 'g',
      'sodium_unit': 'mg',
      'added_sugar_unit': 'g',
      'fatty_acid_saturated_unit': 'g',
      'fatty_acid_monounsaturated_unit': 'g',
      'fatty_acid_polyunsaturated_unit': 'g',
      'potassium_unit': 'mg',
      'cholesterol_unit': 'g',
      'gi_est_unit': 'abs',
      'gi_der_unit': 'abs'
    }
    delete postdata.proteins;
    delete postdata.calories_kcal;
    delete postdata.calories_calculated_for;
    delete postdata.is_end_product;
    delete postdata.fats;
    delete postdata.carbohydrates;
    delete postdata.fibers;
    delete postdata.total_sugar;
    delete postdata.sodium;
    delete postdata.fatty_acid_saturated;
    delete postdata.added_sugar;
    delete postdata.fatty_acid_polyunsaturated;
    delete postdata.fatty_acid_monounsaturated;
    delete postdata.potassium;
    delete postdata.cholesterol;
    delete postdata.gi_est;
    delete postdata.gi_der;
    delete postdata.alias_name_options_count;
    let bodydata = {
      meal_data: "[" + JSON.stringify(postdata) + "]"
    }
    if (this.newitem == true) {
      this.contentService.postToBlade(bodydata, 'meal')
        .subscribe(
          (res: any) => this.onCreateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      this.contentService.updateMealToBlade(this.mealId, bodydata, 'meal')
        .subscribe(
          (res: any) => this.onUpdateSuccess(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("meal has been added"), "OK");
    this.router.navigate(['content/meal'])
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("meal has been updated"), "OK");
    this.router.navigate(['content/meal'])
  }

  // ----------------------------

}
