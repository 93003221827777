<div class="overlay">
    <div class="Downloader">{{timeleftval}}</div>
    <div class="bgaround"></div>
</div>
<div style="width: 940px; overflow: auto;height: 100vmin;">

    <div class="">
        <div id="section1" class="wrapper sec1">
            <div class="center-row" style="margin-bottom: 20px;">
                <div class="col-md-6">
                    <div class="report-title pull-left">
                        <h4 style="padding-bottom: 10px;">Diet Plan, {{mealPlanPatientData?.createdAt | date:'MMM d, y'}}</h4>
                        <h5>Patient:
                            {{mealPlanPatientData?.name}}, {{mealPlanPatientData?.gender}}, {{mealPlanPatientData?.age}}</h5>
                        <p class="text-capitalize">Height: {{mealPlanPatientData?.height}}, Weight: {{mealPlanPatientData?.weight}}</p>
                        <p class="text-capitalize" *ngIf="mealPlanPatientData?.dietType">Diet Type: {{mealPlanPatientData?.dietType}}, Diet Preference: {{mealPlanPatientData?.dietPerference}}</p>
                        <p class="text-capitalize" *ngIf="mealPlanPatientData?.goal">Goal: {{mealPlanPatientData?.goal}}</p>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 20px;">
                    <div class="logo-section pull-right">
                        <img src="assets/img/wellthynewlogo.png"
                            style="height:50px; width: 200px" class="logo" alt="Logo"
                            alt="" />
                    </div>
                </div>

            </div>
                <div class="row">
                    <table mat-table [dataSource]="mealPlanData.mealPlan" multiTemplateDataRows class="widthfluid bordergrey list-table" style="border: 1px solid #000000 !important;">
                      <caption *ngIf="false">Diet Plan</caption>
    
                      <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
                        <th mat-header-cell *matHeaderCellDef [ngClass]="colIndex == 0 ? 'diet-plan-th-0' : 'diet-plan-th'">{{disCol}}</th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': colIndex == 0 ? 'center' : ''}" style="padding-right:5px;padding-left:5px">{{element[disCol]}}</td>
                      </ng-container>
     
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns; let even = even" [ngClass]="{evenRowWhite: even}"></tr>
    
                    </table>
                </div>
        </div>
    </div>
</div>