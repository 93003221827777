import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

  public userdata: any;

  public dataForm = this.fb.group({
    new_email: ['']
  });
  constructor(private dialogRef: MatDialogRef<ChangeEmailComponent>, public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,private commonService: CommonserviceService,private systemService: SystemService,public fb: FormBuilder) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.getUserData(this.data.id);
  }

   // Get User List
  // ----------------------------

  getUserData(ide) {
    this.commonService.getOneDataById(ide, "users")
    .subscribe(
      (res : any) => this.getUser(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }
  private getUser(data) {
    data = (data as any).data;
    this.userdata = data;
    // this.userdata.std_code = this.userdata.std_code.toString();
    // this.form.patchValue(this.userdata);
    // this.getMedia(this.userdata.profile_image);
  }

  // ----------------------------


    // Submitting Data 
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    let bodydata = {
    'new_email': this.dataForm.value.new_email,
    'user_id_data_fk': localStorage.getItem('scarletuserid')
  };
    this.commonService.updateEmail(this.data.id,bodydata)
    .subscribe(
      (res: any) => this.onEmailChange(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onEmailChange() {
    this.getUserData(this.data.id);
    this.dialog.closeAll();
    this.systemService.showSuccessSnackBar(("Email has been updated"), "OK");
  }

  // ----------------------------
}
