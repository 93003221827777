import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
// import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { UserdetailsService } from '../../../healthcoach/services/userdetails/userdetails.service';
import { DoctorDataService } from '../../services/doctor/doctor.service';
import * as moment from 'moment';

@Component({
  selector: 'app-doclabreports',
  templateUrl: './doclabreports.component.html',
  styleUrls: ['./doclabreports.component.scss']
})

export class DocLabReportsComponent implements OnInit {
  //   public userDiarySubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  //   public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public labReportData: any;
  public userHasCKD: boolean;
  public selectedUserID: string;
  //   public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //   public loadingError$ = new Subject<boolean>();
  public subscribeObj: any;
  public filterTypes: Array<string> = ["hba1c", "cholesterol", "creatinine", "hemoglobin", "serum_electrolytes", "uric_acid"];
  public filterList: Array<string> = [];
  public eligibleLogData: any = [];
  dataSource: any;
  tabulardata: any = [];
  displayedColumns = [];
  public morrow: any = new Date();
  defaultcbcoption: any;
  mainoption: any;
  public cbcunits = {
    "wbc_count": "x 10^3 /μl",
    "rbc_count": "x 10^6 /μl",
    "hct": "%",
    "mcv": "fL",
    "mch": "pg",
    "mchc": "g/dl",
    "rdw": "fL",
    "platelet_count": "x 10^3 /μl",
    "esr": "mm/hr",
    "mpv": "fL"
  }
  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required],
    selectedOption: ['', Validators.required],
    cbclabtype: []
  });
  emptyDataText: string;

  @Input() cc: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;


  constructor(private systemService: SystemService, public fb: FormBuilder, private userDetailService: UserdetailsService, private route: ActivatedRoute, private doctorService: DoctorDataService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedUserID = params.id;
      this.getUserEligibleLogs(this.selectedUserID);
      this.dataForm.reset();
      this.displayedColumns = [];
      this.dataSource = '';
    });
    // //   this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
    //     // if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
    //     //   this.selectedUserID = this.appContext.selectedChatUser.getValue();
    //       this.selectedUserID = "25";
    //       // this.initComponentDraw(this.selectedUserID);
    //       // this.initDiaryComponentDraw(this.appContext.selectedChatUser.getValue());
    //       this.getUserEligibleLogs(this.selectedUserID);
    //       this.dataForm.reset();
    // }
    //   });
  }

  convertIsoDate(d) {
    var date = new Date(d);
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString("en-US", options);
  }

  selectVal(e) {
    this.displayedColumns = [];
    this.dataSource = '';
    this.mainoption = e;
  }

  applymainfilter() {
    var postdata = this.dataForm.value;
    var startd = null;
    var endd = null;
    if(postdata.startdate && postdata.enddate){
    startd = postdata.startdate;
    endd = postdata.enddate;
    startd.setHours(0, 0, 0, 0);
    endd.setHours(23, 59, 59, 99);
  }else{
    startd = ""
    endd = ""
  }
    if (endd >= startd) {
      // startd = startd.toISOString();
      // endd = endd.toISOString();
      startd = moment(startd).add(30, 'minutes').add(5, 'hours').toISOString();
      endd = moment(endd).add(30, 'minutes').add(5, 'hours').toISOString();
      if (postdata.selectedOption == 'HbA1C') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Cholesterol') {
        this.displayedColumns = ['log_date', 'hdl', 'ldl', 'triglycerides'];
      }
      else if (postdata.selectedOption == 'Urine Creatinine') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Serum Uric Acid') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Urine Uric Acid') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Blood Urea') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Serum Creatinine') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Urine Albumin') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'eGFR') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Thyroid Function') {
        this.displayedColumns = ['log_date', 't3', 'total_t4', 'free_t4', 'tsh'];
      }
      else if (postdata.selectedOption == 'Haemoglobin') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Electrolytes') {
        this.displayedColumns = ['log_date', 'bicarbonate', 'chloride', 'potassium', 'sodium'];
      }
      else if (postdata.selectedOption == 'BNP') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'NtProBNP') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Coagulation Factor') {
        this.displayedColumns = ['log_date', 'aptt', 'fibrinogen'];
      }
      else if (postdata.selectedOption == 'Spirometry') {
        this.displayedColumns = ['log_date', 'fev1', 'fvc', 'fev1_fvc_ratio'];
      }
      else if (this.defaultcbcoption == 'cbc/wbc') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/wbcDifferential') {
        this.displayedColumns = ['log_date', 'neutrophile', 'lymphocyte', 'monocyte', 'eosinophil', 'basophil', 'immature_granulocyte'];
      }
      else if (this.defaultcbcoption == 'cbc/rbc') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/hematocrit') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/mcv') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/mch') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/mchc') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/rdw') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/plateletCount') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/esr') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (this.defaultcbcoption == 'cbc/meanPlateletVolume') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'IgE') {
        this.displayedColumns = ['log_date', 'measure'];
      }

      else if (postdata.selectedOption == 'Pulse Oximetry') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'FeNO Test/Exhaled Nitric Oxide') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == '6MWT') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'Brain natriuretic peptide(BNP)') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'NT-proBNP') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'International Normalised Ratio') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'Electrolytes') {
        this.displayedColumns = ['log_date', 'sodium', 'potassium', 'chloride', 'bicarbonate'];
      } else if (postdata.selectedOption == 'Bilirubin') {
        this.displayedColumns = ['log_date', 'serum_bilirubin', 'direct_bilirubin', 'indirect_bilirubin'];
      } else if (postdata.selectedOption == 'Iron Status') {
        this.displayedColumns = ['log_date', 'iron', 'ferritin', 'soluble_transferrin_receptor'];
      } else if (postdata.selectedOption == 'Cardiac Troponin') {
        this.displayedColumns = ['log_date', 'troponin_i', 'troponin_t', 'troponin_t_high_sensitivity'];
      } else if (postdata.selectedOption == 'Liver Enzymes') {
        this.displayedColumns = ['log_date', 'ast', 'alt', 'alp', 'ggt'];
      }


      //Abbott
      else if (postdata.selectedOption == 'Alpha-fetoprotein AFP') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Free Beta HCG - Human chorionic gonadotropin') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Estriol Free') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Inhibin A') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Pregnancy-associated plasma protein-A - PAPPa') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Urine Glucose') {
        this.displayedColumns = ['log_date', 'measure'];
      }
      else if (postdata.selectedOption == 'Urine - Protein') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'AMH Anti-Müllerian Hormone') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'Antral follicular count') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'Estradiol') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'FSH Follicle-stimulating hormone') {
        this.displayedColumns = ['log_date', 'measure'];
      } else if (postdata.selectedOption == 'Inhibin B') {
        this.displayedColumns = ['log_date', 'measure'];
      }

      /**Reinitilise user detail component */

      if (postdata.selectedOption == 'Urine Creatinine') {
        postdata.selectedOption = 'urineCreatinine';
      }
      if (postdata.selectedOption == 'Serum Uric Acid') {
        postdata.selectedOption = 'serumUricAcid';
      }
      if (postdata.selectedOption == 'Urine Uric Acid') {
        postdata.selectedOption = 'urineUricAcid'
      }
      if (postdata.selectedOption == 'Blood Urea') {
        postdata.selectedOption = 'bloodUrea';
      }
      if (postdata.selectedOption == 'Serum Creatinine') {
        postdata.selectedOption = 'serumCreatinine';
      }
      if (postdata.selectedOption == 'Urine Albumin') {
        postdata.selectedOption = 'urineAlbumin'
      }
      if (postdata.selectedOption == 'Thyroid Function') {
        postdata.selectedOption = 'thyroidFunction';
      }
      if (postdata.selectedOption == 'Coagulation Factor') {
        postdata.selectedOption = 'coagulationFactor';
      }
      if (postdata.selectedOption == 'Eosinophil Count') {
        postdata.selectedOption = 'eosinophilCount'
      }
      if (postdata.selectedOption == 'NT-proBNP') {
        postdata.selectedOption = 'NtProBNP'
      }
      if (postdata.selectedOption == 'IgE') {
        postdata.selectedOption = 'igE'
      }
      if (postdata.selectedOption == 'Electrolytes') {
        postdata.selectedOption = 'electrolytes'
      }

      if (postdata.selectedOption == 'Complete Blood Count') {


        if (this.defaultcbcoption == "cbc/meanPlateletVolume") {

          postdata.selectedOption = 'cbc/meanPlateletVolume'
        }

        if (this.defaultcbcoption == "cbc/wbcDifferential") {
          postdata.selectedOption = 'cbc/wbcDifferential'
        }
        if (this.defaultcbcoption == "cbc/rbc") {
          postdata.selectedOption = 'cbc/rbc'
        }
        if (this.defaultcbcoption == "cbc/hematocrit") {
          postdata.selectedOption = 'cbc/hematocrit'
        }
        if (this.defaultcbcoption == "cbc/mcv") {
          postdata.selectedOption = 'cbc/mcv'
        }
        if (this.defaultcbcoption == "cbc/plateletCount") {
          postdata.selectedOption = 'cbc/plateletCount'
        }
        if (this.defaultcbcoption == "cbc/mch") {
          postdata.selectedOption = 'cbc/mch'
        }
        if (this.defaultcbcoption == "cbc/mchc") {
          postdata.selectedOption = 'cbc/mchc'
        }

        if (this.defaultcbcoption == "cbc/esr") {
          postdata.selectedOption = 'cbc/esr'
        }
        if (this.defaultcbcoption == "cbc/wbc") {
          postdata.selectedOption = 'cbc/wbc'
        }

        if (this.defaultcbcoption == "cbc/rdw") {
          postdata.selectedOption = 'cbc/rdw'
        }

      }

      if (postdata.selectedOption == 'FeNO Test/Exhaled Nitric Oxide') {
        postdata.selectedOption = 'FeNO'
      }
      if (postdata.selectedOption == 'Pulse Oximetry') {
        postdata.selectedOption = 'pulseOximetry'
      }

      if (postdata.selectedOption == '6MWT') {
        postdata.selectedOption = '6mwt'
      }
      if (postdata.selectedOption == 'Cardiac Troponin') {
        postdata.selectedOption = 'cardiactroponin'
      }
      if (postdata.selectedOption == 'International Normalised Ratio') {
        postdata.selectedOption = 'inr'
      }
      if (postdata.selectedOption == 'Iron Status') {
        postdata.selectedOption = 'ironstatus'
      }
      if (postdata.selectedOption == 'Bilirubin') {
        postdata.selectedOption = 'bilirubin'
      } if (postdata.selectedOption == 'Liver Enzymes') {
        postdata.selectedOption = 'liverenzymes'
      }


      // if (postdata.selectedOption == 'Alpha-fetoprotein AFP') {
      //   postdata.selectedOption = 'afp'
      // }
      // if (postdata.selectedOption == 'Free Beta HCG - Human chorionic gonadotropin') {
      //   postdata.selectedOption = 'betahcg'
      // }
      // if (postdata.selectedOption == 'Estriol Free') {
      //   postdata.selectedOption = 'estriol'
      // }
      // if (postdata.selectedOption == 'Inhibin A') {
      //   postdata.selectedOption = 'inhibina'
      // }
      // if (postdata.selectedOption == 'Pregnancy-associated plasma protein-A - PAPPa') {
      //   postdata.selectedOption = 'pappa'
      // }
      // if (postdata.selectedOption == 'Urine Glucose') {
      //   postdata.selectedOption = 'urineglucose'
      // }
      // if (postdata.selectedOption == 'Urine - Protein') {
      //   postdata.selectedOption = 'urineprotein'
      // }

      //Abbott

      if (postdata.selectedOption == 'Alpha-fetoprotein AFP') {
        postdata.selectedOption = 'afp'
      }
      if (postdata.selectedOption == 'Free Beta HCG - Human chorionic gonadotropin') {
        postdata.selectedOption = 'betahcg'
      }
      if (postdata.selectedOption == 'Estriol Free') {
        postdata.selectedOption = 'estriol'
      }
      if (postdata.selectedOption == 'Inhibin A') {
        postdata.selectedOption = 'inhibina'
      }
      if (postdata.selectedOption == 'Pregnancy-associated plasma protein-A - PAPPa') {
        postdata.selectedOption = 'pappa'
      }
      if (postdata.selectedOption == 'Urine Glucose') {
        postdata.selectedOption = 'urineglucose'
      }
      if (postdata.selectedOption == 'Urine - Protein') {
        postdata.selectedOption = 'urineprotein'
      }
      if (postdata.selectedOption == 'AMH Anti-Müllerian Hormone') {
        postdata.selectedOption = 'amh'
      }
      if (postdata.selectedOption == 'Antral follicular count') {
        postdata.selectedOption = 'afc'
      }
      if (postdata.selectedOption == 'Estradiol') {
        postdata.selectedOption = 'estradiol'
      }
      if (postdata.selectedOption == 'FSH Follicle-stimulating hormone') {
        postdata.selectedOption = 'fsh'
      }
      if (postdata.selectedOption == 'Inhibin B') {
        postdata.selectedOption = 'inhibinb'
      }



      /**Reinitilise user detail component */
      this.doctorService.getPatientDiary(this.selectedUserID, postdata.selectedOption, startd, endd)
        .subscribe(
          (res: any) => this.onSuccesslabReportData((res as any).data),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
    else {
      this.displayedColumns = [];
      this.dataSource = '';
      this.systemService.showSnackBar("End date should be Greater than Start Date", "OK");
    }

  }

  onSuccesslabReportData(data) {
    this.tabulardata = data;
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  tclratio(a, b, c) {
    var bb = (a + (b + (0.2 * c))) / a;
    return bb.toFixed(3);
  }

  initComponentDrawFirst(selectedUserID: string) {
    /**Reinitilise user detail component */
    /**Call API */
    this.doctorService.getUserProfileDetails(selectedUserID)
      .subscribe(
        (res: any) => this.onSuccessinitComponentDrawFirst(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  onSuccessinitComponentDrawFirst(data) {
    this.userHasCKD = data.has_ckd ? data.has_ckd : false;
  }

  initComponentDraw(selectedUserID: string) {
    this.userDetailService.getUserDiary(selectedUserID)
      .subscribe(
        (res: any) => this.onSuccessinitComponentDraw((res == null) ? [] : res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  onSuccessinitComponentDraw(data) {
    this.labReportData = data;
    this.makeFilterList(data);
  }

  getUserEligibleLogs(UserID: string) {
    this.doctorService.getEligibleLogs(UserID)
      .subscribe(
        (res: any) => this.onSuccessUserEligibleLogs(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  onSuccessUserEligibleLogs(data) {
    this.eligibleLogData = data.data;
    this.filteradmincontent(this.eligibleLogData);
  }



  filteradmincontent(a) {
    this.eligibleLogData = [];
    for (var j = 0; j < a.length; j++) {
      if (a[j].type == 'lab_report') {
        this.eligibleLogData.push(a[j]);
      }
    }
  }

  applyFilter(filterType: string): void {
    filterType = filterType.toLowerCase();
    /**Check for All type */
    if (filterType == "all") {
      //   this.userDiarySubject$.next(this.labReportData);
      return;
    }
    for (let key in this.labReportData) {
      if (filterType == key.toLowerCase()) {
        let xData: any = [];
        xData[filterType] = this.labReportData[key];
        // this.userDiarySubject$.next(xData);
      }
    }
  }

  makeFilterList(labData: any): void {
    this.filterList = [];
    for (let key in labData) {
      if (this.filterTypes.includes(key)) {
        this.filterList.push(key);
      }
    }
  }

  showlabrepo(param) {
    if (this.eligibleLogData.filter(aa => aa.response_key == param).length > 0) {
      return true
    }
    else {
      return false
    }
  }

  parseString(t) {
    return t.replace("_", " ");
  }

  UpperCaseArray(input) {
    var result = input.replace(/([A-Z])/g, ' $1').trim();
    return result.split(",");
  }
  changeTextInList(type) {
    if (type == '6MWT') {
      return "6 Minutes Walk Test"
    }
    else {
      return type
    }
  }
}