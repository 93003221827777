<!-- /*==========================================
Title:  Activity
Author: Nikhil Raj A
Date:   16 Jun 2020
Last Change :  Activity Component
===========================================*/ -->
<div class="userdetailsec userdetailcontent">
  <div class="col-md-12 pt-10">
    <div class="row">
      <div class="col-md-12 topsearchsec">
        <div class="row">
          <!-- <mat-form-field appearance="outline" class="col-md-4 filteractivity">
                    <mat-label>Filter</mat-label>
                    <mat-select [(ngModel)]="typeFilters" multiple
                      (selectionChange)="typeFilter($event.value)">
                      <mat-select-trigger>
                        <span *ngIf="typeFilters?.length > 1" class="example-additional-selection">
                          (+{{typeFilters?.length}})
                        </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let types of notificationtypes" value="{{types[0]}}">{{types[1]}}</mat-option>
                    </mat-select>
                    </mat-form-field> -->
          <div class="col-md-12">
            <form>
              <div class="form-group has-feedback has-search">

                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="activity-details">
          <mat-list infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextPage()"
            [scrollWindow]="false"
            style="height: calc(100vmin - 164px);overflow-y: scroll;overflow-x: hidden;margin-top: 10px;">
            <mat-list-item *ngFor="let folder of tabulardata" style="height:80px;" (click)="changeTab(folder?.type,folder?.value,folder?.created_at)">
              <mat-icon mat-list-icon><img [src]="'assets/img/notification_coloured_icons/'+(folder?.type)+'.svg'"
                  alt="Wellthy Care" class="img-responsive" /></mat-icon>
              <div mat-line class="title"> <span
                  class="main-title text-capitalize">{{setstatus(folder?.type,folder?.value)}}</span>
                <!-- <span class="item">5PM</span> -->
              </div>
              <div mat-line class="sub-title"> {{folder.updated_at | date:'MMM d, h:mm a':'+05:30'}} </div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
</div>