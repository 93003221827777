import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MobileNumberChangeAPIService {

  public apiUrl: string = environment.serverUrl;

  constructor(
    public httpService: HttpService
  ) { }

  sendOtp(data: any){

    let url = this.apiUrl + 'otp/login/send';

    let headers = new HttpHeaders()
      .set("phone-update-request", "true");

    let body = new HttpParams()
      .set("phone", data.mobileNumber)
      .set("country_code", data.countryCode)

    return this.httpService.post(url, body,{ headers});
  }

  verifyOTP(data: any){

    let url = this.apiUrl + 'otp/login/verify';

    let body = new HttpParams()
      .set("phone", data.mobileNumber)
      .set("country_code", data.countryCode)
      .set("otp", data.otp);

    return this.httpService.post(url, body);
  }

  changeNumber(data: any) {
    let url = this.apiUrl + 'patient/phone';

    let body = new HttpParams()
      .set("patient_id", data.patientId)
      .set("requester_user_id", data.hcId)
      .set("requester_user_name", data.hcName)
      .set("new_phone", data.newNumber)
      .set("new_country_code", data.newCountryCode)
      .set("current_phone", data.currentPhoneNumber)
      .set("current_country_code", data.currentCountryCode)
      .set("reason", data.reason);

      return this.httpService.post(url, body);
  }

  getAllPhoneChangeRequest(searchTerm: string, pageNumber: number){
    let url = this.apiUrl + 'patient/data/change/list';

    let body = new HttpParams()
      .set('q', searchTerm)
      .set('limit', "20")
      .set('page', pageNumber.toString())

    return this.httpService.post(url, body);
  }

  phoneChangeRequestAction(reqId, reqStatus, adminId, adminName, adminReason){
    let url = this.apiUrl + 'patient/data/change/' + reqId;

    let body = new HttpParams()
      .set('request_status', reqStatus.toString())
      .set('admin_user_id', adminId.toString())
      .set('admin_user_name', adminName)
      .set('admin_status_reason', adminReason)

    return this.httpService.post(url, body);
  }

  getPhoneChangeLogs(customerId){
    let url = this.apiUrl + 'patient/data/change/'  + customerId;

    return this.httpService.get(url);
  }

  getCurrentUserChangeLog(customerId){
    let url = this.apiUrl + 'patient/data/change/list';

    let body = new HttpParams()
      .set('q', "")
      .set('limit', "1")
      .set('page', "1")
      .set("status", "0")
      .set("patient_id", customerId);


    return this.httpService.post(url, body);
  }

  getUserPhoneChangeLog(customerId, pageNumber){
    let url = this.apiUrl + 'patient/data/change/list';

    let body = new HttpParams()
      .set('q', "")
      .set('limit', "20")
      .set('page', pageNumber.toString())
      .set("patient_id", customerId.toString());


    return this.httpService.post(url, body);
  }

  doesPhoneNumberExist(countryCode, phone){
    let url = this.apiUrl + 'patient/exist';

    let body = new HttpParams()
      .set('country_code', countryCode)
      .set('phone', phone);

    return this.httpService.post(url, body);

  }

}
