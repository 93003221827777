import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ClientRoutingModule } from './client.routing.module';
import { EditclientComponent } from './editclient/editclient.component';
import { ClientComponent } from './client.component';
import { HcassignleftnavModule } from '../../shared/hcassignleftnav/hcassignleftnav.module';
import { AdminleftnavModule } from '../../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../../shared/adminsectionleftnav/adminsectionleftnav.module';

@NgModule({
  declarations: [
    
    ClientComponent,
    EditclientComponent,
    
  ],
  imports: [
    CommonModule,
    ClientRoutingModule,
    ImageCropperModule,
    HcassignleftnavModule,
    AdminleftnavModule,
    AdminsectionleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class ClientModule {}