
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject, Subject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../../utility/validation';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-createcodegen',
  templateUrl: './createcodegen.component.html',
  styleUrls: ['./createcodegen.component.scss']
})

export class CreatecodegenComponent implements OnInit {
  public title: any = "New Request";
  public newitem: boolean = true;
  public showButtonLoader: any;
  public ide: any;
  public allconditions: any;
  public alltherapies: any;
  public comorb: any;
  public showAllcondition: boolean = false;
  public allhc: any;
  public allclients: any;
  public allcampaigns: any;
  
  public coachaccess: any;
  public clientaccess: any;
  public campaignaccess: any;
  public doctorData: any;
  public docaccess: any;
  public showFerrerFields: boolean = false;
  public dataForm = this.fb.group({
    code_count: ['', [Validators.required, Validators.min(1)]],
    is_test_code: ['', Validators.required],
    therapy_data_fk: ['', Validators.required],
    created_by_data_fk: [''],
    has_coach_access: ['', Validators.required],
    coach_ids: [[]],
    has_client: [''],
    client_data: [''],
    has_doctor_access: [''],
    doctor_data: [''],
    has_campaign: ['', Validators.required],
    campaign_data: [''],
    request_name: ['', [Validators.required, whiteSpaceValidation]],
    show_support_phone_number: [true],
    show_support_email_id: [true],
    show_questionnaire: [false],
    show_triggers: [false],
    show_aqi: [false],
    show_bmi: [false],
    is_paid: [false],
    pump_access: [1],
    medication_access: [1],
    lab_report_access: [1],
    show_history: [false],
    show_utilities: [false],
    show_targets: [false],
    remodulin: ['none', Validators.required],
    is_remodulin: [''],
    is_nonremodulin: [''],
    use_geolocation: [false],
    show_manufacturer: [false],
    default_weight_unit: ['kg'],
    default_height_unit: ['ft'],
    profile_flags: [[]],
    chat_flags: [[]],
    dairy_flags: [[]],
    health_flags: [[]],
    pro_flags: [[]],
    show_medsurvey: [true],
    max_upload_limit: [''],
  });
  public searchmattherapy: FormControl = new FormControl();
  public searchmathc: FormControl = new FormControl();
  public searchmatclient: FormControl = new FormControl();
  public searchmatcampaign: FormControl = new FormControl();
  public searchmatdoc: FormControl = new FormControl();

  @ViewChild('singleSelectTherapy', { static: false }) singleSelectTherapy: MatSelect;
  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;
  @ViewChild('singleSelectClient', { static: false }) singleSelectClient: MatSelect;
  @ViewChild('singleSelectCampaign', { static: false }) singleSelectCampaign: MatSelect;
  @ViewChild('singleSelectDOC', { static: false }) singleSelectDOC: MatSelect;

  private _onDestroy = new Subject<void>();
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredHC: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredDoc: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCampaign: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public userid: any;
  public userdata: any;
  public optiontype: any;
  public clientdata: any;
  public hcdata: any;
  public docdata: any;
  public isNewItem: any;
  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder,
    private commonService: CommonserviceService, private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreatecodegenComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Create Code Generation');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getTherapy();
    this.getHC();
    this.getClients();
    this.getCampaigns();
    this.getDoctList();

    this.isNewItem = this.data.element;

    if (this.isNewItem == 'newsection') {
      this.newitem = true;
    }
    else {
      this.newitem = false;
      this.userid = this.isNewItem;
      this.getFormData(this.isNewItem);
    }

    // this.route.params.subscribe(params => {
    //   if (params.id == 'new') {
    //     this.newitem = true;
    //   }
    //   else {
    //     this.newitem = false;
    //     this.userid = params.id;
    //     this.getFormData(params.id);
    //   }
    // })


  }

  checkClient(client) {

    if (client == '9') {
      this.showFerrerFields = true;
    }
    else {
      this.showFerrerFields = false;
    }

  }

  // To get Doctor List
  // ----------------------------

  getDoctList() {
    this.commonService.getGraphQlData('{getAllDoctorUsers(appMode:false,status:true){code,name,id}}')
      .subscribe(
        (res: any) => this.getDocData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getDocData(data) {
    data = (data as any).data;
    this.doctorData = data.getAllDoctorUsers;
  }

  // ----------------------------


  // To get Form Data
  // ----------------------------

  getFormData(ide) {
    this.commonService.getOneDataById(ide, "activationdata")
      .subscribe(
        (res: any) => this.toGetFormData(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private toGetFormData() {
    this.dataForm.patchValue(this.userdata);
  }

  // ----------------------------

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  modelChangedTherapy() {
    this.filterTherapy();
  }

  modelChangedHC() {
    this.filterHC();
  }

  modelChangedDOC() {
    this.filterDOC();
  }

  modelChangedClient() {
    this.filterClients();
  }

  modelChangedCampaign() {
    this.filterCampaign();
  }

  private filterDOC() {
    if (!this.doctorData) {
      return;
    }
    // get the search keyword
    let search = this.searchmatdoc.value;
    if (!search) {
      this.filteredDoc.next(this.doctorData.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    // users = users.filter(obj => obj.name == filter.name && obj.address == filter.address)

    this.filteredDoc.next(
      this.doctorData.filter(bank => (bank.name.toLowerCase().indexOf(search) > -1 || bank.code.toLowerCase().indexOf(search) > -1))
    );
  }

  private filterHC() {
    if (!this.allhc) {
      return;
    }
    // get the search keyword
    let search = this.searchmathc.value;
    if (!search) {
      this.filteredHC.next(this.allhc.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredHC.next(
      this.allhc.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterCampaign() {
    if (!this.allcampaigns) {
      return;
    }
    // get the search keyword
    let search = this.searchmatcampaign.value;
    if (!search) {
      this.filteredCampaign.next(this.allcampaigns.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredCampaign.next(
      this.allcampaigns.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterTherapy() {
    if (!this.alltherapies) {
      return;
    }
    // get the search keyword
    let search = this.searchmattherapy.value;
    if (!search) {
      this.filteredTherapy.next(this.alltherapies.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.alltherapies.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  // To Get Therapy Data 
  // ----------------------------

  getTherapy() {
    this.commonService.getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.getTherapyData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getTherapyData(data) {
    data = (data as any).data;
    this.alltherapies = data.getAllTherapies;
    this.filteredTherapy.next(this.alltherapies.slice());
  }

  // ----------------------------


  // To Get HealthCoach Data 
  // ----------------------------

  getHC() {
    this.commonService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name,display_name}}')
      .subscribe(
        (res: any) => this.getHCData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getHCData(data) {
    data = (data as any).data;
    this.allhc = data.getAllHealthcoaches;
    this.filteredHC.next(this.allhc.slice());
  }

  // ----------------------------


  // To Get Client Data 
  // ----------------------------

  getClients() {
    this.commonService.getGraphQlData('{getAllClients(appMode: false, status:true){id,title}}')
      .subscribe(
        (res: any) => this.getClientData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getClientData(data) {
    data = (data as any).data;
    this.allclients = data.getAllClients;
    this.filteredClient.next(this.allclients.slice());
  }

  // ----------------------------


  // To Get Campaign Data 
  // ----------------------------

  getCampaigns() {
    this.commonService
      .getGraphQlData('{getAllCampaigns(appMode: false, status:true){id,title}}')
      .subscribe(
        (res: any) => this.getCampaignData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getCampaignData(data) {
    data = (data as any).data;
    this.allcampaigns = data.getAllCampaigns;
    this.filteredCampaign.next(this.allcampaigns.slice());
  }

  // ----------------------------

  campaignAccess(e) {
    this.optiontype = e;
    if (this.optiontype) {
      this.dataForm.addControl('campaign_data', new FormControl('', Validators.required));
    }
    else {
      this.dataForm.removeControl('campaign_data');
    }
  }

  clientAccess(e) {
    this.clientdata = e;
    if (this.clientdata) {
      this.dataForm.addControl('client_data', new FormControl('', Validators.required));
    }
    else {
      this.dataForm.removeControl('client_data');
      this.showFerrerFields = false;
    }
  }

  HCAccess(e) {
    this.hcdata = e;
    if (this.hcdata) {
      this.dataForm.addControl('coach_ids', new FormControl([], Validators.required));
    }
    else {
      this.dataForm.removeControl('coach_ids');
    }
  }

  doctorAccess(e) {
    this.docdata = e;
    if (this.docdata) {
      this.dataForm.addControl('doctor_data', new FormControl([], Validators.required));
    }
    else {
      this.dataForm.removeControl('doctor_data');
    }
  }


  // Submitting New Activation Code Data
  // ----------------------------

  dataObject() {
    var newarray = [];
    var postdata = this.dataForm.value;
    postdata.code_count = postdata.code_count.toString();
    postdata.is_test_code = postdata.is_test_code.toString();
    postdata.therapy_data_fk = postdata.therapy_data_fk.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.has_coach_access = postdata.has_coach_access.toString();
    postdata.has_campaign = postdata.has_campaign.toString();
    postdata.has_client = postdata.has_client.toString();
    postdata.has_doctor_access = postdata.has_doctor_access.toString();
    // postdata.doctor_data = postdata.doctor_data.toString();
    postdata.request_name = this.removeHTMLtags(postdata.request_name.toString());
    postdata.show_support_phone_number = postdata.show_support_phone_number.toString();
    postdata.show_support_email_id = postdata.show_support_email_id.toString();
    postdata.show_questionnaire = postdata.show_questionnaire.toString();
    postdata.show_triggers = postdata.show_triggers.toString();
    postdata.show_aqi = postdata.show_aqi.toString();
    postdata.show_bmi = postdata.show_bmi.toString();
    postdata.is_paid = postdata.is_paid.toString();
    postdata.use_geolocation = postdata.use_geolocation.toString();
    postdata.show_manufacturer = postdata.show_manufacturer.toString();
    postdata.default_weight_unit = postdata.default_weight_unit.toString();
    postdata.default_height_unit = postdata.default_height_unit.toString();
    postdata.max_upload_limit = postdata.max_upload_limit.toString();

    if (postdata.pump_access == 2) {
      postdata.pump_access = "add"
    } else if (postdata.pump_access == 3) {
      postdata.pump_access = "view"
    } else {
      postdata.pump_access = ""
    }
    if (postdata.medication_access == 2) {
      postdata.medication_access = "add"
    } else if (postdata.medication_access == 3) {
      postdata.medication_access = "view"
    } else {
      postdata.medication_access = ""
    }

    if (postdata.lab_report_access == 2) {
      postdata.lab_report_access = "add"
    } else if (postdata.lab_report_access == 3) {
      postdata.lab_report_access = "view"
    } else {
      postdata.lab_report_access = ""
    }
    if (postdata.remodulin == 'is_remodulin') {
      postdata.is_remodulin = 'true';
    } else {
      postdata.is_remodulin = 'false';
    }
    if (postdata.remodulin == 'is_nonremodulin') {
      postdata.is_nonremodulin = 'true';
    } else {
      postdata.is_nonremodulin = 'false';
    }

    postdata.show_history = postdata.show_history.toString(),
      postdata.show_utilities = postdata.show_utilities.toString(),
      postdata.show_targets = postdata.show_targets.toString();

    if (this.coachaccess) {
      postdata.coach_ids = JSON.stringify(postdata.coach_ids);
    }
    else {
      postdata.coach_ids = "[]";
    }
    if (this.clientaccess) {
      postdata.client_data = postdata.client_data.toString();
    }
    else {
      postdata.client_data = "";
    }
    if (this.campaignaccess) {
      postdata.campaign_data = postdata.campaign_data.toString();
    }
    else {
      postdata.campaign_data = "";
    }
    if (this.docaccess) {
      postdata.doctor_data = postdata.doctor_data.toString();
    }
    else {
      postdata.doctor_data = "";
    }
    if (postdata.show_questionnaire == "true") {
      postdata.profile_flags.push('SQR')

    }
    if (postdata.show_triggers == "true") {
      postdata.profile_flags.push('STR')

    }
    if (postdata.show_aqi == "true") {
      postdata.profile_flags.push('SAQ')

    }
    if (postdata.show_history == "true") {
      postdata.profile_flags.push('SHS')
    }
    if (postdata.show_utilities == "true") {
      postdata.profile_flags.push('SUT')
    }
    if (postdata.show_targets == "true") {
      postdata.profile_flags.push('STG')
    }
    if (postdata.is_remodulin == "true") {
      postdata.profile_flags.push('IRM')
    }
    if (postdata.is_nonremodulin == "true") {
      postdata.profile_flags.push('INR')
    }
    if (postdata.use_geolocation == "true") {
      postdata.profile_flags.push('UGL')
    }
    if (postdata.show_manufacturer == "true") {
      postdata.profile_flags.push('SMF')
    };
    if (postdata.show_medsurvey == true) {
      postdata.profile_flags.push('SMS')
    }
    if (postdata.chat_flags) {
      for (var i in postdata.chat_flags) {
        postdata.profile_flags.push(postdata.chat_flags[i])
      }
    }

    if (postdata.dairy_flags) {
      for (var i in postdata.dairy_flags) {
        postdata.profile_flags.push(postdata.dairy_flags[i])
      }
    }
    if (postdata.pro_flags) {
      for (var i in postdata.pro_flags) {
        postdata.profile_flags.push(postdata.pro_flags[i])
      }

    }

    if (postdata.health_flags) {
      for (var i in postdata.health_flags) {
        postdata.profile_flags.push(postdata.health_flags[i])
      }
    }

    newarray = [...new Set(postdata.profile_flags)]
    postdata.profile_flags = JSON.stringify(newarray);
    delete postdata.remodulin;
    delete postdata.chat_flags;
    delete postdata.dairy_flags;
    delete postdata.health_flags;
    delete postdata.pro_flags;
    delete postdata.show_medsurvey;

    this.commonService.postToBlade(postdata, 'activationdata')
      .subscribe(
        (res: any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Activation codes has been generated"), "OK");
    // this.router.navigate(['admin/codegenerate']);
    this.closeModal();
  }

  // ----------------------------
  showChatList = [
    { "name": "show carey", "code": "SCA" },
    { "name": "show coach", "code": "SCO" }
  ]
  showDairyList = [
    { "name": "insight", "code": "ISG" },
    { "name": "streaks", "code": "STK" }
  ]
  showProfileList = [
    { "name": "AppDetail page title", "code": "TIT" },
    { "name": "Mother tongue", "code": "LGL" },
    { "name": "show reminders", "code": "SRM" },
    { "name": "show prescription", "code": "SPR" },
    { "name": "show FAQ", "code": "SFQ" },
    { "name": "show YOU tab", "code": "YOU" },
    { "name": "show Setting tab", "code": "SET" },
    { "name": "Health detail", "code": "SHD" },
    { "name": "show caregiver", "code": "SCG" },
    { "name": "show doctor", "code": "DOC" },
    { "name": "show Subscription", "code": "SUB" },
    { "name": "show theme", "code": "THM" },
    { "name": "show language", "code": "LNG" },
    { "name": "show t&C", "code": "TAC" },
    { "name": "show privacy policy", "code": "PPY" },
    { "name": "show app detail", "code": "DET" },
    { "name": "customer support", "code": "CSU" },
    { "name": "Skip preview screen", "code": "SPS" },
    { "name": "Show Policy Progress data", "code": "SPD" },
    { "name": "Add policy members", "code": "APM" },
    { "name": "Steps to be shown", "code": "SST" },
    { "name": "Show Reward Section", "code": "SRW" },
    { "name": "coach mark", "code": "ECM" },
    { "name": "Report AE", "code": "RAE" },
    { "name": "Resync_GoogleFit", "code": "RGH" }
    // {"name": "show medication survey", "code": "SMS"},
  ]
  showHealthList = [
    { "name": "show Blood Sugar Monitor", "code": "BSM" },
    { "name": "show Eye Problem", "code": "EYP" },
    { "name": "show Gym Access", "code": "GYM" },
    { "name": "show Working Professional", "code": "WOP" },
    { "name": "Location Access", "code": "LOA" },
    { "name": "show diabietic", "code": "DBS" },
    { "name": "show high blood pressure", "code": "HBP" }
  ]

  closeModal(): void {
    this.dialogRef.close();
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}