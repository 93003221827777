import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { MagazinecollectionComponent } from './magazinecollection.component';
import { EditmagazinecollectionComponent } from './editmagazinecollection/editmagazinecollection.component';
const routes: Routes = [
  { path: '', component: MagazinecollectionComponent, canActivate: [ContentGuardGuard] },
  { path: 'magazinecollection/edit/:id/:langid/:chid', component: EditmagazinecollectionComponent, canActivate: [ContentGuardGuard] },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MagazinecollectionRoutingModule { }
