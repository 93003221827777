<mat-drawer-container class="sidenav-container" autosize>

  <app-hcleftnav></app-hcleftnav>

  <div class="sidenav-content">
    <div class="col-md-12 toptagbar">
      <div class="col-md-4 tagsection">
        <h3 class="tagtitle">Tags</h3>
        <div class="clearfix"><br /></div>
        <form [formGroup]="tagForm" (ngSubmit)="saveTag()" #form="ngForm">
          <mat-form-field appearance="outline" class="full-width-formfield inputboxcss">
            <mat-label>Tag Name</mat-label>
            <input matInput placeholder="Give a tag title" autocomplete="off" formControlName="name">
          </mat-form-field>

          <div class="clearfix"></div>

          <button mat-flat-button color="primary" class="centerbtn" [disabled]="!tagForm.valid">Create Tag</button>
          <!-- *ngIf="tagCreationSubject$ | async; else buttonSubmitLoader" -->
          <ng-template #buttonSubmitLoader>
            <app-loader showLoader="{{true}}"></app-loader>
          </ng-template>
        </form>
      </div>
      <div class="col-md-6 col-md-offset-1 tagsectionissuelist">

        <h3 class="tagtitle">All Tags</h3>
        <div class="clearfix"><br /></div>
        <div class="col-md-12">
          <div class="row">
            <form>
              <div class="form-group has-feedback has-search">
                <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                  placeholder="Search">
                <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                  <mat-icon matSuffix>search</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="clearfix"><br /></div>
        <div class="no-data" *ngIf="tabulardata?.length == 0">
          No Tags Available
        </div>
        <div class="container-table tags-table" *ngIf="tabulardata?.length != 0" infiniteScroll
          [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">
          <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
            class="widthfluid bordergrey list-table">

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Tag Title </th>
              <td mat-cell *matCellDef="let element" class="camelcased" data-toggle="tooltip" title="{{element.title}}">
                <span [class]="element.is_active ? 'circle green' : 'circle red'"></span>
                {{element.title | truncate:[40, '...']}}
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="menu-holder">
                    <div class="example-button-row">
                      <button mat-button disableRipple class="no-hover-effect active-green left-btn"
                        style="padding-left: 0;text-align: left;min-width: 80px;"
                        [ngClass]="{'my-class': element.is_active == true}"
                        (click)="isActive(element.is_active,element.id,'5')">{{element.is_active? "Deactivate" :
                        "Activate"}}</button>
                      <button class="last-btn no-hover-effect" mat-button disableRipple
                        *ngIf="element.is_active == false" (click)="openDialog(element.id)">Delete</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>