import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-userprofileview',
  templateUrl: './userprofileview.component.html',
  styleUrls: ['./userprofileview.component.scss']
})

export class UserprofileviewComponent implements OnInit {
  image_path: any;
  s3url: any = environment.s3url;
  type:any;
  constructor(
    public dialogRef: MatDialogRef<UserprofileviewComponent>,
    private commonService: CommonserviceService,
    private systemService: SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  userView: any;
  public showButtonLoader: boolean = false;

  ngOnInit() {
    this.userView = this.data.element;

    
    if (this.userView.profile_image != null) {
      this.getMedia(this.userView.profile_image)
    }
    else {
      this.image_path = null;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  // To Get User Image  
  // ----------------------------

  getMedia(id) {
    this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
      .subscribe(
        (res: any) => this.onSuccessMedia(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onSuccessMedia(data) {
    data = (data as any).data;
    this.image_path = data.getOneDocumentMedia.path;
  }

  // ----------------------------

  gender(data) {
    if (data == 'm') {
      return 'Male';
    }
    else if (data == 'f') {
      return 'Female';
    }
    else {
      return 'Others';
    }
  }

}
