import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.component.html',
  styleUrls: ['./viewdetails.component.scss']
})
export class ViewdetailsComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private contentService: ContentserviceService, private systemService: SystemService) { }

  public ide = this.data.element;
  detailView: any;
  ngOnInit(): void {
    this.getAllLevelID()
    
  }


  getAllLevelID() {
    this.contentService
      .getRequestById(this.ide)
      .subscribe((res: any) => this.ongetAllLevelDataSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetAllLevelDataSuccess(data) {
    
    this.detailView = data;
  }
}
