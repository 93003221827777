import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss']
})

export class CropImageComponent implements OnInit {
  croppedImage: any;
  imageChangedEvent: any;
  filedata:any;

  constructor(
    public dialogRef: MatDialogRef<CropImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.imageChangedEvent = this.data.dataKey;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    var scope = this;
    var myFiledata: Blob = scope.dataURItoBlobb(scope.croppedImage);
    this.filedata = myFiledata;
  }

  dataURItoBlobb(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new File([ia], this.imageChangedEvent.target.files[0].name, {
      type: "image/jpeg",
    })
    return blob;

  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }
  
  loadImageFailed() {
    // show message
  }

  dataObject(){
    this.dialogRef.close({data:this.filedata});
  }

}
