<form [formGroup]="generateCodeForm" validate>

    <div class="col-md-12 p-0">
        <div class="clearfix">
            <h2 class="pull-left title-modal">Generate codes</h2>
            <div class="pull-right">
                <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal"
                    alt="">
            </div>
        </div>
        <mat-form-field appearance="outline" class="col-md-12 no-padding">
            <mat-label>Code Count</mat-label>
            <input type="number" minlength="1" maxlength="6" pattern="^\d+(?:\.\d{0,1})?$"
                oninput="validity.valid||(value='');" matInput placeholder="No of Codes" formControlName="code_count">
        </mat-form-field>
    </div>

    <button mat-flat-button color="primary" class="submit-btn" [disabled]="!generateCodeForm.valid"
        (click)="dataObject()">Done</button>

</form>