<mat-dialog-content class="mat-typography pump-add">

    <div class="row">
        <div class="col-md-12">
            <h3 class="title">Hospitalisation</h3>
            <form [formGroup]="addHospitalisation" class="row">
                <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Indate</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Indate"
                        formControlName="duration_from">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Outdate</mat-label>
                    <input matInput [matDatepicker]="picker1" placeholder="Outdate"
                        formControlName="duration_to">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Reason for hospitalisation</mat-label>
                    <mat-select formControlName="reason">
                        <mat-option value="pah related">
                            PAH Related
                        </mat-option>
                        <mat-option value="other">
                            Other
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option value="emergency">
                            Emergency
                        </mat-option>
                        <mat-option value="scheduled">
                            Scheduled
                        </mat-option>
                        
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Procedure</mat-label>
                    <mat-select formControlName="procedures">
                        <mat-option value="surgery">
                            Surgery
                        </mat-option>
                        <mat-option value="non surgical">
                            Non-Surgical 
                        </mat-option>
                       
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Details of Stay</mat-label>
                    <input type="text" formControlName="stay_details" matInput placeholder="Details of Stay">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Next doctor visit</mat-label>
                    <input matInput [matDatepicker]="picker2" placeholder="Next doctor visit"
                        formControlName="next_doctor_visit">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
            </form>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="start">
    <div>
        <button mat-flat-button color="primary" class="centerbtn" (click)="uploadObject()">Add Hospitalisation</button>
    </div>
</mat-dialog-actions>