import { Component, OnInit } from '@angular/core';
import { DoctorDataService } from '../services/doctor/doctor.service';
//import { DocleftnavComponent, DocleftnavComponent as DocLeftNavComponent } from './../shared/docleftnav/docleftnav.component';

@Component({
  selector: 'app-allqr',
  templateUrl: './allqr.component.html',
  styleUrls: ['./allqr.component.scss']
})
export class AllqrComponent implements OnInit {

  constructor(private doctorService: DoctorDataService) { }

  ngOnInit() {
    this.getAllCampaign();
  }
  getAllCampaign() {
    this.doctorService.getAllCampaign()
      .subscribe(data => {
      })
  }
}
