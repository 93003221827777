import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'policyIsDeletedFilter'
})

export class PolicyIsDeletedFilterPipe implements PipeTransform {

  transform(policies: any[]): any {
    
    let filteredPolicies: any = [];

    for(let policy of policies){
      if (!policy.is_deleted){
        filteredPolicies.push(policy);
      }
    }

    return filteredPolicies;
  }

}
