import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContextService as HealthCoachContextService } from '../../services/context.service';
import { RenderChatsModule } from '../renderchats/renderchats.module';
import { LoaderModule } from '../utilities/loader/loader/loader.module';
import { ChatsectionComponent } from './chatsection.component';

@NgModule({
  declarations: [
    ChatsectionComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    LoaderModule,
    RenderChatsModule,
    SharedModule.forRoot()
  ],
  providers:[
    HealthCoachContextService
  ],
  exports:[
    ChatsectionComponent
  ]
})
export class chatSectionModule { }