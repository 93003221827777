<div class="container">
    <div class="row">
        <div class="col-md-12 col-xs-12 settopmargin30forweb">
            <a [routerLink]="['/application']" routerLinkActive="router-link-active" mat-raised-button
                class="centerbtn pull-left" style="margin-right: 15px;">
                <i class="material-icons">
                    dashboard
                </i></a>
        </div>
        <div class="clearfix"><br /></div>
        <div class="setpaddingforweb">
            <h5 class="activatetitlecss">For Patient</h5>
            <div class="col-md-12 col-xs-12">
                <div class="col-xs-12 col-md-4 navigatorcontainercss preactivate" (click)="openPreActive()">
                    <div class="row">
                        <div class="prod-img col-md-2 col-xs-3 pdl0 pdt10">
                            <img src="assets/img/docactivation_icons/Icons_Pre_Activate.svg" alt="" class="prodimgcss">
                        </div>
                        <div class="col-md-8 col-xs-9">
                            <h5>Pre-activate</h5>
                            <p>Activate your therapy for a patient using his mobile number.</p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-md-4 navigatorcontainercss deeplink" (click)="openDeeplink()">
                    <div class="row">
                        <div class="prod-img col-md-2 col-xs-3 pdl0 pdt10">
                            <img src="assets/img/docactivation_icons/Icons_Gennerate Deeplink.svg" alt=""
                                class="prodimgcss">
                        </div>
                        <div class="col-md-8 col-xs-9">
                            <h5>Share deeplink</h5>
                            <p>Share this link with your patients on multiple platforms to onboard
                                them to your therapy.</p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-md-4 navigatorcontainercss qrcode" (click)="openpatientQR()">
                    <div class="row">
                        <div class="prod-img col-md-2 col-xs-3 pdl0 pdt10 opengeneralqrimg">
                            <img src="assets/img/docactivation_icons/Icons_Genarate QR.svg" alt="" class="prodimgcss"
                                style="width: 45px;margin-left: 25px;">
                        </div>
                        <div class="col-md-8 col-xs-9">
                            <h5>Unique QR code</h5>
                            <p>Scanning this QR code with the wellthy care app, get’s your therapy
                                activated on the patients phone.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <h5 class="activatetitlecss" style="margin-top: 15px;display: inline-block;">General</h5>
            <div class="col-md-12 col-xs-12">
                <div class="col-xs-12 col-md-4 navigatorcontainercss qrcodegeneral" (click)="openGeneralQR()">
                    <div class="row">
                        <div class="prod-img col-md-2 col-xs-3 pdl0 pdt10 opengeneralqrimg">
                            <img src="assets/img/docactivation_icons/Icons_Show QR.svg" alt="" class="prodimgcss"
                                style="width: 45px;margin-left: 25px;">
                        </div>
                        <div class="col-md-8 col-xs-9">
                            <h5>View QR</h5>
                            <p>View various digital solutions assigned to you as part of Wellthy
                                ecosystem. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-4 navigatorcontainercss qrcodegeneral" (click)="openGenericDeeplink()">
                    <div class="row">
                        <div class="prod-img col-md-2 col-xs-3 pdl0 pdt10">
                            <img src="assets/img/docactivation_icons/Icons_Gennerate Deeplink.svg" alt=""
                                class="prodimgcss" style="filter: grayscale(100%);">
                        </div>
                        <div class="col-md-8 col-xs-9">
                            <h5>General Deeplink</h5>
                            <p>Share this link with anyone on multiple platforms to onboard them to
                                your therapy.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>