<mat-drawer-container class="sidenav-container" autosize>

    <app-adminleftnav></app-adminleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="admin/tasklibrary/taskeditID"></app-breadcrumb>
        <div class="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <button mat-button class="btn create-btn btn-filled mt-40 mb-40" (click)="prefillFromLibrary()">
                        Prefill from library 
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-vertical-stepper [ngClass]="{'edit_mode': edit_mode === 1}" fxLayout="column" [linear]="true"
                        #stepper disableRipple="true" class="edit-task" (click)='getStepper()'>
                        <!-- <form [formGroup]="dataForm" class="leftformsection" validate> -->
                        <mat-step [stepControl]="firstFormGroup">
                            <div class="row">
                                <div class="col-md-8">
                                    <form [formGroup]="firstFormGroup" autocomplete="off">
                                        <ng-template matStepperIcon="edit">
                                            <mat-icon>done</mat-icon>
                                        </ng-template>
                                        <ng-template matStepLabel>Configure Conditions</ng-template>

                                        <div class="row row-wrapper mb-20">
                                            <div class="col-md-6">
                                                <mat-select placeholder="Select therapy" class="sortcommonwhite"
                                                    panelClass="custom-material-select-css"
                                                    formControlName="filter_therapies" #singleSelect
                                                    (click)="onclicktheropy()">

                                                    <mat-option>
                                                        <ngx-mat-select-search class="tagmat search-field"
                                                            [placeholderLabel]="'Search'"
                                                            [noEntriesFoundLabel]="'No matching records found'"
                                                            ngDefaultControl (ngModelChange)="livesearch()"
                                                            [formControl]="searchmattag">
                                                            <div
                                                                class="mat-select-search-custom-header-content notranslate">
                                                                <img src="assets/img/dls/icons/search-icon.svg" alt="">
                                                            </div>
                                                        </ngx-mat-select-search>
                                                    </mat-option>

                                                    <mat-option *ngFor="let therapy of therapyData"
                                                        [value]="therapy.id">
                                                        {{therapy.title}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <div class="row row-wrapper mb-15" formArrayName="filter_conditions"
                                            *ngFor="let pl of formData.controls; let i = index;">
                                            <div [formGroupName]="i">
                                                <div class="col-md-3">
                                                    <mat-select placeholder="Select type" class="sortcommonwhite"
                                                        formControlName="type" (selectionChange)="onTextChange(i)">
                                                        <mat-option value="number_of_days_completed">Days Completed
                                                        </mat-option>
                                                        <mat-option value="activity_type">App activity</mat-option>
                                                        <mat-option value="data_parameters">Data parameters</mat-option>
                                                        <mat-option value="patient_age">Years (Age)</mat-option>
                                                        <mat-option value="primary_condition">Disease</mat-option>
                                                        <mat-option value="patient_condition">Comorbidities</mat-option>
                                                        <mat-option value="patient_gender">Gender</mat-option>
                                                        <!-- <mat-option value="patient_therapy">Programs</mat-option> -->
                                                        <mat-option value="patient_language">Language Preference
                                                        </mat-option>
                                                        <mat-option value="clinical_parameter">Clinical Parameter
                                                        </mat-option>
                                                        <mat-option value="patient_chapter">Chapter completed
                                                        </mat-option>
                                                        <mat-option value="patient_lesson">Lesson completed</mat-option>
                                                        <mat-option value="patient_module">Module completed</mat-option>
                                                        <mat-option value="patient_level">Level completed</mat-option>
                                                        <mat-option value="patient_client">Clients</mat-option>
                                                    </mat-select>
                                                    <!-- <button mat-button [matMenuTriggerFor]="animals" formControlName="type">Select Type</button>
                                                    <mat-menu #animals="matMenu">
                                                        <button mat-menu-item value="number_of_days_completed">Completed Days</button>
        
                                                    </mat-menu> -->
                                                </div>
                                                <!-- *ngIf="filter_conditions?.value?.filter_conditions_summary" -->

                                                <div class="col-md-3" *ngIf="pl.get('type').value=='activity_type'">
                                                    <mat-select placeholder="Type" class="sortcommonwhite"
                                                        formControlName="sub_type" onchange="changeSubtype()">
                                                        <mat-option value="diary_activity">Activity</mat-option>
                                                        <mat-option value="diary_meal">Meal</mat-option>
                                                        <mat-option value="app_launch">App launch</mat-option>
                                                        <mat-option value="diary_blood_pressure">Blood Pressure
                                                        </mat-option>
                                                        <mat-option value="diary_blood_sugar">Blood Sugar</mat-option>
                                                        <mat-option value="prescription">Prescription</mat-option>
                                                        <mat-option value="lab_report">Lab Report</mat-option>
                                                        <mat-option value="medicine">Medicine</mat-option>
                                                        <mat-option value="diary_weight">Weight</mat-option>
                                                        <mat-option value="coach_message">Healthcoach chat</mat-option>
                                                        <mat-option value="coach_call">Healthcoach call attempt
                                                        </mat-option>
                                                        <mat-option value="coach_call_completed">Healthcoach call
                                                            completed
                                                        </mat-option>
                                                        <mat-option value="homefeed_chapter_completed">Chapter completed
                                                        </mat-option>
                                                        <mat-option value="homefeed_module_completed">Module completed
                                                        </mat-option>
                                                        <mat-option value="homefeed_lesson_completed">Lesson completed
                                                        </mat-option>
                                                        <mat-option value="homefeed_level_completed">Level completed
                                                        </mat-option>
                                                    </mat-select>
                                                </div>


                                                <div class="col-md-3" *ngIf="pl.get('type').value=='data_parameters'">
                                                    <mat-select placeholder="Type" class="sortcommonwhite"
                                                        formControlName="sub_type">
                                                        <mat-option value="meal_reminder">Meal reminder</mat-option>
                                                        <mat-option value="activity_reminder">Activity reminder
                                                        </mat-option>
                                                        <mat-option value="water_reminder">Water reminder</mat-option>
                                                        <mat-option value="symptom_reminder">Symptom reminder
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                                <div class="col-md-3"
                                                    *ngIf="pl.get('type').value=='clinical_parameter'">
                                                    <mat-select placeholder="Type" class="sortcommonwhite"
                                                        formControlName="sub_type">
                                                        <mat-option value="diary_blood_pressure">Blood pressure
                                                        </mat-option>
                                                        <mat-option value="diary_blood_sugar">Blood sugar</mat-option>
                                                        <mat-option value="diary_weight">Weight</mat-option>
                                                        <mat-option value="diary_hba1c">HbA1c</mat-option>


                                                    </mat-select>
                                                </div>

                                                <div class="col-md-3"
                                                    *ngIf="pl.get('sub_type').value=='diary_blood_pressure' && pl.get('type').value=='clinical_parameter'">
                                                    <mat-select placeholder="Type" multiple class="sortcommonwhite"
                                                        formControlName="field_value">
                                                        <mat-option value="systolic">Systolic</mat-option>
                                                        <mat-option value="diastolic">Diastolic</mat-option>
                                                    </mat-select>
                                                </div>
                                                <!-- *ngIf="pl.get('type').value=='number_of_days_completed'|| pl.get('type').value=='activity_type'|| pl.get('sub_type').value=='diary_activity'|| pl.get('type').value=='patient_age' || pl.get('sub_type').value=='diary_meal' ||  pl.get('sub_type').value=='app_launch'
                                                    ||pl.get('sub_type').value=='prescription'||pl.get('sub_type').value=='lab_report'||pl.get('sub_type').value=='medicine'||pl.get('sub_type').value=='coach_message'
                                                    " -->

                                                <div class="col-md-3" *ngIf="pl.get('type').value=='activity_type'">
                                                    <div>
                                                        <mat-select placeholder="Select operator"
                                                            class="sortcommonwhite" formControlName="operation"
                                                            (selectionChange)="onTextChangeValue(i)">
                                                            <mat-option value="equal_to">Equals To</mat-option>
                                                            <mat-option value="greater_than">Greater than</mat-option>
                                                            <mat-option value="less_than">Less than</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="since">Since</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="yes">
                                                                Logged</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="no">
                                                                Not logged</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="last">
                                                                logged days ago</mat-option>

                                                        </mat-select>
                                                    </div>
                                                </div>


                                                <div class="col-md-3"
                                                    *ngIf="(pl.get('type').value=='number_of_days_completed'||pl.get('type').value=='patient_age'  || pl.get('sub_type').value=='diary_hba1c' ||pl.get('sub_type').value=='diary_weight' || pl.get('sub_type').value=='diary_blood_sugar' ) && pl.get('type').value!='activity_type'">
                                                    <div>

                                                        <mat-select placeholder="Select operator"
                                                            class="sortcommonwhite" formControlName="operation"
                                                            (selectionChange)="onTextChangeValue(i)">
                                                            <mat-option value="equal_to">Equals To</mat-option>
                                                            <mat-option value="greater_than">Greater than</mat-option>
                                                            <mat-option value="less_than">Less than</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="since">Since</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="yes">
                                                                Logged</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="no">
                                                                Not logged</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('sub_type').value=='diary_meal'|| pl.get('sub_type').value=='app_launch' || pl.get('sub_type').value=='diary_blood_pressure' || pl.get('sub_type').value=='diary_blood_sugar' ||pl.get('sub_type').value=='prescription' || pl.get('sub_type').value=='lab_report' || pl.get('sub_type').value=='medicine'"
                                                                value="last">
                                                                logged days ago</mat-option>

                                                        </mat-select>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-10"
                                                    *ngIf="(pl.get('sub_type').value=='diary_blood_pressure')">
                                                    <div *ngFor="let pl of formData.controls; let i = index;">
                                                        <div class="with-select-container">
                                                            <mat-form-field style="width: 49%;margin-right: 1%" appearance="outline" floatPlaceholder="never">
                                                                
                                                                <input type="number" id='numberbox' matInput #input
                                                                   
                                                                    class="example-button" placeholder="Systolic"
                                                                    formControlName="Systolicvalue"><span *ngIf="pl.get('sub_type').value=='diary_blood_pressure'" style="background-color: #FB7A20;
                                                                    border-radius: 4px;" matSuffix>mm Hg</span>
                                                                    <span *ngIf="pl.get('sub_type').value=='diary_blood_sugar'" style="background-color: #FB7A20;
                                                                    border-radius: 4px;" matSuffix>mg/dL
                                                                    
                                                                </span>
            
                                                            </mat-form-field>
                                                            <mat-form-field style="width: 49%;margin-right: 1%" floatLabel="never"
                                                                appearance="outline">
                                                                
                                                                <input type="number" id='numberbox' matInput #input
                                                                  
                                                                    class="example-button" placeholder="Diastolic"
                                                                    formControlName="Diastolicvalue"><span *ngIf="pl.get('sub_type').value=='diary_blood_pressure'" style="background-color: #FB7A20;
                                                                    border-radius: 4px;" matSuffix>mm Hg</span>
                                                                    <span *ngIf="pl.get('sub_type').value=='diary_blood_sugar'" style="background-color: #FB7A20;
                                                                    border-radius: 4px;" matSuffix>mg/dL
                                                                    
                                                                </span>
            
                                                            </mat-form-field>
                                                            </div>
                                                        
                                                  </div>
                                                  </div> -->

                                                <div class="col-md-10"
                                                    *ngIf="(selectedType && pl.get('field_value').value!=null && checkcond('systolic',pl.get('field_value').value)) && pl.get('sub_type').value=='diary_blood_pressure'">
                                                    <div class="with-select-container">


                                                        <mat-select style="width: 40%;" placeholder="Select operator"
                                                            class="sortcommonwhite" formControlName="Systolicoperation"
                                                            (selectionChange)="onTextChangeValue(i)">
                                                            <mat-option value="equal_to">Equals To</mat-option>
                                                            <mat-option value="greater_than">Greater than</mat-option>
                                                            <mat-option value="less_than">Less than</mat-option>


                                                        </mat-select>

                                                        <mat-form-field
                                                            style="width: 27%;margin-right: 1%;    margin-left: 10px"
                                                            floatLabel="never" appearance="outline">
                                                            <!-- <mat-label>Enter some input</mat-label> -->
                                                            <input type="number" id='numberbox' matInput #input
                                                                matTooltipClass="example-tooltip-red"
                                                                aria-label="Button that shows a red tooltip"
                                                                class="example-button" placeholder="Systolic Value"
                                                                formControlName="Systolicvalue">

                                                        </mat-form-field>
                                                        <!-- <mat-label>Select me</mat-label> -->
                                                        <mat-select style="width: 30%;margin-left: 0%;"
                                                            placeholder="Unit" formControlName="unit"
                                                            class="sortcommonwhite"
                                                            panelClass="custom-material-select-css" #singleSelect>

                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_pressure')"
                                                                value="mm hg">mm hg</mat-option>

                                                        </mat-select>

                                                    </div>

                                                </div>

                                                <div class="col-md-10"
                                                    *ngIf="(selectedType && pl.get('field_value').value!=null && checkcond('diastolic',pl.get('field_value').value)) && pl.get('sub_type').value=='diary_blood_pressure'">
                                                    <div class="with-select-container">


                                                        <mat-select style="width: 40%;" placeholder="Select operator"
                                                            class="sortcommonwhite" formControlName="Diastolicoperation"
                                                            (selectionChange)="onTextChangeValue(i)">
                                                            <mat-option value="equal_to">Equals To</mat-option>
                                                            <mat-option value="greater_than">Greater than</mat-option>
                                                            <mat-option value="less_than">Less than</mat-option>


                                                        </mat-select>

                                                        <mat-form-field
                                                            style="width: 27%;margin-right: 1%;margin-left: 10px;"
                                                            floatLabel="never" appearance="outline">
                                                            <!-- <mat-label>Enter some input</mat-label> -->
                                                            <input type="number" id='numberbox' matInput #input
                                                                matTooltipClass="example-tooltip-red"
                                                                aria-label="Button that shows a red tooltip"
                                                                class="example-button" placeholder="diastolic Value"
                                                                formControlName="Diastolicvalue">

                                                        </mat-form-field>
                                                        <!-- <mat-label>Select me</mat-label> -->
                                                        <mat-select style="width: 30%;margin-left: 0%;"
                                                            placeholder="Unit" formControlName="unit"
                                                            class="sortcommonwhite"
                                                            panelClass="custom-material-select-css" #singleSelect>

                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_pressure')"
                                                                value="mm hg">mm hg</mat-option>

                                                        </mat-select>

                                                    </div>

                                                </div>
                                                <!-- <div class="col-md-6"
                                                    *ngIf="pl.get('sub_type').value=='diary_blood_pressure'">
                                                    <div class="with-select-container">
                                                        <mat-form-field style="width: 58%;margin-right: 1%" floatLabel="never"
                                                            appearance="outline">
                                                           
                                                            <input type="number" id='numberbox' matInput #input
                                                                pattern="([1-9]|[1-9][0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])"
                                                                matTooltip="Enter Value from 1 to 365"
                                                                matTooltipClass="example-tooltip-red"
                                                                aria-label="Button that shows a red tooltip"
                                                                class="example-button" placeholder="Diastolic Value"
                                                                formControlName="Diastolicvalue">
        
                                                        </mat-form-field>
        
                                                        <mat-select style="width: 40%;margin-left: 1%;" placeholder="Unit"
                                                            formControlName="unit" class="sortcommonwhite"
                                                            panelClass="custom-material-select-css" #singleSelect>
                                                           
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_pressure')"
                                                                value="mm hg">mm hg</mat-option>
                                                            
                                                        </mat-select>
        
                                                    </div>
                                                   
                                                </div> -->

                                                <div class="col-md-6" *ngIf="pl.get('type').value=='patient_client'">
                                                    <mat-select placeholder="Select client" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect (click)="onclickliveclientsearch()"
                                                        formControlName="value" multiple #singleSelectHC
                                                        (selectionChange)="clientValueChange($event.value)">
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl (ngModelChange)="liveclientsearch()"
                                                                [formControl]="searchmattag1">
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let client of clientData"
                                                            [value]="client.id">
                                                            {{client.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <div class="col-md-6" *ngIf="pl.get('type').value=='patient_level'">
                                                    <mat-select placeholder="Select level" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect (click)="onclicklivelevelsearch()"
                                                        formControlName="value" #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl (ngModelChange)="livelevelsearch()"
                                                                [formControl]="searchmattag1">
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let level of levelData" [value]="level.id">
                                                            {{level.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <div class="col-md-6" *ngIf="pl.get('type').value=='patient_module'">
                                                    <mat-select placeholder="Select module" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect (click)="onclicklivemodulesearch()"
                                                        formControlName="value" #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl (ngModelChange)="livemodulesearch()"
                                                                [formControl]="searchmattag1">
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let module of moduleData"
                                                            [value]="module.id">
                                                            {{module.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <div class="col-md-6" *ngIf="pl.get('type').value=='patient_lesson'">
                                                    <mat-select placeholder="Select lesson" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect (click)="onclicklivelessonsearch()"
                                                        formControlName="value" #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl (ngModelChange)="livelessonsearch()"
                                                                [formControl]="searchmattag1">
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let lesson of lessonData"
                                                            [value]="lesson.id">
                                                            {{lesson.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                                <div class="col-md-6" *ngIf="pl.get('type').value=='patient_chapter'">
                                                    <mat-select placeholder="Select chapter" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect (click)="onclicklivechaptersearch()"
                                                        formControlName="value" #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl (ngModelChange)="livechaptersearch()"
                                                                [formControl]="searchmattag1">
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let chapter of chapterData"
                                                            [value]="chapter.id">
                                                            {{chapter.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <div class="col-md-4" *ngIf="pl.get('type').value=='patient_language'">
                                                    <mat-select placeholder="Select language" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect formControlName="value" #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl (ngModelChange)="livelangsearch()"
                                                                [formControl]="searchmattag1">
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let lang of langData" [value]="lang.id">
                                                            {{lang.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <!-- <div class="col-md-4"
                                                    *ngIf="pl.get('type').value=='patient_condition'||pl.get('type').value=='primary_condition'">
                                                    <mat-select placeholder="Select condition" disableOptionCentering]="true" class="sortcommonwhite"
                                                         panelClass="custom-material-select-css"
                                                        #singleSelect formControlName="value" multiple #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                (ngModelChange)="livesearch1()" [formControl]="searchmattag1"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl>
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg" alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let cond of condData; let i = index;"
                                                            [value]="cond.id" (selectionChange)="onTextChange1(i)">
                                                            {{cond.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div> -->
                                                <div class="col-md-4"
                                                    *ngIf="pl.get('type').value=='patient_condition'||pl.get('type').value=='primary_condition'">
                                                    <!-- <mat-select placeholder="Select condition" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect formControlName="value" multiple #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                (ngModelChange)="livesearch1()" [formControl]="searchmattag1"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl>
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg" alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <cdk-virtual-scroll-viewport itemSize="50" [style.height.px]=5*60>
                                                        <mat-option *cdkVirtualFor="let cond of condData; let i = index;"
                                                            [value]="cond.id" (selectionChange)="onTextChange1(i)">
                                                            {{cond.title}}
                                                        </mat-option>
                                                    </cdk-virtual-scroll-viewport>
                                                    </mat-select> -->
                                                    <mat-select placeholder="Select condition" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect formControlName="value" [multiple]="true"
                                                        #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                (ngModelChange)="livesearch1()"
                                                                [formControl]="searchmattag1" [clearSearchInput]="true"
                                                                [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl>
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let cond of condData; let i = index;"
                                                            [value]="cond.id" (selectionChange)="onTextChange1(i)">
                                                            {{cond.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <div class="col-md-4" *ngIf="pl.get('type').value=='patient_therapy'">
                                                    <mat-select placeholder="Select therapy" class="sortcommonwhite"
                                                        disableOptionCentering panelClass="custom-material-select-css"
                                                        #singleSelect formControlName="value" #singleSelectHC>
                                                        <mat-option>
                                                            <ngx-mat-select-search class="tagmat search-field"
                                                                [clearSearchInput]="true" [placeholderLabel]="'Search'"
                                                                [noEntriesFoundLabel]="'No matching records found'"
                                                                ngDefaultControl>
                                                                <div
                                                                    class="mat-select-search-custom-header-content notranslate">
                                                                    <img src="assets/img/dls/icons/search-icon.svg"
                                                                        alt="">
                                                                </div>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let therapy of therapyData"
                                                            [value]="therapy.id">
                                                            {{therapy.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                                <div class="col-md-4" *ngIf="pl.get('type').value=='patient_gender'">
                                                    <mat-select placeholder="Select gender" class="sortcommonwhite"
                                                        formControlName="value" multiple
                                                        (selectionChange)="ChangeGender($event.value)">
                                                        <mat-option value="male">Male</mat-option>
                                                        <mat-option value="female">Female</mat-option>
                                                        <mat-option value="other">Other</mat-option>
                                                    </mat-select>
                                                </div>
                                                <div class="col-md-3" *ngIf="pl.get('type').value=='data_parameters'">
                                                    <mat-select placeholder="Select" class="sortcommonwhite"
                                                        formControlName="operation">
                                                        <mat-option value="yes">Yes
                                                        </mat-option>
                                                        <mat-option value="no">No</mat-option>
                                                        <mat-option value="last">days ago</mat-option>
                                                    </mat-select>
                                                </div>

                                                <div class="col-md-3"
                                                    *ngIf="(pl.get('type').value=='number_of_days_completed'|| pl.get('type').value=='patient_age'|| pl.get('operation').value=='last' || pl.get('sub_type').value=='diary_hba1c' ||pl.get('sub_type').value=='diary_weight' || pl.get('sub_type').value=='diary_blood_sugar')&&  pl.get('type').value!='activity_type'">
                                                    <div class="with-select-container">
                                                        <mat-form-field style="width: 49%;margin-right: 1%"
                                                            floatLabel="never" appearance="outline">
                                                            <!-- <mat-label>Enter some input</mat-label> -->
                                                            <input type="number" id='numberbox' matInput #input
                                                                pattern="([1-9]|[1-9][0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])"
                                                                maxlength="3"
                                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                matTooltip="Enter Value from 1 to 365"
                                                                onchange="handleNoOfDays(this);"
                                                                matTooltipClass="example-tooltip-red"
                                                                aria-label="Button that shows a red tooltip"
                                                                class="example-button" placeholder="Enter value"
                                                                formControlName="value">

                                                        </mat-form-field>


                                                        <!-- <mat-label>Select me</mat-label> -->
                                                        <mat-select style="width: 49%;margin-left: 1%;"
                                                            placeholder="Unit" formControlName="unit"
                                                            class="sortcommonwhite"
                                                            panelClass="custom-material-select-css" #singleSelect>
                                                            <mat-option
                                                                *ngIf=" pl.get('type').value=='activity_type' || pl.get('type').value=='data_parameters'"
                                                                value="time">Time</mat-option>
                                                            <!--*ngIf="pl.get('sub_type').value =='diary_meal'||pl.get('sub_type').value=='app_launch'"-->
                                                            <mat-option
                                                                *ngIf="pl.get('type').value =='number_of_days_completed'|| pl.get('type').value =='activity_type'"
                                                                value="month">Month</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('type').value =='number_of_days_completed'||pl.get('type').value =='activity_type' || pl.get('type').value=='data_parameters'"
                                                                value="day">Day</mat-option>
                                                            <mat-option *ngIf=" pl.get('type').value=='data_parameters'"
                                                                value="minute">Minute</mat-option>
                                                            <mat-option *ngIf=" pl.get('type').value=='data_parameters'"
                                                                value="hour">Hour</mat-option>
                                                            <mat-option *ngIf="pl.get('type').value=='patient_age'"
                                                                value="year">Years</mat-option>
                                                            <!-- <mat-option
                                                            *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_pressure')"
                                                            value="mm hg">mm hg</mat-option> -->
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_sugar')"
                                                                value="mg/dL">mg/dL</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_sugar')"
                                                                value="mg/dL"> mmol/L</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_hba1c')"
                                                                value="percentage">%</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_weight')"
                                                                value="Kg">Kg</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_weight')"
                                                                value="lbs">lbs</mat-option>
                                                        </mat-select>

                                                    </div>
                                                    <!-- <mat-select placeholder="Unit" class="sortcommonwhite"
                                                        formControlName="measure"
                                                        (selectionChange)="onTextChange($event.value)">
                                                        <mat-option value="16 days"> 16 Days</mat-option>
                                                        <mat-option value="Regular">Month</mat-option>
                                                    </mat-select> -->
                                                </div>

                                                <div class="col-md-3" *ngIf=" pl.get('type').value=='activity_type'">
                                                    <div class="with-select-container">
                                                        <mat-form-field style="width: 49%;margin-right: 1%"
                                                            floatLabel="never" appearance="outline">
                                                            <!-- <mat-label>Enter some input</mat-label> -->
                                                            <input type="number" id='numberbox' matInput #input
                                                                maxlength="3"
                                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                matTooltip="Enter Value from 1 to 365"
                                                                onchange="handleNoOfDays(this);"
                                                                matTooltipClass="example-tooltip-red"
                                                                aria-label="Button that shows a red tooltip"
                                                                class="example-button" placeholder="Enter value"
                                                                formControlName="value">

                                                        </mat-form-field>
                                                        <!-- pattern="([1-9]|[1-9][0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])"-->


                                                        <!-- <mat-label>Select me</mat-label> -->
                                                        <mat-select style="width: 49%;margin-left: 1%;"
                                                            placeholder="Unit" formControlName="unit"
                                                            class="sortcommonwhite"
                                                            panelClass="custom-material-select-css" #singleSelect>
                                                            <mat-option
                                                                *ngIf=" pl.get('type').value=='activity_type' || pl.get('type').value=='data_parameters'"
                                                                value="time">Time</mat-option>
                                                            <!--*ngIf="pl.get('sub_type').value =='diary_meal'||pl.get('sub_type').value=='app_launch'"-->
                                                            <mat-option
                                                                *ngIf="pl.get('type').value =='number_of_days_completed'|| pl.get('type').value =='activity_type'"
                                                                value="month">Month</mat-option>
                                                            <mat-option
                                                                *ngIf="pl.get('type').value =='number_of_days_completed'||pl.get('type').value =='activity_type' || pl.get('type').value=='data_parameters'"
                                                                value="day">Day</mat-option>
                                                            <mat-option *ngIf=" pl.get('type').value=='data_parameters'"
                                                                value="minute">Minute</mat-option>
                                                            <mat-option *ngIf=" pl.get('type').value=='data_parameters'"
                                                                value="hour">Hour</mat-option>
                                                            <mat-option *ngIf="pl.get('type').value=='patient_age'"
                                                                value="year">Years</mat-option>
                                                            <!-- <mat-option
                                                            *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_pressure')"
                                                            value="mm hg">mm hg</mat-option> -->
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_sugar')"
                                                                value="mg/dL">mg/dL</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_blood_sugar')"
                                                                value="mg/dL"> mmol/L</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_hba1c')"
                                                                value="percentage">%</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_weight')"
                                                                value="Kg">Kg</mat-option>
                                                            <mat-option
                                                                *ngIf="( pl.get('type').value=='clinical_parameter') && (pl.get('sub_type').value=='diary_weight')"
                                                                value="lbs">lbs</mat-option>
                                                        </mat-select>

                                                    </div>
                                                    <!-- <mat-select placeholder="Unit" class="sortcommonwhite"
                                                    formControlName="measure"
                                                    (selectionChange)="onTextChange($event.value)">
                                                    <mat-option value="16 days"> 16 Days</mat-option>
                                                    <mat-option value="Regular">Month</mat-option>
                                                </mat-select> -->
                                                </div>


                                                <div class="col-md-3" className="customDatePickerWidth"
                                                    *ngIf="pl.get('operation').value=='last'&& pl.get('type').value=='data_parameters' || pl.get('type').value =='activity_type'">
                                                    <mat-form-field appearance="outline" floatPlaceholder="never">
                                                        <mat-label>Choose a date</mat-label>
                                                        <input matInput ngxDaterangepickerMd [linkedCalendars]="true"
                                                            formControlName="end_date"
                                                            [locale]="{applyLabel: 'Done', format: 'D MMM, YYYY'}"
                                                            startKey="startDate" endKey="endDate"
                                                            [(ngModel)]="selected1" name="daterange"
                                                            (ngModelChange)="selectDateRange($event)">
                                                        <button class="ngx-daterangepicker-action" matSuffix
                                                            mat-icon-button color="primary" (click)="openDatepicker()">
                                                            <mat-icon class="ngx-daterangepicker-action"
                                                                (click)="openDatepicker()">calendar_today</mat-icon>
                                                        </button>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <span class="remove-btn" [ngClass]="{hideremovebtn: i==0}">
                                                <i class="material-icons" (click)="removeBrodcast(i)">remove_circle</i>
                                            </span>

                                        </div>
                                        <button mat-icon-button class="plusrectangletransparent mb-15"
                                            (click)="addMore()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <div>
                                            <button mat-button matStepperNext [disabled]="!firstFormGroup.valid"
                                                (click)="ValidateData()" class="btn mr-5 btn-filled">Next</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-md-4" *ngIf="showsummaryTab && showTab1">
                                    <div class="view-area">
                                        <div class="preview">
                                            <!-- configure conditions -->
                                            <div class="configure-conditions" *ngIf="showTab1">
                                                <h5>Configure Conditions</h5>
                                                <!-- <ng-template matStepLabel>
                                                            {{checkTherapy(firstFormGroup.controls.filter_therapies.value)}}
                                                        </ng-template> -->
                                                <div class="row"
                                                    *ngIf="firstFormGroup.controls.filter_therapies.value !== ''"
                                                    style="padding: 15px;">
                                                    <div class="col-md-1 numberCircle">
                                                        1
                                                    </div>
                                                    <div class="col-md-10">
                                                        {{checkTherapy(firstFormGroup.controls.filter_therapies.value)}}
                                                    </div>
                                                </div>
                                                <div *ngFor="let type of selectedType">
                                                    <div class="row"
                                                        *ngIf="firstFormGroup.controls.filter_therapies.value !== ''"
                                                        style="padding: 15px;">
                                                        <div class="col-md-1 numberCircle">
                                                            2
                                                        </div>
                                                        <div class="col-md-10">
                                                            <span>
                                                                {{conditionChecking(type.type)?conditionChecking(type.type):''}}
                                                                {{appActivitySubType(type.sub_type)?appActivitySubType(type.sub_type):''}}
                                                                {{checkoperation(type.operation)?checkoperation(type.operation):''}}</span>
                                                            <span *ngIf="type?.value?.length > 0">
                                                                <div *ngFor="let val of type.value">
                                                                    {{checkforValue(val)?checkforValue(val):''}}</div>
                                                            </span>
                                                            <span
                                                                *ngIf="typeof(type.value) != 'object'">{{checkforValue(type.value)?checkforValue(type.value):''}}</span>
                                                            {{checkUnit(type.unit)?checkUnit(type.unit):''}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step [stepControl]="secondFormGroup">
                            <div class="row">
                                <div class="col-md-8">
                                    <form [formGroup]="secondFormGroup" autocomplete="off">
                                        <ng-template matStepLabel>Define task </ng-template>

                                        <div formArrayName="task"
                                            *ngFor="let pl of newformData.controls; let j = index;"
                                            class="row row-wrapper mb-15">
                                            <div [formGroupName]="j">
                                                <div class="col-md-4">
                                                    <mat-select placeholder="Select Task" class="sortcommonwhite"
                                                        formControlName="type"
                                                        (selectionChange)="onTaskChange($event.value)">
                                                        <mat-option value="call">Make phone calls</mat-option>
                                                        <mat-option value="broadcast">Send broadcast</mat-option>
                                                        <mat-option value="chat">Send custom chat</mat-option>
                                                        <mat-option value="custom_broadcast">Send custom broadcast
                                                        </mat-option>
                                                        <mat-option value="whatsapp_chat">Send whatsapp chat
                                                        </mat-option>
                                                        <mat-option value="whatsapp_call">Make whatsapp call
                                                        </mat-option>
                                                        <mat-option value="clinical_parameter">Get clinical parameter
                                                        </mat-option>
                                                        <mat-option value="data_parameters">Data parameter</mat-option>
                                                        <mat-option value="activity">Activity compilation
                                                        </mat-option>
                                                        <!-- <mat-option value="data_parameters">Get data parameters</mat-option> -->
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <span class="remove-btn" [ngClass]="{hideremovebtn: j==0}">
                                                <i class="material-icons" (click)="removeTask(j)">remove_circle</i>
                                            </span>
                                        </div>
                                        <button mat-icon-button class="plusrectangletransparent mb-15"
                                            (click)="addMoreTask()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <div class="row row-wrapper mb-15">
                                            <div class="col-md-4">
                                                <mat-select placeholder="Select Priority" class="sortcommonwhite"
                                                    formControlName="priority"
                                                    (selectionChange)="onPriority($event.value)">
                                                    <!--[(ngModel)]="taskPriority"-->
                                                    <mat-option value="2">High</mat-option>
                                                    <mat-option value="1">Medium</mat-option>
                                                    <mat-option value="0">Low</mat-option>
                                                </mat-select>
                                            </div>

                                            <div class="col-md-4">
                                                <mat-select placeholder="Select Scope" class="sortcommonwhite"
                                                    formControlName="scope" (selectionChange)="onScope($event.value)">
                                                    <!--[(ngModel)]="taskScope"-->
                                                    <mat-option value="global">Global</mat-option>
                                                    <mat-option value="patient">Patient</mat-option>
                                                </mat-select>
                                            </div>
                                        </div>

                                        <div class="row row-wrapper expiry-wrapper mb-15 d-flex align-items-center">
                                            <div class="col-md-4">
                                                <mat-checkbox class="example-margin" formControlName="has_expiry">Has
                                                    expiry
                                                </mat-checkbox>
                                            </div>
                                            <div class="col-md-4" *ngIf="secondFormGroup?.value?.has_expiry">
                                                <mat-form-field appearance="outline" floatPlaceholder="never">
                                                    <mat-label>Choose a date</mat-label>
                                                    <input matInput [matDatepicker]="picker"
                                                        formControlName="expiry_date" [min]="todaydate"
                                                        datePickerFormat="D MMM, YYYY" (dateChange)="addEvent($event)">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field><!-- [(ngModel)]="expiry_date"-->
                                            </div>

                                        </div>
                                        <div>
                                            <button mat-button matStepperNext [disabled]="!secondFormGroup.valid"
                                                class="btn btn-filled mr-5">Next</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-md-4" *ngIf="showsummaryTab && showTab2">
                                    <div class="view-area">
                                        <div class="preview">
                                            <div class="configure-conditions" *ngIf="showTab2">
                                                <h5>Task summary1</h5>
                                                <div *ngFor="let type of selectedTask">
                                                    <div fxLayout="column" *ngIf="selectedTask">
                                                        <div class="step" style="padding: 10px;
                                        background: rgba(90, 92, 117, .1);
                                        border-radius: 8px;margin-bottom: 10px;">
                                                            <!-- <ng-template matStepperIcon="edit">
                                                <mat-icon>done</mat-icon>
                                            </ng-template> -->
                                                            <div>{{typeChecking(type.type)}}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-30" style="padding: 10px;">
                                                    <div class="col-md-3" *ngIf="taskPriority">
                                                        <h6>Priority</h6>

                                                        <div class="preview-sub text-capitalize">
                                                            {{priorityInList(taskPriority)}}</div>

                                                    </div>
                                                    <div class="col-md-3" *ngIf="taskScope">
                                                        <h6>Scope</h6>
                                                        <div class="preview-sub text-capitalize">
                                                            {{ScopeList(taskScope)}}
                                                        </div>

                                                    </div>
                                                    <div class="col-md-4" *ngIf="expirySelection">
                                                        <h6>Expiry</h6>
                                                        <div class="preview-sub  text-capitalize">{{expirySelection}}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </mat-step>
                        <mat-step>
                            <div class="row">
                                <div class="col-md-6">
                                    <form [formGroup]="thirdFormGroup" autocomplete="off">
                                        <ng-template matStepLabel>Configure template</ng-template>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="language-tab mb-30">
                                                    <div *ngIf="alllanguages" formArrayName="template">
                                                        <mat-tab-group style="position: sticky;" headerPosition="above"
                                                            [(selectedIndex)]="selectedIndex"
                                                            (selectedIndexChange)="changeindex()">
                                                            <mat-tab
                                                                *ngFor="let tab of tempformData.controls; let j = index;"
                                                                [formGroupName]="j" [label]="tab.value.name">
                                                                <!-- [label]="tab.value.name" -->
                                                                <ng-template mat-tab-label>
                                                                    <div>
                                                                        {{tab.value.name}}
                                                                    </div>
                                                                    <button *ngIf="(tab.value.name!='English')"
                                                                        mat-icon-button (click)="closeTab(j,tab)"
                                                                        class="close-btn">
                                                                        <mat-icon style="float: right;">close
                                                                        </mat-icon>
                                                                    </button>
                                                                </ng-template>
                                                                <!-- Contents for {{tab.value.data}} tab -->
                                                                <!-- <mat-form-field class="example-full-width"> -->
                                                                <div class="textareaDiv">
                                                                    <textarea cols="40" [(ngModel)]="textbox"
                                                                        wrap="hard" class="tab-text-area"
                                                                        style="white-space: pre-wrap;"
                                                                        (focus)="onFocus()"
                                                                        formControlName="description"
                                                                        [required]="sendbroadcastflag">
                                                                    </textarea>
                                                                    <!-- <button class="textareabtn"
                                                                        (click)="toggleEmojiPicker()">😀</button>
                                                                    <emoji-mart class="emoji-mart" set="{{set}}"
                                                                        *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                                                                        title="Pick your emoji…"></emoji-mart> -->
                                                                </div>
                                                            </mat-tab>
                                                            <mat-tab disabled *ngIf="alllanguages.length>=0">
                                                                <ng-template mat-tab-label>
                                                                    <span
                                                                        [ngClass]="alllanguages.length == 0?'hideclass':'showclass'">
                                                                        <button mat-button [matMenuTriggerFor]="menu"
                                                                            class="add-">
                                                                            <mat-icon aria-hidden="false"
                                                                                aria-label="Add">add
                                                                            </mat-icon>
                                                                            Add Language
                                                                        </button></span>
                                                                </ng-template>
                                                            </mat-tab>

                                                            <mat-menu #menu="matMenu">
                                                                <button *ngFor="let lang of alllanguages" mat-menu-item
                                                                    (click)="addTab(lang)">{{lang.name}}</button>
                                                            </mat-menu>
                                                        </mat-tab-group>
                                                        <div class="richmedia_dropdown">
                                                            <mat-select placeholder="Rich Media"
                                                                [(value)]="matRichmedia" id="richMediaSelect" required
                                                                (selectionChange)="changeRichMediaType($event);"
                                                                class="richmedia">
                                                                <mat-option *ngFor="let media of mediaType"
                                                                    style="font-weight: bold;"
                                                                    [disabled]="media.isDisabled === true"
                                                                    [Selected]="media.isSelected === true"
                                                                    [value]="media.name"> {{ media.name | titlecase }}
                                                                </mat-option>

                                                                <mat-option (click)="cancelRichMediaSelection()"
                                                                    style="background-color:var(--stepper-inside-bg);">
                                                                    none</mat-option>
                                                            </mat-select>
                                                            <!-- <select id="richMediaSelect" required
                                                                (change)="changeRichMediaType($event.target.value);">
                                                                <option value="" disabled selected>Rich Media</option>
                                                                <option *ngFor="let media of mediaType"
                                                                    [disabled]="media.isDisabled === true"
                                                                    [selected]="media.isSelected === true"
                                                                    [value]="media.name">
                                                                    {{ media.name | titlecase }}</option>
                                                            </select> -->
                                                        </div>
                                                    </div>
                                                    <!-- <div>
                                                        <span>Select Rich Media </span>
                                                        <select id="richMediaSelect" required
                                                            (change)="changeRichMediaType($event.target.value);">
                                                            <option value="" disabled selected>Select one</option>
                                                            <option *ngFor="let media of mediaType"
                                                                [disabled]="media.isDisabled === true"
                                                                [selected]="media.isSelected === true"
                                                                [value]="media.name">
                                                                {{ media.name | titlecase }}</option>
                                                        </select><br>
                                                        <div>
                                                            <font style="color: #237EE9;font-size: 12px;">Supports :
                                                            </font>
                                                            <font style="color: #fa0035;font-size: 12px;">Image
                                                                (.png,.jpg) ~8MB
                                                            </font>
                                                        </div> -->
                                                    <!-- <div class="tooltip_richmedia"> 
                                                            <font style="color:#5A5C75;">Supports</font>
                                                            <font style="color:#237EE9;"> Rich media</font>
                                                            <span class="tooltiptext">Image (.jpeg,.png,.jpg) ~5 MB 
                                                            Audio (.mp3,.wav,.m4a) ~10 MB 
                                                            Video (.mp4,.mov,.avi) ~20 MB. </span>
                                                            </div> -->
                                                    <!-- </div> -->
                                                </div>

                                            </div>
                                        </div>

                                        <div class="row row-wrapper d-flex align-items-center mb-15">
                                            <div class="col-md-4">
                                                <mat-checkbox class="example-margin" formControlName="show_library"
                                                    required>
                                                    Show in
                                                    library
                                                </mat-checkbox>
                                            </div>

                                            <div class="col-md-4">
                                                <mat-select placeholder="Category" class="sortcommonwhite"
                                                    formControlName="category">
                                                    <mat-option value="onboarding">Onboarding</mat-option>
                                                    <mat-option value="logging">Logging</mat-option>
                                                    <mat-option value="activity">Activity</mat-option>
                                                </mat-select>
                                            </div>
                                        </div>

                                        <div>
                                            <button mat-button [disabled]="!thirdFormGroup.valid" (click)="dataObject()"
                                                class="btn mr-5 btn-filled">{{newitem ? 'Create' : 'Update'}}
                                                Task</button>
                                            <!-- stepper.reset() -->
                                        </div>
                                    </form>
                                </div>
                                <div class="col-md-6" *ngIf="enableRichMedia"
                                    style="background: var(--text-area-bg);border-radius: 8px;overflow: hidden;">
                                    <mat-tab-group #richmediatabGrp [selectedIndex]="matTabIndex" class="rich-media"
                                        mat-stretch-tabs>
                                        <mat-tab label="Repository">
                                            <div class="col-md-12" style="overflow: hidden;padding: 0px;">
                                                <!-- <div class="container-fluid"
                                                style="margin-top: 10px;text-align: center;box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;margin-bottom: 10px;">
                                                <div class="row" style="margin-top: 10px;">
                                                    <div class="col-md-12">
                                                        <span
                                                            style="text-align: center;font-size: 14px;font-weight: 400;">Choose
                                                            Rich Media From Library</span>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="row mt-10">
                                                    <div class="col-md-12">
                                                        <span
                                                            style="text-align: center;font-size: 14px;font-weight: 200;">(OR)</span>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="row">
                                                    <div class="col-md-12" style="height: 200px;">
                                                        <div class="clearfix"><br /></div>
                                                        <div class="upload-wrapper">
                                                            <form  [formGroup]="localMediaUpload"
                                                            (ngSubmit)="onFileSelect($event)" validate #demoForm="ngForm">
                                                                <file-upload  formControlName="files"
                                                                fileslimit="0"
                                                                [(ngModel)]="uploadedFiles"></file-upload>
                                                                <div class="row" style="margin-top: -20px;" *ngIf="uploadedFiles != '' ">
                                                                    <div class="col-md-12">
                                                                        <button mat-flat-button
                                                                            color="primary"
                                                                            class="centerbtn"
                                                                            style="margin-top:15px;"
                                                                            (click)="onFileSelect($event)"
                                                                            [disabled]="!disableUploadbtn">Upload
                                                                            File</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                           
                                                        </div>
                        
                                                    </div>
                                                </div> -->
                                                <!-- </div> -->
                                                <!-- this.localMediaUpload.value.files != null -->
                                                <div class="container-fluid" *ngIf="choose_library_media">
                                                    <div class="row">
                                                        <div class="col-md-12" style="margin-top: 10px;">
                                                            <div class="row">
                                                                <form>
                                                                    <div class="form-group has-feedback has-search"
                                                                        style="margin-left:25px">
                                                                        <input [(ngModel)]="searchcriteria"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control"
                                                                            placeholder="Search here"
                                                                            autocomplete="new-password">
                                                                        <button mat-fab class="searchbtn"
                                                                            style="display: none;"
                                                                            (click)="richMedia_livesearch()">
                                                                            <mat-icon matSuffix>search</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <hr>
                                                        <div class="col-md-12 example-container richselectmedia"
                                                            infiniteScroll [infiniteScrollDistance]="1"
                                                            [infiniteScrollThrottle]="50" (scrolled)="getNextSet()"
                                                            [scrollWindow]="false">
                                                            <mat-button-toggle-group
                                                                class="col-md-12 zeropadleft zeropadright"
                                                                #group="matButtonToggleGroup">
                                                                <mat-button-toggle
                                                                    *ngFor="let data of dataSource;let i = index"
                                                                    value="{{data.id}}"
                                                                    class="col-md-4 chooserichmediabtn"
                                                                    (click)="mediaselect(group)">
                                                                    <img style="width:100%;height: 100px;transition: .5s ease;backface-visibility: hidden; opacity: 1;"
                                                                        [src]="richMedaiS3url + data.thumbnail_path" />
                                                                    <div class="middle">
                                                                        <div class="text">{{data.title}}</div>
                                                                    </div>
                                                                    <!-- <p >{{data.title}}</p> -->

                                                                    <div *ngIf="data.id == grpValue" class="centered">
                                                                        <mat-icon
                                                                            [ngStyle]="{'color':'green','font-size': '90px','width':'auto'}">
                                                                            check</mat-icon>
                                                                    </div>
                                                                </mat-button-toggle>
                                                            </mat-button-toggle-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="container-fluid" style="height: 415px;"
                                                    *ngIf="choose_local_media">
                                                    <div class="row"
                                                        style="margin-right: 15px;margin-left: 15px;margin-top: 20px;margin-bottom: 5px;">
                                                        <div class="image-placeholder"
                                                            style="width: -webkit-fill-available; height: auto;">
                                                            <mat-spinner class="upload-spinner" *ngIf="loader">
                                                            </mat-spinner>
                                                            <div class="profile-image">
                                                                <div class="profile-dummy-img">
                                                                    <img _ngcontent-nwm-c37="" class=""
                                                                        src="assets/img/hcassignment/upload-icon.svg">
                                                                    <figcaption>Supported formats: .jpeg, .png, .jpg
                                                                        <br>
                                                                        Max size: ~5 MB
                                                                    </figcaption>
                                                                </div>
                                                                <!-- <img *ngIf="base64File" class="image-data img-responsive" style="margin: 0px;"
                                                              [src]="base64File"> -->
                                                            </div>

                                                            <form [formGroup]="demoForm" (change)="onFileSelect($event)"
                                                                validate [class]="isinvalid ? 'invalidcls' : ''">
                                                                <file-upload formControlName="files" simple
                                                                    accept=".jpeg,.png,.jpg" [(ngModel)]="uploadedFiles"
                                                                    class="file-uplaod-html">
                                                                    <ng-template let-file #placeholder>

                                                                        <ng-container *ngIf="base64File">
                                                                            {{ filename }}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!base64File">
                                                                            Choose a file...
                                                                        </ng-container>

                                                                    </ng-template>

                                                                    <ng-template let-control="control" #button>
                                                                        <i class="material-icons">
                                                                            cloud_upload
                                                                        </i>
                                                                    </ng-template>
                                                                </file-upload>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12"
                                                    style="padding: 5px 0px 0px 0px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
                                                    <!-- <div class="row" style="height: 50px;"> <h5>Upload Local Media</h5></div> -->
                                                    <!-- One click away to upload media from local -->
                                                    <span *ngIf="choose_library_media">Upload local media<button
                                                            class="here"
                                                            (click)="ChooseMediaSelectionType('local')">here</button></span>
                                                    <span *ngIf="choose_local_media">Upload library media<button
                                                            class="here"
                                                            (click)="ChooseMediaSelectionType('repo')">here</button></span>
                                                </div>
                                                <!-- old local file upload UI -->
                                                <!-- <div class="row">
                                                    <div class="col-md-12" style="height: 200px;">
                                                        <div class="clearfix"><br /></div>
                                                        <div class="upload-wrapper">
                                                            <form [formGroup]="localMediaUpload"
                                                                (ngSubmit)="onFileSelect($event)" validate
                                                                #demoForm="ngForm">
                                                                <file-upload formControlName="files" fileslimit="0"
                                                                    [(ngModel)]="uploadedFiles"></file-upload>
                                                                <div class="row" style="margin-top: -20px;"
                                                                    *ngIf="uploadedFiles != '' ">
                                                                    <div class="col-md-12">
                                                                        <button mat-flat-button color="primary"
                                                                            class="centerbtn" style="margin-top:15px;"
                                                                            (click)="onFileSelect($event)"
                                                                            [disabled]="!disableUploadbtn">Upload
                                                                            File</button>
                                                                    </div>
                                                                </div>
                                                            </form>

                                                        </div>

                                                    </div>
                                                </div> -->
                                                <!-- old local file upload UI -->
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Preview">
                                            <!-- <div class="col-md-6" style="text-align: left;padding-left: 15px;">
                                                    <mat-select [(value)]="matRichmedia" id="richMediaSelect" required
                                                            (selectionChange)="selectNotifyType($event);"
                                                                placeholder="Previewtype" class="richmedia">
                                                                <mat-option *ngFor="let notify of notification"
                                                                style="font-weight: bold;"
                                                                [disabled]="notify.isDisabled === true"
                                                                [Selected]="notify.isSelected === true"
                                                                [value]="notify.name"> {{ notify.name | titlecase }}</mat-option>
                                                            </mat-select>
                                                </div> -->
                                            <!-- <div class="col-md-12 mt-1" style="text-align: right;">
                                                    <button mat-button  class="btn btn-filled">Edit</button>
                                                </div> -->
                                            <div class="col-md-12 iphone-holder-height-rich-media ">
                                                <div class="iphonex-rich-media">
                                                    <div id="iphonebox">
                                                        <div class="content-inside">
                                                            <mat-card class="card">

                                                                <!-- Style 1 for notification -->
                                                                <!-- <div class="row" style="background: #b4b4b442;margin-top: -8px;">
                                                                <div class="text-right col-md-12 offset-md-12"
                                                                    style="padding-right: 21px;font-size: 8px;padding-top: 10px;">
                                                                    1min ago
                                                                </div>
                                                            </div>
                                                            <div class="row" style="background: #b4b4b442;">
                                                                <div class="col-md-12" style="margin-top: -25px;"><img
                                                                        src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                        style="width: 40px;margin-top: 10px; margin-left: 0px;margin-bottom: 5px;border-radius: 50%;">
                                                                    <span style="margin-top: 25px;"
                                                                        class="aligned-with-icon"> Wellthy Notification !!</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <audio controls
                                                                *ngIf="path && mediatype === 'audio'">
                                                                <source [src]="richMedaiS3url + this.path"
                                                                    type="{{mimeType}}">
                                                            </audio>
                                                            <video class="video-js vjs-big-play-centered"
                                                                style="width:100%;height: 250px;" controls
                                                                preload="auto"
                                                                data-setup='{"techOrder": ["flash"]}'
                                                                *ngIf="path && mediatype === 'video'">
                                                                <source [src]="richMedaiS3url + this.path"
                                                                    type="{{mimeType}}">
                                                            </video>
                        
                                                            <img *ngIf="path && mediatype === 'image'"
                                                                class="mat-card-image1"
                                                                [src]="richMedaiS3url + this.path">
                                                            <img *ngIf="base64File" class="mat-card-image1"
                                                                [src]="base64File">
                                                            <img *ngIf="previewRichMediaLink && (path === '') "
                                                                class="mat-card-image1"
                                                                [src]="richMedaiS3url + previewRichMediaLink">
                        
                                                            <hr>
                                                            <mat-card-content>
                                                                <p>
                                                                    {{textbox}}
                                                                </p>
                                                            </mat-card-content> -->
                                                                <!-- Style 1 for notification -->
                                                                <div class="row" style="display: flex; ">
                                                                    <div class="col-md-6">
                                                                        <img src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                                            style="width: 10px;
                                                                        margin-top: 0px;
                                                                        margin-left: 6px;
                                                                        margin-bottom: 2px;">
                                                                        <span
                                                                            style="font-size: 10px;margin-left: 2px;color: grey;">Wellthy
                                                                            &#x2022; Now</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row"
                                                                    style="display: flex;margin-right: -15px; ">
                                                                    <div class="col-md-8"
                                                                        style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                                                        <span
                                                                            style="font-size: 12px;">{{textbox}}</span><br>
                                                                        <span style="font-size: 10px;color: grey;">Sent
                                                                            an attachment</span>
                                                                    </div>
                                                                    <div class="col-md-4" style="margin-left: 20px;">
                                                                        <img *ngIf="path && mediatype === 'image'"
                                                                            [src]="richMedaiS3url + this.path"
                                                                            style="width: 40px;text-align: -webkit-right;height: 35px;margin-left: 20px;">
                                                                        <img *ngIf="base64File" [src]="base64File"
                                                                            style="width: 40px;text-align: -webkit-right;height: 35px;margin-left: 20px;">
                                                                        <img *ngIf="previewRichMediaLink && (path === '') "
                                                                            [src]="richMedaiS3url + previewRichMediaLink"
                                                                            style="width: 40px;text-align: -webkit-right;height: 35px;margin-left: 20px;">
                                                                    </div>
                                                                </div>
                                                            </mat-card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </div>
                        </mat-step>
                        <!-- </form> -->
                    </mat-vertical-stepper>
                </div>
                <!-- Rich media  *ngIf="richMediaType" -->
                <!-- <div class="col-md-4" *ngIf="richMediaType || previewRichMediaLink"
                    style="background: var(--text-area-bg);border-radius: 8px;box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;">
                    <mat-tab-group [selectedIndex]="matTabIndex" class="rich-media" mat-stretch-tabs>
                        <mat-tab label="Repository">
                            <div class="col-md-12">
                                <div class="container-fluid" style="margin-top: 10px;text-align: center;">
                                    <mat-radio-group aria-label="Select an option" (change)="chooseRadiobutton($event)">
                                        <mat-radio-button value="0" checked>Choose Media From Library
                                        </mat-radio-button>
                                        <span>&nbsp;&nbsp;(or)</span>
                                        <mat-radio-button value="1" style="margin-left: 10px;">Choose Media From Local
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="row">
                                        <div *ngIf="path || base64File " class="col-md-12"
                                            style="text-align:center; margin-top: 10px;">
                                            <button mat-button class="btn mr-5 btn-filled"
                                                (click)="attachRichMedia()">Attach Media</button>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="container-fluid" *ngIf="choose_library_media">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <form>
                                                    <div class="form-group has-feedback has-search">
                                                        <input [(ngModel)]="searchcriteria"
                                                            [ngModelOptions]="{standalone: true}" class="form-control"
                                                            placeholder="Search here" autocomplete="new-password">
                                                        <button mat-fab class="searchbtn" style="display: none;"
                                                            (click)="richMedia_livesearch()">
                                                            <mat-icon matSuffix>search</mat-icon>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="col-md-12 example-container selectmedia" infiniteScroll
                                            [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                                            (scrolled)="getNextSet()" [scrollWindow]="false">
                                            <mat-button-toggle-group class="col-md-12 zeropadleft zeropadright"
                                                #group="matButtonToggleGroup">
                                                <mat-button-toggle *ngFor="let data of dataSource;let i = index"
                                                    value="{{data.id}}" class="col-md-4 choosemediabtn"
                                                    (click)="mediaselect(group)">
                                                    <img style="width:100%;max-height:140px;"
                                                        [src]="s3url + data.thumbnail_path" />
                                                    <p>{{data.title}}</p>
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid" *ngIf="choose_local_media">
                                    <div class="row" style="margin-right: 15px;margin-left: 15px;">
                                        <div class="form-group">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 100%;" floatPlaceholder="never">
                                                    <input [hidden]="true" (change)="onFileSelect($event)" type="file"
                                                        accept="all/*" #fileSelect>
                                                    <input readonly matInput [(ngModel)]="filename"
                                                        placeholder="Select file..." />
                                                    <button *ngIf="this.base64File"
                                                        (click)="this.base64File = null; this.filename = null" matSuffix
                                                        matTooltip="Remove selected file" [matTooltipPosition]="'above'"
                                                        type="button" color="warn" mat-icon-button>
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    <button matSuffix mat-icon-button (click)="fileSelect.click();"
                                                        matTooltip="Select a file" [matTooltipPosition]="'above'">
                                                        <mat-icon>file_upload</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-12 m-t" *ngIf="base64File">
                                            <table class="table table-bordered table-stripped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            preview
                                                        </th>
                                                        <th>
                                                            file type
                                                        </th>
                                                        <th>
                                                            file size
                                                        </th>
                                                        <th>
                                                            action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img style="width: 100px" [src]="base64File">
                                                        </td>
                                                        <td>
                                                            {{fileType}}
                                                        <td dir='ltr'>

                                                            {{(filesize/1000)}} KB
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-danger"
                                                                (click)="this.base64File = null; this.filename = null"><i
                                                                    class="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Preview">
        
                            <div class="col-md-12 iphone-holder-height-rich-media ">
                                <div class="iphonex-rich-media">
                                    <div id="iphonebox" *ngIf="this.path || previewRichMediaLink || base64File">
                                        <div class="content-inside">
                                            <mat-card class="card">
                                                <div class="row">
                                                    <div class="col-md-10"><img
                                                            src="https://s3.ap-south-1.amazonaws.com/gene-media-manager/user_profile/media/ae01d708-c350-464e-8c37-a70ebe8a3205_icon_care.png"
                                                            style="width: 40px;margin-top: 20px;margin-left: 10px;">
                                                        <span style="margin-top: 30px;" class="aligned-with-icon">
                                                            Wellthy Notification !!</span>
                                                    </div>
                                                    <div class="text-right col-md-2 offset-md-2"
                                                        style="padding-right: 15px;">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                </div>
                                                <hr>
                                                <audio controls *ngIf="path && mediatype === 'audio'">
                                                    <source [src]="s3url + this.path" type="{{mimeType}}">
                                                </audio>
                                                <video class="video-js vjs-big-play-centered"
                                                    style="width:100%;height: 250px;" controls preload="auto"
                                                    data-setup='{"techOrder": ["flash"]}'
                                                    *ngIf="path && mediatype === 'video'">
                                                    <source [src]="s3url + this.path" type="{{mimeType}}">
                                                </video>

                                                <img *ngIf="path && mediatype === 'image'" class="mat-card-image1"
                                                    [src]="s3url + this.path">
                                                <img *ngIf="base64File" class="mat-card-image1" [src]="base64File">
                                                <img *ngIf="previewRichMediaLink && (path === '') "
                                                    class="mat-card-image1" [src]="s3url + previewRichMediaLink">

                                                <hr>
                                                <mat-card-content>
                                                    <p>
                                                        {{textbox}}
                                                    </p>
                                                </mat-card-content>
                                                <hr>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div> -->
            </div>
        </div>
    </div>
</mat-drawer-container>