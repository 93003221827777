import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../../shared/shared.module';
import { DocleftnavComponent } from './docleftnav.component';

import { AlertsService } from './../../services/alerts/alerts.service';
import { DoctorDataService } from './../../services/doctor/doctor.service';
import { PatientDataService } from './../../services/patient/patient.service';
import { UserListService } from './../../services/userlist/user-list.service';

@NgModule({
  declarations: [
    DocleftnavComponent
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserListService,
    AlertsService,
    PatientDataService,
    DoctorDataService
  ],
  exports:[
    DocleftnavComponent
  ]
  
})
export class DocleftnavModule { }
