import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { DietPlanpdfreportComponent } from './dietplanpdfreport.component';
import { DietPlanpdfreportRoutingModule } from './dietplanpdfreport.routing.module';

@NgModule({
  declarations: [
    DietPlanpdfreportComponent,
  ],
  imports: [
    CommonModule,
    DietPlanpdfreportRoutingModule,
    SharedModule.forRoot()
  ],
  providers: [
  ],
})
export class DietPlanpdfreportModule { }