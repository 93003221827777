import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hctasksemiauto',
  templateUrl: './hctasksemiauto.component.html',
  styleUrls: ['./hctasksemiauto.component.scss']
})
export class HctasksemiautoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
