<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} {{widgetname}}
            </h3>
            <div class="clearfix">
                <br />
                <br>
            </div>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="col-md-12 leftformsection">
                        <div class="row">
                            <div *ngIf="showparamval(0);">
                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Pre Text Count</mat-label>
                                        <mat-select formControlName="pre_text_count"
                                            (selectionChange)="changeOptions($event.value,'pre_text')" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                            <mat-option value="9">9</mat-option>
                                            <mat-option value="10">10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="pretextcount">
                                    <div class="col-md-12 zeropadleft" formArrayName="pre_text">
                                        <div *ngFor="let optn of dataForm.controls.pre_text.controls; let j=index;"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <mat-form-field appearance="outline"
                                                class="full-width-formfield featureimage">
                                                <mat-label>Pre Text</mat-label>
                                                <input matInput placeholder="Enter the pre text"
                                                    formControlName="pretxtoptn" required>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showparamval(1);">
                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Post Text Count</mat-label>
                                        <mat-select formControlName="post_text_count"
                                            (selectionChange)="changeOptions($event.value,'post_text')" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                            <mat-option value="9">9</mat-option>
                                            <mat-option value="10">10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="posttextcount">
                                    <div class="col-md-12 zeropadleft" formArrayName="post_text">
                                        <div *ngFor="let optn of dataForm.controls.post_text.controls; let j=index;"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <mat-form-field appearance="outline"
                                                class="full-width-formfield featureimage">
                                                <mat-label>Post Text</mat-label>
                                                <input matInput placeholder="Enter the post text"
                                                    formControlName="posttxtoptn" required>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 zeropadleft" *ngIf="showparamval(2);">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter the title" formControlName="title" required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft" *ngIf="showparamval(3);">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Sub title</mat-label>
                                    <input matInput placeholder="Enter the sub title" formControlName="sub_title"
                                        required>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 zeropadleft" *ngIf="showparamval(4);">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Image Text</mat-label>
                                    <input matInput placeholder="Enter the image title" formControlName="image_text"
                                        required>
                                </mat-form-field>
                            </div>

                            <div *ngIf="showparamval(5);">
                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Skip Text Count</mat-label>
                                        <mat-select formControlName="skip_text_count"
                                            (selectionChange)="changeOptions($event.value,'skip_text')" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                            <mat-option value="9">9</mat-option>
                                            <mat-option value="10">10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="skiptextcount">
                                    <div class="col-md-12 zeropadleft" formArrayName="skip_text">
                                        <div *ngFor="let optn of dataForm.controls.skip_text.controls; let j=index;"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <mat-form-field appearance="outline"
                                                class="full-width-formfield featureimage">
                                                <mat-label>Skip Text</mat-label>
                                                <input matInput placeholder="Enter the skip text"
                                                    formControlName="skiptxtoptn" required>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 zeropadleft zeromarginleft">
                                <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                                    [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} {{widgetname}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-drawer-container>