import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, AuthenticateService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../services/context.service';

@Injectable({
  providedIn: 'root'
})
export class UserlistService extends HttpService {
  public sessionID: string;
  public apiUrl: string = environment.serverUrl;
  //public apiUrl: string = 'http://192.168.1.100:3000/';
  public graphql: any = environment.gqlUrl + 'gql?query=query';
  private clientListAPI = this.apiUrl + 'clients';
  private hcListAPI = this.apiUrl + 'healthcoach';
  private transferurl = this.apiUrl + 'healthcoach/transfer';
  private gettagurl = this.apiUrl + 'hc_patient_tags';
  private getalltagslist = this.apiUrl + 'hc_patient_tags/getall/data';
  private getalltagslistforhc = this.apiUrl + 'hc_patient_tags/getall/forhc';
  private getusertagurl = this.apiUrl + 'patient/tag';
  private getuserassigntagurl = this.apiUrl + 'patient/tag/map';
  private getusertagremoveurl = this.apiUrl + 'patient/tag/delete';
  private getuserfortagurl = this.apiUrl + 'healthcoach/tag/users';
  private _prop:any={};
  public propchanged:BehaviorSubject<string> = new BehaviorSubject<string>(this._prop);

 
  constructor(private http: HttpClient, private appContext: ContextService, private authService: AuthenticateService ) {
    super(http)
    this.appContext.sessionIDToken.subscribe(sessionID => {
      this.sessionID = sessionID;
    });
  }

  public getHealthCoachUserList(): Observable<any> {
    return this.get(this.hcListAPI)
  }

  public getHealthCoachClients(): Observable<any> {
    return this.http.get(this.clientListAPI)
  }

  transferUserToHc(userid, coachid): Observable<string> {
    var jsondt = {
      'patientId': userid.toString(),
      'newHcId': coachid.toString(),
      'oldHcId': localStorage.getItem('scarletuserid').toString()
    }
    return this.post(this.transferurl, jsondt)
  }

  public updateAccountStatus(currentStatus: boolean): Observable<any> {
    const params: any = {
      "is_active": currentStatus
    };
    return this.http.put<any>(this.hcListAPI, params)
  }
  public getalltags(): Observable<any> {
    return this.get(this.getalltagslist)
  }

  public getalltagsForHc(): Observable<any> {
    return this.get(this.getalltagslistforhc + '/' + localStorage.getItem('scarletuserid'))
  }

  hardDelete(id, useridfk): Observable<string> {
      return this.delete(this.apiUrl + 'hc_patient_tags/delete/' + id,{user_id_fk: useridfk})
  }

  public setStatus(isactive, id, useridfk): Observable<string> {
      let bodydata = {
        user_id_fk: useridfk
      }
      if (isactive) {
        return this.delete(this.apiUrl + 'hc_patient_tags/' + id, bodydata)
      }
      else {
        return this.patch(this.apiUrl + 'hc_patient_tags/restore/' + id, bodydata)
      }
  }

  public getalltagssearchByHc(typetext, page, limit, status): Observable<any> {
    typetext = this.removeHTMLtags(typetext);
    const params: any = {
      q: typetext,
      page: page,
      limit: limit,
      status: status
    };
    return this.post(this.gettagurl + '/search/forhc/' + localStorage.getItem('scarletuserid'), params)
  }

  public getuserfortags(tag_id): Observable<any> {
    return this.get(this.getuserfortagurl)
  }
  public createTag(name): Observable<any> {
    name = this.removeHTMLtags(name);
    const params: any = {
      "title": name,
      "detailed_text": null,
      "color": null,
      "created_by_data_fk": parseInt(localStorage.getItem('scarletuserid')),
      "language_data_fk": 1,
      "self_parent_fk": null
    };
    return this.post(this.gettagurl, params)
  }
  public deleteTag(id): Observable<any> {
    return this.delete(this.gettagurl)
  }
  public deleteUserTag(userid, id): Observable<any> {
    var body = {
      "patient_id_data_fk" : userid,
      "tag_id_data_fk" : id
    }
    return this.post(this.getusertagremoveurl,body)
  }
  public getusertags(id): Observable<any> {
    return this.get(this.getusertagurl + '/' + id)
  }
  public saveUserTag(userID, tag): Observable<any> {
    var body = {
      "patient_id_data_fk" : userID,
      "tag_id_data_fk" : tag
    }
    return this.post(this.getuserassigntagurl, body)
  }
  
  public getGraphQlData(parameter: string, org?): Observable<any> {
    if (org != false && environment.org) {
      var orgdata = 'status:true,organization_data:"' + this.authService.getorg() + '"';
      parameter = parameter.replace('status:true', orgdata)
    }
    return this.get(encodeURI(this.graphql + parameter))
  }

  private __patientData = new BehaviorSubject<any>(null);
  current___patientData= this.__patientData.asObservable();
  
  change_patientData(patientData:any){
    this.__patientData.next(patientData)
  }

  private __selectedpatient = new BehaviorSubject<any>(null);
  current_____selectedpatient= this.__selectedpatient.asObservable();
  
  change___selectedpatient(patientData:any){
    this.__selectedpatient.next(patientData)
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
