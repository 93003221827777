import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdminleftnavModule } from '../../shared/adminleftnav/adminleftnav.module';
import { AdminsectionleftnavModule } from '../../shared/adminsectionleftnav/adminsectionleftnav.module';
import { HcassignleftnavModule } from '../../shared/hcassignleftnav/hcassignleftnav.module';
import { SharedModule } from './../../../shared/shared.module';
import { CampaignComponent } from './campaign.component';
import { CampaignRoutingModule } from './campaign.routing.module';
import { CampaignqrComponent } from './campaignqr/campaignqr.component';
import { CampaignviewComponent } from './campaignview/campaignview.component';
import { CreatecampaignComponent } from './createcampaign/createcampaign.component';
import { CampaigndeeplinkComponent } from './campaigndeeplink/campaigndeeplink.component';
import { CampaignHistoryComponent } from './campaign-history/campaign-history.component';


@NgModule({
  declarations: [
    
    CampaignComponent,
    CampaignqrComponent,
    CampaignviewComponent,
    CreatecampaignComponent,
    CampaigndeeplinkComponent,
    CampaignHistoryComponent,
  ],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    ImageCropperModule,
    HcassignleftnavModule,
    AdminsectionleftnavModule,
    AdminleftnavModule,
    SharedModule.forRoot()
  ],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class CampaignModule {}