import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentGuardGuard } from './../../shared/guards';
import { EditlevelComponent } from './editlevel/editlevel.component';
import { LevelComponent } from './level.component';
import { CreateRequestComponent } from './create-request/create-request.component';
import { LeveldifferenceComponent } from './leveldifference/leveldifference.component';

const routes: Routes = [
    { path: '', component: LevelComponent, canActivate: [ContentGuardGuard] },
    { path: 'level/edit/:id/:langid/:chid', component: EditlevelComponent, canActivate: [ContentGuardGuard] },
    { path: 'level/difference/:id', component: LeveldifferenceComponent, canActivate: [ContentGuardGuard] },
    { path: 'level/createrequest/:id', component: CreateRequestComponent, canActivate: [ContentGuardGuard] },
    
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LevelRoutingModule { }
