import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HcleftnavModule } from '../shared/hcleftnav/hcleftnav.module';
import { SharedModule } from './../../shared/shared.module';
import { ContextService as HealthCoachContextService } from './../services/context.service';
import { HctagsComponent } from './hctags.component';
import { HctagsRoutingModule } from './hctags.routing.module';

@NgModule({
  declarations: [
    HctagsComponent
  ],
  imports: [
    CommonModule,
    HctagsRoutingModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    HcleftnavModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class HctagsModule { }
