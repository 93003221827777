import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-edit-notes',
  templateUrl: './edit-notes.component.html',
  styleUrls: ['./edit-notes.component.scss']
})

export class EditNotesComponent implements OnInit {

  editNotes: String;
  oldString: String;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialogRef: MatDialogRef<EditNotesComponent>,
    private userDetailsService: UserdetailsService,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {
    this.editNotes = this.data.note;
    this.oldString = this.data.note;
  }

  close(){
    this.dialogRef.close(false);
  }

  saveEditNote(){

    this.userDetailsService.saveEditNotes(this.data.userId, this.data.hcId, this.data.id, this.editNotes, this.data.note)
      .subscribe((data) => {
        if (data.status == 200){
          this.systemService.showSuccessSnackBar("Edit Saved Successfully", "Ok");
        }

        this.dialogRef.close(true);
      }, (error) => {
        this.systemService.handleErrorResponse(error);
      })

  }

}
