import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SharedModule } from './../../../shared/shared.module';
import { UserlistnavbarComponent } from './userlistnavbar.component';
import { ContextService as HealthCoachContextService } from './../../services/context.service';
import { sendUserBroadcastModule } from '../senduserbrodcast/senduserbrodcast/senduserbroadcast.module';
import { userTransferModule } from '../usertransfer/usertransfer/usertransfer.module';
//import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    UserlistnavbarComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
   // HighchartsChartModule,
    // sendUserBroadcastModule,
    // userTransferModule,
    SharedModule.forRoot()
  ],
  providers: [
    HealthCoachContextService
  ],
  exports: [
    UserlistnavbarComponent, 
    //HighchartsChartModule
  ]
  /*entryComponents: [
    ViewbroadcastComponent,
    MealbreakupComponent,
    LabreportdialogComponent,
    DiaryLogDialogComponent,
    ShowmodalComponent,
    ChatbotmodalComponent,
    LogtagdialogComponent,
    AddmedicineComponent,
    UploadprescriptionaldialogComponent,
    AddreminderComponent,
    AddsymptomsComponent,
    AlltriggersComponent,
    DeeplinkComponent,
    PaymentlinkComponent,
    CareyChatComponent
  ]*/
})
export class UserlistnavbarModule { }
