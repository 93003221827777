import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { Userlistinterface } from '../../../models/userlistinterface';
import { ContextService } from '../../../services/context.service';
import { SocketService } from '../../../services/socket/socket.service';
import { UserlistService } from '../../../services/userlist/userlist.service';


@Component({
  selector: 'app-usertransfer',
  templateUrl: './usertransfer.component.html',
  styleUrls: ['./usertransfer.component.scss']
})
export class UsertransferComponent implements OnInit {
  public hcSubject$ = new BehaviorSubject<Userlistinterface[]>(null);
  public hcFilterData: Userlistinterface[] = [];
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public loadingError$ = new Subject<boolean>();
  public userTransferForm: FormGroup;
  public userlist: any;
  public hashmapdata : any;
  private progressmessage: any;
  private progressval : any;
  constructor( private appContext: ContextService, private userlistService: UserlistService,
    private systemService: SystemService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    /**Subscribe to user selection changes */
    this.appContext.multiUserSelectionUsers.subscribe(data => {
      if(data != null){
        this.userlist = data;
      }
    });
    /**Build form */
    this.buildUserTransferForm();
  }

  ngAfterViewInit() {
    /**Load hc data */
    this.loadHCData();
  }

  buildUserTransferForm(): void {
    this.userTransferForm = this.formBuilder.group({
      healthcoach: ['', Validators.required]
    });
  }

  startUserTransfer(): void {
    var scope = this;
    var count = 0;
    if(this.userlist == undefined || this.userlist.length == 0)
    {
      this.systemService.showSnackBar("Oops, Kindly select user whome you want to transfer.", "Ok");
    }
    else
    {
      this.userlist.forEach(element => {
      scope.userlistService.transferUserToHc(element.user_id,this.userTransferForm.value.healthcoach)
      .subscribe(
        (data) => {
            count = count + 1;
            if(count == scope.userlist.length)
            {
              this.systemService.showSnackBar("Users Transferred Successfully", " ");
              setTimeout(function(){ 
                window.location.reload();
              }, 200);
            }
        }, error=>{
          this.progressmessage = "User Transferred Failed";
        })
      })
    }
  }

  loadHCData(): void{
    this.loadingSubject.next(false);
    this.userlistService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id, name}}')
        .pipe(
          catchError((error: any) => {
            this.loadingSubject.next(true);
        this.systemService.showSnackBar("Sorry, unable to load health coaches.", "Ok");
        return throwError(error);
          }),
          finalize(() => { this.loadingSubject.next(true); })
        ).subscribe(
          (data) => {
            data = (data as any).data;
            data = data.getAllHealthcoaches;
            this.loadingSubject.next(true);
            let excludeID = localStorage.getItem('scarletuserid');
            data = data.filter(user => {return (user.id).toString() != excludeID});
            this.hcFilterData = data;
          });
  }

}
