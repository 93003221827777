import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';
import { UserlistService } from '../../services/userlist/userlist.service';
@Component({
  selector: 'app-addsymptoms',
  templateUrl: './addsymptoms.component.html',
  styleUrls: ['./addsymptoms.component.scss']
})
export class AddsymptomsComponent implements OnInit {
  public selectedUserID: any;
  public showButtonLoader: boolean;
  public mastercondition: any;
  public schedule_data: any;
  public allsymptoms: any;
  public maxdate = new Date();
  public dataForm = this.fb.group({
    patient_id_data_fk: [],
    symptom_ids: this.fb.array([]),
    log_date: [Validators.required],
    symptom_ids_count: [],
    longitude: ['19.0760'],
    latitude: ['72.8777'],
    location_name: ['Mumbai'],
    logged_by: ['hc'],
    language_data_fk: ['2']
  });

  constructor(public dialog: MatDialog, private userlistService: UserlistService, private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder, private systemService: SystemService) { }

  ngOnInit() {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.dataForm.get('patient_id_data_fk').setValue(this.selectedUserID.toString())
    this.getUserCondition(this.selectedUserID)
  }

  initTypeOptions(ide) {
    return this.fb.group({
      value: [],//'none'
      id: [ide]//ide
    });
  }
  getUserCondition(id) {
    this.userlistService
      .getGraphQlData('{getOnePatient(appMode:false,patientId:' + id + '){therapy_id {master_condition {id, title}}}}')
      .pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        this.mastercondition = data;
        this.mastercondition = this.mastercondition.getOnePatient.therapy_id.master_condition.id;
        this.getSymptoms(this.mastercondition, 1)
      })
  }
  getSymptoms(a, b) {
    this.UserdataService.getSymptoms(a, b).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {

        this.allsymptoms = data;
        this.allsymptoms = this.allsymptoms.data;
        const control1 = <FormArray>this.dataForm.controls['symptom_ids'];
        for (let l = control1.value.length; l < this.allsymptoms.length; l++) {
          control1.push(this.initTypeOptions(this.allsymptoms[l].id));
        }

      })
  }

  dataObject() {
    let symptom_idsData: any = [];
    symptom_idsData = this.dataForm.value.symptom_ids;
    const filterUnwanted = (symptom_idsData) => {
      const required = symptom_idsData.filter(el => {
        return el.value;
      });
      return required;
    };
    this.dataForm.get('symptom_ids_count').setValue(filterUnwanted(symptom_idsData).length.toString());
    var postdata = this.dataForm.value;
    var datum = this.dataForm.value.log_date.toUTCString();
    postdata.log_date = moment(datum).toISOString();
    postdata['created_by_fk'] = localStorage.getItem('scarletuserid')
    postdata.symptom_ids = JSON.stringify(filterUnwanted(symptom_idsData));
    
    this.UserdataService.addSymptoms(postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        postdata.symptom_ids = JSON.parse(postdata.symptom_ids)
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.systemService.showSnackBar("Symptoms added sucessfully.", "OK");
        this.showButtonLoader = false;
      })
  }
}

export enum schedule_data {
  medicine,
  meal,
  activity,
  water
}




