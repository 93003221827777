
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';

@Component({
  selector: 'app-delete-ae',
  templateUrl: './delete-ae.component.html',
  styleUrls: ['./delete-ae.component.scss']
})
export class DeleteAEComponent implements OnInit {
  public dataVal: any;
  public deleteForm = this.fb.group({
  delete_reason: ['', Validators.required]
});
 

  constructor(public dialog: MatDialog, private UserdataService: UserdetailsService, public fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any,private systemService: SystemService,private commonService:CommonserviceService) { }
  ngOnInit() {
    this.dataVal = this.data
  }
  onNoClick(): void {
    this.dialog.closeAll();
  }
  deleteEvent() {
    var postdata = this.deleteForm.value
    postdata.delete_reason = this.deleteForm.value.delete_reason;
    let delete_reason = this.commonService.isHTML( this.deleteForm.value.delete_reason);
   
    if(delete_reason  === true){
      this.deleteForm.value.delete_reason.patchValue('')
      return;
    }
     this.UserdataService.deleteAEEvent(this.dataVal,postdata).pipe(
      catchError((error: any) => {
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.systemService.showSuccessSnackBar("Adverse Event has been deleted!.", "OK");
      })
  }

}

