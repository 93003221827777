import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

declare var videojs: any;


@Component({
  selector: 'app-viewmedia',
  templateUrl: './viewmedia.component.html',
  styleUrls: ['./viewmedia.component.scss']
})

export class ViewmediaComponent implements AfterViewInit, OnInit, OnDestroy {
  userid: any;
  sub: any;
  mediaView: any;
  s3url: any = environment.s3url;
  id: string;
  file: any = null;
  btnactive: boolean = false;
  public responsethumbUrl: any;
  public demoForm = this.fb.group({
    file: new FormControl(null)
  });

  newImageSource = {
    selected: false, 
    url: undefined,
    previewUrl: undefined
  }

  private videoJSplayer: any;
  private mediaType: any;

  constructor(
    private browserTitle: Title,
    public dialog: MatDialog, 
    public fb: FormBuilder, 
    private route: ActivatedRoute, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private systemService: SystemService, 
    private contentService: ContentserviceService, 
    private dialogRef: MatDialogRef<ViewmediaComponent>,
    private commonService:CommonserviceService,
    private sanitizer: DomSanitizer,
  ) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Media');
  }

  ngOnInit() {
    this.setSEOTags();
    this.mediaView = null;
    this.mediaView = this.data.element;

    this.mediaType = this.data.mediaType;

    this.sub = this.route.params.subscribe(params => {
      this.userid = params['id'];
      if(this.mediaType === '0'){
        this.s3url = environment.s3url;
      }else if(this.mediaType === '1'){
        this.s3url = environment.richMedia;
      }else{
        this.s3url = environment.s3url;
      }
    });

    var inputs = document.querySelectorAll('.file-input')
    for (var i = 0, len = inputs.length; i < len; i++) {
      customInput(inputs[i])
    }

    function customInput(el) {
      const fileInput = el.querySelector('[type="file"]')
      const label = el.querySelector('[data-js-label]')

      fileInput.onchange =
        fileInput.onmouseout = function () {
          if (!fileInput.value) return

          var value = fileInput.value.replace(/^.*[\\\/]/, '')
          el.className += ' -chosen'
          label.innerText = value
        }
    }
  }

  copyURL() {
    var abc = this.s3url + this.mediaView.path;
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = abc;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }

  ngAfterViewInit() {
    if (this.mediaView.mime == "video/x-flv") { this.initVideoJs(); }
  }

  initVideoJs() {
    this.videoJSplayer = videojs('video_player');
    this.videoJSplayer = videojs('video/x-flv');
  }

  ngOnDestroy() {
    if (this.mediaView.mime == "video/x-flv") { this.videoJSplayer.dispose(); }

    if (this.newImageSource.selected){
      URL.revokeObjectURL(this.newImageSource.previewUrl);
    }

  }

  imageUpload(e) {
    this.file = e.target.files[0];
    this.btnactive = true;

    this.newImageSource.selected = true;
    this.newImageSource.previewUrl = URL.createObjectURL(this.file);
    this.newImageSource.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.newImageSource.previewUrl);

  }

  uploadObject() {
    this.dialogRef.disableClose = true;
    var newname = (+ new Date()) + localStorage.getItem('scarletuserid') + '.' + this.file.name.split('.').pop()
    var newfile = new File([this.file], newname);
    var thpath = 'healthcoach/media';
    
    if(this.mediaType === '0'){
      this.contentService.getS3URL(thpath, this.file.type, this.file.size, newfile.name)
      .subscribe(
        (data) => {
          this.responsethumbUrl = data;
          this.contentService.uploadToS3(newfile, this.responsethumbUrl.data.signed_request)
            .subscribe((data) => {
                this.contentService.updateThumbnail(this.responsethumbUrl.data.path, this.mediaView.id, this.mediaView.created_by_data_fk)
                  .subscribe(data => {
                    var dte = {
                      "id": this.mediaView.id,
                      "path": this.responsethumbUrl.data.path
                    }
                    this.dialogRef.close(dte);
                    this.systemService.showSnackBar("Thumbnail has been updated", "OK");
                  })
              }, (error) => {
                this.systemService.showSnackBar("Unable to upload files.", "Ok");
              })
        })
    }else if(this.mediaType === '1'){
      this.contentService.getRichMediaS3URL(thpath, this.file.type, this.file.size, newfile.name)
      .subscribe(
        (data) => {
          this.responsethumbUrl = data;
          this.commonService.RichMediauploadToS3(newfile, this.responsethumbUrl.data.signed_request)
            .subscribe(
              (data) => {
                this.contentService.updateThumbnail(this.responsethumbUrl.data.path, this.mediaView.id, this.mediaView.created_by_data_fk)
                  .subscribe(data => {
                    var dte = {
                      "id": this.mediaView.id,
                      "path": this.responsethumbUrl.data.path
                    }
                    this.dialogRef.close(dte);
                    this.systemService.showSnackBar("Thumbnail has been updated", "OK");
                  })
              }, (error) => {
                this.systemService.showSnackBar("Unable to upload files.", "Ok");
              })
        })

    }else{
      this.s3url = environment.s3url;
    }

  }
}
