import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FileUploadValidators } from '@iplab/ngx-file-upload';

@Component({
  selector: 'app-mediainformation',
  templateUrl: './mediainformation.component.html',
  styleUrls: ['./mediainformation.component.scss']
})

export class MediainformationComponent implements OnInit {

  public imageData: any;
  public video: any;
  public audio: any;
  public videomimeType: any;
  public videosource: any;
  public audiosource: any;
  public showprev: any;
  public mediainfoForm = this.fb.group({
    mediadatas: this.fb.array([])
  });

  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));

  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;

  // Validator For Whitespaces
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  mediadataarray(url, type) {
    return this.fb.group({
      url: [url],
      type: [type],
      title: ["", [Validators.required, this.noWhitespaceValidator]],
      detailed_text: [""]
    })
  }

  mediaaudiodataarray(url, type) {
    return this.fb.group({
      url: [url],
      type: [type],
      title: ["", [Validators.required, this.noWhitespaceValidator]],
      detailed_text: [""],
      audio_thumb: this.filesControl
    })
  }
  
  constructor(
    private browserTitle: Title, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public fb: FormBuilder, 
    private dialogRef: MatDialogRef<MediainformationComponent>, 
    private sanitizer: DomSanitizer
  ) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Media');
  }
  
  ngOnInit() {
    this.setSEOTags();
    const control = <FormArray>this.mediainfoForm.controls['mediadatas'];
    var p;
    for (var k = 0; k < this.data.image.length; k++) {
      this.previewFile(this.data.image[k], data => {
        p = data;
        if (p.type.includes("audio/mpeg")) {
          control.push(this.mediaaudiodataarray(p.url, p.type))
        }
        else if (!p.type.includes("audio/mpeg")) {
          control.push(this.mediadataarray(p.url, p.type))
        }
        else {
          control.push(this.mediadataarray(null, null))
        }
      });
    }
  }

  onsubmit() {
    const control = <FormArray>this.mediainfoForm.controls['mediadatas'];
    this.dialogRef.close(control);
  }
  previewFile(event, cb): void {
    let dt;
    var input = event;
    if (input.size > 25000000) {
      dt = null;
      cb(dt)
    }
    else {
      if (input && input.type.match('image')) {
        this.video = null;
        this.audio = null;
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target;
          this.imageData = this.imageData.result;
          dt = {
            "type": "image",
            "url": this.imageData
          }
          cb(dt)
        }
        reader.readAsDataURL(input);
      } else if (input && input.type.match('video')) {
        this.imageData = null;
        this.audio = null;
        var path = URL.createObjectURL(input);
        this.video = {
          sources: [{
            src: path,
            type: input.type
          }],
          options: {
            autoplay: false,
            volume: 0.6
          }
        }
        this.videomimeType = input.type;
        this.videosource = path;
        this.videosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.videosource);
        dt = {
          "type": "video",
          "url": this.videosource
        }
        cb(dt)
      } else if (input && input.type.match('audio')) {
        this.imageData = null;
        this.video = null;
        this.audio = true;
        var path = URL.createObjectURL(input);
        this.audiosource = path;
        this.audiosource = this.sanitizer.bypassSecurityTrustResourceUrl(this.audiosource);
        dt = {
          "type": input.type,
          "url": this.audiosource
        }
        cb(dt)
      }
      this.showprev = true;
    }
  }
}
