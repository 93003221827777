import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';
import * as _ from 'lodash';
import { R } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-requestdetails',
  templateUrl: './requestdetails.component.html',
  styleUrls: ['./requestdetails.component.scss']
})
export class RequestdetailsComponent implements OnInit {
  stDate: any;
  enDate: any;
  perf: any;
  overView: any;
  showHide: boolean;
  isLoading: boolean = false;
  public page: number = 1;
  patientList: any;
  public childid: any;
  private myCathash: any = {};
  public hctaskdata: any
  public taskdata: any;
  public getToday: any;
  iscomplete!: boolean;
  patient_failed = 0;
  progresspatendId: any = [];
  hcId: any;
  constructor(public commonService: CommonserviceService, private dialog: MatDialog, private snackBar: MatSnackBar, private systemService: SystemService, private router: Router, private route: ActivatedRoute) {
    this.showHide = true;
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.childid = params.id;
      this.hcId = params.hc_id;
    });
    this.getHc();
    this.hctaskdata = JSON.parse(localStorage.getItem('hctaskdata'));
    // this.getAllPatients();
    this.getAllTaskData();
    this.getToday = new Date().toISOString();
    this.applyFilters();
    this.getProgress();
  }


  getProgress() {

    var postData = {
      task_library_id: this.hctaskdata.task_library_id.toString(),
      hc_id: this.hctaskdata.hc_id.toString(),
    }
    this.commonService.getProgress(postData, 'getTaskProgress')
      .subscribe(
        (res: any) => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].progress.length > 0) {
              if (res.data[i].progress[0].patient_completed[0]) {
                if (!this.progresspatendId.includes(res.data[i].progress[0].patient_completed[0]) && res.data[i].progress[0].patient_completed[0] != null && (_.filter(res.data[i].patient_list, person => person.id === res.data[i].progress[0].patient_completed[0]).length > 0)) {
                  this.progresspatendId.push(res.data[i].progress[0].patient_completed[0]);
                }
                if (res.data[i].progress[0].patient_failed[0]) {
                  this.patient_failed++
                }

                // this.progresspatendId.push(res.data[i].progress[0].patient_completed[0]);
              }
            }
          }


          this.getAllPatients();
        },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  checkprogress(id) {
    let data = this.progresspatendId.includes(id);
    // 
    return data;

  }
  checkProgress(res) {
    var patientId = this.patientList[0].id;
    var data = res.data.find(element => element.progress[0].patient_completed[0] == patientId);
    if (data) {
      let data1 = data.progress[0].patient_completed[0];
      if (patientId == data1) {
        this.iscomplete = true
      }
    }
    else {
      this.iscomplete = false
    }
    // this.patientLength = localStorage.getItem("patientLength");
    // let patientLength = parseInt(this.patientLength);
    // this.progressPatientLength = parseInt(this.progressPatientLength);
    // if (this.progressPatientLength == patientLength) {
    //   this.checkTaskComplete()
    // }
  }

  changeShowStatus() {
    this.showHide = !this.showHide;
  }

  getAllPatients() {

    var postdata = {
      task_id_fk: this.hctaskdata.task_library_id.toString(),
      user_id_fk: this.hctaskdata.hc_id.toString(),
      page: this.page.toString(),
      limit: "20"
    }

    // if (this.searchcriteria) {
    //   this.searchcriteria = this.searchcriteria
    // } else {
    //   this.searchcriteria = ''
    // }
    // this.lastSearch = this.searchcriteria;
    // this.lastpage = this.page;
    this.isLoading = true;
    // this.searchcriteria = ''

    this.commonService.TaskPost(postdata, 'getPatientList')
      .subscribe(
        (res: any) => this.onTaskListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }


  private onTaskListSuccess(data) {
    data = data.data;
    this.patientList = data;
    this.isLoading = false;
  }

  // To Approve 
  // ----------------------------
  ApproveTask() {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed) {
        this.approve();
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Request has been Approved', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  approve() {
    var postdata = {
      id: this.childid.toString(),
      comment: "",
      is_approved: "true",
      action_taken_by: localStorage.getItem('scarletuserid').toString(),
      has_expiry: "true",
      expiry_date: "2021-09-29T18:30:00.000Z",
      reassign_task: "false"

    }
    this.commonService.approveTask(postdata)
      .subscribe(
        (res: any) => this.onSuccessApprove(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onSuccessApprove() {
    this.systemService.showSnackBar("Request has been Approved.", "Ok");
    this.router.navigate(['admin/taskrequest'])
  }



  // To Decline 
  // ----------------------------
  DeclineTask() {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed) {
        this.decline();
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Request has been Decline', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  decline() {
    var postdata = {
      id: this.childid.toString(),
      comment: "",
      is_approved: "false",
      action_taken_by: localStorage.getItem('scarletuserid').toString(),
      has_expiry: "true",
      expiry_date: "2021-09-29T18:30:00.000Z",
      reassign_task: "false"
    }
    this.commonService.approveTask(postdata)
      .subscribe(
        (res: any) => this.onSuccessDecline(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onSuccessDecline() {
    this.systemService.showSnackBar("Request has been Decline.", "Ok");
    this.router.navigate(['admin/taskrequest'])
  }

  getHc() {
    this.commonService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.onHCStatusSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCStatusSuccess(data) {
    data = (data as any).data;
    data.getAllHealthcoaches.forEach(element => {
      this.myCathash[element.id] = element.name;
    });
  }

  getHCName(category) {
    category = this.myCathash[category];
    return category;

  }


  // To get one task details

  getAllTaskData() {
    this.commonService.getOneTaskData(this.hctaskdata.task_library_id.toString())
      .subscribe(
        (res: any) => this.getOneTaskSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getOneTaskSuccess(data) {
    data = data.data;
    this.taskdata = data
  }

  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }

  convertDate(d) {
    if (d == null) {
      return ''
    } else {
      var date = new Date(d);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      // return date.toLocaleDateString("en-US", options);
      var month = SHORT_MONTHS[date.getMonth()];
      var currentdate = date.getDate()
      var year = date.getFullYear()
      return currentdate + ' ' + month + ',' + ' ' + year;
    }
  }

  applyFilters() {

    var filterdata = {
      "performance": this.perf,
      "create_start_date": this.stDate,
      "create_end_date": this.enDate
    }

    var bodyData = {
      mode: "hc",
      hc_id: this.hcId.toString(),
      user_id_fk: "",
      q: "",
      filters: JSON.stringify(filterdata)
    }
    this.commonService.TaskPost(bodyData, 'filterOverview')
      .subscribe(
        (res: any) => this.onFilterSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onFilterSuccess(data) {
    data = data.data;
    this.overView = data
    this.page = 1;
  }








}
