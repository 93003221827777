<!-- /*==========================================
Title: questionnair mapping 
Author: Nikhil Raj
Date:   13 may 2020
Last Change :  UI Fixes
===========================================*/  -->
<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/questionnairmappingedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                Questionnair Mapping
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" class="leftformsection" (ngSubmit)="dataObject()" validate>
                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Client</mat-label>
                            <mat-select formControlName="client_data_fk" #singleSelectClient>
                                <mat-option>
                                    <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                        [placeholderLabel]="'Search Item'"
                                        [noEntriesFoundLabel]="'No matching records found'"
                                        [formControl]="searchmatclient" ngModel (ngModelChange)="modelChangedClient()"
                                        ngDefaultControl>
                                        <div class="mat-select-search-custom-header-content">
                                            <i class="material-icons">
                                                search
                                            </i>
                                        </div>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let client of filteredClient | async" [value]="client.id">
                                    {{client.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Primary Condition</mat-label>
                            <mat-select formControlName="primary_condition_data_fk" required>
                                <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                    [value]="condition.id">
                                    {{condition.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 zeropadleft">
                        <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Questionnair</mat-label>
                            <mat-select formControlName="questionnaire_ids" multiple #singleSelectClient>
                                <mat-option>
                                    <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                        [placeholderLabel]="'Search Item'"
                                        [noEntriesFoundLabel]="'No matching records found'"
                                        [formControl]="searchmatquestionnair" ngModel
                                        (ngModelChange)="modelChangedQuestionnair()" ngDefaultControl>
                                        <div class="mat-select-search-custom-header-content">
                                            <i class="material-icons">
                                                search
                                            </i></div>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let questionnair of filteredQuestionnair | async"
                                    [value]="questionnair.id">
                                    {{questionnair.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 zeropadleft">
                        <button mat-flat-button color="primary" class="centerbtn" style="margin-top:15px;"
                            [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Questionnaire</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-drawer-container>
