<div class="profile-wrapper">
  <div class="profile-info clearfix">
    <div class="media">
      <div class="media-body media-middle">
        <h4 class="media-heading text-capitalize">{{userView.title}}</h4>
        <div class="profile-content">
          {{userView.is_paid ? 'Paid Campaign' : 'Not Paid'}}
        </div>
      </div>
    </div>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close">
        <img src="assets/img/dls/icons/cross.svg" (click)="onNoClick()" class="img-responsive close-modal" alt="">
      </i>
    </div>

  </div>
  <div class="hc-details">
    <div class="text-over">Campaign Details</div>
    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5>Therapy</h5>
          <p>{{getTherapyName(userView.therapy_data_fk)}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="location">
          <h5>Healthcoach</h5>
          <p>{{getHCName(userView.coach_ids)}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="about-me">
          <h5>Client</h5>
          <p>
            {{userView.client_data==null?'No client':getClientName(userView.client_data)}}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5>Test code</h5>
          <p> {{userView.is_test ? 'Yes' : 'No'}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="location">
          <h5>Valid from</h5>
          <p *ngIf="!userView.start_date" class="value">-</p>
          <p *ngIf="userView.start_date">{{userView.start_date}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="about-me">
          <h5>Valid till</h5>
          <p *ngIf="!userView.end_date" class="value">-</p>
          <p *ngIf="userView.end_date">{{userView.end_date}}</p>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-4">
        <div class="about-me">
          <h5>AMOUNT</h5>
          <p>₹ 3500</p>
        </div>
      </div>
    </div> -->

  </div>
</div>