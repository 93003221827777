import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-addhopitalisation',
  templateUrl: './addhopitalisation.component.html',
  styleUrls: ['./addhopitalisation.component.scss']
})
export class AddhopitalisationComponent implements OnInit {
  hospitalizationData: any;
  public selectedUserID: any;
  showButtonLoader: boolean;

  public addHospitalisation = this.fb.group({ 
    sub_type: [''],
    is_subtype_other: [''],
    functional_class: [''],
    date_of_diagnosis: [''],
    comorbidities_count: [''],
    comorbidities: [''],
    previous_conditions_count:[''],
    previous_conditions:[''],
    allergies:[''],
    general_symptoms:[''],
    hospitalisations_count:[''],
    patient_id_fk:[''],
    hospitalisations: [[]],
    duration_from: ['', Validators.required],
      duration_to: ['',Validators.required],
      reason: ['', Validators.required],
      type: ['', Validators.required],
      procedures: ['', Validators.required],
      stay_details: ['', Validators.required],
      next_doctor_visit: ['',Validators.required]
  });
  
  constructor(public dialog: MatDialog,public fb: FormBuilder,private UserdataService: UserdetailsService,private appContext: ContextService,private systemService: SystemService) { }

  ngOnInit(): void {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getHospitalization();
  }


  getHospitalization() {
    this.UserdataService.getUserMedicalHistory(this.selectedUserID)
    .subscribe(
      (res: any) => this.gethospitaizationData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private gethospitaizationData(data) { 
    data = (data as any).data;
    
    this.hospitalizationData = data;
    
    this.addHospitalisation.patchValue(this.hospitalizationData);
  }

  uploadObject() {    
    var postdata = this.addHospitalisation.value;
    postdata.patient_id_fk = this.selectedUserID.toString();
    if(postdata.is_subtype_other){
      postdata.is_subtype_other = postdata.is_subtype_other.toString();
    }else{
      delete postdata.is_subtype_other;
    }
   
    if(postdata.functional_class){
      postdata.functional_class = postdata.functional_class.toString();
    }else{
      delete postdata.functional_class;
    }
    if(postdata.comorbidities_count){
      postdata.comorbidities_count = postdata.comorbidities_count.toString();
    }else{
      delete postdata.comorbidities_count;
    }

    if(postdata.previous_conditions_count){
      postdata.previous_conditions_count = postdata.previous_conditions_count.toString();
    }else{
      delete postdata.previous_conditions_count;
    }

    if(postdata.comorbidities){
      postdata.comorbidities = JSON.stringify(postdata.comorbidities);
    }else{
      delete postdata.comorbidities;
    }
  
    // if(postdata.hospitalisations){
    //   postdata.hospitalisations = JSON.stringify(postdata.hospitalisations);
    // }else{
    //   delete postdata.hospitalisations;
    // }

    var duration_from = this.convertISODate(this.addHospitalisation.value.duration_from);
    var duration_to = this.convertISODate(this.addHospitalisation.value.duration_to);
    var reason = this.addHospitalisation.value.reason;
    var type = this.addHospitalisation.value.type;
    var procedures = this.addHospitalisation.value.procedures;
    var stay_details = this.addHospitalisation.value.stay_details;
    if(this.addHospitalisation.value.next_doctor_visit)
    var next_doctor_visit = this.convertISODate(this.addHospitalisation.value.next_doctor_visit)
    else
    var next_doctor_visit = ''
    var hospitalisations = {'duration_from':duration_from,
     'duration_to':duration_to,
     'reason':reason,
     'type':type,
     'procedures':procedures,
     'stay_details':stay_details,
     'next_doctor_visit':next_doctor_visit};

     if(postdata.hospitalisations == null ){
      postdata.hospitalisations = [];
     }
     postdata.hospitalisations.push(hospitalisations);
     postdata.hospitalisations_count = postdata.hospitalisations.length.toString();
     postdata.hospitalisations = JSON.stringify(postdata.hospitalisations);

    if(postdata.previous_conditions){
      postdata.previous_conditions = JSON.stringify(postdata.previous_conditions);
    }else{
      delete postdata.previous_conditions;
    }

    if(postdata.general_symptoms){
      postdata.general_symptoms = postdata.general_symptoms.toString();
    }else{
      delete postdata.general_symptoms;
    }

    if(postdata.allergies){
      postdata.allergies = postdata.allergies.toString();
    }else{
      delete postdata.allergies;
    }

    if(postdata.previous_conditions_count){
      postdata.previous_conditions_count = postdata.previous_conditions_count.toString();
    }else{
      delete postdata.previous_conditions_count;
    }

    if(postdata.date_of_diagnosis){
      postdata.date_of_diagnosis = postdata.date_of_diagnosis.toString();
    }else{
      delete postdata.date_of_diagnosis;
    }

    if(postdata.sub_type){
      postdata.sub_type = postdata.sub_type.toString();
    }else{
      delete postdata.sub_type;
    }
    
    delete postdata.duration_from
    delete postdata.duration_to;
    delete postdata.reason;
    delete postdata.type;
    delete postdata.procedures;
    delete postdata.stay_details;
    delete postdata.next_doctor_visit;
   
   this.UserdataService.addUserMedicalHistory(postdata).pipe(
     catchError((error: any) => {
       this.showButtonLoader = false;
       this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
       return throwError(error);
     }),
     finalize(() => {
       this.showButtonLoader = false;
     })
   ).subscribe(
     (data) => {
       this.dialog.closeAll();
       this.systemService.showSuccessSnackBar("Hospitalisation added successfully.", "OK");
       this.sendMqttRequest('Hospitalisation')
       this.showButtonLoader = false;
     })
 }

 convertISODate(log_date){
  var newlogdate = new Date(log_date);
  var mm = (newlogdate.getMonth() + 1).toString().padStart(2, "0");
  var dd = newlogdate.getDate().toString().padStart(2, "0");
  var yy = newlogdate.getFullYear();
  return yy + '-' + mm + '-' + dd;
}

sendMqttRequest(type){
  var obj = {
    link : "https://wellthy.page.link/B421",
    type : type
  }
  this.appContext.changeMessage(obj);
}
}
