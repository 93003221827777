
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { Userdetailsinterface } from '../../models/userdetailsinterface';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';


@Component({
  selector: 'app-paymentlink',
  templateUrl: './paymentlink.component.html',
  styleUrls: ['./paymentlink.component.scss']
})
export class PaymentlinkComponent implements OnInit {
  public userProgressSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public selectedUserID: string;
  public udata: any = {};
  public currentWeek: number = 1;
  public expectedWeek: number = 1;
  public totalWeeks: number = 1;
  public diameterValue: number = 0;
  public currentWeekArray: any = [];
  private tabID: number = 4;
  public subscribeObj: any;
  public selectedWeek: any;
  paymentlinkdata: any;
  public userData: any;
  activecls: any = 'therapy';
  public plandata: any;
  private paymentresponsedata: any;
  public loading: boolean = false;
  public errormessage: any = "We haven't found any plans for this patient. Kindly contact administrator"
  public dataForm = this.fb.group({
    planid: ['', Validators.required]
  });

  constructor(private appContext: ContextService, private userDetailService: UserdetailsService, private dialogRef: MatDialogRef<PaymentlinkComponent>, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getPaymentPlans(this.selectedUserID);
  }

  getPaymentPlans(uid) {
    this.loading = true;
    this.userDetailService.getUserPaymentPlans(uid).pipe(
      delay(100),
      catchError((error: any) => {
        
        this.loading = false;
        if (error.error.status == 422) {
          this.errormessage = "Patient already have an active plan"
        }
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      this.loading = false;
      this.plandata = data;
      this.plandata = this.plandata.data;

      
    });
  }

  createPaymentlink() {
    var planid = this.dataForm.value.planid;
    var getplandata = this.plandata.filter(a => a.id == planid)[0]

    let body = {
      "transaction_amount": getplandata.price.toString(),
      "transaction_currency": "INR",
      "description": getplandata.detailed_text,
      "patient_data_fk": this.selectedUserID.toString(),
      "plan_data_fk": planid.toString(),
      "callback_url": "https://www.wellthy.care",
      "payment_type": getplandata.payment_type
    }
    this.userDetailService.createPaymentLink(body).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      // data = (data as any).data;
      this.paymentresponsedata = data;
      this.paymentresponsedata = this.paymentresponsedata.data;
      var orderdata = {
        "title": getplandata.title,
        "payment_type": getplandata.payment_type,
        "price": getplandata.price,
        "order_id": this.paymentresponsedata.order_id
      }
      this.paymentlinkdata = {
        link: this.paymentresponsedata.short_url,
        order_id: orderdata
      }
      if (this.paymentlinkdata.link) {
        this.dialogRef.close(this.paymentlinkdata);
      }
      else {
        this.dialogRef.close(this.paymentlinkdata = {
          link: ''
        });
      }
    });
  }
}
