import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { ContextService } from '../services/context.service';
import { UserlistService } from '../services/userlist/userlist.service';
import { DeletemodalComponent } from './../../content/shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-hctags',
  templateUrl: './hctags.component.html',
  styleUrls: ['./hctags.component.scss'],
  animations: [
    detailExpandTable
  ],
})

export class HctagsComponent implements OnInit {
  public tagCreationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public tagData: any;
  public filterList: Array<string> = ["Open", "Closed", "Escalated", "On Hold"];
  public selectedFilter: string = "Open";
  public enteredSearchText: string;
  public tagForm: FormGroup;
  public directMode: boolean = false;
  public dataSource: any;
  public lastSearch: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public searchcriteria: any;
  public status: any = 'all';
  tabulardata: any;
  displayedColumns: string[] = ['title'];
  public showloader: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;

  @ViewChild("form", { static: false }) tsForm;

  constructor(private appContext: ContextService, private UserlistService: UserlistService,
    private browserTitle: Title,
    private formBuilder: FormBuilder, private dialog: MatDialog, private systemService: SystemService, private snackBar: MatSnackBar) 
    {
      localStorage.removeItem("notifyPatientName");
    //  localStorage.removeItem("selectedNotification");
     }
 
    private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Tags');
  }

  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = '';
    this.page = 1;
    this.buildtagForm();
    this.getAlltags();
  }

  buildtagForm(): void {
    this.tagForm = this.formBuilder.group({
      name: [null, [Validators.required, whiteSpaceValidation]]
    });
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.getAlltags();
    }
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.getAlltags();
    }
  }


  // Activate Inactivate HC Tags
  // ----------------------------

  isActive(status, id, useridfk) {
    this.UserlistService.setStatus(status, id, useridfk)
    .subscribe(
      (res: any) => this.onStatusSuccess(status, id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onStatusSuccess(status, id) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  refreshtagsLoad(): void {
    this.status = "all";
    this.page = 1;
    this.lastpage = 0;
    this.searchcriteria = "";
    this.endoflist = false;
    this.getAlltags();
  }


  // To Get All Tags
  // ----------------------------

  getAlltags() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.UserlistService.getalltagssearchByHc(this.searchcriteria, this.page.toString(), "20", this.status)
      .subscribe(
        (res: any) => this.getAllTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private getAllTagsSuccess(data) {
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      
      

    }
  }

  // ----------------------------


  // To Delete Tags
  // ----------------------------

  delete(id, useridfk) {
    this.UserlistService.hardDelete(id, useridfk)
    .subscribe(
      (res: any) => this.onDeleteSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id, '5');
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected tag has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }


  // To Save Tags
  // ----------------------------

  saveTag(): void {
    this.tagCreationSubject$.next(false);
    let data: any = this.tagForm.value;
    this.UserlistService.createTag(data.name)
    .subscribe(
      (res: any) => this.onSaveTagsSuccess(),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onSaveTagsSuccess() {
    this.tagCreationSubject$.next(true);
    this.systemService.showSnackBar("Tag has been created", "Ok");
    this.tsForm.resetForm();
    this.tabulardata = [];
    this.refreshtagsLoad();
  }

  ngOnDestroy() {
    this.tagCreationSubject$.unsubscribe();
    this.appContext.tagTicketObjectHandler.next(null);
  }
  
}

export interface PeriodicElement {
  title: string;
}