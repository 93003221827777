import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from 'src/app/shared/guards';
import { AssigntaskComponent } from './assigntask/assigntask.component';
import { EdittaskComponent } from './edittask/edittask.component';
import { PrefillComponent } from './prefill/prefill.component';
import { TasklibraryComponent } from './tasklibrary.component';
import { ViewtaskComponent } from './viewtask/viewtask.component';


const routes: Routes = [
  { path: '', component: TasklibraryComponent, canActivate: [AdminGuardGuard] },
  { path: 'tasklibrary/edit/:id', component: EdittaskComponent, canDeactivate: [AdminGuardGuard] },
  { path: 'tasklibrary/prefill', component :PrefillComponent,canActivate: [AdminGuardGuard]},
  { path: 'tasklibrary/viewtask', component :ViewtaskComponent,canActivate: [AdminGuardGuard]},
  { path: 'tasklibrary/viewtask', component :AssigntaskComponent,canActivate: [AdminGuardGuard]}
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasklibraryRoutingModule { }
