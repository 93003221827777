import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../services/contentservice/contentservice.service';

@Component({
  selector: 'app-uuxid',
  templateUrl: './uuxid.component.html',
  styleUrls: ['./uuxid.component.scss']
})

export class UuxidComponent implements OnInit {
  public userProgressSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showButtonLoader: boolean = false;
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public alltherapies: any;
  public dataForm = this.fb.group({
    level_id: ['', Validators.required],
    therapy_id: ['', Validators.required],
    bypass_suitable_module_check: ['', Validators.required],
    bypass_patient_progress: ['', Validators.required],
    // knowledge_level: ['']
  });
  deeplinkdata: any;
  levelList: any;
  homefeedData: any;
  optionlink: any;
  optionvalue: any;

  constructor(private browserTitle: Title, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService) {
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | UUXID');
  }

  ngOnInit(): void {
    this.getLevels();
    this.setSEOTags();
    this.getConditions();
  }

  modelChangedTags() {
    this.filterLevels();
  }

  // ----------------------------

  // To get All Conditions
  // ----------------------------

  getConditions() {
    this.contentService
      .getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
      .subscribe(
        (res: any) => this.getConditionsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getConditionsSuccess(data) {
    this.alltherapies = data.getAllTherapies;

  }

  // ----------------------------


  private filterLevels() {
    if (!this.levelList) {
      return;
    }
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.levelList.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    this.filteredTags.next(
      this.levelList.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  // ----------------------------


  // To get All Levels
  // ----------------------------

  getLevels() {
    this.contentService
      .getGraphQlData('{getAllLevels(appMode:false,status:true){id, title}}', false)
      .subscribe(
        (res: any) => this.getLevelsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getLevelsSuccess(data) {
    this.levelList = data.getAllLevels;
    this.filteredTags.next(this.levelList.slice());
  }

  // ----------------------------


  // To copyUXID Levels
  // ----------------------------

  copyUXID(type, uxid, title) {
    
    var uxuidlink;
    if (type == 'lesson') {
      uxuidlink = 'lesson/' + uxid;
    }
    else if (type == 'quiz') {
      uxuidlink = 'quiz/' + uxid;
    }
    else if (type == 'level') {
      uxuidlink = 'level/' + uxid;
    }
    else if (type == 'magazine') {
      uxuidlink = 'article/' + uxid;
    }
    else if (type == 'chapter') {
      uxuidlink = 'chapter/' + uxid;
    }
    else {
      uxuidlink = uxid;
    }
    this.contentService.createDynamicLink(uxuidlink)
      .subscribe((res: any) => this.copyUXIDSccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private copyUXIDSccess(data) {
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    this.deeplinkdata = data;
    inp.value = this.deeplinkdata.data.shortLink;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Copied to Clipboard', 'OK');
  }

  // ----------------------------


  // Submitting uuxid data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    this.contentService
      .getHomeFeedData(postdata)
      .subscribe((res: any) => this.getHomeFeedSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getHomeFeedSuccess(data) {
    this.homefeedData = data;
    data = this.homefeedData.data[0] == null ? [] : this.homefeedData.data[0];
    this.userProgressSubject$.next(data);
  }

  // ----------------------------

}
