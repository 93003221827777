<mat-drawer-container class="sidenav-container" autosize>
  <app-contentleftnav></app-contentleftnav>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center" style="height:700px;">
      <div class="row">
        <div class="col-12 text-center">
          <font
            style="font-family: 'Avenir Next';font-style: normal;font-weight: 700;font-size: 24px;line-height: 40px;">
            Welcome to Therapy Dashboard!
          </font>
          <p
            style="font-family: 'Avenir Next';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;color: #5A5C75;">
            We’ve assembled some links to get you started</p>
            <br><br>
        </div>
        <div class="col-md-2">
          <div class="therapy-card text-center" [routerLink]="['/content/magazine']">
            <img style="width: fit-content;" src="assets/img/therapy/Add magazine articles.png">
            <div class="card-body">
              <p class="card-text">
                Magazine Article
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="therapy-card text-center" [routerLink]="['/content/careycard']">
            <img style="width: fit-content;" src="assets/img/therapy/Add carey card.png">
            <div class="card-body">
              <p class="card-text">
                Carey Card
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="therapy-card text-center" [routerLink]="['/content/lesson']">
            <img style="width: fit-content;" src="assets/img/therapy/Add lesson.png">
            <div class="card-body">
              <p class="card-text">
                Lesson
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="therapy-card text-center" [routerLink]="['/content/quiz']">
            <img style="width: fit-content;" src="assets/img/therapy/Add quiz.png">
            <div class="card-body">
              <p class="card-text">
                Quiz
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="therapy-card text-center" [routerLink]="['/content/chapter']">
            <img style="width: fit-content;" src="assets/img/therapy/Add chapter.png">
            <div class="card-body">
              <p class="card-text">
                Chapter
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="therapy-card text-center" [routerLink]="['/content/therapy']">
            <img style="width: fit-content;" src="assets/img/therapy/Design therapy.png">
            <div class="card-body">
              <p class="card-text">
                Design Therapy
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>