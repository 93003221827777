<mat-drawer-container  autosize>
      <div>
        <div class="col-md-12">
          <div class="container-table" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
            (scrolled)="getNextSet()" [scrollWindow]="false">
            <div class="spinner-container" *ngIf="showloader">
              <app-loader showLoader="{{true}}"></app-loader>
            </div>
            <table *ngIf="!emptyplaceholder" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
              class="widthfluid bordergrey list-table">
  
              <!-- start_date Column -->
              <ng-container matColumnDef="start_date">
                <th scope="col" mat-header-cell *matHeaderCellDef> Start Date </th>
                <td mat-cell class="camelcased" *matCellDef="let element">
                  {{element.start_date | date: 'dd/MM/yyyy'}}
                </td>
              </ng-container>
  
              <!-- end_date Column -->
              <ng-container matColumnDef="end_date">
                <th scope="col" mat-header-cell *matHeaderCellDef> End Date </th>
                <td mat-cell *matCellDef="let element" style="text-transform: capitalize;">
                  {{element.end_date | date: 'dd/MM/yyyy'}} </td>
              </ng-container>
  
              <!-- type Column -->
              <ng-container matColumnDef="type">
                <th scope="col" mat-header-cell *matHeaderCellDef> Device Type </th>
                <td mat-cell class="camelcased" *matCellDef="let element">
                  {{element.type}}
                </td>
              </ng-container>
  
              <!-- data Column -->
              <ng-container matColumnDef="data">
                <th scope="col" mat-header-cell *matHeaderCellDef> Raw Log </th>
                <td mat-cell *matCellDef="let element; let z = dataIndex;">
                  <!-- Android object -->
                  <div *ngIf="element.type === 'android'" style="height: 100px; overflow-x: hidden;overflow-y: scroll;margin-top: 5px;">
                    <ng-container *ngFor="let item of convertToAndroidObject(z) | keyvalue;let i = index;">
                      <strong> {{+item.key + +1 }} :</strong> {{item.value | json}}
                     <hr style="border-top: 1px solid #0f0f0f;margin-bottom:5px;margin-top: 5px;"> 
                  </ng-container>
                </div>
                <!-- iOS object -->
                <div *ngIf="element.type === 'ios'" style="height: 100px; overflow-x: hidden;overflow-y: scroll;margin-top: 5px;">
                  <ng-container *ngFor="let item of convertToIOSObject(z) | keyvalue;let i = index;">
                    <strong> {{+item.key + +1 }} :</strong> {{item.value | json}}
                   <hr style="border-top: 1px solid #0f0f0f;margin-bottom:5px;margin-top: 5px;"> 
                </ng-container>
              </div>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="action">
                <th scope="col" mat-header-cell *matHeaderCellDef >Action </th>
                <td class="text-capitalize" mat-cell *matCellDef="let element">
                  <div class="action-button-row">
                    <canvas [hidden]="true" #canvas></canvas>
                    <button mat-button (click)="copyRawlog(element.data)" tooltip="Copy Raw Log">
                      <img src="assets/img/dls/icons/actiontable/Icon_Duplicate.svg" style="height: 28px;" alt="">
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="emptyplaceholder" class="row">
              <div class="col-md-12" style="text-align: center;top: 50%;">
                <span>No Data Found</span>
              </div>
            </div>
          </div>
        </div>
      </div>
     
  </mat-drawer-container>
