<div class="col-md-12 broadcastcss">
    <div class="col-md-12">
        <div class="row">
            <h3 class="broadcasttitle col-md-6">Create</h3>
            <div class="text-right col-md-6">
                <div (click)="closeCreateBroadcast()" class="mt-15"><i class="material-icons close-icon notranslate">clear</i></div>
            </div>
            <div class="clearfix"><br /><br /></div>
        </div>
        <div class="row">
            <form [formGroup]="broadcastForm" validate>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" placeholder="Give a title"
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Select category</mat-label>
                        <mat-select formControlName="type" placeholder="Category">
                            <mat-option value="{{category.value}}" *ngFor="let category of categories;">
                                {{category.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="full-width-formfield">
                        <mat-label>Content</mat-label>
                        <textarea formControlName="message" matInput
                            placeholder="Write the message/description"></textarea>
                    </mat-form-field>
                </div>
                <div class="fileinput">
                    <div class="col-md-5">
                        <div class="">
                            <div class="image-preview border1" *ngIf="imageData">
                                 <div class="closebtn" ><img (click)="trigger('image')"  src="/assets/img/edit.png">
                                </div>
                                <img class="preview" [src]="imageData">
                            </div>
                            <div class="video-preview border1" *ngIf="video">
                                <div class="closebtn" (click)="trigger('video')"><img src="/assets/img/edit.png"></div>
                                <video [src]="videosource" controls></video>
                            </div>
                            <div class="audio-preview border1" *ngIf="audio">
                                <div class="closebtn" (click)="trigger('audio')"><img src="/assets/img/edit.png"></div>
                                <audio [src]="audiosource" controls></audio>
                            </div>
                            <div class="image-preview border1" *ngIf="isPDF">
                                <div class="closebtn" (click)="trigger('others')"><img src="/assets/img/edit.png"></div>
                                <img class="preview"
                                    src="/assets/img/pdficon.jpg">
                            </div>
                            <div class="clearfix"></div>
                            <div class="input-group">
                                <input type="hidden" class="form-control image-preview-filename" disabled="disabled">
                                <!-- don't give a name === doesn't send on POST/GET -->
                                <span class="input-group-btn">
                                    <!-- image-preview-clear button -->
                                    <button type="button" class="btn btn-default image-preview-clear"
                                        style="display:none;">
                                        <span class="fa fa-remove"></span> Clear
                                    </button>
                                    <!-- image-preview-input -->
                                    <div [ngStyle]="{'display':(!imageData||video||audio||isPDF) ? '' : 'none' }" class="btn btn-default image-preview-input">
                                        <i class="material-icons notranslate">backup</i> &nbsp;&nbsp;
                                        <span class="image-preview-input-title">Upload Rich Media</span>
                                        <input #inputFile type="file" id="inputFile" (change)="previewFile($event)"
                                            accept="image/*, video/*, audio/*, application/pdf" /><br>
                                            
                                        <!-- rename it -->
                                    </div>
                                </span>
                            </div><!-- /input-group image-preview [TO HERE]-->
                            <div [ngStyle]="{'display':(!imageData||video||audio||isPDF) ? '' : 'none' }"  class="tooltip_richmedia"> 
                                <font style="color:#5A5C75;">Supports</font>
                                <font style="color:#237EE9;"> Rich media</font>
                                <span class="tooltiptext">Image (.jpeg,.png,.jpg) ~5 MB 
                                Audio (.mp3,.wav,.m4a) ~10 MB 
                                Video (.mp4,.mov,.avi) ~20 MB. </span>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <button mat-flat-button color="primary" [class]="isloading ? 'centerbtn loaderbtn' : 'centerbtn'"
                        [disabled]="!broadcastForm.valid" (click)="broadcastObject()">Create
                        Broadcast</button>
                </div>
            </form>
        </div>

    </div>
</div>