<mat-drawer-container class="sidenav-container" autosize>

    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/magazinecollectionhome/magazinecollectionedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Magazine
                Collection
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>

                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <div class="row">
                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Title</mat-label>
                                        <input matInput placeholder="Enter the title" formControlName="title" required>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Description</mat-label>
                                        <input matInput placeholder="Enter the description"
                                            formControlName="detailed_text">
                                    </mat-form-field>
                                </div>

                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                        <mat-label>Select Image</mat-label>
                                        <input matInput placeholder="Select Image" (click)="openmediabox()"
                                            formControlName="image_media" required readonly>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Magazine Count</mat-label>
                                        <mat-select formControlName="subpart_count"
                                            (selectionChange)="changeOptions($event.value)" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                            <mat-option value="9">9</mat-option>
                                            <mat-option value="10">10</mat-option>
                                            <mat-option value="11">11</mat-option>
                                            <mat-option value="12">12</mat-option>
                                            <mat-option value="13">13</mat-option>
                                            <mat-option value="14">14</mat-option>
                                            <mat-option value="15">15</mat-option>
                                            <mat-option value="16">16</mat-option>
                                            <mat-option value="17">17</mat-option>
                                            <mat-option value="18">18</mat-option>
                                            <mat-option value="19">19</mat-option>
                                            <mat-option value="20">20</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div *ngIf="optioncount" class="retrysection">
                                        <div class="count-box">
                                            <p class="customlabel">Magazines</p>
                                            <div formArrayName="subpart_data">
                                                <div *ngFor="let txtoptn of dataForm.controls.subpart_data.controls; let j=index;"
                                                    [formGroupName]="j" class="boxthekeyvalue">
                                                    <mat-form-field appearance="outline"
                                                        class="full-width-formfield featureimage">
                                                        <mat-label>Select the magazine</mat-label>
                                                        <mat-select formControlName="txtoptn"
                                                            (selectionChange)="selectedOptions($event.value,j)" required
                                                            #singleSelect>
                                                            <mat-option>
                                                                <ngx-mat-select-search [clearSearchInput]="false"
                                                                    [placeholderLabel]="'Search Item'"
                                                                    [noEntriesFoundLabel]="'No matching records found'"
                                                                    [formControl]="searchmat" ngModel
                                                                    (ngModelChange)="modelChanged(j)" ngDefaultControl>
                                                                    <div
                                                                        class="mat-select-search-custom-header-content notranslate">
                                                                        <i class="material-icons">
                                                                            search
                                                                        </i>
                                                                    </div>
                                                                </ngx-mat-select-search>
                                                            </mat-option>
                                                            <mat-option class="camelcased"
                                                                *ngFor="let magazine of filteredMagazines[j] | async"
                                                                [value]="magazine.id"
                                                                [disabled]="disableOptions(magazine.id)">
                                                                {{magazine.title}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Knowledge Level</mat-label>
                                        <!-- <mat-select formControlName="complexity_level" required>
                                      <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                      <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                      <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                                  </mat-select> -->
                                        <mat-select formControlName="knowledge_level" required>
                                            <mat-option [value]="complexityenum[0]" class="text-capitalize">
                                                {{complexityenum[0]}}</mat-option>
                                            <mat-option [value]="complexityenum[1]" class="text-capitalize">
                                                {{complexityenum[1]}}</mat-option>
                                            <mat-option [value]="complexityenum[2]" class="text-capitalize">
                                                {{complexityenum[2]}}</mat-option>
                                        </mat-select>
                                        <!-- knowledge_level -->
                                    </mat-form-field>
                                </div>

                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Condition</mat-label>
                                        <mat-select formControlName="comorbidities" required multiple>
                                            <mat-option class="camelcased" *ngFor="let condition of allconditions"
                                                [value]="condition.id">
                                                {{condition.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>


                                <div class="col-md-12 zeropadleft">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Client</mat-label>
                                        <mat-select formControlName="clients" multiple #singleSelectClient>
                                            <mat-option>
                                                <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                                    [placeholderLabel]="'Search Item'"
                                                    [noEntriesFoundLabel]="'No matching records found'"
                                                    [formControl]="searchmatclient" ngModel
                                                    (ngModelChange)="modelChangedClient()" ngDefaultControl>
                                                    <div class="mat-select-search-custom-header-content">
                                                        <i class="material-icons">
                                                            search
                                                        </i>
                                                    </div>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let client of filteredClient | async"
                                                [value]="client.id">
                                                {{client.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12 leftpadding10">
                            <button mat-flat-button color="primary" class="centerbtn btn-left" style="margin-top:15px;"
                                [disabled]="!dataForm.valid"> {{newitem ? 'New' : 'Update'}} Magazine
                                Collection</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="content-inside">
                            <!-- <button class="btn btn-sm back-btn" *ngIf="!(dataForm?.value.title == '')">
                                <span class="material-icons">chevron_left</span>
                            </button> -->
                            <div class="title-holder">
                                <div class="card">
                                    <div class="options-image">
                                        <img *ngIf="allmedia" [src]="s3url + allmedia" class="img-responsive" />
                                        <div *ngIf="!(dataForm?.value.title == '')">
                                            <div *ngIf="!allmedia" class="no-media"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-text">
                                    <div class="iphonetitle">
                                        {{dataForm?.value?.title}}
                                    </div>
                                    <div class="iphonesubtitle sub-description"
                                        *ngIf="!(dataForm?.value.detailed_text == '')">
                                        {{dataForm?.value?.detailed_text}}
                                    </div>
                                </div>
                                <div *ngFor="let sel of selectedoptn" class="text-card">
                                    {{sel.title}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>