
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})

export class ClientComponent implements OnInit {
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  displayedColumns: string[] = ['name', 'no_of_patients', 'email', 'action'];
   public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  dataSource: any;
  public status = "active";
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  public edithcstatus: any;
  public localStoredData: any;
  constructor(private router:Router,private browserTitle: Title, private commonService: CommonserviceService,
    private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Client');
  }

  ngOnInit() {
    this.setSEOTags();
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.searchcriteria = "";
    this.page = 1;
    this.search();
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtypes = [];
    this.typeFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.searchcriteria = "";
    this.search();
  }

  activeclass(onleave, active) {
    return onleave ? 'onleave-cls' : (active ? 'active-cls' : 'inactive-cls');
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }


  // Client List
  // ----------------------------

  search() {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllGeneralData(this.searchcriteria, this.page.toString(), "20", "active", "client")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onSearchDataSuccess(data) {
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        this.tabulardata = [];
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      if (this.tabulardata.length < 15) {
        this.getNextSet();
      }
   
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------


  delete(id) { 
    this.commonService.removeBranding(id)
      .subscribe((res: any) => this.onDeleteSuccess(id),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDeleteSuccess(id) {
    // for (let i = 0; i < this.tabulardata.length; i++) {
    //   if (this.tabulardata[i].id === id) {
    //     this.tabulardata.splice(i, 1);
    //   }
    // }
    this.tabulardata.forEach(el => {
      if(el.id == id)
      {
        el.coach_ids = null;
        el.bannerMediaId = null;
        el.coloredLogoMediaId = null;
      }
  });
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  removeBranding(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Branding has been removed', 'Ok', {
          duration: 2000,
        });
      }
    });
  }

  clickeditclient(data){   
    localStorage.setItem('clientname',data.title);
    this.router.navigate(['/admin/client/edit', data.id]);
  }
  
}


  // removeBranding(id) {
  //   if (confirm('Are you sure you want to remove Branding?')) {
  //     this.commonService.removeBranding(id)
  //       .subscribe(data => {
  //         this.systemService.showSnackBar('Branding has been Removed', 'OK')
  //         this.tabulardata.forEach(el => {
  //           if (el.id == id) {
  //             el.coach_ids = null;
  //             el.bannerMediaId = null;
  //             el.coloredLogoMediaId = null;
  //           }
  //         });
  //         this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  //       })
  //   }
  // }



export interface PeriodicElement {
  name: string;
  no_of_patients: string;
  status: string;
  email: string;
}