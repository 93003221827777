import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from 'src/app/shared/guards';
import { MobileChangeNumberRequestComponent } from './mobile-change-number-request.component';


const routes: Routes = [
  { path: '', component: MobileChangeNumberRequestComponent, canActivate: [AdminGuardGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MobileChangeRequestRoutingModule { }
