import { CommonserviceService } from './../../../../admin/services/commonservice/commonservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay, takeUntil } from 'rxjs/operators';
import { AwsuploadService, SystemService } from 'src/app/shared/services';
import { Brodcastlistinterface } from '../../../models/brodcastlistinterface';
import { BroadcastService } from '../../../services/broadcast/broadcast.service';
import { ContextService } from '../../../services/context.service';
import { SocketService } from '../../../services/socket/socket.service';
import { UserlistService } from '../../../services/userlist/userlist.service';

declare var $: any;

@Component({
  selector: 'app-senduserbrodcast',
  templateUrl: './senduserbrodcast.component.html',
  styleUrls: ['./senduserbrodcast.component.scss']
})
export class SenduserbrodcastComponent implements OnInit {

  public brodcastSubject$ = new BehaviorSubject<Brodcastlistinterface[]>(null);
  public brodcastPreviewSubject$ = new BehaviorSubject<Brodcastlistinterface[]>(null);
  public brodcastFilterData: Brodcastlistinterface[] = [];
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loadingError$ = new Subject<boolean>();
  public brodcastForm: FormGroup;
  public brodcast_list: FormArray;
  private selectedUsers: any = [];
  private allconvdata: any;
  private allconversations: any;
  private partid: any;
  private convid: any;
  private uid: any;
  private phonelist: any = [];
  public broadcastPreviewData: Array<Brodcastlistinterface> = [];
  public getMessage:any;
  /** control for the MatSelect filter keyword */
  public broadcastFilterCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  public filteredBroadcast: ReplaySubject<Brodcastlistinterface[]> = new ReplaySubject<Brodcastlistinterface[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @ViewChild("form", { static: false }) tsForm;
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  constructor(private socketService: SocketService, private appContext: ContextService, private brodcastService: BroadcastService,
    private formBuilder: FormBuilder,
    private systemService: SystemService, private userlistService: UserlistService,private commonService:CommonserviceService) { }

  ngOnInit() {
    /**Subscribe to user selection changes */
    
    this.appContext.multiUserSelectionUsers.subscribe(data => {
      if (data != null) {
        this.selectedUsers = data;
        
      }
    });
    /**Build form */
    this.buildBrodcastForm();
    // listen for search field value changes
    this.broadcastFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBroadcast();
      });

  }

  ngAfterViewInit() {
    /**Load brodcast list */
    this.getBrodcastList();
    this.appContext.broadcastSource.subscribe(data => {
      var datenew;
      datenew = new Date();
      datenew = datenew * 1;
      var sendData = {
        body:data,
        conversationId: this.partid.chatMetadata[0].conv_id,
        clientId: this.socketService.clientid,
        participants: ['H' + this.uid, 'U' + this.partid.user_id],
        sender:'H' + this.uid,
        trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
        mimeType: 'text/plain',
        type: 'conversation'
      }
      this.sendBrodcastTM(sendData);
  });
  }

  buildBrodcastForm(): void {
    this.brodcastForm = this.formBuilder.group({
      type: ['', Validators.required],
      brodcast_list: this.formBuilder.array([
        this.buildBrodcastSelection(),
      ])
    });
  }

  buildBrodcastSelection(): FormGroup {
    return this.formBuilder.group({
      name: ["", Validators.required]
    })
  }

  get formData(): FormArray { return <FormArray>this.brodcastForm.get('brodcast_list'); }

  addMoreBrodcast(): void {
    this.brodcast_list = this.brodcastForm.get('brodcast_list') as FormArray;
    this.brodcast_list.push(this.buildBrodcastSelection());
  }

  removeBrodcast(index: number) {
    this.brodcast_list = this.brodcastForm.get('brodcast_list') as FormArray;
    this.brodcast_list.removeAt(index);
    /**Draw Preview Message Again */
    this.drawMessagePreview();
  }
  changeOptions(e) {
    this.brodcast_list = this.brodcastForm.get('brodcast_list') as FormArray;
    while (this.brodcast_list.length !== 1) {
      this.brodcast_list.removeAt(0)
    }
  }
  drawMessagePreview($event?: any): void {
    let selectedBrodcast: any = this.brodcastForm.controls["brodcast_list"].value.map(broadcast => { return broadcast.name });
    /**Check for duplicates */
    if (this.hasDuplicates(selectedBrodcast)) {
      this.systemService.showSnackBar("Broadcast already selected.", "Ok");
      // return;
    }
    else {
      let broadcastData: any = [];
      selectedBrodcast.forEach(data => {
        let obj = this.brodcastFilterData.filter(broadcast => { return (broadcast.id == data) });
        broadcastData.push(obj[0]);
      });
      /**First Push Into Service */
      this.broadcastPreviewData = broadcastData;
      this.broadcastPreviewData.forEach((data, key) => {
        if (data.media_url) {
          this.socketService.getfileurl(data.media_url, data.mime_type, data => {
            broadcastData[key]["broadcastResponseImageUrl"] = data;
            broadcastData[key]["broadcastImageUrl"] = data;
          })
        }
      });
    }
  }
  processBrodcast() {
    
    let selectedBroadcast: any = this.brodcastForm.controls["brodcast_list"].value.map(broadcast => { return broadcast.name });
    if (this.hasDuplicates(selectedBroadcast)) {
      this.systemService.showSnackBar("Duplicate Broadcast Selected.", "Ok");
      return
    }
    
    var answer = confirm("Are you sure want to send broadcast to " + this.selectedUsers.length + " User")
    if (answer) {
      let broadcastData: any = [];
      this.uid = localStorage.getItem('scarletuserid');
      selectedBroadcast.forEach(data => {
        let obj = this.brodcastFilterData.filter(broadcast => { return (broadcast.id == data) });
        broadcastData.push(obj[0]);
      });
      let broadcastType = this.brodcastForm.controls["type"].value;
      let scope = this;
      let counter = 0;
      /**Check for selected user */
      if (!this.selectedUsers.length) {
        this.systemService.showSnackBar("Please select users first to broadcast.", "Ok");
        return false;
      }
      if (broadcastType == "sms") {
        this.sendBroadcastViaSMS(broadcastData);
      } else {
        var attachment;
        var addType;
        var partner;
        var allconv;
        var datenew;
        scope.allconvdata = scope.socketService.getHashMap();
        broadcastData.forEach(broadcastselected => {
          attachment = null;
          addType = null
          partner = null;
          allconv = null;
          datenew = null;
          if (broadcastselected.mime_type) {
            if ((broadcastselected.mime_type).includes('image')) {
              addType = 'image'
            } else if ((broadcastselected.mime_type).includes('video')) {
              addType = 'video'
            } else {
              addType = 'file'
            }
            attachment = {
              link: broadcastselected.media_url,
              type: addType
            }
          } else {
            attachment = null;
          }
          allconv = scope.allconversations;
          this.selectedUsers.forEach(element => {
            scope.partid = element;
            
            scope.convid = [];
            partner = null;
            let newmessagedata;
            datenew = new Date();
            datenew = datenew * 1;
            if (scope.partid.chatMetadata[0].conv_id) {
              broadcastselected.message =  this.removeHTMLtags(broadcastselected.message);
              if (attachment) {
                newmessagedata = {
                  conversationId: scope.partid.chatMetadata[0].conv_id,
                  clientId: this.socketService.clientid,
                  //title: this.uid + scope.partid.user_id,
                  type: 'conversation',
                  sender: 'H' + this.uid,
                  //receiver: scope.partid.user_id,
                  trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                  participants: ['H' + this.uid, 'U' + scope.partid.user_id],
                  attachment: {
                    link: broadcastselected.media_url,
                    type: broadcastselected.mime_type
                  },
                  body: broadcastselected.message,
                  mimeType: 'text'
                };
              }
              else {
                newmessagedata = {
                  conversationId: scope.partid.chatMetadata[0].conv_id,
                  clientId: this.socketService.clientid,
                  //title: this.uid + scope.partid.user_id,
                  type: 'conversation',
                  sender: 'H' + this.uid,
                  //receiver: scope.partid.user_id,
                  participants: ['H' + this.uid, 'U' + scope.partid.user_id],
                  body: broadcastselected.message,
                  trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                  mimeType: 'text/plain',
                };
              }
              this.socketService.emitNewMessage(newmessagedata)
                .subscribe(data => {
                  
                  counter = counter + 1;
                  this.checksuccess(counter, this.selectedUsers.length);
                });
            }
            else {
              /* if(attachment)
              {
                  newmessagedata = {
                    title: this.uid + scope.partid.id,
                    type: 'conversation',
                    sender: this.uid,
                    receiver: scope.partid.id,
                    trackID : Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                    participants: [this.uid, scope.partid.id],
                    attachment: {
                        link: broadcastselected.media_url,
                        type: broadcastselected.mime_type
                    },
                    body: broadcastselected.message,
                    mimeType: 'text'                            
                  };
              }
              else
              {
                newmessagedata = {
                    title: this.uid + scope.partid.id,
                    type: 'conversation',
                    sender: this.uid,
                    receiver: scope.partid.id,
                    participants: [this.uid, scope.partid.id],
                    body: broadcastselected.message,
                    trackID : Math.random().toString(36).slice(2).substr(0, 7) + datenew,
                    mimeType: 'text/plain',
                  };
              } */
              //this.socketService.emitNewMessage(newmessagedata);  
              counter = counter + 1;
              this.checksuccess(counter, this.selectedUsers.length)
            }
          });
        });
      }

    }
  }
  checksuccess(counter, userlength) {
    if (counter == userlength) {
      localStorage.setItem('getStatus', 'Broadcast has been successfully sent');
      this.systemService.showSnackBar("Brodcast Successfully Sent.", "Ok");
      /*Clear user list */
      this.appContext.multiUserSelectionUsers.next(null);
      /**Reset form */
      this.tsForm.resetForm();
      //  scope.selection.clear();
      //scope.showmessage();
    }
  }
  sendBroadcastViaSMS(selectedBroadcast: any): void {
    var hc_id_data_fk = localStorage.getItem('scarletuserid');
    
    let scope = this;
    
    // parseInt(localStorage.getItem('scarletuserid')
    this.getphoneNumberList(data => {
      selectedBroadcast[0].message =  this.removeHTMLtags(selectedBroadcast[0].message);
      if (selectedBroadcast[0].message.length > 120) {
        this.systemService.showSnackBar("Error : Message length is more than 120 Character", "Ok");
        return;
      }
      this.brodcastService.sendSMS(scope.phonelist.join(), selectedBroadcast[0].message,hc_id_data_fk)
      .pipe(
        catchError((error: any) => {
          this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        
      )
        .subscribe(data => {
          scope.systemService.showSnackBar("Message sent.", "Ok");
          scope.appContext.multiUserSelectionUsers.next(null);
          scope.tsForm.resetForm();
        })
    })
  }
  getphoneNumberList(cb) {
    var scope = this;
    var count = 0;
    this.selectedUsers.forEach(async (user) => {
      await this.getUserPhonenumber(user.user_id, data => {
        
        scope.phonelist.push('+' + data.country_code + data.phone);
        count = count + 1;
        if (count == scope.selectedUsers.length) {
          cb(true)
        }
      })
    });
  }
  getUserPhonenumber(id, cb) {
    this.userlistService
      .getGraphQlData('{getOnePatient(appMode:false,patientId:' + id + '){country_code,phone}}')
      .pipe(
        catchError((error: any) => {
          this.systemService.showSnackBar(
            error.error && error.error.error_message
              ? error.error.error_message
              : 'Sorry something went wrong.',
            'OK'
          );
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
        data = (data as any).data;
        cb(data.getOnePatient)
      })
  }
  protected filterBroadcast() {
    if (!this.brodcastFilterData) {
      return;
    }
    // get the search keyword
    let search = this.broadcastFilterCtrl.value;
    let valid_search = this.commonService.isHTML(search);
    if(valid_search  === true){
      this.broadcastFilterCtrl.value.patchValue('')
      return;
    }
    if (!search) {
      this.filteredBroadcast.next(this.brodcastFilterData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the broadcast
    this.filteredBroadcast.next(
      this.brodcastFilterData.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getBrodcastList(): void {
    this.loadingSubject.next(true);
    this.brodcastService.getAllBrodcast().pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      this.brodcastFilterData = data;
      this.filteredBroadcast.next(data);
      this.brodcastSubject$.next(data);
    });
  }

  hasDuplicates(array): boolean {
    return (new Set(array)).size !== array.length;
  }

  sendBrodcastTM(sendData){
    let counter = 0;
    this.socketService.emitNewMessage(sendData)
    .subscribe(data => {
      counter = counter + 1;
      this.checksuccess(counter, this.selectedUsers.length);
    });
  }
  removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }
}
