import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from 'src/app/shared/services';
import { PolicyApiService } from 'src/app/shared/services/policy-api.service';

@Component({
  selector: 'app-policy-mobile-number-verification-modal',
  templateUrl: './policy-mobile-number-verification-modal.component.html',
  styleUrls: ['./policy-mobile-number-verification-modal.component.scss']
})


export class PolicyMobileNumberVerificationModalComponent implements OnInit {

  public addMobileNumberForm: FormGroup;
  public disableOtpBtn: boolean = true;

  constructor(
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialogRef: MatDialogRef<PolicyMobileNumberVerificationModalComponent>,
    private policyApiService: PolicyApiService,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {

    this.addMobileNumberForm = this.formBuilder.group({
      name: [{ value: this.data.customer_details.name, disabled: true}],
      mobileNumber: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      otp: ["", [Validators.required]]
    });

  }

  get mobileNumber () {
    return this.addMobileNumberForm.get('mobileNumber');
  }

  get otp () {
    return this.addMobileNumberForm.get('otp');
  }

  isValidMobileNumber(){
    return this.mobileNumber.value.toString().length === 10;
  }
  

  sendOTP(){

    let custDetails = { ...this.data, mobileNumber: this.mobileNumber.value };
    this.policyApiService.sendOtp(custDetails)
      .subscribe((data) => {
        if ( data.status === 201 ){
          this.systemService.showSuccessSnackBar(data.message,"Ok");
          this.disableOtpBtn = false;
        }
      }, (error) => {
        this.systemService.handleErrorResponse(error);
      });
  }

  verifyOTP(){
    let custDetails = { ...this.data, mobileNumber: this.mobileNumber.value, otp: this.otp.value  };
    this.policyApiService.verifyOTP(custDetails)
      .subscribe((data) => {
        if ( data.status === 200 ){
          this.systemService.showSuccessSnackBar(data.message, "Ok");
          this.dialogRef.close(true);
        }
      }, (error: any) => {
        this.systemService.handleErrorResponse(error);
      });
  }

  close(){
    this.dialogRef.close(false);
  }

}
