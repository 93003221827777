/*==========================================
Title: Productivity hours changes
Author: Soundharya AM
Date:   29 April 2020
Last Change : Productivity hours changes
===========================================*/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

declare var google: any;

export interface PeriodicElement {
  time: string;
  sr_no: number;
  break_time: string;
}

@Component({
  selector: 'app-hctimelog',
  templateUrl: './hctimelog.component.html',
  styleUrls: ['./hctimelog.component.scss']
})

export class HctimelogComponent implements OnInit {
  newDate: any;
  displayedColumns: string[] = ['sr_no', 'time'];
  dataSource: any;
  tabulardata: any;
  public showdata: boolean = false;
  public showButtonLoader: boolean = false;
  public localStoredHCData: any;
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public todaytimelogdata: any = 0;
  public breakcount: any = 0
  public starttime: any;
  public userdata: any;
  firstloggeddate: any;
  public morrow: any = new Date();
  total_time: any;
  public onit: boolean = true;
  public selectButton: boolean = false;
  roomsFilter: any;
  currentlogdata: any;
  temptime: any;
  currentstarttime: any;
  entrydate: any;
  mainfilterdays: any;
  graph_data: any;
  public lastpage: number = 0;
  hclogdata: any;
  graphGenerated: boolean = false;
  todaydate: any = [];
  total_hours: any;
  public activeButton: any = "today";
  weekly_dates: any = [];
  monthly_dates: any = [];
  random_dates: any = [];
  charthours: any = [];
  monthly_data: any = {};
  hc_data: any;
  HCLoggedValue: any;
  adminLogTime: any;
  page: any;
  templatedata: any;
  hc_timelog_data: any;
  range: any = 'today';
  currentdate: any;
  public endoflist: boolean = false;
  weekly_startdate = ['2020-03-27T00:00:00.000Z', '2020-03-28T00:00:00.000Z', '2020-03-29T00:00:00.000Z', '2020-03-30T00:00:00.000Z', '2020-03-31T00:00:00.000Z', '2020-04-01T00:00:00.000Z', '2020-04-02T00:00:00.000Z'];
  weekly_enddate = ['2020-03-27T23:59:59.000Z', '2020-03-28T23:59:59.000Z', '2020-03-29T23:59:59.000Z', '2020-03-30T23:59:59.000Z', '2020-03-31T23:59:59.000Z', '2020-04-01T23:59:59.000Z', '2020-04-02T23:59:59.000Z'];
  productivitytime: any = [];

  constructor(private browserTitle: Title, public fb: FormBuilder, private route: ActivatedRoute, private commonService: CommonserviceService, private systemService: SystemService) { }

  public dataForm = this.fb.group({
    startdate: ['', Validators.required],
    enddate: ['', Validators.required]
  });

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Health Coach Time Logs');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.localStoredHCData = params.id;
      this.getUserData(this.localStoredHCData);
      this.currentDate(this.localStoredHCData);
      this.page = 1;
      this.timelogs(this.localStoredHCData, 'today');
    })

  }

  showActiveButton(event) {
    this.activeButton = event;
    this.range = event;
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.dataSource = '';
    this.hclogdata = '';
    this.tabulardata = [];
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.timelogs(this.localStoredHCData, this.range);
      this.graphGenerated = true;
    }
  }


  // Health Coach TimeLogs 
  // ----------------------------

  timelogs(hc_id, range) {
    if (this.page != this.lastpage) {
      this.lastpage = this.page;
      this.selectButton = true;
      if (range == 'today') {
        var d = new Date();
        var p = new Date();
        var graphtime_start = new Date();
        graphtime_start.setHours(0, 0, 0, 0);
        var dep_graphtime_start = moment(graphtime_start).toISOString();
        var dep = moment(d).toISOString();
        var pep = moment(p).toISOString();
        setTimeout(() => {
          this.getGraphData(dep, pep, this.localStoredHCData, 'daily');
        }, 800);
      }
      else if (range == 'weekly') {
        var date = new Date();
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        var start_week = new Date(date.setDate(diff));
        var current_data = new Date();
        var dep = moment(start_week).toISOString();
        var dep_graphtime_start = moment(start_week).toISOString();
        var pep = moment(current_data).toISOString();
        setTimeout(() => {
          this.getGraphData(dep, pep, this.localStoredHCData, 'weekly');
        }, 800);
      }
      else if (range == 'monthly') {
        this.selectButton = true;
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var p = new Date();
        firstDay.setHours(0, 0, 0, 0);
        var dep = moment(firstDay).toISOString();
        var dep_graphtime_start = moment(firstDay).toISOString();
        var pep = moment(p).toISOString();
        setTimeout(() => {
          this.getGraphData(dep, pep, this.localStoredHCData, 'weekly');
        }, 800);
      }
      else if (range == 'random') {
        var postdata = this.dataForm.value;
        var startd = postdata.startdate;
        var endd = postdata.enddate;
        startd.setHours(0, 0, 0, 0);
        endd.setHours(23, 59, 59, 99);
        var dep_graphtime_start = moment(startd).toISOString();
        var dep = moment(startd).toISOString();
        var pep = moment(endd).toISOString();
        setTimeout(() => {
          this.getGraphData(dep, pep, this.localStoredHCData, 'weekly');
        }, 800);
      }
      this.commonService.getTimelog(dep_graphtime_start, pep, hc_id, this.page.toString(), '10')
        .subscribe(
          (res: any) => this.getTimelogSuccess(range, res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }

  }

  private getTimelogSuccess(range, data) {
    this.dataForm.reset();
    this.hclogdata = data;
    if (this.hclogdata.data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
      }
    }
    else {

      if (this.onit) {
        this.tabulardata = this.hclogdata.data;
      }
      else {
        for (var k = 0; k < this.hclogdata.data.length; k++) {
          this.tabulardata.push(this.hclogdata.data[k]);
        }
      }
      if (range == 'today') {
        this.firstloggeddate = this.tabulardata[0];
      }

      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);

    }
  }


  // To Get Graph Data
  // ----------------------------

  getGraphData(dep, pep, hc_id, type) {
    this.commonService.getGraphData(dep, pep, hc_id, type)
      .subscribe(
        (res: any) => this.onGraphDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGraphDataSuccess(data) {
    this.graph_data = data;
    this.productivityChart(this.graph_data.data)
  }

  // ----------------------------


  // To Get Current Data
  // ----------------------------

  currentDate(hc_id) {
    this.selectButton = true;
    var d = new Date();
    var p = new Date();
    var dep = moment(d).format("YYYY-MM-DD");
    var pep = moment(p).format("YYYY-MM-DD");

    this.commonService.getCount(dep, pep, hc_id)
      .subscribe(
        (res: any) => this.onCurrentDateSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onCurrentDateSuccess(data) {
    this.showdata = true;
    this.hc_data = data;
    this.HCLogTime(this.hc_data);
    this.adminLoggedTime(this.hc_data);
    
  }

  adminLoggedTime(hc_data) {
    if (hc_data.data.admin_total_minutes == 0) {
      this.adminLogTime = "00 Hrs 00 Min";
    } else {
      var num = hc_data.data.admin_total_minutes;
      var hours = (num / 60);
      let rhours: any = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      let rminutes: any = Math.round(minutes);
      let data1 = rhours.toString();
      let data2 = rminutes.toString();
      if (data1.length == 1) {
        rhours = "0" + rhours;
      }
      if (data2.length == 1) {
        rminutes = "0" + rminutes;
      }
      this.adminLogTime = rhours + " Hrs " + rminutes + " Min";
    }
  }


  HCLogTime(hc_data) {
    if (hc_data.data.total_logs == 0) {
      this.HCLoggedValue = "00 Hrs 00 Min";
    } else {
      let HCLoggedValue = hc_data.data.total_logs;
      let hour: any = (HCLoggedValue * 6) / 60;
      let min: any = (HCLoggedValue * 6) % 60
      let resultHR: any = this.getroundown(hour);
      let Hr: any = resultHR.toString();
      let Min: any = min.toString();
      if (Hr.length == 1) {
        resultHR = "0" + resultHR;
      }
      if (Min.length == 1) {
        min = "0" + min;
      }
      this.HCLoggedValue = resultHR + " Hrs " + min + " Min";
    }
  }
  // ----------------------------

  productivityChart(graph_data) {
    google.load('visualization', '1', { packages: ['corechart'] });
    google.charts.setOnLoadCallback(drawStuff);

    function drawStuff() {
      var Combined = new Array();
      Combined[0] = ['Date', 'Minutes'];
      for (var i = 0; i < graph_data.length; i++) {
        Combined[i + 1] = [graph_data[i].key, graph_data[i].total_minutes];
      }
      var data = new google.visualization.arrayToDataTable(Combined, false);
      var options = {
        bars: 'vertical',
        chartArea: {
          width: '70%',
          height: '60%'
        },
        legend: {
          position: 'none'
        },
        explorer: { axis: 'vertical', keepInBounds: true }
      };
      var chart = new google.visualization.BarChart(document.getElementById('productivityChart'));
      chart.draw(data, options);
    }
    google.charts.setOnLoadCallback(drawStuff);
  }


  // To Get Current Data
  // ----------------------------

  getUserData(ide) {
    this.commonService.getOneDataById(ide, "users")
      .subscribe(
        (res: any) => this.onUserDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onUserDataSuccess(data) {
    this.userdata = data.data;
  }

  // ----------------------------


  // Date Filters
  // ----------------------------

  datefilter(today, next) {
    this.charthours = [];
    this.commonService.gethctimelog(today, next, this.localStoredHCData, 'hctimelog')
      .subscribe(
        (res: any) => this.onDateFilterSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onDateFilterSuccess(data) {
    if (data.length != 0) {
      this.charthours.push(Math.floor((data.length * 6) / 60) + '.' + (data.length * 6) % 60);
    }
  }

  // ----------------------------

  convertDate(d) {
    var date = new Date(d);
    return date.toLocaleString();
  }

  // getroundown(a) {
  //   var hours = Math.floor(a / 60);
  //   return hours;
  // }
  getroundown(a) {
    return Math.floor(a);
  }

  convertTime(datetime) {
    var d = new Date(datetime);
    return d.toLocaleTimeString();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataForm.get('enddate').reset();
    this.currentdate = event.target.value;
    this.newDate = new Date(event.target.value);
    this.newDate.setDate(this.newDate.getDate() + 29)
  }

}
