import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticateService } from 'src/app/shared/services';
@Injectable({
  providedIn: 'root'
})
export class ContentGuardGuard implements CanActivate {
  
  constructor(private router: Router, private authService: AuthenticateService) {}
  
  canLoad(route: Route): boolean {
    const isAdmin = (this.authService.app === 'content' || this.authService.app === 'admin') || this.authService.app === 'superadmin';
    if (isAdmin) {
      return true;
    }
    this.router.navigateByUrl(`/application`);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAdmin = (this.authService.app === 'content' ||  this.authService.app === 'admin') || this.authService.app === 'superadmin';
    if (isAdmin) {
      return true;
    }
    this.router.navigateByUrl(`/application`);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
  
}
