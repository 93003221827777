/*==========================================
Title: questionnair mapping 
Author: Mandeep singh
Date:   5 may 2020
Last Change :  questionnair mapping  creation
===========================================*/
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { throwError } from 'rxjs/index';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs/Rx';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-createquestionnairmapping',
  templateUrl: './createquestionnairmapping.component.html',
  styleUrls: ['./createquestionnairmapping.component.scss']
})

export class CreatequestionnairmappingComponent implements OnInit {
  public dataForm = this.fb.group({
    client_data_fk: ['', [Validators.required]],
    primary_condition_data_fk: ['', Validators.required],
    created_by_data_fk: [''],
    questionnaire_ids: ['', Validators.required],
  });
  public newitem: boolean = false;
  public allclients: any;
  allquestionnair: any;
  allconditions: any;
  mappingData: any;
  private myhash: any = {};
  questionannaring_mapping_id: any;
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredQuestionnair: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public showButtonLoader: any;
  public searchmatclient: FormControl = new FormControl();
  public searchmatquestionnair: FormControl = new FormControl();
  formdata: any;
  
  @ViewChild('singleSelectClient', { static: false }) singleSelectClient: MatSelect;


  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Questionnaire Mapping');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      if (params.id == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        this.questionannaring_mapping_id = params.id;
        this.getMappingData(params.id);
      }
    })
    this.getClients();
    this.getConditions();
    this.getQuestionnairData();
  }

  modelChangedClient() {
    this.filterClients();
  }

  modelChangedQuestionnair() {
    this.filterQuestionnair();
  }

  private filterQuestionnair() {
    if (!this.allquestionnair) {
      return;
    }
    // get the search keyword
    let search = this.searchmatquestionnair.value;
    if (!search) {
      this.filteredQuestionnair.next(this.allquestionnair.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredQuestionnair.next(
      this.allquestionnair.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  // To Get Mapping Data
  // ----------------------------

  getMappingData(id) {
    this.contentService.getOneDataById(id, "questionnairemapping")
    .subscribe((res: any) => this.getMappingDataSuccess((res as any).data),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getMappingDataSuccess(data) {
    this.mappingData = data;
    this.formdata = this.mappingData[0];
    this.formdata.client_data_fk = this.formdata.client_data_fk.toString();
    this.formdata.primary_condition_data_fk = this.formdata.primary_condition_data_fk.toString();
    this.formdata.questionnaire_ids = this.formdata.questionnaire_ids.toString();
    this.formdata.questionnaire_ids = (this.formdata.questionnaire_ids ? this.formdata.questionnaire_ids.split(",") : [])
    this.dataForm.patchValue(this.formdata);
  }

  // ----------------------------


  // To Get Conditions Data
  // ----------------------------

  getConditions() {
    this.contentService
    .getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}',false)
    .subscribe((res: any) => this.getConditionSuccesss((res as any).data),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getConditionSuccesss(data){
    this.allconditions = data.getAllConditions;
    this.allconditions.forEach(element => {
    this.myhash[element.id] = element.title;
    });
  }

  // ----------------------------


  // To Get Clients Data
  // ----------------------------

  getClients() {
    this.contentService
      .getGraphQlData('{getAllClients(appMode: false, status:true){id,title}}', true)
      .subscribe((res: any) => this.getClientsSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )  
    }
    
    private getClientsSuccess(data) {
      this.allclients = data.getAllClients;
      this.filteredClient.next(this.allclients.slice());
    }

  // ----------------------------


  // To Get Questionnair Data
  // ----------------------------

  getQuestionnairData() {
    this.contentService
    .getGraphQlData('{getParentQuestionnaire(status: true){id,title}}')
    .subscribe((res: any) => this.getQuestionnairSuccess((res as any).data),
    (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getQuestionnairSuccess(data) {
    this.allquestionnair = data.getParentQuestionnaire;
    this.filteredQuestionnair.next(this.allquestionnair.slice());
  }

  // ----------------------------


  // Submitting QuestionnairMapping 
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.client_data_fk = postdata.client_data_fk.toString();
    postdata.primary_condition_data_fk = postdata.primary_condition_data_fk.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.questionnaire_ids = JSON.stringify(postdata.questionnaire_ids);

    if (this.newitem == true) {
      this.contentService
        .postToBlade(postdata, 'questionnairemapping')
        .subscribe((res: any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
    else {
      this.contentService
        .updateMappingToBlade(postdata, this.questionannaring_mapping_id, 'questionnairemapping')
        .subscribe((res: any) => this.onUpdateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Questionnair Mapping has been done"), "OK");
    this.router.navigate(['/content/questionnairmapping']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Questionnair Mapping has been updated"), "OK");
    this.router.navigate(['/content/questionnairmapping']);
  }

  // ----------------------------

}
