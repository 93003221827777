import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';

@Injectable()
export class AwsuploadService {
  apiUrl: string = environment.serverUrl;

  private prodUrl = this.apiUrl;

  constructor(private _http: Http) { }

  getS3URL(path, type): Observable<string> {
    var SessionID = localStorage.getItem('scarlettoken');
    if((SessionID) || (SessionID != ''))
    {
      const endPoint = 'users/aws';
      let headers = new Headers();
      headers.append('sessionID', SessionID);
      headers.append('path', path);
      headers.append('type', type);
      let options = new RequestOptions({ headers: headers });
      return this._http
                .get(this.prodUrl + endPoint, options)
                .map(response => <string> response.json());
    }
  }

  uploadToS3(file, type, url): Observable<any> {
    var SessionID = localStorage.getItem('scarlettoken');
    if((SessionID) || (SessionID != ''))
    {
      let headers = new Headers();
      headers.append('Content-Type', type);
      let options = new RequestOptions({ headers: headers });
      return this._http
                .put(url,file,options)
                .map(response => response);
    }
  }
}
