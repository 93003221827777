import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-viewallactivation',
  templateUrl: './viewallactivation.component.html',
  styleUrls: ['./viewallactivation.component.scss']
})

export class ViewallactivationComponent implements OnInit {
  userid: any;
  public searchcriteria: any;
  public showloader: any;
  lastSearch: any;
  status: any = status;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any = [];
  filterdata: any = [];
  public emptyplaceholder: boolean = false;
  dataSource: any;
  displayedColumns: string[] = ['code', 'is_active', 'is_activated'];


  constructor(private browserTitle: Title, private route: ActivatedRoute, private commonService: CommonserviceService,
    private systemService: SystemService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ViewallactivationComponent>) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | View All Activation Code');
  }

  ngOnInit() {
    this.setSEOTags();
    // this.route.params.subscribe(params => {
    //   this.userid = params.id;
    // })

    this.userid = this.data.element.id;

    this.searchcriteria = "";
    this.page = 1;
    this.status = 'active';
    this.getActivationcodes(this.userid);

  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.getActivationcodes(this.userid);
    }
  }


  // To Get Activation Codes 
  // ----------------------------

  getActivationcodes(id) {
    if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.lastpage = this.page;
      this.showloader = true;
      this.commonService.getAllActivationCodeData(this.page.toString(), "20", this.status, id)
        .subscribe(
          (res: any) => this.activationCodesData(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  private activationCodesData(data) {
    data = (data as any).data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    }
  }

  // ----------------------------

  closeModal(): void {
    this.dialogRef.close();
  }

}

export enum status {

}