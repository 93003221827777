import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-medicalhistoryadd',
  templateUrl: './medicalhistoryadd.component.html',
  styleUrls: ['./medicalhistoryadd.component.scss']
})
export class MedicalhistoryaddComponent implements OnInit {

  public selectedUserID: any;
  public typeFilters: any;
  public showButtonLoader: boolean;
  public hideDiv: boolean = true;
  medicalData:any;
  pipe = new DatePipe('en-US');

  public dataForm = this.fb.group({
    patient_id_fk: [''], //[, [Validators.required]],
    sub_type: [''],
    other_subtype: [''],
    functional_class: [''],
    date_of_diagnosis: [''],
    condition: [''],
    cur_date_of_diagnosis: [''],
    previous_condition: [''],
    pre_date_of_diagnosis: [''],
    end_date: [''],
    allergies: [''],
    general_symptoms: [''],
  });

  constructor(public dialog: MatDialog, private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder, private systemService: SystemService) { }

  ngOnInit(): void {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getMedicalData()

  }
  onChange(event) {
    if (event.value === 'other') {
      this.hideDiv = false;
    } else {
      this.hideDiv = true;
    }
  }

  getMedicalData() {
    this.UserdataService.getUserMedicalHistory(this.selectedUserID)
    .subscribe(
      (res: any) => this.gethospitaizationData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    );
  }

  private gethospitaizationData(data) { 
    data = (data as any).data;
    
    this.medicalData = data;
    
    this.dataForm.patchValue(this.medicalData);
    this.dataForm.controls.functional_class.patchValue(this.medicalData.functional_class.toString())
    this.dataForm.controls.condition.patchValue(this.medicalData.comorbidities[0].name)
    this.dataForm.controls.cur_date_of_diagnosis.patchValue(this.medicalData.comorbidities[0].date_of_diagnosis)
    this.dataForm.controls.previous_condition.patchValue(this.medicalData.previous_conditions[0].name)
    this.dataForm.controls.pre_date_of_diagnosis.patchValue(this.medicalData.previous_conditions[0].date_of_diagnosis)
    this.dataForm.controls.end_date.patchValue(this.medicalData.previous_conditions[0].end_date)
    // if (this.hospitalizationData.hospitalisations.length != 0) {
    //   name.value.shift();
    //   name.controls.shift();
    //   for (var i = 0; i < this.hospitalizationData.hospitalisations.length; i++) {
    //     name.push(this.initTextOptionswithdata(this.medicalData.hospitalisations[i]));
    //   }
    // }
  }
  uploadObject() {
    var t = [];
    var e = [];
    var i = [];
    var postdata = this.dataForm.value;
    let is_subtype_other = false;
    if (postdata.sub_type === 'other') {
      postdata.sub_type = postdata.other_subtype;
      postdata.is_subtype_other = true;
    } else {
      postdata.sub_type = postdata.sub_type;
      postdata.is_subtype_other = false;
    }
    postdata.sub_type = postdata.sub_type;
    postdata.functional_class = postdata.functional_class;
    postdata.date_of_diagnosis = this.pipe.transform(postdata.date_of_diagnosis, 'yyyy-MM-dd');
    postdata.comorbidities_count = '1';
    postdata.previous_conditions_count = '1';
    postdata.comorbidities = postdata.comorbidities;
    postdata.previous_conditions = postdata.previous_conditions;
    postdata.is_subtype_other = postdata.is_subtype_other;
    postdata.allergies = postdata.allergies;
    
    postdata.general_symptoms = postdata.general_symptoms;
    postdata.patient_id_fk = this.appContext.selectedChatUser.getValue().toString();
    let jsonData = {
      "patient_id_fk": (this.appContext.selectedChatUser.getValue()).toString(),
      "previous_conditions": JSON.stringify([{ "name": postdata.previous_condition, "date_of_diagnosis": this.pipe.transform(postdata.pre_date_of_diagnosis, 'yyyy-MM-dd'), "end_date": this.pipe.transform(postdata.end_date, 'yyyy-MM-dd') }]),
      "previous_conditions_count": "1",
      "allergies": postdata.allergies,
      "general_symptoms": postdata.general_symptoms,
      "sub_type": postdata.sub_type,
      "is_subtype_other": (postdata.is_subtype_other).toString(),
      "functional_class": postdata.functional_class,
      "date_of_diagnosis": postdata.date_of_diagnosis,
      "comorbidities_count": "1",//postdata.comorbidities_count,
      "comorbidities": JSON.stringify([{ "name": postdata.condition, "date_of_diagnosis": this.pipe.transform(postdata.cur_date_of_diagnosis, 'yyyy-MM-dd') }])

    };
    this.UserdataService.addUserMedicalHistory(jsonData).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.systemService.showSnackBar("Medical History added sucessfully.", "OK");
        this.showButtonLoader = false;
      })
  }
  convertIsoDatetime(d) {
    var date = new Date(d);
    
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

}
