import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private browserTitle: Title) { }

  ngOnInit() {
    this.setSEOTags();
  }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Home Page');
  }

}
