import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SocketService } from '../../services/socket/socket.service';

@Component({
  selector: 'app-hcleftnav',
  templateUrl: './hcleftnav.component.html',
  styleUrls: ['./hcleftnav.component.scss']
})
export class HcleftnavComponent implements OnInit {
  public haveAlerts: boolean = false;
  public dummyImage: string = "assets/img/imgplaceholder.svg";
  public imageurl: string;
  public responseImageUrl: any;
  public localStoredHCData: any;
  public swithchId : any;
  s3url : any = environment.s3url;

  constructor(private socketService: SocketService) { 
   
  }

  ngOnInit() {
    this.swithchId = sessionStorage.getItem('switchId');
    this.localStoredHCData = JSON.parse(localStorage.getItem('scarletuserdata'));
    
    /**SUbscribe alerts */
    this.socketService.systemHaveAlerts.subscribe(data => {
      this.haveAlerts = data;
    });
  }
  chat(){
  let data={
    "selectedpatent":'',
    "selectedpatentflag":false
  }
  localStorage.setItem("selectedpatentdata",JSON.stringify(data));
  
    localStorage.setItem("disableTask","hideTask");
  }
}
