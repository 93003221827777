<!-- /*==========================================
Title:  User Detail Tabs
Author: Nikhil Raj A
Date:   16 Jan 2020
Last Change :  Activity Component Added in tabs
===========================================*/ -->
<div class="col-md-5 userdetailsec">
  <mat-tab-group #tabGroup  (selectedTabChange)="tabChanged($event)"><!-- [selectedIndex]="8"(focusChange)="tabChanged($event)" -->
    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/activity.svg" />
        <span class="subtabtext">Activity</span>
      </ng-template>
      <app-activity></app-activity>
    </mat-tab>
    <mat-tab label="Know about your user">
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/about.svg" />
        <span class="subtabtext">About</span>
      </ng-template>
      <app-knowuser></app-knowuser>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/details.svg" />
        <span class="subtabtext">Details</span>
      </ng-template>
      <!-- <div class="tabtitle">
        <h4 class="titleh3">
          User details
        </h4>
      </div> -->
      <app-userdetail></app-userdetail>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/program.svg" />
        <span class="subtabtext">Program</span>
      </ng-template>
      <!-- <div class="tabtitle">
        <h4 class="titleh3">
          Program details
        </h4>
      </div> -->
      <app-programdetail></app-programdetail>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/progress.svg" />
        <span class="subtabtext">Progress</span>
      </ng-template>
      <app-programprogress></app-programprogress>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/diary.svg" />
        <span class="subtabtext">Diary</span>
      </ng-template>
      <app-diary></app-diary>
    </mat-tab>

    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/healthcoach/icons/targets.svg" />
        <span class="subtabtext">Targets</span>
      </ng-template>
      <app-targets></app-targets>
    </mat-tab> -->

    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/report.svg" />
        <span class="subtabtext">Reports</span>
      </ng-template>
      <app-labreports></app-labreports>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <img src="assets/img/healthcoach/vectors/more.svg" />
        <span class="subtabtext">Others</span>
      </ng-template>
      <!--  <div class="tabtitle">
        <h4 class="titleh3">
          Prescriptions
        </h4>
      </div> -->
      <app-prescriptions></app-prescriptions>
    </mat-tab>
    <mat-tab *ngIf="!hideTaskDisplay && localStoredHCData.client_ids[0] != '9'"><!--*ngIf="(localStoredHCData.client_ids[0] != '9')"-->
      <ng-template mat-tab-label>
        <img src="assets/img/dls/icons/hcicons/tabpartials/tasks.svg" />
        <span class="subtabtext">Tasks</span>
      </ng-template>
      <app-tasks></app-tasks>
    </mat-tab>

  </mat-tab-group>
</div>