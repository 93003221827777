import { HttpErrorResponse } from '@angular/common/http';
import { IfStmt } from '@angular/compiler';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-googleapplerawdata',
  templateUrl: './googleapplerawdata.component.html',
  styleUrls: ['./googleapplerawdata.component.scss']
})
export class GoogleAppleRawdataComponent implements OnInit {
  displayedColumns: string[] = ['start_date', 'end_date', 'type', 'data', 'action'];
  dataSource: any;
  public page: number = 1;
  public showloader: boolean = false;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  tabulardata: any;
  patient_id: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GoogleAppleRawdataComponent>, private browserTitle: Title, private _bottomSheet: MatBottomSheet, private commonService: CommonserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('MatSort', { static: false }) sort: MatSort;

  ngOnInit() {
    this.page = 1;
    this.patient_id = this.data.element;
    this.getHealthKitRawData(this.patient_id)
  }
  getHealthKitRawData(id) {
    this.commonService.getHealthKitRawData(id)
      .subscribe(
        (res: any) => {
          let data = (res as any).data;
          if (data.length == 0) {
            if (this.onit == true) {
              this.tabulardata = [];
              this.emptyplaceholder = true;
              this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
            }
            else {
              this.endoflist = true;
              this.emptyplaceholder = false;
            }
          }
          else {
            this.emptyplaceholder = false;
            if (this.onit) {
              this.tabulardata = data;
            }
            else {
              for (var k = 0; k < data.length; k++) {
                this.tabulardata.push(data[k]);
              }
            }
            this.onit = false;
            this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
            this.dataSource.sort = this.sort;
          }
        }
        ,
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.getHealthKitRawData(this.patient_id);
    }
  }

  copyRawlog(data) {
    var abc = JSON.stringify(data);
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = abc;
    inp.select();
    document.execCommand('copy', false);
    inp.remove()
    this.systemService.showSnackBar('Rawlog Copied to Clipboard', 'OK');
  }

  convertToAndroidObject(index) {
    let showData: any[] = [];
    let finalData: any[] = [];
    let data;
    showData.push(this.tabulardata.at(index));
    for(let i = 0 ; i < showData.length ; i++){
      for(let key of Object.keys(showData[i].data)){
        data = JSON.parse(JSON.stringify(showData[i].data[key]));
        finalData.push(data)
      }
    }
    return finalData
  }
  convertToIOSObject(index) {
    let showData: any[] = [];
    let finalData: any[] = [];
    showData.push(this.tabulardata.at(index));
    for(let i = 0 ; i < showData[0].data.length ; i++){
        finalData.push(showData[0].data[i])
    }
    return finalData
  }
}
