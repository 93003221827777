import { CommonserviceService } from './../../../admin/services/commonservice/commonservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject, Subject} from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from 'src/app/utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-editquiz',
  templateUrl: './editquiz.component.html',
  styleUrls: ['./editquiz.component.scss']
})

export class EditquizComponent implements OnInit {
  public dataForm = this.fb.group({
    title: ["", [Validators.required, whiteSpaceValidation]],
    detailed_text: ["", [Validators.required, whiteSpaceValidation]],
    created_by_data_fk: [""],
    language_data_fk: [Validators.required],
    success_content: [""],
    retry_content: [""],
    total_question: ["", Validators.required],
    question_mapping: this.fb.array([]),
    retryarray: this.fb.array([]),
    question: [''],
    total_retry: ["", Validators.required],
    pass_score: ["", Validators.required],
    complexity_level: ["", Validators.required],
    allow_feedback: [""],
    feedback: [""],
    tags: ["", Validators.required],
    self_parent_fk: [Validators.required]
  });
  htmlContent: any;
  chooseMedia: any;
  quizdata: any;
  passscorearray: any;
  checked: boolean = false;
  public typeselected: any = 'text';
  public newitem: boolean = true;
  public questiongroup: any;
  public showButtonLoader: any;
  public langid: any;
  public selfparentid: any;
  public childid: any;
  public retry: number;
  public totalquestions: number;
  public retryselected: boolean = true;
  public allquestions: any;
  public alltags: any;
  public allfeedbacks: any;
  public selfparentide: any;
  public language: any;
  public selectedquestionoptn: any = [];
  public isretrydisabled: boolean = false;
  public allmedia: any;
  public totquestion: any;
  public totretry: any;
  public quesno: any;
  public searchmat: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  filteredQuizes: Array<ReplaySubject<any[]>> = [];
  public searchmattag: FormControl = new FormControl();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public searchmatfeedback: FormControl = new FormControl();
  public filteredFeedback: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  @ViewChild('singleSelectTag', { static: false }) singleSelectTag: MatSelect;
  @ViewChild('singleSelectFeedback', { static: false }) singleSelectFeedback: MatSelect;

  constructor(private browserTitle: Title, public fb: FormBuilder, private cdr: ChangeDetectorRef, public dialog: MatDialog, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute, private router: Router,private commonService:CommonserviceService) { }

  initRetry() {
    return this.fb.group({
      retryarray: this.fb.array([])
    });
  }

  initQuestions() {
    return this.fb.group({
      question: ['']
    });
  }

  ngOnInit() {
    this.setSEOTags();
    this.getTags();
    this.getFeedback();
    this.getQuestions(res => {
      this.route.params.subscribe((params) => {
        this.language = <FormArray>this.dataForm.controls.language_data_fk;
        this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
        this.langid = params.langid;
        if (params.id == 'new') {
          this.selfparentid = null;
          this.selfparentide.setValue(null)
          this.newitem = true;
          this.language.setValue(this.langid);
        }
        else {
          if (params.langid == 1 && params.chid == 0) {
            this.childid = params.id;
            this.selfparentid = null;
            this.getallQuizData(this.childid);
            this.newitem = false;
          }
          else if (params.langid != 1 && params.chid == 0) {
            this.newitem = true;
            this.childid = params.id;
            this.selfparentid = params.id;
            this.getallQuizData(this.childid);
          }
          else {
            this.childid = params.chid;
            this.newitem = false;
            this.selfparentid = params.id;
            this.getallQuizData(params.chid);
          }
          this.isretrydisabled = true;
        }
      });
    })
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Quiz');
  }

  modelChanged(event, index) {
    this.filterBanksMulti(index);
  }

  modelChangedTags() {
    this.filterTags();
  }

  modelChangedFeedback() {
    this.filterFeedback();
  }

  private filterBanksMulti(index) {
    if (!this.allquestions) {
      return;
    }
    let search = this.searchmat.value;
    if (!search) {
      this.filteredQuizes[index].next(this.allquestions.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredQuizes[index].next(
      this.allquestions.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  private filterTags() {
    if (!this.alltags) {
      return;
    }
    // get the search keyword
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterFeedback() {
    if (!this.allfeedbacks) {
      return;
    }
    // get the search keyword
    let search = this.searchmatfeedback.value;
    if (!search) {
      this.filteredFeedback.next(this.allfeedbacks.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredFeedback.next(
      this.allfeedbacks.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  isretryDisabled() {
    if (this.isretrydisabled == true) {
      return true;
    }
    else {
      return false;
    }
  }

  totalretry(e) {
    this.totretry = e;
    this.totquestion = this.dataForm.controls.total_question.reset();
    var control = <FormArray>this.dataForm.controls['question_mapping'];
    while (control.length !== 0) {
      control.removeAt(0);
    }
    for (let k = 0; k < e; k++) {
      control.push(this.initRetry());
    }
  }

  totalquestion(e) {
    this.totquestion = e;
    this.quesno = parseInt(e);
    this.passscorearray = [];
    var control1 = <FormArray>this.dataForm.controls['question_mapping'];
    for (let z = 0; z < this.totretry; z++) {
      if ((<FormArray>control1.controls[z]).controls['retryarray'] && (<FormArray>control1.controls[z]).controls['retryarray'].length !== 0) {
        if ((<FormArray>control1.controls[z]).controls['retryarray'].length < e) {
          for (let m = (<FormArray>control1.controls[z]).controls['retryarray'].length; m < e; m++) {
            (<FormArray>control1.controls[z]).controls['retryarray'].push(this.initQuestions());
            this.filteredQuizes.splice((z * this.quesno + m - 1), 0, new ReplaySubject<any[]>(1));
            this.filteredTags[m] = new ReplaySubject<any[]>(1);
            this.filteredFeedback[m] = new ReplaySubject<any[]>(1);
          }
        }
        if ((<FormArray>control1.controls[z]).controls['retryarray'].length > e) {
          for (let m = (<FormArray>control1.controls[z]).controls['retryarray'].value.length; m > e; m--) {
            (<FormArray>control1.controls[z]).controls['retryarray'].removeAt(m - 1);
            this.filteredQuizes.splice(z * this.quesno + m - 1, 0);
            this.filteredTags[m] = new ReplaySubject<any[]>(1);
            this.filteredFeedback[m] = new ReplaySubject<any[]>(1);
          }
          this.selectOptions();
        }
      }
      else {

        for (let p = 0; p < control1.controls.length; p++) {
          for (let k = 0; k < e; k++) {
            (<FormArray>control1.controls[p]).controls['retryarray'].push(this.initQuestions());
            this.filteredQuizes[p * this.quesno + k] = new ReplaySubject<any[]>(1);
            this.filteredTags[k] = new ReplaySubject<any[]>(1);
            this.filteredFeedback[k] = new ReplaySubject<any[]>(1);
          }
        }
        this.selectOptions();
      }
    }
    for (let count = e; count > 0; count--) {
      this.passscorearray.push(count);
    }
  }

  selectOptions() {
    this.selectedquestionoptn = [];
    var control4 = <FormArray>this.dataForm.controls['question_mapping'];
    if (control4 && control4.value) {
      for (let z = 0; z < this.totretry; z++) {
        this.selectedquestionoptn[z] = [];
        for (let k = 0; k < this.totquestion; k++) {
          for (let l = 0; l < this.allquestions.length; l++) {
            if (this.allquestions[l].id == control4.value[z].retryarray[k].question) {
              this.selectedquestionoptn[z][k] = this.allquestions[l];
            }
          }
        }
      }
    }
  }

  selectedOptions(e, j, i) {
    if (!this.selectedquestionoptn[i]) {
      this.selectedquestionoptn[i] = [];
    }
    for (let k = 0; k < this.allquestions.length; k++) {
      if (e == this.allquestions[k].id) {
        this.selectedquestionoptn[i][j] = this.allquestions[k];
      }
    }
  }

  disableOptions(e, i) {
    if (this.selectedquestionoptn[i]) {
      for (let k = 0; k < this.selectedquestionoptn[i].length; k++) {
        if (this.selectedquestionoptn[i][k] && this.selectedquestionoptn[i][k].id && e == this.selectedquestionoptn[i][k].id) {
          return true;
        }
      }
    }
  }


  // To Get Questions
  // ----------------------------

  getQuestions(cb) {
    this.contentService.getGraphQlData('{getParentQuestions(status:true){id,title}}', false)
      .subscribe((res: any) => this.getQuestionSuccess(cb,(res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getQuestionSuccess (cb,data) {
    this.allquestions = data.getParentQuestions;
    for (let j = 0; j < this.allquestions; j++) {
      this.filteredQuizes[j].next(this.allquestions.slice());
    }
    cb(true);
  }

  changeValue(value) {
    this.checked = !value;
  }

  // ----------------------------

  // Get all Quiz Data
  // ----------------------------

  getallQuizData(ide) {
    this.contentService.getOneDataById(ide, "quiz")
      .subscribe((res: any) => this.ongetQuizSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetQuizSuccess(data) {
    this.quizdata = data;
    this.checked = this.quizdata.allow_feedback;
    this.totalretry(this.quizdata.total_retry);
    this.totalquestion(this.quizdata.total_question);
    for (let k = 0; k < this.quizdata.total_retry; k++) {
      for (let l = 0; l < this.quizdata.total_question; l++) {
        let retryformelement = (<FormArray>(this.dataForm.controls['question_mapping']));
        (<FormArray>retryformelement.controls[k]).controls['retryarray'].controls[l].controls['question'].setValue(this.quizdata.question_mapping[k][l]);
        this.selectedOptions(this.quizdata.question_mapping[k][l], l, k);
        this.disableOptions(this.quizdata.question_mapping[k][l], k);
      }
    }
    this.quizdata.feedback = this.quizdata.feedback == null || this.quizdata.feedback == '' ? '' : this.quizdata.feedback.toString();
    this.dataForm.patchValue(this.quizdata);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);

  }

  // ----------------------------

  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
    .getGraphQlData('{getParentTags(status:true){id,title}}', false)
    .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }
  
  // ----------------------------

  // Get all Feedback
  // ----------------------------

  getFeedback() {
    this.contentService.getGraphQlData('{getParentFeedbacks(status:true){id,title}}', false)
    .subscribe((res: any) => this.ongetFeedbackSuccess((res as any).data),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  ongetFeedbackSuccess(data) {
    this.allfeedbacks = data.getParentFeedbacks;
    this.filteredFeedback.next(this.allfeedbacks.slice());
  }

  // ----------------------------


  // Submitting Chapter Data
  // -----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    if (!this.checked) {
      postdata.feedback = '';
    }
    let detailed_text = this.commonService.isHTML( this.dataForm.value.detailed_text);
    let title = this.commonService.isHTML( this.dataForm.value.title);
    let question = this.commonService.isHTML( this.dataForm.value.question);
    let feedback = this.commonService.isHTML( this.dataForm.value.feedback);
    let retry_content = this.commonService.isHTML( this.dataForm.value.retry_content);
    let success_content = this.commonService.isHTML( this.dataForm.value.success_content);
   

    if(detailed_text  === true){
      this.dataForm.controls.detailed_text.patchValue('')
      return;
    }
    if(question  === true){
      this.dataForm.controls.question.patchValue('')
      return;
    }
    if(feedback  === true){
      this.dataForm.controls.feedback.patchValue('')
      return;
    }
    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    if(retry_content  === true){
      this.dataForm.controls.retry_content.patchValue('')
      return;
    }
    if(success_content  === true){
      this.dataForm.controls.success_content.patchValue('')
      return;
    }
    
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    postdata.language_data_fk = postdata.language_data_fk.toString();
    postdata.allow_feedback = this.checked.toString();
    postdata.total_question = postdata.total_question.toString();
    postdata.total_retry = postdata.total_retry.toString();
    postdata.pass_score = postdata.pass_score.toString();
    delete postdata.retryarray;
    let p = [];
    var k = 0;
    (postdata.question_mapping).forEach(r => {
      p[k] = [];
      r.retryarray.forEach(q => {
        p[k].push(q.question)
      });
      k++;
    });
    postdata.question_mapping = JSON.stringify(p);
    postdata.tags = JSON.stringify(postdata.tags);
    postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
    delete postdata.question;
    delete postdata.image_url;
    if (this.newitem == true) {
      this.contentService.postToBlade(postdata, 'quiz')
      .subscribe(
        (res: any) => this.onCreateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
    else {
      this.contentService.updateToBlade(this.childid, this.selfparentid, postdata, 'quiz')
      .subscribe(
        (res: any) => this.onUpdateSuccess(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Quiz has been added"), "OK");
    this.router.navigate(['content/quiz']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Quiz has been updated"), "OK");
    this.router.navigate(['content/quiz']);
  }
  ​ removeHTMLtags(value:string){
    return value.replace( /(<([^>]+)>)/ig, '');
  }

  // -----------------------------


}


