import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { local } from 'd3-selection';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';

@Component({
  selector: 'app-caregiverhistory',
  templateUrl: './caregiverhistory.component.html',
  styleUrls: ['./caregiverhistory.component.scss']
})
export class CaregiverhistoryComponent implements OnInit {
  
  public deviceData: any;
  caregiverDataVal: any;
  showcaregiverHidtory: boolean;
  caregiver: boolean;
  dataSource: MatTableDataSource<any>;
  userid: any;
  caregiverColumns= ['__old','__new','updated_by','caregiver_updated_date'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,public dialogRef: MatDialogRef<CaregiverhistoryComponent>,public commonService:CommonserviceService
    ,public systemService:SystemService,private userDetailService: UserdetailsService) { }
  
    ngOnInit() {
      this.userid = this.data.userid;
      this.careGiverHistory();
    }
  careGiverHistory() {
    this.userDetailService.getUserRemSettingDetails(this.userid)
    .subscribe(
      (res: any) => this.caregiverHistorySuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  selectedUserID(selectedUserID: any) {
    throw new Error('Method not implemented.');
  }
  caregiverHistorySuccess(res: any): void {
    let response = (res as any).data;
    this.caregiverDataVal = response.caregiver_data.caregiver_history;
    let dummyArr = [];
    let dummy1 = [];
    for(let i = 0 ; i < this.caregiverDataVal.length ; i++){
      if( this.caregiverDataVal[i].changes){
        this.caregiverDataVal[i].changes['created_at'] = this.caregiverDataVal[i].created_at;
        this.caregiverDataVal[i].changes['updated_by'] = this.caregiverDataVal[i].updated_by;
        dummyArr.push(this.caregiverDataVal[i].changes);
       
      }
    }
    this.showcaregiverHidtory=true;
    this.caregiver = true;
    dummyArr=dummyArr.sort((a, b) => a.created_at - b.created_at);
    dummyArr = dummyArr.reverse();
    this.dataSource = new MatTableDataSource(dummyArr);
  }
  }
  