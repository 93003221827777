import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Brodcastlistinterface } from '../../models/brodcastlistinterface';
import { SocketService } from '../../services/socket/socket.service';

@Component({
  selector: 'app-viewbroadcast',
  templateUrl: './viewbroadcast.component.html',
  styleUrls: ['./viewbroadcast.component.scss']
})

export class ViewbroadcastComponent implements OnInit {
  public broadcastData: Brodcastlistinterface;
  public broadcastResponseImageUrl: any;
  public broadcastImageUrl: string;
  private type: any;

  constructor(private browserTitle: Title, public dialogRef: MatDialogRef<ViewbroadcastComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private socketService: SocketService, public dialog: MatDialog) { }
  
  private setSEOTags(): void {
    this.browserTitle.setTitle('Health Coach | Broadcast');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.broadcastData = this.data;
    if (this.broadcastData.media_url) {
      this.socketService.getfileurl(this.broadcastData.media_url, this.broadcastData.mime_type, data => {
        this.broadcastImageUrl = data;
        this.broadcastData.mime_type = this.broadcastData.mime_type.split("/")[0];
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
