import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-deletemodal',
  templateUrl: './deletemodal.component.html',
  styleUrls: ['./deletemodal.component.scss']
})

export class DeletemodalComponent implements OnInit {

  message: string;
  confirmButtonText = "Confirm";
  cancelButtonText = "Cancel";

  ngOnInit() {
    if(localStorage.getItem('modal_header')!== null){
      this.message = localStorage.getItem('modal_header');
    }else{
      this.message = "Are you sure you want to delete?";
    }
    this.dialogRef.updateSize('386px', '150px');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any, 
    private dialogRef: MatDialogRef<DeletemodalComponent>
  ) { }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onCancelClick(): void {
    this.dialogRef.close(false);
  }
}
