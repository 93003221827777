import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { SHORT_MONTHS } from 'src/app/shared/data';
import { SystemService } from 'src/app/shared/services';
import { Userdetailsinterface } from '../../../models/userdetailsinterface';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { UserlistService } from '../../../services/userlist/userlist.service';
import { LogtagdialogComponent } from './logtagdialog/logtagdialog.component';
import { interval } from 'rxjs';
import { DeviceDialogComponent } from './olddevice/olddevice.component';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { UpdateMobileModalComponent } from './update-mobile-modal/update-mobile-modal.component';
import { ConfirmationModalComponent } from 'src/app/content/shared/confirmation-modal/confirmation-modal.component';
import { MobileNumberChangeAPIService } from 'src/app/shared/services/mobile-number-change-api.service';
import { PhoneNumberChangeLogComponent } from './phone-number-change-log/phone-number-change-log.component';
import { CreateDietPlanModalComponent } from './create-diet-plan-modal/create-diet-plan-modal.component';
import { formatDate } from '@angular/common';
import { DietPlanAPIService } from 'src/app/shared/services/diet-plan-api.service';

@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.scss']
})

export class UserdetailComponent implements OnInit {
  public userDetailsSubject$: BehaviorSubject<Userdetailsinterface[]> = new BehaviorSubject<Userdetailsinterface[]>(null);
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public tabID: number = 2;
  public subscribeObj: any;
  public showcaregiverform: any;
  public tagData: any = {};
  public userTags: any;
  public coWINData: any;
  public getSlots: any;
  private alltagval: any;
  private tagdialogopen: any;
  public isOPTOUT: boolean;
  public isRating: boolean = false;
  public displayedColumns = ['min_age_limit', 'available_capacity_dose1', 'available_capacity_dose2', 'date'];
  public dataSource: any;
  public devicedataSource: any;
  whatsapp: boolean = false;
  Resync_GoogleFit: boolean = false;
  resync_google_health_ask_at:any;
  Resync_Healthkit: boolean = false;
  googlefit: boolean = false;
  locationAccess: boolean = false;
  camera: boolean = false;
  gallery: boolean = false;
  call: boolean = false;
  applefit: boolean = false;
  ratingValue: any;
  rating_given_at: any;
  appMasterData: any;
  getLatestDevice: any;
  latestDeviceId: any;
  showRating: boolean = false;
  permissions: any
  showPermission: boolean = false;
  device_type: any;
  app_version: any;
  device_version: any;
  showgooglefit: boolean = false;
  showapplefit: boolean = false;
  showlocationAccess: boolean = false;
  showCamera: boolean = false;
  showgallery: boolean = false;
  showSurvey: boolean = false;
  // showResync_GoogleFit:boolean = false;
  showCoachMark: boolean = false;
  showWhatsapp: boolean = false;
  deviceData: any;
  whatsAppNo: any;
  isWhatsAppno: boolean = false;
  year: any;
  month: any;
  monthFinal: any;
  enableCoachMark: any;
  screen: any = [];
  bmiValue: any;
  targetPatientData: any = [];
  alllanguages: any = []
  result: any;
  bmiData: any;
  public coachmarkPermission: boolean;
  public caregiverForm = this.fb.group({
    name: ["", Validators.required],
    code: ["", Validators.required],
    phone: ["", Validators.required],
    relationship: ["", Validators.required]
  });
  PreferedLanguage: any;
  androidcurrentapp: any;
  ioscurrentapp: any;
  isUpdatedVersion: boolean = false;
  coachmarkProgress: boolean = false;
  is_device_rooted: any;
  is_debug_app: any;
  public userClientDetails: any;

  idealWeightRange = {
    kg: {
      start: 0,
      end: 0
    },
    lb: {
      start: 0,
      end: 0
    }
  }

  public userMobile = {
    number:"",
    code: ""
  }
  public userDietPlanInput = {
    dob:"",
    height:"",
    height_unit:"",
    weight:"",
    weight_unit:"",
    gender:"",
    name:"",
    therapy_condition_data: [""]
  }
  @ViewChild('fit_kit_data_asked') fit_kit_data_asked: TemplateRef<MatDialog>;
  @ViewChild('confirm_fit_kit_data_asked') confirm_fit_kit_data_asked: TemplateRef<MatDialog>;
  public morrow: any = new Date();

  
  public dataForm = this.fb.group({
    startdate: [''],
    enddate: [''],
  });
  enableReyncCalender:boolean= false;
  resync_type: string;
  ask_permission_event: any;
  ask_permission: any;
  resync_startDate: any;
  resync_endDate: any;

  hasPhoneNumberChangeLogs: boolean = false;
  isPhoneNumberChangeStatus: boolean = false;

  hideDietPlanBuilder = false;
  hideDietPlanHistory = true;
  public appDebug: boolean = false;
  public DebugAppPermission: boolean;
    debug_mode_activated_at: any;
  mediaUploadSize: any;

  constructor(
  private contentService: ContentserviceService, private commonService: CommonserviceService, private dialog: MatDialog, private appContext: ContextService, private userDetailService: UserdetailsService, public fb: FormBuilder, private UserlistService: UserlistService, private systemService: SystemService, private mobileChangeApiService: MobileNumberChangeAPIService, private dietPlanAPIService: DietPlanAPIService) { }

  ngOnInit() {
    this.getLanguages()
    this.whatsAppNo = JSON.parse(localStorage.getItem("scarletuserdata")).whatsapp_number
    this.subscribeObj = this.appContext.changeWatcher.subscribe(data => {
      if (this.appContext.selectedChatUser.getValue() != null && (this.appContext.selectedTab.getValue() == this.tabID) && !this.appContext.isSelectedChatUserIsBot.getValue()) {
        this.initComponentDraw(this.appContext.selectedChatUser.getValue());
        this.getpermissionData();

        this.search();
      }
    });
    if (this.whatsAppNo) this.isWhatsAppno = true; else this.isWhatsAppno = false;
  
  }

  getDietPlans() {
    this.dietPlanAPIService.getAllDietPlan({ patient_data_fk: this.appContext.selectedChatUser.getValue().toString() }).subscribe((data) => {
      if (data.status === 200){
        if(data.data.length > 0) {
          this.hideDietPlanHistory = false;
        } else {
          this.hideDietPlanHistory = true;
        }
      }
    },
    (error) => {
      this.systemService.handleErrorResponse(error);
    });
  }


  getLanguages() {
    this.contentService
      .getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name,short_code}}')
      .subscribe(
        (res: any) => this.getAllLanguageSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLanguageSuccess(data: { getAllLanguages: any; }) {
    this.alllanguages = data.getAllLanguages;
  }

  search() {
    this.commonService.getAppMasterData()
      .subscribe(
        (res: any) => this.getAppMasterSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAppMasterSuccess(data: any) {
    this.appMasterData = data;

    for (var i = 0; i <= this.appMasterData.length; i++) {

      if (this.appMasterData[i] && this.appMasterData[i].key == 'ANDROID_CURRENT_APP_VERSION') {
        this.androidcurrentapp = this.appMasterData[i].value;
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'IOS_CURRENT_APP_VERSION') {
        this.ioscurrentapp = this.appMasterData[i].value;
      }
      if (this.appMasterData[i] && this.appMasterData[i].key == 'ALLOWED_ATTACHMENT_MAXIMUM_SIZE') {
        this.mediaUploadSize = JSON.stringify(this.appMasterData[i].value);
        localStorage.setItem('mediaUploadSize',this.mediaUploadSize);
    }
    }
  }
  ngAfterViewInit() { }

  getallTags() {
    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    /**Call API */
    this.UserlistService.getalltagsForHc().pipe(
      delay(100),
      catchError((error: any) => {
        if (error.error.message.includes("204")) {
          this.loadingError$.next(false);
          this.tagData = {};
        } else {
          this.loadingError$.next(true);
          return throwError(error);
        }
      }),
      finalize(() => { this.loadingSubject$.next(false) })
    ).subscribe(data => {
      data = (data as any).data;
      this.alltagval = data;
      data.forEach((d: { id: string | number; title: any; }) => {
        this.tagData[d.id] = d.title;
      });
      this.getuserTags();
    });
  }

  getuserTags() {
    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    /**Call API */
    this.UserlistService.getusertags(this.appContext.selectedChatUser.getValue()).pipe(
      delay(100),
      catchError((error: any) => {

        if (error.error.message.includes("204")) {
          this.loadingError$.next(false);
          this.userTags = [];
        } else {
          this.loadingError$.next(true);
          return throwError(error);
        }
      }),
      finalize(() => { this.loadingSubject$.next(false) })
    ).subscribe(data => {
      data = (data as any).data;
      if (data) {
        this.userTags = data;
      }
    });
  }

  openTagDialog() {
    var scope = this;
    this.tagdialogopen = this.dialog.open(LogtagdialogComponent, {
      width: '350px',
      height: 'auto',
      data: {
        alltags: scope.alltagval,
        userID: this.appContext.selectedChatUser.getValue(),
        userTags: this.userTags
      }
    });
    this.tagdialogopen.afterClosed().subscribe(() => {
      scope.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  deletetag(id: any) {
    this.UserlistService.deleteUserTag(this.appContext.selectedChatUser.getValue(), id).pipe(
      delay(50),
      catchError((error: any) => {
        return throwError(error);
      }),
      finalize(() => { })
    ).subscribe(data => {
      /**Hide loader */
      this.systemService.showSnackBar("Tag deleted", "OK");
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  initComponentDraw(selectedUserID: string) {
    this.tagData = {};
    this.userTags = [];
    this.loadingSubject$.next(true);
    /**Reinitilise user detail component */
    this.userDetailsSubject$ = new BehaviorSubject<Userdetailsinterface[]>(null);
    /**Call API */

    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      if (data.patient_client_data != null) {
        this.userClientDetails = data.patient_client_data.title;
      }

      if (data.patient_client_data != null) {
        this.userClientDetails = data.patient_client_data.title;
      }

      if (this.device_type == "Android") {
        if (this.app_version && this.androidcurrentapp) {
          this.result = this.app_version.localeCompare(this.androidcurrentapp, undefined, { numeric: true, sensitivity: 'base' })
        }
      }
      else if (this.device_type == "iOS") {


        if (this.app_version && this.ioscurrentapp) {
          this.result = this.app_version.localeCompare(this.ioscurrentapp, undefined, { numeric: true, sensitivity: 'base' })
        }
      }

      this.bmiValue = (data.weight / Math.pow((data.height / 100), 2)).toFixed(1);

      if (data.show_bmi == true && this.bmiValue < 18.5) {
        this.bmiData = "Underweight";
      }
      else if (data.show_bmi == true && this.bmiValue > 18.5 && this.bmiValue < 24.9) {
        this.bmiData = "Healthy";
      }
      else if (data.show_bmi == true && this.bmiValue > 25.0 && this.bmiValue < 29.9) {
        this.bmiData = "Overweight";
      }
      else if (data.show_bmi == true && this.bmiValue > 30.0) {
        this.bmiData = "Obese";
      }

      if (data.weight_unit === 'kg') {

        // Multiply height by 0.01 to convert cm into m
        if (data.height_unit === 'cm') {
          this.idealWeightRange.kg.start = Math.round(this.getIdealWeight(18, data.height * 0.01));
          this.idealWeightRange.kg.end = Math.round(this.getIdealWeight(22.9, data.height * 0.01));
        }

        // Multiply height by 0.3048 to convert feet into m
        if (data.height_unit === 'feet') {
          this.idealWeightRange.kg.start = Math.round(this.getIdealWeight(18, data.height * 0.3048));
          this.idealWeightRange.kg.end = Math.round(this.getIdealWeight(22.9, data.height * 0.3048));
        }

      }

      if (data.weight_unit === 'lbs') {

        // Multiplying weight by 2.20 converts kgs to lbs
        if (data.height_unit === 'cm') {
          this.idealWeightRange.lb.start = Math.round((this.getIdealWeight(18, data.height * 0.01) * 2.20));
          this.idealWeightRange.lb.end = Math.round((this.getIdealWeight(22.9, data.height * 0.01) * 2.20));
        }

        // Multiply height by 0.3048 to convert feet into m
        if (data.height_unit === 'feet') {
          this.idealWeightRange.lb.start = Math.round((this.getIdealWeight(18, data.height * 0.3048) * 2.20));
          this.idealWeightRange.lb.end = Math.round((this.getIdealWeight(22.9, data.height * 0.3048) * 2.20));
        }

      }

      this.enableCoachMark = data.profile_flags.includes("ECM")
      if (this.enableCoachMark == true) {
        this.getCoachmarkProgressData();
      } else if (this.enableCoachMark == false) {
        this.coachmarkProgress = false;
        this.coachmarkPermission = false;
      }
      let agedata = data.dob;
      this.getAge(agedata)
      this.getPatientTargets(data.id);
      data = (data == null) ? [] : data;
      this.userDetailsSubject$.next(data);
      let lang = data.language_data_fk.toString();
      const object = this.alllanguages.find((obj: { id: any; }) => obj.id === lang);
      this.PreferedLanguage = object.name;
      this.getallTags();
      this.getpermissionData();
      this.getCowinData();
      // this.getpermissionData();
      this.dataSource = false;

      this.userMobile.number = data.phone;
      this.userMobile.code = data.country_code;

      this.userDietPlanInput = {
        dob : data.dob,
        height: data.height,
        height_unit: data.height_unit,
        weight: data.weight,
        weight_unit: data.weight_unit,
        gender: data.gender,
        name: data.name,
        therapy_condition_data: data.therapy_condition_data.map((tcd)=> tcd.title)
      }
      this.loadingSubject$.next(false);
      this.loadingError$.next(false);
    });

    this.mobileChangeApiService.getPhoneChangeLogs(selectedUserID)
      .subscribe((data) => {
        if (data.data.length > 0){
          this.hasPhoneNumberChangeLogs = true;
        }
      }, (error) => {
        this.systemService.handleErrorResponse(error);
      });
    this.getDietPlans();
    this.isRequestInProgress();
  }

  isRequestInProgress(){
    this.mobileChangeApiService.getCurrentUserChangeLog(this.appContext.selectedChatUser.getValue())
    .subscribe((data) => {
      if (data.data.length > 0){
        this.isPhoneNumberChangeStatus = true;
      }
    }, (error) => {
      this.systemService.handleErrorResponse(error);
    })
  }

  // Gets height in meters
  getIdealWeight(bmi: number, height: number) {
    return bmi * Math.pow(height, 2);
  }

  getPatientTargets(patientID: any) {

    this.userDetailService.getPatientTargetData(patientID)
      .subscribe((res: any) => {

        if (res.data[0].type == 'meal') {
          this.targetPatientData = res.data[0];
        } else if (res.data[1].type == 'meal') {
          this.targetPatientData = res.data[1];
        }

      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  convertDecimal(num: number) {
    return Math.floor(num).toFixed(2);
  }


  checkMonth(month: string | number) {
    if (month == 'N' || month == 0) {
      return "";
    }
    else {
      return month + " Month";
    }
  }
  careObject() {
    let caregiverval = {
      "name": this.caregiverForm.value.name,
      "phone": "+" + this.caregiverForm.value.code + " " + this.caregiverForm.value.phone,
      "relationship": this.caregiverForm.value.relationship
    }
    //caregiverval.phone = this.caregiverForm.value.phone;
    this.userDetailService.postCareGiver(this.appContext.selectedChatUser.getValue(), caregiverval).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(data => {
      this.systemService.showSnackBar("Caregiver details has been updated", "OK");
      this.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  //To get last opt-out date
  getCowinData() {
    let postdata = {
      "patient_data_fk": this.appContext.selectedChatUser.getValue().toString(),
    }
    this.userDetailService.cowinPatientData(postdata)
      .subscribe(
        (res: any) => this.successcoWINdata(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private successcoWINdata(data: { opt_out: boolean; }) {
    data = (data as any).data;
    if (data) {
      if (data.opt_out == false) {
        this.isOPTOUT = true;
      } else {
        this.isOPTOUT = false;
      }
      this.coWINData = data;
      this.dataSource = false;
    }
    else {
      this.coWINData = [];
    }
  }

  //To opt-out notifications from HC
  patientOPTout(e: boolean) {
    if (e == false) {
      var optOut = true;
    } else {
      var optOut = false;
    }
    let postdata = {
      "patient_data_fk": this.appContext.selectedChatUser.getValue().toString(),
      "opt_out": optOut.toString(),
    }
    this.userDetailService.cowinPreference(postdata)
      .subscribe(
        (res: any) => this.successOptOut(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private successOptOut() {
    this.getCowinData()
  }

  // To get cowin slots
  getCowinSlots() {
    let postdata = {
      "patient_data_fk": this.appContext.selectedChatUser.getValue().toString(),
    }
    this.userDetailService.cowinSlotData(postdata)
      .subscribe(
        (res: any) => this.successSlots(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private successSlots(data: string | any[]) {
    data = (data as any).data;
    var currentObjState = []
    for (var i = 0; i < data.length; i++) {
      currentObjState.push(data[i].sessions);
    }
    this.dataSource = [].concat(...currentObjState);
  }

  // To get cowin slots
  getRatingHistory() {


    //   let postdata = {
    //     "patient_data_fk": this.appContext.selectedChatUser.getValue().toString(),
    //   }
    //   this.userDetailService.cowinSlotData(postdata)
    //   .subscribe(
    //     (res: any) => this.successRating(res),
    //     (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    //   );
    // }
    // private successRating(data) {
    //   data = (data as any).data;
    //  var currentObjState = []
    //   for(var i = 0; i < data.length; i++){
    //     currentObjState.push(data[i].sessions);
    //   }
    //   this.dataSource = [].concat(...currentObjState);
  }

  // To save permissions for patient
  askPermission(permission:any, event:any) {
    let epochValue:any;
    let postdata: { patient_id_fk: string; gallery_access: string; google_fit_access: string; apple_fit_access: string; location_access: string; camera_access: string; whatsapp_opt_in: string; call_opt_in: string; ask_for_rating: string; device_id: string; debug_mode_activated_at: string;}
    this.ask_permission = permission;
    this.ask_permission_event = permission;
    this.permissions = JSON.parse(localStorage.getItem('allpermission'));
    if (permission == "Resync_GoogleFit") {
      this.resync_type = 'Resync_GoogleFit';
      this.enableReyncCalender = false;
      this.dataForm.reset();
      this.dialog.open(this.fit_kit_data_asked);
    } else if (permission == "Resync_HealthKit") {
      this.resync_type = 'Resync_HealthKit';
      this.enableReyncCalender = false;
      this.dataForm.reset();
      this.dialog.open(this.fit_kit_data_asked);
    } else {
      if (permission === 'debug_mode_activated_at') {
        let isAppDebugChecked = event.target.checked;
        if (isAppDebugChecked === true) {
          this.appDebug = true;
          this.DebugAppPermission = true;
          epochValue = Date.now().toString()
          const date1 = new Date();
          localStorage.setItem("app-debug-time", JSON.stringify(date1));
        } else {
          return;
        }
        this.getpermissionRequest(permission, event, '', isAppDebugChecked)
      }
      postdata = {
        "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
        "gallery_access": this.gallery.toString(),
        "google_fit_access": this.googlefit.toString(),
        "apple_fit_access": this.applefit.toString(),
        "location_access": this.locationAccess.toString(),
        "camera_access": this.camera.toString(),
        "whatsapp_opt_in": this.whatsapp.toString(),
        "call_opt_in": this.call.toString(),
        "ask_for_rating": this.isRating.toString(),
        "debug_mode_activated_at":epochValue,
        "device_id": localStorage.getItem('latestdeviceid').toString(),
      }
      this.userDetailService.postPermissionData(postdata)
        .subscribe(
          (res: any) => this.successpermissions(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }
  private successpermissions(data: any) {
    data = (data as any).data;
    this.systemService.showSnackBar("Request sent", "OK");
    this.getpermissionData();
  }

  getCoachmarkProgressData() {
    this.userDetailService.getCoachmarkProgressdata(this.appContext.selectedChatUser.getValue().toString())
      .subscribe((res: any) => {
        this.successCoachmarkData(res.data)
      },
        ((err: HttpErrorResponse) => {
          if (err.status == 404) {
            let data: any = { screen: [] }
            this.successCoachmarkData(data)
          }
          else if (err.status == 500 || err.status == 400 || err.status == 405 || err.status == 401 || err.status == 422) {
            this.systemService.handleErrorResponse(err)
          }
        })
      );
  }

  successCoachmarkData(data: { screen: any; }) {
    let AllCoachMarkList: any[];
    if (this.userClientDetails == 'Ferrer') {
      AllCoachMarkList = ['home', 'lesson_feedback', 'magazine', 'coach', 'diary', 'profile', 'medication_toggle']
    } else {
      AllCoachMarkList = ['home', 'lesson_feedback', 'magazine', 'coach', 'diary', 'profile']
    }
    let currentArray = data.screen;
    if (currentArray.length == 0) {
      this.coachmarkProgress = true;
      this.coachmarkPermission = true;
    } else if (currentArray.length != 0) {
      if (AllCoachMarkList.every((r: any) => currentArray.includes(r))) {
        this.coachmarkProgress = false;
        this.coachmarkPermission = false;
      } else {
        this.coachmarkProgress = true;
        this.coachmarkPermission = true;
      }
    }
  }

  getpermissionData() {

    this.userDetailService.getpatientPermissiondata(this.appContext.selectedChatUser.getValue().toString())
      .subscribe(
        (res: any) => this.successpermisonData(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }
  private successpermisonData(data: any[]) {
    data = (data as any).data;
    this.deviceData = data;
    // this.whatsapp = true
    // if(data.whatsapp_opt_in){
    //   this.whatsapp = true
    // }else if(data.location_acess){
    //   this.locationAccess = true
    // }else if(data.camera_access){
    //   this.camera = true
    // }else if(data.google_fit_access){
    //   this.googlefit = true
    // }else if(data.gallery_access){
    //   this.gallery = true
    // }
    if (data.length) {
      this.showPermission = true;

      if (data.length && data[0].rating_number != 0) {
        this.ratingValue = data[0].rating_number.toString();
        this.isRating = data[0].ask_for_rating;
        this.rating_given_at = data[0].rating_given_at;
        this.showRating = true;
      } else {
        this.showRating = false;
        this.latestDeviceId = ""
        localStorage.setItem('latestdeviceid', this.latestDeviceId)
      }
      if(data[0].debug_mode_activated_at !== null && data[0].debug_mode_activated_at !== "0"){
        this.appDebug = true;
        this.DebugAppPermission = true;
        this.debug_mode_activated_at = data[0].debug_mode_activated_at;
        let date = new Date(JSON.parse(this.debug_mode_activated_at))
        this.defuseAppDebug(date);
      } else {
        this.appDebug = false;
        this.DebugAppPermission = false;
      }


      if(data[0].debug_mode_activated_at !== null && data[0].debug_mode_activated_at !== "0"){
        this.appDebug = true;
        this.DebugAppPermission = true;
      }else{
        this.appDebug = false;
        this.DebugAppPermission = false;
      }

      this.getLatestDevice = data.filter((a: { is_active: boolean; }) => a.is_active == true);

      if (this.getLatestDevice.length) {
        if (this.getLatestDevice[0].device_id != "false") {
          this.latestDeviceId = this.getLatestDevice[0].device_id;
          this.device_type = this.getLatestDevice[0].device_type;
          this.app_version = this.getLatestDevice[0].app_version;
          this.device_version = this.getLatestDevice[0].device_version;
          this.whatsapp = this.getLatestDevice[0].whatsapp_opt_in
          this.locationAccess = this.getLatestDevice[0].location_access
          this.camera = this.getLatestDevice[0].camera_access
          this.googlefit = this.getLatestDevice[0].google_fit_access
          this.applefit = this.getLatestDevice[0].apple_fit_access
          this.gallery = this.getLatestDevice[0].gallery_access
          this.call = this.getLatestDevice[0].call_opt_in
          this.is_device_rooted = this.getLatestDevice[0].is_device_rooted
          this.is_debug_app = this.getLatestDevice[0].is_debug_app
          this.resync_google_health_ask_at = this.getLatestDevice[0].resync_google_health_ask_at;
          if (this.device_type === 'iOS') {
            this.Resync_Healthkit = this.getLatestDevice[0].ask_for_resync_google_health;
          } else if (this.device_type = 'Android') {
            this.Resync_GoogleFit = this.getLatestDevice[0].ask_for_resync_google_health;
          }
          localStorage.setItem('allpermission', JSON.stringify(this.getLatestDevice[0]))
        } else {
          this.latestDeviceId = ""
        }
      } else {
        this.latestDeviceId = ""
      }
      localStorage.setItem('latestdeviceid', this.latestDeviceId)

    } else {
      this.showPermission = false;
      this.latestDeviceId = ""
      localStorage.setItem('latestdeviceid', this.latestDeviceId)
    }

  }

  getpermissionRequest(permissions: any, event: any,resync?: string,appDebug?:any) {
    switch (permissions) {
      case "Whatsapp":
        this.whatsapp = true;
        this.sendWhatsappLink();
        break;
      case "googlefit":
        this.showgooglefit = true;
        this.AskApp('Google Fit');
        break;
      case "applefit":
        this.showapplefit = true;
        this.AskApp('Apple Health');
        break;
      case "locationAccess":
        this.showlocationAccess = true;
        this.AskApp('Location Access');
        break;
      case "camera":
        this.showCamera = true;
        this.AskApp('Camera');
        break;
      case "gallery":
        this.showgallery = true;
        this.AskApp('Gallery');
        break;
      case "ask_for_rating":
        this.isRating = true;
        this.AskApp('App Rating');
        break;
      case "coachMark":
        this.showCoachMark = true;
        this.checkCoachMarkProgress(event);
        this.AskApp('Coach Mark');
        break;
      case "Resync_GoogleFit":
        this.Resync_GoogleFit = true;
        this.AskApp('Resync Google Fit',resync);
        break;
      case "Resync_HealthKit":
        this.Resync_Healthkit = true;
        this.AskApp('Resync Health Kit',resync);
        break;
      case "medSurvey":
        this.showSurvey = true;
        this.AskApp('Medication Survey')
        break;
        case "debug_mode_activated_at":
            if (appDebug) {
                this.appDebug = true;
                this.AskApp('AppDebug');
                break;
              }
    }
  }



  checkCoachMarkProgress(event: boolean) {
    let patient_id_fk = this.appContext.selectedChatUser.getValue().toString();
    if (event == true) {
      let screenData: any = "[]";
      this.userDetailService.getCoachMarkProgress(patient_id_fk, screenData).subscribe(
        (res: any) => this.CoachmarkSuccess(res),
        (err: HttpErrorResponse) => {
          this.systemService.handleErrorResponse(err)
          //this.enableCoachMark = err.status;
        }
      );
    }
    else if (event == false) {
      let screenData: any;;
      if (this.userClientDetails == 'Ferrer') {
        screenData = '["home", "lesson_feedback", "magazine","coach", "diary", "profile", "medication_toggle"]';
      } else {
        screenData = '["home", "lesson_feedback", "magazine", "coach", "diary", "profile"]';
      }
      this.userDetailService.getCoachMarkProgress(patient_id_fk, screenData).subscribe(
        (res: any) => this.CoachmarkSuccess(res),
        (err: HttpErrorResponse) => {
          this.systemService.handleErrorResponse(err)
          //this.enableCoachMark = err.status;
        }
      );
    }
  }


  private CoachmarkSuccess(data: any) {
    data = (data as any).data;
    let coachmarkData: any = data;

  }

  AskApp(type: any,resync?: any) {
    var obj
    if(resync === "true"){
      obj = {
        link: "",
        type: type,
        hk_start_date:this.resync_startDate.toISOString().toString(),
        hk_end_date:this.resync_endDate.toISOString().toString()

      }
      this.appContext.changeMessage(obj);
      this.resetAsk();
    }else{
        obj = {
        link: "",
        type: type,
      }
      this.appContext.changeMessage(obj);
      this.resetAsk();
      //in 60 seconds do something
    }
  }

  resetAsk() {
    interval(60000).subscribe(x => {
      this.getpermissionData();
      this.showgooglefit = false;
      this.showapplefit = false;
      this.showlocationAccess = false;
      this.showCamera = false;
      this.showgallery = false;
      this.showSurvey = false;
      this.showCoachMark = false;
      this.isWhatsAppno = false;
    });
  }

  convertDate(date: string | number | Date) {
    var d = new Date(date);
    var month = SHORT_MONTHS[d.getMonth()];
    var currentdate = d.getDate()
    var year = d.getFullYear()
    // 
    return currentdate + ' ' + month + ' ' + year + ',' + d.toLocaleTimeString();;

  }
  sendWhatsappLink() {
    var obj = {
      link: 'https://api.whatsapp.com/send?phone=' + this.whatsAppNo + '&text=Hi%F0%9F%98%80',
      type: "whatsapp"
    }
    this.appContext.changeMessage(obj);
    this.resetAsk();

    // localStorage.setItem('whatsapplink','https://api.whatsapp.com/send?phone=919810383938&text=Hi%F0%9F%98%80')
  }

  openDeviceModel() {
    var scope = this;
    let patient_id_fk = this.appContext.selectedChatUser.getValue().toString();
    this.tagdialogopen = this.dialog.open(DeviceDialogComponent, {
      width: '500px',
      height: '500px',
      data: {
        devicedataSource: patient_id_fk,
      }
    });

    this.tagdialogopen.afterClosed().subscribe(() => {
      scope.initComponentDraw(this.appContext.selectedChatUser.getValue());
    });
  }

  openUpdateNumberModal() {


    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      data: {
        question: `Updating the phone number will transfer all the data to the new number, Do you wish to continue ?`
      }
    });

    confirmationDialog.afterClosed().subscribe((data) => {

      let hcData = JSON.parse(localStorage.getItem("scarletuserdata"));

      if (data === 'confirm') {
        const updateMobileDialog = this.dialog.open(UpdateMobileModalComponent, {
          width: '750px',
          height: '430px',
          data: {
            mobileNumber: this.userMobile.number,
            countryCode: this.userMobile.code,
            patientId: this.appContext.selectedChatUser.getValue(),
            hcId: hcData.id,
            hcName: hcData.name
          }
        });

        updateMobileDialog.afterClosed().subscribe((data) => {
          if (data){
            this.isRequestInProgress();
          }
        })
      }
    });
  }

  openCreateDietPlanModal(history) {
    const dietPlanDialog = this.dialog.open(CreateDietPlanModalComponent, {
      width: '70vw',
      height: '80%',
      data: {
        history,
        userDietPlanInput: this.userDietPlanInput,
        patientId: this.appContext.selectedChatUser.getValue()
      }
    });

    dietPlanDialog.afterClosed().subscribe(() => { 
       this.getDietPlans();
    });
 }

  resyncData(type:any){
    let postdata: { patient_id_fk: string; gallery_access: string; google_fit_access: string; apple_fit_access: string; location_access: string; camera_access: string; whatsapp_opt_in: string; call_opt_in: string; ask_for_rating: string; device_id: string; ask_for_resync_google_health: string; };
    if(type === 'full_data'){
      if(this.resync_type == "Resync_GoogleFit"){
        this.Resync_GoogleFit = true;
         postdata = {
          "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
          "gallery_access": this.gallery.toString(),
          "google_fit_access": this.googlefit.toString(),
          "apple_fit_access": this.applefit.toString(),
          "location_access": this.locationAccess.toString(),
          "camera_access": this.camera.toString(),
          "whatsapp_opt_in": this.whatsapp.toString(),
          "call_opt_in": this.call.toString(),
          "ask_for_rating": this.isRating.toString(),
          "device_id": localStorage.getItem('latestdeviceid').toString(),
          'ask_for_resync_google_health': this.Resync_GoogleFit.toString(),
          
        }
      }else if(this.resync_type == "Resync_HealthKit"){
        this.Resync_Healthkit = true;
          postdata = {
            "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
            "gallery_access": this.gallery.toString(),
            "google_fit_access": this.googlefit.toString(),
            "apple_fit_access": this.applefit.toString(),
            "location_access": this.locationAccess.toString(),
            "camera_access": this.camera.toString(),
            "whatsapp_opt_in": this.whatsapp.toString(),
            "call_opt_in": this.call.toString(),
            "ask_for_rating": this.isRating.toString(),
            "device_id": localStorage.getItem('latestdeviceid').toString(),
            'ask_for_resync_google_health': this.Resync_Healthkit.toString()
          }
        }
        this.userDetailService.postPermissionData(postdata)
        .subscribe(
          (res: any) => {
            this.getpermissionRequest(this.ask_permission, this.ask_permission_event,'false')
            this.successpermissions(res)}
            ,
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
        this.dialog.closeAll();
      }
      else if (type === 'particular_data'){
      this.enableReyncCalender = true;
     
    }
  }
  customResync(){
   
    if(this.dataForm.value.startdate && this.dataForm.value.enddate){
      this.dialog.open(this.confirm_fit_kit_data_asked);
    }else{
      this.systemService.showSnackBar('Kindly Select Start Date / End Date','OK')
    }
  }
  applyCustomResync(){
    let postdata: { patient_id_fk: string; gallery_access: string; google_fit_access: string; apple_fit_access: string; location_access: string; camera_access: string; whatsapp_opt_in: string; call_opt_in: string; ask_for_rating: string; device_id: string; ask_for_resync_google_health: string; hk_start_date: any; hk_end_date: any;debug_mode_activated_at:any };
    this.resync_startDate = new Date(this.dataForm.value.startdate);
    this.resync_endDate = new Date(this.dataForm.value.enddate);
    if(this.resync_type == "Resync_GoogleFit"){
       this.Resync_GoogleFit = true;
      postdata = {
       "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
       "gallery_access": this.gallery.toString(),
       "google_fit_access": this.googlefit.toString(),
       "apple_fit_access": this.applefit.toString(),
       "location_access": this.locationAccess.toString(),
       "camera_access": this.camera.toString(),
       "whatsapp_opt_in": this.whatsapp.toString(),
       "call_opt_in": this.call.toString(),
       "ask_for_rating": this.isRating.toString(),
       "device_id": localStorage.getItem('latestdeviceid').toString(),
       'ask_for_resync_google_health': this.Resync_GoogleFit.toString(),
       'hk_start_date':this.resync_startDate.toISOString().toString(),
       'hk_end_date':this.resync_endDate.toISOString().toString(),
       'debug_mode_activated_at': this.appDebug.toString(),
     }
   }else if(this.resync_type == "Resync_HealthKit"){
    this.Resync_Healthkit = true;
       postdata = {
         "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
         "gallery_access": this.gallery.toString(),
         "google_fit_access": this.googlefit.toString(),
         "apple_fit_access": this.applefit.toString(),
         "location_access": this.locationAccess.toString(),
         "camera_access": this.camera.toString(),
         "whatsapp_opt_in": this.whatsapp.toString(),
         "call_opt_in": this.call.toString(),
         "ask_for_rating": this.isRating.toString(),
         "device_id": localStorage.getItem('latestdeviceid').toString(),
         'ask_for_resync_google_health': this.Resync_Healthkit.toString(),
         'hk_start_date':this.resync_startDate.toISOString().toString(),
         'hk_end_date':this.resync_startDate.toISOString().toString(),
         'debug_mode_activated_at': this.appDebug.toString(),
     }
     this.userDetailService.postPermissionData(postdata)
     .subscribe(
       (res: any) => {
         this.getpermissionRequest(this.ask_permission, this.ask_permission_event,'true')
         this.successpermissions(res)
       },
       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
     );
     this.dialog.closeAll();
  }
}

  ngOnDestroy() {
    this.subscribeObj.unsubscribe();
    this.userDetailsSubject$.unsubscribe();
    // this.loadingError$.unsubscribe();
    // this.loadingSubject$.unsubscribe();
  }

  openPhoneNumberChangeLog(){
    this.dialog.open(PhoneNumberChangeLogComponent, {
      data: this.appContext.selectedChatUser.getValue(),
      width: "1024px",
    });
  }
  getAge(dateString) {
    let fulldate = dateString
    dateString = formatDate(dateString, 'dd/MM/yyyy', 'en-US');
    let  now = new Date();
    let  yearNow = now.getFullYear();
    let  monthNow = now.getMonth() + 1;
    let  dateNow = now.getDate();
    let  dob = new Date(fulldate);
  
    let  yearDob = dob.getFullYear();
    let  monthDob = dob.getMonth()+ 1 ;
    let  dateDob = dob.getDate();
    let  age;
    let  ageString = "";
    let  yearString = "";
    let  monthString = "";
    let  dayString = "";
    let monthAge ;
    let yearAge = yearNow - yearDob;
  
    if (monthNow >= monthDob)
    monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }
  
    if (dateNow >= dateDob)
      var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;
  
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
  
     age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
        };
  
    if ( age.years > 1 ) yearString = " years";
    else yearString = " year";
    if ( age.months> 1 ) monthString = "m";
    else monthString = "m";
    if ( age.days > 1 ) dayString = " d";
    else dayString = " d";
  
  
    if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
      this.year = age.years + yearString +" "+ age.months + monthString;
    else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
    this.year  = "Only " + age.days + dayString;
    else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
    this.year  = age.years + yearString;
    else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
    this.year  = age.years + yearString +" "+ age.months + monthString ;
    else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
    this.year  = age.months + monthString + age.days + dayString;
    else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
    this.year  = age.years + yearString;
    else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
      ageString = age.months + monthString;
    else this.year  = "";
  
    return this.year ;
  }
  defuseAppDebug(debugStart) {
    const currentTime = new Date();
    var difference = currentTime.getTime() - debugStart.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    if (resultInMinutes > 14) {
      this.DebugAppPermission = false
      this.appDebug = false;
      let epochValue = "0"
      let postdata = {
        "patient_id_fk": this.appContext.selectedChatUser.getValue().toString(),
        "gallery_access": this.gallery.toString(),
        "google_fit_access": this.googlefit.toString(),
        "apple_fit_access": this.applefit.toString(),
        "location_access": this.locationAccess.toString(),
        "camera_access": this.camera.toString(),
        "whatsapp_opt_in": this.whatsapp.toString(),
        "call_opt_in": this.call.toString(),
        "ask_for_rating": this.isRating.toString(),
        "debug_mode_activated_at": epochValue,
        "device_id": localStorage.getItem('latestdeviceid').toString(),
      }
      this.userDetailService.postPermissionData(postdata)
        .subscribe(
          (res: any) => this.getpermissionData(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        );
    }
  }

}