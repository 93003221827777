<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
        <div class="row">
          <h3 class="commontitle" style="margin-top:0px;">Choose Media</h3>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <button mat-icon-button class="pluscommontransparent mt30" (click)="selectmedia(group)">

              <mat-icon>
                {{mediaselection?'done':'add'}}
              </mat-icon>

            </button>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row">
            <br/>
              <div class="col-md-12">
                    <div class="row">
                      <form>
                        <div class="col-md-10">
                          <form>
                            <div class="form-group has-feedback has-search">
                              <input [(ngModel)]="searchcriteria" [ngModelOptions]="{standalone: true}" class="form-control"
                                placeholder="Search here" autocomplete="new-password">
                              <button mat-fab class="searchbtn" style="display: none;" (click)="livesearch()">
                                <mat-icon matSuffix>search</mat-icon>
                              </button>
                            </div>
                          </form>
                        </div>
                      </form>
                    </div>
              </div>
        </div>
    </div>
    <div class="col-md-12 example-container selectmedia" infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50" (scrolled)="getNextSet()" [scrollWindow]="false">
        <mat-button-toggle-group class="col-md-12 zeropadleft zeropadright" #group="matButtonToggleGroup">
          <mat-button-toggle *ngFor="let data of dataSource;let i = index" value="{{data.id}}" class="col-md-4 choosemediabtn" (click)="mediaselect()">
            <img style="width:100%;max-height:140px;" [src]="s3url + data.thumbnail_path" /> 
            <p>{{data.title}}</p>
          </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
  </div>
</div>