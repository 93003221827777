import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay, map, take, tap } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { ContentserviceService } from 'src/app/content/services/contentservice/contentservice.service';
import { ContextService } from 'src/app/healthcoach/services/context.service';
import { UserdetailsService } from 'src/app/healthcoach/services/userdetails/userdetails.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { PumpaddComponent } from '../pumpadd/pumpadd.component';
import { DeletemodalComponent } from 'src/app/content/shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-edithcpump',
  templateUrl: './edithcpump.component.html',
  styleUrls: ['./edithcpump.component.scss']
})
export class EdithcpumpComponent implements OnInit {
  public dataVal: any;
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedUserID: any;
  bodydata: any;
  pumpDetails: any;
  public isinvalid: boolean = false;
  private allfiles: any;
  public uploadedfiletype: any;
  public supportedfiletype: Array<string> = ['jpeg', 'png', 'jpg', 'pdf'];
  prescription_type: any;
  responseUrl: any;
  public allpumpdata: any;
  private responsethumbUrl: any;
  upload_prescription_type: any;
  upload_type: any = upload_type;
  upload_doc_type: any;
  private resdata: any;
  public image_path: any;
  s3url: any = environment.s3url;
  public loadingError$: Subject<boolean> = new Subject<boolean>();
  public dataForm = this.fb.group({
    patient_id_fk: [''],
    pump_id_fk: ['', [Validators.required]],
    serial_number: [''],
    // media_ids: ['', Validators.required],
    expiry_date: [''],
    pump_route_type: ['', Validators.required],
    custom_days: [''],
    time: [[]],
    is_other_pump: [false],
    other_pump_name: [''],
    manufacturer: [''],
    reason_for_change: [''],
    reason_for_addition: [''],
    label_for_remodulin: [''],
    date_of_change: [''],
    hourly_dose: [''],
    time_of_infusion: [''],
    temperature: [''],
    occulsions: [''],
    errors: [''],
    positive_pressure_maintained: [''],
    t: [''],
    battery_status: [''],
    pump_status: ['', [Validators.required]],
    select_active_pump: ['', [Validators.required]],
  });
  isloadingpres: boolean = false;
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  public demoForm = new FormGroup({
    files: this.filesControl,
  });
  fullPumpData: any;
  pumpStatus: any = 0;
  selected_pump_status: any;

  max_reserve_pump_count: any;
  min_current_pump_count: any;
  min_reserve_pump_count: any;
  max_current_pump_count: any;
  newCurrentPump: any;
  temp_pump_id: any;
  selectedBrandID: any;
  can_be_reserve:  boolean = true;
  valid_pump_status:any=[];
  selected_valid_pump: string;
  is_other_pump: any;
  selectNewCurrentPumpDropDown: any;
  other_pump_selected_status: any;
  mediaUploadsize: any;

  constructor(public router: Router, private SystemService: SystemService, private contentService: ContentserviceService, public fb: FormBuilder,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private appContext: ContextService, private userDetailService: UserdetailsService,
    private systemService: SystemService, private commonService: CommonserviceService, private readonly changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dataVal = this.data;
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getPumpData();
    this.getPumpList();
    this.getPumpFlags(this.appContext.selectedChatUser.getValue());
    this.mediaUploadsize = JSON.parse(JSON.parse(localStorage.getItem('mediaUploadSize')));

  }

  getPumpList() {
    this.userDetailService.getOnePumpData(this.dataVal)
      .subscribe(
        (res: any) => this.getSinglePumpResponse(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getSinglePumpResponse(res) {
    this.pumpDetails = res.data;
    this.dataForm.patchValue(this.pumpDetails);
    this.dataForm.patchValue(this.pumpDetails.reminder_joined_data);
    if (this.pumpDetails.reminder_joined_data.hasOwnProperty('time')) {
      var reminderTime = this.pumpDetails.reminder_joined_data.time[0]?.i
      var d = new Date(reminderTime);
    }
    this.dataForm.controls.t.patchValue(d);
    this.selected_pump_status = this.pumpDetails.pump_status;
    this.other_pump_selected_status = this.pumpDetails.pump_status;

    this.is_other_pump = this.pumpDetails.is_other_pump;
    var valid_pump_status =  this.pumpDetails.valid_pump_status;
    if(valid_pump_status){
      valid_pump_status.find((value)=>{
        if(value===1  || value==="1"){
          this.valid_pump_status.push({name:'Current',value:'1'})
          this.selected_valid_pump = '1';
        }else if(value===2  || value==="2"){
          this.valid_pump_status.push({name:'Reserve',value:'2'})
          this.selected_valid_pump = '2';
        }else if(value===3  || value==="3"){
          this.valid_pump_status.push({name:'Disabled',value:'3'})
          this.selected_valid_pump = '3';
        }
    })
    }
    this.dataForm.patchValue({
      pump_status: this.pumpDetails.pump_status.toString(),
    });
    this.getUserPumps();
  }

  getPumpFlags(selectedUserID: string) {
    this.userDetailService.getUserProfileDetails(selectedUserID).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => { this.loadingSubject$.next(false); })
    ).subscribe(data => {
      data = (data as any).data;
      data = (data == null) ? [] : data;

      this.max_reserve_pump_count = data.patient_client_data.max_reserve_pump_count;
      this.min_reserve_pump_count = data.patient_client_data.min_reserve_pump_count;

      this.max_current_pump_count = data.patient_client_data.max_current_pump_count;
      this.min_current_pump_count = data.patient_client_data.min_current_pump_count;
    });
  }
  // To Get Media 
  // ----------------------------

  getMedia(id) {
    this.commonService.getGraphQlData('{getOneDocumentMedia(appMode:false,mediaId:' + id + '){path,id}}')
      .subscribe(
        (res: any) => this.onMediaDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onMediaDataSuccess(data) {
    data = (data as any).data;
    this.image_path = data.getOneDocumentMedia?.path;
    // this.image_id = data.getOneDocumentMedia.id;
    // this.uploadedparam = true;
  }
  // ----------------------------
  getPumpData() {
    this.contentService.getGraphQlData('{getAllPump(status:true){id,title,route_type,add_pump_status,valid_pump_status}}', false)
      .subscribe(
        (res: any) => this.onPumpDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private onPumpDataSuccess(data) {
    data = (data as any).data;
    this.allpumpdata = data.getAllPump;
  }

  closeDialog() {
    this.dialog.closeAll();
  }
  convertIsoDatetime(d) {
    var date = new Date(d);

    // var UTCdate = date.toUTCString()
    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // var getHr = date.getUTCHours()
    // var getMin = date.getUTCMinutes()
    // var getTime = getHr +":"+getMin
    // 
    //  return getTime.toString();
    const number = moment(date).format("HH:mm");

    // return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return number;
  }

  uploadObject() {
    this.bodydata = this.dataForm.value;
    if((this.selected_pump_status === 1 || this.selected_pump_status === '1') && (this.bodydata.pump_status === '2' || this.bodydata.pump_status === 2 ||this.bodydata.pump_status === '3' || this.bodydata.pump_status === 3)){
      if(this.bodydata.select_active_pump === '' || this.bodydata.select_active_pump === undefined ||this.bodydata.select_active_pump === null){
        this.systemService.showSnackBar("Select New Current Pump", "Ok");
        return;
      }
    }
    this.bodydata.patient_id_fk = this.selectedUserID.toString();
    this.bodydata.custom_days = this.bodydata.custom_days.toString();
    const momentDate = new Date(this.dataForm.value.expiry_date);
    this.bodydata.expiry_date = moment(momentDate).format("YYYY-MM-DD");
    this.bodydata.serial_number = this.bodydata.serial_number;
    this.bodydata.is_other_pump = this.bodydata.is_other_pump.toString();
    if (this.bodydata.time_of_infusion) {
      this.bodydata.time_of_infusion = this.bodydata.time_of_infusion;
    } else {
      delete this.bodydata.time_of_infusion;
    }
    if (this.bodydata.is_other_pump == null || this.bodydata.is_other_pump == undefined ||this.bodydata.is_other_pump == '')  {
      delete this.bodydata.is_other_pump;
    } else {
      this.bodydata.pump_id_fk = this.bodydata.pump_id_fk;
    }
    if (this.bodydata.is_other_pump) {
      this.bodydata.other_pump_name = this.bodydata.other_pump_name;
    } else {
      delete this.bodydata.other_pump_name;
    }
    if (this.bodydata.manufacturer) {
      this.bodydata.manufacturer = this.bodydata.manufacturer;
    } else {
      delete this.bodydata.manufacturer;
    }
    if (this.bodydata.reason_for_change) {
      this.bodydata.reason_for_change = this.bodydata.reason_for_change;
    } else {
      delete this.bodydata.reason_for_change;
    }
    if (this.bodydata.reason_for_addition) {
      this.bodydata.reason_for_addition = this.bodydata.reason_for_addition;
    } else {
      delete this.bodydata.reason_for_addition;
    }
    if (this.bodydata.label_for_remodulin) {
      this.bodydata.label_for_remodulin = this.bodydata.label_for_remodulin;
    } else {
      delete this.bodydata.label_for_remodulin;
    }
    if (this.dataForm.value.date_of_change) {
      const momentDate1 = new Date(this.dataForm.value.date_of_change);
      this.bodydata.date_of_change = momentDate1.toISOString();
    } else {
      delete this.bodydata.date_of_change;
    }
    if (this.bodydata.hourly_dose) {
      this.bodydata.hourly_dose = this.bodydata.hourly_dose;
    } else {
      delete this.bodydata.hourly_dose;
    }
    if (this.dataForm.value.time_of_infusion) {
      const momentDate2 = new Date(this.dataForm.value.time_of_infusion);
      this.bodydata.time_of_infusion = momentDate2.toISOString();
    } else {
      delete this.bodydata.time_of_infusion;
    }
    if (this.bodydata.temperature) {
      this.bodydata.temperature = this.bodydata.temperature;
    } else {
      delete this.bodydata.temperature;
    }
    if (this.bodydata.occulsions) {
      this.bodydata.occulsions = this.bodydata.occulsions;
    } else {
      delete this.bodydata.occulsions;
    }
    if (this.bodydata.errors) {
      this.bodydata.errors = this.bodydata.errors;
    } else {
      delete this.bodydata.errors;
    }
    if (this.bodydata.positive_pressure_maintained) {
      this.bodydata.positive_pressure_maintained = this.bodydata.positive_pressure_maintained;
    } else {
      delete this.bodydata.positive_pressure_maintained;
    }
    if (this.bodydata.battery_status) {
      this.bodydata.battery_status = this.bodydata.battery_status;
    } else {
      delete this.bodydata.battery_status;
    }
    if (this.bodydata.pump_status) {
      this.bodydata.pump_status = (this.bodydata.pump_status);
    } else {
      delete this.bodydata.pump_status;
    }
    delete this.bodydata.select_active_pump;
    if(this.bodydata.time !== ''){
      this.bodydata.time =  JSON.stringify(this.bodydata.time);
      delete this.bodydata.t;
    }else{
      var time = this.convertIsoDatetime(this.bodydata.t);
      var d = new Date(this.bodydata.t);
      var date = d.toISOString()
      var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      // Replace event.value with your date value
      this.bodydata.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      delete this.bodydata.t;
    }

    if (this.demoForm.value.files) {
      this.demoForm.value.files.forEach(el => {
        if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.video) && (el.type.includes('video'))) {
          this.isinvalid = true;
        }
        else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.audio) && (el.type.includes('audio'))) {
          this.isinvalid = true;
        }
        else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.image) && (el.type.includes('image'))) {
          this.isinvalid = true;
        }
        else if (((el.size / (1024 * 1024)) >  this.mediaUploadsize.document) && (el.type.includes('pdf'))) {
          this.isinvalid = true;
        }
        else {
          this.isinvalid = false;
          this.isloadingpres = false;
        }
      });
      if (this.isinvalid != true) {
        if (this.demoForm.status == 'INVALID') {
          this.isinvalid = true;
          this.isloadingpres = false;
        }
        else {
          this.isinvalid = false;
          this.allfiles = this.demoForm.value.files;
          this.uploadedfiletype = (this.allfiles[0].type).split('/')[1];

          if (!this.supportedfiletype.includes(this.uploadedfiletype)) {
            this.isloadingpres = false;
            this.systemService.showSnackBar("Your filetype is not supported.", "Ok");
          }
          else {
            var imageinfo = {
              detailed_text: 'pump',//this.dataForm.value.name,
              title: 'pump',//this.dataForm.value.name,
              type: this.uploadedfiletype,
            }

            this.isloadingpres = true;
            var scope = this;
            var last = false;
            var imagedetails;
            processfiles(0);
            function processfiles(i) {
              if (imageinfo) {
                scope.isloadingpres = true;
                imagedetails = "";
                imagedetails = imageinfo;
                if (scope.allfiles[i].type.includes('pdf')) {
                  scope.prescription_type = 'pump';
                }
                if (scope.allfiles[i].type.includes('image')) {
                  scope.prescription_type = 'pump';
                }
                scope.commonService.getS3URL(scope.prescription_type, scope.allfiles[i].type, scope.allfiles[i].size, scope.allfiles[i].name)
                  .subscribe(
                    (data) => {
                      scope.responseUrl = data;
                      scope.commonService.uploadToS3(scope.allfiles[i], scope.responseUrl.data.signed_request)
                        .subscribe(
                          (data) => {
                            scope.uploadthumbnail(scope.responseUrl.data.path, scope.allfiles[i], imagedetails, data => {
                            });
                          }, (error) => {
                            scope.isloadingpres = false;
                            scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                          })
                    }, (error) => {
                      scope.isloadingpres = false;
                      scope.systemService.showSnackBar("Unable to upload file.", "Ok");
                    });
              }
            }
          }
        }
      }
    } else {
      this.bodydata.media_ids = JSON.stringify(this.bodydata.media_ids);
      if (this.bodydata.media_ids === undefined) {
        this.bodydata.media_ids = "[]";
      }
      if( this.is_other_pump === 'false' || this.is_other_pump === ''  || this.is_other_pump === undefined || this.is_other_pump === null && this.bodydata.is_other_pump === 'true'){
        delete this.bodydata.pump_id_fk;
        this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
        this.addOtherPumpDetails(this.bodydata.pump_status,this.bodydata);
      }else{
        this.pumpStatusChangeType(this.selected_pump_status.toString(), this.bodydata.pump_status, this.dataVal);
      }
    
    }
  }

  uploadthumbnail(path, ele, imageinfo, cb) {
    var scope = this;
    var fileReader = new FileReader();
    if (ele.type.match('image')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.src = fileReader.result as string;
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);
          scope.commonService.getS3URL('pump', thtype, ele.size, ele.name)
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);
    }
    else if (ele.type.match('pdf')) {
      fileReader.onloadend = function () {
        let tempImg = new Image();
        tempImg.setAttribute('crossorigin', 'anonymous');
        tempImg.src = '/assets/img/pdficon.jpg';
        tempImg.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 300;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(tempImg, 0, 0, tempW, tempH);
          var thtype = "image/jpeg";
          var dataURL = canvas.toDataURL("image/jpeg");
          var myFile: Blob = scope.dataURItoBlobb(dataURL);

          scope.commonService.getS3URL('pump_image', thtype, myFile.size, 'image.png')
            .subscribe(
              (data) => {
                scope.responsethumbUrl = data;
                scope.commonService.uploadToS3(myFile, scope.responsethumbUrl.data.signed_request)
                  .subscribe(
                    (data) => {
                      saveIttoDB(path, ele.size, ele.type, scope.responsethumbUrl.data.path, imageinfo);
                    }, (error) => {
                      scope.isloadingpres = true;
                      scope.systemService.showSnackBar("Unable to upload files.", "Ok");
                    })
              })
        }
      }
      fileReader.readAsDataURL(ele);

    }
    else {
      this.isloadingpres = true;
      this.systemService.showSnackBar('File Format Not Allowed', 'OK');
      cb(false);
    }

    function saveIttoDB(path, size, type, thumbpath, imageinfo) {
      if (scope.allfiles[0].type.includes('pdf')) {
        scope.upload_prescription_type = upload_type[0];
        scope.upload_doc_type = 'document';
      }
      if (scope.allfiles[0].type.includes('image')) {
        scope.upload_prescription_type = upload_type[1];
        scope.upload_doc_type = 'gallary';
      }
      scope.commonService.postImageToBlade(path, size.toString(), imageinfo.title, imageinfo.detailed_text, type, scope.upload_prescription_type, scope.upload_doc_type)
        .subscribe(
          (data) => {
            scope.resdata = data;
            scope.image_path = scope.resdata.data?.path;
            scope.dataForm.reset();
            scope.demoForm.reset();
            scope.isloadingpres = false;
            scope.bodydata.media_ids = [scope.resdata.data.id];
            scope.bodydata.media_ids = JSON.stringify(scope.bodydata.media_ids);
            if (scope.resdata.status === 201) {
              if(scope.is_other_pump === 'false' || scope.is_other_pump === ''  || scope.is_other_pump === undefined || scope.is_other_pump === null && scope.bodydata.is_other_pump === 'true'){
                delete this.bodydata.pump_id_fk;
                scope.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');
                scope.addOtherPumpDetails(scope.bodydata.pump_status,scope.bodydata);
              }else{
                scope.pumpStatusChangeType(scope.selected_pump_status.toString(), scope.bodydata.pump_status, scope.dataVal);
              }
            }
            else {
              scope.isloadingpres = true;
              scope.systemService.showSnackBar("Unknown error occured", "Ok");
            }
            cb(true);
          })
    }
  }

  dataURItoBlobb(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getUserPumps() {
    let data = {
      "patient_id_fk": this.appContext.selectedChatUser.getValue().toString()
    }
    this.userDetailService.getPumps(data)
      .subscribe(
        (res: any) => this.allPumps(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  allPumps(res: any) {
    res = res.data;
    this.fullPumpData = res.filter(a => a.is_deleted == false);
    this.selectNewCurrentPumpDropDown = this.fullPumpData.filter(pump => pump.pump_status !== 1)
    res.filter((a => {
      if (a.id === this.pumpDetails.id) {
        this.pumpDetails['title'] = a.title;
        this.can_be_reserve = a.can_be_reserve;
      }
    }));
  }

  selectActivePump(event: any) {
    this.newCurrentPump = event;
  }

  onStatusChange(value) {
    this.pumpStatus = value.value;
  }

  buildObject(data: any, pump_type: any, user_selected_pump_status: any) {
    let tempData: any;
    tempData = { ...data };
    if (pump_type === 'current') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.temperature;
      delete tempData.valid_pump_status;
      delete tempData.add_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();

      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
      let t = parseInt(tempData.time[0].t)
      var time = this.convertIsoDatetime(t);
      var d = new Date(t);
      var date = d.toISOString()
      var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      }else{
        tempData.time = tempData.time?.toString();
      }
      tempData.pump_status = '1';
    } else if (pump_type === 'reserve') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.temperature;
      delete tempData.valid_pump_status;
      delete tempData.add_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();

      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if (tempData?.time) {
        let t = parseInt(tempData?.time[0].t)
        var time = this.convertIsoDatetime(t);
        var d = new Date(t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      } else {
        tempData.time = tempData.time?.toString();
      }

      tempData.pump_status = '2';
    } else if (pump_type === 'disabled') {
      delete tempData.id;
      delete tempData.reminderIdDataFkId;
      delete tempData.reminder_id_data_fk;
      delete tempData.is_active;
      delete tempData.is_deleted;
      delete tempData.updated_at;
      delete tempData.created_at;
      delete tempData.createdByFkId;
      delete tempData.created_by_fk;
      delete tempData.updatedByFkId;
      delete tempData.pumpIdFkId;
      delete tempData.patientIdFkId;
      delete tempData.title;
      delete tempData.route_type;
      delete tempData.remodulin_labels;
      delete tempData.media_data;
      delete tempData.manufacturer;
      delete tempData.reason_for_change;
      delete tempData.reason_for_addition;
      delete tempData.label_for_remodulin;
      delete tempData.date_of_change;
      delete tempData.hourly_dose;
      delete tempData.time_of_infusion;
      delete tempData.temperature;
      delete tempData.occulsions;
      delete tempData.errors;
      delete tempData.positive_pressure_maintained;
      delete tempData.battery_status;
      delete tempData.updated_by_fk;
      delete tempData.temperature;
      delete tempData.valid_pump_status;
      delete tempData.add_pump_status;

      tempData.is_other_pump = tempData.is_other_pump?.toString();
      tempData.patient_id_fk = tempData.patient_id_fk?.toString();

      if (tempData.media_ids === null || tempData.media_ids === undefined) {
        tempData.media_ids = '[]'
      } else {
        tempData.media_ids = tempData.media_ids?.toString();
      }
      tempData.custom_days = tempData.custom_days?.toString();
      if(tempData?.time){
        let t = parseInt(tempData?.time[0].t)
        var time = this.convertIsoDatetime(t);
        var d = new Date(t);
        var date = d.toISOString()
        var newdate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        tempData.time = JSON.stringify([{ "i": newdate, "e": true, "t": time }]);
      }
      tempData.pump_status = '3';
    }
    return tempData;
  }

  openAddNewPump() {
    this.dialog.open(PumpaddComponent, {
      width: '500px',
      height: '600px',
      data: {
        dataKey: this.selectedUserID,
      }
    });
  }

  async updatePumpDetails() {
    this.userDetailService.updatePumpData(this.dataVal, this.bodydata).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.dialog.closeAll();
          this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
        }
      },
      (err: HttpErrorResponse) => {
        this.dialog.closeAll();
        this.systemService.handleErrorResponse(err)
      }
    )
  }
  async addOtherPumpDetails(pump_status:any,bodydata:any) {
    let userfullpumpdata: any = [];
    userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
    let formObject;
    let customDiablogHeader:any;
    // check for current and disable it.
    if(pump_status === '1' || pump_status === 1){
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
          customDiablogHeader = 'Already have a current pump. Do you want to make Pump ' +  this.bodydata.other_pump_name + 'current ?';
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              const a = document.createElement('a');
              a.click();
              a.remove();
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.userDetailService.addPump(bodydata).subscribe(
                    (res: any) => {
                      if (res.status == 200) {
                        this.dialog.closeAll();
                        this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                      }
                    },
                    (err: HttpErrorResponse) => {
                      this.dialog.closeAll();
                      this.systemService.handleErrorResponse(err)
                    }
                  )
                }));
            } else {
              this.dialog.closeAll();
            }
          });
        } 
      }
    }
      // check for Reserve and disable it.
    else if(pump_status === '2' || pump_status === 2){
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
          customDiablogHeader = 'Already have a reserve pump. Do you want to make Pump ' +  this.bodydata.other_pump_name + 'reserve ?';
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              const a = document.createElement('a');
              a.click();
              a.remove();
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.userDetailService.addPump(bodydata).subscribe(
                    (res: any) => {
                      if (res.status == 200) {
                        this.dialog.closeAll();
                        this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                      }
                    },
                    (err: HttpErrorResponse) => {
                      this.dialog.closeAll();
                      this.systemService.handleErrorResponse(err)
                    }
                  )
                }));
            } else {
              this.dialog.closeAll();
            }
          });
        } 
      }
    }else{
      this.userDetailService.addPump(bodydata).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.dialog.closeAll();
            this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
          }
        },
        (err: HttpErrorResponse) => {
          this.dialog.closeAll();
          this.systemService.handleErrorResponse(err)
        }
      )
    }
  }

  pumpStatusChangeType(user_selected_previous_pump_status: any, user_edited_pump_status: any, selected_row_pump_id: any) {
     // current to current
    if ((user_selected_previous_pump_status === 1 || user_selected_previous_pump_status === "1") && (user_edited_pump_status === 1 || user_edited_pump_status === "1")) {
      this.updatePumpDetails();
    }
    // ****** current to Reserve ********
    else if ((user_selected_previous_pump_status === 1 || user_selected_previous_pump_status === "1") && (user_edited_pump_status === 2 || user_edited_pump_status === "2")) {
      //check if there is reserve/Current and max and min count 
      let reservePumpSize = this.fullPumpData.filter(x => x.pump_status === 2).length;
      let currentPumpSize = this.fullPumpData.filter(x => x.pump_status === 1).length;
      let customDiablogHeader: any;
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let formObject;
      // check for reserve pump and change it to disable pump
      if(reservePumpSize > 0){
        for (let i = 0; i < userfullpumpdata.length; i++) {
          if (userfullpumpdata[i].pump_status === 2 || userfullpumpdata[i].pump_status === "2") {
            if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
              customDiablogHeader = 'Already have an reserve pump. Do you want make ' +''+ this.bodydata.other_pump_name +''+ ' as reserve pump?';
            }else{
              customDiablogHeader = 'Already have an reserve pump. Do you want make ' +''+ this.pumpDetails.title +''+ ' as reserve pump?';
            }
            localStorage.setItem('modal_header', customDiablogHeader);
            const dialogRef = this.dialog.open(DeletemodalComponent);
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                localStorage.removeItem("modal_header");
                const a = document.createElement('a');
                a.click();
                a.remove();
                //making existing reserve pump to disabled state
                formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
                this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                  .then((data => {
                    // check for current pump and change it to reserve pump
                    for (let i = 0; i < userfullpumpdata.length; i++) {
                      if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
                        formObject = this.buildObject(userfullpumpdata[i], 'reserve', '2');
                        this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                          .then((data => {
                            // update new current pump
                            for (let i = 0; i < userfullpumpdata.length; i++) {
                              if (userfullpumpdata[i].id === this.newCurrentPump.id) {
                                formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                                this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                                  .then((data => {
                                    this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                                    this.dialog.closeAll();
                                  }));
                              }
                            }
                          }));
  
                      }
                    }
                  }));
              } else {
                this.dialog.closeAll();
              }
            });
          }
        }
      }else{
          for (let i = 0; i < userfullpumpdata.length; i++) {
            if (userfullpumpdata[i].id === selected_row_pump_id) {
              formObject = this.buildObject(userfullpumpdata[i], 'reserve', '2');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  for (let i = 0; i < userfullpumpdata.length; i++) {
                    if (userfullpumpdata[i].id === this.newCurrentPump.id) {
                      formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                      this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                        .then((data => {
                          this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                          this.dialog.closeAll();
                        }));
                    }
                  }
                }));
            }
        }
      }
    }
    // ****** current to Disable  ******
    else if ((user_selected_previous_pump_status === 1 || user_selected_previous_pump_status === "1") && (user_edited_pump_status === 3 || user_edited_pump_status === "3")) {
      //check if there is dsiable/Current  count 
      let disablePumpSize = this.fullPumpData.filter(x => x.pump_status === 3).length;
      let currentPumpSize = this.fullPumpData.filter(x => x.pump_status === 1).length;
      let customDiablogHeader: any;
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let newReservePump: any;
      let formObject;
      if (currentPumpSize == 1 && disablePumpSize === 0) {
        customDiablogHeader = 'There is only "1" current pump available you cannot disable it';
        localStorage.setItem('modal_header', customDiablogHeader);
        const dialogRef = this.dialog.open(DeletemodalComponent);
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            localStorage.removeItem("modal_header");
            const a = document.createElement('a');
            a.click();
            a.remove();
            this.dialog.closeAll();
          } else {
            this.dialog.closeAll();
          }
        });
      } else if (disablePumpSize > 0) {
        for (let i = 0; i < userfullpumpdata.length; i++) {
          if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
            if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
              customDiablogHeader = 'Are you sure you want to Disable ' + this.bodydata.other_pump_name + '?';
            }else{
              customDiablogHeader = 'Are you sure you want to Disable ' + this.pumpDetails.title + '?';
            }
            localStorage.setItem('modal_header', customDiablogHeader);
            const dialogRef = this.dialog.open(DeletemodalComponent);
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                localStorage.removeItem("modal_header");
                const a = document.createElement('a');
                formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
                // newReservePump = userfullpumpdata[i];
                this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                  .then((data => {
                    userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
                    //check for user selected current pump
                    if (  this.newCurrentPump !== '') {
                      // if user new selected pump is in disabled state
                      if (this.newCurrentPump.pump_status === 3 ||   this.newCurrentPump.pump_status === "3") {
                        for (let i = 0; i < userfullpumpdata.length; i++) {
                          if (userfullpumpdata[i].id ===   this.newCurrentPump.id) {
                            formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                            this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                              .then((data => {
                                this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                                this.dialog.closeAll();
                              }));
                          }
                        }
                      }
                      // if  user new selected pump is in reserved state
                      else if (this.newCurrentPump.pump_status === 2 ||this.newCurrentPump.pump_status === "2") {
                        // change reserve to new current pump
                        for (let i = 0; i < userfullpumpdata.length; i++) {
                          if (userfullpumpdata[i].id ===   this.newCurrentPump.id) {
                            formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                            this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                              .then((data => {
                                this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                                this.dialog.closeAll();
                              }));
                          }
                        }
                      }
                    }
                  }));
                a.click();
                a.remove();
              } else {
                this.dialog.closeAll();
              }
            });
          }
        }
      };
    }
    //  ****** Reserve to Reserve  ******
    else if ((user_selected_previous_pump_status === 2 || user_selected_previous_pump_status === "2") && (user_edited_pump_status === 2 || user_edited_pump_status === "2")) {
      this.updatePumpDetails();

    }
    //  ****** Reserve to Current  ******
    else if ((user_selected_previous_pump_status === 2 || user_selected_previous_pump_status === "2") && (user_edited_pump_status === 1 || user_edited_pump_status === "1")) {
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let newReservePump: any;
      let formObject;
      let customDiablogHeader: any;
      // check for current and make it as disabled
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
          if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
            customDiablogHeader = 'Already have a current pump. Do you want make ' +''+ this.bodydata.other_pump_name +''+ ' as current pump?';
          }else{
            customDiablogHeader = 'Already have an current pump. Do you want make ' +''+ this.pumpDetails.title +''+ ' as current pump?';
          }
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              newReservePump = userfullpumpdata[i].id;
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  // check for reserve and make it as current
                  for (let i = 0; i < userfullpumpdata.length; i++) {
                    if (userfullpumpdata[i].pump_status === 2 || userfullpumpdata[i].pump_status === "2") {
                      formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                      this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                        .then((data => {
                          this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                          this.dialog.closeAll();
                        }));
                    }
                  }
                }));
              const a = document.createElement('a');
              a.click();
              a.remove();
            } else {
              this.dialog.closeAll();
            }
          });
        } else{
          for (let i = 0; i < userfullpumpdata.length; i++) {
            if (userfullpumpdata[i].id === selected_row_pump_id) {
              formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                  this.dialog.closeAll();
                }));
            }
          }
        }
      }
    }

    //  ****** Reserve to Disabled  ******
    else if ((user_selected_previous_pump_status === 2 || user_selected_previous_pump_status === "2") && (user_edited_pump_status === 3 || user_edited_pump_status === "3")) {
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let newReservePump: any;
      let formObject;
      let customDiablogHeader: any;
      // check for reserve and disable it.
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 2 || userfullpumpdata[i].pump_status === "2") {
          if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
            customDiablogHeader = 'Are you sure you want to Disable ' + this.bodydata.other_pump_name + '?';
          }else{
            customDiablogHeader = 'Are you sure you want to Disable ' + this.pumpDetails.title + '?';
          }
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              newReservePump = userfullpumpdata[i].id;
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                  this.dialog.closeAll();
                }));
              const a = document.createElement('a');
              a.click();
              a.remove();

            } else {
              this.dialog.closeAll();
            }
          });

        }
      else{
        for (let i = 0; i < userfullpumpdata.length; i++) {
          if (userfullpumpdata[i].id === selected_row_pump_id) {
            formObject = this.buildObject(userfullpumpdata[i], 'Disabled', '3');
            this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
              .then((data => {
                this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                this.dialog.closeAll();
              }));
          }
        }
      }
    }
  }
    
    //  ****** Disabled to Disabled  ******
    else if ((user_selected_previous_pump_status === 3 || user_selected_previous_pump_status === "3") && (user_edited_pump_status === 3 || user_edited_pump_status === "3")) {
      this.updatePumpDetails();

    }
    //  ****** Disabled to Reserve  ******
    else if ((user_selected_previous_pump_status === 3 || user_selected_previous_pump_status === "3") && (user_edited_pump_status === 2 || user_edited_pump_status === "2")) {
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let formObject;
      let customDiablogHeader: any;
      // check for reserve and disable it.
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 2 || userfullpumpdata[i].pump_status === "2") {
          if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
            customDiablogHeader = 'Already have a reserve pump. Do you want make ' +''+ this.bodydata.other_pump_name +''+ ' as reserve pump?';
          }else{
            customDiablogHeader = 'Already have an reserve pump. Do you want make ' +''+ this.pumpDetails.title +''+ ' as reserve pump?';
          }
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              const a = document.createElement('a');
              a.click();
              a.remove();
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  // change user selected disabled pump to reserve  
                  for (let i = 0; i < userfullpumpdata.length; i++) {
                    if (userfullpumpdata[i].id === selected_row_pump_id) {
                      formObject = this.buildObject(userfullpumpdata[i], 'reserve', '2');
                      this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                        .then((data => {
                          this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                          this.dialog.closeAll();
                        }));
                    }
                  }
                }));
            } else {
              this.dialog.closeAll();
            }
          });
        }
        else {
          // change user selected disabled pump to reserve  
          for (let i = 0; i < userfullpumpdata.length; i++) {
            if (userfullpumpdata[i].id === selected_row_pump_id) {
              formObject = this.buildObject(userfullpumpdata[i], 'reserve', '2');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                  this.dialog.closeAll();
                }));
            }
          }
        }
      }
    }
    //  ******  Disabled to Current  ******
    else if ((user_selected_previous_pump_status === 3 || user_selected_previous_pump_status === "3") && (user_edited_pump_status === 1 || user_edited_pump_status === "1")) {
      let userfullpumpdata: any = [];
      userfullpumpdata = JSON.parse(JSON.stringify(this.fullPumpData))
      let formObject;
      let customDiablogHeader:any;
      // check for current and disable it.
      for (let i = 0; i < userfullpumpdata.length; i++) {
        if (userfullpumpdata[i].pump_status === 1 || userfullpumpdata[i].pump_status === "1") {
          if(this.bodydata.is_other_pump === 'true' || this.bodydata.is_other_pump === true){
            customDiablogHeader = 'Already have a current pump. Do you want make ' +''+ this.bodydata.other_pump_name +''+ ' as current pump?';
          }else{
            customDiablogHeader = 'Already have an current pump. Do you want make ' +''+ this.pumpDetails.title +''+ ' as current pump?';
          }
          localStorage.setItem('modal_header', customDiablogHeader);
          const dialogRef = this.dialog.open(DeletemodalComponent);
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              localStorage.removeItem("modal_header");
              const a = document.createElement('a');
              a.click();
              a.remove();
              formObject = this.buildObject(userfullpumpdata[i], 'disabled', '3');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  // change user selected disabled pump to current  
                  for (let i = 0; i < userfullpumpdata.length; i++) {
                    if (userfullpumpdata[i].id === selected_row_pump_id) {
                      formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
                      this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                        .then((data => {
                          this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                          this.dialog.closeAll();
                        }));
                    }
                  }
                }));
            } else {
              this.dialog.closeAll();
            }
          });
        } else {
          // change user selected disabled pump to current  
          for (let i = 0; i < userfullpumpdata.length; i++) {
            if (userfullpumpdata[i].id === selected_row_pump_id) {
              formObject = this.buildObject(userfullpumpdata[i], 'current', '1');
              this.userDetailService.updatePumpData(userfullpumpdata[i].id, formObject).pipe().toPromise()
                .then((data => {
                  this.systemService.showSuccessSnackBar("Pump has been updated", "Ok");
                  this.dialog.closeAll();
                }));
            }
          }
        }
      }
    }
  }
  onBrandChange(event:any){
    var valid_pump_status =  this.allpumpdata;
    this.valid_pump_status = [];
    valid_pump_status.find((value)=>{
        if(event.value === value.id){
          value.valid_pump_status.find((value)=>{
            if(value===1 || value==="1"){
              this.valid_pump_status.push({name:'Current',value:'1'})
            }else if(value===2  || value==="2"){
              this.valid_pump_status.push({name:'Reserve',value:'2'})
             
            }else if(value===3  || value==="3"){
              this.valid_pump_status.push({name:'Disabled',value:'3'})
            }
        })
        }
    })
}
}
  

export enum upload_type {
  prescription_pdf,
  prescription_image
}

