import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthcoachGuardGuard } from './../../../shared/guards';
import { UploadprescriptionaldialogComponent } from './uploadprescriptionaldialog.component';

const routes: Routes = [
  { path: '', component: UploadprescriptionaldialogComponent, canActivate: [HealthcoachGuardGuard] },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UploadprescriptionaldialogRoutingModule { }
