import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../shared/guards';
import { HardsetotpComponent } from './hardsetotp.component';
import { EdithardsetotpComponent } from './edithardsetotp/edithardsetotp.component';

const routes: Routes = [
  { path: '', component: HardsetotpComponent, canActivate: [AdminGuardGuard] },
  { path: 'hardsetotp/edit/:id', component: EdithardsetotpComponent, canActivate: [AdminGuardGuard] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HardsetotpRoutingModule {}