<span class="slidetogglecss" (click)="togglenav()">
    <i [class]="showingnav ? 'material-icons' : 'material-icons forwardbtn'">
        {{showingnav ? 'arrow_back_ios' : 'arrow_forward_ios'}}
    </i>
</span>
<div class="userlistnavcontent">
    <div class="row">
        <div class="col-md-12 submenubar">
            <h3 class="userlisttitle">Payment Section</h3>
            <a [routerLink]="['/admin/payment']" routerLinkActive="active">Payment List</a>
            <a [routerLink]="['/admin/paymentmapping']" routerLinkActive="active">Payment Mapping</a>

        </div>
    </div>
</div>