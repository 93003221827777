<div class="matcardbottomzero other-details-wrapper">

  <div class="chartheader">
      
    <mat-select [(value)]="selected" class="sortcommonwhite others-mat-select" (selectionChange)="onSelectChange($event)">
      <mat-option value="prescription">Prescription</mat-option>
      <mat-option value="reminder" *ngIf="userClientDetails != 'Ferrer'">Reminder</mat-option>
      <mat-option value="symptom">Symptoms</mat-option>
      <mat-option value="trigger" *ngIf="userDataQ?.show_triggers">Triggers</mat-option>
      <mat-option value="questionnaire" *ngIf="userDataQ?.show_questionnaire">Questionnaire</mat-option>
      <mat-option value="medication" *ngIf="userClientDetails == 'Ferrer'">Medication</mat-option>
      <mat-option value="pump" *ngIf="userClientDetails == 'Ferrer'">Pump</mat-option>
      <mat-option value="pump" *ngIf="userClientDetails == 'Ferrer'">Historical Pump</mat-option>
      <mat-option value="medicalhistory" *ngIf="userClientDetails == 'Ferrer'">Medical History</mat-option>
      <mat-option value="hospitalisation" *ngIf="userClientDetails == 'Ferrer'">Hospitalisation</mat-option>

      <!-- <mat-option value="medicine">Medicine</mat-option> -->
      <!-- <mat-option value="roa">Route Of Administration</mat-option> -->

    </mat-select>

  </div>

  <ng-container *ngIf="selected == 'prescription' || selected == 'reminder' || selected == 'symptom'">
    
    <table mat-table multiTemplateDataRows [dataSource]="renderingDataSource" class="prescription-table">

      <!-- Prescription: Added On -->
      <ng-container matColumnDef="addedOn">
        <mat-header-cell *matHeaderCellDef> Added on </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{convertIsoData(element.date)}} </mat-cell>
      </ng-container>

      <!-- Prescription: Title -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>

      <!-- Prescription: Attachments -->
      <ng-container matColumnDef="attachments">
        <mat-header-cell *matHeaderCellDef> Attachments </mat-header-cell>
        <mat-cell *matCellDef="let element" class="media-wrapper">
          <div class="media-container">
            <img *ngIf="element.type == 'image'" [src]="s3url + element.path" (click)="openDialogImg(s3url + element.path,'image')" alt=""/>

            <a *ngIf="element.type == 'pdf'" [href]="s3url + element.path" target="_blank"> 
              <img [src]="documentPlaceHolder" class="imageboxcss" alt=""/> 
            </a> 
          </div>          

        </mat-cell>
      </ng-container>

      <!-- Reminder: Added On -->
      <ng-container matColumnDef="reminderAddedOn">
        <mat-header-cell *matHeaderCellDef> Added on </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{convertIsoData(element.created_at)}} </mat-cell>
      </ng-container>

      <!-- Reminder: Name -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-capitalize"> {{ element.name }} </mat-cell>
      </ng-container>

      <!-- Reminder: Type -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-capitalize"> {{ element.type }} </mat-cell>
      </ng-container>

      <!-- Reminder: Scheduled days -->
      <ng-container matColumnDef="scheduledDays">
        <mat-header-cell *matHeaderCellDef> Scheduled days </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.scheduledDays}} </mat-cell>
      </ng-container>

      <!-- Reminder: Scheduled time -->
      <ng-container matColumnDef="scheduledTime">
        <mat-header-cell *matHeaderCellDef> Scheduled time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.scheduledTime}} </mat-cell>
      </ng-container>

      <!-- Symptoms: Scheduled days -->
      <ng-container matColumnDef="symptoms">
        <mat-header-cell *matHeaderCellDef> Symptoms </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-capitalize"> {{element.title}} </mat-cell>
      </ng-container>

      <!-- Symptoms: Severity -->
      <ng-container matColumnDef="severity">
        <mat-header-cell *matHeaderCellDef> Severity </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-capitalize"> {{element.value}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-capitalize">{{element.log_date | date:'MMM d, y, h:mm a'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="rendreingDisplayColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: rendreingDisplayColumns;"></mat-row>
    </table>

    <div *ngIf="renderingDataSource.length == 0" class="text-center empty-holder">
      <p class="no-data">No Data Avaliable</p>
    </div>

  </ng-container>

  <ng-container *ngIf="selected == 'medicine' || selected == 'trigger' || selected == 'questionnaire' || selected == 'medication' || selected == 'pump' || selected == 'medicalhistory' || selected == 'roa' || selected == 'hospitalisation'">
    <mat-card class="matCard other-card">
      <div class="prescriptiondetail matcardbottomzero">
  
        <div *ngIf="selected == 'medicine'">
          <div *ngIf="medicineData.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
          <div class="col-md-12" *ngFor="let med of medicineData">
            <div class="row dashed-border-bottom" *ngIf="med">
              <p class="med-data"> {{medData(med?.created_at)}}</p>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Name</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{med.name}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Medicine Type</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{med.medicine_type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left" *ngIf="med.medicine_type == 'tablet' || typeFilters == 'capsule'">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Medicine Shape</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{med.medicine_shape}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left"
                *ngIf="!(med.medicine_type == 'tablet' || med.medicine_type == 'capsule' || med.medicine_type == 'insulin_pen' || med.medicine_type == 'ointment')">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Volume(ml)</mat-card-title>
                    <mat-card-subtitle>{{med.volume}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left"
                *ngIf="med.medicine_type == 'capsule' || med.medicine_type == 'tablet'">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Dosage(M/A/E)</mat-card-title>
                    <mat-card-subtitle>{{dosage(med.dosage)}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
              <div class="col-md-4 no-padding-left"
                *ngIf="med.medicine_type == 'capsule' || med.medicine_type == 'tablet'">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Pre Meal</mat-card-title>
                    <mat-card-subtitle>{{preMeal(med.pre_meal)}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
              <div class="col-md-4 no-padding-left" *ngIf="med.duration != 0">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Duration</mat-card-title>
                    <mat-card-subtitle>{{duration(med.duration)}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
  
              <div class="col-md-4 no-padding-left"
                *ngIf="med.medicine_type == 'Insulin_pen' || med.medicine_type == 'injectable'">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Number of Shots</mat-card-title>
                    <mat-card-subtitle>{{med.num_shots}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left"
                *ngIf="!(med.medicine_type == 'syrup' || med.medicine_type == 'ointment' || med.medicine_type == 'injectable')">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Number of Units</mat-card-title>
                    <mat-card-subtitle>{{med.num_units}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Color</mat-card-title>
                    <mat-card-subtitle>{{conversion(med.color)}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
  
            </div>
  
          </div>
  
        </div>
  
        <!-- <div *ngIf="selected == 'reminder'">
          <div class="clearfix">
          </div>
          <div *ngIf="reminderData?.length == 0" class="text-center">
            <p class="no-data">-- No data Avaliable --</p>
          </div>
          <div class="col-md-12" *ngFor="let reminder of reminderData">
            <div class="row" *ngFor="let a of reminder.data">
              <p class="med-data"> {{convertIsoData(a?.created_at)}}
                {{convertIsoDatetime(a?.created_at)}}</p>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Name</mat-card-title>
                    <mat-card-subtitle>{{a?.name}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Category</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{reminder.type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Schedule for no. of days</mat-card-title>
                    <mat-card-subtitle><span *ngFor="let day of a?.days" class="days">{{convertdate(day)}}</span>
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left" *ngIf="reminder.meal_type">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Meal Type</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{reminder.meal_type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </div>
        </div> -->
  
        <div *ngIf="selected == 'reminder-false'">
  
          <div *ngIf="reminderData?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
  
          <div class="col-md-12" *ngFor="let reminder of reminderData">
            <div class="row ">
              <p class="med-data">
                {{convertIsoData(reminder.created_at)}}
                {{convertIsoDatetime(reminder.created_at)}}
              </p>
  
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Name</mat-card-title>
                    <mat-card-subtitle>{{reminder.name}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Category</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{reminder.type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
              <!-- <div class="col-md-4 no-padding-left" *ngIf="reminder.schedule != 0">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Schedule for no. of days</mat-card-title>
                    <mat-card-subtitle>{{duration(reminder.schedule)}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div> -->
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Schedule for no. of days</mat-card-title>
                    <mat-card-subtitle>{{reminder?.custom_days != null ?reminder.custom_days :'0'}} days 
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
              <div class="col-md-4 no-padding-left" *ngIf="reminder.meal_type">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Meal Type</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{reminder.meal_type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
            </div>
          </div>
  
        </div>
  
        <div *ngIf="selected == 'symptom-false'">
          <div *ngIf="allsymptoms?.length == 0" class="text-center empty-holder">
            <p class="no-data"> No data Avaliable </p>
          </div>
          <div class="col-md-12" *ngFor="let symptom of allsymptoms">
            <div class="row">
              <div class="col-md-12 no-padding-left border-class" *ngIf="symptom?.symptom_ids?.length > 0">
                <div class="col-md-12">
                  <div class="row">
                    <p>{{symptom.log_date | date:'short'}}</p>
                  </div>
                </div>
                <!-- <div class="col-md-6 zeropadleft">
                  <p class="med-data-loc"> Longitude : {{symptom.longitude | number : '1.2-2'}}</p>
                </div>
                <div class="col-md-6 zeropadright">
                  <p class="med-data-loc"> Lattitude : {{symptom.latitude | number : '1.2-2'}}</p>
                </div> -->
                <div class="clearfix"><br /></div>
                <mat-card class="userdetail-card triggerlogs">
                  <mat-card-header>
                    <mat-card-title>Symptom Logged</mat-card-title>
                    <mat-card-subtitle *ngFor="let log of symptom?.symptom_ids">
                      <p style="text-transform: capitalize;"><b>{{log.value}} </b>| {{log.title}}</p>
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </div>
          <button mat-flat-button (click)="goNextPage('symptom')" *ngIf="showmore">More</button>
        </div>
  
        <div *ngIf="selected == 'trigger'">
          <div *ngIf="alltriggers?.length == 0" class="text-center">
            <p class="no-data"> No data Avaliable</p>
          </div>
          <div class="col-md-12" *ngFor="let trigger of alltriggers">
            <div class="row">
              <div class="col-md-12 no-padding-left border-class" *ngIf="trigger?.trigger_logs?.length > 0">
                <div class="col-md-12">
                  <div class="row">
                    <p>{{trigger.log_date | date:'short'}}</p>
                  </div>
                </div>
                <div class="col-md-6 zeropadleft">
                  <p class="med-data-loc"> Longitude : {{trigger.longitude | number : '1.2-2'}}</p>
                </div>
                <div class="col-md-6 zeropadright">
                  <p class="med-data-loc"> Lattitude : {{trigger.latitude | number : '1.2-2'}}</p>
                </div>
                <div class="clearfix"><br /></div>
                <mat-card class="userdetail-card triggerlogs">
                  <mat-card-header>
                    <mat-card-title>Triggers Logged</mat-card-title>
                    <mat-card-subtitle *ngFor="let log of trigger?.trigger_logs"><img
                        src="{{s3url + log?.image_media?.thumbnail_path}}" />
                      <p>{{log.title}}</p>
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </div>
          <button mat-flat-button (click)="goNextPage('trigger')" *ngIf="showmore">More</button>
        </div>
  
        <div *ngIf="selected == 'questionnaire'">
          <!-- <h2 class="heading-text">Reminder</h2> -->
          <div *ngIf="allQuestionnaire?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
          <div class="col-md-12" *ngFor="let questionnaire of allQuestionnaire">
            <div class="row">
              <div class="col-md-12 no-padding-left border-class" *ngIf="questionnaire?.response?.length > 0">
                <div class="col-md-12">
                  <div class="row">
                    <p>{{questionnaire.updated_at | date:'short'}}</p>
                  </div>
                </div>
                <div class="col-md-6 zeropadleft">
                  <p class="med-data-loc"> {{questionnaire.type}}</p>
                </div>
                <div class="col-md-6 zeropadright">
                  <p class="med-data-loc"> Score : {{questionnaire.overall_score | number : '1.2-2'}}</p>
                </div>
                <div class="col-md-12 zeropadleft zeropadright topbottomspacing">
                  {{questionnaire?.feedback?.length == 0 ? 'No Feedback Found' : questionnaire.feedback[0]}}
                </div>
                <div class="clearfix"><br /></div>
                <mat-card class="userdetail-card triggerlogs">
                  <mat-card-header>
                    <mat-card-title>Responses</mat-card-title>
                    <mat-card-subtitle *ngFor="let log of questionnaire?.response">
                      <p>{{log.question}}</p>
                      <div class="clearfix"><br /></div>
                      <div *ngIf="!(isArray(log.selection))">
                        <p class="optionquescss">{{log?.selection?.text}}</p>
                      </div>
                      <div *ngIf="isArray(log.selection)">
                        <p class="optionquescss" *ngFor="let ans of log.selection">{{ans?.text}}</p>
                      </div>
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </div>
          <button mat-flat-button (click)="goNextPage('questionnaire')" *ngIf="showmore">More</button>
        </div>
  
        <!-- <div *ngIf="selected == 'infusionsite'">
  
          <div *ngIf="infusionSiteData?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
          <div class="col-md-12" *ngFor="let infusion of infusionSiteData">
            <div class="row ">
              <p class="med-data">
                {{convertIsoData(infusion.log_date)}}
                {{convertIsoDatetime(infusion.log_date)}}
              </p>
  
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Infusion Site</mat-card-title>
                    <mat-card-subtitle>{{infusion.infusion_site}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Reason For Change</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{infusion.site_change_reason}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Interval</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{infusion.reminder_days}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
            </div>
          </div>
  
        </div> -->
  
        <div *ngIf="selected == 'medication'">
  
          <div *ngIf="medicationData?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
          <div class="col-md-12" *ngFor="let medi of medicationData">
            <div class="row">
              <div class="col-md-12">
                <p class="med-data">
                  {{convertIsoData(medi.created_at)}}
                  {{convertIsoDatetime(medi.created_at)}}
                </p>
              </div>
  
              <div class="col-md-4">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Medicine</mat-card-title>
                    <mat-card-subtitle>{{medi.title}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
              <div class="col-md-4">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Route of Administration</mat-card-title>
                    <mat-card-subtitle>{{medi.medication_route_type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4" *ngIf="medi?.flow_rate">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Flow Rate</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{medi.flow_rate}}{{medi.flow_rate_unit}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Dose</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{medi.dose}}{{medi.dose_unit}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Drug Concentration</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{medi.drug_concentration}}{{medi.drug_concentration_unit}}
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </div>
  
        </div>
  
        <div *ngIf="selected == 'pump'">
  
          <div *ngIf="pumpData?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
          <div class="col-md-12" *ngFor="let pumpdetail of pumpData">
            <div class="row ">
              <p class="med-data">
                <!-- {{convertIsoDatetime(reminder.start_date_time)}} -->
                {{convertIsoData(pumpdetail.created_at)}}
                {{convertIsoDatetime(pumpdetail.created_at)}}
              </p>
  
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Pump Name</mat-card-title>
                    <mat-card-subtitle>{{pumpdetail.title}}{{pumpdetail.other_pump_name}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Type</mat-card-title>
                    <mat-card-subtitle class="uppercase">{{pumpdetail.pump_route_type}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
              <div class="col-md-4 no-padding-left">
                <mat-card class="userdetail-card">
                  <mat-card-header>
                    <mat-card-title>Serial Number</mat-card-title>
                    <mat-card-subtitle class="camelcased">{{pumpdetail.serial_number}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </div>
  
            </div>
          </div>
  
        </div>
  
        <div *ngIf="selected == 'medicalhistory'">
  
          <div *ngIf="MedicalHistoryData?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
  
          <div class="medicationhisto-card" *ngIf="MedicalHistoryData">
            <div class="row">
              <div class="col-md-12">
                <div class="text-over">Current Conditions</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h4>PAH Data</h4>
              </div>
              <div class="col-md-4">
                <div class="items">
                  <p>
                    PAH Subtype
                  </p>
                  <h5 class="text-capitalize">{{MedicalHistoryData?.sub_type}}</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="items">
                  <p>
                    Current functional class
                  </p>
                  <h5>{{MedicalHistoryData?.functional_class}}</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="items">
                  <p>
                    Date of Diagnosis
                  </p>
                  <h5>{{MedicalHistoryData?.date_of_diagnosis | date: 'dd/MM/yyyy'}}</h5>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <hr>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-12">
                <h4>Comorbidities</h4>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div *ngFor="let data of MedicalHistoryData.comorbidities; let i = index;">
  
                    <div class="col-md-6">
                      <div class="items">
                        <p>
                          PAH Subtype
                        </p>
                        <h5 class="text-capitalize">{{data?.name}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="items">
                        <p>
                          Date of Diagnosis
                        </p>
                        <h5> {{data?.date_of_diagnosis | date: 'dd/MM/yyyy'}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="medicationhisto-card">
            <div class="row">
              <div class="col-md-12">
                <div class="text-over">Previous Conditions</div>
              </div>
            </div>
            <div class="row">
              <div *ngFor="let data of MedicalHistoryData.previous_conditions; let i = index;">
                <div class="col-md-4">
                  <div class="items">
                    <p>
                      Condition Name
                    </p>
                    <h5>{{data?.name}}</h5>
                  </div>
                </div>
  
                <div class="col-md-4">
                  <div class="items">
                    <p>
                      Date of Diagnosis
                    </p>
                    <h5>{{data.date_of_diagnosis | date: 'dd/MM/yyyy'}}</h5>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="items">
                    <p>
                      End Date
                    </p>
                    <h5>{{data?.end_date | date: 'dd/MM/yyyy'}}</h5>
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
  
          <div class="medicationhisto-card medicationhisto">
            <div class="row">
              <div class="col-md-12">
                <div class="text-over">Others</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="items">
                  <p class="allergies">
                    Allergies
                  </p>
                  <h5 class="alergy-data">{{MedicalHistoryData?.allergies}}</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="items">
                  <p class="allergies">
                    General Symptoms
                  </p>
                  <h5 class="alergy-data">{{MedicalHistoryData?.general_symptoms}}</h5>
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
  
        <div *ngIf="selected == 'roa'">
  
          <div *ngIf="routeofAdminData?.length == 0" class="text-center empty-holder">
            <p class="no-data">No data Avaliable</p>
          </div>
  
          <table mat-table [dataSource]="routeofAdminData" *ngIf="routeofAdminData" class="widthfluid bordergrey list-table">
  
  
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.log_date | date}} </td>
            </ng-container>
  
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Route Of Administration </th>
              <td mat-cell *matCellDef="let element"> {{element.route_of_administration}} </td>
            </ng-container>
  
            <!-- Weight Column -->
            <ng-container matColumnDef="reason">
              <th mat-header-cell *matHeaderCellDef> Reason for addition/changes </th>
              <td mat-cell *matCellDef="let element"> {{element.reason_for_addition}} </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
  
  
        </div>
  
        <div *ngIf="selected == 'hospitalisation'">
          <div class="medication medication-details" *ngFor="let item of hospitalizationData">
  
            <div class="form-card">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-over" style="width: 120px;">{{item.duration_from | date: 'dd-MMM-yyyy'}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p>Reason for hospitalisation</p>
                  <h5 class="text-capitalize">{{item.reason}}</h5>
                </div>
                <div class="col-md-6">
                  <div class="itmes">
                    <p>
                      Type
                    </p>
                    <h5 class="text-capitalize">{{item.type}}</h5>
                  </div>
                </div>
                <div class="col-md-6">
                  <p>Procedure</p>
                  <h5 class="text-capitalize">{{item.procedures}}</h5>
                </div>
                <div class="col-md-6">
                  <div class="itmes">
                    <p>
                      Next Doctor Visit
                    </p>
                    <h5 class="text-capitalize">{{item.next_doctor_visit | date: 'dd-MMM-yyyy'}}</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="itmes">
                    <p>
                      Details Of Stay
                    </p>
                    <h5>{{item.stay_details}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <ng-template #loadingOrError>
          <app-loadorerror [loadError]="loadingError$ | async" [loadSubject]="loadingSubject$ | async"></app-loadorerror>
        </ng-template>
      </div>
    </mat-card>
  </ng-container>

</div>