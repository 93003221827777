<div *ngIf="noappSelectedUserFound">
  <div class="nouserselected-card">
    <div class="content">
      <img src="assets/img/healthcoach/noresult.svg">
      <span>Hola! Get started by selecting a user.</span>
    </div>
  </div>
</div>

<ng-container *ngIf="!noappSelectedUserFound">

  <div [class]="isFullWidth == 'no' ? 'col-md-12 chatbgsec' : 'col-md-7 chatbgsec'" >
    <div class="chattitlesec">
      <div class="col-md-7">
        <h3 class="customername notranslate">
          <div [class]="onlinestatus == true ? 'onlinegreen' : 'offlinered'"></div>{{userName$ | async}}
          <span style="border: 1px solid orange;padding:5px;" *ngIf="gestationalAge._value !== null && gestationalAge._value !== 'NA'">
            <small>{{gestationalAge | async}}</small>
            <div style="float: right;"><img  *ngIf="gestationalAge._value !== 'Not Pregnant'" style="width: 24px;height: 21px;padding-left: 4px;" src="assets/img/motherhood.png" /></div>
           </span>
        </h3>
        <h5 class="customerlastseen" *ngIf="showTyper == true">Typing...</h5>
        <h5 class="customerlastseen" *ngIf="showTyper == false">{{onlinestatus == true ? 'Online' : userLastSeen}}</h5>
        <!-- {{onlinestatus == true ? 'Online' : 'Offline'}} -->
      </div>
  
      <div class="col-md-5">
        <!-- <button class="pull-right ml5" (click)="createSupportTicket();" mat-icon-button color="primary">
          <img src="assets/img/healthcoach/vectors/help-desk.svg" style="height: 26px;" alt="">
        </button> -->
  
        <mat-menu #appMenu="matMenu" class="callmenu">
          <button mat-menu-item *ngFor="let item of calllisting" (click)="calltoaction(item?.phone_number)">
            <mat-icon>phone</mat-icon> {{item?.phone_number}}
          </button>
        </mat-menu>
  
        <!-- <button *ngIf="userClientDetails != 'Ferrer'" class="pull-right callbtntopmargin" [matMenuTriggerFor]="appMenu"
          mat-mini-fab color="primary">
          <mat-icon *ngIf="startcallloader == false">phone</mat-icon>
          <svg *ngIf="startcallloader == true" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </button> -->
        <!-- <button class="pull-right supporbtntopmargin"  (click)="downloadReport();" mat-stroked-button color="primary">
                    <mat-progress-spinner *ngIf="isdownloadingreport"
                    color="primary"
                    mode="indeterminate"
                    value="50"
                    diameter="20"
                    class="pull-left"
                    style="margin-top: 6px;margin-right: 5px;">
                </mat-progress-spinner>  {{ isdownloadingreport ? 'Downloading' : 'Download Report' }}
                </button> -->
      </div>
  
      <div class="clearfix"><br /></div>
    </div>
    <div class="chatarea">
      <!--*ngIf="isNormalChat"-->
      <div class="spinner-container" style="height:calc(100vmin - 140px) !important;" *ngIf="allmessageloaded">
        <app-loader style="position: absolute;margin-top: 60%;margin-left: 110px;" showLoader="{{true}}"></app-loader>
      </div>
      <div class="searchchatpanel"><input type="text" [(ngModel)]="term" class="searchchatbox"
          placeholder="Search activity" />
        <span [class]="isGallery == false? 'showimages' : 'showimages selectedimages'" (click)="isGallery = !isGallery">
          <i class="img-holder">
            <img src="assets/img/dls/icons/hcicons/gallery.svg" class="img-responsive" alt="">
          </i>
          <!-- <i class="material-icons">photo</i> -->
        </span>
      </div>
  
      <div class="clearfix"></div>
      <img *ngIf="showChatLoader" style="width: 80%;margin-top: 12vmin;margin-left: 10%;" src="assets/img/Parachute.gif" />

      <div *ngIf="allchats?.length == 0 && !usermigrate && !showChatLoader" style="height: 155px;">
        <p class="text-center no-conversation">No conversations yet</p>
      </div>

      <ng-container *ngIf="allchats.length != 0">
        
        <div *ngIf="!isChat && !isCall"  #scrollToBottom>
          <cdk-virtual-scroll-viewport  [ngClass]="routeUrl === '/healthcoach/hctasks/hctaskmanual' ? 'gkz-viewport' : 'gkz-viewport-new'" class="gkz-viewport" [itemSize]="(100*allchats.length)" [minBufferPx]="500"
            [maxBufferPx]="1000" #virtualScroller>
            <div class="chatloadersection" id="loadingsec" *ngIf="isvisible && !showChatLoader && allchats?.length != 0">
              <button mat-button (click)="getNextPage()">Load More</button>
            </div>
            <br />

            <div *ngFor="let chat of allchats | filter:term; let i = index;">
              <app-renderchats 
                *ngIf="(((!showChatLoader) && (chat?.attachment?.link || !isGallery)) && (checkdeletemsg(chat) && (chat.id != deletedId)) )"
                [recStatus]="recStatusval" [chat]="chat" [usId]="userid" [last]="i == (allchats.length - 1) ? 'yes' : 'no'"
                [totallength]="allchats.length" [lastsetide]="lastID" [chatlastId]="chatlastID" [showGallery]="isGallery"
                [conversationid]="convoid" id="{{chat.id}}" [usName]="userName$ | async"></app-renderchats>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
    
        <div *ngIf="isChat" #scrollToBottom>
          <cdk-virtual-scroll-viewport class="gkz-viewport1" [itemSize]="(100*allchats.length)" [minBufferPx]="500"
            [maxBufferPx]="1000" #virtualScroller>
            <div class="chatloadersection" id="loadingsec" *ngIf="isvisible && !showChatLoader && allchats?.length != 0">
              <button mat-button (click)="getNextPage()">Load More</button>
            </div>
            <br />
            <div *ngFor="let chat of allchats | filter:term; let i = index;">
              <app-renderchats
                *ngIf="(((!showChatLoader) && (chat?.attachment?.link || !isGallery)) && (chat.is_deleted != true) && (chat.id!=deletedId))"
                [recStatus]="recStatusval" [chat]="chat" [usId]="userid" [last]="i == (allchats.length - 1) ? 'yes' : 'no'"
                [totallength]="allchats.length" [lastsetide]="lastID" [chatlastId]="chatlastID" [showGallery]="isGallery"
                [conversationid]="convoid" id="{{chat.id}}" [usName]="userName$ | async"></app-renderchats>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>

        <div *ngIf="isCall" #scrollToBottom>
          <cdk-virtual-scroll-viewport class="gkz-viewport2" [itemSize]="(100*allchats.length)" [minBufferPx]="500"
            [maxBufferPx]="1000" #virtualScroller>
            <div class="chatloadersection" id="loadingsec" *ngIf="isvisible && !showChatLoader && allchats?.length != 0">
              <button mat-button (click)="getNextPage()">Load More</button>
            </div>
            <br />
            <div *ngFor="let chat of allchats | filter:term; let i = index;">
              <app-renderchats
                *ngIf="(((!showChatLoader) && (chat?.attachment?.link || !isGallery)) && (chat.is_deleted != true) && (chat.id!=deletedId))"
                [recStatus]="recStatusval" [chat]="chat" [usId]="userid" [last]="i == (allchats.length - 1) ? 'yes' : 'no'"
                [totallength]="allchats.length" [lastsetide]="lastID" [chatlastId]="chatlastID" [showGallery]="isGallery"
                [conversationid]="convoid" id="{{chat.id}}" [usName]="userName$ | async"></app-renderchats>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </ng-container>

      <div class="replypreview" *ngIf="replydata != null || replydata != undefined">
        <div [innerHtml]="replydata?.body"></div> <span class="closereply" (click)="closereply()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
      <div class="image-preview" *ngIf="imageData">
        <div class="closebtn" (click)="close()"><i class="material-icons">close</i></div>
        <img class="preview" [src]="imageData">
      </div>
      <div class="video-preview" *ngIf="video">
        <div class="closebtn" (click)="close()"><i class="material-icons">close</i></div>
        <video  style="width:100%;height: 100%;" class="videoprev" [src]="videosource" controls controlsList="nodownload noremoteplayback"
          disablepictureinpicture></video>
      </div>
      <div class="audio-preview" *ngIf="audio">
        <div class="closebtn" (click)="close()"><i class="material-icons">close</i></div>
        <audio class="audioprev" [src]="audiosource" controls controlsList="nodownload noremoteplayback"></audio>
      </div>
      <div class="image-preview" *ngIf="isPDF">
        <div class="closebtn" (click)="close()"><i class="material-icons">close</i></div>
        <img class="preview" src="/assets/img/pdficon.jpg">
      </div>
      <div class="deeplink-preview" *ngIf="optiontype == 'deeplink' && this.optionvalue != 'Coach Mark'" (keyup.enter)="sendMessage()">
        <div class="closebtn" (click)="close()"><i class="material-icons">close</i></div>
        <div class="deeplink" (keyup.enter)="sendMessage()">
          <p (keyup.enter)="sendMessage()">{{optionvalue}}</p>
          <p class="text-ccapitalize" (keyup.enter)="sendMessage()">{{typeValue}}</p>
          <span (keyup.enter)="sendMessage()">
            <mat-icon>link</mat-icon> {{optionlink}}
          </span>
        </div>
      </div>
      <div class="paymentlink-preview" *ngIf="optiontype == 'payment'">
        <div class="closebtn" (click)="close()"><i class="material-icons">close</i></div>
        <div class="paymentlink">
          <p>Payment Link</p>
          <span>
            <mat-icon>link</mat-icon> {{optionlink}}
          </span>
        </div>
      </div>
  
    </div>
  
    <div class="spinner-holder" *ngIf="isLoading">
      <mat-progress-spinner class="spinner-margin" color="accent" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>
  
  
    <!-- <form *ngIf="(connectionchecker) && (diffDays < 0 ? false : true)" [formGroup]="chatForm" (ngSubmit)="sendMessage()" (keydown)="keyDownFunction($event)" style="width: 100%;display: block;"> -->
    <div class="chatsendbox-wrapper" *ngIf="isNormalChat && !noappSelectedUserFound">
      <form [formGroup]="chatForm" *ngIf="!isexpired" (keydown)="keyDownFunction($event)"
        style="width: 100%;display: block;" [class]="isFullWidth == 'no' ? 'chatsendboxfull' : ''" autocomplete="off">
        <div class="chatsendbox" appearance="outline">
          <textarea (keyup.enter)="sendMessage()" rows='1' class="form-control" placeholder="Type your message here"
            (change)="TextChange($event)" id="txtmessage" formControlName="messagetext" novalidate></textarea>
        </div>
  
        <div class="row d-flex align-items-center justify-content-spacebetween">
          <div class="col-md-10">
            <div class="holder">
              <!-- <mat-select placeholder="Wellthy" class="sortcommonwhite" style="width: 40%;">
                  <mat-option value="Freemium"> <img src="assets/img/dls/icons/hcicons/gallery.svg" alt="">Wellthy
                  </mat-option>
                  <mat-option value="Regular"><img src="assets/img/dls/icons/hcicons/gallery.svg" alt="">Regular
                  </mat-option>
                </mat-select> -->
  
              <button mat-icon-button [matMenuTriggerFor]="appMenup" (keyup.enter)="sendMessage()">
                <img src="assets/img/dls/icons/hcicons/Attachment.svg" alt="">
              </button>
              <button mat-icon-button style="opacity: 0.4;" tooltip="disabled" (keyup.enter)="sendMessage()">
                <img src="assets/img/dls/icons/hcicons/Smiley.svg" alt="">
              </button>
              <button mat-icon-button [matMenuTriggerFor]="appMenu" style="opacity: 0.4;" tooltip="disabled"
                (keyup.enter)="sendMessage()">
                <img src="assets/img/dls/icons/hcicons/Call.svg" alt="">
              </button>
              <button mat-icon-button disabled>
                <img src="assets/img/dls/icons/hcicons/Chat_Links.svg" alt="" style="opacity: 0.4;" tooltip="disabled"
                  (keyup.enter)="sendMessage()">
              </button>
            </div>
  
          </div>
          <div class="col-2">
            <button type="submit" (click)="sendMessage()" [disabled]="urlDetect" mat-icon-button>
              <mat-icon>send</mat-icon>
            </button>
          </div>
          <!--[disabled]="urlDetect"-->
        </div>
        <mat-menu #appMenup="matMenu">
          <button mat-menu-item class="forupload" (click)="trigger('image')" (keyup.enter)="sendMessage()">
            <mat-icon>image</mat-icon> Image
          </button>
          <button mat-menu-item class="forupload" (click)="trigger('video')" (keyup.enter)="sendMessage()">
            <mat-icon>videocam</mat-icon> Video
          </button>
          <button mat-menu-item class="forupload" (click)="trigger('audio')" (keyup.enter)="sendMessage()">
            <mat-icon>audiotrack</mat-icon> Audio
          </button>
          <button mat-menu-item class="forupload" (click)="trigger('other')" (keyup.enter)="sendMessage()">
            <mat-icon>picture_as_pdf</mat-icon> Other
          </button>
          <button mat-menu-item class="forupload" (click)="trigger('deeplink')" (keyup.enter)="sendMessage()">
            <mat-icon>link</mat-icon> Deeplink
          </button>
          <button mat-menu-item class="forupload" *ngIf="userClientDetails != 'Ferrer'" (click)="trigger('payment')"
            (keyup.enter)="sendMessage()">
            <mat-icon>money</mat-icon> Payment
          </button>
        </mat-menu>
        <input #inputFile id="file-upload" type="file" (change)="previewFile($event)" class="fileinputcss" />
        <!-- <input #inputFile2 id="file-video" type="file" (change)="previewFile($event)" class="fileinputcss"
                accept="video/*" />
                <input #inputFile3 id="file-audio" type="file" (change)="previewFile($event)" class="fileinputcss"
                accept="audio/*"/>
                <input #inputFile4 id="file-other" type="file" (change)="previewFile($event)" class="fileinputcss"
                accept="application/pdf"/> -->
      </form>
    </div>
  </div>

  <div class="chatsendbox-wrapper" *ngIf="isCall">
    <form [formGroup]="chatForm" *ngIf="!isexpired" style="width: 100%;display: block;margin-top:0px;"
      [class]="isFullWidth == 'no' ? 'chatsendboxfull' : ''" autocomplete="off">
      <!-- <div class="chatsendbox" appearance="outline" style="margin-top:0px;margin-bottom: 0%;">
        <textarea rows='1' class="form-control" placeholder="Make call and fill all details to complete the task"
          (change)="onChangeEvent($event)" id="txtmessage" formControlName="messagetext" readonly novalidate></textarea>
      </div> -->
      <input type="text" style="outline: none;border: aliceblue;inline-size: inherit;"
        placeholder="Make call and fill all details to complete the task" readonly>
      <hr style="margin-top: 8px; margin-bottom: -7px;border: 0;border-top: 1px solid #eee;">
      <div class="row" style="margin-top:0px;">
        <div class="col-md-5">
          <div class="title-area" style="margin-left: 0%;">
            <h6 style="font-size: 14px;font-weight: bold;font-family: inherit;">Call duration</h6>
            <input type="text" placeholder="  hr" maxlength="2" size="1" [(ngModel)]="callDurationhr"
              [ngModelOptions]="{standalone: true}" style="text-align: center;border-color:var(--primary-orange);  border-radius: 9px;font-family: inherit;
              font-size: inherit;
              line-height: inherit;
              border: 1px solid var(--primary-orange);">:
            <input type="text" placeholder="  min" [(ngModel)]="callDurationmin" [ngModelOptions]="{standalone: true}"
              maxlength="2" size="1" style="text-align: center;border-color:var(--primary-orange);  border-radius: 9px; font-family: inherit;
              font-size: inherit;
              line-height: inherit;
              border: 1px solid var(--primary-orange);">:
            <input type="text" [(ngModel)]="callDurationsec" [ngModelOptions]="{standalone: true}" placeholder=" sec"
              maxlength="2" size="1" style="text-align: center;border-color:var(--primary-orange);  border-radius: 9px;font-family: inherit;
              font-size: inherit;
              line-height: inherit;
              border: 1px solid var(--primary-orange);">
          </div>
        </div>
        <div class="col-md-4">
          <div class="title-area" style="margin-left: 0%;">
            <h6 style="font-size: 14px;font-weight: bold;font-family: inherit;">Add screenshot</h6>
  
            <!-- <button mat-icon-button class="plusrectangletransparent mb-20" (click)="trigger('image')" >
              <mat-icon>add</mat-icon>
  
            </button> -->
            <button mat-icon-button class="plusrectangletransparent mb-20" (click)="trigger('image')"
              *ngIf="!selectedFile">
              <mat-icon>add</mat-icon>
            </button>
  
            <input #inputFile id="file-upload" type="file" (change)="previewFile($event)" class="fileinputcss" />
  
            <div class="chatsendbox" appearance="outline" *ngIf="selectedFile" style="position: relative;"
              [(ngModel)]="selectedImage" [ngModelOptions]="{standalone: true}">
              <textarea rows='1' style="padding: 5px" class="form-control" id="txtmessage" readonly
                novalidate>{{selectedFile}} </textarea>
              <!-- <span class='fa fa-edit' style="position: absolute;
               display: inline-block;
               top: 7px;             
               right:5px;
               color: var(--primary-orange);;
               font-size: 23px; " (click)="trigger('image')"></span> -->
              &nbsp; <img src="assets/img/dls/admin-table/Ico_Edit_HC.svg" style="position: absolute;
               display: inline-block;
               top: 3px;             
               right:5px;            
              " (click)="trigger('image')" alt="Wellthy">
            </div>
  
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 zeromarginleft" style="margin-top:5px;">
          <button mat-flat-button color="primary" class="centerbtn" (click)="sendCallMessage()"
            [disabled]="!callDurationhr || !callDurationmin ||  !callDurationsec ">Done</button>
          <!--|| !selectedFile-->
        </div>
      </div>
    </form>
  </div>

  <!-- <div style="margin-top: 20px;"></div> -->
  <div class="chatsendbox-wrapper" *ngIf="isChat">
    <form [formGroup]="chatForm" *ngIf="!isexpired" style="width: 100%;display: block;"
      [class]="isFullWidth == 'no' ? 'chatsendboxfull' : ''" autocomplete="off">

      <div class="row" style="margin-top:0px;">
        <div class="col-md-10">
          <h4 style="color: black;"><b>Template</b></h4>
        </div>
      </div>

      <div style="margin-top:0px;color: black; ">
        <!-- <div class="chatsendbox" appearance="outline">
          <textarea rows='1' class="form-control" placeholder="Type your message here" id="testmessage" name="testmessage"
            formControlName="messagetext" novalidate></textarea>
        </div> -->

        <form [formGroup]="thirdFormGroup" autocomplete="off">
          <ng-template matStepLabel>Configure template</ng-template>
          <div class="row">
            <div class="col-md-12">
              <div class="language-tab ">
                <div>
                  <mat-menu #menu="matMenu" style="margin-left: 0%;">
                    <button *ngFor="let data of templateData" mat-menu-item>{{data.id}}</button>
                  </mat-menu>

                  <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="changeindex($event)" style="margin-left: 0%;">
                    <mat-tab *ngFor="let data of templateData;" [label]="data.id" style="margin-left: 0%;" [disabled]="!checklang(data.id)">
                      <ng-template mat-tab-label>
                        <div>
                          {{getLanguageName(data.id)}}
                        </div>
                      </ng-template>
                  
                      <textarea style="resize:none" class="tab-text-area" formControlName="description" readonly>{{data.description}}</textarea>
                    </mat-tab>

                  </mat-tab-group>
                </div>
              </div>

            </div>
          </div>
        </form>

      </div>
      <br />

      <div class="row">
        <div class="col-md-2 zeromarginleft">
          <button mat-flat-button color="primary" [disabled]="selectedUserID.length==0" class="centerbtn"
            [disabled]="taskcompleteflag" (click)="sentCustomChat()">Send chat</button>
        </div>
      </div>
    </form>
  </div>

</ng-container>
