<mat-drawer-container class="sidenav-container" autosize>
    <app-contentleftnav></app-contentleftnav>
    <div class="sidenav-content">
        <app-breadcrumb route="content/careyhome/careyedit"></app-breadcrumb>
        <div class="col-md-12">
            <h3 class="commontitleprimary my-20">
                <app-backbutton></app-backbutton>
                {{newitem ? 'New' : 'Update'}} Carey Card
            </h3>
        </div>
        <div class="col-md-12">
            <div class="col-md-8">
                <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
                    <div class="row">
                        <div class="col-md-12 leftformsection">
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Carey Card Name</mat-label>
                                <input matInput placeholder="Enter the carey card name" formControlName="title"
                                    required>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Description</mat-label>
                                <input matInput placeholder="Enter the description" formControlName="detailed_text">
                            </mat-form-field>

                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Complexity Level</mat-label>
                                        <mat-select formControlName="complexity_level" required>
                                            <mat-option [value]="complexityenum[0]">{{complexityenum[0]}}</mat-option>
                                            <mat-option [value]="complexityenum[1]">{{complexityenum[1]}}</mat-option>
                                            <mat-option [value]="complexityenum[2]">{{complexityenum[2]}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                <mat-label>Select Image</mat-label>
                                <input matInput placeholder="Select Image" formControlName="image_media" readonly>
                                <button type="button" matSuffix mat-flat-button color="primary"
                                    (click)="openmediabox('imagemedia')" style="width: 100px;bottom: 7px;">{{allmedia ?
                                    'Edit' : 'Choose'}}</button>
                            </mat-form-field>
                            <div class="col-md-12 zeropadleft">
                                <p class="colorheader">Select the color *</p>
                                <div class="row colorholder">
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#479020"
                                            id="color1" />
                                        <label for="color1">
                                            <div class="rectangle" [style.background]="'#479020'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#691dd6"
                                            id="color2" />
                                        <label for="color2">
                                            <div class="rectangle" [style.background]="'#691dd6'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#cb0054"
                                            id="color3" />
                                        <label for="color3">
                                            <div class="rectangle" [style.background]="'#cb0054'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#0053d5"
                                            id="color4" />
                                        <label for="color4">
                                            <div class="rectangle" [style.background]="'#0053d5'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#008e9a"
                                            id="color5" />
                                        <label for="color5">
                                            <div class="rectangle" [style.background]="'#008e9a'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#cb0083"
                                            id="color6" />
                                        <label for="color6">
                                            <div class="rectangle" [style.background]="'#cb0083'"></div>
                                        </label>
                                    </div>
                                    <div class="radio-holder">
                                        <input type="radio" name="color" formControlName="color" value="#ff7400"
                                            id="color7" />
                                        <label for="color7">
                                            <div class="rectangle" [style.background]="'#ff7400'"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Type</mat-label>
                                        <mat-select formControlName="type" (selectionChange)="contentType($event.value)"
                                            required>
                                            <mat-option value="content">
                                                Content
                                            </mat-option>
                                            <mat-option value="intent">
                                                Intent
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="type=='content'">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select SubType</mat-label>
                                        <mat-select formControlName="sub_type" (selectionChange)="subType($event.value)"
                                            required>
                                            <mat-option value="trigger_card">
                                                Trigger
                                            </mat-option>
                                            <mat-option value="symptom_card">
                                                Symptom
                                            </mat-option>
                                            <mat-option value="peak_flow">
                                                Peak Flow
                                            </mat-option>
                                            <mat-option value="none">
                                                None
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="type=='content'">
                                <div class="row">
                                    <mat-form-field appearance="outline" class="full-width-formfield">
                                        <mat-label>Select Text Count</mat-label>
                                        <mat-select formControlName="text_option_count"
                                            (selectionChange)="changeOptions($event.value)" required>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3" *ngIf="dataForm?.controls?.sub_type?.value == 'none'">
                                                3</mat-option>
                                            <mat-option value="4" *ngIf="dataForm?.controls?.sub_type?.value == 'none'">
                                                4</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div [ngClass]="!(optioncount) ? 'hidefield ' : ''" class="retrysection">
                                    <div>
                                        <div formArrayName="options">
                                            <div *ngFor="let txtoptn of dataForm.controls.options.controls; let j=index;"
                                                [formGroupName]="j" class="boxthekeyvalue">
                                                <h5>Text options {{j+1}}</h5>
                                                <mat-form-field appearance="outline"
                                                    class="full-width-formfield featureimage">
                                                    <mat-label>Enter the text options</mat-label>
                                                    <input matInput formControlName="txtoptn" [required]="optioncount">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix">
                                        <hr />
                                    </div>
                                    <div>
                                        <div formArrayName="options_media">
                                            <div *ngFor="let imgoptn of dataForm.controls.options_media.controls; let j=index;"
                                                [formGroupName]="j" class="boxthekeyvalue">
                                                <h5>Select Media {{j+1}}</h5>
                                                <mat-form-field appearance="outline"
                                                    class="full-width-formfield featureimage">
                                                    <mat-label>Select Image</mat-label>
                                                    <input matInput (click)="openmediabox('optionmedia',j)"
                                                        formControlName="imgoptn" readonly>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div  class="retrysection col-md-12">
                                <div>
                                    <h5>Audio options</h5>
                                    <div formArrayName="options_media">
                                        <div *ngFor="let imgoptn of dataForm.controls.options_media.controls; let j=index;"
                                            [formGroupName]="j" class="boxthekeyvalue">
                                            <mat-form-field appearance="outline" class="full-width-formfield featureimage">
                                                <mat-label>Select Image</mat-label>
                                                <input matInput (click)="openmediabox()" formControlName="imgoptn" readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div> -->


                            <div class="col-md-12" [ngClass]="!(type=='intent') ? 'hidefield' : 'intent-retry'">
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Intent Button Text</mat-label>
                                    <input matInput formControlName="intent_button_text" [required]="type=='intent'">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width-formfield">
                                    <mat-label>Select Intents</mat-label>
                                    <mat-select formControlName="intent" [required]="type=='intent'">
                                        <mat-option class="camelcased" *ngFor="let intent of allintents"
                                            [value]="intent.id">
                                            {{intent.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <mat-form-field appearance="outline" class="full-width-formfield">
                                <mat-label>Select Tags</mat-label>
                                <mat-select formControlName="tags" multiple required #singleSelectTag>
                                    <mat-option>
                                        <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                                            [placeholderLabel]="'Search Item'"
                                            [noEntriesFoundLabel]="'No matching records found'"
                                            [formControl]="searchmattag" ngModel (ngModelChange)="modelChangedTags()"
                                            ngDefaultControl>
                                            <div class="mat-select-search-custom-header-content notranslate">
                                                <i class="material-icons">
                                                    search
                                                </i>
                                            </div>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option class="camelcased select-tags-active"
                                        *ngFor="let tag of filteredTags | async" [value]="tag.id">
                                        {{tag.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col-md-12 leftpad40">
                            <button mat-flat-button color="primary" class="centerbtn mt-15"
                                [disabled]="!dataForm.valid">{{newitem ? 'New' : 'Update'}} Carey Card</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4 iphone-holder-height">
                <div class="iphonex">
                    <div class="adios" id="iphonebox">
                        <div class="intent-layout" *ngIf="!(dataForm?.value.title == '')">
                            <div class="content-holder">
                                <div class="row">
                                    <div class="col-md-8" style="padding-right: 0;">
                                        <div class="iphonetitle" [style.color]='dataForm?.value?.color'>
                                            {{dataForm?.value?.title}}
                                        </div>
                                        <div class="iphonesubtitle">
                                            {{dataForm?.value?.detailed_text}}
                                        </div>
                                    </div>
                                    <div class="col-md-4" style="padding-right: 0;">
                                        <img *ngIf="allmedia" [src]="s3url + allmedia" class="img-responsive" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="type == 'content'" class="flex-container">
                                <div *ngFor="let txtoptn of dataForm.controls.options.controls; let j=index;"
                                    class="boxthekeyvalue flex-item">
                                    <div *ngIf="txtoptn?.value?.txtoptn">
                                        <button mat-flat-button color="primary" class="content-btn"
                                            [style.background]='dataForm?.value?.color'>
                                            {{txtoptn?.value?.txtoptn}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="type == 'intent'" class="flex-container">
                                <div class="intent-btnholder boxthekeyvalue flex-item">
                                    <div class="default-btn">
                                        <button mat-flat-button color="primary" class="intent-btn"
                                            [ngStyle]="setBtnStyles()">
                                            Later
                                        </button>
                                    </div>
                                    <button mat-flat-button class="intent-btn"
                                        *ngIf="dataForm?.value?.intent_button_text"
                                        [style.background]='dataForm?.value?.color'>{{dataForm?.value?.intent_button_text}}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-drawer-container>