<mat-drawer-container class="sidenav-container" autosize>

    <app-docleftnav></app-docleftnav>

    <div class="sidenav-content" [hidden]="true">
        <div class="col-md-12 topalertbar">
            <div class="col-md-12">
                <div class="row">
                    <h3 class="alerttitle">Activate Patient</h3>
                    <div class="show-notification-count">Add Phone number and select activation mode to activate wellthy
                        app</div>
                </div>
            </div>
            <div class="clearfix">
            </div>
            <div class="margin-spaces"></div>
            <div class="col-md-12 formitemcss">
            </div>
            <ng-template #pageLoadingLoader>
                <app-loader showLoader="true"></app-loader>
            </ng-template>
        </div>
    </div>
    <div class="sidenav-content" [hidden]="true">
        <div id="content">
            <div class="col-md-12 actiobtncss">
                <div class="col-md-6">
                    <button mat-icon-button class="commonbackbtn qrbckbtn" [routerLink]="['/content/chapter/']"><i
                            class="material-icons">keyboard_arrow_left</i></button>
                </div>
                <div class="col-md-6 pr-0">
                    <button mat-icon-button class="commonbackbtn pull-right qrsharebtn"><i
                            class="material-icons">share</i></button>

                    <button mat-icon-button class="commonbackbtn pull-right qrdownloadbtn"><i
                            class="material-icons">cloud_download</i></button>
                </div>
            </div>
            <div class="container-fluid showdownload">
                <!-- <div class="bgsvg">
                    <div class="bgsvg-inner">
                        <p class="stepsheading">STEP 1</p>
                        <p class="stepsdescription">Download the Wellthy Care App</p>
                        <p class="stepsheading stepsheadingmargin">STEP 2</p>
                        <p class="stepsdescription">Sign in with <b>+91-8107019560</b></p>
                        <p class="stepsheading stepsheadingmargin">STEP 3</p>
                        <p class="stepsdescription">Scan the QR code below</p>
                        <div class="overlay"></div>
                        <button class="downloadbutton" mat-stroked-button color="primary"
                            (click)="downloadQR()">Download</button>
                    </div>
                </div> -->
                <div class="qrbox">
                    <img src="assets/img/wellthy_logo_2.png" class="wellthylogo" />
                    <img [src]="imgurl" style="margin:0 auto;display:block;" />
                </div>
            </div>
        </div>
    </div>
    <div class="sidenav-content">
        <div id="content">
            <div class="col-md-12 actiobtncss">
                <div class="col-md-6">
                    <button mat-icon-button class="commonbackbtn qrbckbtn" [routerLink]="['/content/chapter/']"><i
                            class="material-icons">keyboard_arrow_left</i></button>
                </div>
                <div class="col-md-6 pl-0">
                    <button mat-icon-button class="commonbackbtn pull-right qrsharebtn"><i
                            class="material-icons">share</i></button>
                </div>
            </div>
            <div class="container-fluid showdownload">
                <div class="bgsvgp">
                    <div class="bgsvg-inner">
                        <p class="stepsheading">STEP 1</p>
                        <p class="stepsdescription">Share the below link with patient (<b>+91-8107019560</b>).</p>
                        <p class="stepsheading stepsheadingmargin">STEP 2</p>
                        <p class="stepsdescription">On click of below link it will download and active the wellthy app
                        </p>
                        <!--<div class="overlay"></div>
                        <button class="downloadbutton" mat-stroked-button color="primary" (click)="downloadQR()">Download</button>-->
                    </div>
                </div>
                <div class="qrboxp">
                    <a href="#">
                        https://wellthy.io/resources/icons/?icon=cloud_download&style=baseline
                    </a>
                </div>
                <div class="copyurl">
                    <button mat-flat-button class="commonbackbtn pull-right qrdownloadbtn"><i
                            class="material-icons">cloud_download</i> Copy Link</button>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>