<mat-drawer-container class="sidenav-container" autosize>

    <app-hcleftnav></app-hcleftnav>

    <div class="hc-content-wrapper">
        <app-breadcrumb route="healthcoach/policies"></app-breadcrumb>
        
        <div class="container-fluid policies-wrapper">
            <div class="row">
                <div class="col-md-12">

                    <form>
                        <div class="form-group has-feedback has-search has-clear">
                            <input [(ngModel)]="policyNumber" type="text" class="form-control" name="policyNumber" placeholder="Enter Policy Number/Customer ID"
                                (keyup.enter)="callPoliciesSearch()">
                            <span class="clear-btn" *ngIf="policyNumber.length > 0" (click)="clearSearch()"></span>
                        </div>
                    </form>

                </div>
            </div>

            <div class="row">
                <div class="col-md-12 policy-cont-height">

                    <!-- No policy found -->
                    <ng-container *ngIf="noPolicyFound">    
                        <div class="error-container">
                            <div>
                                <mat-icon>error</mat-icon>
                            </div>
                            <p>Policy Number/Customer ID not found</p>
                        </div>
                    </ng-container>

                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let policies of policyData">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ policies[0].policy_number}}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
        
                          <div class="customers" *ngFor="let customerDetails of policies | policyIsDeletedFilter">
                            <div class="customer-details">
                                Name: {{ customerDetails?.customer_details?.name }} <br />
                                Customer ID: {{customerDetails.customer_id}} <br />
        
                                <ng-container *ngIf="customerDetails?.is_phone_verified">
                                    Mobile: {{ customerDetails.phone}}
                                </ng-container>
                            </div>
        
                            <div class="actions-wrapper">
        
                                <ng-container *ngIf="customerDetails.is_proposer">
                                    <div class="chip">
                                        Proposer
                                    </div>
                                </ng-container>
        
                                <ng-container *ngIf="customerDetails.is_phone_verified">
                                    <mat-checkbox [checked]="true" name="verified" [disabled]="true"></mat-checkbox>
                                </ng-container>
        
                                <ng-container *ngIf="!customerDetails.is_phone_verified">
                                    <span class="verify-text" (click)="verifyMobileNumber(customerDetails)">Verify</span>
                                </ng-container>
                            </div>
                          </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

        </div>

    </div>
    
  
</mat-drawer-container>