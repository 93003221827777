<!-- /*==========================================
Title: Activation Code 
Author: Mandeep singh
Date:   23 Apr 2020
Last Change : validations
===========================================*/ -->
<!-- <mat-drawer-container class="sidenav-container" autosize>
  <app-adminleftnav></app-adminleftnav> -->
  <!-- <div class="sidenav-content">
      <div class="breadcrumbholder">
          <app-breadcrumb route="admin/codegeneratehome/codegenerate"></app-breadcrumb>
      </div> -->
      <div class="col-md-12">
          <h3 class="commontitleprimary">
              <!-- <app-backbutton></app-backbutton> -->
              {{title}}
              <div class="pull-right">
                <img src="assets/img/dls/icons/cross.svg" (click)="closeModal()" class="img-responsive close-modal"
                    alt="">
            </div>
          </h3>
      </div>
      <div class="col-md-12">
          <form [formGroup]="dataForm" (ngSubmit)="dataObject()" validate>
              <div class="col-md-12 leftformsection">
                  <div class="row">
                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Request Name</mat-label>
                              <input matInput placeholder="Request Name" formControlName="request_name" required>
                          </mat-form-field>
                      </div>

                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Total code to generate</mat-label>
                              <input type="number" min="1" matInput placeholder="Total codes"
                                  formControlName="code_count" required>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Select Therapy</mat-label>
                              <mat-select formControlName="therapy_data_fk" required #singleSelectTherapy required>
                                  <mat-option>
                                      <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                          [placeholderLabel]="'Search Item'"
                                          [noEntriesFoundLabel]="'No matching records found'"
                                          [formControl]="searchmattherapy" ngModel
                                          (ngModelChange)="modelChangedTherapy()" ngDefaultControl>
                                          <div class="mat-select-search-custom-header-content">
                                              <i class="material-icons">
                                                  search
                                              </i></div>
                                      </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let therapy of filteredTherapy | async" [value]="therapy.id">
                                      {{therapy.title}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Has healthcoach access?</mat-label>
                              <mat-select (selectionChange)="HCAccess($event.value);coachaccess = $event.value"
                                  formControlName="has_coach_access" required>
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft" *ngIf="coachaccess">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Select Healthcoach</mat-label>
                              <mat-select formControlName="coach_ids" multiple #singleSelectHC
                                  [required]="coachaccess">
                                  <mat-option>
                                      <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                          [placeholderLabel]="'Search Item'"
                                          [noEntriesFoundLabel]="'No matching records found'"
                                          [formControl]="searchmathc" ngModel (ngModelChange)="modelChangedHC()"
                                          ngDefaultControl>
                                          <div class="mat-select-search-custom-header-content">
                                              <i class="material-icons">
                                                  search
                                              </i></div>
                                      </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let hc of filteredHC | async" [value]="hc.id">
                                      {{hc.name}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>

                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Has Doctor access?</mat-label>
                              <mat-select (selectionChange)="doctorAccess($event.value);docaccess = $event.value"
                                  formControlName="has_doctor_access" required>
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>

                      <div class="col-md-12 zeropadleft" *ngIf="docaccess">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Select Doctor</mat-label>
                              <mat-select formControlName="doctor_data" single #singleSelectDOC
                                  [required]="docaccess">
                                  <mat-option>
                                      <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                          [placeholderLabel]="'Search Item'"
                                          [noEntriesFoundLabel]="'No matching records found'"
                                          [formControl]="searchmatdoc" ngModel (ngModelChange)="modelChangedDOC()"
                                          ngDefaultControl>
                                          <div class="mat-select-search-custom-header-content">
                                              <i class="material-icons">
                                                  search
                                              </i></div>
                                      </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let hc of filteredDoc | async" [value]="hc.id">
                                      {{hc.name}} - {{hc.code}}

                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>


                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Has client?</mat-label>
                              <mat-select (selectionChange)="clientAccess($event.value);clientaccess = $event.value"
                                  formControlName="has_client" required>
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft" *ngIf="clientaccess">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Select Client</mat-label>
                              <mat-select formControlName="client_data" #singleSelectClient [required]="clientaccess" (selectionChange)="checkClient($event.value);">
                                  <mat-option>
                                      <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                          [placeholderLabel]="'Search Item'"
                                          [noEntriesFoundLabel]="'No matching records found'"
                                          [formControl]="searchmatclient" ngModel
                                          (ngModelChange)="modelChangedClient()" ngDefaultControl>
                                          <div class="mat-select-search-custom-header-content">
                                              <i class="material-icons">
                                                  search
                                              </i></div>
                                      </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let client of filteredClient | async" [value]="client.id">
                                      {{client.title}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Has Campaign?</mat-label>
                              <mat-select
                                  (selectionChange)="campaignAccess($event.value);campaignaccess = $event.value"
                                  formControlName="has_campaign" required>
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft" *ngIf="campaignaccess">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Select Campaign</mat-label>
                              <mat-select formControlName="campaign_data" #singleSelectClient
                                  [required]="campaignaccess">
                                  <mat-option>
                                      <ngx-mat-select-search class="tagmat" [clearSearchInput]="true"
                                          [placeholderLabel]="'Search Item'"
                                          [noEntriesFoundLabel]="'No matching records found'"
                                          [formControl]="searchmatcampaign" ngModel
                                          (ngModelChange)="modelChangedCampaign()" ngDefaultControl>
                                          <div class="mat-select-search-custom-header-content">
                                              <i class="material-icons">
                                                  search
                                              </i></div>
                                      </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let campaign of filteredCampaign | async" [value]="campaign.id">
                                      {{campaign.title}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>


                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                              <mat-label>Test Code</mat-label>
                              <mat-select formControlName="is_test_code" required>
                                  <mat-option value="true">Yes</mat-option>
                                  <mat-option value="false">No</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Pump access</mat-label>
                            <mat-select 
                              formControlName="pump_access" required>
                              <mat-option [value]="1">No access</mat-option>
                              <mat-option [value]="2">Add and view</mat-option>
                              <mat-option [value]="3">View</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" >
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Medication Access</mat-label>
                            <mat-select 
                              formControlName="medication_access" required>
                              <mat-option [value]="1">No access</mat-option>
                              <mat-option [value]="2">Add and View</mat-option>
                              <mat-option [value]="3">View</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Lab Report Access</mat-label>
                            <mat-select 
                              formControlName="lab_report_access" required>
                              <mat-option [value]="1">No access</mat-option>
                              <mat-option [value]="2">Add and View</mat-option>
                              <mat-option [value]="3">View</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" >
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Show history</mat-label>
                            <mat-select 
                              formControlName="show_history" required>
                              <mat-option [value]="true">Yes</mat-option>
                              <mat-option [value]="false">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Show Utilities</mat-label>
                            <mat-select 
                              formControlName="show_utilities" required>
                              <mat-option [value]="true">Yes</mat-option>
                              <mat-option [value]="false">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Show targets</mat-label>
                            <mat-select 
                              formControlName="show_targets" required>
                              <mat-option [value]="true">Yes</mat-option>
                              <mat-option [value]="false">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Use Geolocation</mat-label>
                            <mat-select 
                              formControlName="use_geolocation" required>
                              <mat-option [value]="true">Yes</mat-option>
                              <mat-option [value]="false">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Show Manufacturer</mat-label>
                            <mat-select 
                              formControlName="show_manufacturer" required>
                              <mat-option [value]="true">Yes</mat-option>
                              <mat-option [value]="false">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" >
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Default Weight</mat-label>
                            <mat-select 
                              formControlName="default_weight_unit" required>
                              <mat-option value="kg">kg</mat-option>
                              <mat-option value="lbs">lbs</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft" >
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Default Height</mat-label>
                            <mat-select 
                              formControlName="default_height_unit" required>
                              <mat-option value ="ft">ft</mat-option>
                              <mat-option value ="cm">cm</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div> 
                        <div class="col-md-12"  *ngIf="showFerrerFields">
                          <div class="gender-wrapper">
                            <mat-radio-group aria-label="Select an option" formControlName="remodulin" required>
                              <mat-radio-button value="is_remodulin">Remodulin</mat-radio-button>
                              <mat-radio-button value="is_nonremodulin">Non Remodulin</mat-radio-button>
                              <mat-radio-button value="none">None</mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Chat Access</mat-label>
                            <mat-select formControlName="chat_flags" multiple #singleSelectHC>
                              <mat-option *ngFor="let list of showChatList" [value]="list.code">
                                {{list.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Diary Access</mat-label>
                            <mat-select formControlName="dairy_flags" multiple #singleSelectHC>
                              <mat-option *ngFor="let list of showDairyList" [value]="list.code">
                                {{list.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Select Profile Access</mat-label>
                            <mat-select formControlName="pro_flags" multiple #singleSelectHC>
                              <mat-option *ngFor="let list of showProfileList" [value]="list.code">
                                {{list.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <!-- <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Current Flags</mat-label>
                            <mat-select formControlName="profile_flags" multiple #singleSelectHC>
                              <mat-option *ngFor="let list of showAppList" [value]="list.code">
                                {{list.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div> -->
                        <div class="col-md-12 zeropadleft" *ngIf="!showFerrerFields">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Health Detail</mat-label>
                            <mat-select formControlName="health_flags" multiple #singleSelectHC>
                              <mat-option *ngFor="let list of showHealthList" [value]="list.code">
                                {{list.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        
                        <div class="col-md-12 zeropadleft">
                          <mat-form-field appearance="outline" class="full-width-formfield">
                            <mat-label>Prescription Count</mat-label>
                            <input matInput type="number" placeholder="Add Prescription Count" formControlName="max_upload_limit">
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedtriggers" formControlName="is_paid">
                              Is Paid
                          </mat-checkbox>
                          <br />
                          <br />
                      </div>
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedsupportphone" formControlName="show_support_phone_number">
                              Show Support Phone Number
                          </mat-checkbox>
                          <br />
                          <br />
                      </div>
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedsupportemail" formControlName="show_support_email_id">
                              Show Support Email
                          </mat-checkbox>
                          <br />
                          <br />
                      </div>
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedquestinnair" formControlName="show_questionnaire">
                              Show Questionnaire
                          </mat-checkbox>
                          <br />
                          <br />
                      </div>
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedtriggers" formControlName="show_triggers">
                              Show Trigger
                          </mat-checkbox>
                          <br />
                          <br />
                      </div>
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedAQI" formControlName="show_aqi">
                              Show AQI
                          </mat-checkbox>
                          <br />
                          <br />
                      </div>
                      <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedBMI" formControlName="show_bmi">
                            Show BMI
                          </mat-checkbox>
                          <br />
                          <br />
                        </div>
                        <div class="col-md-12 zeropadleft fbcheckbox checkbox-margin">
                          <mat-checkbox value="checkedSMS" formControlName="show_medsurvey">
                            Show Medication Survey
                          </mat-checkbox>
                          <br />
                          <br />
                        </div>
                  </div>


                  <div class="row">
                      <div class="col-md-12 zeropadleft zeromarginleft">
                          <button mat-flat-button color="primary" class="centerbtn mt-15"
                              [disabled]="!dataForm.valid">Generate Code</button>
                      </div>
                  </div>
              </div>
          </form>
      </div>
  <!-- </div> -->
<!-- </mat-drawer-container> -->
