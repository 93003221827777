import { CreateHospitalComponent } from './create-hospital/create-hospital.component';
import { HospitalListComponent } from './hospital-list/hospital-list.component';
import { ClientDoctorAdminComponent } from './client-doctor-admin/client-doctor-admin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from './../../shared/guards';
import { DoctoruserComponent } from './doctor.component';
import { EditdoctorComponent } from './editdoctor/editdoctor.component';
import { ChangePasswordHistoryComponent } from './change-password-history/change-password-history.component';
//import { GeneralqrbottomsheetComponent } from './generalqrbottomsheet/generalqrbottomsheet.component';
//import { GenericDeeplinkComponent } from './genericdeeplink/genericdeeplink.component';
//import { QrbottomsheetComponent } from './qrbottomsheet/qrbottomsheet.component';

const routes: Routes = [
  { path: '', component: DoctoruserComponent, canActivate: [AdminGuardGuard] },
  { path: 'doctors/edit/:id', component: EditdoctorComponent, canActivate: [AdminGuardGuard] },
  { path: 'doctors/passwordHistory/:id', component: ChangePasswordHistoryComponent, canActivate: [AdminGuardGuard] },
  { path: 'client-doctors', component: ClientDoctorAdminComponent, canActivate: [AdminGuardGuard] },
  { path: 'hospital', component: HospitalListComponent, canActivate: [AdminGuardGuard] },
  { path: 'hospital/edit/:id', component: CreateHospitalComponent, canActivate: [AdminGuardGuard] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoctorRoutingModule {}