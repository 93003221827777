import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { isObject } from 'rxjs/internal/util/isObject';
import { detailExpandTable } from 'src/app/shared/animations';
import { SystemService } from 'src/app/shared/services';
import { isArray } from 'util';
import { ContentserviceService } from '../services/contentservice/contentservice.service';
import { DeletemodalComponent } from './../shared/deletemodal/deletemodal.component';

@Component({
  selector: 'app-healthgoal',
  templateUrl: './healthgoal.component.html',
  styleUrls: ['./healthgoal.component.scss'],
  animations: [detailExpandTable]
})

export class HealthgoalComponent implements OnInit {
  displayedColumns: string[] = ['title', 'updatedat','action'];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
   public isactive = 'all';
  public showloader: boolean = false;
  public token: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public alllanguages: any;
  public primarylanguage: any;
  public newsection: any = 'new';
  public tags: any;
  private myhash: any = {};
  private myCathash: any = {};
  public filteredtabulardata: any;
  public showButtonLoader: any;
  public allusers: any;
  public allCategory: any;
  public alltags: any;
  activecls = 'active';
  public tagFilters: any;
  public filteredtags: any;
  Lastfilteredtags: any;
  public status: any = 'active';

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private browserTitle: Title, private contentService: ContentserviceService, private systemService: SystemService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Magazine');
  }
  
  ngOnInit() {
    this.setSEOTags();
    this.searchcriteria = '';
    this.page = 1;
    this.getLanguages();
    this.search();
    this.getUsers();
    this.getTags();
    this.getCat();
  }

  getNextSet() {
    for (var i = 0; i < this.tabulardata.length; i++) {
    }
    if (this.endoflist == false) {
      this.page = this.page + 1;
      this.search();
    }
  }

  tableSort(getFilterValue) {
    this.tabulardata = [];
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
    this.status = getFilterValue.toString();
    this.activecls = this.status;
    this.filteredtags = [];
    this.tagFilters = '';
    this.page = 1;
    this.lastpage = 0;
    this.searchcriteria = "";
    this.endoflist = false;
    this.search();
  }

  tagfilter(fln) {
    this.filteredtags = fln;
    this.tabulardata = [];
    this.page = 1;
    this.lastpage = 0;
    this.search();
  }


  // To Delete Health Goals
  // ----------------------------

  delete(id) {
    this.contentService.hardDelete('healthgoals', id)
    .subscribe(
      (res: any) => this.onDeleteSuccess(id),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onDeleteSuccess(id) {
    for (let i = 0; i < this.tabulardata.length; i++) {
      if (this.tabulardata[i].id === id) {
        this.tabulardata.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }

  // ----------------------------

  openDialog(id) {
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.delete(id);
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected Health goal has been deleted', 'Ok', {
          duration: 2000,
        });
      }
    });
  }


  // To Get Users
  // ----------------------------

  getUsers() {
    this.contentService.getGraphQlData('{getAllUsers(appMode: false, status:true){id,name,display_name,display_bio,profile_image{path}}}')
    .subscribe(
      (res: any) => this.onSuccessUser(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onSuccessUser(data) {
    data = (data as any).data;
    this.allusers = data.getAllUsers;
    this.allusers.forEach(element => {
      this.myhash[element.id] = element.name;
    });
  }

  // ----------------------------

  getCat() {
    this.contentService.getGraphQlData('{getParentMagazineTypes(status:true){id,title}}')
    .subscribe(
      (res: any) => this.onSuccessCategory(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onSuccessCategory(data) {
    data = (data as any).data;
    this.allCategory = data.getParentMagazineTypes;
    this.allCategory.forEach(element => {
      this.myCathash[element.id] = element.title;
    });
  }

  // To Get Tags
  // ----------------------------

  getTags() {
    this.contentService.getGraphQlData('{getParentTags(status:true){id,title}}',false)
    .subscribe(
      (res: any) => this.onSuccessTags(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onSuccessTags(data) {
    data = (data as any).data;
    this.alltags = data.getParentTags;
  }

  // ----------------------------
  

  getAuthorName(author) {
    author = this.myhash[author];
    return author;
  }

  getCategoryName(category) {
    category = this.myCathash[category];
    return category;
  }

  // ----------------------------


  // Active Deactive
  // ----------------------------

  // isActive(status, id) {
  //   this.contentService.setStatus(status, 'healthgoals', id)
  //     .subscribe(
  //       (res: any) => this.onStatusSuccess(status, id),
  //       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
  //     )
  // }

  // private onStatusSuccess(status, id) {
  //   this.tabulardata.forEach((item) => {
  //     if (item.id === id) {
  //       item.is_active = !status;
  //     }
  //   });
  //   this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  // }
  isActive(status, id) {
    let value;
    if(status){
      value = "Deactivate"
    }else{
      value = "Activate"
    }
    let header = "Are you sure you want to "+""+ value +" "+ "health goal ?";
    localStorage.setItem('modal_header',header);
    const dialogRef = this.dialog.open(DeletemodalComponent);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        localStorage.removeItem("modal_header");
        this.contentService.setStatus(status, 'healthgoals', id)
        .subscribe(
          (res: any) => this.onChangeStatusSuccess(status, id,value),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.snackBar.open('Selected health goal has been '+ value+'d', 'Ok', {
          duration: 2000,
        });
      }
    });
    
  }

  private onChangeStatusSuccess(status, id,value) {
    this.tabulardata.forEach((item) => {
      if (item.id === id) {
        item.is_active = !status;
      }
    });
    if (value == "Activate") {
      this.tableSort('active');
    } else if (value == "Deactivate") {
      this.tableSort('inactive');
    }
    this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  }
  // ----------------------------


  // To Get Language Data
  // ----------------------------

  getLanguages() {
    this.showloader = true;
    this.contentService.getGraphQlData('{getAllLanguages(appMode:false,status:true){id,name}}')
    .subscribe(
      (res: any) => this.onLanguageSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onLanguageSuccess(data) {
    data = (data as any).data;
    this.alllanguages = data.getAllLanguages;
    this.primarylanguage = this.alllanguages;
    this.primarylanguage = this.primarylanguage.filter(language => language.id == '1')[0].id;
  }

  // ----------------------------
  
  getChildID(itemData: any, languageID) {
    if (
      !isObject(itemData) ||
      !itemData.child_data ||
      !isArray(itemData.child_data) ||
      !(Number(languageID) > 0)
    ) {
      return 0;
    }
    let rowID = 0;
    itemData.child_data.forEach(item => {
      if (Number(item.language_id) === Number(languageID)) {
        rowID = item.id;
      }
    });
    return rowID;
  }

  getUsername(id) {
    return this.contentService.allusers[id];
  }

  applyFilterType(val) {
    var filtereddata = null;
    if (val != 'all') {
      filtereddata = this.tabulardata.filter(t => t.type == val);
    } else {
      filtereddata = this.tabulardata;
    }
    this.dataSource = new MatTableDataSource<Element>(filtereddata);
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  // ----------------------------


  // Health goal list 
  // ----------------------------

  search() {
    if (this.Lastfilteredtags == '' || this.Lastfilteredtags != this.filteredtags || this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
      this.lastSearch = this.searchcriteria;
      this.Lastfilteredtags = this.filteredtags;
      if (this.filteredtags) {
        this.filteredtags = this.filteredtags.toString();
      }
      else {
        this.filteredtags = this.filteredtags;
      }
      this.lastpage = this.page;
      this.showloader = true;
      this.contentService.getSearchData(this.searchcriteria,this.page.toString(), "20", this.status, "healthgoals")
        .subscribe(
          (res: any) => this.onSearchDataSuccess(res),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }
  }

  onSearchDataSuccess(data) {
    data = data.data;
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;
      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
    }
  }

  // ----------------------------

}

export interface PeriodicElement {
  title: string;
  author: string;
  tags: string;
  category: string;
  isactive: boolean;
}
