<mat-drawer-container class="sidenav-container" autosize>
    <app-hcleftnav></app-hcleftnav>
    <div class="sidenav-content">
        <div class="col-md-12 topsettingbar">
            <div class="col-md-12">
                <h3 class="settingtitle">Settings</h3>
            </div>
            <div class="clearfix"><br /><br /><br /><br /></div>
            <form [formGroup]="settingForm" (ngSubmit)="updateUserSettings()" novalidate>
                <div class="col-md-12 settingsection">
                    <h4 class="settingsubtitle">General Settings</h4>
                    <br />
                    <mat-checkbox formControlName="open_user_section">Keep user detail section open by default
                    </mat-checkbox>
                    <br />
                    <mat-checkbox formControlName="logout_on_no_activity">Logout if there is no activity</mat-checkbox>
                    <br /><br />
                    <h4 class="settingsubtitle">Notification Settings</h4>
                    <br />
                    <mat-checkbox formControlName="message_alerts">Show message alerts in notification</mat-checkbox>
                    <br />
                    <mat-checkbox formControlName="push_notifications">Allow push notification</mat-checkbox>
                    <br /><br />
                    <h4 class="settingsubtitle">Theme Settings</h4>
                    <br />
                    <mat-radio-group formControlName="theme">
                        <mat-radio-button class="example-margin" value="light">
                            Light
                        </mat-radio-button>
                        &nbsp;&nbsp;
                        <mat-radio-button class="example-margin" value="dark">
                            Dark
                        </mat-radio-button>
                        &nbsp;&nbsp;
                        <mat-radio-button class="example-margin" value="superdark">
                            Super Dark (beta)
                        </mat-radio-button>
                    </mat-radio-group>
                    <br /><br />
                    <div class="clearfix"><br /></div>
                    <button mat-flat-button color="primary" class="centerbtn" [disabled]="!settingForm.valid"
                        *ngIf="settingUpdateSubject$ | async; else buttonSubmitLoader">Save</button>
                    <ng-template #buttonSubmitLoader>
                        <app-loader showLoader="{{true}}"></app-loader>
                    </ng-template>
                </div>
            </form>
        </div>
    </div>
</mat-drawer-container>