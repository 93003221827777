import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../services/commonservice/commonservice.service';
import { Moment } from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { MatSort } from '@angular/material/sort';
import moment = require('moment');
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UndoManager } from 'brace';
import { filter } from 'rxjs/operators';

export interface therapyDataFilter {
  therapyName: string;
  therapyid: number;
}
export interface clientDataFilter {
  clientName: string;
  clientid: number;
}
export interface ownerDataFilter {
  ownerName: string;
  ownerid: number;
}
export interface priorityDataFilter {
  title: string;
  id: number;
}
export interface expiryDataFilter {
  title: string;
  id: number;
}

@Component({
  selector: 'app-taskrequest',
  templateUrl: './taskrequest.component.html',
  styleUrls: ['./taskrequest.component.scss']
})
export class TaskrequestComponent implements OnInit {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public displayedColumns = ['task_library_id', 'filter_conditions_summary', 'hc_id', 'priority', 'action'];
  public dataSource: any;
  public searchcriteria: any;
  public showloader: any;
  public conditionfilter: any;
  lastSearch: any;
  // displayedColumns = ['task_id', 'task_summary', 'category', 'priority', 'expiry', 'action'];
   public isactive = 'all';
  public newsection: any = 'new';
  public typeFilters: any;
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  tabulardata: any;
  public emptyplaceholder: boolean = false;
  // dataSource: any 
  activecls = 'active';
  public showButtonLoader: boolean = false;
  public filteredtabulardata: any;
  public filteredtypes: any = [];
  isLoading: boolean = false;
  filters: any;
  sort_by: any;
  sort_direction: any
  public tagFilters: any;
  private myCathash: any = {};
  startDate: any;
  endDate: any;
  therapyData: any;
  therapyName: any;
  clientData: any;
  therapyid: any;
  clientid: any;
  ownerList: any;
  ownerid: any;
  clientName: any;
  ownerName: any;
  priorityid: any = [];
  public filterModel: any = { "low": false, "high": false, "medium": false }
  selected: { startDate: Moment, endDate: Moment };
  highPriority: any = undefined;
  mediumPriority: any = undefined;
  lowPriority: any = undefined;
  priority: any = [];
  dataList: any = [];
  therapyDataFilter: any = [];
  clientDataFilter: any = [];
  ownerDataFilter: any = [];
  priorityDataFilter: any = [];
  expiryDataFilter: any = [];
  startDate1: any;
  endDate1: any;
  filterEvent:any;  
  chosenTherapy:any;
  chosenClient:any;
  chosenOwner:any;
  Chosendate:any;
  public status = 'active';
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  @ViewChild(MatSort, { static: false })
  sort: MatSort = new MatSort;
  constructor(public commonService: CommonserviceService,
    private systemService: SystemService) { }

  ngOnInit(): void {
    this.search();
    this.getHc();
    this.getFilteredList();
  }


  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      this.search();
    }
  }

  //cancel search
  cancelSearch() {
    this.searchcriteria = '';
    this.page = 1;
    this.lastpage = 0;
    this.endoflist = false;
    this.onit = true;
    this.search();
  }

  //clear btn 
  clear(myCheckBox, myCheckBox1, myCheckBox2) {
    myCheckBox.checked = false;
    myCheckBox1.checked = false;
    myCheckBox2.checked = false;
    this.priorityDataFilter=[];
     this.lowPriority = false;
    this.mediumPriority = false;
    this.highPriority = false;
    this.priorityid = []
  }
  //mat chips for therapy
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.therapyDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }
  //remove mat chip for therapy
  async remove(filter: therapyDataFilter): Promise<void> {
    const index = this.therapyDataFilter.indexOf(filter);
    if (index >= 0) {
      this.therapyDataFilter.splice(index, 1);
      this.therapyid = false
      if (this.therapyid == false) {
        this.therapyid = false;
        this.therapyName = false;   
        this.chosenTherapy = this.therapyData.title==false;  
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      
    }
  }
  callsidebar(element){
    if(!element.is_active && !element.is_approved){
      return 'circle red'
    }
    if(element.is_approved){
      return 'circle green';
    }else{
      return 'circle gray'
    }
  }
  //mat chip for client
  add1(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.clientDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove1(filter: clientDataFilter): Promise<void> {
    const index = this.clientDataFilter.indexOf(filter);
    if (index >= 0) {
      this.clientDataFilter.splice(index, 1);
      this.clientid = false;
      if (this.clientid == false) {
        this.clientid = false;
        this.clientName = false;   
        this.chosenClient = this.clientData.title==false;  
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      
    }
  }

  //mat chip for owner
  add2(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.ownerDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove2(filter: ownerDataFilter): Promise<void> {
    const index = this.ownerDataFilter.indexOf(filter);
    if (index >= 0) {
      this.ownerDataFilter.splice(index, 1);
      this.ownerid = false;
      if (this.ownerid == false) {
        this.ownerid = false;
        this.ownerName = false;   
        this.chosenOwner = this.ownerList.title==false;  
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      
    }
  }

  //mat chip for priority
  add3(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.priorityDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove3(filter: priorityDataFilter): Promise<void> {

    // const index = this.priorityDataFilter.indexOf(filter);    
    if (filter.id == 0) {
      const index = this.priorityDataFilter.indexOf(filter);
      this.priorityDataFilter.splice(index, 1);
      if ((this.priorityDataFilter.some(priority => priority.id == 1)) && this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [1, 2]
        this.lowPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 1)) {
        this.priorityid = [1]
        this.lowPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [2]
        this.lowPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.length == 0) {
        this.priorityid = []
        this.lowPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
    }
    if (filter.id == 1) {
      const index = this.priorityDataFilter.indexOf(filter);
      this.priorityDataFilter.splice(index, 1);
      if ((this.priorityDataFilter.some(priority => priority.id == 0)) && this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [0, 2]
        this.mediumPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 0)) {
        this.priorityid = [0]
        this.mediumPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 2)) {
        this.priorityid = [2]
        this.mediumPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.length == 0) {
        this.priorityid = []
        this.mediumPriority=false;
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
    }
    if (filter.id == 2) {
      const index = this.priorityDataFilter.indexOf(filter);
      this.priorityDataFilter.splice(index, 1);
      if ((this.priorityDataFilter.some(priority => priority.id == 0)) && this.priorityDataFilter.some(priority => priority.id == 1)) {
        this.priorityid = [0, 1]
        this.highPriority=false;    
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 0)) {
        this.priorityid = [0]
        this.highPriority=false;    
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.some(priority => priority.id == 1)) {
        this.priorityid = [1]
        this.highPriority=false;    
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
      else if (this.priorityDataFilter.length == 0) {
        this.priorityid = []
        this.highPriority=false;    
        this.onit = true;
        this.search();
        await this.sleep(1000)
      }
    }
  }

  //mat chip for expiry
  add4(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.expiryDataFilter.push({ name: value });
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  async remove4(filter: expiryDataFilter): Promise<void> {
    const index = this.expiryDataFilter.indexOf(filter);
    if (index >= 0) {
      this.expiryDataFilter.splice(index, 1);     
      this.startDate = false;
      this.endDate =false;
      if( this.startDate == false && this.endDate ==false)
      {
        this.Chosendate=false; 
      }
      this.onit = true;
      this.search();
      await this.sleep(1000)
      
    }
  }

  async clearChips() {
    this.therapyDataFilter = [];
    this.clientDataFilter = [];
    this.ownerDataFilter = [];
    this.priorityDataFilter = [];
    this.expiryDataFilter = [];
    this.therapyid = ""
    this.clientid = ""
    this.ownerid = ""
    this.lowPriority=false;
    this.mediumPriority=false;
    this.highPriority=false;
    this.priorityid = []
    this.startDate = ""
    this.endDate = ""
    this.chosenTherapy = this.therapyData.title==false;
    this.chosenClient = this.clientData.title==false;   
    this.chosenOwner = this.ownerList.title==false;  
    this.Chosendate=false; 
    this.onit = true;
    this.search();
    await this.sleep(1000)
  }
  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  //get filter data
  getFilteredList() {

    this.commonService.getFilterList()
      .subscribe(
        (res: any) => this.getFilterListSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  //calender
  openCalendar(e) {
    this.pickerDirective.open(e)
  }

  private getFilterListSuccess(data) {
    data = (data as any).data;
    this.therapyData = data.filter_therapy_list;
    this.therapyName = this.therapyData;
    this.clientData = data.filter_client_list;
    this.ownerList = data.filter_owner_list;
    // this.therapyName.forEach(element => {
    //   this.therapyNamebyId[element.id] = element.title;
    // });
  }

  selectedTherapy(event, name) {
    if (event) {
      this.therapyid = event;
      this.therapyName = name;
      this.onit = true;
    } else {
      this.therapyid = ""
    }
    this.search();
  }

  selectedClient(event, name) {
    if (event) {
      this.clientid = event;
      this.clientName = name;
      this.onit = true;
    }
    else {
      this.clientid = ""
    }
    this.search();
  }

  selectedOwner(event, name) {
    if (event) {
      this.ownerid = event;
      this.ownerName = name;
      this.onit = true;
    }
    else {
      this.clientid = ""
    }
    this.search();
  }

  selectedPriority() {
    if (this.highPriority) { this.priority.push(2) };
    if (this.lowPriority) { this.priority.push(0) };
    if (this.mediumPriority) { this.priority.push(1) };
    
    let event = this.priority;
    if (event) {
      this.priorityid = event;
      this.onit = true;
    }
    else {
      this.clientid = ""
    }
    this.search();
  }

  tagfilter(event) {
    this.filterEvent=event
    if (event == "Today") {
      var today = new Date();
      this.startDate = today.toISOString();
      this.endDate = today.toISOString();
    }

    if (event == "Thisweek") {
      var curr = new Date; // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      var firstday = new Date(curr.setDate(first)).toISOString();
      var lastday = new Date(curr.setDate(last)).toISOString();
      this.startDate = firstday.toString()
      this.endDate = lastday.toString()
      this.startDate1 = moment(this.startDate).toISOString();
      this.endDate1 = moment(this.endDate).toISOString();
      
      
    }
    this.onit = true;
    this.search();

    this.tagFilters = event;
  }

  selectDateRange(){ 
this.filterEvent="customDate" ;
    let d1=this.selected.startDate.toDate();
    let d2=this.selected.endDate.toDate();
    this.startDate = moment(d1).toISOString();
    this.endDate = moment(d2).toISOString(); 
    this.startDate1 = moment(this.startDate).toISOString();
    this.endDate1 = moment(this.endDate).toISOString();
    this.onit = true;
    this.search();

    this.tagFilters = event;
}

 
  //  Task List
  // ----------------------------

  search() {
    if (this.searchcriteria) {
      this.searchcriteria = this.searchcriteria
    } else {
      this.searchcriteria = ''
    }
    this.lastSearch = this.searchcriteria;
    this.lastpage = this.page;
    this.isLoading = true;
    // this.searchcriteria = ''
    this.sort_by = ""
    this.sort_direction = ""
    this.therapyDataFilter = [];
    this.clientDataFilter = [];
    this.ownerDataFilter = [];
    this.priorityDataFilter = [];
    this.expiryDataFilter = [];
    this.filters = { "therapy_id": this.therapyid, "client_id": this.clientid, "owner_id": this.ownerid, "priority": this.priorityid, "expiry_start_date": this.startDate, "expiry_end_date": this.endDate }
    this.commonService.getReqTaskList(this.searchcriteria, this.page.toString(), '20', this.status, this.sort_by, this.sort_direction, this.filters)
      .subscribe((res: any) => {
        this.onTaskListSuccess(res)
        this.dataList = res.data;
      },
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    this.priority = [];
  }

  private onTaskListSuccess(data) {  
    let date1 = new Date( this.startDate);
    let date2= new Date(this.endDate);
   this.startDate1 = moment(date1).format('D MMM, YYYY');
   this.endDate1 = moment(date2).format('D MMM, YYYY');
    data = data.data.sort((a, b) => (a.is_approved !== b.is_approved) ? 1 : -1)
    if (data.length == 0) {
      if (this.onit == true) {
        this.tabulardata = [];
        this.emptyplaceholder = true;
        this.dataSource = new MatTableDataSource<Element>(this.tabulardata);       
        this.isLoading = false;
        if (this.filters) {
          let therapy, client, owner, priority;
          if (this.filters.therapy_id) {
            therapy = { therapyName: this.therapyName, therapyid: this.therapyid }
            this.therapyDataFilter.push(therapy)
          }
          if (this.filters.client_id) {
            client = { clientName: this.clientName, clientid: this.clientid }
            this.clientDataFilter.push(client)
          }
          if (this.filters.owner_id) {
            owner = { ownerName: this.ownerName, ownerid: this.ownerid }
            this.ownerDataFilter.push(owner)
          }
          if (this.filters.priority.length > 0) {
            let prioritytitle, priorityId;
            if (this.filters.priority.includes(0)) {
              prioritytitle = "Low", priorityId = 0
              let priority1 = { title: prioritytitle, id: priorityId }
              this.priorityDataFilter.push(priority1)
            }
            if (this.filters.priority.includes(1)) {
              prioritytitle = "Medium", priorityId = 1
              let priority2 = { title: prioritytitle, id: priorityId }
              this.priorityDataFilter.push(priority2)
            }
            if (this.filters.priority.includes(2)) {
              prioritytitle = "High", priorityId = 2
              let priority3 = { title: prioritytitle, id: priorityId }
              this.priorityDataFilter.push(priority3)
            }
          }         
          if ((this.filterEvent == "Today")&& ( this.filters.expiry_start_date && this.filters.expiry_end_date )) 
          {
            let Date = { title: this.startDate1, id: 0 }
            this.expiryDataFilter.push(Date)
          } 
          if ((this.filterEvent == "Thisweek")&& ( this.filters.expiry_start_date && this.filters.expiry_end_date )) 
          {
            let Date = { title: this.startDate1  + "-" +  this.endDate1 , id: 0 }
            this.expiryDataFilter.push(Date)
          }
          if ((this.filterEvent == "customDate") && ( this.filters.expiry_start_date && this.filters.expiry_end_date ))
          {
            let Date = { title: this.startDate1  + "-" + this.endDate1 , id: 0 }
            this.expiryDataFilter.push(Date)
          }        
          

        }
      }
      else {
        this.endoflist = true;
        this.emptyplaceholder = false;
      }
    }
    else {
      this.emptyplaceholder = false;

      if (this.onit) {
        this.tabulardata = data;
      }
      else {
        for (var k = 0; k < data.length; k++) {
          this.tabulardata.push(data[k]);
        }
      }
      this.onit = false;
      
      this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
      this.dataSource.sort = this.sort;
      this.isLoading = false;
      if (this.filters) {
        let therapy, client, owner, priority;
        if (this.filters.therapy_id) {
          therapy = { therapyName: this.therapyName, therapyid: this.therapyid }
          this.therapyDataFilter.push(therapy)
        }
        if (this.filters.client_id) {
          client = { clientName: this.clientName, clientid: this.clientid }
          this.clientDataFilter.push(client)
        }
        if (this.filters.owner_id) {
          owner = { ownerName: this.ownerName, ownerid: this.ownerid }
          this.ownerDataFilter.push(owner)
        }
        if (this.filters.priority.length > 0) {
          let prioritytitle, priorityId;
          if (this.filters.priority.includes(0)) {
            prioritytitle = "Low", priorityId = 0
            let priority1 = { title: prioritytitle, id: priorityId }
            this.priorityDataFilter.push(priority1)
          }
          if (this.filters.priority.includes(1)) {
            prioritytitle = "Medium", priorityId = 1
            let priority2 = { title: prioritytitle, id: priorityId }
            this.priorityDataFilter.push(priority2)
          }
          if (this.filters.priority.includes(2)) {
            prioritytitle = "High", priorityId = 2
            let priority3 = { title: prioritytitle, id: priorityId }
            this.priorityDataFilter.push(priority3)
          }
        }         
        if ((this.filterEvent == "Today")&& ( this.filters.expiry_start_date && this.filters.expiry_end_date )) 
        {
          let Date = { title: this.startDate1, id: 0 }
          this.expiryDataFilter.push(Date)
        } 
        if ((this.filterEvent == "Thisweek")&& ( this.filters.expiry_start_date && this.filters.expiry_end_date )) 
        {
          let Date = { title: this.startDate1  + "-" +  this.endDate1 , id: 0 }
          this.expiryDataFilter.push(Date)
        }
        if ((this.filterEvent == "customDate") && ( this.filters.expiry_start_date && this.filters.expiry_end_date ))
        {
          let Date = { title: this.startDate1  + "-" + this.endDate1 , id: 0 }
          this.expiryDataFilter.push(Date)
        }        
        

      }
    }
  }


  priorityInList(type) {
    if (type == 0) {
      return "Low"
    } else if (type == 1) {
      return 'Medium'
    }
    else if (type == 2) {
      return 'High'
    }
    else {
      return type
    }
  }



  getHc() {
    this.commonService.getGraphQlData('{getAllHealthcoaches(appMode:false,status:true){id,name}}')
      .subscribe(
        (res: any) => this.onHCStatusSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onHCStatusSuccess(data) {
    data = (data as any).data;
    data.getAllHealthcoaches.forEach(element => {
      this.myCathash[element.id] = element.name;
    });
  }

  getHCName(category) {
    category = this.myCathash[category];
    return category;

  }

  hctaskData(data) {
    localStorage.setItem('hctaskdata', JSON.stringify(data));
    localStorage.setItem('task_display_id', JSON.stringify(data.task_display_id));
  }

}
