import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { CommonserviceService } from 'src/app/admin/services/commonservice/commonservice.service';
import { SystemService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';
import { EditorinstructionsComponent } from '../../shared/editorinstructions/editorinstructions.component';
import { ChoosemediamodalComponent } from './../../mediamanager/choosemediamodal/choosemediamodal.component';

declare var $: any;

@Component({
  selector: 'app-editlesson',
  templateUrl: './editlesson.component.html',
  styleUrls: ['./editlesson.component.scss']
})

export class EditlessonComponent implements OnInit {
  public options = {
    showPreviewPanel: false,
    showBorder: true,
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'],
    hideToolbar: true,
    usingFontAwesome5: false,
    scrollPastEnd: 0,
    enablePreviewContentClick: false
  }
  public selfparentid: any;
  s3url: any = environment.s3url;
  public showButtonLoader: boolean = false;
  public newitem: boolean = false;
  public childid: any;
  public langid: any;
  complexityenum: any = complexityenum;
  lsndata: any;
  lessondata: any;
  allfeedback: any;
  alltags: any;
  optiontype: any;
  htmlContent: any;
  checked: boolean = false;
  chooseMedia: any;
  doUpload: any;
  myHtml: string = '';
  public renderhtml: any;
  public mode: any;
  public selfparentide: any;
  public language: any;
  public imageUrl: any;
  public allmedia: any = [];
  public allmediatitle: any = [];
  public thumbnailMedia: any;
  public mediaorientation: any;
  public addingClass: any = '';
  public noofoptions: any;
  public imageData: any;
  disabled: boolean = true;

  public dataForm = this.fb.group({
    title: ['', [Validators.required, whiteSpaceValidation]],
    raw_content: [''],
    content: [''],
    type: ['', Validators.required],
    sub_title: ['', Validators.required],
    complexity_level: ['', Validators.required],
    allow_feedback: [''],
    time_to_finish: ['', [Validators.required, Validators.max(3600), Validators.min(1)]],
    tags: [[], Validators.required],
    feedback: [''],
    media: this.fb.array([], Validators.required),
    created_by_data_fk: [''],
    language_data_fk: [Validators.required],
    self_parent_fk: [Validators.required],
    media_orientation: [''],
    imagecount: [''],
    additionaltext: ['']
  });

  private _onDestroy = new Subject<void>();
  public searchmattag: FormControl = new FormControl();
  public searchmatfeedback: FormControl = new FormControl();

  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredFeedback: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelectFeedback', { static: false }) singleSelectFeedback: MatSelect;

  initMediaOptions() {
    return this.fb.group({
      audiovideomedia: []
    });
  }

  checkvalid() {
  }

  constructor(private browserTitle: Title, private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute, public fb: FormBuilder, public dialog: MatDialog, private contentService: ContentserviceService, private systemService: SystemService, protected sanitizer: DomSanitizer,private commonService:CommonserviceService) {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  uirender() {
    var scope = this;
    setTimeout(() => {
      scope.renderhtml = $('.preview-panel').html();
      scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
    }, 100);
    setTimeout(() => {
      scope.renderhtml = $('.preview-panel').html();
      scope.renderhtml = scope.sanitizer.bypassSecurityTrustHtml(scope.renderhtml);
    }, 300);
  }

  additionalTextChange(e) {
    const rawcontent = <FormControl>this.dataForm.controls.raw_content;
    const additionaltextcontent = <FormControl>this.dataForm.controls.additionaltext;
    additionaltextcontent.setValue(rawcontent.value);
  }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Lesson');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.selfparentide = <FormArray>this.dataForm.controls.self_parent_fk;
      this.language = <FormArray>this.dataForm.controls.language_data_fk;
      this.langid = params.langid;
      if (params.id == 'new') {
        this.selfparentid = null;
        this.newitem = true;
        this.selfparentide.setValue(null);
        this.language.setValue(this.langid);

      } else {
        if (params.langid == 1 && params.chid == 0) {
          this.childid = params.id;
          this.selfparentid = null;
          this.newitem = false;
          this.getAllLessonData(this.childid);
        } else if (params.langid != 1 && params.chid == 0) {
          this.newitem = true;
          this.childid = params.id;
          this.selfparentid = params.id;
          this.getAllLessonData(this.childid);
        } else {
          this.childid = params.chid;
          this.newitem = false;
          this.selfparentid = params.id;
          this.getAllLessonData(params.chid);
        }
      }
      this.getFeedbacks();
      this.getTags();
    });
  }

  ngAfterViewInit() {
  }

  openinstructions(): void {
    this.dialog.open(EditorinstructionsComponent, {
      width: '850px'
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  preRenderFunc(content: string) {
    var exp = /((youtube:https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var exp1 = /^((video:https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/i;
    content = content.replace(exp, "<iframe width='100%' height='auto' src='$1' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
    content = content.replace(exp1, "<video width='100%' controls><source src='$&'></video>");
    content = content.replace(/youtube:/g, '');
    content = content.replace(/video:/g, '');
    return content;
  }

  modelChangedTags() {
    this.filterTags();
  }

  modelChangedFeedback() {
    this.filterFeedback();
  }

  morientation(e) {
    this.mediaorientation = e;
  }

  private filterTags() {
    if (!this.alltags) {
      return;
    }
    let search = this.searchmattag.value;
    if (!search) {
      this.filteredTags.next(this.alltags.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    this.filteredTags.next(
      this.alltags.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterFeedback() {
    if (!this.allfeedback) {
      return;
    }
    let search = this.searchmatfeedback.value;
    if (!search) {
      this.filteredFeedback.next(this.allfeedback.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    this.filteredFeedback.next(
      this.allfeedback.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  // To Get All Lesson
  // ----------------------------

  getAllLessonData(ide) {
    this.contentService
      .getOneDataById(ide, 'lesson')
      .subscribe((res: any) => this.getAllLessonSuccess((res as any).data),
       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getAllLessonSuccess(data) {
    this.lsndata = data;
    
    if (this.lsndata.type == 'image') {
      this.generateOptions(this.lsndata.media.length);
      for (let k = 0; k < this.lsndata.media.length; k++) {
        const formelement = (<FormArray>(
          this.dataForm.controls['media']
        )).at(k);
        formelement['controls'].audiovideomedia.setValue(this.lsndata.media[k]);
      }
    }
    else if (this.lsndata.type == 'audio' || this.lsndata.type == 'video') {
      this.generateOptions(0);
      this.generateOneMediaOption();
      const formelement = (<FormArray>(
        this.dataForm.controls['media']
      )).at(0);
      formelement['controls'].audiovideomedia.setValue(this.lsndata.media[0]);
    }

    if (this.lsndata.allow_feedback == true) {
      this.checked = this.lsndata.allow_feedback;
      const formelement = (<FormControl>(this.dataForm.controls['feedback']));
      this.lsndata.feedback = this.lsndata.feedback.toString();
      formelement.setValue(this.lsndata.feedback);
    }
    this.imageUrl = this.lsndata.media_joined_data;
    this.dataForm.patchValue(this.lsndata);
    this.selfparentide.setValue(this.selfparentid);
    this.language.setValue(this.langid);
    this.optiontype = this.lsndata.type;
    this.mediaorientation = this.lsndata.media_orientation;
    if (this.optiontype == 'image') {
      const imageformelement = (<FormControl>(this.dataForm.controls['imagecount']));
      imageformelement.setValue(this.lsndata.media.length);
    }
    for (let i = 0; i < this.lsndata.media_joined_data.length; i++) {
      this.allmedia[i] = this.lsndata.media_joined_data[i].path;
      this.allmediatitle[i] = this.lsndata.media_joined_data[i].title;
    }
    if (this.optiontype == 'text') {
      this.dataForm.removeControl('media');
    }
    this.uirender();
  }

  // ----------------------------


  // To Get Feedback
  // ----------------------------

  getFeedbacks() {
    this.contentService
      .getGraphQlData('{getParentFeedbacks(status:true){id,title}}', false)
      .subscribe((res: any) => this.getFeedbackSuccess((res as any).data),
       (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private getFeedbackSuccess(data) {
    this.allfeedback = data.getParentFeedbacks;
    this.filteredFeedback.next(this.allfeedback.slice());
  }

  // ----------------------------


  // Get all Tags
  // ----------------------------

  getTags() {
    this.contentService
      .getGraphQlData('{getParentTags(status:true){id,title}}', false)
      .subscribe((res: any) => this.ongetTagsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  ongetTagsSuccess(data) {
    this.alltags = data.getParentTags;
    this.filteredTags.next(this.alltags.slice());
  }

  // ----------------------------

  generateOneMediaOption() {
    var control2 = <FormArray>this.dataForm.controls['media'];
    while (control2.length !== 0) {
      control2.removeAt(0);
    }
    for (var k = 0; k < 1; k++) {
      control2.push(this.initMediaOptions());
    }
  }

  generateOptions(e) {
    this.noofoptions = e;
    for (let j = 0; j < e; j++) {
      this.filteredTags[j] = new ReplaySubject<any[]>(1);
    }
    var control2 = <FormArray>this.dataForm.controls['media'];
    if (control2.length !== 0) {
      if (control2.length < e) {
        for (let l = control2.length; l < e; l++) {
          control2.push(this.initMediaOptions());
        }
      }
      if (control2.length > e) {
        for (let l = control2.length; l > e; l--) {
          control2.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        control2.push(this.initMediaOptions());
      }
    }
  }

  // Get Media
  // -----------------------------

  getMedia(id) {
    this.contentService.getGraphQlData('{getOneMedia(appMode:false,mediaId:' + id + '){thumbnail_path}}')
      .subscribe(
        (res: any) => this.onGetMediaSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onGetMediaSuccess(data) {
    this.imageData = data.getOneMedia;
    this.allmedia = this.imageData.thumbnail_path;
  }

  // -----------------------------


  // Submitting Lesson Data
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    var imgoption = [];
    if (this.optiontype != 'text') {
      postdata.media.forEach(el => {
        imgoption.push(el.audiovideomedia);
      });
    }
    if (!this.checked) {
      postdata.feedback = '';

    }
    if (!imgoption.every((e, i, a) => a.indexOf(e) === i)) {
      this.systemService.showSnackBar("Please select the unique images to create the lesson.", "OK");
    }

    let title = this.commonService.isHTML( this.dataForm.value.title);
    let content = this.commonService.isHTML( this.dataForm.value.content);
    let sub_title = this.commonService.isHTML( this.dataForm.value.sub_title);
    let feedback = this.commonService.isHTML( this.dataForm.value.feedback);
    let additionaltext = this.commonService.isHTML( this.dataForm.value.additionaltext);

    if(title  === true){
      this.dataForm.controls.title.patchValue('')
      return;
    }
    if(content  === true){
      this.dataForm.controls.content.patchValue('')
      return;
    }
    if(sub_title  === true){
      this.dataForm.controls.sub_title.patchValue('')
      return;
    }
    if(feedback  === true){
      this.dataForm.controls.feedback.patchValue('')
      return;
    }
    if(additionaltext  === true){
      this.dataForm.controls.additionaltext.patchValue('')
      return;
    }
    else {
      postdata.media = JSON.stringify(imgoption);
      postdata.title = postdata.title.toString();
      if (postdata.type == 'image' || postdata.type == 'text') {
        postdata.media_orientation = 'portrait';
      }
      // if(postdata.type=='image'|| postdata.type == 'text'|| postdata.type == 'video'){
      //   postdata.background_image_media = '';
      // }
      delete postdata.additionaltext;
      // postdata.background_image_media = postdata.background_image_media.toString();
      postdata.media_orientation = postdata.media_orientation.toString();
      postdata.type = postdata.type.toString();
      postdata.sub_title = postdata.sub_title.toString();
      postdata.complexity_level = postdata.complexity_level.toString();
      postdata.allow_feedback = this.checked.toString();
      postdata.time_to_finish = postdata.time_to_finish.toString();
      postdata.feedback = postdata.feedback == null ? null : postdata.feedback.toString();
      postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
      postdata.language_data_fk = postdata.language_data_fk.toString();
      postdata.tags = JSON.stringify(postdata.tags);
      if ((this.optiontype == "image" || this.optiontype == "text" || (this.optiontype == "video" && this.mediaorientation == "landscape") || (this.optiontype == "audio" && this.mediaorientation == "landscape"))) {
        postdata.content = $('.preview-panel').html();
        postdata.raw_content = postdata.raw_content.toString();
      }
      else {
        postdata.raw_content = '';
        postdata.content = '';
      }
      delete postdata.imagecount;
      postdata.self_parent_fk = postdata.self_parent_fk == null ? null : postdata.self_parent_fk.toString();
      if (this.newitem == true) {
        this.contentService
          .postToBlade(postdata, 'lesson')
          .subscribe(
            (res: any) => this.onCreateSuccess((res as any).data),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      } else {
        this.contentService
          .updateToBlade(this.childid, this.selfparentid, postdata, 'lesson')
          .subscribe(
            (res: any) => this.onUpdateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          )
      }
    }
  }

  private onCreateSuccess(data) {
    this.lessondata = data;
    this.systemService.showSuccessSnackBar(("Lesson has been added"), "OK");
    this.router.navigate(['content/lesson']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Lesson has been updated"), "OK");
    this.router.navigate(['content/lesson'])
  }

  // ----------------------------

  changeValue(value) {
    this.checked = !value;
  }

  changeOptions(e) {
    this.allmedia = [];
    this.optiontype = e;
    const formelement = <FormControl>this.dataForm.controls['imagecount'];
    formelement.setValue(0);
    if (this.optiontype == 'text') {
      if (this.dataForm.get('media')) {
        this.dataForm.removeControl('media');
      }
    }
    else {
      if (!this.dataForm.get('media')) {
        this.dataForm.addControl('media', this.fb.array([], Validators.required));
      }
    }

    if (this.optiontype == 'audio' || this.optiontype == 'video') {
      this.generateOneMediaOption();
    }
    if (this.optiontype == 'image') {
      this.generateOptions(0);
    }
    this.addingClass = '';
  }

  openmediabox(e, type) {
    this.chooseMedia = this.dialog.open(ChoosemediamodalComponent, {
      width: '600px',
      height: '600px',
      data: {
        type: (type == 'background') ? 'image' : this.optiontype,
      },
      panelClass: 'mediaDailog'

    });

    // if (type == 'background') {
    //   this.chooseMedia.afterClosed().subscribe(data => {
    //     const formelement = <FormControl>this.dataForm.controls['background_image_media'];
    //     formelement.setValue(data.id);
    //     this.thumbnailMedia = data.path;
    //   });
    // }
    if (type == 'media') {
      this.chooseMedia.afterClosed().subscribe(data => {
        const formelement = (<FormArray>(
          this.dataForm.controls['media']
        )).at(e);
        formelement['controls'].audiovideomedia.setValue(data.id);
        this.allmedia[e] = data.path;
        this.allmediatitle[e] = data.title;
      });
    }
  }
}

export enum complexityenum {
  High,
  Medium,
  Low
}
