/*==========================================
Title:  Validataion
Author: Soundharya AM
Date:   May 08, 2020
Last Change : Validataion in creation hardset OTP
===========================================*/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../utility/validation';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-edithardsetotp',
  templateUrl: './edithardsetotp.component.html',
  styleUrls: ['./edithardsetotp.component.scss']
})

export class EdithardsetotpComponent implements OnInit {
  public showButtonLoader: any;
  localStoredData: any;
  userData: any;
  public morrow: any = new Date();
  public userdata: any;
  myFilter = (d: Date): boolean => {
    var dateobj = new Date();
    var day1 = dateobj.getDate();
    const day = d.getDate();
    // Prevent Saturday and Sunday from being selected.
    return (day >= day1 && day <= day1 + 7);
  }
  public newitem: boolean = false;
  userid: any;
  public dataForm = this.fb.group({
    phone: ["", [Validators.required, whiteSpaceValidation]],
    otp: ["", [Validators.required, whiteSpaceValidation]],
    country_code: ["", [Validators.required, whiteSpaceValidation]],
    beneficiary_name: ["", [Validators.required, whiteSpaceValidation]],
    requester_info: ["", [Validators.required, whiteSpaceValidation]],
    reason_for_hardset_otp: ["", [Validators.required, whiteSpaceValidation]],
    expiry_date: ["", [Validators.required, whiteSpaceValidation]],
    // created_by:["", [Validators.required, whiteSpaceValidation]],
    is_dummy: [],

  });

  constructor(private route: ActivatedRoute, private browserTitle: Title, private router: Router, public fb: FormBuilder, private commonService: CommonserviceService, private systemService: SystemService) { }
  private setSEOTags(): void {
    this.browserTitle.setTitle('Admin | Update Hard Reset OTP');
  }

  ngOnInit() {
    this.setSEOTags();
    this.localStoredData = JSON.parse(localStorage.getItem('scarletuserdata'));
    this.userData = (this.localStoredData.user_acl == 'superadmin' ? true : false);
    this.route.params.subscribe(params => {
      if (params.id == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        this.userid = params.id;
        this.getOneClient(params.id);
      }
    })
  }

  getOneClient(id) {
    this.commonService.getOneHelperOTP(id)
      .subscribe(
        (res: any) => this.onClientDataSuccess(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }
  private onClientDataSuccess(data) {
    data = (data as any).data;
    this.userdata = data;

    // 
    this.dataForm.patchValue(this.userdata);
  }
  // Submitting Hardset data
  // ----------------------------
  dataObject() {
    
    var postdata = this.dataForm.value;
    let reason_for_hardset_otp = this.commonService.isHTML( this.dataForm.value.reason_for_hardset_otp);
    let beneficiary_name = this.commonService.isHTML( this.dataForm.value.beneficiary_name);

    if(reason_for_hardset_otp  === true){
      this.dataForm.controls.reason_for_hardset_otp.patchValue('')
      return;
    }
    if(beneficiary_name  === true){
      this.dataForm.controls.beneficiary_name.patchValue('')
      return;
    }
    let sourceUserName: any = JSON.parse(localStorage.getItem('scarletuserdata'));
    postdata.phone = postdata.phone.toString();
    postdata.otp = postdata.otp.toString();
    postdata.country_code = postdata.country_code.toString();
    postdata.beneficiary_name = postdata.beneficiary_name.toString();
    postdata.requester_info = postdata.requester_info.toString();
    postdata.reason_for_hardset_otp = postdata.reason_for_hardset_otp.toString();
    postdata.expiry_date = this.formatDate(postdata.expiry_date.toString());
     postdata['source_user_name'] = sourceUserName.name.toString();

    if (postdata.is_dummy != null) {
      postdata.is_dummy = postdata.is_dummy.toString();
    }
    // this.commonService.postToBlade(postdata, 'hardsetotpcreate')
    //   .subscribe(
    //     (res: any) => this.onSuccessData(),
    //     (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    //   )


    if (this.newitem == true) {

      // let d={
      //   "phone":postdata.phone.toString(),
      //   "otp" : postdata.otp.toString(),
      //   "country_code":postdata.country_code.toString(),
      //   "beneficiary_name":postdata.beneficiary_name.toString(),
      //   "requester_info": postdata.requester_info.toString(),
      //   "reason_for_hardset_otp":postdata.reason_for_hardset_otp.toString(),
      //   "expiry_date":postdata.expiry_date.toString(),
      //   "is_dummy":postdata.is_dummy.toString(),

      // }
      // 
      this.commonService.postToBlade(postdata,'hardsetotpcreate')
        .subscribe(
          (res: any) => this.onSuccessData(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    } 
    else{

      var postdata1 = this.dataForm.value;
      // let d={
      //   "phone":postdata1.phone.toString(),
      //   "otp" : postdata1.otp.toString(),
      //   "country_code":postdata1.country_code.toString(),
      //   "beneficiary_name":postdata1.beneficiary_name.toString(),
      //   "requester_info": postdata1.requester_info.toString(),
      //   "reason_for_hardset_otp":postdata1.reason_for_hardset_otp.toString(),
      //   "expiry_date":postdata1.expiry_date.toString(), 
      //   "is_dummy":postdata1.is_dummy.toString(),

      // }
      this.commonService.hardsetotpedit( this.userid ,postdata1)
        .subscribe(
          (res: any) => this.onSuccessData(),
          (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
        )
    }

  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  private onSuccessData() {
    this.systemService.showSuccessSnackBar(("New Hardset OTP has been created"), "OK");
    this.router.navigate(['admin/hardsetotp']);
  }

  // ----------------------------


}
