import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError } from 'rxjs/index';
import { catchError, finalize } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../../services/context.service';
import { UserdetailsService } from '../../../services/userdetails/userdetails.service';
import { SocketService } from './../../../services/socket/socket.service';

@Component({
  selector: 'app-labreportdialog',
  templateUrl: './labreportdialog.component.html',
  styleUrls: ['./labreportdialog.component.scss']
})
export class LabreportdialogComponent implements OnInit {
  maxDate = new Date();
  dt = new Date();
  minDate = new Date((this.dt.getFullYear() - 1), this.maxDate.getMonth(), this.maxDate.getDate()); eligibleLogData: any;
  defaultleftoption: any;
  defaultcbcoption: any;
  showsuccess = false;
  rawdata: any;
  showerror = false;
  hasckd: boolean;
  hascholestrol: boolean;
  showButtonLoader: boolean;
  eligibledata: any;
  public bodydata: any = {};
  public selectedUserID: string;
  public loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingError$ = new Subject<boolean>();
  public labreportForm = this.fb.group({
    labtype: [Validators.required],
    cbclabtype: [],
    on_date: [Validators.required],
    val: [""],
    tc: [],
    hdl: [],
    ldl: [],

    t3: [],
    total_t4: [],
    free_t4: [],
    tsh: [],

    triglyceride: [],
    sodium: [],
    potassium: [],
    chloride: [],
    bicarbonate: [],
    neutrophile: [''],
    lymphocyte: [''],
    monocyte: [''],
    eosinophil: [''],
    basophil: [''],
    immature_granulocyte: [''],
    aptt: [''],
    fibrinogen: [''],
    fev1: [''],
    fev1_unit: [''],
    fvc: [''],
    fvc_unit: [''],
    fev1_fvc_ratio: [''],
    measure_val: [''],
    blood_eosinophils: [''],
    sputum_eosinophils: [''],
    absolute_eosinophils: [''],

    MWT: [''],
    serum_bilirubin: [''],
    direct_bilirubin: [''],
    indirect_bilirubin: [''],
    troponin_i: [''],
    troponin_t_high_sensitivity: [''],
    troponin_t: [''],
    inr: [''],
    iron: [''],
    ferritin: [''],
    soluble_transferrin_receptor: [''],
    ast: [''],
    alt: [''],
    alp: [''],
    ggt: [''],
    tag: [''],
    //Abbott
    mes: [''],
    unitsforprotien: ['']


  });
  selectedTag: any;
  cbcData: any;
  minFev1Unit: any;
  maxFev1Unit: any;
  minFvcUnit: any;
  maxFvcUnit: any;
  MCHITagData: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public fb: FormBuilder,
    private UserdataService: UserdetailsService, private systemService: SystemService, private appContext: ContextService, private userDetailService: UserdetailsService,private socketService:SocketService) {
  }

  ngOnInit() {
    this.showsuccess = false;
    this.hasckd = this.data.hasckd;
    this.eligibledata = this.data.eligibledata;
    this.hascholestrol = this.data.hascholestrol;
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getUserEligibleLogs(this.selectedUserID);
    this.getMCHITags()
  }

  getMCHITags() {
    this.userDetailService.getMCHITags().subscribe(
      (res: any) => this.MCHITags(res),
      (err: HttpErrorResponse) => {
        this.systemService.handleErrorResponse(err)
        //this.enableCoachMark = err.status;
      }
    );
  }
  private MCHITags(data) {
    data = (data as any).data;
    let MCHITags: any = data.MCHI_TAGS_FOR_MONTH_VERIFY;
    var myarray = MCHITags.split(',');
    for (var i = 0; i < myarray.length; i++) {
      this.MCHITagData.push(myarray[i]);
    }

  }

  showlabrepo(param) {
    if (this.eligibledata.filter(aa => aa.response_key == param).length > 0) {
      return true
    }
    else {
      return false
    }
  }

  getUserEligibleLogs(id: string) {
    this.userDetailService.getEligibleLogs(id).pipe(
      delay(100),
      catchError((error: any) => {
        this.loadingError$.next(true);
        return throwError(error);
      }),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(data => {
      data = (data as any).data;
      this.eligibleLogData = data;
      this.filteradmincontent(this.eligibleLogData);
      this.loadingError$.next(false);
    });
  }

  filteradmincontent(a) {
    this.eligibleLogData = [];
    for (var j = 0; j < a.length; j++) {
      if (a[j].type == 'lab_report') {
        this.eligibleLogData.push(a[j]);

      }
    }
  }

  fev1Unit(val) {
    if (val == '%') {
      this.minFev1Unit = 0;
      this.maxFev1Unit = 99;
    }
    else if (val == "L") {
      this.minFev1Unit = 0;
      this.maxFev1Unit = 999;
    }
  }

  fvcUnit(val) {
    if (val == '%') {
      this.minFvcUnit = 0;
      this.maxFvcUnit = 99;
    }
    else if (val == "L") {
      this.minFvcUnit = 0;
      this.maxFvcUnit = 999;
    }
  }






  labreportObject() {
    var innerdata;
    var logdate = null;
    var labjson;
    var isvalidvalue = true;
    var newdatetime = this.labreportForm.value.on_date;
    var todaydate = new Date();
    newdatetime.setHours(todaydate.getHours(), todaydate.getMinutes(), todaydate.getSeconds());
    var datum = newdatetime.toUTCString();
    var date = new Date();
    var timestamp: any = date.getTime();
    var logdate = null;
    logdate = moment(datum).toISOString();
    timestamp = Math.floor(timestamp / 1000);
   
   

    /*---- HBA1C -----*/
    if (this.defaultleftoption == 'HbA1C') {
      if ((this.labreportForm.value.val < 2) || (this.labreportForm.value.val > 20)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid HbA1C Value Entered, Valid range is [2-20]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "%"
      }
      labjson = {
        'hba1c': innerdata
      };
    }

    /*---- Cholestrol -----*/
    if (this.defaultleftoption == 'Cholesterol') {
      if (this.labreportForm.value.hdl == null && this.labreportForm.value.hdl == null && this.labreportForm.value.hdl == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.hdl != null) && ((this.labreportForm.value.hdl < 15) || (this.labreportForm.value.hdl > 150))) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid HDL Value Entered, Valid range is [15-150mg/dL]', 'OK');
        }
        if ((this.labreportForm.value.ldl != null) && ((this.labreportForm.value.ldl < 20) || (this.labreportForm.value.ldl > 550))) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid LDL Value Entered, Valid range is [20-550mg/dL]', 'OK');
        }
        if ((this.labreportForm.value.triglyceride != null) && ((this.labreportForm.value.triglyceride < 40) || (this.labreportForm.value.triglyceride > 1000))) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Triglyceride Value Entered, Valid range is [40-1000mg/dL]', 'OK');
        }
        if ((this.labreportForm.value.tc != null) && ((this.labreportForm.value.tc < 1) || (this.labreportForm.value.tc > 999))) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Total Cholesterol Value Entered, Valid range is [1-999mg/dL]', 'OK');

        }
      }
      innerdata = {
        "total_cholesterol": this.labreportForm.value.tc,
        "hdl": this.labreportForm.value.hdl,
        "ldl": this.labreportForm.value.ldl,
        "triglyceride": this.labreportForm.value.triglyceride,
        "total": (this.labreportForm.value.hdl ? this.labreportForm.value.hdl : 0) + (this.labreportForm.value.ldl ? this.labreportForm.value.ldl : 0) + (this.labreportForm.value.triglyceride ? this.labreportForm.value.ldl : 0),
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/dL"
      }
      labjson = {
        'cholesterol': innerdata
      };
    }

    /*---- urine_creatinine -----*/
    if (this.defaultleftoption == 'Urine Creatinine') {

      if ((this.labreportForm.value.val < 400) || (this.labreportForm.value.val > 3000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/ kg/ 24 hr",
        "type": "urine"
      }
      labjson = {
        'creatinine': innerdata
      };
    }

    /*---- serum_creatinine -----*/
    if (this.defaultleftoption == 'Serum Creatinine') {
      if ((this.labreportForm.value.val < 0.1) || (this.labreportForm.value.val > 40)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/dL",
        "type": "serum"
      }
      labjson = {
        'creatinine': innerdata
      };
    }

    /*---- urine_uric_acid -----*/
    if (this.defaultleftoption == 'Urine Uric Acid') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 1500)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/ kg/ 24 hr",
        "type": "urine"
      }
      labjson = {
        'uric_acid': innerdata
      };
    }

    /*---- serum_uric_acid -----*/
    if (this.defaultleftoption == 'Serum Uric Acid') {
      if ((this.labreportForm.value.val < 0.4) || (this.labreportForm.value.val > 20)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/dL",
        "type": "serum"
      }
      labjson = {
        'uric_acid': innerdata
      };
    }


    /*---- blood_urea -----*/
    if (this.defaultleftoption == 'Blood Urea') {
      if ((this.labreportForm.value.val < 3) || (this.labreportForm.value.val > 120)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Blood Urea Value Entered, Valid range is [3-120]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/dL",
      }
      labjson = {
        'blood_urea': innerdata
      };
    }

    if (this.defaultleftoption == 'Pulse Oximetry') {
      if ((this.labreportForm.value.measure_val < 0) || (this.labreportForm.value.measure_val > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Pulse Oximetry Value Entered, Valid range is [0-100%]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.measure_val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "%",
      }
      labjson = {
        'blood_urea': innerdata
      };
    }


    /*---- urine_albumin -----*/
    if (this.defaultleftoption == 'Urine Albumin') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 800)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Urine Albumin Value Entered, Valid range is [0-800mg/24 hr]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/24 hr",
      }
      labjson = {
        'urine_albumin': innerdata
      };
    }

    /*---- eGFR -----*/
    if (this.defaultleftoption == 'eGFR') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 500)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid eGFR Value Entered, Valid range is [1-500 mL/min/1.73 m^2]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mL/min/1.73 m^2",
      }
      labjson = {
        'eGFR': innerdata
      };
    }

    /*---- thyroid_function -----*/
    if (this.defaultleftoption == 'Thyroid Function') {

      if (this.labreportForm.value.t3 == null && this.labreportForm.value.total_t4 == null && this.labreportForm.value.free_t4 == null && this.labreportForm.value.tsh == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.t3 != null) && ((this.labreportForm.value.t3 < 1) || (this.labreportForm.value.t3 > 600))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.total_t4 != null) && ((this.labreportForm.value.total_t4 < 0.1) || (this.labreportForm.value.total_t4 > 30))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.free_t4 != null) && ((this.labreportForm.value.free_t4 < 0.1) || (this.labreportForm.value.free_t4 > 15))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.tsh != null) && ((this.labreportForm.value.tsh < 0.1) || (this.labreportForm.value.tsh > 25))) {
          isvalidvalue = false;
        }
      }
      innerdata = {
        "t3": this.labreportForm.value.t3,
        "total_t4": this.labreportForm.value.total_t4,
        "free_t4": this.labreportForm.value.free_t4,
        "tsh": this.labreportForm.value.tsh,
        "track_id": timestamp,
        "on_date": logdate,
        "t3_unit": "ng/dL",
        "total_t4_unit": "mcg/dL",
        "free_t4_unit": "ng/dL",
        "tsh_unit": "mU/L"
      }
      labjson = {
        'thyroid_function': innerdata
      };
    }


    /*---- Serum Electrolytes -----*/
    if (this.defaultleftoption == 'Electrolytes') {
      if (this.labreportForm.value.sodium == null && this.labreportForm.value.potassium == null && this.labreportForm.value.chloride == null && this.labreportForm.value.bicarbonate == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.sodium != null) && ((this.labreportForm.value.sodium < 40) || (this.labreportForm.value.sodium > 205))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.potassium != null) && ((this.labreportForm.value.potassium < 1.5) || (this.labreportForm.value.potassium > 15))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.chloride != null) && ((this.labreportForm.value.chloride < 50) || (this.labreportForm.value.chloride > 200))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.bicarbonate != null) && ((this.labreportForm.value.bicarbonate < 15) || (this.labreportForm.value.bicarbonate > 40))) {
          isvalidvalue = false;
        }
      }
      innerdata = {
        "sodium": this.labreportForm.value.sodium,
        "potassium": this.labreportForm.value.potassium,
        "chloride": this.labreportForm.value.chloride,
        "bicarbonate": this.labreportForm.value.bicarbonate,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mEq/L"
      }
      labjson = {
        'serum_electrolytes': innerdata
      };
    }

    /*---- Hemoglobin -----*/
    if (this.defaultleftoption == 'Haemoglobin') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 25)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "g/dL"
      }
      labjson = {
        'hemoglobin': innerdata
      };
    }

    /*---- bnp -----*/
    if (this.defaultleftoption == 'BNP') {
      if ((this.labreportForm.value.val < 5) || (this.labreportForm.value.val > 5000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "pgmol/L"
      }
      labjson = {
        'bnp': innerdata
      };
    }

    /*---- nt_pro_bnp -----*/
    if (this.defaultleftoption == 'NT-proBNP') {
      if ((this.labreportForm.value.val < 5) || (this.labreportForm.value.val > 35000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "pg/mL"
      }
      labjson = {
        'nt_pro_bnp': innerdata
      };
    }
    if (this.defaultleftoption == 'Coagulation Factor') {
      // if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 20000)) {
      //   isvalidvalue = false;
      // }
      if (this.labreportForm.value.aptt == null && this.labreportForm.value.fibrinogen == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.aptt != null) && ((this.labreportForm.value.aptt < 18) || (this.labreportForm.value.aptt > 150))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.fibrinogen != null) && ((this.labreportForm.value.fibrinogen < 100) || (this.labreportForm.value.fibrinogen > 900))) {
          isvalidvalue = false;
        }
      }

      innerdata = {
        "aptt": this.labreportForm.value.aptt,
        "fibrinogen": this.labreportForm.value.fibrinogen,
        "on_date": logdate,
        "aptt_unit": "seconds",
        "fibrinogen_unit": "mg/dL"
      }
      labjson = {
        'coagulationFactor': innerdata
      };
    }


    if (this.defaultleftoption == 'Eosinophil Count') {
      if ((this.labreportForm.value.blood_eosinophils < 0) || (this.labreportForm.value.blood_eosinophils > 99)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Blood Eosinophils Value Entered, Valid range is 0%-99%', 'OK');
      }
    
       if((this.labreportForm.value.sputum_eosinophils < 0) || (this.labreportForm.value.sputum_eosinophils > 99) ){
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Sputum Eosinophils Value Entered, Valid range is 0%-99%', 'OK');
       }
      
        if((this.labreportForm.value.absolute_eosinophils < 0) || (this.labreportForm.value.absolute_eosinophils > 20000)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Absolute Eosinophils Value Entered, Valid range is 0-99μL−1', 'OK');
      }
      innerdata = {
        "blood_eosinophils": this.labreportForm.value.blood_eosinophils,
        "blood_eosinophils_unit": '%',
        "sputum_eosinophils": this.labreportForm.value.sputum_eosinophils,
        "sputum_eosinophils_unit": '%',
        "absolute_eosinophils": this.labreportForm.value.absolute_eosinophils,
        "absolute_eosinophils_unit": 'μL−1',
        "on_date": logdate,
      }
      labjson = {
        'coagulationFactor': innerdata
      };
    }

    if (this.defaultleftoption == 'IgE') {
      if ((this.labreportForm.value.measure_val < 20) || (this.labreportForm.value.measure_val > 2000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.measure_val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "IU/ml"
      }
      labjson = {
        'igE': innerdata
      };
    }
    if (this.defaultleftoption == 'FeNO Test/Exhaled Nitric Oxide') {
      if ((this.labreportForm.value.measure_val < 5) || (this.labreportForm.value.measure_val > 300)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.measure_val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "ppb"
      }
      labjson = {
        'FeNO': innerdata
      };
    }



    if (this.defaultleftoption == 'Spirometry') {
      if (this.labreportForm.value.fev1_unit == '%') {
        if ((this.labreportForm.value.fev1 < 0) || (this.labreportForm.value.fev1 > 99)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid FEV1 Value Entered, Valid range is 0%-99%', 'OK');
          return;
        }
      }
      else if (this.labreportForm.value.fev1_unit == 'l') {
        if ((this.labreportForm.value.fev1 < 0) || (this.labreportForm.value.fev1 > 999)) {
          isvalidvalue = false;
        }
      }

      if (this.labreportForm.value.fvc_unit == '%') {
        if ((this.labreportForm.value.fvc < 0) || (this.labreportForm.value.fvc > 99)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid FVC Value Entered, Valid range is 0%-99%', 'OK');
        }
      }
      else if (this.labreportForm.value.fvc_unit == 'l') {
        if ((this.labreportForm.value.fvc < 0) || (this.labreportForm.value.fvc > 999)) {
          isvalidvalue = false;
        }
      }
        if ((this.labreportForm.value.fev1_fvc_ratio < 0) || (this.labreportForm.value.fev1_fvc_ratio > 99)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid fev1_fvc_ratio Value Entered, Valid range is 0%-99%', 'OK');
        }

      innerdata = {
        "fev1": this.labreportForm.value.fev1,
        "fev1_unit": this.labreportForm.value.fev1_unit,
        "fvc": this.labreportForm.value.fvc,
        "fvc_unit": this.labreportForm.value.fvc_unit,
        "fev1_fvc_ratio": this.labreportForm.value.fev1_fvc_ratio,
        "on_date": logdate,
      }
      labjson = {
        'spirometry': innerdata
      };
    }


    if (this.defaultleftoption == 'Bilirubin') {

      if (this.labreportForm.value.serum_bilirubin == null && this.labreportForm.value.direct_bilirubin == null && this.labreportForm.value.indirect_bilirubin == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.serum_bilirubin != null) && ((this.labreportForm.value.serum_bilirubin < 0) || (this.labreportForm.value.serum_bilirubin > 20))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.direct_bilirubin != null) && ((this.labreportForm.value.direct_bilirubin < 0) || (this.labreportForm.value.direct_bilirubin > 20))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.indirect_bilirubin != null) && ((this.labreportForm.value.indirect_bilirubin < 0) || (this.labreportForm.value.indirect_bilirubin > 20))) {
          isvalidvalue = false;
        }
      }
      innerdata = {
        "serum_bilirubin": this.labreportForm.value.serum_bilirubin,
        "direct_bilirubin": this.labreportForm.value.direct_bilirubin,
        "indirect_bilirubin": this.labreportForm.value.indirect_bilirubin,
        "serum_bilirubin_unit": 'mg/dL',
        "direct_bilirubin_unit": 'mg/dL',
        "indirect_bilirubin_unit": 'mg/dL',
        "on_date": logdate,
        "track_id": timestamp,
      }
      labjson = {
        'bilirubin': innerdata
      };
    }

    if (this.defaultleftoption == "Liver Enzymes") {

      if (this.labreportForm.value.ast == null && this.labreportForm.value.alt == null && this.labreportForm.value.alp == null && this.labreportForm.value.ggt == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.ast != null) && ((this.labreportForm.value.ast < 10) || (this.labreportForm.value.ast > 550))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.alt != null) && ((this.labreportForm.value.alt < 1) || (this.labreportForm.value.alt > 1200))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.alp != null) && ((this.labreportForm.value.alp < 20) || (this.labreportForm.value.alp > 900))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.ggt != null) && ((this.labreportForm.value.ggt < 1) || (this.labreportForm.value.ggt > 1200))) {
          isvalidvalue = false;
        }
      }
      innerdata = {
        "ast": this.labreportForm.value.ast,
        "alt": this.labreportForm.value.alt,
        "alp": this.labreportForm.value.alp,
        "ggt": this.labreportForm.value.ggt,
        "ast_unit": 'U/L',
        "alp_unit": 'U/L',
        "alt_unit": 'U/L',
        "ggt_unit": 'U/L',
        "on_date": logdate,
        "track_id": timestamp,
      }
      labjson = {
        'liverenzymes': innerdata
      };
    }


    if (this.defaultleftoption == "Cardiac Troponin") {

      if (this.labreportForm.value.troponin_i == null && this.labreportForm.value.troponin_t == null && this.labreportForm.value.troponin_t_high_sensitivity == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.troponin_i != null) && ((this.labreportForm.value.troponin_i < 0) || (this.labreportForm.value.troponin_i > 20))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.troponin_t != null) && ((this.labreportForm.value.troponin_t < 0) || (this.labreportForm.value.troponin_t > 10))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.troponin_t_high_sensitivity != null) && ((this.labreportForm.value.troponin_t_high_sensitivity < 0) || (this.labreportForm.value.troponin_t_high_sensitivity > 50))) {
          isvalidvalue = false;
        }
      }
      innerdata = {
        "troponin_i": this.labreportForm.value.troponin_i,
        "troponin_t": this.labreportForm.value.troponin_t,
        "troponin_t_high_sensitivity": this.labreportForm.value.troponin_t_high_sensitivity,
        "troponin_i_unit": 'ng/mL',
        "troponin_t_unit": 'ng/mL',
        "troponin_t_high_sensitivity_unit": 'ng/L',
        "on_date": logdate,
        "track_id": timestamp,
      }
      labjson = {
        'cardiactroponin': innerdata
      };
    }


    if (this.defaultleftoption == "Iron Status") {

      if (this.labreportForm.value.iron == null && this.labreportForm.value.ferritin == null && this.labreportForm.value.soluble_transferrin_receptor == null) {
        isvalidvalue = false;
      }
      else {
        if ((this.labreportForm.value.iron != null) && ((this.labreportForm.value.iron < 0) || (this.labreportForm.value.iron > 400))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.ferritin != null) && ((this.labreportForm.value.ferritin < 0) || (this.labreportForm.value.ferritin > 400))) {
          isvalidvalue = false;
        }
        if ((this.labreportForm.value.soluble_transferrin_receptor != null) && ((this.labreportForm.value.soluble_transferrin_receptor < 0) || (this.labreportForm.value.soluble_transferrin_receptor > 10))) {
          isvalidvalue = false;
        }
      }
      this.bodydata = {
        "iron": this.labreportForm.value.iron,
        "ferritin": this.labreportForm.value.ferritin,
        "soluble_transferrin_receptor": this.labreportForm.value.soluble_transferrin_receptor,
        "iron_unit": 'μmol/L',
        "ferritin_unit": 'ng/mL',
        "soluble_transferrin_receptor_unit": 'mg/L',
        "on_date": logdate,
        "track_id": timestamp,
      };
      labjson = {
        'ironstatus': innerdata
      };
    }


    if (this.defaultcbcoption == 'cbc/wbc') {
      if ((this.labreportForm.value.val < 0.2) || (this.labreportForm.value.val > 99)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid cbc/wbc Value Entered, valid range is [0.2x 10^3/μl to 99 x 10^3/μl]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "x 10^3 /μl"
      }
      labjson = {
        'cbc/wbc': innerdata
      };
    }

    if (this.defaultcbcoption == 'cbc/wbcDifferential') {
      if ((this.labreportForm.value.neutrophile < 0.1) || (this.labreportForm.value.neutrophile > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid neutrophile Value Entered, Valid range is 0.1%-100%', 'OK');
        return;
      } else if ((this.labreportForm.value.lymphocyte < 0.1) || (this.labreportForm.value.lymphocyte > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid lymphocyte Value Entered, Valid range is 0.1%-100%', 'OK');
        return;
      } else if ((this.labreportForm.value.monocyte < 0.1) || (this.labreportForm.value.monocyte > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid monocyte Value Entered, Valid range is 0.1%-100%', 'OK');
        return;
      } else if ((this.labreportForm.value.eosinophil < 0.1) || (this.labreportForm.value.eosinophil > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid eosinophil Value Entered, Valid range is 0.1%-100%', 'OK');
        return;
      } else if ((this.labreportForm.value.immature_granulocyte < 0.1) || (this.labreportForm.value.immature_granulocyte > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Immature granulocyte Value Entered, Valid range is 0.1%-100%', 'OK');
        return;
      } else if ((this.labreportForm.value.basophil < 0.1) || (this.labreportForm.value.basophil > 100)) {
        this.systemService.showSnackBar('Invalid basophil Value Entered, Valid range is 0.1%-100%', 'OK');
        return;
      }

      innerdata = {
        'neutrophile': this.labreportForm.value.neutrophile,
        'lymphocyte': this.labreportForm.value.lymphocyte,
        'monocyte': this.labreportForm.value.monocyte,
        'eosinophil': this.labreportForm.value.eosinophil,
        'basophil': this.labreportForm.value.basophil,
        'immature_granulocyte': this.labreportForm.value.immature_granulocyte,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "%"
      }
      labjson = {
        'cbc/wbcDifferential': innerdata
      };
    }

    if (this.defaultcbcoption == 'cbc/rbc') {
      if ((this.labreportForm.value.val < 0.2) || (this.labreportForm.value.val > 8)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "x 10^6 /μl"
      }
      labjson = {
        'cbc/rbc': innerdata
      };
    }
    if (this.defaultcbcoption == 'cbc/hematocrit') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 100)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "%"
      }
      labjson = {
        'cbc/hematocrit': innerdata
      };
    }
    if (this.defaultcbcoption == 'cbc/mcv') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 200)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "fL"
      }
      labjson = {
        'cbc/mcv': innerdata
      };
    }
    if (this.defaultcbcoption == 'cbc/mch') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 999)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Mean corpuscular hemoglobin Value Entered, Valid range is [1pg - 999pg]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "pg"
      }
      labjson = {
        'cbc/mch': innerdata
      };
    }

    if (this.defaultcbcoption == 'cbc/mchc') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Mean corpuscular hemoglobin concentration Value Entered, Valid range is [1pg - 100pg]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "g/dl"
      }
      labjson = {
        'cbc/mchc': innerdata
      };
    }
    if (this.defaultcbcoption == 'cbc/rdw') {
      if ((this.labreportForm.value.val < 1) || (this.labreportForm.value.val > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Red cell distribution width Value Entered, Valid range is [1fl - 100fl]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "fL"
      }
      labjson = {
        'cbc/rdw': innerdata
      };
    }

    if (this.defaultcbcoption == 'cbc/meanPlateletVolume') {
      if ((this.labreportForm.value.val < 0.2) || (this.labreportForm.value.val > 9999)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid cbc/meanPlateletVolume Value Entered, Valid range is [0.2  - 9999fL]', 'OK');
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "fL"
      }
      labjson = {
        'cbc/meanPlateletVolume': innerdata
      };
    }
    if (this.defaultcbcoption == 'cbc/esr') {
      if ((this.labreportForm.value.val < 0.1) || (this.labreportForm.value.val > 999)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid ESR  Value Entered, Valid range is [0.1mm/hr  - 999mm/hr]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mm/hr"
      }
      labjson = {
        'cbc/esr': innerdata
      };
    }
    if (this.defaultcbcoption == 'cbc/plateletCount') {
      if ((this.labreportForm.value.val < 10) || (this.labreportForm.value.val > 1000)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid plateletCount Value Entered, Valid range is [10-1000]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "x 10^3 /μl"
      }
      labjson = {
        'cbc/plateletCount': innerdata
      };
    }


    if (this.defaultleftoption == '6MWT') {
      if ((this.labreportForm.value.MWT < 0) || (this.labreportForm.value.MWT > 1000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.MWT,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "meter"
      }
      labjson = {
        '6mwt': innerdata
      };
    }


    if (this.defaultleftoption == 'Alpha-fetoprotein AFP') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 1000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "ng/ml"
      }
      labjson = {
        'afp': innerdata
      };
    }

    if (this.defaultleftoption == 'Free Beta HCG - Human chorionic gonadotropin') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 90000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "mIU/ml"
      }
      labjson = {
        'betahcg': innerdata
      };
    }
    if (this.defaultleftoption == 'Estriol Free') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 50)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "ng/ml"
      }
      labjson = {
        'estriol': innerdata
      };
    }
    if (this.defaultleftoption == 'Inhibin A') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 1000)) {
        this.systemService.showSnackBar('Invalid Inhibin A Value Entered, Valid range is [0 - 1000pg/ml]', 'OK');
        isvalidvalue = false;
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "pg/ml"
      }
      labjson = {
        'inhibina': innerdata
      };
    }
    if (this.defaultleftoption == 'Pregnancy-associated plasma protein-A - PAPPa') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 50)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "mIU/ml"
      }
      labjson = {
        'pappa': innerdata
      };
    }
    if (this.defaultleftoption == 'Urine Glucose') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 9000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "mg/dL"
      }
      labjson = {
        'urineglucose': innerdata
      };
    }
    if (this.defaultleftoption == 'Urine - Protein') {
      if ((this.labreportForm.value.unitsforprotien === 'mg/24hr')) {
        if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 800)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Urine - Protein Value Entered, Valid range is [0 - 800 mg/24hr] ', 'OK');
          return;
        }
      } else if ((this.labreportForm.value.unitsforprotien === 'mg/dL')) {
        if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 9000)) {
          isvalidvalue = false;
          this.systemService.showSnackBar('Invalid Urine - Protein Value Entered, Valid range is [0 - 9000 mg/dL]', 'OK');
          return;
        }
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": this.labreportForm.value.unitsforprotien
      }
      labjson = {
        'urineprotein': innerdata
      };
    }

    if (this.defaultleftoption == 'AMH Anti-Müllerian Hormone') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 100)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "ng/mL"
      }
      labjson = {
        'amh': innerdata
      };
    }
    if (this.defaultleftoption == 'Antral follicular count') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 80)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Antral follicular count Value Entered, Valid range is [0 - 80]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "number"
      }
      labjson = {
        'afc': innerdata
      };
    }
    if (this.defaultleftoption == 'FSH Follicle-stimulating hormone') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 500)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid FSH Follicle-stimulating hormone Value Entered, Valid range is [0 - 500 mIU/ml]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "mIU/ml"
      }
      labjson = {
        'fsh': innerdata
      };
    }
    if (this.defaultleftoption == 'Estradiol') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 9000)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "pg/ml"
      }
      labjson = {
        'estradiol': innerdata
      };


    }

    if (this.defaultleftoption == 'Inhibin B') {
      if ((this.labreportForm.value.mes < 0) || (this.labreportForm.value.mes > 1000)) {
        this.systemService.showSnackBar('Invalid Inhibin B Value Entered, Valid range is [0 - 1000pg/ml]', 'OK');
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.mes,
        "on_date": logdate,
        "unit": "pg/ml"
      }
      labjson = {
        'inhibinb': innerdata
      };
    }

    if (this.defaultleftoption == "International Normalised Ratio") {
      if ((this.labreportForm.value.inr < 0) || (this.labreportForm.value.inr > 10)) {
        isvalidvalue = false;
      }
      innerdata = {
        "value": this.labreportForm.value.inr,
        "on_date": logdate,
        "unit": ""
      }
      labjson = {
        'inr': innerdata
      };
    }

    if (this.defaultleftoption == 'Blood urea nitrogen') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Blood urea nitrogen Value Entered, Valid range is [0 - 100 mg/dL]', 'OK');
        return;
      
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/dL",
      }
      labjson = {
        'blood_urea_nitrogen': innerdata
      };
    }
    if (this.defaultleftoption == 'CRP') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 1000)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid CRP Value Entered, Valid range is [0 - 1000 mg/L]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/L",
      }
      labjson = {
        'crp': innerdata
      };
    }
    if (this.defaultleftoption == 'Albumin') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Albumin Value Entered, Valid range is [0 - 100 mg/dL]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "mg/dL",
      }
      labjson = {
        'albumin': innerdata
      };
    }
    if (this.defaultleftoption == 'Anti Cyclic Citrullinated Peptide antibodies') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 1000)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Anti Cyclic Citrullinated Peptide antibodies Value Entered, Valid range is [0 - 1000 EU/mL]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "EU/mL",
      }
      labjson = {
        'antiCCP': innerdata
      };
    }
    if (this.defaultleftoption == 'Rheumatoid Factor') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 100)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('Invalid Rheumatoid Factor Value Entered, Valid range is [0 - 100 IU/mL]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "IU/mL",
      }
      labjson = {
        'rheumatoidFactor': innerdata
      };
    }
    if (this.defaultleftoption == 'Antinuclear antibody') {
      if ((this.labreportForm.value.val < 0) || (this.labreportForm.value.val > 1000)) {
        isvalidvalue = false;
        this.systemService.showSnackBar('InvalidAntinuclear antibody Value Entered, Valid range is [0 - 1000 unit]', 'OK');
        return;
      }
      innerdata = {
        "value": this.labreportForm.value.val,
        "track_id": timestamp,
        "on_date": logdate,
        "unit": "unit",
      }
      labjson = {
        'ana': innerdata
      };
    }



    /*---- heart_rate -----*/
    /* if (this.defaultleftoption == 'heart_rate') {
     if ((this.labreportForm.value.val < 30) || (this.labreportForm.value.val > 250)) {
       isvalidvalue = false;
     }
     innerdata = {
       "value": this.labreportForm.value.val,
       "track_id": timestamp,
       "on_date": datum,
       "unit" : "bpm"
     }
     labjson = {
       'heart_rate': [innerdata]
     };
   } */

    var scope = this;
    if (isvalidvalue == false) {
      scope.showerror = true;
      this.validationMessageTimer();
    }
    else {
      scope.showerror = false;
      this.showButtonLoader = true;

      if (this.defaultleftoption == "HbA1C") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey,
          tag: this.labreportForm.value.tag
        };
      }
      else if (this.defaultleftoption == "Cholesterol") {
        this.bodydata = {
          total_cholesterol: innerdata.total_cholesterol,
          hdl: innerdata.hdl,
          ldl: innerdata.ldl,
          triglycerides: innerdata.triglyceride,
          hdl_unit: innerdata.unit,
          ldl_unit: innerdata.unit,
          triglycerides_unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey,
          tag: this.labreportForm.value.tag,
          total_cholesterol_unit: innerdata.unit
        };
      }
      else if (this.defaultleftoption == "Urine Creatinine") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Serum Uric Acid") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Urine Uric Acid") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Serum Creatinine") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Blood Urea") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Urine Albumin") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "eGFR") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Spirometry") {
        this.bodydata = {
          fev1: innerdata.fev1,
          fev1_unit: innerdata.fev1_unit,
          fvc: innerdata.fvc,
          fvc_unit: innerdata.fvc_unit,
          fev1_fvc_ratio: innerdata.fev1_fvc_ratio,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "Eosinophil Count") {
        this.bodydata = {
          blood_eosinophils: innerdata.blood_eosinophils,
          blood_eosinophils_unit: innerdata.blood_eosinophils_unit,
          sputum_eosinophils: innerdata.sputum_eosinophils,
          sputum_eosinophils_unit: innerdata.sputum_eosinophils_unit,
          absolute_eosinophils: innerdata.absolute_eosinophils,
          absolute_eosinophils_unit: innerdata.absolute_eosinophils_unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "Thyroid Function") {
        this.bodydata = {
          t3: innerdata.t3,
          t3_unit: innerdata.t3_unit,
          total_t4: innerdata.total_t4,
          total_t4_unit: innerdata.total_t4_unit,
          free_t4: innerdata.free_t4,
          free_t4_unit: innerdata.free_t4_unit,
          tsh: innerdata.tsh,
          free_tsh: innerdata.unit,
          tsh_unit: innerdata.tsh_unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Electrolytes") {
        this.bodydata = {
          sodium: innerdata.sodium,
          potassium: innerdata.potassium,
          chloride: innerdata.chloride,
          bicarbonate: innerdata.bicarbonate,
          sodium_unit: innerdata.unit,
          potassium_unit: innerdata.unit,
          chloride_unit: innerdata.unit,
          bicarbonate_unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey

        };
      }
      else if (this.defaultleftoption == "Haemoglobin") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "BNP") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "FeNO Test/Exhaled Nitric Oxide") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Pulse Oximetry") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "NT-proBNP") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/wbc") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/rbc") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/rbc") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Coagulation Factor") {
        this.bodydata = {
          aptt: innerdata.aptt,
          aptt_unit: innerdata.aptt_unit,
          fibrinogen: innerdata.fibrinogen,
          fibrinogen_unit: innerdata.fibrinogen_unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultcbcoption == "cbc/hematocrit") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultcbcoption == "cbc/wbcDifferential") {
        this.bodydata = {
          neutrophile: innerdata.neutrophile,
          lymphocyte: innerdata.lymphocyte,
          monocyte: innerdata.monocyte,
          eosinophil: innerdata.neutrophile,
          basophil: innerdata.neutrophile,
          immature_granulocyte: innerdata.immature_granulocyte,
          neutrophile_unit: innerdata.unit,
          lymphocyte_unit: innerdata.unit,
          monocyte_unit: innerdata.unit,
          eosinophil_unit: innerdata.unit,
          basophil_unit: innerdata.unit,
          immature_granulocyte_unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/mcv") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/mch") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/mchc") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/rdw") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/plateletCount") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/esr") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultcbcoption == "cbc/meanPlateletVolume") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "IgE") {
        this.bodydata = {

          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultcbcoption == "cbc/meanPlateletVolume") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "6MWT") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "Bilirubin") {
        this.bodydata = {
          serum_bilirubin: innerdata.serum_bilirubin,
          direct_bilirubin: innerdata.direct_bilirubin,
          indirect_bilirubin: innerdata.indirect_bilirubin,
          serum_bilirubin_unit: innerdata.serum_bilirubin_unit,
          direct_bilirubin_unit: innerdata.direct_bilirubin_unit,
          indirect_bilirubin_unit: innerdata.indirect_bilirubin_unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "Cardiac Troponin") {
        this.bodydata = {
          troponin_i: innerdata.troponin_i,
          troponin_t: innerdata.troponin_t,
          troponin_t_high_sensitivity: innerdata.troponin_t_high_sensitivity,
          troponin_i_unit: innerdata.troponin_i_unit,
          troponin_t_unit: innerdata.troponin_t_unit,
          troponin_t_high_sensitivity_unit: innerdata.troponin_t_high_sensitivity_unit,
          log_date: logdate,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "International Normalised Ratio") {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Iron Status") {
        this.bodydata = {
          iron: this.labreportForm.value.iron,
          ferritin: this.labreportForm.value.ferritin,
          soluble_transferrin_receptor: this.labreportForm.value.soluble_transferrin_receptor,
          iron_unit: 'μmol/L',
          ferritin_unit: 'ng/mL',
          soluble_transferrin_receptor_unit: 'mg/L',
          log_date: logdate,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Liver Enzymes") {
        this.bodydata = {
          ast: innerdata.ast,
          alt: innerdata.alt,
          alp: innerdata.alp,
          ggt: innerdata.ggt,
          ast_unit: innerdata.ast_unit,
          alp_unit: innerdata.alp_unit,
          alt_unit: innerdata.alt_unit,
          ggt_unit: innerdata.ggt_unit,
          log_date: logdate,
          patient_id_data_fk: this.data.dataKey
        };
      }

      //Abbott
      else if (this.defaultleftoption == "Alpha-fetoprotein AFP") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }

      else if (this.defaultleftoption == "Free Beta HCG - Human chorionic gonadotropin") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }


      else if (this.defaultleftoption == "Estriol Free") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Inhibin A") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Pregnancy-associated plasma protein-A - PAPPa") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Urine Glucose") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Urine - Protein") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "AMH Anti-Müllerian Hormone") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Antral follicular count") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Estradiol") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "FSH Follicle-stimulating hormone") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == "Inhibin B") {
        this.bodydata = {
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          measure: innerdata.value,
          patient_id_data_fk: this.data.dataKey
        };
      } else if (this.defaultleftoption == 'Blood urea nitrogen') {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == 'CRP') {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == 'Albumin') {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == 'Anti Cyclic Citrullinated Peptide antibodies') {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == 'Rheumatoid Factor') {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }
      else if (this.defaultleftoption == 'Antinuclear antibody') {
        this.bodydata = {
          measure: innerdata.value,
          unit: innerdata.unit,
          log_date: innerdata.on_date,
          patient_id_data_fk: this.data.dataKey
        };
      }

      if (this.defaultleftoption == 'Urine Creatinine') {
        this.defaultleftoption = 'urineCreatinine';
      }
      else if (this.defaultleftoption == 'Serum Uric Acid') {
        this.defaultleftoption = 'serumUricAcid';
      }
      else if (this.defaultleftoption == 'Urine Uric Acid') {
        this.defaultleftoption = 'urineUricAcid'
      }
      else if (this.defaultleftoption == 'Blood Urea') {
        this.defaultleftoption = 'bloodUrea'
      }
      else if (this.defaultleftoption == 'Serum Creatinine') {
        this.defaultleftoption = 'serumCreatinine';
      }
      else if (this.defaultleftoption == 'Urine Albumin') {
        this.defaultleftoption = 'urineAlbumin'
      }
      else if (this.defaultleftoption == 'eGFR') {
        this.defaultleftoption = 'egfr'
      }
      else if (this.defaultleftoption == 'Haemoglobin') {
        this.defaultleftoption = 'haemoglobin';
      }
      else if (this.defaultleftoption == 'BNP') {
        this.defaultleftoption = 'bnp'
      }
      else if (this.defaultleftoption == 'NT-proBNP') {
        this.defaultleftoption = 'ntProBnp'
      }

      else if (this.defaultleftoption == 'Cholesterol') {
        this.defaultleftoption = 'cholesterol';
      }
      else if (this.defaultleftoption == 'Thyroid Function') {
        this.defaultleftoption = 'thyroidFunction'
      }
      else if (this.defaultleftoption == 'Electrolytes') {
        this.defaultleftoption = 'electrolytes'
      }
      else if (this.defaultleftoption == 'Serum Creatinine') {
        this.defaultleftoption = 'serumCreatinine';
      }
      else if (this.defaultleftoption == 'Coagulation Factor') {
        this.defaultleftoption = 'coagulationFactor'
      }
      else if (this.defaultleftoption == 'eGFR') {
        this.defaultleftoption = 'egfr'
      }
      else if (this.defaultleftoption == 'Spirometry') {
        this.defaultleftoption = 'spirometry';
      }
      else if (this.defaultleftoption == 'IgE') {
        this.defaultleftoption = 'igE'
      }
      else if (this.defaultleftoption == 'Eosinophil Count') {
        this.defaultleftoption = 'eosinophilCount'
      }
      else if (this.defaultleftoption == 'FeNO Test/Exhaled Nitric Oxide') {
        this.defaultleftoption = 'FeNO'
      }
      else if (this.defaultleftoption == 'Pulse Oximetry') {
        this.defaultleftoption = 'pulseOximetry'
      }

      else if (this.defaultcbcoption == "cbc/meanPlateletVolume") {
        this.defaultleftoption = 'cbc/meanPlateletVolume'
      }

      else if (this.defaultcbcoption == "cbc/wbcDifferential") {
        this.defaultleftoption = 'cbc/wbcDifferential'
      }
      else if (this.defaultcbcoption == "cbc/rbc") {
        this.defaultleftoption = 'cbc/rbc'
      }
      else if (this.defaultcbcoption == "cbc/hematocrit") {
        this.defaultleftoption = 'cbc/hematocrit'
      }
      else if (this.defaultcbcoption == "cbc/mcv") {
        this.defaultleftoption = 'cbc/mcv'
      }
      else if (this.defaultcbcoption == "cbc/plateletCount") {
        this.defaultleftoption = 'cbc/plateletCount'
      }
      else if (this.defaultcbcoption == "cbc/mch") {
        this.defaultleftoption = 'cbc/mch'
      }
      else if (this.defaultcbcoption == "cbc/mchc") {
        this.defaultleftoption = 'cbc/mchc'
      }
      else if (this.defaultcbcoption == "cbc/rdw") {
        this.defaultleftoption = 'cbc/rdw'
      }
      else if (this.defaultcbcoption == "cbc/esr") {
        this.defaultleftoption = 'cbc/esr'
      }
      else if (this.defaultcbcoption == "cbc/wbc") {
        this.defaultleftoption = 'cbc/wbc'
      }
      else if (this.defaultleftoption == "6MWT") {
        this.defaultleftoption = '6mwt'
      }

      else if (this.defaultleftoption == "Bilirubin") {
        this.defaultleftoption = 'bilirubin'
      }
      else if (this.defaultleftoption == "Cardiac Troponin") {
        this.defaultleftoption = 'cardiactroponin'
      }
      else if (this.defaultleftoption == "International Normalised Ratio") {
        this.defaultleftoption = 'inr'
      }
      else if (this.defaultleftoption == "Iron Status") {
        this.defaultleftoption = 'ironstatus'
      }
      else if (this.defaultleftoption == "Liver Enzymes") {
        this.defaultleftoption = 'liverenzymes'
      }
      if (this.defaultleftoption == 'Alpha-fetoprotein AFP') {
        this.defaultleftoption = 'afp'
      }
      if (this.defaultleftoption == 'Free Beta HCG - Human chorionic gonadotropin') {
        this.defaultleftoption = 'betahcg'
      }
      if (this.defaultleftoption == 'Estriol Free') {
        this.defaultleftoption = 'estriol'
      }
      if (this.defaultleftoption == 'Inhibin A') {
        this.defaultleftoption = 'inhibina'
      }
      if (this.defaultleftoption == 'Pregnancy-associated plasma protein-A - PAPPa') {
        this.defaultleftoption = 'pappa'
      }
      if (this.defaultleftoption == 'Urine Glucose') {
        this.defaultleftoption = 'urineglucose'
      }
      if (this.defaultleftoption == 'Urine - Protein') {
        this.defaultleftoption = 'urineprotein'
      }
      if (this.defaultleftoption == 'AMH Anti-Müllerian Hormone') {
        this.defaultleftoption = 'amh'
      }
      if (this.defaultleftoption == 'Antral follicular count') {
        this.defaultleftoption = 'afc'
      }
      if (this.defaultleftoption == 'Estradiol') {
        this.defaultleftoption = 'estradiol'
      }
      if (this.defaultleftoption == 'FSH Follicle-stimulating hormone') {
        this.defaultleftoption = 'fsh'
      }
      if (this.defaultleftoption == 'Inhibin B') {
        this.defaultleftoption = 'inhibinb'
      }
      if (this.defaultleftoption == 'Blood urea nitrogen') {
        this.defaultleftoption = 'bloodUreaNitrogen'
      }
      if (this.defaultleftoption == 'CRP') {
        this.defaultleftoption = 'crp'
      }
      if (this.defaultleftoption == 'Albumin') {
        this.defaultleftoption = 'albumin'
      }
      if (this.defaultleftoption == 'Anti Cyclic Citrullinated Peptide antibodies') {
        this.defaultleftoption = 'antiCCP'
      }
      if (this.defaultleftoption == 'Rheumatoid Factor') {
        this.defaultleftoption = 'rheumatoidFactor'
      }
      if (this.defaultleftoption == 'Antinuclear antibody') {
        this.defaultleftoption = 'ana'
      }

      // 
      this.bodydata['logged_by'] = 'hc'
      this.bodydata['created_by_fk'] = localStorage.getItem('scarletuserid');


      this.UserdataService.postLabReport(this.defaultleftoption, this.bodydata, this.data.dataKey).pipe(
        catchError((error: any) => {
          this.showButtonLoader = false;
          this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
          return throwError(error);
        }),
        finalize(() => {
          this.showButtonLoader = false;
        })
      ).subscribe(
        (data) => {
          scope.dialog.closeAll();
          this.systemService.showSnackBar("Report Value added.", "OK");
          this.rawdata = data;
          this.showButtonLoader = false;
          this.labreportForm.reset();
          this.sendMqttmsg();
        })
    }


  }
  changeLabOptions() {
    this.selectedTag = this.labreportForm.value.labtype;
    this.showerror = false;
    if(this.defaultleftoption !=='Complete Blood Count'){
      this.defaultcbcoption = '';
      }

  }

  validationMessageTimer() {
    var scope = this;
    setTimeout(() => {
      scope.showerror = false

    }, 5000);
  }

  onNoClick(): void {
    this.dialog.closeAll();
  }

  changeTextInList(type) {
    if (type == '6MWT') {
      return "6 Minutes Walk Test"
    } else if (type == 'BNP') {
      return 'Brain Natriuretic Peptide'
    }
    else if (type == 'eGFR') {
      return 'Estimated Glomerular Filtration Rate'
    }
    else if (type == 'IgE') {
      return 'Immunoglobulin E (IgE)'
    }
    else {
      return type
    }
  }
  sendMqttmsg(){
    let convID = (this.appContext.selectedChatUserRowData.getValue() as any).chatMetadata[0].conv_id;
    let datenew;
    let healthCoachId = localStorage.getItem('scarletuserid');
    var hccode = 'H' + (healthCoachId).toString();
    var ucode = 'U' + (this.selectedUserID).toString();
    datenew = new Date();
    datenew = datenew * 1;
    let newmessagedata = {
      conversationId: convID,
      sender: hccode,
      clientId: this.socketService.clientid,
      participants: [hccode,  ucode],
      body: '',
      attachment: {
          link: '',
          type: "deeplink",
          value: 'Diary',
          additionalData: 'Diary Refresh',
      },
      trackId: Math.random().toString(36).slice(2).substr(0, 7) + datenew,
      mimeType: 'text/plain',
      type: 'deeplink',
      replyTo: '',
  };
  this.socketService.emitNewMessage(newmessagedata).subscribe(data => {
  });        
  }
}
