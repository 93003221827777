/*==========================================
Title: created_by_data_fk changes for Log Mapping
Author: Soundharya AM
Date:   May 4, 2020
Last Change : created_by_data_fk changes for Log Mapping
===========================================*/

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { whiteSpaceValidation } from '../../../utility/validation';
import { ContentserviceService } from '../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-createloggingmap',
  templateUrl: './createloggingmap.component.html',
  styleUrls: ['./createloggingmap.component.scss']
})

export class CreateloggingmapComponent implements OnInit {
  public newitem: boolean = true;
  public showButtonLoader: any;
  public showAllcondition: boolean = false;
  public clientid: any;
  private myhash: any = {};
  private myidhash: any = {};
  public alllogs: any;
  public conditionid: any;
  public mappingid: any;
  public donepatcharr: any;
  todo = [];
  done = [];
  allconditions: any;
  loggingData: any;
  public dataForm = this.fb.group({
    condition_id_data_fk: ["", [Validators.required, whiteSpaceValidation]],
    log_ids: this.fb.array([]),
    created_by_data_fk: ['5']
  });

  constructor(private browserTitle: Title, private router: Router, public fb: FormBuilder, private contentService: ContentserviceService, private systemService: SystemService, private route: ActivatedRoute) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Update Logging Map');
  }

  ngOnInit() {
    this.setSEOTags();
    this.getListedSymptomConditions();
    this.getConditions();
    this.route.params.subscribe(params => {
      this.clientid = params.id;
      this.conditionid = params.condid;
      this.mappingid = params.mappingid;
      if (params.condid == 'new') {
        this.newitem = true;
      }
      else {
        this.newitem = false;
        const conditionidval = <FormControl>this.dataForm.controls['condition_id_data_fk'];
        conditionidval.setValue(this.conditionid);
        this.getLogFlow(this.conditionid);
      }
    });
  
  }

  getListedSymptomConditions() {
    this.contentService.current__symptomData.subscribe(
      (response: any) => {
        this.loggingData = response;
      },
      (error: any) => {

      },
      () => { }
    )
  }


  checkSymptom(id) {
    for (let i = 0; i < this.loggingData.length; i++) {
      // 
      if (id == this.loggingData[i].condition_data_fk) {
        return true;
      }
    }
  }


  // To Get Conditions
  // ----------------------------

  getConditions() {
    this.contentService.getGraphQlData('{getAllConditions(appMode:false,status:true){id,title}}', false)
      .subscribe(
        (res: any) => this.getConditionSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getConditionSuccess(data) {
    this.allconditions = data.getAllConditions;
    // let conditions: any = []
    // // this.allconditions = data.getAllConditions;
    // conditions = data.getAllConditions;
    // let a: any = [];
    // let b: any = [];
    // a = conditions;
    // b = this.loggingData;
    // const isSameUser = (a, b) => a.title === b.condition_title;
    // const onlyInLeft = (left, right, compareFunction) =>
    //   left.filter(leftValue =>
    //     !right.some(rightValue =>
    //       compareFunction(leftValue, rightValue)));
    // const onlyInA = onlyInLeft(a, b, isSameUser);
    // const onlyInB = onlyInLeft(b, a, isSameUser);
    // const result = [...onlyInA, ...onlyInB];
    // this.allconditions = result;
  }

  // ----------------------------


  // To Get Log Flow
  // ----------------------------

  getLogFlow(conditionid) {
    this.contentService.getLogPriorityForCondition(conditionid)
      .subscribe(
        (res: any) => this.getLogFlowSuccess(conditionid, (res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getLogFlowSuccess(conditionid, data) {
    this.donepatcharr = data;
    this.dataForm.patchValue(this.donepatcharr);
    for (var k = 0; k < this.donepatcharr.length; k++) {
      this.done.push({
        id: this.donepatcharr[k].id,
        title: this.donepatcharr[k].title
      })
    }
    this.getLogs(conditionid);
  }

  // ----------------------------


  // To Get Logs
  // ----------------------------

  getLogs(condid) {
    this.contentService.getAllLogsForCondition(condid)
      .subscribe(
        (res: any) => this.getLogsSuccess((res as any).data),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      );
  }

  private getLogsSuccess(data) {
    this.alllogs = data;
    if (this.newitem == false) {
      for (let k = 0; k < this.donepatcharr.length; k++) {
        this.alllogs = this.alllogs.filter(a => a.id != this.donepatcharr[k].id);
      }
      this.alllogs.forEach(element => {
        this.myhash[element.id] = element.title;
        this.myidhash[element.title] = element.id;
      });
      this.todo = this.alllogs;
    }
    else {
      this.todo = this.alllogs;
      this.alllogs.forEach(element => {
        this.myhash[element.id] = element.title;
        this.myidhash[element.title] = element.id;
      });
    }
  }

  // ----------------------------

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  // Submitting Logging Flow
  // ----------------------------

  dataObject() {
    var postdata = this.dataForm.value;
    postdata.log_ids = [];
    postdata.condition_id_data_fk = postdata.condition_id_data_fk.toString();
    postdata.created_by_data_fk = localStorage.getItem('scarletuserid').toString();
    this.done.forEach(el => {
      postdata.log_ids.push(el.id);
    });
    postdata.log_ids = JSON.stringify(postdata.log_ids);
    if (this.done.length > 0) {
      if (this.newitem == true) {
        this.contentService.createLogPriority(postdata)
          .subscribe(
            (res: any) => this.onCreateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          );
      }
      else {
        delete postdata.created_by_data_fk;
        this.contentService.updateLogPriority(postdata, this.mappingid)
          .subscribe(
            (res: any) => this.onUpdateSuccess(),
            (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
          );
      }
    }
    else {
      this.systemService.showSnackBar(("Please drag the logs to the right"), "OK");
    }
  }

  private onCreateSuccess() {
    this.systemService.showSuccessSnackBar(("Logging flow has been prepared"), "OK");
    this.router.navigate(['content/logging/mapping/']);
  }

  private onUpdateSuccess() {
    this.systemService.showSuccessSnackBar(("Logging flow has been updated"), "OK");
    this.router.navigate(['content/logging/mapping/']);
  }

  // ----------------------------

}

