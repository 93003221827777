import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardGuard } from '../shared/guards';
import { AdminprofileComponent } from './shared/adminprofile/adminprofile.component';

const routes: Routes = [
  { path: 'settings', component: AdminprofileComponent, canActivate: [AdminGuardGuard] },
  { path: 'hchome', loadChildren: () => import('./hcassign/hcassignhome/hcassignhome.module').then(m => m.HcassignhomeModule), canActivate: [AdminGuardGuard] },
  { path: 'hcuserassign', loadChildren: () => import('./hcassign/hcuserassign/hcuserassign.module').then(m => m.HcuserassignModule), canActivate: [AdminGuardGuard] },
  { path: 'users', loadChildren: () => import('./adminsection/adminusers.module').then(m => m.AdminusersModule), canActivate: [AdminGuardGuard] },

  { path: 'profile', loadChildren: () => import('./shared/userprofileview/userprofileview.module').then(m => m.UserprofileviewModule), canActivate: [AdminGuardGuard] },
  { path: 'client', loadChildren: () => import('./hcassign/client/client.module').then(m => m.ClientModule), canActivate: [AdminGuardGuard] },
  { path: 'codegenerate', loadChildren: () => import('./hcassign/activation/activation.module').then(m => m.ActivationModule), canActivate: [AdminGuardGuard] },

  { path: 'campaign', loadChildren: () => import('./hcassign/campaign/campaign.module').then(m => m.CampaignModule), canActivate: [AdminGuardGuard] },
  { path: 'doctors', loadChildren: () => import('./doctor/doctor.module').then(m => m.DoctorModule), canActivate: [AdminGuardGuard] },
  { path: 'changepassword', loadChildren: () => import('./shared/changepassword/changepassword.module').then(m => m.ChangepasswordModule), canActivate: [AdminGuardGuard] },
  { path: 'patients', loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule), canActivate: [AdminGuardGuard] },
  { path: 'hardsetotp', loadChildren: () => import('./hardsetotp/hardsetotp.module').then(m => m.HardsetotpModule), canActivate: [AdminGuardGuard] },
  { path: 'app-master', loadChildren: () => import('./app-master/app-master.module').then(m => m.AppMasterModule), canActivate: [AdminGuardGuard] },
  { path: 'publishrequests', loadChildren: () => import('./publish-request/publish-request.module').then(m => m.PublishRequestModule), canActivate: [AdminGuardGuard] },
  { path: 'settings/userprofile', loadChildren: () => import('./profileimage/profileimage.module').then(m => m.ProfileimageModule), canActivate: [AdminGuardGuard] },
  { path: 'activationsearch', loadChildren: () => import('./activationsearch/activationsearch.module').then(m => m.ActivationsearchModule), canActivate: [AdminGuardGuard] },
  { path: 'payment', loadChildren: () => import('./paymentplan/paymentplan.module').then(m => m.PaymentplanModule), canActivate: [AdminGuardGuard] },
  { path: 'paymentmapping', loadChildren: () => import('./paymentmapping/paymentmapping.module').then(m => m.PaymentmappingModule), canActivate: [AdminGuardGuard] },

  { path: 'tasklibrary', loadChildren: () => import('./tasklibrary/tasklibrary.module').then(m => m.TasklibraryModule), canActivate: [AdminGuardGuard] },
  { path: 'taskadmin', loadChildren: () => import('./taskadmin/taskadmin.module').then(m => m.TaskadminModule), canActivate: [AdminGuardGuard] },
  { path: 'taskrequest', loadChildren: () => import('./taskrequest/taskrequest.module').then(m => m.TaskrequestModule), canActivate: [AdminGuardGuard] },

  { path: 'rawdata', loadChildren: () => import('./shared/googleapplerawdata/googleapplerawdata.module').then(m => m.GoogleapplerawdataModule), canActivate: [AdminGuardGuard] },

  { path: 'mobilechange', loadChildren: () => import ('./mobile-change-request/mobile-change-request.module').then(m => m.MobileChangeRequestModule), canActivate: [AdminGuardGuard]},

  { path: 'apilogs', loadChildren: () => import ('./api-logs/api-logs.module').then(m => m.APILogsModule), canActivate: [AdminGuardGuard]}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }