
<div class="profile-wrapper">
  <div class="profile-info clearfix">
    <div class="media">
      <div class="media-body media-middle">
        <img src="https://media.glassdoor.com/sqll/1596461/wellthy-therapeutics-squarelogo-1556794579439.png"
          class="img-responsive">
        <h4 class="value">{{!userView.name?'-':userView.name}}</h4>


        <div class="profile-content">
          <p class="value">{{!userView.email?'-':userView.email}}</p>
        </div>
      </div>
    </div>
    <div class="close-icon">
      <i class="material-icons mat-dialog-close notranslate" (click)="onNoClick()">
        close
      </i>
    </div>
  </div>

  <div class="hc-details">
    <div class="text-over">User Details</div>
    <div class="row">
      <div class="col-md-4">
        <div class="location">
          <h5 class="text-uppercase">STATUS</h5>
          <p class="camelcased">{{patient_status}}</p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="about-me">
          <h5 class="text-uppercase">phone</h5>
          <p>+{{!userView.country_code?'-':userView.country_code}}-{{!userView.phone?'-':userView.phone}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="about-me">
          <h5 class="text-uppercase">therapy</h5>
          <p>{{userView.therapy_data.name}}</p>
          <p *ngIf="!userView.therapy_data" class="value">-</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="email">
          <h5 class="text-uppercase">Master Condition</h5>
          <p *ngIf="userView.master_condition_data">{{userView.master_condition_data.name}}</p>
          <p *ngIf="!userView.master_condition_data" class="value">-</p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="email">
          <h5 class="text-uppercase">Conditions</h5>
          <span class="condition-data" *ngFor="let condition of userView.secondary_condition_data">{{condition }}</span>
          <p *ngIf="!userView.secondary_condition_data" class="value">-</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="email">
          <h5 class="text-uppercase">Healthcoach</h5>
          <p *ngIf="userView.hc_data">{{userView.hc_data.name}}</p>
          <p *ngIf="!userView.hc_data" class="value">-</p>
        </div>
      </div>
    </div>
  </div>