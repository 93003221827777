<mat-drawer-container class="sidenav-container" autosize>

  <app-docleftnav></app-docleftnav>

  <div class="sidenav-content">
    <div class="col-md-8 col-md-offset-2 hcprofile">

      <div class="media knowusercontent">
        <div class="media-left media-middle">
          <div class="card-avatar">
            <img *ngIf="doctorData?.profile_image?.path" [src]="s3url + doctorData?.profile_image?.path"
              class="hclogo center-block" />
          </div>
        </div>
        <div class="media-body">
          <div class="clearfix">
            <h4 class="media-heading pull-left">{{doctorData?.name}} &nbsp; • &nbsp; {{doctorData?.code}}</h4>
            <div class="jeg_nav_item jeg_dark_mode pull-right">
              <label class="dark_mode_switch">
                <input type="checkbox" class="jeg_dark_mode_toggle" type="checkbox" id="checkbox"
                  [(ngModel)]=isThemeDark (change)="toggleDarkTheme(isThemeDark)">
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <p>{{doctorData?.user_type}} &nbsp;
            • &nbsp; {{doctorData?.phone}}</p>
            <p class="text-capitalize">Hospital &nbsp;
              • &nbsp; <span *ngFor="let hospital of doctorData.hospital_data">{{hospital.name}}-{{hospital.location.city}}</span></p>
        </div>
      </div>

      <hr />
      <div class="clearfix"><br /></div>
      <div class="reset-password">
        <mat-accordion>
          <form [formGroup]="dataForm" (ngSubmit)="changePassword()" validate>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Change Password
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <br>
                <mat-form-field appearance="outline" class="col-md-12 col-xs-12">
                  <mat-label>New Password</mat-label>

                  <input matInput type="password" formControlName="new_password" placeholder="New Password">
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline" class="col-md-12 col-xs-12">
                  <mat-label>Confirm Password</mat-label>

                  <input matInput type="password" formControlName="confirmPassword" placeholder="Confirm Password">
                </mat-form-field>
                <p *ngIf="this.error" class="errorcls">{{this.message}}</p>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-flat-button color="primary" class="centerbtn" [disabled]="!dataForm.valid">Reset
                    Password</button>
                </div>
              </div>
            </mat-expansion-panel>
          </form>
        </mat-accordion>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <h4 class="h4title">Or</h4>


          <button mat-raised-button class="centerbtn pull-left notranslate" (click)="logoutAccount()">
            <i class="material-icons notranslate">
              exit_to_app
            </i> Logout from all devices</button>
          <div class="clearfix"><br /><br /></div>
          <div class="col-md-6 p-0">
            <a *ngIf="isFerrer" class="tncandpolicy" [routerLink]="['serviceagreement-es']">Terms and
              Condition</a>
            <a *ngIf="!isFerrer" href="https://documents.wellthy.app/termsandcondition" target="_blank" class="tncandpolicy" rel="noopener">Terms and
              Condition</a>
            <a *ngIf="!isFerrer"  href="https://documents.wellthy.app/privacypolicy" target="_blank" class="tncandpolicy" rel="noopener">Privacy
              Policy</a>
            <a *ngIf="!isFerrer"  href="mailto:helpdesk@wellthy.care?subject=Opt out from Doctor Dashboard&body=Hi, I want to opt out from Doctor dashboard."
              target="_blank" class="tncandpolicy">Opt Out</a>
          </div>
        </div>
      </div>
      <br />
      <!-- <ng-template #buttonSubmitLoader>
        <app-loader showLoader="{{true}}"></app-loader>
      </ng-template> -->
    </div>
  </div>
</mat-drawer-container>