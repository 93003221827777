import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ReplaySubject} from 'rxjs/index';
import { SystemService } from 'src/app/shared/services';
import { CommonserviceService } from '../../services/commonservice/commonservice.service';

@Component({
  selector: 'app-editpaymentmapping',
  templateUrl: './editpaymentmapping.component.html',
  styleUrls: ['./editpaymentmapping.component.scss']
})
export class EditpaymentmappingComponent implements OnInit {
  isNewItem: any;

  constructor( @Inject(MAT_DIALOG_DATA) public dataModal: any,private router: Router, public fb: FormBuilder,  private commonService: CommonserviceService, private systemService: SystemService, private route: ActivatedRoute, public dialogRef: MatDialogRef<EditpaymentmappingComponent>) { }
  ngOnInit(): void {
    this.getTherapy();
    this.getClients();
    this.getPlans();
    this.isNewItem = this.dataModal.element;
    if (this.isNewItem) {
      this.newitem = false;
      this.userid = this.isNewItem
      this.getPaymentMapData(this.isNewItem);
    }
    else {
      this.newitem = true;
    }
  }

  mySelections: string[];
  public userid: any;
  public allclients: any;
  allplan:any;
  length:boolean = false;
  public alltherapies: any;
  public showButtonLoader: false;
  public newitem: boolean = true;
  productdata:any;
  public dataForm = this.fb.group({
    client_data_fk: [''],
    therapy_data_fk: ['', Validators.required],
    plan_ids: ['', Validators.required],
    created_by_data_fk:['']
  });
  public searchmattherapy: FormControl = new FormControl();
  public searchmathc: FormControl = new FormControl();
  public searchmatclient: FormControl = new FormControl();
  public searchmatplan: FormControl = new FormControl();
  public searchmatcampaign: FormControl = new FormControl();
  public searchmatdoc: FormControl = new FormControl();

  @ViewChild('singleSelectTherapy', { static: false }) singleSelectTherapy: MatSelect;
  @ViewChild('singleSelectHC', { static: false }) singleSelectHC: MatSelect;
  @ViewChild('singleSelectClient', { static: false }) singleSelectClient: MatSelect;
  @ViewChild('singleSelectCampaign', { static: false }) singleSelectCampaign: MatSelect;
  @ViewChild('singleSelectDOC', { static: false }) singleSelectDOC: MatSelect;
  @ViewChild('singleSelectplan', { static: false }) singleSelectplan: MatSelect;
  
  public filteredTherapy: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredHC: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredDoc: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredPlan: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredCampaign: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  modelChangedTherapy() {
    this.filterTherapy();
  }
  modelChangedClient() {
    this.filterClients();
  }

  modelChangedPlan() {
    this.filterPlan();
  }

  private filterClients() {
    if (!this.allclients) {
      return;
    }
    // get the search keyword
    let search = this.searchmatclient.value;
    if (!search) {
      this.filteredClient.next(this.allclients.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredClient.next(
      this.allclients.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterPlan() {
    if (!this.allplan) {
      return;
    }
    // get the search keyword
    let search = this.searchmatplan.value;
    if (!search) {
      this.filteredPlan.next(this.allplan.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredPlan.next(
      this.allplan.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterTherapy() {
    if (!this.alltherapies) {
      return;
    }
    // get the search keyword
    let search = this.searchmattherapy.value;
    if (!search) {
      this.filteredTherapy.next(this.alltherapies.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    // filter the modules
    this.filteredTherapy.next(
      this.alltherapies.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }


  // To Get Payment Map Data
  // ----------------------------

  getPaymentMapData(ide) {
    this.commonService.getOneDataById(ide, "productmapping")
    .subscribe(
      (res: any) => this.onPaymentDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }
  
  private onPaymentDataSuccess(data) {
    data = (data as any).data;
    this.productdata = data;
    
    if(this.productdata.client_data_fk){
      this.productdata.client_data_fk = this.productdata.client_data_fk.toString();
    }
    else{
      this.productdata.client_data_fk = '';
    }
    this.productdata.therapy_data_fk = this.productdata.therapy_data_fk.toString();
    this.dataForm.patchValue(this.productdata);
  }

  // ----------------------------


  // To Get Therapy Data
  // ----------------------------

  getTherapy() {
    this.commonService.getGraphQlData('{getAllTherapies(appMode:false,status:true){id,title}}')
    .subscribe(
      (res: any) => this.onTherapyDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onTherapyDataSuccess(data) {
    data = (data as any).data;
    this.alltherapies = data.getAllTherapies;
    this.filteredTherapy.next(this.alltherapies.slice());
  }

  // ----------------------------


  // To Get Client
  // ----------------------------

  getClients() {
    this.commonService.getGraphQlData('{getAllClients(appMode: false, status:true){id,title}}')
    .subscribe(
      (res: any) => this.onClientDataSuccess(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private onClientDataSuccess(data) {
    data = (data as any).data;
    this.allclients = data.getAllClients;
    this.filteredClient.next(this.allclients.slice());
  }

  // ----------------------------


  // To Get Plans
  // ----------------------------

  getPlans() {
    this.commonService.getGraphQlData('{getParentProductPlans(status:true){id,title}}')
    .subscribe(
      (res: any) => this.getPlanData(res),
      (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
    )
  }

  private getPlanData(data) {
    data = (data as any).data;
    this.allplan = data.getParentProductPlans;
    this.filteredPlan.next(this.allplan.slice());
  }

  // ----------------------------

  changed() {
    if (this.dataForm.value.plan_ids.length < 3) {
      this.length = true;
    }
    else {
      this.length = false;
    }
  }


  // Submitting Payment Map Data
  // ----------------------------  

  dataObject(){
    var postdata = this.dataForm.value;
    postdata['created_by_data_fk'] = localStorage.getItem('scarletuserid');
    postdata.client_data_fk = postdata.client_data_fk.toString();
    postdata.therapy_data_fk = postdata.therapy_data_fk.toString();
    postdata.plan_ids = JSON.stringify(postdata.plan_ids);
    
    if (this.newitem == true) {
      this.commonService.postToBlade(postdata, 'productmapping')
      .subscribe(
        (res: any) => this.onCreateMapping(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
    else{
      this.commonService.updatedataToBlade(this.userid, postdata, 'productmapping')
      .subscribe(
        (res: any) => this.onUpdateMapping(),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
    }
  }

  private onCreateMapping() {
    this.systemService.showSuccessSnackBar(("Payment Mapping has been created"), "OK");
    this.dialogRef.close();  
    this.router.navigate(['admin/paymentmapping']);
  }

  private onUpdateMapping() {
    this.systemService.showSuccessSnackBar(("Payment Mapping has been updated"), "OK");
    this.dialogRef.close();  
    this.router.navigate(['admin/paymentmapping']);
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  // ---------------------------- 

}
