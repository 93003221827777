
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/internal/operators';
import { SystemService } from 'src/app/shared/services';
import { ContextService } from '../../services/context.service';
import { UserdetailsService } from '../../services/userdetails/userdetails.service';
import * as moment from 'moment';
@Component({
  selector: 'app-medicationadd',
  templateUrl: './medicationadd.component.html',
  styleUrls: ['./medicationadd.component.scss']
})
export class MedicationaddComponent implements OnInit {

  public selectedUserID: any;
  public typeFilters: any;
  public remType: any;
  public showButtonLoader: boolean;
  public showmLh: boolean;
  public showUH: boolean;
  public showpLh: boolean;
  public showmL24h: boolean;
  public showmLday: boolean;
  public showFlow: boolean;
  public showmcg: boolean;
  public showtablet: boolean;
  public showDose: boolean;
  public showDuration: boolean = true;
  public showCustomDays!: boolean;
  public schedule_data: any;
  public showmgml: boolean;
  public showngml: boolean;
  public showmcgml: boolean;
  public showmg: boolean;
  public showMeal: boolean;
  public showBreath: boolean;
  public showOralDose: boolean;
  public showInhaleDose: boolean;
  public showOtherDose: boolean;
  noofoptions: any;
  drugconcentration: any;
  public dosageData: any;
  mainoption: any = 'all';
  public morrow: any = new Date();
  public filteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  checked: boolean = false;
  isCustomInter: boolean = false;
  allMedications: any;
  myhash: any;
  ROAList: any;
  newitem: boolean = false;
  public dataForm = this.fb.group({
    patient_id_fk: [''],
    medication_id_fk: ['', [Validators.required]],
    medication_route_type: ['', Validators.required],
    flow_rate: [''],
    flow_rate_unit: [''],
    dose: ['', Validators.required],
    dose_unit: [''],
    drug_concentration: [['']],
    drug_concentration_unit: [''],
    is_custom_interval: [false],
    start_date: [''],
    end_date: [''],
    days: [''],
    custom_days: [''],
    featurecount: [''],
    time: this.fb.array([]),
    meal_type: [''],
    number_of_breaths: ['']
  });

  currentTime = new Date();

  minDate = new Date();
  maxDate = new Date(this.minDate.getFullYear(), +20, this.dataForm.get('start_date')?.value);
  constructor(public dialog: MatDialog,
    private UserdataService: UserdetailsService, private appContext: ContextService, public fb: FormBuilder, private systemService: SystemService) { }

  ngOnInit(): void {
    this.selectedUserID = this.appContext.selectedChatUser.getValue();
    this.getallMedications();
  }

  dateClass = (d: any) => {
    const year = d.getYear();
    const date = d.getDate();
    const day = d.getDay();




    // Highlight the 1st and 20th day of each month.
    return (day == 1 || date == 16 || year == 1982) ? 'notranslate' : undefined;
  }


  // get All medication

  getallMedications() {
    this.UserdataService.getGraphQlData('{getAllMedication(status:true){id,title,route_of_administration,drug_concentration}}')
      .subscribe(
        (res: any) => this.onSuccessUser(res),
        (err: HttpErrorResponse) => this.systemService.handleErrorResponse(err)
      )
  }

  private onSuccessUser(data) {
    data = (data as any).data;

    this.allMedications = data.getAllMedication;
  }

  getROAList(allROA) {
    // this.ROAList = allROA.map(i => this.myhash[i]);
    //   return allROA;
    this.allMedications.filter(x => x.id === allROA);
    this.allMedications.filter((x) => {
      if (x.id === allROA) {
        this.ROAList = x.route_of_administration;
      }
    });

    this.getDrugConc(allROA)
  }

  getDrugConc(ROA) {

    this.allMedications.filter(x => x.id === ROA);
    this.allMedications.filter((x) => {
      if (x.id === ROA) {
        this.drugconcentration = x.drug_concentration;
      }
    });
  }


  getFlowUnits(ROA) {
    if (ROA == 'subcutaneous') {
      this.showmLh = true;
      this.showUH = true;
      this.showpLh = true;
      this.showmL24h = false;
      this.showmLday = false;
      this.showmcg = false;
      this.showtablet = false;
      this.showFlow = true;
      this.showDose = true;
      this.showDuration = true;
      this.showmgml = true;
      this.showngml = false;
      this.showmcgml = false;
      this.showmg = false;
      this.showMeal = false;
      this.showBreath = false;
      this.showOralDose = false;
      this.showInhaleDose = false;
      this.showOtherDose = true;
    } else if (ROA == 'intravenous') {
      this.showmLh = true;
      this.showUH = false;
      this.showpLh = true;
      this.showmL24h = true;
      this.showmLday = false;
      this.showmcg = false;
      this.showtablet = false;
      this.showFlow = true;
      this.showDose = true;
      this.showDuration = true;
      this.showCustomDays = true;
      this.showmgml = false;
      this.showngml = true;
      this.showmcgml = false;
      this.showmg = false;
      this.showMeal = false;
      this.showBreath = false;
      this.showOralDose = false;
      this.showInhaleDose = false;
      this.showOtherDose = true;
    } else if (ROA == 'inhaled') {
      this.showFlow = false;
      this.showmLh = false;
      this.showUH = false;
      this.showpLh = false;
      this.showmL24h = false;
      this.showmLday = false;
      this.showmcg = true;
      this.showtablet = false;
      this.showDose = true;
      this.showDuration = true;
      this.showmgml = false;
      this.showngml = false;
      this.showmcgml = true;
      this.showmg = false;
      this.showMeal = false;
      this.showBreath = true;
      this.showOralDose = false;
      this.showInhaleDose = true;
      this.showOtherDose = false;
    }
    else if (ROA == 'implantable') {
      this.showFlow = true;
      this.showmLh = false;
      this.showUH = false;
      this.showpLh = false;
      this.showmL24h = false;
      this.showmLday = true;
      this.showmcg = false;
      this.showtablet = false;
      this.showDose = false;
      this.showDuration = false;
      this.showmgml = false;
      this.showngml = false;
      this.showmcgml = false;
      this.showmg = false;
      this.showMeal = false;
      this.showBreath = false;
      this.showOralDose = false;
      this.showInhaleDose = false;
      this.showOtherDose = true;
    }
    else if (ROA == 'oral') {
      this.showFlow = false;
      this.showmLh = false;
      this.showUH = false;
      this.showpLh = false;
      this.showmL24h = false;
      this.showmLday = false;
      this.showmcg = false;
      this.showtablet = true;
      this.showDose = true;
      this.showDuration = true;
      this.showCustomDays = false;
      this.showmgml = false;
      this.showngml = false;
      this.showmcgml = false;
      this.showmg = true;
      this.showMeal = true;
      this.showBreath = false;
      this.showOralDose = true;
      this.showInhaleDose = false;
      this.showOtherDose = false;
    }
  }

  isCustomInterval(value) {
    this.isCustomInter = !value;
  }

  changeValue(value) {
    this.checked = !value;
  }

  initTextOptions() {
    return this.fb.group({
      e: [true],
      t: ['', Validators.required],
      i: [this.generateRandomNumber()]
    });
  }
  generateRandomNumber() {
    return Math.random().toString(36).replace(/[^0-9]+/g, "").substr(0, 5);
  }

  // To generate options
  // ----------------------------

  generateOptions(e) {
    this.noofoptions = e;
    for (let j = 0; j < e; j++) {
      this.filteredTags[j] = new ReplaySubject<any[]>(1);
    }
    const t = <FormArray>(this.dataForm.controls['time']);
    if (t.length !== 0) {
      if (t.length < e) {
        for (let l = t.length; l < e; l++) {
          t.push(this.initTextOptions());
        }
      }
      if (t.length > e) {
        for (let l = t.length; l > e; l--) {
          t.removeAt(l - 1);
        }
      }
    }
    else {
      for (var k = 0; k < e; k++) {
        t.push(this.initTextOptions());
      }
    }
  }



  dataObject() {
    var t = [];
    var e = [];
    var i = [];
    var postdata = this.dataForm.value;

    postdata.medication_route_type = postdata.medication_route_type;
    postdata.is_custom_interval = "false";
    if (postdata.drug_concentration_unit) {
      postdata.drug_concentration = JSON.parse(postdata.drug_concentration);
    } else {
      delete postdata.drug_concentration;
    }
    if (postdata.flow_rate_unit) {
      postdata.flow_rate_unit = postdata.flow_rate_unit;
    } else {
      delete postdata.flow_rate_unit
    }

    if (postdata.flow_rate) {
      postdata.flow_rate = postdata.flow_rate;
    } else {
      delete postdata.flow_rate
    }
    if (postdata.dose) {
      postdata.dose = postdata.dose;
    } else {
      delete postdata.dose
    }
    if (postdata.dose_unit) {
      postdata.dose_unit = postdata.dose_unit;
    } else {
      delete postdata.dose_unit
    }

    if (postdata.custom_days) {
      postdata.custom_days = postdata.custom_days.toString();

    } else {
      delete postdata.custom_days;
    }
    if (postdata.days) {
      postdata.days = JSON.stringify(postdata.days);
    } else {
      delete postdata.days;
    }

    var newArray = [];
    if (this.dataForm.value.time.length != 0 && this.dataForm.value.time != '[]') {
      newArray = postdata.time.map(el => {
        return {
          e: el.e,
          t: this.convertIsoDatetime(el.t),
          i: this.convertIsoDate1(el.t)
        }
      });
    }
    if (postdata.time.length != 0) {
      postdata.time = JSON.stringify(newArray);
    } else {
      delete postdata.time;
    }
    if (postdata.meal_type) {
      postdata.meal_type = postdata.meal_type;
    } else {
      delete postdata.meal_type
    }
    if (postdata.number_of_breaths) {
      postdata.number_of_breaths = postdata.number_of_breaths.toString();
    } else {
      delete postdata.number_of_breaths
    }
    var start_date = new Date( postdata.start_date);
    var end_date = new Date( postdata.end_date);
    var date = new Date();
    var finalStartDate = null;
    var finalEndDate = null;
  
    start_date.setHours(date.getHours(),date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    end_date.setHours(date.getHours(),date.getMinutes(), date.getSeconds(), date.getMilliseconds());
   
    finalStartDate = moment(start_date).toISOString();
    finalEndDate = moment(end_date).toISOString();

   if (postdata.start_date == null || postdata.start_date == "") {
      postdata.start_date = ""
      delete postdata.start_date;
    } else {
      postdata.start_date = finalStartDate;
    }
    if (postdata.end_date == null || postdata.end_date == "") {
      postdata.end_date = ""
      delete postdata.end_date;
    } else {
      postdata.end_date = finalEndDate;
    }
    postdata.patient_id_fk = this.selectedUserID.toString();
    postdata.language_data_fk = "1";
    postdata['created_by_fk'] = localStorage.getItem('scarletuserid')
    if (postdata.custom_days) {
      postdata['is_custom_interval'] = "true"
    }
    else {
      postdata['is_custom_interval'] = "false"
    }
    delete postdata.featurecount;

    if (this.newitem == true) { }

    this.UserdataService.addMedication(postdata).pipe(
      catchError((error: any) => {
        this.showButtonLoader = false;
        this.systemService.showSnackBar(error.error.message ? error.error.message : 'Sorry something went wrong.', 'OK');
        return throwError(error);
      }),
      finalize(() => {
        this.showButtonLoader = false;
      })
    ).subscribe(
      (data) => {
        this.dialog.closeAll();
        //  this.sendMqttRequest('Medication');
        this.systemService.showSnackBar("Medication added sucessfully.", "OK");
        this.showButtonLoader = false;
      })
  }


  convertIsoDate1(d) {
    var newdate = new Date(d);
    var date = newdate.toISOString();
    var isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return isoDate;
  }

  convertIsoDatetime(d) {
    var date = new Date(d);

    // var UTCdate = date.toUTCString()
    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // var getHr = date.getUTCHours()
    // var getMin = date.getUTCMinutes()
    // var getTime = getHr +":"+getMin
    // 
    //  return getTime.toString();
    const number = moment(date).format("HH:mm");

    // return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return number;
  }

  convertIsoDate(d) {
    var newdate = new Date(d);
    var date = newdate.toISOString();
    var isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return isoDate;
  }

  
  // sendMqttRequest(type){
  //   var obj = {
  //     link : "https://wellthy.page.link/B421",
  //     type : type
  //   }
  //   this.appContext.changeMessage(obj);
  // }

}
