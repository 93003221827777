import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/shared/services';
import { ContentserviceService } from '../../../services/contentservice/contentservice.service';

@Component({
  selector: 'app-viewactivationcode',
  templateUrl: './viewactivationcode.component.html',
  styleUrls: ['./viewactivationcode.component.scss']
})

export class ViewactivationcodeComponent implements OnInit {
   public isactive = 'all';
  public showButtonLoader: any;
  public alltags: any;
  displayedColumns: string[] = ["code", "isactive", "hasclient", "hascampaign", "hashc"];
  dataSource: any;
  lastSearch: any;
  searchcriteria: any;
  tabulardata: any;
  public newsection: any = 'new';
  public page: number = 1;
  public lastpage: number = 0;
  public endoflist: boolean = false;
  public onit: boolean = true;
  public emptyplaceholder: boolean = false;
  public primarylanguage: any;
  public alllanguages: any;
  public tags: any;
  public therapyid: any;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private browserTitle: Title, private route: ActivatedRoute, private contentService: ContentserviceService, private systemService: SystemService) { }

  private setSEOTags(): void {
    this.browserTitle.setTitle('Therapy | Therapy View Activation Code');
  }

  ngOnInit() {
    this.setSEOTags();
    this.route.params.subscribe(params => {
      this.therapyid = params.id;
    })
    this.searchcriteria = "";
    this.page = 1;
  }

  getNextSet() {
    if (this.endoflist == false) {
      this.page = this.page + 1;
    }
  }

  livesearch() {
    if (this.lastSearch != this.searchcriteria) {
      this.page = 1;
      this.lastpage = 0;
      this.endoflist = false;
      this.onit = true;
      // this.search();
    }
  }
  
  // search() {
  //   if (this.lastSearch != this.searchcriteria || this.page != this.lastpage) {
  //     this.lastSearch = this.searchcriteria;
  //     this.lastpage = this.page;
  //     this.contentService.getAllActivationData(this.searchcriteria, this.page.toString(), "20", "active", this.therapyid, "activationcode")
  //       .pipe(
  //         catchError((error: any) => {
  //           this.showButtonLoader = false;
  //           this.systemService.showSnackBar(((error.error && error.error.error_message) ? error.error.error_message : "Sorry something went wrong."), "OK");
  //           return throwError(error);
  //         }),
  //         finalize(() => { this.showButtonLoader = false; })
  //       ).subscribe(
  //         (data) => {
  //           data = (data as any).data;
  //           if (data.length == 0) {
  //             if (this.onit == true) {
  //               this.tabulardata = [];
  //               this.emptyplaceholder = true;
  //               this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  //             }
  //             else {
  //               this.endoflist = true;
  //               this.emptyplaceholder = false;
  //             }
  //           }
  //           else {
  //             this.emptyplaceholder = false;
  //             if (this.onit) {
  //               this.tabulardata = data;
  //             }
  //             else {
  //               for (var k = 0; k < data.length; k++) {
  //                 this.tabulardata.push(data[k]);
  //               }
  //             }
  //             this.onit = false;
  //             this.dataSource = new MatTableDataSource<Element>(this.tabulardata);
  //           }
  //         })
  //   }
  // }


}
export interface PeriodicElement {
  code: string;
  isactive: string;
  hasclient: string;
  hascampaign: string;
  hashc: string;
}
